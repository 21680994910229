import { TableHeadCell } from '@momentum/routes/campaign/e-commerce/common/TableHeadCell'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, Typography, Box, Stack } from '@mui/material'
import { first, last, sumBy } from 'lodash'
import { useSalesContext } from '../salesContext'
import { useValueFormatter } from '../utils'
import { SalesReportView } from '../salesContext'
import { useMemo } from 'react'

const BodyTableCell = styled(TableCell)(({ theme }) => ({
  ...theme.typography.label2,
  fontWeight: 400
}))

export const SalesTable = () => {
  const { filteredSalesData, salesReportView } = useSalesContext()
  const valueFormatter = useValueFormatter()

  const minDate = first(filteredSalesData)?.date
  const maxDate = last(filteredSalesData)?.date

  if (!minDate || !maxDate) return null

  const weeks = Math.ceil(maxDate.diff(minDate, 'weeks').weeks || 1)
  const weeksArray = new Array(weeks).fill(0)

  const weekData = weeksArray.map((_, index) => {
    const start = minDate.plus({ weeks: index })
    const end = start.plus({ weeks: 1 })
    const weekRangeData = filteredSalesData.filter(d => d.date >= start && d.date < end)
    return {
      start,
      end,
      totalSum: sumBy(weekRangeData, i => i.value)
    }
  })

  const headerUnits = useMemo(() => {
    return salesReportView === SalesReportView.UNITS_SOLD ? 'units sales' : salesReportView
  }, [salesReportView])
  const rowUnits = useMemo(() => {
    return salesReportView === SalesReportView.UNITS_SOLD ? 'units' : salesReportView
  }, [salesReportView])

  return (
  <Stack bgcolor={'white'} border={t => `1px solid ${t.palette.grey.A200}`} borderRadius={'4px'}>
    <TableContainer
      data-cy="salesTable"
      sx={{
        '&::-webkit-scrollbar': {
          height: '40px',
          background: 'white'
        },
        '&::-webkit-scrollbar-thumb': {
          border: '16px solid white',
          background: '#C4C4C4',
          borderRadius: '25px',
          height: '10px',
          width: '10px'
        }
      }}
    >
      <Table
        sx={{
          overflowX: 'auto',
          whiteSpace: 'nowrap'
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant={'label1'}>Weekly {headerUnits}</Typography>
            </TableCell>
            {weekData.map((d, index) => (
              <TableHeadCell key={index} week={index + 1} date={d.start} align={'center'} />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <BodyTableCell>
              <Stack direction={'row'} spacing={1}>
                <Box width={8} borderRadius={100} bgcolor={'info.main'} />
                <Typography variant={'label4'} fontWeight={800}>
                  Total {rowUnits}
                </Typography>
              </Stack>
            </BodyTableCell>
            {weekData.map((d, index) => (
              <BodyTableCell key={index} align={'center'}>
                {valueFormatter(d.totalSum)}
              </BodyTableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </Stack>
  )
}
