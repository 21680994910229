import { ReactNode } from 'react'
import { Stack, Typography } from '@mui/material'

export const Question = ({
  primaryText,
  subtext,
  children,
  fullWidth = true
}: {
  primaryText?: string | React.ReactNode
  subtext?: string | ReactNode
  fullWidth?: boolean
  children: ReactNode
}) => {
  return (
    <Stack alignItems={fullWidth ? 'stretch' : 'flex-start'}>
      {primaryText && (
        <Typography variant={'label1'} mb={subtext ? 0.5 : 2}>
          {primaryText}
        </Typography>
      )}
      {subtext && (
        <Typography variant={'label3'} mb={2} color={'grey.A700'}>
          {subtext}
        </Typography>
      )}
      {children}
    </Stack>
  )
}
