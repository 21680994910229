import { SvgIcon, SvgIconProps } from '@mui/material'

export default function EasterEggIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 25" fill="none">
      <path
        d="M6.48251 9.25233C6.48251 9.25233 5.11376 10.7298 4.02813 11.5755C2.83938 12.498 0.583757 13.4748 0.846257 14.3336C1.04126 14.9636 2.78688 15.0067 3.39438 14.8492C4.00188 14.6917 6.61563 16.4861 6.61563 16.4861C6.61563 16.4861 18.8631 16.143 18.9963 16.0117C19.1275 15.8805 20.8713 14.7442 21.3456 14.7705C21.82 14.7967 22.8944 14.5117 23.1138 13.9792C23.2994 13.5311 22.2156 13.0023 21.1075 12.2767C19.2963 11.0917 18.7319 10.0998 18.7319 10.0998C18.7319 10.0998 19.8063 6.64983 17.4831 4.25733C15.31 2.0167 10.8644 1.40358 8.22438 4.11295C6.16751 6.22608 6.48251 9.25233 6.48251 9.25233Z"
        fill="#FFB903"
      />
      <path
        d="M5.34797 13.4751L5.42672 14.9282L4.37109 15.0332L5.34797 13.4751ZM18.7298 15.5338L18.8086 14.0301L19.733 14.9545L18.7298 15.5338Z"
        fill="#B0B1B3"
      />
      <path
        d="M12.3407 23.48C17.435 23.45 20.915 19.1468 21.0782 15.7193C21.1307 14.6112 20.4707 14.7687 20.4707 14.7687L19.4675 14.3993L17.99 15.56C17.99 15.56 15.3763 13.685 15.1119 13.6325C14.8475 13.58 12.1025 15.4006 12.1025 15.4006C12.1025 15.4006 9.22441 13.5537 8.98816 13.5537C8.75004 13.5537 6.45504 15.2693 6.45504 15.2693L4.73941 14.0806L3.78879 14.7931L3.10254 15.0312C3.10254 15.0331 3.28816 23.5325 12.3407 23.48Z"
        fill="#DEDEDE"
      />
      <path
        d="M5.39127 19.0006C6.18252 20.1988 6.83502 20.7069 7.08064 20.9188C7.32627 21.1306 7.71439 21.3594 7.96002 21.0763C8.20564 20.795 7.92439 20.495 7.57377 20.1444C7.22314 19.7938 6.39439 18.9125 6.06064 18.4194C5.72689 17.9263 5.37439 16.9231 5.30314 16.7131C5.23189 16.5013 4.95064 16.0794 4.58127 16.2556C4.21189 16.4319 4.33564 16.8894 4.44064 17.1706C4.54752 17.4519 4.74064 18.0163 5.39127 19.0006Z"
        fill="#FDFDFD"
      />
      <path
        d="M9.74561 21.7414C9.82291 21.4856 9.63325 21.2021 9.322 21.108C9.01075 21.0139 8.69577 21.145 8.61847 21.4007C8.54118 21.6565 8.73084 21.94 9.04209 22.0341C9.35334 22.1282 9.66832 21.9971 9.74561 21.7414Z"
        fill="#FDFDFD"
      />
      <path
        d="M5.83092 12.8769C6.23217 13.1712 5.70717 13.6156 5.51405 13.8275C5.38842 13.9662 4.49405 15.0762 3.29592 15.2169C2.14655 15.3519 1.4828 15.0069 1.0178 14.5831C0.631548 14.2306 0.867799 14.0337 1.14905 14.2794C1.3178 14.4275 2.08092 14.7594 3.06717 14.6C4.05342 14.4406 4.72092 13.7731 5.00217 13.4731C5.16717 13.3006 5.56655 12.6837 5.83092 12.8769ZM18.114 13.2106C17.8947 13.4675 18.1665 13.8256 18.624 14.1612C19.0815 14.4969 20.1672 15.3387 21.7909 15.0725C23.0753 14.8606 23.2872 13.8012 23.0584 13.8987C22.8297 13.9962 22.3365 14.4612 21.7384 14.5137C21.1403 14.5662 20.3078 14.4687 19.6797 14.0562C19.1697 13.7225 18.9934 13.5462 18.6934 13.3175C18.594 13.2406 18.324 12.965 18.114 13.2106Z"
        fill="#FF8E01"
      />
      <path
        d="M10.772 6.4007C10.7232 6.9407 10.4288 7.48257 9.87945 7.38695C9.29445 7.2857 9.12758 6.79632 9.17445 6.25632C9.2232 5.71632 9.63945 5.39195 10.0538 5.42945C10.4682 5.46507 10.8188 5.8607 10.772 6.4007ZM17.1695 7.75257C17.0645 8.28507 16.7251 8.75007 16.1795 8.63945C15.5963 8.52132 15.4707 7.98507 15.6282 7.3382C15.7576 6.81132 16.2245 6.6257 16.6313 6.70445C17.0401 6.78507 17.2726 7.22007 17.1695 7.75257Z"
        fill="#2E3030"
      />
      <path
        d="M13.3829 7.00839C13.2629 6.94089 11.2979 8.56652 11.2979 8.56652C11.2979 8.56652 12.2616 10.824 12.3535 10.8371C12.4454 10.8503 14.3335 9.29214 14.3335 9.29214C14.3335 9.29214 13.4766 7.06089 13.3829 7.00839Z"
        fill="#FF8C00"
      />
      <path
        d="M10.9351 8.39952C10.8207 8.64514 11.0232 8.82139 11.4188 8.95452C11.8144 9.08764 12.0882 9.13077 12.0882 9.13077C12.0882 9.13077 12.2738 9.83389 12.5813 9.91452C12.8888 9.99327 13.3726 9.46639 13.3726 9.46639C13.3726 9.46639 13.7682 9.50952 13.9351 9.51889C14.1019 9.52827 14.5501 9.67452 14.5951 9.34264C14.6476 8.95639 14.1544 9.01639 13.9876 8.97327C13.8207 8.93014 13.4326 8.82327 13.4326 8.82327C13.4326 8.82327 13.3276 8.11077 13.0538 8.03202C12.7819 7.95327 12.2701 8.57764 12.2701 8.57764C12.2701 8.57764 11.7957 8.42764 11.6551 8.38452C11.5144 8.33764 11.0476 8.15389 10.9351 8.39952Z"
        fill="#FF5F02"
      />
    </SvgIcon>
  )
}
