import { yupResolver } from '@hookform/resolvers/yup'
import { ImageSelect } from '@momentum/components/image-select'
import { AgencyInputData } from '@momentum/forms/types'
import { getImageKeyFromUrl } from '@momentum/utils/imageUtils'
import { urlPreTransform } from '@momentum/utils/validationUtils'
import { Typography } from '@mui/material'
import { Stack } from '@mui/system'
import {
  PaymentTermsType,
  UploadRequestType,
  InvoiceMethod,
  SubscriptionLevel
} from '@productwindtom/shared-momentum-zeus-types'
import { Form, RadioInput, SubmitButton, TextInput } from '@productwindtom/ui-base'
import { v4 } from 'uuid'
import * as yup from 'yup'
import { BackButton } from '../../components/back-button'
import AgencyFormFields from '../agency-form/AgencyFormFields'
import { agencyFieldsSchema } from '../agency-form/validation'
import PaymentTermsFields from '@momentum/forms/inputs/PaymentTermsFields'

const schema = yup
  .object({
    id: yup.string().optional(),
    name: yup.string().required('Required'),
    logo: yup
      .string()
      .notRequired()
      .transform(s => (s ? getImageKeyFromUrl(s) : s)),
    websiteUrl: yup.string().transform(urlPreTransform).url('Not a valid URL').required('Required'),
    paymentTermsType: yup.string<PaymentTermsType>().required('Required'),
    paymentTermsCustomNetDays: yup.number().when('paymentTermsType', {
      is: PaymentTermsType.NET_CUSTOM,
      then: s => s.required('Required'),
      otherwise: s => s.nullable().optional()
    }),
    invoiceMethod: yup.string<InvoiceMethod>().required('Required'),
    agency: agencyFieldsSchema.notRequired()
  })
  .noUnknown(true)

export type CompanyFormData = {
  id?: string
  name: string
  logo?: string | null
  websiteUrl: string
  subscriptionLevel?: SubscriptionLevel
  paymentTermsType: PaymentTermsType
  paymentTermsCustomNetDays?: number
  invoiceMethod: InvoiceMethod
  agency?: AgencyInputData | null
}

export const CompanyForm = ({
  defaultData,
  hasExistingSubscription,
  onSubmit,
  onCancel
}: {
  defaultData?: CompanyFormData
  hasExistingSubscription?: boolean
  onCancel: () => void
  onSubmit: (data: CompanyFormData) => Promise<void>
}) => {
  const handleSubmit = async (submitValues: CompanyFormData) => {
    await onSubmit({
      ...submitValues,
      paymentTermsCustomNetDays:
        submitValues.paymentTermsType === PaymentTermsType.NET_CUSTOM
          ? submitValues.paymentTermsCustomNetDays
          : undefined
    })
  }

  const initialCompany: CompanyFormData = defaultData
    ? {
        ...defaultData,
        subscriptionLevel: defaultData.subscriptionLevel || SubscriptionLevel.BRAND,
        paymentTermsType: defaultData.paymentTermsType
          ? defaultData.paymentTermsType
          : PaymentTermsType.DUE_UPON_RECEIPT
      }
    : {
        id: v4(),
        name: '',
        websiteUrl: '',
        subscriptionLevel: SubscriptionLevel.BRAND,
        paymentTermsType: PaymentTermsType.DUE_UPON_RECEIPT,
        invoiceMethod: InvoiceMethod.BILL
      }

  return (
    <Form onSubmit={handleSubmit} defaultValues={initialCompany} resolver={yupResolver(schema)}>
      <Stack spacing={3}>
        <TextInput name={'name'} primaryText={'Company name'} placeholder={'Company name'} />
        <TextInput name={'websiteUrl'} primaryText={'Company website'} placeholder={'Company website URL'} />

        <ImageSelect
          name={'logo'}
          primaryText="Upload company logo"
          buttonText={'Click to upload'}
          companyId={initialCompany.id}
          buttonProps={{
            variant: 'outlined'
          }}
          uploadType={UploadRequestType.UPLOAD_COMPANY_LOGO}
        />
        <RadioInput
          name={'subscriptionLevel'}
          primaryText={'Are the subscription and credits managed at the brand level or the company level?'}
          radioProps={{ sx: { py: 0.5 }, disableRipple: true }}
          disabled={hasExistingSubscription}
          options={[
            {
              label: (
                <Typography variant={'label3'}>Subscription and credits are managed at the brand level</Typography>
              ),
              value: SubscriptionLevel.BRAND
            },
            {
              label: (
                <Typography variant={'label3'}>Subscription and credits are managed at the company level</Typography>
              ),
              value: SubscriptionLevel.COMPANY
            }
          ]}
        />
        <PaymentTermsFields
          paymentTermsTypeName="paymentTermsType"
          paymentTermsCustomNetDaysName="paymentTermsCustomNetDays"
        />
        <RadioInput
          primaryText={'What is this company’s invoice method?'}
          name={'invoiceMethod'}
          radioProps={{ sx: { py: 0.5 }, disableRipple: true }}
          options={[
            {
              label: <Typography variant={'label3'}>Generate invoices using Bill.com</Typography>,
              value: InvoiceMethod.BILL
            },
            {
              label: <Typography variant={'label3'}>Generate invoices using a PO</Typography>,
              value: InvoiceMethod.CUSTOM_PO
            }
          ]}
        />
        <AgencyFormFields />
        <Stack direction={'row'} justifyContent={'flex-end'} spacing={1}>
          <BackButton variant={'text'} onClick={onCancel} text={'Cancel'} />
          <SubmitButton variant={'contained'} disableOnDirty={false}>
            Save
          </SubmitButton>
        </Stack>
      </Stack>
    </Form>
  )
}
