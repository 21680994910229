import { mApi } from '@momentum/api'
import {
  BenchmarkType,
  GraphQLTypes,
  InputType,
  ModelTypes,
  Selector
} from '@productwindtom/shared-momentum-zeus-types'

export const etailerProductMetricsSelector = Selector('EtailerProductMetrics')({
  unitsSold: true,
  totalSalesAmount: true,
  pageViews: true,
  date: true,
  skuId: true
})

export type EtailerSkuMetrics = InputType<GraphQLTypes['EtailerProductMetrics'], typeof etailerProductMetricsSelector>

export const getBenchmarkMetrics = async (
  skuId: string,
  benchmarkType: BenchmarkType,
  benchmarkGoal: number,
  variationIds?: string[],
  campaignId?: string,
  limit?: number
) => {
  return (
    await mApi('query')({
      getBenchmarkMetrics: [{ skuId, variationIds, benchmarkGoal, benchmarkType, campaignId, limit }, etailerProductMetricsSelector]
    })
  ).getBenchmarkMetrics
}

export const getCampaignBenchmark = async (campaignId: string, skuId: string) => {
  return (
    await mApi('query')({
      getCampaignBenchmark: [{ skuId, campaignId }, {
        benchmarkedProductIds: true,
        benchmarkGoal: true,
        benchmarkType: true
      }]
    })
  ).getCampaignBenchmark
}

export const createCampaignBenchmark = async (input: ModelTypes['ModelInputCreateCampaignBenchmark']) => {
  return (
    await mApi('mutation')({
      createCampaignBenchmark: [
        {
          input
        },
        {
          campaignId: true,
          skuId: true
        }
      ]
    })
  ).createCampaignBenchmark
}

export const updateCampaignBenchmark = async (input: ModelTypes['ModelInputUpdateCampaignBenchmark']) => {
  return (
    await mApi('mutation')({
      updateCampaignBenchmark: [
        {
          input
        },
        {
          campaignId: true,
          skuId: true
        }
      ]
    })
  ).updateCampaignBenchmark
}
