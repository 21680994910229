import { Theme } from '@mui/material'
import { DateTime } from 'luxon';
import CampaignLaunchImage from '/images/campaign-launch.png'
import CampaignEndedImage from '/images/calendar-check.png'

export const subLabelsPlugin = (
  labels: string[],
  subLabels: (string | null)[],
  chart: any,
  theme: Theme,
  maxItemsBeforeRotationLabels: number = 8
): void => {
  const ctx = chart.ctx;
  const xAxis = chart.scales["x"];

  xAxis.ticks.forEach((tick: any, index: number) => {
    const x = xAxis.getPixelForTick(index);
    const y = xAxis.bottom + 10;

    const label = labels[index];
    const tag = subLabels[index];

    ctx.save();
    ctx.textAlign = "center";
    ctx.font = `16px ${theme.typography.fontFamily}`;

    if (xAxis.ticks.length > maxItemsBeforeRotationLabels) {
      ctx.translate(x, y + 35);
      ctx.rotate(-Math.PI / 3);
      ctx.fillText(label, 0, 0);
      
      if (tag) {
        ctx.font = `13px ${theme.typography.fontFamily}`;;
        ctx.fillStyle = tag === 'Launch' ? theme.palette.info.main :  theme.palette.grey.A700;
        ctx.fillText(tag, 0, 18);
      }
    } else {
      ctx.fillText(label, x, y + 10);
      
      if (tag) {
        ctx.font = `13px ${theme.typography.fontFamily}`;;
        ctx.fillStyle = tag === 'Launch' ? theme.palette.info.main :  theme.palette.grey.A700;
        ctx.fillText(tag, x, y + 30);
      }
    }

    ctx.restore();
  });
}

const drawImage = (imageSrc: string, meta: any, ctx: any) => {
  const imageSize = 24
  const imageOffset = imageSize / 2
  const yOffset = 20
  const image = new Image()
  image.src = imageSrc
  ctx.drawImage(
    image, 
    meta.x - imageOffset,
    meta.y - yOffset - imageOffset,
    imageSize,
    imageSize
  )
}

export const campaignImagePlugin = (
  campaignDetails: any,
  dates: any,
  chart: any,
  weekly: boolean = false
) => {
  if (dates.length === 0) return

  const { ctx } = chart
  const startDateDateTime = DateTime.fromISO(campaignDetails.startDate)
  const endDateDateTime = campaignDetails.endDate ? DateTime.fromISO(campaignDetails.endDate) : undefined
  const startDateNoTime = startDateDateTime.toISODate()
  const endDateNoTime = endDateDateTime?.toISODate()

  chart.data.labels.forEach((_label: string, index: number) => {
    const currentDate = dates[index]
    if (!currentDate) return
    const meta = chart.getDatasetMeta(0).data[index]

    if (weekly) {
      const weekStart = DateTime.fromISO(currentDate)
      const weekEnd = weekStart.plus({ weeks: 1 })
      if (startDateDateTime >= weekStart && startDateDateTime < weekEnd) {
        drawImage(CampaignLaunchImage, meta, ctx)
      } else if (endDateDateTime && endDateDateTime >= weekStart && endDateDateTime < weekEnd) {
        drawImage(CampaignEndedImage, meta, ctx)
      }
    } else {
      if (currentDate === startDateNoTime) {
        drawImage(CampaignLaunchImage, meta, ctx)
      } else if (endDateNoTime && currentDate === endDateNoTime) {
        drawImage(CampaignEndedImage, meta, ctx)
      }
    }
  })
}