import { Line } from 'react-chartjs-2'
import { useBenchmarkContext } from '../../context/BenchmarkContext'
import { useTheme } from '@mui/material'
import { get, keyBy, last } from 'lodash'
import { BENCHMARK_PRODUCT_COLORS, BENCHMARK_TYPE_TO_VALUE } from '../../constants'
import { defaultTooltipOptions } from '@momentum/utils/tooltipUtils'
import { DateTime } from 'luxon'
import { EtailerSkuMetrics } from '../../queries'
import { useMemo } from 'react'
import { BenchmarkType } from '@productwindtom/shared-momentum-zeus-types'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import CampaignLaunchImage from '/images/campaign-launch.png'
import CampaignEndedImage from '/images/calendar-check.png'
import { useState } from 'react'

export const CumulativeGraph = () => {
  const theme = useTheme()
  const { graphMetrics, promotedProductId, enabledBenchmarkProducts, benchmarkData } = useBenchmarkContext()
  const { campaignDetails } = useCampaignContext()
  const [startDateIndex, setStartDateIndex] = useState(0)
  const [endDateIndex, setEndDateIndex] = useState(0)

  const rocketImage = new Image(24, 24)
  rocketImage.src = CampaignLaunchImage

  const completedImage = new Image(24, 24)
  completedImage.src = CampaignEndedImage

  const metrics = useMemo(() => {
    if (graphMetrics) {
      const { metricType } = graphMetrics

      const metricKey = BENCHMARK_TYPE_TO_VALUE[metricType]

      const aggregatedData = Object.entries(benchmarkData).map(([skuId, metrics]) => ({
        data: aggregateData(
          metrics,
          skuId
        ),
        skuId
      })).filter(d => d.data.length > 0)

      const minLength = Math.min(...aggregatedData.map(d => d.data.length))
      const trimmedData = aggregatedData.map(d => ({
        ...d,
        data: d.data.slice(0, minLength)
      }))

      if (trimmedData.length === 0) {
        return {
          labels: [],
          datasets: []
        }
      }

      setStartDateIndex(trimmedData[0].data.findIndex(item => item.date === DateTime.fromISO(campaignDetails.startDate).toISODate()))
      setEndDateIndex(trimmedData[0].data.findIndex(item => item.date === campaignDetails?.endDate ? DateTime.fromISO(campaignDetails?.endDate).toISODate() : undefined))

      const promotedProductData = trimmedData.find(({ skuId }) => skuId === promotedProductId)
      const maxDays = promotedProductData?.data?.length || 0

      const labels = Array.from({ length: maxDays }, (_, i) => `Day ${i}`)

      const datasets = Object.values(trimmedData).map(({ data, skuId }, index) => ({
        data: data.map(item => {
          const value = item[metricKey as keyof typeof item] as number

          return metricType === BenchmarkType.REVENUE && value ? value / 100 : value
        }),
        rawData: data,
        skuId: skuId,
        borderColor: get(theme.palette, BENCHMARK_PRODUCT_COLORS[index]),
        pointBorderColor: get(theme.palette, BENCHMARK_PRODUCT_COLORS[index]),
        pointBackgroundColor: get(theme.palette, BENCHMARK_PRODUCT_COLORS[index]),
        pointStyle: data.map((_, i) => {
          if (index !== 0) {
            return undefined
          }
          return i === startDateIndex ? rocketImage : i === endDateIndex ? completedImage : undefined
        }),
        ...(skuId === promotedProductId && {
          segment: {
            borderColor: function (context: any) {
              const date = data[context.p0DataIndex].date
              const endDate = campaignDetails.endDate || DateTime.now().toISO()

              return date >= campaignDetails.startDate && endDate >= date
                ? get(theme.palette, BENCHMARK_PRODUCT_COLORS[index])
                : `${get(theme.palette, BENCHMARK_PRODUCT_COLORS[index])}`
            }
          }
        })
      }))

      return {
        labels,
        datasets
      }
    }
  }, [graphMetrics, benchmarkData, startDateIndex, endDateIndex])

  const datasets = metrics?.datasets
  const visibleDataSets = useMemo(() => {
    return datasets?.map(dataset => ({
      ...dataset,
      hidden: !enabledBenchmarkProducts.includes(dataset.skuId)
    }))
  }, [datasets, enabledBenchmarkProducts])

  if (!metrics || !graphMetrics) return null

  const { labels } = metrics
  const tickDefinition = labels.map((_, index) => (labels.length < 15 ? 1 : index % 4 === 0 ? 1 : 0))

  return (
    <Line
      data-cy="benchmark-cumulative-graph"
      data={{
        labels: labels,
        datasets: visibleDataSets?.map(d => ({
          ...d,
          borderWidth: 4,
          cubicInterpolationMode: 'monotone'
        })) || []
      }}
      height={500}
      options={{
        borderColor: theme.palette.grey.A400,
        elements: {
          point: {
            radius: value => {
              return value.datasetIndex === 0 && (value.dataIndex === startDateIndex || value.dataIndex === endDateIndex)
                ? 10
                : 0
            },
            hitRadius: 10,
            drawActiveElementsOnTop: true
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            ...defaultTooltipOptions,
            callbacks: {
              label: item => {
                return item.formattedValue
              },
              title: () => '',
              footer: () => ''
            }
          }
        },
        scales: {
          y: {
            display: true,
            beginAtZero: false,
            grid: {
              drawOnChartArea: true,
              drawTicks: false,
              color: theme.palette.grey.A400,
              lineWidth: 1.5
            },
            offset: true,
            ticks: {
              font: {
                family: theme.typography.fontFamily as string,
                weight: theme.typography.fontWeightRegular as number,
                size: theme.typography.label1.fontSize as number
              },
              color: 'black',
              padding: 16
            },
            border: {
              width: 1,
              color: theme.palette.grey.A200
            }
          },
          x: {
            ticks: {
              source: 'labels',
              minRotation: 60,
              padding: 16,
              font: {
                family: theme.typography.fontFamily as string,
                weight: theme.typography.fontWeightRegular as number,
                size: theme.typography.label1.fontSize as number
              }
            },
            grid: {
              drawOnChartArea: true,
              drawTicks: false,
              color: theme.palette.grey.A200,
              lineWidth: tickDefinition
            },
            border: {
              width: 1,
              color: theme.palette.grey.A200
            }
          }
        }
    }}
  />
  )
}

const aggregateData = (metrics: EtailerSkuMetrics[], skuId: string) => {
  if (!metrics.length) return []

  let aggregatedUnitsSold = 0
  let aggregatedTotalSalesAmount = 0
  let aggregatedPageViews = 0

  const startDate = DateTime.fromISO(metrics[0].date)
  const endDate = DateTime.fromISO(last(metrics)?.date || DateTime.now().toISO())
  const metricsByDate = keyBy(metrics, 'date')

  return Array.from({ length: Math.round(endDate.diff(startDate, 'days').days + 2) }, (_, i) => {
    const currentDate = startDate.plus({ days: i })
    const existingMetric = metricsByDate[currentDate.toISODate()!]

    if (existingMetric) {
      aggregatedUnitsSold += existingMetric.unitsSold || 0
      aggregatedTotalSalesAmount += existingMetric.totalSalesAmount || 0
      aggregatedPageViews += existingMetric.pageViews || 0

      return {
        ...existingMetric,
        unitsSold: aggregatedUnitsSold,
        totalSalesAmount: aggregatedTotalSalesAmount,
        pageViews: aggregatedPageViews
      }
    } else {
      return {
        skuId,
        date: currentDate.toISODate()!,
        unitsSold: aggregatedUnitsSold,
        totalSalesAmount: aggregatedTotalSalesAmount,
        pageViews: aggregatedPageViews
      }
    }
  })
}
