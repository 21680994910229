import { SvgIcon, SvgIconProps } from '@mui/material'

export default function ShoppingBagsIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 25" fill="none">
      <path
        d="M5.0544 7.89688C4.91377 6.83188 4.67565 4.95501 4.83315 3.92188C4.99065 2.88876 5.40315 1.93626 6.26377 1.55938C7.00815 1.23501 7.93627 1.57813 8.47252 2.38063C8.77815 2.83813 8.95252 3.40251 9.10065 3.96126C9.35377 4.92126 9.59565 6.23376 9.71752 7.22188"
        stroke="#12B76A"
        strokeWidth="0.5625"
        strokeMiterlimit="10"
      />
      <path
        d="M5.75746 22.3702L1.22559 20.482L2.27746 15.8433L2.05246 5.27203L13.52 3.92578V4.92703L16.2481 5.27203L5.75746 22.3702Z"
        fill="#41CB88"
      />
      <path
        d="M17.2219 20.6451L5.75625 22.3813L4.78125 16.3101V7.00635L16.2488 5.27197V14.5738L17.2219 20.6451Z"
        fill="#71D4A6"
      />
      <path
        d="M4.78184 7.0062V5.95995L2.05371 5.27183L13.5437 3.99683L13.9112 4.81058L16.2493 5.27183L4.78184 7.0062Z"
        fill="#263238"
      />
      <path
        d="M1.22559 20.482C1.30434 20.482 3.94059 18.3913 3.94059 18.3913L5.75559 22.3813L5.36559 16.0195L4.77496 7.00635L4.00809 16.747L1.22559 20.482Z"
        fill="#12B76A"
      />
      <path
        d="M13.51 4.11681L13.7875 4.75806L13.8232 4.84243L13.9113 4.86493L15.3082 5.22306L4.96941 6.78868V5.81368L4.82691 5.77806L3.08691 5.33931L13.51 4.11681ZM13.6282 3.91431L2.05566 5.27181L4.78379 5.95993V7.00806L16.2494 5.27181L13.96 4.68306L13.6282 3.91431Z"
        fill="#41CB88"
      />
      <path
        d="M8.24044 7.70949C8.09981 6.64636 6.63544 2.56261 9.43856 2.04136C11.5573 1.64761 12.4929 6.16074 12.6167 7.15074"
        stroke="#71D4A6"
        strokeWidth="0.5625"
        strokeMiterlimit="10"
      />
      <path
        d="M8.59027 8.00573C8.42715 7.45636 8.25652 6.34636 8.24902 6.29761L8.80402 6.21323C8.8059 6.22448 9.00465 7.35698 9.22215 7.88761L8.59027 8.00573ZM12.9628 7.34011C12.7996 6.79073 12.629 5.68073 12.6215 5.63198L13.1765 5.54761C13.1784 5.55886 13.409 6.71011 13.6021 7.22386L12.9628 7.34011ZM8.7834 21.9239L10.3584 19.1957L9.60652 11.9226L15.9009 10.6982L12.5259 21.3651L8.7834 21.9239Z"
        fill="#41CB88"
      />
      <path
        d="M13.7004 11.197C13.7004 11.197 12.9916 7.43388 15.3522 7.68326C16.6366 7.81826 16.5841 10.8051 16.5841 10.8051"
        stroke="#F04D4D"
        strokeWidth="0.5625"
        strokeMiterlimit="10"
      />
      <path
        d="M13.9614 23.7483L10.4795 22.2915L11.2895 18.7252L11.117 10.599L19.9295 9.56396V10.3346L22.0257 10.599L13.9614 23.7483Z"
        fill="#F04D4D"
      />
      <path
        d="M22.7735 22.4149L13.961 23.7499L13.2129 19.083V11.9336L22.0254 10.5986V17.7499L22.7735 22.4149Z"
        fill="#F58080"
      />
      <path
        d="M13.2125 11.934L12.5075 11.394L11.1162 10.599L19.9287 9.56396L20.7293 10.0683L22.025 10.599L13.2125 11.934Z"
        fill="#263238"
      />
      <path
        d="M19.895 9.75709L21.4043 10.5015L13.2518 11.7352L11.66 10.7227L19.895 9.75709ZM19.9287 9.56396L11.1162 10.599L13.2125 11.934L22.025 10.599L19.9287 9.56396Z"
        fill="#F04D4D"
      />
      <path
        d="M16.1923 12.6519C16.0666 12.2319 15.9373 11.3788 15.9316 11.3431L16.4266 11.2794C16.4285 11.2888 16.5541 12.1475 16.6704 12.5431L16.1923 12.6519ZM19.5466 12.1363C19.421 11.7163 19.2916 10.8631 19.286 10.8275L19.7754 10.7656C19.7773 10.775 19.8785 11.66 19.9948 12.0556L19.5466 12.1363Z"
        fill="#F04D4D"
      />
      <path
        d="M15.9143 12.6707C15.8055 11.8532 14.6918 8.40696 16.7918 8.11633C18.4324 7.88946 19.1393 11.2832 19.2349 12.0426"
        stroke="#F58080"
        strokeWidth="0.5625"
        strokeMiterlimit="10"
      />
      <path
        d="M10.4795 22.2914L12.2589 20.7839L13.9614 23.7501L13.217 19.1114L13.2245 11.9564L13.0145 11.8701L12.3507 19.1432L10.4795 22.2914Z"
        fill="#EA0000"
      />
    </SvgIcon>
  )
}
