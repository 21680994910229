import { Product } from '@momentum/routes/proposals-create/context/queries'
import { Stack } from '@mui/system'
import { CheckCircleOutlined, Cancel } from '@mui/icons-material'
import { Typography, Chip } from '@mui/material'
import PackagePlusIcon from '@momentum/components/icons/package-plus'

export const SelectedProduct = ({ product, onRemove }: { product: Product; onRemove: () => void }) => {
  return (
    <Stack direction={'row'} spacing={1} alignItems={'center'} data-cy="selected-product" display={'flex'}>
      <CheckCircleOutlined color={'success'} />
      <Typography variant={'label3'}>{product?.name}</Typography>

      {!product?.skuId && (
        <Chip
          icon={<PackagePlusIcon color={'primary'} />}
          sx={theme => ({
            background: theme.palette.primary.lighter,
            color: theme.palette.primary.main
          })}
          size={'small'}
          label="Placeholder product"
        />
      )}
      <Cancel
        onClick={onRemove}
        fontSize={'mSmall'}
        sx={{ cursor: 'pointer', color: t => t.palette.grey.A400, pl: 1 }}
      />
    </Stack>
  )
}
