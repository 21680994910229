import { yupResolver } from '@hookform/resolvers/yup'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { Grid, Stack, Typography } from '@mui/material'
import { Form, SubmitButton, TextInput } from '@productwindtom/ui-base'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { Brand } from '../queries'
import { updateBrand } from './queries'
import { captureException } from '@sentry/react'
import { successToast } from '@momentum/utils/toastUtils'

type BrandBillingFormValues = {
  paymentBillingContactId: string
  paymentBillingContactEmail: string
}

const schema = yup.object({
  paymentBillingContactEmail: yup.string().email('Not a valid email').required('Required'),
  paymentBillingContactId: yup.string().required('Required')
})

export const BrandBilling = ({ brand, readOnly }: { brand: Brand; readOnly: boolean }) => {
  const { refreshBrand } = useUserSessionContext()

  const defaultValues = {
    paymentBillingContactEmail: brand.paymentBillingContactEmail ?? '',
    paymentBillingContactId: brand.paymentBillingContactId ?? ''
  }

  const onSubmit = async (submitValues: BrandBillingFormValues) => {
    try {
      await updateBrand({ id: brand.id, ...submitValues })
      successToast('Brand billing saved successfully!')
    } catch (error) {
      captureException(error)
      toast(<Typography variant={'subtitle2'}>Failed to save brand billing!</Typography>, { type: 'error' })
    }
    await refreshBrand(brand.id)
  }

  return (
    <Form resolver={yupResolver(schema)} defaultValues={defaultValues} onSubmit={onSubmit}>
      <BrandBillingForm readOnly={readOnly} />
    </Form>
  )
}

const BrandBillingForm = ({ readOnly }: { readOnly?: boolean }) => {
  return (
    <Stack spacing={2}>
      <Typography variant={'h4'}>Brand billing</Typography>

      <Stack border={1} borderRadius={'4px'} borderColor={'grey.A200'} p={3} spacing={3}>
        <Stack spacing={2}>
          <Typography variant={'label3'}>
            {readOnly
              ? 'This is the primary billing contact. Invoices will be emailed to this contact.'
              : 'Add the primary billing contact for your Bill.com account. Invoices will be emailed to this contact.'}
          </Typography>
          <Grid container direction={'row'} spacing={1}>
            <Grid item xs={6}>
              <Stack spacing={1} display={'inline'}>
                <Typography variant={'label2'}>Billing email</Typography>
                <TextInput
                  name={'paymentBillingContactEmail'}
                  disabled={readOnly}
                  fullWidth
                  placeholder={'Enter Bill.com billing email'}
                />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack spacing={1} display={'inline'}>
                <Typography variant={'label2'}>Customer ID in bill.com</Typography>
                <TextInput
                  name={'paymentBillingContactId'}
                  disabled={readOnly}
                  fullWidth
                  placeholder={'Enter Bill.com customer ID'}
                />
              </Stack>
            </Grid>
          </Grid>
        </Stack>

        {!readOnly && (
          <SubmitButton
            data-cy={'saveBrandInformationButton'}
            variant={'contained'}
            style={{
              alignSelf: 'start',
              marginTop: '24px'
            }}
          >
            Save billing information
          </SubmitButton>
        )}
      </Stack>
    </Stack>
  )
}
