import { Paper, Stack, Typography, Rating, Box } from '@mui/material'
import { DateTime } from 'luxon'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { first, last } from 'lodash'
import {
  useReviewsContext,
  TopLevelViewType,
  ReviewMetric
} from '@momentum/routes/campaign/e-commerce/reviewsV2/reviewsContext'
import ReviewPencilGraphicIcon from '@momentum/components/icons/review-pencil-graphic'
import { Straight } from '@mui/icons-material'

const timelineReviewsGoal = 25

export const Summary = () => {
  const { campaignDetails } = useCampaignContext()
  const { reviewsData, topLevelViewType } = useReviewsContext()

  const startDate = DateTime.fromISO(campaignDetails.startDate)
  const startDateDate = startDate.toISODate()!
  const endDate = campaignDetails.endDate ? DateTime.fromISO(campaignDetails.endDate).toISODate()! : undefined
  const ratingsGoal = campaignDetails.expectedReviewsCount || timelineReviewsGoal
  const startOfCampaignData = reviewsData.filter(d => d.date >= startDateDate)
  const startRating = last(reviewsData.filter(r => r.date < startDateDate)) || first(reviewsData)
  const endRating = last(reviewsData.filter(r => r.date >= startDateDate && (!endDate || r.date <= endDate)))

  const ratingDiff = endRating && startRating ? endRating.rating - startRating.rating : undefined

  const daysToHitGoal = determineDaysToHitRatingsGoal(startOfCampaignData, ratingsGoal)

  const daysToHitGoalActual = daysToHitGoal
    ? daysToHitGoal
    : DateTime.fromISO(last(reviewsData)!.date).diff(DateTime.fromISO(first(reviewsData)!.date), 'days').days
  const ratingsGoalActual = daysToHitGoal
    ? ratingsGoal
    : last(startOfCampaignData)!.cumulativeRatings - first(startOfCampaignData)!.cumulativeRatings

  return (
    <Paper sx={{ p: 2 }}>
      {topLevelViewType === TopLevelViewType.REVIEWS && !!daysToHitGoalActual && (
        <Stack spacing={3} alignItems={'center'}>
          <Stack direction={'row'} alignItems={'center'} spacing={3}>
            <ReviewPencilGraphicIcon sx={{ height: 48, width: 48 }} />
            <Stack spacing={0.5}>
              <Typography variant={'h4'}>
                The product got {ratingsGoalActual.toLocaleString()} reviews & ratings in {daysToHitGoalActual} days!
              </Typography>
              <Typography variant={'label3'} color={t => t.palette.grey.A700}>
                from {startDate.toLocaleString(DateTime.DATE_MED)} -{' '}
                {startDate.plus({ days: daysToHitGoalActual }).toLocaleString(DateTime.DATE_MED)}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      )}
      {topLevelViewType === TopLevelViewType.STAR_RATING && (
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-evenly'}>
          <Stack alignItems={'center'}>
            <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
              <Typography variant={'h4'}>{startRating?.rating?.toFixed(2)}</Typography>
              <Typography variant={'label3'}>rating</Typography>
            </Stack>
            <Rating
              value={startRating?.rating}
              readOnly
              precision={0.1}
              sx={{
                '& .MuiRating-iconFilled': {
                  color: '#767676'
                }
              }}
            />
            <Typography variant={'label3'} color={'grey.A700'}>
              on {startRating?.date ? DateTime.fromISO(startRating.date).toLocaleString(DateTime.DATE_MED) : undefined}
            </Typography>
          </Stack>
          <Stack alignItems={'center'}>
            <Stack direction={'row'} alignItems={'center'}>
              <Typography variant={'h4'}>{endRating?.rating?.toFixed(2)}</Typography>
              {(ratingDiff || 0) > 0 ? <Straight color={'success'} fontSize={'medium'} /> : <Box mr={0.5} />}
              <Typography variant={'label3'}>rating</Typography>
            </Stack>
            <Rating value={endRating?.rating} readOnly precision={0.1} />
            <Typography variant={'label3'} color={'grey.A700'}>
              on {endRating?.date ? DateTime.fromISO(endRating.date).toLocaleString(DateTime.DATE_MED) : undefined}
            </Typography>
          </Stack>
        </Stack>
      )}
    </Paper>
  )
}

const determineDaysToHitRatingsGoal = (reviewsData: ReviewMetric[], goal: number) => {
  if (!reviewsData.length) return undefined

  const firstRatingCount = first(reviewsData)!.cumulativeRatings
  const dateWhenMet = reviewsData.find(s => s.cumulativeRatings - firstRatingCount >= goal)?.date

  if (dateWhenMet) {
    return DateTime.fromISO(dateWhenMet).diff(DateTime.fromISO(first(reviewsData)!.date), 'days').days
  }
}
