import { yupResolver } from '@hookform/resolvers/yup'
import { mApi } from '@momentum/api'
import { RoutesBreadcrumb } from '@momentum/components/routes-breadcrumb-v2'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { useClientBreadcrumbs } from '@momentum/hooks/useClientBreadcrumbs'
import { useDisclose } from '@momentum/hooks/useDisclose'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { AddProductCallback } from '@momentum/routes/brand/context/types'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { errorToast, successToast } from '@momentum/utils/toastUtils'
import { CheckCircleOutlined, Close, InfoOutlined, RocketLaunchOutlined, UpcomingOutlined } from '@mui/icons-material'
import PauseIcon from '@mui/icons-material/Pause'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import {
  Alert,
  AlertTitle,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'
import { CampaignStatus, getCampaignStatus } from '@productwindtom/shared-campaign'
import { CampaignEnderType, InvoiceStatus, PauseReason } from '@productwindtom/shared-momentum-zeus-types'
import { notEmpty } from '@productwindtom/shared-node'
import { toCurrencyString } from '@productwindtom/shared-ws-currency'
import { Form, SubmitButton, TextInput } from '@productwindtom/ui-base'
import { useFlag } from '@unleash/proxy-client-react'
import { uniq } from 'lodash'
import { DateTime } from 'luxon'
import * as yup from 'yup'
import EndCampaign from './EndCampaign'
import { ProductCard } from './ProductCard'

export const HeaderBar = () => {
  const { selectedCompany, selectedBrand, isAdminView } = useUserSessionContext()
  const {
    campaignDetails: {
      id,
      skuId,
      title,
      product,
      numCreatorsJoined,
      numCreators,
      startDate,
      preLaunchDate,
      endDate,
      endedByType,
      proposal,
      pauseReason,
      productListingVariatedAt
    },
    activeStatus,
    adjustCampaignProduct
  } = useCampaignContext()

  const crumbs = [...useClientBreadcrumbs(selectedCompany, selectedBrand, { id: id, name: title, skuId: skuId })]

  //  Going to wait on this for now
  // const nowDate = DateTime.now().toISODate()
  // const campaignsWithPaymentDue = campaigns?.find(
  //   c =>
  //     c.id !== id && c.proposal?.invoiceStatus === InvoiceStatus.NOT_PAID && (c.proposal.invoiceDueDate || '') > nowDate
  // )

  const paymentDueInformation =
    proposal?.invoiceStatus === InvoiceStatus.NOT_PAID && proposal.invoiceDueDate
      ? {
          scheduledLaunchDate: DateTime.fromISO(proposal.launchDate),
          dueDate: DateTime.fromISO(proposal.invoiceDueDate),
          invoiceLink: proposal.invoiceLink,
          invoiceId: proposal.invoiceId,
          invoiceNumber: proposal.invoiceNumber,
          daysTillDue: Math.floor(DateTime.fromISO(proposal.invoiceDueDate).diffNow('days').days),
          billingContacts: proposal.billingContacts || [],
          paymentBillingContactEmail: proposal.paymentBillingContactEmail,
          paymentBillingContactId: proposal.paymentBillingContactId,
          invoiceTotal: proposal.totalCredits,
          scheduledAt: proposal.submittedAt ? DateTime.fromISO(proposal.submittedAt) : undefined
        }
      : null

  const brandPlaceholderScheduleCampaign = useFlag('BrandPlaceholderScheduleCampaign')
  const campaignStatus = getCampaignStatus(startDate, preLaunchDate, endDate, numCreatorsJoined > 0, pauseReason)
  const hasUpdateProductEnabled =
    !product.skuId &&
    (isAdminView || brandPlaceholderScheduleCampaign) &&
    [CampaignStatus.UPCOMING, CampaignStatus.DELAYED].includes(campaignStatus)

  return (
    <Stack spacing={2} data-cy={'campaignHeader'}>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <RoutesBreadcrumb crumbs={crumbs} />
        {isAdminView && activeStatus !== CampaignStatus.FINISHED && <EndCampaign />}
      </Stack>

      <Stack direction={'row'} justifyContent={'space-between'} spacing={2} alignItems={'flex-start'}>
        <Stack spacing={2}>
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <Typography
              variant={'h3'}
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical'
              }}
            >
              {title}
            </Typography>
            {proposal?.invoiceStatus === InvoiceStatus.NOT_PAID && <NotPaidBadge />}
            {proposal?.invoiceStatus === InvoiceStatus.PAID && <PaidBadge />}
            {proposal?.invoiceStatus === InvoiceStatus.PAYMENT_SCHEDULED && <PaymentProcessingBadge />}
          </Stack>
          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            {activeStatus === CampaignStatus.ACTIVE && (
              <Chip icon={<RocketLaunchOutlined />} label={'Active'} color={'success'} size={'medium'} />
            )}
            {activeStatus === CampaignStatus.UPCOMING && (
              <Chip icon={<UpcomingOutlined />} label={'Upcoming'} color={'info'} size={'medium'} />
            )}
            {activeStatus === CampaignStatus.PRE_LAUNCH && (
              <Chip icon={<UpcomingOutlined />} label={'Pre-Launch'} color={'info'} size={'medium'} />
            )}
            {activeStatus === CampaignStatus.FINISHED && (
              <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                <Chip icon={<RocketLaunchOutlined />} label={'Ended'} size={'medium'} />
                <Tooltip
                  title={
                    isAdminView && endedByType === CampaignEnderType.MOMENTUM_USER
                      ? 'Campaign ended by Operations Team'
                      : isAdminView && endedByType === CampaignEnderType.SYSTEM
                        ? 'Campaign ended by Momentum. Campaigns are automatically ended once all campaign deliverables have been completed'
                        : 'Campaigns are automatically ended once all campaign deliverables have been completed'
                  }
                >
                  <InfoOutlined fontSize={'medium'} sx={{ color: 'grey.A700' }} />
                </Tooltip>
              </Stack>
            )}
            {activeStatus === CampaignStatus.PAUSED && (
              <Chip
                icon={
                  <PauseIcon
                    style={{
                      color: 'black',
                      fontSize: '18px'
                    }}
                  />
                }
                label={'Paused'}
                color={'warning'}
                size={'medium'}
              />
            )}
            <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
              <Typography variant={'label3'}>
                {numCreatorsJoined}/{numCreators}
              </Typography>
              <Typography variant={'caption2'}>creators joined</Typography>
            </Stack>
            <Typography variant={'label3'}>
              {DateTime.fromISO(startDate).toLocaleString(DateTime.DATE_MED)} -
              {endDate
                ? ` ${DateTime.fromISO(endDate).toLocaleString(DateTime.DATE_MED)}`
                : activeStatus === CampaignStatus.ACTIVE
                  ? ' today'
                  : ''}
            </Typography>
          </Stack>
        </Stack>
        <ProductCard
          product={product}
          onProductAdd={adjustCampaignProduct}
          campaignId={id}
          hasUpdateProductEnabled={hasUpdateProductEnabled}
        />
      </Stack>

      {activeStatus === CampaignStatus.PAUSED && pauseReason === PauseReason.OUT_OF_STOCK && (
        <Alert severity={'warning'} variant={'outlined'}>
          <AlertTitle>
            <Typography variant={'label2'} color={'black'}>
              The campaign is paused!
            </Typography>
          </AlertTitle>
          <Typography color={'black'}>
            The campaign is paused due to the product being out of stock. It will resume once the product is back in
            stock.
          </Typography>
        </Alert>
      )}

      {!!productListingVariatedAt && (
        <Alert severity={'warning'} variant={'outlined'}>
          <AlertTitle>
            <Typography variant={'label2'} color={'black'}>
              Your product variated on {DateTime.fromISO(productListingVariatedAt).toLocaleString(DateTime.DATE_SHORT)}!
            </Typography>
          </AlertTitle>
          <Typography color={'black'}>
            We detected that your product variated on{' '}
            {DateTime.fromISO(productListingVariatedAt).toLocaleString(DateTime.DATE_SHORT)}. Campaign reporting may be
            affected.
          </Typography>
        </Alert>
      )}

      {/*{campaignsWithPaymentDue && (*/}
      {/*  <Alert severity={'error'} variant={'outlined'}>*/}
      {/*    <AlertTitle>*/}
      {/*      <Typography variant={'label2'}>Payment for another campaign is overdue!</Typography>*/}
      {/*    </AlertTitle>*/}
      {/*    <Typography color={'black'}>*/}
      {/*      View invoice{' '}*/}
      {/*      <Link href={campaignsWithPaymentDue.proposal?.invoiceLink}>*/}
      {/*        #{campaignsWithPaymentDue.proposal?.invoiceId?.split('_').pop()}*/}
      {/*      </Link>*/}
      {/*      . This campaign will not run until your other invoice is paid.*/}
      {/*    </Typography>*/}
      {/*  </Alert>*/}
      {/*)}*/}
      {paymentDueInformation && (
        <PaymentDueAlert title={'this'} id={id} {...paymentDueInformation} campaignStatus={activeStatus} />
      )}
      {hasUpdateProductEnabled && (
        <PlaceholderProductAlert
          campaignId={id}
          productId={product.id}
          campaignStatus={campaignStatus}
          onProductAdd={adjustCampaignProduct}
        />
      )}
    </Stack>
  )
}

const getPaymentString = (activeStatus: CampaignStatus, dueDate: DateTime, scheduledLaunchDate: DateTime) => {
  switch (activeStatus) {
    case CampaignStatus.ACTIVE:
      return 'This campaign will stop running if payment is not received on the payment due date.'
    case CampaignStatus.DELAYED:
    case CampaignStatus.UPCOMING:
    case CampaignStatus.PRE_LAUNCH: {
      if (dueDate > scheduledLaunchDate) {
        return 'This campaign may stop running if payment is not received on the payment due date.'
      }
      return 'This campaign will not start until payment is received.'
    }
    default:
      return ''
  }
}

const PaymentDueAlert = (input: {
  id: string
  title: string
  dueDate: DateTime
  invoiceLink?: string
  invoiceNumber?: string
  campaignStatus: CampaignStatus
  scheduledLaunchDate: DateTime
  scheduledAt?: DateTime
  invoiceTotal?: number
  paymentBillingContactEmail?: string
  billingContacts: { email: string }[]
}) => {
  const { title, dueDate, invoiceNumber, invoiceTotal, campaignStatus, scheduledLaunchDate } = input

  const daysTillDue = Math.ceil(dueDate.diff(DateTime.local(), 'days').days)

  const severity = daysTillDue <= 0 ? 'error' : daysTillDue >= 10 ? 'info' : 'warning'
  const message =
    severity === 'error'
      ? `Payment for ${title} campaign is overdue!`
      : `Payment for ${title} campaign is due in ${daysTillDue} days!`
  return (
    <Alert severity={severity} variant={'outlined'}>
      <AlertTitle>
        <Typography variant={'label2'} color={'black'}>
          {message}
        </Typography>
      </AlertTitle>
      {!!invoiceNumber && (
        <Typography color={'black'}>
          <ProposalEmailModal {...input} invoiceTotal={invoiceTotal || 0} invoiceNumber={invoiceNumber!} />
          {getPaymentString(campaignStatus, dueDate, scheduledLaunchDate)}
        </Typography>
      )}
      {!invoiceNumber && (
        <Typography color={'black'}>{getPaymentString(campaignStatus, dueDate, scheduledLaunchDate)}</Typography>
      )}
    </Alert>
  )
}

const PlaceholderProductAlert = (input: {
  campaignId: string
  productId: string
  campaignStatus: CampaignStatus
  onProductAdd: AddProductCallback
}) => {
  const { setIsAddProductActiveOrCallback } = useBrandContext()

  return (
    <Stack
      data-cy={'placeholderAlert'}
      direction={'row'}
      alignItems={'center'}
      spacing={1}
      flex={1}
      p={2}
      sx={{
        borderRadius: '4px',
        borderColor: theme =>
          input.campaignStatus === CampaignStatus.DELAYED ? theme.palette.error.main : theme.palette.info.main,
        borderWidth: '1px',
        borderStyle: 'solid'
      }}
    >
      {input.campaignStatus === CampaignStatus.DELAYED ? (
        <WarningAmberOutlinedIcon color={'error'} sx={{ alignSelf: 'start' }} />
      ) : (
        <InfoOutlined color={'info'} sx={{ alignSelf: 'start' }} />
      )}

      <Typography color={'black'} variant={'label3'} flex={1}>
        This campaign is using a placeholder product. Add the live product URL before the campaign start date in order
        to launch this campaign.
      </Typography>
      <Button
        variant="outlined"
        sx={{
          marginLeft: 1,
          flex: '0 0 auto'
        }}
        onClick={() =>
          setIsAddProductActiveOrCallback({
            callback: input.onProductAdd,
            disableManual: true,
            campaignId: input.campaignId,
            selectedProductId: input.productId
          })
        }
      >
        Add live product URL
      </Button>
    </Stack>
  )
}

const PaidBadge = () => (
  <Stack
    direction={'row'}
    spacing={0.5}
    alignItems={'center'}
    borderRadius={'100px'}
    border={'1px solid #EBEDF6'}
    px={1}
    py={0.5}
    bgcolor={'#F8FBFF'}
    data-cy={'paidBadge'}
  >
    <Typography variant={'label3'}>Paid</Typography>
    <CheckCircleOutlined color={'success'} />
  </Stack>
)

const NotPaidBadge = () => (
  <Stack
    direction={'row'}
    spacing={0.5}
    alignItems={'center'}
    borderRadius={'4px'}
    border={'1px solid #F9E4A7'}
    px={1}
    py={0.5}
    bgcolor={'#FDF4DC'}
    data-cy={'notPaidBadge'}
  >
    <Typography variant={'label4'}>Unpaid</Typography>
  </Stack>
)

const PaymentProcessingBadge = () => (
  <Stack
    direction={'row'}
    spacing={0.5}
    alignItems={'center'}
    borderRadius={'4px'}
    border={'1px solid #F9E4A7'}
    px={1}
    py={0.5}
    bgcolor={'#FDF4DC'}
    data-cy={'notPaidBadge'}
  >
    <Typography variant={'label4'}>Payment processing</Typography>
    <Tooltip
      title={
        'Payment for this campaign is processing in Bill.com. This campaign will be marked as paid once payment is received.'
      }
    >
      <InfoOutlined fontSize={'medium'} sx={{ color: 'grey.A700' }} />
    </Tooltip>
  </Stack>
)

export const schema = yup.object({
  email: yup.string().required('Required').email('Invalid email')
})

const ProposalEmailModal = ({
  id,
  scheduledAt,
  invoiceTotal,
  invoiceLink,
  billingContacts,
  paymentBillingContactEmail,
  invoiceNumber
}: {
  id: string
  invoiceNumber: string
  scheduledAt?: DateTime
  invoiceTotal: number
  invoiceLink?: string
  paymentBillingContactEmail?: string
  billingContacts: { email: string }[]
}) => {
  const { onClose, onOpen, isOpen } = useDisclose()

  const sendInvoice = async ({ email }: { email: string }) => {
    try {
      const resp = await mApi('mutation')({
        sendProposalInvoice: [
          {
            id,
            invoiceNumber,
            emails: [email]
          },
          {
            sent: true
          }
        ]
      })

      if (resp.sendProposalInvoice?.sent) {
        successToast('Invoice sent')
      } else {
        errorToast('Failed to send invoice')
      }
    } catch (e) {
      errorToast('Failed to send invoice')
    }
  }

  const contacts = uniq([paymentBillingContactEmail, ...billingContacts.map(c => c.email)].filter(notEmpty))

  return (
    <Typography color={'black'} component={'span'}>
      View invoice{' '}
      {invoiceLink ? (
        <Link variant={'body1'} href={invoiceLink} target={'_blank'}>
          #{invoiceNumber}
        </Link>
      ) : (
        <Typography component={'span'} variant={'body1'} color={'primary'} onClick={onOpen} sx={{ cursor: 'pointer' }}>
          #{invoiceNumber}
        </Typography>
      )}
      .{' '}
      <Dialog open={isOpen} onClose={onClose} maxWidth={'xs'}>
        <Form onSubmit={sendInvoice} defaultValues={{ email: '' }} resolver={yupResolver(schema)}>
          <DialogTitle>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography variant={'h4'}>Review invoice</Typography>
              <IconButton onClick={onClose} size={'small'}>
                <Close />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <Typography variant={'label3'}>
                Invoice <b>#{invoiceNumber}</b> was sent on <b>{scheduledAt?.toLocaleString(DateTime.DATE_MED)}</b> for{' '}
                {toCurrencyString(invoiceTotal, 'US')} to the following contacts:
              </Typography>
              <Stack>
                {contacts.map(n => (
                  <Typography key={n} variant={'label3'}>
                    {'\u2022'} {n}
                  </Typography>
                ))}
              </Stack>

              <Typography variant={'h4'}>Resend a copy of the invoice</Typography>
              <TextInput name={'email'} placeholder={'Enter email'} />

              <Stack direction={'row'} justifyContent={'flex-end'} mt={1} spacing={1}>
                <Button onClick={onClose}>Cancel</Button>
                <SubmitButton variant={'contained'}>Send</SubmitButton>
              </Stack>
            </Stack>
          </DialogContent>
        </Form>
      </Dialog>
    </Typography>
  )
}
