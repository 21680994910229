import { Stack, TableCell, TableRow, IconButton, Typography } from "@mui/material"
import { useState } from "react";
import { DateTime } from "luxon";
import { KeyboardArrowUp, KeyboardArrowDown, Circle } from "@mui/icons-material";
import { purchaseActions } from "./index";
import { SubscriptionCreditAction } from "@momentum/routes/queries";
import { useUserSessionContext } from "@momentum/contexts/UserSession";
import { useSubscriptionContext } from "@momentum/contexts/Subscription";
import { StatusChip } from "./StatusChip";
import { InvoiceStatus, CreditAction } from "@productwindtom/shared-momentum-zeus-types";

export const Row = ({ month, items, actionBalances   }: { month: string, items: SubscriptionCreditAction[], actionBalances: Record<string, number> }) => {
  const { brands } = useUserSessionContext()
  const { name, endsAt, getSubscriptionStatus } = useSubscriptionContext()
  const { isActive, hasStarted } = getSubscriptionStatus()
  const subscriptionEnded = !isActive && hasStarted && endsAt

  const [open, setOpen] = useState(() => {
    if (subscriptionEnded) return false
    const monthDate = DateTime.fromFormat(month, 'MMMM yyyy')
    const currentDate = DateTime.now()
    return monthDate >= currentDate.minus({ months: 1 }).startOf('month')
  });
  
  const buyingCredits = (item: SubscriptionCreditAction): boolean => {
    return purchaseActions.includes(item.action)
  }

  const invoiceNumber = (item: SubscriptionCreditAction): string => {
    return item.invoiceNumber || item.proposal?.invoiceNumber || ''
  }

  const amountColor = (item: SubscriptionCreditAction): string => {
    if (item.action === CreditAction.REMOVE) {
      return 'black'
    } else if (item.action === CreditAction.ADD) {
      return 'success.main'
    }
    return 'grey.A700'
  }

  const itemName = (item: SubscriptionCreditAction): string | undefined => {
    const brandOrCompanyName = brands.find(b => b.id === item.associatedBrandId)?.name || name
    const  proposalTitle = item.proposal?.title
    if (proposalTitle) {
      return `${brandOrCompanyName} ${proposalTitle}`
    }
    return brandOrCompanyName
  }

  const itemActionName = (item: SubscriptionCreditAction): string | undefined => {
    if (item.action === CreditAction.BUY_CREDITS || (item.action === CreditAction.ADD && item.invoiceId)) return 'Purchased credits'
    if (item.action === CreditAction.ADD) return 'Added credits'
    if (item.action === CreditAction.REMOVE) return 'Used credits'
  }

  return (
    <>
      <TableRow>
        <TableCell colSpan={4} sx={{bgcolor: theme => theme.palette.grey.A100, px: 2, py: 1.5}}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
            <Stack direction={'row'} spacing={2}>
              <Typography variant="subtitle1">{month}</Typography>
              <Typography variant="body1" color={'grey.700'}>{`${items.length} transactions`}</Typography>
            </Stack>
          </Stack>
        </TableCell>
      </TableRow>
      {open && items.map(item => {
        return (
          <TableRow key={item.actionId} data-cy="subscriptionTableDataRow">
            <TableCell sx={{ pl: 4, pr: 2, py: 1 }} data-cy="subscriptionTableDataRowDate">
              <Stack direction={'column'} spacing={0.5}>
                <Typography variant={'label2'} noWrap sx={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '400px' }}>{itemName(item)}</Typography>
                <Stack direction={'row'} spacing={1} color={'grey.700'} alignItems="center">
                  <Typography variant={'body1'}>{DateTime.fromISO(item.createdAt).toLocaleString(DateTime.DATE_SHORT)}</Typography>
                  <Circle sx={{ color: 'grey.A400', fontSize: '4px' }} />
                  <Typography variant={'body1'}>{item.actionCreatedByFirstName} {item.actionCreatedByLastName}</Typography>
                </Stack>
              </Stack>
            </TableCell>
            <TableCell sx={{ px: 2, py: 1 }} data-cy="subscriptionTableDataRowActivity">
              <Stack direction={'column'} spacing={0.5}>
                <Typography variant={'label3'} color={'black'}>{itemActionName(item)}</Typography>
                {(!!item.invoiceNumber || !!item.proposal?.invoiceNumber) && 
                  <Stack direction={'row'} spacing={1} alignItems="center">
                    <Typography color={'grey.700'} variant={'body1'}>
                      Issued invoice <span style={{ fontWeight: 'bold' }}>#{invoiceNumber(item)}</span>
                    </Typography>
                    {item.invoiceStatus !== InvoiceStatus.PAID && item.invoiceDueDate &&
                      <>
                        <Circle sx={{ color: 'grey.A400', fontSize: '4px' }} />
                        <Typography 
                          variant={'body1'} 
                          color={DateTime.fromISO(item.invoiceDueDate) < DateTime.now() ? theme => theme.palette.error.main : undefined}
                        fontWeight={DateTime.fromISO(item.invoiceDueDate) < DateTime.now() ? 800 : undefined}
                      >
                        Due {DateTime.fromISO(item.invoiceDueDate).toFormat('M/d/yy')}
                      </Typography>
                      </>
                    }
                  </Stack>
                }
              </Stack>
            </TableCell>
            <TableCell sx={{ px: 2, py: 1 }} data-cy="subscriptionTableDataRowStatus">
              <StatusChip status={item.invoiceStatus} date={item.invoiceDueDate} />
            </TableCell>
            <TableCell sx={{ pl: 2, pr: 4, py: 1 }} data-cy="subscriptionTableDataRowAmount">
              <Stack direction={'column'} spacing={0.5}>
                <Typography variant={'label2'} color={amountColor(item)}>{`${buyingCredits(item) ? '+' : '-'} ${item.numCredits.toLocaleString()} credits`}</Typography>
                { (item.action === CreditAction.REMOVE || item.action === CreditAction.ADD) &&
                  <Typography variant={'body1'}>{`Balance: ${actionBalances[item.actionId].toLocaleString()} credits`}</Typography>
                }
              </Stack>
            </TableCell>
          </TableRow>
        )
      })}
    </>
  )
}
