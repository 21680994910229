import Loading from '@momentum/components/loading'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { ReviewsGraph } from '@momentum/routes/campaign/e-commerce/reviewsV2/reviews-graph'
import { SelectedReviews } from '@momentum/routes/campaign/e-commerce/reviewsV2/selected-reviews'
import { StarGraph } from '@momentum/routes/campaign/e-commerce/reviewsV2/star-graph'
import { Stack, Typography } from '@mui/material'
import { Retailer, STORE_TO_RETAILER } from '@productwindtom/shared-momentum'
import { last, max, min, orderBy } from 'lodash'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { NotSupported } from './not-supported'
import { PeriodSelector } from './PeriodSelector'
import { ReviewsTable } from './reviews-table'
import ReviewsContext, { TopLevelViewType, ViewType } from './reviewsContext'
import { Summary } from './Summary'
import { TopLevelToggle } from './TopLevelToggle'
import { useMappedReviews } from './useMappedReviews'
import { EmptyState } from '@momentum/routes/campaign/e-commerce/common/EmptyState'
import EmptyStateImage from '/images/empty-states/reviews-v2.png'

export const DAYS_DATA = 14
export const ReviewsV2 = () => {
  const [topLevelViewType, setTopLevelViewType] = useState(TopLevelViewType.REVIEWS)
  const [viewType, setViewType] = useState(ViewType.COUNT)

  const { campaignDetails, reviewMetricsV2: reviewMetrics } = useCampaignContext()

  const { startDate, endDate, product } = campaignDetails

  const is3pReviewsVisible = STORE_TO_RETAILER[product.store] === Retailer.AMAZON

  // We want to start day before start date
  const startDateDate = DateTime.fromISO(startDate.split('T')[0]).minus({ days: 1 })
  const minDateFromStart = startDateDate.minus({ days: DAYS_DATA - 1 })
  const momentumReviewRecordsDates =
    reviewMetrics?.map(m => DateTime.fromISO(m.fromDate)).filter(m => m > minDateFromStart) || []

  const momentumReviewRecordsAfterStart = momentumReviewRecordsDates.filter(m => m >= startDateDate)

  const dataMinDate = min(momentumReviewRecordsDates)
  const startDateForFilter = min([DateTime.now(), ...momentumReviewRecordsAfterStart])!
  const minDate = min([startDateForFilter, dataMinDate])!

  const endDateDateTime = max([max(momentumReviewRecordsDates), endDate ? DateTime.fromISO(endDate) : DateTime.now()])
  const maxDate = endDateDateTime ? min([DateTime.now(), endDateDateTime.plus({ days: DAYS_DATA })])! : DateTime.now()

  const [startDateFilter, setStartDateFilter] = useState<DateTime>()
  const [endDateFilter, setEndDateFilter] = useState<DateTime>()

  useEffect(() => {
    if (reviewMetrics && !startDateFilter && !endDateFilter) {
      setStartDateFilter(startDateForFilter)
      setEndDateFilter(endDateDateTime || maxDate)
    }
  }, [reviewMetrics])

  const mappedReviewsData = useMappedReviews()

  const isSupported =
    !!campaignDetails.numUgcCreators || !!campaignDetails.numSocialCreators || !!campaignDetails.numPremiumUgcCreators

  if (!isSupported) {
    return <NotSupported />
  }

  if (!reviewMetrics || !startDateFilter || !endDateFilter || !mappedReviewsData) {
    return <Loading />
  }

  if (!reviewMetrics.length || !reviewMetrics.find(r => r.momentumReviewCount)) {
    return <EmptyState
      image={EmptyStateImage} 
      title="We’re collecting reviews & ratings"
      description="The reviews & ratings dashboard will be available soon."
    />
  }

  const filteredReviewData = orderBy(
    mappedReviewsData.filter(
      d =>
        (!startDateFilter || d.date >= startDateFilter.toISODate()!) &&
        (!endDateFilter || d.date <= endDateFilter.toISODate()!)
    ),
    'date'
  )

  const lastUpdatedDate = last(reviewMetrics)?.toDate

  return (
    <ReviewsContext.Provider
      value={{
        reviewsData: mappedReviewsData,
        filteredReviewData: filteredReviewData,

        is3pReviewsVisible,
        minDate,
        maxDate,
        topLevelViewType,
        setTopLevelViewType,
        viewType,
        setViewType,
        initialStartDate: startDateForFilter,
        initialEndDate: endDateDateTime || maxDate,
        startDateFilter,
        setStartDateFilter,
        endDateFilter,
        setEndDateFilter
      }}
    >
      <Stack spacing={5}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            <Typography variant={'h4'}>Reviews & ratings</Typography>
            {!!lastUpdatedDate && (
              <Typography variant={'caption2'} color={theme => theme.palette.grey.A700}>
                Last updated: {DateTime.fromISO(lastUpdatedDate).toLocaleString(DateTime.DATE_MED)}
              </Typography>
            )}
          </Stack>
          <TopLevelToggle />
        </Stack>
        <Summary />
        <PeriodSelector />
        <Stack spacing={3} p={3} bgcolor={t => t.palette.grey.A100} border={t => `1px solid ${t.palette.grey.A200}`}>
          {topLevelViewType === TopLevelViewType.STAR_RATING ? <StarGraph /> : <ReviewsGraph />}
          <ReviewsTable />
        </Stack>
        <SelectedReviews />
      </Stack>
    </ReviewsContext.Provider>
  )
}
