import { ButtonToggleGroup } from '@momentum/components/button-toggle-group'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { ROUTE_NAMES_PROPOSAL } from '@momentum/routes/RouteNames'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { SelectedProductInformation } from '@momentum/routes/proposals-create'
import { useCreateProposalContext } from '@momentum/routes/proposals-create/context/CreateProposalContext'
import { CampaignImpact } from '@momentum/routes/proposals-create/modules/estmated-deliverables/CampaignImpact'
import { CampaignTimeline } from '@momentum/routes/proposals-create/modules/estmated-deliverables/CampaignTimeline'
import useEstimatedDeliverables from '@momentum/routes/proposals-create/modules/useEstimatedDeliverables'
import { ProposalCreateForm } from '@momentum/routes/proposals-create/types'
import { InfoOutlined, SaveAltOutlined } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Divider, Grid, Link, Tooltip, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { getProposalCreatorCounts, ProposalCreatorCounts, STORE_TO_CURRENCY } from '@productwindtom/shared-momentum'
import { CreatorType, Currency, PricingCreatorType, ProposalGoal } from '@productwindtom/shared-momentum-zeus-types'
import { getOrBlank, notEmpty } from '@productwindtom/shared-node'
import { toCurrencyString } from '@productwindtom/shared-ws-currency'
import { captureException } from '@sentry/react'
import { keyBy, maxBy } from 'lodash'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { createProposalSlide } from '../../mutations'

enum DeliverablesTab {
  IMPACT = 'IMPACT',
  TIMELINE = 'TIMELINE'
}

export const EstimatedDeliverables = () => {
  const [tabSelected, setTabSelected] = useState(DeliverablesTab.IMPACT)

  const {
    watch,
    formState: { isValid },
    setValue
  } = useFormContext<ProposalCreateForm>()
  const { pathname } = useLocation()

  const { company } = useBrandContext()
  const { isAdminView, agencies } = useUserSessionContext()

  const { isDisplayResults, selectedProduct, locale, markAsDraft, markAsPublished, ...results } =
    useEstimatedDeliverables()
  const { proposal, selectableProducts } = useCreateProposalContext()
  const [creatingPresentation, setCreatingPresentation] = useState(false)

  const formData = watch()

  const [
    creatorPricing,
    goal,
    productPriceOverride,
    managedServiceFeeEnabled,
    managedServiceFee,
    subscriptionFeeEnabled,
    subscriptionFee,
    subscriptionTerm
  ] = watch([
    'creatorPricing',
    'goal',
    'productPriceOverride',
    'managedServiceFeeEnabled',
    'managedServiceFee',
    'subscriptionFeeEnabled',
    'subscriptionFee',
    'subscriptionTerm'
  ])

  const proposalCreatorCounts = getProposalCreatorCounts(creatorPricing)
  const isAgencyUser = agencies.some(a => a.id === company?.agencyId)
  const canDownloadPresentation =
    isValid &&
    proposalCreatorCounts.totalCreators &&
    [
      ProposalGoal.PRODUCT_LAUNCH,
      ProposalGoal.BOOST_RATING,
      ProposalGoal.EXISTING_PRODUCT,
      ProposalGoal.OTHER
    ].includes(formData.goal)

  const isMonthly = formData?.goal === ProposalGoal.EVERGREEN
  const monthlySuffix = isMonthly ? '   /mo' : ''
  const creditsSuffix = ' credits' + monthlySuffix

  const isDisplayData = !!selectedProduct

  useEffect(() => {
    if (pathname.endsWith(ROUTE_NAMES_PROPOSAL.TIMELINE)) {
      setTabSelected(DeliverablesTab.TIMELINE)
    } else if (pathname.endsWith(ROUTE_NAMES_PROPOSAL.PRODUCT_CREATORS)) {
      setTabSelected(DeliverablesTab.IMPACT)
    }
  }, [pathname])

  const getLastUpdatedString = () => {
    if (!proposal) {
      return
    }

    if (proposal.updatedAt) {
      const dateTime = DateTime.fromISO(proposal.updatedAt)
      return `Saved: ${dateTime.toLocaleString(DateTime.TIME_SIMPLE)} on ${dateTime.toLocaleString(DateTime.DATE_SHORT)} by ${proposal.updatedBy?.firstName} ${proposal.updatedBy?.lastName[0]}.`
    }
  }

  const updatedString = getLastUpdatedString()

  const handleDownloadPresentation = async () => {
    setCreatingPresentation(true)

    // Get the highest price of the selected variations
    const selectedVariations =
      selectedProduct &&
      !!formData.productVariationSkus?.length &&
      selectedProduct?.childrenIds
        ?.map(id => selectableProducts.find(p => p.id === id))
        .filter(notEmpty)
        .filter(v => v.skuId && formData.productVariationSkus?.includes(v.skuId))
    const highestPrice = maxBy(selectedVariations || [], 'priceCents')?.priceCents
    const productPriceCents = productPriceOverride ?? highestPrice ?? selectedProduct?.priceCents ?? 0

    try {
      const result = await createProposalSlide({
        productId: selectedProduct!.id,
        creatorPricing: formData.creatorPricing.map(cp => ({
          type: cp.type,
          price: cp.price,
          numCreators: cp.numCreators
        })),
        goal: formData.goal,
        ratingGoal: formData.ratingGoal,
        exchangeRate: formData.exchangeRate || 1,
        totalCustomFeedbackQuestions: formData.customFeedbackQuestions?.length,
        managedServiceFee: formData.managedServiceFee,
        productPriceCents
      })
      window.open(result.link, '_blank')
      toast(
        <Link variant={'subtitle2'} href={result.link} target="_blank">
          {result.link}
        </Link>,
        { type: 'info' }
      )
    } catch (e) {
      captureException(e)
      toast(<Typography variant={'subtitle2'}>Error creating presentation</Typography>, { type: 'error' })
    }
    setCreatingPresentation(false)
  }

  return (
    <Box px={2} py={2} sx={{ opacity: isDisplayData ? 1 : 0.4 }} bgcolor={'white'}>
      <Stack spacing={2}>
        {selectedProduct ? (
          <SelectedProductInformation
            product={selectedProduct}
            goal={goal}
            productPriceOverride={productPriceOverride}
            onPriceOverride={value => setValue('productPriceOverride', value ? value : undefined)}
            readonly={!isAdminView}
          />
        ) : (
          <Box height={100} bgcolor={'primary.lighter'} />
        )}
        <Stack spacing={2}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant={'caption2'} color={theme => theme.palette.grey.A700}>
              {!!updatedString && updatedString}
            </Typography>
            <Stack direction={'row'}></Stack>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} px={2}>
            <Typography variant={'h4'}>Campaign impact</Typography>
            {(isAdminView || isAgencyUser) && (
              <LoadingButton
                variant={'text'}
                startIcon={<SaveAltOutlined />}
                onClick={handleDownloadPresentation}
                loading={creatingPresentation}
                disabled={!canDownloadPresentation}
              >
                Download presentation
              </LoadingButton>
            )}
          </Stack>
          <ButtonToggleGroup
            value={tabSelected}
            values={[
              { label: 'Deliverables', value: DeliverablesTab.IMPACT },
              { label: 'Milestones', value: DeliverablesTab.TIMELINE }
            ]}
            onChange={v => setTabSelected(v)}
          />
        </Stack>
        <CreatorOverview proposalCreatorCounts={proposalCreatorCounts} creatorPricing={creatorPricing} />
        <Divider />
        {tabSelected === DeliverablesTab.IMPACT && <CampaignImpact />}
        {tabSelected === DeliverablesTab.TIMELINE && <CampaignTimeline />}
        <Divider />

        <Stack spacing={1} px={2}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant={'label2'}>Creator cost</Typography>
            <Typography variant={'label1'}>
              {getOrBlank(results?.totalCreatorCostCredits, '--')}
              <Typography component={'span'}>{creditsSuffix}</Typography>
            </Typography>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
              <Typography variant={'label2'}>Product cost</Typography>
              {selectedProduct?.store && STORE_TO_CURRENCY[selectedProduct?.store] !== Currency.USD && (
                <Tooltip
                  title={`Credits are in USD and using an exchange rate of ${formData.exchangeRate?.toFixed(2)} USD to ${STORE_TO_CURRENCY[selectedProduct?.store]}.`}
                >
                  <InfoOutlined sx={{ color: theme => theme.palette.grey.A700 }} fontSize={'mSmall'} />
                </Tooltip>
              )}
            </Stack>
            <Typography variant={'label1'}>
              {getOrBlank(results?.totalProductCostCredits, '--')}
              <Typography component={'span'}>{creditsSuffix}</Typography>
            </Typography>
          </Stack>
          {(formData.goal === ProposalGoal.MARKET_RESEARCH || !!formData.customFeedbackQuestions?.length) && (
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                <Typography variant={'label2'}>Custom feedback cost</Typography>
              </Stack>
              <Typography variant={'label1'} data-cy={'customFeedbackCost'}>
                {getOrBlank(results?.totalCustomFeedbackCost, '--')}
                <Typography component={'span'}>{creditsSuffix}</Typography>
              </Typography>
            </Stack>
          )}
        </Stack>
        <Divider />

        {managedServiceFeeEnabled && (
          <>
            <Stack direction={'row'} justifyContent={'space-between'} px={2}>
              <Typography variant={'label1'}>Managed service fee</Typography>
              <Typography variant={'label1'} data-cy={'managedServiceFeeText'}>
                {managedServiceFee != null ? `${managedServiceFee.toLocaleString()}` : '--'}
                <Typography component={'span'}>{creditsSuffix}</Typography>
              </Typography>
            </Stack>
            <Divider />
          </>
        )}

        <Stack direction={'row'} justifyContent={'space-between'} px={2}>
          <Typography variant={'h4'}>Campaign cost</Typography>
          <Typography variant={'h4'} data-cy="campaignCost">
            {getOrBlank(results?.totalCostCredits, '--')}
            <Typography component={'span'}>{creditsSuffix}</Typography>
          </Typography>
        </Stack>
      </Stack>

      {subscriptionFeeEnabled && (
        <Stack
          sx={{
            borderRadius: 1,
            mt: 2,
            py: 1,
            px: 2,
            backgroundColor: t => t.palette.primary.lighter
          }}
        >
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant={'label1'}>Software subscription fee</Typography>
            <Typography variant={'label1'} data-cy={'subscriptionFeeText'}>
              {subscriptionFee != null ? toCurrencyString(subscriptionFee, 'US') : '-'}
            </Typography>
          </Stack>
          <Typography variant={'label3'} color={'grey.A700'} mt={0.5}>
            *billed separately from campaign invoice
          </Typography>
          <Stack direction={'row'} justifyContent={'space-between'} mt={1.5}>
            <Typography variant={'label1'}>Subscription term</Typography>
            <Typography variant={'label1'} data-cy={'subscriptionTermText'}>
              {subscriptionTerm == null ? '-' : subscriptionTerm === 1 ? '1 month' : `${subscriptionTerm} months`}
            </Typography>
          </Stack>
          <Divider
            sx={{
              my: 1.5,
              opacity: 0.5,
              borderColor: theme => theme.palette.primary.main
            }}
          />
          <Stack direction={'row'} justifyContent={'space-between'} my={1}>
            <Typography variant={'label1'}>Software subscription fee & campaign cost</Typography>
            <Typography variant={'label1'}>
              {toCurrencyString((results?.totalCostCredits ?? 0) + (subscriptionFee ?? 0), 'US')}
            </Typography>
          </Stack>
        </Stack>
      )}
    </Box>
  )
}

const CreatorOverview = ({
  proposalCreatorCounts,
  creatorPricing
}: {
  proposalCreatorCounts: ProposalCreatorCounts
  creatorPricing: {
    numCreators: number
    price: number
    type: PricingCreatorType | CreatorType
  }[]
}) => {
  const { totalCreators, brandAdvocateProposalCount, ugcProposalCount, premiumUgcProposalCount, socialProposalCount } =
    proposalCreatorCounts
  const keyedPricing = keyBy(creatorPricing, 'type')

  return (
    <Stack spacing={2} px={2}>
      <Typography variant={'label2'}>
        Creators{' '}
        <Typography variant={'body1'} component={'span'} color={'grey.A700'}>
          ({(totalCreators || 0).toLocaleString()})
        </Typography>
      </Typography>
      <Stack direction={'row'}>
        {(!!brandAdvocateProposalCount ||
          !creatorPricing.length ||
          (keyedPricing[PricingCreatorType.ADVOCATE] && !totalCreators)) && (
          <Grid item xs={4}>
            <Metric label={'Brand advocates'} value={getOrBlank(brandAdvocateProposalCount)} />
          </Grid>
        )}
        {(!!ugcProposalCount || !creatorPricing.length || (keyedPricing[PricingCreatorType.UGC] && !totalCreators)) && (
          <Grid item xs={2}>
            <Metric label={'UGC'} value={getOrBlank(ugcProposalCount)} />
          </Grid>
        )}
        {(!!premiumUgcProposalCount ||
          !creatorPricing.length ||
          (keyedPricing[PricingCreatorType.PREMIUM_UGC] && !totalCreators)) && (
          <Grid item xs={4}>
            <Metric label={'Premium UGC'} value={getOrBlank(premiumUgcProposalCount)} />
          </Grid>
        )}
        {(!!socialProposalCount ||
          !creatorPricing.length ||
          (keyedPricing[PricingCreatorType.SOCIAL] && !totalCreators)) && (
          <Grid item xs={2}>
            <Metric label={'Social'} value={getOrBlank(socialProposalCount)} />
          </Grid>
        )}
      </Stack>
    </Stack>
  )
}

const Metric = ({ label, value }: { label: string; value: string }) => {
  return (
    <Stack alignItems={'flex-start'}>
      <Typography variant={'label1'}>{value}</Typography>
      <Typography variant={'body1'} color={'grey.A700'}>
        {label}
      </Typography>
    </Stack>
  )
}
