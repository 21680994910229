import { SAMPLE_CAMPAIGN_WIRELESS_EARBUDS } from '@momentum/api/interceptors/getCampaign/sample-data/wireless-earbuds'
import product1 from './1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
import product2 from './c39486ce-efb1-4368-b608-8e2bfc51be0a'
import product3 from './ef977bea-87d4-4c54-8b65-f701c972b257'
import promotedProduct from './sample-sku-wireless-earbuds'

export default {
  [SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId]: promotedProduct,
  '1ff3c70c-108c-4a6e-a5ce-f73256e9109e': product1,
  'c39486ce-efb1-4368-b608-8e2bfc51be0a': product2,
  'ef977bea-87d4-4c54-8b65-f701c972b257': product3
}
