
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { Button, Stack, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { Box } from '@mui/system'
import { generatePath } from 'react-router-dom'
import { ROUTES } from '@momentum/routes/RouteNames'

export const EmptyState = ({
  image,
  title,
  description,
  linkAccountButton = false
}: {
  image: string
  title: string
  description: string
  linkAccountButton?: boolean
}) => {
  const { brand } = useBrandContext()

  return (
    <Stack 
      alignItems={'center'} 
      spacing={3} 
      pt={2}
      sx={{
        position: 'relative',
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <Box 
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: `linear-gradient(to top, rgba(255,255,255,1) 1%, transparent 10%), url(${image})`,
          backgroundSize: '100% auto',
          backgroundPosition: 'top center',
          backgroundRepeat: 'no-repeat',
          zIndex: -1
        }}
      />
      <Stack sx={{ position: 'relative', zIndex: 1 }}>
        <Typography variant={'h3'} textAlign={'center'} mt={10} dangerouslySetInnerHTML={{ __html: title }} />
        <Typography fontSize={16} textAlign={'center'} mt={3} dangerouslySetInnerHTML={{ __html: description }} />
        {linkAccountButton && (
          <Button
            sx={{ mt: 3, width: 'fit-content', alignSelf: 'center' }}
            variant={'contained'}
            component={RouterLink}
            to={generatePath(ROUTES.BRAND_PROFILE, { brandId: brand.id })}
          >
            Link seller account
          </Button>
        )}
      </Stack>
    </Stack>
  )
}
