import { yupResolver } from '@hookform/resolvers/yup'
import { ProposalCreateForm, ProposalCreatorPricingInput } from '@momentum/routes/proposals-create/types'
import { LockOutlined } from '@mui/icons-material'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'
import { Button, Chip, Typography } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import { Stack } from '@mui/system'
import { DEFAULT_MANAGED_SERVICE_FEE, MANAGED_SERVICE_CREATOR_TYPES } from '@productwindtom/shared-momentum'
import { Form, NumberInput, SelectInput, SwitchInput } from '@productwindtom/ui-base'
import { useFlag } from '@unleash/proxy-client-react'
import { useEffect, useState } from 'react'
import { useFormContext, UseFormReset } from 'react-hook-form'
import { extraFeesFormValidationSchema, SUBSCRIPTION_TERM_MONTHS } from '../validations'

type ExtraFeesFormData = {
  managedServiceFeeEnabled: boolean
  managedServiceFee?: number | null
  subscriptionFeeEnabled: boolean
  subscriptionFee?: number | null
  subscriptionTerm?: number | null
}

export const ExtraFeesForm = () => {
  const { getValues, setValue } = useFormContext<ProposalCreateForm>()
  const [expanded, setExpanded] = useState(false)

  const [
    managedServiceFeeEnabled,
    managedServiceFee,
    subscriptionFeeEnabled,
    subscriptionFee,
    subscriptionTerm,
    creatorPricing
  ] = getValues([
    'managedServiceFeeEnabled',
    'managedServiceFee',
    'subscriptionFeeEnabled',
    'subscriptionFee',
    'subscriptionTerm',
    'creatorPricing'
  ])

  const defaultValues: ExtraFeesFormData = {
    managedServiceFeeEnabled: !!managedServiceFeeEnabled,
    managedServiceFee,
    subscriptionFeeEnabled: !!subscriptionFeeEnabled,
    subscriptionFee,
    subscriptionTerm
  }

  const handleSubmit = async (submitValues: ExtraFeesFormData) => {
    setValue('managedServiceFeeEnabled', !!submitValues.managedServiceFeeEnabled)
    setValue('managedServiceFee', submitValues.managedServiceFeeEnabled ? submitValues.managedServiceFee : null)
    setValue('subscriptionFeeEnabled', !!submitValues.subscriptionFeeEnabled)
    setValue('subscriptionFee', submitValues.subscriptionFeeEnabled ? submitValues.subscriptionFee : null)
    setValue('subscriptionTerm', submitValues.subscriptionFeeEnabled ? submitValues.subscriptionTerm : null)
    setExpanded(false)
  }

  const handleCancel = (reset: UseFormReset<ExtraFeesFormData>) => {
    reset(defaultValues)
    setExpanded(false)
  }

  return (
    <Form
      key={JSON.stringify(defaultValues)}
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      resolver={yupResolver(extraFeesFormValidationSchema)}
    >
      <Accordion
        expanded={expanded}
        onChange={(_, expanded) => setExpanded(expanded)}
        sx={{
          borderBottom: `1px solid #EAEAEA`,
          padding: 2
        }}
      >
        <AccordionSummary
          expandIcon={
            <Stack data-cy="extraFeesExpandButton">
              <ExpandMoreOutlinedIcon />
            </Stack>
          }
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            padding: 0
          }}
        >
          <Stack spacing={1} direction={'row'}>
            <Typography variant="label1">Managed service fee & SAAS fee</Typography>
            <Chip label={'Admin'} size="small" color="secondary" />
          </Stack>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: 0
          }}
        >
          <ExtraFeesFormBody onSubmit={handleSubmit} onCancel={handleCancel} creatorPricing={creatorPricing} />
        </AccordionDetails>
      </Accordion>
    </Form>
  )
}

const ExtraFeesFormBody = ({
  onSubmit,
  onCancel,
  creatorPricing
}: {
  onSubmit: (submitValues: ExtraFeesFormData) => void
  onCancel: (reset: UseFormReset<ExtraFeesFormData>) => void
  creatorPricing: ProposalCreatorPricingInput[]
}) => {
  const {
    watch,
    formState: { isValid, isDirty },
    handleSubmit,
    reset,
    setValue
  } = useFormContext<ExtraFeesFormData>()

  const managedServiceFeeEnabled = watch('managedServiceFeeEnabled')
  const managedServiceFee = watch('managedServiceFee')
  const subscriptionFeeEnabled = watch('subscriptionFeeEnabled')

  const managedServiceFeeInputFlag = useFlag('ManagedServiceFeeInput')
  const subscriptionFeeInputFlag = useFlag('SubscriptionFeeInput')

  const hasManagedServiceCreators = creatorPricing.some(
    c => MANAGED_SERVICE_CREATOR_TYPES.includes(c.type) && c.numCreators > 0
  )

  // Similar code exists in the parent form, but since this is a nested form we need to also reflect the pricing changes here
  useEffect(() => {
    const hasManagedServiceCreators = creatorPricing.some(
      c => MANAGED_SERVICE_CREATOR_TYPES.includes(c.type) && c.numCreators > 0
    )
    if (hasManagedServiceCreators) {
      setValue('managedServiceFeeEnabled', true, {
        shouldValidate: true
      })
      setValue(
        'managedServiceFee',
        managedServiceFeeInputFlag && managedServiceFee != null ? managedServiceFee : DEFAULT_MANAGED_SERVICE_FEE
      )
    }
  }, [creatorPricing])

  return (
    <Stack spacing={3} pr={3}>
      <Stack spacing={2}>
        {hasManagedServiceCreators ? (
          <Stack direction={'row'} spacing={1}>
            <Typography variant="label2" color={!managedServiceFeeEnabled ? 'grey.A700' : undefined}>
              Change managed service fee
            </Typography>
            {!managedServiceFeeInputFlag && <LockOutlined />}
          </Stack>
        ) : (
          <Stack spacing={1} direction={'row'} alignItems={'center'}>
            <Typography variant="label2" color={!managedServiceFeeEnabled ? 'grey.A700' : undefined}>
              Add managed service fee
            </Typography>
            <SwitchInput name="managedServiceFeeEnabled" data-cy="managedServiceFeeEnabledSwitch" />
          </Stack>
        )}

        <NumberInput
          name={'managedServiceFee'}
          data-cy={'managedServiceFee'}
          returnAsNumber
          allowNegative={false}
          placeholder="Enter managed service fee amount"
          disabled={!managedServiceFeeEnabled || (hasManagedServiceCreators && !managedServiceFeeInputFlag)}
        />
      </Stack>
      {subscriptionFeeInputFlag && (
        <Stack spacing={2}>
          <Stack spacing={1} direction={'row'} alignItems={'center'}>
            <Typography variant="label2" color={!subscriptionFeeEnabled ? 'grey.A700' : undefined}>
              Display software subscription
            </Typography>
            <SwitchInput name="subscriptionFeeEnabled" />
          </Stack>

          <NumberInput
            name={'subscriptionFee'}
            data-cy={'subscriptionFee'}
            returnAsNumber
            allowNegative={false}
            placeholder="Enter subscription fee amount managed service fee amount"
            disabled={!subscriptionFeeEnabled}
          />
          <SelectInput
            name="subscriptionTerm"
            options={SUBSCRIPTION_TERM_MONTHS.map(o => {
              return {
                value: o,
                label: `${o} month term`
              }
            })}
            placeholder="Select subscription term"
            disabled={!subscriptionFeeEnabled}
          />
        </Stack>
      )}
      <Stack direction={'row'} spacing={1}>
        <Button
          type="button"
          variant="outlined"
          disabled={!isValid || !isDirty}
          onClick={handleSubmit(onSubmit)}
          data-cy="extraFeesSaveButton"
        >
          Save selection
        </Button>
        <Button onClick={() => onCancel(reset)}>Cancel</Button>
      </Stack>
    </Stack>
  )
}
