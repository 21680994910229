import { Add } from '@mui/icons-material'
import { Alert, AlertTitle, Button, Chip, Stack, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

export const MultiTextListInput = ({
  name,
  addText,
  placeholder,
  max,
  disabled,
  validate
}: {
  name: string
  addText: string
  placeholder?: string
  max?: number
  disabled?: boolean
  validate?: (value: string) => string | undefined
}) => {
  const [validationError, setValidationError] = useState<string | undefined>()
  const [searchInput, setSearchInput] = useState<string>('')
  const { control, getValues } = useFormContext()
  const { append, fields, remove } = useFieldArray({ name, control })

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      const modifiedSearchInput = searchInput.trim()
      const vals = getValues(name)
      if (!searchInput || (vals || []).find((v: string) => v.trim() === modifiedSearchInput)) return
      handleAdd()
    }
  }

  const handleAdd = () => {
    const validationError = validate?.(searchInput)
    setValidationError(validationError)
    if (!validationError) {
      append(searchInput)
      setSearchInput('')
    }
  }

  return (
    <Stack spacing={2} data-cy={name}>
      {!!fields.length && (
        <Stack direction={'row'} flexWrap={'wrap'}>
          {fields.map((v, index) => (
            <Chip
              data-cy={`${name}_${getValues(`${name}[${index}]`)}`}
              key={v.id}
              label={<Typography variant={'label3'}>{getValues(`${name}[${index}]`)}</Typography>}
              size={'small'}
              sx={{
                'mr': 1,
                'mb': 1,
                'bgcolor': theme => theme.palette.info.main,
                'color': 'white',
                '& .MuiChip-deleteIcon': {
                  color: 'white'
                }
              }}
              disabled={disabled}
              onDelete={() => {
                remove(index)
              }}
            />
          ))}
        </Stack>
      )}

      <TextField
        data-cy={`${name}_search`}
        variant={'outlined'}
        value={searchInput}
        onKeyDown={handleKeyDown}
        onChange={e => setSearchInput(e.target.value)}
        disabled={fields.length >= 5 || disabled}
        placeholder={placeholder}
      />
      <Box>
        <Button
          data-cy={`${name}_add`}
          startIcon={<Add />}
          variant={'text'}
          onClick={handleAdd}
          disabled={!searchInput?.trim() || (!!max && fields.length >= max) || disabled}
          sx={{ typography: 'link2' }}
        >
          {addText}
        </Button>
      </Box>

      {validationError && (
        <Alert
          severity={'error'}
          variant={'outlined'}
          style={{
            marginTop: '8px',
            alignSelf: 'flex-start',
            maxWidth: '360px'
          }}
        >
          <AlertTitle paddingBottom={0}>
            <Typography variant={'label3'} color={'black'}>
              {validationError}
            </Typography>
          </AlertTitle>
        </Alert>
      )}
    </Stack>
  )
}
