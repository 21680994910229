import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { ThemeSelector } from '@momentum/routes/campaign/e-commerce/feedback/ThemeSelector'
import { FeedbackDataDisplay } from '@momentum/routes/campaign/e-commerce/feedback/feedback-data-display'
import { Collapse, Paper, Stack, Typography } from '@mui/material'
import { ProductFeedbackStatus } from '@productwindtom/shared-momentum-zeus-types'
import { pdf } from '@react-pdf/renderer'
import { orderBy } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types'
import { Summary } from './Summary'
import { MAX_THEMES, OTHER_THEME, THEME_COLOR_SET } from './constants'
import FeedbackContext, { AISummary } from './feedbackContext'
import { FeedbackPDFDocument } from './pdf'
import { ProductFeedbackSummary, generateFeedbackReport, getProductFeedbackSummary } from './queries'
import { ThemeSummary } from './theme-summary'
import { EmptyState } from '@momentum/routes/campaign/e-commerce/common/EmptyState'
import EmptyStateImage from '/images/empty-states/feedback.png'

export const Feedback = () => {
  const { isAdminView } = useUserSessionContext()
  const { campaignDetails } = useCampaignContext()
  const [selectedThemeName, setSelectedThemeName] = useState<undefined | string>()
  const [chartRef, setChartRef] = useState<ChartJSOrUndefined<'doughnut'> | null>(null)
  const [aiSummary, setAISummary] = useState<AISummary | undefined>()
  const [productFeedbackSummary, setProductFeedbackSummary] = useState<undefined | ProductFeedbackSummary>()
  const [loading, setLoading] = useState(true)
  const [monitorRef, setMonitorRef] = useState<NodeJS.Timeout | null>(null)

  useEffect(() => {
    getProductFeedbackSummary(campaignDetails.id)
      .then(setProductFeedbackSummary)
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    if (!monitorRef && productFeedbackSummary?.status === ProductFeedbackStatus.IN_PROGRESS) {
      const t = setInterval(() => getProductFeedbackSummary(campaignDetails.id).then(setProductFeedbackSummary), 30000)
      setMonitorRef(t)
    } else if (monitorRef) {
      clearInterval(monitorRef)
    }
    return () => {
      if (monitorRef) {
        clearInterval(monitorRef)
      }
    }
  }, [productFeedbackSummary])

  useEffect(() => {
    if (productFeedbackSummary && productFeedbackSummary.jsonDownloadUrl) {
      fetch(productFeedbackSummary.jsonDownloadUrl)
        .then(resp => resp.json())
        .then((json: AISummary) => setAISummary(json))
    }
  }, [productFeedbackSummary])

  const generateProductFeedback = async () => {
    const resp = await generateFeedbackReport(campaignDetails.id)
    setProductFeedbackSummary(resp)
  }

  const allThemes = useMemo(
    () =>
      aiSummary
        ? aiSummary.themes
            .map(theme => {
              const feedback = aiSummary.feedback.filter(f => f.theme === theme.theme)
              return {
                theme: theme.theme,
                positiveFeedback: feedback.filter(f => f.sentiment === 'positive').map(f => f.feedback),
                criticalFeedback: feedback.filter(f => f.sentiment === 'negative').map(f => f.feedback),
                summary: theme.summary
              }
            })
            .filter(theme => theme.criticalFeedback.length || theme.positiveFeedback.length)
        : [],
    [aiSummary]
  )

  const totalFeedback =
    allThemes.flatMap(t => t.positiveFeedback).length + allThemes.flatMap(t => t.criticalFeedback).length
  const ordered = orderBy(allThemes, t => t.positiveFeedback.length + t.criticalFeedback.length, 'desc')
  const individual = ordered.slice(0, MAX_THEMES)
  const other = ordered.slice(MAX_THEMES)
  const newSet = [
    ...individual,
    ...(other.length
      ? [
          {
            theme: OTHER_THEME,
            summary: OTHER_THEME,
            positiveFeedback: other.flatMap(t => t.positiveFeedback),
            criticalFeedback: other.flatMap(t => t.criticalFeedback)
          }
        ]
      : [])
  ].map((t, index) => ({
    ...t,
    color: THEME_COLOR_SET[index],
    percentage: Math.round(((t.positiveFeedback.length + t.criticalFeedback.length) / totalFeedback) * 100)
  }))
  const themes = orderBy(newSet, n => n.percentage, 'desc')

  const selectedTheme = useMemo(() => themes.find(t => t.theme === selectedThemeName), [selectedThemeName])

  const generatePdf = async () => {
    const pdfDocument = (
      <FeedbackPDFDocument
        productFeedbackSummary={productFeedbackSummary!}
        aiSummary={aiSummary}
        themes={themes}
        chartBase64Image={chartRef?.toBase64Image()}
        campaignDetails={campaignDetails}
      />
    )

    await pdf(pdfDocument)
      .toBlob()
      .then(blob => {
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `feedback_${campaignDetails.title.toLowerCase().replace(' ', '_')}.pdf`
        a.click()
      })
  }

  return (
    <FeedbackContext.Provider
      value={{
        themes,
        aiSummary,
        productFeedbackSummary,
        loading,
        selectedTheme,
        setSelectedTheme: setSelectedThemeName,
        setChartRef,
        generateProductFeedback,
        generatePdf
      }}
    >
      <Stack spacing={5}>
        {(!!themes.length || isAdminView) && (
          <Paper sx={{ px: 4, py: 2 }}>
            <Summary />
          </Paper>
        )}

        {!themes.length && <EmptyState
          image={EmptyStateImage} 
          title="We’re collecting product feedback"
          description="The product feedback report will be available soon."
        />}
        {!!themes.length && (
          <Stack spacing={3}>
            <Typography variant={'h4'}>Main themes</Typography>
            <Stack spacing={4}>
              <ThemeSelector />
              <FeedbackDataDisplay />
            </Stack>
          </Stack>
        )}

        <Collapse in={!!selectedTheme}>
          <Paper sx={{ px: 4, py: 2 }}>
            <ThemeSummary />
          </Paper>
        </Collapse>
      </Stack>
    </FeedbackContext.Provider>
  )
}
