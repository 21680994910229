import { useUserSessionContext } from '@momentum/contexts/UserSession'
import {
  adjustSubscriptionCredits,
  getCredits,
  purchaseSubscriptionCredits,
  SubscriptionCreditAction
} from '@momentum/routes/queries'
import { adjustSubscription } from '@momentum/routes/subscription/queries'
import {
  CreditAction,
  InvoiceStatus,
  SubscriptionLevel,
  PaymentTermsType
} from '@productwindtom/shared-momentum-zeus-types'
import { sum, uniqBy } from 'lodash'
import { DateTime } from 'luxon'
import React, { useEffect, useMemo, useState } from 'react'
import { DEFAULT_NET_TERMS } from '@momentum/utils/proposalUtils'

export type BuyCreditsInput = {
  numCredits: number
  paymentDueDate: DateTime
  paymentBillingContactEmail: string
  paymentBillingContactId: string
  billingContacts: {
    email?: string
    name?: string
  }[]
  associatedProposalId?: string
}

export type SubscriptionConfiguration = {
  id: string
  name: string
  subscriptionStartsAt?: string
  subscriptionEndsAt?: string
  subscriptionTermMonths?: number
}

export type CompanySubscriptionContext = {
  name?: string
  subscriptionLevel: SubscriptionLevel
  startsAt?: DateTime
  endsAt?: DateTime
  termMonths?: number
  paymentTermsType: PaymentTermsType
  netTermsDays: number
  getSubscriptionStatus: () => { isActive: boolean; hasStarted: boolean; hasSubscription: boolean; hasEnded: boolean }
  subscriptionCreditActions: SubscriptionCreditAction[]
  overdueCreditActions: SubscriptionCreditAction[]
  creditsRemaining: number
  updateSubscription: (startDate?: string, endDate?: string, termMonths?: number) => Promise<void>
  addCredits: (input: { numCredits: number }) => Promise<SubscriptionCreditAction>
  spendCredits: (input: { numCredits: number; associatedBrandId?: string }) => Promise<SubscriptionCreditAction>
  purchaseCredits: (input: BuyCreditsInput) => Promise<SubscriptionCreditAction>
  refreshCredits: () => Promise<void>
}
const SubscriptionContext = React.createContext<CompanySubscriptionContext>({
  name: '',
  subscriptionLevel: SubscriptionLevel.BRAND,
  getSubscriptionStatus: () => ({ isActive: false, hasStarted: false, hasSubscription: false, hasEnded: false }),
  subscriptionCreditActions: [],
  overdueCreditActions: [],
  creditsRemaining: 0,
  paymentTermsType: PaymentTermsType.DUE_UPON_RECEIPT,
  netTermsDays: DEFAULT_NET_TERMS,
  updateSubscription: () => Promise.resolve(),
  addCredits: () => Promise.resolve({} as any),
  spendCredits: () => Promise.resolve({} as any),
  refreshCredits: () => Promise.resolve(),
  purchaseCredits: () => Promise.resolve({} as any)
})

export const SubscriptionProvider = ({ children }: { children?: React.ReactNode }) => {
  const [subscriptionCreditActions, setSubscriptionCreditActions] = useState<SubscriptionCreditAction[]>([])

  const { selectedBrand, selectedCompany, refreshCompany, refreshBrand, agencies } = useUserSessionContext()

  const subscriptionConfig: SubscriptionConfiguration | undefined =
    selectedCompany?.subscriptionLevel === SubscriptionLevel.COMPANY ? selectedCompany : selectedBrand
  const subscriptionLevel = selectedCompany?.subscriptionLevel || SubscriptionLevel.BRAND

  const companyAgency = agencies?.find(agency => agency.id === selectedCompany?.agencyId)
  const agencyTerms = companyAgency?.paymentTermsType

  const paymentTermsType = agencyTerms || selectedCompany?.paymentTermsType || PaymentTermsType.DUE_UPON_RECEIPT
  const netTerms =
    paymentTermsType === PaymentTermsType.NET_CUSTOM
      ? companyAgency?.paymentTermsCustomNetDays || selectedCompany?.paymentTermsCustomNetDays || 0
      : DEFAULT_NET_TERMS

  useEffect(() => {
    refreshCredits()
  }, [subscriptionConfig?.id])

  const overdueCreditActions = useMemo(() => {
    return uniqBy(
      subscriptionCreditActions?.filter(
        ({ invoiceStatus, invoiceDueDate }) =>
          invoiceStatus === InvoiceStatus.NOT_PAID && invoiceDueDate && invoiceDueDate < DateTime.now().toISODate()
      ) ?? [],
      ca => ca.invoiceId
    )
  }, [subscriptionCreditActions])

  const refreshCredits = async () => {
    if (subscriptionConfig) {
      setSubscriptionCreditActions(await getCredits(subscriptionConfig.id))
    }
  }

  const getSubscriptionStatus = ({
    subscriptionStartsAt,
    subscriptionEndsAt
  }: {
    subscriptionStartsAt?: string
    subscriptionEndsAt?: string
  }) => {
    const now = DateTime.now()
    const startDateTimeObj = subscriptionStartsAt ? DateTime.fromISO(subscriptionStartsAt) : undefined
    const endDateTimeObj = subscriptionEndsAt ? DateTime.fromISO(subscriptionEndsAt) : undefined
    const isActive = !!startDateTimeObj && now > startDateTimeObj && (!endDateTimeObj || now < endDateTimeObj)
    const hasStarted = !!startDateTimeObj && now > startDateTimeObj
    const hasSubscription = !!startDateTimeObj
    const hasEnded = !!endDateTimeObj && now > endDateTimeObj
    return {
      isActive,
      hasStarted,
      hasSubscription,
      hasEnded
    }
  }

  const refreshSource = () => {
    if (subscriptionConfig) {
      if (subscriptionLevel === SubscriptionLevel.COMPANY) {
        refreshCompany(subscriptionConfig.id)
      } else {
        refreshBrand(subscriptionConfig.id)
      }
    }
  }

  const handleUpdateSubscription = async (
    subscriptionStartsAt?: string,
    subscriptionEndsAt?: string,
    subscriptionTermMonths?: number
  ) => {
    if (subscriptionConfig) {
      await adjustSubscription({
        id: subscriptionConfig.id,
        subscriptionStartsAt,
        subscriptionEndsAt,
        subscriptionTermMonths
      })
      refreshSource()
      refreshCredits()
    }
  }

  const handleAdjustCredits = async ({
    action,
    numCredits,
    associatedBrandId
  }: {
    action: CreditAction
    numCredits: number
    associatedBrandId?: string
  }) => {
    if (subscriptionConfig && selectedBrand) {
      const newAction = await adjustSubscriptionCredits({
        id: selectedBrand.id,
        numCredits,
        action,
        associatedBrandId
      })

      setSubscriptionCreditActions(prevActions => [...prevActions, newAction])
      return newAction
    }
    throw new Error('No selected company to adjust credits for')
  }

  const handlePurchaseCredits = async ({ billingContacts, paymentDueDate, ...rest }: BuyCreditsInput) => {
    if (selectedBrand) {
      const newAction = await purchaseSubscriptionCredits({
        id: selectedBrand.id,
        billingContacts: billingContacts
          .filter(({ email, name }) => email && name)
          .map(({ email, name }) => ({ email: email!, name: name! })),
        invoiceDueDate: paymentDueDate.toISODate()!,
        ...rest
      })
      setSubscriptionCreditActions(prevActions => [...prevActions, newAction])
      return newAction
    } else {
      throw new Error('No selected company to adjust credits for')
    }
  }

  return (
    <SubscriptionContext.Provider
      value={{
        name: subscriptionConfig?.name,
        subscriptionLevel,
        startsAt: subscriptionConfig?.subscriptionStartsAt
          ? DateTime.fromISO(subscriptionConfig?.subscriptionStartsAt)
          : undefined,
        endsAt: subscriptionConfig?.subscriptionEndsAt
          ? DateTime.fromISO(subscriptionConfig?.subscriptionEndsAt)
          : undefined,
        termMonths: subscriptionConfig?.subscriptionTermMonths,
        subscriptionCreditActions,
        overdueCreditActions,
        paymentTermsType: paymentTermsType,
        netTermsDays: netTerms,
        getSubscriptionStatus: () => getSubscriptionStatus(subscriptionConfig || {}),
        creditsRemaining: sum(
          subscriptionCreditActions.map(
            s => s.numCredits * (s.action === CreditAction.REMOVE ? -1 : s.action === CreditAction.ADD ? 1 : 0)
          )
        ),
        updateSubscription: handleUpdateSubscription,
        addCredits: input => handleAdjustCredits({ ...input, action: CreditAction.ADD }),
        spendCredits: input => handleAdjustCredits({ ...input, action: CreditAction.REMOVE }),
        purchaseCredits: handlePurchaseCredits,
        refreshCredits
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  )
}

SubscriptionContext.displayName = 'SubscriptionContext'

export default SubscriptionContext

export const useSubscriptionContext = () => React.useContext(SubscriptionContext)
