import { Stack, Typography, Select, MenuItem } from '@mui/material'
import { useBenchmarkContext } from '../../context/BenchmarkContext'
import { SkuSelectionType } from '@momentum/routes/campaign/context/CampaignContext'

export const SkuSelector = () => {
  const { graphMetrics, setGraphMetrics } = useBenchmarkContext()
  
  return (
    <Stack direction={'column'} spacing={1} sx={{ width: '100%' }}>
      <Typography variant={'label1'}>Select data view</Typography>
      <Select
        value={graphMetrics?.benchmarkSkuType}
        onChange={e => setGraphMetrics({
          ...graphMetrics!,
          benchmarkSkuType: e.target.value as SkuSelectionType
        })}
        sx={{ minWidth: 200 }}
      >
        <MenuItem value={SkuSelectionType.SELECTED_VARIATIONS}>Selected variations</MenuItem>
        <MenuItem value={SkuSelectionType.ENTIRE_LISTING}>Entire listing</MenuItem>
      </Select>
    </Stack>
  )
}
