import { HelpOutline } from '@mui/icons-material'
import { Dialog, Typography, ListItemButton, ListItemIcon, ListItemText, Stack, List } from '@mui/material'
import React, { useState } from 'react'
import ContactSupportForm from './ContactSupportForm'
import { useFlag } from '@unleash/proxy-client-react'
import QuestionSpeechIcon from '@momentum/components/icons/question-speech'

const Support = () => {
  const supportFlag = useFlag('Support')

  const [isContactSupportOpen, setIsContactSupportOpen] = useState<boolean>(false)

  const handleSupportClick = () => {
    setIsContactSupportOpen(true)
  }

  const handleClose = () => {
    setIsContactSupportOpen(false)
  }

  if (!supportFlag) {
    return null
  }

  return (
    <Stack>
      <Typography variant={'body1'} color={'white'}>
        Support
      </Typography>
      <List>
        <ListItemButton
          href={'https://info.productwind.com/'}
          target={'_blank'}
          dense
          sx={{
            px: 1,
            borderRadius: '4px'
          }}
        >
          <ListItemIcon>
            <HelpOutline sx={{ color: 'white' }} />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant={'label3'} color={theme => theme.palette.primary.contrastText}>
                Help center
              </Typography>
            }
          />
        </ListItemButton>
        <ListItemButton
          onClick={handleSupportClick}
          dense
          sx={{
            px: 1,
            borderRadius: '4px'
          }}
        >
          <ListItemIcon>
            <QuestionSpeechIcon sx={{ color: 'white' }} />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant={'label3'} color={theme => theme.palette.primary.contrastText}>
                Contact support
              </Typography>
            }
          />
        </ListItemButton>
      </List>
      <Dialog open={isContactSupportOpen} maxWidth="xs">
        <ContactSupportForm onClose={handleClose} />
      </Dialog>
    </Stack>
  )
}

export default Support
