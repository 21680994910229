import { Retailer } from '@productwindtom/shared-momentum'
import { Region, Store } from '@productwindtom/shared-momentum-zeus-types'

export const WALMART_ITEM_ID_REGEX = /https?:\/\/(?:www\.)?walmart\.[a-z.]{2,6}\/ip\/[^\/]+\/(\d+)(?!.*\/\d+)/
export const AMAZON_ASIN_REGEX = /(?<![\w/])[A-Z0-9]{10}(?![\w])/
export const AMAZON_ASIN_FULL_URL_REGEX = /https?:\/\/(?:www\.)?amazon\.[a-z.]{2,6}\/(?:[^\/]+\/)?dp\/([A-Z0-9]{10})/

export const getStoreFromRegionRetailer = (region: Region, retailer: Retailer): Store => {
  let append = ''
  if (region !== Region.US) {
    switch (region) {
      case Region.CA:
        append = '_ca'
        break
      case Region.UK:
        append = '_uk'
        break
      case Region.DE:
        append = '_de'
        break
    }
  }

  switch (retailer) {
    case Retailer.AMAZON:
      return (Store.amazon + append) as Store
    case Retailer.BEST_BUY:
      return (Store.bestBuy + append) as Store
    case Retailer.TARGET:
      return (Store.target + append) as Store
    case Retailer.WALMART:
      return (Store.walmart + append) as Store
    case Retailer.COSTCO:
      return (Store.costco + append) as Store
    case Retailer.DTC:
      return Store.dtc
    case Retailer.KROGER:
      return Store.kroger
    case Retailer.OTHER:
      return Store.other
  }
}

export const NON_OTHER_STORES = [
  Store.amazon,
  Store.amazon_ca,
  Store.amazon_uk,
  Store.amazon_de,
  Store.target,
  Store.walmart
]

/**
 * Returns the store to use for creator pricing. Creator pricing is set for the main set and all other stores go under "Other"
 * @param store
 */
export const getCreatorPricingStore = (store: Store): Store => {
  return NON_OTHER_STORES.includes(store) ? store : Store.other
}

export const STORES_BY_REGION: Record<Region, Store[]> = {
  [Region.US]: [Store.amazon, Store.target, Store.walmart, Store.other],
  [Region.CA]: [Store.amazon_ca, Store.other],
  [Region.UK]: [Store.amazon_uk, Store.other],
  [Region.DE]: [Store.amazon_de, Store.other]
}

export const REGIONAL_STORES: Record<string, Store[]> = {
  [Store.amazon]: [Store.amazon, Store.amazon_ca, Store.amazon_uk, Store.amazon_de],
  [Store.walmart]: [Store.walmart],
  [Store.target]: [Store.target],
  [Store.other]: [Store.other]
}

export const STORE_DETAILS: Record<Store, { name: string; link?: string }> = {
  [Store.amazon]: {
    name: 'Amazon',
    link: 'amazon.com'
  },
  [Store.amazon_ca]: {
    name: 'Amazon',
    link: 'amazon.ca'
  },
  [Store.amazon_uk]: {
    name: 'Amazon',
    link: 'amazon.co.uk'
  },
  [Store.amazon_de]: {
    name: 'Amazon',
    link: 'amazon.de'
  },
  [Store.bestBuy]: {
    name: 'Best Buy',
    link: 'bestbuy.com'
  },
  [Store.walmart]: {
    name: 'Walmart',
    link: 'walmart.com'
  },
  [Store.target]: {
    name: 'Target',
    link: 'target.com'
  },
  [Store.costco]: {
    name: 'Costco',
    link: 'costco.com'
  },
  [Store.shopify]: {
    name: 'DTC',
    link: 'shopify.com'
  },
  [Store.dtc]: {
    name: 'DTC'
  },
  [Store.kroger]: {
    name: 'Kroger',
    link: 'kroger.com'
  },
  [Store.other]: {
    name: 'Other retailers'
  }
}

export const retailerProgramReviewsName = (store: Retailer) => {
  switch (store) {
    case Retailer.AMAZON:
      return 'Vine'
    case Retailer.WALMART:
      return 'BazaarVoice'
    default:
      return undefined
  }
}
export const getSkuType = (store: Store) => {
  switch (store) {
    case Store.amazon:
    case Store.amazon_ca:
    case Store.amazon_uk:
    case Store.amazon_de:
      return 'ASIN'
    case Store.walmart:
      return 'Item ID'
    default:
      return 'SKU'
  }
}
