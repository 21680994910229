import { DateTime } from 'luxon'
import { Stack, Stepper, Step, StepLabel, Typography } from '@mui/material'
import { getStringForStep, TimelineStep, TimelineStepType } from '@productwindtom/shared-momentum'
import EventHeartIcon from '@momentum/components/icons/event-heart'

export const EstimatedTimeline = ({
  timelineSteps,
  launchDate,
  isMonthly
}: {
  launchDate?: DateTime
  isMonthly?: boolean
  timelineSteps: TimelineStep[]
}) => {
  return (
    <Stack spacing={3}>
      <Stepper orientation="vertical">
        {timelineSteps.map((step, index) => (
          <Step key={index} index={step.displayIndex === -1 ? index : step.displayIndex} active>
            <StepLabel
              sx={{ py: 0.5 }}
              StepIconProps={
                step.type === TimelineStepType.EVENT
                  ? {
                      icon: <EventHeartIcon color={'primary'} fontSize={'medium'} />
                    }
                  : {}
              }
            >
              <Stack direction={'row'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
                <Typography variant={'label3'}>{getStringForStep(step)}</Typography>
                <Typography variant={'label1'}>
                  {step.date
                    ? step.date.toLocaleString(DateTime.DATE_SHORT)
                    : step.type !== TimelineStepType.REVIEWS_PER_WEEK
                      ? '--'
                      : ''}
                </Typography>
              </Stack>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  )
}
