import { Question } from '@momentum/components/proposal-common/Question'
import {
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableCell as MuiTableCell,
  TableRow,
  Typography,
  TableBody,
  styled,
  Link,
  Dialog
} from '@mui/material'
import { CREATOR_TYPE_PRICING_CONFIG } from '@momentum/utils/brandPricing'
import { sum, max } from 'lodash'
import { ProposalCreatorPricing } from '@momentum/routes/campaign/context/queries'
import { convertFromCreatorPricing } from '@momentum/utils/proposalUtils'
import { toWeeklyPartition } from '@productwindtom/shared-momentum'
import { CreatorScheduleEditor } from '@momentum/routes/proposals-create/timeline/creator-schedule-editor'
import { useDisclose } from '@momentum/hooks/useDisclose'
import { DateTime } from 'luxon'
import { ProposalGoal } from '@productwindtom/shared-momentum-zeus-types'

const TableCell = styled(MuiTableCell)`
  padding: 8px;
`
export const CreatorScheduleViewer = ({
  goal,
  startDate,
  eventDate,
  creatorPricing
}: {
  goal: ProposalGoal
  startDate: DateTime
  eventDate?: DateTime
  creatorPricing: ProposalCreatorPricing[]
}) => {
  const { isOpen, onOpen, onClose } = useDisclose()
  const pricing = convertFromCreatorPricing(creatorPricing)
  const groupedPricing = pricing.map(r => ({ ...r, schedule: toWeeklyPartition(r.schedule) }))

  const weeks = max(groupedPricing.map(creator => creator.schedule.length)) || 0
  const weeksArray = Array.from({ length: weeks }, (_, i) => i + 1)

  const totals = groupedPricing
    .map(r => r.schedule)
    .reduce((acc, schedule) => {
      for (let i = 0; i < schedule.length; i++) {
        acc[i] = (acc[i] || 0) + schedule[i]
      }
      return acc
    }, [])

  return (
    <Question
      primaryText={'Review creator schedule'}
      subtext={
        <>
          Creators will join your campaign according to the recommended schedule below.{' '}
          <Link variant={'link2'} onClick={onOpen} underline={'none'} sx={{ cursor: 'pointer' }}>
            View full creator schedule
          </Link>
        </>
      }
    >
      <TableContainer
        sx={{
          borderRadius: '4px',
          borderTop: '1px solid #eaeaea',
          borderLeft: '1px solid #eaeaea',
          borderRight: '1px solid #eaeaea'
        }}
      >
        <Table size={'small'}>
          <TableHead>
            <TableRow>
              <TableCell padding={'none'} width={60} />
              {pricing.map((creator, index) => (
                <TableCell key={index}>
                  <Stack alignItems={'center'}>
                    <Typography variant={'label2'} textAlign={'center'}>
                      {CREATOR_TYPE_PRICING_CONFIG[creator.type]?.title}
                    </Typography>
                    <Typography variant={'label2'}>{sum(creator.schedule)}</Typography>
                  </Stack>
                </TableCell>
              ))}
              <TableCell>
                <Stack alignItems={'center'}>
                  <Typography variant={'label2'} textAlign={'center'}>
                    Total creators
                  </Typography>
                  <Typography variant={'label2'}>{sum(totals)}</Typography>
                </Stack>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {weeksArray.map((week, weekIndex) => (
              <TableRow key={week}>
                <TableCell sx={{ py: 2 }}>
                  <Typography variant={'label2'}>Week {week}</Typography>
                </TableCell>
                {groupedPricing.map((creator, index) => (
                  <TableCell key={index} align={'center'} data-cy={`${creator.type}.week.${weekIndex}`}>
                    <Typography variant={'label3'}>{(creator.schedule[weekIndex] || 0).toLocaleString()}</Typography>
                  </TableCell>
                ))}
                <TableCell align={'center'} data-cy={`total.week.${weekIndex}`}>
                  <Typography variant={'label2'}>{totals[weekIndex]?.toLocaleString()}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={isOpen} PaperProps={{ sx: { p: 3 } }} maxWidth={'md'} fullWidth>
        <CreatorScheduleEditor
          goal={goal}
          startDate={startDate}
          eventDate={eventDate}
          creatorPricing={pricing}
          onClose={onClose}
          readonly
        />
      </Dialog>
    </Question>
  )
}
