import { TableCell, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { DateTime } from 'luxon'

export const TableHeadCell = ({
  week,
  date,
  align
}: {
  week: number
  date?: DateTime
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify'
}) => {
  return (
    <TableCell align={align}>
      <Stack>
        <Typography variant={'label2'}>{week >= 1 ? `Week ${week}` : 'Pre-launch'}</Typography>
        {date && (
          <Typography variant={'label3'} color={theme => theme.palette.grey.A700}>
            {date.toLocaleString({ month: 'short', day: 'numeric' })} -{' '}
            {date.plus({ weeks: 1, days: -1 }).toLocaleString({ month: 'short', day: 'numeric' })}
          </Typography>
        )}
      </Stack>
    </TableCell>
  )
}
