import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'

export default [
  {
    unitsSold: 1,
    totalSalesAmount: 13999,
    pageViews: 0,
    date: '2023-10-25',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2023-10-26',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 13999,
    pageViews: 0,
    date: '2023-10-27',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2023-10-28',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2023-10-29',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2023-10-30',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 176,
    date: '2023-10-31',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 253,
    date: '2023-11-01',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 262,
    date: '2023-11-02',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 240,
    date: '2023-11-03',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 248,
    date: '2023-11-04',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 323,
    date: '2023-11-05',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 161,
    date: '2023-11-06',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 189,
    date: '2023-11-07',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 261,
    date: '2023-11-08',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 259,
    date: '2023-11-09',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 431,
    date: '2023-11-10',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 469,
    date: '2023-11-11',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 467,
    date: '2023-11-12',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 401,
    date: '2023-11-13',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2023-11-14',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2023-11-15',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 27899,
    pageViews: 0,
    date: '2023-11-16',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2023-11-17',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 38997,
    pageViews: 0,
    date: '2023-11-18',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2023-11-19',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2023-11-20',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2023-11-21',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2023-11-22',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 12999,
    pageViews: 0,
    date: '2023-11-23',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 12999,
    pageViews: 0,
    date: '2023-11-24',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 25998,
    pageViews: 0,
    date: '2023-11-25',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2023-11-26',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 64995,
    pageViews: 0,
    date: '2023-11-27',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 206,
    date: '2023-11-28',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 160,
    date: '2023-11-29',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 196,
    date: '2023-11-30',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 155,
    date: '2023-12-01',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 220,
    date: '2023-12-02',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 283,
    date: '2023-12-03',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 199,
    date: '2023-12-04',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 196,
    date: '2023-12-05',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 213,
    date: '2023-12-06',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 256,
    date: '2023-12-07',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 223,
    date: '2023-12-08',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 205,
    date: '2023-12-09',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 438,
    date: '2023-12-10',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 365,
    date: '2023-12-11',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2023-12-12',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2023-12-13',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 23998,
    pageViews: 0,
    date: '2023-12-14',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 11999,
    pageViews: 0,
    date: '2023-12-15',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 23998,
    pageViews: 0,
    date: '2023-12-16',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 23998,
    pageViews: 0,
    date: '2023-12-17',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 11999,
    pageViews: 0,
    date: '2023-12-18',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 35997,
    pageViews: 0,
    date: '2023-12-19',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 23998,
    pageViews: 0,
    date: '2023-12-20',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 11999,
    pageViews: 0,
    date: '2023-12-21',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 23998,
    pageViews: 0,
    date: '2023-12-22',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 11999,
    pageViews: 0,
    date: '2023-12-23',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2023-12-24',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 35997,
    pageViews: 0,
    date: '2023-12-25',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 217,
    date: '2023-12-26',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 205,
    date: '2023-12-27',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 204,
    date: '2023-12-28',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 190,
    date: '2023-12-29',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 190,
    date: '2023-12-30',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 166,
    date: '2023-12-31',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 238,
    date: '2024-01-01',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 249,
    date: '2024-01-02',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 343,
    date: '2024-01-03',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 334,
    date: '2024-01-04',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 327,
    date: '2024-01-05',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 616,
    date: '2024-01-06',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 511,
    date: '2024-01-07',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 376,
    date: '2024-01-08',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2024-01-09',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2024-01-10',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 13999,
    pageViews: 0,
    date: '2024-01-11',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 13999,
    pageViews: 0,
    date: '2024-01-12',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 13999,
    pageViews: 0,
    date: '2024-01-13',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2024-01-14',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2024-01-15',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 13999,
    pageViews: 0,
    date: '2024-01-16',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2024-01-17',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 13999,
    pageViews: 0,
    date: '2024-01-18',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 13999,
    pageViews: 0,
    date: '2024-01-19',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2024-01-20',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2024-01-21',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 13999,
    pageViews: 0,
    date: '2024-01-22',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 13975,
    pageViews: 0,
    date: '2024-02-06',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 27998,
    pageViews: 0,
    date: '2024-02-07',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 13999,
    pageViews: 0,
    date: '2024-02-08',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 13999,
    pageViews: 0,
    date: '2024-02-09',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2024-02-10',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2024-02-11',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 12499,
    pageViews: 0,
    date: '2024-02-12',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 12499,
    pageViews: 0,
    date: '2024-02-13',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2024-02-14',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 12499,
    pageViews: 0,
    date: '2024-02-15',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 12499,
    pageViews: 0,
    date: '2024-02-16',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 12499,
    pageViews: 0,
    date: '2024-02-17',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 24998,
    pageViews: 0,
    date: '2024-02-18',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 12495,
    pageViews: 0,
    date: '2024-02-19',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 41997,
    pageViews: 206,
    date: '2024-03-05',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 13999,
    pageViews: 234,
    date: '2024-03-06',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 69995,
    pageViews: 276,
    date: '2024-03-07',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 13999,
    pageViews: 211,
    date: '2024-03-08',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 13999,
    pageViews: 224,
    date: '2024-03-09',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 24998,
    pageViews: 346,
    date: '2024-03-10',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 24998,
    pageViews: 292,
    date: '2024-03-11',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 12499,
    pageViews: 255,
    date: '2024-03-12',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 24998,
    pageViews: 248,
    date: '2024-03-13',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 12499,
    pageViews: 320,
    date: '2024-03-14',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 263,
    date: '2024-03-15',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 24998,
    pageViews: 284,
    date: '2024-03-16',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 329,
    date: '2024-03-17',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 12499,
    pageViews: 255,
    date: '2024-03-18',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 242,
    date: '2024-04-02',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 236,
    date: '2024-04-03',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 41997,
    pageViews: 224,
    date: '2024-04-04',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 13999,
    pageViews: 293,
    date: '2024-04-05',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 273,
    date: '2024-04-06',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 13999,
    pageViews: 284,
    date: '2024-04-07',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 273,
    date: '2024-04-08',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 85,
    date: '2024-04-09',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 100,
    date: '2024-04-10',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 104,
    date: '2024-04-11',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 13999,
    pageViews: 97,
    date: '2024-04-12',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 83,
    date: '2024-04-13',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 104,
    date: '2024-04-14',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 13999,
    pageViews: 57,
    date: '2024-04-15',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 11999,
    pageViews: 278,
    date: '2024-04-30',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 218,
    date: '2024-05-01',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 243,
    date: '2024-05-02',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 35997,
    pageViews: 505,
    date: '2024-05-03',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 11999,
    pageViews: 461,
    date: '2024-05-04',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 35997,
    pageViews: 498,
    date: '2024-05-05',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 23998,
    pageViews: 443,
    date: '2024-05-06',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 35997,
    pageViews: 378,
    date: '2024-05-07',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 59995,
    pageViews: 397,
    date: '2024-05-08',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 333,
    date: '2024-05-09',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 23998,
    pageViews: 372,
    date: '2024-05-10',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 35997,
    pageViews: 361,
    date: '2024-05-11',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 11999,
    pageViews: 347,
    date: '2024-05-12',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 27760,
    pageViews: 350,
    date: '2024-05-13',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 130,
    date: '2024-05-28',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 13802,
    pageViews: 181,
    date: '2024-05-29',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 13802,
    pageViews: 1,
    date: '2024-05-30',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 13802,
    pageViews: 2,
    date: '2024-05-31',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2024-06-01',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2024-06-02',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 13802,
    pageViews: 135,
    date: '2024-06-03',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 149,
    date: '2024-06-04',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 27604,
    pageViews: 171,
    date: '2024-06-05',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 11899,
    pageViews: 289,
    date: '2024-06-06',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 407,
    date: '2024-06-07',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 23798,
    pageViews: 406,
    date: '2024-06-08',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 362,
    date: '2024-06-09',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 410,
    date: '2024-06-10',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 149,
    date: '2024-06-25',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 12666,
    pageViews: 117,
    date: '2024-06-26',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 134,
    date: '2024-06-27',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 13837,
    pageViews: 120,
    date: '2024-06-28',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 27564,
    pageViews: 182,
    date: '2024-06-29',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 27564,
    pageViews: 209,
    date: '2024-06-30',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 121,
    date: '2024-07-01',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 141,
    date: '2024-07-02',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 97,
    date: '2024-07-03',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 13784,
    pageViews: 108,
    date: '2024-07-04',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 13784,
    pageViews: 83,
    date: '2024-07-05',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 115,
    date: '2024-07-06',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 13784,
    pageViews: 124,
    date: '2024-07-07',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 100,
    date: '2024-07-08',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 43996,
    pageViews: 533,
    date: '2024-07-23',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 452,
    date: '2024-07-24',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 383,
    date: '2024-07-25',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 10999,
    pageViews: 503,
    date: '2024-07-26',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 498,
    date: '2024-07-27',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 21998,
    pageViews: 467,
    date: '2024-07-28',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 43996,
    pageViews: 534,
    date: '2024-07-29',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 10999,
    pageViews: 436,
    date: '2024-07-30',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 21998,
    pageViews: 388,
    date: '2024-07-31',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 43996,
    pageViews: 367,
    date: '2024-08-01',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 10999,
    pageViews: 413,
    date: '2024-08-02',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 21998,
    pageViews: 405,
    date: '2024-08-03',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 32997,
    pageViews: 386,
    date: '2024-08-04',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 32997,
    pageViews: 375,
    date: '2024-08-05',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 32997,
    pageViews: 328,
    date: '2024-08-20',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 21998,
    pageViews: 250,
    date: '2024-08-21',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 32997,
    pageViews: 273,
    date: '2024-08-22',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 335,
    date: '2024-08-23',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 32997,
    pageViews: 356,
    date: '2024-08-24',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 115,
    date: '2024-08-25',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 13999,
    pageViews: 98,
    date: '2024-08-26',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 13999,
    pageViews: 108,
    date: '2024-08-27',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 27998,
    pageViews: 91,
    date: '2024-08-28',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 94,
    date: '2024-08-29',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 13999,
    pageViews: 113,
    date: '2024-08-30',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 112,
    date: '2024-08-31',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 118,
    date: '2024-09-01',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 152,
    date: '2024-09-02',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 31996,
    pageViews: 726,
    date: '2024-09-17',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 23997,
    pageViews: 416,
    date: '2024-09-18',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 23997,
    pageViews: 155,
    date: '2024-09-19',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 16063,
    pageViews: 135,
    date: '2024-09-20',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 15998,
    pageViews: 182,
    date: '2024-09-21',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 133,
    date: '2024-09-22',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 23997,
    pageViews: 198,
    date: '2024-09-23',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 7999,
    pageViews: 382,
    date: '2024-09-24',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 554,
    date: '2024-09-25',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 23997,
    pageViews: 556,
    date: '2024-09-26',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 33773,
    pageViews: 592,
    date: '2024-09-27',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 39971,
    pageViews: 647,
    date: '2024-09-28',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 39995,
    pageViews: 586,
    date: '2024-09-29',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 31996,
    pageViews: 372,
    date: '2024-09-30',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 15998,
    pageViews: 223,
    date: '2024-10-01',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 208,
    date: '2024-10-02',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 208,
    date: '2024-10-03',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 163,
    date: '2024-10-04',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 191,
    date: '2024-10-05',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 10640,
    pageViews: 204,
    date: '2024-10-06',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 193,
    date: '2024-10-07',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 52493,
    pageViews: 904,
    date: '2024-10-08',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 65616,
    pageViews: 1287,
    date: '2024-10-09',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 11,
    totalSalesAmount: 80996,
    pageViews: 877,
    date: '2024-10-10',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 197,
    date: '2024-10-11',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 186,
    date: '2024-10-12',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 10874,
    pageViews: 305,
    date: '2024-10-13',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 26997,
    pageViews: 260,
    date: '2024-10-14',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 35996,
    pageViews: 199,
    date: '2024-10-15',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 190,
    date: '2024-10-16',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 8300,
    pageViews: 197,
    date: '2024-10-17',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 16600,
    pageViews: 218,
    date: '2024-10-18',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 24900,
    pageViews: 236,
    date: '2024-10-19',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 8300,
    pageViews: 285,
    date: '2024-10-20',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 8300,
    pageViews: 215,
    date: '2024-10-21',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 15600,
    pageViews: 210,
    date: '2024-10-22',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 15600,
    pageViews: 231,
    date: '2024-10-23',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 31200,
    pageViews: 243,
    date: '2024-10-24',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 38850,
    pageViews: 243,
    date: '2024-10-25',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 172,
    date: '2024-10-26',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 7725,
    pageViews: 194,
    date: '2024-10-27',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 178,
    date: '2024-10-28',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 165,
    date: '2024-10-29',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 191,
    date: '2024-10-30',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 22884,
    pageViews: 220,
    date: '2024-10-31',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 22884,
    pageViews: 204,
    date: '2024-11-01',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 223,
    date: '2024-11-02',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 7628,
    pageViews: 304,
    date: '2024-11-03',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 7628,
    pageViews: 285,
    date: '2024-11-04',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 7628,
    pageViews: 214,
    date: '2024-11-05',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 7628,
    pageViews: 172,
    date: '2024-11-06',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 213,
    date: '2024-11-07',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 248,
    date: '2024-11-08',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 7672,
    pageViews: 292,
    date: '2024-11-09',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 15392,
    pageViews: 364,
    date: '2024-11-10',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 7696,
    pageViews: 315,
    date: '2024-11-11',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 8674,
    pageViews: 269,
    date: '2024-11-12',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 312,
    date: '2024-11-13',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 229,
    date: '2024-11-14',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 17316,
    pageViews: 271,
    date: '2024-11-15',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 7999,
    pageViews: 269,
    date: '2024-11-16',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 323,
    date: '2024-11-17',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 27949,
    pageViews: 817,
    date: '2024-11-18',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 13300,
    pageViews: 823,
    date: '2024-11-19',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 33250,
    pageViews: 655,
    date: '2024-11-20',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 39900,
    pageViews: 1005,
    date: '2024-11-21',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 39900,
    pageViews: 1024,
    date: '2024-11-22',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 39300,
    pageViews: 724,
    date: '2024-11-23',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 52210,
    pageViews: 539,
    date: '2024-11-24',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 45583,
    pageViews: 625,
    date: '2024-11-25',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 12,
    totalSalesAmount: 77928,
    pageViews: 600,
    date: '2024-11-26',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 37006,
    pageViews: 720,
    date: '2024-11-27',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 43400,
    pageViews: 786,
    date: '2024-11-28',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 14,
    totalSalesAmount: 86904,
    pageViews: 950,
    date: '2024-11-29',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 14,
    totalSalesAmount: 86800,
    pageViews: 946,
    date: '2024-11-30',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 31000,
    pageViews: 633,
    date: '2024-12-01',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 43400,
    pageViews: 795,
    date: '2024-12-02',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 12,
    totalSalesAmount: 74400,
    pageViews: 910,
    date: '2024-12-03',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 6200,
    pageViews: 859,
    date: '2024-12-04',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 24800,
    pageViews: 1150,
    date: '2024-12-05',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 43969,
    pageViews: 959,
    date: '2024-12-06',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 43400,
    pageViews: 837,
    date: '2024-12-07',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 31067,
    pageViews: 600,
    date: '2024-12-08',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 49600,
    pageViews: 446,
    date: '2024-12-09',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 49600,
    pageViews: 389,
    date: '2024-12-10',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 6200,
    pageViews: 530,
    date: '2024-12-11',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 43400,
    pageViews: 390,
    date: '2024-12-12',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 37200,
    pageViews: 284,
    date: '2024-12-13',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 12,
    totalSalesAmount: 74400,
    pageViews: 350,
    date: '2024-12-14',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 28468,
    pageViews: 379,
    date: '2024-12-15',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 9868,
    pageViews: 325,
    date: '2024-12-16',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 8034,
    pageViews: 281,
    date: '2024-12-17',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 16057,
    pageViews: 206,
    date: '2024-12-18',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 8034,
    pageViews: 228,
    date: '2024-12-19',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 24102,
    pageViews: 239,
    date: '2024-12-20',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 8034,
    pageViews: 242,
    date: '2024-12-21',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 8034,
    pageViews: 252,
    date: '2024-12-22',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 227,
    date: '2024-12-23',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 234,
    date: '2024-12-24',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 8034,
    pageViews: 332,
    date: '2024-12-25',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 24102,
    pageViews: 282,
    date: '2024-12-26',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 214,
    date: '2024-12-27',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 8034,
    pageViews: 163,
    date: '2024-12-28',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 161,
    date: '2024-12-29',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 16068,
    pageViews: 133,
    date: '2024-12-30',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 24400,
    pageViews: 198,
    date: '2024-12-31',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 25760,
    pageViews: 215,
    date: '2025-01-01',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 24400,
    pageViews: 381,
    date: '2025-01-02',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 24400,
    pageViews: 408,
    date: '2025-01-03',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 12200,
    pageViews: 350,
    date: '2025-01-04',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 427,
    date: '2025-01-05',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 6100,
    pageViews: 322,
    date: '2025-01-06',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 6100,
    pageViews: 53,
    date: '2025-01-07',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 5900,
    pageViews: 42,
    date: '2025-01-08',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 5900,
    pageViews: 42,
    date: '2025-01-09',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: -1,
    totalSalesAmount: -6100,
    pageViews: 61,
    date: '2025-01-10',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 5900,
    pageViews: 95,
    date: '2025-01-11',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 11800,
    pageViews: 161,
    date: '2025-01-12',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 23600,
    pageViews: 398,
    date: '2025-01-13',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 35200,
    pageViews: 1465,
    date: '2025-01-14',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 23400,
    pageViews: 379,
    date: '2025-01-15',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2025-01-16',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2025-01-17',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2025-01-18',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: -2,
    totalSalesAmount: -11800,
    pageViews: 0,
    date: '2025-01-19',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2025-01-20',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: -2,
    totalSalesAmount: -12000,
    pageViews: 0,
    date: '2025-01-21',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2025-01-22',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2025-01-23',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: -1,
    totalSalesAmount: -5900,
    pageViews: 0,
    date: '2025-01-24',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2025-01-25',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: -2,
    totalSalesAmount: -12000,
    pageViews: 0,
    date: '2025-01-26',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 34200,
    pageViews: 833,
    date: '2025-01-27',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 5700,
    pageViews: 838,
    date: '2025-01-28',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: -2,
    totalSalesAmount: -11800,
    pageViews: 146,
    date: '2025-01-29',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: -1,
    totalSalesAmount: -5900,
    pageViews: 82,
    date: '2025-01-30',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 102,
    date: '2025-01-31',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 73,
    date: '2025-02-01',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 103,
    date: '2025-02-02',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 27500,
    pageViews: 316,
    date: '2025-02-03',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 37,
    date: '2025-02-04',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: -1,
    totalSalesAmount: -5900,
    pageViews: 34,
    date: '2025-02-05',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 5500,
    pageViews: 29,
    date: '2025-02-06',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 22,
    date: '2025-02-07',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: -1,
    totalSalesAmount: -8034,
    pageViews: 27,
    date: '2025-02-08',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 22000,
    pageViews: 137,
    date: '2025-02-09',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2025-02-10',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2025-02-11',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: -2,
    totalSalesAmount: -11400,
    pageViews: 1,
    date: '2025-02-12',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 52,
    date: '2025-02-13',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 5500,
    pageViews: 15,
    date: '2025-02-14',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: -600,
    pageViews: 199,
    date: '2025-02-15',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2025-02-16',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2025-02-17',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: -1,
    totalSalesAmount: -5900,
    pageViews: 291,
    date: '2025-02-18',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 5500,
    pageViews: 40,
    date: '2025-02-19',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: -1,
    totalSalesAmount: -5500,
    pageViews: 121,
    date: '2025-02-20',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: -400,
    pageViews: 125,
    date: '2025-02-21',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 1321,
    pageViews: 27,
    date: '2025-02-22',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: -1,
    totalSalesAmount: -5700,
    pageViews: 3,
    date: '2025-02-23',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 8083,
    pageViews: 17,
    date: '2025-02-24',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 8004,
    pageViews: 32,
    date: '2025-02-25',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2025-02-26',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2025-02-27',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2025-02-28',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 22,
    date: '2025-03-02',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 89,
    date: '2025-03-03',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 85,
    date: '2025-03-04',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 139,
    date: '2025-03-05',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 123,
    date: '2025-03-06',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 15998,
    pageViews: 147,
    date: '2025-03-07',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 134,
    date: '2025-03-08',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 135,
    date: '2025-03-09',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 23997,
    pageViews: 187,
    date: '2025-03-10',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 144,
    date: '2025-03-11',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 143,
    date: '2025-03-12',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 7999,
    pageViews: 118,
    date: '2025-03-13',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 7999,
    pageViews: 158,
    date: '2025-03-14',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 124,
    date: '2025-03-15',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 7999,
    pageViews: 129,
    date: '2025-03-16',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 180,
    date: '2025-03-17',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 7999,
    pageViews: 119,
    date: '2025-03-18',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 15998,
    pageViews: 135,
    date: '2025-03-19',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 7999,
    pageViews: 135,
    date: '2025-03-20',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 7999,
    pageViews: 85,
    date: '2025-03-21',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 119,
    date: '2025-03-22',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 15998,
    pageViews: 126,
    date: '2025-03-23',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: -1,
    totalSalesAmount: -7999,
    pageViews: 89,
    date: '2025-03-24',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 126,
    date: '2025-03-25',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 121,
    date: '2025-03-26',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 15998,
    pageViews: 114,
    date: '2025-03-27',
    skuId: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e'
  }
] as Array<ModelTypes['EtailerProductMetrics']>
