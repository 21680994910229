import { Bar } from 'react-chartjs-2'
import { useBenchmarkContext } from '../../context/BenchmarkContext'
import { useTheme } from '@mui/material'
import { get, sumBy, last } from 'lodash'
import { BENCHMARK_PRODUCT_COLORS, BENCHMARK_TYPE_TO_VALUE } from '../../constants'
import { defaultTooltipOptions } from '@momentum/utils/tooltipUtils'
import { DateTime } from 'luxon'
import { EtailerSkuMetrics } from '../../queries'
import { useMemo } from 'react'
import { BenchmarkType } from '@productwindtom/shared-momentum-zeus-types'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { campaignImagePlugin, subLabelsPlugin } from '../../../common/GraphPlugins'

export const WeeklyGraph = () => {
  const theme = useTheme()
  const { products } = useBrandContext()
  const { graphMetrics, promotedProductId, enabledBenchmarkProducts, benchmarkData } = useBenchmarkContext()
  const { campaignDetails } = useCampaignContext()

  const metrics = useMemo(() => {
    if (graphMetrics) {
      const { metricType } = graphMetrics

      const metricKey = BENCHMARK_TYPE_TO_VALUE[metricType]

      const aggregatedData = Object.entries(benchmarkData).map(([skuId, metrics]) => ({
        data: aggregateData(
          metrics,
          skuId
        ),
        skuId
      }))

      const promotedProductData = aggregatedData.find(({ skuId }) => skuId === promotedProductId)
      const maxDays = promotedProductData?.data?.length || 0

      const labels = Array.from({ length: maxDays }, (_, i) => `Week ${i + 1}`)

      const datasets = Object.values(aggregatedData).map(({ data, skuId }, index) => ({
        data: data.map(item => {
          const value = item[metricKey as keyof typeof item] as number

          return metricType === BenchmarkType.REVENUE && value ? value / 100 : value
        }),
        rawData: data,
        skuId: skuId,
        backgroundColor: get(theme.palette, BENCHMARK_PRODUCT_COLORS[index]),
        ...(skuId === promotedProductId && {
          segment: {
            borderColor: function (context: any) {
              const date = data[context.p0DataIndex].date
              const endDate = campaignDetails.endDate || DateTime.now().toISO()

              return date >= campaignDetails.startDate && endDate >= date
                ? get(theme.palette, BENCHMARK_PRODUCT_COLORS[index])
                : `${get(theme.palette, BENCHMARK_PRODUCT_COLORS[index])}`
            }
          }
        })
      }))

      return {
        labels,
        datasets
      }
    }
  }, [campaignDetails, graphMetrics, benchmarkData, promotedProductId, products])

  
  const datasets = metrics?.datasets?.map((dataset, index) => ({
    ...dataset,
    data: dataset.data.slice(0, metrics.datasets[0].data.length)
  }))
  
  const visibleDataSets = useMemo(() => {
    return datasets?.map(dataset => ({
      ...dataset,
      hidden: !enabledBenchmarkProducts.includes(dataset.skuId)
    }))
  }, [datasets, enabledBenchmarkProducts])

  if (!metrics || !graphMetrics) return null


  const weeklyTagLabels = visibleDataSets?.[0]?.rawData.map((d, i) => {
    const startDateDateTime = DateTime.fromISO(campaignDetails.startDate)
    const currentWeekStart = DateTime.fromISO(d.date)
    const currentWeekEnd = currentWeekStart.plus({ weeks: 1 })

    if (startDateDateTime >= currentWeekStart && startDateDateTime < currentWeekEnd) {
      return 'Launch'
    }
    return null
  })

  const { labels } = metrics

  const barImagePlugin = {
    id: 'barImagePlugin',
    afterDraw: (chart: any) => {
      const dates = visibleDataSets?.[0]?.rawData.map(d => DateTime.fromISO(d.date)) || []
      campaignImagePlugin(campaignDetails, dates, chart, true)
    }
  }

  const weeklyLabelsPlugin = {
    id: "weeklyLabelsPlugin",
    beforeDraw(chart: any) {
      if (!chart.scales["x"]) return;
      subLabelsPlugin(labels, weeklyTagLabels || [], chart, theme)
    },
  };

  const graphBottomPadding = (() => {
    if (labels.length > 8) {
      return 100
    } else {
      return 60
    }
  })()

  return (
    <Bar
      data-cy="benchmark-weekly-graph"
      key={`${labels?.length}-${Object.keys(benchmarkData).join('-')}`}
      data={{
        labels: labels,
        datasets: visibleDataSets?.map((d, index) => ({
          ...d,
          borderRadius: 3
        })) || []
      }}
      height={500}
      plugins={[barImagePlugin, weeklyLabelsPlugin]}
      options={{
        layout: {
          padding: {
            bottom: graphBottomPadding
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            ...defaultTooltipOptions,
            callbacks: {
              label: item => {
                return item.formattedValue
              },
              title: () => '',
              footer: () => ''
            }
          }
        },
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              drawOnChartArea: true,
              drawTicks: false,
              color: theme.palette.grey.A400,
              lineWidth: 1.5
            },
            ticks: {
              color: 'black',
              padding: 16,
              font: {
                family: theme.typography.fontFamily as string,
                size: theme.typography.label1.fontSize as number
              }
            },
          },
          x: {
            grid: {
              drawTicks: false,
            },
            ticks: {
              display: false,
              source: 'labels',
              minRotation: 60,
              padding: 16,
              font: {
                family: theme.typography.fontFamily as string,
                weight: theme.typography.fontWeightRegular as number,
                size: theme.typography.label1.fontSize as number
              }
            },
          }
        }
    }}
  />
  )
}

const aggregateData = (metrics: EtailerSkuMetrics[], skuId: string) => {
  if (!metrics.length) return []

  const startDate = DateTime.fromISO(metrics[0].date)
  const endDate = DateTime.fromISO(last(metrics)?.date || DateTime.now().toISO())
  const weeks = Math.ceil(endDate.diff(startDate, 'weeks').weeks || 1)
  const weeksArray = new Array(weeks).fill(0)

  return weeksArray.map((_, index) => {
    const weekStart = startDate.plus({ weeks: index })
    const weekEnd = weekStart.plus({ weeks: 1 })
    const weekRangeData = metrics.filter(m => {
      const date = DateTime.fromISO(m.date)
      return date >= weekStart && date < weekEnd
    })

    return {
      skuId,
      date: weekStart.toISODate()!,
      unitsSold: sumBy(weekRangeData, m => m.unitsSold || 0),
      totalSalesAmount: sumBy(weekRangeData, m => m.totalSalesAmount || 0),
      pageViews: sumBy(weekRangeData, m => m.pageViews || 0)
    }
  })
}
