import { DateTime } from 'luxon'
import { Dispatch, SetStateAction, createContext, useContext } from 'react'
import { EtailerProductMetric } from '@momentum/routes/campaign/context/queries'
import { SkuSelectionType, ReportType } from '@momentum/routes/campaign/context/CampaignContext'

export enum SalesReportView {
  REVENUE = 'revenue',
  UNITS_SOLD = 'units sold'
}

export type SalesData = {
  date: DateTime
  value: number
}

export type SalesContextType = {
  isIntegrated: boolean
  salesData: SalesData[]
  filteredSalesData: SalesData[]
  minDate: DateTime
  maxDate: DateTime
  startDateFilter?: DateTime
  setStartDateFilter: Dispatch<SetStateAction<DateTime | undefined>>
  endDateFilter?: DateTime
  setEndDateFilter: Dispatch<SetStateAction<DateTime | undefined>>
  salesReportView: SalesReportView
  setSalesReportView: Dispatch<SetStateAction<SalesReportView>>
  salesReportType: ReportType
  setSalesReportType: Dispatch<SetStateAction<ReportType>>
  salesSkuType: SkuSelectionType
  setSalesSkuType: Dispatch<SetStateAction<SkuSelectionType>>
  salesMetricMap: Record<string, EtailerProductMetric[]>
}

const SalesContext = createContext<SalesContextType>({
  isIntegrated: false,
  salesData: [],
  filteredSalesData: [],
  minDate: DateTime.now(),
  maxDate: DateTime.now(),
  startDateFilter: undefined,
  setStartDateFilter: () => {},
  endDateFilter: undefined,
  setEndDateFilter: () => {},
  salesReportView: SalesReportView.UNITS_SOLD,
  setSalesReportView: () => {},
  salesReportType: ReportType.CUMULATIVE,
  setSalesReportType: () => {},
  salesSkuType: SkuSelectionType.SELECTED_VARIATIONS,
  setSalesSkuType: () => {},
  salesMetricMap: {}
})

SalesContext.displayName = 'SalesContext'

export default SalesContext

export const useSalesContext = () => useContext(SalesContext)
