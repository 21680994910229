import Loading from '@momentum/components/loading'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { ReportType, SkuSelectionType } from '@momentum/routes/campaign/context/CampaignContext'
import { EtailerProductMetric, getEtailerProductMetrics } from '@momentum/routes/campaign/context/queries'
import { Summary } from '@momentum/routes/campaign/e-commerce/sales/Summary'
import { SalesGraph } from '@momentum/routes/campaign/e-commerce/sales/sales-graph'
import { SalesTable } from '@momentum/routes/campaign/e-commerce/sales/sales-table'
import SalesContext, { SalesData, SalesReportView } from '@momentum/routes/campaign/e-commerce/sales/salesContext'
import { Paper, Stack, Typography } from '@mui/material'
import { notEmpty } from '@productwindtom/shared-node'
import { max, min, orderBy, sumBy } from 'lodash'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { useCampaignContext } from '../../context/CampaignContext'
import { EmptyState } from '../common/EmptyState'
import { isWithinPeriod } from '../common/utils'
import { PeriodSelector } from './PeriodSelector'
import SalesReportViewSelector from './SalesReportViewSelector'
import { SalesSkuSelector } from './SalesSkuSelector'
import { SAMPLE_CAMPAIGN_SALES } from './sample-data'
import EmptyStateImage from '/images/empty-states/sales.png'

export const Sales = () => {
  const [salesReportView, setSalesReportView] = useState(SalesReportView.UNITS_SOLD)
  const [salesReportType, setSalesReportType] = useState(ReportType.CUMULATIVE)
  const { isAdminView } = useUserSessionContext()
  const { brand } = useBrandContext()
  const { campaignDetails, selectedVariants } = useCampaignContext()
  const [salesSkuType, setSalesSkuType] = useState(SkuSelectionType.SELECTED_VARIATIONS)
  const [salesMetricMap, setSalesMetricMap] = useState<Record<string, EtailerProductMetric[]>>({})
  const { startDate, endDate } = campaignDetails
  const [isLoading, setIsLoading] = useState(true)
  const minMetricsDate = min([DateTime.now(), DateTime.fromISO(startDate).minus({ weeks: 3 })])!
  const yesterday = DateTime.now().minus({ days: 1 })
  const maxMetricsDate = endDate ? min([DateTime.fromISO(endDate).plus({ weeks: 3 }), yesterday])! : yesterday
  const [startDateFilter, setStartDateFilter] = useState<DateTime | undefined>()
  const [endDateFilter, setEndDateFilter] = useState<DateTime | undefined>()
  const [minDate, setMinDate] = useState<DateTime>(DateTime.now())
  const [maxDate, setMaxDate] = useState<DateTime>(DateTime.now())

  let salesData: SalesData[]

  const getAllSkuIds = (): string[] => {
    const product = campaignDetails.product
    if (product.parentSkuId) {
      return product.parentSku?.childSkus?.items?.map(sku => sku.id) ?? [product.id]
    } else if (product.childSkus?.items?.length > 0) {
      return [product.id, ...(product.childSkus?.items?.map(sku => sku.id) ?? [])]
    } else {
      return [product.id]
    }
  }

  const allSkuIds = getAllSkuIds()
  const selectedVariantsSkuIds = selectedVariants

  useEffect(() => {
    if (Object.keys(salesMetricMap).length === 0) {
      Promise.all(
        allSkuIds.map(skuId =>
          getEtailerProductMetrics(skuId, minMetricsDate.toISODate()!, maxMetricsDate.toISODate()!).then(metrics => ({
            skuId,
            metrics
          }))
        )
      ).then(results => {
        setSalesMetricMap(
          results.reduce((acc: Record<string, EtailerProductMetric[]>, { skuId, metrics }) => {
            acc[skuId] = metrics
            return acc
          }, {})
        )
        setIsLoading(false)
      })
    }
  }, [allSkuIds])

  const getSampleSalesData = () => {
    const data =
      salesSkuType === SkuSelectionType.SELECTED_VARIATIONS
        ? SAMPLE_CAMPAIGN_SALES[campaignDetails.id]['selected-variants']
        : SAMPLE_CAMPAIGN_SALES[campaignDetails.id]['all-variants']

    if (salesReportView === SalesReportView.REVENUE) {
      return data.map(d => ({
        ...d,
        value: d.value * (campaignDetails.product.priceCents || 1)
      }))
    }

    return data
  }

  useEffect(() => {
    const dates = SAMPLE_CAMPAIGN_SALES[campaignDetails.id]
      ? getSampleSalesData().map(d => DateTime.fromISO(d.date.toISODate()!))
      : Object.values(salesMetricMap).flatMap(metrics => metrics.map(m => DateTime.fromISO(m.date)))

    const minDate = min(dates)! || DateTime.now()
    const maxDate = max(dates)! || DateTime.now()

    setMinDate(minDate)
    setMaxDate(maxDate)
    setStartDateFilter(minDate)
    setEndDateFilter(maxDate)
  }, [salesSkuType, salesMetricMap])

  const getMetricsFromSkuIds = (date: string | null, skuIds: (string | undefined)[]) => {
    return skuIds
      .filter(notEmpty)
      .map(skuId => salesMetricMap[skuId]?.find(d => d.date === date))
      .filter(Boolean)
      .reduce(
        (acc, curr) => ({
          totalSalesAmount: (acc?.totalSalesAmount || 0) + (curr?.totalSalesAmount || 0),
          unitsSold: (acc?.unitsSold || 0) + (curr?.unitsSold || 0)
        }),
        { totalSalesAmount: 0, unitsSold: 0 }
      )
  }

  if (SAMPLE_CAMPAIGN_SALES[campaignDetails.id]) {
    salesData = getSampleSalesData()
  } else {
    const days = Math.ceil(maxDate.diff(minDate, 'days').days) + 1
    const daysArray = new Array(days).fill(0)
    salesData = daysArray
      .map((_, index) => {
        const date = minDate.plus({ days: index })
        const dateString = date.toISODate()
        const momentumPurchases = campaignDetails.creators
          .map(c => c.purchaseDetails)
          .filter(notEmpty)
          .filter(p => DateTime.fromISO(p.purchaseDate).toISODate() === dateString)

        const metrics =
          salesSkuType === SkuSelectionType.SELECTED_VARIATIONS
            ? getMetricsFromSkuIds(dateString, selectedVariantsSkuIds)
            : getMetricsFromSkuIds(dateString, allSkuIds)
        const revenueFromMomentum = sumBy(momentumPurchases, p => p.amountCents)
        const value =
          salesReportView === SalesReportView.REVENUE
            ? metrics?.totalSalesAmount ?? revenueFromMomentum
            : metrics?.unitsSold ?? momentumPurchases.length

        return { date, value }
      })
      .filter(d => isWithinPeriod(d.date.toISODate()!, minDate.toISODate()!, maxDate.toISODate()!))
  }

  const filteredSalesData = orderBy(
    salesData.filter(
      d => (!startDateFilter || d.date >= startDateFilter) && (!endDateFilter || d.date <= endDateFilter)
    ),
    'date'
  )

  const integrated = brand.brandApis.find(api => api.enabled && api.isIntegrated)

  const multipleVariants = allSkuIds.length > selectedVariantsSkuIds.length

  if (!isAdminView && !integrated) {
    return (
      <EmptyState
        image={EmptyStateImage}
        title="Your sales analytics will live here"
        description="Link your Amazon Seller account to access real-time sales analytics."
        linkAccountButton={true}
      />
    )
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <SalesContext.Provider
      value={{
        isIntegrated: !!integrated,
        salesData,
        filteredSalesData,
        minDate,
        maxDate,
        startDateFilter,
        setStartDateFilter,
        endDateFilter,
        setEndDateFilter,
        salesReportView,
        setSalesReportView,
        salesReportType,
        setSalesReportType,
        salesSkuType,
        setSalesSkuType,
        salesMetricMap
      }}
    >
      <Stack spacing={3}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} gap={2}>
          <Typography variant="h6" fontWeight={'bold'}>
            Campaign sales summary
          </Typography>
          <SalesReportViewSelector />
        </Stack>
        {multipleVariants && <SalesSkuSelector />}
        <PeriodSelector />
        <Paper sx={{ p: 3, backgroundColor: 'grey.A100' }}>
          <Stack direction={'column'} spacing={3}>
            <Summary />
            <SalesGraph />
            <SalesTable />
          </Stack>
        </Paper>
      </Stack>
    </SalesContext.Provider>
  )
}
