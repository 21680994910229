import { BenchmarkProductsInput } from '@momentum/components/proposal-common/BenchmarkProductsInput'
import { MultiTextListInput } from '@momentum/components/proposal-common/MultiTextListInput'
import { Question } from '@momentum/components/proposal-common/Question'
import { CreatorScheduleInput } from '@momentum/routes/proposals-create/timeline/CreatorScheduleInput'
import { ProposalCreateForm } from '@momentum/routes/proposals-create/types'
import { timelineFormValidationSchema } from '@momentum/routes/proposals-create/validations'
import { ROUTE_NAMES_PROPOSAL } from '@momentum/routes/RouteNames'
import { Button, Link, Stack, Typography, Box } from '@mui/material'
import { CUSTOM_FEEDBACK_RESPONSE_COST } from '@productwindtom/shared-momentum'
import { PricingCreatorType, ProposalGoal } from '@productwindtom/shared-momentum-zeus-types'
import { useFlag } from '@unleash/proxy-client-react'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { useCreateProposalContext } from '../context/CreateProposalContext'
import { CustomFeedbackQuestionsInput } from './CustomFeedbackQuestionsInput'
import { BOX_PROPS } from '@momentum/routes/proposals-create/common/styling'
import { SaveNextButton } from '@momentum/routes/proposals-create/common/SaveNextButton'

export const Timeline = () => {
  const { getValues, setValue, watch } = useFormContext<ProposalCreateForm>()
  const { selectedProduct } = useCreateProposalContext()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const marketResearchTypeFlag = useFlag('MarketResearchType')

  useEffect(() => {
    if (!selectedProduct) {
      navigate([...pathname.split('/').slice(0, -1), ROUTE_NAMES_PROPOSAL.PRODUCT_CREATORS].join('/'), {
        replace: true
      })
    }
  }, [])

  const creatorPricing = getValues('creatorPricing')
  const goal = getValues('goal')

  const numBrandAdvocates = creatorPricing.find(c => c.type === PricingCreatorType.ADVOCATE)?.numCreators ?? 0

  const searchTerms = watch('searchTerms')

  return (
    <Stack spacing={2}>
      <Box {...BOX_PROPS}>
        <Question
          primaryText={'Review creator schedule'}
          subtext={
            'Creators will join your campaign according to the schedule below. The recommended schedule is shown below.'
          }
        >
          <CreatorScheduleInput />
        </Question>
      </Box>
      {marketResearchTypeFlag && numBrandAdvocates > 0 && (
        <Box {...BOX_PROPS}>
          <Question
            primaryText={`What questions would you like to ask your brand advocate creators? ${goal !== ProposalGoal.MARKET_RESEARCH ? '(optional)' : ''}`}
            subtext={
              <Typography color={'black'} variant={'body2'}>
                Ask your brand advocates additional customized questions to get early feedback from consumers.{' '}
                <Typography
                  component={'span'}
                  variant={'body2'}
                  sx={{
                    fontWeight: 800
                  }}
                >
                  We charge {CUSTOM_FEEDBACK_RESPONSE_COST} credits per brand advocate.{' '}
                </Typography>
                <Link
                  variant={'link2'}
                  target="_blank"
                  href="https://info.productwind.com/what-is-the-custom-feedback-feature"
                  underline={'none'}
                  sx={{ cursor: 'pointer', display: 'inline' }}
                >
                  Learn more
                </Link>
              </Typography>
            }
          >
            <CustomFeedbackQuestionsInput />
          </Question>
        </Box>
      )}
      <Box {...BOX_PROPS}>
        <Question
          primaryText={'What search terms are you tracking for this product? (optional)'}
          subtext={
            'Momentum will track your product across several search terms. We will include your search term when building our list.'
          }
        >
          <MultiTextListInput
            name={'searchTerms'}
            addText={'Add search term'}
            placeholder={'Enter search term'}
            max={5}
            validate={value => {
              return searchTerms?.map(s => s.trim()).includes(value?.trim())
                ? 'This search term has already been added! Please enter a new search term.'
                : undefined
            }}
          />
        </Question>
      </Box>
      <Box {...BOX_PROPS}>
        <Question
          primaryText={'What products are you benchmarking against? (optional)'}
          subtext={
            'Momentum will benchmark your product against other relevant products for traffic, sales, and revenue. Add up to 3 relevant products.'
          }
        >
          <BenchmarkProductsInput
            name={'benchmarkProducts'}
            addText={'Add product'}
            placeholder={selectedProduct?.store?.includes('amazon') ? 'Enter product URL or ASIN' : 'Enter product URL'}
            storeName={selectedProduct?.store}
            max={3}
          />
        </Question>
      </Box>
      <NavigateButtons />
    </Stack>
  )
}

const NavigateButtons = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { watch } = useFormContext<ProposalCreateForm>()
  const data = watch()

  const handleBack = () => {
    navigate([...pathname.split('/').slice(0, -1), ROUTE_NAMES_PROPOSAL.PRODUCT_CREATORS].join('/'), { replace: true })
  }

  return (
    <Stack direction={'row'} spacing={1} justifyContent={'flex-end'} pb={2}>
      <Button variant={'text'} onClick={handleBack} data-cy="goBackButton">
        Go back
      </Button>
      <SaveNextButton
        validationSchema={timelineFormValidationSchema}
        disabled={data.goal === ProposalGoal.MARKET_RESEARCH && !data.customFeedbackQuestions?.length}
        nextPath={ROUTE_NAMES_PROPOSAL.REVIEW}
      />
    </Stack>
  )
}
