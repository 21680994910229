// @ts-nocheck
import { SAMPLE_CAMPAIGN_WIRELESS_EARBUDS } from '@momentum/api/interceptors/getCampaign/sample-data/wireless-earbuds'
import { CampaignPerformance } from '@momentum/routes/campaign/e-commerce/performance/queries'
import { PerformanceComparisonMetric, PerformanceSummaryTitleType } from '@productwindtom/shared-momentum-zeus-types'

export const SAMPLE_CAMPAIGN_PERFORMANCE_WIRELESS_EARBUDS: CampaignPerformance = {
  campaignId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id,
  skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId,
  promotedProductId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId,
  isVisible: false,
  isTtrVisible: true,

  comparisonMetric: PerformanceComparisonMetric.BEST_SELLER_RANK,
  comparisonMetricValue: 50,
  summaryTitle: PerformanceSummaryTitleType.SIMILAR_PRODUCT,

  products: [
    {
      id: 'sample-sku-wireless-earbuds',
      productName: 'Wireless Earbuds',
      productUrl: 'https://www.amazon.com',
      productImage: 'https://media.productwind.com/sample/images/sample-sku-wireless-earbuds.png',
      comparisonMetric: 'BEST_SELLER_RANK',
      productPerformanceRecords: [
        {
          date: '2025-01-24',
          reviewCount: null,
          rating: null,
          salesRank: 274468
        },
        {
          date: '2025-01-25',
          reviewCount: null,
          rating: null,
          salesRank: 254713
        },
        {
          date: '2025-01-26',
          reviewCount: null,
          rating: null,
          salesRank: 222985
        },
        {
          date: '2025-01-27',
          reviewCount: null,
          rating: null,
          salesRank: 142429
        },
        {
          date: '2025-01-28',
          reviewCount: null,
          rating: null,
          salesRank: 189530
        },
        {
          date: '2025-01-29',
          reviewCount: null,
          rating: null,
          salesRank: 190212
        },
        {
          date: '2025-01-30',
          reviewCount: null,
          rating: null,
          salesRank: 313937
        },
        {
          date: '2025-01-31',
          reviewCount: null,
          rating: null,
          salesRank: 358705
        },
        {
          date: '2025-02-01',
          reviewCount: null,
          rating: null,
          salesRank: 364641
        },
        {
          date: '2025-02-02',
          reviewCount: null,
          rating: null,
          salesRank: 274808
        },
        {
          date: '2025-02-03',
          reviewCount: null,
          rating: null,
          salesRank: 111587
        },
        {
          date: '2025-02-04',
          reviewCount: null,
          rating: null,
          salesRank: 111366
        },
        {
          date: '2025-02-05',
          reviewCount: 3,
          rating: 3,
          salesRank: 92492
        },
        {
          date: '2025-02-06',
          reviewCount: 3,
          rating: 3,
          salesRank: 50585
        },
        {
          date: '2025-02-07',
          reviewCount: 3,
          rating: 3,
          salesRank: 65004
        },
        {
          date: '2025-02-08',
          reviewCount: 3,
          rating: 3,
          salesRank: 70713
        },
        {
          date: '2025-02-09',
          reviewCount: 3,
          rating: 3,
          salesRank: 39998
        },
        {
          date: '2025-02-10',
          reviewCount: 3,
          rating: 3,
          salesRank: 13343
        },
        {
          date: '2025-02-11',
          reviewCount: 5,
          rating: 3,
          salesRank: 29929
        },
        {
          date: '2025-02-12',
          reviewCount: 5,
          rating: 3,
          salesRank: 23163
        },
        {
          date: '2025-02-13',
          reviewCount: 5,
          rating: 3,
          salesRank: 29931
        },
        {
          date: '2025-02-14',
          reviewCount: 5,
          rating: 3,
          salesRank: 38650
        },
        {
          date: '2025-02-15',
          reviewCount: 5,
          rating: 3,
          salesRank: 36698
        },
        {
          date: '2025-02-16',
          reviewCount: 20,
          rating: 4,
          salesRank: 34216
        },
        {
          date: '2025-02-17',
          reviewCount: 20,
          rating: 4,
          salesRank: 14548
        },
        {
          date: '2025-02-18',
          reviewCount: 20,
          rating: 4,
          salesRank: 11805
        },
        {
          date: '2025-02-19',
          reviewCount: 20,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-20',
          reviewCount: 20,
          rating: 4,
          salesRank: 15685
        },
        {
          date: '2025-02-21',
          reviewCount: 20,
          rating: 4,
          salesRank: 14680
        },
        {
          date: '2025-02-22',
          reviewCount: 48,
          rating: 4,
          salesRank: 18357
        },
        {
          date: '2025-02-23',
          reviewCount: 48,
          rating: 4,
          salesRank: 16537
        },
        {
          date: '2025-02-24',
          reviewCount: 48,
          rating: 4,
          salesRank: 27543
        },
        {
          date: '2025-02-25',
          reviewCount: 48,
          rating: 4,
          salesRank: 30158
        },
        {
          date: '2025-02-26',
          reviewCount: 48,
          rating: 4,
          salesRank: 25449
        },
        {
          date: '2025-02-27',
          reviewCount: 67,
          rating: 4,
          salesRank: 26912
        },
        {
          date: '2025-02-28',
          reviewCount: 67,
          rating: 4,
          salesRank: 22801
        },
        {
          date: '2025-03-01',
          reviewCount: 67,
          rating: 4,
          salesRank: 24315
        },
        {
          date: '2025-03-02',
          reviewCount: 67,
          rating: 4,
          salesRank: 21798
        },
        {
          date: '2025-03-03',
          reviewCount: 67,
          rating: 4,
          salesRank: 21290
        },
        {
          date: '2025-03-04',
          reviewCount: 85,
          rating: 4,
          salesRank: 16069
        },
        {
          date: '2025-03-05',
          reviewCount: 85,
          rating: 4,
          salesRank: 24161
        },
        {
          date: '2025-03-06',
          reviewCount: 85,
          rating: 4,
          salesRank: 18746
        },
        {
          date: '2025-03-07',
          reviewCount: 85,
          rating: 4,
          salesRank: 15862
        },
        {
          date: '2025-03-08',
          reviewCount: 85,
          rating: 4,
          salesRank: 18579
        },
        {
          date: '2025-03-09',
          reviewCount: 103,
          rating: 4,
          salesRank: 18649
        },
        {
          date: '2025-03-10',
          reviewCount: 103,
          rating: 4,
          salesRank: 20536
        },
        {
          date: '2025-03-11',
          reviewCount: 103,
          rating: 4,
          salesRank: 23539
        },
        {
          date: '2025-03-12',
          reviewCount: 103,
          rating: 4,
          salesRank: 23010
        },
        {
          date: '2025-03-13',
          reviewCount: 103,
          rating: 4,
          salesRank: 34753
        },
        {
          date: '2025-03-14',
          reviewCount: 103,
          rating: 4,
          salesRank: 47812
        },
        {
          date: '2025-03-15',
          reviewCount: 124,
          rating: 4,
          salesRank: 37682
        },
        {
          date: '2025-03-16',
          reviewCount: 124,
          rating: 4,
          salesRank: 34188
        },
        {
          date: '2025-03-17',
          reviewCount: 124,
          rating: 4,
          salesRank: 26411
        },
        {
          date: '2025-03-18',
          reviewCount: null,
          rating: null,
          salesRank: 31964
        }
      ],
      reviewDataUploaded: true,
      bestSellerRankDataUploaded: true,
      isVisible: true,
      isCategoryAverage: false,
      createdAt: '2025-02-17T16:27:56.498Z'
    },
    {
      id: 'ef977bea-87d4-4c54-8b65-f701c972b257',
      productName: 'In Ear Earbud Headphone',
      productUrl: 'https://www.amazon.com',
      productImage: 'https://media.productwind.com/sample/images/sample-sku-wireless-earbuds-3.png',
      comparisonMetric: 'BEST_SELLER_RANK',
      productPerformanceRecords: [
        {
          date: '2023-09-18',
          reviewCount: null,
          rating: null,
          salesRank: 1041071
        },
        {
          date: '2023-09-19',
          reviewCount: null,
          rating: null,
          salesRank: 1231450
        },
        {
          date: '2023-09-20',
          reviewCount: null,
          rating: null,
          salesRank: 1433411
        },
        {
          date: '2023-09-21',
          reviewCount: null,
          rating: null,
          salesRank: 1669634
        },
        {
          date: '2023-09-22',
          reviewCount: null,
          rating: null,
          salesRank: 1876430
        },
        {
          date: '2023-09-23',
          reviewCount: null,
          rating: null,
          salesRank: 2064616
        },
        {
          date: '2023-09-24',
          reviewCount: null,
          rating: null,
          salesRank: 2228428
        },
        {
          date: '2023-09-25',
          reviewCount: null,
          rating: null,
          salesRank: 2370588
        },
        {
          date: '2023-09-26',
          reviewCount: null,
          rating: null,
          salesRank: 2485392
        },
        {
          date: '2023-09-27',
          reviewCount: null,
          rating: null,
          salesRank: 2584178
        },
        {
          date: '2023-09-28',
          reviewCount: null,
          rating: null,
          salesRank: 2699910
        },
        {
          date: '2023-09-29',
          reviewCount: null,
          rating: null,
          salesRank: 2785184
        },
        {
          date: '2023-09-30',
          reviewCount: null,
          rating: null,
          salesRank: 2859868
        },
        {
          date: '2023-10-01',
          reviewCount: null,
          rating: null,
          salesRank: 663246
        },
        {
          date: '2023-10-02',
          reviewCount: null,
          rating: null,
          salesRank: 218912
        },
        {
          date: '2023-10-03',
          reviewCount: null,
          rating: null,
          salesRank: 348061
        },
        {
          date: '2023-10-04',
          reviewCount: null,
          rating: null,
          salesRank: 506943
        },
        {
          date: '2023-10-05',
          reviewCount: null,
          rating: null,
          salesRank: 612368
        },
        {
          date: '2023-10-06',
          reviewCount: null,
          rating: null,
          salesRank: 190805
        },
        {
          date: '2023-10-07',
          reviewCount: null,
          rating: null,
          salesRank: 302968
        },
        {
          date: '2023-10-08',
          reviewCount: null,
          rating: null,
          salesRank: 82519
        },
        {
          date: '2023-10-09',
          reviewCount: null,
          rating: null,
          salesRank: 98490
        },
        {
          date: '2023-10-10',
          reviewCount: null,
          rating: null,
          salesRank: 43462
        },
        {
          date: '2023-10-11',
          reviewCount: null,
          rating: null,
          salesRank: 47808
        },
        {
          date: '2023-10-12',
          reviewCount: null,
          rating: null,
          salesRank: 53411
        },
        {
          date: '2023-10-13',
          reviewCount: null,
          rating: null,
          salesRank: 43002
        },
        {
          date: '2023-10-14',
          reviewCount: null,
          rating: null,
          salesRank: 30710
        },
        {
          date: '2023-10-15',
          reviewCount: null,
          rating: null,
          salesRank: 14755
        },
        {
          date: '2023-10-16',
          reviewCount: null,
          rating: null,
          salesRank: 12408
        },
        {
          date: '2023-10-17',
          reviewCount: null,
          rating: null,
          salesRank: 9329
        },
        {
          date: '2023-10-18',
          reviewCount: null,
          rating: null,
          salesRank: 5896
        },
        {
          date: '2023-10-19',
          reviewCount: null,
          rating: null,
          salesRank: 6845
        },
        {
          date: '2023-10-20',
          reviewCount: null,
          rating: null,
          salesRank: 9219
        },
        {
          date: '2023-10-21',
          reviewCount: null,
          rating: null,
          salesRank: 11192
        },
        {
          date: '2023-10-22',
          reviewCount: null,
          rating: null,
          salesRank: 9431
        },
        {
          date: '2023-10-23',
          reviewCount: null,
          rating: null,
          salesRank: 6662
        },
        {
          date: '2023-10-24',
          reviewCount: null,
          rating: null,
          salesRank: 7017
        },
        {
          date: '2023-10-25',
          reviewCount: null,
          rating: null,
          salesRank: 6672
        },
        {
          date: '2023-10-26',
          reviewCount: null,
          rating: null,
          salesRank: 6405
        },
        {
          date: '2023-10-27',
          reviewCount: null,
          rating: null,
          salesRank: 6496
        },
        {
          date: '2023-10-30',
          reviewCount: null,
          rating: null,
          salesRank: 8796
        },
        {
          date: '2023-10-31',
          reviewCount: null,
          rating: null,
          salesRank: 8748
        },
        {
          date: '2023-11-01',
          reviewCount: null,
          rating: null,
          salesRank: 8353
        },
        {
          date: '2023-11-02',
          reviewCount: null,
          rating: null,
          salesRank: 13764
        },
        {
          date: '2023-11-03',
          reviewCount: null,
          rating: null,
          salesRank: 15833
        },
        {
          date: '2023-11-04',
          reviewCount: null,
          rating: null,
          salesRank: 18277
        },
        {
          date: '2023-11-05',
          reviewCount: null,
          rating: null,
          salesRank: 27627
        },
        {
          date: '2023-11-06',
          reviewCount: null,
          rating: null,
          salesRank: 26066
        },
        {
          date: '2023-11-07',
          reviewCount: null,
          rating: null,
          salesRank: 25817
        },
        {
          date: '2023-11-08',
          reviewCount: null,
          rating: null,
          salesRank: 36446
        },
        {
          date: '2023-11-09',
          reviewCount: null,
          rating: null,
          salesRank: 42912
        },
        {
          date: '2023-11-10',
          reviewCount: null,
          rating: null,
          salesRank: 24313
        },
        {
          date: '2023-11-11',
          reviewCount: null,
          rating: null,
          salesRank: 18196
        },
        {
          date: '2023-11-12',
          reviewCount: null,
          rating: null,
          salesRank: 15326
        },
        {
          date: '2023-11-13',
          reviewCount: null,
          rating: null,
          salesRank: 20027
        },
        {
          date: '2023-11-14',
          reviewCount: null,
          rating: null,
          salesRank: 13047
        },
        {
          date: '2023-11-15',
          reviewCount: null,
          rating: null,
          salesRank: 12809
        },
        {
          date: '2023-11-16',
          reviewCount: null,
          rating: null,
          salesRank: 14333
        },
        {
          date: '2023-11-17',
          reviewCount: null,
          rating: null,
          salesRank: 11917
        },
        {
          date: '2023-11-18',
          reviewCount: null,
          rating: null,
          salesRank: 14904
        },
        {
          date: '2023-11-19',
          reviewCount: null,
          rating: null,
          salesRank: 16167
        },
        {
          date: '2023-11-20',
          reviewCount: null,
          rating: null,
          salesRank: 12448
        },
        {
          date: '2023-11-21',
          reviewCount: null,
          rating: null,
          salesRank: 9433
        },
        {
          date: '2023-11-22',
          reviewCount: null,
          rating: null,
          salesRank: 8715
        },
        {
          date: '2023-11-23',
          reviewCount: null,
          rating: null,
          salesRank: 9259
        },
        {
          date: '2023-11-24',
          reviewCount: null,
          rating: null,
          salesRank: 9649
        },
        {
          date: '2023-11-25',
          reviewCount: null,
          rating: null,
          salesRank: 8199
        },
        {
          date: '2023-11-26',
          reviewCount: null,
          rating: null,
          salesRank: 7342
        },
        {
          date: '2023-11-27',
          reviewCount: null,
          rating: null,
          salesRank: 6447
        },
        {
          date: '2023-11-28',
          reviewCount: null,
          rating: null,
          salesRank: 4518
        },
        {
          date: '2023-11-29',
          reviewCount: null,
          rating: null,
          salesRank: 3567
        },
        {
          date: '2023-11-30',
          reviewCount: null,
          rating: null,
          salesRank: 3584
        },
        {
          date: '2023-12-01',
          reviewCount: null,
          rating: null,
          salesRank: 4127
        },
        {
          date: '2023-12-02',
          reviewCount: null,
          rating: null,
          salesRank: 4852
        },
        {
          date: '2023-12-03',
          reviewCount: null,
          rating: null,
          salesRank: 5867
        },
        {
          date: '2023-12-04',
          reviewCount: null,
          rating: null,
          salesRank: 6876
        },
        {
          date: '2023-12-05',
          reviewCount: null,
          rating: null,
          salesRank: 8453
        },
        {
          date: '2023-12-06',
          reviewCount: null,
          rating: null,
          salesRank: 8213
        },
        {
          date: '2023-12-07',
          reviewCount: null,
          rating: null,
          salesRank: 11135
        },
        {
          date: '2023-12-08',
          reviewCount: null,
          rating: null,
          salesRank: 14069
        },
        {
          date: '2023-12-09',
          reviewCount: null,
          rating: null,
          salesRank: 12849
        },
        {
          date: '2023-12-10',
          reviewCount: null,
          rating: null,
          salesRank: 13071
        },
        {
          date: '2023-12-11',
          reviewCount: null,
          rating: null,
          salesRank: 9557
        },
        {
          date: '2023-12-12',
          reviewCount: null,
          rating: null,
          salesRank: 9694
        },
        {
          date: '2023-12-13',
          reviewCount: null,
          rating: null,
          salesRank: 9030
        },
        {
          date: '2023-12-14',
          reviewCount: null,
          rating: null,
          salesRank: 7523
        },
        {
          date: '2023-12-15',
          reviewCount: null,
          rating: null,
          salesRank: 6299
        },
        {
          date: '2023-12-16',
          reviewCount: null,
          rating: null,
          salesRank: 6744
        },
        {
          date: '2023-12-17',
          reviewCount: null,
          rating: null,
          salesRank: 7976
        },
        {
          date: '2023-12-18',
          reviewCount: null,
          rating: null,
          salesRank: 1771
        },
        {
          date: '2023-12-19',
          reviewCount: null,
          rating: null,
          salesRank: 1298
        },
        {
          date: '2023-12-20',
          reviewCount: null,
          rating: null,
          salesRank: 1075
        },
        {
          date: '2023-12-21',
          reviewCount: null,
          rating: null,
          salesRank: 973
        },
        {
          date: '2023-12-22',
          reviewCount: null,
          rating: null,
          salesRank: 660
        },
        {
          date: '2023-12-23',
          reviewCount: null,
          rating: null,
          salesRank: 895
        },
        {
          date: '2023-12-24',
          reviewCount: null,
          rating: null,
          salesRank: 1162
        },
        {
          date: '2023-12-25',
          reviewCount: null,
          rating: null,
          salesRank: 996
        },
        {
          date: '2023-12-26',
          reviewCount: null,
          rating: null,
          salesRank: 899
        },
        {
          date: '2023-12-27',
          reviewCount: null,
          rating: null,
          salesRank: 1209
        },
        {
          date: '2023-12-28',
          reviewCount: null,
          rating: null,
          salesRank: 1135
        },
        {
          date: '2023-12-29',
          reviewCount: null,
          rating: null,
          salesRank: 1152
        },
        {
          date: '2023-12-30',
          reviewCount: null,
          rating: null,
          salesRank: 816
        },
        {
          date: '2023-12-31',
          reviewCount: null,
          rating: null,
          salesRank: 818
        },
        {
          date: '2024-01-01',
          reviewCount: null,
          rating: null,
          salesRank: 2338
        },
        {
          date: '2024-01-02',
          reviewCount: null,
          rating: null,
          salesRank: 2251
        },
        {
          date: '2024-01-03',
          reviewCount: null,
          rating: null,
          salesRank: 2673
        },
        {
          date: '2024-01-04',
          reviewCount: null,
          rating: null,
          salesRank: 2761
        },
        {
          date: '2024-01-05',
          reviewCount: null,
          rating: null,
          salesRank: 2610
        },
        {
          date: '2024-01-06',
          reviewCount: null,
          rating: null,
          salesRank: 2859
        },
        {
          date: '2024-01-07',
          reviewCount: null,
          rating: null,
          salesRank: 3172
        },
        {
          date: '2024-01-08',
          reviewCount: null,
          rating: null,
          salesRank: 2964
        },
        {
          date: '2024-01-09',
          reviewCount: null,
          rating: null,
          salesRank: 2593
        },
        {
          date: '2024-01-10',
          reviewCount: null,
          rating: null,
          salesRank: 3061
        },
        {
          date: '2024-01-11',
          reviewCount: null,
          rating: null,
          salesRank: 2478
        },
        {
          date: '2024-01-12',
          reviewCount: null,
          rating: null,
          salesRank: 2182
        },
        {
          date: '2024-01-13',
          reviewCount: null,
          rating: null,
          salesRank: 2003
        },
        {
          date: '2024-01-14',
          reviewCount: null,
          rating: null,
          salesRank: 2314
        },
        {
          date: '2024-01-15',
          reviewCount: null,
          rating: null,
          salesRank: 1801
        },
        {
          date: '2024-01-16',
          reviewCount: null,
          rating: null,
          salesRank: 1574
        },
        {
          date: '2024-01-17',
          reviewCount: null,
          rating: null,
          salesRank: 1254
        },
        {
          date: '2024-01-18',
          reviewCount: null,
          rating: null,
          salesRank: 971
        },
        {
          date: '2024-01-19',
          reviewCount: null,
          rating: null,
          salesRank: 1121
        },
        {
          date: '2024-01-20',
          reviewCount: null,
          rating: null,
          salesRank: 1710
        },
        {
          date: '2024-01-21',
          reviewCount: null,
          rating: null,
          salesRank: 2265
        },
        {
          date: '2024-01-22',
          reviewCount: null,
          rating: null,
          salesRank: 2259
        },
        {
          date: '2024-01-23',
          reviewCount: null,
          rating: null,
          salesRank: 1921
        },
        {
          date: '2024-01-24',
          reviewCount: null,
          rating: null,
          salesRank: 2705
        },
        {
          date: '2024-01-25',
          reviewCount: null,
          rating: null,
          salesRank: 3597
        },
        {
          date: '2024-01-26',
          reviewCount: null,
          rating: null,
          salesRank: 4640
        },
        {
          date: '2024-01-27',
          reviewCount: null,
          rating: null,
          salesRank: 6135
        },
        {
          date: '2024-01-28',
          reviewCount: null,
          rating: null,
          salesRank: 7508
        },
        {
          date: '2024-01-29',
          reviewCount: null,
          rating: null,
          salesRank: 6074
        },
        {
          date: '2024-01-30',
          reviewCount: null,
          rating: null,
          salesRank: 4596
        },
        {
          date: '2024-01-31',
          reviewCount: null,
          rating: null,
          salesRank: 4204
        },
        {
          date: '2024-02-01',
          reviewCount: null,
          rating: null,
          salesRank: 3206
        },
        {
          date: '2024-02-02',
          reviewCount: null,
          rating: null,
          salesRank: 6523
        },
        {
          date: '2024-02-03',
          reviewCount: null,
          rating: null,
          salesRank: 4417
        },
        {
          date: '2024-02-04',
          reviewCount: null,
          rating: null,
          salesRank: 5049
        },
        {
          date: '2024-02-05',
          reviewCount: null,
          rating: null,
          salesRank: 5229
        },
        {
          date: '2024-02-06',
          reviewCount: null,
          rating: null,
          salesRank: 5198
        },
        {
          date: '2024-02-07',
          reviewCount: null,
          rating: null,
          salesRank: 5452
        },
        {
          date: '2024-02-08',
          reviewCount: null,
          rating: null,
          salesRank: 6101
        },
        {
          date: '2024-02-09',
          reviewCount: null,
          rating: null,
          salesRank: 9147
        },
        {
          date: '2024-02-10',
          reviewCount: null,
          rating: null,
          salesRank: 11752
        },
        {
          date: '2024-02-11',
          reviewCount: null,
          rating: null,
          salesRank: 9681
        },
        {
          date: '2024-02-12',
          reviewCount: null,
          rating: null,
          salesRank: 6987
        },
        {
          date: '2024-02-13',
          reviewCount: null,
          rating: null,
          salesRank: 5699
        },
        {
          date: '2024-02-14',
          reviewCount: null,
          rating: null,
          salesRank: 5194
        },
        {
          date: '2024-02-15',
          reviewCount: null,
          rating: null,
          salesRank: 5527
        },
        {
          date: '2024-02-16',
          reviewCount: null,
          rating: null,
          salesRank: 4803
        },
        {
          date: '2024-02-17',
          reviewCount: null,
          rating: null,
          salesRank: 6055
        },
        {
          date: '2024-02-18',
          reviewCount: null,
          rating: null,
          salesRank: 6724
        },
        {
          date: '2024-02-19',
          reviewCount: null,
          rating: null,
          salesRank: 6908
        },
        {
          date: '2024-02-20',
          reviewCount: null,
          rating: null,
          salesRank: 6253
        },
        {
          date: '2024-02-21',
          reviewCount: null,
          rating: null,
          salesRank: 6793
        },
        {
          date: '2024-02-22',
          reviewCount: null,
          rating: null,
          salesRank: 7329
        },
        {
          date: '2024-02-23',
          reviewCount: null,
          rating: null,
          salesRank: 7799
        },
        {
          date: '2024-02-24',
          reviewCount: null,
          rating: null,
          salesRank: 11143
        },
        {
          date: '2024-02-25',
          reviewCount: null,
          rating: null,
          salesRank: 15353
        },
        {
          date: '2024-02-26',
          reviewCount: null,
          rating: null,
          salesRank: 16283
        },
        {
          date: '2024-02-27',
          reviewCount: null,
          rating: null,
          salesRank: 11257
        },
        {
          date: '2024-02-28',
          reviewCount: null,
          rating: null,
          salesRank: 7723
        },
        {
          date: '2024-02-29',
          reviewCount: null,
          rating: null,
          salesRank: 7069
        },
        {
          date: '2024-03-01',
          reviewCount: null,
          rating: null,
          salesRank: 7364
        },
        {
          date: '2024-03-02',
          reviewCount: null,
          rating: null,
          salesRank: 3799
        },
        {
          date: '2024-03-03',
          reviewCount: null,
          rating: null,
          salesRank: 4721
        },
        {
          date: '2024-03-04',
          reviewCount: null,
          rating: null,
          salesRank: 5961
        },
        {
          date: '2024-03-05',
          reviewCount: null,
          rating: null,
          salesRank: 6034
        },
        {
          date: '2024-03-06',
          reviewCount: null,
          rating: null,
          salesRank: 4849
        },
        {
          date: '2024-03-07',
          reviewCount: null,
          rating: null,
          salesRank: 5069
        },
        {
          date: '2024-03-08',
          reviewCount: null,
          rating: null,
          salesRank: 5239
        },
        {
          date: '2024-03-09',
          reviewCount: null,
          rating: null,
          salesRank: 5717
        },
        {
          date: '2024-03-10',
          reviewCount: null,
          rating: null,
          salesRank: 6378
        },
        {
          date: '2024-03-11',
          reviewCount: null,
          rating: null,
          salesRank: 5800
        },
        {
          date: '2024-03-12',
          reviewCount: null,
          rating: null,
          salesRank: 4791
        },
        {
          date: '2024-03-13',
          reviewCount: null,
          rating: null,
          salesRank: 4922
        },
        {
          date: '2024-03-14',
          reviewCount: null,
          rating: null,
          salesRank: 5064
        },
        {
          date: '2024-03-15',
          reviewCount: null,
          rating: null,
          salesRank: 5303
        },
        {
          date: '2024-03-16',
          reviewCount: null,
          rating: null,
          salesRank: 8920
        },
        {
          date: '2024-03-17',
          reviewCount: null,
          rating: null,
          salesRank: 15340
        },
        {
          date: '2024-03-18',
          reviewCount: null,
          rating: null,
          salesRank: 12144
        },
        {
          date: '2024-03-19',
          reviewCount: null,
          rating: null,
          salesRank: 13939
        },
        {
          date: '2024-03-20',
          reviewCount: null,
          rating: null,
          salesRank: 10266
        },
        {
          date: '2024-03-21',
          reviewCount: null,
          rating: null,
          salesRank: 8241
        },
        {
          date: '2024-03-22',
          reviewCount: null,
          rating: null,
          salesRank: 10547
        },
        {
          date: '2024-03-23',
          reviewCount: null,
          rating: null,
          salesRank: 12196
        },
        {
          date: '2024-03-24',
          reviewCount: null,
          rating: null,
          salesRank: 11866
        },
        {
          date: '2024-03-25',
          reviewCount: null,
          rating: null,
          salesRank: 10079
        },
        {
          date: '2024-03-26',
          reviewCount: null,
          rating: null,
          salesRank: 8219
        },
        {
          date: '2024-03-27',
          reviewCount: null,
          rating: null,
          salesRank: 11216
        },
        {
          date: '2024-03-28',
          reviewCount: null,
          rating: null,
          salesRank: 11236
        },
        {
          date: '2024-03-29',
          reviewCount: null,
          rating: null,
          salesRank: 12051
        },
        {
          date: '2024-03-30',
          reviewCount: null,
          rating: null,
          salesRank: 8284
        },
        {
          date: '2024-03-31',
          reviewCount: null,
          rating: null,
          salesRank: 5356
        },
        {
          date: '2024-04-01',
          reviewCount: null,
          rating: null,
          salesRank: 6969
        },
        {
          date: '2024-04-02',
          reviewCount: null,
          rating: null,
          salesRank: 5573
        },
        {
          date: '2024-04-03',
          reviewCount: null,
          rating: null,
          salesRank: 5249
        },
        {
          date: '2024-04-04',
          reviewCount: null,
          rating: null,
          salesRank: 4686
        },
        {
          date: '2024-04-05',
          reviewCount: null,
          rating: null,
          salesRank: 4251
        },
        {
          date: '2024-04-06',
          reviewCount: null,
          rating: null,
          salesRank: 4057
        },
        {
          date: '2024-04-07',
          reviewCount: null,
          rating: null,
          salesRank: 5108
        },
        {
          date: '2024-04-08',
          reviewCount: null,
          rating: null,
          salesRank: 5405
        },
        {
          date: '2024-04-09',
          reviewCount: null,
          rating: null,
          salesRank: 5523
        },
        {
          date: '2024-04-10',
          reviewCount: null,
          rating: null,
          salesRank: 6341
        },
        {
          date: '2024-04-11',
          reviewCount: null,
          rating: null,
          salesRank: 7031
        },
        {
          date: '2024-04-12',
          reviewCount: null,
          rating: null,
          salesRank: 6560
        },
        {
          date: '2024-04-13',
          reviewCount: null,
          rating: null,
          salesRank: 9254
        },
        {
          date: '2024-04-14',
          reviewCount: null,
          rating: null,
          salesRank: 13678
        },
        {
          date: '2024-04-15',
          reviewCount: null,
          rating: null,
          salesRank: 17321
        },
        {
          date: '2024-04-16',
          reviewCount: null,
          rating: null,
          salesRank: 14186
        },
        {
          date: '2024-04-17',
          reviewCount: null,
          rating: null,
          salesRank: 10332
        },
        {
          date: '2024-04-18',
          reviewCount: null,
          rating: null,
          salesRank: 9553
        },
        {
          date: '2024-04-19',
          reviewCount: null,
          rating: null,
          salesRank: 12143
        },
        {
          date: '2024-04-20',
          reviewCount: null,
          rating: null,
          salesRank: 11205
        },
        {
          date: '2024-04-21',
          reviewCount: null,
          rating: null,
          salesRank: 10988
        },
        {
          date: '2024-04-22',
          reviewCount: null,
          rating: null,
          salesRank: 12455
        },
        {
          date: '2024-04-23',
          reviewCount: null,
          rating: null,
          salesRank: 10725
        },
        {
          date: '2024-04-24',
          reviewCount: null,
          rating: null,
          salesRank: 10370
        },
        {
          date: '2024-04-25',
          reviewCount: null,
          rating: null,
          salesRank: 10297
        },
        {
          date: '2024-04-26',
          reviewCount: null,
          rating: null,
          salesRank: 12160
        },
        {
          date: '2024-04-27',
          reviewCount: null,
          rating: null,
          salesRank: 11683
        },
        {
          date: '2024-04-28',
          reviewCount: null,
          rating: null,
          salesRank: 13887
        },
        {
          date: '2024-04-29',
          reviewCount: null,
          rating: null,
          salesRank: 12982
        },
        {
          date: '2024-04-30',
          reviewCount: null,
          rating: null,
          salesRank: 12468
        },
        {
          date: '2024-05-01',
          reviewCount: null,
          rating: null,
          salesRank: 11602
        },
        {
          date: '2024-05-02',
          reviewCount: null,
          rating: null,
          salesRank: 10985
        },
        {
          date: '2024-05-03',
          reviewCount: null,
          rating: null,
          salesRank: 9853
        },
        {
          date: '2024-05-04',
          reviewCount: null,
          rating: null,
          salesRank: 11319
        },
        {
          date: '2024-05-05',
          reviewCount: null,
          rating: null,
          salesRank: 11150
        },
        {
          date: '2024-05-06',
          reviewCount: null,
          rating: null,
          salesRank: 12110
        },
        {
          date: '2024-05-07',
          reviewCount: null,
          rating: null,
          salesRank: 10841
        },
        {
          date: '2024-05-08',
          reviewCount: null,
          rating: null,
          salesRank: 9709
        },
        {
          date: '2024-05-09',
          reviewCount: null,
          rating: null,
          salesRank: 11515
        },
        {
          date: '2024-05-10',
          reviewCount: null,
          rating: null,
          salesRank: 11209
        },
        {
          date: '2024-05-11',
          reviewCount: null,
          rating: null,
          salesRank: 15464
        },
        {
          date: '2024-05-12',
          reviewCount: null,
          rating: null,
          salesRank: 23156
        },
        {
          date: '2024-05-13',
          reviewCount: null,
          rating: null,
          salesRank: 21369
        },
        {
          date: '2024-05-14',
          reviewCount: null,
          rating: null,
          salesRank: 16894
        },
        {
          date: '2024-05-15',
          reviewCount: null,
          rating: null,
          salesRank: 17868
        },
        {
          date: '2024-05-16',
          reviewCount: null,
          rating: null,
          salesRank: 16617
        },
        {
          date: '2024-05-17',
          reviewCount: null,
          rating: null,
          salesRank: 16043
        },
        {
          date: '2024-05-18',
          reviewCount: null,
          rating: null,
          salesRank: 16549
        },
        {
          date: '2024-05-19',
          reviewCount: null,
          rating: null,
          salesRank: 19230
        },
        {
          date: '2024-05-20',
          reviewCount: null,
          rating: null,
          salesRank: 17324
        },
        {
          date: '2024-05-21',
          reviewCount: null,
          rating: null,
          salesRank: 19235
        },
        {
          date: '2024-05-22',
          reviewCount: null,
          rating: null,
          salesRank: 20822
        },
        {
          date: '2024-05-23',
          reviewCount: null,
          rating: null,
          salesRank: 20478
        },
        {
          date: '2024-05-24',
          reviewCount: null,
          rating: null,
          salesRank: 18371
        },
        {
          date: '2024-05-25',
          reviewCount: null,
          rating: null,
          salesRank: 18651
        },
        {
          date: '2024-05-26',
          reviewCount: null,
          rating: null,
          salesRank: 21389
        },
        {
          date: '2024-05-27',
          reviewCount: null,
          rating: null,
          salesRank: 23803
        },
        {
          date: '2024-05-28',
          reviewCount: null,
          rating: null,
          salesRank: 23732
        },
        {
          date: '2024-05-29',
          reviewCount: null,
          rating: null,
          salesRank: 22841
        },
        {
          date: '2024-05-30',
          reviewCount: null,
          rating: null,
          salesRank: 12193
        },
        {
          date: '2024-05-31',
          reviewCount: null,
          rating: null,
          salesRank: 10918
        },
        {
          date: '2024-06-01',
          reviewCount: null,
          rating: null,
          salesRank: 10486
        },
        {
          date: '2024-06-02',
          reviewCount: null,
          rating: null,
          salesRank: 13681
        },
        {
          date: '2024-06-03',
          reviewCount: null,
          rating: null,
          salesRank: 11756
        },
        {
          date: '2024-06-04',
          reviewCount: null,
          rating: null,
          salesRank: 12681
        },
        {
          date: '2024-06-05',
          reviewCount: null,
          rating: null,
          salesRank: 13211
        },
        {
          date: '2024-06-06',
          reviewCount: null,
          rating: null,
          salesRank: 12287
        },
        {
          date: '2024-06-07',
          reviewCount: null,
          rating: null,
          salesRank: 16157
        },
        {
          date: '2024-06-08',
          reviewCount: null,
          rating: null,
          salesRank: 17208
        },
        {
          date: '2024-06-09',
          reviewCount: null,
          rating: null,
          salesRank: 18510
        },
        {
          date: '2024-06-10',
          reviewCount: null,
          rating: null,
          salesRank: 27310
        },
        {
          date: '2024-06-11',
          reviewCount: null,
          rating: null,
          salesRank: 23504
        },
        {
          date: '2024-06-12',
          reviewCount: null,
          rating: null,
          salesRank: 30171
        },
        {
          date: '2024-06-13',
          reviewCount: null,
          rating: null,
          salesRank: 35148
        },
        {
          date: '2024-06-14',
          reviewCount: null,
          rating: null,
          salesRank: 20143
        },
        {
          date: '2024-06-15',
          reviewCount: null,
          rating: null,
          salesRank: 25780
        },
        {
          date: '2024-06-16',
          reviewCount: null,
          rating: null,
          salesRank: 28507
        },
        {
          date: '2024-06-17',
          reviewCount: null,
          rating: null,
          salesRank: 24687
        },
        {
          date: '2024-06-18',
          reviewCount: null,
          rating: null,
          salesRank: 24243
        },
        {
          date: '2024-06-19',
          reviewCount: null,
          rating: null,
          salesRank: 23197
        },
        {
          date: '2024-06-20',
          reviewCount: null,
          rating: null,
          salesRank: 17006
        },
        {
          date: '2024-06-21',
          reviewCount: null,
          rating: null,
          salesRank: 16702
        },
        {
          date: '2024-06-22',
          reviewCount: null,
          rating: null,
          salesRank: 16473
        },
        {
          date: '2024-06-23',
          reviewCount: null,
          rating: null,
          salesRank: 14951
        },
        {
          date: '2024-06-24',
          reviewCount: null,
          rating: null,
          salesRank: 16994
        },
        {
          date: '2024-06-25',
          reviewCount: null,
          rating: null,
          salesRank: 20983
        },
        {
          date: '2024-06-26',
          reviewCount: null,
          rating: null,
          salesRank: 24411
        },
        {
          date: '2024-06-27',
          reviewCount: null,
          rating: null,
          salesRank: 24589
        },
        {
          date: '2024-06-28',
          reviewCount: null,
          rating: null,
          salesRank: 23699
        },
        {
          date: '2024-06-29',
          reviewCount: null,
          rating: null,
          salesRank: 21701
        },
        {
          date: '2024-06-30',
          reviewCount: null,
          rating: null,
          salesRank: 22265
        },
        {
          date: '2024-07-01',
          reviewCount: null,
          rating: null,
          salesRank: 27283
        },
        {
          date: '2024-07-02',
          reviewCount: null,
          rating: null,
          salesRank: 20578
        },
        {
          date: '2024-07-03',
          reviewCount: null,
          rating: null,
          salesRank: 18691
        },
        {
          date: '2024-07-04',
          reviewCount: null,
          rating: null,
          salesRank: 25557
        },
        {
          date: '2024-07-05',
          reviewCount: null,
          rating: null,
          salesRank: 26808
        },
        {
          date: '2024-07-06',
          reviewCount: null,
          rating: null,
          salesRank: 28037
        },
        {
          date: '2024-07-07',
          reviewCount: null,
          rating: null,
          salesRank: 30004
        },
        {
          date: '2024-07-08',
          reviewCount: null,
          rating: null,
          salesRank: 23334
        },
        {
          date: '2024-07-09',
          reviewCount: null,
          rating: null,
          salesRank: 26148
        },
        {
          date: '2024-07-10',
          reviewCount: null,
          rating: null,
          salesRank: 22706
        },
        {
          date: '2024-07-11',
          reviewCount: null,
          rating: null,
          salesRank: 25663
        },
        {
          date: '2024-07-12',
          reviewCount: null,
          rating: null,
          salesRank: 36979
        },
        {
          date: '2024-07-13',
          reviewCount: null,
          rating: null,
          salesRank: 47728
        },
        {
          date: '2024-07-14',
          reviewCount: null,
          rating: null,
          salesRank: 48587
        },
        {
          date: '2024-07-15',
          reviewCount: null,
          rating: null,
          salesRank: 28314
        },
        {
          date: '2024-07-16',
          reviewCount: null,
          rating: null,
          salesRank: 16252
        },
        {
          date: '2024-07-17',
          reviewCount: null,
          rating: null,
          salesRank: 14728
        },
        {
          date: '2024-07-18',
          reviewCount: null,
          rating: null,
          salesRank: 14177
        },
        {
          date: '2024-07-19',
          reviewCount: null,
          rating: null,
          salesRank: 15902
        },
        {
          date: '2024-07-20',
          reviewCount: null,
          rating: null,
          salesRank: 17983
        },
        {
          date: '2024-07-21',
          reviewCount: null,
          rating: null,
          salesRank: 19302
        },
        {
          date: '2024-07-22',
          reviewCount: null,
          rating: null,
          salesRank: 22767
        },
        {
          date: '2024-07-23',
          reviewCount: null,
          rating: null,
          salesRank: 24403
        },
        {
          date: '2024-07-24',
          reviewCount: null,
          rating: null,
          salesRank: 24742
        },
        {
          date: '2024-07-25',
          reviewCount: null,
          rating: null,
          salesRank: 24529
        },
        {
          date: '2024-07-26',
          reviewCount: null,
          rating: null,
          salesRank: 26365
        },
        {
          date: '2024-07-27',
          reviewCount: null,
          rating: null,
          salesRank: 29121
        },
        {
          date: '2024-07-28',
          reviewCount: null,
          rating: null,
          salesRank: 35198
        },
        {
          date: '2024-07-29',
          reviewCount: null,
          rating: null,
          salesRank: 23584
        },
        {
          date: '2024-07-30',
          reviewCount: null,
          rating: null,
          salesRank: 28366
        },
        {
          date: '2024-07-31',
          reviewCount: null,
          rating: null,
          salesRank: 31401
        },
        {
          date: '2024-08-01',
          reviewCount: null,
          rating: null,
          salesRank: 33079
        },
        {
          date: '2024-08-02',
          reviewCount: null,
          rating: null,
          salesRank: 25115
        },
        {
          date: '2024-08-03',
          reviewCount: null,
          rating: null,
          salesRank: 28186
        },
        {
          date: '2024-08-04',
          reviewCount: null,
          rating: null,
          salesRank: 28513
        },
        {
          date: '2024-08-05',
          reviewCount: null,
          rating: null,
          salesRank: 19797
        },
        {
          date: '2024-08-06',
          reviewCount: null,
          rating: null,
          salesRank: 19656
        },
        {
          date: '2024-08-07',
          reviewCount: null,
          rating: null,
          salesRank: 18976
        },
        {
          date: '2024-08-09',
          reviewCount: null,
          rating: null,
          salesRank: 21260
        },
        {
          date: '2024-08-10',
          reviewCount: null,
          rating: null,
          salesRank: 17725
        },
        {
          date: '2024-08-11',
          reviewCount: null,
          rating: null,
          salesRank: 17330
        },
        {
          date: '2024-08-12',
          reviewCount: null,
          rating: null,
          salesRank: 17899
        },
        {
          date: '2024-08-13',
          reviewCount: null,
          rating: null,
          salesRank: 19743
        },
        {
          date: '2024-08-14',
          reviewCount: null,
          rating: null,
          salesRank: 20157
        },
        {
          date: '2024-08-15',
          reviewCount: null,
          rating: null,
          salesRank: 18542
        },
        {
          date: '2024-08-16',
          reviewCount: null,
          rating: null,
          salesRank: 18633
        },
        {
          date: '2024-08-19',
          reviewCount: null,
          rating: null,
          salesRank: 21073
        },
        {
          date: '2024-08-20',
          reviewCount: null,
          rating: null,
          salesRank: 19773
        },
        {
          date: '2024-08-21',
          reviewCount: null,
          rating: null,
          salesRank: 19513
        },
        {
          date: '2024-08-22',
          reviewCount: null,
          rating: null,
          salesRank: 14291
        },
        {
          date: '2024-08-23',
          reviewCount: null,
          rating: null,
          salesRank: 15210
        },
        {
          date: '2024-08-24',
          reviewCount: null,
          rating: null,
          salesRank: 14347
        },
        {
          date: '2024-08-25',
          reviewCount: null,
          rating: null,
          salesRank: 14651
        },
        {
          date: '2024-08-26',
          reviewCount: null,
          rating: null,
          salesRank: 15050
        },
        {
          date: '2024-08-27',
          reviewCount: null,
          rating: null,
          salesRank: 13512
        },
        {
          date: '2024-08-29',
          reviewCount: null,
          rating: null,
          salesRank: 14183
        },
        {
          date: '2024-08-30',
          reviewCount: null,
          rating: null,
          salesRank: 13776
        },
        {
          date: '2024-08-31',
          reviewCount: null,
          rating: null,
          salesRank: 11891
        },
        {
          date: '2024-09-01',
          reviewCount: null,
          rating: null,
          salesRank: 16023
        },
        {
          date: '2024-09-02',
          reviewCount: null,
          rating: null,
          salesRank: 21501
        },
        {
          date: '2024-09-03',
          reviewCount: null,
          rating: null,
          salesRank: 21404
        },
        {
          date: '2024-09-04',
          reviewCount: null,
          rating: null,
          salesRank: 18675
        },
        {
          date: '2024-09-05',
          reviewCount: null,
          rating: null,
          salesRank: 12622
        },
        {
          date: '2024-09-06',
          reviewCount: null,
          rating: null,
          salesRank: 9556
        },
        {
          date: '2024-09-07',
          reviewCount: null,
          rating: null,
          salesRank: 11014
        },
        {
          date: '2024-09-08',
          reviewCount: null,
          rating: null,
          salesRank: 10594
        },
        {
          date: '2024-09-09',
          reviewCount: null,
          rating: null,
          salesRank: 10089
        },
        {
          date: '2024-09-10',
          reviewCount: null,
          rating: null,
          salesRank: 9896
        },
        {
          date: '2024-09-11',
          reviewCount: null,
          rating: null,
          salesRank: 11385
        },
        {
          date: '2024-09-12',
          reviewCount: null,
          rating: null,
          salesRank: 13574
        },
        {
          date: '2024-09-13',
          reviewCount: null,
          rating: null,
          salesRank: 11561
        },
        {
          date: '2024-09-14',
          reviewCount: null,
          rating: null,
          salesRank: 13734
        },
        {
          date: '2024-09-15',
          reviewCount: null,
          rating: null,
          salesRank: 12439
        },
        {
          date: '2024-09-16',
          reviewCount: null,
          rating: null,
          salesRank: 12056
        },
        {
          date: '2024-09-17',
          reviewCount: null,
          rating: null,
          salesRank: 16200
        },
        {
          date: '2024-09-18',
          reviewCount: null,
          rating: null,
          salesRank: 12266
        },
        {
          date: '2024-09-19',
          reviewCount: null,
          rating: null,
          salesRank: 14902
        },
        {
          date: '2024-09-20',
          reviewCount: null,
          rating: null,
          salesRank: 13830
        },
        {
          date: '2024-09-21',
          reviewCount: null,
          rating: null,
          salesRank: 15327
        },
        {
          date: '2024-09-22',
          reviewCount: null,
          rating: null,
          salesRank: 11694
        },
        {
          date: '2024-09-23',
          reviewCount: null,
          rating: null,
          salesRank: 10426
        },
        {
          date: '2024-09-24',
          reviewCount: null,
          rating: null,
          salesRank: 12274
        },
        {
          date: '2024-09-25',
          reviewCount: null,
          rating: null,
          salesRank: 8132
        },
        {
          date: '2024-09-26',
          reviewCount: null,
          rating: null,
          salesRank: 12328
        },
        {
          date: '2024-09-27',
          reviewCount: null,
          rating: null,
          salesRank: 7127
        },
        {
          date: '2024-09-28',
          reviewCount: null,
          rating: null,
          salesRank: 4273
        },
        {
          date: '2024-09-29',
          reviewCount: null,
          rating: null,
          salesRank: 5425
        },
        {
          date: '2024-09-30',
          reviewCount: null,
          rating: null,
          salesRank: 5704
        },
        {
          date: '2024-10-01',
          reviewCount: null,
          rating: null,
          salesRank: 6634
        },
        {
          date: '2024-10-02',
          reviewCount: null,
          rating: null,
          salesRank: 8147
        },
        {
          date: '2024-10-03',
          reviewCount: null,
          rating: null,
          salesRank: 6262
        },
        {
          date: '2024-10-04',
          reviewCount: null,
          rating: null,
          salesRank: 7206
        },
        {
          date: '2024-10-05',
          reviewCount: null,
          rating: null,
          salesRank: 7663
        },
        {
          date: '2024-10-06',
          reviewCount: null,
          rating: null,
          salesRank: 6546
        },
        {
          date: '2024-10-07',
          reviewCount: null,
          rating: null,
          salesRank: 7933
        },
        {
          date: '2024-10-08',
          reviewCount: null,
          rating: null,
          salesRank: 9449
        },
        {
          date: '2024-10-09',
          reviewCount: null,
          rating: null,
          salesRank: 9120
        },
        {
          date: '2024-10-10',
          reviewCount: null,
          rating: null,
          salesRank: 6486
        },
        {
          date: '2024-10-11',
          reviewCount: null,
          rating: null,
          salesRank: 4640
        },
        {
          date: '2024-10-12',
          reviewCount: null,
          rating: null,
          salesRank: 4853
        },
        {
          date: '2024-10-13',
          reviewCount: null,
          rating: null,
          salesRank: 4739
        },
        {
          date: '2024-10-14',
          reviewCount: null,
          rating: null,
          salesRank: 7232
        },
        {
          date: '2024-10-15',
          reviewCount: null,
          rating: null,
          salesRank: 5141
        },
        {
          date: '2024-10-16',
          reviewCount: null,
          rating: null,
          salesRank: 3534
        },
        {
          date: '2024-10-17',
          reviewCount: null,
          rating: null,
          salesRank: 2319
        },
        {
          date: '2024-10-18',
          reviewCount: null,
          rating: null,
          salesRank: 2101
        },
        {
          date: '2024-10-19',
          reviewCount: null,
          rating: null,
          salesRank: 2312
        },
        {
          date: '2024-10-20',
          reviewCount: null,
          rating: null,
          salesRank: 2274
        },
        {
          date: '2024-10-21',
          reviewCount: null,
          rating: null,
          salesRank: 2480
        },
        {
          date: '2024-10-22',
          reviewCount: null,
          rating: null,
          salesRank: 2652
        },
        {
          date: '2024-10-23',
          reviewCount: null,
          rating: null,
          salesRank: 2582
        },
        {
          date: '2024-10-24',
          reviewCount: null,
          rating: null,
          salesRank: 2462
        },
        {
          date: '2024-10-25',
          reviewCount: null,
          rating: null,
          salesRank: 2041
        },
        {
          date: '2024-10-26',
          reviewCount: null,
          rating: null,
          salesRank: 2470
        },
        {
          date: '2024-10-27',
          reviewCount: null,
          rating: null,
          salesRank: 2285
        },
        {
          date: '2024-10-28',
          reviewCount: null,
          rating: null,
          salesRank: 2157
        },
        {
          date: '2024-10-29',
          reviewCount: null,
          rating: null,
          salesRank: 1823
        },
        {
          date: '2024-10-30',
          reviewCount: null,
          rating: null,
          salesRank: 1815
        },
        {
          date: '2024-10-31',
          reviewCount: null,
          rating: null,
          salesRank: 1890
        },
        {
          date: '2024-11-01',
          reviewCount: null,
          rating: null,
          salesRank: 1924
        },
        {
          date: '2024-11-02',
          reviewCount: null,
          rating: null,
          salesRank: 2064
        },
        {
          date: '2024-11-03',
          reviewCount: null,
          rating: null,
          salesRank: 1695
        },
        {
          date: '2024-11-04',
          reviewCount: null,
          rating: null,
          salesRank: 1672
        },
        {
          date: '2024-11-05',
          reviewCount: null,
          rating: null,
          salesRank: 1624
        },
        {
          date: '2024-11-06',
          reviewCount: null,
          rating: null,
          salesRank: 1670
        },
        {
          date: '2024-11-07',
          reviewCount: null,
          rating: null,
          salesRank: 1604
        },
        {
          date: '2024-11-08',
          reviewCount: null,
          rating: null,
          salesRank: 1546
        },
        {
          date: '2024-11-09',
          reviewCount: null,
          rating: null,
          salesRank: 2022
        },
        {
          date: '2024-11-10',
          reviewCount: null,
          rating: null,
          salesRank: 2447
        },
        {
          date: '2024-11-11',
          reviewCount: null,
          rating: null,
          salesRank: 2567
        },
        {
          date: '2024-11-12',
          reviewCount: null,
          rating: null,
          salesRank: 1906
        },
        {
          date: '2024-11-13',
          reviewCount: null,
          rating: null,
          salesRank: 1453
        },
        {
          date: '2024-11-14',
          reviewCount: null,
          rating: null,
          salesRank: 1320
        },
        {
          date: '2024-11-15',
          reviewCount: null,
          rating: null,
          salesRank: 1519
        },
        {
          date: '2024-11-16',
          reviewCount: null,
          rating: null,
          salesRank: 1397
        },
        {
          date: '2024-11-17',
          reviewCount: null,
          rating: null,
          salesRank: 1906
        },
        {
          date: '2024-11-18',
          reviewCount: null,
          rating: null,
          salesRank: 2102
        },
        {
          date: '2024-11-19',
          reviewCount: null,
          rating: null,
          salesRank: 2191
        },
        {
          date: '2024-11-20',
          reviewCount: null,
          rating: null,
          salesRank: 2465
        },
        {
          date: '2024-11-21',
          reviewCount: null,
          rating: null,
          salesRank: 1980
        },
        {
          date: '2024-11-22',
          reviewCount: null,
          rating: null,
          salesRank: 2459
        },
        {
          date: '2024-11-23',
          reviewCount: null,
          rating: null,
          salesRank: 2501
        },
        {
          date: '2024-11-24',
          reviewCount: null,
          rating: null,
          salesRank: 2802
        },
        {
          date: '2024-11-25',
          reviewCount: null,
          rating: null,
          salesRank: 2969
        },
        {
          date: '2024-11-26',
          reviewCount: null,
          rating: null,
          salesRank: 2746
        },
        {
          date: '2024-11-27',
          reviewCount: null,
          rating: null,
          salesRank: 3155
        },
        {
          date: '2024-11-28',
          reviewCount: null,
          rating: null,
          salesRank: 2652
        },
        {
          date: '2024-11-29',
          reviewCount: null,
          rating: null,
          salesRank: 2671
        },
        {
          date: '2024-11-30',
          reviewCount: null,
          rating: null,
          salesRank: 1933
        },
        {
          date: '2024-12-01',
          reviewCount: null,
          rating: null,
          salesRank: 1515
        },
        {
          date: '2024-12-02',
          reviewCount: null,
          rating: null,
          salesRank: 1403
        },
        {
          date: '2024-12-03',
          reviewCount: null,
          rating: null,
          salesRank: 1241
        },
        {
          date: '2024-12-04',
          reviewCount: null,
          rating: null,
          salesRank: 1138
        },
        {
          date: '2024-12-05',
          reviewCount: null,
          rating: null,
          salesRank: 1139
        },
        {
          date: '2024-12-06',
          reviewCount: null,
          rating: null,
          salesRank: 931
        },
        {
          date: '2024-12-07',
          reviewCount: null,
          rating: null,
          salesRank: 969
        },
        {
          date: '2024-12-08',
          reviewCount: null,
          rating: null,
          salesRank: 918
        },
        {
          date: '2024-12-09',
          reviewCount: null,
          rating: null,
          salesRank: 1110
        },
        {
          date: '2024-12-10',
          reviewCount: null,
          rating: null,
          salesRank: 1111
        },
        {
          date: '2024-12-11',
          reviewCount: null,
          rating: null,
          salesRank: 1210
        },
        {
          date: '2024-12-12',
          reviewCount: null,
          rating: null,
          salesRank: 1247
        },
        {
          date: '2024-12-13',
          reviewCount: null,
          rating: null,
          salesRank: 1348
        },
        {
          date: '2024-12-14',
          reviewCount: null,
          rating: null,
          salesRank: 1185
        },
        {
          date: '2024-12-15',
          reviewCount: null,
          rating: null,
          salesRank: 1158
        },
        {
          date: '2024-12-16',
          reviewCount: null,
          rating: null,
          salesRank: 1542
        },
        {
          date: '2024-12-17',
          reviewCount: null,
          rating: null,
          salesRank: 1686
        },
        {
          date: '2024-12-18',
          reviewCount: null,
          rating: null,
          salesRank: 1723
        },
        {
          date: '2024-12-19',
          reviewCount: null,
          rating: null,
          salesRank: 1934
        },
        {
          date: '2024-12-20',
          reviewCount: null,
          rating: null,
          salesRank: 1911
        },
        {
          date: '2024-12-21',
          reviewCount: null,
          rating: null,
          salesRank: 1723
        },
        {
          date: '2024-12-22',
          reviewCount: null,
          rating: null,
          salesRank: 1196
        },
        {
          date: '2024-12-23',
          reviewCount: null,
          rating: null,
          salesRank: 1051
        },
        {
          date: '2024-12-24',
          reviewCount: null,
          rating: null,
          salesRank: 765
        },
        {
          date: '2024-12-25',
          reviewCount: null,
          rating: null,
          salesRank: 812
        },
        {
          date: '2024-12-26',
          reviewCount: null,
          rating: null,
          salesRank: 760
        },
        {
          date: '2024-12-27',
          reviewCount: null,
          rating: null,
          salesRank: 906
        },
        {
          date: '2024-12-28',
          reviewCount: null,
          rating: null,
          salesRank: 1181
        },
        {
          date: '2024-12-29',
          reviewCount: null,
          rating: null,
          salesRank: 1675
        },
        {
          date: '2024-12-30',
          reviewCount: null,
          rating: null,
          salesRank: 1724
        },
        {
          date: '2024-12-31',
          reviewCount: null,
          rating: null,
          salesRank: 1811
        },
        {
          date: '2025-01-01',
          reviewCount: null,
          rating: null,
          salesRank: 1758
        },
        {
          date: '2025-01-02',
          reviewCount: null,
          rating: null,
          salesRank: 1582
        },
        {
          date: '2025-01-03',
          reviewCount: null,
          rating: null,
          salesRank: 1605
        },
        {
          date: '2025-01-04',
          reviewCount: null,
          rating: null,
          salesRank: 1771
        },
        {
          date: '2025-01-05',
          reviewCount: null,
          rating: null,
          salesRank: 1530
        },
        {
          date: '2025-01-06',
          reviewCount: null,
          rating: null,
          salesRank: 2014
        },
        {
          date: '2025-01-07',
          reviewCount: null,
          rating: null,
          salesRank: 1265
        },
        {
          date: '2025-01-08',
          reviewCount: null,
          rating: null,
          salesRank: 699
        },
        {
          date: '2025-01-09',
          reviewCount: null,
          rating: null,
          salesRank: 722
        },
        {
          date: '2025-01-10',
          reviewCount: null,
          rating: null,
          salesRank: 772
        },
        {
          date: '2025-01-11',
          reviewCount: null,
          rating: null,
          salesRank: 945
        },
        {
          date: '2025-01-12',
          reviewCount: null,
          rating: null,
          salesRank: 1016
        },
        {
          date: '2025-01-13',
          reviewCount: null,
          rating: null,
          salesRank: 1086
        },
        {
          date: '2025-01-14',
          reviewCount: null,
          rating: null,
          salesRank: 787
        },
        {
          date: '2025-01-15',
          reviewCount: null,
          rating: null,
          salesRank: 878
        },
        {
          date: '2025-01-16',
          reviewCount: null,
          rating: null,
          salesRank: 812
        },
        {
          date: '2025-01-17',
          reviewCount: null,
          rating: null,
          salesRank: 425
        },
        {
          date: '2025-01-18',
          reviewCount: null,
          rating: null,
          salesRank: 552
        },
        {
          date: '2025-01-19',
          reviewCount: null,
          rating: null,
          salesRank: 625
        },
        {
          date: '2025-01-20',
          reviewCount: null,
          rating: null,
          salesRank: 660
        },
        {
          date: '2025-01-21',
          reviewCount: null,
          rating: null,
          salesRank: 465
        },
        {
          date: '2025-01-22',
          reviewCount: null,
          rating: null,
          salesRank: 418
        },
        {
          date: '2025-01-23',
          reviewCount: null,
          rating: null,
          salesRank: 565
        },
        {
          date: '2025-01-24',
          reviewCount: null,
          rating: null,
          salesRank: 692
        },
        {
          date: '2025-01-25',
          reviewCount: null,
          rating: null,
          salesRank: 845
        },
        {
          date: '2025-01-26',
          reviewCount: null,
          rating: null,
          salesRank: 1099
        },
        {
          date: '2025-01-27',
          reviewCount: null,
          rating: null,
          salesRank: 1089
        },
        {
          date: '2025-01-28',
          reviewCount: null,
          rating: null,
          salesRank: 1256
        },
        {
          date: '2025-01-29',
          reviewCount: null,
          rating: null,
          salesRank: 1397
        },
        {
          date: '2025-01-30',
          reviewCount: null,
          rating: null,
          salesRank: 1431
        },
        {
          date: '2025-01-31',
          reviewCount: null,
          rating: null,
          salesRank: 1062
        },
        {
          date: '2025-02-01',
          reviewCount: null,
          rating: null,
          salesRank: 1474
        },
        {
          date: '2025-02-02',
          reviewCount: null,
          rating: null,
          salesRank: 1728
        },
        {
          date: '2025-02-03',
          reviewCount: null,
          rating: null,
          salesRank: null
        },
        {
          date: '2025-02-04',
          reviewCount: null,
          rating: null,
          salesRank: 2048
        },
        {
          date: '2025-02-05',
          reviewCount: null,
          rating: null,
          salesRank: 1336
        },
        {
          date: '2025-02-06',
          reviewCount: null,
          rating: null,
          salesRank: 856
        },
        {
          date: '2025-02-07',
          reviewCount: null,
          rating: null,
          salesRank: 604
        },
        {
          date: '2025-02-08',
          reviewCount: null,
          rating: null,
          salesRank: 755
        },
        {
          date: '2025-02-09',
          reviewCount: null,
          rating: null,
          salesRank: 867
        },
        {
          date: '2025-02-10',
          reviewCount: null,
          rating: null,
          salesRank: 1003
        },
        {
          date: '2025-02-11',
          reviewCount: null,
          rating: null,
          salesRank: 1044
        },
        {
          date: '2025-02-12',
          reviewCount: null,
          rating: null,
          salesRank: 1306
        },
        {
          date: '2025-02-13',
          reviewCount: null,
          rating: null,
          salesRank: 1050
        },
        {
          date: '2025-02-14',
          reviewCount: null,
          rating: null,
          salesRank: 946
        },
        {
          date: '2025-02-15',
          reviewCount: null,
          rating: null,
          salesRank: 978
        },
        {
          date: '2025-02-16',
          reviewCount: null,
          rating: null,
          salesRank: 1070
        },
        {
          date: '2025-02-17',
          reviewCount: null,
          rating: null,
          salesRank: 1043
        },
        {
          date: '2025-02-18',
          reviewCount: null,
          rating: null,
          salesRank: 1018
        },
        {
          date: '2025-02-19',
          reviewCount: null,
          rating: null,
          salesRank: 931
        },
        {
          date: '2025-02-20',
          reviewCount: null,
          rating: null,
          salesRank: 821
        },
        {
          date: '2025-02-21',
          reviewCount: null,
          rating: null,
          salesRank: 808
        },
        {
          date: '2025-02-22',
          reviewCount: null,
          rating: null,
          salesRank: 907
        },
        {
          date: '2025-02-23',
          reviewCount: null,
          rating: null,
          salesRank: 1067
        },
        {
          date: '2025-02-24',
          reviewCount: null,
          rating: null,
          salesRank: 1268
        },
        {
          date: '2025-02-25',
          reviewCount: null,
          rating: null,
          salesRank: 1419
        },
        {
          date: '2025-02-26',
          reviewCount: null,
          rating: null,
          salesRank: 1843
        },
        {
          date: '2025-02-27',
          reviewCount: null,
          rating: null,
          salesRank: 1574
        },
        {
          date: '2025-02-28',
          reviewCount: null,
          rating: null,
          salesRank: 1476
        },
        {
          date: '2025-03-01',
          reviewCount: null,
          rating: null,
          salesRank: 1832
        },
        {
          date: '2025-03-02',
          reviewCount: null,
          rating: null,
          salesRank: 1641
        },
        {
          date: '2025-03-03',
          reviewCount: null,
          rating: null,
          salesRank: 1638
        },
        {
          date: '2025-03-04',
          reviewCount: null,
          rating: null,
          salesRank: 1523
        },
        {
          date: '2025-03-05',
          reviewCount: null,
          rating: null,
          salesRank: 1873
        },
        {
          date: '2025-03-06',
          reviewCount: null,
          rating: null,
          salesRank: 2207
        },
        {
          date: '2025-03-07',
          reviewCount: null,
          rating: null,
          salesRank: 2754
        },
        {
          date: '2025-03-08',
          reviewCount: null,
          rating: null,
          salesRank: 4045
        },
        {
          date: '2025-03-09',
          reviewCount: null,
          rating: null,
          salesRank: 4239
        },
        {
          date: '2025-03-10',
          reviewCount: null,
          rating: null,
          salesRank: 4006
        },
        {
          date: '2025-03-11',
          reviewCount: null,
          rating: null,
          salesRank: 3757
        },
        {
          date: '2025-03-12',
          reviewCount: null,
          rating: null,
          salesRank: 3775
        },
        {
          date: '2025-03-13',
          reviewCount: null,
          rating: null,
          salesRank: 4519
        },
        {
          date: '2025-03-14',
          reviewCount: null,
          rating: null,
          salesRank: 4300
        },
        {
          date: '2025-03-15',
          reviewCount: null,
          rating: null,
          salesRank: 3296
        },
        {
          date: '2025-03-16',
          reviewCount: null,
          rating: null,
          salesRank: 4272
        },
        {
          date: '2025-03-17',
          reviewCount: null,
          rating: null,
          salesRank: 4639
        },
        {
          date: '2025-03-18',
          reviewCount: null,
          rating: null,
          salesRank: 4455
        }
      ],
      reviewDataUploaded: false,
      bestSellerRankDataUploaded: true,
      isVisible: true,
      isCategoryAverage: false,
      createdAt: '2025-02-24T18:08:39.623Z'
    },
    {
      id: 'ef977bea-87d4-4c54-8b65-f701c972b257',
      productName: 'In Ear Earbud Headphone',
      productUrl: 'https://www.amazon.com',
      productImage: 'https://media.productwind.com/sample/images/sample-sku-wireless-earbuds-3.png',
      comparisonMetric: 'REVIEWS',
      productPerformanceRecords: [
        {
          date: '2023-09-25',
          reviewCount: 7,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-26',
          reviewCount: 7,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-27',
          reviewCount: 7,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-28',
          reviewCount: 7,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-29',
          reviewCount: 7,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-30',
          reviewCount: 7,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-01',
          reviewCount: 7,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-02',
          reviewCount: 7,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-03',
          reviewCount: 7,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-04',
          reviewCount: 10,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-05',
          reviewCount: 10,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-06',
          reviewCount: 10,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-07',
          reviewCount: 10,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-08',
          reviewCount: 10,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-09',
          reviewCount: 28,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-10',
          reviewCount: 28,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-11',
          reviewCount: 28,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-12',
          reviewCount: 28,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-13',
          reviewCount: 28,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-14',
          reviewCount: 28,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-15',
          reviewCount: 30,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-16',
          reviewCount: 30,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-17',
          reviewCount: 30,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-18',
          reviewCount: 30,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-19',
          reviewCount: 30,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-20',
          reviewCount: 34,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-21',
          reviewCount: 34,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-22',
          reviewCount: 34,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-23',
          reviewCount: 34,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-24',
          reviewCount: 34,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-25',
          reviewCount: 34,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-26',
          reviewCount: 39,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-27',
          reviewCount: 39,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-28',
          reviewCount: 39,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-29',
          reviewCount: 39,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-30',
          reviewCount: 39,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-31',
          reviewCount: 39,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-01',
          reviewCount: 50,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-02',
          reviewCount: 50,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-03',
          reviewCount: 50,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-04',
          reviewCount: 50,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-05',
          reviewCount: 50,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-06',
          reviewCount: 50,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-07',
          reviewCount: 55,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-08',
          reviewCount: 55,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-09',
          reviewCount: 55,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-10',
          reviewCount: 55,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-11',
          reviewCount: 55,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-12',
          reviewCount: 55,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-13',
          reviewCount: 55,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-14',
          reviewCount: 55,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-15',
          reviewCount: 55,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-16',
          reviewCount: 55,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-17',
          reviewCount: 89,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-18',
          reviewCount: 89,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-19',
          reviewCount: 89,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-20',
          reviewCount: 89,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-21',
          reviewCount: 89,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-22',
          reviewCount: 96,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-23',
          reviewCount: 96,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-24',
          reviewCount: 96,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-25',
          reviewCount: 96,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-26',
          reviewCount: 96,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-27',
          reviewCount: 96,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-28',
          reviewCount: 110,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-29',
          reviewCount: 110,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-30',
          reviewCount: 110,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-01',
          reviewCount: 110,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-02',
          reviewCount: 110,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-03',
          reviewCount: 121,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-04',
          reviewCount: 121,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-05',
          reviewCount: 121,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-06',
          reviewCount: 121,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-07',
          reviewCount: 121,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-08',
          reviewCount: 121,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-09',
          reviewCount: 134,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-10',
          reviewCount: 134,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-11',
          reviewCount: 134,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-12',
          reviewCount: 134,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-13',
          reviewCount: 143,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-14',
          reviewCount: 143,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-15',
          reviewCount: 143,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-16',
          reviewCount: 143,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-17',
          reviewCount: 150,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-18',
          reviewCount: 150,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-19',
          reviewCount: 150,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-20',
          reviewCount: 150,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-21',
          reviewCount: 165,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-22',
          reviewCount: 165,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-23',
          reviewCount: 165,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-24',
          reviewCount: 165,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-25',
          reviewCount: 177,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-26',
          reviewCount: 177,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-27',
          reviewCount: 177,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-28',
          reviewCount: 177,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-29',
          reviewCount: 188,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-30',
          reviewCount: 188,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-31',
          reviewCount: 188,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-01',
          reviewCount: 195,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-02',
          reviewCount: 195,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-03',
          reviewCount: 195,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-04',
          reviewCount: 204,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-05',
          reviewCount: 204,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-06',
          reviewCount: 204,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-07',
          reviewCount: 204,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-08',
          reviewCount: 219,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-09',
          reviewCount: 219,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-10',
          reviewCount: 219,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-11',
          reviewCount: 234,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-12',
          reviewCount: 234,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-13',
          reviewCount: 234,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-14',
          reviewCount: 245,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-15',
          reviewCount: 245,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-16',
          reviewCount: 245,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-17',
          reviewCount: 245,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-18',
          reviewCount: 261,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-19',
          reviewCount: 261,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-20',
          reviewCount: 261,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-21',
          reviewCount: 282,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-22',
          reviewCount: 282,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-23',
          reviewCount: 282,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-24',
          reviewCount: 293,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-25',
          reviewCount: 293,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-26',
          reviewCount: 293,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-27',
          reviewCount: 293,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-28',
          reviewCount: 309,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-29',
          reviewCount: 309,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-30',
          reviewCount: 309,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-31',
          reviewCount: 309,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-01',
          reviewCount: 283,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-02',
          reviewCount: 283,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-03',
          reviewCount: 283,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-04',
          reviewCount: 297,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-05',
          reviewCount: 297,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-06',
          reviewCount: 297,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-07',
          reviewCount: 369,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-08',
          reviewCount: 369,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-09',
          reviewCount: 369,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-10',
          reviewCount: 369,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-11',
          reviewCount: 405,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-12',
          reviewCount: 405,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-13',
          reviewCount: 405,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-14',
          reviewCount: 420,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-15',
          reviewCount: 420,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-16',
          reviewCount: 420,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-17',
          reviewCount: 420,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-18',
          reviewCount: 420,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-19',
          reviewCount: 420,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-20',
          reviewCount: 458,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-21',
          reviewCount: 458,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-22',
          reviewCount: 458,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-23',
          reviewCount: 458,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-24',
          reviewCount: 458,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-25',
          reviewCount: 504,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-26',
          reviewCount: 504,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-27',
          reviewCount: 504,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-28',
          reviewCount: 504,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-29',
          reviewCount: 504,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-01',
          reviewCount: 535,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-02',
          reviewCount: 535,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-03',
          reviewCount: 535,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-04',
          reviewCount: 535,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-05',
          reviewCount: 535,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-06',
          reviewCount: 535,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-07',
          reviewCount: 560,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-08',
          reviewCount: 560,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-09',
          reviewCount: 560,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-10',
          reviewCount: 560,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-11',
          reviewCount: 560,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-12',
          reviewCount: 560,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-13',
          reviewCount: 592,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-14',
          reviewCount: 592,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-15',
          reviewCount: 592,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-16',
          reviewCount: 592,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-17',
          reviewCount: 592,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-18',
          reviewCount: 592,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-19',
          reviewCount: 621,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-20',
          reviewCount: 621,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-21',
          reviewCount: 621,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-22',
          reviewCount: 621,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-23',
          reviewCount: 621,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-24',
          reviewCount: 621,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-25',
          reviewCount: 621,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-26',
          reviewCount: 621,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-27',
          reviewCount: 621,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-28',
          reviewCount: 621,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-29',
          reviewCount: 621,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-30',
          reviewCount: 621,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-31',
          reviewCount: 621,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-01',
          reviewCount: 621,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-02',
          reviewCount: 621,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-03',
          reviewCount: 621,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-04',
          reviewCount: 621,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-05',
          reviewCount: 621,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-06',
          reviewCount: 621,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-07',
          reviewCount: 621,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-08',
          reviewCount: 621,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-09',
          reviewCount: 621,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-10',
          reviewCount: 713,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-11',
          reviewCount: 713,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-12',
          reviewCount: 713,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-13',
          reviewCount: 713,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-14',
          reviewCount: 713,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-15',
          reviewCount: 736,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-16',
          reviewCount: 736,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-17',
          reviewCount: 736,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-18',
          reviewCount: 736,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-19',
          reviewCount: 736,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-20',
          reviewCount: 736,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-21',
          reviewCount: 760,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-22',
          reviewCount: 760,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-23',
          reviewCount: 760,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-24',
          reviewCount: 760,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-25',
          reviewCount: 760,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-26',
          reviewCount: 760,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-27',
          reviewCount: 782,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-28',
          reviewCount: 782,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-29',
          reviewCount: 782,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-30',
          reviewCount: 782,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-01',
          reviewCount: 782,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-02',
          reviewCount: 797,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-03',
          reviewCount: 797,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-04',
          reviewCount: 797,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-05',
          reviewCount: 797,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-06',
          reviewCount: 797,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-07',
          reviewCount: 813,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-08',
          reviewCount: 813,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-09',
          reviewCount: 813,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-10',
          reviewCount: 813,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-11',
          reviewCount: 813,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-12',
          reviewCount: 832,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-13',
          reviewCount: 832,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-14',
          reviewCount: 832,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-15',
          reviewCount: 832,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-16',
          reviewCount: 832,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-17',
          reviewCount: 832,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-18',
          reviewCount: 852,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-19',
          reviewCount: 852,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-20',
          reviewCount: 852,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-21',
          reviewCount: 852,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-22',
          reviewCount: 852,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-23',
          reviewCount: 858,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-24',
          reviewCount: 858,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-25',
          reviewCount: 858,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-26',
          reviewCount: 858,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-27',
          reviewCount: 858,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-28',
          reviewCount: 858,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-29',
          reviewCount: 1407,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-30',
          reviewCount: 1407,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-31',
          reviewCount: 1407,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-01',
          reviewCount: 1407,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-02',
          reviewCount: 1407,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-03',
          reviewCount: 1406,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-04',
          reviewCount: 1406,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-05',
          reviewCount: 1406,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-06',
          reviewCount: 1406,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-07',
          reviewCount: 1406,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-08',
          reviewCount: 1406,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-09',
          reviewCount: 1428,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-10',
          reviewCount: 1428,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-11',
          reviewCount: 1428,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-12',
          reviewCount: 1428,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-13',
          reviewCount: 1428,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-14',
          reviewCount: 795,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-15',
          reviewCount: 795,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-16',
          reviewCount: 795,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-17',
          reviewCount: 795,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-18',
          reviewCount: 795,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-19',
          reviewCount: 795,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-20',
          reviewCount: 809,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-21',
          reviewCount: 809,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-22',
          reviewCount: 809,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-23',
          reviewCount: 809,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-24',
          reviewCount: 809,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-25',
          reviewCount: 819,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-26',
          reviewCount: 819,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-27',
          reviewCount: 819,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-28',
          reviewCount: 819,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-29',
          reviewCount: 819,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-30',
          reviewCount: 819,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-01',
          reviewCount: 832,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-02',
          reviewCount: 832,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-03',
          reviewCount: 832,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-04',
          reviewCount: 832,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-05',
          reviewCount: 832,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-06',
          reviewCount: 847,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-07',
          reviewCount: 847,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-08',
          reviewCount: 847,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-09',
          reviewCount: 847,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-10',
          reviewCount: 847,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-11',
          reviewCount: 849,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-12',
          reviewCount: 849,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-13',
          reviewCount: 849,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-14',
          reviewCount: 849,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-15',
          reviewCount: 849,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-16',
          reviewCount: 849,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-17',
          reviewCount: 860,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-18',
          reviewCount: 860,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-19',
          reviewCount: 860,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-20',
          reviewCount: 860,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-21',
          reviewCount: 860,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-22',
          reviewCount: 869,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-23',
          reviewCount: 869,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-24',
          reviewCount: 869,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-25',
          reviewCount: 869,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-26',
          reviewCount: 869,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-27',
          reviewCount: 869,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-28',
          reviewCount: 881,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-29',
          reviewCount: 881,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-30',
          reviewCount: 881,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-31',
          reviewCount: 881,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-01',
          reviewCount: 881,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-02',
          reviewCount: 881,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-03',
          reviewCount: 891,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-04',
          reviewCount: 891,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-05',
          reviewCount: 891,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-06',
          reviewCount: 891,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-07',
          reviewCount: 891,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-08',
          reviewCount: 1515,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-09',
          reviewCount: 1515,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-10',
          reviewCount: 1515,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-11',
          reviewCount: 1515,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-12',
          reviewCount: 1515,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-13',
          reviewCount: 1515,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-14',
          reviewCount: 1524,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-15',
          reviewCount: 1524,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-16',
          reviewCount: 1524,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-17',
          reviewCount: 1524,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-18',
          reviewCount: 1524,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-19',
          reviewCount: 1537,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-20',
          reviewCount: 1537,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-21',
          reviewCount: 1537,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-22',
          reviewCount: 1537,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-23',
          reviewCount: 1537,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-24',
          reviewCount: 1537,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-25',
          reviewCount: 1547,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-26',
          reviewCount: 1547,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-27',
          reviewCount: 1547,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-28',
          reviewCount: 1547,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-29',
          reviewCount: 1547,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-30',
          reviewCount: 1547,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-31',
          reviewCount: 1557,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-01',
          reviewCount: 1557,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-02',
          reviewCount: 1557,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-03',
          reviewCount: 1557,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-04',
          reviewCount: 1557,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-05',
          reviewCount: 1557,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-06',
          reviewCount: 1569,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-07',
          reviewCount: 1569,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-08',
          reviewCount: 1569,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-09',
          reviewCount: 1569,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-10',
          reviewCount: 1569,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-11',
          reviewCount: 1578,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-12',
          reviewCount: 1578,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-13',
          reviewCount: 1578,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-14',
          reviewCount: 1578,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-15',
          reviewCount: 1578,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-16',
          reviewCount: 1586,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-17',
          reviewCount: 1586,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-18',
          reviewCount: 1586,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-19',
          reviewCount: 1586,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-20',
          reviewCount: 1586,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-21',
          reviewCount: 1586,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-22',
          reviewCount: 1595,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-23',
          reviewCount: 1595,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-24',
          reviewCount: 1595,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-25',
          reviewCount: 1595,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-26',
          reviewCount: 1595,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-27',
          reviewCount: 1595,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-28',
          reviewCount: 1141,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-29',
          reviewCount: 1141,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-30',
          reviewCount: 1141,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-01',
          reviewCount: 1141,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-02',
          reviewCount: 1141,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-03',
          reviewCount: 1149,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-04',
          reviewCount: 1149,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-05',
          reviewCount: 1149,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-06',
          reviewCount: 1149,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-07',
          reviewCount: 1149,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-08',
          reviewCount: 1157,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-09',
          reviewCount: 1157,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-10',
          reviewCount: 1157,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-11',
          reviewCount: 1157,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-12',
          reviewCount: 1157,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-13',
          reviewCount: 1168,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-14',
          reviewCount: 1168,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-15',
          reviewCount: 1168,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-16',
          reviewCount: 1168,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-17',
          reviewCount: 1168,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-18',
          reviewCount: 1168,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-19',
          reviewCount: 1181,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-20',
          reviewCount: 1181,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-21',
          reviewCount: 1181,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-22',
          reviewCount: 1181,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-23',
          reviewCount: 1181,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-24',
          reviewCount: 1196,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-25',
          reviewCount: 1196,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-26',
          reviewCount: 1196,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-27',
          reviewCount: 1196,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-28',
          reviewCount: 1196,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-29',
          reviewCount: 1196,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-30',
          reviewCount: 1211,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-31',
          reviewCount: 1211,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-01',
          reviewCount: 1211,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-02',
          reviewCount: 1211,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-03',
          reviewCount: 1211,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-04',
          reviewCount: 1222,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-05',
          reviewCount: 1222,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-06',
          reviewCount: 1222,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-07',
          reviewCount: 1222,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-08',
          reviewCount: 1231,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-09',
          reviewCount: 1231,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-10',
          reviewCount: 1231,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-11',
          reviewCount: 1241,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-12',
          reviewCount: 1241,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-13',
          reviewCount: 1241,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-14',
          reviewCount: 1241,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-15',
          reviewCount: 1249,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-16',
          reviewCount: 1249,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-17',
          reviewCount: 1249,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-18',
          reviewCount: 1259,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-19',
          reviewCount: 1259,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-20',
          reviewCount: 1259,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-21',
          reviewCount: 1259,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-22',
          reviewCount: 1276,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-23',
          reviewCount: 1276,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-24',
          reviewCount: 1276,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-25',
          reviewCount: 1290,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-26',
          reviewCount: 1290,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-27',
          reviewCount: 1290,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-28',
          reviewCount: 1290,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-29',
          reviewCount: 1309,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-30',
          reviewCount: 1309,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-01',
          reviewCount: 1309,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-02',
          reviewCount: 1320,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-03',
          reviewCount: 1320,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-04',
          reviewCount: 1320,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-05',
          reviewCount: 1320,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-06',
          reviewCount: 1339,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-07',
          reviewCount: 1339,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-08',
          reviewCount: 1339,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-09',
          reviewCount: 1356,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-10',
          reviewCount: 1356,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-11',
          reviewCount: 1356,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-12',
          reviewCount: 1356,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-13',
          reviewCount: 1395,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-14',
          reviewCount: 1395,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-15',
          reviewCount: 1395,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-16',
          reviewCount: 1415,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-17',
          reviewCount: 1415,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-18',
          reviewCount: 1415,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-19',
          reviewCount: 1415,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-20',
          reviewCount: 1449,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-21',
          reviewCount: 1449,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-22',
          reviewCount: 1449,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-23',
          reviewCount: 1478,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-24',
          reviewCount: 1478,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-25',
          reviewCount: 1478,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-26',
          reviewCount: 1478,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-27',
          reviewCount: 1500,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-28',
          reviewCount: 1500,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-29',
          reviewCount: 1500,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-30',
          reviewCount: 1526,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-31',
          reviewCount: 1526,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-01',
          reviewCount: 1526,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-02',
          reviewCount: 1546,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-03',
          reviewCount: 1546,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-04',
          reviewCount: 1546,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-05',
          reviewCount: 1566,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-06',
          reviewCount: 1566,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-07',
          reviewCount: 1566,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-08',
          reviewCount: 1566,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-09',
          reviewCount: 1609,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-10',
          reviewCount: 1609,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-11',
          reviewCount: 1609,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-12',
          reviewCount: 1626,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-13',
          reviewCount: 1626,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-14',
          reviewCount: 1626,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-15',
          reviewCount: 1626,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-16',
          reviewCount: 1650,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-17',
          reviewCount: 1650,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-18',
          reviewCount: 1650,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-19',
          reviewCount: 1685,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-20',
          reviewCount: 1685,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-21',
          reviewCount: 1685,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-22',
          reviewCount: 1685,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-23',
          reviewCount: 1733,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-24',
          reviewCount: 1733,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-25',
          reviewCount: 1733,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-26',
          reviewCount: 1759,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-27',
          reviewCount: 1759,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-28',
          reviewCount: 1759,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-29',
          reviewCount: 1759,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-30',
          reviewCount: 1823,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-31',
          reviewCount: 1823,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-01',
          reviewCount: 1823,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-02',
          reviewCount: 1849,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-03',
          reviewCount: 1849,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-04',
          reviewCount: 1849,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-05',
          reviewCount: 1849,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-06',
          reviewCount: 1881,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-07',
          reviewCount: 1881,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-08',
          reviewCount: 1881,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-09',
          reviewCount: 1904,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-10',
          reviewCount: 1904,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-11',
          reviewCount: 1904,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-12',
          reviewCount: 1904,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-13',
          reviewCount: 1904,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-14',
          reviewCount: 1904,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-15',
          reviewCount: 1904,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-16',
          reviewCount: 1968,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-17',
          reviewCount: 1968,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-18',
          reviewCount: 1968,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-19',
          reviewCount: 1968,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-20',
          reviewCount: 2003,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-21',
          reviewCount: 2003,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-22',
          reviewCount: 2003,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-23',
          reviewCount: 2038,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-24',
          reviewCount: 2038,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-25',
          reviewCount: 2038,
          rating: 4,
          salesRank: null
        }
      ],
      reviewDataUploaded: true,
      bestSellerRankDataUploaded: false,
      isVisible: true,
      isCategoryAverage: false,
      createdAt: '2025-02-24T18:08:10.775Z'
    },
    {
      id: 'c39486ce-efb1-4368-b608-8e2bfc51be0a',
      productName: 'Bluetooth Headphones with 16 Hours Playtime',
      productUrl: 'https://www.amazon.com',
      productImage: 'https://media.productwind.com/sample/images/sample-sku-wireless-earbuds-2.png',
      comparisonMetric: 'BEST_SELLER_RANK',
      productPerformanceRecords: [
        {
          date: '2023-06-15',
          reviewCount: null,
          rating: null,
          salesRank: 75991
        },
        {
          date: '2023-06-16',
          reviewCount: null,
          rating: null,
          salesRank: 88421
        },
        {
          date: '2023-06-17',
          reviewCount: null,
          rating: null,
          salesRank: 139274
        },
        {
          date: '2023-06-18',
          reviewCount: null,
          rating: null,
          salesRank: 176612
        },
        {
          date: '2023-06-19',
          reviewCount: null,
          rating: null,
          salesRank: 201298
        },
        {
          date: '2023-06-20',
          reviewCount: null,
          rating: null,
          salesRank: 237326
        },
        {
          date: '2023-06-21',
          reviewCount: null,
          rating: null,
          salesRank: 278913
        },
        {
          date: '2023-06-22',
          reviewCount: null,
          rating: null,
          salesRank: 291056
        },
        {
          date: '2023-06-23',
          reviewCount: null,
          rating: null,
          salesRank: 348219
        },
        {
          date: '2023-06-24',
          reviewCount: null,
          rating: null,
          salesRank: 366044
        },
        {
          date: '2023-06-25',
          reviewCount: null,
          rating: null,
          salesRank: 378915
        },
        {
          date: '2023-06-26',
          reviewCount: null,
          rating: null,
          salesRank: 391479
        },
        {
          date: '2023-06-27',
          reviewCount: null,
          rating: null,
          salesRank: 405241
        },
        {
          date: '2023-06-28',
          reviewCount: null,
          rating: null,
          salesRank: 428141
        },
        {
          date: '2023-06-29',
          reviewCount: null,
          rating: null,
          salesRank: 436298
        },
        {
          date: '2023-06-30',
          reviewCount: null,
          rating: null,
          salesRank: 448804
        },
        {
          date: '2023-07-01',
          reviewCount: null,
          rating: null,
          salesRank: 459297
        },
        {
          date: '2023-07-02',
          reviewCount: null,
          rating: null,
          salesRank: 468501
        },
        {
          date: '2023-07-03',
          reviewCount: null,
          rating: null,
          salesRank: 479053
        },
        {
          date: '2023-07-04',
          reviewCount: null,
          rating: null,
          salesRank: 486622
        },
        {
          date: '2023-07-05',
          reviewCount: null,
          rating: null,
          salesRank: 500575
        },
        {
          date: '2023-07-06',
          reviewCount: null,
          rating: null,
          salesRank: 510233
        },
        {
          date: '2023-07-07',
          reviewCount: null,
          rating: null,
          salesRank: 525188
        },
        {
          date: '2023-07-08',
          reviewCount: null,
          rating: null,
          salesRank: 538342
        },
        {
          date: '2023-07-09',
          reviewCount: null,
          rating: null,
          salesRank: 550440
        },
        {
          date: '2023-07-10',
          reviewCount: null,
          rating: null,
          salesRank: 561564
        },
        {
          date: '2023-07-11',
          reviewCount: null,
          rating: null,
          salesRank: 580590
        },
        {
          date: '2023-07-12',
          reviewCount: null,
          rating: null,
          salesRank: 600927
        },
        {
          date: '2023-07-13',
          reviewCount: null,
          rating: null,
          salesRank: 616708
        },
        {
          date: '2023-07-14',
          reviewCount: null,
          rating: null,
          salesRank: 629962
        },
        {
          date: '2023-07-15',
          reviewCount: null,
          rating: null,
          salesRank: 642286
        },
        {
          date: '2023-07-16',
          reviewCount: null,
          rating: null,
          salesRank: 656536
        },
        {
          date: '2023-07-17',
          reviewCount: null,
          rating: null,
          salesRank: 669716
        },
        {
          date: '2023-07-18',
          reviewCount: null,
          rating: null,
          salesRank: 682272
        },
        {
          date: '2023-07-19',
          reviewCount: null,
          rating: null,
          salesRank: 695909
        },
        {
          date: '2023-07-20',
          reviewCount: null,
          rating: null,
          salesRank: 707752
        },
        {
          date: '2023-07-21',
          reviewCount: null,
          rating: null,
          salesRank: 719340
        },
        {
          date: '2023-07-22',
          reviewCount: null,
          rating: null,
          salesRank: 731280
        },
        {
          date: '2023-07-23',
          reviewCount: null,
          rating: null,
          salesRank: 743116
        },
        {
          date: '2023-07-24',
          reviewCount: null,
          rating: null,
          salesRank: 753460
        },
        {
          date: '2023-07-25',
          reviewCount: null,
          rating: null,
          salesRank: 764701
        },
        {
          date: '2023-07-26',
          reviewCount: null,
          rating: null,
          salesRank: 777869
        },
        {
          date: '2023-07-27',
          reviewCount: null,
          rating: null,
          salesRank: 787124
        },
        {
          date: '2023-07-28',
          reviewCount: null,
          rating: null,
          salesRank: 801348
        },
        {
          date: '2023-07-29',
          reviewCount: null,
          rating: null,
          salesRank: 810259
        },
        {
          date: '2023-07-30',
          reviewCount: null,
          rating: null,
          salesRank: 820004
        },
        {
          date: '2023-07-31',
          reviewCount: null,
          rating: null,
          salesRank: 829920
        },
        {
          date: '2023-08-01',
          reviewCount: null,
          rating: null,
          salesRank: 839347
        },
        {
          date: '2023-08-02',
          reviewCount: null,
          rating: null,
          salesRank: 848821
        },
        {
          date: '2023-08-03',
          reviewCount: null,
          rating: null,
          salesRank: 855307
        },
        {
          date: '2023-08-04',
          reviewCount: null,
          rating: null,
          salesRank: 863946
        },
        {
          date: '2023-08-05',
          reviewCount: null,
          rating: null,
          salesRank: 870597
        },
        {
          date: '2023-08-06',
          reviewCount: null,
          rating: null,
          salesRank: 878119
        },
        {
          date: '2023-08-07',
          reviewCount: null,
          rating: null,
          salesRank: 881714
        },
        {
          date: '2023-08-08',
          reviewCount: null,
          rating: null,
          salesRank: 894619
        },
        {
          date: '2023-08-09',
          reviewCount: null,
          rating: null,
          salesRank: 900516
        },
        {
          date: '2023-08-10',
          reviewCount: null,
          rating: null,
          salesRank: 906325
        },
        {
          date: '2023-08-11',
          reviewCount: null,
          rating: null,
          salesRank: 911703
        },
        {
          date: '2023-08-12',
          reviewCount: null,
          rating: null,
          salesRank: 917552
        },
        {
          date: '2023-08-13',
          reviewCount: null,
          rating: null,
          salesRank: 922157
        },
        {
          date: '2023-08-14',
          reviewCount: null,
          rating: null,
          salesRank: 926990
        },
        {
          date: '2023-08-15',
          reviewCount: null,
          rating: null,
          salesRank: 931854
        },
        {
          date: '2023-08-16',
          reviewCount: null,
          rating: null,
          salesRank: 935760
        },
        {
          date: '2023-08-17',
          reviewCount: null,
          rating: null,
          salesRank: 940152
        },
        {
          date: '2023-08-18',
          reviewCount: null,
          rating: null,
          salesRank: 943665
        },
        {
          date: '2023-08-19',
          reviewCount: null,
          rating: null,
          salesRank: 947062
        },
        {
          date: '2023-08-20',
          reviewCount: null,
          rating: null,
          salesRank: 951114
        },
        {
          date: '2023-08-21',
          reviewCount: null,
          rating: null,
          salesRank: 954278
        },
        {
          date: '2023-08-22',
          reviewCount: null,
          rating: null,
          salesRank: 957143
        },
        {
          date: '2023-08-23',
          reviewCount: null,
          rating: null,
          salesRank: 960042
        },
        {
          date: '2023-08-24',
          reviewCount: null,
          rating: null,
          salesRank: 961775
        },
        {
          date: '2023-08-25',
          reviewCount: null,
          rating: null,
          salesRank: 963558
        },
        {
          date: '2023-08-26',
          reviewCount: null,
          rating: null,
          salesRank: 965551
        },
        {
          date: '2023-08-27',
          reviewCount: null,
          rating: null,
          salesRank: 967463
        },
        {
          date: '2023-08-28',
          reviewCount: null,
          rating: null,
          salesRank: 969797
        },
        {
          date: '2023-08-29',
          reviewCount: null,
          rating: null,
          salesRank: 972015
        },
        {
          date: '2023-08-30',
          reviewCount: null,
          rating: null,
          salesRank: 973830
        },
        {
          date: '2023-08-31',
          reviewCount: null,
          rating: null,
          salesRank: 975264
        },
        {
          date: '2023-09-01',
          reviewCount: null,
          rating: null,
          salesRank: 976448
        },
        {
          date: '2023-09-02',
          reviewCount: null,
          rating: null,
          salesRank: 977886
        },
        {
          date: '2023-09-03',
          reviewCount: null,
          rating: null,
          salesRank: 978725
        },
        {
          date: '2023-09-04',
          reviewCount: null,
          rating: null,
          salesRank: 980260
        },
        {
          date: '2023-09-05',
          reviewCount: null,
          rating: null,
          salesRank: 982789
        },
        {
          date: '2023-09-06',
          reviewCount: null,
          rating: null,
          salesRank: 984852
        },
        {
          date: '2023-09-07',
          reviewCount: null,
          rating: null,
          salesRank: 985592
        },
        {
          date: '2023-09-08',
          reviewCount: null,
          rating: null,
          salesRank: 987048
        },
        {
          date: '2023-09-09',
          reviewCount: null,
          rating: null,
          salesRank: 987332
        },
        {
          date: '2023-09-10',
          reviewCount: null,
          rating: null,
          salesRank: 988164
        },
        {
          date: '2023-09-11',
          reviewCount: null,
          rating: null,
          salesRank: 989082
        },
        {
          date: '2023-09-12',
          reviewCount: null,
          rating: null,
          salesRank: 990399
        },
        {
          date: '2023-09-13',
          reviewCount: null,
          rating: null,
          salesRank: 990859
        },
        {
          date: '2023-09-14',
          reviewCount: null,
          rating: null,
          salesRank: 991145
        },
        {
          date: '2023-09-15',
          reviewCount: null,
          rating: null,
          salesRank: 991672
        },
        {
          date: '2023-09-16',
          reviewCount: null,
          rating: null,
          salesRank: 991742
        },
        {
          date: '2023-09-17',
          reviewCount: null,
          rating: null,
          salesRank: 991805
        },
        {
          date: '2023-09-18',
          reviewCount: null,
          rating: null,
          salesRank: 992543
        },
        {
          date: '2023-09-19',
          reviewCount: null,
          rating: null,
          salesRank: 993336
        },
        {
          date: '2023-09-20',
          reviewCount: null,
          rating: null,
          salesRank: 994174
        },
        {
          date: '2023-09-21',
          reviewCount: null,
          rating: null,
          salesRank: 994810
        },
        {
          date: '2023-09-22',
          reviewCount: null,
          rating: null,
          salesRank: 995609
        },
        {
          date: '2023-09-23',
          reviewCount: null,
          rating: null,
          salesRank: 995218
        },
        {
          date: '2023-09-24',
          reviewCount: null,
          rating: null,
          salesRank: 995548
        },
        {
          date: '2023-09-25',
          reviewCount: null,
          rating: null,
          salesRank: 995681
        },
        {
          date: '2023-09-26',
          reviewCount: null,
          rating: null,
          salesRank: 996314
        },
        {
          date: '2023-09-27',
          reviewCount: null,
          rating: null,
          salesRank: 996959
        },
        {
          date: '2023-09-28',
          reviewCount: null,
          rating: null,
          salesRank: 996004
        },
        {
          date: '2023-09-29',
          reviewCount: null,
          rating: null,
          salesRank: 996450
        },
        {
          date: '2023-09-30',
          reviewCount: null,
          rating: null,
          salesRank: 996083
        },
        {
          date: '2023-10-01',
          reviewCount: null,
          rating: null,
          salesRank: 996361
        },
        {
          date: '2023-10-02',
          reviewCount: null,
          rating: null,
          salesRank: 997050
        },
        {
          date: '2023-10-03',
          reviewCount: null,
          rating: null,
          salesRank: 997888
        },
        {
          date: '2023-10-04',
          reviewCount: null,
          rating: null,
          salesRank: 996820
        },
        {
          date: '2023-10-05',
          reviewCount: null,
          rating: null,
          salesRank: 997668
        },
        {
          date: '2023-10-06',
          reviewCount: null,
          rating: null,
          salesRank: 998394
        },
        {
          date: '2023-10-07',
          reviewCount: null,
          rating: null,
          salesRank: 998982
        },
        {
          date: '2023-10-08',
          reviewCount: null,
          rating: null,
          salesRank: 998888
        },
        {
          date: '2023-10-09',
          reviewCount: null,
          rating: null,
          salesRank: 999122
        },
        {
          date: '2023-10-10',
          reviewCount: null,
          rating: null,
          salesRank: 999803
        },
        {
          date: '2023-10-11',
          reviewCount: null,
          rating: null,
          salesRank: 1000932
        },
        {
          date: '2023-10-12',
          reviewCount: null,
          rating: null,
          salesRank: 1001738
        },
        {
          date: '2023-10-13',
          reviewCount: null,
          rating: null,
          salesRank: 1003157
        },
        {
          date: '2023-10-14',
          reviewCount: null,
          rating: null,
          salesRank: 1001364
        },
        {
          date: '2023-10-15',
          reviewCount: null,
          rating: null,
          salesRank: 1001261
        },
        {
          date: '2023-10-16',
          reviewCount: null,
          rating: null,
          salesRank: 1001504
        },
        {
          date: '2023-10-17',
          reviewCount: null,
          rating: null,
          salesRank: 1001622
        },
        {
          date: '2023-10-18',
          reviewCount: null,
          rating: null,
          salesRank: 1001257
        },
        {
          date: '2023-10-19',
          reviewCount: null,
          rating: null,
          salesRank: 1000291
        },
        {
          date: '2023-10-20',
          reviewCount: null,
          rating: null,
          salesRank: 1000255
        },
        {
          date: '2023-10-21',
          reviewCount: null,
          rating: null,
          salesRank: 999918
        },
        {
          date: '2023-10-22',
          reviewCount: null,
          rating: null,
          salesRank: 999576
        },
        {
          date: '2023-10-23',
          reviewCount: null,
          rating: null,
          salesRank: 999520
        },
        {
          date: '2023-10-24',
          reviewCount: null,
          rating: null,
          salesRank: 999283
        },
        {
          date: '2023-10-25',
          reviewCount: null,
          rating: null,
          salesRank: 999099
        },
        {
          date: '2023-10-26',
          reviewCount: null,
          rating: null,
          salesRank: 999276
        },
        {
          date: '2023-10-27',
          reviewCount: null,
          rating: null,
          salesRank: 999561
        },
        {
          date: '2023-10-28',
          reviewCount: null,
          rating: null,
          salesRank: 999665
        },
        {
          date: '2023-10-29',
          reviewCount: null,
          rating: null,
          salesRank: 999971
        },
        {
          date: '2023-10-30',
          reviewCount: null,
          rating: null,
          salesRank: 1000078
        },
        {
          date: '2023-10-31',
          reviewCount: null,
          rating: null,
          salesRank: 999992
        },
        {
          date: '2023-11-01',
          reviewCount: null,
          rating: null,
          salesRank: 1000215
        },
        {
          date: '2023-11-02',
          reviewCount: null,
          rating: null,
          salesRank: 1000268
        },
        {
          date: '2023-11-03',
          reviewCount: null,
          rating: null,
          salesRank: 1000745
        },
        {
          date: '2023-11-04',
          reviewCount: null,
          rating: null,
          salesRank: 1000911
        },
        {
          date: '2023-11-05',
          reviewCount: null,
          rating: null,
          salesRank: 1001504
        },
        {
          date: '2023-11-06',
          reviewCount: null,
          rating: null,
          salesRank: 1002655
        },
        {
          date: '2023-11-07',
          reviewCount: null,
          rating: null,
          salesRank: 1002249
        },
        {
          date: '2023-11-08',
          reviewCount: null,
          rating: null,
          salesRank: 1004093
        },
        {
          date: '2023-11-09',
          reviewCount: null,
          rating: null,
          salesRank: 1004085
        },
        {
          date: '2023-11-10',
          reviewCount: null,
          rating: null,
          salesRank: 1004527
        },
        {
          date: '2023-11-11',
          reviewCount: null,
          rating: null,
          salesRank: 1004801
        },
        {
          date: '2023-11-12',
          reviewCount: null,
          rating: null,
          salesRank: 1005292
        },
        {
          date: '2023-11-13',
          reviewCount: null,
          rating: null,
          salesRank: 1005691
        },
        {
          date: '2023-11-14',
          reviewCount: null,
          rating: null,
          salesRank: 1005891
        },
        {
          date: '2023-11-15',
          reviewCount: null,
          rating: null,
          salesRank: 1005939
        },
        {
          date: '2023-11-16',
          reviewCount: null,
          rating: null,
          salesRank: 1005945
        },
        {
          date: '2023-11-17',
          reviewCount: null,
          rating: null,
          salesRank: 1005853
        },
        {
          date: '2023-11-18',
          reviewCount: null,
          rating: null,
          salesRank: 1006733
        },
        {
          date: '2023-11-19',
          reviewCount: null,
          rating: null,
          salesRank: 1007065
        },
        {
          date: '2023-11-20',
          reviewCount: null,
          rating: null,
          salesRank: 1007615
        },
        {
          date: '2023-11-21',
          reviewCount: null,
          rating: null,
          salesRank: 1007974
        },
        {
          date: '2023-11-22',
          reviewCount: null,
          rating: null,
          salesRank: 1007933
        },
        {
          date: '2023-11-23',
          reviewCount: null,
          rating: null,
          salesRank: 1007479
        },
        {
          date: '2023-11-24',
          reviewCount: null,
          rating: null,
          salesRank: 1008031
        },
        {
          date: '2023-11-25',
          reviewCount: null,
          rating: null,
          salesRank: 1009361
        },
        {
          date: '2023-11-26',
          reviewCount: null,
          rating: null,
          salesRank: 1009740
        },
        {
          date: '2023-11-27',
          reviewCount: null,
          rating: null,
          salesRank: 1010797
        },
        {
          date: '2023-11-28',
          reviewCount: null,
          rating: null,
          salesRank: 1012493
        },
        {
          date: '2023-11-29',
          reviewCount: null,
          rating: null,
          salesRank: 1011979
        },
        {
          date: '2023-11-30',
          reviewCount: null,
          rating: null,
          salesRank: 1012212
        },
        {
          date: '2023-12-01',
          reviewCount: null,
          rating: null,
          salesRank: 1012601
        },
        {
          date: '2023-12-02',
          reviewCount: null,
          rating: null,
          salesRank: 1012830
        },
        {
          date: '2023-12-03',
          reviewCount: null,
          rating: null,
          salesRank: 1013561
        },
        {
          date: '2023-12-04',
          reviewCount: null,
          rating: null,
          salesRank: 1014385
        },
        {
          date: '2023-12-05',
          reviewCount: null,
          rating: null,
          salesRank: 1015254
        },
        {
          date: '2023-12-06',
          reviewCount: null,
          rating: null,
          salesRank: 1013583
        },
        {
          date: '2023-12-07',
          reviewCount: null,
          rating: null,
          salesRank: 1015450
        },
        {
          date: '2023-12-08',
          reviewCount: null,
          rating: null,
          salesRank: 1016808
        },
        {
          date: '2023-12-09',
          reviewCount: null,
          rating: null,
          salesRank: 1017445
        },
        {
          date: '2023-12-10',
          reviewCount: null,
          rating: null,
          salesRank: 1018177
        },
        {
          date: '2023-12-11',
          reviewCount: null,
          rating: null,
          salesRank: 1018708
        },
        {
          date: '2023-12-12',
          reviewCount: null,
          rating: null,
          salesRank: 1019127
        },
        {
          date: '2023-12-13',
          reviewCount: null,
          rating: null,
          salesRank: 1019068
        },
        {
          date: '2023-12-14',
          reviewCount: null,
          rating: null,
          salesRank: 1019334
        },
        {
          date: '2023-12-15',
          reviewCount: null,
          rating: null,
          salesRank: 1019452
        },
        {
          date: '2023-12-16',
          reviewCount: null,
          rating: null,
          salesRank: 1018808
        },
        {
          date: '2023-12-17',
          reviewCount: null,
          rating: null,
          salesRank: 1018786
        },
        {
          date: '2023-12-18',
          reviewCount: null,
          rating: null,
          salesRank: 1018564
        },
        {
          date: '2023-12-19',
          reviewCount: null,
          rating: null,
          salesRank: 1018740
        },
        {
          date: '2023-12-20',
          reviewCount: null,
          rating: null,
          salesRank: 1018160
        },
        {
          date: '2023-12-21',
          reviewCount: null,
          rating: null,
          salesRank: 1018190
        },
        {
          date: '2023-12-22',
          reviewCount: null,
          rating: null,
          salesRank: 1017800
        },
        {
          date: '2023-12-23',
          reviewCount: null,
          rating: null,
          salesRank: 1017108
        },
        {
          date: '2023-12-24',
          reviewCount: null,
          rating: null,
          salesRank: 1016289
        },
        {
          date: '2023-12-25',
          reviewCount: null,
          rating: null,
          salesRank: 1015271
        },
        {
          date: '2023-12-26',
          reviewCount: null,
          rating: null,
          salesRank: 1015844
        },
        {
          date: '2023-12-27',
          reviewCount: null,
          rating: null,
          salesRank: 1016421
        },
        {
          date: '2023-12-28',
          reviewCount: null,
          rating: null,
          salesRank: 1017028
        },
        {
          date: '2023-12-29',
          reviewCount: null,
          rating: null,
          salesRank: 1017351
        },
        {
          date: '2023-12-30',
          reviewCount: null,
          rating: null,
          salesRank: 1017371
        },
        {
          date: '2023-12-31',
          reviewCount: null,
          rating: null,
          salesRank: 1017650
        },
        {
          date: '2024-01-01',
          reviewCount: null,
          rating: null,
          salesRank: 1017630
        },
        {
          date: '2024-01-02',
          reviewCount: null,
          rating: null,
          salesRank: 1017496
        },
        {
          date: '2024-01-03',
          reviewCount: null,
          rating: null,
          salesRank: 1018242
        },
        {
          date: '2024-01-04',
          reviewCount: null,
          rating: null,
          salesRank: 1018665
        },
        {
          date: '2024-01-05',
          reviewCount: null,
          rating: null,
          salesRank: 1018385
        },
        {
          date: '2024-01-06',
          reviewCount: null,
          rating: null,
          salesRank: 1018325
        },
        {
          date: '2024-01-07',
          reviewCount: null,
          rating: null,
          salesRank: 1018733
        },
        {
          date: '2024-01-08',
          reviewCount: null,
          rating: null,
          salesRank: 1019314
        },
        {
          date: '2024-01-09',
          reviewCount: null,
          rating: null,
          salesRank: 1019537
        },
        {
          date: '2024-01-10',
          reviewCount: null,
          rating: null,
          salesRank: 1019570
        },
        {
          date: '2024-01-11',
          reviewCount: null,
          rating: null,
          salesRank: 1019416
        },
        {
          date: '2024-01-12',
          reviewCount: null,
          rating: null,
          salesRank: 1019397
        },
        {
          date: '2024-01-13',
          reviewCount: null,
          rating: null,
          salesRank: 1019604
        },
        {
          date: '2024-01-14',
          reviewCount: null,
          rating: null,
          salesRank: 1019644
        },
        {
          date: '2024-01-15',
          reviewCount: null,
          rating: null,
          salesRank: 1022082
        },
        {
          date: '2024-01-16',
          reviewCount: null,
          rating: null,
          salesRank: 1021898
        },
        {
          date: '2024-01-17',
          reviewCount: null,
          rating: null,
          salesRank: 1021721
        },
        {
          date: '2024-01-18',
          reviewCount: null,
          rating: null,
          salesRank: 1021414
        },
        {
          date: '2024-01-19',
          reviewCount: null,
          rating: null,
          salesRank: 1021452
        },
        {
          date: '2024-01-20',
          reviewCount: null,
          rating: null,
          salesRank: 1021624
        },
        {
          date: '2024-01-21',
          reviewCount: null,
          rating: null,
          salesRank: 1021875
        },
        {
          date: '2024-01-22',
          reviewCount: null,
          rating: null,
          salesRank: 1022082
        },
        {
          date: '2024-01-23',
          reviewCount: null,
          rating: null,
          salesRank: 1022433
        },
        {
          date: '2024-01-24',
          reviewCount: null,
          rating: null,
          salesRank: 1022779
        },
        {
          date: '2024-01-25',
          reviewCount: null,
          rating: null,
          salesRank: 1022972
        },
        {
          date: '2024-01-26',
          reviewCount: null,
          rating: null,
          salesRank: 1023189
        },
        {
          date: '2024-01-27',
          reviewCount: null,
          rating: null,
          salesRank: 1023692
        },
        {
          date: '2024-01-28',
          reviewCount: null,
          rating: null,
          salesRank: 1024637
        },
        {
          date: '2024-01-29',
          reviewCount: null,
          rating: null,
          salesRank: 1025635
        },
        {
          date: '2024-01-30',
          reviewCount: null,
          rating: null,
          salesRank: 1026797
        },
        {
          date: '2024-01-31',
          reviewCount: null,
          rating: null,
          salesRank: 1028459
        },
        {
          date: '2024-02-01',
          reviewCount: null,
          rating: null,
          salesRank: 1030344
        },
        {
          date: '2024-02-02',
          reviewCount: null,
          rating: null,
          salesRank: 1031728
        },
        {
          date: '2024-02-03',
          reviewCount: null,
          rating: null,
          salesRank: 1033143
        },
        {
          date: '2024-02-04',
          reviewCount: null,
          rating: null,
          salesRank: 1034794
        },
        {
          date: '2024-02-05',
          reviewCount: null,
          rating: null,
          salesRank: 1035495
        },
        {
          date: '2024-02-06',
          reviewCount: null,
          rating: null,
          salesRank: 1037745
        },
        {
          date: '2024-02-07',
          reviewCount: null,
          rating: null,
          salesRank: 1038738
        },
        {
          date: '2024-02-08',
          reviewCount: null,
          rating: null,
          salesRank: 1039553
        },
        {
          date: '2024-02-09',
          reviewCount: null,
          rating: null,
          salesRank: 1040758
        },
        {
          date: '2024-02-10',
          reviewCount: null,
          rating: null,
          salesRank: 1042049
        },
        {
          date: '2024-02-11',
          reviewCount: null,
          rating: null,
          salesRank: 1042592
        },
        {
          date: '2024-02-12',
          reviewCount: null,
          rating: null,
          salesRank: 1043436
        },
        {
          date: '2024-02-13',
          reviewCount: null,
          rating: null,
          salesRank: 1036135
        },
        {
          date: '2024-02-14',
          reviewCount: null,
          rating: null,
          salesRank: 1036949
        },
        {
          date: '2024-02-15',
          reviewCount: null,
          rating: null,
          salesRank: 1037189
        },
        {
          date: '2024-02-16',
          reviewCount: null,
          rating: null,
          salesRank: 1037869
        },
        {
          date: '2024-02-17',
          reviewCount: null,
          rating: null,
          salesRank: 1038577
        },
        {
          date: '2024-02-18',
          reviewCount: null,
          rating: null,
          salesRank: 1039464
        },
        {
          date: '2024-02-19',
          reviewCount: null,
          rating: null,
          salesRank: 1040198
        },
        {
          date: '2024-02-20',
          reviewCount: null,
          rating: null,
          salesRank: 1041751
        },
        {
          date: '2024-02-21',
          reviewCount: null,
          rating: null,
          salesRank: 1042327
        },
        {
          date: '2024-02-22',
          reviewCount: null,
          rating: null,
          salesRank: 1043180
        },
        {
          date: '2024-02-23',
          reviewCount: null,
          rating: null,
          salesRank: 1044673
        },
        {
          date: '2024-02-24',
          reviewCount: null,
          rating: null,
          salesRank: 1045661
        },
        {
          date: '2024-02-25',
          reviewCount: null,
          rating: null,
          salesRank: 1047179
        },
        {
          date: '2024-02-26',
          reviewCount: null,
          rating: null,
          salesRank: 1049441
        },
        {
          date: '2024-02-27',
          reviewCount: null,
          rating: null,
          salesRank: 182624
        },
        {
          date: '2024-02-28',
          reviewCount: null,
          rating: null,
          salesRank: 212272
        },
        {
          date: '2024-02-29',
          reviewCount: null,
          rating: null,
          salesRank: 20149
        },
        {
          date: '2024-03-01',
          reviewCount: null,
          rating: null,
          salesRank: 10959
        },
        {
          date: '2024-03-02',
          reviewCount: null,
          rating: null,
          salesRank: 14935
        },
        {
          date: '2024-03-03',
          reviewCount: null,
          rating: null,
          salesRank: 12538
        },
        {
          date: '2024-03-04',
          reviewCount: null,
          rating: null,
          salesRank: 9049
        },
        {
          date: '2024-03-05',
          reviewCount: null,
          rating: null,
          salesRank: 9478
        },
        {
          date: '2024-03-06',
          reviewCount: null,
          rating: null,
          salesRank: 12138
        },
        {
          date: '2024-03-07',
          reviewCount: null,
          rating: null,
          salesRank: 14269
        },
        {
          date: '2024-03-08',
          reviewCount: null,
          rating: null,
          salesRank: 10588
        },
        {
          date: '2024-03-09',
          reviewCount: null,
          rating: null,
          salesRank: 2046
        },
        {
          date: '2024-03-10',
          reviewCount: null,
          rating: null,
          salesRank: 2623
        },
        {
          date: '2024-03-11',
          reviewCount: null,
          rating: null,
          salesRank: 2404
        },
        {
          date: '2024-03-12',
          reviewCount: null,
          rating: null,
          salesRank: 2076
        },
        {
          date: '2024-03-13',
          reviewCount: null,
          rating: null,
          salesRank: 2744
        },
        {
          date: '2024-03-14',
          reviewCount: null,
          rating: null,
          salesRank: 2827
        },
        {
          date: '2024-03-15',
          reviewCount: null,
          rating: null,
          salesRank: 2465
        },
        {
          date: '2024-03-16',
          reviewCount: null,
          rating: null,
          salesRank: 2398
        },
        {
          date: '2024-03-17',
          reviewCount: null,
          rating: null,
          salesRank: 3326
        },
        {
          date: '2024-03-18',
          reviewCount: null,
          rating: null,
          salesRank: 2698
        },
        {
          date: '2024-03-19',
          reviewCount: null,
          rating: null,
          salesRank: 3232
        },
        {
          date: '2024-03-20',
          reviewCount: null,
          rating: null,
          salesRank: 8168
        },
        {
          date: '2024-03-21',
          reviewCount: null,
          rating: null,
          salesRank: 4600
        },
        {
          date: '2024-03-22',
          reviewCount: null,
          rating: null,
          salesRank: 2317
        },
        {
          date: '2024-03-23',
          reviewCount: null,
          rating: null,
          salesRank: 2046
        },
        {
          date: '2024-03-24',
          reviewCount: null,
          rating: null,
          salesRank: 2227
        },
        {
          date: '2024-03-25',
          reviewCount: null,
          rating: null,
          salesRank: 1518
        },
        {
          date: '2024-03-26',
          reviewCount: null,
          rating: null,
          salesRank: 1583
        },
        {
          date: '2024-03-27',
          reviewCount: null,
          rating: null,
          salesRank: 1772
        },
        {
          date: '2024-03-28',
          reviewCount: null,
          rating: null,
          salesRank: 1132
        },
        {
          date: '2024-03-29',
          reviewCount: null,
          rating: null,
          salesRank: 1708
        },
        {
          date: '2024-03-30',
          reviewCount: null,
          rating: null,
          salesRank: 1805
        },
        {
          date: '2024-03-31',
          reviewCount: null,
          rating: null,
          salesRank: 1746
        },
        {
          date: '2024-04-01',
          reviewCount: null,
          rating: null,
          salesRank: 1814
        },
        {
          date: '2024-04-02',
          reviewCount: null,
          rating: null,
          salesRank: 1810
        },
        {
          date: '2024-04-03',
          reviewCount: null,
          rating: null,
          salesRank: 1405
        },
        {
          date: '2024-04-04',
          reviewCount: null,
          rating: null,
          salesRank: 1329
        },
        {
          date: '2024-04-05',
          reviewCount: null,
          rating: null,
          salesRank: 1157
        },
        {
          date: '2024-04-06',
          reviewCount: null,
          rating: null,
          salesRank: 1569
        },
        {
          date: '2024-04-07',
          reviewCount: null,
          rating: null,
          salesRank: 1984
        },
        {
          date: '2024-04-08',
          reviewCount: null,
          rating: null,
          salesRank: 1741
        },
        {
          date: '2024-04-09',
          reviewCount: null,
          rating: null,
          salesRank: 2565
        },
        {
          date: '2024-04-10',
          reviewCount: null,
          rating: null,
          salesRank: 2725
        },
        {
          date: '2024-04-11',
          reviewCount: null,
          rating: null,
          salesRank: 2642
        },
        {
          date: '2024-04-12',
          reviewCount: null,
          rating: null,
          salesRank: 2038
        },
        {
          date: '2024-04-13',
          reviewCount: null,
          rating: null,
          salesRank: 3045
        },
        {
          date: '2024-04-14',
          reviewCount: null,
          rating: null,
          salesRank: 3577
        },
        {
          date: '2024-04-15',
          reviewCount: null,
          rating: null,
          salesRank: 3443
        },
        {
          date: '2024-04-16',
          reviewCount: null,
          rating: null,
          salesRank: 3965
        },
        {
          date: '2024-04-17',
          reviewCount: null,
          rating: null,
          salesRank: 3035
        },
        {
          date: '2024-04-18',
          reviewCount: null,
          rating: null,
          salesRank: 3962
        },
        {
          date: '2024-04-19',
          reviewCount: null,
          rating: null,
          salesRank: 3236
        },
        {
          date: '2024-04-20',
          reviewCount: null,
          rating: null,
          salesRank: 4041
        },
        {
          date: '2024-04-21',
          reviewCount: null,
          rating: null,
          salesRank: 4014
        },
        {
          date: '2024-04-22',
          reviewCount: null,
          rating: null,
          salesRank: 3599
        },
        {
          date: '2024-04-23',
          reviewCount: null,
          rating: null,
          salesRank: 2601
        },
        {
          date: '2024-04-24',
          reviewCount: null,
          rating: null,
          salesRank: 2239
        },
        {
          date: '2024-04-25',
          reviewCount: null,
          rating: null,
          salesRank: 2290
        },
        {
          date: '2024-04-26',
          reviewCount: null,
          rating: null,
          salesRank: 1654
        },
        {
          date: '2024-04-27',
          reviewCount: null,
          rating: null,
          salesRank: 1970
        },
        {
          date: '2024-04-28',
          reviewCount: null,
          rating: null,
          salesRank: 2263
        },
        {
          date: '2024-04-29',
          reviewCount: null,
          rating: null,
          salesRank: 2934
        },
        {
          date: '2024-04-30',
          reviewCount: null,
          rating: null,
          salesRank: 3009
        },
        {
          date: '2024-05-01',
          reviewCount: null,
          rating: null,
          salesRank: 2629
        },
        {
          date: '2024-05-02',
          reviewCount: null,
          rating: null,
          salesRank: 2465
        },
        {
          date: '2024-05-03',
          reviewCount: null,
          rating: null,
          salesRank: 2823
        },
        {
          date: '2024-05-04',
          reviewCount: null,
          rating: null,
          salesRank: 3184
        },
        {
          date: '2024-05-05',
          reviewCount: null,
          rating: null,
          salesRank: 2725
        },
        {
          date: '2024-05-06',
          reviewCount: null,
          rating: null,
          salesRank: 3257
        },
        {
          date: '2024-05-07',
          reviewCount: null,
          rating: null,
          salesRank: 3197
        },
        {
          date: '2024-05-08',
          reviewCount: null,
          rating: null,
          salesRank: 3064
        },
        {
          date: '2024-05-09',
          reviewCount: null,
          rating: null,
          salesRank: 2759
        },
        {
          date: '2024-05-10',
          reviewCount: null,
          rating: null,
          salesRank: 2626
        },
        {
          date: '2024-05-11',
          reviewCount: null,
          rating: null,
          salesRank: 2459
        },
        {
          date: '2024-05-12',
          reviewCount: null,
          rating: null,
          salesRank: 2625
        },
        {
          date: '2024-05-13',
          reviewCount: null,
          rating: null,
          salesRank: 3461
        },
        {
          date: '2024-05-14',
          reviewCount: null,
          rating: null,
          salesRank: 3202
        },
        {
          date: '2024-05-15',
          reviewCount: null,
          rating: null,
          salesRank: 2678
        },
        {
          date: '2024-05-16',
          reviewCount: null,
          rating: null,
          salesRank: 2613
        },
        {
          date: '2024-05-17',
          reviewCount: null,
          rating: null,
          salesRank: 2426
        },
        {
          date: '2024-05-18',
          reviewCount: null,
          rating: null,
          salesRank: 3083
        },
        {
          date: '2024-05-19',
          reviewCount: null,
          rating: null,
          salesRank: 3689
        },
        {
          date: '2024-05-20',
          reviewCount: null,
          rating: null,
          salesRank: 3891
        },
        {
          date: '2024-05-21',
          reviewCount: null,
          rating: null,
          salesRank: 3389
        },
        {
          date: '2024-05-22',
          reviewCount: null,
          rating: null,
          salesRank: 3665
        },
        {
          date: '2024-05-23',
          reviewCount: null,
          rating: null,
          salesRank: 3047
        },
        {
          date: '2024-05-24',
          reviewCount: null,
          rating: null,
          salesRank: 3227
        },
        {
          date: '2024-05-25',
          reviewCount: null,
          rating: null,
          salesRank: 3849
        },
        {
          date: '2024-05-26',
          reviewCount: null,
          rating: null,
          salesRank: 4314
        },
        {
          date: '2024-05-27',
          reviewCount: null,
          rating: null,
          salesRank: 4098
        },
        {
          date: '2024-05-28',
          reviewCount: null,
          rating: null,
          salesRank: 4149
        },
        {
          date: '2024-05-29',
          reviewCount: null,
          rating: null,
          salesRank: 3983
        },
        {
          date: '2024-05-30',
          reviewCount: null,
          rating: null,
          salesRank: 3646
        },
        {
          date: '2024-05-31',
          reviewCount: null,
          rating: null,
          salesRank: 3430
        },
        {
          date: '2024-06-01',
          reviewCount: null,
          rating: null,
          salesRank: 3291
        },
        {
          date: '2024-06-02',
          reviewCount: null,
          rating: null,
          salesRank: 3656
        },
        {
          date: '2024-06-03',
          reviewCount: null,
          rating: null,
          salesRank: 3905
        },
        {
          date: '2024-06-04',
          reviewCount: null,
          rating: null,
          salesRank: 3097
        },
        {
          date: '2024-06-05',
          reviewCount: null,
          rating: null,
          salesRank: 3754
        },
        {
          date: '2024-06-06',
          reviewCount: null,
          rating: null,
          salesRank: 3273
        },
        {
          date: '2024-06-07',
          reviewCount: null,
          rating: null,
          salesRank: 3778
        },
        {
          date: '2024-06-08',
          reviewCount: null,
          rating: null,
          salesRank: 3909
        },
        {
          date: '2024-06-09',
          reviewCount: null,
          rating: null,
          salesRank: 3528
        },
        {
          date: '2024-06-10',
          reviewCount: null,
          rating: null,
          salesRank: 3415
        },
        {
          date: '2024-06-11',
          reviewCount: null,
          rating: null,
          salesRank: 3509
        },
        {
          date: '2024-06-12',
          reviewCount: null,
          rating: null,
          salesRank: 3403
        },
        {
          date: '2024-06-13',
          reviewCount: null,
          rating: null,
          salesRank: 3994
        },
        {
          date: '2024-06-14',
          reviewCount: null,
          rating: null,
          salesRank: 3939
        },
        {
          date: '2024-06-15',
          reviewCount: null,
          rating: null,
          salesRank: 4345
        },
        {
          date: '2024-06-16',
          reviewCount: null,
          rating: null,
          salesRank: 4583
        },
        {
          date: '2024-06-17',
          reviewCount: null,
          rating: null,
          salesRank: 4822
        },
        {
          date: '2024-06-18',
          reviewCount: null,
          rating: null,
          salesRank: 4496
        },
        {
          date: '2024-06-19',
          reviewCount: null,
          rating: null,
          salesRank: 4389
        },
        {
          date: '2024-06-20',
          reviewCount: null,
          rating: null,
          salesRank: 4169
        },
        {
          date: '2024-06-21',
          reviewCount: null,
          rating: null,
          salesRank: 4400
        },
        {
          date: '2024-06-22',
          reviewCount: null,
          rating: null,
          salesRank: 4115
        },
        {
          date: '2024-06-23',
          reviewCount: null,
          rating: null,
          salesRank: 4883
        },
        {
          date: '2024-06-24',
          reviewCount: null,
          rating: null,
          salesRank: 5026
        },
        {
          date: '2024-06-25',
          reviewCount: null,
          rating: null,
          salesRank: 6283
        },
        {
          date: '2024-06-26',
          reviewCount: null,
          rating: null,
          salesRank: 5362
        },
        {
          date: '2024-06-27',
          reviewCount: null,
          rating: null,
          salesRank: 4425
        },
        {
          date: '2024-06-28',
          reviewCount: null,
          rating: null,
          salesRank: 3835
        },
        {
          date: '2024-06-29',
          reviewCount: null,
          rating: null,
          salesRank: 4342
        },
        {
          date: '2024-06-30',
          reviewCount: null,
          rating: null,
          salesRank: 4257
        },
        {
          date: '2024-07-01',
          reviewCount: null,
          rating: null,
          salesRank: 4352
        },
        {
          date: '2024-07-02',
          reviewCount: null,
          rating: null,
          salesRank: 4324
        },
        {
          date: '2024-07-03',
          reviewCount: null,
          rating: null,
          salesRank: 3866
        },
        {
          date: '2024-07-04',
          reviewCount: null,
          rating: null,
          salesRank: 3438
        },
        {
          date: '2024-07-05',
          reviewCount: null,
          rating: null,
          salesRank: 5031
        },
        {
          date: '2024-07-06',
          reviewCount: null,
          rating: null,
          salesRank: 5202
        },
        {
          date: '2024-07-07',
          reviewCount: null,
          rating: null,
          salesRank: 4531
        },
        {
          date: '2024-07-08',
          reviewCount: null,
          rating: null,
          salesRank: 4381
        },
        {
          date: '2024-07-09',
          reviewCount: null,
          rating: null,
          salesRank: 4764
        },
        {
          date: '2024-07-10',
          reviewCount: null,
          rating: null,
          salesRank: 4158
        },
        {
          date: '2024-07-11',
          reviewCount: null,
          rating: null,
          salesRank: 4616
        },
        {
          date: '2024-07-12',
          reviewCount: null,
          rating: null,
          salesRank: 3758
        },
        {
          date: '2024-07-13',
          reviewCount: null,
          rating: null,
          salesRank: 3562
        },
        {
          date: '2024-07-14',
          reviewCount: null,
          rating: null,
          salesRank: 4422
        },
        {
          date: '2024-07-15',
          reviewCount: null,
          rating: null,
          salesRank: 4947
        },
        {
          date: '2024-07-16',
          reviewCount: null,
          rating: null,
          salesRank: 4845
        },
        {
          date: '2024-07-17',
          reviewCount: null,
          rating: null,
          salesRank: 5689
        },
        {
          date: '2024-07-18',
          reviewCount: null,
          rating: null,
          salesRank: 4968
        },
        {
          date: '2024-07-19',
          reviewCount: null,
          rating: null,
          salesRank: 4191
        },
        {
          date: '2024-07-20',
          reviewCount: null,
          rating: null,
          salesRank: 3823
        },
        {
          date: '2024-07-21',
          reviewCount: null,
          rating: null,
          salesRank: 4176
        },
        {
          date: '2024-07-22',
          reviewCount: null,
          rating: null,
          salesRank: 4843
        },
        {
          date: '2024-07-23',
          reviewCount: null,
          rating: null,
          salesRank: 4279
        },
        {
          date: '2024-07-24',
          reviewCount: null,
          rating: null,
          salesRank: 3924
        },
        {
          date: '2024-07-25',
          reviewCount: null,
          rating: null,
          salesRank: 3950
        },
        {
          date: '2024-07-26',
          reviewCount: null,
          rating: null,
          salesRank: 2629
        },
        {
          date: '2024-07-27',
          reviewCount: null,
          rating: null,
          salesRank: 2888
        },
        {
          date: '2024-07-28',
          reviewCount: null,
          rating: null,
          salesRank: 4043
        },
        {
          date: '2024-07-29',
          reviewCount: null,
          rating: null,
          salesRank: 3452
        },
        {
          date: '2024-07-30',
          reviewCount: null,
          rating: null,
          salesRank: 2984
        },
        {
          date: '2024-07-31',
          reviewCount: null,
          rating: null,
          salesRank: 3036
        },
        {
          date: '2024-08-01',
          reviewCount: null,
          rating: null,
          salesRank: 2961
        },
        {
          date: '2024-08-02',
          reviewCount: null,
          rating: null,
          salesRank: 2972
        },
        {
          date: '2024-08-03',
          reviewCount: null,
          rating: null,
          salesRank: 3078
        },
        {
          date: '2024-08-04',
          reviewCount: null,
          rating: null,
          salesRank: 3342
        },
        {
          date: '2024-08-05',
          reviewCount: null,
          rating: null,
          salesRank: 2964
        },
        {
          date: '2024-08-06',
          reviewCount: null,
          rating: null,
          salesRank: 2606
        },
        {
          date: '2024-08-07',
          reviewCount: null,
          rating: null,
          salesRank: 2860
        },
        {
          date: '2024-08-09',
          reviewCount: null,
          rating: null,
          salesRank: 2658
        },
        {
          date: '2024-08-10',
          reviewCount: null,
          rating: null,
          salesRank: 3202
        },
        {
          date: '2024-08-11',
          reviewCount: null,
          rating: null,
          salesRank: 4023
        },
        {
          date: '2024-08-12',
          reviewCount: null,
          rating: null,
          salesRank: 3683
        },
        {
          date: '2024-08-13',
          reviewCount: null,
          rating: null,
          salesRank: 3024
        },
        {
          date: '2024-08-14',
          reviewCount: null,
          rating: null,
          salesRank: 2951
        },
        {
          date: '2024-08-15',
          reviewCount: null,
          rating: null,
          salesRank: 2546
        },
        {
          date: '2024-08-16',
          reviewCount: null,
          rating: null,
          salesRank: 2070
        },
        {
          date: '2024-08-19',
          reviewCount: null,
          rating: null,
          salesRank: 2494
        },
        {
          date: '2024-08-20',
          reviewCount: null,
          rating: null,
          salesRank: 2656
        },
        {
          date: '2024-08-21',
          reviewCount: null,
          rating: null,
          salesRank: 2502
        },
        {
          date: '2024-08-22',
          reviewCount: null,
          rating: null,
          salesRank: 2383
        },
        {
          date: '2024-08-23',
          reviewCount: null,
          rating: null,
          salesRank: 2250
        },
        {
          date: '2024-08-24',
          reviewCount: null,
          rating: null,
          salesRank: 2847
        },
        {
          date: '2024-08-25',
          reviewCount: null,
          rating: null,
          salesRank: 2680
        },
        {
          date: '2024-08-26',
          reviewCount: null,
          rating: null,
          salesRank: 2616
        },
        {
          date: '2024-08-27',
          reviewCount: null,
          rating: null,
          salesRank: 2381
        },
        {
          date: '2024-08-28',
          reviewCount: null,
          rating: null,
          salesRank: 3007
        },
        {
          date: '2024-08-29',
          reviewCount: null,
          rating: null,
          salesRank: 2860
        },
        {
          date: '2024-08-30',
          reviewCount: null,
          rating: null,
          salesRank: 2064
        },
        {
          date: '2024-08-31',
          reviewCount: null,
          rating: null,
          salesRank: 2832
        },
        {
          date: '2024-09-01',
          reviewCount: null,
          rating: null,
          salesRank: 2511
        },
        {
          date: '2024-09-02',
          reviewCount: null,
          rating: null,
          salesRank: 2727
        },
        {
          date: '2024-09-03',
          reviewCount: null,
          rating: null,
          salesRank: 2281
        },
        {
          date: '2024-09-04',
          reviewCount: null,
          rating: null,
          salesRank: 2565
        },
        {
          date: '2024-09-05',
          reviewCount: null,
          rating: null,
          salesRank: 2096
        },
        {
          date: '2024-09-06',
          reviewCount: null,
          rating: null,
          salesRank: 2237
        },
        {
          date: '2024-09-07',
          reviewCount: null,
          rating: null,
          salesRank: 1790
        },
        {
          date: '2024-09-08',
          reviewCount: null,
          rating: null,
          salesRank: 2919
        },
        {
          date: '2024-09-09',
          reviewCount: null,
          rating: null,
          salesRank: 2711
        },
        {
          date: '2024-09-10',
          reviewCount: null,
          rating: null,
          salesRank: 2053
        },
        {
          date: '2024-09-11',
          reviewCount: null,
          rating: null,
          salesRank: 2448
        },
        {
          date: '2024-09-12',
          reviewCount: null,
          rating: null,
          salesRank: 1809
        },
        {
          date: '2024-09-13',
          reviewCount: null,
          rating: null,
          salesRank: 1683
        },
        {
          date: '2024-09-14',
          reviewCount: null,
          rating: null,
          salesRank: 1840
        },
        {
          date: '2024-09-15',
          reviewCount: null,
          rating: null,
          salesRank: 2416
        },
        {
          date: '2024-09-16',
          reviewCount: null,
          rating: null,
          salesRank: 2662
        },
        {
          date: '2024-09-17',
          reviewCount: null,
          rating: null,
          salesRank: 2348
        },
        {
          date: '2024-09-18',
          reviewCount: null,
          rating: null,
          salesRank: 1927
        },
        {
          date: '2024-09-19',
          reviewCount: null,
          rating: null,
          salesRank: 2155
        },
        {
          date: '2024-09-20',
          reviewCount: null,
          rating: null,
          salesRank: 2108
        },
        {
          date: '2024-09-21',
          reviewCount: null,
          rating: null,
          salesRank: 2098
        },
        {
          date: '2024-09-22',
          reviewCount: null,
          rating: null,
          salesRank: 2332
        },
        {
          date: '2024-09-23',
          reviewCount: null,
          rating: null,
          salesRank: 2039
        },
        {
          date: '2024-09-24',
          reviewCount: null,
          rating: null,
          salesRank: 2228
        },
        {
          date: '2024-09-25',
          reviewCount: null,
          rating: null,
          salesRank: 2200
        },
        {
          date: '2024-09-26',
          reviewCount: null,
          rating: null,
          salesRank: 2097
        },
        {
          date: '2024-09-27',
          reviewCount: null,
          rating: null,
          salesRank: 1696
        },
        {
          date: '2024-09-28',
          reviewCount: null,
          rating: null,
          salesRank: 2260
        },
        {
          date: '2024-09-29',
          reviewCount: null,
          rating: null,
          salesRank: 2725
        },
        {
          date: '2024-09-30',
          reviewCount: null,
          rating: null,
          salesRank: 1947
        },
        {
          date: '2024-10-01',
          reviewCount: null,
          rating: null,
          salesRank: 1802
        },
        {
          date: '2024-10-02',
          reviewCount: null,
          rating: null,
          salesRank: 1934
        },
        {
          date: '2024-10-03',
          reviewCount: null,
          rating: null,
          salesRank: 1738
        },
        {
          date: '2024-10-04',
          reviewCount: null,
          rating: null,
          salesRank: 2001
        },
        {
          date: '2024-10-05',
          reviewCount: null,
          rating: null,
          salesRank: 1839
        },
        {
          date: '2024-10-06',
          reviewCount: null,
          rating: null,
          salesRank: 1930
        },
        {
          date: '2024-10-07',
          reviewCount: null,
          rating: null,
          salesRank: 1996
        },
        {
          date: '2024-10-08',
          reviewCount: null,
          rating: null,
          salesRank: 1095
        },
        {
          date: '2024-10-09',
          reviewCount: null,
          rating: null,
          salesRank: 973
        },
        {
          date: '2024-10-10',
          reviewCount: null,
          rating: null,
          salesRank: 7557
        },
        {
          date: '2024-10-11',
          reviewCount: null,
          rating: null,
          salesRank: 6855
        },
        {
          date: '2024-10-12',
          reviewCount: null,
          rating: null,
          salesRank: 7566
        },
        {
          date: '2024-10-13',
          reviewCount: null,
          rating: null,
          salesRank: 7133
        },
        {
          date: '2024-10-14',
          reviewCount: null,
          rating: null,
          salesRank: 8398
        },
        {
          date: '2024-10-15',
          reviewCount: null,
          rating: null,
          salesRank: 7107
        },
        {
          date: '2024-10-16',
          reviewCount: null,
          rating: null,
          salesRank: 7605
        },
        {
          date: '2024-10-17',
          reviewCount: null,
          rating: null,
          salesRank: 6533
        },
        {
          date: '2024-10-18',
          reviewCount: null,
          rating: null,
          salesRank: 5690
        },
        {
          date: '2024-10-19',
          reviewCount: null,
          rating: null,
          salesRank: 6297
        },
        {
          date: '2024-10-20',
          reviewCount: null,
          rating: null,
          salesRank: 7701
        },
        {
          date: '2024-10-21',
          reviewCount: null,
          rating: null,
          salesRank: 10276
        },
        {
          date: '2024-10-22',
          reviewCount: null,
          rating: null,
          salesRank: 9578
        },
        {
          date: '2024-10-23',
          reviewCount: null,
          rating: null,
          salesRank: 9055
        },
        {
          date: '2024-10-24',
          reviewCount: null,
          rating: null,
          salesRank: 6890
        },
        {
          date: '2024-10-25',
          reviewCount: null,
          rating: null,
          salesRank: 7502
        },
        {
          date: '2024-10-26',
          reviewCount: null,
          rating: null,
          salesRank: 7174
        },
        {
          date: '2024-10-27',
          reviewCount: null,
          rating: null,
          salesRank: 8172
        },
        {
          date: '2024-10-28',
          reviewCount: null,
          rating: null,
          salesRank: 8895
        },
        {
          date: '2024-10-29',
          reviewCount: null,
          rating: null,
          salesRank: 8140
        },
        {
          date: '2024-10-30',
          reviewCount: null,
          rating: null,
          salesRank: 7930
        },
        {
          date: '2024-10-31',
          reviewCount: null,
          rating: null,
          salesRank: 8040
        },
        {
          date: '2024-11-01',
          reviewCount: null,
          rating: null,
          salesRank: 8161
        },
        {
          date: '2024-11-02',
          reviewCount: null,
          rating: null,
          salesRank: 8653
        },
        {
          date: '2024-11-03',
          reviewCount: null,
          rating: null,
          salesRank: 9469
        },
        {
          date: '2024-11-04',
          reviewCount: null,
          rating: null,
          salesRank: 9633
        },
        {
          date: '2024-11-05',
          reviewCount: null,
          rating: null,
          salesRank: 9901
        },
        {
          date: '2024-11-06',
          reviewCount: null,
          rating: null,
          salesRank: 11889
        },
        {
          date: '2024-11-07',
          reviewCount: null,
          rating: null,
          salesRank: 10800
        },
        {
          date: '2024-11-08',
          reviewCount: null,
          rating: null,
          salesRank: 9912
        },
        {
          date: '2024-11-09',
          reviewCount: null,
          rating: null,
          salesRank: 8094
        },
        {
          date: '2024-11-10',
          reviewCount: null,
          rating: null,
          salesRank: 10527
        },
        {
          date: '2024-11-11',
          reviewCount: null,
          rating: null,
          salesRank: 12863
        },
        {
          date: '2024-11-12',
          reviewCount: null,
          rating: null,
          salesRank: 13561
        },
        {
          date: '2024-11-13',
          reviewCount: null,
          rating: null,
          salesRank: 10417
        },
        {
          date: '2024-11-14',
          reviewCount: null,
          rating: null,
          salesRank: 10201
        },
        {
          date: '2024-11-15',
          reviewCount: null,
          rating: null,
          salesRank: 10773
        },
        {
          date: '2024-11-16',
          reviewCount: null,
          rating: null,
          salesRank: 11230
        },
        {
          date: '2024-11-17',
          reviewCount: null,
          rating: null,
          salesRank: 12029
        },
        {
          date: '2024-11-18',
          reviewCount: null,
          rating: null,
          salesRank: 13020
        },
        {
          date: '2024-11-19',
          reviewCount: null,
          rating: null,
          salesRank: 15935
        },
        {
          date: '2024-11-20',
          reviewCount: null,
          rating: null,
          salesRank: 14873
        },
        {
          date: '2024-11-21',
          reviewCount: null,
          rating: null,
          salesRank: 8228
        },
        {
          date: '2024-11-22',
          reviewCount: null,
          rating: null,
          salesRank: 6781
        },
        {
          date: '2024-11-23',
          reviewCount: null,
          rating: null,
          salesRank: 7738
        },
        {
          date: '2024-11-24',
          reviewCount: null,
          rating: null,
          salesRank: 9272
        },
        {
          date: '2024-11-25',
          reviewCount: null,
          rating: null,
          salesRank: 8892
        },
        {
          date: '2024-11-26',
          reviewCount: null,
          rating: null,
          salesRank: 8838
        },
        {
          date: '2024-11-27',
          reviewCount: null,
          rating: null,
          salesRank: 8388
        },
        {
          date: '2024-11-28',
          reviewCount: null,
          rating: null,
          salesRank: 8096
        },
        {
          date: '2024-11-29',
          reviewCount: null,
          rating: null,
          salesRank: 5455
        },
        {
          date: '2024-11-30',
          reviewCount: null,
          rating: null,
          salesRank: 5415
        },
        {
          date: '2024-12-01',
          reviewCount: null,
          rating: null,
          salesRank: 5511
        },
        {
          date: '2024-12-02',
          reviewCount: null,
          rating: null,
          salesRank: 5125
        },
        {
          date: '2024-12-03',
          reviewCount: null,
          rating: null,
          salesRank: 6339
        },
        {
          date: '2024-12-04',
          reviewCount: null,
          rating: null,
          salesRank: 6918
        },
        {
          date: '2024-12-05',
          reviewCount: null,
          rating: null,
          salesRank: 6910
        },
        {
          date: '2024-12-06',
          reviewCount: null,
          rating: null,
          salesRank: 7168
        },
        {
          date: '2024-12-07',
          reviewCount: null,
          rating: null,
          salesRank: 8540
        },
        {
          date: '2024-12-08',
          reviewCount: null,
          rating: null,
          salesRank: 9070
        },
        {
          date: '2024-12-09',
          reviewCount: null,
          rating: null,
          salesRank: 9740
        },
        {
          date: '2024-12-10',
          reviewCount: null,
          rating: null,
          salesRank: 10911
        },
        {
          date: '2024-12-11',
          reviewCount: null,
          rating: null,
          salesRank: 10982
        },
        {
          date: '2024-12-12',
          reviewCount: null,
          rating: null,
          salesRank: 10928
        },
        {
          date: '2024-12-13',
          reviewCount: null,
          rating: null,
          salesRank: 9320
        },
        {
          date: '2024-12-14',
          reviewCount: null,
          rating: null,
          salesRank: 9417
        },
        {
          date: '2024-12-15',
          reviewCount: null,
          rating: null,
          salesRank: 9882
        },
        {
          date: '2024-12-16',
          reviewCount: null,
          rating: null,
          salesRank: 11294
        },
        {
          date: '2024-12-17',
          reviewCount: null,
          rating: null,
          salesRank: 11311
        },
        {
          date: '2024-12-18',
          reviewCount: null,
          rating: null,
          salesRank: 12513
        },
        {
          date: '2024-12-19',
          reviewCount: null,
          rating: null,
          salesRank: 11363
        },
        {
          date: '2024-12-20',
          reviewCount: null,
          rating: null,
          salesRank: 10410
        },
        {
          date: '2024-12-21',
          reviewCount: null,
          rating: null,
          salesRank: 8470
        },
        {
          date: '2024-12-22',
          reviewCount: null,
          rating: null,
          salesRank: 7580
        },
        {
          date: '2024-12-23',
          reviewCount: null,
          rating: null,
          salesRank: 6901
        },
        {
          date: '2024-12-24',
          reviewCount: null,
          rating: null,
          salesRank: 4971
        },
        {
          date: '2024-12-25',
          reviewCount: null,
          rating: null,
          salesRank: 5954
        },
        {
          date: '2024-12-26',
          reviewCount: null,
          rating: null,
          salesRank: 5636
        },
        {
          date: '2024-12-27',
          reviewCount: null,
          rating: null,
          salesRank: 6015
        },
        {
          date: '2024-12-28',
          reviewCount: null,
          rating: null,
          salesRank: 6775
        },
        {
          date: '2024-12-29',
          reviewCount: null,
          rating: null,
          salesRank: 5976
        },
        {
          date: '2024-12-30',
          reviewCount: null,
          rating: null,
          salesRank: 7616
        },
        {
          date: '2024-12-31',
          reviewCount: null,
          rating: null,
          salesRank: 6862
        },
        {
          date: '2025-01-01',
          reviewCount: null,
          rating: null,
          salesRank: 6578
        },
        {
          date: '2025-01-02',
          reviewCount: null,
          rating: null,
          salesRank: 5625
        },
        {
          date: '2025-01-03',
          reviewCount: null,
          rating: null,
          salesRank: 5001
        },
        {
          date: '2025-01-04',
          reviewCount: null,
          rating: null,
          salesRank: 4934
        },
        {
          date: '2025-01-05',
          reviewCount: null,
          rating: null,
          salesRank: 5989
        },
        {
          date: '2025-01-06',
          reviewCount: null,
          rating: null,
          salesRank: 5685
        },
        {
          date: '2025-01-07',
          reviewCount: null,
          rating: null,
          salesRank: 5764
        },
        {
          date: '2025-01-08',
          reviewCount: null,
          rating: null,
          salesRank: 5397
        },
        {
          date: '2025-01-09',
          reviewCount: null,
          rating: null,
          salesRank: 4522
        },
        {
          date: '2025-01-10',
          reviewCount: null,
          rating: null,
          salesRank: 4408
        },
        {
          date: '2025-01-11',
          reviewCount: null,
          rating: null,
          salesRank: 4573
        },
        {
          date: '2025-01-12',
          reviewCount: null,
          rating: null,
          salesRank: 4890
        },
        {
          date: '2025-01-13',
          reviewCount: null,
          rating: null,
          salesRank: 5247
        },
        {
          date: '2025-01-14',
          reviewCount: null,
          rating: null,
          salesRank: 4688
        },
        {
          date: '2025-01-15',
          reviewCount: null,
          rating: null,
          salesRank: 3944
        },
        {
          date: '2025-01-16',
          reviewCount: null,
          rating: null,
          salesRank: 4029
        },
        {
          date: '2025-01-17',
          reviewCount: null,
          rating: null,
          salesRank: 4260
        },
        {
          date: '2025-01-18',
          reviewCount: null,
          rating: null,
          salesRank: 4735
        },
        {
          date: '2025-01-19',
          reviewCount: null,
          rating: null,
          salesRank: 4287
        },
        {
          date: '2025-01-20',
          reviewCount: null,
          rating: null,
          salesRank: 4711
        },
        {
          date: '2025-01-21',
          reviewCount: null,
          rating: null,
          salesRank: 4127
        },
        {
          date: '2025-01-22',
          reviewCount: null,
          rating: null,
          salesRank: 3442
        },
        {
          date: '2025-01-23',
          reviewCount: null,
          rating: null,
          salesRank: 3061
        },
        {
          date: '2025-01-24',
          reviewCount: null,
          rating: null,
          salesRank: 2795
        },
        {
          date: '2025-01-25',
          reviewCount: null,
          rating: null,
          salesRank: 3963
        },
        {
          date: '2025-01-26',
          reviewCount: null,
          rating: null,
          salesRank: 4052
        },
        {
          date: '2025-01-27',
          reviewCount: null,
          rating: null,
          salesRank: 3601
        },
        {
          date: '2025-01-28',
          reviewCount: null,
          rating: null,
          salesRank: 4591
        },
        {
          date: '2025-01-29',
          reviewCount: null,
          rating: null,
          salesRank: 3781
        },
        {
          date: '2025-01-30',
          reviewCount: null,
          rating: null,
          salesRank: 4420
        },
        {
          date: '2025-01-31',
          reviewCount: null,
          rating: null,
          salesRank: 4084
        },
        {
          date: '2025-02-01',
          reviewCount: null,
          rating: null,
          salesRank: 4578
        },
        {
          date: '2025-02-02',
          reviewCount: null,
          rating: null,
          salesRank: 5299
        },
        {
          date: '2025-02-03',
          reviewCount: null,
          rating: null,
          salesRank: 5227
        },
        {
          date: '2025-02-04',
          reviewCount: null,
          rating: null,
          salesRank: 4773
        },
        {
          date: '2025-02-05',
          reviewCount: null,
          rating: null,
          salesRank: 5420
        },
        {
          date: '2025-02-06',
          reviewCount: null,
          rating: null,
          salesRank: 5763
        },
        {
          date: '2025-02-07',
          reviewCount: null,
          rating: null,
          salesRank: 4744
        },
        {
          date: '2025-02-08',
          reviewCount: null,
          rating: null,
          salesRank: 5304
        },
        {
          date: '2025-02-09',
          reviewCount: null,
          rating: null,
          salesRank: 5984
        },
        {
          date: '2025-02-10',
          reviewCount: null,
          rating: null,
          salesRank: 6833
        },
        {
          date: '2025-02-11',
          reviewCount: null,
          rating: null,
          salesRank: 5986
        },
        {
          date: '2025-02-12',
          reviewCount: null,
          rating: null,
          salesRank: 4905
        },
        {
          date: '2025-02-13',
          reviewCount: null,
          rating: null,
          salesRank: 5609
        },
        {
          date: '2025-02-14',
          reviewCount: null,
          rating: null,
          salesRank: 4566
        },
        {
          date: '2025-02-15',
          reviewCount: null,
          rating: null,
          salesRank: 4712
        },
        {
          date: '2025-02-16',
          reviewCount: null,
          rating: null,
          salesRank: 6146
        },
        {
          date: '2025-02-17',
          reviewCount: null,
          rating: null,
          salesRank: 5018
        },
        {
          date: '2025-02-18',
          reviewCount: null,
          rating: null,
          salesRank: 5079
        },
        {
          date: '2025-02-19',
          reviewCount: null,
          rating: null,
          salesRank: 4466
        },
        {
          date: '2025-02-20',
          reviewCount: null,
          rating: null,
          salesRank: 4777
        },
        {
          date: '2025-02-21',
          reviewCount: null,
          rating: null,
          salesRank: 5339
        },
        {
          date: '2025-02-22',
          reviewCount: null,
          rating: null,
          salesRank: 5072
        },
        {
          date: '2025-02-23',
          reviewCount: null,
          rating: null,
          salesRank: 5693
        },
        {
          date: '2025-02-24',
          reviewCount: null,
          rating: null,
          salesRank: 5901
        },
        {
          date: '2025-02-25',
          reviewCount: null,
          rating: null,
          salesRank: 6372
        },
        {
          date: '2025-02-26',
          reviewCount: null,
          rating: null,
          salesRank: 4870
        },
        {
          date: '2025-02-27',
          reviewCount: null,
          rating: null,
          salesRank: 5024
        },
        {
          date: '2025-02-28',
          reviewCount: null,
          rating: null,
          salesRank: 5071
        },
        {
          date: '2025-03-01',
          reviewCount: null,
          rating: null,
          salesRank: 5720
        },
        {
          date: '2025-03-02',
          reviewCount: null,
          rating: null,
          salesRank: 6293
        },
        {
          date: '2025-03-03',
          reviewCount: null,
          rating: null,
          salesRank: 5307
        },
        {
          date: '2025-03-04',
          reviewCount: null,
          rating: null,
          salesRank: 5462
        },
        {
          date: '2025-03-05',
          reviewCount: null,
          rating: null,
          salesRank: 5814
        },
        {
          date: '2025-03-06',
          reviewCount: null,
          rating: null,
          salesRank: 6356
        },
        {
          date: '2025-03-07',
          reviewCount: null,
          rating: null,
          salesRank: 5358
        },
        {
          date: '2025-03-08',
          reviewCount: null,
          rating: null,
          salesRank: null
        },
        {
          date: '2025-03-09',
          reviewCount: null,
          rating: null,
          salesRank: 6687
        },
        {
          date: '2025-03-10',
          reviewCount: null,
          rating: null,
          salesRank: 6288
        },
        {
          date: '2025-03-11',
          reviewCount: null,
          rating: null,
          salesRank: 5236
        },
        {
          date: '2025-03-12',
          reviewCount: null,
          rating: null,
          salesRank: 6373
        },
        {
          date: '2025-03-13',
          reviewCount: null,
          rating: null,
          salesRank: 7180
        },
        {
          date: '2025-03-14',
          reviewCount: null,
          rating: null,
          salesRank: 5442
        },
        {
          date: '2025-03-15',
          reviewCount: null,
          rating: null,
          salesRank: 6824
        },
        {
          date: '2025-03-16',
          reviewCount: null,
          rating: null,
          salesRank: 6972
        },
        {
          date: '2025-03-17',
          reviewCount: null,
          rating: null,
          salesRank: 6653
        },
        {
          date: '2025-03-18',
          reviewCount: null,
          rating: null,
          salesRank: 6007
        }
      ],
      reviewDataUploaded: false,
      bestSellerRankDataUploaded: true,
      isVisible: true,
      isCategoryAverage: false,
      createdAt: '2025-02-24T18:04:51.814Z'
    },
    {
      id: 'c39486ce-efb1-4368-b608-8e2bfc51be0a',
      productName: 'Bluetooth Headphones with 16 Hours Playtime',
      productUrl: 'https://www.amazon.com',
      productImage: 'https://media.productwind.com/sample/images/sample-sku-wireless-earbuds-2.png',
      comparisonMetric: 'REVIEWS',
      productPerformanceRecords: [
        {
          date: '2023-07-11',
          reviewCount: 1,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-12',
          reviewCount: 1,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-13',
          reviewCount: 1,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-14',
          reviewCount: 1,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-15',
          reviewCount: 1,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-16',
          reviewCount: 1,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-17',
          reviewCount: 1,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-18',
          reviewCount: 1,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-19',
          reviewCount: 1,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-20',
          reviewCount: 1,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-21',
          reviewCount: 1,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-22',
          reviewCount: 1,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-23',
          reviewCount: 1,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-24',
          reviewCount: 1,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-25',
          reviewCount: 1,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-26',
          reviewCount: 1,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-27',
          reviewCount: 1,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-28',
          reviewCount: 1,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-29',
          reviewCount: 1,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-30',
          reviewCount: 1,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-31',
          reviewCount: 1,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-01',
          reviewCount: 1,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-02',
          reviewCount: 2,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-03',
          reviewCount: 2,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-04',
          reviewCount: 2,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-05',
          reviewCount: 2,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-06',
          reviewCount: 2,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-07',
          reviewCount: 2,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-08',
          reviewCount: 2,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-09',
          reviewCount: 2,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-10',
          reviewCount: 2,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-11',
          reviewCount: 2,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-12',
          reviewCount: 2,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-13',
          reviewCount: 2,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-14',
          reviewCount: 2,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-15',
          reviewCount: 2,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-16',
          reviewCount: 2,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-17',
          reviewCount: 2,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-18',
          reviewCount: 3,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-19',
          reviewCount: 3,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-20',
          reviewCount: 3,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-21',
          reviewCount: 3,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-22',
          reviewCount: 3,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-23',
          reviewCount: 3,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-24',
          reviewCount: 3,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-25',
          reviewCount: 3,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-26',
          reviewCount: 3,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-27',
          reviewCount: 3,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-28',
          reviewCount: 3,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-29',
          reviewCount: 3,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-30',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-31',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-01',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-02',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-03',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-04',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-05',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-06',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-07',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-08',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-09',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-10',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-11',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-12',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-13',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-14',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-15',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-16',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-17',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-18',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-19',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-20',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-21',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-22',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-23',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-24',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-25',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-26',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-27',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-28',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-29',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-30',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-01',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-02',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-03',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-04',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-05',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-06',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-07',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-08',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-09',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-10',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-11',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-12',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-13',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-14',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-15',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-16',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-17',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-18',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-19',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-20',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-21',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-22',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-23',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-24',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-25',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-26',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-27',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-28',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-29',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-30',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-31',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-01',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-02',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-03',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-04',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-05',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-06',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-07',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-08',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-09',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-10',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-11',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-12',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-13',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-14',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-15',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-16',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-17',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-18',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-19',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-20',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-21',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-22',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-23',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-24',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-25',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-26',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-27',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-28',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-29',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-30',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-01',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-02',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-03',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-04',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-05',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-06',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-07',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-08',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-09',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-10',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-11',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-12',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-13',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-14',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-15',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-16',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-17',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-18',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-19',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-20',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-21',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-22',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-23',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-24',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-25',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-26',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-27',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-28',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-29',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-30',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-31',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-01',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-02',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-03',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-04',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-05',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-06',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-07',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-08',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-09',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-10',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-11',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-12',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-13',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-14',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-15',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-16',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-17',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-18',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-19',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-20',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-21',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-22',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-23',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-24',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-25',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-26',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-27',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-28',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-29',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-30',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-31',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-01',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-02',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-03',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-04',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-05',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-06',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-07',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-08',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-09',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-10',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-11',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-12',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-13',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-14',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-15',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-16',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-17',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-18',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-19',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-20',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-21',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-22',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-23',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-24',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-25',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-26',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-27',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-28',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-29',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-01',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-02',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-03',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-04',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-05',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-06',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-07',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-08',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-09',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-10',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-11',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-12',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-13',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-14',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-15',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-16',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-17',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-18',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-19',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-20',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-21',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-22',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-23',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-24',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-25',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-26',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-27',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-28',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-29',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-30',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-31',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-01',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-02',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-03',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-04',
          reviewCount: 26,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-05',
          reviewCount: 26,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-06',
          reviewCount: 26,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-07',
          reviewCount: 26,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-08',
          reviewCount: 26,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-09',
          reviewCount: 26,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-10',
          reviewCount: 43,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-11',
          reviewCount: 43,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-12',
          reviewCount: 43,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-13',
          reviewCount: 43,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-14',
          reviewCount: 43,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-15',
          reviewCount: 53,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-16',
          reviewCount: 53,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-17',
          reviewCount: 53,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-18',
          reviewCount: 53,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-19',
          reviewCount: 53,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-20',
          reviewCount: 53,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-21',
          reviewCount: 64,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-22',
          reviewCount: 64,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-23',
          reviewCount: 64,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-24',
          reviewCount: 64,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-25',
          reviewCount: 64,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-26',
          reviewCount: 64,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-27',
          reviewCount: 79,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-28',
          reviewCount: 79,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-29',
          reviewCount: 79,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-30',
          reviewCount: 79,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-01',
          reviewCount: 79,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-02',
          reviewCount: 99,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-03',
          reviewCount: 99,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-04',
          reviewCount: 99,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-05',
          reviewCount: 99,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-06',
          reviewCount: 99,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-07',
          reviewCount: 122,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-08',
          reviewCount: 122,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-09',
          reviewCount: 122,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-10',
          reviewCount: 122,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-11',
          reviewCount: 122,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-12',
          reviewCount: 129,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-13',
          reviewCount: 129,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-14',
          reviewCount: 129,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-15',
          reviewCount: 129,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-16',
          reviewCount: 129,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-17',
          reviewCount: 129,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-18',
          reviewCount: 145,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-19',
          reviewCount: 145,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-20',
          reviewCount: 145,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-21',
          reviewCount: 145,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-22',
          reviewCount: 145,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-23',
          reviewCount: 157,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-24',
          reviewCount: 157,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-25',
          reviewCount: 157,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-26',
          reviewCount: 157,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-27',
          reviewCount: 157,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-28',
          reviewCount: 157,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-29',
          reviewCount: 169,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-30',
          reviewCount: 169,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-31',
          reviewCount: 169,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-01',
          reviewCount: 169,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-02',
          reviewCount: 169,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-03',
          reviewCount: 183,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-04',
          reviewCount: 183,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-05',
          reviewCount: 183,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-06',
          reviewCount: 183,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-07',
          reviewCount: 183,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-08',
          reviewCount: 183,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-09',
          reviewCount: 200,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-10',
          reviewCount: 200,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-11',
          reviewCount: 200,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-12',
          reviewCount: 200,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-13',
          reviewCount: 200,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-14',
          reviewCount: 221,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-15',
          reviewCount: 221,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-16',
          reviewCount: 221,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-17',
          reviewCount: 221,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-18',
          reviewCount: 221,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-19',
          reviewCount: 221,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-20',
          reviewCount: 241,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-21',
          reviewCount: 241,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-22',
          reviewCount: 241,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-23',
          reviewCount: 241,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-24',
          reviewCount: 241,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-25',
          reviewCount: 252,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-26',
          reviewCount: 252,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-27',
          reviewCount: 252,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-28',
          reviewCount: 252,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-29',
          reviewCount: 252,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-30',
          reviewCount: 252,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-01',
          reviewCount: 267,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-02',
          reviewCount: 267,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-03',
          reviewCount: 267,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-04',
          reviewCount: 267,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-05',
          reviewCount: 267,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-06',
          reviewCount: 281,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-07',
          reviewCount: 281,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-08',
          reviewCount: 281,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-09',
          reviewCount: 281,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-10',
          reviewCount: 281,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-11',
          reviewCount: 291,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-12',
          reviewCount: 291,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-13',
          reviewCount: 291,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-14',
          reviewCount: 291,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-15',
          reviewCount: 291,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-16',
          reviewCount: 291,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-17',
          reviewCount: 307,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-18',
          reviewCount: 307,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-19',
          reviewCount: 307,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-20',
          reviewCount: 307,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-21',
          reviewCount: 307,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-22',
          reviewCount: 321,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-23',
          reviewCount: 321,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-24',
          reviewCount: 321,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-25',
          reviewCount: 321,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-26',
          reviewCount: 321,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-27',
          reviewCount: 321,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-28',
          reviewCount: 331,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-29',
          reviewCount: 331,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-30',
          reviewCount: 331,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-31',
          reviewCount: 331,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-01',
          reviewCount: 331,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-02',
          reviewCount: 331,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-03',
          reviewCount: 353,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-04',
          reviewCount: 353,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-05',
          reviewCount: 353,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-06',
          reviewCount: 353,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-07',
          reviewCount: 353,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-08',
          reviewCount: 370,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-09',
          reviewCount: 370,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-10',
          reviewCount: 370,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-11',
          reviewCount: 370,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-12',
          reviewCount: 370,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-13',
          reviewCount: 370,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-14',
          reviewCount: 376,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-15',
          reviewCount: 376,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-16',
          reviewCount: 376,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-17',
          reviewCount: 376,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-18',
          reviewCount: 376,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-19',
          reviewCount: 383,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-20',
          reviewCount: 383,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-21',
          reviewCount: 383,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-22',
          reviewCount: 383,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-23',
          reviewCount: 383,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-24',
          reviewCount: 383,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-25',
          reviewCount: 401,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-26',
          reviewCount: 401,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-27',
          reviewCount: 401,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-28',
          reviewCount: 401,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-29',
          reviewCount: 401,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-30',
          reviewCount: 401,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-31',
          reviewCount: 414,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-01',
          reviewCount: 414,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-02',
          reviewCount: 414,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-03',
          reviewCount: 414,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-04',
          reviewCount: 414,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-05',
          reviewCount: 414,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-06',
          reviewCount: 427,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-07',
          reviewCount: 427,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-08',
          reviewCount: 427,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-09',
          reviewCount: 427,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-10',
          reviewCount: 427,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-11',
          reviewCount: 437,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-12',
          reviewCount: 437,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-13',
          reviewCount: 437,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-14',
          reviewCount: 437,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-15',
          reviewCount: 437,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-16',
          reviewCount: 445,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-17',
          reviewCount: 445,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-18',
          reviewCount: 445,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-19',
          reviewCount: 445,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-20',
          reviewCount: 445,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-21',
          reviewCount: 445,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-22',
          reviewCount: 463,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-23',
          reviewCount: 463,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-24',
          reviewCount: 463,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-25',
          reviewCount: 463,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-26',
          reviewCount: 463,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-27',
          reviewCount: 463,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-28',
          reviewCount: 472,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-29',
          reviewCount: 472,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-30',
          reviewCount: 472,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-01',
          reviewCount: 472,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-02',
          reviewCount: 472,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-03',
          reviewCount: 482,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-04',
          reviewCount: 482,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-05',
          reviewCount: 482,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-06',
          reviewCount: 482,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-07',
          reviewCount: 482,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-08',
          reviewCount: 490,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-09',
          reviewCount: 490,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-10',
          reviewCount: 490,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-11',
          reviewCount: 490,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-12',
          reviewCount: 490,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-13',
          reviewCount: 504,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-14',
          reviewCount: 504,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-15',
          reviewCount: 504,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-16',
          reviewCount: 504,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-17',
          reviewCount: 504,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-18',
          reviewCount: 504,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-19',
          reviewCount: 521,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-20',
          reviewCount: 521,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-21',
          reviewCount: 521,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-22',
          reviewCount: 521,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-23',
          reviewCount: 521,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-24',
          reviewCount: 538,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-25',
          reviewCount: 538,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-26',
          reviewCount: 538,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-27',
          reviewCount: 538,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-28',
          reviewCount: 538,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-29',
          reviewCount: 538,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-30',
          reviewCount: 543,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-31',
          reviewCount: 543,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-01',
          reviewCount: 543,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-02',
          reviewCount: 543,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-03',
          reviewCount: 543,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-04',
          reviewCount: 552,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-05',
          reviewCount: 552,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-06',
          reviewCount: 552,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-07',
          reviewCount: 552,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-08',
          reviewCount: 552,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-09',
          reviewCount: 552,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-10',
          reviewCount: 557,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-11',
          reviewCount: 557,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-12',
          reviewCount: 557,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-13',
          reviewCount: 557,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-14',
          reviewCount: 557,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-15',
          reviewCount: 557,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-16',
          reviewCount: 560,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-17',
          reviewCount: 560,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-18',
          reviewCount: 560,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-19',
          reviewCount: 560,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-20',
          reviewCount: 560,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-21',
          reviewCount: 570,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-22',
          reviewCount: 570,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-23',
          reviewCount: 570,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-24',
          reviewCount: 570,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-25',
          reviewCount: 570,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-26',
          reviewCount: 578,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-27',
          reviewCount: 578,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-28',
          reviewCount: 578,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-29',
          reviewCount: 578,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-30',
          reviewCount: 578,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-01',
          reviewCount: 729,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-02',
          reviewCount: 729,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-03',
          reviewCount: 729,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-04',
          reviewCount: 729,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-05',
          reviewCount: 729,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-06',
          reviewCount: 729,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-07',
          reviewCount: 749,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-08',
          reviewCount: 749,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-09',
          reviewCount: 749,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-10',
          reviewCount: 749,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-11',
          reviewCount: 749,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-12',
          reviewCount: 766,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-13',
          reviewCount: 766,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-14',
          reviewCount: 766,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-15',
          reviewCount: 766,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-16',
          reviewCount: 766,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-17',
          reviewCount: 786,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-18',
          reviewCount: 786,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-19',
          reviewCount: 786,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-20',
          reviewCount: 786,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-21',
          reviewCount: 799,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-22',
          reviewCount: 799,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-23',
          reviewCount: 799,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-24',
          reviewCount: 801,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-25',
          reviewCount: 801,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-26',
          reviewCount: 801,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-27',
          reviewCount: 801,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-28',
          reviewCount: 815,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-29',
          reviewCount: 815,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-30',
          reviewCount: 815,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-31',
          reviewCount: 819,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-01',
          reviewCount: 819,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-02',
          reviewCount: 819,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-03',
          reviewCount: 819,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-04',
          reviewCount: 829,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-05',
          reviewCount: 829,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-06',
          reviewCount: 829,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-07',
          reviewCount: 829,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-08',
          reviewCount: 848,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-09',
          reviewCount: 848,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-10',
          reviewCount: 848,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-11',
          reviewCount: 848,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-12',
          reviewCount: 869,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-13',
          reviewCount: 869,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-14',
          reviewCount: 869,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-15',
          reviewCount: 869,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-16',
          reviewCount: 869,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-17',
          reviewCount: 895,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-18',
          reviewCount: 895,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-19',
          reviewCount: 895,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-20',
          reviewCount: 895,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-21',
          reviewCount: 895,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-22',
          reviewCount: 895,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-23',
          reviewCount: 913,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-24',
          reviewCount: 913,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-25',
          reviewCount: 913,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-26',
          reviewCount: 913,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-27',
          reviewCount: 913,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-28',
          reviewCount: 942,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-29',
          reviewCount: 942,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-30',
          reviewCount: 942,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-31',
          reviewCount: 942,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-01',
          reviewCount: 950,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-02',
          reviewCount: 950,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-03',
          reviewCount: 950,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-04',
          reviewCount: 950,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-05',
          reviewCount: 972,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-06',
          reviewCount: 972,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-07',
          reviewCount: 972,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-08',
          reviewCount: 972,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-09',
          reviewCount: 1003,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-10',
          reviewCount: 1003,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-11',
          reviewCount: 1003,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-12',
          reviewCount: 1003,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-13',
          reviewCount: 1003,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-14',
          reviewCount: 1003,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-15',
          reviewCount: 1003,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-16',
          reviewCount: 1028,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-17',
          reviewCount: 1028,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-18',
          reviewCount: 1028,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-19',
          reviewCount: 1028,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-20',
          reviewCount: 1036,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-21',
          reviewCount: 1036,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-22',
          reviewCount: 1036,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-23',
          reviewCount: 1053,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-24',
          reviewCount: 1053,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-25',
          reviewCount: 1053,
          rating: 4,
          salesRank: null
        }
      ],
      reviewDataUploaded: true,
      bestSellerRankDataUploaded: false,
      isVisible: true,
      isCategoryAverage: false,
      createdAt: '2025-02-24T18:03:55.334Z'
    },
    {
      id: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e',
      productName: 'True Wireless Bluetooth Earbuds',
      productUrl: 'https://www.amazon.com',
      productImage: 'https://media.productwind.com/sample/images/sample-sku-wireless-earbuds-3.png',
      comparisonMetric: 'REVIEWS',
      productPerformanceRecords: [
        {
          date: '2017-08-16',
          reviewCount: 1,
          rating: 5,
          salesRank: null
        },
        {
          date: '2017-08-17',
          reviewCount: 1,
          rating: 5,
          salesRank: null
        },
        {
          date: '2017-08-18',
          reviewCount: 2,
          rating: 5,
          salesRank: null
        },
        {
          date: '2017-08-19',
          reviewCount: 2,
          rating: 5,
          salesRank: null
        },
        {
          date: '2017-08-20',
          reviewCount: 2,
          rating: 5,
          salesRank: null
        },
        {
          date: '2017-08-21',
          reviewCount: 3,
          rating: 5,
          salesRank: null
        },
        {
          date: '2017-08-22',
          reviewCount: 3,
          rating: 5,
          salesRank: null
        },
        {
          date: '2017-08-23',
          reviewCount: 3,
          rating: 5,
          salesRank: null
        },
        {
          date: '2017-08-24',
          reviewCount: 3,
          rating: 5,
          salesRank: null
        },
        {
          date: '2017-08-25',
          reviewCount: 3,
          rating: 5,
          salesRank: null
        },
        {
          date: '2017-08-26',
          reviewCount: 3,
          rating: 5,
          salesRank: null
        },
        {
          date: '2017-08-27',
          reviewCount: 3,
          rating: 5,
          salesRank: null
        },
        {
          date: '2017-08-28',
          reviewCount: 3,
          rating: 5,
          salesRank: null
        },
        {
          date: '2017-08-29',
          reviewCount: 3,
          rating: 5,
          salesRank: null
        },
        {
          date: '2017-08-30',
          reviewCount: 3,
          rating: 5,
          salesRank: null
        },
        {
          date: '2017-08-31',
          reviewCount: 3,
          rating: 5,
          salesRank: null
        },
        {
          date: '2017-09-01',
          reviewCount: 3,
          rating: 5,
          salesRank: null
        },
        {
          date: '2017-09-02',
          reviewCount: 3,
          rating: 5,
          salesRank: null
        },
        {
          date: '2017-09-03',
          reviewCount: 3,
          rating: 5,
          salesRank: null
        },
        {
          date: '2017-09-04',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-09-05',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-09-06',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-09-07',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-09-08',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-09-09',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-09-10',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-09-11',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-09-12',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-09-13',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-09-14',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-09-15',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-09-16',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-09-17',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-09-18',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-09-19',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-09-20',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-09-21',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-09-22',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-09-23',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-09-24',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-09-25',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-09-26',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-09-27',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-09-28',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-09-29',
          reviewCount: 4,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-09-30',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-10-01',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-10-02',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-10-03',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-10-04',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-10-05',
          reviewCount: 5,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-10-06',
          reviewCount: 6,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-10-07',
          reviewCount: 6,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-10-08',
          reviewCount: 6,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-10-09',
          reviewCount: 7,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-10-10',
          reviewCount: 8,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-10-11',
          reviewCount: 8,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-10-12',
          reviewCount: 8,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-10-13',
          reviewCount: 8,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-10-14',
          reviewCount: 9,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-10-15',
          reviewCount: 9,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-10-16',
          reviewCount: 9,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-10-17',
          reviewCount: 9,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-10-18',
          reviewCount: 9,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-10-19',
          reviewCount: 9,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-10-20',
          reviewCount: 9,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-10-21',
          reviewCount: 9,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-10-22',
          reviewCount: 9,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-10-23',
          reviewCount: 11,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-10-24',
          reviewCount: 12,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-10-25',
          reviewCount: 14,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-10-26',
          reviewCount: 14,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-10-27',
          reviewCount: 20,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-10-28',
          reviewCount: 21,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-10-29',
          reviewCount: 24,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-10-30',
          reviewCount: 25,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-10-31',
          reviewCount: 25,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-11-01',
          reviewCount: 25,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-11-02',
          reviewCount: 26,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-11-03',
          reviewCount: 28,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-11-04',
          reviewCount: 29,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-11-05',
          reviewCount: 30,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-11-06',
          reviewCount: 32,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-11-07',
          reviewCount: 34,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-11-08',
          reviewCount: 35,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-11-09',
          reviewCount: 35,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-11-10',
          reviewCount: 37,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-11-11',
          reviewCount: 38,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-11-12',
          reviewCount: 38,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-11-13',
          reviewCount: 38,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-11-14',
          reviewCount: 39,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-11-15',
          reviewCount: 42,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-11-16',
          reviewCount: 44,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-11-17',
          reviewCount: 46,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-11-18',
          reviewCount: 47,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-11-19',
          reviewCount: 48,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-11-20',
          reviewCount: 48,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-11-21',
          reviewCount: 52,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-11-22',
          reviewCount: 52,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-11-23',
          reviewCount: 54,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-11-24',
          reviewCount: 56,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-11-25',
          reviewCount: 57,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-11-26',
          reviewCount: 61,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-11-27',
          reviewCount: 61,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-11-28',
          reviewCount: 68,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-11-29',
          reviewCount: 71,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-11-30',
          reviewCount: 75,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-12-01',
          reviewCount: 80,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-12-02',
          reviewCount: 82,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-12-03',
          reviewCount: 87,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-12-04',
          reviewCount: 90,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-12-05',
          reviewCount: 90,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-12-06',
          reviewCount: 93,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-12-07',
          reviewCount: 96,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-12-08',
          reviewCount: 96,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-12-09',
          reviewCount: 98,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-12-10',
          reviewCount: 104,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-12-11',
          reviewCount: 110,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-12-12',
          reviewCount: 112,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-12-13',
          reviewCount: 119,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-12-14',
          reviewCount: 123,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-12-15',
          reviewCount: 131,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-12-16',
          reviewCount: 132,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-12-17',
          reviewCount: 138,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-12-18',
          reviewCount: 141,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-12-19',
          reviewCount: 143,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-12-20',
          reviewCount: 143,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-12-21',
          reviewCount: 147,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-12-22',
          reviewCount: 150,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-12-23',
          reviewCount: 152,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-12-24',
          reviewCount: 152,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-12-25',
          reviewCount: 154,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-12-26',
          reviewCount: 156,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-12-27',
          reviewCount: 157,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-12-28',
          reviewCount: 157,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-12-29',
          reviewCount: 158,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-12-30',
          reviewCount: 161,
          rating: 4,
          salesRank: null
        },
        {
          date: '2017-12-31',
          reviewCount: 165,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-01-01',
          reviewCount: 166,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-01-02',
          reviewCount: 175,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-01-03',
          reviewCount: 181,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-01-04',
          reviewCount: 183,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-01-05',
          reviewCount: 187,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-01-06',
          reviewCount: 189,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-01-07',
          reviewCount: 196,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-01-08',
          reviewCount: 204,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-01-09',
          reviewCount: 210,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-01-10',
          reviewCount: 214,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-01-11',
          reviewCount: 218,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-01-12',
          reviewCount: 220,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-01-13',
          reviewCount: 222,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-01-14',
          reviewCount: 223,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-01-15',
          reviewCount: 231,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-01-16',
          reviewCount: 235,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-01-17',
          reviewCount: 241,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-01-18',
          reviewCount: 247,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-01-19',
          reviewCount: 249,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-01-20',
          reviewCount: 254,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-01-21',
          reviewCount: 256,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-01-22',
          reviewCount: 258,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-01-23',
          reviewCount: 258,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-01-24',
          reviewCount: 261,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-01-25',
          reviewCount: 267,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-01-26',
          reviewCount: 270,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-01-27',
          reviewCount: 270,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-01-28',
          reviewCount: 276,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-01-29',
          reviewCount: 284,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-01-30',
          reviewCount: 290,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-01-31',
          reviewCount: 294,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-02-01',
          reviewCount: 302,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-02-02',
          reviewCount: 305,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-02-03',
          reviewCount: 308,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-02-04',
          reviewCount: 315,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-02-05',
          reviewCount: 316,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-02-06',
          reviewCount: 322,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-02-07',
          reviewCount: 329,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-02-08',
          reviewCount: 339,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-02-09',
          reviewCount: 341,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-02-10',
          reviewCount: 344,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-02-11',
          reviewCount: 351,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-02-12',
          reviewCount: 355,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-02-13',
          reviewCount: 360,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-02-14',
          reviewCount: 366,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-02-15',
          reviewCount: 374,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-02-16',
          reviewCount: 377,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-02-17',
          reviewCount: 383,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-02-18',
          reviewCount: 386,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-02-19',
          reviewCount: 394,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-02-20',
          reviewCount: 398,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-02-21',
          reviewCount: 401,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-02-22',
          reviewCount: 404,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-02-23',
          reviewCount: 407,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-02-24',
          reviewCount: 409,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-02-25',
          reviewCount: 410,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-02-26',
          reviewCount: 412,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-02-27',
          reviewCount: 419,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-02-28',
          reviewCount: 421,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-03-01',
          reviewCount: 421,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-03-02',
          reviewCount: 423,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-03-03',
          reviewCount: 423,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-03-04',
          reviewCount: 428,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-03-05',
          reviewCount: 432,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-03-06',
          reviewCount: 434,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-03-07',
          reviewCount: 439,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-03-08',
          reviewCount: 438,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-03-09',
          reviewCount: 442,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-03-10',
          reviewCount: 442,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-03-11',
          reviewCount: 444,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-03-12',
          reviewCount: 443,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-03-13',
          reviewCount: 448,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-03-14',
          reviewCount: 450,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-03-15',
          reviewCount: 455,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-03-16',
          reviewCount: 458,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-03-17',
          reviewCount: 464,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-03-18',
          reviewCount: 470,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-03-19',
          reviewCount: 473,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-03-20',
          reviewCount: 476,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-03-21',
          reviewCount: 480,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-03-22',
          reviewCount: 482,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-03-23',
          reviewCount: 483,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-03-24',
          reviewCount: 487,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-03-25',
          reviewCount: 488,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-03-26',
          reviewCount: 492,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-03-27',
          reviewCount: 495,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-03-28',
          reviewCount: 497,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-03-29',
          reviewCount: 499,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-03-30',
          reviewCount: 501,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-03-31',
          reviewCount: 502,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-04-01',
          reviewCount: 504,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-04-02',
          reviewCount: 508,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-04-03',
          reviewCount: 511,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-04-04',
          reviewCount: 515,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-04-05',
          reviewCount: 518,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-04-06',
          reviewCount: 520,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-04-07',
          reviewCount: 522,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-04-08',
          reviewCount: 523,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-04-09',
          reviewCount: 529,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-04-10',
          reviewCount: 530,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-04-11',
          reviewCount: 532,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-04-12',
          reviewCount: 534,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-04-13',
          reviewCount: 536,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-04-14',
          reviewCount: 537,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-04-15',
          reviewCount: 537,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-04-16',
          reviewCount: 538,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-04-17',
          reviewCount: 542,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-04-18',
          reviewCount: 542,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-04-19',
          reviewCount: 542,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-04-20',
          reviewCount: 543,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-04-21',
          reviewCount: 544,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-04-22',
          reviewCount: 547,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-04-23',
          reviewCount: 550,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-04-24',
          reviewCount: 550,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-04-25',
          reviewCount: 552,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-04-26',
          reviewCount: 556,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-04-27',
          reviewCount: 556,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-04-28',
          reviewCount: 557,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-04-29',
          reviewCount: 558,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-04-30',
          reviewCount: 560,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-05-01',
          reviewCount: 562,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-05-02',
          reviewCount: 566,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-05-03',
          reviewCount: 566,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-05-04',
          reviewCount: 566,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-05-05',
          reviewCount: 567,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-05-06',
          reviewCount: 567,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-05-07',
          reviewCount: 567,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-05-08',
          reviewCount: 569,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-05-09',
          reviewCount: 569,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-05-10',
          reviewCount: 572,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-05-11',
          reviewCount: 573,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-05-12',
          reviewCount: 575,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-05-13',
          reviewCount: 575,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-05-14',
          reviewCount: 576,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-05-15',
          reviewCount: 576,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-05-16',
          reviewCount: 576,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-05-17',
          reviewCount: 575,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-05-18',
          reviewCount: 576,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-05-19',
          reviewCount: 576,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-05-20',
          reviewCount: 577,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-05-21',
          reviewCount: 578,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-05-22',
          reviewCount: 580,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-05-23',
          reviewCount: 580,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-05-24',
          reviewCount: 581,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-05-25',
          reviewCount: 574,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-05-26',
          reviewCount: 576,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-05-27',
          reviewCount: 577,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-05-28',
          reviewCount: 577,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-05-29',
          reviewCount: 577,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-05-30',
          reviewCount: 577,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-05-31',
          reviewCount: 577,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-06-01',
          reviewCount: 577,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-06-02',
          reviewCount: 577,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-06-03',
          reviewCount: 577,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-06-04',
          reviewCount: 584,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-06-05',
          reviewCount: 584,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-06-06',
          reviewCount: 584,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-06-07',
          reviewCount: 584,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-06-08',
          reviewCount: 584,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-06-09',
          reviewCount: 584,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-06-10',
          reviewCount: 584,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-06-11',
          reviewCount: 584,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-06-12',
          reviewCount: 585,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-06-13',
          reviewCount: 587,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-06-14',
          reviewCount: 587,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-06-15',
          reviewCount: 587,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-06-16',
          reviewCount: 587,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-06-17',
          reviewCount: 587,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-06-18',
          reviewCount: 589,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-06-19',
          reviewCount: 589,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-06-20',
          reviewCount: 589,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-06-21',
          reviewCount: 589,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-06-22',
          reviewCount: 589,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-06-23',
          reviewCount: 589,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-06-24',
          reviewCount: 589,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-06-25',
          reviewCount: 589,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-06-26',
          reviewCount: 589,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-06-27',
          reviewCount: 589,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-06-28',
          reviewCount: 589,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-06-29',
          reviewCount: 589,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-06-30',
          reviewCount: 589,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-07-01',
          reviewCount: 589,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-07-02',
          reviewCount: 592,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-07-03',
          reviewCount: 592,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-07-04',
          reviewCount: 591,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-07-05',
          reviewCount: 591,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-07-06',
          reviewCount: 589,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-07-07',
          reviewCount: 589,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-07-08',
          reviewCount: 589,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-07-09',
          reviewCount: 589,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-07-10',
          reviewCount: 587,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-07-11',
          reviewCount: 587,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-07-12',
          reviewCount: 587,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-07-13',
          reviewCount: 587,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-07-14',
          reviewCount: 587,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-07-15',
          reviewCount: 588,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-07-16',
          reviewCount: 588,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-07-17',
          reviewCount: 588,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-07-18',
          reviewCount: 588,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-07-19',
          reviewCount: 588,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-07-20',
          reviewCount: 588,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-07-21',
          reviewCount: 589,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-07-22',
          reviewCount: 589,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-07-23',
          reviewCount: 589,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-07-24',
          reviewCount: 589,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-07-25',
          reviewCount: 590,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-07-26',
          reviewCount: 590,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-07-27',
          reviewCount: 592,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-07-28',
          reviewCount: 592,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-07-29',
          reviewCount: 592,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-07-30',
          reviewCount: 592,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-07-31',
          reviewCount: 592,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-08-01',
          reviewCount: 592,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-08-02',
          reviewCount: 597,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-08-03',
          reviewCount: 597,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-08-04',
          reviewCount: 597,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-08-05',
          reviewCount: 597,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-08-06',
          reviewCount: 597,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-08-07',
          reviewCount: 598,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-08-08',
          reviewCount: 598,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-08-09',
          reviewCount: 598,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-08-10',
          reviewCount: 598,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-08-11',
          reviewCount: 598,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-08-12',
          reviewCount: 598,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-08-13',
          reviewCount: 598,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-08-14',
          reviewCount: 598,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-08-15',
          reviewCount: 598,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-08-16',
          reviewCount: 601,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-08-17',
          reviewCount: 601,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-08-18',
          reviewCount: 601,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-08-19',
          reviewCount: 601,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-08-20',
          reviewCount: 601,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-08-21',
          reviewCount: 601,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-08-22',
          reviewCount: 600,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-08-23',
          reviewCount: 600,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-08-24',
          reviewCount: 599,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-08-25',
          reviewCount: 599,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-08-26',
          reviewCount: 599,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-08-27',
          reviewCount: 601,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-08-28',
          reviewCount: 601,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-08-29',
          reviewCount: 601,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-08-30',
          reviewCount: 606,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-08-31',
          reviewCount: 606,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-09-01',
          reviewCount: 606,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-09-02',
          reviewCount: 606,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-09-03',
          reviewCount: 609,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-09-04',
          reviewCount: 609,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-09-05',
          reviewCount: 609,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-09-06',
          reviewCount: 609,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-09-07',
          reviewCount: 609,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-09-08',
          reviewCount: 609,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-09-09',
          reviewCount: 611,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-09-10',
          reviewCount: 611,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-09-11',
          reviewCount: 611,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-09-12',
          reviewCount: 615,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-09-13',
          reviewCount: 615,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-09-14',
          reviewCount: 615,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-09-15',
          reviewCount: 617,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-09-16',
          reviewCount: 617,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-09-17',
          reviewCount: 617,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-09-18',
          reviewCount: 617,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-09-19',
          reviewCount: 618,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-09-20',
          reviewCount: 618,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-09-21',
          reviewCount: 618,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-09-22',
          reviewCount: 619,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-09-23',
          reviewCount: 619,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-09-24',
          reviewCount: 619,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-09-25',
          reviewCount: 619,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-09-26',
          reviewCount: 621,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-09-27',
          reviewCount: 621,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-09-28',
          reviewCount: 621,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-09-29',
          reviewCount: 621,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-09-30',
          reviewCount: 622,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-10-01',
          reviewCount: 622,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-10-02',
          reviewCount: 622,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-10-03',
          reviewCount: 622,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-10-04',
          reviewCount: 622,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-10-05',
          reviewCount: 622,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-10-06',
          reviewCount: 622,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-10-07',
          reviewCount: 627,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-10-08',
          reviewCount: 627,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-10-09',
          reviewCount: 627,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-10-10',
          reviewCount: 627,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-10-11',
          reviewCount: 627,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-10-12',
          reviewCount: 627,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-10-13',
          reviewCount: 627,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-10-14',
          reviewCount: 627,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-10-15',
          reviewCount: 629,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-10-16',
          reviewCount: 629,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-10-17',
          reviewCount: 629,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-10-18',
          reviewCount: 629,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-10-19',
          reviewCount: 629,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-10-20',
          reviewCount: 629,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-10-21',
          reviewCount: 630,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-10-22',
          reviewCount: 630,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-10-23',
          reviewCount: 630,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-10-24',
          reviewCount: 630,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-10-25',
          reviewCount: 630,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-10-26',
          reviewCount: 630,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-10-27',
          reviewCount: 630,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-10-28',
          reviewCount: 630,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-10-29',
          reviewCount: 630,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-10-30',
          reviewCount: 630,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-10-31',
          reviewCount: 630,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-11-01',
          reviewCount: 630,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-11-02',
          reviewCount: 630,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-11-03',
          reviewCount: 630,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-11-04',
          reviewCount: 630,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-11-05',
          reviewCount: 630,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-11-06',
          reviewCount: 630,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-11-07',
          reviewCount: 630,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-11-08',
          reviewCount: 630,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-11-09',
          reviewCount: 630,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-11-10',
          reviewCount: 630,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-11-11',
          reviewCount: 630,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-11-12',
          reviewCount: 630,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-11-13',
          reviewCount: 630,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-11-14',
          reviewCount: 630,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-11-15',
          reviewCount: 630,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-11-16',
          reviewCount: 663,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-11-17',
          reviewCount: 663,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-11-18',
          reviewCount: 663,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-11-19',
          reviewCount: 663,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-11-20',
          reviewCount: 663,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-11-21',
          reviewCount: 663,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-11-22',
          reviewCount: 674,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-11-23',
          reviewCount: 674,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-11-24',
          reviewCount: 674,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-11-25',
          reviewCount: 674,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-11-26',
          reviewCount: 674,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-11-27',
          reviewCount: 674,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-11-28',
          reviewCount: 686,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-11-29',
          reviewCount: 686,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-11-30',
          reviewCount: 686,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-12-01',
          reviewCount: 686,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-12-02',
          reviewCount: 686,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-12-03',
          reviewCount: 686,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-12-04',
          reviewCount: 700,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-12-05',
          reviewCount: 700,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-12-06',
          reviewCount: 700,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-12-07',
          reviewCount: 700,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-12-08',
          reviewCount: 700,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-12-09',
          reviewCount: 700,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-12-10',
          reviewCount: 700,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-12-11',
          reviewCount: 713,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-12-12',
          reviewCount: 713,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-12-13',
          reviewCount: 713,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-12-14',
          reviewCount: 713,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-12-15',
          reviewCount: 713,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-12-16',
          reviewCount: 713,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-12-17',
          reviewCount: 725,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-12-18',
          reviewCount: 725,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-12-19',
          reviewCount: 725,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-12-20',
          reviewCount: 725,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-12-21',
          reviewCount: 725,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-12-22',
          reviewCount: 725,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-12-23',
          reviewCount: 740,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-12-24',
          reviewCount: 740,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-12-25',
          reviewCount: 740,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-12-26',
          reviewCount: 740,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-12-27',
          reviewCount: 740,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-12-28',
          reviewCount: 740,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-12-29',
          reviewCount: 756,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-12-30',
          reviewCount: 756,
          rating: 4,
          salesRank: null
        },
        {
          date: '2018-12-31',
          reviewCount: 756,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-01-01',
          reviewCount: 756,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-01-02',
          reviewCount: 756,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-01-03',
          reviewCount: 756,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-01-04',
          reviewCount: 780,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-01-05',
          reviewCount: 780,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-01-06',
          reviewCount: 780,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-01-07',
          reviewCount: 780,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-01-08',
          reviewCount: 780,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-01-09',
          reviewCount: 790,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-01-10',
          reviewCount: 790,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-01-11',
          reviewCount: 790,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-01-12',
          reviewCount: 790,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-01-13',
          reviewCount: 790,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-01-14',
          reviewCount: 790,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-01-15',
          reviewCount: 790,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-01-16',
          reviewCount: 805,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-01-17',
          reviewCount: 805,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-01-18',
          reviewCount: 805,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-01-19',
          reviewCount: 805,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-01-20',
          reviewCount: 805,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-01-21',
          reviewCount: 805,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-01-22',
          reviewCount: 833,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-01-23',
          reviewCount: 833,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-01-24',
          reviewCount: 833,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-01-25',
          reviewCount: 833,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-01-26',
          reviewCount: 833,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-01-27',
          reviewCount: 833,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-01-28',
          reviewCount: 856,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-01-29',
          reviewCount: 856,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-01-30',
          reviewCount: 856,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-01-31',
          reviewCount: 856,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-02-01',
          reviewCount: 856,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-02-02',
          reviewCount: 856,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-02-03',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-02-04',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-02-05',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-02-06',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-02-07',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-02-08',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-02-09',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-02-10',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-02-11',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-02-12',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-02-13',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-02-14',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-02-15',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-02-16',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-02-17',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-02-18',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-02-19',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-02-20',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-02-21',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-02-22',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-02-23',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-02-24',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-02-25',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-02-26',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-02-27',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-02-28',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-03-01',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-03-02',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-03-03',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-03-04',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-03-05',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-03-06',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-03-07',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-03-08',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-03-09',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-03-10',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-03-11',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-03-12',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-03-13',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-03-14',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-03-15',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-03-16',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-03-17',
          reviewCount: 878,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-03-18',
          reviewCount: 999,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-03-19',
          reviewCount: 999,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-03-20',
          reviewCount: 999,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-03-21',
          reviewCount: 999,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-03-22',
          reviewCount: 999,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-03-23',
          reviewCount: 999,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-03-24',
          reviewCount: 999,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-03-25',
          reviewCount: 1017,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-03-26',
          reviewCount: 1017,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-03-27',
          reviewCount: 1017,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-03-28',
          reviewCount: 1017,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-03-29',
          reviewCount: 1017,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-03-30',
          reviewCount: 1017,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-03-31',
          reviewCount: 1017,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-04-01',
          reviewCount: 1023,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-04-02',
          reviewCount: 1023,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-04-03',
          reviewCount: 1023,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-04-04',
          reviewCount: 1023,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-04-05',
          reviewCount: 1023,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-04-06',
          reviewCount: 1023,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-04-07',
          reviewCount: 1023,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-04-08',
          reviewCount: 1032,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-04-09',
          reviewCount: 1032,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-04-10',
          reviewCount: 1032,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-04-11',
          reviewCount: 1032,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-04-12',
          reviewCount: 1032,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-04-13',
          reviewCount: 1032,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-04-14',
          reviewCount: 1032,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-04-15',
          reviewCount: 1032,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-04-16',
          reviewCount: 1032,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-04-17',
          reviewCount: 1032,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-04-18',
          reviewCount: 1032,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-04-19',
          reviewCount: 1032,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-04-20',
          reviewCount: 1032,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-04-21',
          reviewCount: 1032,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-04-22',
          reviewCount: 1032,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-04-23',
          reviewCount: 1032,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-04-24',
          reviewCount: 1032,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-04-25',
          reviewCount: 1032,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-04-26',
          reviewCount: 1032,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-04-27',
          reviewCount: 1032,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-04-28',
          reviewCount: 1032,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-04-29',
          reviewCount: 1032,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-04-30',
          reviewCount: 1032,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-05-01',
          reviewCount: 1032,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-05-02',
          reviewCount: 1032,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-05-03',
          reviewCount: 1032,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-05-04',
          reviewCount: 1032,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-05-05',
          reviewCount: 1032,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-05-06',
          reviewCount: 1032,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-05-07',
          reviewCount: 1032,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-05-08',
          reviewCount: 1032,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-05-09',
          reviewCount: 1032,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-05-10',
          reviewCount: 1032,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-05-11',
          reviewCount: 1032,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-05-12',
          reviewCount: 1032,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-05-13',
          reviewCount: 1032,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-05-14',
          reviewCount: 1032,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-05-15',
          reviewCount: 1032,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-05-16',
          reviewCount: 1079,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-05-17',
          reviewCount: 1079,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-05-18',
          reviewCount: 1079,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-05-19',
          reviewCount: 1079,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-05-20',
          reviewCount: 1079,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-05-21',
          reviewCount: 1079,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-05-22',
          reviewCount: 1079,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-05-23',
          reviewCount: 1088,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-05-24',
          reviewCount: 1088,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-05-25',
          reviewCount: 1088,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-05-26',
          reviewCount: 1088,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-05-27',
          reviewCount: 1088,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-05-28',
          reviewCount: 1088,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-05-29',
          reviewCount: 1088,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-05-30',
          reviewCount: 1088,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-05-31',
          reviewCount: 1088,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-06-01',
          reviewCount: 1088,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-06-02',
          reviewCount: 1088,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-06-03',
          reviewCount: 1088,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-06-04',
          reviewCount: 1088,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-06-05',
          reviewCount: 1088,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-06-06',
          reviewCount: 1088,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-06-07',
          reviewCount: 1088,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-06-08',
          reviewCount: 1088,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-06-09',
          reviewCount: 1088,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-06-10',
          reviewCount: 1088,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-06-11',
          reviewCount: 1088,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-06-12',
          reviewCount: 1102,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-06-13',
          reviewCount: 1102,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-06-14',
          reviewCount: 1102,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-06-15',
          reviewCount: 1102,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-06-16',
          reviewCount: 1102,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-06-17',
          reviewCount: 1102,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-06-18',
          reviewCount: 1102,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-06-19',
          reviewCount: 1106,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-06-20',
          reviewCount: 1106,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-06-21',
          reviewCount: 1106,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-06-22',
          reviewCount: 1106,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-06-23',
          reviewCount: 1106,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-06-24',
          reviewCount: 1106,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-06-25',
          reviewCount: 1106,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-06-26',
          reviewCount: 1106,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-06-27',
          reviewCount: 1106,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-06-28',
          reviewCount: 1106,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-06-29',
          reviewCount: 1106,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-06-30',
          reviewCount: 1106,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-07-01',
          reviewCount: 1106,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-07-02',
          reviewCount: 1106,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-07-03',
          reviewCount: 1114,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-07-04',
          reviewCount: 1114,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-07-05',
          reviewCount: 1114,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-07-06',
          reviewCount: 1114,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-07-07',
          reviewCount: 1114,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-07-08',
          reviewCount: 1114,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-07-09',
          reviewCount: 1114,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-07-10',
          reviewCount: 1114,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-07-11',
          reviewCount: 1114,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-07-12',
          reviewCount: 1114,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-07-13',
          reviewCount: 1122,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-07-14',
          reviewCount: 1122,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-07-15',
          reviewCount: 1122,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-07-16',
          reviewCount: 1122,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-07-17',
          reviewCount: 1122,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-07-18',
          reviewCount: 1122,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-07-19',
          reviewCount: 1122,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-07-20',
          reviewCount: 1122,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-07-21',
          reviewCount: 1122,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-07-22',
          reviewCount: 1122,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-07-23',
          reviewCount: 1122,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-07-24',
          reviewCount: 1131,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-07-25',
          reviewCount: 1131,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-07-26',
          reviewCount: 1131,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-07-27',
          reviewCount: 1131,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-07-28',
          reviewCount: 1131,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-07-29',
          reviewCount: 1131,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-07-30',
          reviewCount: 1131,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-07-31',
          reviewCount: 1135,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-08-01',
          reviewCount: 1135,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-08-02',
          reviewCount: 1135,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-08-03',
          reviewCount: 1135,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-08-04',
          reviewCount: 1135,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-08-05',
          reviewCount: 1135,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-08-06',
          reviewCount: 1135,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-08-07',
          reviewCount: 1144,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-08-08',
          reviewCount: 1144,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-08-09',
          reviewCount: 1144,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-08-10',
          reviewCount: 1144,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-08-11',
          reviewCount: 1144,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-08-12',
          reviewCount: 1144,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-08-13',
          reviewCount: 1144,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-08-14',
          reviewCount: 1148,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-08-15',
          reviewCount: 1148,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-08-16',
          reviewCount: 1148,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-08-17',
          reviewCount: 1148,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-08-18',
          reviewCount: 1148,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-08-19',
          reviewCount: 1148,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-08-20',
          reviewCount: 1148,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-08-21',
          reviewCount: 1148,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-08-22',
          reviewCount: 1155,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-08-23',
          reviewCount: 1155,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-08-24',
          reviewCount: 1155,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-08-25',
          reviewCount: 1155,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-08-26',
          reviewCount: 1155,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-08-27',
          reviewCount: 1155,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-08-28',
          reviewCount: 1155,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-08-29',
          reviewCount: 1159,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-08-30',
          reviewCount: 1159,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-08-31',
          reviewCount: 1159,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-09-01',
          reviewCount: 1159,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-09-02',
          reviewCount: 1159,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-09-03',
          reviewCount: 1159,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-09-04',
          reviewCount: 1159,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-09-05',
          reviewCount: 1164,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-09-06',
          reviewCount: 1164,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-09-07',
          reviewCount: 1164,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-09-08',
          reviewCount: 1164,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-09-09',
          reviewCount: 1164,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-09-10',
          reviewCount: 1164,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-09-11',
          reviewCount: 1164,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-09-12',
          reviewCount: 1168,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-09-13',
          reviewCount: 1168,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-09-14',
          reviewCount: 1168,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-09-15',
          reviewCount: 1168,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-09-16',
          reviewCount: 1168,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-09-17',
          reviewCount: 1168,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-09-18',
          reviewCount: 1168,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-09-19',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-09-20',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-09-21',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-09-22',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-09-23',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-09-24',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-09-25',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-09-26',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-09-27',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-09-28',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-09-29',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-09-30',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-10-01',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-10-02',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-10-03',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-10-04',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-10-05',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-10-06',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-10-07',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-10-08',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-10-09',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-10-10',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-10-11',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-10-12',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-10-13',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-10-14',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-10-15',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-10-16',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-10-17',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-10-18',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-10-19',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-10-20',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-10-21',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-10-22',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-10-23',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-10-24',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-10-25',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-10-26',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-10-27',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-10-28',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-10-29',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-10-30',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-10-31',
          reviewCount: 1174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-11-01',
          reviewCount: 1246,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-11-02',
          reviewCount: 1246,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-11-03',
          reviewCount: 1246,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-11-04',
          reviewCount: 1248,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-11-05',
          reviewCount: 1248,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-11-06',
          reviewCount: 1248,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-11-07',
          reviewCount: 1248,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-11-08',
          reviewCount: 1248,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-11-09',
          reviewCount: 1248,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-11-10',
          reviewCount: 1248,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-11-11',
          reviewCount: 1248,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-11-12',
          reviewCount: 1248,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-11-13',
          reviewCount: 1273,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-11-14',
          reviewCount: 1273,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-11-15',
          reviewCount: 1273,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-11-16',
          reviewCount: 1273,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-11-17',
          reviewCount: 1273,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-11-18',
          reviewCount: 1273,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-11-19',
          reviewCount: 1273,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-11-20',
          reviewCount: 1273,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-11-21',
          reviewCount: 1273,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-11-22',
          reviewCount: 1273,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-11-23',
          reviewCount: 1273,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-11-24',
          reviewCount: 1320,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-11-25',
          reviewCount: 1320,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-11-26',
          reviewCount: 1320,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-11-27',
          reviewCount: 1320,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-11-28',
          reviewCount: 1320,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-11-29',
          reviewCount: 1320,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-11-30',
          reviewCount: 1320,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-12-01',
          reviewCount: 1320,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-12-02',
          reviewCount: 1320,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-12-03',
          reviewCount: 1320,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-12-04',
          reviewCount: 1320,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-12-05',
          reviewCount: 1320,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-12-06',
          reviewCount: 1320,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-12-07',
          reviewCount: 1320,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-12-08',
          reviewCount: 1320,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-12-09',
          reviewCount: 1320,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-12-10',
          reviewCount: 1370,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-12-11',
          reviewCount: 1370,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-12-12',
          reviewCount: 1370,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-12-13',
          reviewCount: 1370,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-12-14',
          reviewCount: 1370,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-12-15',
          reviewCount: 1370,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-12-16',
          reviewCount: 1370,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-12-17',
          reviewCount: 1428,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-12-18',
          reviewCount: 1428,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-12-19',
          reviewCount: 1428,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-12-20',
          reviewCount: 1428,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-12-21',
          reviewCount: 1428,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-12-22',
          reviewCount: 1428,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-12-23',
          reviewCount: 1428,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-12-24',
          reviewCount: 1428,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-12-25',
          reviewCount: 1479,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-12-26',
          reviewCount: 1479,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-12-27',
          reviewCount: 1479,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-12-28',
          reviewCount: 1479,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-12-29',
          reviewCount: 1479,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-12-30',
          reviewCount: 1479,
          rating: 4,
          salesRank: null
        },
        {
          date: '2019-12-31',
          reviewCount: 1479,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-01-01',
          reviewCount: 1479,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-01-02',
          reviewCount: 1528,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-01-03',
          reviewCount: 1528,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-01-04',
          reviewCount: 1528,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-01-05',
          reviewCount: 1528,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-01-06',
          reviewCount: 1528,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-01-07',
          reviewCount: 1528,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-01-08',
          reviewCount: 1528,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-01-09',
          reviewCount: 1528,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-01-10',
          reviewCount: 1673,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-01-11',
          reviewCount: 1673,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-01-12',
          reviewCount: 1673,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-01-13',
          reviewCount: 1673,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-01-14',
          reviewCount: 1673,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-01-15',
          reviewCount: 1673,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-01-16',
          reviewCount: 1673,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-01-17',
          reviewCount: 1673,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-01-18',
          reviewCount: 1673,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-01-19',
          reviewCount: 1679,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-01-20',
          reviewCount: 1679,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-01-21',
          reviewCount: 1679,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-01-22',
          reviewCount: 1679,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-01-23',
          reviewCount: 1679,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-01-24',
          reviewCount: 1679,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-01-25',
          reviewCount: 1679,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-01-26',
          reviewCount: 1679,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-01-27',
          reviewCount: 1679,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-01-28',
          reviewCount: 1766,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-01-29',
          reviewCount: 1766,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-01-30',
          reviewCount: 1766,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-01-31',
          reviewCount: 1766,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-02-01',
          reviewCount: 1766,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-02-02',
          reviewCount: 1766,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-02-03',
          reviewCount: 1766,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-02-04',
          reviewCount: 1766,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-02-05',
          reviewCount: 1766,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-02-06',
          reviewCount: 1766,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-02-07',
          reviewCount: 1766,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-02-08',
          reviewCount: 1906,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-02-09',
          reviewCount: 1906,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-02-10',
          reviewCount: 1906,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-02-11',
          reviewCount: 1906,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-02-12',
          reviewCount: 1906,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-02-13',
          reviewCount: 1906,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-02-14',
          reviewCount: 1906,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-02-15',
          reviewCount: 1906,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-02-16',
          reviewCount: 1906,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-02-17',
          reviewCount: 1906,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-02-18',
          reviewCount: 1906,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-02-19',
          reviewCount: 1906,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-02-20',
          reviewCount: 1906,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-02-21',
          reviewCount: 2041,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-02-22',
          reviewCount: 2041,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-02-23',
          reviewCount: 2041,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-02-24',
          reviewCount: 2041,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-02-25',
          reviewCount: 2041,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-02-26',
          reviewCount: 2041,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-02-27',
          reviewCount: 2041,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-02-28',
          reviewCount: 2041,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-02-29',
          reviewCount: 2041,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-03-01',
          reviewCount: 2041,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-03-02',
          reviewCount: 2041,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-03-03',
          reviewCount: 2234,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-03-04',
          reviewCount: 2234,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-03-05',
          reviewCount: 2234,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-03-06',
          reviewCount: 2234,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-03-07',
          reviewCount: 2234,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-03-08',
          reviewCount: 2234,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-03-09',
          reviewCount: 2234,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-03-10',
          reviewCount: 2234,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-03-11',
          reviewCount: 2234,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-03-12',
          reviewCount: 2234,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-03-13',
          reviewCount: 2234,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-03-14',
          reviewCount: 2333,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-03-15',
          reviewCount: 2333,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-03-16',
          reviewCount: 2333,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-03-17',
          reviewCount: 2333,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-03-18',
          reviewCount: 2333,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-03-19',
          reviewCount: 2333,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-03-20',
          reviewCount: 2333,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-03-21',
          reviewCount: 2333,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-03-22',
          reviewCount: 2333,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-03-23',
          reviewCount: 2408,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-03-24',
          reviewCount: 2424,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-03-25',
          reviewCount: 2440,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-03-26',
          reviewCount: 2448,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-03-27',
          reviewCount: 2457,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-03-28',
          reviewCount: 2481,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-03-29',
          reviewCount: 2487,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-03-30',
          reviewCount: 2492,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-03-31',
          reviewCount: 2506,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-04-01',
          reviewCount: 2526,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-04-02',
          reviewCount: 2541,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-04-03',
          reviewCount: 2561,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-04-04',
          reviewCount: 2576,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-04-05',
          reviewCount: 2581,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-04-06',
          reviewCount: 2588,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-04-07',
          reviewCount: 2608,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-04-08',
          reviewCount: 2619,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-04-09',
          reviewCount: 2626,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-04-10',
          reviewCount: 2639,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-04-11',
          reviewCount: 2653,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-04-12',
          reviewCount: 2658,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-04-13',
          reviewCount: 2658,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-04-14',
          reviewCount: 2685,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-04-15',
          reviewCount: 2700,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-04-16',
          reviewCount: 2720,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-04-17',
          reviewCount: 2749,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-04-18',
          reviewCount: 2800,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-04-19',
          reviewCount: 2837,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-04-20',
          reviewCount: 2850,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-04-21',
          reviewCount: 2857,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-04-22',
          reviewCount: 2857,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-04-23',
          reviewCount: 2881,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-04-24',
          reviewCount: 2887,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-04-25',
          reviewCount: 2889,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-04-26',
          reviewCount: 2893,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-04-27',
          reviewCount: 2896,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-04-28',
          reviewCount: 2901,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-04-29',
          reviewCount: 2908,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-04-30',
          reviewCount: 2915,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-05-01',
          reviewCount: 2915,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-05-02',
          reviewCount: 2915,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-05-03',
          reviewCount: 2915,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-05-04',
          reviewCount: 2915,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-05-05',
          reviewCount: 2915,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-05-06',
          reviewCount: 2915,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-05-07',
          reviewCount: 2973,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-05-08',
          reviewCount: 2973,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-05-09',
          reviewCount: 2973,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-05-10',
          reviewCount: 2973,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-05-11',
          reviewCount: 2973,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-05-12',
          reviewCount: 2973,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-05-13',
          reviewCount: 2973,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-05-14',
          reviewCount: 2973,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-05-15',
          reviewCount: 2973,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-05-16',
          reviewCount: 3030,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-05-17',
          reviewCount: 3030,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-05-18',
          reviewCount: 3030,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-05-19',
          reviewCount: 3030,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-05-20',
          reviewCount: 3030,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-05-21',
          reviewCount: 3030,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-05-22',
          reviewCount: 3030,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-05-23',
          reviewCount: 3030,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-05-24',
          reviewCount: 3030,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-05-25',
          reviewCount: 3061,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-05-26',
          reviewCount: 3061,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-05-27',
          reviewCount: 3061,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-05-28',
          reviewCount: 3061,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-05-29',
          reviewCount: 3061,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-05-30',
          reviewCount: 3061,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-05-31',
          reviewCount: 3061,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-06-01',
          reviewCount: 3061,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-06-02',
          reviewCount: 3061,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-06-03',
          reviewCount: 3061,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-06-04',
          reviewCount: 3119,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-06-05',
          reviewCount: 3119,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-06-06',
          reviewCount: 3119,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-06-07',
          reviewCount: 3119,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-06-08',
          reviewCount: 3119,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-06-09',
          reviewCount: 3119,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-06-10',
          reviewCount: 3119,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-06-11',
          reviewCount: 3119,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-06-12',
          reviewCount: 3119,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-06-13',
          reviewCount: 3119,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-06-14',
          reviewCount: 3119,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-06-15',
          reviewCount: 3171,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-06-16',
          reviewCount: 3171,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-06-17',
          reviewCount: 3171,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-06-18',
          reviewCount: 3171,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-06-19',
          reviewCount: 3171,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-06-20',
          reviewCount: 3171,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-06-21',
          reviewCount: 3171,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-06-22',
          reviewCount: 3171,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-06-23',
          reviewCount: 3171,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-06-24',
          reviewCount: 3171,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-06-25',
          reviewCount: 3171,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-06-26',
          reviewCount: 3225,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-06-27',
          reviewCount: 3225,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-06-28',
          reviewCount: 3225,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-06-29',
          reviewCount: 3225,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-06-30',
          reviewCount: 3225,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-07-01',
          reviewCount: 3225,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-07-02',
          reviewCount: 3225,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-07-03',
          reviewCount: 3225,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-07-04',
          reviewCount: 3225,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-07-05',
          reviewCount: 3244,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-07-06',
          reviewCount: 3244,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-07-07',
          reviewCount: 3244,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-07-08',
          reviewCount: 3244,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-07-09',
          reviewCount: 3244,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-07-10',
          reviewCount: 3244,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-07-11',
          reviewCount: 3244,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-07-12',
          reviewCount: 3244,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-07-13',
          reviewCount: 3244,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-07-14',
          reviewCount: 3244,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-07-15',
          reviewCount: 3244,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-07-16',
          reviewCount: 3244,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-07-17',
          reviewCount: 3244,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-07-18',
          reviewCount: 3306,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-07-19',
          reviewCount: 3306,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-07-20',
          reviewCount: 3306,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-07-21',
          reviewCount: 3306,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-07-22',
          reviewCount: 3306,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-07-23',
          reviewCount: 3306,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-07-24',
          reviewCount: 3306,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-07-25',
          reviewCount: 3306,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-07-26',
          reviewCount: 3306,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-07-27',
          reviewCount: 3397,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-07-28',
          reviewCount: 3397,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-07-29',
          reviewCount: 3397,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-07-30',
          reviewCount: 3397,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-07-31',
          reviewCount: 3397,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-08-01',
          reviewCount: 3397,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-08-02',
          reviewCount: 3397,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-08-03',
          reviewCount: 3397,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-08-04',
          reviewCount: 3501,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-08-05',
          reviewCount: 3501,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-08-06',
          reviewCount: 3501,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-08-07',
          reviewCount: 3501,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-08-08',
          reviewCount: 3501,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-08-09',
          reviewCount: 3501,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-08-10',
          reviewCount: 3501,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-08-11',
          reviewCount: 3501,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-08-12',
          reviewCount: 3501,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-08-13',
          reviewCount: 3501,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-08-14',
          reviewCount: 3604,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-08-15',
          reviewCount: 3604,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-08-16',
          reviewCount: 3604,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-08-17',
          reviewCount: 3604,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-08-18',
          reviewCount: 3604,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-08-19',
          reviewCount: 3604,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-08-20',
          reviewCount: 3604,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-08-21',
          reviewCount: 3604,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-08-22',
          reviewCount: 3604,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-08-23',
          reviewCount: 3604,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-08-24',
          reviewCount: 3604,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-08-25',
          reviewCount: 3604,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-08-26',
          reviewCount: 3604,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-08-27',
          reviewCount: 3828,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-08-28',
          reviewCount: 3828,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-08-29',
          reviewCount: 3828,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-08-30',
          reviewCount: 3828,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-08-31',
          reviewCount: 3828,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-09-01',
          reviewCount: 3828,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-09-02',
          reviewCount: 3900,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-09-03',
          reviewCount: 3900,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-09-04',
          reviewCount: 3900,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-09-05',
          reviewCount: 3900,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-09-06',
          reviewCount: 3900,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-09-07',
          reviewCount: 3900,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-09-08',
          reviewCount: 3900,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-09-09',
          reviewCount: 3900,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-09-10',
          reviewCount: 3900,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-09-11',
          reviewCount: 3900,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-09-12',
          reviewCount: 3900,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-09-13',
          reviewCount: 3900,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-09-14',
          reviewCount: 4031,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-09-15',
          reviewCount: 4031,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-09-16',
          reviewCount: 4031,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-09-17',
          reviewCount: 4031,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-09-18',
          reviewCount: 4031,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-09-19',
          reviewCount: 4031,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-09-20',
          reviewCount: 4031,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-09-21',
          reviewCount: 4108,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-09-22',
          reviewCount: 4108,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-09-23',
          reviewCount: 4108,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-09-24',
          reviewCount: 4108,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-09-25',
          reviewCount: 4108,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-09-26',
          reviewCount: 4108,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-09-27',
          reviewCount: 4108,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-09-28',
          reviewCount: 4161,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-09-29',
          reviewCount: 4161,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-09-30',
          reviewCount: 4161,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-10-01',
          reviewCount: 4161,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-10-02',
          reviewCount: 4161,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-10-03',
          reviewCount: 4161,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-10-04',
          reviewCount: 4161,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-10-05',
          reviewCount: 4242,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-10-06',
          reviewCount: 4242,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-10-07',
          reviewCount: 4242,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-10-08',
          reviewCount: 4242,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-10-09',
          reviewCount: 4242,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-10-10',
          reviewCount: 4242,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-10-11',
          reviewCount: 4242,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-10-12',
          reviewCount: 4299,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-10-13',
          reviewCount: 4299,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-10-14',
          reviewCount: 4299,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-10-15',
          reviewCount: 4299,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-10-16',
          reviewCount: 4299,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-10-17',
          reviewCount: 4299,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-10-18',
          reviewCount: 4299,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-10-19',
          reviewCount: 4299,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-10-20',
          reviewCount: 4299,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-10-21',
          reviewCount: 4399,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-10-22',
          reviewCount: 4399,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-10-23',
          reviewCount: 4399,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-10-24',
          reviewCount: 4399,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-10-25',
          reviewCount: 4399,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-10-26',
          reviewCount: 4399,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-10-27',
          reviewCount: 4399,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-10-28',
          reviewCount: 4498,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-10-29',
          reviewCount: 4498,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-10-30',
          reviewCount: 4498,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-10-31',
          reviewCount: 4498,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-11-01',
          reviewCount: 4498,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-11-02',
          reviewCount: 4498,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-11-03',
          reviewCount: 4498,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-11-04',
          reviewCount: 4498,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-11-05',
          reviewCount: 4498,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-11-06',
          reviewCount: 4646,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-11-07',
          reviewCount: 4658,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-11-08',
          reviewCount: 4669,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-11-09',
          reviewCount: 4669,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-11-10',
          reviewCount: 4702,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-11-11',
          reviewCount: 4717,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-11-12',
          reviewCount: 4741,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-11-13',
          reviewCount: 4765,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-11-14',
          reviewCount: 4765,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-11-15',
          reviewCount: 4795,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-11-16',
          reviewCount: 4795,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-11-17',
          reviewCount: 4795,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-11-18',
          reviewCount: 4795,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-11-19',
          reviewCount: 4795,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-11-20',
          reviewCount: 4795,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-11-21',
          reviewCount: 4795,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-11-22',
          reviewCount: 4795,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-11-23',
          reviewCount: 4949,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-11-24',
          reviewCount: 4966,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-11-25',
          reviewCount: 4985,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-11-26',
          reviewCount: 5024,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-11-27',
          reviewCount: 5041,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-11-28',
          reviewCount: 5041,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-11-29',
          reviewCount: 5053,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-11-30',
          reviewCount: 5056,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-12-01',
          reviewCount: 5060,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-12-02',
          reviewCount: 5066,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-12-03',
          reviewCount: 5084,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-12-04',
          reviewCount: 5118,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-12-05',
          reviewCount: 5134,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-12-06',
          reviewCount: 5161,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-12-07',
          reviewCount: 5183,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-12-08',
          reviewCount: 5226,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-12-09',
          reviewCount: 5230,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-12-10',
          reviewCount: 5250,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-12-11',
          reviewCount: 5275,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-12-12',
          reviewCount: 5305,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-12-13',
          reviewCount: 5323,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-12-14',
          reviewCount: 5343,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-12-15',
          reviewCount: 5373,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-12-16',
          reviewCount: 5393,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-12-17',
          reviewCount: 5417,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-12-18',
          reviewCount: 5457,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-12-19',
          reviewCount: 5474,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-12-20',
          reviewCount: 5495,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-12-21',
          reviewCount: 5513,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-12-22',
          reviewCount: 5531,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-12-23',
          reviewCount: 5559,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-12-24',
          reviewCount: 5608,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-12-25',
          reviewCount: 5621,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-12-26',
          reviewCount: 5644,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-12-27',
          reviewCount: 5658,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-12-28',
          reviewCount: 5677,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-12-29',
          reviewCount: 5697,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-12-30',
          reviewCount: 5727,
          rating: 4,
          salesRank: null
        },
        {
          date: '2020-12-31',
          reviewCount: 5780,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-01-01',
          reviewCount: 5799,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-01-02',
          reviewCount: 5818,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-01-03',
          reviewCount: 5844,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-01-04',
          reviewCount: 5883,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-01-05',
          reviewCount: 5919,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-01-06',
          reviewCount: 5943,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-01-07',
          reviewCount: 5993,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-01-08',
          reviewCount: 5998,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-01-09',
          reviewCount: 6021,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-01-10',
          reviewCount: 6037,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-01-11',
          reviewCount: 6056,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-01-12',
          reviewCount: 6083,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-01-13',
          reviewCount: 6110,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-01-14',
          reviewCount: 6144,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-01-15',
          reviewCount: 6185,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-01-16',
          reviewCount: 6216,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-01-17',
          reviewCount: 6239,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-01-18',
          reviewCount: 6262,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-01-19',
          reviewCount: 6289,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-01-20',
          reviewCount: 6314,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-01-21',
          reviewCount: 6340,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-01-22',
          reviewCount: 6377,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-01-23',
          reviewCount: 6421,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-01-24',
          reviewCount: 6425,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-01-25',
          reviewCount: 6450,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-01-26',
          reviewCount: 6477,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-01-27',
          reviewCount: 6504,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-01-28',
          reviewCount: 6541,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-01-29',
          reviewCount: 6591,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-01-30',
          reviewCount: 6635,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-01-31',
          reviewCount: 6637,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-02-01',
          reviewCount: 6654,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-02-02',
          reviewCount: 6692,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-02-03',
          reviewCount: 6725,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-02-04',
          reviewCount: 6763,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-02-05',
          reviewCount: 6795,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-02-06',
          reviewCount: 6867,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-02-07',
          reviewCount: 6868,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-02-08',
          reviewCount: 6887,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-02-09',
          reviewCount: 6915,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-02-10',
          reviewCount: 6948,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-02-11',
          reviewCount: 6980,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-02-12',
          reviewCount: 7012,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-02-13',
          reviewCount: 7066,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-02-14',
          reviewCount: 7066,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-02-15',
          reviewCount: 7095,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-02-16',
          reviewCount: 7123,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-02-17',
          reviewCount: 7151,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-02-18',
          reviewCount: 7188,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-02-19',
          reviewCount: 7215,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-02-20',
          reviewCount: 7254,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-02-21',
          reviewCount: 7258,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-02-22',
          reviewCount: 7280,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-02-23',
          reviewCount: 7300,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-02-24',
          reviewCount: 7328,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-02-25',
          reviewCount: 7364,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-02-26',
          reviewCount: 7388,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-02-27',
          reviewCount: 7410,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-02-28',
          reviewCount: 7414,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-03-01',
          reviewCount: 7440,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-03-02',
          reviewCount: 7457,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-03-03',
          reviewCount: 7486,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-03-04',
          reviewCount: 7512,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-03-05',
          reviewCount: 7538,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-03-06',
          reviewCount: 7583,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-03-07',
          reviewCount: 7587,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-03-08',
          reviewCount: 7606,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-03-09',
          reviewCount: 7632,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-03-10',
          reviewCount: 7650,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-03-11',
          reviewCount: 7671,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-03-12',
          reviewCount: 7686,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-03-13',
          reviewCount: 7726,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-03-14',
          reviewCount: 7729,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-03-15',
          reviewCount: 7746,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-03-16',
          reviewCount: 7746,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-03-17',
          reviewCount: 7746,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-03-18',
          reviewCount: 7746,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-03-19',
          reviewCount: 7746,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-03-20',
          reviewCount: 7746,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-03-21',
          reviewCount: 7857,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-03-22',
          reviewCount: 7857,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-03-23',
          reviewCount: 7857,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-03-24',
          reviewCount: 7857,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-03-25',
          reviewCount: 7857,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-03-26',
          reviewCount: 7857,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-03-27',
          reviewCount: 7857,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-03-28',
          reviewCount: 7857,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-03-29',
          reviewCount: 7857,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-03-30',
          reviewCount: 8007,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-03-31',
          reviewCount: 8007,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-04-01',
          reviewCount: 8007,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-04-02',
          reviewCount: 8007,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-04-03',
          reviewCount: 8007,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-04-04',
          reviewCount: 8007,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-04-05',
          reviewCount: 8007,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-04-06',
          reviewCount: 8007,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-04-07',
          reviewCount: 8007,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-04-08',
          reviewCount: 8007,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-04-09',
          reviewCount: 8007,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-04-10',
          reviewCount: 8174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-04-11',
          reviewCount: 8174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-04-12',
          reviewCount: 8174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-04-13',
          reviewCount: 8174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-04-14',
          reviewCount: 8174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-04-15',
          reviewCount: 8174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-04-16',
          reviewCount: 8174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-04-17',
          reviewCount: 8174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-04-18',
          reviewCount: 8174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-04-19',
          reviewCount: 8288,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-04-20',
          reviewCount: 8288,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-04-21',
          reviewCount: 8288,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-04-22',
          reviewCount: 8288,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-04-23',
          reviewCount: 8288,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-04-24',
          reviewCount: 8288,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-04-25',
          reviewCount: 8288,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-04-26',
          reviewCount: 8288,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-04-27',
          reviewCount: 8288,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-04-28',
          reviewCount: 8288,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-04-29',
          reviewCount: 8379,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-04-30',
          reviewCount: 8379,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-05-01',
          reviewCount: 8379,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-05-02',
          reviewCount: 8379,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-05-03',
          reviewCount: 8379,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-05-04',
          reviewCount: 8379,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-05-05',
          reviewCount: 8379,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-05-06',
          reviewCount: 8379,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-05-07',
          reviewCount: 8467,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-05-08',
          reviewCount: 8467,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-05-09',
          reviewCount: 8467,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-05-10',
          reviewCount: 8467,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-05-11',
          reviewCount: 8467,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-05-12',
          reviewCount: 8467,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-05-13',
          reviewCount: 8467,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-05-14',
          reviewCount: 8467,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-05-15',
          reviewCount: 8467,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-05-16',
          reviewCount: 8467,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-05-17',
          reviewCount: 8467,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-05-18',
          reviewCount: 8467,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-05-19',
          reviewCount: 8467,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-05-20',
          reviewCount: 8467,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-05-21',
          reviewCount: 8539,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-05-22',
          reviewCount: 8539,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-05-23',
          reviewCount: 8539,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-05-24',
          reviewCount: 8539,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-05-25',
          reviewCount: 8539,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-05-26',
          reviewCount: 8539,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-05-27',
          reviewCount: 8539,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-05-28',
          reviewCount: 8539,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-05-29',
          reviewCount: 8585,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-05-30',
          reviewCount: 8585,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-05-31',
          reviewCount: 8585,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-06-01',
          reviewCount: 8585,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-06-02',
          reviewCount: 8585,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-06-03',
          reviewCount: 8585,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-06-04',
          reviewCount: 8585,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-06-05',
          reviewCount: 8585,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-06-06',
          reviewCount: 8612,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-06-07',
          reviewCount: 8612,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-06-08',
          reviewCount: 8612,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-06-09',
          reviewCount: 8612,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-06-10',
          reviewCount: 8612,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-06-11',
          reviewCount: 8612,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-06-12',
          reviewCount: 8612,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-06-13',
          reviewCount: 8612,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-06-14',
          reviewCount: 8667,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-06-15',
          reviewCount: 8667,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-06-16',
          reviewCount: 8667,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-06-17',
          reviewCount: 8667,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-06-18',
          reviewCount: 8667,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-06-19',
          reviewCount: 8667,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-06-20',
          reviewCount: 8667,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-06-21',
          reviewCount: 8667,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-06-22',
          reviewCount: 8667,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-06-23',
          reviewCount: 8667,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-06-24',
          reviewCount: 8667,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-06-25',
          reviewCount: 8710,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-06-26',
          reviewCount: 8710,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-06-27',
          reviewCount: 8710,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-06-28',
          reviewCount: 8710,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-06-29',
          reviewCount: 8710,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-06-30',
          reviewCount: 8710,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-07-01',
          reviewCount: 8710,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-07-02',
          reviewCount: 8710,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-07-03',
          reviewCount: 8776,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-07-04',
          reviewCount: 8776,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-07-05',
          reviewCount: 8776,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-07-06',
          reviewCount: 8776,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-07-07',
          reviewCount: 8776,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-07-08',
          reviewCount: 8776,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-07-09',
          reviewCount: 8776,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-07-10',
          reviewCount: 8776,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-07-11',
          reviewCount: 8776,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-07-12',
          reviewCount: 8829,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-07-13',
          reviewCount: 8829,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-07-14',
          reviewCount: 8829,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-07-15',
          reviewCount: 8829,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-07-16',
          reviewCount: 8829,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-07-17',
          reviewCount: 8829,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-07-18',
          reviewCount: 8829,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-07-19',
          reviewCount: 8829,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-07-20',
          reviewCount: 8858,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-07-21',
          reviewCount: 8858,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-07-22',
          reviewCount: 8858,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-07-23',
          reviewCount: 8858,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-07-24',
          reviewCount: 8858,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-07-25',
          reviewCount: 8858,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-07-26',
          reviewCount: 8858,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-07-27',
          reviewCount: 8883,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-07-28',
          reviewCount: 8883,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-07-29',
          reviewCount: 8883,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-07-30',
          reviewCount: 8883,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-07-31',
          reviewCount: 8883,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-08-01',
          reviewCount: 8883,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-08-02',
          reviewCount: 8883,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-08-03',
          reviewCount: 8883,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-08-04',
          reviewCount: 8921,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-08-05',
          reviewCount: 8921,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-08-06',
          reviewCount: 8921,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-08-07',
          reviewCount: 8921,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-08-08',
          reviewCount: 8921,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-08-09',
          reviewCount: 8921,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-08-10',
          reviewCount: 8921,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-08-11',
          reviewCount: 8951,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-08-12',
          reviewCount: 8951,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-08-13',
          reviewCount: 8951,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-08-14',
          reviewCount: 8951,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-08-15',
          reviewCount: 8951,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-08-16',
          reviewCount: 8951,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-08-17',
          reviewCount: 8951,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-08-18',
          reviewCount: 8974,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-08-19',
          reviewCount: 8974,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-08-20',
          reviewCount: 8974,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-08-21',
          reviewCount: 8974,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-08-22',
          reviewCount: 8974,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-08-23',
          reviewCount: 8974,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-08-24',
          reviewCount: 8974,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-08-25',
          reviewCount: 8974,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-08-26',
          reviewCount: 9010,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-08-27',
          reviewCount: 9010,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-08-28',
          reviewCount: 9010,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-08-29',
          reviewCount: 9010,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-08-30',
          reviewCount: 9010,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-08-31',
          reviewCount: 9010,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-09-01',
          reviewCount: 9010,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-09-02',
          reviewCount: 9010,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-09-03',
          reviewCount: 9010,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-09-04',
          reviewCount: 9045,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-09-05',
          reviewCount: 9045,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-09-06',
          reviewCount: 9045,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-09-07',
          reviewCount: 9045,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-09-08',
          reviewCount: 9045,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-09-09',
          reviewCount: 9045,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-09-10',
          reviewCount: 9045,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-09-11',
          reviewCount: 9045,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-09-12',
          reviewCount: 9045,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-09-13',
          reviewCount: 9045,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-09-14',
          reviewCount: 9091,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-09-15',
          reviewCount: 9091,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-09-16',
          reviewCount: 9091,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-09-17',
          reviewCount: 9091,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-09-18',
          reviewCount: 9091,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-09-19',
          reviewCount: 9091,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-09-20',
          reviewCount: 9091,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-09-21',
          reviewCount: 9091,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-09-22',
          reviewCount: 9091,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-09-23',
          reviewCount: 9091,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-09-24',
          reviewCount: 9091,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-09-25',
          reviewCount: 9091,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-09-26',
          reviewCount: 9091,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-09-27',
          reviewCount: 9091,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-09-28',
          reviewCount: 9142,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-09-29',
          reviewCount: 9142,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-09-30',
          reviewCount: 9142,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-10-01',
          reviewCount: 9142,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-10-02',
          reviewCount: 9142,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-10-03',
          reviewCount: 9142,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-10-04',
          reviewCount: 9142,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-10-05',
          reviewCount: 9142,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-10-06',
          reviewCount: 9170,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-10-07',
          reviewCount: 9170,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-10-08',
          reviewCount: 9170,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-10-09',
          reviewCount: 9170,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-10-10',
          reviewCount: 9170,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-10-11',
          reviewCount: 9170,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-10-12',
          reviewCount: 9170,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-10-13',
          reviewCount: 9200,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-10-14',
          reviewCount: 9200,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-10-15',
          reviewCount: 9200,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-10-16',
          reviewCount: 9200,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-10-17',
          reviewCount: 9200,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-10-18',
          reviewCount: 9200,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-10-19',
          reviewCount: 9200,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-10-20',
          reviewCount: 9200,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-10-21',
          reviewCount: 9223,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-10-22',
          reviewCount: 9223,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-10-23',
          reviewCount: 9223,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-10-24',
          reviewCount: 9223,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-10-25',
          reviewCount: 9223,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-10-26',
          reviewCount: 9223,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-10-27',
          reviewCount: 9223,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-10-28',
          reviewCount: 9223,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-10-29',
          reviewCount: 9266,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-10-30',
          reviewCount: 9266,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-10-31',
          reviewCount: 9266,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-11-01',
          reviewCount: 9266,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-11-02',
          reviewCount: 9266,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-11-03',
          reviewCount: 9266,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-11-04',
          reviewCount: 9266,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-11-05',
          reviewCount: 9266,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-11-06',
          reviewCount: 9266,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-11-07',
          reviewCount: 9308,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-11-08',
          reviewCount: 9308,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-11-09',
          reviewCount: 9308,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-11-10',
          reviewCount: 9308,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-11-11',
          reviewCount: 9333,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-11-12',
          reviewCount: 9333,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-11-13',
          reviewCount: 9333,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-11-14',
          reviewCount: 9347,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-11-15',
          reviewCount: 9350,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-11-16',
          reviewCount: 9356,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-11-17',
          reviewCount: 9361,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-11-18',
          reviewCount: 9372,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-11-19',
          reviewCount: 9381,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-11-20',
          reviewCount: 9385,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-11-21',
          reviewCount: 9395,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-11-22',
          reviewCount: 9402,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-11-23',
          reviewCount: 9412,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-11-24',
          reviewCount: 9414,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-11-25',
          reviewCount: 9425,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-11-26',
          reviewCount: 9437,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-11-27',
          reviewCount: 9448,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-11-28',
          reviewCount: 9447,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-11-29',
          reviewCount: 9449,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-11-30',
          reviewCount: 9452,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-12-01',
          reviewCount: 9455,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-12-02',
          reviewCount: 9456,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-12-03',
          reviewCount: 9460,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-12-04',
          reviewCount: 9464,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-12-05',
          reviewCount: 9476,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-12-06',
          reviewCount: 9482,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-12-07',
          reviewCount: 9489,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-12-08',
          reviewCount: 9498,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-12-09',
          reviewCount: 9503,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-12-10',
          reviewCount: 9521,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-12-11',
          reviewCount: 9528,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-12-12',
          reviewCount: 9528,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-12-13',
          reviewCount: 9553,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-12-14',
          reviewCount: 9560,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-12-15',
          reviewCount: 9574,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-12-16',
          reviewCount: 9585,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-12-17',
          reviewCount: 9591,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-12-18',
          reviewCount: 9596,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-12-19',
          reviewCount: 9606,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-12-20',
          reviewCount: 9622,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-12-21',
          reviewCount: 9637,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-12-22',
          reviewCount: 9647,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-12-23',
          reviewCount: 9669,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-12-24',
          reviewCount: 9677,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-12-25',
          reviewCount: 9701,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-12-26',
          reviewCount: 9716,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-12-27',
          reviewCount: 9729,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-12-28',
          reviewCount: 9735,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-12-29',
          reviewCount: 9759,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-12-30',
          reviewCount: 9785,
          rating: 4,
          salesRank: null
        },
        {
          date: '2021-12-31',
          reviewCount: 9794,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-01-01',
          reviewCount: 9814,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-01-02',
          reviewCount: 9824,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-01-03',
          reviewCount: 9835,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-01-04',
          reviewCount: 9850,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-01-05',
          reviewCount: 9867,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-01-06',
          reviewCount: 9902,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-01-07',
          reviewCount: 9906,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-01-08',
          reviewCount: 9929,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-01-09',
          reviewCount: 9949,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-01-10',
          reviewCount: 9969,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-01-11',
          reviewCount: 9986,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-01-12',
          reviewCount: 10014,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-01-13',
          reviewCount: 10050,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-01-14',
          reviewCount: 10074,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-01-15',
          reviewCount: 10091,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-01-16',
          reviewCount: 10117,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-01-17',
          reviewCount: 10117,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-01-18',
          reviewCount: 10159,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-01-19',
          reviewCount: 10188,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-01-20',
          reviewCount: 10218,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-01-21',
          reviewCount: 10260,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-01-22',
          reviewCount: 10301,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-01-23',
          reviewCount: 10321,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-01-24',
          reviewCount: 10343,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-01-25',
          reviewCount: 10372,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-01-26',
          reviewCount: 10403,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-01-27',
          reviewCount: 10432,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-01-28',
          reviewCount: 10467,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-01-29',
          reviewCount: 10495,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-01-30',
          reviewCount: 10521,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-01-31',
          reviewCount: 10548,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-02-01',
          reviewCount: 10614,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-02-02',
          reviewCount: 10622,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-02-03',
          reviewCount: 10659,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-02-04',
          reviewCount: 10733,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-02-05',
          reviewCount: 10739,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-02-06',
          reviewCount: 10770,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-02-07',
          reviewCount: 10770,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-02-08',
          reviewCount: 10841,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-02-09',
          reviewCount: 10879,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-02-10',
          reviewCount: 10915,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-02-11',
          reviewCount: 10977,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-02-12',
          reviewCount: 10978,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-02-13',
          reviewCount: 11003,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-02-14',
          reviewCount: 11028,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-02-15',
          reviewCount: 11053,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-02-16',
          reviewCount: 11075,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-02-17',
          reviewCount: 11123,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-02-18',
          reviewCount: 11148,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-02-19',
          reviewCount: 11208,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-02-20',
          reviewCount: 11211,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-02-21',
          reviewCount: 11242,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-02-22',
          reviewCount: 11280,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-02-23',
          reviewCount: 11300,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-02-24',
          reviewCount: 11330,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-02-25',
          reviewCount: 11365,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-02-26',
          reviewCount: 11426,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-02-27',
          reviewCount: 11428,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-02-28',
          reviewCount: 11451,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-03-01',
          reviewCount: 11472,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-03-02',
          reviewCount: 11492,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-03-03',
          reviewCount: 11537,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-03-04',
          reviewCount: 11550,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-03-05',
          reviewCount: 11583,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-03-06',
          reviewCount: 11584,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-03-07',
          reviewCount: 11584,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-03-08',
          reviewCount: 11644,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-03-09',
          reviewCount: 11672,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-03-10',
          reviewCount: 11699,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-03-11',
          reviewCount: 11728,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-03-12',
          reviewCount: 11779,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-03-13',
          reviewCount: 11780,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-03-14',
          reviewCount: 11801,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-03-15',
          reviewCount: 11850,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-03-16',
          reviewCount: 11852,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-03-17',
          reviewCount: 11873,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-03-18',
          reviewCount: 11895,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-03-19',
          reviewCount: 11924,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-03-20',
          reviewCount: 11949,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-03-21',
          reviewCount: 11964,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-03-22',
          reviewCount: 11985,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-03-23',
          reviewCount: 12007,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-03-24',
          reviewCount: 12033,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-03-25',
          reviewCount: 12046,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-03-26',
          reviewCount: 12080,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-03-27',
          reviewCount: 12095,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-03-28',
          reviewCount: 12120,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-03-29',
          reviewCount: 12143,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-03-30',
          reviewCount: 12162,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-03-31',
          reviewCount: 12182,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-04-01',
          reviewCount: 12205,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-04-02',
          reviewCount: 12220,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-04-03',
          reviewCount: 12223,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-04-04',
          reviewCount: 12223,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-04-05',
          reviewCount: 12223,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-04-06',
          reviewCount: 12223,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-04-07',
          reviewCount: 12223,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-04-08',
          reviewCount: 12223,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-04-09',
          reviewCount: 12223,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-04-10',
          reviewCount: 12286,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-04-11',
          reviewCount: 12286,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-04-12',
          reviewCount: 12286,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-04-13',
          reviewCount: 12286,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-04-14',
          reviewCount: 12286,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-04-15',
          reviewCount: 12286,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-04-16',
          reviewCount: 12286,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-04-17',
          reviewCount: 12286,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-04-18',
          reviewCount: 12520,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-04-19',
          reviewCount: 12520,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-04-20',
          reviewCount: 12520,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-04-21',
          reviewCount: 12520,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-04-22',
          reviewCount: 12520,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-04-23',
          reviewCount: 12520,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-04-24',
          reviewCount: 12520,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-04-25',
          reviewCount: 12628,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-04-26',
          reviewCount: 12628,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-04-27',
          reviewCount: 12628,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-04-28',
          reviewCount: 12628,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-04-29',
          reviewCount: 12628,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-04-30',
          reviewCount: 12628,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-05-01',
          reviewCount: 12628,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-05-02',
          reviewCount: 12720,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-05-03',
          reviewCount: 12720,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-05-04',
          reviewCount: 12720,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-05-05',
          reviewCount: 12720,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-05-06',
          reviewCount: 12720,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-05-07',
          reviewCount: 12720,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-05-08',
          reviewCount: 12720,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-05-09',
          reviewCount: 12815,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-05-10',
          reviewCount: 12815,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-05-11',
          reviewCount: 12815,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-05-12',
          reviewCount: 12815,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-05-13',
          reviewCount: 12815,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-05-14',
          reviewCount: 12815,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-05-15',
          reviewCount: 12815,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-05-16',
          reviewCount: 12893,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-05-17',
          reviewCount: 12893,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-05-18',
          reviewCount: 12893,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-05-19',
          reviewCount: 12893,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-05-20',
          reviewCount: 12893,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-05-21',
          reviewCount: 12893,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-05-22',
          reviewCount: 12893,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-05-23',
          reviewCount: 12893,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-05-24',
          reviewCount: 12893,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-05-25',
          reviewCount: 13000,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-05-26',
          reviewCount: 13000,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-05-27',
          reviewCount: 13000,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-05-28',
          reviewCount: 13000,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-05-29',
          reviewCount: 13000,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-05-30',
          reviewCount: 13000,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-05-31',
          reviewCount: 13000,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-06-01',
          reviewCount: 13000,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-06-02',
          reviewCount: 13000,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-06-03',
          reviewCount: 13000,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-06-04',
          reviewCount: 13107,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-06-05',
          reviewCount: 13107,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-06-06',
          reviewCount: 13107,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-06-07',
          reviewCount: 13107,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-06-08',
          reviewCount: 13107,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-06-09',
          reviewCount: 13107,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-06-10',
          reviewCount: 13107,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-06-11',
          reviewCount: 13107,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-06-12',
          reviewCount: 13107,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-06-13',
          reviewCount: 13107,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-06-14',
          reviewCount: 13171,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-06-15',
          reviewCount: 13171,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-06-16',
          reviewCount: 13171,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-06-17',
          reviewCount: 13171,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-06-18',
          reviewCount: 13171,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-06-19',
          reviewCount: 13171,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-06-20',
          reviewCount: 13171,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-06-21',
          reviewCount: 13171,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-06-22',
          reviewCount: 13171,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-06-23',
          reviewCount: 13241,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-06-24',
          reviewCount: 13241,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-06-25',
          reviewCount: 13241,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-06-26',
          reviewCount: 13241,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-06-27',
          reviewCount: 13241,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-06-28',
          reviewCount: 13241,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-06-29',
          reviewCount: 13241,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-06-30',
          reviewCount: 13241,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-07-01',
          reviewCount: 13241,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-07-02',
          reviewCount: 13241,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-07-03',
          reviewCount: 13304,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-07-04',
          reviewCount: 13304,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-07-05',
          reviewCount: 13304,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-07-06',
          reviewCount: 13304,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-07-07',
          reviewCount: 13304,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-07-08',
          reviewCount: 13304,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-07-09',
          reviewCount: 13304,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-07-10',
          reviewCount: 13304,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-07-11',
          reviewCount: 13304,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-07-12',
          reviewCount: 13304,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-07-13',
          reviewCount: 13304,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-07-14',
          reviewCount: 13359,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-07-15',
          reviewCount: 13359,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-07-16',
          reviewCount: 13359,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-07-17',
          reviewCount: 13359,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-07-18',
          reviewCount: 13359,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-07-19',
          reviewCount: 13359,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-07-20',
          reviewCount: 13359,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-07-21',
          reviewCount: 13359,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-07-22',
          reviewCount: 13359,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-07-23',
          reviewCount: 13359,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-07-24',
          reviewCount: 13419,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-07-25',
          reviewCount: 13419,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-07-26',
          reviewCount: 13419,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-07-27',
          reviewCount: 13419,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-07-28',
          reviewCount: 13419,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-07-29',
          reviewCount: 13419,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-07-30',
          reviewCount: 13419,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-07-31',
          reviewCount: 13419,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-08-01',
          reviewCount: 13419,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-08-02',
          reviewCount: 13419,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-08-03',
          reviewCount: 13419,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-08-04',
          reviewCount: 13419,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-08-05',
          reviewCount: 13488,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-08-06',
          reviewCount: 13488,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-08-07',
          reviewCount: 13488,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-08-08',
          reviewCount: 13488,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-08-09',
          reviewCount: 13488,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-08-10',
          reviewCount: 13488,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-08-11',
          reviewCount: 13488,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-08-12',
          reviewCount: 13488,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-08-13',
          reviewCount: 13488,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-08-14',
          reviewCount: 13488,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-08-15',
          reviewCount: 13488,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-08-16',
          reviewCount: 13556,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-08-17',
          reviewCount: 13556,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-08-18',
          reviewCount: 13556,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-08-19',
          reviewCount: 13556,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-08-20',
          reviewCount: 13556,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-08-21',
          reviewCount: 13556,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-08-22',
          reviewCount: 13556,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-08-23',
          reviewCount: 13556,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-08-24',
          reviewCount: 13556,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-08-25',
          reviewCount: 13556,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-08-26',
          reviewCount: 13556,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-08-27',
          reviewCount: 13608,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-08-28',
          reviewCount: 13608,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-08-29',
          reviewCount: 13608,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-08-30',
          reviewCount: 13608,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-08-31',
          reviewCount: 13608,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-09-01',
          reviewCount: 13608,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-09-02',
          reviewCount: 13608,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-09-03',
          reviewCount: 13608,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-09-04',
          reviewCount: 13608,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-09-05',
          reviewCount: 13608,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-09-06',
          reviewCount: 13608,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-09-07',
          reviewCount: 13608,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-09-08',
          reviewCount: 13608,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-09-09',
          reviewCount: 13644,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-09-10',
          reviewCount: 13644,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-09-11',
          reviewCount: 13644,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-09-12',
          reviewCount: 13644,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-09-13',
          reviewCount: 13644,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-09-14',
          reviewCount: 13644,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-09-15',
          reviewCount: 13644,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-09-16',
          reviewCount: 13644,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-09-17',
          reviewCount: 13644,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-09-18',
          reviewCount: 13644,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-09-19',
          reviewCount: 13644,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-09-20',
          reviewCount: 13644,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-09-21',
          reviewCount: 13742,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-09-22',
          reviewCount: 13742,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-09-23',
          reviewCount: 13742,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-09-24',
          reviewCount: 13742,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-09-25',
          reviewCount: 13742,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-09-26',
          reviewCount: 13742,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-09-27',
          reviewCount: 13742,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-09-28',
          reviewCount: 13742,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-09-29',
          reviewCount: 13773,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-09-30',
          reviewCount: 13773,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-10-01',
          reviewCount: 13773,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-10-02',
          reviewCount: 13773,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-10-03',
          reviewCount: 13773,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-10-04',
          reviewCount: 13773,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-10-05',
          reviewCount: 13773,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-10-06',
          reviewCount: 13773,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-10-07',
          reviewCount: 13818,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-10-08',
          reviewCount: 13818,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-10-09',
          reviewCount: 13818,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-10-10',
          reviewCount: 13818,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-10-11',
          reviewCount: 13818,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-10-12',
          reviewCount: 13818,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-10-13',
          reviewCount: 13818,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-10-14',
          reviewCount: 13818,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-10-15',
          reviewCount: 13818,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-10-16',
          reviewCount: 13843,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-10-17',
          reviewCount: 13843,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-10-18',
          reviewCount: 13843,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-10-19',
          reviewCount: 13843,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-10-20',
          reviewCount: 13843,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-10-21',
          reviewCount: 13843,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-10-22',
          reviewCount: 13843,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-10-23',
          reviewCount: 13843,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-10-24',
          reviewCount: 13843,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-10-25',
          reviewCount: 13882,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-10-26',
          reviewCount: 13882,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-10-27',
          reviewCount: 13886,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-10-28',
          reviewCount: 13886,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-10-29',
          reviewCount: 13886,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-10-30',
          reviewCount: 13886,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-10-31',
          reviewCount: 13886,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-11-01',
          reviewCount: 13886,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-11-02',
          reviewCount: 13904,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-11-03',
          reviewCount: 13904,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-11-04',
          reviewCount: 13904,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-11-05',
          reviewCount: 13904,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-11-06',
          reviewCount: 13904,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-11-07',
          reviewCount: 13904,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-11-08',
          reviewCount: 13904,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-11-09',
          reviewCount: 13904,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-11-10',
          reviewCount: 13904,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-11-11',
          reviewCount: 13904,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-11-12',
          reviewCount: 13944,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-11-13',
          reviewCount: 13944,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-11-14',
          reviewCount: 13944,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-11-15',
          reviewCount: 13944,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-11-16',
          reviewCount: 13944,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-11-17',
          reviewCount: 13944,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-11-18',
          reviewCount: 13944,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-11-19',
          reviewCount: 13944,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-11-20',
          reviewCount: 13944,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-11-21',
          reviewCount: 13944,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-11-22',
          reviewCount: 13984,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-11-23',
          reviewCount: 13984,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-11-24',
          reviewCount: 13991,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-11-25',
          reviewCount: 13997,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-11-26',
          reviewCount: 13999,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-11-27',
          reviewCount: 14001,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-11-28',
          reviewCount: 14004,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-11-29',
          reviewCount: 14008,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-11-30',
          reviewCount: 14009,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-12-01',
          reviewCount: 14015,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-12-02',
          reviewCount: 14020,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-12-03',
          reviewCount: 14029,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-12-04',
          reviewCount: 14030,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-12-05',
          reviewCount: 14031,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-12-06',
          reviewCount: 14038,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-12-07',
          reviewCount: 14043,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-12-08',
          reviewCount: 14043,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-12-09',
          reviewCount: 14056,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-12-10',
          reviewCount: 14057,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-12-11',
          reviewCount: 14063,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-12-12',
          reviewCount: 14063,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-12-13',
          reviewCount: 14063,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-12-14',
          reviewCount: 14063,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-12-15',
          reviewCount: 14083,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-12-16',
          reviewCount: 14083,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-12-17',
          reviewCount: 14083,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-12-18',
          reviewCount: 14083,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-12-19',
          reviewCount: 14105,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-12-20',
          reviewCount: 14105,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-12-21',
          reviewCount: 14105,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-12-22',
          reviewCount: 14105,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-12-23',
          reviewCount: 14114,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-12-24',
          reviewCount: 14114,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-12-25',
          reviewCount: 14114,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-12-26',
          reviewCount: 14114,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-12-27',
          reviewCount: 14133,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-12-28',
          reviewCount: 14133,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-12-29',
          reviewCount: 14133,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-12-30',
          reviewCount: 14133,
          rating: 4,
          salesRank: null
        },
        {
          date: '2022-12-31',
          reviewCount: 14168,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-01-01',
          reviewCount: 14168,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-01-02',
          reviewCount: 14168,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-01-03',
          reviewCount: 14168,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-01-04',
          reviewCount: 14196,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-01-05',
          reviewCount: 14196,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-01-06',
          reviewCount: 14196,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-01-07',
          reviewCount: 14196,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-01-08',
          reviewCount: 14228,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-01-09',
          reviewCount: 14228,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-01-10',
          reviewCount: 14228,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-01-11',
          reviewCount: 14228,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-01-12',
          reviewCount: 14264,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-01-13',
          reviewCount: 14264,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-01-14',
          reviewCount: 14264,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-01-15',
          reviewCount: 14264,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-01-16',
          reviewCount: 14294,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-01-17',
          reviewCount: 14294,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-01-18',
          reviewCount: 14294,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-01-19',
          reviewCount: 14294,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-01-20',
          reviewCount: 14330,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-01-21',
          reviewCount: 14330,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-01-22',
          reviewCount: 14330,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-01-23',
          reviewCount: 14330,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-01-24',
          reviewCount: 14365,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-01-25',
          reviewCount: 14365,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-01-26',
          reviewCount: 14365,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-01-27',
          reviewCount: 14365,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-01-28',
          reviewCount: 14365,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-01-29',
          reviewCount: 14365,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-01-30',
          reviewCount: 14428,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-01-31',
          reviewCount: 14428,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-02-01',
          reviewCount: 14428,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-02-02',
          reviewCount: 14428,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-02-03',
          reviewCount: 14428,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-02-04',
          reviewCount: 14428,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-02-05',
          reviewCount: 14606,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-02-06',
          reviewCount: 14606,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-02-07',
          reviewCount: 14606,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-02-08',
          reviewCount: 14606,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-02-09',
          reviewCount: 14606,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-02-10',
          reviewCount: 14606,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-02-11',
          reviewCount: 14661,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-02-12',
          reviewCount: 14661,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-02-13',
          reviewCount: 14661,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-02-14',
          reviewCount: 14661,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-02-15',
          reviewCount: 14661,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-02-16',
          reviewCount: 14661,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-02-17',
          reviewCount: 14710,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-02-18',
          reviewCount: 14710,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-02-19',
          reviewCount: 14710,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-02-20',
          reviewCount: 14710,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-02-21',
          reviewCount: 14710,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-02-22',
          reviewCount: 14710,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-02-23',
          reviewCount: 14807,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-02-24',
          reviewCount: 14807,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-02-25',
          reviewCount: 14807,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-02-26',
          reviewCount: 14807,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-02-27',
          reviewCount: 14847,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-02-28',
          reviewCount: 14847,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-03-01',
          reviewCount: 14847,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-03-02',
          reviewCount: 14887,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-03-03',
          reviewCount: 14887,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-03-04',
          reviewCount: 14887,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-03-05',
          reviewCount: 14887,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-03-06',
          reviewCount: 14942,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-03-07',
          reviewCount: 14942,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-03-08',
          reviewCount: 14942,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-03-09',
          reviewCount: 14942,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-03-10',
          reviewCount: 14978,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-03-11',
          reviewCount: 14978,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-03-12',
          reviewCount: 14978,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-03-13',
          reviewCount: 14999,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-03-14',
          reviewCount: 14999,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-03-15',
          reviewCount: 14999,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-03-16',
          reviewCount: 14999,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-03-17',
          reviewCount: 15043,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-03-18',
          reviewCount: 15043,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-03-19',
          reviewCount: 15043,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-03-20',
          reviewCount: 15043,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-03-21',
          reviewCount: 15089,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-03-22',
          reviewCount: 15089,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-03-23',
          reviewCount: 15089,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-03-24',
          reviewCount: 15089,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-03-25',
          reviewCount: 15118,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-03-26',
          reviewCount: 15118,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-03-27',
          reviewCount: 15118,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-03-28',
          reviewCount: 15145,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-03-29',
          reviewCount: 15145,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-03-30',
          reviewCount: 15145,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-03-31',
          reviewCount: 15145,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-04-01',
          reviewCount: 15180,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-04-02',
          reviewCount: 15180,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-04-03',
          reviewCount: 15180,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-04-04',
          reviewCount: 15180,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-04-05',
          reviewCount: 15214,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-04-06',
          reviewCount: 15214,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-04-07',
          reviewCount: 15214,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-04-08',
          reviewCount: 15214,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-04-09',
          reviewCount: 15247,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-04-10',
          reviewCount: 15247,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-04-11',
          reviewCount: 15247,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-04-12',
          reviewCount: 15247,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-04-13',
          reviewCount: 15280,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-04-14',
          reviewCount: 15280,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-04-15',
          reviewCount: 15280,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-04-16',
          reviewCount: 15280,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-04-17',
          reviewCount: 15310,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-04-18',
          reviewCount: 15310,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-04-19',
          reviewCount: 15310,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-04-20',
          reviewCount: 15310,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-04-21',
          reviewCount: 15346,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-04-22',
          reviewCount: 15346,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-04-23',
          reviewCount: 15346,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-04-24',
          reviewCount: 15346,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-04-25',
          reviewCount: 15375,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-04-26',
          reviewCount: 15375,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-04-27',
          reviewCount: 15375,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-04-28',
          reviewCount: 15375,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-04-29',
          reviewCount: 15404,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-04-30',
          reviewCount: 15404,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-05-01',
          reviewCount: 15404,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-05-02',
          reviewCount: 15404,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-05-03',
          reviewCount: 15434,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-05-04',
          reviewCount: 15434,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-05-05',
          reviewCount: 15434,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-05-06',
          reviewCount: 15434,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-05-07',
          reviewCount: 15459,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-05-08',
          reviewCount: 15459,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-05-09',
          reviewCount: 15459,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-05-10',
          reviewCount: 15459,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-05-11',
          reviewCount: 15468,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-05-12',
          reviewCount: 15468,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-05-13',
          reviewCount: 15468,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-05-14',
          reviewCount: 15468,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-05-15',
          reviewCount: 15468,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-05-16',
          reviewCount: 15468,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-05-17',
          reviewCount: 15497,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-05-18',
          reviewCount: 15497,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-05-19',
          reviewCount: 15497,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-05-20',
          reviewCount: 15497,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-05-21',
          reviewCount: 15497,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-05-22',
          reviewCount: 15497,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-05-23',
          reviewCount: 15523,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-05-24',
          reviewCount: 15523,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-05-25',
          reviewCount: 15523,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-05-26',
          reviewCount: 15523,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-05-27',
          reviewCount: 15523,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-05-28',
          reviewCount: 15523,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-05-29',
          reviewCount: 15537,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-05-30',
          reviewCount: 15537,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-05-31',
          reviewCount: 15537,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-06-01',
          reviewCount: 15537,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-06-02',
          reviewCount: 15537,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-06-03',
          reviewCount: 15537,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-06-04',
          reviewCount: 15562,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-06-05',
          reviewCount: 15562,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-06-06',
          reviewCount: 15562,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-06-07',
          reviewCount: 15562,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-06-08',
          reviewCount: 15562,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-06-09',
          reviewCount: 15562,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-06-10',
          reviewCount: 15577,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-06-11',
          reviewCount: 15577,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-06-12',
          reviewCount: 15577,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-06-13',
          reviewCount: 15577,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-06-14',
          reviewCount: 15577,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-06-15',
          reviewCount: 15577,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-06-16',
          reviewCount: 15600,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-06-17',
          reviewCount: 15600,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-06-18',
          reviewCount: 15600,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-06-19',
          reviewCount: 15600,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-06-20',
          reviewCount: 15600,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-06-21',
          reviewCount: 15600,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-06-22',
          reviewCount: 15616,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-06-23',
          reviewCount: 15616,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-06-24',
          reviewCount: 15616,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-06-25',
          reviewCount: 15616,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-06-26',
          reviewCount: 15616,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-06-27',
          reviewCount: 15616,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-06-28',
          reviewCount: 15642,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-06-29',
          reviewCount: 15642,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-06-30',
          reviewCount: 15642,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-01',
          reviewCount: 15642,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-02',
          reviewCount: 15642,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-03',
          reviewCount: 15642,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-04',
          reviewCount: 15665,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-05',
          reviewCount: 15665,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-06',
          reviewCount: 15665,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-07',
          reviewCount: 15665,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-08',
          reviewCount: 15665,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-09',
          reviewCount: 15665,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-10',
          reviewCount: 15683,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-11',
          reviewCount: 15683,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-12',
          reviewCount: 15683,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-13',
          reviewCount: 15683,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-14',
          reviewCount: 15683,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-15',
          reviewCount: 15683,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-16',
          reviewCount: 15701,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-17',
          reviewCount: 15701,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-18',
          reviewCount: 15701,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-19',
          reviewCount: 15701,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-20',
          reviewCount: 15701,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-21',
          reviewCount: 15701,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-22',
          reviewCount: 15718,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-23',
          reviewCount: 15718,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-24',
          reviewCount: 15718,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-25',
          reviewCount: 15718,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-26',
          reviewCount: 15718,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-27',
          reviewCount: 15718,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-28',
          reviewCount: 15740,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-29',
          reviewCount: 15740,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-30',
          reviewCount: 15740,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-07-31',
          reviewCount: 15740,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-01',
          reviewCount: 15740,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-02',
          reviewCount: 15740,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-03',
          reviewCount: 15760,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-04',
          reviewCount: 15760,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-05',
          reviewCount: 15760,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-06',
          reviewCount: 15760,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-07',
          reviewCount: 15760,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-08',
          reviewCount: 15760,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-09',
          reviewCount: 15768,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-10',
          reviewCount: 15768,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-11',
          reviewCount: 15768,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-12',
          reviewCount: 15768,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-13',
          reviewCount: 15768,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-14',
          reviewCount: 15768,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-15',
          reviewCount: 15784,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-16',
          reviewCount: 15784,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-17',
          reviewCount: 15784,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-18',
          reviewCount: 15784,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-19',
          reviewCount: 15784,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-20',
          reviewCount: 15784,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-21',
          reviewCount: 15798,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-22',
          reviewCount: 15798,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-23',
          reviewCount: 15798,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-24',
          reviewCount: 15798,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-25',
          reviewCount: 15798,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-26',
          reviewCount: 15798,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-27',
          reviewCount: 15815,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-28',
          reviewCount: 15815,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-29',
          reviewCount: 15815,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-30',
          reviewCount: 15815,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-08-31',
          reviewCount: 15815,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-01',
          reviewCount: 15815,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-02',
          reviewCount: 15835,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-03',
          reviewCount: 15835,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-04',
          reviewCount: 15835,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-05',
          reviewCount: 15835,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-06',
          reviewCount: 15835,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-07',
          reviewCount: 15835,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-08',
          reviewCount: 15849,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-09',
          reviewCount: 15849,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-10',
          reviewCount: 15849,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-11',
          reviewCount: 15849,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-12',
          reviewCount: 15849,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-13',
          reviewCount: 15849,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-14',
          reviewCount: 15860,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-15',
          reviewCount: 15860,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-16',
          reviewCount: 15860,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-17',
          reviewCount: 15860,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-18',
          reviewCount: 15860,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-19',
          reviewCount: 15860,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-20',
          reviewCount: 15879,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-21',
          reviewCount: 15879,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-22',
          reviewCount: 15879,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-23',
          reviewCount: 15879,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-24',
          reviewCount: 15879,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-25',
          reviewCount: 15879,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-26',
          reviewCount: 15898,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-27',
          reviewCount: 15898,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-28',
          reviewCount: 15898,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-29',
          reviewCount: 15898,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-30',
          reviewCount: 15898,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-01',
          reviewCount: 15898,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-02',
          reviewCount: 15915,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-03',
          reviewCount: 15915,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-04',
          reviewCount: 15915,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-05',
          reviewCount: 15915,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-06',
          reviewCount: 15915,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-07',
          reviewCount: 15915,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-08',
          reviewCount: 15919,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-09',
          reviewCount: 15919,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-10',
          reviewCount: 15919,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-11',
          reviewCount: 15919,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-12',
          reviewCount: 15919,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-13',
          reviewCount: 15919,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-14',
          reviewCount: 15932,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-15',
          reviewCount: 15932,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-16',
          reviewCount: 15932,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-17',
          reviewCount: 15932,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-18',
          reviewCount: 15932,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-19',
          reviewCount: 15932,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-20',
          reviewCount: 15952,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-21',
          reviewCount: 15952,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-22',
          reviewCount: 15952,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-23',
          reviewCount: 15952,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-24',
          reviewCount: 15952,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-25',
          reviewCount: 15952,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-26',
          reviewCount: 15961,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-27',
          reviewCount: 15961,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-28',
          reviewCount: 15961,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-29',
          reviewCount: 15961,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-30',
          reviewCount: 15975,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-10-31',
          reviewCount: 15975,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-01',
          reviewCount: 15975,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-02',
          reviewCount: 15975,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-03',
          reviewCount: 15980,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-04',
          reviewCount: 15980,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-05',
          reviewCount: 15980,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-06',
          reviewCount: 15980,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-07',
          reviewCount: 15995,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-08',
          reviewCount: 15995,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-09',
          reviewCount: 15995,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-10',
          reviewCount: 15995,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-11',
          reviewCount: 16005,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-12',
          reviewCount: 16005,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-13',
          reviewCount: 16005,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-14',
          reviewCount: 16019,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-15',
          reviewCount: 16019,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-16',
          reviewCount: 16019,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-17',
          reviewCount: 16036,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-18',
          reviewCount: 16036,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-19',
          reviewCount: 16036,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-20',
          reviewCount: 16036,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-21',
          reviewCount: 16045,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-22',
          reviewCount: 16045,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-23',
          reviewCount: 16045,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-24',
          reviewCount: 16045,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-25',
          reviewCount: 16057,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-26',
          reviewCount: 16057,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-27',
          reviewCount: 16057,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-28',
          reviewCount: 16057,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-29',
          reviewCount: 16075,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-11-30',
          reviewCount: 16075,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-01',
          reviewCount: 16075,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-02',
          reviewCount: 16075,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-03',
          reviewCount: 16096,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-04',
          reviewCount: 16096,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-05',
          reviewCount: 16096,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-06',
          reviewCount: 16096,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-07',
          reviewCount: 16112,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-08',
          reviewCount: 16112,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-09',
          reviewCount: 16112,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-10',
          reviewCount: 16112,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-11',
          reviewCount: 16131,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-12',
          reviewCount: 16131,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-13',
          reviewCount: 16131,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-14',
          reviewCount: 16131,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-15',
          reviewCount: 16154,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-16',
          reviewCount: 16154,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-17',
          reviewCount: 16154,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-18',
          reviewCount: 16154,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-19',
          reviewCount: 16166,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-20',
          reviewCount: 16166,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-21',
          reviewCount: 16166,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-22',
          reviewCount: 16166,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-23',
          reviewCount: 16178,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-24',
          reviewCount: 16178,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-25',
          reviewCount: 16178,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-26',
          reviewCount: 16178,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-27',
          reviewCount: 16190,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-28',
          reviewCount: 16190,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-29',
          reviewCount: 16190,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-30',
          reviewCount: 16206,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-31',
          reviewCount: 16206,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-01',
          reviewCount: 16206,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-02',
          reviewCount: 16224,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-03',
          reviewCount: 16224,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-04',
          reviewCount: 16224,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-05',
          reviewCount: 16224,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-06',
          reviewCount: 16250,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-07',
          reviewCount: 16250,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-08',
          reviewCount: 16250,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-09',
          reviewCount: 16274,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-10',
          reviewCount: 16274,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-11',
          reviewCount: 16274,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-12',
          reviewCount: 16274,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-13',
          reviewCount: 16300,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-14',
          reviewCount: 16300,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-15',
          reviewCount: 16300,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-16',
          reviewCount: 16323,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-17',
          reviewCount: 16323,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-18',
          reviewCount: 16323,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-19',
          reviewCount: 16323,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-20',
          reviewCount: 16355,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-21',
          reviewCount: 16355,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-22',
          reviewCount: 16355,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-23',
          reviewCount: 16369,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-24',
          reviewCount: 16369,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-25',
          reviewCount: 16369,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-26',
          reviewCount: 16391,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-27',
          reviewCount: 16391,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-28',
          reviewCount: 16391,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-29',
          reviewCount: 16408,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-30',
          reviewCount: 16408,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-01-31',
          reviewCount: 16408,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-01',
          reviewCount: 16408,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-02',
          reviewCount: 16437,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-03',
          reviewCount: 16437,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-04',
          reviewCount: 16437,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-05',
          reviewCount: 16437,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-06',
          reviewCount: 16475,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-07',
          reviewCount: 16475,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-08',
          reviewCount: 16475,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-09',
          reviewCount: 16475,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-10',
          reviewCount: 16520,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-11',
          reviewCount: 16520,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-12',
          reviewCount: 16520,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-13',
          reviewCount: 16549,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-14',
          reviewCount: 16549,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-15',
          reviewCount: 16549,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-16',
          reviewCount: 16549,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-17',
          reviewCount: 16577,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-18',
          reviewCount: 16577,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-19',
          reviewCount: 16577,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-20',
          reviewCount: 16577,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-21',
          reviewCount: 16610,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-22',
          reviewCount: 16610,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-23',
          reviewCount: 16610,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-24',
          reviewCount: 16610,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-25',
          reviewCount: 16655,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-26',
          reviewCount: 16655,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-27',
          reviewCount: 16655,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-28',
          reviewCount: 16680,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-02-29',
          reviewCount: 16680,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-01',
          reviewCount: 16680,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-02',
          reviewCount: 16680,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-03',
          reviewCount: 16731,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-04',
          reviewCount: 16731,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-05',
          reviewCount: 16731,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-06',
          reviewCount: 16731,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-07',
          reviewCount: 16783,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-08',
          reviewCount: 16783,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-09',
          reviewCount: 16783,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-10',
          reviewCount: 16783,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-11',
          reviewCount: 16819,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-12',
          reviewCount: 16819,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-13',
          reviewCount: 16819,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-14',
          reviewCount: 16819,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-15',
          reviewCount: 16819,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-16',
          reviewCount: 16851,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-17',
          reviewCount: 16851,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-18',
          reviewCount: 16851,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-19',
          reviewCount: 16851,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-20',
          reviewCount: 16851,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-21',
          reviewCount: 16865,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-22',
          reviewCount: 16865,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-23',
          reviewCount: 16865,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-24',
          reviewCount: 16865,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-25',
          reviewCount: 16865,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-26',
          reviewCount: 16136,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-27',
          reviewCount: 16136,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-28',
          reviewCount: 16136,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-29',
          reviewCount: 16136,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-30',
          reviewCount: 16136,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-31',
          reviewCount: 16136,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-01',
          reviewCount: 16136,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-02',
          reviewCount: 16136,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-03',
          reviewCount: 16136,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-04',
          reviewCount: 16136,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-05',
          reviewCount: 16136,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-06',
          reviewCount: 16169,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-07',
          reviewCount: 16169,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-08',
          reviewCount: 16169,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-09',
          reviewCount: 16169,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-10',
          reviewCount: 16169,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-11',
          reviewCount: 16169,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-12',
          reviewCount: 16169,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-13',
          reviewCount: 16169,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-14',
          reviewCount: 16169,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-15',
          reviewCount: 16169,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-16',
          reviewCount: 16169,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-17',
          reviewCount: 16169,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-18',
          reviewCount: 16169,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-19',
          reviewCount: 16169,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-20',
          reviewCount: 16169,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-21',
          reviewCount: 16169,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-22',
          reviewCount: 16169,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-23',
          reviewCount: 16169,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-24',
          reviewCount: 16169,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-25',
          reviewCount: 16204,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-26',
          reviewCount: 16204,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-27',
          reviewCount: 16204,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-28',
          reviewCount: 16204,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-29',
          reviewCount: 16204,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-30',
          reviewCount: 16204,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-01',
          reviewCount: 16204,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-02',
          reviewCount: 16204,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-03',
          reviewCount: 16204,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-04',
          reviewCount: 2132,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-05',
          reviewCount: 2132,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-06',
          reviewCount: 2132,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-07',
          reviewCount: 2132,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-08',
          reviewCount: 2132,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-09',
          reviewCount: 2132,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-10',
          reviewCount: 2132,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-11',
          reviewCount: 2132,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-12',
          reviewCount: 2132,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-13',
          reviewCount: 2140,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-14',
          reviewCount: 2140,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-15',
          reviewCount: 2140,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-16',
          reviewCount: 2140,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-17',
          reviewCount: 2140,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-18',
          reviewCount: 2140,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-19',
          reviewCount: 2141,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-20',
          reviewCount: 2141,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-21',
          reviewCount: 2141,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-22',
          reviewCount: 2141,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-23',
          reviewCount: 2141,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-24',
          reviewCount: 2141,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-25',
          reviewCount: 2156,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-26',
          reviewCount: 2156,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-27',
          reviewCount: 2156,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-28',
          reviewCount: 2156,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-29',
          reviewCount: 2156,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-30',
          reviewCount: 2156,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-05-31',
          reviewCount: 2156,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-01',
          reviewCount: 2156,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-02',
          reviewCount: 2156,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-03',
          reviewCount: 2156,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-04',
          reviewCount: 2156,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-05',
          reviewCount: 2156,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-06',
          reviewCount: 2156,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-07',
          reviewCount: 2156,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-08',
          reviewCount: 2156,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-09',
          reviewCount: 2156,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-10',
          reviewCount: 2156,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-11',
          reviewCount: 2156,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-12',
          reviewCount: 2160,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-13',
          reviewCount: 2160,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-14',
          reviewCount: 2160,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-15',
          reviewCount: 2160,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-16',
          reviewCount: 2160,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-17',
          reviewCount: 2160,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-18',
          reviewCount: 2161,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-19',
          reviewCount: 2161,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-20',
          reviewCount: 2161,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-21',
          reviewCount: 2161,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-22',
          reviewCount: 2161,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-23',
          reviewCount: 2161,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-24',
          reviewCount: 2165,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-25',
          reviewCount: 2165,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-26',
          reviewCount: 2165,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-27',
          reviewCount: 2165,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-28',
          reviewCount: 2165,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-29',
          reviewCount: 2165,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-06-30',
          reviewCount: 2165,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-01',
          reviewCount: 2165,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-02',
          reviewCount: 2165,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-03',
          reviewCount: 2165,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-04',
          reviewCount: 2165,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-05',
          reviewCount: 2165,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-06',
          reviewCount: 2170,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-07',
          reviewCount: 2170,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-08',
          reviewCount: 2170,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-09',
          reviewCount: 2170,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-10',
          reviewCount: 2170,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-11',
          reviewCount: 2170,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-12',
          reviewCount: 2171,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-13',
          reviewCount: 2171,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-14',
          reviewCount: 2171,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-15',
          reviewCount: 2171,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-16',
          reviewCount: 2171,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-17',
          reviewCount: 2171,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-18',
          reviewCount: 2173,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-19',
          reviewCount: 2173,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-20',
          reviewCount: 2173,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-21',
          reviewCount: 2173,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-22',
          reviewCount: 2173,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-23',
          reviewCount: 2173,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-24',
          reviewCount: 2174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-25',
          reviewCount: 2174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-26',
          reviewCount: 2174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-27',
          reviewCount: 2174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-28',
          reviewCount: 2174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-29',
          reviewCount: 2174,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-30',
          reviewCount: 2175,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-07-31',
          reviewCount: 2175,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-01',
          reviewCount: 2175,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-02',
          reviewCount: 2175,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-03',
          reviewCount: 2175,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-04',
          reviewCount: 2175,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-05',
          reviewCount: 2175,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-06',
          reviewCount: 2175,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-07',
          reviewCount: 2175,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-08',
          reviewCount: 2175,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-09',
          reviewCount: 2175,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-10',
          reviewCount: 2175,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-11',
          reviewCount: 2180,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-12',
          reviewCount: 2180,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-13',
          reviewCount: 2180,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-14',
          reviewCount: 2180,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-15',
          reviewCount: 2180,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-16',
          reviewCount: 2180,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-17',
          reviewCount: 2180,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-18',
          reviewCount: 2183,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-19',
          reviewCount: 2183,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-20',
          reviewCount: 2183,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-21',
          reviewCount: 2183,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-22',
          reviewCount: 2183,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-23',
          reviewCount: 2183,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-24',
          reviewCount: 2187,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-25',
          reviewCount: 2187,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-26',
          reviewCount: 2187,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-27',
          reviewCount: 2187,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-28',
          reviewCount: 2187,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-29',
          reviewCount: 2188,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-30',
          reviewCount: 2188,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-08-31',
          reviewCount: 2188,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-01',
          reviewCount: 2188,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-02',
          reviewCount: 2188,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-03',
          reviewCount: 2188,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-04',
          reviewCount: 2190,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-05',
          reviewCount: 2190,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-06',
          reviewCount: 2190,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-07',
          reviewCount: 2190,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-08',
          reviewCount: 2190,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-09',
          reviewCount: 2190,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-10',
          reviewCount: 2192,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-11',
          reviewCount: 2192,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-12',
          reviewCount: 2192,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-13',
          reviewCount: 2192,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-14',
          reviewCount: 2192,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-15',
          reviewCount: 2192,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-16',
          reviewCount: 2197,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-17',
          reviewCount: 2197,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-18',
          reviewCount: 2197,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-19',
          reviewCount: 2197,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-20',
          reviewCount: 2197,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-21',
          reviewCount: 2199,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-22',
          reviewCount: 2199,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-23',
          reviewCount: 2199,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-24',
          reviewCount: 2199,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-25',
          reviewCount: 2199,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-26',
          reviewCount: 2200,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-27',
          reviewCount: 2200,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-28',
          reviewCount: 2200,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-29',
          reviewCount: 2200,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-09-30',
          reviewCount: 2200,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-01',
          reviewCount: 2200,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-02',
          reviewCount: 2203,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-03',
          reviewCount: 2203,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-04',
          reviewCount: 2203,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-05',
          reviewCount: 2203,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-06',
          reviewCount: 2203,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-07',
          reviewCount: 2203,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-08',
          reviewCount: 2206,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-09',
          reviewCount: 2206,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-10',
          reviewCount: 2206,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-11',
          reviewCount: 2206,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-12',
          reviewCount: 2206,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-13',
          reviewCount: 2211,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-14',
          reviewCount: 2211,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-15',
          reviewCount: 2211,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-16',
          reviewCount: 2211,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-17',
          reviewCount: 2211,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-18',
          reviewCount: 2211,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-19',
          reviewCount: 2219,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-20',
          reviewCount: 2219,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-21',
          reviewCount: 2219,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-22',
          reviewCount: 2219,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-23',
          reviewCount: 2219,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-24',
          reviewCount: 2219,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-25',
          reviewCount: 2220,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-26',
          reviewCount: 2220,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-27',
          reviewCount: 2220,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-28',
          reviewCount: 2220,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-29',
          reviewCount: 2220,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-30',
          reviewCount: 2220,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-10-31',
          reviewCount: 2223,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-01',
          reviewCount: 2223,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-02',
          reviewCount: 2223,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-03',
          reviewCount: 2223,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-04',
          reviewCount: 2223,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-05',
          reviewCount: 2223,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-06',
          reviewCount: 2230,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-07',
          reviewCount: 2230,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-08',
          reviewCount: 2230,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-09',
          reviewCount: 2230,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-10',
          reviewCount: 2230,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-11',
          reviewCount: 2230,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-12',
          reviewCount: 2234,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-13',
          reviewCount: 2234,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-14',
          reviewCount: 2234,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-15',
          reviewCount: 2234,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-16',
          reviewCount: 2234,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-17',
          reviewCount: 2234,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-18',
          reviewCount: 2238,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-19',
          reviewCount: 2238,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-20',
          reviewCount: 2238,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-21',
          reviewCount: 2238,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-22',
          reviewCount: 2238,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-23',
          reviewCount: 2238,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-24',
          reviewCount: 2247,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-25',
          reviewCount: 2247,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-26',
          reviewCount: 2247,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-27',
          reviewCount: 2247,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-28',
          reviewCount: 2247,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-29',
          reviewCount: 2247,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-11-30',
          reviewCount: 2256,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-01',
          reviewCount: 2256,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-02',
          reviewCount: 2256,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-03',
          reviewCount: 2256,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-04',
          reviewCount: 2256,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-05',
          reviewCount: 2256,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-06',
          reviewCount: 2267,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-07',
          reviewCount: 2267,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-08',
          reviewCount: 2267,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-09',
          reviewCount: 2267,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-10',
          reviewCount: 2267,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-11',
          reviewCount: 2267,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-12',
          reviewCount: 2280,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-13',
          reviewCount: 2280,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-14',
          reviewCount: 2280,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-15',
          reviewCount: 2280,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-16',
          reviewCount: 2280,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-17',
          reviewCount: 2287,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-18',
          reviewCount: 2287,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-19',
          reviewCount: 2287,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-20',
          reviewCount: 2287,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-21',
          reviewCount: 2287,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-22',
          reviewCount: 2287,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-23',
          reviewCount: 2292,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-24',
          reviewCount: 2292,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-25',
          reviewCount: 2292,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-26',
          reviewCount: 2292,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-27',
          reviewCount: 2299,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-28',
          reviewCount: 2299,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-29',
          reviewCount: 2299,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-30',
          reviewCount: 2299,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-12-31',
          reviewCount: 2303,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-01',
          reviewCount: 2303,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-02',
          reviewCount: 2303,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-03',
          reviewCount: 2304,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-04',
          reviewCount: 2304,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-05',
          reviewCount: 2304,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-06',
          reviewCount: 2304,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-07',
          reviewCount: 2304,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-08',
          reviewCount: 2304,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-09',
          reviewCount: 2312,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-10',
          reviewCount: 2312,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-11',
          reviewCount: 2312,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-12',
          reviewCount: 2312,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-13',
          reviewCount: 2312,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-14',
          reviewCount: 2316,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-15',
          reviewCount: 2316,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-16',
          reviewCount: 2316,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-17',
          reviewCount: 2316,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-18',
          reviewCount: 2316,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-19',
          reviewCount: 2326,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-20',
          reviewCount: 2326,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-21',
          reviewCount: 2326,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-22',
          reviewCount: 2326,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-23',
          reviewCount: 2326,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-24',
          reviewCount: 2326,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-25',
          reviewCount: 2359,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-26',
          reviewCount: 2359,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-27',
          reviewCount: 2359,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-28',
          reviewCount: 2359,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-29',
          reviewCount: 2359,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-30',
          reviewCount: 2369,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-01-31',
          reviewCount: 2369,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-01',
          reviewCount: 2369,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-02',
          reviewCount: 2369,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-03',
          reviewCount: 2369,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-04',
          reviewCount: 2373,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-05',
          reviewCount: 2373,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-06',
          reviewCount: 2373,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-07',
          reviewCount: 2373,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-08',
          reviewCount: 2373,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-09',
          reviewCount: 2373,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-10',
          reviewCount: 2379,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-11',
          reviewCount: 2379,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-12',
          reviewCount: 2379,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-13',
          reviewCount: 2379,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-14',
          reviewCount: 2379,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-15',
          reviewCount: 2379,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-16',
          reviewCount: 2385,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-17',
          reviewCount: 2385,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-18',
          reviewCount: 2385,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-19',
          reviewCount: 2385,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-20',
          reviewCount: 2385,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-21',
          reviewCount: 2385,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-22',
          reviewCount: 2393,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-23',
          reviewCount: 2393,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-24',
          reviewCount: 2393,
          rating: 4,
          salesRank: null
        },
        {
          date: '2025-02-25',
          reviewCount: 2393,
          rating: 4,
          salesRank: null
        }
      ],
      reviewDataUploaded: true,
      bestSellerRankDataUploaded: false,
      isVisible: true,
      isCategoryAverage: false,
      createdAt: '2025-02-24T18:06:15.066Z'
    },
    {
      id: '1ff3c70c-108c-4a6e-a5ce-f73256e9109e',
      productName: 'True Wireless Bluetooth Earbuds',
      productUrl: 'https://www.amazon.com',
      productImage: 'https://media.productwind.com/sample/images/sample-sku-wireless-earbuds-3.png',
      comparisonMetric: 'BEST_SELLER_RANK',
      productPerformanceRecords: [
        {
          date: '2017-07-31',
          reviewCount: null,
          rating: null,
          salesRank: 585786
        },
        {
          date: '2017-08-01',
          reviewCount: null,
          rating: null,
          salesRank: 806960
        },
        {
          date: '2017-08-05',
          reviewCount: null,
          rating: null,
          salesRank: 357669
        },
        {
          date: '2017-08-07',
          reviewCount: null,
          rating: null,
          salesRank: 131054
        },
        {
          date: '2017-08-08',
          reviewCount: null,
          rating: null,
          salesRank: 198599
        },
        {
          date: '2017-08-11',
          reviewCount: null,
          rating: null,
          salesRank: 86411
        },
        {
          date: '2017-08-12',
          reviewCount: null,
          rating: null,
          salesRank: 166620
        },
        {
          date: '2017-08-15',
          reviewCount: null,
          rating: null,
          salesRank: 499302
        },
        {
          date: '2017-08-18',
          reviewCount: null,
          rating: null,
          salesRank: 162258
        },
        {
          date: '2017-08-19',
          reviewCount: null,
          rating: null,
          salesRank: 215034
        },
        {
          date: '2017-08-22',
          reviewCount: null,
          rating: null,
          salesRank: 161019
        },
        {
          date: '2017-08-23',
          reviewCount: null,
          rating: null,
          salesRank: 68726
        },
        {
          date: '2017-08-24',
          reviewCount: null,
          rating: null,
          salesRank: 134963
        },
        {
          date: '2017-08-25',
          reviewCount: null,
          rating: null,
          salesRank: 112096
        },
        {
          date: '2017-08-26',
          reviewCount: null,
          rating: null,
          salesRank: 106647
        },
        {
          date: '2017-08-27',
          reviewCount: null,
          rating: null,
          salesRank: 138514
        },
        {
          date: '2017-08-28',
          reviewCount: null,
          rating: null,
          salesRank: 87352
        },
        {
          date: '2017-08-29',
          reviewCount: null,
          rating: null,
          salesRank: 158734
        },
        {
          date: '2017-08-30',
          reviewCount: null,
          rating: null,
          salesRank: 176231
        },
        {
          date: '2017-08-31',
          reviewCount: null,
          rating: null,
          salesRank: 46557
        },
        {
          date: '2017-09-01',
          reviewCount: null,
          rating: null,
          salesRank: 41174
        },
        {
          date: '2017-09-02',
          reviewCount: null,
          rating: null,
          salesRank: 28589
        },
        {
          date: '2017-09-04',
          reviewCount: null,
          rating: null,
          salesRank: 40251
        },
        {
          date: '2017-09-05',
          reviewCount: null,
          rating: null,
          salesRank: 75415
        },
        {
          date: '2017-09-06',
          reviewCount: null,
          rating: null,
          salesRank: 53337
        },
        {
          date: '2017-09-07',
          reviewCount: null,
          rating: null,
          salesRank: 78075
        },
        {
          date: '2017-09-08',
          reviewCount: null,
          rating: null,
          salesRank: 104026
        },
        {
          date: '2017-09-09',
          reviewCount: null,
          rating: null,
          salesRank: 70582
        },
        {
          date: '2017-09-10',
          reviewCount: null,
          rating: null,
          salesRank: 38535
        },
        {
          date: '2017-09-11',
          reviewCount: null,
          rating: null,
          salesRank: 29646
        },
        {
          date: '2017-09-12',
          reviewCount: null,
          rating: null,
          salesRank: 41387
        },
        {
          date: '2017-09-13',
          reviewCount: null,
          rating: null,
          salesRank: 51255
        },
        {
          date: '2017-09-15',
          reviewCount: null,
          rating: null,
          salesRank: 58266
        },
        {
          date: '2017-09-16',
          reviewCount: null,
          rating: null,
          salesRank: 35552
        },
        {
          date: '2017-09-17',
          reviewCount: null,
          rating: null,
          salesRank: 56596
        },
        {
          date: '2017-09-18',
          reviewCount: null,
          rating: null,
          salesRank: 74024
        },
        {
          date: '2017-09-19',
          reviewCount: null,
          rating: null,
          salesRank: 33453
        },
        {
          date: '2017-09-20',
          reviewCount: null,
          rating: null,
          salesRank: 34568
        },
        {
          date: '2017-09-21',
          reviewCount: null,
          rating: null,
          salesRank: 51970
        },
        {
          date: '2017-09-22',
          reviewCount: null,
          rating: null,
          salesRank: 28172
        },
        {
          date: '2017-09-23',
          reviewCount: null,
          rating: null,
          salesRank: 26745
        },
        {
          date: '2017-09-24',
          reviewCount: null,
          rating: null,
          salesRank: 23701
        },
        {
          date: '2017-09-25',
          reviewCount: null,
          rating: null,
          salesRank: 38160
        },
        {
          date: '2017-09-26',
          reviewCount: null,
          rating: null,
          salesRank: 28876
        },
        {
          date: '2017-09-27',
          reviewCount: null,
          rating: null,
          salesRank: 31865
        },
        {
          date: '2017-09-28',
          reviewCount: null,
          rating: null,
          salesRank: 34517
        },
        {
          date: '2017-09-29',
          reviewCount: null,
          rating: null,
          salesRank: 22651
        },
        {
          date: '2017-09-30',
          reviewCount: null,
          rating: null,
          salesRank: 37250
        },
        {
          date: '2017-10-01',
          reviewCount: null,
          rating: null,
          salesRank: 26154
        },
        {
          date: '2017-10-02',
          reviewCount: null,
          rating: null,
          salesRank: 18417
        },
        {
          date: '2017-10-03',
          reviewCount: null,
          rating: null,
          salesRank: 20798
        },
        {
          date: '2017-10-05',
          reviewCount: null,
          rating: null,
          salesRank: 20364
        },
        {
          date: '2017-10-06',
          reviewCount: null,
          rating: null,
          salesRank: 14546
        },
        {
          date: '2017-10-07',
          reviewCount: null,
          rating: null,
          salesRank: 14402
        },
        {
          date: '2017-10-08',
          reviewCount: null,
          rating: null,
          salesRank: 18736
        },
        {
          date: '2017-10-09',
          reviewCount: null,
          rating: null,
          salesRank: 19003
        },
        {
          date: '2017-10-10',
          reviewCount: null,
          rating: null,
          salesRank: 14708
        },
        {
          date: '2017-10-11',
          reviewCount: null,
          rating: null,
          salesRank: 18729
        },
        {
          date: '2017-10-12',
          reviewCount: null,
          rating: null,
          salesRank: 11876
        },
        {
          date: '2017-10-13',
          reviewCount: null,
          rating: null,
          salesRank: 9327
        },
        {
          date: '2017-10-14',
          reviewCount: null,
          rating: null,
          salesRank: 10032
        },
        {
          date: '2017-10-15',
          reviewCount: null,
          rating: null,
          salesRank: 12886
        },
        {
          date: '2017-10-16',
          reviewCount: null,
          rating: null,
          salesRank: 10255
        },
        {
          date: '2017-10-17',
          reviewCount: null,
          rating: null,
          salesRank: 7997
        },
        {
          date: '2017-10-18',
          reviewCount: null,
          rating: null,
          salesRank: 10113
        },
        {
          date: '2017-10-19',
          reviewCount: null,
          rating: null,
          salesRank: 9101
        },
        {
          date: '2017-10-20',
          reviewCount: null,
          rating: null,
          salesRank: 12623
        },
        {
          date: '2017-10-21',
          reviewCount: null,
          rating: null,
          salesRank: 720
        },
        {
          date: '2017-10-22',
          reviewCount: null,
          rating: null,
          salesRank: 1843
        },
        {
          date: '2017-10-23',
          reviewCount: null,
          rating: null,
          salesRank: 3228
        },
        {
          date: '2017-10-24',
          reviewCount: null,
          rating: null,
          salesRank: 3718
        },
        {
          date: '2017-10-25',
          reviewCount: null,
          rating: null,
          salesRank: 6031
        },
        {
          date: '2017-10-26',
          reviewCount: null,
          rating: null,
          salesRank: 4916
        },
        {
          date: '2017-10-27',
          reviewCount: null,
          rating: null,
          salesRank: 4972
        },
        {
          date: '2017-10-29',
          reviewCount: null,
          rating: null,
          salesRank: 4502
        },
        {
          date: '2017-10-30',
          reviewCount: null,
          rating: null,
          salesRank: 3721
        },
        {
          date: '2017-10-31',
          reviewCount: null,
          rating: null,
          salesRank: 3951
        },
        {
          date: '2017-11-01',
          reviewCount: null,
          rating: null,
          salesRank: 3250
        },
        {
          date: '2017-11-02',
          reviewCount: null,
          rating: null,
          salesRank: 3536
        },
        {
          date: '2017-11-03',
          reviewCount: null,
          rating: null,
          salesRank: 3217
        },
        {
          date: '2017-11-04',
          reviewCount: null,
          rating: null,
          salesRank: 4158
        },
        {
          date: '2017-11-05',
          reviewCount: null,
          rating: null,
          salesRank: 3253
        },
        {
          date: '2017-11-06',
          reviewCount: null,
          rating: null,
          salesRank: 2720
        },
        {
          date: '2017-11-07',
          reviewCount: null,
          rating: null,
          salesRank: 2901
        },
        {
          date: '2017-11-08',
          reviewCount: null,
          rating: null,
          salesRank: 2013
        },
        {
          date: '2017-11-09',
          reviewCount: null,
          rating: null,
          salesRank: 2227
        },
        {
          date: '2017-11-10',
          reviewCount: null,
          rating: null,
          salesRank: 1849
        },
        {
          date: '2017-11-11',
          reviewCount: null,
          rating: null,
          salesRank: 1218
        },
        {
          date: '2017-11-12',
          reviewCount: null,
          rating: null,
          salesRank: 1085
        },
        {
          date: '2017-11-13',
          reviewCount: null,
          rating: null,
          salesRank: 843
        },
        {
          date: '2017-11-14',
          reviewCount: null,
          rating: null,
          salesRank: 1094
        },
        {
          date: '2017-11-15',
          reviewCount: null,
          rating: null,
          salesRank: 634
        },
        {
          date: '2017-11-16',
          reviewCount: null,
          rating: null,
          salesRank: 645
        },
        {
          date: '2017-11-17',
          reviewCount: null,
          rating: null,
          salesRank: 670
        },
        {
          date: '2017-11-18',
          reviewCount: null,
          rating: null,
          salesRank: 561
        },
        {
          date: '2017-11-19',
          reviewCount: null,
          rating: null,
          salesRank: 509
        },
        {
          date: '2017-11-20',
          reviewCount: null,
          rating: null,
          salesRank: 478
        },
        {
          date: '2017-11-21',
          reviewCount: null,
          rating: null,
          salesRank: 444
        },
        {
          date: '2017-11-22',
          reviewCount: null,
          rating: null,
          salesRank: 503
        },
        {
          date: '2017-11-23',
          reviewCount: null,
          rating: null,
          salesRank: 475
        },
        {
          date: '2017-11-24',
          reviewCount: null,
          rating: null,
          salesRank: 321
        },
        {
          date: '2017-11-25',
          reviewCount: null,
          rating: null,
          salesRank: 435
        },
        {
          date: '2017-11-26',
          reviewCount: null,
          rating: null,
          salesRank: 263
        },
        {
          date: '2017-11-27',
          reviewCount: null,
          rating: null,
          salesRank: 303
        },
        {
          date: '2017-11-28',
          reviewCount: null,
          rating: null,
          salesRank: 362
        },
        {
          date: '2017-11-29',
          reviewCount: null,
          rating: null,
          salesRank: 438
        },
        {
          date: '2017-11-30',
          reviewCount: null,
          rating: null,
          salesRank: 398
        },
        {
          date: '2017-12-01',
          reviewCount: null,
          rating: null,
          salesRank: 497
        },
        {
          date: '2017-12-02',
          reviewCount: null,
          rating: null,
          salesRank: 433
        },
        {
          date: '2017-12-03',
          reviewCount: null,
          rating: null,
          salesRank: 458
        },
        {
          date: '2017-12-04',
          reviewCount: null,
          rating: null,
          salesRank: 410
        },
        {
          date: '2017-12-05',
          reviewCount: null,
          rating: null,
          salesRank: 351
        },
        {
          date: '2017-12-06',
          reviewCount: null,
          rating: null,
          salesRank: 292
        },
        {
          date: '2017-12-07',
          reviewCount: null,
          rating: null,
          salesRank: 270
        },
        {
          date: '2017-12-08',
          reviewCount: null,
          rating: null,
          salesRank: 261
        },
        {
          date: '2017-12-09',
          reviewCount: null,
          rating: null,
          salesRank: 234
        },
        {
          date: '2017-12-10',
          reviewCount: null,
          rating: null,
          salesRank: 234
        },
        {
          date: '2017-12-11',
          reviewCount: null,
          rating: null,
          salesRank: 243
        },
        {
          date: '2017-12-12',
          reviewCount: null,
          rating: null,
          salesRank: 188
        },
        {
          date: '2017-12-13',
          reviewCount: null,
          rating: null,
          salesRank: 169
        },
        {
          date: '2017-12-14',
          reviewCount: null,
          rating: null,
          salesRank: 196
        },
        {
          date: '2017-12-15',
          reviewCount: null,
          rating: null,
          salesRank: 229
        },
        {
          date: '2017-12-16',
          reviewCount: null,
          rating: null,
          salesRank: 242
        },
        {
          date: '2017-12-17',
          reviewCount: null,
          rating: null,
          salesRank: 327
        },
        {
          date: '2017-12-18',
          reviewCount: null,
          rating: null,
          salesRank: 1011
        },
        {
          date: '2017-12-19',
          reviewCount: null,
          rating: null,
          salesRank: 2015
        },
        {
          date: '2017-12-20',
          reviewCount: null,
          rating: null,
          salesRank: 2300
        },
        {
          date: '2017-12-21',
          reviewCount: null,
          rating: null,
          salesRank: 4130
        },
        {
          date: '2017-12-22',
          reviewCount: null,
          rating: null,
          salesRank: 4395
        },
        {
          date: '2017-12-23',
          reviewCount: null,
          rating: null,
          salesRank: 4665
        },
        {
          date: '2017-12-24',
          reviewCount: null,
          rating: null,
          salesRank: 4771
        },
        {
          date: '2017-12-25',
          reviewCount: null,
          rating: null,
          salesRank: 5384
        },
        {
          date: '2017-12-26',
          reviewCount: null,
          rating: null,
          salesRank: 8145
        },
        {
          date: '2017-12-27',
          reviewCount: null,
          rating: null,
          salesRank: 8986
        },
        {
          date: '2017-12-28',
          reviewCount: null,
          rating: null,
          salesRank: 1732
        },
        {
          date: '2017-12-29',
          reviewCount: null,
          rating: null,
          salesRank: 576
        },
        {
          date: '2017-12-30',
          reviewCount: null,
          rating: null,
          salesRank: 361
        },
        {
          date: '2017-12-31',
          reviewCount: null,
          rating: null,
          salesRank: 373
        },
        {
          date: '2018-01-01',
          reviewCount: null,
          rating: null,
          salesRank: 452
        },
        {
          date: '2018-01-02',
          reviewCount: null,
          rating: null,
          salesRank: 498
        },
        {
          date: '2018-01-03',
          reviewCount: null,
          rating: null,
          salesRank: 880
        },
        {
          date: '2018-01-04',
          reviewCount: null,
          rating: null,
          salesRank: 1675
        },
        {
          date: '2018-01-05',
          reviewCount: null,
          rating: null,
          salesRank: 3252
        },
        {
          date: '2018-01-06',
          reviewCount: null,
          rating: null,
          salesRank: 4796
        },
        {
          date: '2018-01-07',
          reviewCount: null,
          rating: null,
          salesRank: 717
        },
        {
          date: '2018-01-08',
          reviewCount: null,
          rating: null,
          salesRank: 497
        },
        {
          date: '2018-01-09',
          reviewCount: null,
          rating: null,
          salesRank: 468
        },
        {
          date: '2018-01-10',
          reviewCount: null,
          rating: null,
          salesRank: 578
        },
        {
          date: '2018-01-11',
          reviewCount: null,
          rating: null,
          salesRank: 640
        },
        {
          date: '2018-01-12',
          reviewCount: null,
          rating: null,
          salesRank: 661
        },
        {
          date: '2018-01-13',
          reviewCount: null,
          rating: null,
          salesRank: 607
        },
        {
          date: '2018-01-14',
          reviewCount: null,
          rating: null,
          salesRank: 342
        },
        {
          date: '2018-01-15',
          reviewCount: null,
          rating: null,
          salesRank: 812
        },
        {
          date: '2018-01-16',
          reviewCount: null,
          rating: null,
          salesRank: 2539
        },
        {
          date: '2018-01-17',
          reviewCount: null,
          rating: null,
          salesRank: 2942
        },
        {
          date: '2018-01-18',
          reviewCount: null,
          rating: null,
          salesRank: 5087
        },
        {
          date: '2018-01-19',
          reviewCount: null,
          rating: null,
          salesRank: 944
        },
        {
          date: '2018-01-20',
          reviewCount: null,
          rating: null,
          salesRank: 583
        },
        {
          date: '2018-01-21',
          reviewCount: null,
          rating: null,
          salesRank: 461
        },
        {
          date: '2018-01-22',
          reviewCount: null,
          rating: null,
          salesRank: 462
        },
        {
          date: '2018-01-23',
          reviewCount: null,
          rating: null,
          salesRank: 478
        },
        {
          date: '2018-01-24',
          reviewCount: null,
          rating: null,
          salesRank: 437
        },
        {
          date: '2018-01-25',
          reviewCount: null,
          rating: null,
          salesRank: 560
        },
        {
          date: '2018-01-26',
          reviewCount: null,
          rating: null,
          salesRank: 480
        },
        {
          date: '2018-01-27',
          reviewCount: null,
          rating: null,
          salesRank: 388
        },
        {
          date: '2018-01-28',
          reviewCount: null,
          rating: null,
          salesRank: 390
        },
        {
          date: '2018-01-29',
          reviewCount: null,
          rating: null,
          salesRank: 397
        },
        {
          date: '2018-01-30',
          reviewCount: null,
          rating: null,
          salesRank: 324
        },
        {
          date: '2018-01-31',
          reviewCount: null,
          rating: null,
          salesRank: 309
        },
        {
          date: '2018-02-01',
          reviewCount: null,
          rating: null,
          salesRank: 243
        },
        {
          date: '2018-02-02',
          reviewCount: null,
          rating: null,
          salesRank: 252
        },
        {
          date: '2018-02-03',
          reviewCount: null,
          rating: null,
          salesRank: 247
        },
        {
          date: '2018-02-04',
          reviewCount: null,
          rating: null,
          salesRank: 227
        },
        {
          date: '2018-02-05',
          reviewCount: null,
          rating: null,
          salesRank: 204
        },
        {
          date: '2018-02-06',
          reviewCount: null,
          rating: null,
          salesRank: 180
        },
        {
          date: '2018-02-07',
          reviewCount: null,
          rating: null,
          salesRank: 177
        },
        {
          date: '2018-02-08',
          reviewCount: null,
          rating: null,
          salesRank: 244
        },
        {
          date: '2018-02-09',
          reviewCount: null,
          rating: null,
          salesRank: 390
        },
        {
          date: '2018-02-10',
          reviewCount: null,
          rating: null,
          salesRank: 466
        },
        {
          date: '2018-02-11',
          reviewCount: null,
          rating: null,
          salesRank: 606
        },
        {
          date: '2018-02-12',
          reviewCount: null,
          rating: null,
          salesRank: 731
        },
        {
          date: '2018-02-13',
          reviewCount: null,
          rating: null,
          salesRank: 512
        },
        {
          date: '2018-02-14',
          reviewCount: null,
          rating: null,
          salesRank: 665
        },
        {
          date: '2018-02-15',
          reviewCount: null,
          rating: null,
          salesRank: 709
        },
        {
          date: '2018-02-16',
          reviewCount: null,
          rating: null,
          salesRank: 846
        },
        {
          date: '2018-02-17',
          reviewCount: null,
          rating: null,
          salesRank: 1014
        },
        {
          date: '2018-02-18',
          reviewCount: null,
          rating: null,
          salesRank: 1017
        },
        {
          date: '2018-02-19',
          reviewCount: null,
          rating: null,
          salesRank: 962
        },
        {
          date: '2018-02-20',
          reviewCount: null,
          rating: null,
          salesRank: 1160
        },
        {
          date: '2018-02-21',
          reviewCount: null,
          rating: null,
          salesRank: 1297
        },
        {
          date: '2018-02-22',
          reviewCount: null,
          rating: null,
          salesRank: 1139
        },
        {
          date: '2018-02-23',
          reviewCount: null,
          rating: null,
          salesRank: 1197
        },
        {
          date: '2018-02-24',
          reviewCount: null,
          rating: null,
          salesRank: 1161
        },
        {
          date: '2018-02-25',
          reviewCount: null,
          rating: null,
          salesRank: 1392
        },
        {
          date: '2018-02-26',
          reviewCount: null,
          rating: null,
          salesRank: 1218
        },
        {
          date: '2018-02-27',
          reviewCount: null,
          rating: null,
          salesRank: 1341
        },
        {
          date: '2018-02-28',
          reviewCount: null,
          rating: null,
          salesRank: 1415
        },
        {
          date: '2018-03-01',
          reviewCount: null,
          rating: null,
          salesRank: 1747
        },
        {
          date: '2018-03-02',
          reviewCount: null,
          rating: null,
          salesRank: 1967
        },
        {
          date: '2018-03-03',
          reviewCount: null,
          rating: null,
          salesRank: 1876
        },
        {
          date: '2018-03-04',
          reviewCount: null,
          rating: null,
          salesRank: 2110
        },
        {
          date: '2018-03-05',
          reviewCount: null,
          rating: null,
          salesRank: 2112
        },
        {
          date: '2018-03-06',
          reviewCount: null,
          rating: null,
          salesRank: 1548
        },
        {
          date: '2018-03-07',
          reviewCount: null,
          rating: null,
          salesRank: 978
        },
        {
          date: '2018-03-08',
          reviewCount: null,
          rating: null,
          salesRank: 1371
        },
        {
          date: '2018-03-09',
          reviewCount: null,
          rating: null,
          salesRank: 1522
        },
        {
          date: '2018-03-10',
          reviewCount: null,
          rating: null,
          salesRank: 1654
        },
        {
          date: '2018-03-11',
          reviewCount: null,
          rating: null,
          salesRank: 2010
        },
        {
          date: '2018-03-12',
          reviewCount: null,
          rating: null,
          salesRank: 2027
        },
        {
          date: '2018-03-13',
          reviewCount: null,
          rating: null,
          salesRank: 1934
        },
        {
          date: '2018-03-14',
          reviewCount: null,
          rating: null,
          salesRank: 2061
        },
        {
          date: '2018-03-15',
          reviewCount: null,
          rating: null,
          salesRank: 2138
        },
        {
          date: '2018-03-16',
          reviewCount: null,
          rating: null,
          salesRank: 1924
        },
        {
          date: '2018-03-17',
          reviewCount: null,
          rating: null,
          salesRank: 2593
        },
        {
          date: '2018-03-18',
          reviewCount: null,
          rating: null,
          salesRank: 2350
        },
        {
          date: '2018-03-19',
          reviewCount: null,
          rating: null,
          salesRank: 2177
        },
        {
          date: '2018-03-20',
          reviewCount: null,
          rating: null,
          salesRank: 2325
        },
        {
          date: '2018-03-21',
          reviewCount: null,
          rating: null,
          salesRank: 1897
        },
        {
          date: '2018-03-22',
          reviewCount: null,
          rating: null,
          salesRank: 2662
        },
        {
          date: '2018-03-23',
          reviewCount: null,
          rating: null,
          salesRank: 2712
        },
        {
          date: '2018-03-24',
          reviewCount: null,
          rating: null,
          salesRank: 2854
        },
        {
          date: '2018-03-25',
          reviewCount: null,
          rating: null,
          salesRank: 3538
        },
        {
          date: '2018-03-26',
          reviewCount: null,
          rating: null,
          salesRank: 3134
        },
        {
          date: '2018-03-27',
          reviewCount: null,
          rating: null,
          salesRank: 3084
        },
        {
          date: '2018-03-28',
          reviewCount: null,
          rating: null,
          salesRank: 3021
        },
        {
          date: '2018-03-29',
          reviewCount: null,
          rating: null,
          salesRank: 3179
        },
        {
          date: '2018-03-30',
          reviewCount: null,
          rating: null,
          salesRank: 3185
        },
        {
          date: '2018-03-31',
          reviewCount: null,
          rating: null,
          salesRank: 3380
        },
        {
          date: '2018-04-01',
          reviewCount: null,
          rating: null,
          salesRank: 4220
        },
        {
          date: '2018-04-02',
          reviewCount: null,
          rating: null,
          salesRank: 3224
        },
        {
          date: '2018-04-03',
          reviewCount: null,
          rating: null,
          salesRank: 3141
        },
        {
          date: '2018-04-04',
          reviewCount: null,
          rating: null,
          salesRank: 4002
        },
        {
          date: '2018-04-05',
          reviewCount: null,
          rating: null,
          salesRank: 4107
        },
        {
          date: '2018-04-06',
          reviewCount: null,
          rating: null,
          salesRank: 4353
        },
        {
          date: '2018-04-07',
          reviewCount: null,
          rating: null,
          salesRank: 3376
        },
        {
          date: '2018-04-08',
          reviewCount: null,
          rating: null,
          salesRank: 3920
        },
        {
          date: '2018-04-09',
          reviewCount: null,
          rating: null,
          salesRank: 3307
        },
        {
          date: '2018-04-10',
          reviewCount: null,
          rating: null,
          salesRank: 3399
        },
        {
          date: '2018-04-11',
          reviewCount: null,
          rating: null,
          salesRank: 3074
        },
        {
          date: '2018-04-12',
          reviewCount: null,
          rating: null,
          salesRank: 4724
        },
        {
          date: '2018-04-13',
          reviewCount: null,
          rating: null,
          salesRank: 4346
        },
        {
          date: '2018-04-14',
          reviewCount: null,
          rating: null,
          salesRank: 4087
        },
        {
          date: '2018-04-15',
          reviewCount: null,
          rating: null,
          salesRank: 4380
        },
        {
          date: '2018-04-16',
          reviewCount: null,
          rating: null,
          salesRank: 4688
        },
        {
          date: '2018-04-17',
          reviewCount: null,
          rating: null,
          salesRank: 4026
        },
        {
          date: '2018-04-18',
          reviewCount: null,
          rating: null,
          salesRank: 4007
        },
        {
          date: '2018-04-19',
          reviewCount: null,
          rating: null,
          salesRank: 3935
        },
        {
          date: '2018-04-20',
          reviewCount: null,
          rating: null,
          salesRank: 4944
        },
        {
          date: '2018-04-21',
          reviewCount: null,
          rating: null,
          salesRank: 4584
        },
        {
          date: '2018-04-22',
          reviewCount: null,
          rating: null,
          salesRank: 4852
        },
        {
          date: '2018-04-23',
          reviewCount: null,
          rating: null,
          salesRank: 5564
        },
        {
          date: '2018-04-24',
          reviewCount: null,
          rating: null,
          salesRank: 5291
        },
        {
          date: '2018-04-25',
          reviewCount: null,
          rating: null,
          salesRank: 5531
        },
        {
          date: '2018-04-26',
          reviewCount: null,
          rating: null,
          salesRank: 5198
        },
        {
          date: '2018-04-27',
          reviewCount: null,
          rating: null,
          salesRank: 4762
        },
        {
          date: '2018-04-28',
          reviewCount: null,
          rating: null,
          salesRank: 4910
        },
        {
          date: '2018-04-29',
          reviewCount: null,
          rating: null,
          salesRank: 5909
        },
        {
          date: '2018-04-30',
          reviewCount: null,
          rating: null,
          salesRank: 6305
        },
        {
          date: '2018-05-01',
          reviewCount: null,
          rating: null,
          salesRank: 4464
        },
        {
          date: '2018-05-02',
          reviewCount: null,
          rating: null,
          salesRank: 5997
        },
        {
          date: '2018-05-03',
          reviewCount: null,
          rating: null,
          salesRank: 5797
        },
        {
          date: '2018-05-04',
          reviewCount: null,
          rating: null,
          salesRank: 4813
        },
        {
          date: '2018-05-05',
          reviewCount: null,
          rating: null,
          salesRank: 5964
        },
        {
          date: '2018-05-06',
          reviewCount: null,
          rating: null,
          salesRank: 5770
        },
        {
          date: '2018-05-07',
          reviewCount: null,
          rating: null,
          salesRank: 6014
        },
        {
          date: '2018-05-08',
          reviewCount: null,
          rating: null,
          salesRank: 6656
        },
        {
          date: '2018-05-09',
          reviewCount: null,
          rating: null,
          salesRank: 5614
        },
        {
          date: '2018-05-10',
          reviewCount: null,
          rating: null,
          salesRank: 6420
        },
        {
          date: '2018-05-11',
          reviewCount: null,
          rating: null,
          salesRank: 7337
        },
        {
          date: '2018-05-12',
          reviewCount: null,
          rating: null,
          salesRank: 8026
        },
        {
          date: '2018-05-13',
          reviewCount: null,
          rating: null,
          salesRank: 6535
        },
        {
          date: '2018-05-14',
          reviewCount: null,
          rating: null,
          salesRank: 6707
        },
        {
          date: '2018-05-15',
          reviewCount: null,
          rating: null,
          salesRank: 8224
        },
        {
          date: '2018-05-16',
          reviewCount: null,
          rating: null,
          salesRank: 6796
        },
        {
          date: '2018-05-17',
          reviewCount: null,
          rating: null,
          salesRank: 7210
        },
        {
          date: '2018-05-18',
          reviewCount: null,
          rating: null,
          salesRank: 9232
        },
        {
          date: '2018-05-19',
          reviewCount: null,
          rating: null,
          salesRank: 9430
        },
        {
          date: '2018-05-20',
          reviewCount: null,
          rating: null,
          salesRank: 7074
        },
        {
          date: '2018-05-21',
          reviewCount: null,
          rating: null,
          salesRank: 7933
        },
        {
          date: '2018-05-22',
          reviewCount: null,
          rating: null,
          salesRank: 7863
        },
        {
          date: '2018-05-23',
          reviewCount: null,
          rating: null,
          salesRank: 8125
        },
        {
          date: '2018-05-24',
          reviewCount: null,
          rating: null,
          salesRank: 8066
        },
        {
          date: '2018-05-25',
          reviewCount: null,
          rating: null,
          salesRank: 8041
        },
        {
          date: '2018-05-26',
          reviewCount: null,
          rating: null,
          salesRank: 8482
        },
        {
          date: '2018-05-27',
          reviewCount: null,
          rating: null,
          salesRank: 9995
        },
        {
          date: '2018-05-28',
          reviewCount: null,
          rating: null,
          salesRank: 8597
        },
        {
          date: '2018-05-29',
          reviewCount: null,
          rating: null,
          salesRank: 6922
        },
        {
          date: '2018-05-30',
          reviewCount: null,
          rating: null,
          salesRank: 7630
        },
        {
          date: '2018-05-31',
          reviewCount: null,
          rating: null,
          salesRank: 8090
        },
        {
          date: '2018-06-01',
          reviewCount: null,
          rating: null,
          salesRank: 7623
        },
        {
          date: '2018-06-02',
          reviewCount: null,
          rating: null,
          salesRank: 8435
        },
        {
          date: '2018-06-03',
          reviewCount: null,
          rating: null,
          salesRank: 7703
        },
        {
          date: '2018-06-04',
          reviewCount: null,
          rating: null,
          salesRank: 6150
        },
        {
          date: '2018-06-05',
          reviewCount: null,
          rating: null,
          salesRank: 7552
        },
        {
          date: '2018-06-06',
          reviewCount: null,
          rating: null,
          salesRank: 7484
        },
        {
          date: '2018-06-07',
          reviewCount: null,
          rating: null,
          salesRank: 9059
        },
        {
          date: '2018-06-08',
          reviewCount: null,
          rating: null,
          salesRank: 9324
        },
        {
          date: '2018-06-09',
          reviewCount: null,
          rating: null,
          salesRank: 7873
        },
        {
          date: '2018-06-10',
          reviewCount: null,
          rating: null,
          salesRank: 7325
        },
        {
          date: '2018-06-11',
          reviewCount: null,
          rating: null,
          salesRank: 7881
        },
        {
          date: '2018-06-12',
          reviewCount: null,
          rating: null,
          salesRank: 10322
        },
        {
          date: '2018-06-13',
          reviewCount: null,
          rating: null,
          salesRank: 8846
        },
        {
          date: '2018-06-14',
          reviewCount: null,
          rating: null,
          salesRank: 8136
        },
        {
          date: '2018-06-15',
          reviewCount: null,
          rating: null,
          salesRank: 9619
        },
        {
          date: '2018-06-16',
          reviewCount: null,
          rating: null,
          salesRank: 8381
        },
        {
          date: '2018-06-17',
          reviewCount: null,
          rating: null,
          salesRank: 9620
        },
        {
          date: '2018-06-18',
          reviewCount: null,
          rating: null,
          salesRank: 10919
        },
        {
          date: '2018-06-19',
          reviewCount: null,
          rating: null,
          salesRank: 8973
        },
        {
          date: '2018-06-20',
          reviewCount: null,
          rating: null,
          salesRank: 11904
        },
        {
          date: '2018-06-21',
          reviewCount: null,
          rating: null,
          salesRank: 10271
        },
        {
          date: '2018-06-22',
          reviewCount: null,
          rating: null,
          salesRank: 10584
        },
        {
          date: '2018-06-23',
          reviewCount: null,
          rating: null,
          salesRank: 9861
        },
        {
          date: '2018-06-24',
          reviewCount: null,
          rating: null,
          salesRank: 8638
        },
        {
          date: '2018-06-25',
          reviewCount: null,
          rating: null,
          salesRank: 13191
        },
        {
          date: '2018-06-26',
          reviewCount: null,
          rating: null,
          salesRank: 13211
        },
        {
          date: '2018-06-27',
          reviewCount: null,
          rating: null,
          salesRank: 11084
        },
        {
          date: '2018-06-28',
          reviewCount: null,
          rating: null,
          salesRank: 13344
        },
        {
          date: '2018-06-29',
          reviewCount: null,
          rating: null,
          salesRank: 12168
        },
        {
          date: '2018-06-30',
          reviewCount: null,
          rating: null,
          salesRank: 11437
        },
        {
          date: '2018-07-01',
          reviewCount: null,
          rating: null,
          salesRank: 10888
        },
        {
          date: '2018-07-02',
          reviewCount: null,
          rating: null,
          salesRank: 11722
        },
        {
          date: '2018-07-03',
          reviewCount: null,
          rating: null,
          salesRank: 11508
        },
        {
          date: '2018-07-04',
          reviewCount: null,
          rating: null,
          salesRank: 11701
        },
        {
          date: '2018-07-05',
          reviewCount: null,
          rating: null,
          salesRank: 12422
        },
        {
          date: '2018-07-06',
          reviewCount: null,
          rating: null,
          salesRank: 10553
        },
        {
          date: '2018-07-07',
          reviewCount: null,
          rating: null,
          salesRank: 14055
        },
        {
          date: '2018-07-08',
          reviewCount: null,
          rating: null,
          salesRank: 9823
        },
        {
          date: '2018-07-09',
          reviewCount: null,
          rating: null,
          salesRank: 10402
        },
        {
          date: '2018-07-10',
          reviewCount: null,
          rating: null,
          salesRank: 13870
        },
        {
          date: '2018-07-11',
          reviewCount: null,
          rating: null,
          salesRank: 11482
        },
        {
          date: '2018-07-12',
          reviewCount: null,
          rating: null,
          salesRank: 16189
        },
        {
          date: '2018-07-13',
          reviewCount: null,
          rating: null,
          salesRank: 14195
        },
        {
          date: '2018-07-14',
          reviewCount: null,
          rating: null,
          salesRank: 13316
        },
        {
          date: '2018-07-15',
          reviewCount: null,
          rating: null,
          salesRank: 13849
        },
        {
          date: '2018-07-16',
          reviewCount: null,
          rating: null,
          salesRank: 10205
        },
        {
          date: '2018-07-17',
          reviewCount: null,
          rating: null,
          salesRank: 4109
        },
        {
          date: '2018-07-18',
          reviewCount: null,
          rating: null,
          salesRank: 5819
        },
        {
          date: '2018-07-19',
          reviewCount: null,
          rating: null,
          salesRank: 6997
        },
        {
          date: '2018-07-20',
          reviewCount: null,
          rating: null,
          salesRank: 8195
        },
        {
          date: '2018-07-21',
          reviewCount: null,
          rating: null,
          salesRank: 7042
        },
        {
          date: '2018-07-22',
          reviewCount: null,
          rating: null,
          salesRank: 6725
        },
        {
          date: '2018-07-23',
          reviewCount: null,
          rating: null,
          salesRank: 8579
        },
        {
          date: '2018-07-24',
          reviewCount: null,
          rating: null,
          salesRank: 7818
        },
        {
          date: '2018-07-25',
          reviewCount: null,
          rating: null,
          salesRank: 7948
        },
        {
          date: '2018-07-26',
          reviewCount: null,
          rating: null,
          salesRank: 7291
        },
        {
          date: '2018-07-27',
          reviewCount: null,
          rating: null,
          salesRank: 8016
        },
        {
          date: '2018-07-28',
          reviewCount: null,
          rating: null,
          salesRank: 10219
        },
        {
          date: '2018-07-29',
          reviewCount: null,
          rating: null,
          salesRank: 9826
        },
        {
          date: '2018-07-30',
          reviewCount: null,
          rating: null,
          salesRank: 8553
        },
        {
          date: '2018-07-31',
          reviewCount: null,
          rating: null,
          salesRank: 8820
        },
        {
          date: '2018-08-01',
          reviewCount: null,
          rating: null,
          salesRank: 9700
        },
        {
          date: '2018-08-02',
          reviewCount: null,
          rating: null,
          salesRank: 6788
        },
        {
          date: '2018-08-03',
          reviewCount: null,
          rating: null,
          salesRank: 6438
        },
        {
          date: '2018-08-04',
          reviewCount: null,
          rating: null,
          salesRank: 7331
        },
        {
          date: '2018-08-05',
          reviewCount: null,
          rating: null,
          salesRank: 9166
        },
        {
          date: '2018-08-06',
          reviewCount: null,
          rating: null,
          salesRank: 7841
        },
        {
          date: '2018-08-07',
          reviewCount: null,
          rating: null,
          salesRank: 6902
        },
        {
          date: '2018-08-08',
          reviewCount: null,
          rating: null,
          salesRank: 8994
        },
        {
          date: '2018-08-09',
          reviewCount: null,
          rating: null,
          salesRank: 8122
        },
        {
          date: '2018-08-10',
          reviewCount: null,
          rating: null,
          salesRank: 9651
        },
        {
          date: '2018-08-11',
          reviewCount: null,
          rating: null,
          salesRank: 11777
        },
        {
          date: '2018-08-12',
          reviewCount: null,
          rating: null,
          salesRank: 15711
        },
        {
          date: '2018-08-13',
          reviewCount: null,
          rating: null,
          salesRank: 8779
        },
        {
          date: '2018-08-14',
          reviewCount: null,
          rating: null,
          salesRank: 3571
        },
        {
          date: '2018-08-15',
          reviewCount: null,
          rating: null,
          salesRank: 4724
        },
        {
          date: '2018-08-16',
          reviewCount: null,
          rating: null,
          salesRank: 6092
        },
        {
          date: '2018-08-17',
          reviewCount: null,
          rating: null,
          salesRank: 6925
        },
        {
          date: '2018-08-18',
          reviewCount: null,
          rating: null,
          salesRank: 7721
        },
        {
          date: '2018-08-19',
          reviewCount: null,
          rating: null,
          salesRank: 7601
        },
        {
          date: '2018-08-20',
          reviewCount: null,
          rating: null,
          salesRank: 5628
        },
        {
          date: '2018-08-21',
          reviewCount: null,
          rating: null,
          salesRank: 7349
        },
        {
          date: '2018-08-22',
          reviewCount: null,
          rating: null,
          salesRank: 7190
        },
        {
          date: '2018-08-23',
          reviewCount: null,
          rating: null,
          salesRank: 6335
        },
        {
          date: '2018-08-24',
          reviewCount: null,
          rating: null,
          salesRank: 7306
        },
        {
          date: '2018-08-25',
          reviewCount: null,
          rating: null,
          salesRank: 7109
        },
        {
          date: '2018-08-26',
          reviewCount: null,
          rating: null,
          salesRank: 7029
        },
        {
          date: '2018-08-27',
          reviewCount: null,
          rating: null,
          salesRank: 6712
        },
        {
          date: '2018-08-28',
          reviewCount: null,
          rating: null,
          salesRank: 5732
        },
        {
          date: '2018-08-29',
          reviewCount: null,
          rating: null,
          salesRank: 7222
        },
        {
          date: '2018-08-30',
          reviewCount: null,
          rating: null,
          salesRank: 7684
        },
        {
          date: '2018-08-31',
          reviewCount: null,
          rating: null,
          salesRank: 6455
        },
        {
          date: '2018-09-01',
          reviewCount: null,
          rating: null,
          salesRank: 7155
        },
        {
          date: '2018-09-02',
          reviewCount: null,
          rating: null,
          salesRank: 5370
        },
        {
          date: '2018-09-03',
          reviewCount: null,
          rating: null,
          salesRank: 6219
        },
        {
          date: '2018-09-04',
          reviewCount: null,
          rating: null,
          salesRank: 5593
        },
        {
          date: '2018-09-05',
          reviewCount: null,
          rating: null,
          salesRank: 5970
        },
        {
          date: '2018-09-06',
          reviewCount: null,
          rating: null,
          salesRank: 6367
        },
        {
          date: '2018-09-07',
          reviewCount: null,
          rating: null,
          salesRank: 6814
        },
        {
          date: '2018-09-08',
          reviewCount: null,
          rating: null,
          salesRank: 5474
        },
        {
          date: '2018-09-09',
          reviewCount: null,
          rating: null,
          salesRank: 5079
        },
        {
          date: '2018-09-10',
          reviewCount: null,
          rating: null,
          salesRank: 6113
        },
        {
          date: '2018-09-11',
          reviewCount: null,
          rating: null,
          salesRank: 5594
        },
        {
          date: '2018-09-12',
          reviewCount: null,
          rating: null,
          salesRank: 6980
        },
        {
          date: '2018-09-13',
          reviewCount: null,
          rating: null,
          salesRank: 6640
        },
        {
          date: '2018-09-14',
          reviewCount: null,
          rating: null,
          salesRank: 6095
        },
        {
          date: '2018-09-15',
          reviewCount: null,
          rating: null,
          salesRank: 6205
        },
        {
          date: '2018-09-16',
          reviewCount: null,
          rating: null,
          salesRank: 7812
        },
        {
          date: '2018-09-17',
          reviewCount: null,
          rating: null,
          salesRank: 4982
        },
        {
          date: '2018-09-18',
          reviewCount: null,
          rating: null,
          salesRank: 5759
        },
        {
          date: '2018-09-19',
          reviewCount: null,
          rating: null,
          salesRank: 5634
        },
        {
          date: '2018-09-20',
          reviewCount: null,
          rating: null,
          salesRank: 5283
        },
        {
          date: '2018-09-21',
          reviewCount: null,
          rating: null,
          salesRank: 5420
        },
        {
          date: '2018-09-22',
          reviewCount: null,
          rating: null,
          salesRank: 4316
        },
        {
          date: '2018-09-23',
          reviewCount: null,
          rating: null,
          salesRank: 5132
        },
        {
          date: '2018-09-24',
          reviewCount: null,
          rating: null,
          salesRank: 5050
        },
        {
          date: '2018-09-25',
          reviewCount: null,
          rating: null,
          salesRank: 5163
        },
        {
          date: '2018-09-26',
          reviewCount: null,
          rating: null,
          salesRank: 5130
        },
        {
          date: '2018-09-27',
          reviewCount: null,
          rating: null,
          salesRank: 6466
        },
        {
          date: '2018-09-28',
          reviewCount: null,
          rating: null,
          salesRank: 4388
        },
        {
          date: '2018-09-29',
          reviewCount: null,
          rating: null,
          salesRank: 3535
        },
        {
          date: '2018-09-30',
          reviewCount: null,
          rating: null,
          salesRank: 3471
        },
        {
          date: '2018-10-01',
          reviewCount: null,
          rating: null,
          salesRank: 3182
        },
        {
          date: '2018-10-02',
          reviewCount: null,
          rating: null,
          salesRank: 4769
        },
        {
          date: '2018-10-03',
          reviewCount: null,
          rating: null,
          salesRank: 5690
        },
        {
          date: '2018-10-04',
          reviewCount: null,
          rating: null,
          salesRank: 4442
        },
        {
          date: '2018-10-05',
          reviewCount: null,
          rating: null,
          salesRank: 5765
        },
        {
          date: '2018-10-06',
          reviewCount: null,
          rating: null,
          salesRank: 5908
        },
        {
          date: '2018-10-07',
          reviewCount: null,
          rating: null,
          salesRank: 5830
        },
        {
          date: '2018-10-08',
          reviewCount: null,
          rating: null,
          salesRank: 4706
        },
        {
          date: '2018-10-09',
          reviewCount: null,
          rating: null,
          salesRank: 5257
        },
        {
          date: '2018-10-10',
          reviewCount: null,
          rating: null,
          salesRank: 4914
        },
        {
          date: '2018-10-11',
          reviewCount: null,
          rating: null,
          salesRank: 5662
        },
        {
          date: '2018-10-12',
          reviewCount: null,
          rating: null,
          salesRank: 5637
        },
        {
          date: '2018-10-13',
          reviewCount: null,
          rating: null,
          salesRank: 4926
        },
        {
          date: '2018-10-14',
          reviewCount: null,
          rating: null,
          salesRank: 3544
        },
        {
          date: '2018-10-15',
          reviewCount: null,
          rating: null,
          salesRank: 2620
        },
        {
          date: '2018-10-16',
          reviewCount: null,
          rating: null,
          salesRank: 2625
        },
        {
          date: '2018-10-17',
          reviewCount: null,
          rating: null,
          salesRank: 2840
        },
        {
          date: '2018-10-18',
          reviewCount: null,
          rating: null,
          salesRank: 1866
        },
        {
          date: '2018-10-19',
          reviewCount: null,
          rating: null,
          salesRank: 454
        },
        {
          date: '2018-10-20',
          reviewCount: null,
          rating: null,
          salesRank: 1028
        },
        {
          date: '2018-10-21',
          reviewCount: null,
          rating: null,
          salesRank: 1032
        },
        {
          date: '2018-10-22',
          reviewCount: null,
          rating: null,
          salesRank: 1014
        },
        {
          date: '2018-10-23',
          reviewCount: null,
          rating: null,
          salesRank: 861
        },
        {
          date: '2018-10-24',
          reviewCount: null,
          rating: null,
          salesRank: 942
        },
        {
          date: '2018-10-25',
          reviewCount: null,
          rating: null,
          salesRank: 923
        },
        {
          date: '2018-10-26',
          reviewCount: null,
          rating: null,
          salesRank: 977
        },
        {
          date: '2018-10-27',
          reviewCount: null,
          rating: null,
          salesRank: 1158
        },
        {
          date: '2018-10-28',
          reviewCount: null,
          rating: null,
          salesRank: 1083
        },
        {
          date: '2018-10-29',
          reviewCount: null,
          rating: null,
          salesRank: 1160
        },
        {
          date: '2018-10-30',
          reviewCount: null,
          rating: null,
          salesRank: 1184
        },
        {
          date: '2018-10-31',
          reviewCount: null,
          rating: null,
          salesRank: 1479
        },
        {
          date: '2018-11-01',
          reviewCount: null,
          rating: null,
          salesRank: 1890
        },
        {
          date: '2018-11-02',
          reviewCount: null,
          rating: null,
          salesRank: 1900
        },
        {
          date: '2018-11-03',
          reviewCount: null,
          rating: null,
          salesRank: 1690
        },
        {
          date: '2018-11-04',
          reviewCount: null,
          rating: null,
          salesRank: 1277
        },
        {
          date: '2018-11-05',
          reviewCount: null,
          rating: null,
          salesRank: 1173
        },
        {
          date: '2018-11-06',
          reviewCount: null,
          rating: null,
          salesRank: 1351
        },
        {
          date: '2018-11-07',
          reviewCount: null,
          rating: null,
          salesRank: 1580
        },
        {
          date: '2018-11-08',
          reviewCount: null,
          rating: null,
          salesRank: 1922
        },
        {
          date: '2018-11-09',
          reviewCount: null,
          rating: null,
          salesRank: 1699
        },
        {
          date: '2018-11-10',
          reviewCount: null,
          rating: null,
          salesRank: 1334
        },
        {
          date: '2018-11-11',
          reviewCount: null,
          rating: null,
          salesRank: 704
        },
        {
          date: '2018-11-12',
          reviewCount: null,
          rating: null,
          salesRank: 724
        },
        {
          date: '2018-11-13',
          reviewCount: null,
          rating: null,
          salesRank: 973
        },
        {
          date: '2018-11-14',
          reviewCount: null,
          rating: null,
          salesRank: 865
        },
        {
          date: '2018-11-15',
          reviewCount: null,
          rating: null,
          salesRank: 1062
        },
        {
          date: '2018-11-16',
          reviewCount: null,
          rating: null,
          salesRank: 921
        },
        {
          date: '2018-11-17',
          reviewCount: null,
          rating: null,
          salesRank: 992
        },
        {
          date: '2018-11-18',
          reviewCount: null,
          rating: null,
          salesRank: 977
        },
        {
          date: '2018-11-19',
          reviewCount: null,
          rating: null,
          salesRank: 1127
        },
        {
          date: '2018-11-20',
          reviewCount: null,
          rating: null,
          salesRank: 1376
        },
        {
          date: '2018-11-21',
          reviewCount: null,
          rating: null,
          salesRank: 1399
        },
        {
          date: '2018-12-05',
          reviewCount: null,
          rating: null,
          salesRank: 1917
        },
        {
          date: '2018-12-06',
          reviewCount: null,
          rating: null,
          salesRank: 1945
        },
        {
          date: '2018-12-07',
          reviewCount: null,
          rating: null,
          salesRank: 1752
        },
        {
          date: '2018-12-08',
          reviewCount: null,
          rating: null,
          salesRank: 1967
        },
        {
          date: '2018-12-09',
          reviewCount: null,
          rating: null,
          salesRank: 2031
        },
        {
          date: '2018-12-10',
          reviewCount: null,
          rating: null,
          salesRank: 1617
        },
        {
          date: '2018-12-11',
          reviewCount: null,
          rating: null,
          salesRank: 1824
        },
        {
          date: '2018-12-12',
          reviewCount: null,
          rating: null,
          salesRank: 1957
        },
        {
          date: '2018-12-13',
          reviewCount: null,
          rating: null,
          salesRank: 1969
        },
        {
          date: '2018-12-14',
          reviewCount: null,
          rating: null,
          salesRank: 2166
        },
        {
          date: '2018-12-15',
          reviewCount: null,
          rating: null,
          salesRank: 2592
        },
        {
          date: '2018-12-16',
          reviewCount: null,
          rating: null,
          salesRank: 102
        },
        {
          date: '2018-12-17',
          reviewCount: null,
          rating: null,
          salesRank: 317
        },
        {
          date: '2018-12-18',
          reviewCount: null,
          rating: null,
          salesRank: 641
        },
        {
          date: '2018-12-19',
          reviewCount: null,
          rating: null,
          salesRank: 881
        },
        {
          date: '2018-12-20',
          reviewCount: null,
          rating: null,
          salesRank: 1118
        },
        {
          date: '2018-12-21',
          reviewCount: null,
          rating: null,
          salesRank: 1381
        },
        {
          date: '2018-12-22',
          reviewCount: null,
          rating: null,
          salesRank: 1853
        },
        {
          date: '2018-12-23',
          reviewCount: null,
          rating: null,
          salesRank: 2183
        },
        {
          date: '2018-12-24',
          reviewCount: null,
          rating: null,
          salesRank: 2288
        },
        {
          date: '2018-12-25',
          reviewCount: null,
          rating: null,
          salesRank: 2568
        },
        {
          date: '2018-12-26',
          reviewCount: null,
          rating: null,
          salesRank: 2774
        },
        {
          date: '2018-12-27',
          reviewCount: null,
          rating: null,
          salesRank: 2179
        },
        {
          date: '2018-12-28',
          reviewCount: null,
          rating: null,
          salesRank: 2060
        },
        {
          date: '2018-12-29',
          reviewCount: null,
          rating: null,
          salesRank: 2522
        },
        {
          date: '2018-12-30',
          reviewCount: null,
          rating: null,
          salesRank: 2445
        },
        {
          date: '2018-12-31',
          reviewCount: null,
          rating: null,
          salesRank: 2697
        },
        {
          date: '2019-01-01',
          reviewCount: null,
          rating: null,
          salesRank: 2215
        },
        {
          date: '2019-01-02',
          reviewCount: null,
          rating: null,
          salesRank: 1957
        },
        {
          date: '2019-01-03',
          reviewCount: null,
          rating: null,
          salesRank: 2073
        },
        {
          date: '2019-01-04',
          reviewCount: null,
          rating: null,
          salesRank: 2229
        },
        {
          date: '2019-01-05',
          reviewCount: null,
          rating: null,
          salesRank: 2370
        },
        {
          date: '2019-01-06',
          reviewCount: null,
          rating: null,
          salesRank: 2763
        },
        {
          date: '2019-01-07',
          reviewCount: null,
          rating: null,
          salesRank: 2627
        },
        {
          date: '2019-01-08',
          reviewCount: null,
          rating: null,
          salesRank: 2299
        },
        {
          date: '2019-01-10',
          reviewCount: null,
          rating: null,
          salesRank: 2354
        },
        {
          date: '2019-01-11',
          reviewCount: null,
          rating: null,
          salesRank: 1684
        },
        {
          date: '2019-01-12',
          reviewCount: null,
          rating: null,
          salesRank: 1377
        },
        {
          date: '2019-01-13',
          reviewCount: null,
          rating: null,
          salesRank: 1387
        },
        {
          date: '2019-01-14',
          reviewCount: null,
          rating: null,
          salesRank: 1140
        },
        {
          date: '2019-01-15',
          reviewCount: null,
          rating: null,
          salesRank: 960
        },
        {
          date: '2019-01-16',
          reviewCount: null,
          rating: null,
          salesRank: 412
        },
        {
          date: '2019-01-17',
          reviewCount: null,
          rating: null,
          salesRank: 397
        },
        {
          date: '2019-01-18',
          reviewCount: null,
          rating: null,
          salesRank: 430
        },
        {
          date: '2019-01-19',
          reviewCount: null,
          rating: null,
          salesRank: 621
        },
        {
          date: '2019-01-20',
          reviewCount: null,
          rating: null,
          salesRank: 660
        },
        {
          date: '2019-01-21',
          reviewCount: null,
          rating: null,
          salesRank: 598
        },
        {
          date: '2019-01-22',
          reviewCount: null,
          rating: null,
          salesRank: 488
        },
        {
          date: '2019-01-23',
          reviewCount: null,
          rating: null,
          salesRank: 523
        },
        {
          date: '2019-01-24',
          reviewCount: null,
          rating: null,
          salesRank: 728
        },
        {
          date: '2019-01-25',
          reviewCount: null,
          rating: null,
          salesRank: 813
        },
        {
          date: '2019-01-26',
          reviewCount: null,
          rating: null,
          salesRank: 864
        },
        {
          date: '2019-01-27',
          reviewCount: null,
          rating: null,
          salesRank: 1290
        },
        {
          date: '2019-01-28',
          reviewCount: null,
          rating: null,
          salesRank: 1155
        },
        {
          date: '2019-01-29',
          reviewCount: null,
          rating: null,
          salesRank: 1543
        },
        {
          date: '2019-01-30',
          reviewCount: null,
          rating: null,
          salesRank: 1029
        },
        {
          date: '2019-01-31',
          reviewCount: null,
          rating: null,
          salesRank: 1229
        },
        {
          date: '2019-02-01',
          reviewCount: null,
          rating: null,
          salesRank: 431
        },
        {
          date: '2019-02-02',
          reviewCount: null,
          rating: null,
          salesRank: 450
        },
        {
          date: '2019-02-03',
          reviewCount: null,
          rating: null,
          salesRank: 517
        },
        {
          date: '2019-02-04',
          reviewCount: null,
          rating: null,
          salesRank: 689
        },
        {
          date: '2019-02-05',
          reviewCount: null,
          rating: null,
          salesRank: 1006
        },
        {
          date: '2019-02-06',
          reviewCount: null,
          rating: null,
          salesRank: 1027
        },
        {
          date: '2019-02-07',
          reviewCount: null,
          rating: null,
          salesRank: 836
        },
        {
          date: '2019-02-08',
          reviewCount: null,
          rating: null,
          salesRank: 1502
        },
        {
          date: '2019-02-09',
          reviewCount: null,
          rating: null,
          salesRank: 1683
        },
        {
          date: '2019-02-10',
          reviewCount: null,
          rating: null,
          salesRank: 1624
        },
        {
          date: '2019-02-11',
          reviewCount: null,
          rating: null,
          salesRank: 1362
        },
        {
          date: '2019-02-12',
          reviewCount: null,
          rating: null,
          salesRank: 1324
        },
        {
          date: '2019-02-13',
          reviewCount: null,
          rating: null,
          salesRank: 1347
        },
        {
          date: '2019-02-14',
          reviewCount: null,
          rating: null,
          salesRank: 1364
        },
        {
          date: '2019-02-15',
          reviewCount: null,
          rating: null,
          salesRank: 1502
        },
        {
          date: '2019-02-16',
          reviewCount: null,
          rating: null,
          salesRank: 1492
        },
        {
          date: '2019-02-17',
          reviewCount: null,
          rating: null,
          salesRank: 818
        },
        {
          date: '2019-02-18',
          reviewCount: null,
          rating: null,
          salesRank: 1755
        },
        {
          date: '2019-02-19',
          reviewCount: null,
          rating: null,
          salesRank: 1799
        },
        {
          date: '2019-02-20',
          reviewCount: null,
          rating: null,
          salesRank: 1700
        },
        {
          date: '2019-02-21',
          reviewCount: null,
          rating: null,
          salesRank: 1399
        },
        {
          date: '2019-02-22',
          reviewCount: null,
          rating: null,
          salesRank: 1666
        },
        {
          date: '2019-02-23',
          reviewCount: null,
          rating: null,
          salesRank: 1097
        },
        {
          date: '2019-02-24',
          reviewCount: null,
          rating: null,
          salesRank: 1421
        },
        {
          date: '2019-02-25',
          reviewCount: null,
          rating: null,
          salesRank: 1416
        },
        {
          date: '2019-02-26',
          reviewCount: null,
          rating: null,
          salesRank: 1758
        },
        {
          date: '2019-02-27',
          reviewCount: null,
          rating: null,
          salesRank: 1750
        },
        {
          date: '2019-02-28',
          reviewCount: null,
          rating: null,
          salesRank: 2125
        },
        {
          date: '2019-03-01',
          reviewCount: null,
          rating: null,
          salesRank: 2500
        },
        {
          date: '2019-03-02',
          reviewCount: null,
          rating: null,
          salesRank: 2608
        },
        {
          date: '2019-03-03',
          reviewCount: null,
          rating: null,
          salesRank: 2865
        },
        {
          date: '2019-03-04',
          reviewCount: null,
          rating: null,
          salesRank: 2846
        },
        {
          date: '2019-03-05',
          reviewCount: null,
          rating: null,
          salesRank: 2322
        },
        {
          date: '2019-03-06',
          reviewCount: null,
          rating: null,
          salesRank: 2229
        },
        {
          date: '2019-03-07',
          reviewCount: null,
          rating: null,
          salesRank: 44
        },
        {
          date: '2019-03-08',
          reviewCount: null,
          rating: null,
          salesRank: 148
        },
        {
          date: '2019-03-09',
          reviewCount: null,
          rating: null,
          salesRank: 412
        },
        {
          date: '2019-03-10',
          reviewCount: null,
          rating: null,
          salesRank: 758
        },
        {
          date: '2019-03-11',
          reviewCount: null,
          rating: null,
          salesRank: 1019
        },
        {
          date: '2019-03-12',
          reviewCount: null,
          rating: null,
          salesRank: 1316
        },
        {
          date: '2019-03-13',
          reviewCount: null,
          rating: null,
          salesRank: 1603
        },
        {
          date: '2019-03-14',
          reviewCount: null,
          rating: null,
          salesRank: 2043
        },
        {
          date: '2019-03-15',
          reviewCount: null,
          rating: null,
          salesRank: 2075
        },
        {
          date: '2019-03-16',
          reviewCount: null,
          rating: null,
          salesRank: 2543
        },
        {
          date: '2019-03-17',
          reviewCount: null,
          rating: null,
          salesRank: 3555
        },
        {
          date: '2019-03-18',
          reviewCount: null,
          rating: null,
          salesRank: 3645
        },
        {
          date: '2019-03-19',
          reviewCount: null,
          rating: null,
          salesRank: 3989
        },
        {
          date: '2019-03-20',
          reviewCount: null,
          rating: null,
          salesRank: 3848
        },
        {
          date: '2019-03-21',
          reviewCount: null,
          rating: null,
          salesRank: 3899
        },
        {
          date: '2019-03-22',
          reviewCount: null,
          rating: null,
          salesRank: 4180
        },
        {
          date: '2019-03-23',
          reviewCount: null,
          rating: null,
          salesRank: 3970
        },
        {
          date: '2019-03-24',
          reviewCount: null,
          rating: null,
          salesRank: 4055
        },
        {
          date: '2019-03-25',
          reviewCount: null,
          rating: null,
          salesRank: 9300
        },
        {
          date: '2019-03-26',
          reviewCount: null,
          rating: null,
          salesRank: 13878
        },
        {
          date: '2019-03-27',
          reviewCount: null,
          rating: null,
          salesRank: 17350
        },
        {
          date: '2019-03-28',
          reviewCount: null,
          rating: null,
          salesRank: 19457
        },
        {
          date: '2019-03-29',
          reviewCount: null,
          rating: null,
          salesRank: 8509
        },
        {
          date: '2019-03-30',
          reviewCount: null,
          rating: null,
          salesRank: 5164
        },
        {
          date: '2019-03-31',
          reviewCount: null,
          rating: null,
          salesRank: 6134
        },
        {
          date: '2019-04-01',
          reviewCount: null,
          rating: null,
          salesRank: 5259
        },
        {
          date: '2019-04-02',
          reviewCount: null,
          rating: null,
          salesRank: 6344
        },
        {
          date: '2019-04-03',
          reviewCount: null,
          rating: null,
          salesRank: 7193
        },
        {
          date: '2019-04-04',
          reviewCount: null,
          rating: null,
          salesRank: 6393
        },
        {
          date: '2019-04-05',
          reviewCount: null,
          rating: null,
          salesRank: 6359
        },
        {
          date: '2019-04-06',
          reviewCount: null,
          rating: null,
          salesRank: 6638
        },
        {
          date: '2019-04-07',
          reviewCount: null,
          rating: null,
          salesRank: 6281
        },
        {
          date: '2019-04-08',
          reviewCount: null,
          rating: null,
          salesRank: 7437
        },
        {
          date: '2019-04-09',
          reviewCount: null,
          rating: null,
          salesRank: 7059
        },
        {
          date: '2019-04-10',
          reviewCount: null,
          rating: null,
          salesRank: 7820
        },
        {
          date: '2019-04-11',
          reviewCount: null,
          rating: null,
          salesRank: 7045
        },
        {
          date: '2019-04-12',
          reviewCount: null,
          rating: null,
          salesRank: 6821
        },
        {
          date: '2019-04-13',
          reviewCount: null,
          rating: null,
          salesRank: 8079
        },
        {
          date: '2019-04-14',
          reviewCount: null,
          rating: null,
          salesRank: 6657
        },
        {
          date: '2019-04-15',
          reviewCount: null,
          rating: null,
          salesRank: 8020
        },
        {
          date: '2019-04-16',
          reviewCount: null,
          rating: null,
          salesRank: 8040
        },
        {
          date: '2019-04-17',
          reviewCount: null,
          rating: null,
          salesRank: 6923
        },
        {
          date: '2019-04-18',
          reviewCount: null,
          rating: null,
          salesRank: 8455
        },
        {
          date: '2019-04-19',
          reviewCount: null,
          rating: null,
          salesRank: 7015
        },
        {
          date: '2019-04-20',
          reviewCount: null,
          rating: null,
          salesRank: 8294
        },
        {
          date: '2019-04-21',
          reviewCount: null,
          rating: null,
          salesRank: 9189
        },
        {
          date: '2019-04-22',
          reviewCount: null,
          rating: null,
          salesRank: 9103
        },
        {
          date: '2019-04-23',
          reviewCount: null,
          rating: null,
          salesRank: 7655
        },
        {
          date: '2019-04-24',
          reviewCount: null,
          rating: null,
          salesRank: 8211
        },
        {
          date: '2019-04-25',
          reviewCount: null,
          rating: null,
          salesRank: 7577
        },
        {
          date: '2019-04-26',
          reviewCount: null,
          rating: null,
          salesRank: 9228
        },
        {
          date: '2019-04-27',
          reviewCount: null,
          rating: null,
          salesRank: 6520
        },
        {
          date: '2019-04-28',
          reviewCount: null,
          rating: null,
          salesRank: 10106
        },
        {
          date: '2019-04-29',
          reviewCount: null,
          rating: null,
          salesRank: 9722
        },
        {
          date: '2019-04-30',
          reviewCount: null,
          rating: null,
          salesRank: 7586
        },
        {
          date: '2019-05-01',
          reviewCount: null,
          rating: null,
          salesRank: 8169
        },
        {
          date: '2019-05-02',
          reviewCount: null,
          rating: null,
          salesRank: 8550
        },
        {
          date: '2019-05-03',
          reviewCount: null,
          rating: null,
          salesRank: 8725
        },
        {
          date: '2019-05-04',
          reviewCount: null,
          rating: null,
          salesRank: 12043
        },
        {
          date: '2019-05-05',
          reviewCount: null,
          rating: null,
          salesRank: 12243
        },
        {
          date: '2019-05-06',
          reviewCount: null,
          rating: null,
          salesRank: 13076
        },
        {
          date: '2019-05-07',
          reviewCount: null,
          rating: null,
          salesRank: 14960
        },
        {
          date: '2019-05-08',
          reviewCount: null,
          rating: null,
          salesRank: 12569
        },
        {
          date: '2019-05-09',
          reviewCount: null,
          rating: null,
          salesRank: 16971
        },
        {
          date: '2019-05-10',
          reviewCount: null,
          rating: null,
          salesRank: 12910
        },
        {
          date: '2019-05-11',
          reviewCount: null,
          rating: null,
          salesRank: 13479
        },
        {
          date: '2019-05-12',
          reviewCount: null,
          rating: null,
          salesRank: 16351
        },
        {
          date: '2019-05-13',
          reviewCount: null,
          rating: null,
          salesRank: 18417
        },
        {
          date: '2019-05-14',
          reviewCount: null,
          rating: null,
          salesRank: 12645
        },
        {
          date: '2019-05-15',
          reviewCount: null,
          rating: null,
          salesRank: 18310
        },
        {
          date: '2019-05-16',
          reviewCount: null,
          rating: null,
          salesRank: 9876
        },
        {
          date: '2019-05-17',
          reviewCount: null,
          rating: null,
          salesRank: 9161
        },
        {
          date: '2019-05-18',
          reviewCount: null,
          rating: null,
          salesRank: 9849
        },
        {
          date: '2019-05-19',
          reviewCount: null,
          rating: null,
          salesRank: 11629
        },
        {
          date: '2019-05-20',
          reviewCount: null,
          rating: null,
          salesRank: 9331
        },
        {
          date: '2019-05-21',
          reviewCount: null,
          rating: null,
          salesRank: 9847
        },
        {
          date: '2019-05-22',
          reviewCount: null,
          rating: null,
          salesRank: 9858
        },
        {
          date: '2019-05-23',
          reviewCount: null,
          rating: null,
          salesRank: 9908
        },
        {
          date: '2019-05-24',
          reviewCount: null,
          rating: null,
          salesRank: 11379
        },
        {
          date: '2019-05-25',
          reviewCount: null,
          rating: null,
          salesRank: 14369
        },
        {
          date: '2019-05-26',
          reviewCount: null,
          rating: null,
          salesRank: 9911
        },
        {
          date: '2019-05-27',
          reviewCount: null,
          rating: null,
          salesRank: 9526
        },
        {
          date: '2019-05-28',
          reviewCount: null,
          rating: null,
          salesRank: 8791
        },
        {
          date: '2019-05-29',
          reviewCount: null,
          rating: null,
          salesRank: 7893
        },
        {
          date: '2019-05-30',
          reviewCount: null,
          rating: null,
          salesRank: 8444
        },
        {
          date: '2019-05-31',
          reviewCount: null,
          rating: null,
          salesRank: 8536
        },
        {
          date: '2019-06-01',
          reviewCount: null,
          rating: null,
          salesRank: 10117
        },
        {
          date: '2019-06-02',
          reviewCount: null,
          rating: null,
          salesRank: 8823
        },
        {
          date: '2019-06-03',
          reviewCount: null,
          rating: null,
          salesRank: 10281
        },
        {
          date: '2019-06-04',
          reviewCount: null,
          rating: null,
          salesRank: 8813
        },
        {
          date: '2019-06-05',
          reviewCount: null,
          rating: null,
          salesRank: 11689
        },
        {
          date: '2019-06-06',
          reviewCount: null,
          rating: null,
          salesRank: 8867
        },
        {
          date: '2019-06-07',
          reviewCount: null,
          rating: null,
          salesRank: 11189
        },
        {
          date: '2019-06-08',
          reviewCount: null,
          rating: null,
          salesRank: 10552
        },
        {
          date: '2019-06-09',
          reviewCount: null,
          rating: null,
          salesRank: 13313
        },
        {
          date: '2019-06-10',
          reviewCount: null,
          rating: null,
          salesRank: 13093
        },
        {
          date: '2019-06-11',
          reviewCount: null,
          rating: null,
          salesRank: 11681
        },
        {
          date: '2019-06-12',
          reviewCount: null,
          rating: null,
          salesRank: 12896
        },
        {
          date: '2019-06-13',
          reviewCount: null,
          rating: null,
          salesRank: 13939
        },
        {
          date: '2019-06-14',
          reviewCount: null,
          rating: null,
          salesRank: 13909
        },
        {
          date: '2019-06-15',
          reviewCount: null,
          rating: null,
          salesRank: 15430
        },
        {
          date: '2019-06-16',
          reviewCount: null,
          rating: null,
          salesRank: 12440
        },
        {
          date: '2019-06-17',
          reviewCount: null,
          rating: null,
          salesRank: 11777
        },
        {
          date: '2019-06-18',
          reviewCount: null,
          rating: null,
          salesRank: 10502
        },
        {
          date: '2019-06-19',
          reviewCount: null,
          rating: null,
          salesRank: 10724
        },
        {
          date: '2019-06-20',
          reviewCount: null,
          rating: null,
          salesRank: 9787
        },
        {
          date: '2019-06-21',
          reviewCount: null,
          rating: null,
          salesRank: 11728
        },
        {
          date: '2019-06-22',
          reviewCount: null,
          rating: null,
          salesRank: 14628
        },
        {
          date: '2019-06-23',
          reviewCount: null,
          rating: null,
          salesRank: 11689
        },
        {
          date: '2019-06-24',
          reviewCount: null,
          rating: null,
          salesRank: 10719
        },
        {
          date: '2019-06-25',
          reviewCount: null,
          rating: null,
          salesRank: 16597
        },
        {
          date: '2019-06-26',
          reviewCount: null,
          rating: null,
          salesRank: 11260
        },
        {
          date: '2019-06-27',
          reviewCount: null,
          rating: null,
          salesRank: 12280
        },
        {
          date: '2019-06-28',
          reviewCount: null,
          rating: null,
          salesRank: 13412
        },
        {
          date: '2019-06-29',
          reviewCount: null,
          rating: null,
          salesRank: 10602
        },
        {
          date: '2019-06-30',
          reviewCount: null,
          rating: null,
          salesRank: 10938
        },
        {
          date: '2019-07-01',
          reviewCount: null,
          rating: null,
          salesRank: 12648
        },
        {
          date: '2019-07-02',
          reviewCount: null,
          rating: null,
          salesRank: 12936
        },
        {
          date: '2019-07-03',
          reviewCount: null,
          rating: null,
          salesRank: 14678
        },
        {
          date: '2019-07-04',
          reviewCount: null,
          rating: null,
          salesRank: 13516
        },
        {
          date: '2019-07-05',
          reviewCount: null,
          rating: null,
          salesRank: 17094
        },
        {
          date: '2019-07-06',
          reviewCount: null,
          rating: null,
          salesRank: 15130
        },
        {
          date: '2019-07-07',
          reviewCount: null,
          rating: null,
          salesRank: 11465
        },
        {
          date: '2019-07-08',
          reviewCount: null,
          rating: null,
          salesRank: 13913
        },
        {
          date: '2019-07-09',
          reviewCount: null,
          rating: null,
          salesRank: 13967
        },
        {
          date: '2019-07-10',
          reviewCount: null,
          rating: null,
          salesRank: 13310
        },
        {
          date: '2019-07-11',
          reviewCount: null,
          rating: null,
          salesRank: 12414
        },
        {
          date: '2019-07-12',
          reviewCount: null,
          rating: null,
          salesRank: 14165
        },
        {
          date: '2019-07-13',
          reviewCount: null,
          rating: null,
          salesRank: 20538
        },
        {
          date: '2019-07-14',
          reviewCount: null,
          rating: null,
          salesRank: 16648
        },
        {
          date: '2019-07-15',
          reviewCount: null,
          rating: null,
          salesRank: 4709
        },
        {
          date: '2019-07-16',
          reviewCount: null,
          rating: null,
          salesRank: 675
        },
        {
          date: '2019-07-17',
          reviewCount: null,
          rating: null,
          salesRank: 1826
        },
        {
          date: '2019-07-18',
          reviewCount: null,
          rating: null,
          salesRank: 2350
        },
        {
          date: '2019-07-19',
          reviewCount: null,
          rating: null,
          salesRank: 4626
        },
        {
          date: '2019-07-20',
          reviewCount: null,
          rating: null,
          salesRank: 5673
        },
        {
          date: '2019-07-21',
          reviewCount: null,
          rating: null,
          salesRank: 7204
        },
        {
          date: '2019-07-22',
          reviewCount: null,
          rating: null,
          salesRank: 8232
        },
        {
          date: '2019-07-23',
          reviewCount: null,
          rating: null,
          salesRank: 7255
        },
        {
          date: '2019-07-24',
          reviewCount: null,
          rating: null,
          salesRank: 7484
        },
        {
          date: '2019-07-25',
          reviewCount: null,
          rating: null,
          salesRank: 10737
        },
        {
          date: '2019-07-26',
          reviewCount: null,
          rating: null,
          salesRank: 12115
        },
        {
          date: '2019-07-27',
          reviewCount: null,
          rating: null,
          salesRank: 10417
        },
        {
          date: '2019-07-28',
          reviewCount: null,
          rating: null,
          salesRank: 11916
        },
        {
          date: '2019-07-29',
          reviewCount: null,
          rating: null,
          salesRank: 12991
        },
        {
          date: '2019-07-30',
          reviewCount: null,
          rating: null,
          salesRank: 13465
        },
        {
          date: '2019-07-31',
          reviewCount: null,
          rating: null,
          salesRank: 12728
        },
        {
          date: '2019-08-01',
          reviewCount: null,
          rating: null,
          salesRank: 11798
        },
        {
          date: '2019-08-02',
          reviewCount: null,
          rating: null,
          salesRank: 12168
        },
        {
          date: '2019-08-03',
          reviewCount: null,
          rating: null,
          salesRank: 12854
        },
        {
          date: '2019-08-04',
          reviewCount: null,
          rating: null,
          salesRank: 12857
        },
        {
          date: '2019-08-05',
          reviewCount: null,
          rating: null,
          salesRank: 11456
        },
        {
          date: '2019-08-06',
          reviewCount: null,
          rating: null,
          salesRank: 10746
        },
        {
          date: '2019-08-07',
          reviewCount: null,
          rating: null,
          salesRank: 14067
        },
        {
          date: '2019-08-08',
          reviewCount: null,
          rating: null,
          salesRank: 12551
        },
        {
          date: '2019-08-09',
          reviewCount: null,
          rating: null,
          salesRank: 13763
        },
        {
          date: '2019-08-10',
          reviewCount: null,
          rating: null,
          salesRank: 14412
        },
        {
          date: '2019-08-11',
          reviewCount: null,
          rating: null,
          salesRank: 13160
        },
        {
          date: '2019-08-12',
          reviewCount: null,
          rating: null,
          salesRank: 11785
        },
        {
          date: '2019-08-13',
          reviewCount: null,
          rating: null,
          salesRank: 11481
        },
        {
          date: '2019-08-14',
          reviewCount: null,
          rating: null,
          salesRank: 12029
        },
        {
          date: '2019-08-15',
          reviewCount: null,
          rating: null,
          salesRank: 13018
        },
        {
          date: '2019-08-16',
          reviewCount: null,
          rating: null,
          salesRank: 13212
        },
        {
          date: '2019-08-17',
          reviewCount: null,
          rating: null,
          salesRank: 12901
        },
        {
          date: '2019-08-18',
          reviewCount: null,
          rating: null,
          salesRank: 9972
        },
        {
          date: '2019-08-19',
          reviewCount: null,
          rating: null,
          salesRank: 12229
        },
        {
          date: '2019-08-20',
          reviewCount: null,
          rating: null,
          salesRank: 11427
        },
        {
          date: '2019-08-21',
          reviewCount: null,
          rating: null,
          salesRank: 9328
        },
        {
          date: '2019-08-22',
          reviewCount: null,
          rating: null,
          salesRank: 10739
        },
        {
          date: '2019-08-23',
          reviewCount: null,
          rating: null,
          salesRank: 12956
        },
        {
          date: '2019-08-24',
          reviewCount: null,
          rating: null,
          salesRank: 10106
        },
        {
          date: '2019-08-25',
          reviewCount: null,
          rating: null,
          salesRank: 11118
        },
        {
          date: '2019-08-26',
          reviewCount: null,
          rating: null,
          salesRank: 13037
        },
        {
          date: '2019-08-27',
          reviewCount: null,
          rating: null,
          salesRank: 12267
        },
        {
          date: '2019-08-28',
          reviewCount: null,
          rating: null,
          salesRank: 10945
        },
        {
          date: '2019-08-29',
          reviewCount: null,
          rating: null,
          salesRank: 12283
        },
        {
          date: '2019-08-30',
          reviewCount: null,
          rating: null,
          salesRank: 15131
        },
        {
          date: '2019-08-31',
          reviewCount: null,
          rating: null,
          salesRank: 12978
        },
        {
          date: '2019-09-01',
          reviewCount: null,
          rating: null,
          salesRank: 16444
        },
        {
          date: '2019-09-02',
          reviewCount: null,
          rating: null,
          salesRank: 15800
        },
        {
          date: '2019-09-03',
          reviewCount: null,
          rating: null,
          salesRank: 18658
        },
        {
          date: '2019-09-04',
          reviewCount: null,
          rating: null,
          salesRank: 13619
        },
        {
          date: '2019-09-05',
          reviewCount: null,
          rating: null,
          salesRank: 15997
        },
        {
          date: '2019-09-06',
          reviewCount: null,
          rating: null,
          salesRank: 11525
        },
        {
          date: '2019-09-07',
          reviewCount: null,
          rating: null,
          salesRank: 9996
        },
        {
          date: '2019-09-08',
          reviewCount: null,
          rating: null,
          salesRank: 12671
        },
        {
          date: '2019-09-09',
          reviewCount: null,
          rating: null,
          salesRank: 12551
        },
        {
          date: '2019-09-10',
          reviewCount: null,
          rating: null,
          salesRank: 11627
        },
        {
          date: '2019-09-11',
          reviewCount: null,
          rating: null,
          salesRank: 12935
        },
        {
          date: '2019-09-12',
          reviewCount: null,
          rating: null,
          salesRank: 12334
        },
        {
          date: '2019-09-13',
          reviewCount: null,
          rating: null,
          salesRank: 12379
        },
        {
          date: '2019-09-14',
          reviewCount: null,
          rating: null,
          salesRank: 16929
        },
        {
          date: '2019-09-15',
          reviewCount: null,
          rating: null,
          salesRank: 12710
        },
        {
          date: '2019-09-16',
          reviewCount: null,
          rating: null,
          salesRank: 10254
        },
        {
          date: '2019-09-17',
          reviewCount: null,
          rating: null,
          salesRank: 11047
        },
        {
          date: '2019-09-18',
          reviewCount: null,
          rating: null,
          salesRank: 10109
        },
        {
          date: '2019-09-19',
          reviewCount: null,
          rating: null,
          salesRank: 10481
        },
        {
          date: '2019-09-20',
          reviewCount: null,
          rating: null,
          salesRank: 11391
        },
        {
          date: '2019-09-21',
          reviewCount: null,
          rating: null,
          salesRank: 9474
        },
        {
          date: '2019-09-22',
          reviewCount: null,
          rating: null,
          salesRank: 9096
        },
        {
          date: '2019-09-23',
          reviewCount: null,
          rating: null,
          salesRank: 9117
        },
        {
          date: '2019-09-24',
          reviewCount: null,
          rating: null,
          salesRank: 8450
        },
        {
          date: '2019-09-25',
          reviewCount: null,
          rating: null,
          salesRank: 7961
        },
        {
          date: '2019-09-26',
          reviewCount: null,
          rating: null,
          salesRank: 8281
        },
        {
          date: '2019-09-27',
          reviewCount: null,
          rating: null,
          salesRank: 8499
        },
        {
          date: '2019-09-28',
          reviewCount: null,
          rating: null,
          salesRank: 8036
        },
        {
          date: '2019-09-29',
          reviewCount: null,
          rating: null,
          salesRank: 6708
        },
        {
          date: '2019-09-30',
          reviewCount: null,
          rating: null,
          salesRank: 8157
        },
        {
          date: '2019-10-01',
          reviewCount: null,
          rating: null,
          salesRank: 7145
        },
        {
          date: '2019-10-02',
          reviewCount: null,
          rating: null,
          salesRank: 7222
        },
        {
          date: '2019-10-03',
          reviewCount: null,
          rating: null,
          salesRank: 7762
        },
        {
          date: '2019-10-04',
          reviewCount: null,
          rating: null,
          salesRank: 7865
        },
        {
          date: '2019-10-05',
          reviewCount: null,
          rating: null,
          salesRank: 5533
        },
        {
          date: '2019-10-06',
          reviewCount: null,
          rating: null,
          salesRank: 2725
        },
        {
          date: '2019-10-07',
          reviewCount: null,
          rating: null,
          salesRank: 4319
        },
        {
          date: '2019-10-08',
          reviewCount: null,
          rating: null,
          salesRank: 4482
        },
        {
          date: '2019-10-09',
          reviewCount: null,
          rating: null,
          salesRank: 4266
        },
        {
          date: '2019-10-10',
          reviewCount: null,
          rating: null,
          salesRank: 4397
        },
        {
          date: '2019-10-11',
          reviewCount: null,
          rating: null,
          salesRank: 5279
        },
        {
          date: '2019-10-12',
          reviewCount: null,
          rating: null,
          salesRank: 4024
        },
        {
          date: '2019-10-13',
          reviewCount: null,
          rating: null,
          salesRank: 3946
        },
        {
          date: '2019-10-14',
          reviewCount: null,
          rating: null,
          salesRank: 4329
        },
        {
          date: '2019-10-15',
          reviewCount: null,
          rating: null,
          salesRank: 4023
        },
        {
          date: '2019-10-16',
          reviewCount: null,
          rating: null,
          salesRank: 3848
        },
        {
          date: '2019-10-17',
          reviewCount: null,
          rating: null,
          salesRank: 3682
        },
        {
          date: '2019-10-18',
          reviewCount: null,
          rating: null,
          salesRank: 3737
        },
        {
          date: '2019-10-19',
          reviewCount: null,
          rating: null,
          salesRank: 3066
        },
        {
          date: '2019-10-20',
          reviewCount: null,
          rating: null,
          salesRank: 3104
        },
        {
          date: '2019-10-21',
          reviewCount: null,
          rating: null,
          salesRank: 3081
        },
        {
          date: '2019-10-22',
          reviewCount: null,
          rating: null,
          salesRank: 3009
        },
        {
          date: '2019-10-23',
          reviewCount: null,
          rating: null,
          salesRank: 2740
        },
        {
          date: '2019-10-24',
          reviewCount: null,
          rating: null,
          salesRank: 3015
        },
        {
          date: '2019-10-25',
          reviewCount: null,
          rating: null,
          salesRank: 3459
        },
        {
          date: '2019-10-26',
          reviewCount: null,
          rating: null,
          salesRank: 1736
        },
        {
          date: '2019-10-27',
          reviewCount: null,
          rating: null,
          salesRank: 2434
        },
        {
          date: '2019-10-28',
          reviewCount: null,
          rating: null,
          salesRank: 3096
        },
        {
          date: '2019-10-29',
          reviewCount: null,
          rating: null,
          salesRank: 2761
        },
        {
          date: '2019-10-30',
          reviewCount: null,
          rating: null,
          salesRank: 2733
        },
        {
          date: '2019-10-31',
          reviewCount: null,
          rating: null,
          salesRank: 2853
        },
        {
          date: '2019-11-01',
          reviewCount: null,
          rating: null,
          salesRank: 1366
        },
        {
          date: '2019-11-02',
          reviewCount: null,
          rating: null,
          salesRank: 1673
        },
        {
          date: '2019-11-03',
          reviewCount: null,
          rating: null,
          salesRank: 1510
        },
        {
          date: '2019-11-04',
          reviewCount: null,
          rating: null,
          salesRank: 912
        },
        {
          date: '2019-11-05',
          reviewCount: null,
          rating: null,
          salesRank: 1014
        },
        {
          date: '2019-11-06',
          reviewCount: null,
          rating: null,
          salesRank: 1118
        },
        {
          date: '2019-11-07',
          reviewCount: null,
          rating: null,
          salesRank: 1067
        },
        {
          date: '2019-11-08',
          reviewCount: null,
          rating: null,
          salesRank: 1131
        },
        {
          date: '2019-11-09',
          reviewCount: null,
          rating: null,
          salesRank: 711
        },
        {
          date: '2019-11-10',
          reviewCount: null,
          rating: null,
          salesRank: 729
        },
        {
          date: '2019-11-11',
          reviewCount: null,
          rating: null,
          salesRank: 404
        },
        {
          date: '2019-11-12',
          reviewCount: null,
          rating: null,
          salesRank: 596
        },
        {
          date: '2019-11-13',
          reviewCount: null,
          rating: null,
          salesRank: 576
        },
        {
          date: '2019-11-14',
          reviewCount: null,
          rating: null,
          salesRank: 910
        },
        {
          date: '2019-11-15',
          reviewCount: null,
          rating: null,
          salesRank: 1300
        },
        {
          date: '2019-11-16',
          reviewCount: null,
          rating: null,
          salesRank: 1536
        },
        {
          date: '2019-11-17',
          reviewCount: null,
          rating: null,
          salesRank: 1381
        },
        {
          date: '2019-11-18',
          reviewCount: null,
          rating: null,
          salesRank: 1672
        },
        {
          date: '2019-11-19',
          reviewCount: null,
          rating: null,
          salesRank: 2963
        },
        {
          date: '2019-11-20',
          reviewCount: null,
          rating: null,
          salesRank: 3163
        },
        {
          date: '2019-11-21',
          reviewCount: null,
          rating: null,
          salesRank: 3693
        },
        {
          date: '2019-11-22',
          reviewCount: null,
          rating: null,
          salesRank: 4176
        },
        {
          date: '2019-11-23',
          reviewCount: null,
          rating: null,
          salesRank: 2508
        },
        {
          date: '2019-11-24',
          reviewCount: null,
          rating: null,
          salesRank: 2438
        },
        {
          date: '2019-11-25',
          reviewCount: null,
          rating: null,
          salesRank: 2293
        },
        {
          date: '2019-11-26',
          reviewCount: null,
          rating: null,
          salesRank: 1945
        },
        {
          date: '2019-11-27',
          reviewCount: null,
          rating: null,
          salesRank: 2043
        },
        {
          date: '2019-11-28',
          reviewCount: null,
          rating: null,
          salesRank: 1912
        },
        {
          date: '2019-11-29',
          reviewCount: null,
          rating: null,
          salesRank: 168
        },
        {
          date: '2019-11-30',
          reviewCount: null,
          rating: null,
          salesRank: 828
        },
        {
          date: '2019-12-01',
          reviewCount: null,
          rating: null,
          salesRank: 1858
        },
        {
          date: '2019-12-02',
          reviewCount: null,
          rating: null,
          salesRank: 359
        },
        {
          date: '2019-12-03',
          reviewCount: null,
          rating: null,
          salesRank: 941
        },
        {
          date: '2019-12-04',
          reviewCount: null,
          rating: null,
          salesRank: 1424
        },
        {
          date: '2019-12-05',
          reviewCount: null,
          rating: null,
          salesRank: 1777
        },
        {
          date: '2019-12-06',
          reviewCount: null,
          rating: null,
          salesRank: 2251
        },
        {
          date: '2019-12-07',
          reviewCount: null,
          rating: null,
          salesRank: 2200
        },
        {
          date: '2019-12-08',
          reviewCount: null,
          rating: null,
          salesRank: 2650
        },
        {
          date: '2019-12-09',
          reviewCount: null,
          rating: null,
          salesRank: 2218
        },
        {
          date: '2019-12-10',
          reviewCount: null,
          rating: null,
          salesRank: 572
        },
        {
          date: '2019-12-11',
          reviewCount: null,
          rating: null,
          salesRank: 1171
        },
        {
          date: '2019-12-12',
          reviewCount: null,
          rating: null,
          salesRank: 1823
        },
        {
          date: '2019-12-13',
          reviewCount: null,
          rating: null,
          salesRank: 2739
        },
        {
          date: '2019-12-14',
          reviewCount: null,
          rating: null,
          salesRank: 1772
        },
        {
          date: '2019-12-15',
          reviewCount: null,
          rating: null,
          salesRank: 2640
        },
        {
          date: '2019-12-16',
          reviewCount: null,
          rating: null,
          salesRank: 2764
        },
        {
          date: '2019-12-17',
          reviewCount: null,
          rating: null,
          salesRank: 1611
        },
        {
          date: '2019-12-18',
          reviewCount: null,
          rating: null,
          salesRank: 1339
        },
        {
          date: '2019-12-19',
          reviewCount: null,
          rating: null,
          salesRank: 1844
        },
        {
          date: '2019-12-20',
          reviewCount: null,
          rating: null,
          salesRank: 2267
        },
        {
          date: '2019-12-21',
          reviewCount: null,
          rating: null,
          salesRank: 2344
        },
        {
          date: '2019-12-22',
          reviewCount: null,
          rating: null,
          salesRank: 2534
        },
        {
          date: '2019-12-23',
          reviewCount: null,
          rating: null,
          salesRank: 2167
        },
        {
          date: '2019-12-24',
          reviewCount: null,
          rating: null,
          salesRank: 953
        },
        {
          date: '2019-12-25',
          reviewCount: null,
          rating: null,
          salesRank: 992
        },
        {
          date: '2019-12-26',
          reviewCount: null,
          rating: null,
          salesRank: 1126
        },
        {
          date: '2019-12-27',
          reviewCount: null,
          rating: null,
          salesRank: 408
        },
        {
          date: '2019-12-28',
          reviewCount: null,
          rating: null,
          salesRank: 369
        },
        {
          date: '2019-12-29',
          reviewCount: null,
          rating: null,
          salesRank: 577
        },
        {
          date: '2019-12-30',
          reviewCount: null,
          rating: null,
          salesRank: 1164
        },
        {
          date: '2019-12-31',
          reviewCount: null,
          rating: null,
          salesRank: 1926
        },
        {
          date: '2020-01-01',
          reviewCount: null,
          rating: null,
          salesRank: 2139
        },
        {
          date: '2020-01-02',
          reviewCount: null,
          rating: null,
          salesRank: 2185
        },
        {
          date: '2020-01-03',
          reviewCount: null,
          rating: null,
          salesRank: 2123
        },
        {
          date: '2020-01-04',
          reviewCount: null,
          rating: null,
          salesRank: 1966
        },
        {
          date: '2020-01-05',
          reviewCount: null,
          rating: null,
          salesRank: 2240
        },
        {
          date: '2020-01-06',
          reviewCount: null,
          rating: null,
          salesRank: 2148
        },
        {
          date: '2020-01-07',
          reviewCount: null,
          rating: null,
          salesRank: 1861
        },
        {
          date: '2020-01-08',
          reviewCount: null,
          rating: null,
          salesRank: 1996
        },
        {
          date: '2020-01-09',
          reviewCount: null,
          rating: null,
          salesRank: 1701
        },
        {
          date: '2020-01-10',
          reviewCount: null,
          rating: null,
          salesRank: 1633
        },
        {
          date: '2020-01-11',
          reviewCount: null,
          rating: null,
          salesRank: 1912
        },
        {
          date: '2020-01-12',
          reviewCount: null,
          rating: null,
          salesRank: 2059
        },
        {
          date: '2020-01-13',
          reviewCount: null,
          rating: null,
          salesRank: 1811
        },
        {
          date: '2020-01-14',
          reviewCount: null,
          rating: null,
          salesRank: 1956
        },
        {
          date: '2020-01-15',
          reviewCount: null,
          rating: null,
          salesRank: 2294
        },
        {
          date: '2020-01-16',
          reviewCount: null,
          rating: null,
          salesRank: 2388
        },
        {
          date: '2020-01-17',
          reviewCount: null,
          rating: null,
          salesRank: 2466
        },
        {
          date: '2020-01-18',
          reviewCount: null,
          rating: null,
          salesRank: 2365
        },
        {
          date: '2020-01-19',
          reviewCount: null,
          rating: null,
          salesRank: 2291
        },
        {
          date: '2020-01-20',
          reviewCount: null,
          rating: null,
          salesRank: 2061
        },
        {
          date: '2020-01-21',
          reviewCount: null,
          rating: null,
          salesRank: 1700
        },
        {
          date: '2020-01-22',
          reviewCount: null,
          rating: null,
          salesRank: 1819
        },
        {
          date: '2020-01-23',
          reviewCount: null,
          rating: null,
          salesRank: 1595
        },
        {
          date: '2020-01-24',
          reviewCount: null,
          rating: null,
          salesRank: 926
        },
        {
          date: '2020-01-25',
          reviewCount: null,
          rating: null,
          salesRank: 1406
        },
        {
          date: '2020-01-26',
          reviewCount: null,
          rating: null,
          salesRank: 1627
        },
        {
          date: '2020-01-27',
          reviewCount: null,
          rating: null,
          salesRank: 1849
        },
        {
          date: '2020-01-28',
          reviewCount: null,
          rating: null,
          salesRank: 2125
        },
        {
          date: '2020-01-29',
          reviewCount: null,
          rating: null,
          salesRank: 1762
        },
        {
          date: '2020-01-30',
          reviewCount: null,
          rating: null,
          salesRank: 2279
        },
        {
          date: '2020-01-31',
          reviewCount: null,
          rating: null,
          salesRank: 2473
        },
        {
          date: '2020-02-01',
          reviewCount: null,
          rating: null,
          salesRank: 2452
        },
        {
          date: '2020-02-02',
          reviewCount: null,
          rating: null,
          salesRank: 2442
        },
        {
          date: '2020-02-03',
          reviewCount: null,
          rating: null,
          salesRank: 2422
        },
        {
          date: '2020-02-04',
          reviewCount: null,
          rating: null,
          salesRank: 2147
        },
        {
          date: '2020-02-05',
          reviewCount: null,
          rating: null,
          salesRank: 2596
        },
        {
          date: '2020-02-06',
          reviewCount: null,
          rating: null,
          salesRank: 2416
        },
        {
          date: '2020-02-07',
          reviewCount: null,
          rating: null,
          salesRank: 2401
        },
        {
          date: '2020-02-08',
          reviewCount: null,
          rating: null,
          salesRank: 2134
        },
        {
          date: '2020-02-09',
          reviewCount: null,
          rating: null,
          salesRank: 2111
        },
        {
          date: '2020-02-10',
          reviewCount: null,
          rating: null,
          salesRank: 2010
        },
        {
          date: '2020-02-11',
          reviewCount: null,
          rating: null,
          salesRank: 2174
        },
        {
          date: '2020-02-12',
          reviewCount: null,
          rating: null,
          salesRank: 1991
        },
        {
          date: '2020-02-13',
          reviewCount: null,
          rating: null,
          salesRank: 961
        },
        {
          date: '2020-02-14',
          reviewCount: null,
          rating: null,
          salesRank: 2079
        },
        {
          date: '2020-02-15',
          reviewCount: null,
          rating: null,
          salesRank: 2463
        },
        {
          date: '2020-02-16',
          reviewCount: null,
          rating: null,
          salesRank: 2813
        },
        {
          date: '2020-02-17',
          reviewCount: null,
          rating: null,
          salesRank: 3250
        },
        {
          date: '2020-02-18',
          reviewCount: null,
          rating: null,
          salesRank: 3021
        },
        {
          date: '2020-02-19',
          reviewCount: null,
          rating: null,
          salesRank: 2734
        },
        {
          date: '2020-02-20',
          reviewCount: null,
          rating: null,
          salesRank: 3072
        },
        {
          date: '2020-02-21',
          reviewCount: null,
          rating: null,
          salesRank: 2773
        },
        {
          date: '2020-02-22',
          reviewCount: null,
          rating: null,
          salesRank: 3549
        },
        {
          date: '2020-02-23',
          reviewCount: null,
          rating: null,
          salesRank: 4720
        },
        {
          date: '2020-02-24',
          reviewCount: null,
          rating: null,
          salesRank: 4343
        },
        {
          date: '2020-02-25',
          reviewCount: null,
          rating: null,
          salesRank: 4320
        },
        {
          date: '2020-02-26',
          reviewCount: null,
          rating: null,
          salesRank: 4898
        },
        {
          date: '2020-02-27',
          reviewCount: null,
          rating: null,
          salesRank: 4981
        },
        {
          date: '2020-02-28',
          reviewCount: null,
          rating: null,
          salesRank: 2890
        },
        {
          date: '2020-02-29',
          reviewCount: null,
          rating: null,
          salesRank: 4549
        },
        {
          date: '2020-03-01',
          reviewCount: null,
          rating: null,
          salesRank: 4790
        },
        {
          date: '2020-03-02',
          reviewCount: null,
          rating: null,
          salesRank: 4400
        },
        {
          date: '2020-03-03',
          reviewCount: null,
          rating: null,
          salesRank: 5096
        },
        {
          date: '2020-03-04',
          reviewCount: null,
          rating: null,
          salesRank: 4508
        },
        {
          date: '2020-03-05',
          reviewCount: null,
          rating: null,
          salesRank: 5937
        },
        {
          date: '2020-03-06',
          reviewCount: null,
          rating: null,
          salesRank: 6586
        },
        {
          date: '2020-03-07',
          reviewCount: null,
          rating: null,
          salesRank: 4985
        },
        {
          date: '2020-03-08',
          reviewCount: null,
          rating: null,
          salesRank: 4503
        },
        {
          date: '2020-03-09',
          reviewCount: null,
          rating: null,
          salesRank: 2378
        },
        {
          date: '2020-03-10',
          reviewCount: null,
          rating: null,
          salesRank: 3289
        },
        {
          date: '2020-03-11',
          reviewCount: null,
          rating: null,
          salesRank: 3757
        },
        {
          date: '2020-03-12',
          reviewCount: null,
          rating: null,
          salesRank: 3756
        },
        {
          date: '2020-03-13',
          reviewCount: null,
          rating: null,
          salesRank: 2589
        },
        {
          date: '2020-03-14',
          reviewCount: null,
          rating: null,
          salesRank: 1392
        },
        {
          date: '2020-03-15',
          reviewCount: null,
          rating: null,
          salesRank: 1332
        },
        {
          date: '2020-03-16',
          reviewCount: null,
          rating: null,
          salesRank: 1049
        },
        {
          date: '2020-03-17',
          reviewCount: null,
          rating: null,
          salesRank: 1152
        },
        {
          date: '2020-03-18',
          reviewCount: null,
          rating: null,
          salesRank: 727
        },
        {
          date: '2020-03-19',
          reviewCount: null,
          rating: null,
          salesRank: 868
        },
        {
          date: '2020-03-20',
          reviewCount: null,
          rating: null,
          salesRank: 899
        },
        {
          date: '2020-03-21',
          reviewCount: null,
          rating: null,
          salesRank: 1085
        },
        {
          date: '2020-03-22',
          reviewCount: null,
          rating: null,
          salesRank: 759
        },
        {
          date: '2020-03-23',
          reviewCount: null,
          rating: null,
          salesRank: 513
        },
        {
          date: '2020-03-24',
          reviewCount: null,
          rating: null,
          salesRank: 449
        },
        {
          date: '2020-03-25',
          reviewCount: null,
          rating: null,
          salesRank: 502
        },
        {
          date: '2020-03-26',
          reviewCount: null,
          rating: null,
          salesRank: 461
        },
        {
          date: '2020-03-27',
          reviewCount: null,
          rating: null,
          salesRank: 411
        },
        {
          date: '2020-03-28',
          reviewCount: null,
          rating: null,
          salesRank: 1116
        },
        {
          date: '2020-03-29',
          reviewCount: null,
          rating: null,
          salesRank: 842
        },
        {
          date: '2020-03-30',
          reviewCount: null,
          rating: null,
          salesRank: 792
        },
        {
          date: '2020-03-31',
          reviewCount: null,
          rating: null,
          salesRank: 660
        },
        {
          date: '2020-04-01',
          reviewCount: null,
          rating: null,
          salesRank: 582
        },
        {
          date: '2020-04-02',
          reviewCount: null,
          rating: null,
          salesRank: 649
        },
        {
          date: '2020-04-03',
          reviewCount: null,
          rating: null,
          salesRank: 700
        },
        {
          date: '2020-04-04',
          reviewCount: null,
          rating: null,
          salesRank: 326
        },
        {
          date: '2020-04-05',
          reviewCount: null,
          rating: null,
          salesRank: 819
        },
        {
          date: '2020-04-06',
          reviewCount: null,
          rating: null,
          salesRank: 1359
        },
        {
          date: '2020-04-07',
          reviewCount: null,
          rating: null,
          salesRank: 3390
        },
        {
          date: '2020-04-08',
          reviewCount: null,
          rating: null,
          salesRank: 3974
        },
        {
          date: '2020-04-09',
          reviewCount: null,
          rating: null,
          salesRank: 4155
        },
        {
          date: '2020-04-10',
          reviewCount: null,
          rating: null,
          salesRank: 5948
        },
        {
          date: '2020-04-11',
          reviewCount: null,
          rating: null,
          salesRank: 6765
        },
        {
          date: '2020-04-12',
          reviewCount: null,
          rating: null,
          salesRank: 7670
        },
        {
          date: '2020-04-13',
          reviewCount: null,
          rating: null,
          salesRank: 9753
        },
        {
          date: '2020-04-14',
          reviewCount: null,
          rating: null,
          salesRank: 15233
        },
        {
          date: '2020-04-15',
          reviewCount: null,
          rating: null,
          salesRank: 15798
        },
        {
          date: '2020-04-16',
          reviewCount: null,
          rating: null,
          salesRank: 15503
        },
        {
          date: '2020-04-17',
          reviewCount: null,
          rating: null,
          salesRank: 11927
        },
        {
          date: '2020-04-18',
          reviewCount: null,
          rating: null,
          salesRank: 17436
        },
        {
          date: '2020-04-19',
          reviewCount: null,
          rating: null,
          salesRank: 15001
        },
        {
          date: '2020-04-20',
          reviewCount: null,
          rating: null,
          salesRank: 17071
        },
        {
          date: '2020-04-21',
          reviewCount: null,
          rating: null,
          salesRank: 17736
        },
        {
          date: '2020-04-22',
          reviewCount: null,
          rating: null,
          salesRank: 17910
        },
        {
          date: '2020-04-23',
          reviewCount: null,
          rating: null,
          salesRank: 24462
        },
        {
          date: '2020-04-24',
          reviewCount: null,
          rating: null,
          salesRank: 22706
        },
        {
          date: '2020-04-25',
          reviewCount: null,
          rating: null,
          salesRank: 27821
        },
        {
          date: '2020-04-26',
          reviewCount: null,
          rating: null,
          salesRank: 25530
        },
        {
          date: '2020-04-27',
          reviewCount: null,
          rating: null,
          salesRank: 19908
        },
        {
          date: '2020-04-28',
          reviewCount: null,
          rating: null,
          salesRank: 22092
        },
        {
          date: '2020-04-29',
          reviewCount: null,
          rating: null,
          salesRank: 19213
        },
        {
          date: '2020-04-30',
          reviewCount: null,
          rating: null,
          salesRank: 21544
        },
        {
          date: '2020-05-01',
          reviewCount: null,
          rating: null,
          salesRank: 23382
        },
        {
          date: '2020-05-02',
          reviewCount: null,
          rating: null,
          salesRank: 28100
        },
        {
          date: '2020-05-03',
          reviewCount: null,
          rating: null,
          salesRank: 28221
        },
        {
          date: '2020-05-04',
          reviewCount: null,
          rating: null,
          salesRank: 23276
        },
        {
          date: '2020-05-05',
          reviewCount: null,
          rating: null,
          salesRank: 21430
        },
        {
          date: '2020-05-06',
          reviewCount: null,
          rating: null,
          salesRank: 23373
        },
        {
          date: '2020-05-07',
          reviewCount: null,
          rating: null,
          salesRank: 23028
        },
        {
          date: '2020-05-08',
          reviewCount: null,
          rating: null,
          salesRank: 21983
        },
        {
          date: '2020-05-09',
          reviewCount: null,
          rating: null,
          salesRank: 17260
        },
        {
          date: '2020-05-10',
          reviewCount: null,
          rating: null,
          salesRank: 16033
        },
        {
          date: '2020-05-11',
          reviewCount: null,
          rating: null,
          salesRank: 18210
        },
        {
          date: '2020-05-12',
          reviewCount: null,
          rating: null,
          salesRank: 16083
        },
        {
          date: '2020-05-13',
          reviewCount: null,
          rating: null,
          salesRank: 15948
        },
        {
          date: '2020-05-14',
          reviewCount: null,
          rating: null,
          salesRank: 17600
        },
        {
          date: '2020-05-15',
          reviewCount: null,
          rating: null,
          salesRank: 15134
        },
        {
          date: '2020-05-16',
          reviewCount: null,
          rating: null,
          salesRank: 17786
        },
        {
          date: '2020-05-17',
          reviewCount: null,
          rating: null,
          salesRank: 15288
        },
        {
          date: '2020-05-18',
          reviewCount: null,
          rating: null,
          salesRank: 15942
        },
        {
          date: '2020-05-19',
          reviewCount: null,
          rating: null,
          salesRank: 15870
        },
        {
          date: '2020-05-20',
          reviewCount: null,
          rating: null,
          salesRank: 15399
        },
        {
          date: '2020-05-21',
          reviewCount: null,
          rating: null,
          salesRank: 11366
        },
        {
          date: '2020-05-22',
          reviewCount: null,
          rating: null,
          salesRank: 11430
        },
        {
          date: '2020-05-23',
          reviewCount: null,
          rating: null,
          salesRank: 11378
        },
        {
          date: '2020-05-24',
          reviewCount: null,
          rating: null,
          salesRank: 17425
        },
        {
          date: '2020-05-25',
          reviewCount: null,
          rating: null,
          salesRank: 16990
        },
        {
          date: '2020-05-26',
          reviewCount: null,
          rating: null,
          salesRank: 17370
        },
        {
          date: '2020-05-27',
          reviewCount: null,
          rating: null,
          salesRank: 16643
        },
        {
          date: '2020-05-28',
          reviewCount: null,
          rating: null,
          salesRank: 13123
        },
        {
          date: '2020-05-29',
          reviewCount: null,
          rating: null,
          salesRank: 15499
        },
        {
          date: '2020-05-30',
          reviewCount: null,
          rating: null,
          salesRank: 16295
        },
        {
          date: '2020-05-31',
          reviewCount: null,
          rating: null,
          salesRank: 15567
        },
        {
          date: '2020-06-01',
          reviewCount: null,
          rating: null,
          salesRank: 14369
        },
        {
          date: '2020-06-02',
          reviewCount: null,
          rating: null,
          salesRank: 10802
        },
        {
          date: '2020-06-03',
          reviewCount: null,
          rating: null,
          salesRank: 12779
        },
        {
          date: '2020-06-04',
          reviewCount: null,
          rating: null,
          salesRank: 13212
        },
        {
          date: '2020-06-05',
          reviewCount: null,
          rating: null,
          salesRank: 16914
        },
        {
          date: '2020-06-06',
          reviewCount: null,
          rating: null,
          salesRank: 15259
        },
        {
          date: '2020-06-07',
          reviewCount: null,
          rating: null,
          salesRank: 17709
        },
        {
          date: '2020-06-08',
          reviewCount: null,
          rating: null,
          salesRank: 12614
        },
        {
          date: '2020-06-09',
          reviewCount: null,
          rating: null,
          salesRank: 13568
        },
        {
          date: '2020-06-10',
          reviewCount: null,
          rating: null,
          salesRank: 16045
        },
        {
          date: '2020-06-11',
          reviewCount: null,
          rating: null,
          salesRank: 14805
        },
        {
          date: '2020-06-12',
          reviewCount: null,
          rating: null,
          salesRank: 13243
        },
        {
          date: '2020-06-13',
          reviewCount: null,
          rating: null,
          salesRank: 13724
        },
        {
          date: '2020-06-14',
          reviewCount: null,
          rating: null,
          salesRank: 16439
        },
        {
          date: '2020-06-15',
          reviewCount: null,
          rating: null,
          salesRank: 13797
        },
        {
          date: '2020-06-16',
          reviewCount: null,
          rating: null,
          salesRank: 17332
        },
        {
          date: '2020-06-17',
          reviewCount: null,
          rating: null,
          salesRank: 15831
        },
        {
          date: '2020-06-18',
          reviewCount: null,
          rating: null,
          salesRank: 13958
        },
        {
          date: '2020-06-19',
          reviewCount: null,
          rating: null,
          salesRank: 8175
        },
        {
          date: '2020-06-20',
          reviewCount: null,
          rating: null,
          salesRank: 13167
        },
        {
          date: '2020-06-21',
          reviewCount: null,
          rating: null,
          salesRank: 15645
        },
        {
          date: '2020-06-22',
          reviewCount: null,
          rating: null,
          salesRank: 16791
        },
        {
          date: '2020-06-23',
          reviewCount: null,
          rating: null,
          salesRank: 18478
        },
        {
          date: '2020-06-24',
          reviewCount: null,
          rating: null,
          salesRank: 19810
        },
        {
          date: '2020-06-25',
          reviewCount: null,
          rating: null,
          salesRank: 18280
        },
        {
          date: '2020-06-26',
          reviewCount: null,
          rating: null,
          salesRank: 18692
        },
        {
          date: '2020-06-27',
          reviewCount: null,
          rating: null,
          salesRank: 17447
        },
        {
          date: '2020-06-28',
          reviewCount: null,
          rating: null,
          salesRank: 20632
        },
        {
          date: '2020-06-29',
          reviewCount: null,
          rating: null,
          salesRank: 17407
        },
        {
          date: '2020-06-30',
          reviewCount: null,
          rating: null,
          salesRank: 13871
        },
        {
          date: '2020-07-01',
          reviewCount: null,
          rating: null,
          salesRank: 14168
        },
        {
          date: '2020-07-02',
          reviewCount: null,
          rating: null,
          salesRank: 18260
        },
        {
          date: '2020-07-03',
          reviewCount: null,
          rating: null,
          salesRank: 15343
        },
        {
          date: '2020-07-04',
          reviewCount: null,
          rating: null,
          salesRank: 14108
        },
        {
          date: '2020-07-05',
          reviewCount: null,
          rating: null,
          salesRank: 14166
        },
        {
          date: '2020-07-06',
          reviewCount: null,
          rating: null,
          salesRank: 13512
        },
        {
          date: '2020-07-07',
          reviewCount: null,
          rating: null,
          salesRank: 15063
        },
        {
          date: '2020-07-08',
          reviewCount: null,
          rating: null,
          salesRank: 13880
        },
        {
          date: '2020-07-09',
          reviewCount: null,
          rating: null,
          salesRank: 6831
        },
        {
          date: '2020-07-10',
          reviewCount: null,
          rating: null,
          salesRank: 14550
        },
        {
          date: '2020-07-11',
          reviewCount: null,
          rating: null,
          salesRank: 18948
        },
        {
          date: '2020-07-12',
          reviewCount: null,
          rating: null,
          salesRank: 19393
        },
        {
          date: '2020-07-13',
          reviewCount: null,
          rating: null,
          salesRank: 14665
        },
        {
          date: '2020-07-14',
          reviewCount: null,
          rating: null,
          salesRank: 14062
        },
        {
          date: '2020-07-15',
          reviewCount: null,
          rating: null,
          salesRank: 14173
        },
        {
          date: '2020-07-16',
          reviewCount: null,
          rating: null,
          salesRank: 17953
        },
        {
          date: '2020-07-17',
          reviewCount: null,
          rating: null,
          salesRank: 16447
        },
        {
          date: '2020-07-18',
          reviewCount: null,
          rating: null,
          salesRank: 20110
        },
        {
          date: '2020-07-19',
          reviewCount: null,
          rating: null,
          salesRank: 19840
        },
        {
          date: '2020-07-20',
          reviewCount: null,
          rating: null,
          salesRank: 18552
        },
        {
          date: '2020-07-21',
          reviewCount: null,
          rating: null,
          salesRank: 16999
        },
        {
          date: '2020-07-22',
          reviewCount: null,
          rating: null,
          salesRank: 15393
        },
        {
          date: '2020-07-23',
          reviewCount: null,
          rating: null,
          salesRank: 12268
        },
        {
          date: '2020-07-24',
          reviewCount: null,
          rating: null,
          salesRank: 16177
        },
        {
          date: '2020-07-25',
          reviewCount: null,
          rating: null,
          salesRank: 18641
        },
        {
          date: '2020-07-26',
          reviewCount: null,
          rating: null,
          salesRank: 19838
        },
        {
          date: '2020-07-27',
          reviewCount: null,
          rating: null,
          salesRank: 20847
        },
        {
          date: '2020-07-28',
          reviewCount: null,
          rating: null,
          salesRank: 14296
        },
        {
          date: '2020-07-29',
          reviewCount: null,
          rating: null,
          salesRank: 19183
        },
        {
          date: '2020-07-30',
          reviewCount: null,
          rating: null,
          salesRank: 22218
        },
        {
          date: '2020-07-31',
          reviewCount: null,
          rating: null,
          salesRank: 21562
        },
        {
          date: '2020-08-01',
          reviewCount: null,
          rating: null,
          salesRank: 18128
        },
        {
          date: '2020-08-02',
          reviewCount: null,
          rating: null,
          salesRank: 22938
        },
        {
          date: '2020-08-03',
          reviewCount: null,
          rating: null,
          salesRank: 26599
        },
        {
          date: '2020-08-04',
          reviewCount: null,
          rating: null,
          salesRank: 22708
        },
        {
          date: '2020-08-05',
          reviewCount: null,
          rating: null,
          salesRank: 21481
        },
        {
          date: '2020-08-06',
          reviewCount: null,
          rating: null,
          salesRank: 18604
        },
        {
          date: '2020-08-07',
          reviewCount: null,
          rating: null,
          salesRank: 15363
        },
        {
          date: '2020-08-08',
          reviewCount: null,
          rating: null,
          salesRank: 22340
        },
        {
          date: '2020-08-09',
          reviewCount: null,
          rating: null,
          salesRank: 18161
        },
        {
          date: '2020-08-12',
          reviewCount: null,
          rating: null,
          salesRank: 26684
        },
        {
          date: '2020-08-13',
          reviewCount: null,
          rating: null,
          salesRank: 21000
        },
        {
          date: '2020-08-14',
          reviewCount: null,
          rating: null,
          salesRank: 20688
        },
        {
          date: '2020-08-15',
          reviewCount: null,
          rating: null,
          salesRank: 19528
        },
        {
          date: '2020-08-16',
          reviewCount: null,
          rating: null,
          salesRank: 21653
        },
        {
          date: '2020-08-17',
          reviewCount: null,
          rating: null,
          salesRank: 22345
        },
        {
          date: '2020-08-18',
          reviewCount: null,
          rating: null,
          salesRank: 21327
        },
        {
          date: '2020-08-19',
          reviewCount: null,
          rating: null,
          salesRank: 19958
        },
        {
          date: '2020-08-20',
          reviewCount: null,
          rating: null,
          salesRank: 24336
        },
        {
          date: '2020-08-21',
          reviewCount: null,
          rating: null,
          salesRank: 22317
        },
        {
          date: '2020-08-22',
          reviewCount: null,
          rating: null,
          salesRank: 15846
        },
        {
          date: '2020-08-23',
          reviewCount: null,
          rating: null,
          salesRank: 17889
        },
        {
          date: '2020-08-24',
          reviewCount: null,
          rating: null,
          salesRank: 20684
        },
        {
          date: '2020-08-25',
          reviewCount: null,
          rating: null,
          salesRank: 15736
        },
        {
          date: '2020-08-26',
          reviewCount: null,
          rating: null,
          salesRank: 15647
        },
        {
          date: '2020-08-27',
          reviewCount: null,
          rating: null,
          salesRank: 8654
        },
        {
          date: '2020-08-28',
          reviewCount: null,
          rating: null,
          salesRank: 12031
        },
        {
          date: '2020-08-29',
          reviewCount: null,
          rating: null,
          salesRank: 15711
        },
        {
          date: '2020-08-30',
          reviewCount: null,
          rating: null,
          salesRank: 17289
        },
        {
          date: '2020-08-31',
          reviewCount: null,
          rating: null,
          salesRank: 18554
        },
        {
          date: '2020-09-01',
          reviewCount: null,
          rating: null,
          salesRank: 15864
        },
        {
          date: '2020-09-02',
          reviewCount: null,
          rating: null,
          salesRank: 15135
        },
        {
          date: '2020-09-03',
          reviewCount: null,
          rating: null,
          salesRank: 18613
        },
        {
          date: '2020-09-04',
          reviewCount: null,
          rating: null,
          salesRank: 18686
        },
        {
          date: '2020-09-05',
          reviewCount: null,
          rating: null,
          salesRank: 19311
        },
        {
          date: '2020-09-06',
          reviewCount: null,
          rating: null,
          salesRank: 19864
        },
        {
          date: '2020-09-07',
          reviewCount: null,
          rating: null,
          salesRank: 17903
        },
        {
          date: '2020-09-08',
          reviewCount: null,
          rating: null,
          salesRank: 16362
        },
        {
          date: '2020-09-09',
          reviewCount: null,
          rating: null,
          salesRank: 11709
        },
        {
          date: '2020-09-10',
          reviewCount: null,
          rating: null,
          salesRank: 13599
        },
        {
          date: '2020-09-11',
          reviewCount: null,
          rating: null,
          salesRank: 13968
        },
        {
          date: '2020-09-12',
          reviewCount: null,
          rating: null,
          salesRank: 14095
        },
        {
          date: '2020-09-13',
          reviewCount: null,
          rating: null,
          salesRank: 16270
        },
        {
          date: '2020-09-14',
          reviewCount: null,
          rating: null,
          salesRank: 16989
        },
        {
          date: '2020-09-15',
          reviewCount: null,
          rating: null,
          salesRank: 13447
        },
        {
          date: '2020-09-16',
          reviewCount: null,
          rating: null,
          salesRank: 13107
        },
        {
          date: '2020-09-17',
          reviewCount: null,
          rating: null,
          salesRank: 11590
        },
        {
          date: '2020-09-18',
          reviewCount: null,
          rating: null,
          salesRank: 9248
        },
        {
          date: '2020-09-19',
          reviewCount: null,
          rating: null,
          salesRank: 12266
        },
        {
          date: '2020-09-20',
          reviewCount: null,
          rating: null,
          salesRank: 10715
        },
        {
          date: '2020-09-21',
          reviewCount: null,
          rating: null,
          salesRank: 5420
        },
        {
          date: '2020-09-22',
          reviewCount: null,
          rating: null,
          salesRank: 6854
        },
        {
          date: '2020-09-23',
          reviewCount: null,
          rating: null,
          salesRank: 7006
        },
        {
          date: '2020-09-24',
          reviewCount: null,
          rating: null,
          salesRank: 9532
        },
        {
          date: '2020-09-25',
          reviewCount: null,
          rating: null,
          salesRank: 9176
        },
        {
          date: '2020-09-26',
          reviewCount: null,
          rating: null,
          salesRank: 12861
        },
        {
          date: '2020-09-27',
          reviewCount: null,
          rating: null,
          salesRank: 12791
        },
        {
          date: '2020-09-28',
          reviewCount: null,
          rating: null,
          salesRank: 13061
        },
        {
          date: '2020-09-29',
          reviewCount: null,
          rating: null,
          salesRank: 12336
        },
        {
          date: '2020-09-30',
          reviewCount: null,
          rating: null,
          salesRank: 12215
        },
        {
          date: '2020-10-01',
          reviewCount: null,
          rating: null,
          salesRank: 9873
        },
        {
          date: '2020-10-02',
          reviewCount: null,
          rating: null,
          salesRank: 7454
        },
        {
          date: '2020-10-03',
          reviewCount: null,
          rating: null,
          salesRank: 6328
        },
        {
          date: '2020-10-04',
          reviewCount: null,
          rating: null,
          salesRank: 7743
        },
        {
          date: '2020-10-05',
          reviewCount: null,
          rating: null,
          salesRank: 6941
        },
        {
          date: '2020-10-06',
          reviewCount: null,
          rating: null,
          salesRank: 6276
        },
        {
          date: '2020-10-07',
          reviewCount: null,
          rating: null,
          salesRank: 5831
        },
        {
          date: '2020-10-08',
          reviewCount: null,
          rating: null,
          salesRank: 7437
        },
        {
          date: '2020-10-09',
          reviewCount: null,
          rating: null,
          salesRank: 7497
        },
        {
          date: '2020-10-10',
          reviewCount: null,
          rating: null,
          salesRank: 8126
        },
        {
          date: '2020-10-11',
          reviewCount: null,
          rating: null,
          salesRank: 9794
        },
        {
          date: '2020-10-12',
          reviewCount: null,
          rating: null,
          salesRank: 9632
        },
        {
          date: '2020-10-13',
          reviewCount: null,
          rating: null,
          salesRank: 3217
        },
        {
          date: '2020-10-14',
          reviewCount: null,
          rating: null,
          salesRank: 220
        },
        {
          date: '2020-10-15',
          reviewCount: null,
          rating: null,
          salesRank: 484
        },
        {
          date: '2020-10-16',
          reviewCount: null,
          rating: null,
          salesRank: 1007
        },
        {
          date: '2020-10-17',
          reviewCount: null,
          rating: null,
          salesRank: 1838
        },
        {
          date: '2020-10-18',
          reviewCount: null,
          rating: null,
          salesRank: 2927
        },
        {
          date: '2020-10-19',
          reviewCount: null,
          rating: null,
          salesRank: 4484
        },
        {
          date: '2020-10-20',
          reviewCount: null,
          rating: null,
          salesRank: 5658
        },
        {
          date: '2020-10-21',
          reviewCount: null,
          rating: null,
          salesRank: 6911
        },
        {
          date: '2020-10-22',
          reviewCount: null,
          rating: null,
          salesRank: 8175
        },
        {
          date: '2020-10-23',
          reviewCount: null,
          rating: null,
          salesRank: 9911
        },
        {
          date: '2020-10-24',
          reviewCount: null,
          rating: null,
          salesRank: 8641
        },
        {
          date: '2020-10-25',
          reviewCount: null,
          rating: null,
          salesRank: 6162
        },
        {
          date: '2020-10-26',
          reviewCount: null,
          rating: null,
          salesRank: 5274
        },
        {
          date: '2020-10-27',
          reviewCount: null,
          rating: null,
          salesRank: 3974
        },
        {
          date: '2020-10-28',
          reviewCount: null,
          rating: null,
          salesRank: 4315
        },
        {
          date: '2020-10-29',
          reviewCount: null,
          rating: null,
          salesRank: 4983
        },
        {
          date: '2020-10-30',
          reviewCount: null,
          rating: null,
          salesRank: 4420
        },
        {
          date: '2020-10-31',
          reviewCount: null,
          rating: null,
          salesRank: 3442
        },
        {
          date: '2020-11-01',
          reviewCount: null,
          rating: null,
          salesRank: 5103
        },
        {
          date: '2020-11-02',
          reviewCount: null,
          rating: null,
          salesRank: 5541
        },
        {
          date: '2020-11-03',
          reviewCount: null,
          rating: null,
          salesRank: 4059
        },
        {
          date: '2020-11-04',
          reviewCount: null,
          rating: null,
          salesRank: 3093
        },
        {
          date: '2020-11-05',
          reviewCount: null,
          rating: null,
          salesRank: 3647
        },
        {
          date: '2020-11-06',
          reviewCount: null,
          rating: null,
          salesRank: 4582
        },
        {
          date: '2020-11-07',
          reviewCount: null,
          rating: null,
          salesRank: 4658
        },
        {
          date: '2020-11-08',
          reviewCount: null,
          rating: null,
          salesRank: 5338
        },
        {
          date: '2020-11-09',
          reviewCount: null,
          rating: null,
          salesRank: 4896
        },
        {
          date: '2020-11-10',
          reviewCount: null,
          rating: null,
          salesRank: 5204
        },
        {
          date: '2020-11-11',
          reviewCount: null,
          rating: null,
          salesRank: 4983
        },
        {
          date: '2020-11-12',
          reviewCount: null,
          rating: null,
          salesRank: 3243
        },
        {
          date: '2020-11-13',
          reviewCount: null,
          rating: null,
          salesRank: 3182
        },
        {
          date: '2020-11-14',
          reviewCount: null,
          rating: null,
          salesRank: 3731
        },
        {
          date: '2020-11-15',
          reviewCount: null,
          rating: null,
          salesRank: 3087
        },
        {
          date: '2020-11-16',
          reviewCount: null,
          rating: null,
          salesRank: 3355
        },
        {
          date: '2020-11-17',
          reviewCount: null,
          rating: null,
          salesRank: 3683
        },
        {
          date: '2020-11-18',
          reviewCount: null,
          rating: null,
          salesRank: 1860
        },
        {
          date: '2020-11-19',
          reviewCount: null,
          rating: null,
          salesRank: 1594
        },
        {
          date: '2020-11-20',
          reviewCount: null,
          rating: null,
          salesRank: 1711
        },
        {
          date: '2020-11-21',
          reviewCount: null,
          rating: null,
          salesRank: 656
        },
        {
          date: '2020-11-22',
          reviewCount: null,
          rating: null,
          salesRank: 3960
        },
        {
          date: '2020-11-23',
          reviewCount: null,
          rating: null,
          salesRank: 6311
        },
        {
          date: '2020-11-24',
          reviewCount: null,
          rating: null,
          salesRank: 7357
        },
        {
          date: '2020-11-25',
          reviewCount: null,
          rating: null,
          salesRank: 9891
        },
        {
          date: '2020-11-26',
          reviewCount: null,
          rating: null,
          salesRank: 7241
        },
        {
          date: '2020-11-27',
          reviewCount: null,
          rating: null,
          salesRank: 9516
        },
        {
          date: '2020-11-28',
          reviewCount: null,
          rating: null,
          salesRank: 10557
        },
        {
          date: '2020-11-30',
          reviewCount: null,
          rating: null,
          salesRank: 5422
        },
        {
          date: '2020-12-01',
          reviewCount: null,
          rating: null,
          salesRank: 3255
        },
        {
          date: '2020-12-02',
          reviewCount: null,
          rating: null,
          salesRank: 2620
        },
        {
          date: '2020-12-03',
          reviewCount: null,
          rating: null,
          salesRank: 2778
        },
        {
          date: '2020-12-04',
          reviewCount: null,
          rating: null,
          salesRank: 3190
        },
        {
          date: '2020-12-05',
          reviewCount: null,
          rating: null,
          salesRank: 3757
        },
        {
          date: '2020-12-06',
          reviewCount: null,
          rating: null,
          salesRank: 4750
        },
        {
          date: '2020-12-07',
          reviewCount: null,
          rating: null,
          salesRank: 3833
        },
        {
          date: '2020-12-08',
          reviewCount: null,
          rating: null,
          salesRank: 3135
        },
        {
          date: '2020-12-09',
          reviewCount: null,
          rating: null,
          salesRank: 2755
        },
        {
          date: '2020-12-10',
          reviewCount: null,
          rating: null,
          salesRank: 2729
        },
        {
          date: '2020-12-11',
          reviewCount: null,
          rating: null,
          salesRank: 5998
        },
        {
          date: '2020-12-12',
          reviewCount: null,
          rating: null,
          salesRank: 2208
        },
        {
          date: '2020-12-14',
          reviewCount: null,
          rating: null,
          salesRank: 3113
        },
        {
          date: '2020-12-15',
          reviewCount: null,
          rating: null,
          salesRank: 3424
        },
        {
          date: '2020-12-16',
          reviewCount: null,
          rating: null,
          salesRank: 2609
        },
        {
          date: '2020-12-17',
          reviewCount: null,
          rating: null,
          salesRank: 2524
        },
        {
          date: '2020-12-18',
          reviewCount: null,
          rating: null,
          salesRank: 1310
        },
        {
          date: '2020-12-19',
          reviewCount: null,
          rating: null,
          salesRank: 2047
        },
        {
          date: '2020-12-20',
          reviewCount: null,
          rating: null,
          salesRank: 514
        },
        {
          date: '2020-12-21',
          reviewCount: null,
          rating: null,
          salesRank: 1024
        },
        {
          date: '2020-12-22',
          reviewCount: null,
          rating: null,
          salesRank: 1335
        },
        {
          date: '2020-12-23',
          reviewCount: null,
          rating: null,
          salesRank: 1416
        },
        {
          date: '2020-12-24',
          reviewCount: null,
          rating: null,
          salesRank: 1637
        },
        {
          date: '2020-12-25',
          reviewCount: null,
          rating: null,
          salesRank: 1926
        },
        {
          date: '2020-12-26',
          reviewCount: null,
          rating: null,
          salesRank: 1702
        },
        {
          date: '2020-12-27',
          reviewCount: null,
          rating: null,
          salesRank: 1606
        },
        {
          date: '2020-12-28',
          reviewCount: null,
          rating: null,
          salesRank: 2124
        },
        {
          date: '2020-12-29',
          reviewCount: null,
          rating: null,
          salesRank: 1522
        },
        {
          date: '2020-12-30',
          reviewCount: null,
          rating: null,
          salesRank: 1366
        },
        {
          date: '2020-12-31',
          reviewCount: null,
          rating: null,
          salesRank: 1302
        },
        {
          date: '2021-01-01',
          reviewCount: null,
          rating: null,
          salesRank: 1612
        },
        {
          date: '2021-01-02',
          reviewCount: null,
          rating: null,
          salesRank: 1642
        },
        {
          date: '2021-01-03',
          reviewCount: null,
          rating: null,
          salesRank: 3599
        },
        {
          date: '2021-01-04',
          reviewCount: null,
          rating: null,
          salesRank: 5553
        },
        {
          date: '2021-01-05',
          reviewCount: null,
          rating: null,
          salesRank: 6165
        },
        {
          date: '2021-01-06',
          reviewCount: null,
          rating: null,
          salesRank: 6981
        },
        {
          date: '2021-01-07',
          reviewCount: null,
          rating: null,
          salesRank: 8650
        },
        {
          date: '2021-01-08',
          reviewCount: null,
          rating: null,
          salesRank: 9820
        },
        {
          date: '2021-01-09',
          reviewCount: null,
          rating: null,
          salesRank: 9862
        },
        {
          date: '2021-01-10',
          reviewCount: null,
          rating: null,
          salesRank: 11258
        },
        {
          date: '2021-01-11',
          reviewCount: null,
          rating: null,
          salesRank: 2801
        },
        {
          date: '2021-01-12',
          reviewCount: null,
          rating: null,
          salesRank: 1646
        },
        {
          date: '2021-01-13',
          reviewCount: null,
          rating: null,
          salesRank: 1644
        },
        {
          date: '2021-01-14',
          reviewCount: null,
          rating: null,
          salesRank: 1750
        },
        {
          date: '2021-01-15',
          reviewCount: null,
          rating: null,
          salesRank: 1511
        },
        {
          date: '2021-01-16',
          reviewCount: null,
          rating: null,
          salesRank: 1286
        },
        {
          date: '2021-01-17',
          reviewCount: null,
          rating: null,
          salesRank: 1409
        },
        {
          date: '2021-01-18',
          reviewCount: null,
          rating: null,
          salesRank: 1580
        },
        {
          date: '2021-01-19',
          reviewCount: null,
          rating: null,
          salesRank: 1399
        },
        {
          date: '2021-01-20',
          reviewCount: null,
          rating: null,
          salesRank: 1299
        },
        {
          date: '2021-01-21',
          reviewCount: null,
          rating: null,
          salesRank: 1226
        },
        {
          date: '2021-01-22',
          reviewCount: null,
          rating: null,
          salesRank: 1252
        },
        {
          date: '2021-01-23',
          reviewCount: null,
          rating: null,
          salesRank: 1226
        },
        {
          date: '2021-01-24',
          reviewCount: null,
          rating: null,
          salesRank: 1553
        },
        {
          date: '2021-01-25',
          reviewCount: null,
          rating: null,
          salesRank: 1216
        },
        {
          date: '2021-01-26',
          reviewCount: null,
          rating: null,
          salesRank: 1079
        },
        {
          date: '2021-01-27',
          reviewCount: null,
          rating: null,
          salesRank: 1209
        },
        {
          date: '2021-01-28',
          reviewCount: null,
          rating: null,
          salesRank: 1154
        },
        {
          date: '2021-01-29',
          reviewCount: null,
          rating: null,
          salesRank: 1103
        },
        {
          date: '2021-01-30',
          reviewCount: null,
          rating: null,
          salesRank: 1113
        },
        {
          date: '2021-01-31',
          reviewCount: null,
          rating: null,
          salesRank: 1135
        },
        {
          date: '2021-02-01',
          reviewCount: null,
          rating: null,
          salesRank: 1443
        },
        {
          date: '2021-02-02',
          reviewCount: null,
          rating: null,
          salesRank: 1697
        },
        {
          date: '2021-02-03',
          reviewCount: null,
          rating: null,
          salesRank: 1356
        },
        {
          date: '2021-02-04',
          reviewCount: null,
          rating: null,
          salesRank: 828
        },
        {
          date: '2021-02-05',
          reviewCount: null,
          rating: null,
          salesRank: 862
        },
        {
          date: '2021-02-06',
          reviewCount: null,
          rating: null,
          salesRank: 1027
        },
        {
          date: '2021-02-07',
          reviewCount: null,
          rating: null,
          salesRank: 935
        },
        {
          date: '2021-02-08',
          reviewCount: null,
          rating: null,
          salesRank: 968
        },
        {
          date: '2021-02-09',
          reviewCount: null,
          rating: null,
          salesRank: 1073
        },
        {
          date: '2021-02-10',
          reviewCount: null,
          rating: null,
          salesRank: 431
        },
        {
          date: '2021-02-11',
          reviewCount: null,
          rating: null,
          salesRank: 744
        },
        {
          date: '2021-02-12',
          reviewCount: null,
          rating: null,
          salesRank: 776
        },
        {
          date: '2021-02-13',
          reviewCount: null,
          rating: null,
          salesRank: 954
        },
        {
          date: '2021-02-14',
          reviewCount: null,
          rating: null,
          salesRank: 887
        },
        {
          date: '2021-02-15',
          reviewCount: null,
          rating: null,
          salesRank: 788
        },
        {
          date: '2021-02-16',
          reviewCount: null,
          rating: null,
          salesRank: 912
        },
        {
          date: '2021-02-17',
          reviewCount: null,
          rating: null,
          salesRank: 1057
        },
        {
          date: '2021-02-18',
          reviewCount: null,
          rating: null,
          salesRank: 998
        },
        {
          date: '2021-02-19',
          reviewCount: null,
          rating: null,
          salesRank: 1054
        },
        {
          date: '2021-02-20',
          reviewCount: null,
          rating: null,
          salesRank: 1251
        },
        {
          date: '2021-02-21',
          reviewCount: null,
          rating: null,
          salesRank: 1100
        },
        {
          date: '2021-02-22',
          reviewCount: null,
          rating: null,
          salesRank: 973
        },
        {
          date: '2021-02-23',
          reviewCount: null,
          rating: null,
          salesRank: 2297
        },
        {
          date: '2021-02-24',
          reviewCount: null,
          rating: null,
          salesRank: 3940
        },
        {
          date: '2021-02-25',
          reviewCount: null,
          rating: null,
          salesRank: 5403
        },
        {
          date: '2021-02-26',
          reviewCount: null,
          rating: null,
          salesRank: 6826
        },
        {
          date: '2021-02-27',
          reviewCount: null,
          rating: null,
          salesRank: 7602
        },
        {
          date: '2021-02-28',
          reviewCount: null,
          rating: null,
          salesRank: 11021
        },
        {
          date: '2021-03-01',
          reviewCount: null,
          rating: null,
          salesRank: 11020
        },
        {
          date: '2021-03-02',
          reviewCount: null,
          rating: null,
          salesRank: 10393
        },
        {
          date: '2021-03-03',
          reviewCount: null,
          rating: null,
          salesRank: 11381
        },
        {
          date: '2021-03-04',
          reviewCount: null,
          rating: null,
          salesRank: 11251
        },
        {
          date: '2021-03-05',
          reviewCount: null,
          rating: null,
          salesRank: 12445
        },
        {
          date: '2021-03-06',
          reviewCount: null,
          rating: null,
          salesRank: 13468
        },
        {
          date: '2021-03-07',
          reviewCount: null,
          rating: null,
          salesRank: 16319
        },
        {
          date: '2021-03-08',
          reviewCount: null,
          rating: null,
          salesRank: 20362
        },
        {
          date: '2021-03-09',
          reviewCount: null,
          rating: null,
          salesRank: 16419
        },
        {
          date: '2021-03-10',
          reviewCount: null,
          rating: null,
          salesRank: 18440
        },
        {
          date: '2021-03-11',
          reviewCount: null,
          rating: null,
          salesRank: 20420
        },
        {
          date: '2021-03-12',
          reviewCount: null,
          rating: null,
          salesRank: 20632
        },
        {
          date: '2021-03-13',
          reviewCount: null,
          rating: null,
          salesRank: 24681
        },
        {
          date: '2021-03-14',
          reviewCount: null,
          rating: null,
          salesRank: 26147
        },
        {
          date: '2021-03-15',
          reviewCount: null,
          rating: null,
          salesRank: 27813
        },
        {
          date: '2021-03-16',
          reviewCount: null,
          rating: null,
          salesRank: 23908
        },
        {
          date: '2021-03-17',
          reviewCount: null,
          rating: null,
          salesRank: 19890
        },
        {
          date: '2021-03-18',
          reviewCount: null,
          rating: null,
          salesRank: 19443
        },
        {
          date: '2021-03-19',
          reviewCount: null,
          rating: null,
          salesRank: 24889
        },
        {
          date: '2021-03-20',
          reviewCount: null,
          rating: null,
          salesRank: 21826
        },
        {
          date: '2021-03-21',
          reviewCount: null,
          rating: null,
          salesRank: 29206
        },
        {
          date: '2021-03-22',
          reviewCount: null,
          rating: null,
          salesRank: 30369
        },
        {
          date: '2021-03-23',
          reviewCount: null,
          rating: null,
          salesRank: 32378
        },
        {
          date: '2021-03-24',
          reviewCount: null,
          rating: null,
          salesRank: 23944
        },
        {
          date: '2021-03-25',
          reviewCount: null,
          rating: null,
          salesRank: 30170
        },
        {
          date: '2021-03-26',
          reviewCount: null,
          rating: null,
          salesRank: 38391
        },
        {
          date: '2021-03-27',
          reviewCount: null,
          rating: null,
          salesRank: 50108
        },
        {
          date: '2021-03-28',
          reviewCount: null,
          rating: null,
          salesRank: 35582
        },
        {
          date: '2021-03-29',
          reviewCount: null,
          rating: null,
          salesRank: 43272
        },
        {
          date: '2021-03-30',
          reviewCount: null,
          rating: null,
          salesRank: 41193
        },
        {
          date: '2021-03-31',
          reviewCount: null,
          rating: null,
          salesRank: 31859
        },
        {
          date: '2021-04-01',
          reviewCount: null,
          rating: null,
          salesRank: 36038
        },
        {
          date: '2021-04-02',
          reviewCount: null,
          rating: null,
          salesRank: 33757
        },
        {
          date: '2021-04-03',
          reviewCount: null,
          rating: null,
          salesRank: 28906
        },
        {
          date: '2021-04-04',
          reviewCount: null,
          rating: null,
          salesRank: 28533
        },
        {
          date: '2021-04-05',
          reviewCount: null,
          rating: null,
          salesRank: 30420
        },
        {
          date: '2021-04-06',
          reviewCount: null,
          rating: null,
          salesRank: 28913
        },
        {
          date: '2021-04-07',
          reviewCount: null,
          rating: null,
          salesRank: 36244
        },
        {
          date: '2021-04-08',
          reviewCount: null,
          rating: null,
          salesRank: 35061
        },
        {
          date: '2021-04-09',
          reviewCount: null,
          rating: null,
          salesRank: 37120
        },
        {
          date: '2021-04-10',
          reviewCount: null,
          rating: null,
          salesRank: 39276
        },
        {
          date: '2021-04-11',
          reviewCount: null,
          rating: null,
          salesRank: 36957
        },
        {
          date: '2021-04-12',
          reviewCount: null,
          rating: null,
          salesRank: 30764
        },
        {
          date: '2021-04-13',
          reviewCount: null,
          rating: null,
          salesRank: 38915
        },
        {
          date: '2021-04-14',
          reviewCount: null,
          rating: null,
          salesRank: 39266
        },
        {
          date: '2021-04-15',
          reviewCount: null,
          rating: null,
          salesRank: 31689
        },
        {
          date: '2021-04-16',
          reviewCount: null,
          rating: null,
          salesRank: 32506
        },
        {
          date: '2021-04-17',
          reviewCount: null,
          rating: null,
          salesRank: 31837
        },
        {
          date: '2021-04-18',
          reviewCount: null,
          rating: null,
          salesRank: 39429
        },
        {
          date: '2021-04-19',
          reviewCount: null,
          rating: null,
          salesRank: 32940
        },
        {
          date: '2021-04-20',
          reviewCount: null,
          rating: null,
          salesRank: 40662
        },
        {
          date: '2021-04-21',
          reviewCount: null,
          rating: null,
          salesRank: 37082
        },
        {
          date: '2021-04-22',
          reviewCount: null,
          rating: null,
          salesRank: 45296
        },
        {
          date: '2021-04-23',
          reviewCount: null,
          rating: null,
          salesRank: 42585
        },
        {
          date: '2021-04-24',
          reviewCount: null,
          rating: null,
          salesRank: 36542
        },
        {
          date: '2021-04-25',
          reviewCount: null,
          rating: null,
          salesRank: 33206
        },
        {
          date: '2021-04-26',
          reviewCount: null,
          rating: null,
          salesRank: 31370
        },
        {
          date: '2021-04-27',
          reviewCount: null,
          rating: null,
          salesRank: 34483
        },
        {
          date: '2021-04-28',
          reviewCount: null,
          rating: null,
          salesRank: 40636
        },
        {
          date: '2021-04-29',
          reviewCount: null,
          rating: null,
          salesRank: 50524
        },
        {
          date: '2021-04-30',
          reviewCount: null,
          rating: null,
          salesRank: 42269
        },
        {
          date: '2021-05-01',
          reviewCount: null,
          rating: null,
          salesRank: 40734
        },
        {
          date: '2021-05-02',
          reviewCount: null,
          rating: null,
          salesRank: 36832
        },
        {
          date: '2021-05-03',
          reviewCount: null,
          rating: null,
          salesRank: 41148
        },
        {
          date: '2021-05-04',
          reviewCount: null,
          rating: null,
          salesRank: 34257
        },
        {
          date: '2021-05-05',
          reviewCount: null,
          rating: null,
          salesRank: 23735
        },
        {
          date: '2021-05-06',
          reviewCount: null,
          rating: null,
          salesRank: 26370
        },
        {
          date: '2021-05-07',
          reviewCount: null,
          rating: null,
          salesRank: 18064
        },
        {
          date: '2021-05-08',
          reviewCount: null,
          rating: null,
          salesRank: 18293
        },
        {
          date: '2021-05-09',
          reviewCount: null,
          rating: null,
          salesRank: 16676
        },
        {
          date: '2021-05-10',
          reviewCount: null,
          rating: null,
          salesRank: 14970
        },
        {
          date: '2021-05-11',
          reviewCount: null,
          rating: null,
          salesRank: 18824
        },
        {
          date: '2021-05-12',
          reviewCount: null,
          rating: null,
          salesRank: 21738
        },
        {
          date: '2021-05-13',
          reviewCount: null,
          rating: null,
          salesRank: 13270
        },
        {
          date: '2021-05-14',
          reviewCount: null,
          rating: null,
          salesRank: 15459
        },
        {
          date: '2021-05-15',
          reviewCount: null,
          rating: null,
          salesRank: 15833
        },
        {
          date: '2021-05-16',
          reviewCount: null,
          rating: null,
          salesRank: 21755
        },
        {
          date: '2021-05-17',
          reviewCount: null,
          rating: null,
          salesRank: 19924
        },
        {
          date: '2021-05-18',
          reviewCount: null,
          rating: null,
          salesRank: 17688
        },
        {
          date: '2021-05-19',
          reviewCount: null,
          rating: null,
          salesRank: 18524
        },
        {
          date: '2021-05-20',
          reviewCount: null,
          rating: null,
          salesRank: 17346
        },
        {
          date: '2021-05-21',
          reviewCount: null,
          rating: null,
          salesRank: 19587
        },
        {
          date: '2021-05-22',
          reviewCount: null,
          rating: null,
          salesRank: 19271
        },
        {
          date: '2021-05-23',
          reviewCount: null,
          rating: null,
          salesRank: 25790
        },
        {
          date: '2021-05-24',
          reviewCount: null,
          rating: null,
          salesRank: 22878
        },
        {
          date: '2021-05-25',
          reviewCount: null,
          rating: null,
          salesRank: 19187
        },
        {
          date: '2021-05-26',
          reviewCount: null,
          rating: null,
          salesRank: 21999
        },
        {
          date: '2021-05-27',
          reviewCount: null,
          rating: null,
          salesRank: 20302
        },
        {
          date: '2021-05-28',
          reviewCount: null,
          rating: null,
          salesRank: 20871
        },
        {
          date: '2021-05-29',
          reviewCount: null,
          rating: null,
          salesRank: 17090
        },
        {
          date: '2021-05-30',
          reviewCount: null,
          rating: null,
          salesRank: 20909
        },
        {
          date: '2021-05-31',
          reviewCount: null,
          rating: null,
          salesRank: 21598
        },
        {
          date: '2021-06-01',
          reviewCount: null,
          rating: null,
          salesRank: 20945
        },
        {
          date: '2021-06-02',
          reviewCount: null,
          rating: null,
          salesRank: 21507
        },
        {
          date: '2021-06-03',
          reviewCount: null,
          rating: null,
          salesRank: 20084
        },
        {
          date: '2021-06-04',
          reviewCount: null,
          rating: null,
          salesRank: 21887
        },
        {
          date: '2021-06-05',
          reviewCount: null,
          rating: null,
          salesRank: 23712
        },
        {
          date: '2021-06-06',
          reviewCount: null,
          rating: null,
          salesRank: 25453
        },
        {
          date: '2021-06-07',
          reviewCount: null,
          rating: null,
          salesRank: 24190
        },
        {
          date: '2021-06-08',
          reviewCount: null,
          rating: null,
          salesRank: 21408
        },
        {
          date: '2021-06-09',
          reviewCount: null,
          rating: null,
          salesRank: 18212
        },
        {
          date: '2021-06-10',
          reviewCount: null,
          rating: null,
          salesRank: 19766
        },
        {
          date: '2021-06-11',
          reviewCount: null,
          rating: null,
          salesRank: 23376
        },
        {
          date: '2021-06-12',
          reviewCount: null,
          rating: null,
          salesRank: 32120
        },
        {
          date: '2021-06-13',
          reviewCount: null,
          rating: null,
          salesRank: 29039
        },
        {
          date: '2021-06-14',
          reviewCount: null,
          rating: null,
          salesRank: 22534
        },
        {
          date: '2021-06-15',
          reviewCount: null,
          rating: null,
          salesRank: 24313
        },
        {
          date: '2021-06-16',
          reviewCount: null,
          rating: null,
          salesRank: 21467
        },
        {
          date: '2021-06-17',
          reviewCount: null,
          rating: null,
          salesRank: 17151
        },
        {
          date: '2021-06-18',
          reviewCount: null,
          rating: null,
          salesRank: 21038
        },
        {
          date: '2021-06-19',
          reviewCount: null,
          rating: null,
          salesRank: 20247
        },
        {
          date: '2021-06-20',
          reviewCount: null,
          rating: null,
          salesRank: 21094
        },
        {
          date: '2021-06-21',
          reviewCount: null,
          rating: null,
          salesRank: 3691
        },
        {
          date: '2021-06-22',
          reviewCount: null,
          rating: null,
          salesRank: 4794
        },
        {
          date: '2021-06-23',
          reviewCount: null,
          rating: null,
          salesRank: 5279
        },
        {
          date: '2021-06-24',
          reviewCount: null,
          rating: null,
          salesRank: 6494
        },
        {
          date: '2021-06-25',
          reviewCount: null,
          rating: null,
          salesRank: 7742
        },
        {
          date: '2021-06-26',
          reviewCount: null,
          rating: null,
          salesRank: 7606
        },
        {
          date: '2021-06-27',
          reviewCount: null,
          rating: null,
          salesRank: 9320
        },
        {
          date: '2021-06-28',
          reviewCount: null,
          rating: null,
          salesRank: 9006
        },
        {
          date: '2021-06-29',
          reviewCount: null,
          rating: null,
          salesRank: 9189
        },
        {
          date: '2021-06-30',
          reviewCount: null,
          rating: null,
          salesRank: 11407
        },
        {
          date: '2021-07-01',
          reviewCount: null,
          rating: null,
          salesRank: 10562
        },
        {
          date: '2021-07-02',
          reviewCount: null,
          rating: null,
          salesRank: 12767
        },
        {
          date: '2021-07-03',
          reviewCount: null,
          rating: null,
          salesRank: 15436
        },
        {
          date: '2021-07-04',
          reviewCount: null,
          rating: null,
          salesRank: 15326
        },
        {
          date: '2021-07-05',
          reviewCount: null,
          rating: null,
          salesRank: 19409
        },
        {
          date: '2021-07-06',
          reviewCount: null,
          rating: null,
          salesRank: 13753
        },
        {
          date: '2021-07-07',
          reviewCount: null,
          rating: null,
          salesRank: 13399
        },
        {
          date: '2021-07-08',
          reviewCount: null,
          rating: null,
          salesRank: 12560
        },
        {
          date: '2021-07-09',
          reviewCount: null,
          rating: null,
          salesRank: 13151
        },
        {
          date: '2021-07-10',
          reviewCount: null,
          rating: null,
          salesRank: 13931
        },
        {
          date: '2021-07-11',
          reviewCount: null,
          rating: null,
          salesRank: 14498
        },
        {
          date: '2021-07-12',
          reviewCount: null,
          rating: null,
          salesRank: 12801
        },
        {
          date: '2021-07-13',
          reviewCount: null,
          rating: null,
          salesRank: 14207
        },
        {
          date: '2021-07-14',
          reviewCount: null,
          rating: null,
          salesRank: 17190
        },
        {
          date: '2021-07-15',
          reviewCount: null,
          rating: null,
          salesRank: 12594
        },
        {
          date: '2021-07-16',
          reviewCount: null,
          rating: null,
          salesRank: 11942
        },
        {
          date: '2021-07-17',
          reviewCount: null,
          rating: null,
          salesRank: 12638
        },
        {
          date: '2021-07-18',
          reviewCount: null,
          rating: null,
          salesRank: 19953
        },
        {
          date: '2021-07-19',
          reviewCount: null,
          rating: null,
          salesRank: 20336
        },
        {
          date: '2021-07-20',
          reviewCount: null,
          rating: null,
          salesRank: 16520
        },
        {
          date: '2021-07-21',
          reviewCount: null,
          rating: null,
          salesRank: 18489
        },
        {
          date: '2021-07-22',
          reviewCount: null,
          rating: null,
          salesRank: 15976
        },
        {
          date: '2021-07-23',
          reviewCount: null,
          rating: null,
          salesRank: 19785
        },
        {
          date: '2021-07-24',
          reviewCount: null,
          rating: null,
          salesRank: 18369
        },
        {
          date: '2021-07-25',
          reviewCount: null,
          rating: null,
          salesRank: 20155
        },
        {
          date: '2021-07-26',
          reviewCount: null,
          rating: null,
          salesRank: 21080
        },
        {
          date: '2021-07-27',
          reviewCount: null,
          rating: null,
          salesRank: 15454
        },
        {
          date: '2021-07-28',
          reviewCount: null,
          rating: null,
          salesRank: 19517
        },
        {
          date: '2021-07-29',
          reviewCount: null,
          rating: null,
          salesRank: 18736
        },
        {
          date: '2021-07-30',
          reviewCount: null,
          rating: null,
          salesRank: 15499
        },
        {
          date: '2021-07-31',
          reviewCount: null,
          rating: null,
          salesRank: 16524
        },
        {
          date: '2021-08-01',
          reviewCount: null,
          rating: null,
          salesRank: 16366
        },
        {
          date: '2021-08-02',
          reviewCount: null,
          rating: null,
          salesRank: 15117
        },
        {
          date: '2021-08-03',
          reviewCount: null,
          rating: null,
          salesRank: 11856
        },
        {
          date: '2021-08-04',
          reviewCount: null,
          rating: null,
          salesRank: 11082
        },
        {
          date: '2021-08-05',
          reviewCount: null,
          rating: null,
          salesRank: 10655
        },
        {
          date: '2021-08-06',
          reviewCount: null,
          rating: null,
          salesRank: 11559
        },
        {
          date: '2021-08-07',
          reviewCount: null,
          rating: null,
          salesRank: 11927
        },
        {
          date: '2021-08-08',
          reviewCount: null,
          rating: null,
          salesRank: 11274
        },
        {
          date: '2021-08-09',
          reviewCount: null,
          rating: null,
          salesRank: 10353
        },
        {
          date: '2021-08-10',
          reviewCount: null,
          rating: null,
          salesRank: 10610
        },
        {
          date: '2021-08-11',
          reviewCount: null,
          rating: null,
          salesRank: 11462
        },
        {
          date: '2021-08-12',
          reviewCount: null,
          rating: null,
          salesRank: 10062
        },
        {
          date: '2021-08-13',
          reviewCount: null,
          rating: null,
          salesRank: 10816
        },
        {
          date: '2021-08-14',
          reviewCount: null,
          rating: null,
          salesRank: 9545
        },
        {
          date: '2021-08-15',
          reviewCount: null,
          rating: null,
          salesRank: 8080
        },
        {
          date: '2021-08-16',
          reviewCount: null,
          rating: null,
          salesRank: 9108
        },
        {
          date: '2021-08-17',
          reviewCount: null,
          rating: null,
          salesRank: 6520
        },
        {
          date: '2021-08-18',
          reviewCount: null,
          rating: null,
          salesRank: 8639
        },
        {
          date: '2021-08-19',
          reviewCount: null,
          rating: null,
          salesRank: 8773
        },
        {
          date: '2021-08-20',
          reviewCount: null,
          rating: null,
          salesRank: 9277
        },
        {
          date: '2021-08-21',
          reviewCount: null,
          rating: null,
          salesRank: 8038
        },
        {
          date: '2021-08-22',
          reviewCount: null,
          rating: null,
          salesRank: 9243
        },
        {
          date: '2021-08-23',
          reviewCount: null,
          rating: null,
          salesRank: 8915
        },
        {
          date: '2021-08-24',
          reviewCount: null,
          rating: null,
          salesRank: 7442
        },
        {
          date: '2021-08-25',
          reviewCount: null,
          rating: null,
          salesRank: 7490
        },
        {
          date: '2021-08-26',
          reviewCount: null,
          rating: null,
          salesRank: 8515
        },
        {
          date: '2021-08-27',
          reviewCount: null,
          rating: null,
          salesRank: 6812
        },
        {
          date: '2021-08-28',
          reviewCount: null,
          rating: null,
          salesRank: 8199
        },
        {
          date: '2021-08-29',
          reviewCount: null,
          rating: null,
          salesRank: 9921
        },
        {
          date: '2021-08-30',
          reviewCount: null,
          rating: null,
          salesRank: 9454
        },
        {
          date: '2021-08-31',
          reviewCount: null,
          rating: null,
          salesRank: 5675
        },
        {
          date: '2021-09-01',
          reviewCount: null,
          rating: null,
          salesRank: 5927
        },
        {
          date: '2021-09-02',
          reviewCount: null,
          rating: null,
          salesRank: 5330
        },
        {
          date: '2021-09-03',
          reviewCount: null,
          rating: null,
          salesRank: 6369
        },
        {
          date: '2021-09-04',
          reviewCount: null,
          rating: null,
          salesRank: 6917
        },
        {
          date: '2021-09-05',
          reviewCount: null,
          rating: null,
          salesRank: 5955
        },
        {
          date: '2021-09-06',
          reviewCount: null,
          rating: null,
          salesRank: 5903
        },
        {
          date: '2021-09-07',
          reviewCount: null,
          rating: null,
          salesRank: 4239
        },
        {
          date: '2021-09-08',
          reviewCount: null,
          rating: null,
          salesRank: 5267
        },
        {
          date: '2021-09-09',
          reviewCount: null,
          rating: null,
          salesRank: 6562
        },
        {
          date: '2021-09-10',
          reviewCount: null,
          rating: null,
          salesRank: 8295
        },
        {
          date: '2021-09-11',
          reviewCount: null,
          rating: null,
          salesRank: 9254
        },
        {
          date: '2021-09-12',
          reviewCount: null,
          rating: null,
          salesRank: 10087
        },
        {
          date: '2021-09-13',
          reviewCount: null,
          rating: null,
          salesRank: 9982
        },
        {
          date: '2021-09-14',
          reviewCount: null,
          rating: null,
          salesRank: 9883
        },
        {
          date: '2021-09-15',
          reviewCount: null,
          rating: null,
          salesRank: 9100
        },
        {
          date: '2021-09-16',
          reviewCount: null,
          rating: null,
          salesRank: 7902
        },
        {
          date: '2021-09-17',
          reviewCount: null,
          rating: null,
          salesRank: 5476
        },
        {
          date: '2021-09-18',
          reviewCount: null,
          rating: null,
          salesRank: 6359
        },
        {
          date: '2021-09-19',
          reviewCount: null,
          rating: null,
          salesRank: 8057
        },
        {
          date: '2021-09-20',
          reviewCount: null,
          rating: null,
          salesRank: 8051
        },
        {
          date: '2021-09-21',
          reviewCount: null,
          rating: null,
          salesRank: 8818
        },
        {
          date: '2021-09-22',
          reviewCount: null,
          rating: null,
          salesRank: 7422
        },
        {
          date: '2021-09-23',
          reviewCount: null,
          rating: null,
          salesRank: 8163
        },
        {
          date: '2021-09-24',
          reviewCount: null,
          rating: null,
          salesRank: 6992
        },
        {
          date: '2021-09-25',
          reviewCount: null,
          rating: null,
          salesRank: 7879
        },
        {
          date: '2021-09-26',
          reviewCount: null,
          rating: null,
          salesRank: 5774
        },
        {
          date: '2021-09-27',
          reviewCount: null,
          rating: null,
          salesRank: 6173
        },
        {
          date: '2021-09-28',
          reviewCount: null,
          rating: null,
          salesRank: 5230
        },
        {
          date: '2021-09-29',
          reviewCount: null,
          rating: null,
          salesRank: 6001
        },
        {
          date: '2021-09-30',
          reviewCount: null,
          rating: null,
          salesRank: 4534
        },
        {
          date: '2021-10-01',
          reviewCount: null,
          rating: null,
          salesRank: 5979
        },
        {
          date: '2021-10-02',
          reviewCount: null,
          rating: null,
          salesRank: 7098
        },
        {
          date: '2021-10-03',
          reviewCount: null,
          rating: null,
          salesRank: 5975
        },
        {
          date: '2021-10-04',
          reviewCount: null,
          rating: null,
          salesRank: 7340
        },
        {
          date: '2021-10-05',
          reviewCount: null,
          rating: null,
          salesRank: 5335
        },
        {
          date: '2021-10-06',
          reviewCount: null,
          rating: null,
          salesRank: 5712
        },
        {
          date: '2021-10-07',
          reviewCount: null,
          rating: null,
          salesRank: 6015
        },
        {
          date: '2021-10-08',
          reviewCount: null,
          rating: null,
          salesRank: 7030
        },
        {
          date: '2021-10-09',
          reviewCount: null,
          rating: null,
          salesRank: 7936
        },
        {
          date: '2021-10-10',
          reviewCount: null,
          rating: null,
          salesRank: 6483
        },
        {
          date: '2021-10-11',
          reviewCount: null,
          rating: null,
          salesRank: 7451
        },
        {
          date: '2021-10-12',
          reviewCount: null,
          rating: null,
          salesRank: 5246
        },
        {
          date: '2021-10-13',
          reviewCount: null,
          rating: null,
          salesRank: 5950
        },
        {
          date: '2021-10-14',
          reviewCount: null,
          rating: null,
          salesRank: 5471
        },
        {
          date: '2021-10-15',
          reviewCount: null,
          rating: null,
          salesRank: 4149
        },
        {
          date: '2021-10-16',
          reviewCount: null,
          rating: null,
          salesRank: 4211
        },
        {
          date: '2021-10-17',
          reviewCount: null,
          rating: null,
          salesRank: 5010
        },
        {
          date: '2021-10-18',
          reviewCount: null,
          rating: null,
          salesRank: 5878
        },
        {
          date: '2021-10-19',
          reviewCount: null,
          rating: null,
          salesRank: 6346
        },
        {
          date: '2021-10-20',
          reviewCount: null,
          rating: null,
          salesRank: 5560
        },
        {
          date: '2021-10-21',
          reviewCount: null,
          rating: null,
          salesRank: 4602
        },
        {
          date: '2021-10-22',
          reviewCount: null,
          rating: null,
          salesRank: 4192
        },
        {
          date: '2021-10-23',
          reviewCount: null,
          rating: null,
          salesRank: 4291
        },
        {
          date: '2021-10-24',
          reviewCount: null,
          rating: null,
          salesRank: 4075
        },
        {
          date: '2021-10-25',
          reviewCount: null,
          rating: null,
          salesRank: 3307
        },
        {
          date: '2021-10-26',
          reviewCount: null,
          rating: null,
          salesRank: 3386
        },
        {
          date: '2021-10-27',
          reviewCount: null,
          rating: null,
          salesRank: 3862
        },
        {
          date: '2021-10-28',
          reviewCount: null,
          rating: null,
          salesRank: 3997
        },
        {
          date: '2021-10-29',
          reviewCount: null,
          rating: null,
          salesRank: 4006
        },
        {
          date: '2021-10-30',
          reviewCount: null,
          rating: null,
          salesRank: 4027
        },
        {
          date: '2021-10-31',
          reviewCount: null,
          rating: null,
          salesRank: 3777
        },
        {
          date: '2021-11-01',
          reviewCount: null,
          rating: null,
          salesRank: 4047
        },
        {
          date: '2021-11-02',
          reviewCount: null,
          rating: null,
          salesRank: 2767
        },
        {
          date: '2021-11-03',
          reviewCount: null,
          rating: null,
          salesRank: 2418
        },
        {
          date: '2021-11-04',
          reviewCount: null,
          rating: null,
          salesRank: 2220
        },
        {
          date: '2021-11-05',
          reviewCount: null,
          rating: null,
          salesRank: 2021
        },
        {
          date: '2021-11-06',
          reviewCount: null,
          rating: null,
          salesRank: 1667
        },
        {
          date: '2021-11-07',
          reviewCount: null,
          rating: null,
          salesRank: 1321
        },
        {
          date: '2021-11-08',
          reviewCount: null,
          rating: null,
          salesRank: 1596
        },
        {
          date: '2021-11-09',
          reviewCount: null,
          rating: null,
          salesRank: 1185
        },
        {
          date: '2021-11-10',
          reviewCount: null,
          rating: null,
          salesRank: 1635
        },
        {
          date: '2021-11-11',
          reviewCount: null,
          rating: null,
          salesRank: 1882
        },
        {
          date: '2021-11-12',
          reviewCount: null,
          rating: null,
          salesRank: 2108
        },
        {
          date: '2021-11-13',
          reviewCount: null,
          rating: null,
          salesRank: 2474
        },
        {
          date: '2021-11-14',
          reviewCount: null,
          rating: null,
          salesRank: 2524
        },
        {
          date: '2021-11-15',
          reviewCount: null,
          rating: null,
          salesRank: 1914
        },
        {
          date: '2021-11-16',
          reviewCount: null,
          rating: null,
          salesRank: 2163
        },
        {
          date: '2021-11-17',
          reviewCount: null,
          rating: null,
          salesRank: 2119
        },
        {
          date: '2021-11-18',
          reviewCount: null,
          rating: null,
          salesRank: 2039
        },
        {
          date: '2021-11-19',
          reviewCount: null,
          rating: null,
          salesRank: 2825
        },
        {
          date: '2021-11-20',
          reviewCount: null,
          rating: null,
          salesRank: 2218
        },
        {
          date: '2021-11-21',
          reviewCount: null,
          rating: null,
          salesRank: 1886
        },
        {
          date: '2021-11-22',
          reviewCount: null,
          rating: null,
          salesRank: 1852
        },
        {
          date: '2021-11-23',
          reviewCount: null,
          rating: null,
          salesRank: 1369
        },
        {
          date: '2021-11-24',
          reviewCount: null,
          rating: null,
          salesRank: 1206
        },
        {
          date: '2021-11-25',
          reviewCount: null,
          rating: null,
          salesRank: 946
        },
        {
          date: '2021-11-26',
          reviewCount: null,
          rating: null,
          salesRank: 1143
        },
        {
          date: '2021-11-27',
          reviewCount: null,
          rating: null,
          salesRank: 943
        },
        {
          date: '2021-11-28',
          reviewCount: null,
          rating: null,
          salesRank: 1437
        },
        {
          date: '2021-11-29',
          reviewCount: null,
          rating: null,
          salesRank: 3901
        },
        {
          date: '2021-11-30',
          reviewCount: null,
          rating: null,
          salesRank: 5406
        },
        {
          date: '2021-12-01',
          reviewCount: null,
          rating: null,
          salesRank: 5786
        },
        {
          date: '2021-12-02',
          reviewCount: null,
          rating: null,
          salesRank: 8211
        },
        {
          date: '2021-12-03',
          reviewCount: null,
          rating: null,
          salesRank: 9882
        },
        {
          date: '2021-12-04',
          reviewCount: null,
          rating: null,
          salesRank: 9784
        },
        {
          date: '2021-12-05',
          reviewCount: null,
          rating: null,
          salesRank: 11411
        },
        {
          date: '2021-12-06',
          reviewCount: null,
          rating: null,
          salesRank: 14856
        },
        {
          date: '2021-12-07',
          reviewCount: null,
          rating: null,
          salesRank: 13062
        },
        {
          date: '2021-12-08',
          reviewCount: null,
          rating: null,
          salesRank: 2211
        },
        {
          date: '2021-12-09',
          reviewCount: null,
          rating: null,
          salesRank: 1343
        },
        {
          date: '2021-12-10',
          reviewCount: null,
          rating: null,
          salesRank: 1159
        },
        {
          date: '2021-12-11',
          reviewCount: null,
          rating: null,
          salesRank: 1359
        },
        {
          date: '2021-12-12',
          reviewCount: null,
          rating: null,
          salesRank: 1573
        },
        {
          date: '2021-12-13',
          reviewCount: null,
          rating: null,
          salesRank: 1557
        },
        {
          date: '2021-12-14',
          reviewCount: null,
          rating: null,
          salesRank: 1590
        },
        {
          date: '2021-12-15',
          reviewCount: null,
          rating: null,
          salesRank: 1576
        },
        {
          date: '2021-12-16',
          reviewCount: null,
          rating: null,
          salesRank: 1571
        },
        {
          date: '2021-12-17',
          reviewCount: null,
          rating: null,
          salesRank: 1525
        },
        {
          date: '2021-12-18',
          reviewCount: null,
          rating: null,
          salesRank: 1438
        },
        {
          date: '2021-12-19',
          reviewCount: null,
          rating: null,
          salesRank: 487
        },
        {
          date: '2021-12-20',
          reviewCount: null,
          rating: null,
          salesRank: 401
        },
        {
          date: '2021-12-21',
          reviewCount: null,
          rating: null,
          salesRank: 572
        },
        {
          date: '2021-12-22',
          reviewCount: null,
          rating: null,
          salesRank: 667
        },
        {
          date: '2021-12-23',
          reviewCount: null,
          rating: null,
          salesRank: 671
        },
        {
          date: '2021-12-24',
          reviewCount: null,
          rating: null,
          salesRank: 688
        },
        {
          date: '2021-12-25',
          reviewCount: null,
          rating: null,
          salesRank: 744
        },
        {
          date: '2021-12-26',
          reviewCount: null,
          rating: null,
          salesRank: 732
        },
        {
          date: '2021-12-27',
          reviewCount: null,
          rating: null,
          salesRank: 598
        },
        {
          date: '2021-12-28',
          reviewCount: null,
          rating: null,
          salesRank: 503
        },
        {
          date: '2021-12-29',
          reviewCount: null,
          rating: null,
          salesRank: 525
        },
        {
          date: '2021-12-30',
          reviewCount: null,
          rating: null,
          salesRank: 547
        },
        {
          date: '2021-12-31',
          reviewCount: null,
          rating: null,
          salesRank: 601
        },
        {
          date: '2022-01-01',
          reviewCount: null,
          rating: null,
          salesRank: 727
        },
        {
          date: '2022-01-02',
          reviewCount: null,
          rating: null,
          salesRank: 582
        },
        {
          date: '2022-01-03',
          reviewCount: null,
          rating: null,
          salesRank: 480
        },
        {
          date: '2022-01-04',
          reviewCount: null,
          rating: null,
          salesRank: 402
        },
        {
          date: '2022-01-05',
          reviewCount: null,
          rating: null,
          salesRank: 310
        },
        {
          date: '2022-01-06',
          reviewCount: null,
          rating: null,
          salesRank: 336
        },
        {
          date: '2022-01-07',
          reviewCount: null,
          rating: null,
          salesRank: 296
        },
        {
          date: '2022-01-08',
          reviewCount: null,
          rating: null,
          salesRank: 248
        },
        {
          date: '2022-01-09',
          reviewCount: null,
          rating: null,
          salesRank: 258
        },
        {
          date: '2022-01-10',
          reviewCount: null,
          rating: null,
          salesRank: 207
        },
        {
          date: '2022-01-11',
          reviewCount: null,
          rating: null,
          salesRank: 154
        },
        {
          date: '2022-01-12',
          reviewCount: null,
          rating: null,
          salesRank: 181
        },
        {
          date: '2022-01-13',
          reviewCount: null,
          rating: null,
          salesRank: 174
        },
        {
          date: '2022-01-14',
          reviewCount: null,
          rating: null,
          salesRank: 202
        },
        {
          date: '2022-01-15',
          reviewCount: null,
          rating: null,
          salesRank: 232
        },
        {
          date: '2022-01-16',
          reviewCount: null,
          rating: null,
          salesRank: 358
        },
        {
          date: '2022-01-17',
          reviewCount: null,
          rating: null,
          salesRank: 641
        },
        {
          date: '2022-01-18',
          reviewCount: null,
          rating: null,
          salesRank: 688
        },
        {
          date: '2022-01-19',
          reviewCount: null,
          rating: null,
          salesRank: 642
        },
        {
          date: '2022-01-20',
          reviewCount: null,
          rating: null,
          salesRank: 646
        },
        {
          date: '2022-01-21',
          reviewCount: null,
          rating: null,
          salesRank: 621
        },
        {
          date: '2022-01-22',
          reviewCount: null,
          rating: null,
          salesRank: 488
        },
        {
          date: '2022-01-23',
          reviewCount: null,
          rating: null,
          salesRank: 436
        },
        {
          date: '2022-01-24',
          reviewCount: null,
          rating: null,
          salesRank: 398
        },
        {
          date: '2022-01-25',
          reviewCount: null,
          rating: null,
          salesRank: 350
        },
        {
          date: '2022-01-26',
          reviewCount: null,
          rating: null,
          salesRank: 292
        },
        {
          date: '2022-01-27',
          reviewCount: null,
          rating: null,
          salesRank: 271
        },
        {
          date: '2022-01-28',
          reviewCount: null,
          rating: null,
          salesRank: 236
        },
        {
          date: '2022-01-29',
          reviewCount: null,
          rating: null,
          salesRank: 218
        },
        {
          date: '2022-01-30',
          reviewCount: null,
          rating: null,
          salesRank: 222
        },
        {
          date: '2022-01-31',
          reviewCount: null,
          rating: null,
          salesRank: 240
        },
        {
          date: '2022-02-01',
          reviewCount: null,
          rating: null,
          salesRank: 248
        },
        {
          date: '2022-02-02',
          reviewCount: null,
          rating: null,
          salesRank: 196
        },
        {
          date: '2022-02-03',
          reviewCount: null,
          rating: null,
          salesRank: 208
        },
        {
          date: '2022-02-04',
          reviewCount: null,
          rating: null,
          salesRank: 321
        },
        {
          date: '2022-02-05',
          reviewCount: null,
          rating: null,
          salesRank: 437
        },
        {
          date: '2022-02-06',
          reviewCount: null,
          rating: null,
          salesRank: 425
        },
        {
          date: '2022-02-07',
          reviewCount: null,
          rating: null,
          salesRank: 513
        },
        {
          date: '2022-02-08',
          reviewCount: null,
          rating: null,
          salesRank: 486
        },
        {
          date: '2022-02-09',
          reviewCount: null,
          rating: null,
          salesRank: 502
        },
        {
          date: '2022-02-10',
          reviewCount: null,
          rating: null,
          salesRank: 602
        },
        {
          date: '2022-02-11',
          reviewCount: null,
          rating: null,
          salesRank: 794
        },
        {
          date: '2022-02-12',
          reviewCount: null,
          rating: null,
          salesRank: 907
        },
        {
          date: '2022-02-13',
          reviewCount: null,
          rating: null,
          salesRank: 940
        },
        {
          date: '2022-02-14',
          reviewCount: null,
          rating: null,
          salesRank: 844
        },
        {
          date: '2022-02-15',
          reviewCount: null,
          rating: null,
          salesRank: 180
        },
        {
          date: '2022-02-16',
          reviewCount: null,
          rating: null,
          salesRank: 392
        },
        {
          date: '2022-02-17',
          reviewCount: null,
          rating: null,
          salesRank: 597
        },
        {
          date: '2022-02-18',
          reviewCount: null,
          rating: null,
          salesRank: 754
        },
        {
          date: '2022-02-19',
          reviewCount: null,
          rating: null,
          salesRank: 1002
        },
        {
          date: '2022-02-20',
          reviewCount: null,
          rating: null,
          salesRank: 1034
        },
        {
          date: '2022-02-21',
          reviewCount: null,
          rating: null,
          salesRank: 1262
        },
        {
          date: '2022-02-22',
          reviewCount: null,
          rating: null,
          salesRank: 1181
        },
        {
          date: '2022-02-23',
          reviewCount: null,
          rating: null,
          salesRank: 1457
        },
        {
          date: '2022-02-24',
          reviewCount: null,
          rating: null,
          salesRank: 1610
        },
        {
          date: '2022-02-25',
          reviewCount: null,
          rating: null,
          salesRank: 1237
        },
        {
          date: '2022-02-26',
          reviewCount: null,
          rating: null,
          salesRank: 1484
        },
        {
          date: '2022-02-27',
          reviewCount: null,
          rating: null,
          salesRank: 1236
        },
        {
          date: '2022-02-28',
          reviewCount: null,
          rating: null,
          salesRank: 1196
        },
        {
          date: '2022-03-01',
          reviewCount: null,
          rating: null,
          salesRank: 1392
        },
        {
          date: '2022-03-02',
          reviewCount: null,
          rating: null,
          salesRank: 1432
        },
        {
          date: '2022-03-03',
          reviewCount: null,
          rating: null,
          salesRank: 1332
        },
        {
          date: '2022-03-04',
          reviewCount: null,
          rating: null,
          salesRank: 1899
        },
        {
          date: '2022-03-05',
          reviewCount: null,
          rating: null,
          salesRank: 2042
        },
        {
          date: '2022-03-06',
          reviewCount: null,
          rating: null,
          salesRank: 2766
        },
        {
          date: '2022-03-07',
          reviewCount: null,
          rating: null,
          salesRank: 2530
        },
        {
          date: '2022-03-08',
          reviewCount: null,
          rating: null,
          salesRank: 2229
        },
        {
          date: '2022-03-09',
          reviewCount: null,
          rating: null,
          salesRank: 2071
        },
        {
          date: '2022-03-10',
          reviewCount: null,
          rating: null,
          salesRank: 2313
        },
        {
          date: '2022-03-11',
          reviewCount: null,
          rating: null,
          salesRank: 2659
        },
        {
          date: '2022-03-12',
          reviewCount: null,
          rating: null,
          salesRank: 2696
        },
        {
          date: '2022-03-13',
          reviewCount: null,
          rating: null,
          salesRank: 2517
        },
        {
          date: '2022-03-14',
          reviewCount: null,
          rating: null,
          salesRank: 2518
        },
        {
          date: '2022-03-15',
          reviewCount: null,
          rating: null,
          salesRank: 2663
        },
        {
          date: '2022-03-16',
          reviewCount: null,
          rating: null,
          salesRank: 3611
        },
        {
          date: '2022-03-17',
          reviewCount: null,
          rating: null,
          salesRank: 3002
        },
        {
          date: '2022-03-18',
          reviewCount: null,
          rating: null,
          salesRank: 3512
        },
        {
          date: '2022-03-19',
          reviewCount: null,
          rating: null,
          salesRank: 3801
        },
        {
          date: '2022-03-20',
          reviewCount: null,
          rating: null,
          salesRank: 4508
        },
        {
          date: '2022-03-21',
          reviewCount: null,
          rating: null,
          salesRank: 4180
        },
        {
          date: '2022-03-22',
          reviewCount: null,
          rating: null,
          salesRank: 4149
        },
        {
          date: '2022-03-23',
          reviewCount: null,
          rating: null,
          salesRank: 3893
        },
        {
          date: '2022-03-24',
          reviewCount: null,
          rating: null,
          salesRank: 3806
        },
        {
          date: '2022-03-25',
          reviewCount: null,
          rating: null,
          salesRank: 4460
        },
        {
          date: '2022-03-26',
          reviewCount: null,
          rating: null,
          salesRank: 4172
        },
        {
          date: '2022-03-27',
          reviewCount: null,
          rating: null,
          salesRank: 4374
        },
        {
          date: '2022-03-28',
          reviewCount: null,
          rating: null,
          salesRank: 3733
        },
        {
          date: '2022-03-29',
          reviewCount: null,
          rating: null,
          salesRank: 3727
        },
        {
          date: '2022-03-30',
          reviewCount: null,
          rating: null,
          salesRank: 4945
        },
        {
          date: '2022-03-31',
          reviewCount: null,
          rating: null,
          salesRank: 5141
        },
        {
          date: '2022-04-01',
          reviewCount: null,
          rating: null,
          salesRank: 4859
        },
        {
          date: '2022-04-02',
          reviewCount: null,
          rating: null,
          salesRank: 5416
        },
        {
          date: '2022-04-03',
          reviewCount: null,
          rating: null,
          salesRank: 5496
        },
        {
          date: '2022-04-04',
          reviewCount: null,
          rating: null,
          salesRank: 5215
        },
        {
          date: '2022-04-05',
          reviewCount: null,
          rating: null,
          salesRank: 5110
        },
        {
          date: '2022-04-06',
          reviewCount: null,
          rating: null,
          salesRank: 5378
        },
        {
          date: '2022-04-07',
          reviewCount: null,
          rating: null,
          salesRank: 5253
        },
        {
          date: '2022-04-08',
          reviewCount: null,
          rating: null,
          salesRank: 5617
        },
        {
          date: '2022-04-09',
          reviewCount: null,
          rating: null,
          salesRank: 5064
        },
        {
          date: '2022-04-10',
          reviewCount: null,
          rating: null,
          salesRank: 5932
        },
        {
          date: '2022-04-11',
          reviewCount: null,
          rating: null,
          salesRank: 5673
        },
        {
          date: '2022-04-12',
          reviewCount: null,
          rating: null,
          salesRank: 5914
        },
        {
          date: '2022-04-13',
          reviewCount: null,
          rating: null,
          salesRank: 6144
        },
        {
          date: '2022-04-14',
          reviewCount: null,
          rating: null,
          salesRank: 6944
        },
        {
          date: '2022-04-15',
          reviewCount: null,
          rating: null,
          salesRank: 6442
        },
        {
          date: '2022-04-17',
          reviewCount: null,
          rating: null,
          salesRank: 6602
        },
        {
          date: '2022-04-18',
          reviewCount: null,
          rating: null,
          salesRank: 5422
        },
        {
          date: '2022-04-19',
          reviewCount: null,
          rating: null,
          salesRank: 5951
        },
        {
          date: '2022-04-20',
          reviewCount: null,
          rating: null,
          salesRank: 5305
        },
        {
          date: '2022-04-21',
          reviewCount: null,
          rating: null,
          salesRank: 5145
        },
        {
          date: '2022-04-22',
          reviewCount: null,
          rating: null,
          salesRank: 6149
        },
        {
          date: '2022-04-23',
          reviewCount: null,
          rating: null,
          salesRank: 6634
        },
        {
          date: '2022-04-24',
          reviewCount: null,
          rating: null,
          salesRank: 6113
        },
        {
          date: '2022-04-25',
          reviewCount: null,
          rating: null,
          salesRank: 5205
        },
        {
          date: '2022-04-26',
          reviewCount: null,
          rating: null,
          salesRank: 5425
        },
        {
          date: '2022-04-27',
          reviewCount: null,
          rating: null,
          salesRank: 5542
        },
        {
          date: '2022-04-28',
          reviewCount: null,
          rating: null,
          salesRank: 5685
        },
        {
          date: '2022-04-29',
          reviewCount: null,
          rating: null,
          salesRank: 6791
        },
        {
          date: '2022-04-30',
          reviewCount: null,
          rating: null,
          salesRank: 7448
        },
        {
          date: '2022-05-01',
          reviewCount: null,
          rating: null,
          salesRank: 6592
        },
        {
          date: '2022-05-02',
          reviewCount: null,
          rating: null,
          salesRank: 7942
        },
        {
          date: '2022-05-03',
          reviewCount: null,
          rating: null,
          salesRank: 8731
        },
        {
          date: '2022-05-04',
          reviewCount: null,
          rating: null,
          salesRank: 7598
        },
        {
          date: '2022-05-05',
          reviewCount: null,
          rating: null,
          salesRank: 8522
        },
        {
          date: '2022-05-06',
          reviewCount: null,
          rating: null,
          salesRank: 9520
        },
        {
          date: '2022-05-07',
          reviewCount: null,
          rating: null,
          salesRank: 9323
        },
        {
          date: '2022-05-08',
          reviewCount: null,
          rating: null,
          salesRank: 11550
        },
        {
          date: '2022-05-09',
          reviewCount: null,
          rating: null,
          salesRank: 9666
        },
        {
          date: '2022-05-10',
          reviewCount: null,
          rating: null,
          salesRank: 6482
        },
        {
          date: '2022-05-11',
          reviewCount: null,
          rating: null,
          salesRank: 5968
        },
        {
          date: '2022-05-12',
          reviewCount: null,
          rating: null,
          salesRank: 7073
        },
        {
          date: '2022-05-13',
          reviewCount: null,
          rating: null,
          salesRank: 6532
        },
        {
          date: '2022-05-14',
          reviewCount: null,
          rating: null,
          salesRank: 7048
        },
        {
          date: '2022-05-15',
          reviewCount: null,
          rating: null,
          salesRank: 7848
        },
        {
          date: '2022-05-16',
          reviewCount: null,
          rating: null,
          salesRank: 8278
        },
        {
          date: '2022-05-17',
          reviewCount: null,
          rating: null,
          salesRank: 7898
        },
        {
          date: '2022-05-18',
          reviewCount: null,
          rating: null,
          salesRank: 7382
        },
        {
          date: '2022-05-19',
          reviewCount: null,
          rating: null,
          salesRank: 6782
        },
        {
          date: '2022-05-20',
          reviewCount: null,
          rating: null,
          salesRank: 7410
        },
        {
          date: '2022-05-21',
          reviewCount: null,
          rating: null,
          salesRank: 6949
        },
        {
          date: '2022-05-22',
          reviewCount: null,
          rating: null,
          salesRank: 10522
        },
        {
          date: '2022-05-23',
          reviewCount: null,
          rating: null,
          salesRank: 8895
        },
        {
          date: '2022-05-24',
          reviewCount: null,
          rating: null,
          salesRank: 7875
        },
        {
          date: '2022-05-25',
          reviewCount: null,
          rating: null,
          salesRank: 7655
        },
        {
          date: '2022-05-26',
          reviewCount: null,
          rating: null,
          salesRank: 7968
        },
        {
          date: '2022-05-27',
          reviewCount: null,
          rating: null,
          salesRank: 7701
        },
        {
          date: '2022-05-28',
          reviewCount: null,
          rating: null,
          salesRank: 8920
        },
        {
          date: '2022-05-29',
          reviewCount: null,
          rating: null,
          salesRank: 8519
        },
        {
          date: '2022-05-30',
          reviewCount: null,
          rating: null,
          salesRank: 9192
        },
        {
          date: '2022-05-31',
          reviewCount: null,
          rating: null,
          salesRank: 9019
        },
        {
          date: '2022-06-01',
          reviewCount: null,
          rating: null,
          salesRank: 9777
        },
        {
          date: '2022-06-02',
          reviewCount: null,
          rating: null,
          salesRank: 8079
        },
        {
          date: '2022-06-03',
          reviewCount: null,
          rating: null,
          salesRank: 7431
        },
        {
          date: '2022-06-04',
          reviewCount: null,
          rating: null,
          salesRank: 8379
        },
        {
          date: '2022-06-05',
          reviewCount: null,
          rating: null,
          salesRank: 10215
        },
        {
          date: '2022-06-06',
          reviewCount: null,
          rating: null,
          salesRank: 9563
        },
        {
          date: '2022-06-07',
          reviewCount: null,
          rating: null,
          salesRank: 9489
        },
        {
          date: '2022-06-08',
          reviewCount: null,
          rating: null,
          salesRank: 9810
        },
        {
          date: '2022-06-09',
          reviewCount: null,
          rating: null,
          salesRank: 8333
        },
        {
          date: '2022-06-10',
          reviewCount: null,
          rating: null,
          salesRank: 10841
        },
        {
          date: '2022-06-11',
          reviewCount: null,
          rating: null,
          salesRank: 10466
        },
        {
          date: '2022-06-12',
          reviewCount: null,
          rating: null,
          salesRank: 10637
        },
        {
          date: '2022-06-13',
          reviewCount: null,
          rating: null,
          salesRank: 11827
        },
        {
          date: '2022-06-14',
          reviewCount: null,
          rating: null,
          salesRank: 10220
        },
        {
          date: '2022-06-15',
          reviewCount: null,
          rating: null,
          salesRank: 10281
        },
        {
          date: '2022-06-16',
          reviewCount: null,
          rating: null,
          salesRank: 9442
        },
        {
          date: '2022-06-18',
          reviewCount: null,
          rating: null,
          salesRank: 11080
        },
        {
          date: '2022-06-19',
          reviewCount: null,
          rating: null,
          salesRank: 12107
        },
        {
          date: '2022-06-20',
          reviewCount: null,
          rating: null,
          salesRank: 14318
        },
        {
          date: '2022-06-21',
          reviewCount: null,
          rating: null,
          salesRank: 12499
        },
        {
          date: '2022-06-22',
          reviewCount: null,
          rating: null,
          salesRank: 11547
        },
        {
          date: '2022-06-23',
          reviewCount: null,
          rating: null,
          salesRank: 10129
        },
        {
          date: '2022-06-24',
          reviewCount: null,
          rating: null,
          salesRank: 10525
        },
        {
          date: '2022-06-25',
          reviewCount: null,
          rating: null,
          salesRank: 12973
        },
        {
          date: '2022-06-26',
          reviewCount: null,
          rating: null,
          salesRank: 12267
        },
        {
          date: '2022-06-27',
          reviewCount: null,
          rating: null,
          salesRank: 10115
        },
        {
          date: '2022-06-28',
          reviewCount: null,
          rating: null,
          salesRank: 11138
        },
        {
          date: '2022-06-29',
          reviewCount: null,
          rating: null,
          salesRank: 11433
        },
        {
          date: '2022-06-30',
          reviewCount: null,
          rating: null,
          salesRank: 11770
        },
        {
          date: '2022-07-01',
          reviewCount: null,
          rating: null,
          salesRank: 12682
        },
        {
          date: '2022-07-02',
          reviewCount: null,
          rating: null,
          salesRank: 12531
        },
        {
          date: '2022-07-03',
          reviewCount: null,
          rating: null,
          salesRank: 13870
        },
        {
          date: '2022-07-04',
          reviewCount: null,
          rating: null,
          salesRank: 12364
        },
        {
          date: '2022-07-05',
          reviewCount: null,
          rating: null,
          salesRank: 13028
        },
        {
          date: '2022-07-06',
          reviewCount: null,
          rating: null,
          salesRank: 15032
        },
        {
          date: '2022-07-07',
          reviewCount: null,
          rating: null,
          salesRank: 11692
        },
        {
          date: '2022-07-08',
          reviewCount: null,
          rating: null,
          salesRank: 11685
        },
        {
          date: '2022-07-09',
          reviewCount: null,
          rating: null,
          salesRank: 11719
        },
        {
          date: '2022-07-10',
          reviewCount: null,
          rating: null,
          salesRank: 14213
        },
        {
          date: '2022-07-11',
          reviewCount: null,
          rating: null,
          salesRank: 10471
        },
        {
          date: '2022-07-12',
          reviewCount: null,
          rating: null,
          salesRank: 7204
        },
        {
          date: '2022-07-13',
          reviewCount: null,
          rating: null,
          salesRank: 6308
        },
        {
          date: '2022-07-14',
          reviewCount: null,
          rating: null,
          salesRank: 7256
        },
        {
          date: '2022-07-15',
          reviewCount: null,
          rating: null,
          salesRank: 7967
        },
        {
          date: '2022-07-16',
          reviewCount: null,
          rating: null,
          salesRank: 7866
        },
        {
          date: '2022-07-17',
          reviewCount: null,
          rating: null,
          salesRank: 8852
        },
        {
          date: '2022-07-18',
          reviewCount: null,
          rating: null,
          salesRank: 9200
        },
        {
          date: '2022-07-19',
          reviewCount: null,
          rating: null,
          salesRank: 10586
        },
        {
          date: '2022-07-20',
          reviewCount: null,
          rating: null,
          salesRank: 10968
        },
        {
          date: '2022-07-21',
          reviewCount: null,
          rating: null,
          salesRank: 9791
        },
        {
          date: '2022-07-22',
          reviewCount: null,
          rating: null,
          salesRank: 9018
        },
        {
          date: '2022-07-23',
          reviewCount: null,
          rating: null,
          salesRank: 9067
        },
        {
          date: '2022-07-24',
          reviewCount: null,
          rating: null,
          salesRank: 8757
        },
        {
          date: '2022-07-25',
          reviewCount: null,
          rating: null,
          salesRank: 8621
        },
        {
          date: '2022-07-26',
          reviewCount: null,
          rating: null,
          salesRank: 8296
        },
        {
          date: '2022-07-27',
          reviewCount: null,
          rating: null,
          salesRank: 8760
        },
        {
          date: '2022-07-28',
          reviewCount: null,
          rating: null,
          salesRank: 8213
        },
        {
          date: '2022-07-29',
          reviewCount: null,
          rating: null,
          salesRank: 8937
        },
        {
          date: '2022-07-30',
          reviewCount: null,
          rating: null,
          salesRank: 14814
        },
        {
          date: '2022-07-31',
          reviewCount: null,
          rating: null,
          salesRank: 14161
        },
        {
          date: '2022-08-01',
          reviewCount: null,
          rating: null,
          salesRank: 13476
        },
        {
          date: '2022-08-02',
          reviewCount: null,
          rating: null,
          salesRank: 12966
        },
        {
          date: '2022-08-03',
          reviewCount: null,
          rating: null,
          salesRank: 11719
        },
        {
          date: '2022-08-04',
          reviewCount: null,
          rating: null,
          salesRank: 12102
        },
        {
          date: '2022-08-05',
          reviewCount: null,
          rating: null,
          salesRank: 11171
        },
        {
          date: '2022-08-06',
          reviewCount: null,
          rating: null,
          salesRank: 11509
        },
        {
          date: '2022-08-07',
          reviewCount: null,
          rating: null,
          salesRank: 12812
        },
        {
          date: '2022-08-08',
          reviewCount: null,
          rating: null,
          salesRank: 10550
        },
        {
          date: '2022-08-09',
          reviewCount: null,
          rating: null,
          salesRank: 9590
        },
        {
          date: '2022-08-10',
          reviewCount: null,
          rating: null,
          salesRank: 11121
        },
        {
          date: '2022-08-11',
          reviewCount: null,
          rating: null,
          salesRank: 14662
        },
        {
          date: '2022-08-12',
          reviewCount: null,
          rating: null,
          salesRank: 14000
        },
        {
          date: '2022-08-13',
          reviewCount: null,
          rating: null,
          salesRank: 11945
        },
        {
          date: '2022-08-14',
          reviewCount: null,
          rating: null,
          salesRank: 12619
        },
        {
          date: '2022-08-15',
          reviewCount: null,
          rating: null,
          salesRank: 13208
        },
        {
          date: '2022-08-16',
          reviewCount: null,
          rating: null,
          salesRank: 13211
        },
        {
          date: '2022-08-17',
          reviewCount: null,
          rating: null,
          salesRank: 13384
        },
        {
          date: '2022-08-18',
          reviewCount: null,
          rating: null,
          salesRank: 14352
        },
        {
          date: '2022-08-19',
          reviewCount: null,
          rating: null,
          salesRank: 17011
        },
        {
          date: '2022-08-20',
          reviewCount: null,
          rating: null,
          salesRank: 15956
        },
        {
          date: '2022-08-21',
          reviewCount: null,
          rating: null,
          salesRank: 16878
        },
        {
          date: '2022-08-22',
          reviewCount: null,
          rating: null,
          salesRank: 13810
        },
        {
          date: '2022-08-23',
          reviewCount: null,
          rating: null,
          salesRank: 12385
        },
        {
          date: '2022-08-24',
          reviewCount: null,
          rating: null,
          salesRank: 14568
        },
        {
          date: '2022-08-25',
          reviewCount: null,
          rating: null,
          salesRank: 14556
        },
        {
          date: '2022-08-26',
          reviewCount: null,
          rating: null,
          salesRank: 14408
        },
        {
          date: '2022-08-27',
          reviewCount: null,
          rating: null,
          salesRank: 14541
        },
        {
          date: '2022-08-28',
          reviewCount: null,
          rating: null,
          salesRank: 14461
        },
        {
          date: '2022-08-29',
          reviewCount: null,
          rating: null,
          salesRank: 14427
        },
        {
          date: '2022-08-30',
          reviewCount: null,
          rating: null,
          salesRank: 12420
        },
        {
          date: '2022-08-31',
          reviewCount: null,
          rating: null,
          salesRank: 13762
        },
        {
          date: '2022-09-01',
          reviewCount: null,
          rating: null,
          salesRank: 11969
        },
        {
          date: '2022-09-02',
          reviewCount: null,
          rating: null,
          salesRank: 8876
        },
        {
          date: '2022-09-03',
          reviewCount: null,
          rating: null,
          salesRank: 14790
        },
        {
          date: '2022-09-04',
          reviewCount: null,
          rating: null,
          salesRank: 16412
        },
        {
          date: '2022-09-05',
          reviewCount: null,
          rating: null,
          salesRank: 12816
        },
        {
          date: '2022-09-06',
          reviewCount: null,
          rating: null,
          salesRank: 11532
        },
        {
          date: '2022-09-07',
          reviewCount: null,
          rating: null,
          salesRank: 8691
        },
        {
          date: '2022-09-08',
          reviewCount: null,
          rating: null,
          salesRank: 9650
        },
        {
          date: '2022-09-09',
          reviewCount: null,
          rating: null,
          salesRank: 8150
        },
        {
          date: '2022-09-10',
          reviewCount: null,
          rating: null,
          salesRank: 10340
        },
        {
          date: '2022-09-11',
          reviewCount: null,
          rating: null,
          salesRank: 8000
        },
        {
          date: '2022-09-12',
          reviewCount: null,
          rating: null,
          salesRank: 7860
        },
        {
          date: '2022-09-13',
          reviewCount: null,
          rating: null,
          salesRank: 7193
        },
        {
          date: '2022-09-14',
          reviewCount: null,
          rating: null,
          salesRank: 7103
        },
        {
          date: '2022-09-15',
          reviewCount: null,
          rating: null,
          salesRank: 9428
        },
        {
          date: '2022-09-16',
          reviewCount: null,
          rating: null,
          salesRank: 7288
        },
        {
          date: '2022-09-17',
          reviewCount: null,
          rating: null,
          salesRank: 7286
        },
        {
          date: '2022-09-18',
          reviewCount: null,
          rating: null,
          salesRank: 7614
        },
        {
          date: '2022-09-19',
          reviewCount: null,
          rating: null,
          salesRank: 6224
        },
        {
          date: '2022-09-20',
          reviewCount: null,
          rating: null,
          salesRank: 6066
        },
        {
          date: '2022-09-21',
          reviewCount: null,
          rating: null,
          salesRank: 5954
        },
        {
          date: '2022-09-22',
          reviewCount: null,
          rating: null,
          salesRank: 7350
        },
        {
          date: '2022-09-23',
          reviewCount: null,
          rating: null,
          salesRank: 7767
        },
        {
          date: '2022-09-24',
          reviewCount: null,
          rating: null,
          salesRank: 7451
        },
        {
          date: '2022-09-25',
          reviewCount: null,
          rating: null,
          salesRank: 10200
        },
        {
          date: '2022-09-26',
          reviewCount: null,
          rating: null,
          salesRank: 7845
        },
        {
          date: '2022-09-27',
          reviewCount: null,
          rating: null,
          salesRank: 6989
        },
        {
          date: '2022-09-28',
          reviewCount: null,
          rating: null,
          salesRank: 6339
        },
        {
          date: '2022-09-29',
          reviewCount: null,
          rating: null,
          salesRank: 5165
        },
        {
          date: '2022-09-30',
          reviewCount: null,
          rating: null,
          salesRank: 5437
        },
        {
          date: '2022-10-01',
          reviewCount: null,
          rating: null,
          salesRank: 5631
        },
        {
          date: '2022-10-02',
          reviewCount: null,
          rating: null,
          salesRank: 5256
        },
        {
          date: '2022-10-03',
          reviewCount: null,
          rating: null,
          salesRank: 5207
        },
        {
          date: '2022-10-04',
          reviewCount: null,
          rating: null,
          salesRank: 4395
        },
        {
          date: '2022-10-05',
          reviewCount: null,
          rating: null,
          salesRank: 3166
        },
        {
          date: '2022-10-06',
          reviewCount: null,
          rating: null,
          salesRank: 3678
        },
        {
          date: '2022-10-07',
          reviewCount: null,
          rating: null,
          salesRank: 4147
        },
        {
          date: '2022-10-08',
          reviewCount: null,
          rating: null,
          salesRank: 5355
        },
        {
          date: '2022-10-09',
          reviewCount: null,
          rating: null,
          salesRank: 5089
        },
        {
          date: '2022-10-10',
          reviewCount: null,
          rating: null,
          salesRank: 3389
        },
        {
          date: '2022-10-11',
          reviewCount: null,
          rating: null,
          salesRank: 3175
        },
        {
          date: '2022-10-12',
          reviewCount: null,
          rating: null,
          salesRank: 2843
        },
        {
          date: '2022-10-13',
          reviewCount: null,
          rating: null,
          salesRank: 3058
        },
        {
          date: '2022-10-14',
          reviewCount: null,
          rating: null,
          salesRank: 3787
        },
        {
          date: '2022-10-15',
          reviewCount: null,
          rating: null,
          salesRank: 3843
        },
        {
          date: '2022-10-16',
          reviewCount: null,
          rating: null,
          salesRank: 4691
        },
        {
          date: '2022-10-17',
          reviewCount: null,
          rating: null,
          salesRank: 4136
        },
        {
          date: '2022-10-18',
          reviewCount: null,
          rating: null,
          salesRank: 3871
        },
        {
          date: '2022-10-19',
          reviewCount: null,
          rating: null,
          salesRank: 2330
        },
        {
          date: '2022-10-20',
          reviewCount: null,
          rating: null,
          salesRank: 2515
        },
        {
          date: '2022-10-21',
          reviewCount: null,
          rating: null,
          salesRank: 2617
        },
        {
          date: '2022-10-22',
          reviewCount: null,
          rating: null,
          salesRank: 2270
        },
        {
          date: '2022-10-23',
          reviewCount: null,
          rating: null,
          salesRank: 2902
        },
        {
          date: '2022-10-24',
          reviewCount: null,
          rating: null,
          salesRank: 2769
        },
        {
          date: '2022-10-25',
          reviewCount: null,
          rating: null,
          salesRank: 1988
        },
        {
          date: '2022-10-26',
          reviewCount: null,
          rating: null,
          salesRank: 2084
        },
        {
          date: '2022-10-27',
          reviewCount: null,
          rating: null,
          salesRank: 1880
        },
        {
          date: '2022-10-28',
          reviewCount: null,
          rating: null,
          salesRank: 1845
        },
        {
          date: '2022-10-29',
          reviewCount: null,
          rating: null,
          salesRank: 2108
        },
        {
          date: '2022-10-30',
          reviewCount: null,
          rating: null,
          salesRank: 2685
        },
        {
          date: '2022-10-31',
          reviewCount: null,
          rating: null,
          salesRank: 2304
        },
        {
          date: '2022-11-01',
          reviewCount: null,
          rating: null,
          salesRank: 2264
        },
        {
          date: '2022-11-02',
          reviewCount: null,
          rating: null,
          salesRank: 2124
        },
        {
          date: '2022-11-03',
          reviewCount: null,
          rating: null,
          salesRank: 2349
        },
        {
          date: '2022-11-04',
          reviewCount: null,
          rating: null,
          salesRank: 2137
        },
        {
          date: '2022-11-05',
          reviewCount: null,
          rating: null,
          salesRank: 2563
        },
        {
          date: '2022-11-06',
          reviewCount: null,
          rating: null,
          salesRank: 3837
        },
        {
          date: '2022-11-07',
          reviewCount: null,
          rating: null,
          salesRank: 2972
        },
        {
          date: '2022-11-08',
          reviewCount: null,
          rating: null,
          salesRank: 2564
        },
        {
          date: '2022-11-09',
          reviewCount: null,
          rating: null,
          salesRank: 2499
        },
        {
          date: '2022-11-10',
          reviewCount: null,
          rating: null,
          salesRank: 2709
        },
        {
          date: '2022-11-11',
          reviewCount: null,
          rating: null,
          salesRank: 3172
        },
        {
          date: '2022-11-12',
          reviewCount: null,
          rating: null,
          salesRank: 3634
        },
        {
          date: '2022-11-13',
          reviewCount: null,
          rating: null,
          salesRank: 4188
        },
        {
          date: '2022-11-14',
          reviewCount: null,
          rating: null,
          salesRank: 3693
        },
        {
          date: '2022-11-15',
          reviewCount: null,
          rating: null,
          salesRank: 2528
        },
        {
          date: '2022-11-16',
          reviewCount: null,
          rating: null,
          salesRank: 1544
        },
        {
          date: '2022-11-17',
          reviewCount: null,
          rating: null,
          salesRank: 1440
        },
        {
          date: '2022-11-18',
          reviewCount: null,
          rating: null,
          salesRank: 1230
        },
        {
          date: '2022-11-19',
          reviewCount: null,
          rating: null,
          salesRank: 1265
        },
        {
          date: '2022-11-20',
          reviewCount: null,
          rating: null,
          salesRank: 1153
        },
        {
          date: '2022-11-21',
          reviewCount: null,
          rating: null,
          salesRank: 855
        },
        {
          date: '2022-11-22',
          reviewCount: null,
          rating: null,
          salesRank: 820
        },
        {
          date: '2022-11-23',
          reviewCount: null,
          rating: null,
          salesRank: 1510
        },
        {
          date: '2022-11-24',
          reviewCount: null,
          rating: null,
          salesRank: 2135
        },
        {
          date: '2022-11-25',
          reviewCount: null,
          rating: null,
          salesRank: 2897
        },
        {
          date: '2022-11-26',
          reviewCount: null,
          rating: null,
          salesRank: 2018
        },
        {
          date: '2022-11-27',
          reviewCount: null,
          rating: null,
          salesRank: 1947
        },
        {
          date: '2022-11-28',
          reviewCount: null,
          rating: null,
          salesRank: 1751
        },
        {
          date: '2022-11-29',
          reviewCount: null,
          rating: null,
          salesRank: 2441
        },
        {
          date: '2022-11-30',
          reviewCount: null,
          rating: null,
          salesRank: 4370
        },
        {
          date: '2022-12-01',
          reviewCount: null,
          rating: null,
          salesRank: 6190
        },
        {
          date: '2022-12-02',
          reviewCount: null,
          rating: null,
          salesRank: 3241
        },
        {
          date: '2022-12-03',
          reviewCount: null,
          rating: null,
          salesRank: 2590
        },
        {
          date: '2022-12-04',
          reviewCount: null,
          rating: null,
          salesRank: 2679
        },
        {
          date: '2022-12-05',
          reviewCount: null,
          rating: null,
          salesRank: 2492
        },
        {
          date: '2022-12-06',
          reviewCount: null,
          rating: null,
          salesRank: 1727
        },
        {
          date: '2022-12-07',
          reviewCount: null,
          rating: null,
          salesRank: 2221
        },
        {
          date: '2022-12-08',
          reviewCount: null,
          rating: null,
          salesRank: 2675
        },
        {
          date: '2022-12-09',
          reviewCount: null,
          rating: null,
          salesRank: 3567
        },
        {
          date: '2022-12-10',
          reviewCount: null,
          rating: null,
          salesRank: 3955
        },
        {
          date: '2022-12-11',
          reviewCount: null,
          rating: null,
          salesRank: 4125
        },
        {
          date: '2022-12-12',
          reviewCount: null,
          rating: null,
          salesRank: 4132
        },
        {
          date: '2022-12-13',
          reviewCount: null,
          rating: null,
          salesRank: 3231
        },
        {
          date: '2022-12-14',
          reviewCount: null,
          rating: null,
          salesRank: 2857
        },
        {
          date: '2022-12-15',
          reviewCount: null,
          rating: null,
          salesRank: 2589
        },
        {
          date: '2022-12-16',
          reviewCount: null,
          rating: null,
          salesRank: 2255
        },
        {
          date: '2022-12-17',
          reviewCount: null,
          rating: null,
          salesRank: 2150
        },
        {
          date: '2022-12-18',
          reviewCount: null,
          rating: null,
          salesRank: 2423
        },
        {
          date: '2022-12-19',
          reviewCount: null,
          rating: null,
          salesRank: 2190
        },
        {
          date: '2022-12-20',
          reviewCount: null,
          rating: null,
          salesRank: 1688
        },
        {
          date: '2022-12-21',
          reviewCount: null,
          rating: null,
          salesRank: 1875
        },
        {
          date: '2022-12-22',
          reviewCount: null,
          rating: null,
          salesRank: 1836
        },
        {
          date: '2022-12-23',
          reviewCount: null,
          rating: null,
          salesRank: 1701
        },
        {
          date: '2022-12-24',
          reviewCount: null,
          rating: null,
          salesRank: 1348
        },
        {
          date: '2022-12-25',
          reviewCount: null,
          rating: null,
          salesRank: 1685
        },
        {
          date: '2022-12-26',
          reviewCount: null,
          rating: null,
          salesRank: 1103
        },
        {
          date: '2022-12-27',
          reviewCount: null,
          rating: null,
          salesRank: 527
        },
        {
          date: '2022-12-28',
          reviewCount: null,
          rating: null,
          salesRank: 456
        },
        {
          date: '2022-12-29',
          reviewCount: null,
          rating: null,
          salesRank: 785
        },
        {
          date: '2022-12-30',
          reviewCount: null,
          rating: null,
          salesRank: 1883
        },
        {
          date: '2022-12-31',
          reviewCount: null,
          rating: null,
          salesRank: 3670
        },
        {
          date: '2023-01-01',
          reviewCount: null,
          rating: null,
          salesRank: 5353
        },
        {
          date: '2023-01-02',
          reviewCount: null,
          rating: null,
          salesRank: 7607
        },
        {
          date: '2023-01-03',
          reviewCount: null,
          rating: null,
          salesRank: 7476
        },
        {
          date: '2023-01-04',
          reviewCount: null,
          rating: null,
          salesRank: 5615
        },
        {
          date: '2023-01-05',
          reviewCount: null,
          rating: null,
          salesRank: 2165
        },
        {
          date: '2023-01-06',
          reviewCount: null,
          rating: null,
          salesRank: 1815
        },
        {
          date: '2023-01-07',
          reviewCount: null,
          rating: null,
          salesRank: 1694
        },
        {
          date: '2023-01-08',
          reviewCount: null,
          rating: null,
          salesRank: 1675
        },
        {
          date: '2023-01-09',
          reviewCount: null,
          rating: null,
          salesRank: 1724
        },
        {
          date: '2023-01-10',
          reviewCount: null,
          rating: null,
          salesRank: 1785
        },
        {
          date: '2023-01-11',
          reviewCount: null,
          rating: null,
          salesRank: 1787
        },
        {
          date: '2023-01-12',
          reviewCount: null,
          rating: null,
          salesRank: 1773
        },
        {
          date: '2023-01-13',
          reviewCount: null,
          rating: null,
          salesRank: 1307
        },
        {
          date: '2023-01-14',
          reviewCount: null,
          rating: null,
          salesRank: 1997
        },
        {
          date: '2023-01-15',
          reviewCount: null,
          rating: null,
          salesRank: 1805
        },
        {
          date: '2023-01-16',
          reviewCount: null,
          rating: null,
          salesRank: 1516
        },
        {
          date: '2023-01-17',
          reviewCount: null,
          rating: null,
          salesRank: 1175
        },
        {
          date: '2023-01-18',
          reviewCount: null,
          rating: null,
          salesRank: 1042
        },
        {
          date: '2023-01-19',
          reviewCount: null,
          rating: null,
          salesRank: 1337
        },
        {
          date: '2023-01-20',
          reviewCount: null,
          rating: null,
          salesRank: 1839
        },
        {
          date: '2023-01-21',
          reviewCount: null,
          rating: null,
          salesRank: 1848
        },
        {
          date: '2023-01-22',
          reviewCount: null,
          rating: null,
          salesRank: 2248
        },
        {
          date: '2023-01-23',
          reviewCount: null,
          rating: null,
          salesRank: 2040
        },
        {
          date: '2023-01-24',
          reviewCount: null,
          rating: null,
          salesRank: 2156
        },
        {
          date: '2023-01-25',
          reviewCount: null,
          rating: null,
          salesRank: 1798
        },
        {
          date: '2023-01-26',
          reviewCount: null,
          rating: null,
          salesRank: 1474
        },
        {
          date: '2023-01-27',
          reviewCount: null,
          rating: null,
          salesRank: 1054
        },
        {
          date: '2023-01-28',
          reviewCount: null,
          rating: null,
          salesRank: 1033
        },
        {
          date: '2023-01-29',
          reviewCount: null,
          rating: null,
          salesRank: 939
        },
        {
          date: '2023-01-30',
          reviewCount: null,
          rating: null,
          salesRank: 976
        },
        {
          date: '2023-01-31',
          reviewCount: null,
          rating: null,
          salesRank: 1136
        },
        {
          date: '2023-02-01',
          reviewCount: null,
          rating: null,
          salesRank: 1514
        },
        {
          date: '2023-02-02',
          reviewCount: null,
          rating: null,
          salesRank: 1333
        },
        {
          date: '2023-02-03',
          reviewCount: null,
          rating: null,
          salesRank: 1078
        },
        {
          date: '2023-02-04',
          reviewCount: null,
          rating: null,
          salesRank: 1160
        },
        {
          date: '2023-02-05',
          reviewCount: null,
          rating: null,
          salesRank: 1221
        },
        {
          date: '2023-02-06',
          reviewCount: null,
          rating: null,
          salesRank: 911
        },
        {
          date: '2023-02-07',
          reviewCount: null,
          rating: null,
          salesRank: 870
        },
        {
          date: '2023-02-08',
          reviewCount: null,
          rating: null,
          salesRank: 887
        },
        {
          date: '2023-02-09',
          reviewCount: null,
          rating: null,
          salesRank: 1091
        },
        {
          date: '2023-02-10',
          reviewCount: null,
          rating: null,
          salesRank: 1275
        },
        {
          date: '2023-02-11',
          reviewCount: null,
          rating: null,
          salesRank: 1489
        },
        {
          date: '2023-02-12',
          reviewCount: null,
          rating: null,
          salesRank: 1599
        },
        {
          date: '2023-02-13',
          reviewCount: null,
          rating: null,
          salesRank: 1300
        },
        {
          date: '2023-02-14',
          reviewCount: null,
          rating: null,
          salesRank: 1045
        },
        {
          date: '2023-02-15',
          reviewCount: null,
          rating: null,
          salesRank: 1049
        },
        {
          date: '2023-02-16',
          reviewCount: null,
          rating: null,
          salesRank: 1394
        },
        {
          date: '2023-02-17',
          reviewCount: null,
          rating: null,
          salesRank: 1539
        },
        {
          date: '2023-02-18',
          reviewCount: null,
          rating: null,
          salesRank: 1103
        },
        {
          date: '2023-02-19',
          reviewCount: null,
          rating: null,
          salesRank: 1336
        },
        {
          date: '2023-02-20',
          reviewCount: null,
          rating: null,
          salesRank: 1629
        },
        {
          date: '2023-02-21',
          reviewCount: null,
          rating: null,
          salesRank: 1656
        },
        {
          date: '2023-02-22',
          reviewCount: null,
          rating: null,
          salesRank: 1668
        },
        {
          date: '2023-02-23',
          reviewCount: null,
          rating: null,
          salesRank: 1937
        },
        {
          date: '2023-02-24',
          reviewCount: null,
          rating: null,
          salesRank: 1848
        },
        {
          date: '2023-02-25',
          reviewCount: null,
          rating: null,
          salesRank: 2002
        },
        {
          date: '2023-02-26',
          reviewCount: null,
          rating: null,
          salesRank: 1872
        },
        {
          date: '2023-02-27',
          reviewCount: null,
          rating: null,
          salesRank: 1953
        },
        {
          date: '2023-02-28',
          reviewCount: null,
          rating: null,
          salesRank: 1904
        },
        {
          date: '2023-03-01',
          reviewCount: null,
          rating: null,
          salesRank: 1799
        },
        {
          date: '2023-03-02',
          reviewCount: null,
          rating: null,
          salesRank: 1968
        },
        {
          date: '2023-03-03',
          reviewCount: null,
          rating: null,
          salesRank: 1996
        },
        {
          date: '2023-03-04',
          reviewCount: null,
          rating: null,
          salesRank: 2355
        },
        {
          date: '2023-03-05',
          reviewCount: null,
          rating: null,
          salesRank: 2776
        },
        {
          date: '2023-03-06',
          reviewCount: null,
          rating: null,
          salesRank: 2303
        },
        {
          date: '2023-03-07',
          reviewCount: null,
          rating: null,
          salesRank: 2398
        },
        {
          date: '2023-03-08',
          reviewCount: null,
          rating: null,
          salesRank: 2907
        },
        {
          date: '2023-03-09',
          reviewCount: null,
          rating: null,
          salesRank: 3479
        },
        {
          date: '2023-03-10',
          reviewCount: null,
          rating: null,
          salesRank: 3694
        },
        {
          date: '2023-03-11',
          reviewCount: null,
          rating: null,
          salesRank: 2948
        },
        {
          date: '2023-03-12',
          reviewCount: null,
          rating: null,
          salesRank: 4271
        },
        {
          date: '2023-03-13',
          reviewCount: null,
          rating: null,
          salesRank: 3300
        },
        {
          date: '2023-03-14',
          reviewCount: null,
          rating: null,
          salesRank: 2484
        },
        {
          date: '2023-03-15',
          reviewCount: null,
          rating: null,
          salesRank: 2163
        },
        {
          date: '2023-03-16',
          reviewCount: null,
          rating: null,
          salesRank: 2295
        },
        {
          date: '2023-03-17',
          reviewCount: null,
          rating: null,
          salesRank: 2184
        },
        {
          date: '2023-03-18',
          reviewCount: null,
          rating: null,
          salesRank: 2802
        },
        {
          date: '2023-03-19',
          reviewCount: null,
          rating: null,
          salesRank: 3430
        },
        {
          date: '2023-03-20',
          reviewCount: null,
          rating: null,
          salesRank: 2909
        },
        {
          date: '2023-03-21',
          reviewCount: null,
          rating: null,
          salesRank: 2270
        },
        {
          date: '2023-03-22',
          reviewCount: null,
          rating: null,
          salesRank: 2724
        },
        {
          date: '2023-03-23',
          reviewCount: null,
          rating: null,
          salesRank: 2788
        },
        {
          date: '2023-03-24',
          reviewCount: null,
          rating: null,
          salesRank: 2992
        },
        {
          date: '2023-03-25',
          reviewCount: null,
          rating: null,
          salesRank: 3577
        },
        {
          date: '2023-03-26',
          reviewCount: null,
          rating: null,
          salesRank: 4682
        },
        {
          date: '2023-03-27',
          reviewCount: null,
          rating: null,
          salesRank: 4180
        },
        {
          date: '2023-03-28',
          reviewCount: null,
          rating: null,
          salesRank: 4254
        },
        {
          date: '2023-03-29',
          reviewCount: null,
          rating: null,
          salesRank: 3440
        },
        {
          date: '2023-03-30',
          reviewCount: null,
          rating: null,
          salesRank: 4552
        },
        {
          date: '2023-03-31',
          reviewCount: null,
          rating: null,
          salesRank: 3665
        },
        {
          date: '2023-04-01',
          reviewCount: null,
          rating: null,
          salesRank: 4936
        },
        {
          date: '2023-04-02',
          reviewCount: null,
          rating: null,
          salesRank: 6520
        },
        {
          date: '2023-04-03',
          reviewCount: null,
          rating: null,
          salesRank: 6415
        },
        {
          date: '2023-04-04',
          reviewCount: null,
          rating: null,
          salesRank: 5451
        },
        {
          date: '2023-04-05',
          reviewCount: null,
          rating: null,
          salesRank: 5109
        },
        {
          date: '2023-04-06',
          reviewCount: null,
          rating: null,
          salesRank: 4855
        },
        {
          date: '2023-04-07',
          reviewCount: null,
          rating: null,
          salesRank: 4412
        },
        {
          date: '2023-04-08',
          reviewCount: null,
          rating: null,
          salesRank: 4909
        },
        {
          date: '2023-04-09',
          reviewCount: null,
          rating: null,
          salesRank: 5527
        },
        {
          date: '2023-04-10',
          reviewCount: null,
          rating: null,
          salesRank: 5117
        },
        {
          date: '2023-04-11',
          reviewCount: null,
          rating: null,
          salesRank: 4198
        },
        {
          date: '2023-04-12',
          reviewCount: null,
          rating: null,
          salesRank: 3554
        },
        {
          date: '2023-04-13',
          reviewCount: null,
          rating: null,
          salesRank: 4273
        },
        {
          date: '2023-04-14',
          reviewCount: null,
          rating: null,
          salesRank: 4032
        },
        {
          date: '2023-04-15',
          reviewCount: null,
          rating: null,
          salesRank: 5276
        },
        {
          date: '2023-04-16',
          reviewCount: null,
          rating: null,
          salesRank: 5792
        },
        {
          date: '2023-04-17',
          reviewCount: null,
          rating: null,
          salesRank: 5379
        },
        {
          date: '2023-04-18',
          reviewCount: null,
          rating: null,
          salesRank: 4628
        },
        {
          date: '2023-04-19',
          reviewCount: null,
          rating: null,
          salesRank: 4725
        },
        {
          date: '2023-04-20',
          reviewCount: null,
          rating: null,
          salesRank: 5786
        },
        {
          date: '2023-04-21',
          reviewCount: null,
          rating: null,
          salesRank: 6191
        },
        {
          date: '2023-04-22',
          reviewCount: null,
          rating: null,
          salesRank: 6430
        },
        {
          date: '2023-04-23',
          reviewCount: null,
          rating: null,
          salesRank: 7814
        },
        {
          date: '2023-04-24',
          reviewCount: null,
          rating: null,
          salesRank: 8509
        },
        {
          date: '2023-04-25',
          reviewCount: null,
          rating: null,
          salesRank: 6839
        },
        {
          date: '2023-04-26',
          reviewCount: null,
          rating: null,
          salesRank: 7058
        },
        {
          date: '2023-04-27',
          reviewCount: null,
          rating: null,
          salesRank: 7669
        },
        {
          date: '2023-04-28',
          reviewCount: null,
          rating: null,
          salesRank: 7465
        },
        {
          date: '2023-04-29',
          reviewCount: null,
          rating: null,
          salesRank: 8003
        },
        {
          date: '2023-04-30',
          reviewCount: null,
          rating: null,
          salesRank: 9522
        },
        {
          date: '2023-05-01',
          reviewCount: null,
          rating: null,
          salesRank: 6486
        },
        {
          date: '2023-05-02',
          reviewCount: null,
          rating: null,
          salesRank: 6447
        },
        {
          date: '2023-05-03',
          reviewCount: null,
          rating: null,
          salesRank: 6602
        },
        {
          date: '2023-05-04',
          reviewCount: null,
          rating: null,
          salesRank: 6096
        },
        {
          date: '2023-05-05',
          reviewCount: null,
          rating: null,
          salesRank: 6205
        },
        {
          date: '2023-05-06',
          reviewCount: null,
          rating: null,
          salesRank: 6532
        },
        {
          date: '2023-05-07',
          reviewCount: null,
          rating: null,
          salesRank: 8845
        },
        {
          date: '2023-05-08',
          reviewCount: null,
          rating: null,
          salesRank: 9786
        },
        {
          date: '2023-05-09',
          reviewCount: null,
          rating: null,
          salesRank: 7858
        },
        {
          date: '2023-05-10',
          reviewCount: null,
          rating: null,
          salesRank: 8370
        },
        {
          date: '2023-05-11',
          reviewCount: null,
          rating: null,
          salesRank: 7938
        },
        {
          date: '2023-05-12',
          reviewCount: null,
          rating: null,
          salesRank: 8138
        },
        {
          date: '2023-05-13',
          reviewCount: null,
          rating: null,
          salesRank: 9212
        },
        {
          date: '2023-05-14',
          reviewCount: null,
          rating: null,
          salesRank: 10023
        },
        {
          date: '2023-05-15',
          reviewCount: null,
          rating: null,
          salesRank: 8451
        },
        {
          date: '2023-05-16',
          reviewCount: null,
          rating: null,
          salesRank: 9378
        },
        {
          date: '2023-05-17',
          reviewCount: null,
          rating: null,
          salesRank: 8250
        },
        {
          date: '2023-05-18',
          reviewCount: null,
          rating: null,
          salesRank: 7534
        },
        {
          date: '2023-05-19',
          reviewCount: null,
          rating: null,
          salesRank: 8032
        },
        {
          date: '2023-05-20',
          reviewCount: null,
          rating: null,
          salesRank: 7811
        },
        {
          date: '2023-05-21',
          reviewCount: null,
          rating: null,
          salesRank: 10958
        },
        {
          date: '2023-05-22',
          reviewCount: null,
          rating: null,
          salesRank: 8725
        },
        {
          date: '2023-05-23',
          reviewCount: null,
          rating: null,
          salesRank: 10961
        },
        {
          date: '2023-05-24',
          reviewCount: null,
          rating: null,
          salesRank: 10047
        },
        {
          date: '2023-05-25',
          reviewCount: null,
          rating: null,
          salesRank: 9581
        },
        {
          date: '2023-05-26',
          reviewCount: null,
          rating: null,
          salesRank: 9404
        },
        {
          date: '2023-05-27',
          reviewCount: null,
          rating: null,
          salesRank: 9498
        },
        {
          date: '2023-05-28',
          reviewCount: null,
          rating: null,
          salesRank: 8743
        },
        {
          date: '2023-05-30',
          reviewCount: null,
          rating: null,
          salesRank: 10129
        },
        {
          date: '2023-05-31',
          reviewCount: null,
          rating: null,
          salesRank: 8965
        },
        {
          date: '2023-06-01',
          reviewCount: null,
          rating: null,
          salesRank: 9630
        },
        {
          date: '2023-06-02',
          reviewCount: null,
          rating: null,
          salesRank: 10822
        },
        {
          date: '2023-06-03',
          reviewCount: null,
          rating: null,
          salesRank: 8799
        },
        {
          date: '2023-06-04',
          reviewCount: null,
          rating: null,
          salesRank: 9145
        },
        {
          date: '2023-06-05',
          reviewCount: null,
          rating: null,
          salesRank: 8819
        },
        {
          date: '2023-06-06',
          reviewCount: null,
          rating: null,
          salesRank: 9607
        },
        {
          date: '2023-06-07',
          reviewCount: null,
          rating: null,
          salesRank: 6798
        },
        {
          date: '2023-06-08',
          reviewCount: null,
          rating: null,
          salesRank: 8145
        },
        {
          date: '2023-06-09',
          reviewCount: null,
          rating: null,
          salesRank: 7681
        },
        {
          date: '2023-06-10',
          reviewCount: null,
          rating: null,
          salesRank: 9301
        },
        {
          date: '2023-06-11',
          reviewCount: null,
          rating: null,
          salesRank: 10530
        },
        {
          date: '2023-06-12',
          reviewCount: null,
          rating: null,
          salesRank: 10111
        },
        {
          date: '2023-06-13',
          reviewCount: null,
          rating: null,
          salesRank: 9544
        },
        {
          date: '2023-06-14',
          reviewCount: null,
          rating: null,
          salesRank: 11431
        },
        {
          date: '2023-06-15',
          reviewCount: null,
          rating: null,
          salesRank: 9670
        },
        {
          date: '2023-06-16',
          reviewCount: null,
          rating: null,
          salesRank: 12617
        },
        {
          date: '2023-06-17',
          reviewCount: null,
          rating: null,
          salesRank: 14415
        },
        {
          date: '2023-06-18',
          reviewCount: null,
          rating: null,
          salesRank: 12400
        },
        {
          date: '2023-06-19',
          reviewCount: null,
          rating: null,
          salesRank: 13727
        },
        {
          date: '2023-06-20',
          reviewCount: null,
          rating: null,
          salesRank: 12822
        },
        {
          date: '2023-06-21',
          reviewCount: null,
          rating: null,
          salesRank: 11669
        },
        {
          date: '2023-06-22',
          reviewCount: null,
          rating: null,
          salesRank: 11112
        },
        {
          date: '2023-06-23',
          reviewCount: null,
          rating: null,
          salesRank: 9852
        },
        {
          date: '2023-06-24',
          reviewCount: null,
          rating: null,
          salesRank: 9749
        },
        {
          date: '2023-06-25',
          reviewCount: null,
          rating: null,
          salesRank: 12132
        },
        {
          date: '2023-06-26',
          reviewCount: null,
          rating: null,
          salesRank: 12645
        },
        {
          date: '2023-06-27',
          reviewCount: null,
          rating: null,
          salesRank: 12012
        },
        {
          date: '2023-06-28',
          reviewCount: null,
          rating: null,
          salesRank: 14295
        },
        {
          date: '2023-06-29',
          reviewCount: null,
          rating: null,
          salesRank: 15085
        },
        {
          date: '2023-06-30',
          reviewCount: null,
          rating: null,
          salesRank: 17659
        },
        {
          date: '2023-07-01',
          reviewCount: null,
          rating: null,
          salesRank: 20553
        },
        {
          date: '2023-07-02',
          reviewCount: null,
          rating: null,
          salesRank: 20335
        },
        {
          date: '2023-07-03',
          reviewCount: null,
          rating: null,
          salesRank: 19488
        },
        {
          date: '2023-07-04',
          reviewCount: null,
          rating: null,
          salesRank: 16493
        },
        {
          date: '2023-07-05',
          reviewCount: null,
          rating: null,
          salesRank: 16439
        },
        {
          date: '2023-07-06',
          reviewCount: null,
          rating: null,
          salesRank: 16897
        },
        {
          date: '2023-07-07',
          reviewCount: null,
          rating: null,
          salesRank: 11962
        },
        {
          date: '2023-07-08',
          reviewCount: null,
          rating: null,
          salesRank: 12668
        },
        {
          date: '2023-07-09',
          reviewCount: null,
          rating: null,
          salesRank: 13810
        },
        {
          date: '2023-07-10',
          reviewCount: null,
          rating: null,
          salesRank: 13092
        },
        {
          date: '2023-07-11',
          reviewCount: null,
          rating: null,
          salesRank: 7301
        },
        {
          date: '2023-07-12',
          reviewCount: null,
          rating: null,
          salesRank: 10650
        },
        {
          date: '2023-07-13',
          reviewCount: null,
          rating: null,
          salesRank: 10848
        },
        {
          date: '2023-07-14',
          reviewCount: null,
          rating: null,
          salesRank: 10580
        },
        {
          date: '2023-07-15',
          reviewCount: null,
          rating: null,
          salesRank: 12326
        },
        {
          date: '2023-07-16',
          reviewCount: null,
          rating: null,
          salesRank: 13933
        },
        {
          date: '2023-07-17',
          reviewCount: null,
          rating: null,
          salesRank: 12872
        },
        {
          date: '2023-07-18',
          reviewCount: null,
          rating: null,
          salesRank: 14244
        },
        {
          date: '2023-07-19',
          reviewCount: null,
          rating: null,
          salesRank: 12125
        },
        {
          date: '2023-07-20',
          reviewCount: null,
          rating: null,
          salesRank: 13300
        },
        {
          date: '2023-07-21',
          reviewCount: null,
          rating: null,
          salesRank: 13628
        },
        {
          date: '2023-07-22',
          reviewCount: null,
          rating: null,
          salesRank: 13459
        },
        {
          date: '2023-07-23',
          reviewCount: null,
          rating: null,
          salesRank: 16795
        },
        {
          date: '2023-07-24',
          reviewCount: null,
          rating: null,
          salesRank: 14066
        },
        {
          date: '2023-07-25',
          reviewCount: null,
          rating: null,
          salesRank: 13707
        },
        {
          date: '2023-07-26',
          reviewCount: null,
          rating: null,
          salesRank: 15590
        },
        {
          date: '2023-07-27',
          reviewCount: null,
          rating: null,
          salesRank: 17915
        },
        {
          date: '2023-07-28',
          reviewCount: null,
          rating: null,
          salesRank: 12824
        },
        {
          date: '2023-07-29',
          reviewCount: null,
          rating: null,
          salesRank: 15502
        },
        {
          date: '2023-07-30',
          reviewCount: null,
          rating: null,
          salesRank: 16652
        },
        {
          date: '2023-07-31',
          reviewCount: null,
          rating: null,
          salesRank: 16593
        },
        {
          date: '2023-08-01',
          reviewCount: null,
          rating: null,
          salesRank: 13150
        },
        {
          date: '2023-08-02',
          reviewCount: null,
          rating: null,
          salesRank: 12374
        },
        {
          date: '2023-08-03',
          reviewCount: null,
          rating: null,
          salesRank: 12218
        },
        {
          date: '2023-08-04',
          reviewCount: null,
          rating: null,
          salesRank: 11285
        },
        {
          date: '2023-08-05',
          reviewCount: null,
          rating: null,
          salesRank: 11681
        },
        {
          date: '2023-08-06',
          reviewCount: null,
          rating: null,
          salesRank: 11149
        },
        {
          date: '2023-08-07',
          reviewCount: null,
          rating: null,
          salesRank: 11621
        },
        {
          date: '2023-08-08',
          reviewCount: null,
          rating: null,
          salesRank: 12346
        },
        {
          date: '2023-08-09',
          reviewCount: null,
          rating: null,
          salesRank: 10890
        },
        {
          date: '2023-08-10',
          reviewCount: null,
          rating: null,
          salesRank: 12817
        },
        {
          date: '2023-08-11',
          reviewCount: null,
          rating: null,
          salesRank: 13359
        },
        {
          date: '2023-08-12',
          reviewCount: null,
          rating: null,
          salesRank: 12909
        },
        {
          date: '2023-08-13',
          reviewCount: null,
          rating: null,
          salesRank: 14200
        },
        {
          date: '2023-08-14',
          reviewCount: null,
          rating: null,
          salesRank: 11363
        },
        {
          date: '2023-08-15',
          reviewCount: null,
          rating: null,
          salesRank: 11129
        },
        {
          date: '2023-08-16',
          reviewCount: null,
          rating: null,
          salesRank: 15739
        },
        {
          date: '2023-08-17',
          reviewCount: null,
          rating: null,
          salesRank: 11813
        },
        {
          date: '2023-08-18',
          reviewCount: null,
          rating: null,
          salesRank: 13630
        },
        {
          date: '2023-08-19',
          reviewCount: null,
          rating: null,
          salesRank: 12944
        },
        {
          date: '2023-08-20',
          reviewCount: null,
          rating: null,
          salesRank: 13234
        },
        {
          date: '2023-08-21',
          reviewCount: null,
          rating: null,
          salesRank: 12931
        },
        {
          date: '2023-08-22',
          reviewCount: null,
          rating: null,
          salesRank: 12874
        },
        {
          date: '2023-08-23',
          reviewCount: null,
          rating: null,
          salesRank: 11455
        },
        {
          date: '2023-08-24',
          reviewCount: null,
          rating: null,
          salesRank: 13612
        },
        {
          date: '2023-08-25',
          reviewCount: null,
          rating: null,
          salesRank: 13441
        },
        {
          date: '2023-08-26',
          reviewCount: null,
          rating: null,
          salesRank: 14381
        },
        {
          date: '2023-08-27',
          reviewCount: null,
          rating: null,
          salesRank: 14956
        },
        {
          date: '2023-08-28',
          reviewCount: null,
          rating: null,
          salesRank: 12497
        },
        {
          date: '2023-08-29',
          reviewCount: null,
          rating: null,
          salesRank: 12379
        },
        {
          date: '2023-08-30',
          reviewCount: null,
          rating: null,
          salesRank: 12248
        },
        {
          date: '2023-08-31',
          reviewCount: null,
          rating: null,
          salesRank: 12383
        },
        {
          date: '2023-09-01',
          reviewCount: null,
          rating: null,
          salesRank: 11204
        },
        {
          date: '2023-09-02',
          reviewCount: null,
          rating: null,
          salesRank: 14070
        },
        {
          date: '2023-09-03',
          reviewCount: null,
          rating: null,
          salesRank: 12103
        },
        {
          date: '2023-09-04',
          reviewCount: null,
          rating: null,
          salesRank: 14771
        },
        {
          date: '2023-09-05',
          reviewCount: null,
          rating: null,
          salesRank: 12902
        },
        {
          date: '2023-09-06',
          reviewCount: null,
          rating: null,
          salesRank: 11491
        },
        {
          date: '2023-09-07',
          reviewCount: null,
          rating: null,
          salesRank: 9507
        },
        {
          date: '2023-09-08',
          reviewCount: null,
          rating: null,
          salesRank: 10865
        },
        {
          date: '2023-09-09',
          reviewCount: null,
          rating: null,
          salesRank: 9624
        },
        {
          date: '2023-09-10',
          reviewCount: null,
          rating: null,
          salesRank: 12480
        },
        {
          date: '2023-09-11',
          reviewCount: null,
          rating: null,
          salesRank: 11921
        },
        {
          date: '2023-09-12',
          reviewCount: null,
          rating: null,
          salesRank: 11454
        },
        {
          date: '2023-09-13',
          reviewCount: null,
          rating: null,
          salesRank: 12090
        },
        {
          date: '2023-09-14',
          reviewCount: null,
          rating: null,
          salesRank: 10267
        },
        {
          date: '2023-09-15',
          reviewCount: null,
          rating: null,
          salesRank: 12608
        },
        {
          date: '2023-09-16',
          reviewCount: null,
          rating: null,
          salesRank: 12044
        },
        {
          date: '2023-09-17',
          reviewCount: null,
          rating: null,
          salesRank: 12396
        },
        {
          date: '2023-09-18',
          reviewCount: null,
          rating: null,
          salesRank: 11435
        },
        {
          date: '2023-09-19',
          reviewCount: null,
          rating: null,
          salesRank: 12096
        },
        {
          date: '2023-09-20',
          reviewCount: null,
          rating: null,
          salesRank: 7025
        },
        {
          date: '2023-09-21',
          reviewCount: null,
          rating: null,
          salesRank: 9116
        },
        {
          date: '2023-09-22',
          reviewCount: null,
          rating: null,
          salesRank: 7665
        },
        {
          date: '2023-09-23',
          reviewCount: null,
          rating: null,
          salesRank: 8338
        },
        {
          date: '2023-09-24',
          reviewCount: null,
          rating: null,
          salesRank: 8499
        },
        {
          date: '2023-09-25',
          reviewCount: null,
          rating: null,
          salesRank: 6962
        },
        {
          date: '2023-09-26',
          reviewCount: null,
          rating: null,
          salesRank: 7427
        },
        {
          date: '2023-09-27',
          reviewCount: null,
          rating: null,
          salesRank: 7393
        },
        {
          date: '2023-09-28',
          reviewCount: null,
          rating: null,
          salesRank: 8236
        },
        {
          date: '2023-09-29',
          reviewCount: null,
          rating: null,
          salesRank: 8325
        },
        {
          date: '2023-09-30',
          reviewCount: null,
          rating: null,
          salesRank: 8247
        },
        {
          date: '2023-10-01',
          reviewCount: null,
          rating: null,
          salesRank: 8323
        },
        {
          date: '2023-10-02',
          reviewCount: null,
          rating: null,
          salesRank: 7472
        },
        {
          date: '2023-10-03',
          reviewCount: null,
          rating: null,
          salesRank: 7883
        },
        {
          date: '2023-10-04',
          reviewCount: null,
          rating: null,
          salesRank: 9016
        },
        {
          date: '2023-10-05',
          reviewCount: null,
          rating: null,
          salesRank: 7912
        },
        {
          date: '2023-10-06',
          reviewCount: null,
          rating: null,
          salesRank: 7362
        },
        {
          date: '2023-10-07',
          reviewCount: null,
          rating: null,
          salesRank: 7984
        },
        {
          date: '2023-10-08',
          reviewCount: null,
          rating: null,
          salesRank: 9684
        },
        {
          date: '2023-10-09',
          reviewCount: null,
          rating: null,
          salesRank: 7254
        },
        {
          date: '2023-10-10',
          reviewCount: null,
          rating: null,
          salesRank: 5343
        },
        {
          date: '2023-10-11',
          reviewCount: null,
          rating: null,
          salesRank: 2937
        },
        {
          date: '2023-10-12',
          reviewCount: null,
          rating: null,
          salesRank: 3460
        },
        {
          date: '2023-10-13',
          reviewCount: null,
          rating: null,
          salesRank: 4271
        },
        {
          date: '2023-10-14',
          reviewCount: null,
          rating: null,
          salesRank: 4768
        },
        {
          date: '2023-10-15',
          reviewCount: null,
          rating: null,
          salesRank: 4624
        },
        {
          date: '2023-10-16',
          reviewCount: null,
          rating: null,
          salesRank: 4876
        },
        {
          date: '2023-10-17',
          reviewCount: null,
          rating: null,
          salesRank: 4516
        },
        {
          date: '2023-10-18',
          reviewCount: null,
          rating: null,
          salesRank: 5252
        },
        {
          date: '2023-10-19',
          reviewCount: null,
          rating: null,
          salesRank: 5556
        },
        {
          date: '2023-10-20',
          reviewCount: null,
          rating: null,
          salesRank: 5283
        },
        {
          date: '2023-10-21',
          reviewCount: null,
          rating: null,
          salesRank: 5857
        },
        {
          date: '2023-10-22',
          reviewCount: null,
          rating: null,
          salesRank: 5832
        },
        {
          date: '2023-10-23',
          reviewCount: null,
          rating: null,
          salesRank: 5727
        },
        {
          date: '2023-10-24',
          reviewCount: null,
          rating: null,
          salesRank: 4201
        },
        {
          date: '2023-10-25',
          reviewCount: null,
          rating: null,
          salesRank: 3252
        },
        {
          date: '2023-10-26',
          reviewCount: null,
          rating: null,
          salesRank: 3322
        },
        {
          date: '2023-10-27',
          reviewCount: null,
          rating: null,
          salesRank: 4038
        },
        {
          date: '2023-10-28',
          reviewCount: null,
          rating: null,
          salesRank: 3641
        },
        {
          date: '2023-10-31',
          reviewCount: null,
          rating: null,
          salesRank: 1891
        },
        {
          date: '2023-11-01',
          reviewCount: null,
          rating: null,
          salesRank: 1486
        },
        {
          date: '2023-11-02',
          reviewCount: null,
          rating: null,
          salesRank: 1120
        },
        {
          date: '2023-11-03',
          reviewCount: null,
          rating: null,
          salesRank: 791
        },
        {
          date: '2023-11-04',
          reviewCount: null,
          rating: null,
          salesRank: 1168
        },
        {
          date: '2023-11-05',
          reviewCount: null,
          rating: null,
          salesRank: 1039
        },
        {
          date: '2023-11-06',
          reviewCount: null,
          rating: null,
          salesRank: 841
        },
        {
          date: '2023-11-07',
          reviewCount: null,
          rating: null,
          salesRank: 1052
        },
        {
          date: '2023-11-08',
          reviewCount: null,
          rating: null,
          salesRank: 1060
        },
        {
          date: '2023-11-09',
          reviewCount: null,
          rating: null,
          salesRank: 1115
        },
        {
          date: '2023-11-10',
          reviewCount: null,
          rating: null,
          salesRank: 1360
        },
        {
          date: '2023-11-11',
          reviewCount: null,
          rating: null,
          salesRank: 1756
        },
        {
          date: '2023-11-12',
          reviewCount: null,
          rating: null,
          salesRank: 1403
        },
        {
          date: '2023-11-13',
          reviewCount: null,
          rating: null,
          salesRank: 1280
        },
        {
          date: '2023-11-14',
          reviewCount: null,
          rating: null,
          salesRank: 1131
        },
        {
          date: '2023-11-15',
          reviewCount: null,
          rating: null,
          salesRank: 1605
        },
        {
          date: '2023-11-16',
          reviewCount: null,
          rating: null,
          salesRank: 2013
        },
        {
          date: '2023-11-17',
          reviewCount: null,
          rating: null,
          salesRank: 2792
        },
        {
          date: '2023-11-18',
          reviewCount: null,
          rating: null,
          salesRank: 2846
        },
        {
          date: '2023-11-19',
          reviewCount: null,
          rating: null,
          salesRank: 3071
        },
        {
          date: '2023-11-20',
          reviewCount: null,
          rating: null,
          salesRank: 4664
        },
        {
          date: '2023-11-21',
          reviewCount: null,
          rating: null,
          salesRank: 4731
        },
        {
          date: '2023-11-22',
          reviewCount: null,
          rating: null,
          salesRank: 2783
        },
        {
          date: '2023-11-23',
          reviewCount: null,
          rating: null,
          salesRank: 3282
        },
        {
          date: '2023-11-24',
          reviewCount: null,
          rating: null,
          salesRank: 1864
        },
        {
          date: '2023-11-25',
          reviewCount: null,
          rating: null,
          salesRank: 3927
        },
        {
          date: '2023-11-26',
          reviewCount: null,
          rating: null,
          salesRank: 5286
        },
        {
          date: '2023-11-27',
          reviewCount: null,
          rating: null,
          salesRank: 3163
        },
        {
          date: '2023-11-28',
          reviewCount: null,
          rating: null,
          salesRank: 1912
        },
        {
          date: '2023-11-29',
          reviewCount: null,
          rating: null,
          salesRank: 1697
        },
        {
          date: '2023-11-30',
          reviewCount: null,
          rating: null,
          salesRank: 2000
        },
        {
          date: '2023-12-01',
          reviewCount: null,
          rating: null,
          salesRank: 2284
        },
        {
          date: '2023-12-02',
          reviewCount: null,
          rating: null,
          salesRank: 2931
        },
        {
          date: '2023-12-03',
          reviewCount: null,
          rating: null,
          salesRank: 1411
        },
        {
          date: '2023-12-04',
          reviewCount: null,
          rating: null,
          salesRank: 1746
        },
        {
          date: '2023-12-05',
          reviewCount: null,
          rating: null,
          salesRank: 2842
        },
        {
          date: '2023-12-06',
          reviewCount: null,
          rating: null,
          salesRank: 2555
        },
        {
          date: '2023-12-07',
          reviewCount: null,
          rating: null,
          salesRank: 3743
        },
        {
          date: '2023-12-08',
          reviewCount: null,
          rating: null,
          salesRank: 4219
        },
        {
          date: '2023-12-09',
          reviewCount: null,
          rating: null,
          salesRank: 5404
        },
        {
          date: '2023-12-10',
          reviewCount: null,
          rating: null,
          salesRank: 5668
        },
        {
          date: '2023-12-11',
          reviewCount: null,
          rating: null,
          salesRank: 5404
        },
        {
          date: '2023-12-12',
          reviewCount: null,
          rating: null,
          salesRank: 5555
        },
        {
          date: '2023-12-13',
          reviewCount: null,
          rating: null,
          salesRank: 5287
        },
        {
          date: '2023-12-14',
          reviewCount: null,
          rating: null,
          salesRank: 5318
        },
        {
          date: '2023-12-15',
          reviewCount: null,
          rating: null,
          salesRank: 6590
        },
        {
          date: '2023-12-16',
          reviewCount: null,
          rating: null,
          salesRank: 6869
        },
        {
          date: '2023-12-17',
          reviewCount: null,
          rating: null,
          salesRank: 8702
        },
        {
          date: '2023-12-18',
          reviewCount: null,
          rating: null,
          salesRank: 9238
        },
        {
          date: '2023-12-19',
          reviewCount: null,
          rating: null,
          salesRank: 7393
        },
        {
          date: '2023-12-20',
          reviewCount: null,
          rating: null,
          salesRank: 3788
        },
        {
          date: '2023-12-21',
          reviewCount: null,
          rating: null,
          salesRank: 3045
        },
        {
          date: '2023-12-22',
          reviewCount: null,
          rating: null,
          salesRank: 3459
        },
        {
          date: '2023-12-23',
          reviewCount: null,
          rating: null,
          salesRank: 4127
        },
        {
          date: '2023-12-24',
          reviewCount: null,
          rating: null,
          salesRank: 2923
        },
        {
          date: '2023-12-25',
          reviewCount: null,
          rating: null,
          salesRank: 5071
        },
        {
          date: '2023-12-26',
          reviewCount: null,
          rating: null,
          salesRank: 3689
        },
        {
          date: '2023-12-27',
          reviewCount: null,
          rating: null,
          salesRank: 3526
        },
        {
          date: '2023-12-28',
          reviewCount: null,
          rating: null,
          salesRank: 3409
        },
        {
          date: '2023-12-29',
          reviewCount: null,
          rating: null,
          salesRank: 2670
        },
        {
          date: '2023-12-30',
          reviewCount: null,
          rating: null,
          salesRank: 2637
        },
        {
          date: '2023-12-31',
          reviewCount: null,
          rating: null,
          salesRank: 2652
        },
        {
          date: '2024-01-01',
          reviewCount: null,
          rating: null,
          salesRank: 2866
        },
        {
          date: '2024-01-02',
          reviewCount: null,
          rating: null,
          salesRank: 2361
        },
        {
          date: '2024-01-03',
          reviewCount: null,
          rating: null,
          salesRank: 1945
        },
        {
          date: '2024-01-04',
          reviewCount: null,
          rating: null,
          salesRank: 1133
        },
        {
          date: '2024-01-05',
          reviewCount: null,
          rating: null,
          salesRank: 1108
        },
        {
          date: '2024-01-06',
          reviewCount: null,
          rating: null,
          salesRank: 1103
        },
        {
          date: '2024-01-07',
          reviewCount: null,
          rating: null,
          salesRank: 1054
        },
        {
          date: '2024-01-08',
          reviewCount: null,
          rating: null,
          salesRank: 758
        },
        {
          date: '2024-01-09',
          reviewCount: null,
          rating: null,
          salesRank: 1212
        },
        {
          date: '2024-01-10',
          reviewCount: null,
          rating: null,
          salesRank: 778
        },
        {
          date: '2024-01-11',
          reviewCount: null,
          rating: null,
          salesRank: 507
        },
        {
          date: '2024-01-12',
          reviewCount: null,
          rating: null,
          salesRank: 599
        },
        {
          date: '2024-01-13',
          reviewCount: null,
          rating: null,
          salesRank: 533
        },
        {
          date: '2024-01-14',
          reviewCount: null,
          rating: null,
          salesRank: 410
        },
        {
          date: '2024-01-15',
          reviewCount: null,
          rating: null,
          salesRank: 532
        },
        {
          date: '2024-01-16',
          reviewCount: null,
          rating: null,
          salesRank: 219
        },
        {
          date: '2024-01-17',
          reviewCount: null,
          rating: null,
          salesRank: 393
        },
        {
          date: '2024-01-18',
          reviewCount: null,
          rating: null,
          salesRank: 417
        },
        {
          date: '2024-01-19',
          reviewCount: null,
          rating: null,
          salesRank: 335
        },
        {
          date: '2024-01-20',
          reviewCount: null,
          rating: null,
          salesRank: 407
        },
        {
          date: '2024-01-21',
          reviewCount: null,
          rating: null,
          salesRank: 328
        },
        {
          date: '2024-01-22',
          reviewCount: null,
          rating: null,
          salesRank: 357
        },
        {
          date: '2024-01-23',
          reviewCount: null,
          rating: null,
          salesRank: 354
        },
        {
          date: '2024-01-24',
          reviewCount: null,
          rating: null,
          salesRank: 491
        },
        {
          date: '2024-01-25',
          reviewCount: null,
          rating: null,
          salesRank: 743
        },
        {
          date: '2024-01-26',
          reviewCount: null,
          rating: null,
          salesRank: 1162
        },
        {
          date: '2024-01-27',
          reviewCount: null,
          rating: null,
          salesRank: 1579
        },
        {
          date: '2024-01-28',
          reviewCount: null,
          rating: null,
          salesRank: 2221
        },
        {
          date: '2024-01-29',
          reviewCount: null,
          rating: null,
          salesRank: 2265
        },
        {
          date: '2024-01-30',
          reviewCount: null,
          rating: null,
          salesRank: 2063
        },
        {
          date: '2024-01-31',
          reviewCount: null,
          rating: null,
          salesRank: 2091
        },
        {
          date: '2024-02-01',
          reviewCount: null,
          rating: null,
          salesRank: 2633
        },
        {
          date: '2024-02-02',
          reviewCount: null,
          rating: null,
          salesRank: 2870
        },
        {
          date: '2024-02-03',
          reviewCount: null,
          rating: null,
          salesRank: 2988
        },
        {
          date: '2024-02-04',
          reviewCount: null,
          rating: null,
          salesRank: 3087
        },
        {
          date: '2024-02-05',
          reviewCount: null,
          rating: null,
          salesRank: 3251
        },
        {
          date: '2024-02-06',
          reviewCount: null,
          rating: null,
          salesRank: 2974
        },
        {
          date: '2024-02-07',
          reviewCount: null,
          rating: null,
          salesRank: 1534
        },
        {
          date: '2024-02-08',
          reviewCount: null,
          rating: null,
          salesRank: 2212
        },
        {
          date: '2024-02-09',
          reviewCount: null,
          rating: null,
          salesRank: 2686
        },
        {
          date: '2024-02-10',
          reviewCount: null,
          rating: null,
          salesRank: 2948
        },
        {
          date: '2024-02-11',
          reviewCount: null,
          rating: null,
          salesRank: 2876
        },
        {
          date: '2024-02-12',
          reviewCount: null,
          rating: null,
          salesRank: 3236
        },
        {
          date: '2024-02-13',
          reviewCount: null,
          rating: null,
          salesRank: 3101
        },
        {
          date: '2024-02-14',
          reviewCount: null,
          rating: null,
          salesRank: 3129
        },
        {
          date: '2024-02-15',
          reviewCount: null,
          rating: null,
          salesRank: 3161
        },
        {
          date: '2024-02-16',
          reviewCount: null,
          rating: null,
          salesRank: 1706
        },
        {
          date: '2024-02-17',
          reviewCount: null,
          rating: null,
          salesRank: 2722
        },
        {
          date: '2024-02-18',
          reviewCount: null,
          rating: null,
          salesRank: 3622
        },
        {
          date: '2024-02-19',
          reviewCount: null,
          rating: null,
          salesRank: 3671
        },
        {
          date: '2024-02-20',
          reviewCount: null,
          rating: null,
          salesRank: 2948
        },
        {
          date: '2024-02-21',
          reviewCount: null,
          rating: null,
          salesRank: 3188
        },
        {
          date: '2024-02-22',
          reviewCount: null,
          rating: null,
          salesRank: 4074
        },
        {
          date: '2024-02-23',
          reviewCount: null,
          rating: null,
          salesRank: 4954
        },
        {
          date: '2024-02-24',
          reviewCount: null,
          rating: null,
          salesRank: 2614
        },
        {
          date: '2024-02-25',
          reviewCount: null,
          rating: null,
          salesRank: 3114
        },
        {
          date: '2024-02-26',
          reviewCount: null,
          rating: null,
          salesRank: 3470
        },
        {
          date: '2024-02-27',
          reviewCount: null,
          rating: null,
          salesRank: 3512
        },
        {
          date: '2024-02-28',
          reviewCount: null,
          rating: null,
          salesRank: 4407
        },
        {
          date: '2024-02-29',
          reviewCount: null,
          rating: null,
          salesRank: 4913
        },
        {
          date: '2024-03-01',
          reviewCount: null,
          rating: null,
          salesRank: 4669
        },
        {
          date: '2024-03-02',
          reviewCount: null,
          rating: null,
          salesRank: 3949
        },
        {
          date: '2024-03-03',
          reviewCount: null,
          rating: null,
          salesRank: 5057
        },
        {
          date: '2024-03-04',
          reviewCount: null,
          rating: null,
          salesRank: 4528
        },
        {
          date: '2024-03-05',
          reviewCount: null,
          rating: null,
          salesRank: 5654
        },
        {
          date: '2024-03-06',
          reviewCount: null,
          rating: null,
          salesRank: 6114
        },
        {
          date: '2024-03-07',
          reviewCount: null,
          rating: null,
          salesRank: 6152
        },
        {
          date: '2024-03-08',
          reviewCount: null,
          rating: null,
          salesRank: 6123
        },
        {
          date: '2024-03-09',
          reviewCount: null,
          rating: null,
          salesRank: 6041
        },
        {
          date: '2024-03-10',
          reviewCount: null,
          rating: null,
          salesRank: 7106
        },
        {
          date: '2024-03-11',
          reviewCount: null,
          rating: null,
          salesRank: 5540
        },
        {
          date: '2024-03-12',
          reviewCount: null,
          rating: null,
          salesRank: 5360
        },
        {
          date: '2024-03-13',
          reviewCount: null,
          rating: null,
          salesRank: 6229
        },
        {
          date: '2024-03-14',
          reviewCount: null,
          rating: null,
          salesRank: 6949
        },
        {
          date: '2024-03-15',
          reviewCount: null,
          rating: null,
          salesRank: 7495
        },
        {
          date: '2024-03-16',
          reviewCount: null,
          rating: null,
          salesRank: 9085
        },
        {
          date: '2024-03-17',
          reviewCount: null,
          rating: null,
          salesRank: 10869
        },
        {
          date: '2024-03-18',
          reviewCount: null,
          rating: null,
          salesRank: 8207
        },
        {
          date: '2024-03-19',
          reviewCount: null,
          rating: null,
          salesRank: 7745
        },
        {
          date: '2024-03-20',
          reviewCount: null,
          rating: null,
          salesRank: 8378
        },
        {
          date: '2024-03-21',
          reviewCount: null,
          rating: null,
          salesRank: 7445
        },
        {
          date: '2024-03-22',
          reviewCount: null,
          rating: null,
          salesRank: 7553
        },
        {
          date: '2024-03-23',
          reviewCount: null,
          rating: null,
          salesRank: 10277
        },
        {
          date: '2024-03-24',
          reviewCount: null,
          rating: null,
          salesRank: 12825
        },
        {
          date: '2024-03-25',
          reviewCount: null,
          rating: null,
          salesRank: 11361
        },
        {
          date: '2024-03-26',
          reviewCount: null,
          rating: null,
          salesRank: 26250
        },
        {
          date: '2024-03-27',
          reviewCount: null,
          rating: null,
          salesRank: 31726
        },
        {
          date: '2024-03-28',
          reviewCount: null,
          rating: null,
          salesRank: 30951
        },
        {
          date: '2024-03-29',
          reviewCount: null,
          rating: null,
          salesRank: 36870
        },
        {
          date: '2024-03-30',
          reviewCount: null,
          rating: null,
          salesRank: 58347
        },
        {
          date: '2024-03-31',
          reviewCount: null,
          rating: null,
          salesRank: 70253
        },
        {
          date: '2024-04-01',
          reviewCount: null,
          rating: null,
          salesRank: 79643
        },
        {
          date: '2024-04-02',
          reviewCount: null,
          rating: null,
          salesRank: 88480
        },
        {
          date: '2024-04-03',
          reviewCount: null,
          rating: null,
          salesRank: 90735
        },
        {
          date: '2024-04-04',
          reviewCount: null,
          rating: null,
          salesRank: 93944
        },
        {
          date: '2024-04-05',
          reviewCount: null,
          rating: null,
          salesRank: 100853
        },
        {
          date: '2024-04-06',
          reviewCount: null,
          rating: null,
          salesRank: 76255
        },
        {
          date: '2024-04-07',
          reviewCount: null,
          rating: null,
          salesRank: 67377
        },
        {
          date: '2024-04-08',
          reviewCount: null,
          rating: null,
          salesRank: 90592
        },
        {
          date: '2024-04-09',
          reviewCount: null,
          rating: null,
          salesRank: 98022
        },
        {
          date: '2024-04-10',
          reviewCount: null,
          rating: null,
          salesRank: 102572
        },
        {
          date: '2024-04-11',
          reviewCount: null,
          rating: null,
          salesRank: 103593
        },
        {
          date: '2024-04-12',
          reviewCount: null,
          rating: null,
          salesRank: 109270
        },
        {
          date: '2024-04-13',
          reviewCount: null,
          rating: null,
          salesRank: 110051
        },
        {
          date: '2024-04-14',
          reviewCount: null,
          rating: null,
          salesRank: 102122
        },
        {
          date: '2024-04-15',
          reviewCount: null,
          rating: null,
          salesRank: 124011
        },
        {
          date: '2024-04-16',
          reviewCount: null,
          rating: null,
          salesRank: 116468
        },
        {
          date: '2024-04-17',
          reviewCount: null,
          rating: null,
          salesRank: 109762
        },
        {
          date: '2024-04-18',
          reviewCount: null,
          rating: null,
          salesRank: 110117
        },
        {
          date: '2024-04-19',
          reviewCount: null,
          rating: null,
          salesRank: 121452
        },
        {
          date: '2024-04-20',
          reviewCount: null,
          rating: null,
          salesRank: 129492
        },
        {
          date: '2024-04-21',
          reviewCount: null,
          rating: null,
          salesRank: 106455
        },
        {
          date: '2024-04-22',
          reviewCount: null,
          rating: null,
          salesRank: 116065
        },
        {
          date: '2024-04-23',
          reviewCount: null,
          rating: null,
          salesRank: 122544
        },
        {
          date: '2024-04-24',
          reviewCount: null,
          rating: null,
          salesRank: 113783
        },
        {
          date: '2024-04-25',
          reviewCount: null,
          rating: null,
          salesRank: 111001
        },
        {
          date: '2024-04-26',
          reviewCount: null,
          rating: null,
          salesRank: 108882
        },
        {
          date: '2024-04-27',
          reviewCount: null,
          rating: null,
          salesRank: 113869
        },
        {
          date: '2024-04-28',
          reviewCount: null,
          rating: null,
          salesRank: 113649
        },
        {
          date: '2024-04-29',
          reviewCount: null,
          rating: null,
          salesRank: 129223
        },
        {
          date: '2024-04-30',
          reviewCount: null,
          rating: null,
          salesRank: 129868
        },
        {
          date: '2024-05-01',
          reviewCount: null,
          rating: null,
          salesRank: 129950
        },
        {
          date: '2024-05-02',
          reviewCount: null,
          rating: null,
          salesRank: 106010
        },
        {
          date: '2024-05-03',
          reviewCount: null,
          rating: null,
          salesRank: 113490
        },
        {
          date: '2024-05-04',
          reviewCount: null,
          rating: null,
          salesRank: 100083
        },
        {
          date: '2024-05-05',
          reviewCount: null,
          rating: null,
          salesRank: 108774
        },
        {
          date: '2024-05-06',
          reviewCount: null,
          rating: null,
          salesRank: 123540
        },
        {
          date: '2024-05-07',
          reviewCount: null,
          rating: null,
          salesRank: 135290
        },
        {
          date: '2024-05-08',
          reviewCount: null,
          rating: null,
          salesRank: 120524
        },
        {
          date: '2024-05-09',
          reviewCount: null,
          rating: null,
          salesRank: 129618
        },
        {
          date: '2024-05-10',
          reviewCount: null,
          rating: null,
          salesRank: 123230
        },
        {
          date: '2024-05-11',
          reviewCount: null,
          rating: null,
          salesRank: 140432
        },
        {
          date: '2024-05-12',
          reviewCount: null,
          rating: null,
          salesRank: 132704
        },
        {
          date: '2024-05-13',
          reviewCount: null,
          rating: null,
          salesRank: 128202
        },
        {
          date: '2024-05-14',
          reviewCount: null,
          rating: null,
          salesRank: 147880
        },
        {
          date: '2024-05-15',
          reviewCount: null,
          rating: null,
          salesRank: 100805
        },
        {
          date: '2024-05-16',
          reviewCount: null,
          rating: null,
          salesRank: 73095
        },
        {
          date: '2024-05-17',
          reviewCount: null,
          rating: null,
          salesRank: 51012
        },
        {
          date: '2024-05-18',
          reviewCount: null,
          rating: null,
          salesRank: 39430
        },
        {
          date: '2024-05-19',
          reviewCount: null,
          rating: null,
          salesRank: 36181
        },
        {
          date: '2024-05-20',
          reviewCount: null,
          rating: null,
          salesRank: 27702
        },
        {
          date: '2024-05-21',
          reviewCount: null,
          rating: null,
          salesRank: 39153
        },
        {
          date: '2024-05-22',
          reviewCount: null,
          rating: null,
          salesRank: 40748
        },
        {
          date: '2024-05-23',
          reviewCount: null,
          rating: null,
          salesRank: 28014
        },
        {
          date: '2024-05-24',
          reviewCount: null,
          rating: null,
          salesRank: 32214
        },
        {
          date: '2024-05-25',
          reviewCount: null,
          rating: null,
          salesRank: 41967
        },
        {
          date: '2024-05-26',
          reviewCount: null,
          rating: null,
          salesRank: 38370
        },
        {
          date: '2024-05-27',
          reviewCount: null,
          rating: null,
          salesRank: 35464
        },
        {
          date: '2024-05-28',
          reviewCount: null,
          rating: null,
          salesRank: 37316
        },
        {
          date: '2024-05-29',
          reviewCount: null,
          rating: null,
          salesRank: 44600
        },
        {
          date: '2024-05-30',
          reviewCount: null,
          rating: null,
          salesRank: 32944
        },
        {
          date: '2024-05-31',
          reviewCount: null,
          rating: null,
          salesRank: 34489
        },
        {
          date: '2024-06-01',
          reviewCount: null,
          rating: null,
          salesRank: 39828
        },
        {
          date: '2024-06-02',
          reviewCount: null,
          rating: null,
          salesRank: 31589
        },
        {
          date: '2024-06-03',
          reviewCount: null,
          rating: null,
          salesRank: 36815
        },
        {
          date: '2024-06-04',
          reviewCount: null,
          rating: null,
          salesRank: 29093
        },
        {
          date: '2024-06-05',
          reviewCount: null,
          rating: null,
          salesRank: 34674
        },
        {
          date: '2024-06-06',
          reviewCount: null,
          rating: null,
          salesRank: 30871
        },
        {
          date: '2024-06-07',
          reviewCount: null,
          rating: null,
          salesRank: 34079
        },
        {
          date: '2024-06-08',
          reviewCount: null,
          rating: null,
          salesRank: 28554
        },
        {
          date: '2024-06-09',
          reviewCount: null,
          rating: null,
          salesRank: 41828
        },
        {
          date: '2024-06-10',
          reviewCount: null,
          rating: null,
          salesRank: 45184
        },
        {
          date: '2024-06-11',
          reviewCount: null,
          rating: null,
          salesRank: 33565
        },
        {
          date: '2024-06-12',
          reviewCount: null,
          rating: null,
          salesRank: 33042
        },
        {
          date: '2024-06-13',
          reviewCount: null,
          rating: null,
          salesRank: 42029
        },
        {
          date: '2024-06-14',
          reviewCount: null,
          rating: null,
          salesRank: 35510
        },
        {
          date: '2024-06-15',
          reviewCount: null,
          rating: null,
          salesRank: 34955
        },
        {
          date: '2024-06-16',
          reviewCount: null,
          rating: null,
          salesRank: 36268
        },
        {
          date: '2024-06-17',
          reviewCount: null,
          rating: null,
          salesRank: 40699
        },
        {
          date: '2024-06-18',
          reviewCount: null,
          rating: null,
          salesRank: 30112
        },
        {
          date: '2024-06-19',
          reviewCount: null,
          rating: null,
          salesRank: 33744
        },
        {
          date: '2024-06-20',
          reviewCount: null,
          rating: null,
          salesRank: 32740
        },
        {
          date: '2024-06-21',
          reviewCount: null,
          rating: null,
          salesRank: 34020
        },
        {
          date: '2024-06-22',
          reviewCount: null,
          rating: null,
          salesRank: 26514
        },
        {
          date: '2024-06-23',
          reviewCount: null,
          rating: null,
          salesRank: 38197
        },
        {
          date: '2024-06-24',
          reviewCount: null,
          rating: null,
          salesRank: 40286
        },
        {
          date: '2024-06-25',
          reviewCount: null,
          rating: null,
          salesRank: 29474
        },
        {
          date: '2024-06-26',
          reviewCount: null,
          rating: null,
          salesRank: 33561
        },
        {
          date: '2024-06-27',
          reviewCount: null,
          rating: null,
          salesRank: 34145
        },
        {
          date: '2024-06-28',
          reviewCount: null,
          rating: null,
          salesRank: 33057
        },
        {
          date: '2024-06-29',
          reviewCount: null,
          rating: null,
          salesRank: 37275
        },
        {
          date: '2024-06-30',
          reviewCount: null,
          rating: null,
          salesRank: 38297
        },
        {
          date: '2024-07-01',
          reviewCount: null,
          rating: null,
          salesRank: 28053
        },
        {
          date: '2024-07-02',
          reviewCount: null,
          rating: null,
          salesRank: 28455
        },
        {
          date: '2024-07-03',
          reviewCount: null,
          rating: null,
          salesRank: 28068
        },
        {
          date: '2024-07-04',
          reviewCount: null,
          rating: null,
          salesRank: 30563
        },
        {
          date: '2024-07-05',
          reviewCount: null,
          rating: null,
          salesRank: 27903
        },
        {
          date: '2024-07-06',
          reviewCount: null,
          rating: null,
          salesRank: 34857
        },
        {
          date: '2024-07-07',
          reviewCount: null,
          rating: null,
          salesRank: 33756
        },
        {
          date: '2024-07-08',
          reviewCount: null,
          rating: null,
          salesRank: 32225
        },
        {
          date: '2024-07-09',
          reviewCount: null,
          rating: null,
          salesRank: 31455
        },
        {
          date: '2024-07-10',
          reviewCount: null,
          rating: null,
          salesRank: 25167
        },
        {
          date: '2024-07-11',
          reviewCount: null,
          rating: null,
          salesRank: 28088
        },
        {
          date: '2024-07-12',
          reviewCount: null,
          rating: null,
          salesRank: 25907
        },
        {
          date: '2024-07-13',
          reviewCount: null,
          rating: null,
          salesRank: 26748
        },
        {
          date: '2024-07-14',
          reviewCount: null,
          rating: null,
          salesRank: 30876
        },
        {
          date: '2024-07-15',
          reviewCount: null,
          rating: null,
          salesRank: 29917
        },
        {
          date: '2024-07-16',
          reviewCount: null,
          rating: null,
          salesRank: 23815
        },
        {
          date: '2024-07-17',
          reviewCount: null,
          rating: null,
          salesRank: 24919
        },
        {
          date: '2024-07-18',
          reviewCount: null,
          rating: null,
          salesRank: 29994
        },
        {
          date: '2024-07-19',
          reviewCount: null,
          rating: null,
          salesRank: 27895
        },
        {
          date: '2024-07-20',
          reviewCount: null,
          rating: null,
          salesRank: 31090
        },
        {
          date: '2024-07-21',
          reviewCount: null,
          rating: null,
          salesRank: 28832
        },
        {
          date: '2024-07-22',
          reviewCount: null,
          rating: null,
          salesRank: 34248
        },
        {
          date: '2024-07-23',
          reviewCount: null,
          rating: null,
          salesRank: 29532
        },
        {
          date: '2024-07-24',
          reviewCount: null,
          rating: null,
          salesRank: 27641
        },
        {
          date: '2024-07-25',
          reviewCount: null,
          rating: null,
          salesRank: 35531
        },
        {
          date: '2024-07-26',
          reviewCount: null,
          rating: null,
          salesRank: 37120
        },
        {
          date: '2024-07-27',
          reviewCount: null,
          rating: null,
          salesRank: 37493
        },
        {
          date: '2024-07-28',
          reviewCount: null,
          rating: null,
          salesRank: 34022
        },
        {
          date: '2024-07-29',
          reviewCount: null,
          rating: null,
          salesRank: 37211
        },
        {
          date: '2024-07-30',
          reviewCount: null,
          rating: null,
          salesRank: 28370
        },
        {
          date: '2024-07-31',
          reviewCount: null,
          rating: null,
          salesRank: 35414
        },
        {
          date: '2024-08-01',
          reviewCount: null,
          rating: null,
          salesRank: 31021
        },
        {
          date: '2024-08-02',
          reviewCount: null,
          rating: null,
          salesRank: 29410
        },
        {
          date: '2024-08-03',
          reviewCount: null,
          rating: null,
          salesRank: 31847
        },
        {
          date: '2024-08-04',
          reviewCount: null,
          rating: null,
          salesRank: 30984
        },
        {
          date: '2024-08-05',
          reviewCount: null,
          rating: null,
          salesRank: 27230
        },
        {
          date: '2024-08-06',
          reviewCount: null,
          rating: null,
          salesRank: 24642
        },
        {
          date: '2024-08-07',
          reviewCount: null,
          rating: null,
          salesRank: 23020
        },
        {
          date: '2024-08-08',
          reviewCount: null,
          rating: null,
          salesRank: 22504
        },
        {
          date: '2024-08-09',
          reviewCount: null,
          rating: null,
          salesRank: 32325
        },
        {
          date: '2024-08-10',
          reviewCount: null,
          rating: null,
          salesRank: 29517
        },
        {
          date: '2024-08-11',
          reviewCount: null,
          rating: null,
          salesRank: 25935
        },
        {
          date: '2024-08-12',
          reviewCount: null,
          rating: null,
          salesRank: 26437
        },
        {
          date: '2024-08-13',
          reviewCount: null,
          rating: null,
          salesRank: 22673
        },
        {
          date: '2024-08-14',
          reviewCount: null,
          rating: null,
          salesRank: 24029
        },
        {
          date: '2024-08-15',
          reviewCount: null,
          rating: null,
          salesRank: 28337
        },
        {
          date: '2024-08-16',
          reviewCount: null,
          rating: null,
          salesRank: 25556
        },
        {
          date: '2024-08-19',
          reviewCount: null,
          rating: null,
          salesRank: 23157
        },
        {
          date: '2024-08-20',
          reviewCount: null,
          rating: null,
          salesRank: 23685
        },
        {
          date: '2024-08-21',
          reviewCount: null,
          rating: null,
          salesRank: 17720
        },
        {
          date: '2024-08-22',
          reviewCount: null,
          rating: null,
          salesRank: 20106
        },
        {
          date: '2024-08-23',
          reviewCount: null,
          rating: null,
          salesRank: 18378
        },
        {
          date: '2024-08-24',
          reviewCount: null,
          rating: null,
          salesRank: 25735
        },
        {
          date: '2024-08-25',
          reviewCount: null,
          rating: null,
          salesRank: 24984
        },
        {
          date: '2024-08-26',
          reviewCount: null,
          rating: null,
          salesRank: 22685
        },
        {
          date: '2024-08-27',
          reviewCount: null,
          rating: null,
          salesRank: 18448
        },
        {
          date: '2024-08-28',
          reviewCount: null,
          rating: null,
          salesRank: 22740
        },
        {
          date: '2024-08-29',
          reviewCount: null,
          rating: null,
          salesRank: 24682
        },
        {
          date: '2024-08-30',
          reviewCount: null,
          rating: null,
          salesRank: 22593
        },
        {
          date: '2024-08-31',
          reviewCount: null,
          rating: null,
          salesRank: 22178
        },
        {
          date: '2024-09-01',
          reviewCount: null,
          rating: null,
          salesRank: 19827
        },
        {
          date: '2024-09-02',
          reviewCount: null,
          rating: null,
          salesRank: 21251
        },
        {
          date: '2024-09-03',
          reviewCount: null,
          rating: null,
          salesRank: 18714
        },
        {
          date: '2024-09-04',
          reviewCount: null,
          rating: null,
          salesRank: 17644
        },
        {
          date: '2024-09-05',
          reviewCount: null,
          rating: null,
          salesRank: 15937
        },
        {
          date: '2024-09-06',
          reviewCount: null,
          rating: null,
          salesRank: 15088
        },
        {
          date: '2024-09-07',
          reviewCount: null,
          rating: null,
          salesRank: 14220
        },
        {
          date: '2024-09-08',
          reviewCount: null,
          rating: null,
          salesRank: 14378
        },
        {
          date: '2024-09-09',
          reviewCount: null,
          rating: null,
          salesRank: 16273
        },
        {
          date: '2024-09-10',
          reviewCount: null,
          rating: null,
          salesRank: 14653
        },
        {
          date: '2024-09-11',
          reviewCount: null,
          rating: null,
          salesRank: 11176
        },
        {
          date: '2024-09-12',
          reviewCount: null,
          rating: null,
          salesRank: 11089
        },
        {
          date: '2024-09-13',
          reviewCount: null,
          rating: null,
          salesRank: 12770
        },
        {
          date: '2024-09-14',
          reviewCount: null,
          rating: null,
          salesRank: 13548
        },
        {
          date: '2024-09-15',
          reviewCount: null,
          rating: null,
          salesRank: 15468
        },
        {
          date: '2024-09-16',
          reviewCount: null,
          rating: null,
          salesRank: 14970
        },
        {
          date: '2024-09-17',
          reviewCount: null,
          rating: null,
          salesRank: 14106
        },
        {
          date: '2024-09-18',
          reviewCount: null,
          rating: null,
          salesRank: 13926
        },
        {
          date: '2024-09-19',
          reviewCount: null,
          rating: null,
          salesRank: 17258
        },
        {
          date: '2024-09-20',
          reviewCount: null,
          rating: null,
          salesRank: 16071
        },
        {
          date: '2024-09-21',
          reviewCount: null,
          rating: null,
          salesRank: 14566
        },
        {
          date: '2024-09-22',
          reviewCount: null,
          rating: null,
          salesRank: 14485
        },
        {
          date: '2024-09-23',
          reviewCount: null,
          rating: null,
          salesRank: 14921
        },
        {
          date: '2024-09-24',
          reviewCount: null,
          rating: null,
          salesRank: 16076
        },
        {
          date: '2024-09-25',
          reviewCount: null,
          rating: null,
          salesRank: 15169
        },
        {
          date: '2024-09-26',
          reviewCount: null,
          rating: null,
          salesRank: 16351
        },
        {
          date: '2024-09-27',
          reviewCount: null,
          rating: null,
          salesRank: 14082
        },
        {
          date: '2024-09-28',
          reviewCount: null,
          rating: null,
          salesRank: 9857
        },
        {
          date: '2024-09-29',
          reviewCount: null,
          rating: null,
          salesRank: 12619
        },
        {
          date: '2024-09-30',
          reviewCount: null,
          rating: null,
          salesRank: 9194
        },
        {
          date: '2024-10-01',
          reviewCount: null,
          rating: null,
          salesRank: 8650
        },
        {
          date: '2024-10-02',
          reviewCount: null,
          rating: null,
          salesRank: 11307
        },
        {
          date: '2024-10-03',
          reviewCount: null,
          rating: null,
          salesRank: 10244
        },
        {
          date: '2024-10-04',
          reviewCount: null,
          rating: null,
          salesRank: 11522
        },
        {
          date: '2024-10-05',
          reviewCount: null,
          rating: null,
          salesRank: 11847
        },
        {
          date: '2024-10-06',
          reviewCount: null,
          rating: null,
          salesRank: 14920
        },
        {
          date: '2024-10-07',
          reviewCount: null,
          rating: null,
          salesRank: 12348
        },
        {
          date: '2024-10-08',
          reviewCount: null,
          rating: null,
          salesRank: 4584
        },
        {
          date: '2024-10-09',
          reviewCount: null,
          rating: null,
          salesRank: 4613
        },
        {
          date: '2024-10-10',
          reviewCount: null,
          rating: null,
          salesRank: 5632
        },
        {
          date: '2024-10-11',
          reviewCount: null,
          rating: null,
          salesRank: 6781
        },
        {
          date: '2024-10-12',
          reviewCount: null,
          rating: null,
          salesRank: 9703
        },
        {
          date: '2024-10-13',
          reviewCount: null,
          rating: null,
          salesRank: 8955
        },
        {
          date: '2024-10-14',
          reviewCount: null,
          rating: null,
          salesRank: 12488
        },
        {
          date: '2024-10-15',
          reviewCount: null,
          rating: null,
          salesRank: 7875
        },
        {
          date: '2024-10-16',
          reviewCount: null,
          rating: null,
          salesRank: 7950
        },
        {
          date: '2024-10-17',
          reviewCount: null,
          rating: null,
          salesRank: 8984
        },
        {
          date: '2024-10-18',
          reviewCount: null,
          rating: null,
          salesRank: 9041
        },
        {
          date: '2024-10-19',
          reviewCount: null,
          rating: null,
          salesRank: 8484
        },
        {
          date: '2024-10-20',
          reviewCount: null,
          rating: null,
          salesRank: 10628
        },
        {
          date: '2024-10-21',
          reviewCount: null,
          rating: null,
          salesRank: 9601
        },
        {
          date: '2024-10-22',
          reviewCount: null,
          rating: null,
          salesRank: 9466
        },
        {
          date: '2024-10-23',
          reviewCount: null,
          rating: null,
          salesRank: 10012
        },
        {
          date: '2024-10-24',
          reviewCount: null,
          rating: null,
          salesRank: 10886
        },
        {
          date: '2024-10-25',
          reviewCount: null,
          rating: null,
          salesRank: 12305
        },
        {
          date: '2024-10-26',
          reviewCount: null,
          rating: null,
          salesRank: 12659
        },
        {
          date: '2024-10-27',
          reviewCount: null,
          rating: null,
          salesRank: 14503
        },
        {
          date: '2024-10-28',
          reviewCount: null,
          rating: null,
          salesRank: 10543
        },
        {
          date: '2024-10-29',
          reviewCount: null,
          rating: null,
          salesRank: 11726
        },
        {
          date: '2024-10-30',
          reviewCount: null,
          rating: null,
          salesRank: 20680
        },
        {
          date: '2024-10-31',
          reviewCount: null,
          rating: null,
          salesRank: 20042
        },
        {
          date: '2024-11-01',
          reviewCount: null,
          rating: null,
          salesRank: 21002
        },
        {
          date: '2024-11-02',
          reviewCount: null,
          rating: null,
          salesRank: 19892
        },
        {
          date: '2024-11-03',
          reviewCount: null,
          rating: null,
          salesRank: 24501
        },
        {
          date: '2024-11-04',
          reviewCount: null,
          rating: null,
          salesRank: 17587
        },
        {
          date: '2024-11-05',
          reviewCount: null,
          rating: null,
          salesRank: 13469
        },
        {
          date: '2024-11-06',
          reviewCount: null,
          rating: null,
          salesRank: 14174
        },
        {
          date: '2024-11-07',
          reviewCount: null,
          rating: null,
          salesRank: 11396
        },
        {
          date: '2024-11-08',
          reviewCount: null,
          rating: null,
          salesRank: 12174
        },
        {
          date: '2024-11-09',
          reviewCount: null,
          rating: null,
          salesRank: 12913
        },
        {
          date: '2024-11-10',
          reviewCount: null,
          rating: null,
          salesRank: 11813
        },
        {
          date: '2024-11-11',
          reviewCount: null,
          rating: null,
          salesRank: 11226
        },
        {
          date: '2024-11-12',
          reviewCount: null,
          rating: null,
          salesRank: 8932
        },
        {
          date: '2024-11-13',
          reviewCount: null,
          rating: null,
          salesRank: 10124
        },
        {
          date: '2024-11-14',
          reviewCount: null,
          rating: null,
          salesRank: 9735
        },
        {
          date: '2024-11-15',
          reviewCount: null,
          rating: null,
          salesRank: 9218
        },
        {
          date: '2024-11-16',
          reviewCount: null,
          rating: null,
          salesRank: 8294
        },
        {
          date: '2024-11-17',
          reviewCount: null,
          rating: null,
          salesRank: 9822
        },
        {
          date: '2024-11-18',
          reviewCount: null,
          rating: null,
          salesRank: 8030
        },
        {
          date: '2024-11-19',
          reviewCount: null,
          rating: null,
          salesRank: 8271
        },
        {
          date: '2024-11-20',
          reviewCount: null,
          rating: null,
          salesRank: 8549
        },
        {
          date: '2024-11-21',
          reviewCount: null,
          rating: null,
          salesRank: 6243
        },
        {
          date: '2024-11-22',
          reviewCount: null,
          rating: null,
          salesRank: 5939
        },
        {
          date: '2024-11-23',
          reviewCount: null,
          rating: null,
          salesRank: 7887
        },
        {
          date: '2024-11-24',
          reviewCount: null,
          rating: null,
          salesRank: 9551
        },
        {
          date: '2024-11-25',
          reviewCount: null,
          rating: null,
          salesRank: 10689
        },
        {
          date: '2024-11-26',
          reviewCount: null,
          rating: null,
          salesRank: 11471
        },
        {
          date: '2024-11-27',
          reviewCount: null,
          rating: null,
          salesRank: 11396
        },
        {
          date: '2024-11-28',
          reviewCount: null,
          rating: null,
          salesRank: 10855
        },
        {
          date: '2024-11-29',
          reviewCount: null,
          rating: null,
          salesRank: 9394
        },
        {
          date: '2024-11-30',
          reviewCount: null,
          rating: null,
          salesRank: 9153
        },
        {
          date: '2024-12-01',
          reviewCount: null,
          rating: null,
          salesRank: 7275
        },
        {
          date: '2024-12-02',
          reviewCount: null,
          rating: null,
          salesRank: 5029
        },
        {
          date: '2024-12-03',
          reviewCount: null,
          rating: null,
          salesRank: 4489
        },
        {
          date: '2024-12-04',
          reviewCount: null,
          rating: null,
          salesRank: 7046
        },
        {
          date: '2024-12-05',
          reviewCount: null,
          rating: null,
          salesRank: 5722
        },
        {
          date: '2024-12-06',
          reviewCount: null,
          rating: null,
          salesRank: 3855
        },
        {
          date: '2024-12-07',
          reviewCount: null,
          rating: null,
          salesRank: 4334
        },
        {
          date: '2024-12-08',
          reviewCount: null,
          rating: null,
          salesRank: 5800
        },
        {
          date: '2024-12-09',
          reviewCount: null,
          rating: null,
          salesRank: 7980
        },
        {
          date: '2024-12-10',
          reviewCount: null,
          rating: null,
          salesRank: 10288
        },
        {
          date: '2024-12-11',
          reviewCount: null,
          rating: null,
          salesRank: 12819
        },
        {
          date: '2024-12-12',
          reviewCount: null,
          rating: null,
          salesRank: 13807
        },
        {
          date: '2024-12-13',
          reviewCount: null,
          rating: null,
          salesRank: 14707
        },
        {
          date: '2024-12-14',
          reviewCount: null,
          rating: null,
          salesRank: 14655
        },
        {
          date: '2024-12-15',
          reviewCount: null,
          rating: null,
          salesRank: 11719
        },
        {
          date: '2024-12-16',
          reviewCount: null,
          rating: null,
          salesRank: 9690
        },
        {
          date: '2024-12-17',
          reviewCount: null,
          rating: null,
          salesRank: 8067
        },
        {
          date: '2024-12-18',
          reviewCount: null,
          rating: null,
          salesRank: 11382
        },
        {
          date: '2024-12-19',
          reviewCount: null,
          rating: null,
          salesRank: 12990
        },
        {
          date: '2024-12-20',
          reviewCount: null,
          rating: null,
          salesRank: 13635
        },
        {
          date: '2024-12-21',
          reviewCount: null,
          rating: null,
          salesRank: 13831
        },
        {
          date: '2024-12-22',
          reviewCount: null,
          rating: null,
          salesRank: 13238
        },
        {
          date: '2024-12-23',
          reviewCount: null,
          rating: null,
          salesRank: 9819
        },
        {
          date: '2024-12-24',
          reviewCount: null,
          rating: null,
          salesRank: 10528
        },
        {
          date: '2024-12-25',
          reviewCount: null,
          rating: null,
          salesRank: 11043
        },
        {
          date: '2024-12-26',
          reviewCount: null,
          rating: null,
          salesRank: 8792
        },
        {
          date: '2024-12-27',
          reviewCount: null,
          rating: null,
          salesRank: 7695
        },
        {
          date: '2024-12-28',
          reviewCount: null,
          rating: null,
          salesRank: 8496
        },
        {
          date: '2024-12-29',
          reviewCount: null,
          rating: null,
          salesRank: 9834
        },
        {
          date: '2024-12-30',
          reviewCount: null,
          rating: null,
          salesRank: 7144
        },
        {
          date: '2024-12-31',
          reviewCount: null,
          rating: null,
          salesRank: 2501
        },
        {
          date: '2025-01-01',
          reviewCount: null,
          rating: null,
          salesRank: 6088
        },
        {
          date: '2025-01-02',
          reviewCount: null,
          rating: null,
          salesRank: 6518
        },
        {
          date: '2025-01-03',
          reviewCount: null,
          rating: null,
          salesRank: 6923
        },
        {
          date: '2025-01-04',
          reviewCount: null,
          rating: null,
          salesRank: 7039
        },
        {
          date: '2025-01-05',
          reviewCount: null,
          rating: null,
          salesRank: 6190
        },
        {
          date: '2025-01-06',
          reviewCount: null,
          rating: null,
          salesRank: 6576
        },
        {
          date: '2025-01-07',
          reviewCount: null,
          rating: null,
          salesRank: 5143
        },
        {
          date: '2025-01-08',
          reviewCount: null,
          rating: null,
          salesRank: 5815
        },
        {
          date: '2025-01-09',
          reviewCount: null,
          rating: null,
          salesRank: 5324
        },
        {
          date: '2025-01-10',
          reviewCount: null,
          rating: null,
          salesRank: 4875
        },
        {
          date: '2025-01-11',
          reviewCount: null,
          rating: null,
          salesRank: 5277
        },
        {
          date: '2025-01-12',
          reviewCount: null,
          rating: null,
          salesRank: 4980
        },
        {
          date: '2025-01-13',
          reviewCount: null,
          rating: null,
          salesRank: 5015
        },
        {
          date: '2025-01-14',
          reviewCount: null,
          rating: null,
          salesRank: 6234
        },
        {
          date: '2025-01-15',
          reviewCount: null,
          rating: null,
          salesRank: 6682
        },
        {
          date: '2025-01-16',
          reviewCount: null,
          rating: null,
          salesRank: 7188
        },
        {
          date: '2025-01-17',
          reviewCount: null,
          rating: null,
          salesRank: 6454
        },
        {
          date: '2025-01-18',
          reviewCount: null,
          rating: null,
          salesRank: 7096
        },
        {
          date: '2025-01-19',
          reviewCount: null,
          rating: null,
          salesRank: 8829
        },
        {
          date: '2025-01-20',
          reviewCount: null,
          rating: null,
          salesRank: 6933
        },
        {
          date: '2025-01-21',
          reviewCount: null,
          rating: null,
          salesRank: 6242
        },
        {
          date: '2025-01-22',
          reviewCount: null,
          rating: null,
          salesRank: 6209
        },
        {
          date: '2025-01-23',
          reviewCount: null,
          rating: null,
          salesRank: 4425
        },
        {
          date: '2025-01-24',
          reviewCount: null,
          rating: null,
          salesRank: 4531
        },
        {
          date: '2025-01-25',
          reviewCount: null,
          rating: null,
          salesRank: 5119
        },
        {
          date: '2025-01-26',
          reviewCount: null,
          rating: null,
          salesRank: 5064
        },
        {
          date: '2025-01-27',
          reviewCount: null,
          rating: null,
          salesRank: 4258
        },
        {
          date: '2025-01-28',
          reviewCount: null,
          rating: null,
          salesRank: 4899
        },
        {
          date: '2025-01-29',
          reviewCount: null,
          rating: null,
          salesRank: 5872
        },
        {
          date: '2025-01-30',
          reviewCount: null,
          rating: null,
          salesRank: 4954
        },
        {
          date: '2025-01-31',
          reviewCount: null,
          rating: null,
          salesRank: 5144
        },
        {
          date: '2025-02-01',
          reviewCount: null,
          rating: null,
          salesRank: 6910
        },
        {
          date: '2025-02-02',
          reviewCount: null,
          rating: null,
          salesRank: 6558
        },
        {
          date: '2025-02-03',
          reviewCount: null,
          rating: null,
          salesRank: 6423
        },
        {
          date: '2025-02-04',
          reviewCount: null,
          rating: null,
          salesRank: 5951
        },
        {
          date: '2025-02-05',
          reviewCount: null,
          rating: null,
          salesRank: 6244
        },
        {
          date: '2025-02-06',
          reviewCount: null,
          rating: null,
          salesRank: 8227
        },
        {
          date: '2025-02-07',
          reviewCount: null,
          rating: null,
          salesRank: 7653
        },
        {
          date: '2025-02-08',
          reviewCount: null,
          rating: null,
          salesRank: 8118
        },
        {
          date: '2025-02-09',
          reviewCount: null,
          rating: null,
          salesRank: 7364
        },
        {
          date: '2025-02-10',
          reviewCount: null,
          rating: null,
          salesRank: 7621
        },
        {
          date: '2025-02-11',
          reviewCount: null,
          rating: null,
          salesRank: 8590
        },
        {
          date: '2025-02-12',
          reviewCount: null,
          rating: null,
          salesRank: 8187
        },
        {
          date: '2025-02-13',
          reviewCount: null,
          rating: null,
          salesRank: 7701
        },
        {
          date: '2025-02-14',
          reviewCount: null,
          rating: null,
          salesRank: 8088
        },
        {
          date: '2025-02-15',
          reviewCount: null,
          rating: null,
          salesRank: 7222
        },
        {
          date: '2025-02-16',
          reviewCount: null,
          rating: null,
          salesRank: 7976
        },
        {
          date: '2025-02-17',
          reviewCount: null,
          rating: null,
          salesRank: 7861
        },
        {
          date: '2025-02-18',
          reviewCount: null,
          rating: null,
          salesRank: 7218
        },
        {
          date: '2025-02-19',
          reviewCount: null,
          rating: null,
          salesRank: 6831
        },
        {
          date: '2025-02-20',
          reviewCount: null,
          rating: null,
          salesRank: 8166
        },
        {
          date: '2025-02-21',
          reviewCount: null,
          rating: null,
          salesRank: 7035
        },
        {
          date: '2025-02-22',
          reviewCount: null,
          rating: null,
          salesRank: 7406
        },
        {
          date: '2025-02-23',
          reviewCount: null,
          rating: null,
          salesRank: 8472
        },
        {
          date: '2025-02-24',
          reviewCount: null,
          rating: null,
          salesRank: 7511
        },
        {
          date: '2025-02-25',
          reviewCount: null,
          rating: null,
          salesRank: 7825
        },
        {
          date: '2025-02-26',
          reviewCount: null,
          rating: null,
          salesRank: 10120
        },
        {
          date: '2025-02-27',
          reviewCount: null,
          rating: null,
          salesRank: 10253
        },
        {
          date: '2025-02-28',
          reviewCount: null,
          rating: null,
          salesRank: 10449
        },
        {
          date: '2025-03-01',
          reviewCount: null,
          rating: null,
          salesRank: 11462
        },
        {
          date: '2025-03-02',
          reviewCount: null,
          rating: null,
          salesRank: 11580
        },
        {
          date: '2025-03-03',
          reviewCount: null,
          rating: null,
          salesRank: 10801
        },
        {
          date: '2025-03-04',
          reviewCount: null,
          rating: null,
          salesRank: 14804
        },
        {
          date: '2025-03-05',
          reviewCount: null,
          rating: null,
          salesRank: 11990
        },
        {
          date: '2025-03-06',
          reviewCount: null,
          rating: null,
          salesRank: 10041
        },
        {
          date: '2025-03-07',
          reviewCount: null,
          rating: null,
          salesRank: 9803
        },
        {
          date: '2025-03-08',
          reviewCount: null,
          rating: null,
          salesRank: 11222
        },
        {
          date: '2025-03-09',
          reviewCount: null,
          rating: null,
          salesRank: 13921
        },
        {
          date: '2025-03-10',
          reviewCount: null,
          rating: null,
          salesRank: 12935
        },
        {
          date: '2025-03-11',
          reviewCount: null,
          rating: null,
          salesRank: 13165
        },
        {
          date: '2025-03-12',
          reviewCount: null,
          rating: null,
          salesRank: 13019
        },
        {
          date: '2025-03-13',
          reviewCount: null,
          rating: null,
          salesRank: 14519
        },
        {
          date: '2025-03-14',
          reviewCount: null,
          rating: null,
          salesRank: 15921
        },
        {
          date: '2025-03-15',
          reviewCount: null,
          rating: null,
          salesRank: 18447
        },
        {
          date: '2025-03-16',
          reviewCount: null,
          rating: null,
          salesRank: 18475
        },
        {
          date: '2025-03-17',
          reviewCount: null,
          rating: null,
          salesRank: 21041
        },
        {
          date: '2025-03-18',
          reviewCount: null,
          rating: null,
          salesRank: 15196
        }
      ],
      reviewDataUploaded: false,
      bestSellerRankDataUploaded: true,
      isVisible: true,
      isCategoryAverage: false,
      createdAt: '2025-02-24T18:06:45.367Z'
    }
  ]
}
