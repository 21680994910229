//@ts-nocheck
import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'

export const SAMPLE_ETAILER_PRODUCT_METRICS_LAUNDRY: ModelTypes['ModelItemsEtailerProductMetrics'] = {
  items: [
    {
      date: '2023-08-02',
      pageViews: 25830,
      totalSalesAmount: 14980,
      unitsSold: 10
    },
    {
      date: '2023-08-03',
      pageViews: 15430,
      totalSalesAmount: 17976,
      unitsSold: 12
    },
    {
      date: '2023-08-04',
      pageViews: 20223,
      totalSalesAmount: 14980,
      unitsSold: 10
    },
    {
      date: '2023-08-05',
      pageViews: 4631,
      totalSalesAmount: 16478,
      unitsSold: 11
    },
    {
      date: '2023-08-06',
      pageViews: 22604,
      totalSalesAmount: 17976,
      unitsSold: 12
    },
    {
      date: '2023-08-07',
      pageViews: 9947,
      totalSalesAmount: 16478,
      unitsSold: 11
    },
    {
      date: '2023-08-08',
      pageViews: 27678,
      totalSalesAmount: 14980,
      unitsSold: 10
    },
    {
      date: '2023-08-09',
      pageViews: 18214,
      totalSalesAmount: 14980,
      unitsSold: 10
    },
    {
      date: '2023-08-10',
      pageViews: 17987,
      totalSalesAmount: 14980,
      unitsSold: 10
    },
    {
      date: '2023-08-11',
      pageViews: 22340,
      totalSalesAmount: 16478,
      unitsSold: 11
    },
    {
      date: '2023-08-12',
      pageViews: 21108,
      totalSalesAmount: 16478,
      unitsSold: 11
    },
    {
      date: '2023-08-13',
      pageViews: 18025,
      totalSalesAmount: 14980,
      unitsSold: 10
    },
    {
      date: '2023-08-14',
      pageViews: 3349,
      totalSalesAmount: 16478,
      unitsSold: 11
    },
    {
      date: '2023-08-15',
      pageViews: 17765,
      totalSalesAmount: 17976,
      unitsSold: 12
    },
    {
      date: '2023-08-16',
      pageViews: 8553,
      totalSalesAmount: 14980,
      unitsSold: 10
    },
    {
      date: '2023-08-17',
      pageViews: 11350,
      totalSalesAmount: 14980,
      unitsSold: 10
    },
    {
      date: '2023-08-18',
      pageViews: 8210,
      totalSalesAmount: 17976,
      unitsSold: 12
    },
    {
      date: '2023-08-19',
      pageViews: 10624,
      totalSalesAmount: 16478,
      unitsSold: 11
    },
    {
      date: '2023-08-20',
      pageViews: 3717,
      totalSalesAmount: 16478,
      unitsSold: 11
    },
    {
      date: '2023-08-21',
      pageViews: 7082,
      totalSalesAmount: 16478,
      unitsSold: 11
    },
    {
      date: '2023-08-22',
      pageViews: 12451,
      totalSalesAmount: 16478,
      unitsSold: 11
    },
    {
      date: '2023-08-23',
      pageViews: 31501,
      totalSalesAmount: 16478,
      unitsSold: 11
    },
    {
      date: '2023-08-24',
      pageViews: 27077,
      totalSalesAmount: 17976,
      unitsSold: 12
    },
    {
      date: '2023-08-25',
      pageViews: 26721,
      totalSalesAmount: 16478,
      unitsSold: 11
    },
    {
      date: '2023-08-26',
      pageViews: 17142,
      totalSalesAmount: 16478,
      unitsSold: 11
    },
    {
      date: '2023-08-27',
      pageViews: 21285,
      totalSalesAmount: 14980,
      unitsSold: 10
    },
    {
      date: '2023-08-28',
      pageViews: 16636,
      totalSalesAmount: 16478,
      unitsSold: 11
    },
    {
      date: '2023-08-29',
      pageViews: 25641,
      totalSalesAmount: 16478,
      unitsSold: 11
    },
    {
      date: '2023-08-30',
      pageViews: 12414,
      totalSalesAmount: 14980,
      unitsSold: 10
    },
    {
      date: '2023-08-31',
      pageViews: 22958,
      totalSalesAmount: 14980,
      unitsSold: 10
    },
    {
      date: '2023-09-01',
      pageViews: 19812,
      totalSalesAmount: 16478,
      unitsSold: 11
    },
    {
      date: '2023-09-02',
      pageViews: 26333,
      totalSalesAmount: 17976,
      unitsSold: 12
    },
    {
      date: '2023-09-03',
      pageViews: 18689,
      totalSalesAmount: 17976,
      unitsSold: 12
    },
    {
      date: '2023-09-04',
      pageViews: 10117,
      totalSalesAmount: 16478,
      unitsSold: 11
    },
    {
      date: '2023-09-05',
      pageViews: 15702,
      totalSalesAmount: 17976,
      unitsSold: 12
    },
    {
      date: '2023-09-06',
      pageViews: 24348,
      totalSalesAmount: 16478,
      unitsSold: 11
    },
    {
      date: '2023-09-07',
      pageViews: 13699,
      totalSalesAmount: 17976,
      unitsSold: 12
    },
    {
      date: '2023-09-08',
      pageViews: 16427,
      totalSalesAmount: 14980,
      unitsSold: 10
    },
    {
      date: '2023-09-09',
      pageViews: 15049,
      totalSalesAmount: 17976,
      unitsSold: 12
    },
    {
      date: '2023-09-10',
      pageViews: 15521,
      totalSalesAmount: 16478,
      unitsSold: 11
    },
    {
      date: '2023-09-11',
      pageViews: 27562,
      totalSalesAmount: 16478,
      unitsSold: 11
    },
    {
      date: '2023-09-12',
      pageViews: 24299,
      totalSalesAmount: 16478,
      unitsSold: 11
    },
    {
      date: '2023-09-13',
      pageViews: 14827,
      totalSalesAmount: 16478,
      unitsSold: 11
    },
    {
      date: '2023-09-14',
      pageViews: 25006,
      totalSalesAmount: 14980,
      unitsSold: 10
    },
    {
      date: '2023-09-15',
      pageViews: 24572,
      totalSalesAmount: 14980,
      unitsSold: 10
    },
    {
      date: '2023-09-16',
      pageViews: 22908,
      totalSalesAmount: 14980,
      unitsSold: 10
    },
    {
      date: '2023-09-17',
      pageViews: 15398,
      totalSalesAmount: 14980,
      unitsSold: 10
    },
    {
      date: '2023-09-18',
      pageViews: 17734,
      totalSalesAmount: 17976,
      unitsSold: 12
    },
    {
      date: '2023-09-19',
      pageViews: 32082,
      totalSalesAmount: 14980,
      unitsSold: 10
    },
    {
      date: '2023-09-20',
      pageViews: 30392,
      totalSalesAmount: 16478,
      unitsSold: 11
    }
  ]
}
