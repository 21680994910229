import { SvgIcon, SvgIconProps } from '@mui/material'

export default function HeartWithRibbonIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 25" fill="none">
      <path
        d="M17.7972 2.17993C13.6891 2.17993 12.1797 6.37806 12.1797 6.37806C12.1797 6.37806 10.6909 2.17993 6.55282 2.17993C3.44407 2.17993 -0.0996785 4.64368 1.20907 10.2443C2.51782 15.8449 12.1891 22.8218 12.1891 22.8218C12.1891 22.8218 21.8247 15.8449 23.1353 10.2443C24.4459 4.64368 21.1553 2.17993 17.7972 2.17993Z"
        fill="#EF5592"
      />
      <path
        d="M5.92008 2.59806C9.14508 2.59806 10.7463 6.06493 11.2638 7.46931C11.337 7.66993 11.6163 7.67556 11.7007 7.48056L12.1732 6.37806C11.5076 4.24993 9.67945 2.17993 6.55383 2.17993C5.26008 2.17993 3.8932 2.60743 2.83008 3.50931C3.76945 2.89243 4.87008 2.59806 5.92008 2.59806ZM17.7982 2.17993C16.782 2.17993 15.927 2.43681 15.2126 2.82306C15.7076 2.63368 16.3076 2.59806 16.9132 2.59806C19.9526 2.59806 22.6732 4.90243 21.4451 10.2293C20.3913 14.8024 14.2338 20.3506 12.3532 22.4674C12.312 22.5143 12.2782 22.6381 12.252 22.7768C13.0601 22.1824 21.8895 15.5862 23.1382 10.2424C24.447 4.64368 21.1563 2.17993 17.7982 2.17993Z"
        fill="#DA2E75"
      />
      <path
        d="M14.6421 6.99672C14.3121 6.99672 14.0796 6.67797 14.1996 6.37047C14.4227 5.80422 14.7115 5.25672 15.0584 4.76922C15.5721 4.04922 16.549 3.62922 17.1677 4.06609C17.8015 4.51234 17.719 5.40484 17.2746 5.83609C16.3184 6.76047 15.1165 6.99672 14.6421 6.99672ZM2.85398 9.54484C2.8521 9.49797 2.53148 8.75172 2.4621 7.85734C2.38335 6.85047 2.78273 5.79297 3.27585 5.09359C3.74273 4.42984 4.61835 3.72859 5.42835 4.10734C5.69273 4.23109 5.88773 4.51234 5.86523 4.80297C5.84273 5.07672 5.64023 5.32234 5.45085 5.50609C5.22023 5.73297 4.95398 5.91484 4.70835 6.12109C3.33398 7.27234 2.82398 8.87172 2.85398 9.54484Z"
        fill="#F386AB"
      />
      <path
        d="M1.20918 10.2427C1.38168 10.9777 1.69855 11.737 2.11855 12.5002H22.2317C22.6498 11.737 22.9667 10.9777 23.1373 10.2445C23.2329 9.83767 23.3004 9.45142 23.3492 9.07642H1.00293C1.04793 9.44954 1.11543 9.83767 1.20918 10.2427Z"
        fill="#FFC107"
      />
      <path
        d="M12.2626 13.3248C10.7664 12.4248 9.16699 10.8217 8.22387 9.64233L4.53949 10.6567C4.73262 12.1548 4.33887 15.6442 4.33887 15.6442L4.92387 16.3155L5.81824 15.6967L7.42324 16.7655C7.63324 16.9436 7.71387 16.8648 7.74949 16.713C7.91824 16.0061 7.90887 14.8417 7.75324 13.6623C8.62137 14.8623 9.67887 15.8898 10.397 16.3136C10.5301 16.3923 10.6726 16.3436 10.7082 16.1505L10.757 14.2792L12.2139 13.6492C12.3676 13.6098 12.3976 13.4055 12.2626 13.3248Z"
        fill="#DA2E75"
      />
      <path
        d="M5.36816 9.82801L8.43941 9.42676C9.38254 10.6061 11.01 11.9843 12.5082 12.8843C12.6432 12.9649 12.6132 13.1674 12.4613 13.2086L11.1732 13.848L11.1582 15.6593C11.19 15.8111 11.025 15.9274 10.8919 15.8486C9.97691 15.3086 8.58941 14.0524 7.38004 12.8468C6.17066 11.6411 5.36816 9.82801 5.36816 9.82801Z"
        fill="#FFE02E"
      />
      <path
        d="M6.61718 8.72754L4.1928 10.6569C4.38593 12.155 4.24343 14.2832 3.85155 15.9857C3.81593 16.1394 3.9828 16.2594 4.1178 16.1769L5.47343 15.6969L6.78593 16.9438C6.87405 17.0732 7.07093 17.0357 7.10843 16.8838C7.35593 15.8507 7.4178 13.9813 7.39155 12.2732C7.3653 10.565 6.61718 8.72754 6.61718 8.72754Z"
        fill="#FFE02E"
      />
      <path
        d="M9.45926 10.5295C9.07301 10.1564 8.72426 9.78137 8.44113 9.42699L6.61676 8.72949L4.19238 10.657C4.26363 11.2157 4.28988 11.8607 4.27301 12.5395C4.77926 12.6914 6.05426 13.162 7.37238 14.3357C7.38926 13.8557 7.39676 13.357 7.39488 12.8601C7.43613 12.9014 7.47926 12.9426 7.52051 12.9857C7.31988 12.7851 6.89801 12.3464 6.67676 12.007C6.67676 12.0089 8.59863 11.5082 9.45926 10.5295Z"
        fill="#FFC107"
      />
      <path
        d="M3.11055 14.2082C2.4468 14.3919 1.61805 13.1882 1.0068 11.7576C0.395545 10.3269 0.474296 9.1082 0.892421 8.99195C1.52805 8.6807 2.27992 8.6282 3.26055 8.68257C3.89617 8.78007 4.54492 8.96382 5.12242 9.16257C5.77305 9.3857 5.84805 11.1988 5.84805 11.1988C4.77555 13.0926 4.04055 13.9513 3.11055 14.2082Z"
        fill="#FFE02E"
      />
      <path
        d="M5.67151 11.0132C5.67151 11.0132 3.76463 12.1926 3.16463 12.7438C2.31713 13.5219 2.27588 14.0788 2.79713 14.2026C4.19588 14.5363 5.87213 11.2626 5.87213 11.2626L5.67151 11.0132Z"
        fill="#FFA000"
      />
      <path
        d="M10.6473 10.7507C11.2417 10.4019 10.9698 8.96382 10.3848 7.52382C9.79983 6.08382 8.87921 5.28507 8.50421 5.50444C7.83858 5.74257 7.27608 6.24694 6.62546 6.98944C6.25046 7.51444 5.92608 8.10882 5.66171 8.66194C5.36358 9.28632 6.59733 10.6101 6.59733 10.6101C8.68983 11.1651 9.81483 11.2382 10.6473 10.7507Z"
        fill="#FFE02E"
      />
      <path
        d="M6.58691 10.3531C6.58691 10.3531 8.76004 9.80934 9.57004 9.76246C10.7157 9.69871 11.1394 10.0587 10.8619 10.52C10.1194 11.7593 6.62441 10.67 6.62441 10.67L6.58691 10.3531Z"
        fill="#FFA000"
      />
      <path
        d="M22.2286 12.5002C22.6467 11.737 22.9636 10.9777 23.1342 10.2445C23.2298 9.83767 23.2973 9.45142 23.3461 9.07642H11.6723C11.7848 10.6402 11.0029 11.0227 11.0029 11.0227L12.5929 12.5002H22.2286Z"
        fill="#FFE02E"
      />
      <path
        d="M10.895 10.5237C11.2682 9.98559 10.7844 8.43684 10.4113 7.52559C9.82254 6.08371 8.89442 5.28684 8.51754 5.50621C8.51567 5.50621 8.51379 5.50809 8.51192 5.50809C8.24379 5.60371 8.15192 5.95246 8.34504 6.16246C9.67629 7.61559 9.20567 7.96246 9.71942 8.97871C10.0175 9.56934 10.34 9.63496 10.6607 9.88434C11.0338 10.175 10.895 10.5237 10.895 10.5237Z"
        fill="#FFF685"
      />
      <path
        d="M21.4423 10.2295C21.2716 10.9664 20.9679 11.7295 20.5723 12.5002H22.2298C22.6479 11.737 22.9648 10.9777 23.1354 10.2445C23.231 9.83767 23.2985 9.45142 23.3473 9.07642H21.6523C21.6035 9.44392 21.536 9.82642 21.4423 10.2295Z"
        fill="#FFA000"
      />
      <path
        d="M5.71451 11.1836C5.07701 10.8986 4.70389 9.90114 4.65139 9.37051C4.59889 8.83801 5.43889 8.47051 5.62451 8.58489C5.81201 8.69926 6.77764 10.3793 6.77764 10.6343C6.77764 10.8893 6.12889 11.3693 5.71451 11.1836ZM2.89826 12.048C2.85326 11.5774 2.55139 11.1649 2.48576 10.9886C2.30014 10.488 2.17826 9.96301 2.13326 9.43051C2.10514 9.10989 2.16139 8.77989 1.75451 8.77801C1.36826 8.77801 1.13576 8.88676 0.997011 9.08739C0.730761 9.47176 0.865761 10.5855 1.34389 11.6861C1.74889 12.6199 2.45014 13.5724 2.45014 13.5724C2.45014 13.5724 2.96576 12.7455 2.89826 12.048Z"
        fill="#FFF685"
      />
    </SvgIcon>
  )
}
