import { createContext, useContext } from 'react'
import { DateTime } from 'luxon'

export type ReviewsContextType = {
  reviewsData: ReviewMetric[]
  filteredReviewData: ReviewMetric[]
  is3pReviewsVisible: boolean
  initialStartDate: DateTime
  initialEndDate: DateTime
  minDate: DateTime
  maxDate: DateTime
  topLevelViewType: TopLevelViewType
  setTopLevelViewType: (viewType: TopLevelViewType) => void
  viewType: ViewType
  setViewType: (viewType: ViewType) => void
  startDateFilter?: DateTime
  setStartDateFilter: (date?: DateTime) => void
  endDateFilter?: DateTime
  setEndDateFilter: (date?: DateTime) => void
}

export enum TopLevelViewType {
  REVIEWS = 'Reviews',
  STAR_RATING = 'Star rating'
}

export enum ViewType {
  COUNT = 'Count',
  RATING = 'Rating'
}

export type ReviewMetric = {
  rating: number
  ratingsInPeriod: number
  averageRatingsInPeriod: number
  cumulativeRatings: number
  cumulativeAverageRatings: number
  momentumRatingCount: number
  date: string
}

const ReviewsContext = createContext<ReviewsContextType>({
  reviewsData: [],
  filteredReviewData: [],
  is3pReviewsVisible: true,
  initialStartDate: DateTime.now(),
  initialEndDate: DateTime.now(),
  minDate: DateTime.now(),
  maxDate: DateTime.now(),
  topLevelViewType: TopLevelViewType.REVIEWS,
  setTopLevelViewType: () => {},
  viewType: ViewType.COUNT,
  setViewType: () => {},
  startDateFilter: undefined,
  setStartDateFilter: () => {},
  endDateFilter: undefined,
  setEndDateFilter: () => {}
})
ReviewsContext.displayName = 'ReviewsContext'

export default ReviewsContext

export const useReviewsContext = () => useContext(ReviewsContext)
