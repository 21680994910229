import { Box, debounce } from "@mui/material"
import { Typography } from "@mui/material"
import { Stack } from "@mui/material"
import { NumberInput } from "@productwindtom/ui-base"
import { useBenchmarkContext } from "../../context/BenchmarkContext"
import { BENCHMARK_TYPE_TO_GOAL_STRING } from "../../constants"
import { BenchmarkGraphProps, useBenchmarkGraphContext } from "./BenchmarkGraph"
export const GoalSelector = () => {
  const { graphMetrics, handleCampaignBenchmarkMetrics } = useBenchmarkContext()
  const { setMetrics } = useBenchmarkGraphContext()

  if (!graphMetrics) return null
  const { metricType } = graphMetrics 

  const handleMetricGoalChange = debounce(async (value: number) => {
    setMetrics((metrics: BenchmarkGraphProps) => ({...metrics, goal: value}))
    await handleCampaignBenchmarkMetrics()
  }, 800)

  return (
    <Stack direction={'column'} alignItems={'flex-start'} gap={1}>
      <Typography variant={'label1'}>Choose time to goal</Typography>
      <Stack direction="row" alignItems="center" sx={{
        border: t => `1px solid ${t.palette.grey.A200}`,
        backgroundColor: 'white',
        borderRadius: '4px',
        flex: 1,
        px: '16px',
        width: '190px'
      }}>
        <Box sx={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
          <NumberInput
            name='metricGoal'
            returnAsNumber
            min={0}
            onChange={async (e) => {
              if (Number(e.target.value) !== graphMetrics?.metricGoal) {
                await handleMetricGoalChange(Number(e.target.value))
              }
            }}
            value={graphMetrics?.metricGoal}
            sx={{ 
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
              },
              '& .MuiInputBase-input': {
                textAlign: 'center'
              }
            }}
          />
        </Box>
        <Box sx={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
          <Typography variant="body1" color={theme => theme.palette.grey.A700} sx={{ fontWeight: 'bold' }}>
            {BENCHMARK_TYPE_TO_GOAL_STRING[metricType]}
          </Typography>
        </Box>
      </Stack>
    </Stack>
  )
} 
