import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import {
  getAutoBuysDistribution,
  getCampaignReviewsGoalHit,
  getTimelineSteps,
  LEADING_DAYS_TO_APPROVE_INITIAL_CREATORS,
  toWeeklyPartition
} from '@productwindtom/shared-momentum'
import {
  Channel,
  CreatorApprovalStatus,
  CreatorType,
  DraftStatus,
  PricingCreatorType,
  ProposalGoal
} from '@productwindtom/shared-momentum-zeus-types'
import { notEmpty } from '@productwindtom/shared-node'
import { keyBy, max, min, minBy, orderBy, sum } from 'lodash'
import { DateTime } from 'luxon'

export const useCampaignTimelineSteps = () => {
  const { brand } = useBrandContext()
  const {
    campaignDetails,
    liftCampaignDetails,
    campaignCreatorsForApproval,
    campaignContentForApproval,
    reviewMetricsV2,
    productEtailerMetrics
  } = useCampaignContext()

  const now = DateTime.now()
  const weeklyUnitsSoldArray = (productEtailerMetrics || [])
    .filter(u => DateTime.fromISO(u.date).plus({ days: 7 }) > now.startOf('day'))
    .map(m => m.unitsSold)

  const proposalEstimatedSalesPerWeek = (campaignDetails.proposal?.estimatedUnitsSoldPerMonth || 0) / 4

  const weeklyUnitsSold =
    Math.ceil(weeklyUnitsSoldArray.length > 5 ? sum(weeklyUnitsSoldArray) : proposalEstimatedSalesPerWeek) || undefined

  const liftBrandAdvocatesPurchaseDates =
    campaignDetails.numAdditionalBrandAdvocateCreators && liftCampaignDetails
      ? orderBy(
          liftCampaignDetails.creators.map(c => c.purchaseDetails?.purchaseDate).filter(notEmpty),
          c => c,
          'asc'
        ).slice(0, campaignDetails.numAdditionalBrandAdvocateCreators)
      : []

  const brandAdvocatesTotal =
    (campaignDetails.numBrandAdvocateCreators || 0) + (campaignDetails.numAdditionalBrandAdvocateCreators || 0)
  const autoBuysDistribution = getAutoBuysDistribution({
    creatorPricing: [
      { type: PricingCreatorType.ADVOCATE, numCreators: brandAdvocatesTotal },
      { type: PricingCreatorType.UGC, numCreators: campaignDetails.numUgcCreators || 0 },
      { type: PricingCreatorType.PREMIUM_UGC, numCreators: campaignDetails.numPremiumUgcCreators || 0 },
      { type: PricingCreatorType.SOCIAL, numCreators: campaignDetails.numSocialCreators || 0 }
    ]
  })
  const keyedAutoBuys = keyBy(autoBuysDistribution, 'type')

  const schedule = campaignDetails.releaseSchedule ?? {
    brandAdvocatesWeeklySchedule: toWeeklyPartition(keyedAutoBuys[PricingCreatorType.ADVOCATE]?.dailySchedule),
    ugcWeeklySchedule: toWeeklyPartition(keyedAutoBuys[PricingCreatorType.UGC]?.dailySchedule),
    premiumUgcWeeklySchedule: toWeeklyPartition(keyedAutoBuys[PricingCreatorType.PREMIUM_UGC]?.dailySchedule),
    socialWeeklySchedule: toWeeklyPartition(keyedAutoBuys[PricingCreatorType.SOCIAL]?.dailySchedule)
  }

  const { expectedReviewsCount, expectedSocialPostCount, expectedUgcPostCount, expectedPremiumUgcPostCount } =
    campaignDetails

  const firstCreatorApprovalAction = min(
    (campaignCreatorsForApproval || []).map(c =>
      CreatorApprovalStatus.APPROVED === c.approvalState.status ? c.approvalState.updatedAt : undefined
    )
  )

  const contentCreators = campaignDetails.creators.filter(
    c => c.purchaseDetails && c.creatorType !== CreatorType.ADVOCATE
  )

  const socialContent = orderBy(
    contentCreators
      .flatMap(c => c.content)
      .filter(c => c.channel !== Channel.UGC && !c.isHidden)
      .flatMap(c => c.content.map(cc => ({ ...cc, submittedAt: c.submittedAt }))),
    c => c.submittedAt,
    'asc'
  )
  const ugcContent = orderBy(
    contentCreators
      .filter(c => c.creatorType !== CreatorType.PREMIUM_UGC)
      .flatMap(c => c.content)
      .filter(c => c.channel === Channel.UGC && !c.isHidden)
      .flatMap(c => c.content.map(cc => ({ ...cc, submittedAt: c.submittedAt }))),
    c => c.submittedAt,
    'asc'
  )
  const premiumUgcContent = orderBy(
    contentCreators
      .filter(c => c.creatorType === CreatorType.PREMIUM_UGC)
      .flatMap(c => c.content)
      .filter(c => !c.isHidden)
      .flatMap(c => c.content.map(cc => ({ ...cc, submittedAt: c.submittedAt }))),
    c => c.submittedAt,
    'asc'
  )

  const contentCompletedAt =
    (expectedSocialPostCount == null || socialContent.length >= expectedSocialPostCount) &&
    (expectedUgcPostCount == null || ugcContent.length >= expectedUgcPostCount) &&
    (expectedPremiumUgcPostCount == null || premiumUgcContent.length >= expectedPremiumUgcPostCount)
      ? max([
          ...(expectedSocialPostCount != null ? [socialContent[expectedSocialPostCount - 1]?.submittedAt] : []),
          ...(expectedUgcPostCount != null ? [ugcContent[expectedUgcPostCount - 1]?.submittedAt] : []),
          ...(expectedPremiumUgcPostCount != null
            ? [premiumUgcContent[expectedPremiumUgcPostCount - 1]?.submittedAt]
            : [])
        ])
      : undefined

  const approvedContent = campaignContentForApproval
    .flatMap(c => c.draftContent)
    .filter(notEmpty)
    .filter(c => c.draftStatus === DraftStatus.APPROVED)
  const firstApprovalContent = minBy(approvedContent, c => c.reviewedAt)?.reviewedAt

  const purchaseDates = campaignDetails.creators.map(c => c.purchaseDetails?.purchaseDate).filter(notEmpty)
  const firstPurchase = min([...purchaseDates, ...liftBrandAdvocatesPurchaseDates])
  const lastPurchase = max([...purchaseDates, ...liftBrandAdvocatesPurchaseDates])
  const purchasingComplete = campaignDetails.numCreatorsJoined >= campaignDetails.numCreators

  const reviewsDates = (reviewMetricsV2 || []).filter(r => r.momentumRatingCount > 0).map(r => r.fromDate)
  const firstReview = min(reviewsDates)

  const reviewsGoalHit = getCampaignReviewsGoalHit(expectedReviewsCount, reviewMetricsV2)

  const startDate = DateTime.fromISO(campaignDetails.startDate).startOf('day')
  // Handle when start is delayed
  const timelineStartDate =
    !firstCreatorApprovalAction && startDate.minus({ days: LEADING_DAYS_TO_APPROVE_INITIAL_CREATORS }) < now
      ? now.plus({ days: 1 + LEADING_DAYS_TO_APPROVE_INITIAL_CREATORS }).startOf('day')
      : startDate

  return getTimelineSteps(
    {
      ...schedule,
      hasBrandAdvocates: !!brandAdvocatesTotal,
      hasUgc: !!campaignDetails.numUgcCreators,
      hasPremiumUgc: !!campaignDetails.numPremiumUgcCreators,
      hasSocial: !!campaignDetails.numSocialCreators,
      reviewsGoal: expectedReviewsCount,
      unitsSoldPerWeek: weeklyUnitsSold,
      isVendor: !!brand.brandApis.find(api => api.enabled && api.api.includes('vendor')),
      startDate: firstPurchase ? DateTime.fromISO(firstPurchase) : timelineStartDate,
      eventDate:
        campaignDetails.proposal?.goal === ProposalGoal.EVENT && campaignDetails.proposal?.eventDate
          ? DateTime.fromISO(campaignDetails.proposal.eventDate)
          : undefined,
      isAdaptive: true
    },
    {
      lastPurchaseAt: purchasingComplete && lastPurchase ? DateTime.fromISO(lastPurchase) : undefined,
      firstReviewAt: firstReview ? DateTime.fromISO(firstReview) : undefined,
      allContentCompletedAt: contentCompletedAt ? DateTime.fromISO(contentCompletedAt) : undefined,
      initialCreatorApprovedAt: firstCreatorApprovalAction ? DateTime.fromISO(firstCreatorApprovalAction) : undefined,
      initialContentApprovedAt: firstApprovalContent ? DateTime.fromISO(firstApprovalContent) : undefined,
      reviewsGoalHitAt: reviewsGoalHit ? DateTime.fromISO(reviewsGoalHit) : undefined,
      endDate: campaignDetails.endDate ? DateTime.fromISO(campaignDetails.endDate) : undefined
    }
  )
}
