import { BenchmarkType, ModelTypes } from '@productwindtom/shared-momentum-zeus-types'
import { SAMPLE_CAMPAIGN_LAUNDRY } from '../../getCampaign/sample-data/laundry'

export const SAMPLE_CAMPAIGN_BENCHMARK_LAUNDRY: ModelTypes['CampaignBenchmark'] = {
  skuId: SAMPLE_CAMPAIGN_LAUNDRY.skuId,
  campaignId: SAMPLE_CAMPAIGN_LAUNDRY.id,
  benchmarkedProductIds: [
    SAMPLE_CAMPAIGN_LAUNDRY.skuId,
    '602f6e6a-241f-404a-a502-130a7f93c6a9',
    'e963f5d4-fe18-46c1-b117-cc75f70e743c',
    '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  ],
  benchmarkGoal: 1000,
  benchmarkType: BenchmarkType.TRAFFIC,
  createdAt: '2021-07-01T00:00:00.000Z',
  updatedAt: '2021-07-01T00:00:00.000Z'
}
