import { CampaignProductReviewRatingMetric } from '@momentum/routes/campaign/context/queries'

export const SAMPLE_CAMPAIGN_REVIEW_METRICS_HEADPHONES: Array<CampaignProductReviewRatingMetric> = [
  {
    fromDate: '2023-11-24',
    toDate: '2023-11-25',
    numRatings: 2,
    numReviews: 2,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 3.5,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2023-11-25',
    toDate: '2023-11-26',
    numRatings: 3,
    numReviews: 3,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 4.8,
    organicRatingCount: 1,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 3.9,
    momentumRating: 4.8,
    organicRating: 3.9,
    vineRating: 0
  },
  {
    fromDate: '2023-11-26',
    toDate: '2023-11-27',
    numRatings: 5,
    numReviews: 5,
    ratingsInPeriod: 2,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 1,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 3.3,
    momentumRating: 4.8,
    organicRating: 1.7999999999999998,
    vineRating: 0
  },
  {
    fromDate: '2023-11-27',
    toDate: '2023-11-28',
    numRatings: 5,
    numReviews: 5,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 3.3,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2023-11-28',
    toDate: '2023-11-29',
    numRatings: 6,
    numReviews: 6,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 3.5,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2023-11-29',
    toDate: '2023-11-30',
    numRatings: 6,
    numReviews: 6,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 3.5,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2023-11-30',
    toDate: '2023-12-01',
    numRatings: 9,
    numReviews: 9,
    ratingsInPeriod: 3,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 2,
    reviewsInPeriod: 3,
    reviews: [],
    rating: 3.4,
    momentumRating: 4.8,
    organicRating: 2.6999999999999997,
    vineRating: 0
  },
  {
    fromDate: '2023-12-01',
    toDate: '2023-12-02',
    numRatings: 11,
    numReviews: 11,
    ratingsInPeriod: 2,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 4.8,
    organicRatingCount: 2,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 3.6,
    momentumRating: 4.8,
    organicRating: 3.6,
    vineRating: 0
  },
  {
    fromDate: '2023-12-02',
    toDate: '2023-12-03',
    numRatings: 14,
    numReviews: 14,
    ratingsInPeriod: 3,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 4.8,
    organicRatingCount: 3,
    reviewsInPeriod: 3,
    reviews: [],
    rating: 3.8,
    momentumRating: 4.8,
    organicRating: 3.7999999999999994,
    vineRating: 0
  },
  {
    fromDate: '2023-12-03',
    toDate: '2023-12-04',
    numRatings: 14,
    numReviews: 14,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 3.8,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2023-12-04',
    toDate: '2023-12-05',
    numRatings: 18,
    numReviews: 18,
    ratingsInPeriod: 4,
    momentumRatingCount: 2,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.8,
    organicRatingCount: 2,
    reviewsInPeriod: 4,
    reviews: [],
    rating: 3.6,
    momentumRating: 4.8,
    organicRating: 4.800000000000001,
    vineRating: 0
  },
  {
    fromDate: '2023-12-05',
    toDate: '2023-12-06',
    numRatings: 24,
    numReviews: 24,
    ratingsInPeriod: 6,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 4.8,
    organicRatingCount: 6,
    reviewsInPeriod: 6,
    reviews: [],
    rating: 3.9,
    momentumRating: 4.8,
    organicRating: 3.9,
    vineRating: 0
  },
  {
    fromDate: '2023-12-06',
    toDate: '2023-12-07',
    numRatings: 25,
    numReviews: 25,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 3.9,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2023-12-07',
    toDate: '2023-12-08',
    numRatings: 28,
    numReviews: 28,
    ratingsInPeriod: 3,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 2,
    reviewsInPeriod: 3,
    reviews: [],
    rating: 3.9,
    momentumRating: 4.8,
    organicRating: 3.4499999999999997,
    vineRating: 0
  },
  {
    fromDate: '2023-12-08',
    toDate: '2023-12-09',
    numRatings: 29,
    numReviews: 29,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 4.8,
    organicRatingCount: 1,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 3.6,
    momentumRating: 4.8,
    organicRating: 3.6,
    vineRating: 0
  },
  {
    fromDate: '2023-12-09',
    toDate: '2023-12-10',
    numRatings: 31,
    numReviews: 31,
    ratingsInPeriod: 2,
    momentumRatingCount: 2,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2023-12-10',
    toDate: '2023-12-11',
    numRatings: 32,
    numReviews: 32,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 4.8,
    organicRatingCount: 1,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 3.9,
    momentumRating: 4.8,
    organicRating: 3.9,
    vineRating: 0
  },
  {
    fromDate: '2023-12-11',
    toDate: '2023-12-16',
    numRatings: 41,
    numReviews: 41,
    ratingsInPeriod: 9,
    momentumRatingCount: 2,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.8,
    organicRatingCount: 7,
    reviewsInPeriod: 9,
    reviews: [],
    rating: 4,
    momentumRating: 4.8,
    organicRating: 4.457142857142857,
    vineRating: 0
  },
  {
    fromDate: '2023-12-16',
    toDate: '2023-12-17',
    numRatings: 41,
    numReviews: 41,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2023-12-17',
    toDate: '2023-12-18',
    numRatings: 41,
    numReviews: 41,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2023-12-18',
    toDate: '2023-12-21',
    numRatings: 48,
    numReviews: 48,
    ratingsInPeriod: 7,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 6,
    reviewsInPeriod: 7,
    reviews: [],
    rating: 4,
    momentumRating: 4.8,
    organicRating: 3.8666666666666667,
    vineRating: 0
  },
  {
    fromDate: '2023-12-21',
    toDate: '2023-12-22',
    numRatings: 50,
    numReviews: 50,
    ratingsInPeriod: 2,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 4.8,
    organicRatingCount: 2,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4.1,
    momentumRating: 4.8,
    organicRating: 4.1,
    vineRating: 0
  },
  {
    fromDate: '2023-12-22',
    toDate: '2023-12-23',
    numRatings: 51,
    numReviews: 51,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.1,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2023-12-23',
    toDate: '2023-12-24',
    numRatings: 51,
    numReviews: 51,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.1,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2023-12-24',
    toDate: '2023-12-25',
    numRatings: 53,
    numReviews: 53,
    ratingsInPeriod: 2,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 1,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4.1,
    momentumRating: 4.8,
    organicRating: 3.3999999999999995,
    vineRating: 0
  },
  {
    fromDate: '2023-12-25',
    toDate: '2023-12-26',
    numRatings: 57,
    numReviews: 57,
    ratingsInPeriod: 4,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 4.8,
    organicRatingCount: 4,
    reviewsInPeriod: 4,
    reviews: [],
    rating: 4.2,
    momentumRating: 4.8,
    organicRating: 4.2,
    vineRating: 0
  },
  {
    fromDate: '2023-12-26',
    toDate: '2023-12-27',
    numRatings: 57,
    numReviews: 57,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.2,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2023-12-27',
    toDate: '2023-12-28',
    numRatings: 59,
    numReviews: 59,
    ratingsInPeriod: 2,
    momentumRatingCount: 2,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4.2,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2023-12-28',
    toDate: '2023-12-29',
    numRatings: 61,
    numReviews: 61,
    ratingsInPeriod: 2,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 1,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4.2,
    momentumRating: 4.8,
    organicRating: 3.6000000000000005,
    vineRating: 0
  },
  {
    fromDate: '2023-12-29',
    toDate: '2023-12-30',
    numRatings: 62,
    numReviews: 62,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.2,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2023-12-30',
    toDate: '2023-12-31',
    numRatings: 63,
    numReviews: 63,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.2,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2023-12-31',
    toDate: '2024-01-01',
    numRatings: 65,
    numReviews: 65,
    ratingsInPeriod: 2,
    momentumRatingCount: 2,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4.2,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-01',
    toDate: '2024-01-02',
    numRatings: 67,
    numReviews: 67,
    ratingsInPeriod: 2,
    momentumRatingCount: 2,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4.2,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-02',
    toDate: '2024-01-03',
    numRatings: 68,
    numReviews: 68,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.2,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-03',
    toDate: '2024-01-04',
    numRatings: 69,
    numReviews: 69,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.3,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-04',
    toDate: '2024-01-05',
    numRatings: 70,
    numReviews: 70,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.3,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-05',
    toDate: '2024-01-06',
    numRatings: 72,
    numReviews: 72,
    ratingsInPeriod: 2,
    momentumRatingCount: 2,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4.3,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-06',
    toDate: '2024-01-07',
    numRatings: 73,
    numReviews: 73,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.3,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-07',
    toDate: '2024-01-08',
    numRatings: 77,
    numReviews: 77,
    ratingsInPeriod: 4,
    momentumRatingCount: 4,
    vineReviewCount: 0,
    momentumReviewCount: 4,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 4,
    reviews: [],
    rating: 4.3,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-08',
    toDate: '2024-01-09',
    numRatings: 79,
    numReviews: 79,
    ratingsInPeriod: 2,
    momentumRatingCount: 2,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4.3,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-09',
    toDate: '2024-01-10',
    numRatings: 81,
    numReviews: 81,
    ratingsInPeriod: 2,
    momentumRatingCount: 2,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4.3,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-10',
    toDate: '2024-01-11',
    numRatings: 82,
    numReviews: 82,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.3,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-11',
    toDate: '2024-01-12',
    numRatings: 83,
    numReviews: 83,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.3,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-12',
    toDate: '2024-01-13',
    numRatings: 86,
    numReviews: 86,
    ratingsInPeriod: 3,
    momentumRatingCount: 3,
    vineReviewCount: 0,
    momentumReviewCount: 3,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 3,
    reviews: [],
    rating: 4.4,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-13',
    toDate: '2024-01-14',
    numRatings: 91,
    numReviews: 91,
    ratingsInPeriod: 5,
    momentumRatingCount: 5,
    vineReviewCount: 0,
    momentumReviewCount: 5,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 5,
    reviews: [],
    rating: 4.4,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-14',
    toDate: '2024-01-15',
    numRatings: 96,
    numReviews: 96,
    ratingsInPeriod: 5,
    momentumRatingCount: 5,
    vineReviewCount: 0,
    momentumReviewCount: 5,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 5,
    reviews: [],
    rating: 4.4,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-15',
    toDate: '2024-01-16',
    numRatings: 101,
    numReviews: 101,
    ratingsInPeriod: 5,
    momentumRatingCount: 3,
    vineReviewCount: 0,
    momentumReviewCount: 3,
    momentumReviewRating: 4.8,
    organicRatingCount: 2,
    reviewsInPeriod: 5,
    reviews: [],
    rating: 4.4,
    momentumRating: 4.8,
    organicRating: 5,
    vineRating: 0
  }
]
