import { SAMPLE_CAMPAIGN_LAUNDRY } from '@momentum/api/interceptors/getCampaign/sample-data/laundry'
import product1 from './602f6e6a-241f-404a-a502-130a7f93c6a9'
import product3 from './8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
import product2 from './e963f5d4-fe18-46c1-b117-cc75f70e743c'
import promotedProduct from './sample-sku-laundry'

export default {
  [SAMPLE_CAMPAIGN_LAUNDRY.skuId]: promotedProduct,
  '602f6e6a-241f-404a-a502-130a7f93c6a9': product1,
  'e963f5d4-fe18-46c1-b117-cc75f70e743c': product2,
  '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb': product3
}
