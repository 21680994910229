import ProductSelectorV2 from '@momentum/components/form-inputs/ProductSelector'
import { useBenchmarkContext } from '../context/BenchmarkContext'
import { Box, Grid, Stack, Typography } from '@mui/material'
import BenchmarkProductCard from './BenchmarkProductCard'
import { useMemo } from 'react'

const BenchmarkProducts = () => {
  const { nonBenchmarkedProducts, benchmarkedProducts, graphMetrics, setGraphMetrics } = useBenchmarkContext()

  const products = useMemo(() => {
    return benchmarkedProducts?.filter(product => graphMetrics?.benchmarkedProductIds.includes(product.id))
  }, [graphMetrics?.benchmarkedProductIds, benchmarkedProducts])

  return (
    <Stack spacing={1}>
      <Typography variant="label1">Choose products to benchmark</Typography>
      <Typography variant="label3" color="grey.700">Add up to 3 products</Typography>
      <Stack spacing={3}>
      <ProductSelectorV2
        products={nonBenchmarkedProducts}
        disabled={!nonBenchmarkedProducts.length || benchmarkedProducts?.length === 4}
        onSelectProduct={product => {
          setGraphMetrics({
            ...graphMetrics!,
            benchmarkedProductIds: [...graphMetrics!.benchmarkedProductIds, product!.id]
          })
        }}
      />
      <Box>
        <Grid container spacing={2}>
          {products?.map((product, index) => (
            <Grid item xs={6} md={3} key={product.id} data-cy={`benchmark-product-card-${product.id}`}>
              <BenchmarkProductCard product={product} index={index} />
            </Grid>
          ))}
          </Grid>
        </Box>
      </Stack>
    </Stack>
  )
}

export default BenchmarkProducts
