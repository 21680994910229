import { Stack } from '@mui/material'
import { ToggleSwitch } from '@momentum/components/toggle-switch'
import { SalesReportView, useSalesContext } from './salesContext'

export default () => {
  const { salesReportView, setSalesReportView } = useSalesContext()

  return (
    <Stack direction={'row'} justifyContent={'flex-end'}>
      <ToggleSwitch
        value={salesReportView}
        values={[
          { label: 'Units sold', value: SalesReportView.UNITS_SOLD },
          { label: 'Revenue', value: SalesReportView.REVENUE }
        ]}
        onChange={v => setSalesReportView(v)}
      />
    </Stack>
  )
}
