export const SAMPLE_CAMPAIGN_REVIEW_METRICS_TEETH_WHITENING = [
  {
    fromDate: '2024-01-17',
    toDate: '2024-01-18',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-18',
    toDate: '2024-01-19',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-19',
    toDate: '2024-01-20',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-20',
    toDate: '2024-01-21',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-21',
    toDate: '2024-01-22',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-22',
    toDate: '2024-01-23',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-23',
    toDate: '2024-01-24',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-24',
    toDate: '2024-01-25',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-25',
    toDate: '2024-01-26',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-26',
    toDate: '2024-01-27',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-27',
    toDate: '2024-01-28',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-28',
    toDate: '2024-01-29',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-29',
    toDate: '2024-01-30',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-30',
    toDate: '2024-01-31',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-31',
    toDate: '2024-02-01',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-02-01',
    toDate: '2024-02-02',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-02-02',
    toDate: '2024-02-03',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-02-03',
    toDate: '2024-02-04',
    numRatings: 1,
    numReviews: 1,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 5,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-02-04',
    toDate: '2024-02-05',
    numRatings: 1,
    numReviews: 1,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 5,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-02-05',
    toDate: '2024-02-06',
    numRatings: 1,
    numReviews: 1,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 5,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-02-06',
    toDate: '2024-02-07',
    numRatings: 1,
    numReviews: 1,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 5,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-02-07',
    toDate: '2024-02-08',
    numRatings: 1,
    numReviews: 1,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 5,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-02-08',
    toDate: '2024-02-09',
    numRatings: 1,
    numReviews: 1,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 5,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-02-09',
    toDate: '2024-02-10',
    numRatings: 2,
    numReviews: 2,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [
      {
        title: 'Very happy with this!',
        text: 'I have very sensitive teeth, and this product is perfect for that. No more issues with sensitivity to hot or cold. Even a bonus that it helps prevent cavities. Also, minty fresh breath!',
        name: 'Meagan Schultz',
        rating: 5,
        attributedUserId: 'fe02f560-b9d0-403d-b013-35dd8c790c67',
        reviewedAt: '2024-08-12T00:00:00.000Z'
      }
    ],
    rating: 5,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-02-10',
    toDate: '2024-02-11',
    numRatings: 2,
    numReviews: 2,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 5,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-02-11',
    toDate: '2024-02-12',
    numRatings: 2,
    numReviews: 2,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 5,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-02-12',
    toDate: '2024-02-13',
    numRatings: 4,
    numReviews: 4,
    ratingsInPeriod: 2,
    momentumRatingCount: 2,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 5,
    organicRatingCount: 0,
    reviewsInPeriod: 2,
    reviews: [
      {
        title: 'Great flavor',
        text: 'This toothpaste has a great tasting mint flavor. It is not gritty and texture. The amount of toothpaste, and the size of the tube is a great value for your money. The name Orajel means quality to me. It’s a brand I know well.',
        name: 'Christina',
        rating: 5,
        attributedUserId: '0e56b003-5968-4c7a-b9ea-9d2feb173ba7',
        reviewedAt: '2024-08-13T00:00:00.000Z'
      },
      {
        title: 'Feels great',
        text: 'Love the mint',
        name: 'Crystal',
        rating: 5,
        attributedUserId: '2e87dec3-dfe0-4ce7-a610-264852856dfc',
        reviewedAt: '2024-08-13T00:00:00.000Z'
      }
    ],
    rating: 5,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-02-13',
    toDate: '2024-02-14',
    numRatings: 5,
    numReviews: 5,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 1,
    reviewsInPeriod: 1,
    reviews: [
      {
        title: 'Really happy with this!!',
        text: 'Overall good toothpaste- especially for sensitive teeth',
        name: 'Shelby M',
        rating: 5,
        attributedUserId: 'c694be58-336f-483c-903d-def71dced3ab',
        reviewedAt: '2024-08-14T00:00:00.000Z'
      }
    ],
    rating: 5,
    momentumRating: 5,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-02-14',
    toDate: '2024-02-15',
    numRatings: 8,
    numReviews: 8,
    ratingsInPeriod: 3,
    momentumRatingCount: 3,
    vineReviewCount: 0,
    momentumReviewCount: 3,
    momentumReviewRating: 5,
    organicRatingCount: 0,
    reviewsInPeriod: 3,
    reviews: [
      {
        title: 'Helps with sensitivity',
        text: 'I highly recommend this product! I have sensitivity to hot and cold beverages and using this Orajel sensitive relief toothpaste has definitely helped with sensitivity too hot and cold liquids. Highly recommend and will definitely purchase.',
        name: 'Yesenia M',
        rating: 5,
        attributedUserId: '38b3d598-17eb-489a-a562-2ca815a82569',
        reviewedAt: '2024-08-14T00:00:00.000Z'
      },
      {
        title: 'Good product',
        text: 'I liked it. It works just like the product says.',
        name: 'Brittany @letsgetstamps',
        rating: 5,
        attributedUserId: 'd9bd8ff2-4903-4e45-af6f-881cad5e5bb7',
        reviewedAt: '2024-08-15T00:00:00.000Z'
      },
      {
        title: 'Great toothpaste',
        text: 'Works great for sensitive teeth',
        name: 'Melissa Savoie',
        rating: 5,
        attributedUserId: '9c3ba361-d4e0-430f-bfd9-eddc3b4b7427',
        reviewedAt: '2024-08-15T00:00:00.000Z'
      }
    ],
    rating: 5,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-02-15',
    toDate: '2024-02-16',
    numRatings: 9,
    numReviews: 9,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [
      {
        title: 'Love this product',
        text: 'Definitely helps',
        name: 'Dominique',
        rating: 5,
        attributedUserId: '95e261cb-38c5-45d0-9d69-f0bff1465e52',
        reviewedAt: '2024-08-18T00:00:00.000Z'
      }
    ],
    rating: 5,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-02-16',
    toDate: '2024-02-17',
    numRatings: 10,
    numReviews: 10,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 5,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-02-17',
    toDate: '2024-02-20',
    numRatings: 12,
    numReviews: 12,
    ratingsInPeriod: 2,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 3,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 5,
    momentumRating: 4.8,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-02-20',
    toDate: '2024-02-21',
    numRatings: 13,
    numReviews: 13,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 1,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.9,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 4
  },
  {
    fromDate: '2024-02-21',
    toDate: '2024-02-22',
    numRatings: 13,
    numReviews: 13,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 5,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 5,
    momentumRating: 0,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-02-22',
    toDate: '2024-02-23',
    numRatings: 22,
    numReviews: 22,
    ratingsInPeriod: 5,
    momentumRatingCount: 2,
    vineReviewCount: 2,
    momentumReviewCount: 2,
    momentumReviewRating: 4.9,
    organicRatingCount: 1,
    reviewsInPeriod: 5,
    reviews: [
      {
        title: 'Works so well for sensitive gums',
        text: 'It does the job and i love it',
        name: 'Daniel Jarvie',
        rating: 5,
        attributedUserId: '11ab4740-7f4e-4527-8a95-c28a4e297697',
        reviewedAt: '2024-08-24T00:00:00.000Z'
      }
    ],
    rating: 4.9,
    momentumRating: 4.9,
    organicRating: 5,
    vineRating: 5
  },
  {
    fromDate: '2024-02-23',
    toDate: '2024-02-24',
    numRatings: 29,
    numReviews: 29,
    ratingsInPeriod: 7,
    momentumRatingCount: 5,
    vineReviewCount: 2,
    momentumReviewCount: 5,
    momentumReviewRating: 4.92,
    organicRatingCount: 1,
    reviewsInPeriod: 7,
    reviews: [
      {
        title: 'Great if you have sensitive  teeth like me',
        text: 'i have very sensitive teeth and guns due a receding gum line. this has helped alot',
        name: 'Allirose',
        rating: 5,
        attributedUserId: '46a96dfa-f3a2-496e-bd6e-f0d84b5e84d2',
        reviewedAt: '2024-08-26T00:00:00.000Z'
      },
      {
        title: 'Great',
        text: 'Works wonderfully',
        name: 'Preeti Kumar',
        rating: 5,
        attributedUserId: 'e0be156a-1a34-4ad2-a685-2a5b4bf65f14',
        reviewedAt: '2024-08-26T00:00:00.000Z'
      },
      {
        title: 'Great',
        text: 'Great for you and kids , mild mint flavor. Clean feeling!',
        name: 'Diamond Dozier',
        rating: 5,
        attributedUserId: '2dda1fd0-f4f9-4e61-bfd7-f3d19cc10373',
        reviewedAt: '2024-08-26T00:00:00.000Z'
      }
    ],
    rating: 4.9,
    momentumRating: 4.92,
    organicRating: 5,
    vineRating: 5
  },
  {
    fromDate: '2024-02-24',
    toDate: '2024-02-25',
    numRatings: 31,
    numReviews: 31,
    ratingsInPeriod: 2,
    momentumRatingCount: 1,
    vineReviewCount: 1,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4.9,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 5
  },
  {
    fromDate: '2024-02-25',
    toDate: '2024-02-26',
    numRatings: 36,
    numReviews: 36,
    ratingsInPeriod: 5,
    momentumRatingCount: 5,
    vineReviewCount: 0,
    momentumReviewCount: 5,
    momentumReviewRating: 4.640000000000001,
    organicRatingCount: 0,
    reviewsInPeriod: 5,
    reviews: [
      {
        title: 'Best tootbpaste for sensitive teeth!',
        text: 'I loved this tootbpaste for after getting my cavity removed! My son also used it nightly!',
        name: 'Savannah Billingsley',
        rating: 4,
        attributedUserId: '3d41d6bc-28ff-4c89-8111-1b06551bada6',
        reviewedAt: '2024-08-28T00:00:00.000Z'
      }
    ],
    rating: 4.9,
    momentumRating: 4.640000000000001,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-02-26',
    toDate: '2024-02-27',
    numRatings: 37,
    numReviews: 37,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 5,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-02-27',
    toDate: '2024-02-28',
    numRatings: 40,
    numReviews: 40,
    ratingsInPeriod: 3,
    momentumRatingCount: 1,
    vineReviewCount: 1,
    momentumReviewCount: 1,
    momentumReviewRating: 4,
    organicRatingCount: 2,
    reviewsInPeriod: 3,
    reviews: [
      {
        title: 'needed this!',
        text: 'i was just realizing how my teeth get sensitive to eating or drinking cold things, i have used this a few times and it seems the sensitivity is not there as much. hopefully it continues to work but i wish it gave a little more freshness',
        name: 'nataly perez',
        rating: 4,
        attributedUserId: 'cac4e874-02d7-4326-9dda-3262a838203f',
        reviewedAt: '2024-08-29T00:00:00.000Z'
      }
    ],
    rating: 4.9,
    momentumRating: 4,
    organicRating: 5,
    vineRating: 5
  },
  {
    fromDate: '2024-02-28',
    toDate: '2024-02-29',
    numRatings: 44,
    numReviews: 44,
    ratingsInPeriod: 4,
    momentumRatingCount: 3,
    vineReviewCount: 1,
    momentumReviewCount: 3,
    momentumReviewRating: 4.933333333333334,
    organicRatingCount: 1,
    reviewsInPeriod: 4,
    reviews: [
      {
        title: 'It works',
        text: 'I experienced any discomfort since I started using the Orajel toothpaste',
        name: 'ESTHER A. JULES',
        rating: 5,
        attributedUserId: '2f8f19c5-6d6e-455c-96ec-73b59b8f63dd',
        reviewedAt: '2024-08-31T00:00:00.000Z'
      },
      {
        title: 'Sensitive teeth approved',
        text: 'Great flavor and my teeth approve',
        name: 'Taylor Wysong',
        rating: 5,
        attributedUserId: 'a9f6f5bc-cd23-4300-a906-c8a551384e56',
        reviewedAt: '2024-08-31T00:00:00.000Z'
      }
    ],
    rating: 4.9,
    momentumRating: 4.933333333333334,
    organicRating: 5,
    vineRating: 5
  },
  {
    fromDate: '2024-02-29',
    toDate: '2024-03-01',
    numRatings: 47,
    numReviews: 47,
    ratingsInPeriod: 3,
    momentumRatingCount: 3,
    vineReviewCount: 0,
    momentumReviewCount: 3,
    momentumReviewRating: 4.866666666666667,
    organicRatingCount: 0,
    reviewsInPeriod: 3,
    reviews: [
      {
        title: 'Great!',
        text: 'Honestly this toothpaste exceeded my expectations! Grate quality and amount as well',
        name: 'Skyelar Poulimas',
        rating: 5,
        attributedUserId: '514498ac-3178-4a7a-aecf-71e1d60bc6f5',
        reviewedAt: '2024-09-01T00:00:00.000Z'
      }
    ],
    rating: 4.9,
    momentumRating: 4.866666666666667,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-01',
    toDate: '2024-03-02',
    numRatings: 49,
    numReviews: 49,
    ratingsInPeriod: 2,
    momentumRatingCount: 2,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.8,
    organicRatingCount: 1,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4.9,
    momentumRating: 4.8,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-03-02',
    toDate: '2024-03-03',
    numRatings: 51,
    numReviews: 51,
    ratingsInPeriod: 2,
    momentumRatingCount: 2,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4.9,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-03',
    toDate: '2024-03-04',
    numRatings: 51,
    numReviews: 51,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.9,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-04',
    toDate: '2024-03-05',
    numRatings: 52,
    numReviews: 52,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [
      {
        title: 'No more pain!',
        text: 'I switched a few weeks ago, and love how strong my teeth feel when eating hot or cold foods!',
        name: 'ccarline',
        rating: 5,
        attributedUserId: '225a9fe8-79ac-4d67-8172-19c579b22719',
        reviewedAt: '2024-09-04T00:00:00.000Z'
      }
    ],
    rating: 4.9,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-05',
    toDate: '2024-03-06',
    numRatings: 54,
    numReviews: 54,
    ratingsInPeriod: 2,
    momentumRatingCount: 1,
    vineReviewCount: 1,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 1,
    reviewsInPeriod: 2,
    reviews: [
      {
        title: 'helped my sensitive teeth',
        text: 'my teeth are sensitive to coldness so i wanted to give this sensitive toothpaste a try and i have felt the difference since the first use. i absolutely like it',
        name: 'Farah',
        rating: 5,
        attributedUserId: '72890ee2-3e9c-489d-8221-b3e5fd123273',
        reviewedAt: '2024-09-05T00:00:00.000Z'
      }
    ],
    rating: 4.9,
    momentumRating: 5,
    organicRating: 4.700000000000001,
    vineRating: 5
  },
  {
    fromDate: '2024-03-06',
    toDate: '2024-03-07',
    numRatings: 57,
    numReviews: 57,
    ratingsInPeriod: 2,
    momentumRatingCount: 2,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.9,
    organicRatingCount: 0,
    reviewsInPeriod: 2,
    reviews: [
      {
        title: 'Best!!',
        text: 'This was a great choice for my teeth. Smelled good felt good and most importantly I looked good!',
        name: 'Fabby',
        rating: 5,
        attributedUserId: '71b6dd41-b6dd-490c-b2eb-16b4e0ea4393',
        reviewedAt: '2024-09-07T00:00:00.000Z'
      }
    ],
    rating: 4.9,
    momentumRating: 4.9,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-07',
    toDate: '2024-03-08',
    numRatings: 58,
    numReviews: 58,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.9,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-08',
    toDate: '2024-03-09',
    numRatings: 59,
    numReviews: 59,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [
      {
        title: 'Fresh Fresh Fresh',
        text: 'Nice and fresh breath and feels amazing in my teeth !',
        name: 'Amelia',
        rating: 5,
        attributedUserId: 'c66168ba-7c89-40a7-a57b-4f8187fed5cf',
        reviewedAt: '2024-09-10T00:00:00.000Z'
      }
    ],
    rating: 4.9,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-09',
    toDate: '2024-03-10',
    numRatings: 59,
    numReviews: 59,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.9,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-10',
    toDate: '2024-03-11',
    numRatings: 59,
    numReviews: 59,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.9,
    momentumRating: 0,
    organicRating: 4.9,
    vineRating: 0
  },
  {
    fromDate: '2024-03-11',
    toDate: '2024-03-12',
    numRatings: 63,
    numReviews: 63,
    ratingsInPeriod: 4,
    momentumRatingCount: 2,
    vineReviewCount: 2,
    momentumReviewCount: 2,
    momentumReviewRating: 4.9,
    organicRatingCount: 1,
    reviewsInPeriod: 4,
    reviews: [
      {
        title: 'Helps!',
        text: 'Has helped my sensitive teeth. They stop hurting after use',
        name: 'worldlyjen',
        rating: 5,
        attributedUserId: 'b7a1627c-9a4d-4359-aea3-0cda4048b9b4',
        reviewedAt: '2024-09-10T00:00:00.000Z'
      }
    ],
    rating: 4.9,
    momentumRating: 4.9,
    organicRating: 5,
    vineRating: 5
  },
  {
    fromDate: '2024-03-12',
    toDate: '2024-03-13',
    numRatings: 65,
    numReviews: 65,
    ratingsInPeriod: 2,
    momentumRatingCount: 2,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4.9,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-13',
    toDate: '2024-03-14',
    numRatings: 65,
    numReviews: 65,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.9,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-14',
    toDate: '2024-03-15',
    numRatings: 67,
    numReviews: 67,
    ratingsInPeriod: 2,
    momentumRatingCount: 2,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4.9,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-15',
    toDate: '2024-03-16',
    numRatings: 67,
    numReviews: 67,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.9,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-16',
    toDate: '2024-03-17',
    numRatings: 68,
    numReviews: 68,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.9,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-17',
    toDate: '2024-03-18',
    numRatings: 68,
    numReviews: 68,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.9,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-18',
    toDate: '2024-03-19',
    numRatings: 69,
    numReviews: 69,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 1,
    reviewsInPeriod: 1,
    reviews: [
      {
        title: "Taste wasn't overwhelming",
        text: "Loved how the taste wasn't overwhelming minty. You know what I mean where you have to catch your breath plus it was a good sensitive toothpaste where my teeth felt clean but not like I was trying to grind them down. Definitely a win",
        name: 'Karen Hewitt',
        rating: 5,
        attributedUserId: '5fcf206b-47e0-4b6f-8acb-a765371cc302',
        reviewedAt: '2024-09-17T00:00:00.000Z'
      }
    ],
    rating: 4.9,
    momentumRating: 5,
    organicRating: 4.800000000000001,
    vineRating: 0
  },
  {
    fromDate: '2024-03-19',
    toDate: '2024-03-20',
    numRatings: 71,
    numReviews: 71,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [
      {
        title: 'Great Toothpaste',
        text: 'Great flavor, perfect for sensitive teeth and at a really great price! I will for sure be getting this again!',
        name: 'Lizzie Michalak',
        rating: 5,
        attributedUserId: 'ca118d6b-a82d-4504-9c3b-2dc7a89b33d5',
        reviewedAt: '2024-09-18T00:00:00.000Z'
      }
    ],
    rating: 4.9,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-20',
    toDate: '2024-03-21',
    numRatings: 73,
    numReviews: 73,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.8,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-21',
    toDate: '2024-03-22',
    numRatings: 73,
    numReviews: 73,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-22',
    toDate: '2024-03-23',
    numRatings: 74,
    numReviews: 74,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.8,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-23',
    toDate: '2024-03-24',
    numRatings: 75,
    numReviews: 75,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-24',
    toDate: '2024-03-25',
    numRatings: 75,
    numReviews: 75,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-25',
    toDate: '2024-04-01',
    numRatings: 81,
    numReviews: 81,
    ratingsInPeriod: 6,
    momentumRatingCount: 6,
    vineReviewCount: 0,
    momentumReviewCount: 6,
    momentumReviewRating: 4.866666666666667,
    organicRatingCount: 3,
    reviewsInPeriod: 6,
    reviews: [
      {
        title: 'Sensitivity relief',
        text: 'Best option for sensitive teeth. Soft mint taste. My great recommendation!',
        name: 'Olha Shynova',
        rating: 5,
        attributedUserId: 'cc49ef7d-59cb-4268-b1db-86070a3712c1',
        reviewedAt: '2024-09-26T00:00:00.000Z'
      },
      {
        title: 'The best!',
        text: 'Seriously loved this toothpaste, I am super sensitive and this toothpaste has helped me! I definitely recommended this product to all my friends & family!',
        name: 'Julia Arreola',
        rating: 5,
        attributedUserId: '10dd1d26-2f9b-4885-a467-dd629dfcac86',
        reviewedAt: '2024-09-27T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 4.866666666666667,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-04-01',
    toDate: '2024-04-02',
    numRatings: 81,
    numReviews: 81,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-04-02',
    toDate: '2024-04-03',
    numRatings: 82,
    numReviews: 82,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.8,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-04-03',
    toDate: '2024-04-04',
    numRatings: 82,
    numReviews: 82,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-04-04',
    toDate: '2024-04-06',
    numRatings: 82,
    numReviews: 82,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-04-06',
    toDate: '2024-04-07',
    numRatings: 82,
    numReviews: 82,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-04-07',
    toDate: '2024-04-09',
    numRatings: 84,
    numReviews: 84,
    ratingsInPeriod: 2,
    momentumRatingCount: 2,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.9,
    organicRatingCount: 1,
    reviewsInPeriod: 2,
    reviews: [
      {
        title: '10/10',
        text: 'makes brushing my teeth feel so nice. my teeth feel cleaner then ever',
        name: 'Tracy Esseck',
        rating: 5,
        attributedUserId: '6bc469db-fe33-40f1-b375-6afc0376f89c',
        reviewedAt: '2024-10-07T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 4.9,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-04-09',
    toDate: '2024-04-10',
    numRatings: 84,
    numReviews: 84,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-04-10',
    toDate: '2024-04-12',
    numRatings: 85,
    numReviews: 85,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.8,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-04-12',
    toDate: '2024-04-13',
    numRatings: 85,
    numReviews: 85,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-04-13',
    toDate: '2024-04-14',
    numRatings: 85,
    numReviews: 85,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 2,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-04-14',
    toDate: '2024-04-15',
    numRatings: 87,
    numReviews: 87,
    ratingsInPeriod: 2,
    momentumRatingCount: 1,
    vineReviewCount: 1,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4.8,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 5
  },
  {
    fromDate: '2024-04-15',
    toDate: '2024-04-16',
    numRatings: 87,
    numReviews: 87,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-04-16',
    toDate: '2024-04-17',
    numRatings: 87,
    numReviews: 87,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-04-17',
    toDate: '2024-04-18',
    numRatings: 87,
    numReviews: 87,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-04-18',
    toDate: '2024-04-19',
    numRatings: 88,
    numReviews: 88,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.8,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-04-19',
    toDate: '2024-04-20',
    numRatings: 88,
    numReviews: 88,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-04-20',
    toDate: '2024-04-21',
    numRatings: 88,
    numReviews: 88,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-04-21',
    toDate: '2024-04-22',
    numRatings: 88,
    numReviews: 88,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-04-22',
    toDate: '2024-04-23',
    numRatings: 89,
    numReviews: 89,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-04-23',
    toDate: '2024-04-24',
    numRatings: 89,
    numReviews: 89,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-04-24',
    toDate: '2024-04-25',
    numRatings: 89,
    numReviews: 89,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-04-25',
    toDate: '2024-04-26',
    numRatings: 89,
    numReviews: 89,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  }
]
