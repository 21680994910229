import BonusHighlightedIcon from '@momentum/components/icons/bonus-highlighted'
import BonusRefundIcon from '@momentum/components/icons/bonus-refund'
import { useSubscriptionContext } from '@momentum/contexts/Subscription'
import { Box, Stack, Typography } from '@mui/material'
import { useAddProductContext } from './context'

export const CreditsRefund = () => {
  const { creditsRequired } = useAddProductContext()
  const { creditsRemaining } = useSubscriptionContext()

  if (!creditsRequired || creditsRequired >= 0) {
    return null
  }

  return (
    <Box borderRadius={'4px'} border={t => `1px solid ${t.palette.grey.A200}`} bgcolor={'white'}>
      <Stack spacing={2} px={1.5} py={1.5}>
        <Typography variant="label2">You're getting credits back!</Typography>
        <Stack direction={'row'} spacing={1} justifyContent={'space-between'}>
          <Typography variant="label3">Currently available</Typography>
          <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
            <BonusHighlightedIcon sx={{ color: t => t.palette.warning.main }} />
            <Stack direction={'row'} spacing={0.5} alignItems={'baseline'}>
              <Typography typography={'label1'}>{creditsRemaining.toLocaleString()}</Typography>
              <Typography typography={'label3'}> credits</Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction={'row'} spacing={1} justifyContent={'space-between'}>
          <Typography variant="label3">Total to be refunded</Typography>
          <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
            <BonusRefundIcon sx={{ color: t => t.palette.warning.main }} />
            <Stack direction={'row'} spacing={0.5} alignItems={'baseline'}>
              <Typography typography={'label1'} data-cy={'refundCredits-total'}>{Math.abs(creditsRequired).toLocaleString()}</Typography>
              <Typography typography={'label3'}> credits</Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  )
}
