import { last, sumBy } from 'lodash'
import { DateTime } from 'luxon'
import { useCampaignContext } from '../../context/CampaignContext'
import { ReviewMetric } from './reviewsContext'

export const DAYS_DATA = 14

export type MappedReview = {
  cumulativeRatings: number
  cumulativeAverageRatings: number
  rating: number
  date: string
  ratingsInPeriod: number
  averageRatingsInPeriod: number
  momentumRatingCount: number
}

export const useMappedReviews = (): MappedReview[] | undefined => {
  const {
    campaignDetails: { startDate },
    reviewMetricsV2: reviewMetrics
  } = useCampaignContext()
  const startDateDate = DateTime.fromISO(startDate).toISODate()!

  return reviewMetrics
    ?.reduce((prev: ReviewMetric[], r, index) => {
      const fromDate = DateTime.fromISO(r.fromDate)
      const diffDays = DateTime.fromISO(r.toDate).diff(fromDate, 'days').days

      const ratingsInPeriod = r.ratingsInPeriod
      const averageRatingsInPeriod = ratingsInPeriod - r.momentumRatingCount

      const startCumulativeRatings = r.numRatings - ratingsInPeriod

      const record = {
        cumulativeRatings: startCumulativeRatings,
        cumulativeAverageRatings: index === 0 ? startCumulativeRatings : last(prev)?.cumulativeAverageRatings || 0,
        rating: r.rating,
        date: r.toDate
      }

      return [
        ...prev,
        {
          ...record,
          date: fromDate.toISODate()!,
          cumulativeRatings: record.cumulativeRatings + ratingsInPeriod,
          cumulativeAverageRatings: record.cumulativeAverageRatings + averageRatingsInPeriod,
          momentumRatingCount: r.momentumRatingCount,
          ratingsInPeriod: ratingsInPeriod,
          averageRatingsInPeriod: averageRatingsInPeriod
        },
        ...new Array(diffDays - 1).fill(0).map((_, index) => ({
          ...record,
          date: fromDate.plus({ days: index + 1 }).toISODate()!,
          cumulativeRatings: record.cumulativeRatings + ratingsInPeriod,
          cumulativeAverageRatings: record.cumulativeAverageRatings + averageRatingsInPeriod,
          momentumRatingCount: 0,
          ratingsInPeriod: 0,
          averageRatingsInPeriod: 0
        }))
      ]

      /*
      // Comment out above and uncomment this when we want smooth graphs
      const ratingsAddedPerDay = Math.floor(ratingsInPeriod / diffDays)
      const remainderRatingsAddedPerDay = ratingsInPeriod % diffDays

      const averageRatingsAddedPerDay = Math.floor(averageRatingsInPeriod / diffDays)
      const remainderAverageRatingsAddedPerDay = averageRatingsInPeriod % diffDays

      const momentumRatingsAddedPerDay = Math.floor(r.momentumRatingCount / diffDays)
      const remainderMomentumRatingsAddedPerDay = r.momentumRatingCount % diffDays

      return [
        ...prev,
        ...new Array(diffDays)
          .fill(0)
          .map((_, index) => ({
            ...record,
            date: fromDate.plus({ days: index }).toISODate()!,
            ratingsInPeriod: ratingsAddedPerDay + (index < remainderRatingsAddedPerDay ? 1 : 0),
            averageRatingsInPeriod: averageRatingsAddedPerDay + (index < remainderAverageRatingsAddedPerDay ? 1 : 0),
            momentumRatingCount: momentumRatingsAddedPerDay + (index < remainderMomentumRatingsAddedPerDay ? 1 : 0)
          }))
          .reduce(
            (prev: ReviewMetric[], curr, index) => [
              ...prev,
              {
                ...curr,
                cumulativeRatings:
                  (index === 0 ? curr.cumulativeRatings : last(prev)!.cumulativeRatings) + curr.ratingsInPeriod,
                cumulativeAverageRatings:
                  (index === 0 ? curr.cumulativeAverageRatings : last(prev)!.cumulativeAverageRatings) +
                  curr.averageRatingsInPeriod
              }
            ],
            []
          )
      ]*/
    }, [])
    .reduce(
      (prev: ReviewMetric[], curr, index) => [
        ...prev,
        {
          ...curr,
          // This is here to resolve old data issues where the reviews and ratings are separate
          cumulativeAverageRatings:
            curr.cumulativeRatings - sumBy(prev, r => r.momentumRatingCount) - curr.momentumRatingCount
        }
      ],
      []
    )
    .map(r => ({
      ...r,
      cumulativeRatings: r.date < startDateDate ? r.cumulativeAverageRatings : r.cumulativeRatings
    }))
}
