import { Stack, Typography, Button } from '@mui/material'
import { DateInput, Form, SubmitButton } from '@productwindtom/ui-base'
import { DateTime } from 'luxon'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useSalesContext } from '@momentum/routes/campaign/e-commerce/sales/salesContext'
import React, { useEffect } from 'react'
import { useFormContext, UseFormReturn } from 'react-hook-form'

export const schema = yup.object({
  startDate: yup.date().optional(),
  endDate: yup
    .date()
    .min(yup.ref('startDate'), () => `Date needs to be greater than the start date`)
    .optional()
})

export const PeriodSelector = () => {
  const { startDateFilter, endDateFilter, setStartDateFilter, setEndDateFilter } = useSalesContext()

  const onSubmit = (data: { startDate?: Date; endDate?: Date }, methods: UseFormReturn<any>) => {
    setStartDateFilter(data.startDate ? DateTime.fromJSDate(data.startDate) : undefined)
    setEndDateFilter(data.endDate ? DateTime.fromJSDate(data.endDate) : undefined)
    methods.reset(data)
  }

  return (
    <Form
      defaultValues={{ startDate: startDateFilter?.toJSDate(), endDate: endDateFilter?.toJSDate() }}
      onSubmit={onSubmit}
      resolver={yupResolver(schema)}
    >
      <FormContent />
    </Form>
  )
}

const FormContent = () => {
  const { minDate, maxDate, startDateFilter, endDateFilter } = useSalesContext()
  const { reset } = useFormContext()

  useEffect(() => {
    reset({
      startDate: startDateFilter?.toJSDate(),
      endDate: endDateFilter?.toJSDate()
    })
  }, [minDate, maxDate, startDateFilter, endDateFilter, reset])

  return (
    <Stack spacing={2}>
      <Typography variant={'label1'}>Select your period</Typography>
      <Stack direction={'row'} spacing={3} alignItems={'flex-end'}>
        <Stack direction={'row'} spacing={3}>
          <DateInput name={'startDate'} primaryText={'Start date'} minDate={minDate} maxDate={maxDate} />
          <DateInput name={'endDate'} primaryText={'End date'} minDate={minDate} maxDate={maxDate} />
        </Stack>
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <SubmitButton variant={'contained'} disableOnDirty={true}>
            Apply dates
          </SubmitButton>
          <ResetButton />
        </Stack>
      </Stack>
    </Stack>
  )
}

const ResetButton = () => {
  const { reset } = useFormContext()
  const { minDate, maxDate, setStartDateFilter, setEndDateFilter } = useSalesContext()

  const onClick = () => {
    reset({
      startDate: minDate.toJSDate(),
      endDate: maxDate.toJSDate()
    })
    setStartDateFilter(minDate)
    setEndDateFilter(maxDate)
  }
  return <Button onClick={onClick}>Reset</Button>
}
