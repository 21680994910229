import { SvgIcon, SvgIconProps } from '@mui/material'

export default function BackpackIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 25" fill="none">
      <path d="M5.88184 5.45932L5.91371 2.00369L7.49996 1.51807L9.32809 4.65494L5.88184 5.45932Z" fill="#C62828" />
      <path
        opacity="0.59"
        d="M6.8923 4.34007C6.8923 4.34007 6.4048 2.11069 6.95605 1.88944C7.93668 1.49757 8.70168 4.07382 8.70168 4.07382L6.8923 4.34007Z"
        fill="#37474F"
      />
      <path
        d="M3.85978 6.65187C3.8429 6.34624 3.4304 3.90874 4.05665 2.54937C4.34353 1.92874 5.52103 1.51624 6.57478 1.28187C6.8354 1.22374 7.50853 1.18624 7.92665 1.66624C8.30353 2.09937 8.8604 3.04812 9.40978 4.47874L9.01415 5.32999C9.01415 5.32999 8.46853 3.88624 7.9229 2.69374C7.47103 1.70562 6.89728 2.03374 6.83915 2.26437C6.73415 2.68812 7.2704 4.98499 7.2704 4.98499C6.4904 5.31687 3.85978 6.65187 3.85978 6.65187Z"
        fill="#F44336"
      />
      <path
        d="M7.94238 3.75691L8.27051 2.16316L9.56238 1.41504L11.1168 1.41879L12.4105 2.46691L12.7686 4.51066L11.2068 3.98379L10.9949 2.51941L9.56238 2.48379L9.37113 3.64816L7.94238 3.75691Z"
        fill="#455A64"
      />
      <path
        d="M6.57258 1.28391C5.51883 1.51641 4.45945 1.85203 4.05445 2.55141C3.82195 2.95266 3.68695 4.00453 3.7132 4.98141C3.91758 4.65891 4.75195 4.10203 5.37445 3.83203C5.99508 3.56203 6.06633 3.29391 6.0757 3.17578C6.12258 2.63953 6.07758 2.19703 6.23508 1.81828C6.43383 1.33828 6.71695 1.27828 6.88195 1.25391C6.75633 1.25391 6.6457 1.26703 6.57258 1.28391ZM7.42195 1.33641C7.3207 1.30078 7.21945 1.27828 7.12195 1.26703C7.2232 1.29328 7.32258 1.32703 7.42195 1.33641Z"
        fill="#FF7555"
      />
      <path
        d="M16.874 20.9826C19.664 20.2419 20.4965 17.0901 21.2878 16.8257"
        stroke="#784D30"
        strokeWidth="1.8405"
        strokeMiterlimit="10"
      />
      <path
        d="M19.5892 14.5437C19.7936 15.8731 20.1948 15.9724 20.8942 15.9368C21.9536 15.8843 22.0698 15.6406 22.3061 13.8256C22.5986 11.5718 21.7529 8.43118 21.0236 6.27868C20.6373 5.13868 20.6767 4.3568 19.8161 3.4868C19.3436 3.00868 17.5042 2.30555 16.9698 3.22055C16.6023 3.85055 16.7861 4.48993 17.1367 4.99618C18.1548 6.46993 19.0923 11.3281 19.5892 14.5437Z"
        fill="#C62828"
      />
      <path
        opacity="0.59"
        d="M17.4502 5.54588C17.6452 5.94526 17.8458 6.46276 18.0089 7.04588C19.6702 6.99526 21.4702 7.79213 21.4702 7.74338C21.4702 7.70213 21.3296 6.62026 20.8721 5.54776C20.6733 5.08276 20.0677 3.80776 19.3177 3.93151C18.3746 4.08901 17.4502 5.54588 17.4502 5.54588Z"
        fill="#37474F"
      />
      <path
        d="M16.1163 7.84808C16.6057 6.80183 18.4151 4.13933 19.4557 4.05308C19.9713 4.00995 20.5507 5.07683 20.7626 5.65995C21.357 7.29683 22.0038 9.86558 22.1932 12.5468C22.3113 14.2193 22.0057 15.5506 21.3757 16.1037C23.7907 15.6518 22.8476 10.3812 22.6432 9.28995C21.9551 5.63558 21.0532 4.25745 20.3613 3.52058C19.8326 2.95808 17.5301 1.76745 16.3151 1.8237C15.3795 1.86683 13.332 3.6162 12.7732 4.58558C12.6082 4.87058 16.1163 7.84808 16.1163 7.84808Z"
        fill="#F44336"
      />
      <path
        d="M12.873 23.6992C16.0418 23.448 18.0462 21.6667 18.2562 19.9773C18.9799 14.1386 17.5493 5.69547 11.8512 4.05859C11.2155 3.87672 10.098 3.63672 9.12678 3.63672C7.99615 3.63672 6.2524 3.87484 4.9999 4.73359C2.71615 6.30109 2.10678 8.53234 1.95678 9.63859C1.40365 13.6942 8.88678 24.0161 12.873 23.6992Z"
        fill="#FF7555"
      />
      <path
        d="M18.2566 19.9777C18.6335 16.9402 18.4273 13.1996 17.3398 10.0758C16.7735 9.3446 16.1435 9.00148 15.6223 9.17023C14.996 9.37273 14.4466 10.2071 14.6454 11.0471C14.6454 11.0471 16.1623 17.1333 15.131 20.9827C14.4485 23.5308 12.6504 23.7052 12.6504 23.7052C12.7254 23.7052 12.8004 23.7052 12.8735 23.6996C16.0441 23.4483 18.0466 21.6671 18.2566 19.9777Z"
        fill="#C62828"
      />
      <path
        d="M3.49116 23.0712C4.44741 23.3731 6.96179 23.7499 11.4149 23.7499C15.4143 23.7499 15.3862 22.3268 15.7162 20.6562C16.3949 17.2099 15.4649 5.98244 8.49929 5.74056C2.40741 5.52681 1.79804 9.10431 1.33866 12.9893C0.858661 17.0524 0.971161 18.5731 1.20366 20.2287C1.50554 22.3924 2.83304 22.8631 3.49116 23.0712Z"
        fill="#F44336"
      />
      <path
        d="M16.8837 11.7144C16.7225 10.8669 16.4937 9.93501 16.1168 9.02563L15.6631 9.04626C16.0025 9.81688 16.2837 10.7225 16.4862 11.7894C17.1331 15.2075 17.2081 17.4988 17.1743 18.7419C17.1537 19.4881 17.5643 19.4919 17.585 18.695C17.6168 17.4181 17.5306 15.1344 16.8837 11.7144Z"
        fill="#37474F"
      />
      <path
        d="M17.3498 18.3519C17.2397 18.3901 17.1467 18.466 17.0873 18.5662C17.0279 18.6665 17.006 18.7845 17.0255 18.8994L17.2692 20.3132C17.2784 20.378 17.303 20.4397 17.3409 20.4932C17.3788 20.5466 17.4289 20.5901 17.4871 20.6202C17.5453 20.6503 17.6098 20.6661 17.6753 20.6662C17.7408 20.6662 17.8053 20.6506 17.8636 20.6207L18.5423 20.2738C18.7636 20.1613 18.8348 19.8782 18.6942 19.6738L17.9123 18.5376C17.8515 18.4496 17.7637 18.3839 17.6622 18.3504C17.5607 18.3169 17.451 18.3175 17.3498 18.3519Z"
        fill="#E2A610"
      />
      <path
        d="M17.6677 20.3431C17.6339 20.2812 17.4539 19.3174 17.3995 18.8787C17.3395 18.3855 17.5514 18.3574 17.5833 18.3312C17.5062 18.319 17.4274 18.3254 17.3533 18.3498C17.2792 18.3742 17.212 18.4159 17.1572 18.4714C17.1024 18.5269 17.0616 18.5947 17.0382 18.6691C17.0148 18.7435 17.0095 18.8224 17.0227 18.8993L17.2664 20.313C17.2945 20.5062 17.4464 20.6412 17.6208 20.6637C17.6995 20.673 17.782 20.6618 17.8608 20.6205L18.0183 20.5399C18.0108 20.538 17.7783 20.5456 17.6677 20.3431Z"
        fill="#FFCA28"
      />
      <path
        d="M22.4915 17.7742L20.7403 17.9299C20.579 17.9449 20.4346 17.8249 20.4215 17.6617L20.2884 16.1749C20.2734 16.0136 20.3934 15.8692 20.5565 15.8561L22.3078 15.7005C22.469 15.6855 22.6134 15.8055 22.6265 15.9686L22.7596 17.4555C22.7728 17.6167 22.6528 17.7592 22.4915 17.7742Z"
        fill="#546E7A"
      />
      <path
        d="M22.0139 17.8306L20.3564 17.9787C20.1951 17.9937 20.0508 17.8737 20.0376 17.7106L19.9026 16.1937C19.8876 16.0325 20.0076 15.8881 20.1708 15.875L21.8283 15.7269C21.9895 15.7119 22.1339 15.8319 22.147 15.995L22.282 17.5119C22.2951 17.6731 22.1751 17.8156 22.0139 17.8306Z"
        fill="#37474F"
      />
      <path
        d="M20.8662 19.5427L20.3581 16.8652C20.3487 16.8202 20.3806 16.7771 20.4275 16.7733L21.7643 16.6383C21.8018 16.6346 21.8375 16.6589 21.8468 16.6964L22.5068 19.1677C22.55 19.3289 22.4506 19.4958 22.2875 19.5333L21.2225 19.7771C21.0593 19.8127 20.8962 19.7077 20.8662 19.5427Z"
        fill="#A06841"
      />
      <path
        opacity="0.65"
        d="M9.27633 11.5267L6.3832 11.4405C6.13008 11.4405 5.92383 11.2342 5.92383 10.9811L5.9632 9.49233C5.9632 9.24296 6.16195 9.03858 6.41133 9.03296L9.27258 9.10983C9.3332 9.10816 9.39355 9.1185 9.45016 9.14024C9.50678 9.16199 9.55853 9.19472 9.60244 9.23654C9.64636 9.27836 9.68157 9.32846 9.70605 9.38394C9.73053 9.43943 9.74379 9.4992 9.74508 9.55983L9.73945 11.058C9.74133 11.3148 9.53508 11.5267 9.27633 11.5267Z"
        fill="#FFF59D"
      />
      <path
        d="M16.3138 1.82176C15.6051 1.84426 14.2345 2.87551 13.3926 3.78864C13.8276 3.75301 14.857 4.23489 15.712 4.89489C15.8826 5.02614 16.3026 5.00176 16.5763 4.70739C16.8257 4.43926 17.0601 4.23301 17.3113 3.96676C18.7851 2.40489 19.9438 3.17926 19.9438 3.17926C18.7251 2.38614 17.2401 1.79364 16.3138 1.82176Z"
        fill="#FF7555"
      />
      <path
        d="M16.1762 9.16821C15.2519 6.85821 13.3862 4.63633 9.31 4.26883C6.47875 4.01383 4.57187 5.32821 3.79562 5.80446C3.62312 5.90946 3.43 6.04071 3.29875 6.22071C3.13188 6.44946 3.10938 6.63696 3.10938 6.63696C3.10938 6.63696 5.56563 4.34383 9.2725 4.67196C11.3913 4.85946 14.1737 5.56633 15.7056 9.14758C15.835 9.11571 16.0094 9.13446 16.1762 9.16821Z"
        fill="#546E7A"
      />
      <path
        d="M13.0034 21.9219C13.0034 21.9219 10.0071 22.13 6.63774 21.9463C4.86211 21.8488 3.56086 21.5938 3.29648 21.2506C2.97023 20.8269 2.58398 20.4163 2.58398 18.2375C2.58398 16.7694 3.12586 15.5863 4.17586 15.56C4.97836 15.5394 13.4027 15.4475 12.7671 15.4475C12.1315 15.4475 11.5577 16.8406 11.6159 18.3425C11.7584 22.0231 13.0034 21.9219 13.0034 21.9219Z"
        fill="#FF7555"
      />
      <path
        d="M10.0633 18.2374C10.1289 18.2187 10.7251 18.2712 11.2014 17.9393C11.3814 17.8137 11.5239 17.7087 11.6383 17.5887C11.7489 16.4637 12.1914 15.5468 12.6864 15.4568C12.7033 15.4531 12.7201 15.4493 12.7351 15.4474C12.0001 15.4624 5.99076 15.2412 4.11389 15.2693C3.24389 15.2824 2.57264 16.3062 2.63264 17.5099C2.75826 17.7331 2.96076 17.9731 3.27014 18.0199C3.87764 18.1118 9.99764 18.2581 10.0633 18.2374Z"
        fill="#FF7555"
      />
      <path
        opacity="0.45"
        d="M10.0633 18.2374C10.1289 18.2187 10.7251 18.2712 11.2014 17.9393C11.3814 17.8137 11.5239 17.7087 11.6383 17.5887C11.7489 16.4637 12.1914 15.5468 12.6864 15.4568C12.7033 15.4531 12.7201 15.4493 12.7351 15.4474C12.0001 15.4624 5.99076 15.2412 4.11389 15.2693C3.24389 15.2824 2.57264 16.3062 2.63264 17.5099C2.75826 17.7331 2.96076 17.9731 3.27014 18.0199C3.87764 18.1118 9.99764 18.2581 10.0633 18.2374Z"
        fill="#FFF59D"
      />
      <path
        d="M13.9552 17.3187C13.8915 16.34 13.6609 15.875 13.4509 15.6537C13.2577 15.4475 12.9559 15.4306 12.5265 15.4512C12.2602 15.4644 11.7746 15.7194 11.5702 17.225V17.2231C11.409 17.5887 10.9871 17.9525 10.2877 17.9525L3.58462 17.735C2.91337 17.6919 2.66774 17.0506 2.63587 16.9925C2.63587 16.9925 2.53837 17.4444 2.63024 17.7312C2.74649 18.0912 3.17399 18.305 3.58462 18.305L10.2877 18.5225C10.8221 18.5225 11.229 18.3106 11.4915 18.1137C11.4802 18.3462 11.4746 18.5994 11.4746 18.8731C11.4746 21.7531 12.3052 21.9706 12.8359 21.9462C13.3665 21.92 13.8859 21.7175 13.9702 21.2469C14.0902 20.5887 14.0771 19.205 13.9552 17.3187Z"
        fill="#C62828"
      />
    </SvgIcon>
  )
}
