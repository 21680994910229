import { SvgIcon, SvgIconProps } from '@mui/material'

export default function PackageCheckIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 24 24"
      style={{
        fill: 'none'
      }}
    >
      <path
        d="M18.9174 6.18644L15.1454 8.07245M15.1454 8.07245L14.6739 8.3082L10.4304 10.43M15.1454 8.07245V11.373M15.1454 8.07245L6.18688 3.35742M10.4304 10.43L1.94336 6.18644M10.4304 10.43V19.3885"
        stroke="black"
        strokeWidth="1.41451"
        strokeLinecap="round"
      />
      <path
        d="M13.8041 2.30323L15.6901 3.29339C17.7185 4.35804 18.7332 4.8899 19.2971 5.84705C19.8601 6.80325 19.8601 7.99427 19.8601 10.3754V10.4857C19.8601 12.8658 19.8601 14.0568 19.2971 15.0131C18.7332 15.9702 17.7185 16.503 15.6901 17.5677L13.8041 18.5569C12.1482 19.4254 11.3202 19.8601 10.43 19.8601C9.53985 19.8601 8.71189 19.4263 7.05598 18.5569L5.16997 17.5667C3.14156 16.5021 2.12689 15.9702 1.56297 15.0131C1 14.0568 1 12.8658 1 10.4866V10.3763C1 7.99521 1 6.8042 1.56297 5.84799C2.12689 4.89084 3.14156 4.35804 5.16997 3.29433L7.05598 2.30418C8.71189 1.43473 9.53985 1 10.43 1C11.3202 1 12.1482 1.43378 13.8041 2.30323Z"
        stroke="black"
        strokeWidth="1.41451"
        strokeLinecap="round"
      />
      <circle cx="18" cy="18" r="6" fill="#12B76A" />
      <path d="M16.75 21.16L21.5 16.41L20.34 15L16.75 18.59L15.16 17L14 18.16L16.75 21.16Z" fill="white" />
    </SvgIcon>
  )
}
