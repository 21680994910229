import { SvgIcon, SvgIconProps } from '@mui/material'

export default function HammerAndWrenchIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 25" fill="none">
      <path
        d="M2.39019 22.7281C3.63706 23.975 5.15206 23.5419 6.02769 22.5444C6.92206 21.5244 7.39831 20.9319 7.39831 20.9319C8.51394 19.2894 13.1227 13.7994 17.0564 9.84876C17.6039 10.0738 18.1852 10.1919 18.7683 10.1919C19.8071 10.1919 20.8477 9.83376 21.6802 9.11564C22.1753 8.69032 22.5712 8.1617 22.84 7.56696C23.1089 6.97221 23.2442 6.32579 23.2364 5.67314C23.2346 5.58126 23.1858 5.49501 23.1052 5.45001C23.0246 5.40501 22.9271 5.40501 22.8464 5.45001L20.1371 7.01376C18.8283 6.56189 18.0539 5.21564 18.3202 3.86939L21.0202 2.31126C21.1008 2.26439 21.1496 2.18001 21.1496 2.08626C21.1496 1.99251 21.1008 1.90814 21.0202 1.86126C19.2671 0.835638 17.0396 1.12439 15.6033 2.56064C15.1722 2.99305 14.8339 3.50896 14.6092 4.07672C14.3844 4.64449 14.278 5.25217 14.2964 5.86251C14.3168 6.56647 14.5045 7.25547 14.8439 7.87251C10.4977 12.2056 5.19519 16.8913 3.81519 17.9375C3.81519 17.9375 2.98456 18.5394 2.19144 19.355C1.40956 20.1594 1.11894 21.4569 2.39019 22.7281ZM3.30331 20.81C3.30331 20.2588 3.74956 19.8106 4.30269 19.8106C4.85581 19.8106 5.30206 20.2569 5.30206 20.81C5.30206 21.3613 4.85581 21.8094 4.30269 21.8094C3.74956 21.8094 3.30331 21.3613 3.30331 20.81Z"
        fill="#82AEC0"
      />
      <path
        d="M14.2507 8.46327C14.4457 8.27014 14.6445 8.07514 14.847 7.87264C14.5075 7.2556 14.3198 6.5666 14.2995 5.86264C14.2845 5.37514 14.3501 4.89327 14.4888 4.43389C14.6126 4.23139 14.8076 4.01389 14.9313 4.14514C14.8807 5.74077 15.3438 7.40389 16.4538 8.55139C17.0051 9.12139 17.7213 9.53202 18.4901 9.72514C18.8688 9.82077 19.2626 9.86389 19.6545 9.84139C19.8851 9.82827 20.5301 9.59014 20.6595 9.76639V9.77389C20.0673 10.0486 19.4223 10.1907 18.7695 10.1901C18.1863 10.1901 17.6032 10.072 17.0576 9.84702C15.7845 11.1258 14.7307 12.277 13.8457 13.3064C14.2882 12.4701 15.5913 10.9289 15.9401 10.3401C16.0188 10.207 16.1276 9.86577 15.8801 9.55827C15.5295 9.12702 14.8113 8.72577 14.2507 8.46327Z"
        fill="#2F7889"
      />
      <path
        d="M8.94002 15.0782C9.04127 14.9732 8.94565 14.797 8.80315 14.8288C8.5369 14.887 8.15252 15.0388 7.69502 15.4101C6.79877 16.1376 4.43252 18.1026 4.14002 18.4907C3.84752 18.8788 5.02127 18.4851 5.43377 18.412C5.77315 18.3501 8.13752 15.9126 8.94002 15.0782ZM17.1431 2.54947C16.6425 2.90759 16.1925 3.33322 15.7238 3.73259C15.6038 3.83572 15.4256 3.93884 15.3019 3.84134C15.1669 3.73447 15.2513 3.51884 15.3525 3.38009C16.2394 2.16134 17.895 1.28947 19.4963 1.63634C18.6525 1.90822 17.8931 2.01322 17.1431 2.54947Z"
        fill="#B9E4EA"
      />
      <path
        d="M21.1235 6.73234C20.9266 6.85046 20.6529 7.00796 20.4973 7.05296C20.3079 7.10546 19.7716 6.89734 19.616 6.77359C20.2573 6.35546 20.9716 5.88859 21.6129 5.47046C21.686 5.42359 21.7591 5.37484 21.8435 5.34859C21.9429 5.31671 22.0516 5.32046 22.1566 5.32421C22.3029 5.33171 23.0173 5.30734 23.1016 5.43296C23.186 5.56046 22.8898 5.68421 22.7923 5.74234C22.2354 6.07421 21.6785 6.40234 21.1235 6.73234ZM13.4623 13.6567L11.3641 11.238C10.6048 11.9523 9.85289 12.648 9.13477 13.3061L9.74602 13.173C10.0141 13.1167 10.2916 13.1992 10.4866 13.3923L11.4654 14.4892C11.6716 14.6955 11.7054 15.0536 11.6266 15.3348L11.5179 15.8542C12.1366 15.1398 12.791 14.3992 13.4623 13.6567Z"
        fill="#2F7889"
      />
      <path
        d="M22.7612 22.3832L21.5049 23.4688C21.0212 23.8869 20.2862 23.8307 19.8718 23.3469L4.98242 5.37506L6.8968 3.93506L22.8568 20.7669C22.9589 20.8782 23.0376 21.0089 23.0882 21.1512C23.1388 21.2935 23.1603 21.4445 23.1514 21.5953C23.1424 21.7461 23.1033 21.8935 23.0363 22.0289C22.9692 22.1642 22.8757 22.2847 22.7612 22.3832Z"
        fill="#A06841"
      />
      <path
        d="M6.34277 7.0173L9.88652 11.2961C10.4396 10.4636 10.7209 9.46605 10.9478 8.20605L7.5259 4.59668C7.24792 4.82372 7.01131 5.09712 6.82652 5.4048C6.5359 5.89418 6.38402 6.44918 6.34277 7.0173Z"
        fill="#7D5133"
      />
      <path
        d="M13.3665 1.42641C10.1153 1.38891 8.91151 1.67766 7.10026 3.24329C6.55651 3.71204 6.01463 4.18079 5.47088 4.65141C4.88026 5.16141 4.09463 5.60766 4.21838 6.48891C4.26338 6.81141 4.36276 7.13766 4.30276 7.45641C4.24463 7.75829 3.89401 7.90829 3.64463 7.73579C3.41026 7.57454 3.15151 7.37579 2.86276 7.34204C2.59276 7.31204 2.30776 7.39641 2.10338 7.57829L0.843384 8.69766C0.843384 8.69766 0.663384 9.43079 2.07338 11.0264C3.48338 12.622 4.39088 12.5077 4.39088 12.5077L5.57588 11.4652C5.77463 11.2908 5.87776 11.0339 5.87213 10.7695C5.86651 10.4602 5.68651 10.2202 5.57776 9.94266C5.55713 9.89016 5.43901 9.70266 5.64526 9.53204C5.82901 9.37266 6.20776 9.42704 6.42338 9.48516C6.63901 9.54329 6.84526 9.63516 7.06276 9.69141C7.48651 9.80204 7.68526 9.60516 7.97963 9.35204C8.24026 9.12704 9.80963 7.77141 10.3328 7.31766C11.3771 6.41391 9.78526 4.83141 9.78526 4.83141C9.03526 3.98204 13.5859 1.84641 13.5859 1.84641C13.9196 1.73016 13.8184 1.43016 13.3665 1.42641Z"
        fill="#82AEC0"
      />
      <path
        d="M7.06471 9.69306C7.15284 9.71556 7.22971 9.72306 7.30096 9.72118V9.68556C7.28221 9.48306 7.17159 9.29931 7.05909 9.12868C6.35464 8.05687 5.48442 7.10375 4.48096 6.30493C4.39846 6.23931 4.31221 6.17368 4.22221 6.11743C4.20346 6.23181 4.19971 6.35556 4.21846 6.48868C4.26346 6.81118 4.36284 7.13743 4.30284 7.45618C4.23909 7.78618 3.89409 7.87806 3.62971 7.75431C4.31784 8.19868 4.90659 8.79118 5.33596 9.48868C5.39409 9.58243 5.45221 9.68181 5.51971 9.77181C5.52909 9.67993 5.57034 9.59181 5.64721 9.53368C5.83096 9.37431 6.20971 9.42868 6.42534 9.48681C6.64284 9.54306 6.84721 9.63493 7.06471 9.69306ZM3.18721 10.0906C4.17534 11.1237 4.73034 12.1943 4.42659 12.4849C4.12471 12.7737 3.07846 12.1718 2.09034 11.1406C1.10221 10.1093 0.541587 8.98806 0.843462 8.69743C1.14534 8.40868 2.19909 9.05743 3.18721 10.0906Z"
        fill="#2F7889"
      />
      <path
        d="M7.03326 4.75248C7.81888 4.41873 8.51826 3.70248 9.43326 2.91311C9.69388 2.68811 10.0258 2.47436 10.3651 2.31311C10.4926 2.25311 10.4458 2.06186 10.3051 2.06373C9.83451 2.07123 9.41451 2.21373 9.00201 2.39373C8.42451 2.64498 7.91451 3.02561 7.42326 3.41936C7.08576 3.68936 6.29451 4.35686 5.79576 4.77873C5.70201 4.85748 5.77513 4.98123 5.89701 4.98123C6.24763 4.98498 6.44451 5.00373 7.03326 4.75248ZM2.08513 8.02998C1.75326 8.40311 2.17701 8.50436 2.92138 9.11936C3.44638 9.55436 4.00513 9.15123 4.01076 8.74436C4.01451 8.37873 3.92263 8.18186 3.39201 7.84811C2.86138 7.51436 2.38513 7.69248 2.08513 8.02998Z"
        fill="#B9E4EA"
      />
    </SvgIcon>
  )
}
