import { useFormContext } from 'react-hook-form'
import useEstimatedDeliverables from '@momentum/routes/proposals-create/modules/useEstimatedDeliverables'
import { ProposalCreateForm } from '@momentum/routes/proposals-create/types'
import { EstimatedTimeline } from '@momentum/routes/proposals-create/common/EstimatedTimeline'
import { Typography, Stack, Alert } from '@mui/material'

export const CampaignTimeline = () => {
  const { watch } = useFormContext<ProposalCreateForm>()
  const { isDisplayResults, timelineSteps, isMonthly, totalCreators } = useEstimatedDeliverables()

  const formData = watch()

  const isValid = !!isDisplayResults && !!formData.launchDate && !!totalCreators

  if (!isValid) {
    return (
      <Stack
        bgcolor={t => t.palette.primary.lighter}
        p={2}
        height={215}
        borderRadius={'4px'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Typography variant={'label3'} color={t => t.palette.info.main} textAlign={'center'} maxWidth={200}>
          Add creators and select your campaign launch date to view your campaign milestones!
        </Typography>
      </Stack>
    )
  }

  return (
    <Stack spacing={2}>
      <Alert variant={'outlined'} severity={'info'} sx={{ py: 0, px: 1 }}>
        <Typography variant={'label3'}>
          Campaign milestones are estimated based on your projected sales velocity.
        </Typography>
      </Alert>
      <EstimatedTimeline
        launchDate={isValid ? formData.launchDate : undefined}
        timelineSteps={timelineSteps}
        isMonthly={isMonthly}
      />
    </Stack>
  )
}
