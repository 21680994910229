import CloseButton from '@momentum/components/close-button'
import Row from '@momentum/components/row'
import { Product as BrandProduct } from '@momentum/routes/brand/types'
import { getCdnImageUrl, noProductImageSmallAlt } from '@momentum/utils/imageUtils'
import { CheckCircleOutline } from '@mui/icons-material'
import { Box, Button, Dialog, Stack, Typography } from '@mui/material'
import { STORE_TO_LOCALE, Store } from '@productwindtom/shared-momentum-zeus-types'
import { notEmpty } from '@productwindtom/shared-node'
import { toCurrencyStringCents } from '@productwindtom/shared-ws-currency'
import { CheckInput } from '@productwindtom/ui-base'
import { keyBy, orderBy, uniq, uniqBy } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import VariationPriceAlert, { useVariationPriceAlert } from '../variations-price-alert/VariationPriceAlert'

export type ProductVariationFormFields = {
  productId?: string | null
  productVariationSkus?: string[] | null
  variations?: { [key: string]: boolean } | null
}

export type Product = {
  id: string
  skuId?: string
  parentSkuId?: string
  name: string
  image?: string
  priceCents?: number
  store: Store
}

const ProductVariationsInput = <P extends Product>({
  brandProducts,
  selectedProduct,
  setSelectedProduct,
  selectableProducts
}: {
  brandProducts: BrandProduct[] | undefined
  selectedProduct: P | undefined
  setSelectedProduct?: (product: P) => void // optional if you watch('productId')
  selectableProducts: P[]
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { watch, setValue } = useFormContext<ProductVariationFormFields>()

  const productVariationSkus = watch('productVariationSkus')

  const handleClose = () => {
    setIsModalOpen(false)
  }

  const findParent = (currentProduct?: P): P | undefined => {
    const potentialParentSku = brandProducts?.find(p => p.id === currentProduct?.parentSkuId)

    if (
      !potentialParentSku ||
      potentialParentSku.id === currentProduct?.id ||
      potentialParentSku.skuId === currentProduct?.skuId
    ) {
      return currentProduct
    }
    return findParent(potentialParentSku as P)
  }

  const highestParentSku = useMemo(() => {
    if (selectedProduct) {
      return findParent(selectedProduct)
    }
  }, [selectedProduct])

  const findChildren = (currentProduct: P): P[] => {
    const children = brandProducts?.filter(p => p.parentSkuId === currentProduct.id && p.parentSkuId !== p.id) || []
    if (children.length) {
      return children.map(child => findChildren(child as P)).flat()
    }
    return [currentProduct]
  }

  const lowestChildren = useMemo(() => {
    if (highestParentSku) {
      return uniqBy(findChildren(highestParentSku), 'id')
    }
    return []
  }, [highestParentSku, brandProducts])

  useEffect(() => {
    if (selectedProduct?.skuId && !productVariationSkus?.length) {
      if (lowestChildren.find(lc => lc.id === selectedProduct.id)) {
        setValue('productVariationSkus', [selectedProduct.skuId])
      } else {
        setValue('productVariationSkus', lowestChildren.map(lc => lc.skuId).filter(notEmpty))
      }
    } else if (!productVariationSkus?.length) {
      setValue('productVariationSkus', [])
    }
  }, [selectedProduct, productVariationSkus, setValue, lowestChildren])

  const handleSubmit = (variations: ProductVariationFormFields['variations']) => {
    if (!variations) {
      return
    }

    const productVariationSkus = Object.entries(variations)
      .map(([key, value]) => (value ? key : ''))
      .filter(val => !!val)

    if (productVariationSkus.length > 1) {
      if (highestParentSku) {
        setValue('productId', highestParentSku.id)
        setSelectedProduct?.(highestParentSku)
      }
      setValue('productVariationSkus', uniq(productVariationSkus))
    } else if (productVariationSkus.length === 1) {
      const selectedVariation = lowestChildren.find(v => v.skuId === productVariationSkus[0])

      if (selectedVariation) {
        setValue('productId', selectedVariation.id)
        setSelectedProduct?.(selectedVariation)
      }
      setValue('productVariationSkus', [])
    }
    handleClose()
  }

  const keyedSelectableProducts = keyBy(selectableProducts, 'id')

  const selectedVariations = lowestChildren.filter(lc => productVariationSkus?.includes(lc.skuId!)).map(s => s.id)

  const prices = selectedVariations?.map(v => ({
    priceCents: keyedSelectableProducts[v]?.priceCents,
    store: keyedSelectableProducts[v]?.store
  }))

  return (
    <Stack spacing={1}>
      <Row spacing={1}>
        <CheckCircleOutline color="success" />
        <Typography variant="subtitle2">
          Creators can purchase {productVariationSkus?.length} of {lowestChildren?.length} variations of this product.
        </Typography>
        <Button variant="text" onClick={() => setIsModalOpen(true)} data-cy="productVariationsInput-Change">
          Change
        </Button>
        <Dialog open={isModalOpen} onClose={handleClose} maxWidth="sm" fullWidth>
          <Stack p={3} spacing={2}>
            <Row justifyContent={'space-between'}>
              <Typography variant="h4">Product variations</Typography>
              <CloseButton
                iconButtonProps={{
                  onClick: () => setIsModalOpen(false)
                }}
              />
            </Row>
            <VariationsFields
              variations={lowestChildren}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
              initialVariations={productVariationSkus}
              selectedProduct={selectedProduct}
            />
          </Stack>
        </Dialog>
      </Row>
      {prices && <VariationPriceAlert prices={prices} />}
    </Stack>
  )
}

export default ProductVariationsInput

export const VariationsFields = <P extends Product, V extends Product>({
  variations,
  handleClose,
  handleSubmit,
  initialVariations,
  selectedProduct
}: {
  variations: V[]
  initialVariations?: ProductVariationFormFields['productVariationSkus']
  handleClose: () => void
  handleSubmit?: (variations: ProductVariationFormFields['variations']) => void
  selectedProduct: P | undefined
}) => {
  const { control, watch, setValue } = useFormContext<ProductVariationFormFields>()

  const variationsData = watch('variations')

  useEffect(() => {
    setValue(
      'variations',
      initialVariations?.reduce(
        (acc, variation) => ({
          ...acc,
          [variation]: true
        }),
        {}
      )
    )

    return () => {
      setValue('variations', undefined)
    }
  }, [initialVariations, setValue])

  const sortedVariations = useMemo(
    () =>
      orderBy(
        uniqBy(variations, 'id'),
        [a => (a.id === selectedProduct?.id ? 0 : 1), a => a.parentSkuId, a => a.name],
        ['asc', 'asc', 'asc']
      ),
    [variations]
  )

  const selectedVariations = sortedVariations.filter(v => v.skuId && variationsData?.[v.skuId])
  const prices = selectedVariations.map(v => ({
    priceCents: v.priceCents,
    store: v.store
  }))

  const { isOutSideBounds } = useVariationPriceAlert(prices)

  return (
    <Stack spacing={2}>
      {sortedVariations.map(variation => (
        <Row
          key={variation.id}
          spacing={1}
          alignItems={'flex-start'}
          data-cy={`productVariationsInputVariation-${variation.skuId}`}
        >
          <Box>
            <img
              src={getCdnImageUrl(variation.image) || '/images/no-product-small.png'}
              loading="lazy"
              width={40}
              height={40}
              alt={variation.name}
              style={{ objectFit: 'contain' }}
              onError={noProductImageSmallAlt}
            />
          </Box>
          <Row spacing={2} justifyContent={'space-between'} width={'100%'} alignItems={'flex-start'}>
            <Stack spacing={0.2} maxWidth={350} flex={1}>
              <Typography variant="label3">{variation.skuId}</Typography>
              <Typography variant="body2">{variation.name}</Typography>
            </Stack>
            {variation.priceCents && (
              <Stack>
                <Typography variant="label1">
                  {toCurrencyStringCents(variation.priceCents, STORE_TO_LOCALE[variation.store])}
                </Typography>
                <Typography variant="body2" textAlign={'right'}>
                  ASP
                </Typography>
              </Stack>
            )}

            <Stack width={40} alignItems={'center'}>
              <CheckInput
                name={`variations.${variation.skuId}`}
                controlLabelProps={{
                  sx: {
                    m: 0
                  }
                }}
                checkboxProps={{
                  size: 'medium'
                }}
                disabled={!handleSubmit}
                data-cy="productVariationsInput-Checkbox"
              />
            </Stack>
          </Row>
        </Row>
      ))}
      <VariationPriceAlert prices={prices} />
      {handleSubmit ? (
        <Row spacing={1} justifyContent={'flex-end'}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            disabled={isOutSideBounds || !prices.length}
            onClick={() => handleSubmit(variationsData)}
            variant="contained"
            data-cy="productVariationsInput-Save"
          >
            Save
          </Button>
        </Row>
      ) : (
        <Row spacing={1} justifyContent={'flex-end'}>
          <Button onClick={handleClose} variant="contained">
            Done
          </Button>
        </Row>
      )}
    </Stack>
  )
}
