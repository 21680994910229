//@ts-nocheck
import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'

export const SAMPLE_ETAILER_PRODUCT_METRICS_WIRELESS_EARBUDS: ModelTypes['ModelItemsEtailerProductMetrics'] = {
  items: [
    {
      date: '2024-06-03',
      pageViews: 70,
      totalSalesAmount: 29998,
      unitsSold: 2
    },
    {
      date: '2024-06-04',
      pageViews: 75,
      totalSalesAmount: 29998,
      unitsSold: 2
    },
    {
      date: '2024-06-05',
      pageViews: 70,
      totalSalesAmount: 89994,
      unitsSold: 6
    },
    {
      date: '2024-06-06',
      pageViews: 62,
      totalSalesAmount: 29998,
      unitsSold: 2
    },
    {
      date: '2024-06-07',
      pageViews: 158,
      totalSalesAmount: 89994,
      unitsSold: 6
    },
    {
      date: '2024-06-08',
      pageViews: 424,
      totalSalesAmount: 74995,
      unitsSold: 5
    },
    {
      date: '2024-06-09',
      pageViews: 613,
      totalSalesAmount: 44997,
      unitsSold: 3
    },
    {
      date: '2024-06-10',
      pageViews: 427,
      totalSalesAmount: 44997,
      unitsSold: 3
    },
    {
      date: '2024-06-11',
      pageViews: 238,
      totalSalesAmount: 29998,
      unitsSold: 2
    },
    {
      date: '2024-06-12',
      pageViews: 2384,
      totalSalesAmount: 14999,
      unitsSold: 1
    },
    {
      date: '2024-06-13',
      pageViews: 5687,
      totalSalesAmount: 74995,
      unitsSold: 5
    },
    {
      date: '2024-06-14',
      pageViews: 5967,
      totalSalesAmount: 179988,
      unitsSold: 12
    },
    {
      date: '2024-06-15',
      pageViews: 4662,
      totalSalesAmount: 134991,
      unitsSold: 9
    },
    {
      date: '2024-06-16',
      pageViews: 3095,
      totalSalesAmount: 134991,
      unitsSold: 9
    },
    {
      date: '2024-06-17',
      pageViews: 2862,
      totalSalesAmount: 329978,
      unitsSold: 22
    },
    {
      date: '2024-06-18',
      pageViews: 2676,
      totalSalesAmount: 269982,
      unitsSold: 18
    },
    {
      date: '2024-06-19',
      pageViews: 2877,
      totalSalesAmount: 194987,
      unitsSold: 13
    },
    {
      date: '2024-06-20',
      pageViews: 1877,
      totalSalesAmount: 464969,
      unitsSold: 31
    },
    {
      date: '2024-06-21',
      pageViews: 2182,
      totalSalesAmount: 974935,
      unitsSold: 65
    },
    {
      date: '2024-06-22',
      pageViews: 2120,
      totalSalesAmount: 434971,
      unitsSold: 29
    },
    {
      date: '2024-06-23',
      pageViews: 2653,
      totalSalesAmount: 569962,
      unitsSold: 38
    },
    {
      date: '2024-06-24',
      pageViews: 2308,
      totalSalesAmount: 329978,
      unitsSold: 22
    },
    {
      date: '2024-06-25',
      pageViews: 1530,
      totalSalesAmount: 344977,
      unitsSold: 23
    },
    {
      date: '2024-06-26',
      pageViews: 1530,
      totalSalesAmount: 359976,
      unitsSold: 24
    },
    {
      date: '2024-06-27',
      pageViews: 1706,
      totalSalesAmount: 502133,
      unitsSold: 35
    },
    {
      date: '2024-06-28',
      pageViews: 1820,
      totalSalesAmount: 1012924,
      unitsSold: 76
    },
    {
      date: '2024-06-29',
      pageViews: 3026,
      totalSalesAmount: 1116206,
      unitsSold: 86
    },
    {
      date: '2024-06-30',
      pageViews: 2665,
      totalSalesAmount: 893225,
      unitsSold: 67
    },
    {
      date: '2024-07-01',
      pageViews: 2940,
      totalSalesAmount: 854943,
      unitsSold: 57
    },
    {
      date: '2024-07-02',
      pageViews: 1706,
      totalSalesAmount: 666393,
      unitsSold: 69
    },
    {
      date: '2024-07-03',
      pageViews: 1281,
      totalSalesAmount: 569962,
      unitsSold: 50
    },
    {
      date: '2024-07-04',
      pageViews: 1372,
      totalSalesAmount: 651394,
      unitsSold: 68
    },
    {
      date: '2024-07-05',
      pageViews: 1306,
      totalSalesAmount: 136991,
      unitsSold: 18
    },
    {
      date: '2024-07-06',
      pageViews: 1379,
      totalSalesAmount: 329978,
      unitsSold: 22
    },
    {
      date: '2024-07-07',
      pageViews: 1443,
      totalSalesAmount: 599960,
      unitsSold: 40
    },
    {
      date: '2024-07-08',
      pageViews: 1403,
      totalSalesAmount: 468969,
      unitsSold: 31
    },
    {
      date: '2024-07-09',
      pageViews: 967,
      totalSalesAmount: 586961,
      unitsSold: 39
    },
    {
      date: '2024-07-10',
      pageViews: 1203,
      totalSalesAmount: 704953,
      unitsSold: 47
    },
    {
      date: '2024-07-11',
      pageViews: 1459,
      totalSalesAmount: 884941,
      unitsSold: 59
    },
    {
      date: '2024-07-12',
      pageViews: 1199,
      totalSalesAmount: 584961,
      unitsSold: 39
    },
    {
      date: '2024-07-13',
      pageViews: 1430,
      totalSalesAmount: 1021932,
      unitsSold: 68
    },
    {
      date: '2024-07-14',
      pageViews: 1239,
      totalSalesAmount: 690683,
      unitsSold: 47
    },
    {
      date: '2024-07-15',
      pageViews: 1183,
      totalSalesAmount: 764949,
      unitsSold: 51
    },
    {
      date: '2024-07-16',
      pageViews: 1337,
      totalSalesAmount: 794947,
      unitsSold: 53
    },
    {
      date: '2024-07-17',
      pageViews: 1302,
      totalSalesAmount: 735126,
      unitsSold: 49
    },
    {
      date: '2024-07-18',
      pageViews: 1230,
      totalSalesAmount: 870117,
      unitsSold: 58
    },
    {
      date: '2024-07-19',
      pageViews: 1415,
      totalSalesAmount: 629958,
      unitsSold: 42
    },
    {
      date: '2024-07-20',
      pageViews: 1185,
      totalSalesAmount: 569962,
      unitsSold: 38
    },
    {
      date: '2024-07-21',
      pageViews: 959,
      totalSalesAmount: 494967,
      unitsSold: 33
    },
    {
      date: '2024-07-22',
      pageViews: 907,
      totalSalesAmount: 209986,
      unitsSold: 14
    },
    {
      date: '2024-07-23',
      pageViews: 713,
      totalSalesAmount: 314979,
      unitsSold: 21
    },
    {
      date: '2024-07-24',
      pageViews: 820,
      totalSalesAmount: 464969,
      unitsSold: 31
    },
    {
      date: '2024-07-25',
      pageViews: 883,
      totalSalesAmount: 389974,
      unitsSold: 26
    },
    {
      date: '2024-07-26',
      pageViews: 996,
      totalSalesAmount: 532959,
      unitsSold: 41
    },
    {
      date: '2024-07-27',
      pageViews: 975,
      totalSalesAmount: 493700,
      unitsSold: 38
    },
    {
      date: '2024-07-28',
      pageViews: 857,
      totalSalesAmount: 376971,
      unitsSold: 29
    },
    {
      date: '2024-07-29',
      pageViews: 856,
      totalSalesAmount: 400969,
      unitsSold: 31
    },
    {
      date: '2024-07-30',
      pageViews: 795,
      totalSalesAmount: 389970,
      unitsSold: 30
    },
    {
      date: '2024-07-31',
      pageViews: 953,
      totalSalesAmount: 480963,
      unitsSold: 37
    },
    {
      date: '2024-08-01',
      pageViews: 561,
      totalSalesAmount: 402969,
      unitsSold: 31
    },
    {
      date: '2024-08-02',
      pageViews: 561,
      totalSalesAmount: 519960,
      unitsSold: 40
    },
    {
      date: '2024-08-03',
      pageViews: 561,
      totalSalesAmount: 454965,
      unitsSold: 35
    },
    {
      date: '2024-08-04',
      pageViews: 561,
      totalSalesAmount: 480963,
      unitsSold: 37
    },
    {
      date: '2024-08-05',
      pageViews: 561,
      totalSalesAmount: 584955,
      unitsSold: 45
    },
    {
      date: '2024-08-06',
      pageViews: 561,
      totalSalesAmount: 428967,
      unitsSold: 33
    },
    {
      date: '2024-08-07',
      pageViews: 561,
      totalSalesAmount: 441966,
      unitsSold: 34
    },
    {
      date: '2024-08-08',
      pageViews: 561,
      totalSalesAmount: 493962,
      unitsSold: 38
    },
    {
      date: '2024-08-09',
      pageViews: 561,
      totalSalesAmount: 545958,
      unitsSold: 42
    },
    {
      date: '2024-08-10',
      pageViews: 561,
      totalSalesAmount: 467964,
      unitsSold: 36
    }
  ]
}
