import PackageCheckIcon from '@momentum/components/icons/package-check'
import PriceDecreaseIcon from '@momentum/components/icons/price-decrease'
import PriceIncreaseIcon from '@momentum/components/icons/price-increase'
import { getCdnImageUrl, noProductImageAlt } from '@momentum/utils/imageUtils'
import { StoreToFlagIcon, StoreToIcon } from '@momentum/utils/storeIcons'
import { OpenInNewOutlined, Star } from '@mui/icons-material'
import { Button, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { STORE_TO_LOCALE } from '@productwindtom/shared-momentum-zeus-types'
import { toCurrencyStringCents } from '@productwindtom/shared-ws-currency'
import { useAddProductContext } from './context'
import { isParentProduct } from './types'

export const LiveProductDetails = () => {
  const { selectedProduct, highestProductPriceCents, creditsRequired } = useAddProductContext()

  if (!selectedProduct) {
    return null
  }

  return (
    <Box borderRadius={'4px'} border={t => `1px solid ${t.palette.grey.A200}`} bgcolor={'white'}>
      <Stack spacing={2} p={1.5}>
        <Stack direction={'row'} spacing={2} justifyContent={'space-between'}>
          <Stack direction={'row'} spacing={1}>
            <PackageCheckIcon />
            <Typography variant={'label2'} data-cy={'productFound-message'}>Success! We found your product.</Typography>
          </Stack>
          <Stack direction={'row'} spacing={2}>
            {!!selectedProduct.listingLink && (
              <Button
                href={selectedProduct.listingLink}
                target={'_blank'}
                variant={'text'}
                startIcon={<OpenInNewOutlined fontSize={'mSmall'} />}
              >
                <Typography variant={'link2'}>View product</Typography>
              </Button>
            )}
          </Stack>
        </Stack>

        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <Box
            width={'64px'}
            height={'64px'}
            alignItems={'center'}
            justifyContent={'center'}
            borderRadius={'4px'}
            border={'1px solid #F3F0F0'}
          >
            <img
              src={getCdnImageUrl(selectedProduct.image) || '/images/no-product.png'}
              alt={'Product Image'}
              onError={noProductImageAlt}
              loading="lazy"
              width="100%"
              height={'100%'}
              style={{ objectFit: 'contain' }}
            />
          </Box>
          <Stack spacing={1} flex={1}>
            <Typography
              variant={'label3'}
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineBreak: '2',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2
              }}
              data-cy="productName"
            >
              {selectedProduct.name}
            </Typography>
            <Stack direction={'row'} alignItems={'start'} spacing={2}>
              {StoreToIcon[selectedProduct.store]?.({ fontSize: 'mSmall', style: { verticalAlign: 'middle' } })}
              {StoreToFlagIcon[selectedProduct.store]?.({
                fontSize: 'mSmall',
                style: { verticalAlign: 'middle' }
              })}
              <Typography variant={'label3'} color={'grey.A700'}>
                {toCurrencyStringCents(
                  highestProductPriceCents || selectedProduct.priceCents!,
                  STORE_TO_LOCALE[selectedProduct.store]
                )}
              </Typography>
              {!!selectedProduct?.skuId && (
                <>
                  <Typography variant={'label3'} color={'grey.A700'}>
                    ASIN: {selectedProduct.skuId}
                  </Typography>
                </>
              )}
              {!!selectedProduct.ratingSummary?.rating && (
                <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                  <Star fontSize={'mSmall'} color={'warning'} />
                  <Typography variant={'label3'} color={'grey.A700'}>
                    {selectedProduct.ratingSummary.rating}
                  </Typography>
                </Stack>
              )}
              {isParentProduct(selectedProduct) && <Typography variant={'caption2'}>PARENT</Typography>}
            </Stack>
          </Stack>
        </Stack>

        {!!creditsRequired && (
          <Stack
            direction={'row'}
            alignItems={'center'}
            spacing={0.5}
            px={1}
            py={1}
            sx={{
              background: theme => theme.palette.info.lightest,
              borderRadius: '4px'
            }}
          >
            {creditsRequired > 0 ? (
              <>
                <PriceIncreaseIcon />
                <Typography variant={'label3'} color={theme => theme.palette.info.main} data-cy={'priceIncreased-message'}>
                  Your product price increased from your placeholder product.
                </Typography>
              </>
            ) : (
              <>
                <PriceDecreaseIcon />
                <Typography variant={'label3'} color={theme => theme.palette.info.main} data-cy={'priceDecrease-message'}>
                  Your product price decreased from your placeholder product. We will refund you the additional credits!
                </Typography>
              </>
            )}
          </Stack>
        )}
      </Stack>
    </Box>
  )
}