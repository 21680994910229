import useEstimatedDeliverables from '@momentum/routes/proposals-create/modules/useEstimatedDeliverables'
import { ProposalCreateForm } from '@momentum/routes/proposals-create/types'
import { CheckCircle } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { ProposalGoal, PricingCreatorType } from '@productwindtom/shared-momentum-zeus-types'
import { uniq, keyBy } from 'lodash'
import { ReactElement } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

const MIN_WIDTH = 125
const SPACING = 5

export const CampaignImpact = () => {
  const { watch } = useFormContext<ProposalCreateForm>()

  const { isDisplayResults, totalCreators, selectedProduct, locale, markAsDraft, markAsPublished, ...results } =
    useEstimatedDeliverables()

  const formData = watch()
  const keyedCreatorPricing = keyBy(formData.creatorPricing, r => r.type)

  const isMonthly = formData?.goal === ProposalGoal.EVERGREEN

  return (
    <Stack px={2}>
      <Stack direction={'row'} mb={2} spacing={SPACING} justifyContent={'space-between'}>
        <Stack direction={'row'} spacing={1}>
          <CheckCircle color={uniq(results?.estimatedPageOneWins)?.filter(v => v)?.length ? 'success' : 'disabled'} />
          <Stack>
            <Typography variant={'label3'}>Est. organic & paid SEO placement wins</Typography>
            <Typography variant={'body1'} color={'grey.A700'}>
              ProductWind creators drive relevancy to win organic and paid slots on Page 1 of search results.
            </Typography>
          </Stack>
        </Stack>

        <Stack minWidth={MIN_WIDTH} direction={'row'} alignItems={'flex-start'} spacing={2} data-cy="seoWins">
          <MetricsDisplay
            label={'page 1 wins'}
            value={uniq(results?.estimatedPageOneWins)
              ?.filter(v => v)
              ?.map(v => v.toLocaleString())
              .join(' - ')}
            hiddenName={'hiddenSeo'}
          />
        </Stack>
      </Stack>
      {(keyedCreatorPricing[PricingCreatorType.UGC]?.numCreators ||
        keyedCreatorPricing[PricingCreatorType.PREMIUM_UGC]?.numCreators ||
        !totalCreators) && (
        <Stack direction={'row'} mb={2} spacing={SPACING} justifyContent={'space-between'}>
          <Stack direction={'row'} spacing={1}>
            <CheckCircle color={results?.numUgcContent ? 'success' : 'disabled'} />
            <Stack>
              <Typography variant={'label3'}>Unpublished UGC content</Typography>
              <Typography variant={'body1'} color={'grey.A700'}>
                UGC and Premium UGC creators upload videos and photos directly to the Momentum platform
              </Typography>
            </Stack>
          </Stack>
          <Stack minWidth={MIN_WIDTH} direction={'row'} alignItems={'flex-start'} spacing={2} data-cy="ugcContent">
            <MetricsDisplay
              label={'photos & videos'}
              value={results?.numUgcContent}
              hiddenName={'hiddenUgcContent'}
              monthly={isMonthly}
            />
          </Stack>
        </Stack>
      )}
      {(keyedCreatorPricing[PricingCreatorType.UGC]?.numCreators ||
        keyedCreatorPricing[PricingCreatorType.PREMIUM_UGC]?.numCreators ||
        !totalCreators) && (
        <Stack direction={'row'} mb={2} spacing={SPACING} justifyContent={'space-between'}>
          <Stack direction={'row'} spacing={1}>
            <CheckCircle color={results?.numReviews ? 'success' : 'disabled'} />
            <Stack>
              <Typography variant={'label3'}>Est. incremental reviews & ratings</Typography>
              <Typography variant={'body1'} color={'grey.A700'}>
                Momentum campaigns start the retail flywheel and UGC creators drive incremental reviews & ratings.
              </Typography>
            </Stack>
          </Stack>
          <Stack minWidth={MIN_WIDTH} direction={'row'} alignItems={'flex-start'} spacing={2} data-cy="numReviews">
            <MetricsDisplay
              label={'reviews & ratings'}
              value={results?.numReviews}
              hiddenName={'hiddenNumReviews'}
              monthly={isMonthly}
            />
          </Stack>
        </Stack>
      )}
      {!!keyedCreatorPricing[PricingCreatorType.SOCIAL]?.numCreators && (
        <Stack direction={'row'} mb={2} spacing={SPACING} justifyContent={'space-between'}>
          <Stack direction={'row'} spacing={1}>
            <CheckCircle color={results?.numSocialContent ? 'success' : 'disabled'} />
            <Stack>
              <Typography variant={'label3'}>Published social content</Typography>
              <Typography variant={'body1'} color={'grey.A700'}>
                Social creators post videos on Instagram and/or Tiktok.
              </Typography>
            </Stack>
          </Stack>
          <Stack minWidth={MIN_WIDTH} direction={'row'} alignItems={'flex-start'} spacing={2} data-cy="socialContent">
            <MetricsDisplay
              label={'social posts & stories'}
              value={results?.numSocialContent}
              hiddenName={'hiddenSocialContent'}
              monthly={isMonthly}
            />
          </Stack>
        </Stack>
      )}
      {(keyedCreatorPricing[PricingCreatorType.ADVOCATE]?.numCreators || !totalCreators) && (
        <Stack direction={'row'} spacing={SPACING} justifyContent={'space-between'}>
          <Stack direction={'row'} spacing={1}>
            <CheckCircle color={results?.estimatedPdpViews ? 'success' : 'disabled'} />
            <Stack>
              <Typography variant={'label3'}>Est. traffic from ProductWind creators</Typography>
              <Typography variant={'body1'} color={'grey.A700'}>
                Brand Advocates drive traffic to the product detail page.
              </Typography>
            </Stack>
          </Stack>

          <Stack minWidth={MIN_WIDTH} direction={'row'} alignItems={'flex-start'} spacing={2} data-cy="pdpTraffic">
            <MetricsDisplay
              label={'views'}
              value={results?.estimatedPdpViews}
              hiddenName={'hiddenPdpTraffic'}
              monthly={isMonthly}
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}

const MetricsDisplay = ({
  value,
  label,
  hiddenName,
  leadingIcon,
  monthly
}: {
  value?: string | number | ReactElement
  label?: string
  hiddenName: string
  leadingIcon?: ReactElement
  monthly?: boolean
}) => {
  const hidden = useWatch({ name: hiddenName })

  return (
    <Stack>
      <Typography
        variant={'label1'}
        color={theme => (hidden ? theme.palette.grey.A700 : 'black')}
        data-cy="metricValue"
      >
        {leadingIcon}
        {value ? (typeof value === 'number' ? value.toLocaleString() : value) : '--'}{' '}
        {value != null && monthly ? '/mo' : ''}
      </Typography>
      {!!label && (
        <Typography variant={'body1'} color={theme => theme.palette.grey.A700} flexWrap={'wrap'}>
          {label}
        </Typography>
      )}
    </Stack>
  )
}
