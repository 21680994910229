import { isNumber, last } from 'lodash'

export const getCampaignReviewsGoalHit = (
  expectedReviewsCount: number = 0,
  reviewMetrics: {
    fromDate: string
    momentumRatingCount: number
  }[] = []
) => {
  const reviewsAccumulated = (reviewMetrics || []).reduce(
    (acc: { date: string; momentumRatingCount: number }[], r) => [
      ...acc,
      { date: r.fromDate, momentumRatingCount: (last(acc)?.momentumRatingCount || 0) + r.momentumRatingCount }
    ],
    []
  )

  return isNumber(expectedReviewsCount)
    ? reviewsAccumulated.find(r => r.momentumRatingCount >= expectedReviewsCount)?.date
    : undefined
}
