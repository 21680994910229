import { Stack, Typography } from '@mui/material'
import { CreditAction } from '@productwindtom/shared-momentum-zeus-types'
import { SelectInput } from '@productwindtom/ui-base'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { ActionStatus } from './index'

type FilterData = {
  year: number | null
  status: ActionStatus | null
  action: CreditAction | null
}

export const Filters = ({
  availableYears,
  setYear,
  setStatus,
  setAction
}: {
  availableYears: number[],
  year: number | null,
  status: ActionStatus | null,
  action: CreditAction | null,
  setYear: (year: number | null) => void,
  setStatus: (status: ActionStatus | null) => void,
  setAction: (action: CreditAction | null) => void
}) => {

  const yearOptions = [
    {
      label: 'All years (default)',
      value: null
    },
    ...availableYears.map(year => ({
      label: year.toString(),
      value: year
    }))
  ]

  const activityOptions = [
    {
      label: 'All activity (default)',
      value: null
    },
    {
      label: 'Credits Purchased',
      value: CreditAction.ADD
    },
    {
      label: 'Credits Used',
      value: CreditAction.REMOVE
    }
  ]

  const statusOptions = [
    {
      label: 'All statuses (default)',
      value: null
    },
    {
      label: 'Successful transaction',
      value: ActionStatus.SUCCESS
    },
    {
      label: 'Awaiting payment',
      value: ActionStatus.AWAITING_PAYMENT
    },
    {
      label: 'Payment overdue',
      value: ActionStatus.PAYMENT_OVERDUE
    }
  ]

  const methods = useForm<FilterData>({
    mode: 'onChange'
  })
  const { watch } = methods
  const filters = watch()

  useEffect(() => {
    setYear(filters.year ?? null)
    setStatus(filters.status ?? null)
    setAction(filters.action ?? null)
  }, [filters])

  return (
    <Stack direction={'row'} spacing={2}>
      <Typography variant="h4" flexGrow={1} sx={{ display: 'flex', alignItems: 'center' }}>Transactions</Typography>
      <FormProvider {...methods}>
      <Stack direction={'row'} spacing={2} flexGrow={1}>
        <SelectInput
          placeholder="Filter by activity"
          options={activityOptions}
          name={'action'}
          disableClearable
          fullWidth
          sx={{ maxWidth: 250 }}
        />
        <SelectInput
          placeholder="Filter by status"
          options={statusOptions}
          name={'status'}
          disableClearable
          fullWidth
          sx={{ maxWidth: 250 }}
        />
        <SelectInput
          placeholder="Filter by year"
          options={yearOptions}
          name={'year'}
          disableClearable
          fullWidth
          sx={{ maxWidth: 250 }}
        />
      </Stack>
      </FormProvider>
    </Stack>
  )
}

export default Filters
