import { Stack, Typography, Box } from '@mui/material'
import { sumBy } from 'lodash'
import { DateTime } from 'luxon'
import { useCampaignContext } from '../../context/CampaignContext'
import { useSalesContext } from './salesContext'
import CampaignLaunchImage from '/images/campaign-launch.png'
import CampaignEndedImage from '/images/calendar-check.png'
import { useValueFormatter } from './utils'

export const Summary = () => {
  const { campaignDetails } = useCampaignContext()
  const { salesData, salesReportView } = useSalesContext()

  const totalSum = sumBy(salesData, d => d.value)
  const valueFormatter = useValueFormatter()

  return (
    <Stack direction={'row'} flexWrap={'wrap'} gap={2}>
      <Stack
        direction={'row'}
        bgcolor={'white'}
        p={2}
        border={t => `1px solid ${t.palette.grey.A200}`}
        borderRadius={'4px'}
        flexGrow={1}
      >
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <Box width={8} bgcolor={'info.main'} borderRadius={100} height={75} />
          <Stack>
            <Stack direction={'row'} spacing={1}>
              <Typography variant={'h3'} data-cy={`salesSummaryTotal`}>{valueFormatter(totalSum)}</Typography>
            </Stack>
            <Typography variant={'label3'}>{salesReportView}</Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        bgcolor={'white'}
        p={3}
        border={t => `1px solid ${t.palette.grey.A200}`}
        borderRadius={'4px'}
        spacing={2}
      >
        <Stack direction={'row'} spacing={2} alignItems={'flex-start'}>
          <Stack p={0.5} bgcolor={t => t.palette.grey.A100} borderRadius={'50%'}>
            <Box
              component="img"
              src={CampaignLaunchImage}
              width={24}
              height={24}
            />
          </Stack>
          <Stack>
            <Typography variant={'label2'}>Campaign start</Typography>
            <Typography variant={'label3'} color={'grey.A700'}>
              {DateTime.fromISO(campaignDetails.startDate).toFormat('LLL dd, yyyy')}
            </Typography>
          </Stack>
        </Stack>
        {!!campaignDetails.endDate && (
          <Stack direction={'row'} spacing={2} alignItems={'flex-start'}>
            <Stack p={0.5} bgcolor={t => t.palette.grey.A100} borderRadius={'50%'}>
              <Box
                component="img"
                src={CampaignEndedImage}
                width={24}
                height={24}

              />
            </Stack>
            <Stack>
              <Typography variant={'label2'}>Campaign end</Typography>
              <Typography variant={'label3'} color={'grey.A700'}>
                {DateTime.fromISO(campaignDetails.endDate).toFormat('LLL dd, yyyy')}
              </Typography>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}
