import { SvgIcon, SvgIconProps } from '@mui/material'

export default function CalendarCheckOutlineIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16" fill="none">
      <path
        d="M13.1667 2.00002H12.5V0.666687H11.1667V2.00002H5.83333V0.666687H4.5V2.00002H3.83333C3.1 2.00002 2.5 2.60002 2.5 3.33335V12.6667C2.5 13.0203 2.64048 13.3594 2.89052 13.6095C3.14057 13.8595 3.47971 14 3.83333 14H13.1667C13.9067 14 14.5 13.4067 14.5 12.6667V3.33335C14.5 2.97973 14.3595 2.64059 14.1095 2.39054C13.8594 2.1405 13.5203 2.00002 13.1667 2.00002ZM13.1667 12.6667H3.83333V6.00002H13.1667V12.6667ZM3.83333 4.66669V3.33335H13.1667V4.66669H3.83333ZM7.54 11.64L11.5 7.68669L10.7867 6.98002L7.54 10.2267L6.13333 8.82002L5.42667 9.52669L7.54 11.64Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}
