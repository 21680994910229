import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { Product } from '@momentum/routes/brand/types'
import { SkuSelectionType, useCampaignContext, ReportType } from '@momentum/routes/campaign/context/CampaignContext'
import { Form } from '@productwindtom/ui-base'
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react'
import { useFormContext } from 'react-hook-form'
import {
  EtailerSkuMetrics,
  createCampaignBenchmark,
  getBenchmarkMetrics,
  getCampaignBenchmark,
  updateCampaignBenchmark
} from '../queries'
import { BenchmarkType, ProductCreationSource } from '@productwindtom/shared-momentum-zeus-types'
import { toast } from 'react-toastify'
import { Box, Typography } from '@mui/material'
import { GraphMetricsType, MetricGoalType } from '../types'
import Loading from '@momentum/components/loading'
import { DateTime } from 'luxon'
import { map } from 'lodash'
import { captureException } from '@sentry/react'
import { EmptyState } from '../../common/EmptyState'
import EmptyStateImage from '/images/empty-states/benchmark.png'
import { ProposalGoal } from '@productwindtom/shared-momentum-zeus-types'
import { useUserSessionContext } from '@momentum/contexts/UserSession'

const BenchmarkProvider = ({ children }: { children?: React.ReactNode }) => {
  const { campaignDetails, selectedVariants } = useCampaignContext()
  const { products = [], brand } = useBrandContext()
  const { setValue, getValues, watch } = useFormContext<GraphMetricsType>()
  const [graphMetrics, setGraphMetrics] = useState<GraphMetricsType>({
    benchmarkedProductIds: [campaignDetails.skuId],
    metricType: BenchmarkType.UNITS_SOLD,
    metricGoal: 100,
    benchmarkSkuType: SkuSelectionType.SELECTED_VARIATIONS,
    reportType: ReportType.CUMULATIVE
  })
  const [enabledBenchmarkProducts, setEnabledBenchmarkProducts] = useState(graphMetrics?.benchmarkedProductIds || [])
  const [benchmarkData, setBenchmarkData] = useState<{
    [key: string]: EtailerSkuMetrics[]
  }>({})
  const { isAdminView } = useUserSessionContext()
  const metricGoals = useRef<MetricGoalType[]>([{
    metricType: BenchmarkType.UNITS_SOLD,
    metricGoal: 1000,
  }, {
    metricType: BenchmarkType.REVENUE,
    metricGoal: 1000,
  },
  {
    metricType: BenchmarkType.TRAFFIC,
    metricGoal: 1000,
  },
  ])

  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [hasSavedMetrics, setHasSavedMetrics] = useState(false)

  const productId = watch('productId')

  const skuCacheKey = (skuId: string, metricType: BenchmarkType, metricGoal: number, variationIds?: string[], campaignId?: string, limit?: number) => {
    return `benchmark-metrics-${skuId}-${metricType}-${metricGoal}-${JSON.stringify(variationIds)}-${campaignId}-${limit}`
  }

  const filteredProducts = useMemo(
    () =>
      (products || []).filter(
        product =>
          product.id === campaignDetails.skuId ||
          (product.creationSource &&
            [ProductCreationSource.VENDOR_INTEGRATION, ProductCreationSource.SELLER_INTEGRATION].includes(
              product.creationSource
            ) &&
            product.priceCents != null &&
            product?.initialEtailerProductMetric?.date &&
            DateTime.fromISO(product.initialEtailerProductMetric.date).diff(DateTime.now().minus({ years: 2 }), 'days')
              .days > 60)
      ),
    [products, campaignDetails]
  )

  const cachedGetBenchmarkMetrics = async (
    skuId: string,
    metricType: BenchmarkType,
    metricGoal: number,
    variationIds?: string[],
    campaignId?: string,
    limit?: number
  ) => {
    const cacheKey = skuCacheKey(skuId, metricType, metricGoal, variationIds, campaignId, limit)
    const cachedData = sessionStorage.getItem(cacheKey)
    
    if (cachedData) {
      const {data, timestamp} = JSON.parse(cachedData)
      const isExpired = Date.now() - timestamp > 60 * 60 * 1000 // 1 hour
      if (!isExpired) {
        return data
      }
    }
    setIsLoadingData(true)
    const data = await getBenchmarkMetrics(skuId, metricType, metricGoal, variationIds, campaignId, limit)
    sessionStorage.setItem(cacheKey, JSON.stringify({
      data,
      timestamp: Date.now()
    }))
  
    return data
  }
  
  const fetchBenchmarkMetrics = useCallback(async (id: string, limit?: number): Promise<{
    skuId: string,
    data: EtailerSkuMetrics[]
  }> => {
    const skuFromCurrentCampaign = campaignDetails.skuId === id    
    const useSelectedVariants = graphMetrics?.benchmarkSkuType === SkuSelectionType.SELECTED_VARIATIONS &&
      skuFromCurrentCampaign
    
    const goal = graphMetrics.metricType === BenchmarkType.REVENUE ? graphMetrics.metricGoal * 100 : graphMetrics.metricGoal
    const skuMetrics = (await cachedGetBenchmarkMetrics(
      id,
      graphMetrics.metricType, 
      Math.max(goal, 1),
      useSelectedVariants ? selectedVariants : undefined,
      skuFromCurrentCampaign ? campaignDetails.id : undefined,
      limit
    )) || []

    return {
      skuId: id,
      data: skuMetrics.length
        ? [
            {
              date: DateTime.fromISO(skuMetrics[0].date).minus({ days: 1 }).toISO()!,
              skuId: id,
              unitsSold: 0,
              totalSalesAmount: 0,
              pageViews: 0
            },
            ...skuMetrics
          ]
        : []
    }
  }, [campaignDetails, graphMetrics])

  const handleCampaignBenchmarkMetrics = useCallback(
    async () => {
      try {
        const promotedProductMetrics = await fetchBenchmarkMetrics(campaignDetails.skuId)
        const otherProductMetrics = graphMetrics.benchmarkedProductIds.filter(id => id !== campaignDetails.skuId)
        const benchmarkMetrics = await Promise.all(otherProductMetrics.map(async id => {
          return await fetchBenchmarkMetrics(id, promotedProductMetrics.data.length)
        }))

        const metricsData = [promotedProductMetrics, ...benchmarkMetrics].reduce(
          (acc, item) => {
            return {
              ...acc,
              [item.skuId]: item.data
            }
          },
          {} as {
            [key: string]: EtailerSkuMetrics[]
          }
        )

        setBenchmarkData(metricsData)
        setIsLoadingData(false)
      } catch (err) {
        console.error(err)
        captureException(err)
        toast(<Typography variant={'subtitle2'}>An error has occurred, please try again later!</Typography>, {
          type: 'error'
        })
      }
    },
    [campaignDetails, graphMetrics]
  )

  useEffect(() => {
    const newEnabledBenchmarkProducts = enabledBenchmarkProducts.filter(
      id => graphMetrics?.benchmarkedProductIds?.includes(id)
    ) || [productId]
    
    handleCampaignBenchmarkMetrics()
    setEnabledBenchmarkProducts(newEnabledBenchmarkProducts)
  }, [graphMetrics?.benchmarkedProductIds])

  useEffect(() => {
    const updatedMetricGoals = metricGoals.current.map(goal => {
      if (goal.metricType === graphMetrics?.metricType) {
        return { ...goal, metricGoal: graphMetrics.metricGoal }
      }
      return goal
    })
    metricGoals.current = updatedMetricGoals
    handleCampaignBenchmarkMetrics()
  }, [graphMetrics?.metricGoal])

  useEffect(() => {
    const goal = metricGoals.current.find(goal => goal.metricType === graphMetrics?.metricType)?.metricGoal || 1000
    const currentMetricGoal = graphMetrics?.metricGoal
    setGraphMetrics({...graphMetrics, metricGoal: goal})
    if (currentMetricGoal === goal) {
      handleCampaignBenchmarkMetrics()
    }
  }, [graphMetrics?.metricType])

  useEffect(() => {
    if (filteredProducts.length) {
      ;(async () => {
        try {
          const campaignBenchmark = await getCampaignBenchmark(campaignDetails.id, campaignDetails.skuId)

          if (campaignBenchmark) {
            const { benchmarkedProductIds, benchmarkGoal, benchmarkType } = campaignBenchmark
            const productIds = map(filteredProducts, 'id')

            const filteredBenchmarkProductIds = benchmarkedProductIds.filter(id => productIds?.includes(id))
            setGraphMetrics({
              metricType: benchmarkType,
              metricGoal: benchmarkGoal,
              benchmarkedProductIds: filteredBenchmarkProductIds,
              benchmarkSkuType: SkuSelectionType.SELECTED_VARIATIONS,
              reportType: ReportType.CUMULATIVE
            })
            setEnabledBenchmarkProducts(filteredBenchmarkProductIds)

            setHasSavedMetrics(true)
          }
          handleCampaignBenchmarkMetrics()
        } catch (err) {
          console.error(err)
          captureException(err)
          toast(<Typography variant={'subtitle2'}>An error has occurred, please try again later!</Typography>, {
            type: 'error'
          })
        } finally {
          setIsLoading(false)
        }
      })()
    }
  }, [campaignDetails, filteredProducts])

  useEffect(() => {
    if (productId) {
      const benchmarkedIds = getValues().benchmarkedProductIds
      setValue('productId', '')
      setValue('benchmarkedProductIds', [...benchmarkedIds, productId])
    }
  }, [productId, setValue, getValues])

  const { nonBenchmarkedProducts, benchmarkedProducts } = useMemo(() => {
    const benchmarkedProductIds = graphMetrics?.benchmarkedProductIds || []
    const { nonBenchmarkedProducts, benchmarkedProducts } = filteredProducts.reduce(
      (acc, product) => {
        const isBenchmarked = benchmarkedProductIds.includes(product.id)

        isBenchmarked ? acc.benchmarkedProducts.push(product) : acc.nonBenchmarkedProducts.push(product)

        return acc
      },
      {
        nonBenchmarkedProducts: [] as Product[],
        benchmarkedProducts: [] as Product[]
      }
    )

    return {
      nonBenchmarkedProducts,
      benchmarkedProducts: benchmarkedProducts.sort(
        (a, b) => benchmarkedProductIds.indexOf(a.id) - benchmarkedProductIds.indexOf(b.id)
      )
    }
  }, [graphMetrics?.benchmarkedProductIds, filteredProducts])


  useEffect(() => {
    try {
      if (!isLoading) {
        const updateOperation = hasSavedMetrics ? updateCampaignBenchmark : createCampaignBenchmark
        updateOperation({
          campaignId: campaignDetails.id,
          skuId: campaignDetails.skuId,
          benchmarkType: graphMetrics.metricType,
          benchmarkGoal: graphMetrics.metricGoal,
          benchmarkedProductIds: graphMetrics.benchmarkedProductIds
        })

        setHasSavedMetrics(true)
      }
    } catch (err) {
      console.error(err)
      captureException(err)
      toast(<Typography variant={'subtitle2'}>An error has occurred, please try again later!</Typography>, {
        type: 'error'
      })
    }
  }, [graphMetrics, campaignDetails.id, campaignDetails.skuId])

  if (isLoading) {
    return (
      <Box mt={10}>
        <Loading />
      </Box>
    )
  }

  const calculateEmptyState = () => {
    const nonAdmin = !isAdminView
    const notIntegrated = !brand.brandApis.find(api => api.enabled && api.refreshToken)
    const notProductLaunch = campaignDetails.goal !== ProposalGoal.PRODUCT_LAUNCH
    const noBenchmarkData = benchmarkData[campaignDetails.skuId]?.length === 0
    const lessThan3Weeks = DateTime.now().diff(DateTime.fromISO(campaignDetails?.startDate), 'weeks').weeks < 3

    if (notIntegrated) {
      return {
        title: 'Your sales and traffic benchmarking analytics will live here',
        description: 'Link your Amazon Seller account to access benchmarking data.',
        linkAccountButton: true
      }
    } else if (notProductLaunch) {
      return {
        title: 'Sales and traffic benchmarking data is not <br> available for this campaign type.',
        description: 'Benchmarking data is only available for <br> Product Launch campaigns.',
      }
    } else if (noBenchmarkData) {
      return {
        title: 'Your product has no sales <br> benchmarking data available.',
        description: 'This product has no benchmarking data available. <br> This could occur if the product listing was variated.',
      }
    } else if (nonAdmin && lessThan3Weeks) {
      return {
        title: 'Your sales and traffic benchmarking analytics <br> will live here.',
        description: 'Benchmarking data is typically available 3-4 weeks after campaign launch.',
      }
    }
  }
  const emptyState = calculateEmptyState()
  
  if (emptyState) {
    return (
      <EmptyState
        image={EmptyStateImage}
        title={emptyState.title}
        description={emptyState.description}
        linkAccountButton={emptyState.linkAccountButton}
      />
    )
  }

  return (
    <BenchmarkContext.Provider
      value={{
        benchmarkData,
        graphMetrics,
        enabledBenchmarkProducts,
        nonBenchmarkedProducts,
        benchmarkedProducts,
        promotedProductId: campaignDetails.skuId,
        setEnabledBenchmarkProducts,
        setGraphMetrics,
        isLoadingData,
        setIsLoadingData,
        handleCampaignBenchmarkMetrics
      }}
    >
      {children}
    </BenchmarkContext.Provider>
  )
}

export default ({ children }: { children?: React.ReactNode }) => {
  const { campaignDetails } = useCampaignContext()

  return (
    <Form
      onSubmit={() => null}
      defaultValues={{
        benchmarkedProductIds: [campaignDetails.skuId],
        enabledBenchmarkProducts: [campaignDetails.skuId],
        metricType: BenchmarkType.UNITS_SOLD,
        metricGoal: 1000
      }}
    >
      <BenchmarkProvider>{children}</BenchmarkProvider>
    </Form>
  )
}

type BenchmarkContextType = {
  nonBenchmarkedProducts: Product[]
  benchmarkedProducts?: Product[]
  promotedProductId: string
  benchmarkData: {
    [key: string]: EtailerSkuMetrics[]
  }
  graphMetrics?: GraphMetricsType
  enabledBenchmarkProducts: string[]
  setEnabledBenchmarkProducts: (products: string[]) => void
  setGraphMetrics: (metrics: GraphMetricsType) => void,
  isLoadingData: boolean,
  setIsLoadingData: (isLoading: boolean) => void,
  handleCampaignBenchmarkMetrics: () => void
}

const BenchmarkContext = createContext<BenchmarkContextType>({} as BenchmarkContextType)
export const useBenchmarkContext = () => useContext(BenchmarkContext)
