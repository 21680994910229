import { yupResolver } from '@hookform/resolvers/yup'
import { Question } from '@momentum/components/proposal-common/Question'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { updateCompany } from '@momentum/routes/company/queries'
import { Company } from '@momentum/routes/queries'
import { Typography, Stack, Chip, Box, Grid } from '@mui/material'
import { Form, SubmitButton, TextInput } from '@productwindtom/ui-base'
import { captureException } from '@sentry/react'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { UseFormReturn } from 'react-hook-form'

const schema = yup.object().shape({
  paymentBillingContactEmail: yup.string().required('Required').email('Invalid email'),
  paymentBillingContactId: yup.string().required('Required')
})

type BillingFormInput = {
  paymentBillingContactEmail: string
  paymentBillingContactId: string
}
export const CompanyBilling = ({ company }: { company: Company }) => {
  const { refreshCompany } = useUserSessionContext()

  const handleSubmit = async (submitValues: BillingFormInput, methods: UseFormReturn<BillingFormInput>) => {
    try {
      await updateCompany({
        id: company.id,
        ...submitValues
      })

      await refreshCompany(company.id)
      toast(<Typography variant={'subtitle2'}>Company billing settings saved!</Typography>, { type: 'success' })
      methods.reset(submitValues)
    } catch (error) {
      captureException(error)
      toast(<Typography variant={'subtitle2'}>Failed to save company billing settings!</Typography>, {
        type: 'error'
      })
    }
  }

  const defaultValues: BillingFormInput = {
    paymentBillingContactEmail: company.paymentBillingContactEmail || '',
    paymentBillingContactId: company.paymentBillingContactId || ''
  }

  return (
    <Form onSubmit={handleSubmit} defaultValues={defaultValues} resolver={yupResolver(schema)}>
      <FormBody />
    </Form>
  )
}

const FormBody = () => {
  return (
    <Stack spacing={2}>
      <Stack direction={'row'}>
        <Typography variant={'h4'} mr={1}>
          Company billing
        </Typography>
        <Chip label={'Admin'} size="small" color="secondary" />
      </Stack>
      <Stack border={1} borderRadius={'4px'} borderColor={t => t.palette.grey.A200} p={3} spacing={2}>
        <Question
          primaryText={'Contact responsible for paying the bill'}
          subtext={
            'Add the primary billing contact for your Bill.com account. Invoices will be emailed to this contact.'
          }
        >
          <Grid container direction={'row'} spacing={2}>
            <Grid item xs={6}>
              <Stack spacing={1} display={'inline'}>
                <Typography variant={'label3'}>Billing email</Typography>
                <TextInput name={'paymentBillingContactEmail'} fullWidth placeholder={'Enter Bill.com billing email'} />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack spacing={1} display={'inline'}>
                <Typography variant={'label3'}>Customer ID in Bill.com</Typography>
                <TextInput name={'paymentBillingContactId'} fullWidth placeholder={'Enter customer ID'} />
              </Stack>
            </Grid>
          </Grid>
        </Question>
        <Box>
          <SubmitButton variant={'contained'}>Save company billing</SubmitButton>
        </Box>
      </Stack>
    </Stack>
  )
}
