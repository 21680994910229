import { CampaignProductReviewRatingMetric } from '@momentum/routes/campaign/context/queries'
import { DateTime } from 'luxon'

export const SAMPLE_CAMPAIGN_REVIEW_METRICS_LAUNDRY: Array<CampaignProductReviewRatingMetric> = [
  {
    fromDate: '2024-03-21',
    toDate: '2024-03-22',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-22',
    toDate: '2024-03-23',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-23',
    toDate: '2024-03-24',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-24',
    toDate: '2024-03-25',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-25',
    toDate: '2024-03-26',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-26',
    toDate: '2024-03-27',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-27',
    toDate: '2024-03-28',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-28',
    toDate: '2024-03-29',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-29',
    toDate: '2024-03-30',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-30',
    toDate: '2024-03-31',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-31',
    toDate: '2024-04-01',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-04-01',
    toDate: '2024-04-02',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-04-02',
    toDate: '2024-04-03',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-04-03',
    toDate: '2024-04-04',
    numRatings: 1,
    numReviews: 1,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 5,
    momentumRating: 0,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-04-04',
    toDate: '2024-04-05',
    numRatings: 4,
    numReviews: 4,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 1,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 2,
    reviewsInPeriod: 3,
    reviews: [],
    rating: 5,
    momentumRating: 0,
    organicRating: 5,
    vineRating: 5
  },
  {
    fromDate: '2024-04-05',
    toDate: '2024-04-06',
    numRatings: 4,
    numReviews: 4,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 5,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-04-06',
    toDate: '2024-04-07',
    numRatings: 8,
    numReviews: 8,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 1,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 2,
    reviewsInPeriod: 4,
    reviews: [],
    rating: 5,
    momentumRating: 4.8,
    organicRating: 5,
    vineRating: 5
  },
  {
    fromDate: '2024-04-07',
    toDate: '2024-04-08',
    numRatings: 10,
    numReviews: 10,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 5,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-04-08',
    toDate: '2024-04-09',
    numRatings: 10,
    numReviews: 10,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 5,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-04-09',
    toDate: '2024-04-10',
    numRatings: 11,
    numReviews: 11,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 5,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-04-10',
    toDate: '2024-04-11',
    numRatings: 11,
    numReviews: 11,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 5,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-04-11',
    toDate: '2024-04-12',
    numRatings: 12,
    numReviews: 12,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 5,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-04-12',
    toDate: '2024-04-13',
    numRatings: 15,
    numReviews: 15,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 3,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 3,
    reviews: [],
    rating: 5,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-04-13',
    toDate: '2024-04-14',
    numRatings: 18,
    numReviews: 17,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.9,
    organicRatingCount: 1,
    reviewsInPeriod: 2,
    reviews: [
      {
        title: 'This is a 3 in one product!!! Cleans, lifts stains, and the scent is amazing!',
        text: 'I love that you don’t need any added laundry products. This detergent fights hard stains (no need for extra stain removers) and it smells AMAZING (no need for scent boosting beads)!!! Worth it 😁',
        name: 'Emily Hilton',
        rating: 5,
        attributedUserId: '5eefd8e6-33e6-414c-aa53-a1eacbe8ed84',
        reviewedAt: '2024-04-15T00:00:00.000Z'
      }
    ],
    rating: 5,
    momentumRating: 4.9,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-04-14',
    toDate: '2024-04-15',
    numRatings: 18,
    numReviews: 17,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 5,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-04-15',
    toDate: '2024-04-16',
    numRatings: 20,
    numReviews: 19,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 1,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 0,
    reviewsInPeriod: 2,
    reviews: [
      {
        title: 'Say good goodbye to pre-treating stains',
        text: 'I really love this product my favorite thing about the product other than the fact that it cleans my clothes really well is the smell. I absolutely love it and I truly appreciate that I do not have to pre-treat my stains prior to washing',
        name: 'Delicious Vinyl',
        rating: 5,
        attributedUserId: '5964eedf-9271-4d13-9d88-07aa0fe89348',
        reviewedAt: '2024-04-15T00:00:00.000Z'
      }
    ],
    rating: 5,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 5
  },
  {
    fromDate: '2024-04-16',
    toDate: '2024-04-17',
    numRatings: 26,
    numReviews: 25,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 6,
    momentumReviewRating: 4.866666666666667,
    organicRatingCount: 0,
    reviewsInPeriod: 6,
    reviews: [
      {
        title: 'Fresh and clean smell',
        text: 'We love the fresh scent without being overbearing.',
        name: 'Brittany',
        rating: 5,
        attributedUserId: '687309d9-cb89-414b-8eac-75967eabb762',
        reviewedAt: '2024-04-16T00:00:00.000Z'
      },
      {
        title: 'We LOVE ARM & HAMMER',
        text: 'We love this detergent! We were using another brand and it didn’t leave my husbands work clothes smelling FRESH. They still smelt musty. He wears his work clothes for 10-12hrs a day. He came home yesterday and asked me to re wash his entire wardrobe in this detergent because it smells THAT GOOD and actually gets them CLEAN!',
        name: 'Desiree Shaw',
        rating: 5,
        attributedUserId: '74354052-6b57-4486-997f-22ccd0709b3e',
        reviewedAt: '2024-04-16T00:00:00.000Z'
      }
    ],
    rating: 5,
    momentumRating: 4.866666666666667,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-04-17',
    toDate: '2024-04-18',
    numRatings: 30,
    numReviews: 28,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 1,
    momentumReviewCount: 2,
    momentumReviewRating: 4.9,
    organicRatingCount: 1,
    reviewsInPeriod: 3,
    reviews: [
      {
        title: 'Smells great',
        text: 'Smells great and gets all the dirt out!',
        name: 'Lyndsey',
        rating: 5,
        attributedUserId: 'b02041fd-313c-493c-bfb1-ca1670a6b71a',
        reviewedAt: '2024-04-18T00:00:00.000Z'
      }
    ],
    rating: 5,
    momentumRating: 4.9,
    organicRating: 5,
    vineRating: 5
  },
  {
    fromDate: '2024-04-18',
    toDate: '2024-04-19',
    numRatings: 31,
    numReviews: 28,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 5,
    momentumRating: 0,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-04-19',
    toDate: '2024-04-20',
    numRatings: 33,
    numReviews: 31,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.9,
    organicRatingCount: 0,
    reviewsInPeriod: 2,
    reviews: [
      {
        title: 'WOW PERFECT FOR A SWEATY TEEN LIKE ME',
        text: 'I usually play outside and play video games, but my clothes usually start to stink even when I have deodorant and cologne on. My skin usually has bad rashes when I use other deodorant , so I have to use natural deodorant that doesn’t last as Long and doesn’t stop my sweat. So I usually have to use strong detergent but that still kinda doesn’t work, but this does!',
        name: 'Linda B',
        rating: 5,
        attributedUserId: 'f98b9887-1cbc-4b60-841b-e4b79b7afc07',
        reviewedAt: '2024-04-20T00:00:00.000Z'
      }
    ],
    rating: 5,
    momentumRating: 4.9,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-04-20',
    toDate: '2024-04-21',
    numRatings: 34,
    numReviews: 32,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [
      {
        title: 'Great product',
        text: 'i find that i’m able to wash my clothes very efficiently as in cleans very well and leaves a great smell behind',
        name: 'SAAR',
        rating: 5,
        attributedUserId: 'b5cb4ba9-815f-47f7-8787-98c8520d206a',
        reviewedAt: '2024-04-21T00:00:00.000Z'
      }
    ],
    rating: 5,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-04-21',
    toDate: '2024-04-22',
    numRatings: 39,
    numReviews: 36,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 4,
    momentumReviewRating: 4.95,
    organicRatingCount: 1,
    reviewsInPeriod: 4,
    reviews: [
      {
        title: 'Amazing clean smell',
        text: 'A little goes a long way for cleanliness and smell.',
        name: 'Gregorio Rangel',
        rating: 5,
        attributedUserId: 'aceac183-867b-43de-a4ca-85c1d532e5e9',
        reviewedAt: '2024-04-22T00:00:00.000Z'
      },
      {
        title: 'smelling good!',
        text: 'I loved this detergent! It leaves the clothes smelling really good. It deep cleans the stains and and orders the clothes has. I don’t add fabric softer and it still leaves the clothes soft and smelling good!',
        name: 'Alicia R.',
        rating: 5,
        attributedUserId: '837cc89d-0a9f-4428-8cf5-45ad66f6e774',
        reviewedAt: '2024-04-22T00:00:00.000Z'
      },
      {
        title: 'This is great!',
        text: 'I’ve been using Armen Hammer for years and I love the deep queen formula. The smell is great and it gets my clothes, nice and clean and I’ve even noticed it taking some of my stains out.',
        name: 'California Belles',
        rating: 5,
        attributedUserId: '1f05f884-773b-48d5-b766-08bb06a42be3',
        reviewedAt: '2024-04-22T00:00:00.000Z'
      }
    ],
    rating: 5,
    momentumRating: 4.95,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-04-22',
    toDate: '2024-04-23',
    numRatings: 44,
    numReviews: 36,
    ratingsInPeriod: 5,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 5,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 5,
    momentumRating: 0,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-04-23',
    toDate: '2024-04-24',
    numRatings: 49,
    numReviews: 46,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 5,
    momentumReviewRating: 4.92,
    organicRatingCount: 0,
    reviewsInPeriod: 5,
    reviews: [
      {
        title: 'My new favorite detergent',
        text: 'I love how powerful this detergent is! It really does remove those tough and stubborn stains! I also really love the scent!',
        name: 'Denise Ferreira',
        rating: 5,
        attributedUserId: '64a80d6a-9f09-4c5d-ab07-9ddb51765fb2',
        reviewedAt: '2024-04-23T00:00:00.000Z'
      },
      {
        title: 'So good',
        text: 'Smells amazing',
        name: 'Benton',
        rating: 5,
        attributedUserId: '655ad360-6a35-41df-8bbe-33ff400ea93a',
        reviewedAt: '2024-04-24T00:00:00.000Z'
      },
      {
        title: 'Good stuff!',
        text: 'Love that it has the stain remover portion built in. Clothes were fresh and stain free!',
        name: 'survivalofthesahm',
        rating: 5,
        attributedUserId: '19cffb09-f8b9-480c-afcb-5ebe19aa6724',
        reviewedAt: '2024-04-24T00:00:00.000Z'
      }
    ],
    rating: 5,
    momentumRating: 4.92,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-04-24',
    toDate: '2024-04-25',
    numRatings: 55,
    numReviews: 49,
    ratingsInPeriod: 3,
    momentumRatingCount: 3,
    vineReviewCount: 0,
    momentumReviewCount: 3,
    momentumReviewRating: 4.866666666666667,
    organicRatingCount: 3,
    reviewsInPeriod: 3,
    reviews: [
      {
        title: 'Deep clean',
        text: "I recently tried ARM & HAMMER's Deep Clean Stain Formula Liquid Laundry Detergent, and I couldn't be more impressed! This product truly lives up to its promises. Not only does it effectively remove stubborn stains, but it also leaves my clothes smelling fresh and clean after every wash. I love that a little goes a long way, making it incredibly economical. Plus, the 102 fl oz size means I don't have to worry about running out anytime soon. Overall, I highly recommend ARM & HAMMER's Deep Clean Stain Formula to anyone looking for a powerful and reliable laundry detergent that delivers outstanding results every time. I highly recommend",
        name: 'QuelStylz',
        rating: 5,
        attributedUserId: 'f6c61221-06e6-447c-a973-f44547735e56',
        reviewedAt: '2024-04-25T00:00:00.000Z'
      }
    ],
    rating: 4.9,
    momentumRating: 4.866666666666667,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-04-25',
    toDate: '2024-04-26',
    numRatings: 60,
    numReviews: 53,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 4,
    momentumReviewRating: 4.8500000000000005,
    organicRatingCount: 1,
    reviewsInPeriod: 4,
    reviews: [
      {
        title: 'I LOVE THE FRESH CLEAN SCENT! Gets Out Stains!',
        text: 'I absolutely love this Arm & Hammer Deep Clean Stain Remover Liquid Laundry Detergent! I literally washed and rewashed everything in my house so it could smell GOOD & FRESH once I got it! This is a game changer for me! It works on towels, sheets, jeans, workout clothes, as well as my intimates and delicates! I’m so impressed!',
        name: 'Synolve Craft',
        rating: 5,
        attributedUserId: '34ac193a-3750-4286-80af-b1a5d40a7d64',
        reviewedAt: '2024-04-26T00:00:00.000Z'
      }
    ],
    rating: 4.9,
    momentumRating: 4.8500000000000005,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-04-26',
    toDate: '2024-04-27',
    numRatings: 64,
    numReviews: 57,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 1,
    momentumReviewCount: 3,
    momentumReviewRating: 4.866666666666667,
    organicRatingCount: 0,
    reviewsInPeriod: 4,
    reviews: [
      {
        title: 'Smells fresh!',
        text: 'Nice scent and cleans tough stains',
        name: 'Jhofa',
        rating: 5,
        attributedUserId: 'cd6c427c-9e17-4701-b79b-3d5444fe105c',
        reviewedAt: '2024-04-27T00:00:00.000Z'
      }
    ],
    rating: 4.9,
    momentumRating: 4.866666666666667,
    organicRating: 0,
    vineRating: 5
  },
  {
    fromDate: '2024-04-27',
    toDate: '2024-04-28',
    numRatings: 68,
    numReviews: 61,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 4,
    momentumReviewRating: 4.9,
    organicRatingCount: 0,
    reviewsInPeriod: 4,
    reviews: [
      {
        title: 'Wash good',
        text: 'It smells good on clothes and it do wash clean',
        name: 'Patrick',
        rating: 5,
        attributedUserId: 'd68317c9-473e-44de-bf32-01c0e5bf5ab1',
        reviewedAt: '2024-04-28T00:00:00.000Z'
      },
      {
        title: 'The best for getting out tough stains',
        text: 'I loved how this product got out my sons grass soccer stains! It’s my go to product now',
        name: 'Savannah Billingsley',
        rating: 5,
        attributedUserId: '3d41d6bc-28ff-4c89-8111-1b06551bada6',
        reviewedAt: '2024-04-28T00:00:00.000Z'
      }
    ],
    rating: 4.9,
    momentumRating: 4.9,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-04-28',
    toDate: '2024-04-29',
    numRatings: 72,
    numReviews: 64,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 1,
    momentumReviewCount: 2,
    momentumReviewRating: 4.8,
    organicRatingCount: 1,
    reviewsInPeriod: 3,
    reviews: [],
    rating: 4.9,
    momentumRating: 4.8,
    organicRating: 5,
    vineRating: 5
  },
  {
    fromDate: '2024-04-29',
    toDate: '2024-04-30',
    numRatings: 78,
    numReviews: 71,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 6,
    momentumReviewRating: 4.866666666666667,
    organicRatingCount: 0,
    reviewsInPeriod: 6,
    reviews: [
      {
        title: 'The deep clean I needed!',
        text: 'Gets the job done when fighting deep stains left behind by my toddler and dog! Leaves clothes stain free and smelling so fresh. Very pleased with this product.',
        name: 'Amelia',
        rating: 5,
        attributedUserId: '692f5a0e-ad8b-44cf-a218-d9ef5abe55b4',
        reviewedAt: '2024-04-30T00:00:00.000Z'
      },
      {
        title: 'smells so good!',
        text: 'love the smell of this! so fresh',
        name: 'Lindsey 🤍',
        rating: 5,
        attributedUserId: '5b113ddb-2719-4c08-a53f-012c4f8258bc',
        reviewedAt: '2024-04-30T00:00:00.000Z'
      }
    ],
    rating: 4.9,
    momentumRating: 4.866666666666667,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-04-30',
    toDate: '2024-05-01',
    numRatings: 85,
    numReviews: 77,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 6,
    momentumReviewRating: 4.8,
    organicRatingCount: 1,
    reviewsInPeriod: 6,
    reviews: [],
    rating: 4.9,
    momentumRating: 4.8,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-05-01',
    toDate: '2024-05-02',
    numRatings: 90,
    numReviews: 81,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 4,
    momentumReviewRating: 4.65,
    organicRatingCount: 1,
    reviewsInPeriod: 4,
    reviews: [
      {
        title: 'Long lasting',
        text: 'Long lasting fresh and clean scent',
        name: 'ESTHER A. JULES',
        rating: 5,
        attributedUserId: '2f8f19c5-6d6e-455c-96ec-73b59b8f63dd',
        reviewedAt: '2024-05-02T00:00:00.000Z'
      },
      {
        title: 'Detergente líquido con gran poder de limpieza',
        text: 'Cuando use por primera vez este jabón dude de su capacidad de limpieza pero se ha vuelto mi favorito por qué además de dejar la ropa limpia la deja con un agradable aroma a frescura que dura por días y las telas son suaves al contacto',
        name: 'Sandra Luz ordoñez',
        rating: 4,
        attributedUserId: '13388d6f-9090-44e4-809d-7620ff271213',
        reviewedAt: '2024-05-02T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 4.65,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-05-02',
    toDate: '2024-05-03',
    numRatings: 98,
    numReviews: 91,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 8,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 8,
    reviews: [],
    rating: 4.8,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-05-03',
    toDate: '2024-05-04',
    numRatings: 103,
    numReviews: 93,
    ratingsInPeriod: 3,
    momentumRatingCount: 3,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.8,
    organicRatingCount: 3,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4.8,
    momentumRating: 4.8,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-05-04',
    toDate: '2024-05-05',
    numRatings: 106,
    numReviews: 95,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.9,
    organicRatingCount: 1,
    reviewsInPeriod: 2,
    reviews: [
      {
        title: 'Love',
        text: 'One of the better laundry detergents out there. Really like the deep clean and stain formula. I have 2 little mess makers at home and they’re always getting their clothes stained.',
        name: 'Yasmina',
        rating: 5,
        attributedUserId: '5853065d-f6d3-4832-b941-1623ebc8b742',
        reviewedAt: '2024-05-05T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 4.9,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-05-05',
    toDate: '2024-05-06',
    numRatings: 110,
    numReviews: 98,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 3,
    momentumReviewRating: 4.533333333333334,
    organicRatingCount: 1,
    reviewsInPeriod: 3,
    reviews: [
      {
        title: 'Smells good',
        text: 'Smells so good but came with soap spilt',
        name: 'Emily',
        rating: 4,
        attributedUserId: '9916d8d3-6feb-4d3a-88ed-a6d5e778ddf3',
        reviewedAt: '2024-05-06T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 4.533333333333334,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-05-06',
    toDate: '2024-05-07',
    numRatings: 117,
    numReviews: 98,
    ratingsInPeriod: 7,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 7,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-05-07',
    toDate: '2024-05-08',
    numRatings: 121,
    numReviews: 108,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 4,
    momentumReviewRating: 4.9,
    organicRatingCount: 0,
    reviewsInPeriod: 4,
    reviews: [
      {
        title: 'This actually removes stains!',
        text: 'This detergent actually removes stains off clothes. You don’t need any other products to get stains out, just through in your clothes and add some of this into the washer!',
        name: 'Ashley',
        rating: 5,
        attributedUserId: '3787fe37-bfcd-44b8-ac15-f5e3c4984551',
        reviewedAt: '2024-05-08T00:00:00.000Z'
      },
      {
        title: 'Clean !',
        text: 'I love the clean feeling !',
        name: 'Alyssa Omar-Perez',
        rating: 5,
        attributedUserId: 'f65707ec-ee03-4bda-a9ae-8e2d51fd8a4e',
        reviewedAt: '2024-05-08T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 4.9,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-05-08',
    toDate: '2024-05-09',
    numRatings: 127,
    numReviews: 111,
    ratingsInPeriod: 3,
    momentumRatingCount: 3,
    vineReviewCount: 0,
    momentumReviewCount: 3,
    momentumReviewRating: 4.8,
    organicRatingCount: 3,
    reviewsInPeriod: 3,
    reviews: [],
    rating: 4.8,
    momentumRating: 4.8,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-05-09',
    toDate: '2024-05-10',
    numRatings: 132,
    numReviews: 114,
    ratingsInPeriod: 2,
    momentumRatingCount: 1,
    vineReviewCount: 1,
    momentumReviewCount: 2,
    momentumReviewRating: 4.9,
    organicRatingCount: 2,
    reviewsInPeriod: 3,
    reviews: [
      {
        title: 'Smells great and fights stains',
        text: 'It leaves the best fresh scent on my clothes!',
        name: 'Gunther Da Great',
        rating: 5,
        attributedUserId: 'a229d932-0bdd-4a50-badf-dd3acc07e9cb',
        reviewedAt: '2024-05-10T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 4.9,
    organicRating: 5,
    vineRating: 5
  },
  {
    fromDate: '2024-05-10',
    toDate: '2024-05-11',
    numRatings: 137,
    numReviews: 119,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 5,
    momentumReviewRating: 4.840000000000001,
    organicRatingCount: 0,
    reviewsInPeriod: 5,
    reviews: [
      {
        title: 'Smells great',
        text: 'Love the smell',
        name: 'Ashley Ramos',
        rating: 5,
        attributedUserId: 'e0562f18-292d-48a5-a6a9-592955bc671a',
        reviewedAt: '2024-05-11T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 4.840000000000001,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-05-11',
    toDate: '2024-05-12',
    numRatings: 144,
    numReviews: 125,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 6,
    momentumReviewRating: 4.733333333333333,
    organicRatingCount: 1,
    reviewsInPeriod: 6,
    reviews: [
      {
        title: 'Go to',
        text: 'I absolutely love! I have two toddlers and 2 dogs so having something that works well is a must! Love the fresh scent!',
        name: 'Andreia Shlepr',
        rating: 5,
        attributedUserId: '2d47c04c-e501-4992-8ba3-fc66081dd3a2',
        reviewedAt: '2024-05-12T00:00:00.000Z'
      },
      {
        title: 'Great for kids clothes',
        text: 'I was gifted this product. It works really well on my kid’s school clothing.',
        name: 'Katie Ann Ensminger',
        rating: 4,
        attributedUserId: '98264f43-3f54-461d-b453-5524d774920e',
        reviewedAt: '2024-05-12T00:00:00.000Z'
      },
      {
        title: 'Smells amazing',
        text: 'The fragrance is amazing and gets the laundry very clean',
        name: 'Makayla Reid',
        rating: 5,
        attributedUserId: '02ff1fde-e67c-4613-8bef-1745ca77b0d1',
        reviewedAt: '2024-05-12T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 4.733333333333333,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-05-12',
    toDate: '2024-05-13',
    numRatings: 153,
    numReviews: 129,
    ratingsInPeriod: 5,
    momentumRatingCount: 5,
    vineReviewCount: 0,
    momentumReviewCount: 4,
    momentumReviewRating: 4.9,
    organicRatingCount: 5,
    reviewsInPeriod: 4,
    reviews: [
      {
        title: 'Great product',
        text: 'Cleans my laundry and leaves a great smell!',
        name: 'Dominique Payne',
        rating: 5,
        attributedUserId: 'fa402802-3275-4ce6-bdd1-84e368e2d4e1',
        reviewedAt: '2024-05-13T00:00:00.000Z'
      },
      {
        title: 'Arm & Hammer Deep Clean',
        text: 'Hands down the best laundry detergent!',
        name: 'Ariel White',
        rating: 5,
        attributedUserId: '55ed0f31-1432-4855-9f30-b903dc8f414b',
        reviewedAt: '2024-05-13T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 4.9,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-05-13',
    toDate: '2024-05-14',
    numRatings: 157,
    numReviews: 133,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 4,
    momentumReviewRating: 4.95,
    organicRatingCount: 0,
    reviewsInPeriod: 4,
    reviews: [
      {
        title: 'MY FAVORITE DETERGENT',
        text: 'The scent is just amazing, I just love this detergent overall! It takes the diet right out of my clothes!',
        name: 'Taylere Ernest',
        rating: 5,
        attributedUserId: 'fadea699-0c75-424c-9706-36cd4d12c76d',
        reviewedAt: '2024-05-14T00:00:00.000Z'
      },
      {
        title: 'Best smell ever!',
        text: 'Love this product because it makes my clothes so soft and the smell oh God it’s so nice!',
        name: 'Teresa',
        rating: 5,
        attributedUserId: '1a6934ea-dc07-4c56-83d2-8d16fb3b0bf5',
        reviewedAt: '2024-05-14T00:00:00.000Z'
      },
      {
        title: 'Arm & Hammer has a solution for that',
        text: 'I’m a puppy mom and some other products leave that doggy smell even after the wash.. this doesn’t!! It took out that wet dog smell',
        name: 'Tini Wilson',
        rating: 5,
        attributedUserId: '9ce4acb1-aab1-4534-b8d7-f4d918fd7993',
        reviewedAt: '2024-05-14T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 4.95,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-05-14',
    toDate: '2024-05-15',
    numRatings: 164,
    numReviews: 139,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 6,
    momentumReviewRating: 4.866666666666667,
    organicRatingCount: 1,
    reviewsInPeriod: 6,
    reviews: [
      {
        title: 'Took stains away',
        text: 'This was a great stain remover detergent. I pretreated a shirt that my child made a stain on and it came off not only that if left clothes smelling clean and fresh.',
        name: 'MomBoss',
        rating: 5,
        attributedUserId: '91ca22f2-6bf5-4f66-b075-7bf84ce315ed',
        reviewedAt: '2024-05-15T00:00:00.000Z'
      },
      {
        title: 'Works great on stains',
        text: 'My kids always have stains after school and after sports . We use this laundry detergent to remove them quick and easy no need to double wash',
        name: 'Laura millan',
        rating: 5,
        attributedUserId: 'ff09a3fc-e829-480a-b4a3-758ef3e7f44f',
        reviewedAt: '2024-05-15T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 4.866666666666667,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-05-15',
    toDate: '2024-05-16',
    numRatings: 168,
    numReviews: 143,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 4,
    momentumReviewRating: 4.9,
    organicRatingCount: 0,
    reviewsInPeriod: 4,
    reviews: [
      {
        title: 'Perfect for families',
        text: 'Everything comes out smelling fresh and clean. Even after pets, sports, and kids.',
        name: 'Taylor Travis',
        rating: 5,
        attributedUserId: 'a4954f58-ab9b-4e5d-8cf2-36749496e865',
        reviewedAt: '2024-05-16T00:00:00.000Z'
      },
      {
        title: 'It works!',
        text: 'I loved this product!! It cleaned my family’s clothes so well! It’s smells so good but isn’t overbearing! Definitely going to buy again.',
        name: 'Nia',
        rating: 5,
        attributedUserId: '5eb70da1-4f92-416f-80b7-cfb2c25c72bf',
        reviewedAt: '2024-05-16T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 4.9,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-05-16',
    toDate: '2024-05-17',
    numRatings: 175,
    numReviews: 148,
    ratingsInPeriod: 2,
    momentumRatingCount: 2,
    vineReviewCount: 0,
    momentumReviewCount: 5,
    momentumReviewRating: 4.8,
    organicRatingCount: 2,
    reviewsInPeriod: 5,
    reviews: [],
    rating: 4.8,
    momentumRating: 4.8,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-05-17',
    toDate: '2024-05-18',
    numRatings: 182,
    numReviews: 152,
    ratingsInPeriod: 3,
    momentumRatingCount: 2,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.8,
    organicRatingCount: 5,
    reviewsInPeriod: 4,
    reviews: [],
    rating: 4.8,
    momentumRating: 4.8,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-05-18',
    toDate: '2024-05-19',
    numRatings: 183,
    numReviews: 152,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-05-19',
    toDate: '2024-05-20',
    numRatings: 183,
    numReviews: 152,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-05-20',
    toDate: '2024-05-21',
    numRatings: 183,
    numReviews: 152,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-05-21',
    toDate: '2024-05-22',
    numRatings: 185,
    numReviews: 154,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4,
    organicRatingCount: 0,
    reviewsInPeriod: 2,
    reviews: [
      {
        title: 'Stain fighter!',
        text: 'This got out my toughest stains! Definitely recommend!',
        name: 'Katie Record',
        rating: 5,
        attributedUserId: '1893fd58-8c65-47c8-b39a-57f1881b119d',
        reviewedAt: '2024-05-20T00:00:00.000Z'
      },
      {
        title: 'Good product',
        text: 'Does the job as stated, removes tuff stains as long as you follow the directions.',
        name: 'Silisha Ewen',
        rating: 3,
        attributedUserId: 'a4fdbdb9-39a8-49d6-b2d7-3bd4e749c3d3',
        reviewedAt: '2024-05-20T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 4,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-05-22',
    toDate: '2024-05-23',
    numRatings: 187,
    numReviews: 156,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 5,
    organicRatingCount: 0,
    reviewsInPeriod: 2,
    reviews: [
      {
        title: 'Gets stains out!',
        text: 'It really works well on stinky gym clothes and gets stains out too!',
        name: 'Cassie Feldmann',
        rating: 5,
        attributedUserId: 'ed3a01d3-04ca-4760-9e41-89e5004c8869',
        reviewedAt: '2024-05-21T00:00:00.000Z'
      },
      {
        title: 'Great for stains',
        text: 'Loved the scent and was helpful for stain removal from sports clothing.',
        name: 'Gall_adventures',
        rating: 5,
        attributedUserId: '9942419b-5019-4ca3-85f8-5a24a7714dae',
        reviewedAt: '2024-05-21T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-05-23',
    toDate: '2024-05-24',
    numRatings: 192,
    numReviews: 157,
    ratingsInPeriod: 4,
    momentumRatingCount: 4,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 4,
    reviewsInPeriod: 1,
    reviews: [
      {
        title: 'My favorite laundry detergent',
        text: "The arm and hammer deap clean laundry detergent is my favorite. The price is right, it works great. It cleans our clothes and takes the stains out. It smells really good and it makes my clothes soft and great against my skin. The bottle doesn't leak and a little goes a long way. The strength is great for all your laundry.",
        name: 'The Blonde and Co',
        rating: 5,
        attributedUserId: '68ae23d2-b9ba-4e27-a5c1-f626e20ee85a',
        reviewedAt: '2024-05-23T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 5,
    organicRating: 4.75,
    vineRating: 0
  },
  {
    fromDate: '2024-05-24',
    toDate: '2024-05-25',
    numRatings: 193,
    numReviews: 158,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [
      {
        title: 'I love it',
        text: 'This is the best liquid detergent for your household!',
        name: 'alvs becca',
        rating: 5,
        attributedUserId: '59b28d99-8f28-4be8-a45a-7354db0d1a03',
        reviewedAt: '2024-05-25T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-05-25',
    toDate: '2024-05-26',
    numRatings: 196,
    numReviews: 159,
    ratingsInPeriod: 2,
    momentumRatingCount: 2,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 2,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.8,
    momentumRating: 4.8,
    organicRating: 4.799999999999999,
    vineRating: 0
  },
  {
    fromDate: '2024-05-26',
    toDate: '2024-05-27',
    numRatings: 197,
    numReviews: 160,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [
      {
        title: 'Smells Fantastic',
        text: 'It has a fabulous scent that is super long lasting. It leaves my laundry smelling fresh and clean with just the detergent alone. It is also super effective at removing stains.',
        name: 'Blondeducky',
        rating: 5,
        attributedUserId: '2a123963-168f-499d-9eba-61ac7d5fb3cf',
        reviewedAt: '2024-05-27T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-05-27',
    toDate: '2024-05-28',
    numRatings: 198,
    numReviews: 161,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [
      {
        title: 'Love the scent and the cleaning power',
        text: 'Definitely love this laundry detergent. It smells so clean.',
        name: 'Kristen Johnson',
        rating: 5,
        attributedUserId: '0831cf3e-7078-4abb-81e0-564357791dfa',
        reviewedAt: '2024-05-27T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-05-28',
    toDate: '2024-05-29',
    numRatings: 201,
    numReviews: 164,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 3,
    momentumReviewRating: 4.866666666666667,
    organicRatingCount: 0,
    reviewsInPeriod: 3,
    reviews: [
      {
        title: 'Smells so good',
        text: 'I love the long lasting scent! It also tackles toddler stains nicely!',
        name: 'Amanda Ingrid May',
        rating: 5,
        attributedUserId: '296dd660-85b1-4081-8db3-5dd70a31e545',
        reviewedAt: '2024-05-28T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 4.866666666666667,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-05-29',
    toDate: '2024-05-30',
    numRatings: 203,
    numReviews: 166,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 2,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-05-30',
    toDate: '2024-05-31',
    numRatings: 203,
    numReviews: 166,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-05-31',
    toDate: '2024-06-01',
    numRatings: 204,
    numReviews: 166,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-06-01',
    toDate: '2024-06-02',
    numRatings: 204,
    numReviews: 166,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-02',
    toDate: '2024-06-03',
    numRatings: 206,
    numReviews: 166,
    ratingsInPeriod: 2,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 2,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-06-03',
    toDate: '2024-06-04',
    numRatings: 208,
    numReviews: 166,
    ratingsInPeriod: 2,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 2,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-06-04',
    toDate: '2024-06-05',
    numRatings: 209,
    numReviews: 166,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-06-05',
    toDate: '2024-06-06',
    numRatings: 211,
    numReviews: 167,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 1,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.8,
    momentumRating: 4.8,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-06-06',
    toDate: '2024-06-07',
    numRatings: 215,
    numReviews: 168,
    ratingsInPeriod: 3,
    momentumRatingCount: 3,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 3,
    reviewsInPeriod: 1,
    reviews: [
      {
        title: 'It’s deep cleaning',
        text: 'Worth the price and eco friendly',
        name: 'J',
        rating: 5,
        attributedUserId: 'fc80d254-2d70-482e-b433-8eccfabe48dc',
        reviewedAt: '2024-06-07T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 5,
    organicRating: 4.733333333333333,
    vineRating: 0
  },
  {
    fromDate: '2024-06-07',
    toDate: '2024-06-08',
    numRatings: 215,
    numReviews: 168,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-08',
    toDate: '2024-06-09',
    numRatings: 219,
    numReviews: 169,
    ratingsInPeriod: 3,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 4,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-06-09',
    toDate: '2024-06-10',
    numRatings: 220,
    numReviews: 170,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-06-10',
    toDate: '2024-06-11',
    numRatings: 222,
    numReviews: 171,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 1,
    reviewsInPeriod: 1,
    reviews: [
      {
        title: 'Great laundry detergent!',
        text: 'Love how well it cleans and removes stain. It leaves my clothes fresh smelling!',
        name: 'Rhonda Duren',
        rating: 5,
        attributedUserId: 'a1845334-950a-4d41-85f8-bda36cfaa175',
        reviewedAt: '2024-06-11T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 5,
    organicRating: 4.6,
    vineRating: 0
  },
  {
    fromDate: '2024-06-11',
    toDate: '2024-06-12',
    numRatings: 223,
    numReviews: 172,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [
      {
        title: 'Go to laundry detergent',
        text: 'I like for its ease of use and size',
        name: 'Maia Cabije',
        rating: 5,
        attributedUserId: '091a1cfa-4648-4c0c-bef2-7ce088188217',
        reviewedAt: '2024-06-12T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-12',
    toDate: '2024-06-13',
    numRatings: 225,
    numReviews: 172,
    ratingsInPeriod: 2,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 2,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-06-13',
    toDate: '2024-06-14',
    numRatings: 227,
    numReviews: 174,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.9,
    organicRatingCount: 0,
    reviewsInPeriod: 2,
    reviews: [
      {
        title: 'Great',
        text: 'I love this and the smell it has! Works great',
        name: 'Charles Gatton',
        rating: 5,
        attributedUserId: '04542b62-1ed6-472b-8cf5-b7009ad8e306',
        reviewedAt: '2024-06-14T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 4.9,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-14',
    toDate: '2024-06-15',
    numRatings: 229,
    numReviews: 175,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 2,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-06-15',
    toDate: '2024-06-16',
    numRatings: 232,
    numReviews: 176,
    ratingsInPeriod: 2,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 3,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-06-16',
    toDate: '2024-06-17',
    numRatings: 234,
    numReviews: 177,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 2,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-06-17',
    toDate: '2024-06-18',
    numRatings: 235,
    numReviews: 178,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-06-18',
    toDate: '2024-06-19',
    numRatings: 237,
    numReviews: 178,
    ratingsInPeriod: 2,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 2,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-06-19',
    toDate: '2024-06-20',
    numRatings: 240,
    numReviews: 179,
    ratingsInPeriod: 2,
    momentumRatingCount: 2,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 2,
    reviewsInPeriod: 1,
    reviews: [
      {
        title: 'Honest Review',
        text: 'This smell soo good and leave my clothes smelling sweet even after I wear it',
        name: 'Sharii',
        rating: 5,
        attributedUserId: 'b85189b3-97ad-4e07-826f-f978f797cfc5',
        reviewedAt: '2024-06-19T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 5,
    organicRating: 4.699999999999999,
    vineRating: 0
  },
  {
    fromDate: '2024-06-20',
    toDate: '2024-06-21',
    numRatings: 240,
    numReviews: 179,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-21',
    toDate: '2024-06-22',
    numRatings: 241,
    numReviews: 179,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-06-22',
    toDate: '2024-06-23',
    numRatings: 241,
    numReviews: 180,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-23',
    toDate: '2024-06-24',
    numRatings: 241,
    numReviews: 181,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-24',
    toDate: '2024-06-25',
    numRatings: 242,
    numReviews: 181,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-06-25',
    toDate: '2024-06-26',
    numRatings: 243,
    numReviews: 181,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-06-26',
    toDate: '2024-06-27',
    numRatings: 243,
    numReviews: 181,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-27',
    toDate: '2024-06-28',
    numRatings: 243,
    numReviews: 181,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-28',
    toDate: '2024-06-29',
    numRatings: 247,
    numReviews: 181,
    ratingsInPeriod: 4,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 4,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-06-29',
    toDate: '2024-06-30',
    numRatings: 247,
    numReviews: 181,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-30',
    toDate: '2024-07-01',
    numRatings: 248,
    numReviews: 181,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-07-01',
    toDate: '2024-07-02',
    numRatings: 251,
    numReviews: 181,
    ratingsInPeriod: 3,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 3,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-07-02',
    toDate: '2024-07-03',
    numRatings: 252,
    numReviews: 181,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-07-03',
    toDate: '2024-07-04',
    numRatings: 255,
    numReviews: 181,
    ratingsInPeriod: 3,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 3,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-07-04',
    toDate: '2024-07-05',
    numRatings: 257,
    numReviews: 181,
    ratingsInPeriod: 2,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 2,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-07-05',
    toDate: '2024-07-06',
    numRatings: 258,
    numReviews: 182,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [
      {
        title: 'Powerful and Effective Laundry Detergent!',
        text: "The ARM & HAMMER Deep Clean Stain Formula, and I’m very impressed with its performance. The 102 fl oz bottle provides a generous amount of detergent, making it great value for money with up to 68 loads.  This detergent lives up to its claims of deep cleaning. It effectively removes tough stains and odors, leaving clothes looking fresh and smelling great. The formula is strong enough to tackle dirt and grime while being gentle on fabrics.  The liquid detergent dissolves well in water and doesn't leave any residue on clothes. I also appreciate that it has a pleasant, clean scent that isn’t overpowering.  Overall, ARM & HAMMER Deep Clean Stain Formula is a reliable and effective laundry detergent that delivers excellent cleaning power. I highly recommend it for anyone looking for a detergent that can handle heavy-duty cleaning while keeping clothes fresh and clean.",
        name: 'Tiffany Bailey',
        rating: 5,
        attributedUserId: 'c664131d-fc57-4beb-b94b-60d9707b0090',
        reviewedAt: '2024-07-04T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-07-06',
    toDate: '2024-07-07',
    numRatings: 258,
    numReviews: 182,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-07-07',
    toDate: '2024-07-08',
    numRatings: 260,
    numReviews: 183,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 2,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-07-08',
    toDate: '2024-07-09',
    numRatings: 261,
    numReviews: 184,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-07-09',
    toDate: '2024-07-10',
    numRatings: 265,
    numReviews: 185,
    ratingsInPeriod: 3,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 4,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 4.7,
    vineRating: 0
  },
  {
    fromDate: '2024-07-10',
    toDate: '2024-07-11',
    numRatings: 266,
    numReviews: 185,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 4.7,
    vineRating: 0
  },
  {
    fromDate: '2024-07-11',
    toDate: '2024-07-12',
    numRatings: 266,
    numReviews: 185,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-07-12',
    toDate: '2024-07-13',
    numRatings: 269,
    numReviews: 187,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 3,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-07-13',
    toDate: '2024-07-14',
    numRatings: 271,
    numReviews: 187,
    ratingsInPeriod: 2,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 2,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-07-14',
    toDate: '2024-07-15',
    numRatings: 274,
    numReviews: 188,
    ratingsInPeriod: 2,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 3,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 4.7,
    vineRating: 0
  },
  {
    fromDate: '2024-07-15',
    toDate: '2024-07-16',
    numRatings: 277,
    numReviews: 189,
    ratingsInPeriod: 2,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 3,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 4.7,
    vineRating: 0
  }
].map(r => ({
  ...r,
  fromDate: DateTime.fromISO(r.fromDate).minus({ days: 242 }).toISODate()!,
  toDate: DateTime.fromISO(r.toDate).minus({ days: 242 }).toISODate()!,
  momentumRatingCount: r.momentumReviewCount,
  ratingsInPeriod: r.reviewsInPeriod,
  numRatings: r.numReviews
}))
