import { SAMPLE_CAMPAIGN_BENCHMARK } from '../getCampaignBenchmark/sample-data'
import { ZeusInterceptor } from '../types'
import { getSampleCampaign } from '../utils'

export const updateCampaignBenchmark: ZeusInterceptor<'mutation', 'updateCampaignBenchmark'> = async args => {
  const params = args.o.updateCampaignBenchmark?.[0]!

  const isSample = !!getSampleCampaign(params.input.campaignId!)
  if (isSample) {
    return { ...SAMPLE_CAMPAIGN_BENCHMARK[params.input.skuId], ...params }
  }
}
