import { DateTime } from 'luxon'
import { Store } from '@productwindtom/shared-momentum-zeus-types'

export const SUPPORTED_STORES = [Store.amazon, Store.amazon_ca, Store.amazon_uk, Store.amazon_de]

export const getWeekIndex = (startDate: string | undefined, date: string) => {
  if (startDate) {
    return DateTime.fromISO(date).startOf('week').diff(DateTime.fromISO(startDate).startOf('week'), 'weeks').weeks
  }
}
