import { SAMPLE_CAMPAIGN_REVIEW_METRICS_LAUNDRY } from './laundry'
import { SAMPLE_CAMPAIGN_REVIEW_METRICS_HEADPHONES } from './headphones'
import { SAMPLE_CAMPAIGN_REVIEW_METRICS_WATER_FLOSSER_PRIME_DAY } from '@momentum/api/interceptors/getCampaignReviewMetrics/sample-data/water-flosser-pd-launch'
import { CampaignProductReviewRatingMetric } from '@momentum/routes/campaign/context/queries'
import { SAMPLE_CAMPAIGN_WATER_FLOSSER_PRIME_DAY_LAUNCH } from '@momentum/api/interceptors/getCampaign/sample-data/water-flosser-pd-launch'
import { SAMPLE_CAMPAIGN_REVIEWS_KEYBOARD_REVIEWS_PL } from '@momentum/api/interceptors/getCampaignReviewMetrics/sample-data/keyboard-reviews-product-launch'
import { SAMPLE_CAMPAIGN_KEYBOARD_REVIEWS_PL } from '@momentum/api/interceptors/getCampaign/sample-data/keyboard-reviews-product-launch'
import { SAMPLE_CAMPAIGN_REVIEW_OUTDOOR_CAMERA_BOOST } from '@momentum/api/interceptors/getCampaignReviewMetrics/sample-data/outdoor-camera-boost'
import { SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST } from '@momentum/api/interceptors/getCampaign/sample-data/outdoor-camera-boost'
import { SAMPLE_CAMPAIGN_REVIEWS_WIRELESS_EARBUDS } from '@momentum/api/interceptors/getCampaignReviewMetrics/sample-data/wireless-earbuds'
import { SAMPLE_CAMPAIGN_WIRELESS_EARBUDS } from '@momentum/api/interceptors/getCampaign/sample-data/wireless-earbuds'
import { SAMPLE_CAMPAIGN_REVIEW_METRICS_KEYBOARD } from '@momentum/api/interceptors/getCampaignReviewMetrics/sample-data/keyboard'
import { SAMPLE_CAMPAIGN_REVIEW_METRICS_TEETH_WHITENING } from '@momentum/api/interceptors/getCampaignReviewMetrics/sample-data/teeth-whitening'
import { SAMPLE_CAMPAIGN_REVIEW_METRICS_PILLOW } from '@momentum/api/interceptors/getCampaignReviewMetrics/sample-data/pillow'

export const SAMPLE_CAMPAIGN_REVIEW_METRICS: Record<string, Array<CampaignProductReviewRatingMetric>> = {
  'sample-sku-laundry': SAMPLE_CAMPAIGN_REVIEW_METRICS_LAUNDRY,
  'sample-sku-headphones': SAMPLE_CAMPAIGN_REVIEW_METRICS_HEADPHONES,
  [SAMPLE_CAMPAIGN_WATER_FLOSSER_PRIME_DAY_LAUNCH.skuId]: SAMPLE_CAMPAIGN_REVIEW_METRICS_WATER_FLOSSER_PRIME_DAY,
  [SAMPLE_CAMPAIGN_KEYBOARD_REVIEWS_PL.skuId]: SAMPLE_CAMPAIGN_REVIEWS_KEYBOARD_REVIEWS_PL,
  [SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.skuId]: SAMPLE_CAMPAIGN_REVIEW_OUTDOOR_CAMERA_BOOST,
  [SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId]: SAMPLE_CAMPAIGN_REVIEWS_WIRELESS_EARBUDS,
  'sample-sku-keyboard': SAMPLE_CAMPAIGN_REVIEW_METRICS_KEYBOARD,
  'sample-sku-whitening': SAMPLE_CAMPAIGN_REVIEW_METRICS_TEETH_WHITENING,
  'sample-sku-marker': [
    {
      fromDate: '2024-02-21',
      toDate: '2024-02-22',
      rating: 5,
      numRatings: 13,
      numReviews: 12,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 1,
      vineRating: 5,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-02-22',
      toDate: '2024-02-23',
      rating: 5,
      numRatings: 14,
      numReviews: 13,
      ratingsInPeriod: 1,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-02-23',
      toDate: '2024-02-24',
      rating: 5,
      numRatings: 14,
      numReviews: 13,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-02-24',
      toDate: '2024-02-25',
      rating: 5,
      numRatings: 14,
      numReviews: 13,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 2,
      vineRating: 5,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 2,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-02-25',
      toDate: '2024-02-26',
      rating: 5,
      numRatings: 15,
      numReviews: 14,
      ratingsInPeriod: 1,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-02-26',
      toDate: '2024-02-27',
      rating: 5,
      numRatings: 16,
      numReviews: 15,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-02-27',
      toDate: '2024-03-02',
      rating: 4.9,
      numRatings: 18,
      numReviews: 17,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 3,
      vineRating: 4.666666666666667,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 5,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-02',
      toDate: '2024-03-03',
      rating: 4.9,
      numRatings: 20,
      numReviews: 18,
      ratingsInPeriod: 2,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-03',
      toDate: '2024-03-04',
      rating: 4.9,
      numRatings: 20,
      numReviews: 18,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 1,
      vineRating: 5,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-04',
      toDate: '2024-03-05',
      rating: 4.9,
      numRatings: 22,
      numReviews: 20,
      ratingsInPeriod: 1,
      momentumRatingCount: 0,
      vineReviewCount: 1,
      vineRating: 5,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-05',
      toDate: '2024-03-06',
      rating: 4.9,
      numRatings: 47,
      numReviews: 45,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 1,
      vineRating: 5,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-06',
      toDate: '2024-03-07',
      rating: 4.9,
      numRatings: 49,
      numReviews: 22,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 1,
      vineRating: 5,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 2,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-07',
      toDate: '2024-03-08',
      rating: 4.9,
      numRatings: 50,
      numReviews: 48,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 1,
      vineRating: 5,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-08',
      toDate: '2024-03-09',
      rating: 4.9,
      numRatings: 51,
      numReviews: 49,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-09',
      toDate: '2024-03-10',
      rating: 4.9,
      numRatings: 53,
      numReviews: 50,
      ratingsInPeriod: 2,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-10',
      toDate: '2024-03-11',
      rating: 4.9,
      numRatings: 55,
      numReviews: 50,
      ratingsInPeriod: 2,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-11',
      toDate: '2024-03-12',
      rating: 4.9,
      numRatings: 58,
      numReviews: 51,
      ratingsInPeriod: 2,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-12',
      toDate: '2024-03-13',
      rating: 4.9,
      numRatings: 58,
      numReviews: 51,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 1,
      vineRating: 4,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 3,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-13',
      toDate: '2024-03-14',
      rating: 4.8,
      numRatings: 61,
      numReviews: 54,
      ratingsInPeriod: 2,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-14',
      toDate: '2024-03-15',
      rating: 4.8,
      numRatings: 66,
      numReviews: 55,
      ratingsInPeriod: 4,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-15',
      toDate: '2024-03-16',
      rating: 4.8,
      numRatings: 66,
      numReviews: 55,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-16',
      toDate: '2024-03-17',
      rating: 4.8,
      numRatings: 69,
      numReviews: 57,
      ratingsInPeriod: 3,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-17',
      toDate: '2024-03-18',
      rating: 4.8,
      numRatings: 70,
      numReviews: 58,
      ratingsInPeriod: 1,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    }
  ],
  'sample-sku-pillow': SAMPLE_CAMPAIGN_REVIEW_METRICS_PILLOW
}
