import { GraphQLResult } from '@aws-amplify/api-graphql'
import { User } from '@momentum/routes/queries'
import { Thunder } from '@productwindtom/shared-momentum-zeus-types'
import { API, graphqlOperation } from 'aws-amplify'
import * as interceptors from './interceptors'
import { captureException } from '@sentry/react'

export type MomentumApi = typeof _mApi

let interceptedApiEnabled = false
export let currentProfile: User

/**
 * @param enabled
 * @param profile current user profile to be used in interceptors that require it to simulate backend responses
 */
export const enableInterceptedApi = (enabled: boolean, profile: User) => {
  interceptedApiEnabled = enabled
  currentProfile = profile
}

export const _mApi = Thunder(async (query: string, vars?: Record<string, any>) => {
  const entityName = extractEntityFromGraphQLQuery(query)
  try {
    const response = (await API.graphql(graphqlOperation(query, vars))) as GraphQLResult
    if (response.errors) {
      // Force throw so we do not need to duplicate logic.
      // This is usually never the case anyways because when there are errors it is an exception and not a response.
      throw response
    } else {
      return response.data
    }
  } catch (e) {
    const res = e as GraphQLResult
    const error = new Error(`GraphQL call failed for: ${entityName}`)

    captureException(error, {
      level: 'error',
      extra: {
        query,
        vars,
        graphqlErrors: res.errors
      }
    })

    throw e
  }
})

const extractEntityRegex = /{\s*([a-zA-Z_][a-zA-Z0-9_]*)\s*\(/
const extractEntityFromGraphQLQuery = (query: string) => {
  // Regex to match the first field name after the opening curly brace
  const match = query.match(extractEntityRegex)
  if (match) {
    return match[1] // Return the captured entity name
  } else {
    return null // No match found
  }
}

export const mApi: MomentumApi = (operation, graphqlOptions) => async (o, opts) => {
  if (!interceptedApiEnabled) {
    return _mApi(operation, graphqlOptions)(o, opts) as any
  }

  const interceptorResults: Record<string, any> = {}
  for (const [operationName, value] of Object.entries(o)) {
    const interceptorResult = await interceptors[operationName as keyof typeof interceptors]?.({
      operation,
      graphqlOptions,
      o: {
        [operationName]: value
      },
      opts
    })

    if (interceptorResult) {
      // console.log(`Intercepted ${operationName} with result: ${JSON.stringify(interceptorResult, null, 2)}`)
      interceptorResults[operationName] = interceptorResult
      delete o[operationName as keyof typeof o]
    }
  }

  const apiResult = Object.keys(o).length ? await _mApi(operation, graphqlOptions)(o, opts) : {}

  const result = { ...apiResult, ...interceptorResults }
  return result as any
}

export const mApiKey = Thunder(async (query: string, vars?: Record<string, any>) => {
  const response = (await API.graphql({ authMode: 'API_KEY', ...graphqlOperation(query, vars) })) as GraphQLResult

  if (response.errors) {
    throw new Error(`Error with graphql call: ${JSON.stringify(response.errors, null, 2)}`)
  } else {
    return response.data
  }
})
