import { CampaignCreatorStatus, CreatorType, ProposalGoal, Store } from '@productwindtom/shared-momentum-zeus-types'
import { CampaignDetails } from '@momentum/routes/campaign/context/queries'

export const SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING: CampaignDetails = {
  id: 'sample-campaign-energy-drink-pd-existing-launch',
  createdAt: '2024-06-18T21:45:21.697Z',
  skuId: 'sample-sku-energy-drink',
  title: 'Energy Drink - Boost Existing Product Launch - Prime Day Lead In',
  goal: ProposalGoal.PRODUCT_LAUNCH,
  numCreators: 369,
  numCreatorsJoined: 368,

  numBrandAdvocateCreators: 369,
  numSocialCreators: 0,
  numPremiumUgcCreators: 0,
  numUgcCreators: 0,

  expectedPlacementWins: 10,

  releaseSchedule: {
    ugcWeeklySchedule: [],
    premiumUgcWeeklySchedule: [],
    socialWeeklySchedule: [],
    brandAdvocatesWeeklySchedule: [192, 137, 21, 12, 6]
  },
  anticipatedStartDate: '2024-06-19T04:00:00.000Z',
  startDate: '2024-06-19T04:00:00.000Z',

  endDate: '2024-08-07T14:30:54.857Z',
  cost: 2500000,
  product: {
    id: 'sample-sku-energy-drink',
    name: 'Energy Drink',
    skuId: 'EDRINK',
    priceCents: 2249,
    image: 'https://media.productwind.com/sample/images/sample-sku-energy-drink.png',
    listingLink: 'https://www.amazon.com',
    store: Store.amazon,
    availabilityDate: '2022-05-05T19:15:33.142Z',
    childSkus: { items: [] }
  },
  creators: [
    {
      userCampaignId: '597f8b08-351c-4eda-94bb-803ca0d8aecf',
      content: [],
      creator: {
        id: '4df48747-6482-4138-9984-6d03ad3fd288',
        firstName: 'Nicola',
        lastName: 'Ianeselli',
        email: 'nicola.ianeselli@gmail.com',
        instagramProfile: {
          handle: 'nicolaianeselli',
          followers: 4420,
          engagementRate: 0.38235294117647056
        },
        tiktokProfile: {
          handle: 'nicolaianeselli',
          followers: 34,
          engagementRate: 1.6598360655737707
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-19T21:01:51.000Z',
        amountCents: 2096
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '2ac2d327-4f82-4c1f-863e-03016ee03434',
      content: [],
      creator: {
        id: 'a02b49c7-9169-4578-a1d2-498fe3162d62',
        firstName: 'Kristina',
        lastName: 'Trtek',
        email: 'jeremy_kris@comcast.net',
        instagramProfile: {
          handle: 'thedrizzledossier',
          followers: 2568,
          engagementRate: 5.299844236760124
        },

        youtubeProfile: {
          handle: 'kristina8216',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-19T21:05:44.000Z',
        amountCents: 1959
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'f993699b-8899-48e6-bbeb-258ad7941cd0',
      content: [],
      creator: {
        id: '1f334685-341e-40c0-bfc0-469d7ac2a81c',
        firstName: 'Daina',
        lastName: 'Hickling',
        email: 'dhic9258@flhs.us',
        instagramProfile: {
          handle: 'neisha__xox',
          followers: 1384
        },
        tiktokProfile: {
          handle: 'leneishagayle0',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T12:25:48.000Z',
        amountCents: 4500
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '356588e7-631a-4de5-badd-29fd351ae3fb',
      content: [],
      creator: {
        id: 'eee5172b-c161-4b84-9671-61ffc4a284dc',
        firstName: 'Cassey',
        lastName: 'Ahmed',
        email: 'villagesisdoula@gmail.com',
        instagramProfile: {
          handle: 'villagesisdoula',
          followers: 1327,
          engagementRate: 0.2185380557648832
        },
        tiktokProfile: {
          handle: 'villagesisdoula',
          followers: 1493,
          engagementRate: 7.171314741035857
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T15:15:47.000Z',
        amountCents: 2401
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'a924051b-5c23-40b0-9024-1d48a9272b35',
      content: [],
      creator: {
        id: '4d9beaea-b210-4adc-b1a0-047cc4144df5',
        firstName: 'Danielle',
        lastName: 'Munio',
        email: 'dmunio@hotmail.com',
        instagramProfile: {
          handle: 'dmunio1980',
          followers: 188
        },
        tiktokProfile: {
          handle: 'dmunio80',
          followers: 57
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T16:10:02.000Z',
        amountCents: 2384
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'c6e9e8f0-f05d-4318-a577-e4db7c567c5f',
      content: [],
      creator: {
        id: '048514e6-7fbc-4600-93e4-95e522080cba',
        firstName: 'Delton Brissett',
        lastName: 'Renae Farquharson',
        email: 'renaefarquharson21@gmail.com',
        instagramProfile: {
          handle: 'naes_piecescoutureja',
          followers: 129
        },
        tiktokProfile: {
          handle: 'blessed.a1',
          followers: 2622,
          engagementRate: 31.648351648351646
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T16:16:17.000Z',
        amountCents: 2406
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'bcdf5b4c-a436-48ac-a192-60279cbc14ea',
      content: [],
      creator: {
        id: '130655f8-6034-4498-a5b5-e0ad68ff786a',
        firstName: 'Rochelle',
        lastName: 'Harris ',
        email: 'alwaynebedward04@gmail.com',

        tiktokProfile: {
          handle: 'anna_8763',
          followers: 119
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T16:23:38.000Z',
        amountCents: 3238
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '5def3384-e054-4c48-bc80-14d7d94ea65b',
      content: [],
      creator: {
        id: '0e798da8-7ab1-417a-ac6e-9cb9b885eeaf',
        firstName: 'Shakira',
        lastName: 'Rhoden',
        email: 'janelsummerlove@live.com',
        instagramProfile: {
          handle: 'dhayjhanel_remy',
          followers: 1851,
          engagementRate: 1.9611021069692056
        },
        tiktokProfile: {
          handle: 'juju_british',
          followers: 7609,
          engagementRate: 20
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T16:29:13.000Z',
        amountCents: 3405
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'dacafac7-447f-4da4-80ea-888cdc21cf77',
      content: [],
      creator: {
        id: '77b94c58-cac7-45e0-afab-fcca2bde5f7c',
        firstName: 'Martina',
        lastName: 'Ross',
        email: 'mross8390@gmail.com',

        tiktokProfile: {
          handle: '_.martinaross',
          followers: 810,
          engagementRate: 13.543859649122808
        },
        youtubeProfile: {
          handle: 'martinaross5741',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T16:33:04.000Z',
        amountCents: 3200
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '260014cb-9a1f-4eb1-9abb-331bd3dee24c',
      content: [],
      creator: {
        id: '271f7ff6-b9d3-4a59-9a63-1416be921b15',
        firstName: 'Jordane',
        lastName: 'Brown',
        email: 'jordanetheboss@gmail.com',

        tiktokProfile: {
          handle: 'pinkymarvelous22',
          followers: 2296,
          engagementRate: 21.10225614547087
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T16:35:58.000Z',
        amountCents: 6000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '25c659eb-ff8a-405b-9974-808f337da802',
      content: [],
      creator: {
        id: '3c194921-9835-4350-bd5e-34a85cf91010',
        firstName: 'Stenia',
        lastName: 'Martin',
        email: 'steniamartin12@gmail.com',

        tiktokProfile: {
          handle: '_.stenia._',
          followers: 1100,
          engagementRate: 18.960607498813477
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T16:41:14.000Z',
        amountCents: 4172
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'fae46440-12a6-46ff-b8f1-e902e308e541',
      content: [],
      creator: {
        id: 'c40bf9da-0333-459c-b89c-fc00442b1992',
        firstName: 'Rosey',
        lastName: 'Rodriguez',
        email: 'freebiesandmore81@gmail.com',
        instagramProfile: {
          handle: 'triedbyrosey',
          followers: 1903,
          engagementRate: 2.2858644245927486
        },
        tiktokProfile: {
          handle: 'rosey81',
          followers: 13647,
          engagementRate: 2.42914979757085
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T17:14:10.000Z',
        amountCents: 2406
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'f6bf1f4f-2a6f-402c-906c-41296e1fe114',
      content: [],
      creator: {
        id: '5da05871-4c75-4ca3-aab9-2a6ef1d5fa63',
        firstName: 'Marium',
        lastName: 'Tamton',
        email: 'marium.k.tamton@gmail.com',
        instagramProfile: {
          handle: 'nazara.beauty',
          followers: 1922,
          engagementRate: 6.670135275754421
        },
        tiktokProfile: {
          handle: 'mk_reviews8',
          followers: 1197,
          engagementRate: 13.038920678768745
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T17:21:27.000Z',
        amountCents: 2428
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '13f79fc9-f6d0-4265-add6-51da8f24054a',
      content: [],
      creator: {
        id: '86f2e8ed-592e-4c18-bff4-8d91d8dfae8c',
        firstName: 'Vio',
        lastName: 'Sych',
        email: 'villison990@gmail.com',
        instagramProfile: {
          handle: 'sychevskaia.marina',
          followers: 1618,
          engagementRate: 4.243922538112897
        },
        tiktokProfile: {
          handle: 'redpantera',
          followers: 1839,
          engagementRate: 10.72961373390558
        },
        youtubeProfile: {
          handle: 'vioson-kz6sd',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T17:25:53.000Z',
        amountCents: 3228
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'f464a622-041b-453b-bdc4-39a374fb0a81',
      content: [],
      creator: {
        id: '116875e4-4e5b-4a6a-b4a2-bf93e645988c',
        firstName: 'Jessica',
        lastName: 'Torres',
        email: 'jessicaroseslp@gmail.com',
        instagramProfile: {
          handle: 'jessicarose_slp_',
          followers: 1141,
          engagementRate: 1.1831726555652935
        },
        tiktokProfile: {
          handle: 'jessicaroseslp',
          followers: 11041,
          engagementRate: 5.735692272242864
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T17:36:12.000Z',
        amountCents: 2449
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '7a38ebbc-4316-4721-b515-6798c07be724',
      content: [],
      creator: {
        id: '142f249f-f7ad-46f1-b9f8-c1b8cf47767e',
        firstName: 'Gabrielle',
        lastName: 'Williams',
        email: 'williamstav22@gmail.com',

        tiktokProfile: {
          handle: 'iamjustgabby_',
          followers: 47
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T17:38:57.000Z',
        amountCents: 3000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '3fd2bd97-8eaf-492e-be8f-d7e1bb9d667a',
      content: [],
      creator: {
        id: 'f873af65-4062-4e9b-839f-8ee290786f0e',
        firstName: 'Megan',
        lastName: 'Mcfarlane',
        email: 'meganmcfarlane1992@gmail.com',

        tiktokProfile: {
          handle: 'alicias504',
          followers: 587,
          engagementRate: 15.735294117647058
        },
        youtubeProfile: {
          handle: 'meganmcfarlane-ub2ki',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T17:40:37.000Z',
        amountCents: 2095
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '4c1482b9-8c6c-40a0-96e7-e2a1d20bc65b',
      content: [],
      creator: {
        id: 'd965628e-4f77-42b8-aff4-60bcbda60af4',
        firstName: 'Kyara',
        lastName: 'Ibarra',
        email: 'kyara1643@gmail.com',
        instagramProfile: {
          handle: 'ibarrakyara',
          followers: 15446,
          engagementRate: 16.419785057620096
        },
        tiktokProfile: {
          handle: 'ibarrakyara',
          followers: 479,
          engagementRate: 14.421553090332806
        },
        youtubeProfile: {
          handle: 'ibarrakyara',
          followers: 536
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T17:55:19.000Z',
        amountCents: 2249
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '6be455b5-f47f-4f03-85bf-3886ac0cf842',
      content: [],
      creator: {
        id: '78844f3e-0dc4-4f76-95e7-df74ece55df1',
        firstName: 'Yanique',
        lastName: 'Douglas',
        email: 'sandrenedouglas@yahoo.com',

        tiktokProfile: {
          handle: 'yani_ique',
          followers: 796
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T18:04:14.000Z',
        amountCents: 3475
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '9d3b15c4-203d-44b0-bd91-8ca94811b789',
      content: [],
      creator: {
        id: 'b864bf5f-878d-49b2-a48b-4d8dddd04b0f',
        firstName: 'JP',
        lastName: 'Bradley',
        email: 'johnpaulbradley0144@icloud.com',
        instagramProfile: {
          handle: 'product.reviews.by.john',
          followers: 0,
          engagementRate: 0
        },

        youtubeProfile: {
          handle: 'jpb9133',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T18:20:38.000Z',
        amountCents: 2216
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '809e8f80-018d-411e-83de-5aaf9a453106',
      content: [],
      creator: {
        id: 'fe02f560-b9d0-403d-b013-35dd8c790c67',
        firstName: 'Meagan',
        lastName: 'Schultz',
        email: 'collabwithmarlee@gmail.com',
        instagramProfile: {
          handle: 'thriftwithme_andmarlee',
          followers: 7081,
          engagementRate: 6.641717271571812
        },
        tiktokProfile: {
          handle: 'mommy_to_marlee',
          followers: 115,
          engagementRate: 2.178217821782178
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T18:26:40.000Z',
        amountCents: 2435
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '62f245c8-7119-4c8e-ae87-a20420d2e6db',
      content: [],
      creator: {
        id: '1e6b12f7-2cdf-4460-bcd6-b4f5011b7495',
        firstName: 'Amanda',
        lastName: 'Dantice',
        email: 'amanda.dantice@gmail.com',
        instagramProfile: {
          handle: 'dhampandas_reviews',
          followers: 3616,
          engagementRate: 1.4988938053097347
        },
        tiktokProfile: {
          handle: 'dhampandas_reviews',
          followers: 985,
          engagementRate: 4.14239258285477
        },
        youtubeProfile: {
          handle: 'dhampandasreviews',
          followers: 25
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T18:41:48.000Z',
        amountCents: 2392
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'e15e5840-a91b-49a4-bd11-d284051bfd43',
      content: [],
      creator: {
        id: '40a6b5a1-3e81-4678-89ce-2ca016e2a16c',
        firstName: 'Shanice',
        lastName: 'Davis',
        email: 'legendaryshanzy17@gmail.com',
        instagramProfile: {
          handle: 'shandollessentials',
          followers: 795,
          engagementRate: 2.0792079207920793
        },
        tiktokProfile: {
          handle: 'yuhgurlshanzy',
          followers: 1152
        },
        youtubeProfile: {
          handle: 'shanzybanzy156',
          followers: 7
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T18:54:09.000Z',
        amountCents: 2406
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'a2591823-00a5-4f52-80d1-c170598a235d',
      content: [],
      creator: {
        id: 'e787a807-c764-4dc6-9f95-a3e7754121b7',
        firstName: 'Kimberlin',
        lastName: 'Neil',
        email: 'kimberlinneil061115@gmail.com',
        instagramProfile: {
          handle: 'rk_essentials',
          followers: 225
        },
        tiktokProfile: {
          handle: 'k_mamii',
          followers: 81
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T18:56:22.000Z',
        amountCents: 2137
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '884fbc46-06f1-46ea-8877-8fed80c2252a',
      content: [],
      creator: {
        id: '0bb283f9-8ce2-497e-ae98-737ce92b76cd',
        firstName: 'Thalia',
        lastName: 'aguilera',
        email: 'retrodenverfinds@gmail.com',
        instagramProfile: {
          handle: 'retrodenverfinds',
          followers: 3365,
          engagementRate: 2.3506686478454677
        },
        tiktokProfile: {
          handle: 'retrodenverfinds',
          followers: 115,
          engagementRate: 6.086956521739131
        },
        youtubeProfile: {
          handle: 'retrodenverfinds',
          followers: 47
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T19:12:07.000Z',
        amountCents: 2457
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '0cf65ede-0c8e-4814-8824-26a1946f4db9',
      content: [],
      creator: {
        id: 'b0915435-d058-4202-adf7-5ddc3351bb79',
        firstName: 'Danielle',
        lastName: 'Frayre',
        email: 'dngarcia1994@gmail.com',
        instagramProfile: {
          handle: 'da_nielle9944',
          followers: 1397,
          engagementRate: 9.019327129563349
        },
        tiktokProfile: {
          handle: 'danielle2594',
          followers: 1110,
          engagementRate: 19.523809523809526
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T19:12:40.000Z',
        amountCents: 3192
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '404bbf31-c8ca-4f11-a2d8-6180b948eab6',
      content: [],
      creator: {
        id: '69d17b2d-c68a-4a44-a6af-1209b4329aac',
        firstName: 'Jessica',
        lastName: 'Conrad',
        email: 'jessifer.1018@yahoo.com',

        tiktokProfile: {
          handle: 'jessifer8093',
          followers: 0
        },
        youtubeProfile: {
          handle: 'jessiesjems8525',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T19:14:57.000Z',
        amountCents: 2395
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'ed13f21b-bf6c-4ba7-8b89-7f4dee0c60f8',
      content: [],
      creator: {
        id: '605c4933-d768-4f1d-819d-51a79a91d68d',
        firstName: 'Faith',
        lastName: 'Morgan',
        email: 'hotgirlscientist@gmail.com',
        instagramProfile: {
          handle: 'hotgirlscientist',
          followers: 9876,
          engagementRate: 1.866140137707574
        },
        tiktokProfile: {
          handle: 'hotgirlscientist',
          followers: 1375,
          engagementRate: 13.91304347826087
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T19:24:09.000Z',
        amountCents: 2395
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '159ec1bb-1ae2-4555-b425-eff2bb83f8d2',
      content: [],
      creator: {
        id: '13388d6f-9090-44e4-809d-7620ff271213',
        firstName: 'Sandra Luz',
        lastName: 'Ordóñez',
        email: '2710slos@gmail.com',
        instagramProfile: {
          handle: '7537.luz',
          followers: 2488,
          engagementRate: 4.103697749196141
        },
        tiktokProfile: {
          handle: 'luz1468sandy',
          followers: 2932,
          engagementRate: 9.914317302377004
        },
        youtubeProfile: {
          handle: 'sandrasanchez-wr5tw',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T19:26:23.000Z',
        amountCents: 3176
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '567387cb-5c70-4600-9988-e8182b532a59',
      content: [],
      creator: {
        id: '96fd3f14-edad-48c7-8b32-7bba251912f4',
        firstName: 'Annie',
        lastName: 'Michelle',
        email: 'thebananniediaries@yahoo.com',
        instagramProfile: {
          handle: 'thebananniediaries',
          followers: 6666,
          engagementRate: 4.618961896189619
        },
        tiktokProfile: {
          handle: 'bananniediaries',
          followers: 2258,
          engagementRate: 1.9465554142498218
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T20:52:22.000Z',
        amountCents: 2805
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'dc4d3dfd-ce50-4fd5-8856-249497981287',
      content: [],
      creator: {
        id: '8b52522f-f322-4468-b909-c5240e4a07e9',
        firstName: 'Steven',
        lastName: 'Jang',
        email: 'grendy.g85@gmail.com',
        instagramProfile: {
          handle: 'stev.jang',
          followers: 2288,
          engagementRate: 1.5996503496503496
        },
        tiktokProfile: {
          handle: 'stev.jang',
          followers: 1869,
          engagementRate: 12.960760998810938
        },
        youtubeProfile: {
          handle: 'stevenjang2112',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T21:55:43.000Z',
        amountCents: 2271
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '36a3a695-05e2-4387-90ee-e676f4cdde2c',
      content: [],
      creator: {
        id: 'b5621308-1d9a-4e4c-8f15-1e25fa6794c7',
        firstName: 'Samuel',
        lastName: 'Sawiris',
        email: 'sam3195@live.com',
        instagramProfile: {
          handle: 'sam.sawiris',
          followers: 335,
          engagementRate: 20.8955223880597
        },
        tiktokProfile: {
          handle: '_egyptian_papiiii_',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T23:03:19.000Z',
        amountCents: 2398
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'ed5646c2-1219-45c1-8abb-e33f026a18b4',
      content: [],
      creator: {
        id: '3f7d7cfe-f22f-49b9-935a-8a1eefc11fc8',
        firstName: 'Sophian',
        lastName: 'Whyte',
        email: 'sbrownwhyte@yahoo.com',

        tiktokProfile: {
          handle: 'nika_whyte',
          followers: 724
        },
        youtubeProfile: {
          handle: 'glendonwhyde8211',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-21T00:42:21.000Z',
        amountCents: 5000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '78cb44cd-3399-48ad-b787-f5ddbd786fb2',
      content: [],
      creator: {
        id: 'a2b7108d-54d0-4bab-9d89-2aaae28e1282',
        firstName: 'Emma',
        lastName: 'Yoh',
        email: 'emmayoh@icloud.com',

        tiktokProfile: {
          handle: 'they.fw..emz21',
          followers: 14
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-21T00:43:23.000Z',
        amountCents: 3125
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'b3ebc031-41d3-4fc0-b33b-a13495c35fb2',
      content: [],
      creator: {
        id: '9f6186db-5de2-4d2b-b581-f642f648e6df',
        firstName: 'Annika',
        lastName: 'Whyte',
        email: 'annikawhyte@yahoo.com',
        instagramProfile: {
          handle: 'brinasphotography',
          followers: 246
        },

        youtubeProfile: {
          handle: 'neeks6697',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-21T03:09:02.000Z',
        amountCents: 5000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '5bc82ee7-93ae-476a-8c53-bb416a6eaf9a',
      content: [],
      creator: {
        id: 'a171111d-59ed-4710-8269-4f9ad6914099',
        firstName: 'Estefania',
        lastName: 'Madero ortiz',
        email: 'madero.estefania@gmail.com',
        instagramProfile: {
          handle: 'tefamadero',
          followers: 2415,
          engagementRate: 1.5527950310559007
        },
        tiktokProfile: {
          handle: 'estefaniamaderoo',
          followers: 154
        },
        youtubeProfile: {
          handle: 'stefmadero9230',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-21T03:54:32.000Z',
        amountCents: 2429
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '6ed1f936-5bb7-427b-af27-7b9ce5475688',
      content: [],
      creator: {
        id: 'c85f3cb2-b393-47bb-afd2-11663145248e',
        firstName: 'Tara',
        lastName: 'Smith',
        email: 'tarams78@gmail.com',
        instagramProfile: {
          handle: 'reviews.by.tara',
          followers: 0,
          engagementRate: 0
        },
        tiktokProfile: {
          handle: 'tarabertone6',
          followers: 2
        },
        youtubeProfile: {
          handle: 'tarabertone2587',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-21T04:54:57.000Z',
        amountCents: 2251
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '8915a67b-1878-4edf-be7b-ef964fe52441',
      content: [],
      creator: {
        id: '53f9cab6-7f64-4a94-b257-887f43a6fd77',
        firstName: 'Angelica',
        lastName: 'Uscanga',
        email: 'as.2309@live.com',

        tiktokProfile: {
          handle: 'amayuscg_',
          followers: 13
        },
        youtubeProfile: {
          handle: 'angelicauscangasantos2749',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-21T16:12:01.000Z',
        amountCents: 3157
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '714a53f2-b805-49dd-9fe9-eba7453b925c',
      content: [],
      creator: {
        id: '34a9503c-327f-4a38-8cdd-e12b9740b528',
        firstName: 'Carlie',
        lastName: 'Mohr',
        email: 'mohrcarl1992@gmail.com',

        tiktokProfile: {
          handle: 'carbar1231',
          followers: 26
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-21T16:14:27.000Z',
        amountCents: 2944
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'e330ada7-9405-45cf-8978-014507ddd77e',
      content: [],
      creator: {
        id: '50132cca-d423-4b06-ba3e-b76264a85518',
        firstName: 'Joy-Dian',
        lastName: 'Daley',
        email: 'joydiandaley508@gmail.com',
        instagramProfile: {
          handle: 'joydiandaley.cosmetics',
          followers: 1366,
          engagementRate: 2.0741825280624697
        },
        tiktokProfile: {
          handle: 'joydiandaley',
          followers: 1075,
          engagementRate: 9.539207760711399
        },
        youtubeProfile: {
          handle: 'joydiandaley',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-21T16:17:54.000Z',
        amountCents: 2380
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'b78b68f5-cc29-44fc-9ee8-f0deac4d8889',
      content: [],
      creator: {
        id: '57ed4cac-37d1-4142-8d32-5037b3a5d08b',
        firstName: 'Leshon',
        lastName: 'Mattis',
        email: 'leshonmattis@yahoo.com',
        instagramProfile: {
          handle: 'zoeymattis123',
          followers: 6
        },
        tiktokProfile: {
          handle: 'leshyboo2023',
          followers: 19
        },
        youtubeProfile: {
          handle: 'donnamattis457',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-21T16:33:22.000Z',
        amountCents: 2560
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '9dc1a52e-79ce-467d-9d9f-05ac579ed70f',
      content: [],
      creator: {
        id: '1c902d53-c0c6-43f1-b872-1c4c0d1d3bde',
        firstName: 'Tina',
        lastName: 'Wang',
        email: 'hello.tinawang@gmail.com',
        instagramProfile: {
          handle: 'teaa.nuuh',
          followers: 1571,
          engagementRate: 4.659452577975812
        },
        tiktokProfile: {
          handle: 'teaa.nuuh',
          followers: 106,
          engagementRate: 8.4260106091092
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-21T16:45:10.000Z',
        amountCents: 2118
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '08017d13-abfd-472e-93bb-a2515bb1adc8',
      content: [],
      creator: {
        id: 'e933cace-f0b5-476f-a447-fbb2d393fdf4',
        firstName: 'Kiki',
        lastName: 'Hylton',
        email: 'keithannieh@gmail.com',

        tiktokProfile: {
          handle: 'iiaamkiki',
          followers: 197
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-21T16:50:42.000Z',
        amountCents: 2105
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '6a27d882-8cc6-4933-a13f-f5f22a3890ec',
      content: [],
      creator: {
        id: 'b93823c3-3bfa-4799-bdb4-50540dd058f2',
        firstName: 'Apple',
        lastName: 'Berry',
        email: 'appleberry512@yahoo.com',

        tiktokProfile: {
          handle: 'applej027',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-21T16:57:58.000Z',
        amountCents: 4191
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '216448d7-44b6-4eb2-ba8a-560d61d5557f',
      content: [],
      creator: {
        id: 'c4b7f32c-3e38-4fbf-aeb5-9430259a5b88',
        firstName: 'Kayanna ',
        lastName: 'Archibald ',
        email: 'kayanna543@gmail.com',

        tiktokProfile: {
          handle: 'tutsie_kay',
          followers: 35
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-21T17:01:28.000Z',
        amountCents: 5000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'f799b409-d5f7-49d4-a7ce-30046fd1fdcc',
      content: [],
      creator: {
        id: '4ea36506-665b-47d9-8440-c2f3c40db325',
        firstName: 'Julia',
        lastName: 'Osborne',
        email: 'jewels261315@icloud.com',
        instagramProfile: {
          handle: 'jewelsreviews2.0',
          followers: 1514,
          engagementRate: 2.9502421840598854
        },
        tiktokProfile: {
          handle: 'jewels_261315',
          followers: 67
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-21T17:14:32.000Z',
        amountCents: 1945
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'a3fdd208-896d-44bb-abe4-db5e2a6c02cd',
      content: [],
      creator: {
        id: '082b631c-4167-4315-bb61-172749263a92',
        firstName: 'Candy',
        lastName: 'Grant',
        email: 'grantcandy92@yahoo.com',

        tiktokProfile: {
          handle: 'sweetie_love5',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-21T17:17:40.000Z',
        amountCents: 3653
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '16c92a4e-65d5-4c2d-a9a5-b172123234cc',
      content: [],
      creator: {
        id: 'e38f8ce9-19ad-41e0-a3c4-33116cbb0bc3',
        firstName: 'Suheera',
        lastName: 'Malik',
        email: 'suheera@hotmail.com',

        tiktokProfile: {
          handle: 'areehu10',
          followers: 48
        },
        youtubeProfile: {
          handle: 'suheeramalik8877',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-21T17:30:29.000Z',
        amountCents: 1964
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '05bfaf3b-52b2-496c-9c69-906349cb13de',
      content: [],
      creator: {
        id: '34d2ee52-8cb0-4df9-8bdd-0d34909edf13',
        firstName: 'Cindy',
        lastName: 'Gilbert',
        email: 'cindyhackslife@gmail.com',
        instagramProfile: {
          handle: 'cindy_hacks_life',
          followers: 3611,
          engagementRate: 0.6230960952644696
        },
        tiktokProfile: {
          handle: 'cindy_hacks_life',
          followers: 413,
          engagementRate: 2.5455760816723383
        },
        youtubeProfile: {
          handle: 'cindy_hacks_life',
          followers: 19
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-21T17:32:33.000Z',
        amountCents: 2062
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'f49ad3ae-df5f-4c45-98ed-b52d038e8907',
      content: [],
      creator: {
        id: 'd14dc282-84a9-4ab0-9b55-c16f56378e3f',
        firstName: 'Mikhail',
        lastName: 'Thompson',
        email: 'mikhailthompson3@gmail.com',

        tiktokProfile: {
          handle: 'spyro_don',
          followers: 1391,
          engagementRate: 3.939393939393939
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-21T17:40:49.000Z',
        amountCents: 4000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'b0c3d535-fdb4-4b47-a94c-9ad56bd57353',
      content: [],
      creator: {
        id: '6037ddcc-355c-4841-b80f-ae8999f11e5a',
        firstName: 'Alicia',
        lastName: 'Gayle',
        email: 'nalishagayle2003@gmail.com',
        instagramProfile: {
          handle: 'allie_braids_boutique',
          followers: 109,
          engagementRate: 26.911314984709477
        },
        tiktokProfile: {
          handle: '_iam_allie',
          followers: 4513,
          engagementRate: 15.855413716655939
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-21T17:44:09.000Z',
        amountCents: 2081
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '3be3d6b2-4a99-4673-be5e-77c2db9752a8',
      content: [],
      creator: {
        id: 'a4e2a70e-d441-4338-9416-695f3b1a66e5',
        firstName: 'Britney',
        lastName: 'Mcleod',
        email: 'britneymcleod23@gmail.com',

        tiktokProfile: {
          handle: 'theegurlsmommy',
          followers: 2018,
          engagementRate: 15.966386554621847
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-21T17:47:33.000Z',
        amountCents: 4000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '228de208-2310-41f8-8d25-9470b9a3113b',
      content: [],
      creator: {
        id: 'f9203779-cbae-4fb2-8844-cc14d96288f7',
        firstName: 'Brooke',
        lastName: 'Washburn',
        email: 'brookew4813@gmail.com',

        tiktokProfile: {
          handle: 'bookishb98',
          followers: 17
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-21T19:14:53.000Z',
        amountCents: 2780
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '26fa018a-32af-444d-a81f-2482220ea647',
      content: [],
      creator: {
        id: '046df56f-eaa8-4591-aaf8-7f8ab4060d55',
        firstName: 'Maria',
        lastName: 'Plascencia',
        email: 'cristyplapa@hotmail.com',
        instagramProfile: {
          handle: 'cristyplapa',
          followers: 15,
          engagementRate: 0
        },
        tiktokProfile: {
          handle: 'cristyplapa',
          followers: 26,
          engagementRate: 0.3968253968253968
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-21T19:25:40.000Z',
        amountCents: 2115
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'd11ed578-6802-42f6-ac6d-1ef97aff58f7',
      content: [],
      creator: {
        id: '761c6256-ad1d-4a46-9846-e5be634e7f38',
        firstName: 'Glizzie',
        lastName: 'McGuire',
        email: 'glizziemcguire2@gmail.com',
        instagramProfile: {
          handle: 'glizzieemcguire',
          followers: 54,
          engagementRate: 3.6734693877551026
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-21T19:32:29.000Z',
        amountCents: 2118
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'f018e84d-9068-495c-ad3b-832eb2ec16d3',
      content: [],
      creator: {
        id: '1fdf9365-6f59-4abd-92a2-637993309a4c',
        firstName: 'Jamie',
        lastName: 'Lynn',
        email: 'jamiebullington7903@icloud.com',
        instagramProfile: {
          handle: 'jamielynnugc',
          followers: 2255,
          engagementRate: 0.6651884700665188
        },
        tiktokProfile: {
          handle: 'jamielynnugc',
          followers: 2629,
          engagementRate: 11.643835616438356
        },
        youtubeProfile: {
          handle: 'jamielynnugc',
          followers: 7
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-21T19:34:28.000Z',
        amountCents: 2822
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '0d6a1dc4-6ae6-4e50-86ee-81a3a32373c5',
      content: [],
      creator: {
        id: 'c5a04663-7430-4586-bd33-27e40579945c',
        firstName: 'Gabrielle',
        lastName: 'Ffrench',
        email: 'gabbiossy@gmail.com',

        tiktokProfile: {
          handle: 'financetipsfordum',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-21T19:38:03.000Z',
        amountCents: 6000
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'f360e64a-5fd7-4233-bf3f-0989e2f5703e',
      content: [],
      creator: {
        id: '48daa05e-c062-4f1f-9ec3-eb71c7adb3cd',
        firstName: 'Tris-Ann',
        lastName: 'Thompson',
        email: 'trisann_thompson@yahoo.com',
        instagramProfile: {
          handle: 'triplet_fashionz',
          followers: 60,
          engagementRate: 5.833333333333333
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-21T20:32:59.000Z',
        amountCents: 1652
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '2882d465-60c7-496f-8c08-fe36fd0a5f87',
      content: [],
      creator: {
        id: '91db2665-0758-4613-adc9-0643e5b51bf0',
        firstName: 'Britony',
        lastName: 'Whyte',
        email: 'britonywhyte169@gmail.com',

        tiktokProfile: {
          handle: 'bhaddie_15',
          followers: 4
        },
        youtubeProfile: {
          handle: 'britonywhyte6636',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-21T20:43:00.000Z',
        amountCents: 1871
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'eae28296-7b24-4853-9f25-249937785fae',
      content: [],
      creator: {
        id: '42679f65-5dc9-4c23-bc11-b7347c877e0f',
        firstName: 'Camryn',
        lastName: 'Harrison',
        email: 'camrynharrison718@yahoo.com',
        instagramProfile: {
          handle: 'flawlesscam_z_',
          followers: 520
        },
        tiktokProfile: {
          handle: 'flawlesscam_z_',
          followers: 8568
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-21T20:53:36.000Z',
        amountCents: 6000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'e64cf679-d670-4413-a0b3-fac8cf17fe05',
      content: [],
      creator: {
        id: '57ad2a7f-68b0-4174-87a8-017440ab7cff',
        firstName: 'Aneyra',
        lastName: 'Rivera',
        email: 'aneyrarivera25@gmail.com',
        instagramProfile: {
          handle: 'anyrt25',
          followers: 812,
          engagementRate: 1.625615763546798
        },
        tiktokProfile: {
          handle: 'user97715417686705',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-21T21:04:56.000Z',
        amountCents: 3142
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '858f0c2c-c744-48cb-ae40-8b8d19d454b7',
      content: [],
      creator: {
        id: '6bdb4185-d787-4928-a805-3a701e3c17b3',
        firstName: 'Czarina',
        lastName: 'Tagbak',
        email: 'czarinatagbak123@gmail.com',

        tiktokProfile: {
          handle: 'chachaching_24',
          followers: 74
        },
        youtubeProfile: {
          handle: 'czarinatagbak9527',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-21T22:16:41.000Z',
        amountCents: 2897
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '77c039b1-62e8-48a7-91cf-6d6e3006f1e3',
      content: [],
      creator: {
        id: '13bd4b94-1bd0-42b7-89e3-5df7f643ed11',
        firstName: 'Chad',
        lastName: 'Wilson',
        email: 'fool_nobodys@yahoo.com',

        tiktokProfile: {
          handle: 'xxxmitchell',
          followers: 11,
          engagementRate: 7.791890571568148
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T00:07:14.000Z',
        amountCents: 2139
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'aac20b5a-ada0-41e7-890c-144c3be521fb',
      content: [],
      creator: {
        id: 'f11fccce-6b89-41ef-bc81-7a36d08f8b28',
        firstName: 'Gray',
        lastName: 'Lang',
        email: 'graylangemo@yahoo.com',
        instagramProfile: {
          handle: 'graylangemo',
          followers: 992,
          engagementRate: 11.467444120505345
        },
        tiktokProfile: {
          handle: 'graylangemo',
          followers: 103
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T01:06:40.000Z',
        amountCents: 2813
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '3fe11dcd-6687-47c3-bb73-3f886592a287',
      content: [],
      creator: {
        id: '31583f88-f565-4b68-9ed7-885ec2167fc1',
        firstName: 'Tashane',
        lastName: 'Easy',
        email: 'thee.tee.tee342@gmail.com',
        instagramProfile: {
          handle: 'personal_touch.co',
          followers: 84,
          engagementRate: 7.023809523809524
        },
        tiktokProfile: {
          handle: 'personal_touch_',
          followers: 221,
          engagementRate: 10.117071143233195
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T01:54:52.000Z',
        amountCents: 2180
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '5df74c1b-7e16-4689-b8cd-f5447445d961',
      content: [],
      creator: {
        id: '1d73647b-cff6-4ea7-b1e4-25660be52598',
        firstName: 'Ashackie',
        lastName: 'Goodall',
        email: 'delroygoodall@gmail.com',

        tiktokProfile: {
          handle: 'bossianateirah',
          followers: 63,
          engagementRate: 11.904761904761903
        },
        youtubeProfile: {
          handle: 'delroygoodall1754',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T03:24:25.000Z',
        amountCents: 2118
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '011393ad-2bfd-4cad-ac53-bd5b9178773b',
      content: [],
      creator: {
        id: 'da065af2-2ce4-4fc1-ac4f-2f00bb81188a',
        firstName: 'Naomi',
        lastName: 'Ricketts',
        email: 'jayy17ricketts@gmail.com',

        tiktokProfile: {
          handle: 'sweet_leo47',
          followers: 233
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T03:55:33.000Z',
        amountCents: 6313
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '1e3e4aa4-0389-4977-badc-dbbbad8bbf82',
      content: [],
      creator: {
        id: '70008da9-f601-4db5-abfb-fcd12a9c7e43',
        firstName: 'Treshana',
        lastName: 'Bonner',
        email: 'treshbonner@gmail.com',

        tiktokProfile: {
          handle: 'itz_tresh_',
          followers: 1386
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T04:24:34.000Z',
        amountCents: 3105
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'c34c8481-6bf5-4aa0-b630-16cfa76a95a1',
      content: [],
      creator: {
        id: 'b42fa1e5-d398-45bf-bf49-db6a7a04855d',
        firstName: 'Tashane',
        lastName: 'Easy',
        email: 'tashaneeasy1@gmail.com',

        tiktokProfile: {
          handle: 'wig.reviewer3',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T04:25:33.000Z',
        amountCents: 4122
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '0c5e8caf-fc11-4a8d-b93b-183a79639e80',
      content: [],
      creator: {
        id: 'a205cc50-19b2-44bf-a3e5-0d268f885bea',
        firstName: 'Karisha',
        lastName: 'Coke',
        email: 'rishacoke597@gmail.com',
        instagramProfile: {
          handle: 'rusticfashionboutique',
          followers: 1184,
          engagementRate: 0.05067567567567568
        },
        tiktokProfile: {
          handle: 'syndicate_dolly_',
          followers: 1286,
          engagementRate: 20.417183078829833
        },
        youtubeProfile: {
          handle: 'antisocialqxeen7977',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T08:04:03.000Z',
        amountCents: 2277
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '43dcbec3-f2ad-4b53-a134-16233ddbfbe4',
      content: [],
      creator: {
        id: '11ab4740-7f4e-4527-8a95-c28a4e297697',
        firstName: 'Daniel',
        lastName: 'Jarvie',
        email: 'djarviechina@gmail.com',
        instagramProfile: {
          handle: 'travelingdannyboy',
          followers: 32378,
          engagementRate: 0.11674593860028412
        },
        tiktokProfile: {
          handle: 'travelingdannyboy',
          followers: 61137,
          engagementRate: 1.317911279669109
        },
        youtubeProfile: {
          handle: 'danieljarvie9588'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T09:44:24.000Z',
        amountCents: 2723
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '0201834f-7c74-4072-b18d-593232e85ae4',
      content: [],
      creator: {
        id: '214c7b24-e3b7-46bd-b446-3e5196183d93',
        firstName: 'Nia',
        lastName: 'McKenzie',
        email: 'nia_mckenzie@yahoo.com',
        instagramProfile: {
          handle: 'datsadmadgyal',
          followers: 625
        },
        tiktokProfile: {
          handle: 'dannymack4',
          followers: 219
        },
        youtubeProfile: {
          handle: 'niamckenzie3663',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T11:22:42.000Z',
        amountCents: 2406
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'bba8750f-ffbe-4dd7-9ab4-5f2d679719c2',
      content: [],
      creator: {
        id: '66b306f1-6b46-4a25-a5fc-5362d0891d0e',
        firstName: 'Tiffany',
        lastName: 'Johnson',
        email: 'tiffanypen74@gmail.com',
        instagramProfile: {
          handle: 'ts_sparkling_coochie_clenz',
          followers: 168,
          engagementRate: 3.7426900584795324
        },
        tiktokProfile: {
          handle: 'tiffanynation70',
          followers: 1761,
          engagementRate: 8.11552375919678
        },
        youtubeProfile: {
          handle: 'tiffanynation424',
          followers: 700
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T12:04:11.000Z',
        amountCents: 2780
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '8693ef37-8c26-4aca-a77c-1d2c1707892e',
      content: [],
      creator: {
        id: '53b6b206-d5d1-4188-8c03-d37279c5ddbc',
        firstName: 'Ikeisha',
        lastName: 'Bent',
        email: 'edmondsonkylie35@gmail.com',
        instagramProfile: {
          handle: 'kulturekloset_',
          followers: 2142,
          engagementRate: 0.350140056022409
        },
        tiktokProfile: {
          handle: 'chrissycontentcreator',
          followers: 123
        },
        youtubeProfile: {
          handle: 'pauledmondson-uj4ct',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T12:54:04.000Z',
        amountCents: 2780
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '17d8d035-1fab-4d06-bb7d-80db0d2bd03d',
      content: [],
      creator: {
        id: '06b6850f-4910-439c-ac3a-a47df962fd20',
        firstName: 'Feneisha',
        lastName: 'Fletcher',
        email: 'meshabritish@gmail.com',

        tiktokProfile: {
          handle: 'slayqxeenmesha',
          followers: 4193,
          engagementRate: 17.734645525788228
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T16:22:41.000Z',
        amountCents: 6000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'b2e09619-12c2-4d01-8457-71366de0f778',
      content: [],
      creator: {
        id: 'e1c1c7fd-2a8e-499b-a2a3-2e2026f76ad5',
        firstName: 'Amanda Rose',
        lastName: 'Carter',
        email: 'amandatip56@gmail.com',
        instagramProfile: {
          handle: 'queen_amanda_rose',
          followers: 1815,
          engagementRate: 1.581267217630854
        },
        tiktokProfile: {
          handle: 'amandarosecarter',
          followers: 1445,
          engagementRate: 6.793424440483264
        },
        youtubeProfile: {
          handle: 'amandatipaldo9934',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T16:44:08.000Z',
        amountCents: 2093
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'f2f9290b-73ea-4aa3-a24b-edda058c9ca9',
      content: [],
      creator: {
        id: 'ef4f2fa8-fedf-48e2-aa28-21e3131d5671',
        firstName: 'Sanyu',
        lastName: 'Passley',
        email: 'passleysanyu@gmail.com',
        instagramProfile: {
          handle: 'glimmerand.gloss',
          followers: 54,
          engagementRate: 13.939393939393941
        },
        tiktokProfile: {
          handle: 'saniiboo.xo',
          followers: 2972,
          engagementRate: 5.771643663739021
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T16:59:03.000Z',
        amountCents: 1220
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'b7167f99-b909-4172-b3c0-4b075f60e535',
      content: [],
      creator: {
        id: 'ddd86eb4-767d-4510-b848-d1fbc914836e',
        firstName: 'Kim',
        lastName: 'Reynolds',
        email: 'kimmariereynolds@hotmail.com',

        tiktokProfile: {
          handle: 'tigger1960',
          followers: 684
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T16:59:45.000Z',
        amountCents: 2064
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '87d25247-a4f7-4a0f-a4b2-addfff5a1a11',
      content: [],
      creator: {
        id: '707a09cd-b2dc-4c57-b903-4b1e3083248b',
        firstName: 'destiny',
        lastName: 'renteria',
        email: 'llil.princesss22@gmail.com',

        tiktokProfile: {
          handle: 'he.luvs.destinyyyy',
          followers: 443
        },
        youtubeProfile: {
          handle: 'destinyrenteria3796',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T17:35:15.000Z',
        amountCents: 2224
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'df9ff63c-2b14-457b-8fae-b776cc5470cc',
      content: [],
      creator: {
        id: 'f449666e-2b7e-4423-a9a0-c6d30839df15',
        firstName: 'Neely',
        lastName: 'Walker',
        email: 'neelyough@gmail.com',
        instagramProfile: {
          handle: '_neelyshea',
          followers: 1340,
          engagementRate: 0.3687315634218289
        },
        tiktokProfile: {
          handle: 'nelesheuh',
          followers: 18
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T17:36:40.000Z',
        amountCents: 2115
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'fc29b4b4-d1a3-46b5-a4f9-5bebe15423ed',
      content: [],
      creator: {
        id: '32169644-8d24-469a-a165-d0783e1b23a4',
        firstName: 'Janelle',
        lastName: 'Wizzart',
        email: 'wizzartnubian@gmail.com',

        tiktokProfile: {
          handle: 'nubian_ariesjaye',
          followers: 2690,
          engagementRate: 8.786610878661087
        },
        youtubeProfile: {
          handle: 'janellewizzart368',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T17:54:10.000Z',
        amountCents: 1945
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '0afab38c-7a81-494e-9027-f8a7f6c21366',
      content: [],
      creator: {
        id: 'fa75423f-2d99-49f2-8079-7fa7326ea76e',
        firstName: 'O',
        lastName: 'Arrue',
        email: '94oioioi@gmail.com',
        instagramProfile: {
          handle: 'o.arrue',
          followers: 12324,
          engagementRate: 2.358812074001947
        },
        tiktokProfile: {
          handle: 'o.arrue',
          followers: 1272
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T17:57:12.000Z',
        amountCents: 2120
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '59319e70-747e-4b25-9ac9-700f516f15eb',
      content: [],
      creator: {
        id: 'b6be1d88-fea8-463c-9361-0a9e4e0d9ea4',
        firstName: 'Jennifer',
        lastName: 'Potter',
        email: 'rockit_29@yahoo.com',
        instagramProfile: {
          handle: 'rockit.29',
          followers: 64,
          engagementRate: 1.8518518518518516
        },
        tiktokProfile: {
          handle: 'rockit.29',
          followers: 1
        },
        youtubeProfile: {
          handle: 'jenniferpotter9862',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T18:02:19.000Z',
        amountCents: 2104
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '99e2e1f8-bea9-46ce-be00-d2ebff0b37b9',
      content: [],
      creator: {
        id: '695006af-2a75-4a8c-8338-727650cc9756',
        firstName: 'Romoya',
        lastName: 'Barrett',
        email: 'kjjobson42@gmail.com',

        tiktokProfile: {
          handle: 'kaylashomework',
          followers: 572
        },
        youtubeProfile: {
          handle: 'kaylashomeworkcenter',
          followers: 10
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T18:14:13.000Z',
        amountCents: 1945
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '6644c5da-1c8d-4cdd-b4d9-e8b5c4eec132',
      content: [],
      creator: {
        id: 'ed96ec5a-11b9-4984-af88-a6c5f037e94f',
        firstName: 'Terinay',
        lastName: 'Morgan',
        email: 'terinaymorgan84@outlook.com',

        tiktokProfile: {
          handle: 'terinay73',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T18:34:22.000Z',
        amountCents: 4000
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'd9aa10fc-6286-4e3d-9788-78db1a14fcb5',
      content: [],
      creator: {
        id: '77232090-af45-4a63-8680-4402756beea4',
        firstName: 'Noble',
        lastName: 'Dahir',
        email: 'dahirnoble2023@gmail.com',

        tiktokProfile: {
          handle: 'nicole1999028',
          followers: 6,
          engagementRate: 11.622206200432588
        },
        youtubeProfile: {
          handle: 'dahirnoble',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T18:42:43.000Z',
        amountCents: 4448
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '82aa3c6c-50f3-4e51-8445-afa768a25453',
      content: [],
      creator: {
        id: '89098b99-0601-4db7-ac49-5edc6191016a',
        firstName: 'Darin',
        lastName: 'Kleinpell',
        email: 'darinkleinpell@gmail.com',
        instagramProfile: {
          handle: 'darin_kleinpell',
          followers: 376,
          engagementRate: 0.09148936170212765
        },
        tiktokProfile: {
          handle: 'darinkleinpell',
          followers: 189,
          engagementRate: 10.782080485952923
        },
        youtubeProfile: {
          handle: 'kleinpellteamrealty4561',
          followers: 18
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T18:45:41.000Z',
        amountCents: 2049
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '42887eaf-8619-4ed0-b33e-a9f8f03fafb4',
      content: [],
      creator: {
        id: '5a1a2bbc-724c-480a-8610-b309cb1c2a74',
        firstName: 'Joyce',
        lastName: 'Lu',
        email: 'mayday1542002@yahoo.com.tw',

        tiktokProfile: {
          handle: 'hhh26196',
          followers: 0
        },
        youtubeProfile: {
          handle: 'paulwang3561',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T19:04:57.000Z',
        amountCents: 3144
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '6f4579a7-7d3d-4720-a915-6664dbc35919',
      content: [],
      creator: {
        id: 'c16e22ba-2387-49de-8774-d7513c7e26d5',
        firstName: 'Michelle',
        lastName: 'Rhine',
        email: 'meshelleybop1@yahoo.com',
        instagramProfile: {
          handle: 'meshell1216',
          followers: 835,
          engagementRate: 1.3173652694610778
        },
        tiktokProfile: {
          handle: 'michellerhine8',
          followers: 29
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T19:40:15.000Z',
        amountCents: 2782
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'c343783b-2bdb-4d6d-be73-8ff9ed00b1c9',
      content: [],
      creator: {
        id: '68911b58-d055-4375-a199-57c751d3e36e',
        firstName: 'Arianna',
        lastName: 'Gordon',
        email: 'ariannagordon1997@gmail.com',

        tiktokProfile: {
          handle: '_callmearii_',
          followers: 79
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T19:40:23.000Z',
        amountCents: 3422
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '7a2df8dd-557a-4bd4-8875-22f49c544953',
      content: [],
      creator: {
        id: '15796182-62da-4801-aefa-4ba7cd665c3c',
        firstName: 'Judene',
        lastName: 'Ricketts',
        email: 'rickettsjudz@gmail.com',

        tiktokProfile: {
          handle: 'ricketts.judz',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T19:45:40.000Z',
        amountCents: 6415
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '15ba8a30-ce65-489e-ba2f-7a0a553ae42e',
      content: [],
      creator: {
        id: 'ec4dea21-4901-4b4e-a0aa-493aa4dcbf69',
        firstName: 'Shinnel',
        lastName: 'Sewell',
        email: 'shinnelvsewell@gmail.com',

        tiktokProfile: {
          handle: 'nicenessnikki',
          followers: 1069
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T19:55:14.000Z',
        amountCents: 3848
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '5add09ed-a3bf-49f0-8b87-dda37f569058',
      content: [],
      creator: {
        id: '9a867a81-6409-4d17-b5ba-eec7ca4cbd3a',
        firstName: 'Britney',
        lastName: 'Mcleod',
        email: 'britneymcleod7@gmail.com',

        tiktokProfile: {
          handle: 'mcleodbritzz',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T20:23:35.000Z',
        amountCents: 4000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '439af790-3d33-4263-bd62-10a986ab0b05',
      content: [],
      creator: {
        id: '140bbdc6-5e08-4fbd-9664-31d95a31724d',
        firstName: 'Jacqueline',
        lastName: 'Clarke',
        email: 'vyceroy3@gmail.com',
        instagramProfile: {
          handle: 'danienlah__',
          followers: 6838,
          engagementRate: 0.11406844106463879
        },
        tiktokProfile: {
          handle: 'chyaneb_danienlah',
          followers: 5639,
          engagementRate: 2.3952095808383236
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T20:24:28.000Z',
        amountCents: 2081
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '94329707-bb16-4a20-9687-4cca036daefb',
      content: [],
      creator: {
        id: 'c7b91fa4-a274-4ccb-9442-d4fd3ad866b7',
        firstName: 'Timesha',
        lastName: 'Creary',
        email: 'taiicreary@gmail.com',

        tiktokProfile: {
          handle: 'taii._.taii1',
          followers: 12270,
          engagementRate: 17.629024016351558
        },
        youtubeProfile: {
          handle: 'reviewedbytaii',
          followers: 62
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T20:38:22.000Z',
        amountCents: 2115
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'bdee56a0-4f2b-4923-9ace-bc57bd1d7776',
      content: [],
      creator: {
        id: '39f0c29d-e2c2-443f-8233-2beb86db53c7',
        firstName: 'Holly',
        lastName: 'Amore',
        email: 'hamore79@yahoo.com',

        tiktokProfile: {
          handle: 'hollyamore0',
          followers: 46
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T21:44:27.000Z',
        amountCents: 2078
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '940ce475-311d-4fcb-bbb2-d39982b63002',
      content: [],
      creator: {
        id: 'ebaf1eac-dd3b-4ede-a06a-3cc25fd63423',
        firstName: 'Dee',
        lastName: 'Ophi',
        email: 'opheliadent8@gmail.com',

        tiktokProfile: {
          handle: 'd_contentcreator',
          followers: 0
        },
        youtubeProfile: {
          handle: 'reydanii-gk4xc',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T21:54:11.000Z',
        amountCents: 3580
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'b775f9e5-6723-4a3a-a070-327a9ababf8c',
      content: [],
      creator: {
        id: '203d36d5-4719-4fcf-a3fa-8d401cd2bc91',
        firstName: 'Rianna',
        lastName: 'Evans',
        email: 'dirasnaturels@gmail.com',
        instagramProfile: {
          handle: 'reremilesxo',
          followers: 2019,
          engagementRate: 3.15337625887403
        },
        tiktokProfile: {
          handle: 'dirasmamaa',
          followers: 49
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T23:44:24.000Z',
        amountCents: 2782
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'b440b1bc-22ea-49e2-a81a-bb3f8ed28a76',
      content: [],
      creator: {
        id: '87dad00f-a6e1-4acc-a47b-5c51aa64bbd6',
        firstName: 'Grace',
        lastName: 'Hennessee',
        email: 'hennesseege@yahoo.com',
        instagramProfile: {
          handle: 'goodieswithgraceh',
          followers: 2111,
          engagementRate: 5.056845097110374
        },
        tiktokProfile: {
          handle: 'gehreviews',
          followers: 1052,
          engagementRate: 2.263083451202263
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T00:02:53.000Z',
        amountCents: 2853
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'd0981cb3-e335-4f70-85a7-bc3314b55bce',
      content: [],
      creator: {
        id: 'a9b63734-7a1c-4b15-b4bb-a4935f3a00d5',
        firstName: 'Stacey-ann',
        lastName: 'Cowan',
        email: 'cowanstaceyann30@gmail.com',

        tiktokProfile: {
          handle: 'sammiec80',
          followers: 4162,
          engagementRate: 10.430622009569378
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T00:16:32.000Z',
        amountCents: 2081
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '0a67ec4f-8768-4872-94ed-9daf92cfe4eb',
      content: [],
      creator: {
        id: '7f71bb5c-49ee-4433-a18b-2c8c47dc11e0',
        firstName: 'Shinelle',
        lastName: 'West',
        email: 'shinellewest10@gmail.com',
        instagramProfile: {
          handle: 'pretty_slimthaang',
          followers: 7538,
          engagementRate: 0.7275651816107432
        },
        tiktokProfile: {
          handle: 'pretty_slimthaang',
          followers: 4024,
          engagementRate: 5.420264648824951
        },
        youtubeProfile: {
          handle: 'prettyslimthaang3510',
          followers: 922
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T01:16:21.000Z',
        amountCents: 2780
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '0f575488-65ed-40e8-8bb8-8b7c1a17d9be',
      content: [],
      creator: {
        id: 'd66546d3-cd89-4a7d-b7d4-9b1afa1c2c97',
        firstName: 'Leneisha',
        lastName: 'Gayle',
        email: 'alexdbrown718@gmail.com',

        tiktokProfile: {
          handle: 'alvinbrady426',
          followers: 3
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T01:34:02.000Z',
        amountCents: 2284
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'a10426c7-75d4-480a-b02d-0d6202e8df76',
      content: [],
      creator: {
        id: '52be222e-9713-42bd-a627-67146c331db6',
        firstName: 'Kayan',
        lastName: 'Newell',
        email: 'kayannewell51@gmail.com',

        tiktokProfile: {
          handle: 'kayan_lishous',
          followers: 1895,
          engagementRate: 8.75979804087949
        },
        youtubeProfile: {
          handle: 'kayannewell5243',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T01:44:12.000Z',
        amountCents: 2782
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'c1a9d4e7-9cf5-41f9-80eb-e5d4a43fcd0d',
      content: [],
      creator: {
        id: '8010366b-b58e-44e5-9af0-b27c1e37afba',
        firstName: 'Latoya',
        lastName: 'Palmer',
        email: 'lattieshanicolepalmer28@gmail.com',

        tiktokProfile: {
          handle: 'boymomlatty',
          followers: 1323,
          engagementRate: 10.38961038961039
        },
        youtubeProfile: {
          handle: 'lattyoffical',
          followers: 5
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T01:53:07.000Z',
        amountCents: 5060
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'c07377ba-7298-46d3-9f1d-11eea4d4f253',
      content: [],
      creator: {
        id: 'c04f7e06-6ed2-4ae4-9af4-f2c0625382a5',
        firstName: 'Candiece',
        lastName: 'Grant',
        email: 'divadaily152@gmail.com',

        tiktokProfile: {
          handle: 'closetrehabb',
          followers: 15,
          engagementRate: 9.109730848861282
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T02:05:46.000Z',
        amountCents: 3004
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '7bd32075-9327-4e3a-af80-b93028c640f6',
      content: [],
      creator: {
        id: 'dcb5cc7c-e52a-4452-b0b0-0d96caae9781',
        firstName: 'Samantia',
        lastName: 'Richards',
        email: 'samantiar@gmail.com',
        instagramProfile: {
          handle: 'sammiismiless',
          followers: 677,
          engagementRate: 0.9453471196454949
        },
        tiktokProfile: {
          handle: 'sammiismilesss',
          followers: 12195,
          engagementRate: 7.045009784735812
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T02:23:37.000Z',
        amountCents: 2889
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '7e97656e-a968-4b9e-9607-4179cb64f03f',
      content: [],
      creator: {
        id: '144e799e-25af-46c7-869a-6aaed42f4cb9',
        firstName: 'Dellon',
        lastName: 'Dixon',
        email: 'dellondixon@gmail.com',
        instagramProfile: {
          handle: 'dashcam_technologies',
          followers: 799,
          engagementRate: 0.5214851898206091
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T04:24:15.000Z',
        amountCents: 1625
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '84520d60-3c6b-448c-af76-a0dd9e4acea1',
      content: [],
      creator: {
        id: '75b8fe50-87cd-4478-a0b2-41c8715abe95',
        firstName: 'Sabrena',
        lastName: 'Outar ',
        email: 'sabrenaoutar7@gmail.com',

        tiktokProfile: {
          handle: 'pinkchiffon25',
          followers: 669,
          engagementRate: 13.990825688073393
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T04:44:13.000Z',
        amountCents: 2782
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'caf2e07e-46d0-4890-a4f3-239ccb091bd4',
      content: [],
      creator: {
        id: 'a7dcd0a1-1937-442d-8c06-4228fcd5743e',
        firstName: 'Judith',
        lastName: 'Ricketts',
        email: 'jsimpsonricketts@gmail.com',

        tiktokProfile: {
          handle: 'j.rick40',
          followers: 0
        },
        youtubeProfile: {
          handle: 'judenericketts198',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T04:44:27.000Z',
        amountCents: 4908
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '8085a7d9-0def-498f-88ae-3ed5f08e6bb3',
      content: [],
      creator: {
        id: '7c33d486-7166-40e8-b20e-9a80625f2878',
        firstName: 'Nelle',
        lastName: 'Taylor',
        email: 'jenelle_96@hotmail.com',

        tiktokProfile: {
          handle: 'empress_nellee',
          followers: 1425
        },
        youtubeProfile: {
          handle: 'jenelletaylor5092',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T04:57:54.000Z',
        amountCents: 2406
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'c61d36a1-3e97-4b57-be76-d5ecabad8e71',
      content: [],
      creator: {
        id: '5797c426-4a81-471a-897a-760ee63f10d2',
        firstName: 'Tashena',
        lastName: 'Hall',
        email: 'dimanibudram17@gmail.com',

        tiktokProfile: {
          handle: 'tashbabylee',
          followers: 5668,
          engagementRate: 8.114035087719298
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T07:42:36.000Z',
        amountCents: 3105
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'dd83b515-ed39-4ef8-8c5d-f6051f90cf16',
      content: [],
      creator: {
        id: '1cec5322-138b-4a16-b957-a06141b28b59',
        firstName: 'Dannisha',
        lastName: 'Nicholson',
        email: 'dannishanicholson@hotmail.com',
        instagramProfile: {
          handle: 'dannisha_nicholson',
          followers: 921,
          engagementRate: 3.5791757049891544
        },
        tiktokProfile: {
          handle: 'zahtyrerepairs',
          followers: 28
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T07:44:05.000Z',
        amountCents: 2083
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '2cab2d05-9811-4309-aedb-603be1dc2c13',
      content: [],
      creator: {
        id: '9a8d36ef-d655-4c50-8a83-d8a778e700d3',
        firstName: 'Gavinn',
        lastName: 'Woolcock',
        email: 'djmafia101@gmail.com',

        tiktokProfile: {
          handle: 'mafi_xfactor',
          followers: 261
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T07:44:28.000Z',
        amountCents: 2406
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'b240a837-4003-433f-a22f-229c58eb23d5',
      content: [],
      creator: {
        id: 'ca1f26bb-221b-4ea9-8f32-7de55615c704',
        firstName: 'Sasha-Kay',
        lastName: 'Baker',
        email: 'princess12ab34@gmail.com',
        instagramProfile: {
          handle: 'princess_darkbeauty',
          followers: 260
        },
        tiktokProfile: {
          handle: 'princess_darkbeauty',
          followers: 217,
          engagementRate: 2.0118343195266273
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T09:12:47.000Z',
        amountCents: 2139
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '4001994c-d294-4bc6-b4c2-a23ba7569d76',
      content: [],
      creator: {
        id: '49f869b0-c4a6-4ed8-992e-e598def376e8',
        firstName: 'Abigail',
        lastName: 'Ellis',
        email: 'laceherup8@gmail.com',
        instagramProfile: {
          handle: 'lace_her_up',
          followers: 870,
          engagementRate: 11.264367816091953
        },
        tiktokProfile: {
          handle: 'abbey._abbey',
          followers: 390,
          engagementRate: 6.95364238410596
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T10:04:03.000Z',
        amountCents: 3082
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'ff753beb-ac96-4c19-8c85-d01e04ed70e2',
      content: [],
      creator: {
        id: '1bcc7557-4cf6-42b6-a008-744ed9ca36da',
        firstName: 'Tara',
        lastName: 'Smith',
        email: 'diva-patrol.0v@icloud.com',

        tiktokProfile: {
          handle: 'tarabertone',
          followers: 0
        },
        youtubeProfile: {
          handle: 'tm-reviews',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T10:13:39.000Z',
        amountCents: 2701
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '2db5c148-4c7f-43bf-b473-998efcbf0fde',
      content: [],
      creator: {
        id: '64933406-9be2-4547-ae50-c47ec2516fcc',
        firstName: 'Yanique',
        lastName: 'Douglas',
        email: 'yaniquedouglas94@gmail.com',

        tiktokProfile: {
          handle: 'iamcandiece',
          followers: 2056
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T10:44:32.000Z',
        amountCents: 4044
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '7f46c6e1-03db-48f6-89ec-34cb1382774c',
      content: [],
      creator: {
        id: 'afd7d5b9-a9c9-4ea7-8b3a-b17ef1245245',
        firstName: 'Liz',
        lastName: 'Harvey',
        email: 'tlddharvey@gmail.com',

        tiktokProfile: {
          handle: 'listwithlizharvey',
          followers: 179
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T14:44:52.000Z',
        amountCents: 2078
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '17c4a9bc-c8cf-4f25-ac51-63862cd98a54',
      content: [],
      creator: {
        id: '2856ee9c-7700-4b3e-844b-73b3f1efc534',
        firstName: 'Sania ',
        lastName: 'Sue ',
        email: 'camrynharrison927@gmail.com',

        tiktokProfile: {
          handle: 'camrynharrison5',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T16:00:52.000Z',
        amountCents: 5000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '436ac635-5cec-4992-866f-8e452b844494',
      content: [],
      creator: {
        id: 'f633e4cc-58da-4951-a560-bae240363e54',
        firstName: 'Danielle',
        lastName: 'Reid',
        email: 'danielle@drandassociates.com',
        instagramProfile: {
          handle: 'iamdaniellereid',
          followers: 9404,
          engagementRate: 1.271799234368354
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T16:14:54.000Z',
        amountCents: 2083
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '35f7ad6f-2f69-4700-8f23-2975d3cad046',
      content: [],
      creator: {
        id: 'd0073f57-5eb7-4703-a44a-74681f89d335',
        firstName: 'Candiece',
        lastName: 'Grant',
        email: 'blushbyc23@yahoo.com',

        tiktokProfile: {
          handle: 'blushbaby52',
          followers: 0
        },
        youtubeProfile: {
          handle: 'candiecegrant6740',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T16:24:16.000Z',
        amountCents: 4310
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'dc0de089-1a87-4422-9a30-971dec634328',
      content: [],
      creator: {
        id: '1b8a2d08-5fa5-4d72-83d2-fee3f577ec3c',
        firstName: 'Shinnel',
        lastName: 'Sewell',
        email: 'nicenesspartysupplies@gmail.com',

        tiktokProfile: {
          handle: 'nickrush',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T16:26:51.000Z',
        amountCents: 4279
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '23f2e036-760f-47fd-9efb-a4147ef6258e',
      content: [],
      creator: {
        id: '4aedfe8d-23e6-4111-835d-daf40e46b5d7',
        firstName: 'Noble',
        lastName: 'Family',
        email: 'reinamnoble@gmail.com',

        tiktokProfile: {
          handle: 'noblefamily73',
          followers: 0
        },
        youtubeProfile: {
          handle: 'reina-marienoble1323',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T16:51:27.000Z',
        amountCents: 3794
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '0fbde67e-6f6b-4177-8630-729b12debd55',
      content: [],
      creator: {
        id: '958ac6c8-3e4f-46e2-92ed-2eef03eee14d',
        firstName: 'cami',
        lastName: 'parziale ',
        email: 'cparziale07@icloud.com',

        tiktokProfile: {
          handle: 'kanyewestsunderarmhair',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T16:54:03.000Z',
        amountCents: 2950
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '4e9818cd-d89e-4022-a3dd-04c7b5d1cb38',
      content: [],
      creator: {
        id: 'f147adc2-96e5-49f7-b948-1c77911980f8',
        firstName: 'Treshana',
        lastName: 'Bonner',
        email: 'perfectsogos@gmail.com',
        instagramProfile: {
          handle: 'perfect_sogos',
          followers: 50
        },

        youtubeProfile: {
          handle: 'perfectsogos',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T17:10:39.000Z',
        amountCents: 5000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '9f9998c3-a959-4fab-826e-becc59ae7ad1',
      content: [],
      creator: {
        id: 'eda36b09-c2c7-4924-8874-d8317d8e64e6',
        firstName: 'Racquel',
        lastName: 'Harrison',
        email: 'tateakeem30@gmail.com',

        tiktokProfile: {
          handle: 'cocobread_clenz',
          followers: 10486,
          engagementRate: 17.040027132297926
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T18:24:02.000Z',
        amountCents: 2088
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'd6d9f62d-447c-4cd5-9fb8-02e314b2e4b3',
      content: [],
      creator: {
        id: 'd68d4d95-681b-406c-a14d-907d811378cc',
        firstName: 'Tia',
        lastName: 'Doyley',
        email: 'tiadoyley553@gmail.com',

        tiktokProfile: {
          handle: 'pinkey_not_so_friendly',
          followers: 9138,
          engagementRate: 11.855670103092782
        },
        youtubeProfile: {
          handle: 'princessniah-yd4wz',
          followers: 13
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T18:24:16.000Z',
        amountCents: 2869
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'd07ccf8a-2d8f-41e0-8d61-b27c5ce6cd22',
      content: [],
      creator: {
        id: 'e03ed731-128f-4bb2-bb66-4238f6bcd997',
        firstName: 'Alfien',
        lastName: 'Divinagracia',
        email: 'alfienscloset@gmail.com',

        tiktokProfile: {
          handle: 'alfien223',
          followers: 60,
          engagementRate: 0
        },
        youtubeProfile: {
          handle: 'alfiendivinagracia9293',
          followers: 15
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T18:42:26.000Z',
        amountCents: 3209
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '9dda27b0-4e51-4736-a425-81e5354c1883',
      content: [],
      creator: {
        id: '67415ae7-ced7-4d54-b625-776c20bf9f12',
        firstName: 'Isabelle',
        lastName: 'Schreiner',
        email: 'beautybybellanicole@gmail.com',

        tiktokProfile: {
          handle: 'bellalatethannever',
          followers: 10247
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T20:13:58.000Z',
        amountCents: 2107
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '67d804f6-f792-4e2a-ad9d-f459e83e0a7f',
      content: [],
      creator: {
        id: 'cb21d6ac-3a0e-40f1-a967-83fd2fe280bd',
        firstName: 'Saphire',
        lastName: 'Gillespie',
        email: 'saphiregillespie123@gmail.com',
        instagramProfile: {
          handle: 'saphyerr',
          followers: 42
        },
        tiktokProfile: {
          handle: 'saphirevg',
          followers: 111
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T21:22:42.000Z',
        amountCents: 2669
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '44686373-f700-4ff9-a246-50cabb60cad8',
      content: [],
      creator: {
        id: '9cbc916b-a538-4476-8ea1-7830434594e7',
        firstName: 'Brandon',
        lastName: 'Lamoth',
        email: 'lamothbrandon00@gmail.com',

        tiktokProfile: {
          handle: 'lmot14',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T21:52:17.000Z',
        amountCents: 6000
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '23d74d0a-8678-40f2-8b4b-320cf8372a41',
      content: [],
      creator: {
        id: '70346f57-f7e4-454b-b091-9512e768e7bf',
        firstName: 'Christina',
        lastName: 'Thompson',
        email: 'thompsonchristina742@gmail.com',

        tiktokProfile: {
          handle: '_gorgeous.cc',
          followers: 99
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T23:21:44.000Z',
        amountCents: 2198
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '5fd5dc7f-52ed-4bbf-8fb5-6f23483389c3',
      content: [],
      creator: {
        id: '522000b3-30a1-4c0e-a678-988ff78e2bf6',
        firstName: 'Meiry',
        lastName: 'Araujo',
        email: 'meiryedilla@gmail.com',
        instagramProfile: {
          handle: 'mother__reviews',
          followers: 39149,
          engagementRate: 1.5456333495108432
        },
        tiktokProfile: {
          handle: 'mother__reviews',
          followers: 10022,
          engagementRate: 3.8640571277439832
        },
        youtubeProfile: {
          handle: 'meiryedilla',
          followers: 2
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T00:07:56.000Z',
        amountCents: 2088
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '7873aac3-e1ea-4f17-b410-a9b68dc6761d',
      content: [],
      creator: {
        id: '26bd38b9-47d4-4216-9048-6ba2234ffe20',
        firstName: 'Reina',
        lastName: 'Noble',
        email: 'dariennoble5@gmail.com',

        tiktokProfile: {
          handle: 'reinamarie.noble',
          followers: 0
        },
        youtubeProfile: {
          handle: 'dariennoble',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T00:22:41.000Z',
        amountCents: 4766
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '8e500ec4-42d0-4bd7-8c17-028298720d87',
      content: [],
      creator: {
        id: '3de774f5-4ee0-4356-af65-4d59d539c165',
        firstName: 'Kiorah',
        lastName: 'Allen',
        email: 'kuitekiddlala391@gmail.com',

        tiktokProfile: {
          handle: 'joydiandaley.cosmetics',
          followers: 222
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T00:27:48.000Z',
        amountCents: 2866
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'd33002a1-28a6-4844-bdd9-4ae69416091d',
      content: [],
      creator: {
        id: '8011d103-60f9-409d-8cc5-d81e462bba21',
        firstName: 'Shinnel',
        lastName: 'Sewell',
        email: 'matthewrgbennett@gmail.com',

        tiktokProfile: {
          handle: 'matthew.bennett60',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T01:35:08.000Z',
        amountCents: 3998
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'ad3fa6f2-d7d3-48bd-8e92-c756107a3a4e',
      content: [],
      creator: {
        id: '69627e26-aca4-464f-9b35-b261dfae5212',
        firstName: 'Mona',
        lastName: 'Lisa',
        email: 'nobodybusiness33@yahoo.com',

        tiktokProfile: {
          handle: 'nobodnqzrer',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T05:03:05.000Z',
        amountCents: 1363
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'd8265dcd-faf8-46a3-8fad-7baf94283c45',
      content: [],
      creator: {
        id: '191075b7-035d-4c5a-aab0-fa70c228650e',
        firstName: 'Sooyen',
        lastName: 'Gordon',
        email: 'beingsu3@gmail.com',
        instagramProfile: {
          handle: 'beingsu3',
          followers: 713,
          engagementRate: 1.6409537166900419
        },
        tiktokProfile: {
          handle: 'sueology',
          followers: 461,
          engagementRate: 9.30232558139535
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T05:26:23.000Z',
        amountCents: 2406
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '37dfad9b-56f3-4813-855b-6e931d368b3d',
      content: [],
      creator: {
        id: 'bdf9c31f-1b3d-44d4-8fce-0ee353c9d993',
        firstName: 'Moesha',
        lastName: 'Elliott',
        email: 'cn1.moesha.elliott@gmail.com',

        tiktokProfile: {
          handle: 'moeshaelliott',
          followers: 4710
        },
        youtubeProfile: {
          handle: 'moeshaelliott2664',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T05:44:16.000Z',
        amountCents: 2787
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '8649981f-7fd6-4724-9486-63f232a1d4a6',
      content: [],
      creator: {
        id: 'f2128105-bb35-4632-9d35-d15b6dcf30a1',
        firstName: 'Licia',
        lastName: 'Skyers',
        email: 'skyerslicia@gmail.com',
        instagramProfile: {
          handle: 'lightbeing1224',
          followers: 1990,
          engagementRate: 1.1306532663316584
        },
        tiktokProfile: {
          handle: 'lightbeing90',
          followers: 2308,
          engagementRate: 13.131313131313133
        },
        youtubeProfile: {
          handle: 'liciaskyers1377',
          followers: 6
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T05:55:10.000Z',
        amountCents: 3112
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'bea06765-2e1f-442b-9967-58229ae7d28d',
      content: [],
      creator: {
        id: '72fa1d39-8c76-4731-bbd5-099c48d53746',
        firstName: 'Jasmine',
        lastName: 'Jackson ',
        email: 'jasminepagejackson@yahoo.com',

        tiktokProfile: {
          handle: 'blvck.beauty_',
          followers: 58
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T06:08:00.000Z',
        amountCents: 1984
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '97ca570c-ff70-428f-8c16-ccb8cd2a54e8',
      content: [],
      creator: {
        id: '2ee05417-f63e-4436-a94b-2822b6760efe',
        firstName: 'Oraine',
        lastName: 'Easy',
        email: 'easyoraine885@gmail.com',

        tiktokProfile: {
          handle: 'kinggazg',
          followers: 50
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T08:02:50.000Z',
        amountCents: 4699
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'e728832d-8890-4f18-bcef-4f4cd343fcf9',
      content: [],
      creator: {
        id: '1e369002-ec80-48a3-b55a-9c9a07523193',
        firstName: 'Shanoy',
        lastName: 'Gordon',
        email: 'shanoyfebruary12@gmail.com',
        instagramProfile: {
          handle: 'sha._noy',
          followers: 3212,
          engagementRate: 3.147571606475716
        },
        tiktokProfile: {
          handle: 'shummey76',
          followers: 3395,
          engagementRate: 3.88483575790253
        },
        youtubeProfile: {
          handle: 'shummey122',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T09:34:04.000Z',
        amountCents: 2782
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'ef9de652-c35d-41ea-a785-9beae7332b98',
      content: [],
      creator: {
        id: '048ebd67-1a33-426d-8317-23e7a184a0d9',
        firstName: 'Mekeda',
        lastName: 'Carwin',
        email: 'carwinmekeda@gmail.com',
        instagramProfile: {
          handle: '__keeda__',
          followers: 3013
        },
        tiktokProfile: {
          handle: 'keeeda__',
          followers: 202
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T12:26:24.000Z',
        amountCents: 2650
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '14d16941-8e17-41c7-b438-73f87571048e',
      content: [],
      creator: {
        id: '2838d2a2-596f-4c4d-bf41-727549c0a77a',
        firstName: 'Jody -Ann',
        lastName: 'Skerritt',
        email: 'skerrittjodyann@gmail.com',

        tiktokProfile: {
          handle: 'yow_empress',
          followers: 797,
          engagementRate: 9.606299212598426
        },
        youtubeProfile: {
          handle: 'jody-annskerritt2429',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T13:54:31.000Z',
        amountCents: 3344
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '105ca24b-0766-4c97-a5f8-f5bab2f23819',
      content: [],
      creator: {
        id: '95aa0c46-7cc8-425b-a972-16a8e07c211c',
        firstName: 'deasionna',
        lastName: 'edwards',
        email: 'edeasionna@gmail.com',
        instagramProfile: {
          handle: 'allthingsdaee',
          followers: 1133
        },
        tiktokProfile: {
          handle: '....daediorr',
          followers: 479
        },
        youtubeProfile: {
          handle: 'deasionnaedwards9458',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T16:02:29.000Z',
        amountCents: 2885
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'dac8e0dc-1503-4fad-9cbd-36dbab583e43',
      content: [],
      creator: {
        id: 'b6c1b747-a213-47ae-acc1-b09b6c61c36a',
        firstName: 'Cloudy',
        lastName: 'Grant',
        email: 'grantcandiece9292@icloud.com',

        tiktokProfile: {
          handle: 'icloud9292',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T16:14:20.000Z',
        amountCents: 4980
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '65aca28f-cc33-4cd1-acd7-ecc7f2633622',
      content: [],
      creator: {
        id: 'ded1353f-d688-4925-bb79-e664fe1cda6e',
        firstName: 'Dee',
        lastName: 'Basped',
        email: 'dee.strain6@gmail.com',
        instagramProfile: {
          handle: 'dee_basped6',
          followers: 1360,
          engagementRate: 3.257650542941757
        },
        tiktokProfile: {
          handle: 'dee.006',
          followers: 947,
          engagementRate: 8.071041562700506
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T16:22:24.000Z',
        amountCents: 2148
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '5d4fa4ce-6624-40d1-bc02-f38a66307f6a',
      content: [],
      creator: {
        id: '1639057f-94ea-4382-9557-11b1e04b9414',
        firstName: 'Jessica',
        lastName: 'West',
        email: 'collabwjess@gmail.com',
        instagramProfile: {
          handle: 'jess.di0rr',
          followers: 42344,
          engagementRate: 0.2326185528055923
        },
        tiktokProfile: {
          handle: 'jess.di0rr',
          followers: 8752,
          engagementRate: 6.8176160138299995
        },
        youtubeProfile: {
          handle: 'jess.di0rr',
          followers: 24
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T16:24:57.000Z',
        amountCents: 2749
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'fca2b23a-244d-4658-afbc-159b2ef7b2d3',
      content: [],
      creator: {
        id: 'b27d4791-3444-4c29-b9fc-7592120238f1',
        firstName: 'Jaida',
        lastName: 'Henley',
        email: 'jaidahenley11@gmail.com',

        tiktokProfile: {
          handle: 'jaida.henley',
          followers: 12225,
          engagementRate: 10.11209288739594
        },
        youtubeProfile: {
          handle: 'jaidahenley',
          followers: 1890
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T16:30:43.000Z',
        amountCents: 2125
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '89841fb6-eb92-49a5-95de-63e556a87373',
      content: [],
      creator: {
        id: 'b956a94d-2383-4ecf-bfae-3e3a64df6695',
        firstName: 'Kristina',
        lastName: 'Carter',
        email: 'sxbestb@gmail.com',

        tiktokProfile: {
          handle: 'kdoesreviews',
          followers: 2,
          engagementRate: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T16:33:02.000Z',
        amountCents: 2113
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'c4b1027a-3d34-4a86-9561-9b20127e5f73',
      content: [],
      creator: {
        id: 'aa395163-5ddb-42aa-8bef-d9032f50679b',
        firstName: 'Paul',
        lastName: 'Wang',
        email: 'wangpokai@yahoo.com.tw',

        tiktokProfile: {
          handle: 'yoyowang22',
          followers: 70
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T16:38:57.000Z',
        amountCents: 3138
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '4d2544a3-0c14-403e-a232-204b86106c36',
      content: [],
      creator: {
        id: 'b216780b-3e83-4a16-8fa5-06fbf99bb476',
        firstName: 'Dana',
        lastName: 'Barrett',
        email: 'barrettdana81@gmail.com',

        tiktokProfile: {
          handle: 'dime_rose21',
          followers: 9649,
          engagementRate: 9.50354609929078
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T16:47:06.000Z',
        amountCents: 6000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'b0d39cb5-b4d7-4f29-aaeb-e49944f78ecc',
      content: [],
      creator: {
        id: '19ed1e2d-3570-4a98-b32f-ca1aacda625e',
        firstName: 'Moses',
        lastName: 'Nelson',
        email: 'moseslnelson778@gmail.com',

        tiktokProfile: {
          handle: 'nelsonn0503',
          followers: 11
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T16:52:44.000Z',
        amountCents: 5000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '15bc5881-2aa8-4d7a-ac67-43d7d80460af',
      content: [],
      creator: {
        id: 'b553e8fe-5d06-4579-81d4-bac3e082de34',
        firstName: 'Gerico',
        lastName: 'Soller',
        email: 'lastbiteboys@gmail.com',
        instagramProfile: {
          handle: 'lastbiteboys',
          followers: 764,
          engagementRate: 28.24607329842932
        },
        tiktokProfile: {
          handle: 'last.bite.boys',
          followers: 1037,
          engagementRate: 6.8928834128088035
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T18:22:52.000Z',
        amountCents: 2125
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '7a3c6eac-ef36-4b78-97b2-9b04a3f2bc27',
      content: [],
      creator: {
        id: 'b7aa6cc0-eb91-4b58-bc60-f501b6331039',
        firstName: 'Donadine',
        lastName: 'Barrett',
        email: 'donadinebarrett@gmail.com',

        tiktokProfile: {
          handle: 'ishanz_ibee',
          followers: 5183,
          engagementRate: 16.333333333333332
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T18:35:33.000Z',
        amountCents: 4000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '436159dc-fe76-430e-9673-efcff95b45d9',
      content: [],
      creator: {
        id: 'c7f0b70b-32d0-418c-89eb-63610b4d425b',
        firstName: 'Destiny',
        lastName: 'Reed',
        email: 'destinyreed100@gmail.com',

        tiktokProfile: {
          handle: 'thatsjustdestinyy',
          followers: 2787,
          engagementRate: 11.8290884236276
        },
        youtubeProfile: {
          handle: 'thatsjustdestinyy',
          followers: 1410
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T18:56:42.000Z',
        amountCents: 2841
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'e164ab64-807c-4534-b9aa-5d8eaf7faf99',
      content: [],
      creator: {
        id: 'da0626aa-4c4e-4093-8d47-7b2e8fcfce24',
        firstName: 'Shannon',
        lastName: 'ONeil',
        email: 'shaas0721@yahoo.com',
        instagramProfile: {
          handle: 'shanna_maureen',
          followers: 10661,
          engagementRate: 4.258512334677798
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T19:15:05.000Z',
        amountCents: 2077
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '2dae1a96-830d-4b1c-a71f-a274840e2b80',
      content: [],
      creator: {
        id: '350ce40d-d6c7-4432-afa7-d40a93af85c9',
        firstName: 'Marsh',
        lastName: 'Do',
        email: 'aboho369@gmail.com',
        instagramProfile: {
          handle: 'marshian.store',
          followers: 0,
          engagementRate: 25
        },
        tiktokProfile: {
          handle: 'itsnotfunnyatalll',
          followers: 52,
          engagementRate: 25
        },
        youtubeProfile: {
          handle: 'minhdohuongnoi',
          followers: 162
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T19:16:30.000Z',
        amountCents: 1456
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'c21d9c41-d92e-4f5a-a92b-25d049dc144c',
      content: [],
      creator: {
        id: '4969c37c-6776-4f53-9314-867e8f24ea72',
        firstName: 'Omar',
        lastName: 'Shagouri Brown',
        email: 'cunninghamb98@yahoo.com',

        tiktokProfile: {
          handle: 'obrown54',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T19:33:20.000Z',
        amountCents: 6000
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '3e55d5d4-1c41-400f-b530-d43adbbcf2b5',
      content: [],
      creator: {
        id: 'ed5401c4-515c-429c-843b-a5fe280ecb3b',
        firstName: 'Jodi-Ann',
        lastName: 'Thomas',
        email: 'thomasjodiann6@gmail.com',

        tiktokProfile: {
          handle: 'bad._.dest',
          followers: 1208,
          engagementRate: 5.288461538461538
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T20:04:07.000Z',
        amountCents: 1941
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '98248e8e-05aa-4d72-86ba-87e009d1b668',
      content: [],
      creator: {
        id: 'd0d30e19-ad0d-4c95-9036-04e5a1bfe5f5',
        firstName: 'Tatina',
        lastName: 'Coley',
        email: 'tatinacoley2001@gmail.com',

        tiktokProfile: {
          handle: '_.tinaaa.___',
          followers: 1491
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T20:08:09.000Z',
        amountCents: 4000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '66e3e933-d843-483a-9fa9-ee64cfb9293d',
      content: [],
      creator: {
        id: '6ef07f4a-14f7-4d6a-8830-3b692f0dd80e',
        firstName: 'Brittany ',
        lastName: 'Wallace',
        email: 'may30wallace@gmail.com',

        tiktokProfile: {
          handle: 'javiii.mac',
          followers: 442,
          engagementRate: 4.240049751243781
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T20:35:02.000Z',
        amountCents: 5000
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '5bd12877-d5e2-44d1-b9c3-354b75673d88',
      content: [],
      creator: {
        id: '8dab5a4a-fad7-48c8-a1da-91ec65c71879',
        firstName: 'James',
        lastName: 'Candolita',
        email: 'eric1cartman0000@gmail.com',

        tiktokProfile: {
          handle: 'wwhellowww',
          followers: 1327
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T20:39:16.000Z',
        amountCents: 2038
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '2c4a10e1-5c0b-43ad-ba5e-a47705abaa2e',
      content: [],
      creator: {
        id: '223a59a0-6399-427d-b4da-4aecc0346e14',
        firstName: 'Denique',
        lastName: 'Pink',
        email: 'deniquepink@icloud.com',
        instagramProfile: {
          handle: 'fantasticpleasure_ja',
          followers: 75,
          engagementRate: 1.3333333333333335
        },
        tiktokProfile: {
          handle: 'deniquepink',
          followers: 1504,
          engagementRate: 9.090909090909092
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T21:34:37.000Z',
        amountCents: 2077
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '566f50b0-22dc-4907-83b3-3c1405958f91',
      content: [],
      creator: {
        id: '53a2e703-25da-440f-8ccf-7aaeb53dc9cf',
        firstName: 'Darien',
        lastName: 'Noble',
        email: 'dariennoble1@gmail.com',

        tiktokProfile: {
          handle: 'darien.noble5',
          followers: 0
        },
        youtubeProfile: {
          handle: 'dnaorbilen5343',
          followers: 12
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-25T01:11:43.000Z',
        amountCents: 4978
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'a25dc940-a150-4f85-ba7c-7392c280b67e',
      content: [],
      creator: {
        id: '941bc2e7-4765-40fa-a885-d5768ea50125',
        firstName: 'Judith',
        lastName: 'Simpson-Ricketts',
        email: 'rickettsnaomi24@gmail.com',

        tiktokProfile: {
          handle: 'jaii_1999',
          followers: 14
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-25T02:06:14.000Z',
        amountCents: 6854
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '8501d226-d611-48fb-b8c0-1aa6e36b311a',
      content: [],
      creator: {
        id: '195de1cd-d289-461a-a4e8-28da5d82ca9c',
        firstName: 'Crystal',
        lastName: 'Passley',
        email: 'crystalpassley64@gmail.com',

        tiktokProfile: {
          handle: 'shorty_crissy',
          followers: 1213,
          engagementRate: 16.184971098265898
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-25T02:45:27.000Z',
        amountCents: 2998
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'f6b57029-e7d6-41ff-a235-34cf47971ae0',
      content: [],
      creator: {
        id: '38e1585e-3405-4fc0-9b16-19e43369e339',
        firstName: 'Ashley',
        lastName: 'G',
        email: 'shyshygraham17@gmail.com',

        tiktokProfile: {
          handle: 'itssjustashley',
          followers: 363
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-25T03:04:07.000Z',
        amountCents: 2776
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '652f1f66-ce5e-43c6-8638-4d88e9d0178c',
      content: [],
      creator: {
        id: '178a67a6-5d05-4b30-bbeb-4cd901d76566',
        firstName: 'Brandon',
        lastName: 'Lamoth',
        email: 'brandonlamoth933@gmail.com',

        tiktokProfile: {
          handle: 'brandnvsrsd',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-25T03:56:04.000Z',
        amountCents: 6000
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '5a7814d7-cac4-4df8-b32c-e3f9eacc66c2',
      content: [],
      creator: {
        id: 'c97fbe1b-6713-4a32-a08c-2cc07e3e627b',
        firstName: 'Natasha',
        lastName: 'Thomas',
        email: 'natashathomas048@gmail.com',

        tiktokProfile: {
          handle: 'fentyadvise',
          followers: 1400,
          engagementRate: 12.154696132596685
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-25T04:24:10.000Z',
        amountCents: 2787
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '42273300-3874-4609-a7c1-64455535b731',
      content: [],
      creator: {
        id: '23afb3c2-78cc-49a3-8438-800f72333ffb',
        firstName: 'Crystal',
        lastName: 'Gayle',
        email: 'fenlaterorandy@gmail.com',

        tiktokProfile: {
          handle: 'crissy2209',
          followers: 0
        },
        youtubeProfile: {
          handle: 'crystalgayle633',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-25T04:46:33.000Z',
        amountCents: 2799
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '49cea424-19ec-4619-8dcf-a84f196270a8',
      content: [],
      creator: {
        id: 'd289a18f-33b3-4d17-a412-5862ae9dfa40',
        firstName: 'Latoya',
        lastName: 'Noble',
        email: 'dariennoble2@gmail.com',

        tiktokProfile: {
          handle: 'darien.noble2',
          followers: 0
        },
        youtubeProfile: {
          handle: 'dariennoble-ic3ss',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-25T06:06:12.000Z',
        amountCents: 5387
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '2add5c4e-0e30-4f82-b69d-ed59d3ee8655',
      content: [],
      creator: {
        id: 'e1b551b7-39dd-4034-aa27-9b20c357ca0b',
        firstName: 'Felicia',
        lastName: 'Sinclair',
        email: 'feliciasinclair@yahoo.com',

        tiktokProfile: {
          handle: 'giannasinclair13',
          followers: 473,
          engagementRate: 10
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-25T09:34:04.000Z',
        amountCents: 1978
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'f4d74c24-b682-4b10-9816-36be2d7540b6',
      content: [],
      creator: {
        id: '40a630e1-9ff3-467d-bdf6-7404b817f493',
        firstName: 'Taneka ',
        lastName: 'Findlay ',
        email: 'tanekafindlay@gmail.com',

        tiktokProfile: {
          handle: 'tan_eka',
          followers: 6790
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-25T10:12:36.000Z',
        amountCents: 2787
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'fcbb1c12-6779-4c1a-8037-e54462f52d82',
      content: [],
      creator: {
        id: '3f3c1cbe-b767-41f5-bb9c-5a0a775335a3',
        firstName: 'Tanesha',
        lastName: 'Moody',
        email: 'taneshamoody2528@gmail.com',
        instagramProfile: {
          handle: 'freelancersbest',
          followers: 1766,
          engagementRate: 0.7644394110985278
        },
        tiktokProfile: {
          handle: 'moody2glow',
          followers: 1459,
          engagementRate: 21.53846153846154
        },
        youtubeProfile: {
          handle: 'superawesomechannel522',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-25T10:54:24.000Z',
        amountCents: 2782
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '3eb8637d-9ae3-481e-b8c6-7f501e821d56',
      content: [],
      creator: {
        id: 'ec53ecd8-846e-4ce3-aea8-43916ae02df4',
        firstName: 'Zoann',
        lastName: 'Brown',
        email: 'zobrown056@gmail.com',

        tiktokProfile: {
          handle: 'zagga20',
          followers: 522
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-25T12:44:11.000Z',
        amountCents: 2224
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'b8ca1e88-ae0f-4145-8c43-4718d9548a8a',
      content: [],
      creator: {
        id: 'fbc2182f-a233-4961-8eaf-96ec8d75deae',
        firstName: 'Mekaila',
        lastName: 'Fitzgerald',
        email: 'mekailadfitzgerald13@gmail.com',

        tiktokProfile: {
          handle: 'derahh._',
          followers: 979,
          engagementRate: 8.539167254763585
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-25T16:13:07.000Z',
        amountCents: 2200
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '140566f6-d6d6-4ed2-9ef2-7e19311003b9',
      content: [],
      creator: {
        id: '7a55984d-e00b-4c08-94e0-e52b05d76536',
        firstName: 'Shiann',
        lastName: 'Bent',
        email: 'shiannbent@yahoo.com',
        instagramProfile: {
          handle: 'shyyyb1',
          followers: 788,
          engagementRate: 4.073604060913706
        },
        tiktokProfile: {
          handle: 'shyyyb1',
          followers: 1058,
          engagementRate: 10.287081339712918
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-25T16:17:16.000Z',
        amountCents: 4668
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '65c437be-63df-4696-971a-194ebe2aaa95',
      content: [],
      creator: {
        id: 'd5de5812-e788-451d-a1a8-ef70f9611ee2',
        firstName: 'Albert',
        lastName: 'James',
        email: 'flashalbert0301@gmail.com',

        tiktokProfile: {
          handle: 'albertjames741',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-25T16:35:20.000Z',
        amountCents: 3194
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'b09867d9-aa66-4340-afe9-65e863b34f85',
      content: [],
      creator: {
        id: 'd460947d-743a-4b3d-b98d-bb59eff5d80f',
        firstName: 'Enarria',
        lastName: 'Rollins',
        email: 'erollins18@yahoo.com',

        tiktokProfile: {
          handle: 'cay_dab',
          followers: 172
        },
        youtubeProfile: {
          handle: 'cay_dab',
          followers: 8
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-25T17:24:58.000Z',
        amountCents: 2122
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'e628ce67-e95d-4c17-ae9a-093c62996598',
      content: [],
      creator: {
        id: 'eaeade64-475b-4ecb-8f17-6e2868f5788b',
        firstName: 'Katrina',
        lastName: 'Thomas',
        email: 'nicholskatrina1@gmail.com',
        instagramProfile: {
          handle: 'katrina.k.life._',
          followers: 23858,
          engagementRate: 0.5172269259787075
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-25T17:36:32.000Z',
        amountCents: 1938
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '342b721b-8f08-4653-b640-5ee50b76da27',
      content: [],
      creator: {
        id: '95311784-1670-4e17-b37b-79ee0e758649',
        firstName: 'Dennicia',
        lastName: 'Burrell',
        email: 'denniciaburrell31@gmail.com',

        tiktokProfile: {
          handle: 'den.nicia',
          followers: 105,
          engagementRate: 10.625
        },
        youtubeProfile: {
          handle: 'dennicia8686',
          followers: 3
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-25T19:24:16.000Z',
        amountCents: 2773
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'e57c042d-6108-421e-a283-2f98471e0a53',
      content: [],
      creator: {
        id: 'd8069a22-921b-4f11-9a88-a1fdd749e1d0',
        firstName: 'Kimberly',
        lastName: 'Streete',
        email: 'streetekaye1126@gmail.com',

        tiktokProfile: {
          handle: 'kimberly4306',
          followers: 0
        },
        youtubeProfile: {
          handle: 'kimberly-ee3gc',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-25T20:12:59.000Z',
        amountCents: 2555
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '6f21f8d4-b574-4215-af2c-25db390b324a',
      content: [],
      creator: {
        id: '8fbbe6e6-47ac-48f0-a964-e1cce6d87fef',
        firstName: 'Tina',
        lastName: 'Cole',
        email: 'tinacole511@outlook.com',

        tiktokProfile: {
          handle: 'tinacuhg9s1',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-25T20:42:14.000Z',
        amountCents: 4000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '322fdbc0-a32d-40c0-88d6-c5dd882813a9',
      content: [],
      creator: {
        id: 'f4421645-ad88-4975-b1fe-048bbdb9f4ee',
        firstName: 'Cilene ',
        lastName: 'Roye ',
        email: 'frankroye82@gmail.com',
        instagramProfile: {
          handle: 'mis_muffet_',
          followers: 18721
        },
        tiktokProfile: {
          handle: 'heathersimpson50',
          followers: 45
        },
        youtubeProfile: {
          handle: 'cilandshay9245',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-25T20:56:18.000Z',
        amountCents: 2221
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '3eb43755-02d1-4fe9-af25-5ef571d69583',
      content: [],
      creator: {
        id: 'b2ae95e2-d452-429a-a287-4765dee2c974',
        firstName: 'Jordane',
        lastName: 'Shagouri',
        email: 'orlandohue@yahoo.com',

        tiktokProfile: {
          handle: 'ohue2',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-25T21:01:12.000Z',
        amountCents: 6000
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '7f7f7f68-14a8-44e5-82f9-677f1e364a50',
      content: [],
      creator: {
        id: 'ebca23be-4ed1-4a75-8952-798d71e7db00',
        firstName: 'Angella',
        lastName: 'James',
        email: 'angellajames519@gmail.com',

        tiktokProfile: {
          handle: 'jellyjam28',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-25T21:03:27.000Z',
        amountCents: 6000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '78b36a60-a0c8-40e9-befb-5b840642f44c',
      content: [],
      creator: {
        id: 'df2eee04-227e-4527-bec1-cba2cb430235',
        firstName: 'Allanah',
        lastName: 'Campbell',
        email: 'ashareebarrett@hotmail.com',

        tiktokProfile: {
          handle: 'sedulobeautyandcosmetics',
          followers: 27
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-25T21:16:10.000Z',
        amountCents: 4000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'd7efb33d-b0ac-43c3-8704-29a5a5cff584',
      content: [],
      creator: {
        id: 'a359f9c5-d1e2-4c93-ae7e-133c2b0041f7',
        firstName: 'Kena',
        lastName: 'Munez',
        email: 'kenamumez56@gmail.com',

        tiktokProfile: {
          handle: '_.kenakm',
          followers: 604
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-25T21:42:38.000Z',
        amountCents: 2773
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'c0e6efed-d862-4ec9-af17-2bf759e51c0d',
      content: [],
      creator: {
        id: 'c896ba5d-db44-4b02-8923-2a636c3d5cd1',
        firstName: 'Sashagay',
        lastName: 'Richards',
        email: 'sammiirichards@icloud.com',
        instagramProfile: {
          handle: 'sammiis__skin',
          followers: 0,
          engagementRate: 100
        },
        tiktokProfile: {
          handle: 'sammii_smiless',
          followers: 22,
          engagementRate: 1.8556701030927836
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-25T23:43:00.000Z',
        amountCents: 6469
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '6b16b3fe-9f0b-4b47-89d6-8dccbeaf343b',
      content: [],
      creator: {
        id: '2c30d208-7936-423e-ab86-349e3577ded3',
        firstName: 'Brittany ',
        lastName: 'Beecher',
        email: 'brittaney182@gmail.com',
        instagramProfile: {
          handle: 'rxnae.__',
          followers: 0
        },
        tiktokProfile: {
          handle: 'brii.nae',
          followers: 82
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-25T23:43:35.000Z',
        amountCents: 2822
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'a1308ca2-71b1-4962-bc6e-329cf1d8dfd1',
      content: [],
      creator: {
        id: '02e891e1-8899-4e66-b49f-09425efc754a',
        firstName: 'Silvana',
        lastName: 'De Souza silva',
        email: 'silvanasouzatracy@gmail.com',
        instagramProfile: {
          handle: 'silvana.designerdeunhas',
          followers: 212,
          engagementRate: 3.5849056603773586
        },
        tiktokProfile: {
          handle: 'silvanasil0855',
          followers: 53,
          engagementRate: 2.6226447122729586
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T00:35:59.000Z',
        amountCents: 2098
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '5947a539-2b21-42fc-9022-3f91027832cb',
      content: [],
      creator: {
        id: '1426be14-8320-4e48-af54-bab333814aad',
        firstName: 'Denise',
        lastName: 'Wilson',
        email: 'grantcandiece92@gmail.com',

        tiktokProfile: {
          handle: 'den.den7729',
          followers: 3
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T00:55:22.000Z',
        amountCents: 2895
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'ec34387e-2377-4d3d-9e13-045d515c280e',
      content: [],
      creator: {
        id: '236f93ff-bf6e-467e-b2e8-a61f6491856c',
        firstName: 'Shanequa',
        lastName: 'Williams',
        email: 'jednoyamommy2@gmail.com',

        tiktokProfile: {
          handle: 'shanequa876',
          followers: 1267,
          engagementRate: 8.379888268156424
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T01:14:09.000Z',
        amountCents: 2776
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '4265aff9-8db7-4f10-9ac7-6e3cc3a8bd50',
      content: [],
      creator: {
        id: 'b358633c-2eba-4d9a-9c84-be4fea09c898',
        firstName: 'Lotanya',
        lastName: 'Thompson',
        email: 'recabrown63@gmail.com',

        tiktokProfile: {
          handle: 'thomptjlb1n',
          followers: 640,
          engagementRate: 14.506581853520629
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T02:44:26.000Z',
        amountCents: 2085
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'd806b04c-872b-4a38-a75e-a3d193def7d5',
      content: [],
      creator: {
        id: 'fe24e19a-6dab-467f-a940-969c08843449',
        firstName: 'Sean',
        lastName: 'Richy',
        email: 'sean_richy@yahoo.com',

        tiktokProfile: {
          handle: 'richy87659',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T03:03:03.000Z',
        amountCents: 3423
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'd566f6d6-0838-449e-84b2-ddea4b2d79bf',
      content: [],
      creator: {
        id: '0c3f9b36-f25d-4928-af9f-e58cee808990',
        firstName: 'Alexa',
        lastName: 'Patterson',
        email: 'alexa@socialbuzzbranding.com',
        instagramProfile: {
          handle: 'alexapatterson',
          followers: 2777,
          engagementRate: 7.387780548628429
        },
        tiktokProfile: {
          handle: 'alexaapattersonn',
          followers: 814,
          engagementRate: 8.333333333333332
        },
        youtubeProfile: {
          handle: 'alexapatterson-gj8wi',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T04:23:38.000Z',
        amountCents: 1938
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '9d6f06a5-9810-4bc4-8ddf-d316fadb3da9',
      content: [],
      creator: {
        id: '4a124776-b6e0-4704-a469-08d65d33b09f',
        firstName: 'Kim',
        lastName: 'Love',
        email: 'coy755davis@gmail.com',

        tiktokProfile: {
          handle: 'zay19424',
          followers: 9
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T05:04:17.000Z',
        amountCents: 2888
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '35da279d-62db-41a2-b8a5-12950ecf6341',
      content: [],
      creator: {
        id: '95e52ec9-29b8-4360-80c4-b3c3ba208055',
        firstName: 'Shinnel',
        lastName: 'Sewell',
        email: 'shinnelvivine@icloud.com',

        tiktokProfile: {
          handle: 'monieque22',
          followers: 445
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T15:22:53.000Z',
        amountCents: 3696
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'ce0c2451-ee6a-4942-80d9-e831f03aac39',
      content: [],
      creator: {
        id: '6db75cfc-51b9-4d61-8bff-08519b2a8f3a',
        firstName: 'Tenahya',
        lastName: 'Simpson',
        email: 'simpsonvanessa34@gmail.com',

        tiktokProfile: {
          handle: 'gemini_nessa',
          followers: 4094,
          engagementRate: 11.490125673249551
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T16:28:08.000Z',
        amountCents: 5000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'ded22c00-6649-4341-9e1f-7be82ebc4bb9',
      content: [],
      creator: {
        id: '4269263c-61cf-418e-ac14-58a00d071cd1',
        firstName: 'Denise',
        lastName: 'Frutos',
        email: 'denisefrutos88@gmail.com',
        instagramProfile: {
          handle: 'princessaofdarkness_',
          followers: 1016,
          engagementRate: 4.295740561471442
        },
        tiktokProfile: {
          handle: 'extrapickles7',
          followers: 485,
          engagementRate: 0.411522633744856
        },
        youtubeProfile: {
          handle: 'denisefrutos',
          followers: 5
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T16:30:27.000Z',
        amountCents: 3900
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '54d62709-f11c-4f67-b635-bf72bbee0de7',
      content: [],
      creator: {
        id: '42f8dd07-5b78-43b3-aaf1-cebdd6d1e135',
        firstName: 'Candiece',
        lastName: 'Grant',
        email: 'grantcandiece92@yahoo.com',

        tiktokProfile: {
          handle: 'blush8133',
          followers: 0
        },
        youtubeProfile: {
          handle: 'candiecegrant6740',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T16:30:38.000Z',
        amountCents: 4598
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '5c4ce4f0-dfdf-453f-8d4f-6aa33cae6aa5',
      content: [],
      creator: {
        id: '60a753d9-03e6-41b1-a5b2-401173b085e3',
        firstName: 'Shanz',
        lastName: 'Maylor',
        email: 'shanzmaylor23@outlook.com',

        tiktokProfile: {
          handle: 'shanzmaylor',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T17:02:45.000Z',
        amountCents: 5000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '3bb0ac91-4455-455a-b7cd-4be804e8624f',
      content: [],
      creator: {
        id: 'dc2b6abf-2386-449d-8afc-86c8f59b6ebe',
        firstName: 'Tanique',
        lastName: 'Francis',
        email: 'taniquefrancis00@gmail.com',

        tiktokProfile: {
          handle: 'tanithistanithat',
          followers: 373
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T20:46:40.000Z',
        amountCents: 2633
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'd86e26f6-1d72-4b21-a311-4197b108e099',
      content: [],
      creator: {
        id: '00de1f2b-3d9b-447b-acbc-2d69531ba17e',
        firstName: 'Petagay',
        lastName: 'Lattimore',
        email: 'rohjaybartlett@gmail.com',
        instagramProfile: {
          handle: 'keep_up_with_latty',
          followers: 1174,
          engagementRate: 4.250425894378194
        },
        tiktokProfile: {
          handle: 'llatty_toptier',
          followers: 3903,
          engagementRate: 6.872037914691943
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T20:54:37.000Z',
        amountCents: 2000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'c6bbca7c-64f7-471e-b3c9-8ee21f0aa8fb',
      content: [],
      creator: {
        id: 'b50f1e46-1ecf-4f33-a245-1ec22d927f9c',
        firstName: 'Monique',
        lastName: 'Henry',
        email: 'starhenry399@gmail.com',

        tiktokProfile: {
          handle: 'starhenry5',
          followers: 398
        },
        youtubeProfile: {
          handle: 'starhenry1634',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T20:55:28.000Z',
        amountCents: 2196
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '31fff21a-3110-489e-8299-345f256d5bba',
      content: [],
      creator: {
        id: 'b7c9fa67-33e3-40e3-90d0-a34243e1eb10',
        firstName: 'Ikeisha',
        lastName: 'Bent',
        email: 'chrissybaker490@gmail.com',
        instagramProfile: {
          handle: 'chrissyunpredictable',
          followers: 1107,
          engagementRate: 0.5413766434648105
        },
        tiktokProfile: {
          handle: 'thatgirl9179',
          followers: 3
        },
        youtubeProfile: {
          handle: 'kylieedmondson-hd8lt',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T21:53:47.000Z',
        amountCents: 2867
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'b8bcbef4-f474-4b00-8f39-6aeb7088f374',
      content: [],
      creator: {
        id: 'cce23ba4-a78d-4521-a22a-a4079514a46f',
        firstName: 'Andre',
        lastName: 'Arnett',
        email: 'damararnett@gmail.com',

        tiktokProfile: {
          handle: 'damaro549',
          followers: 20
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T23:26:04.000Z',
        amountCents: 6000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '03a62921-3c92-4703-b3d0-a0465a53110a',
      content: [],
      creator: {
        id: 'c00fec4b-07e5-4fd6-a439-0b68e2c9434e',
        firstName: 'Victoria',
        lastName: 'Korus',
        email: 'victoriakorus@gmail.com',
        instagramProfile: {
          handle: 'victoriakorus',
          followers: 4366,
          engagementRate: 86.63536417773706
        },
        tiktokProfile: {
          handle: 'victoriakorus',
          followers: 56881,
          engagementRate: 5.291970802919708
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T23:57:09.000Z',
        amountCents: 2089
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'df505ab6-ee90-475a-a4b8-3c2d8693b8e9',
      content: [],
      creator: {
        id: '2ab6817d-a025-41e3-908c-c99e9f8b3713',
        firstName: 'Tanashay',
        lastName: 'Phillips',
        email: 'tanashayphillips@gmail.com',
        instagramProfile: {
          handle: 'the_hanger_by_cece_x_jev',
          followers: 127,
          engagementRate: 2.6246719160104988
        },
        tiktokProfile: {
          handle: 'live_love_laugh_24',
          followers: 1094,
          engagementRate: 5.88235294117647
        },
        youtubeProfile: {
          handle: 'lifewithcrystal8733',
          followers: 5
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T01:23:03.000Z',
        amountCents: 5393
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'c1033bfe-acac-4f22-8289-e41df08e7bbc',
      content: [],
      creator: {
        id: 'c1cdae2b-8d03-4285-9d28-b19b2107b9cb',
        firstName: 'Claudette',
        lastName: 'Biggs',
        email: 'claudettebiggs@hotmail.com',

        tiktokProfile: {
          handle: 'claudette2628',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T01:36:51.000Z',
        amountCents: 5000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '62d67919-fa89-4a47-9a23-a6a7e047985d',
      content: [],
      creator: {
        id: '48658901-d64b-4fa1-ae2b-b50f615ddf26',
        firstName: 'Shanice',
        lastName: 'Richards',
        email: 'shaniceorichards22@gmail.com',
        instagramProfile: {
          handle: 'tha_glam_plug',
          followers: 145,
          engagementRate: 0
        },
        tiktokProfile: {
          handle: 'shanshanroyal',
          followers: 1314,
          engagementRate: 8.73015873015873
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T01:54:15.000Z',
        amountCents: 3068
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '60d6b1ec-5699-466c-908d-f0f02d50b64a',
      content: [],
      creator: {
        id: '0b72e765-ce0a-4134-bf15-e577b5be9157',
        firstName: 'Javonte',
        lastName: 'Gardner',
        email: 'vallessiagardner@gmail.com',
        instagramProfile: {
          handle: 'vallessiaaaa',
          followers: 3306
        },
        tiktokProfile: {
          handle: 'vallessiaaa',
          followers: 245
        },
        youtubeProfile: {
          handle: 'minmibwoy',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T04:06:25.000Z',
        amountCents: 2933
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '7aa4ed29-51d3-46e6-a406-c0466b161611',
      content: [],
      creator: {
        id: 'f41371e3-f775-4173-994b-585598212913',
        firstName: 'Yvonne',
        lastName: 'Coley',
        email: 'yvonnecoley153@gmail.com',

        tiktokProfile: {
          handle: 'thereal._.noya',
          followers: 86
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T04:31:14.000Z',
        amountCents: 4000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '112167f3-b64a-4b9c-b15f-856c29d3a4ca',
      content: [],
      creator: {
        id: '5a1bc1ca-12d5-4e9e-9973-5690d70440e6',
        firstName: 'Felicia',
        lastName: 'Perez',
        email: 'feliciacperez20@yahoo.com',
        instagramProfile: {
          handle: 'felicia__86',
          followers: 480,
          engagementRate: 0.45662100456621013
        },

        youtubeProfile: {
          handle: 'feliciaperez5699',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T06:54:55.000Z',
        amountCents: 2851
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '1ea53f54-d395-4b98-a626-07b7ccff740b',
      content: [],
      creator: {
        id: '66f33068-20e0-412a-a764-6cff8f2059bc',
        firstName: 'Andre',
        lastName: 'Arnett',
        email: 'andrearnett1@gmail.com',

        tiktokProfile: {
          handle: 'andre_a19',
          followers: 5436,
          engagementRate: 6.8181818181818175
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T07:34:17.000Z',
        amountCents: 6000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'e54f18cf-fcff-452b-bda6-ecf0c07f0317',
      content: [],
      creator: {
        id: '5717bd9d-06b4-4bd8-b398-3acd04227f7a',
        firstName: 'Leesa',
        lastName: 'Nueva',
        email: 'analeeriotiktok@gmail.com',
        instagramProfile: {
          handle: 'auroas_ink_n_gifx',
          followers: 73,
          engagementRate: 19.17808219178082
        },
        tiktokProfile: {
          handle: 'analee_ugc_creator',
          followers: 2834,
          engagementRate: 10.156637255873898
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T08:54:08.000Z',
        amountCents: 2773
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '5a6fb380-722f-4d06-9e21-a7befbf65d8d',
      content: [],
      creator: {
        id: '68361e3e-4a02-43ce-9654-10e9322c0b7d',
        firstName: 'Judz',
        lastName: 'Ricketts',
        email: 'rickettsjudene25@gmail.com',

        tiktokProfile: {
          handle: 'j.r3719',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T10:12:31.000Z',
        amountCents: 3020
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '4b432a71-22cd-49ca-9cd1-29db4174e658',
      content: [],
      creator: {
        id: '76e1e3e8-4ac4-4a40-b895-72fd0e279cc2',
        firstName: 'Grace',
        lastName: 'Tr',
        email: 'foodieg2@gmail.com',
        instagramProfile: {
          handle: 'graceeats247',
          followers: 3166,
          engagementRate: 3.5217940619077703
        },
        tiktokProfile: {
          handle: 'graceeats.247',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T15:39:10.000Z',
        amountCents: 2130
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '834dd846-471f-4abf-8c74-49a43c0e43a6',
      content: [],
      creator: {
        id: '45e8a5b1-31e6-40a9-93bb-dff7e94d4c1e',
        firstName: 'Jessanna',
        lastName: 'Dyce',
        email: 'synatic223@yahoo.com',

        tiktokProfile: {
          handle: 'jayda4489',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T16:11:38.000Z',
        amountCents: 5000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '3bc101ec-8099-4a81-b9fe-da8fca3f0bb7',
      content: [],
      creator: {
        id: 'ccfd1047-f200-42b9-bc89-1c08ed58a9a3',
        firstName: 'Lotanya',
        lastName: 'Thompson',
        email: 'michealwilson6136@gmail.com',

        tiktokProfile: {
          handle: 'lotanyathompson45',
          followers: 9970,
          engagementRate: 18.52100957615837
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T16:32:07.000Z',
        amountCents: 2231
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '9a1b7791-9b24-4697-b371-9c566922a06b',
      content: [],
      creator: {
        id: '3a8aae1e-5eff-45f6-b0dc-cc3ab33c1bb6',
        firstName: 'Julie Ann',
        lastName: 'Friolanita',
        email: 'jaflaguda@gmail.com',

        tiktokProfile: {
          handle: 'julieannfriolanit',
          followers: 6
        },
        youtubeProfile: {
          handle: 'rj_jhazz_all-in2523',
          followers: 7
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T16:32:09.000Z',
        amountCents: 3146
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'fd33d003-4ad3-4029-9335-e111e9f63ef3',
      content: [],
      creator: {
        id: '798a5c6d-d585-4c6a-91c7-03bd31a8340f',
        firstName: 'Latoya',
        lastName: 'Thompson',
        email: 'latoya25thompson@gmail.com',
        instagramProfile: {
          handle: 'lattyz_contentcreator',
          followers: 1043,
          engagementRate: 0.632790028763183
        },
        tiktokProfile: {
          handle: 'ms_hin',
          followers: 8031,
          engagementRate: 14.813573951459949
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T16:32:45.000Z',
        amountCents: 2070
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '61fe03d2-45fb-4046-9f92-566181bf80c7',
      content: [],
      creator: {
        id: '0f73364e-bc06-4b6a-a2cb-e336600d3c1c',
        firstName: 'Mary',
        lastName: 'Jones',
        email: 'nobodyb169@gmail.com',

        tiktokProfile: {
          handle: 'sugarplum1348',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T16:34:01.000Z',
        amountCents: 4255
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'e3cb8f96-4cc2-4e74-94fc-346e7e66525e',
      content: [],
      creator: {
        id: 'bf987fb9-7ff0-4a02-abab-1a0f85d878a5',
        firstName: 'Martina',
        lastName: 'Ross',
        email: 'ranhez97@gmail.com',

        tiktokProfile: {
          handle: 'hez.ran',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T17:18:05.000Z',
        amountCents: 5000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '5b1a70bd-2a70-4798-aae3-5f7358456f6f',
      content: [],
      creator: {
        id: 'c83e8df5-3648-4841-a82b-4ea28efbfa22',
        firstName: 'Viktoriya',
        lastName: 'Lelych',
        email: 'worsted-batsmen.0o@icloud.com',
        instagramProfile: {
          handle: 'videomaker_viktoriya_lelych',
          followers: 41,
          engagementRate: 171.54471544715446
        },
        tiktokProfile: {
          handle: 'vika_lelych',
          followers: 6
        },
        youtubeProfile: {
          handle: 'viktoriyalelych5646',
          followers: 220
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T18:46:08.000Z',
        amountCents: 1932
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '7aa19e1b-095d-40a9-a9a1-74394b116b47',
      content: [],
      creator: {
        id: '9cbb35fb-92dc-4c31-9a2d-4ff03bbc6ea7',
        firstName: 'Danita',
        lastName: 'Baham',
        email: 'danitabaham@yahoo.com',
        instagramProfile: {
          handle: 'abcdanita',
          followers: 2493,
          engagementRate: 1.444043321299639
        },
        tiktokProfile: {
          handle: 'abcdanita',
          followers: 114,
          engagementRate: 0.694458262859417
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T19:14:16.000Z',
        amountCents: 2091
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '2337f564-6236-48f1-b7e9-53146b8bd58c',
      content: [],
      creator: {
        id: '6d10be29-6863-4520-960b-7bf12c9d6d4b',
        firstName: 'Shinnel',
        lastName: 'Sewell',
        email: 'bennettjustin0720@gmail.com',

        tiktokProfile: {
          handle: 'ben201734',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T20:16:22.000Z',
        amountCents: 3239
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '5deb3b7f-3832-4bcd-8549-427ef6d9e828',
      content: [],
      creator: {
        id: '09100cba-137b-4fe5-a246-974d1ed9f802',
        firstName: 'Brandon',
        lastName: 'Lamoth',
        email: 'blamoth03@icloud.com',

        tiktokProfile: {
          handle: 'brandon.lamoth',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T20:22:36.000Z',
        amountCents: 6000
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '196ac781-92fe-4bad-9c6a-adbf73adca6c',
      content: [],
      creator: {
        id: '0d106772-6fab-4e11-aebf-8173d05554e5',
        firstName: 'Crystal',
        lastName: 'McFarlane',
        email: 'Mcfarlanejody75@gmail.com',
        instagramProfile: {
          handle: 'exquisite__nails_by_jody',
          followers: 88,
          engagementRate: 3.0681818181818183
        },
        tiktokProfile: {
          handle: 'crystal_jojo',
          followers: 1153,
          engagementRate: 5.505496514251856
        },
        youtubeProfile: {
          handle: 'crystalmcfarlane9556',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T20:51:46.000Z',
        amountCents: 4600
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '199d87c4-b526-44ea-946a-e45922db736a',
      content: [],
      creator: {
        id: '096220dc-0924-45f5-9f13-38f46ffd7781',
        firstName: 'Cory',
        lastName: 'Baugh',
        email: 't876250@yahoo.com',

        tiktokProfile: {
          handle: '2fifty6',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T22:15:45.000Z',
        amountCents: 6000
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '8b96e934-2e88-422c-9bb4-182e675b5e3e',
      content: [],
      creator: {
        id: 'e17817c3-4242-4f96-8d27-78a401dbded9',
        firstName: 'Alex',
        lastName: 'Baugh',
        email: 'bau_gh@yahoo.com',

        tiktokProfile: {
          handle: 'baughnfufa7',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T22:31:03.000Z',
        amountCents: 6000
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'c1860e6e-f3e2-484a-9fe5-205077e7b10b',
      content: [],
      creator: {
        id: 'e88b2f84-c0c1-4187-9874-1ca60606cc30',
        firstName: 'Nika',
        lastName: 'Whyte',
        email: 'nikawhyte@gmail.com',

        tiktokProfile: {
          handle: 'neekss30',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T03:33:54.000Z',
        amountCents: 5000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '8b617cb3-5b9a-4975-87ff-bac89e239c12',
      content: [],
      creator: {
        id: '11756784-c745-434f-8515-44c05db06f08',
        firstName: 'Rusheann',
        lastName: 'Roberts',
        email: 'samzhair876@gmail.com',

        tiktokProfile: {
          handle: 'samz5749',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T03:34:39.000Z',
        amountCents: 5000
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '76e8ef80-63ef-47ee-8ba5-a30fa328d2b0',
      content: [],
      creator: {
        id: '6e13f9b1-1eae-47dd-a068-3151635719b2',
        firstName: 'Crystal',
        lastName: 'Gayle',
        email: 'fenlatercrystal@gmail.com',

        tiktokProfile: {
          handle: 'crystal.fenlater',
          followers: 0
        },
        youtubeProfile: {
          handle: 'crystalfenlater',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T09:26:23.000Z',
        amountCents: 1926
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '1d015034-1af7-48c8-8dec-e38697b9c260',
      content: [],
      creator: {
        id: 'e944b278-89a7-44ac-b7d0-d1b4746c46f6',
        firstName: 'Celiena',
        lastName: 'Moss-Strong',
        email: 'cmossstrong@gmail.com',

        tiktokProfile: {
          handle: 'ce.liena',
          followers: 1343,
          engagementRate: 7.508532423208192
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T11:45:10.000Z',
        amountCents: 2803
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '12cfe589-dcd1-44c6-8321-c03878d4c702',
      content: [],
      creator: {
        id: '4f3262f1-7df0-4f79-9eec-37ddd58709db',
        firstName: 'Thanique',
        lastName: 'Miller',
        email: 'thaniquemiller@yahoo.com',
        instagramProfile: {
          handle: '_than_sm',
          followers: 198
        },
        tiktokProfile: {
          handle: 'than_.sm',
          followers: 1835,
          engagementRate: 14.423416233362973
        },
        youtubeProfile: {
          handle: 'tmiller2233',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T16:19:37.000Z',
        amountCents: 2070
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '5bdd7874-e0e2-4d58-9ed0-a61f1cad6a60',
      content: [],
      creator: {
        id: '71fe4cba-3095-4a9d-b6f2-c10caba59187',
        firstName: 'Dorothy',
        lastName: 'Douglas',
        email: 'dorothydouglas1741@yahoo.com',

        tiktokProfile: {
          handle: 'dorotswoemk',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T16:22:39.000Z',
        amountCents: 4279
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'f1a01b42-7cea-4870-aa5c-2eaff2227061',
      content: [],
      creator: {
        id: 'ae844710-9026-463b-9da3-75e0c7ed7df2',
        firstName: 'Stephanie ',
        lastName: 'Diaz ',
        email: 'stephanie.diaz1969@gmail.com',

        tiktokProfile: {
          handle: 'diazfamily200',
          followers: 160,
          engagementRate: 6.36604774535809
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T17:25:42.000Z',
        amountCents: 2852
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '6ca0409d-40f6-43ef-b083-1f83e69067e2',
      content: [],
      creator: {
        id: 'ae789013-c7c7-481c-b81a-d6586ff0dd64',
        firstName: 'Tycia',
        lastName: 'Brooks',
        email: 'tyciabrooks1@gmail.com',
        instagramProfile: {
          handle: 'royale_kloset',
          followers: 195,
          engagementRate: 1.3846153846153846
        },
        tiktokProfile: {
          handle: 'tysia508',
          followers: 12866,
          engagementRate: 49.584487534626035
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T17:43:24.000Z',
        amountCents: 4065
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '6bfa6aaf-ebc2-4ec8-a9d5-3bbf471e083c',
      content: [],
      creator: {
        id: 'b1824555-20e3-4987-ad95-cdb18853e81d',
        firstName: 'Tahjna',
        lastName: 'Heath',
        email: 'tahjnaheath44@gmail.com',

        tiktokProfile: {
          handle: 'babyt0900',
          followers: 11180,
          engagementRate: 17.177914110429448
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T17:44:05.000Z',
        amountCents: 2070
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'cd9db140-7e8c-4541-895e-f582ce7fefdc',
      content: [],
      creator: {
        id: '74e453a9-f4be-490b-8334-b1a8aa42de7c',
        firstName: 'Imane',
        lastName: 'El yassini',
        email: 'imane.elyassini@gmail.com',
        instagramProfile: {
          handle: 'imane_elyassini',
          followers: 6002
        },
        tiktokProfile: {
          handle: 'imaneelyassini2',
          followers: 58
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T18:26:02.000Z',
        amountCents: 1928
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '0131de89-e4a3-4e24-9fd5-f8150236cb2e',
      content: [],
      creator: {
        id: '1802789a-2c55-4e04-aed2-32d3e87848c9',
        firstName: 'Tiffany',
        lastName: 'Johnson',
        email: 'tobyanndavis@gmail.com',

        tiktokProfile: {
          handle: 'toby8553',
          followers: 22
        },
        youtubeProfile: {
          handle: 'tobyanndavis',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T18:34:08.000Z',
        amountCents: 2070
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'cb655248-6c74-44aa-8493-db35b0eaa3df',
      content: [],
      creator: {
        id: 'f39d398d-6832-4623-940e-06640e986576',
        firstName: 'Yashica',
        lastName: 'Mundle',
        email: 'yashicaminott@yahoo.com',
        instagramProfile: {
          handle: 'dymonds_variety_store',
          followers: 86,
          engagementRate: 5.952380952380952
        },
        tiktokProfile: {
          handle: 'fun_size.1',
          followers: 4301,
          engagementRate: 8.641760815673859
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T19:13:58.000Z',
        amountCents: 2868
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '720ea23c-839a-43cc-95ef-2f7da538e0e5',
      content: [],
      creator: {
        id: '31bd8bf3-cf0e-4905-9778-bca1c1562823',
        firstName: 'Heather',
        lastName: 'Schumacher',
        email: 'heythurbaby@gmail.com',
        instagramProfile: {
          handle: 'heythurbaby',
          followers: 336,
          engagementRate: 2.6785714285714284
        },
        tiktokProfile: {
          handle: 'heythurbaby24',
          followers: 1111,
          engagementRate: 3.214285714285714
        },
        youtubeProfile: {
          handle: 'heythurbaby',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T19:24:04.000Z',
        amountCents: 2098
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '5c3a73a5-f3d0-4e76-b0b3-20c3336fad3e',
      content: [],
      creator: {
        id: '3183a7bb-f0d7-4657-b918-f979483626f2',
        firstName: 'honeycdrip',
        lastName: 'xxx',
        email: 'honeycdrip@gmail.com',
        instagramProfile: {
          handle: 'honeycdrip',
          followers: 10222,
          engagementRate: 2.316572099393465
        },
        tiktokProfile: {
          handle: 'honeycdrip',
          followers: 531,
          engagementRate: 16.3265306122449
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T19:42:13.000Z',
        amountCents: 2114
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '6fd5471e-6b56-4417-9b4b-45fc843fb599',
      content: [],
      creator: {
        id: '6a0e4c77-6c25-45d2-8334-e9af62af5d51',
        firstName: 'Mirza',
        lastName: 'Nunez',
        email: 'mirzafaith823@gmail.com',
        instagramProfile: {
          handle: 'lifewithrowan',
          followers: 46209,
          engagementRate: 0.020558765608431256
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T20:13:33.000Z',
        amountCents: 2095
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '12939178-7de7-4c2a-b9f5-c60d82d156f4',
      content: [],
      creator: {
        id: '45b84fac-4c63-40c0-8990-621da34b91f6',
        firstName: 'Maddie',
        lastName: 'Ryba',
        email: 'maddiemaxxblue@gmail.com',

        tiktokProfile: {
          handle: 'maddielovesblue9',
          followers: 78
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T22:36:16.000Z',
        amountCents: 2812
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '7dad82c6-d706-4090-b93c-f56e33a0ab13',
      content: [],
      creator: {
        id: '8db90147-90e5-4147-8d60-e96cd7bc76ba',
        firstName: 'Adanary',
        lastName: 'Rivera',
        email: 'adanaryrivera@hotmail.com',
        instagramProfile: {
          handle: 'deliciouscake2018_',
          followers: 32,
          engagementRate: 5.9375
        },
        tiktokProfile: {
          handle: 'adanaryriveraa',
          followers: 216,
          engagementRate: 2.6785714285714284
        },
        youtubeProfile: {
          handle: 'alydrivera',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T23:17:15.000Z',
        amountCents: 2808
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'd7776ded-c30b-485c-9588-e42a9b313ada',
      content: [],
      creator: {
        id: 'd9d87071-744d-47d8-8e93-bfc64109dbc1',
        firstName: 'Keke',
        lastName: 'Wilwil',
        email: 'akeeshamilan@gmail.com',
        instagramProfile: {
          handle: 'guru_beauty__',
          followers: 3876,
          engagementRate: 3.7977296181630544
        },
        tiktokProfile: {
          handle: 'kekewilwil',
          followers: 1062
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-29T04:44:07.000Z',
        amountCents: 2721
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '0e23b259-707c-4a1b-a35f-b052ad99ec1b',
      content: [],
      creator: {
        id: '78481cd4-f9ac-48c8-8696-9700fb9e0dd0',
        firstName: 'Anna',
        lastName: 'Seufert',
        email: 'annaseuf1@gmail.com',
        instagramProfile: {
          handle: 'annaseuf_influence_',
          followers: 8,
          engagementRate: 4.166666666666666
        },
        tiktokProfile: {
          handle: 'annaseuf',
          followers: 8
        },
        youtubeProfile: {
          handle: 'mattannamattanna7976'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-29T04:54:17.000Z',
        amountCents: 2769
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'be7df0b9-2ce5-4d18-806a-c0d11deb8414',
      content: [],
      creator: {
        id: 'ace3d7cb-1695-44ca-88a7-c5907c793950',
        firstName: 'Swayne',
        lastName: 'Ferguson',
        email: 'swayneferguson121@gmail.com',

        tiktokProfile: {
          handle: 'king.sdf',
          followers: 11
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-29T12:24:53.000Z',
        amountCents: 5000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '399363b2-30e6-42cd-b0e3-d7ff880611cd',
      content: [],
      creator: {
        id: '497de579-d8ef-4cbf-b93e-0612ee2bef10',
        firstName: 'Jacqueline',
        lastName: 'Henry',
        email: 'henry.jackie777@gmail.com',

        tiktokProfile: {
          handle: 'jacquelinehenry20',
          followers: 2
        },
        youtubeProfile: {
          handle: 'jackieh7773',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-29T12:54:47.000Z',
        amountCents: 2613
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '3da2f649-29c3-4962-aaa0-91136cfd8de8',
      content: [],
      creator: {
        id: '8a35c48b-95bf-4214-bc04-7f0ddf6befe7',
        firstName: 'Catrina',
        lastName: 'Dickens',
        email: 'catrinad71@gmail.com',
        instagramProfile: {
          handle: 'catd71',
          followers: 995,
          engagementRate: 0.8040201005025126
        },
        tiktokProfile: {
          handle: 'catrinaa.dickens',
          followers: 142
        },
        youtubeProfile: {
          handle: 'catrinadickens9914',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-29T16:08:45.000Z',
        amountCents: 2103
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '7383e3b4-8a7d-492b-aa03-adce2d7a9f5c',
      content: [],
      creator: {
        id: '94c82efb-3574-4247-b931-557453d59c38',
        firstName: 'Leslie',
        lastName: 'Ramirez ',
        email: 'leslieramirez.lvr@gmail.com',

        tiktokProfile: {
          handle: 'leslie.veraniaa',
          followers: 1916
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-29T16:26:18.000Z',
        amountCents: 1954
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'b8737d20-2ff0-439c-8a18-97aead7a8e5c',
      content: [],
      creator: {
        id: '3926b5af-fae2-454b-be6e-ec298a4106e8',
        firstName: 'Rayon',
        lastName: 'Bowen',
        email: 'bowenrayon97@gmail.com',

        tiktokProfile: {
          handle: 'lateshachin',
          followers: 223,
          engagementRate: 16.666666666666664
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-29T17:17:06.000Z',
        amountCents: 2030
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '27ee0cdd-2bc9-47b0-b94a-e6e2c53ead94',
      content: [],
      creator: {
        id: '8e978ffc-59f8-4a13-9fd2-414cc26f3c62',
        firstName: 'Chantelle',
        lastName: 'Morgan RCJ',
        email: 'morganmonique876@gmail.com',

        tiktokProfile: {
          handle: 'monique876',
          followers: 10146,
          engagementRate: 5.0219035088987525
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-29T17:23:30.000Z',
        amountCents: 2221
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '94f4ad95-d083-4028-bbd8-ffc1ef3bc7f4',
      content: [],
      creator: {
        id: '34718c9d-3e98-467b-b768-ba4ea2e4f7e5',
        firstName: 'Carlene',
        lastName: 'Kent',
        email: 'carlenekent24@gmail.com',

        tiktokProfile: {
          handle: 'god_bless_yan',
          followers: 15011,
          engagementRate: 8.14319968359124
        },
        youtubeProfile: {
          handle: 'carlenekent3919',
          followers: 4
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-29T17:25:02.000Z',
        amountCents: 2334
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '4b2b649e-fafc-4871-a81e-beed670b1d5b',
      content: [],
      creator: {
        id: '4f4135e7-9f04-4c72-b4d6-904ee36405d4',
        firstName: 'Reena',
        lastName: 'Patel',
        email: 'reena.dhansukh.patel@gmail.com',
        instagramProfile: {
          handle: 'historiadereina',
          followers: 1371,
          engagementRate: 5.4808794414921325
        },
        tiktokProfile: {
          handle: 'rolltide___',
          followers: 23932,
          engagementRate: 14.021571648690292
        },
        youtubeProfile: {
          handle: 'rolltide___',
          followers: 7
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-29T18:05:53.000Z',
        amountCents: 3169
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '19df029b-fdad-496b-a2cc-36aeb2965856',
      content: [],
      creator: {
        id: 'f2d30177-67dd-45f8-aa3b-52035c4f564a',
        firstName: 'Lauren',
        lastName: 'Loomstein',
        email: 'lauren806.lt@gmail.com',
        instagramProfile: {
          handle: 'laur_en6730',
          followers: 1087,
          engagementRate: 5.841766329346826
        },
        tiktokProfile: {
          handle: 'itsmelt7',
          followers: 76
        },
        youtubeProfile: {
          handle: 'lauren76-p2u',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-29T19:16:15.000Z',
        amountCents: 2031
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'c30a6f41-56f8-4f64-815c-4cd5e34052bb',
      content: [],
      creator: {
        id: 'a6663c70-0d66-486b-b18c-28ad293a9f55',
        firstName: 'Valene',
        lastName: 'Sewell',
        email: 'sweetvalene@yahoo.com',

        tiktokProfile: {
          handle: 'user658798713',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-29T21:02:32.000Z',
        amountCents: 4018
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '41ee0968-e999-4dea-96b1-cd48bbbfba09',
      content: [],
      creator: {
        id: 'b82e04e4-c754-4c9a-ae1d-4294259e3623',
        firstName: 'Shinnel',
        lastName: 'Sewell',
        email: 'nicenessnikki20@gmail.com',

        tiktokProfile: {
          handle: 'niceness56',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-29T22:25:39.000Z',
        amountCents: 4254
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'b747b31f-56dc-47ad-ada9-2cbf69f8a4d3',
      content: [],
      creator: {
        id: '32c80520-df37-4837-b016-84675c7e9f83',
        firstName: 'Fayona',
        lastName: 'Thompson',
        email: 'fayonathompson43@gmail.com',

        tiktokProfile: {
          handle: 'fayona.thompson',
          followers: 8
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-29T23:22:41.000Z',
        amountCents: 1711
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'd51fb734-37fa-4bbf-bbc4-60a2d4f45770',
      content: [],
      creator: {
        id: '2ecd3204-bacb-42bd-abbe-f08f453793c3',
        firstName: 'Tetiana',
        lastName: 'Vushtei ',
        email: 'vushtei@gmail.com',
        instagramProfile: {
          handle: 'pinksale_',
          followers: 1407
        },
        tiktokProfile: {
          handle: 'vushtei',
          followers: 11,
          engagementRate: 0.35608308605341243
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-30T00:09:09.000Z',
        amountCents: 1571
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '85c73405-5dde-43e9-af84-da370298123f',
      content: [],
      creator: {
        id: '56af844c-14a7-4467-a3e1-cfb6bf61cc09',
        firstName: 'Lance',
        lastName: 'Mcmurrin',
        email: 'lmacky56@gmail.com',

        tiktokProfile: {
          handle: 'soccer101114',
          followers: 576
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-30T00:44:37.000Z',
        amountCents: 2080
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '62a1ba72-8948-470b-a601-47e9e4c7c4c6',
      content: [],
      creator: {
        id: '7a2b9ba6-f8ee-44b7-84b4-af04968d8af2',
        firstName: 'Tianna',
        lastName: 'Powell',
        email: 'lascoqueen79@gmail.com',

        tiktokProfile: {
          handle: 'ttsimmer',
          followers: 587,
          engagementRate: 10.761132889926442
        },
        youtubeProfile: {
          handle: 'tiannapowell6616',
          followers: 23
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-30T01:25:53.000Z',
        amountCents: 2733
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'eb434a2c-17bb-4c4e-94a8-b38b5f399ee3',
      content: [],
      creator: {
        id: '647daa4a-4fce-48eb-b194-2c41c350a0e7',
        firstName: 'Cece',
        lastName: 'Xu',
        email: 'chillwithcece@gmail.com',
        instagramProfile: {
          handle: 'chillwithcece_24',
          followers: 1773,
          engagementRate: 6.164692611393119
        },
        tiktokProfile: {
          handle: 'chillwithcece_24',
          followers: 2322,
          engagementRate: 9.550561797752808
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-30T02:52:59.000Z',
        amountCents: 2280
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '01bdd1aa-8da3-465a-b9f7-20d47bdca62f',
      content: [],
      creator: {
        id: '725cb00d-ca1f-4fe5-871e-ae529bf4895c',
        firstName: 'Kimberly',
        lastName: 'Streete',
        email: 'rfinegan2@gmail.com',

        tiktokProfile: {
          handle: 'kimberly.streete',
          followers: 4
        },
        youtubeProfile: {
          handle: 'kimberlystreete4285',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-30T04:13:30.000Z',
        amountCents: 2196
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'cc03980b-5959-40db-a6dc-c32c06b5ca95',
      content: [],
      creator: {
        id: '619021b3-9458-4051-89de-56e7101bca83',
        firstName: 'Danija',
        lastName: 'McLeod',
        email: 'kimgibson853@gmail.com',
        instagramProfile: {
          handle: 'bend.detrendboutique',
          followers: 172,
          engagementRate: 4.942528735632184
        },
        tiktokProfile: {
          handle: 'babyboo5279',
          followers: 1186,
          engagementRate: 7.304409672830725
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-30T07:54:05.000Z',
        amountCents: 2956
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '9046951e-a694-42f3-9042-b72978a4f938',
      content: [],
      creator: {
        id: '2ee4edd6-c49d-47c5-9399-a22561d3f14a',
        firstName: 'Shanoya',
        lastName: 'Gordon',
        email: 'shanoyagordon4@gmail.com',

        tiktokProfile: {
          handle: 'gemini_gyal1',
          followers: 11502,
          engagementRate: 6.847812322522219
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-30T09:44:59.000Z',
        amountCents: 4707
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '37a23a7b-5817-42be-81c9-be02f844010f',
      content: [],
      creator: {
        id: 'c88a037e-f0fb-4cea-9e18-dfa2cc7624e1',
        firstName: 'Tatiana',
        lastName: 'Robinson',
        email: 'tatianarobinson68@outlook.com',

        tiktokProfile: {
          handle: 'tatty6504',
          followers: 1
        },
        youtubeProfile: {
          handle: 'tatinacoley8509',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-30T12:07:10.000Z',
        amountCents: 4000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '6ade936a-df86-4b19-ad28-b543feece368',
      content: [],
      creator: {
        id: 'ac2ec064-0662-4d6b-91a0-fb43919318cf',
        firstName: 'Janeva',
        lastName: 'Young',
        email: 'jenevayoung5@gmail.com',
        instagramProfile: {
          handle: 'jen_unbreakable29',
          followers: 2139,
          engagementRate: 2.5338943431510055
        },
        tiktokProfile: {
          handle: 'jen_unbreakable30',
          followers: 14499,
          engagementRate: 13.360323886639677
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-30T12:14:24.000Z',
        amountCents: 2768
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '5cc181ed-8fea-4b06-9095-5679a3ef6a08',
      content: [],
      creator: {
        id: 'fe314035-62f2-4d8e-a2e3-be2660eaada9',
        firstName: 'Sharon',
        lastName: 'Currie',
        email: 'shopmeckeyt@gmail.com',
        instagramProfile: {
          handle: 'meckeyt',
          followers: 30818,
          engagementRate: 0.6198078920041536
        },
        tiktokProfile: {
          handle: 'meckeyt',
          followers: 1525
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-30T12:54:03.000Z',
        amountCents: 1935
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'fa39893a-7314-4ce6-9b15-27c91a19eb10',
      content: [],
      creator: {
        id: 'f42ab824-1fe0-47f5-8822-2acdc2680ab3',
        firstName: 'Cedella',
        lastName: 'Reid',
        email: 'cedellareid17@gmail.com',
        instagramProfile: {
          handle: 'cedella_reidd',
          followers: 672
        },
        tiktokProfile: {
          handle: 'theareid0',
          followers: 258,
          engagementRate: 6.0037523452157595
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-30T15:17:16.000Z',
        amountCents: 4172
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'f592c8f3-5dae-4a37-ad06-f3f4ce8a4577',
      content: [],
      creator: {
        id: '89ecc1f8-1129-419f-8e6f-f7237b97107a',
        firstName: 'Katlin',
        lastName: 'Futch',
        email: 'katlin5650@gmail.com',

        tiktokProfile: {
          handle: 'futchesskat',
          followers: 145
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-30T16:14:16.000Z',
        amountCents: 2065
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'f3cedcf5-a04b-4464-99b7-a3df03d827ca',
      content: [],
      creator: {
        id: '1c329b3a-5f96-4659-99f3-496a138a8fb2',
        firstName: 'Lexi',
        lastName: 'Ferber',
        email: 'madeebylexi@gmail.com',
        instagramProfile: {
          handle: 'madeebylexi',
          followers: 16292,
          engagementRate: 1.5357230542597593
        },
        tiktokProfile: {
          handle: 'madeebylexi',
          followers: 2142,
          engagementRate: 3.320072771376592
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-30T16:35:11.000Z',
        amountCents: 2101
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '4fc6d628-cd6b-4f3d-94c8-ab8b56f3af16',
      content: [],
      creator: {
        id: 'af1dbcd0-eea6-4431-9271-8597420b2228',
        firstName: 'Ashley',
        lastName: 'Bowlin',
        email: 'ashleybowlin.an@gmail.com',
        instagramProfile: {
          handle: 'itryandreviewforyou',
          followers: 1479,
          engagementRate: 2.5693035835023665
        },
        tiktokProfile: {
          handle: 'itryandreviewforyou',
          followers: 443,
          engagementRate: 1.03359173126615
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-30T16:42:00.000Z',
        amountCents: 2080
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '9e9794b9-812f-4cbe-8835-9070fd1eab50',
      content: [],
      creator: {
        id: 'b60ca1a0-df07-4c94-920b-d7642e401c1b',
        firstName: 'Tenahya ',
        lastName: 'Simpson ',
        email: 'simpsonv849@gmail.com',

        tiktokProfile: {
          handle: 'simpsraombn',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-30T17:15:11.000Z',
        amountCents: 5000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'a347c941-b05d-4954-81cb-4c88cfa0c8b3',
      content: [],
      creator: {
        id: '94e54f51-23f3-4a92-8759-3c4d69092071',
        firstName: 'Gilbert',
        lastName: 'Perez',
        email: 'gilbertrperez_81@yahoo.com',
        instagramProfile: {
          handle: 'grperez81',
          followers: 131
        },

        youtubeProfile: {
          handle: 'gilbertperez-uo9mi',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-30T17:56:13.000Z',
        amountCents: 2846
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '6290bf50-5144-4d6e-8752-556ed1bb849b',
      content: [],
      creator: {
        id: '97e2e251-c178-4a61-9025-db9186ade17a',
        firstName: 'Magon',
        lastName: 'Pinnock',
        email: 'pinnockmegon@gmail.com',

        tiktokProfile: {
          handle: 'megon1630',
          followers: 1179,
          engagementRate: 5.493401186103383
        },
        youtubeProfile: {
          handle: 'megonpinnock6329',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-30T18:02:37.000Z',
        amountCents: 6889
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '999456ad-5121-42a7-ad68-3d01c14f203a',
      content: [],
      creator: {
        id: '69f55aee-54d0-42ec-ab38-bbe69911cfe5',
        firstName: 'Gianna',
        lastName: 'Kleinpell',
        email: 'giannamc@aol.com',
        instagramProfile: {
          handle: 'darin_kleinpell',
          followers: 515,
          engagementRate: 3.747572815533981
        },
        tiktokProfile: {
          handle: 'gianna.realtor',
          followers: 57,
          engagementRate: 1.4760147601476015
        },
        youtubeProfile: {
          handle: 'darinkleinpell',
          followers: 5
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-30T18:16:02.000Z',
        amountCents: 2031
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '287c1d66-d1fe-4eb5-8581-980552b59a66',
      content: [],
      creator: {
        id: '90f3ee8f-fbd8-4cab-acdc-f317a0951af3',
        firstName: 'Joan',
        lastName: 'Ewan',
        email: 'chrisyewan@gmail.com',
        instagramProfile: {
          handle: 'chrisyewan',
          followers: 9134,
          engagementRate: 1.0169573997031847
        },
        tiktokProfile: {
          handle: 'chrisyewanofficial',
          followers: 14366
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-30T18:29:38.000Z',
        amountCents: 2449
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'bfa41d90-16f9-4642-8cbd-9b5ba39991d9',
      content: [],
      creator: {
        id: 'da29b518-32f1-4bf5-b9e9-70d3a65fcf43',
        firstName: 'Brandon',
        lastName: 'Lamoth',
        email: 'balamoth@icloud.com',

        tiktokProfile: {
          handle: 'brandon.lamoth7',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-30T18:55:04.000Z',
        amountCents: 6000
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'bb0f954c-61f2-4dd0-9a49-0ffe1184ba67',
      content: [],
      creator: {
        id: '1e2daacf-108f-413d-aa4d-247fd6657588',
        firstName: 'Randi',
        lastName: 'McCoy',
        email: 'rrmccoy19@gmail.com',
        instagramProfile: {
          handle: 'randi.mccoy19',
          followers: 5271,
          engagementRate: 0.2181749193701385
        },
        tiktokProfile: {
          handle: 'randi.mccoy19',
          followers: 10786,
          engagementRate: 12.989684644857057
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-30T19:42:53.000Z',
        amountCents: 2094
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'a68df997-30c1-491e-b35a-531a92a713e3',
      content: [],
      creator: {
        id: '10d18ab6-f872-4ce4-a3ff-2601df80dd95',
        firstName: 'Maria',
        lastName: 'Johnson',
        email: 'ieshiajohnson25@yahoo.com',
        instagramProfile: {
          handle: 'mariabfunny',
          followers: 1620
        },
        tiktokProfile: {
          handle: 'mariabfunny_',
          followers: 834
        },
        youtubeProfile: {
          handle: 'mariabfunny',
          followers: 7
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-30T20:14:17.000Z',
        amountCents: 2123
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '58d134c9-1187-4abd-b608-de914820a2e0',
      content: [],
      creator: {
        id: '1073ff62-8aa2-4fc0-98f3-8fc4f99cba5f',
        firstName: 'Emma',
        lastName: 'Lee',
        email: 'emmalee477@yahoo.com',

        tiktokProfile: {
          handle: 'emmalhdshgi',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-30T21:44:33.000Z',
        amountCents: 3637
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'aaf601cc-e12a-4bae-9785-acdbc0b4e4cc',
      content: [],
      creator: {
        id: 'ceb0c4d0-ab0c-4555-ad9e-ba131e9b4981',
        firstName: 'Evet',
        lastName: 'Weston',
        email: 'celene.baugh@outlook.com',

        tiktokProfile: {
          handle: 'cforchatterbox',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-30T23:04:41.000Z',
        amountCents: 6000
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '8011d1e8-37c5-4c71-9acc-edeeba729a4e',
      content: [],
      creator: {
        id: '7baf92bf-1da0-42f4-aaa5-9f4e803b11a4',
        firstName: 'Janie',
        lastName: 'Reza',
        email: 'perez_viola@yahoo.com',

        tiktokProfile: {
          handle: 'perez_viola',
          followers: 192,
          engagementRate: 6.444741442888321
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-01T01:32:52.000Z',
        amountCents: 2101
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'c13c0992-4f3f-444d-b2b6-22e98ae1ea57',
      content: [],
      creator: {
        id: '2225fa5f-29df-4365-bb47-eb504aad5138',
        firstName: 'Denise',
        lastName: 'Smith',
        email: 'zyron5156@gmail.com',

        tiktokProfile: {
          handle: 'dendeninfluence',
          followers: 0
        },
        youtubeProfile: {
          handle: 'zyron-tk5gs',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-01T06:11:44.000Z',
        amountCents: 2443
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '91c7028a-7749-48b0-b122-a7a89a747023',
      content: [],
      creator: {
        id: '36f1b896-8323-422e-a2ac-7a9e16b98e12',
        firstName: 'Odena',
        lastName: 'Palmer',
        email: 'businestia1@gmail.com',

        tiktokProfile: {
          handle: 'tia_oneandonly',
          followers: 5646,
          engagementRate: 18.100890207715135
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-01T07:49:10.000Z',
        amountCents: 2064
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '69fac0dd-7194-4b29-9d1d-853be3ddbe4d',
      content: [],
      creator: {
        id: '06b6d896-b231-4738-a02e-0297f6896c79',
        firstName: 'Mo',
        lastName: 'Abou',
        email: 'appleyoutv@gmail.com',
        instagramProfile: {
          handle: 'igaconstruction',
          followers: 72,
          engagementRate: 0.7142857142857143
        },
        tiktokProfile: {
          handle: 'dragon.fruit.king6',
          followers: 0,
          engagementRate: 2.898550724637681
        },
        youtubeProfile: {
          handle: 'annaseuf715',
          followers: 3
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-01T12:44:04.000Z',
        amountCents: 2764
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '410a3429-8a41-4609-8f3a-ef7d5c31f2a4',
      content: [],
      creator: {
        id: '036723d2-7d49-422f-ad1f-d8a4c006c804',
        firstName: 'BarbzUGC',
        lastName: 'Creator',
        email: 'efitima93@gmail.com',
        instagramProfile: {
          handle: 'barbie._tingzja',
          followers: 284,
          engagementRate: 0.7394366197183099
        },
        tiktokProfile: {
          handle: 'barbie._ugccreator',
          followers: 977,
          engagementRate: 13.924050632911392
        },
        youtubeProfile: {
          handle: 'barbzugccreator',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-01T15:44:10.000Z',
        amountCents: 2064
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '7550b822-fee9-412f-91c8-8981c207ac8c',
      content: [],
      creator: {
        id: '2d21afaf-c120-415e-9daf-634626fa8e24',
        firstName: 'Amanda',
        lastName: 'Hughes',
        email: 'amandaslrj@gmail.com',
        instagramProfile: {
          handle: 'amandaslrj',
          followers: 35955,
          engagementRate: 5.982756223056598
        },
        tiktokProfile: {
          handle: 'amandaslrj22',
          followers: 10026,
          engagementRate: 6.649307473033586
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-01T16:18:18.000Z',
        amountCents: 2059
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'bf08b01a-9471-4956-997a-ae7f322b08ee',
      content: [],
      creator: {
        id: '1226d9a8-0dbc-4eb4-a6be-e39575defd15',
        firstName: 'Tara-Gaye',
        lastName: 'Mason',
        email: 'taragayemason16@gmail.com',
        instagramProfile: {
          handle: 's.m_variety',
          followers: 307,
          engagementRate: 16.720955483170467
        },
        tiktokProfile: {
          handle: 'tannii16',
          followers: 1090,
          engagementRate: 8.208955223880597
        },
        youtubeProfile: {
          handle: 'tara-gayemason8894',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-01T16:19:31.000Z',
        amountCents: 2763
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '75d76c73-36e5-42c9-bfda-aef5b55c6519',
      content: [],
      creator: {
        id: '5db0d62c-21ae-4919-844d-03f0872b8a3a',
        firstName: 'Lilian',
        lastName: 'Curcino',
        email: 'lilianararuna@hotmail.com',
        instagramProfile: {
          handle: 'lilycurcino',
          followers: 12270,
          engagementRate: 0.09453952730236348
        },
        tiktokProfile: {
          handle: 'lilycurcino',
          followers: 26,
          engagementRate: 0.16583747927031509
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-01T16:36:31.000Z',
        amountCents: 2088
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'fc20f611-b0fb-48f1-a06c-592a07a819e6',
      content: [],
      creator: {
        id: '0c3d14a2-6d33-4bc4-a90f-cde94d8194a7',
        firstName: 'emily ',
        lastName: 'walker',
        email: 'emilygrace.walker@yahoo.com',

        tiktokProfile: {
          handle: 'emilygracewalkerr',
          followers: 792,
          engagementRate: 8.687615526802219
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-01T17:21:11.000Z',
        amountCents: 2064
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '4dff2797-5065-473b-b383-1e666abe01bf',
      content: [],
      creator: {
        id: 'b0a81006-28c1-4e2b-8b33-42140c579f3a',
        firstName: 'Dareo',
        lastName: 'Cunningham',
        email: 'dareocad@gmail.com',

        tiktokProfile: {
          handle: 'dareo112211',
          followers: 1443,
          engagementRate: 2.5974025974025974
        },
        youtubeProfile: {
          handle: 'dareoc.',
          followers: 40
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-01T17:32:54.000Z',
        amountCents: 4692
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '1b327df8-694c-4e63-b2b7-109d07d1fd72',
      content: [],
      creator: {
        id: '0eb81069-789d-4f7e-b5e8-c411d410d641',
        firstName: 'Peter-gay',
        lastName: 'Smith',
        email: 'kenisha820@gmail.com',

        tiktokProfile: {
          handle: 'annitabrown7',
          followers: 8
        },
        youtubeProfile: {
          handle: 'moneymoney-fx3eu',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-01T17:47:05.000Z',
        amountCents: 1998
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '9e18cafc-521d-425b-8559-a1e9aa43403e',
      content: [],
      creator: {
        id: '2d0499e9-11da-45e5-820c-d8d7eafa6a3c',
        firstName: 'Samanda',
        lastName: 'Richards',
        email: 'samanda.richards2608@gmail.com',

        tiktokProfile: {
          handle: 'ma__ndaaa',
          followers: 4903,
          engagementRate: 6.0113904163393554
        },
        youtubeProfile: {
          handle: 'hawanya360',
          followers: 265
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-01T17:50:54.000Z',
        amountCents: 2064
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '41154148-ee25-4334-ab94-3ea781a24d8e',
      content: [],
      creator: {
        id: '745ab83f-d6b1-4732-8abc-18cdffca986e',
        firstName: 'Brittany',
        lastName: 'Frisbie',
        email: 'brittany_frisbie@hotmail.com',
        instagramProfile: {
          handle: 'brittany_frisbie',
          followers: 13090,
          engagementRate: 1.1932773109243697
        },
        tiktokProfile: {
          handle: 'brittanyfrisbie26',
          followers: 11902,
          engagementRate: 3.3210332103321036
        },
        youtubeProfile: {
          handle: 'brittany_frisbie32',
          followers: 3
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-01T19:15:43.000Z',
        amountCents: 2088
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'f6905721-4963-4cdf-b37b-6e729b38a521',
      content: [],
      creator: {
        id: 'f702e2a4-4a92-4db1-8c94-ea0de3c94eb9',
        firstName: 'Ben',
        lastName: 'Loomstein',
        email: 'benjamin.loomstein@gmail.com',

        tiktokProfile: {
          handle: 'zoomyloomy7',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-01T19:25:07.000Z',
        amountCents: 2025
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'fbbd7866-1007-432a-aec4-faab1a256c93',
      content: [],
      creator: {
        id: '293714cd-a7ab-47df-8378-8105e77d819a',
        firstName: 'Deidra',
        lastName: 'Jeffers',
        email: 'deidramorris@hotmail.com',
        instagramProfile: {
          handle: 'beaureine97',
          followers: 73,
          engagementRate: 0.273972602739726
        },
        tiktokProfile: {
          handle: 'beau_reine97',
          followers: 1118,
          engagementRate: 15.443037974683543
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-01T20:56:45.000Z',
        amountCents: 2054
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '8dcadb06-6a2d-4f70-9320-ffff5f5abeaf',
      content: [],
      creator: {
        id: '3eba5a5d-d739-472f-9ea4-a0ce0b6fce26',
        firstName: 'Iamme',
        lastName: 'Blake',
        email: 'iammeblake3k@icloud.com',

        tiktokProfile: {
          handle: 'yammi_3k',
          followers: 2206
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-01T21:53:41.000Z',
        amountCents: 2064
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '57f52ed0-2569-4fd8-b086-6e7597f812db',
      content: [],
      creator: {
        id: '9c1a595e-612d-4c83-966c-0408d3885001',
        firstName: 'Isaac',
        lastName: 'Pastrana',
        email: 'pastrana.isaac@gmail.com',
        instagramProfile: {
          handle: 'kingodinwar',
          followers: 68,
          engagementRate: 11.601307189542483
        },
        tiktokProfile: {
          handle: 'kingodinwar',
          followers: 142,
          engagementRate: 4.487179487179487
        },
        youtubeProfile: {
          handle: 'kingodinwargaming'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-01T22:13:38.000Z',
        amountCents: 3184
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'c4e023dc-a18e-4345-b6f1-356af0f917a7',
      content: [],
      creator: {
        id: 'afe30c9a-1b6e-4d02-86a5-329246a1460e',
        firstName: 'Amber',
        lastName: 'Sauceda',
        email: 'mamabear.influencer@gmail.com',

        tiktokProfile: {
          handle: 'mrs.asauceda',
          followers: 82,
          engagementRate: 1.042654028436019
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-01T22:16:26.000Z',
        amountCents: 2069
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '75423de2-bf09-4f7e-83ff-b26d2e665e13',
      content: [],
      creator: {
        id: '7ae8eda2-5f83-40ac-8393-efbdb482092c',
        firstName: 'Kayla',
        lastName: 'Cernosek',
        email: 'kayster212@yahoo.com',
        instagramProfile: {
          handle: 'oo.la.yum',
          followers: 1023,
          engagementRate: 3.470185728250244
        },
        tiktokProfile: {
          handle: 'oo.la.yum',
          followers: 273,
          engagementRate: 9.976798143851507
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-01T22:33:29.000Z',
        amountCents: 2088
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '2bb53fea-f1a1-438b-90d5-25c85b092974',
      content: [],
      creator: {
        id: 'e49189c9-27e5-4dc4-82f6-e401578d097e',
        firstName: 'Nicole',
        lastName: 'Feeney',
        email: 'nicole08lepore@gmail.com',
        instagramProfile: {
          handle: 'liamheartspaws',
          followers: 13997,
          engagementRate: 1.392441237408016
        },
        tiktokProfile: {
          handle: 'liamheartspaws',
          followers: 207,
          engagementRate: 5.579264617239301
        },
        youtubeProfile: {
          handle: 'livinglifewithliamf',
          followers: 186
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-01T23:14:27.000Z',
        amountCents: 2057
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'dddde3cf-6286-4e79-ba49-38d088803e14',
      content: [],
      creator: {
        id: 'a2834543-9e36-4d27-bead-398e9f4c074f',
        firstName: 'Christine',
        lastName: 'Cruz',
        email: 'ceejei_cutie04@yahoo.com',

        tiktokProfile: {
          handle: 'sheejaey',
          followers: 41
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-02T00:43:01.000Z',
        amountCents: 2016
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'c411e6f6-3fa8-45e3-8c0c-0bcae2184ee8',
      content: [],
      creator: {
        id: '990cb803-d403-4dfe-957e-2bebf833bc06',
        firstName: 'Rebecca',
        lastName: 'Ruiz',
        email: 'magicalclubruiz@gmail.com',
        instagramProfile: {
          handle: 'magicalclubruiz',
          followers: 2810,
          engagementRate: 1.2206405693950177
        },
        tiktokProfile: {
          handle: 'magical_club_ruiz',
          followers: 3425,
          engagementRate: 4.480324237711485
        },
        youtubeProfile: {
          handle: 'magicalclubruiz',
          followers: 25
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-02T00:45:44.000Z',
        amountCents: 2088
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '271ab01c-913d-413e-b5cf-cb888b1b9b86',
      content: [],
      creator: {
        id: 'c3fe065c-b706-4b7d-bf78-a667a203c6d4',
        firstName: 'Sergey',
        lastName: 'Pivanov',
        email: '18091985p@gmail.com',

        tiktokProfile: {
          handle: 'sergeypivanov7',
          followers: 1
        },
        youtubeProfile: {
          handle: 'sergeypivanov6206',
          followers: 5
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-02T02:35:11.000Z',
        amountCents: 3508
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'a3d6ae33-d121-4bd2-ad76-ad55942cdf5e',
      content: [],
      creator: {
        id: '43e9eba7-a9c3-4e6b-a48b-b5e345239023',
        firstName: 'Keneshia',
        lastName: 'Cole',
        email: 'colekeneshia@yahoo.com',

        tiktokProfile: {
          handle: 'vickyblueee',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-02T03:22:56.000Z',
        amountCents: 3294
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '4b30ab81-ceac-4f11-b93b-5c27d8651cb3',
      content: [],
      creator: {
        id: 'ba418db9-6731-4bd2-a8f6-152d4fbd6267',
        firstName: 'Asharee',
        lastName: 'Millwood',
        email: 'ashareemillwood@gmail.com',
        instagramProfile: {
          handle: 'areez_prodz',
          followers: 256,
          engagementRate: 13.026819923371647
        },
        tiktokProfile: {
          handle: 'rare_don876',
          followers: 241,
          engagementRate: 12.749003984063744
        },
        youtubeProfile: {
          handle: 'beenashstillis4933',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-02T04:44:22.000Z',
        amountCents: 2247
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'f29024c1-535d-410c-b775-076041760974',
      content: [],
      creator: {
        id: '99608c39-e440-491d-a91a-f6338824d298',
        firstName: 'Lucas',
        lastName: 'Wreist',
        email: 'gibbsnikota12@yahoo.com',

        tiktokProfile: {
          handle: 'tyson.gibbs',
          followers: 0
        },
        youtubeProfile: {
          handle: 'hayley5448',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-02T05:52:36.000Z',
        amountCents: 2791
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'dd2a9139-bc89-46d0-a4f8-ba789ce895dd',
      content: [],
      creator: {
        id: '9299e718-3652-40b6-a461-6db661effe27',
        firstName: 'Elizabeth',
        lastName: 'Rendon',
        email: 'rendon.elizabeth5@gmail.com',
        instagramProfile: {
          handle: 'lizr.915',
          followers: 1527,
          engagementRate: 3.628028814669286
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-02T07:25:47.000Z',
        amountCents: 2088
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'a525aea3-3a00-4eac-9429-7c496232cd16',
      content: [],
      creator: {
        id: 'e9e6cbc4-da6c-40d3-8d8a-b3ea8c00e348',
        firstName: 'Shanique',
        lastName: 'Wilson',
        email: 'shianwilson96@gmail.com',

        tiktokProfile: {
          handle: 'shyiishian',
          followers: 363
        },
        youtubeProfile: {
          handle: 'shaniquewilson63',
          followers: 2
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-02T09:14:14.000Z',
        amountCents: 6000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '9b1b3b3a-84ff-497e-bfb7-c442235b9b0c',
      content: [],
      creator: {
        id: 'cfb18040-e5a6-4e5d-a86c-26575b2b60bf',
        firstName: 'Mellisa',
        lastName: 'Sinclair',
        email: 'mellisa_sinclair@hotmail.com',

        tiktokProfile: {
          handle: '__mellisa1',
          followers: 565,
          engagementRate: 5.443548387096774
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-02T09:24:02.000Z',
        amountCents: 2764
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '0c34b107-dffd-4e70-af7f-733a2a39f5b7',
      content: [],
      creator: {
        id: '5f360c4f-43c4-4323-b12c-009b97ab5177',
        firstName: 'Rianna',
        lastName: 'Evans',
        email: 'riannaevans62@gmail.com',
        instagramProfile: {
          handle: 'reremilesxo',
          followers: 2013,
          engagementRate: 3.162775293922835
        },
        tiktokProfile: {
          handle: 'dirasmamaa',
          followers: 58
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-02T10:14:06.000Z',
        amountCents: 2763
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'cd5a5a50-d262-4045-9056-f3d819ad0ed9',
      content: [],
      creator: {
        id: '8f431586-01ef-4dfd-8475-50cfdd694148',
        firstName: 'Destinie',
        lastName: 'Godfrey',
        email: 'destiniegodfrey1@gmail.com',
        instagramProfile: {
          handle: 'ledgefantasy98',
          followers: 2539,
          engagementRate: 0.15754233950374164
        },
        tiktokProfile: {
          handle: 'deeledge',
          followers: 382,
          engagementRate: 2.5210084033613445
        },
        youtubeProfile: {
          handle: 'likklemissbadtingz'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-02T12:33:26.000Z',
        amountCents: 2852
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '216600b5-42c8-4e55-b446-7b8cc779e648',
      content: [],
      creator: {
        id: '9fe5f3fb-41e4-4c8d-afdb-f46ab313d077',
        firstName: 'Monika',
        lastName: 'Conrad',
        email: 'conrad.monika.4@gmail.com',
        instagramProfile: {
          handle: 'ayee_monn',
          followers: 881
        },
        tiktokProfile: {
          handle: 'liliconrad509',
          followers: 10
        },
        youtubeProfile: {
          handle: '18monikalili',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-02T16:45:54.000Z',
        amountCents: 2059
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '5a677718-9a80-4602-86d2-367e1a442ff8',
      content: [],
      creator: {
        id: 'd1687e7a-86c2-4c2d-a1b3-b40fab890f75',
        firstName: 'Treven',
        lastName: 'Warner',
        email: 'sneakerkingsohio@gmail.com',
        instagramProfile: {
          handle: 'madebytreven',
          followers: 5797,
          engagementRate: 1.894083146455063
        },
        tiktokProfile: {
          handle: 'madebytreven',
          followers: 2380
        },
        youtubeProfile: {
          handle: 'trevenwarner',
          followers: 106
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-02T17:24:17.000Z',
        amountCents: 3123
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'acb82e44-93e3-4840-a577-e39636220d96',
      content: [],
      creator: {
        id: 'bd7ce1f6-6103-4b13-931e-ca3071ec1e28',
        firstName: 'Wenny',
        lastName: 'Douglas',
        email: 'wennydouglas@yahoo.com',

        tiktokProfile: {
          handle: 'wenlee851',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-02T17:26:23.000Z',
        amountCents: 4500
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '19c0551d-bf60-482c-ab96-3231a93b9dbe',
      content: [],
      creator: {
        id: '1eee5574-9801-483b-bb7d-a8e4cc4f1179',
        firstName: 'JIJI',
        lastName: 'ROD',
        email: 'reviewsbyjiji@gmail.com',

        tiktokProfile: {
          handle: 'jiji.rod',
          followers: 4194,
          engagementRate: 12.538226299694188
        },
        youtubeProfile: {
          handle: 'jijirod1047',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-02T17:54:05.000Z',
        amountCents: 948
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'e26633a1-7f0e-4386-8121-7703fc092eec',
      content: [],
      creator: {
        id: '40bcb994-3ea8-402a-9a44-a14f3f8dff8b',
        firstName: 'Shanea',
        lastName: 'Fearon',
        email: 'shaneafearon@gmail.com',

        tiktokProfile: {
          handle: 'shaneafearon',
          followers: 4991,
          engagementRate: 16.230366492146597
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-02T18:32:55.000Z',
        amountCents: 2105
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '2f4e0ac0-2836-436f-bb56-16bfb1d5b604',
      content: [],
      creator: {
        id: '135da2b9-0c85-4d80-93b6-92516faaebaf',
        firstName: 'Najoni',
        lastName: 'Montgomery',
        email: 'najonimontgomery@icloud.com',
        instagramProfile: {
          handle: 'lifewithnayy',
          followers: 2100
        },
        tiktokProfile: {
          handle: 'lifeewithnayyy',
          followers: 1106,
          engagementRate: 13.501144164759726
        },
        youtubeProfile: {
          handle: 'lifewithnayy8937',
          followers: 80
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-02T18:55:56.000Z',
        amountCents: 1991
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '8033e1ee-67ad-4e10-a37c-f056ccc6b4bf',
      content: [],
      creator: {
        id: 'a0748f2a-d92f-4ec7-81cc-24e136038e00',
        firstName: 'Shanakay',
        lastName: 'Noble',
        email: 'nobleterryann5@gmail.com',
        instagramProfile: {
          handle: 'authentic_golden_wear',
          followers: 907,
          engagementRate: 0.2631578947368421
        },
        tiktokProfile: {
          handle: 'shanni__boo',
          followers: 660
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-02T19:31:17.000Z',
        amountCents: 2000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'd1492d51-0cef-4eb8-9ec1-c77d990be21e',
      content: [],
      creator: {
        id: '512d89b8-59af-4d76-a19d-c015a0fb4bcf',
        firstName: 'Patrick',
        lastName: 'Ricketts',
        email: 'patrickricketts17@gmail.com',
        instagramProfile: {
          handle: 'cedella_reidd',
          followers: 634
        },
        tiktokProfile: {
          handle: 'dexta1st',
          followers: 4313,
          engagementRate: 8.78623188405797
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-02T19:33:30.000Z',
        amountCents: 2995
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'af3d79c3-7bed-49c0-8557-16a2035caf83',
      content: [],
      creator: {
        id: 'ac3894cb-8ed9-4fb8-ac27-666eb34407f1',
        firstName: 'Taneisha',
        lastName: 'Smith',
        email: 'taneishasmith317@gmail.com',
        instagramProfile: {
          handle: 'lenice_thee_content_creator',
          followers: 638,
          engagementRate: 10.919540229885058
        },
        tiktokProfile: {
          handle: 'chinsue2',
          followers: 1776
        },
        youtubeProfile: {
          handle: 'taneishasmith2084',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-02T19:34:19.000Z',
        amountCents: 1898
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'fa96f0b6-3e89-4d68-9041-e1e41286f75c',
      content: [],
      creator: {
        id: 'fa58e81c-7875-4c5d-b848-2ab19419cc52',
        firstName: 'Brenna',
        lastName: 'Ard',
        email: 'brenna.ard@gmail.com',

        tiktokProfile: {
          handle: 'brendal143',
          followers: 43
        },
        youtubeProfile: {
          handle: 'brennachapman4745',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-02T22:53:25.000Z',
        amountCents: 1991
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'c6130967-6dbf-46a6-9457-e46743b49897',
      content: [],
      creator: {
        id: '3381aa28-3af3-4444-bcc2-ed1271a3f646',
        firstName: 'Tola',
        lastName: 'Anif',
        email: 'tolaanif@gmail.com',
        instagramProfile: {
          handle: 'tolaanif',
          followers: 6611,
          engagementRate: 0.01346015793251974
        },
        tiktokProfile: {
          handle: 'tolaanif',
          followers: 2298,
          engagementRate: 0.6622516556291391
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-03T00:35:14.000Z',
        amountCents: 2126
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'bcaf47ea-f5d1-44fb-aff9-9f3d39c0673e',
      content: [],
      creator: {
        id: '4e839230-4721-4261-9c57-2561e7335cc7',
        firstName: 'Britany',
        lastName: 'Lynch',
        email: 'britanylynch20@icloud.com',

        tiktokProfile: {
          handle: 'brittl',
          followers: 647,
          engagementRate: 18.45878136200717
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-03T01:29:44.000Z',
        amountCents: 2298
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'ade77bc3-58ea-4ef4-a5c4-9f238b24a7ca',
      content: [],
      creator: {
        id: '3cf231d2-3d6c-4ad3-a118-8420f7f955d6',
        firstName: 'Chantelle',
        lastName: 'Morgan RCJ',
        email: 'burgessmonique75@yahoo.com',
        instagramProfile: {
          handle: 'monique_hairnmore',
          followers: 8,
          engagementRate: 0
        },
        tiktokProfile: {
          handle: 'policesoldier.mix',
          followers: 1596
        },
        youtubeProfile: {
          handle: 'mrsburgess',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-03T02:13:38.000Z',
        amountCents: 2337
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'd80636f6-e7f2-4bdd-9732-eca3566a5aad',
      content: [],
      creator: {
        id: 'f141031e-33fb-4fa9-8a9d-12a380fa4575',
        firstName: 'Africka',
        lastName: 'McDonald',
        email: 'stonewall_africka@yahoo.com',

        tiktokProfile: {
          handle: 'queenafricka',
          followers: 17606,
          engagementRate: 17.719568567026194
        },
        youtubeProfile: {
          handle: 'queen_africka',
          followers: 29
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-03T02:26:53.000Z',
        amountCents: 2767
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '87040889-aefe-4392-9e8e-afafc26d8635',
      content: [],
      creator: {
        id: '6f34a9cf-bd60-4db2-9548-1f010e410b8d',
        firstName: 'Hailey',
        lastName: 'Harris',
        email: 'diaryofawannabeworldtraveler@gmail.com',
        instagramProfile: {
          handle: 'diaryofawannabeworldtraveler',
          followers: 7401,
          engagementRate: 0.795838400216187
        },
        tiktokProfile: {
          handle: 'diaryofawannbetraveler',
          followers: 476,
          engagementRate: 6.985294117647059
        },
        youtubeProfile: {
          handle: 'diaryofawannabeworldtraveler'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-03T10:33:19.000Z',
        amountCents: 2654
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '5b6ba4d7-2791-4414-ad14-e5f1e009d14d',
      content: [],
      creator: {
        id: '50585eb5-62b7-4c52-b1b5-db9729f3ada8',
        firstName: 'Neychea',
        lastName: 'Colvin',
        email: 'info.cheaandrea@gmail.com',
        instagramProfile: {
          handle: 'iamcheaandrea',
          followers: 21964,
          engagementRate: 1.4409943543981059
        },
        tiktokProfile: {
          handle: 'ilycheaandrea',
          followers: 785,
          engagementRate: 14.124293785310735
        },
        youtubeProfile: {
          handle: 'bossylifeempire',
          followers: 967
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-03T13:56:29.000Z',
        amountCents: 1925
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '6d1135bd-6377-4441-8ebd-3b673dd3ec21',
      content: [],
      creator: {
        id: '11c75f2c-9867-482b-ac50-4ca14fb3f5dd',
        firstName: 'Shereece',
        lastName: 'Francis',
        email: 'francis.shereece@icloud.com',

        tiktokProfile: {
          handle: 'ree_cy',
          followers: 1007,
          engagementRate: 16.988950276243095
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-03T15:25:05.000Z',
        amountCents: 1818
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '0ea8361e-c616-491a-8fba-da7eb62abaa8',
      content: [],
      creator: {
        id: '9bfb02f3-455e-4f57-a1b4-1306111ce274',
        firstName: 'Brandon',
        lastName: 'Lamoth',
        email: 'blamoth791@outlook.com',

        tiktokProfile: {
          handle: 'brandzpbwo3',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-03T18:28:18.000Z',
        amountCents: 5000
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '3a7f6f59-a131-490c-917f-5a57ed2c0fdc',
      content: [],
      creator: {
        id: '7c81b3bc-d89a-4879-b8fd-88e72fc2aa61',
        firstName: 'Shavoy',
        lastName: 'Grey',
        email: 'gshavoy94@gmail.com',

        tiktokProfile: {
          handle: 'thickaz0645',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-03T21:53:30.000Z',
        amountCents: 3000
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '5c24a13f-8e2c-43f2-b25d-097ffa359efb',
      content: [],
      creator: {
        id: '6c81c6c1-3264-4994-abf1-a9c25db7ddc6',
        firstName: 'Nicolette',
        lastName: 'Thomas may pen',
        email: 'nicolettthomas139@gmail.com',
        instagramProfile: {
          handle: 'nickys_cosmetics',
          followers: 75,
          engagementRate: 6.666666666666667
        },
        tiktokProfile: {
          handle: 'queenzyviralvideo',
          followers: 10125,
          engagementRate: 7.365591397849462
        },
        youtubeProfile: {
          handle: 'queenzy.629',
          followers: 5
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-04T20:35:06.000Z',
        amountCents: 2838
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'b99ce168-e063-4bd1-9852-d011f307d1db',
      content: [],
      creator: {
        id: '292fa8d4-f2af-4dcd-b4c8-dae0161a73da',
        firstName: 'Shana-Lee',
        lastName: 'Clocken',
        email: 's.clocken@yahoo.com',
        instagramProfile: {
          handle: 'clockenscollections',
          followers: 5714,
          engagementRate: 0.29926496324816243
        },
        tiktokProfile: {
          handle: 'beingshanalee',
          followers: 12340,
          engagementRate: 3.0053765279103075
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-05T05:54:16.000Z',
        amountCents: 2767
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'afdcd323-ea2d-43fe-a053-90ec81cb455d',
      content: [],
      creator: {
        id: '09090c78-5bc1-43d0-9d46-719952600d9e',
        firstName: 'Kenisha',
        lastName: 'Bennett-Ellis',
        email: 'bennettkenisha20@gmail.com',

        tiktokProfile: {
          handle: 'kenishaellis6',
          followers: 964
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-05T05:54:18.000Z',
        amountCents: 2767
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '1668446f-4972-43fc-89d1-668e3b7cce85',
      content: [],
      creator: {
        id: '620c953f-61bf-4443-b86f-b4f014f76034',
        firstName: 'Channel',
        lastName: 'Smith',
        email: 'sheisroyalfeb17@gmail.com',

        tiktokProfile: {
          handle: 'roses_for_me',
          followers: 123,
          engagementRate: 7.296909363083614
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-05T06:21:47.000Z',
        amountCents: 2943
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '32dcabea-15b0-4a19-8276-7046ce5d0de8',
      content: [],
      creator: {
        id: 'f1b663ed-6553-4d1d-812a-36d145b652ac',
        firstName: 'Stephen',
        lastName: 'Anderson',
        email: 'stephenstepper@gmail.com',
        instagramProfile: {
          handle: 'yung_genius876',
          followers: 1938,
          engagementRate: 2.7593254982115485
        },
        tiktokProfile: {
          handle: 'yung_genius8',
          followers: 277
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-05T12:24:27.000Z',
        amountCents: 2767
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'fe0b3457-850b-4a77-967a-cd62d8970704',
      content: [],
      creator: {
        id: '95d415e0-cf53-4a81-943d-3b03a01c6870',
        firstName: 'Zanalli',
        lastName: 'Forbes',
        email: 'zanalliforbes21@gmail.com',

        tiktokProfile: {
          handle: 'liam_mommy__',
          followers: 450,
          engagementRate: 7.898009950248755
        },
        youtubeProfile: {
          handle: 'browning876',
          followers: 459
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-05T15:04:57.000Z',
        amountCents: 2664
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '87c6c858-8cad-4fd4-8ff4-803e34898890',
      content: [],
      creator: {
        id: '5680d8e7-d7b1-400c-aecd-20e6ef7b6321',
        firstName: 'Ricardo',
        lastName: 'Anderson ',
        email: 'callmehcardo@gmail.com',

        tiktokProfile: {
          handle: '_iicon_',
          followers: 618
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-05T21:24:00.000Z',
        amountCents: 2767
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '04e931e0-e2f1-44d1-991e-6974189ff701',
      content: [],
      creator: {
        id: '4e85cd6e-4aef-417b-b010-53926c4e3937',
        firstName: 'Tineisha',
        lastName: 'Reid',
        email: 'cedella.reid26@gmail.com',

        tiktokProfile: {
          handle: 'della3177',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-06T13:34:04.000Z',
        amountCents: 3052
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'eac628fd-6fbc-403e-8bb6-2ed765104fad',
      content: [],
      creator: {
        id: 'f3083390-6a45-4a24-aadf-7b50de700ee4',
        firstName: 'Tasheika',
        lastName: 'Hylton',
        email: 'thyltoncampbell@gmail.com',

        tiktokProfile: {
          handle: 'frozen_queen_iceucg',
          followers: 950,
          engagementRate: 24.137931034482758
        },
        youtubeProfile: {
          handle: 'tasheikahylton5230',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-07T11:25:50.000Z',
        amountCents: 2767
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'f693bc9d-f06d-456e-b0f6-b50e94675eed',
      content: [],
      creator: {
        id: 'c5a55410-0165-4386-b7da-255081b12185',
        firstName: 'Sarah',
        lastName: 'Evans',
        email: 'evans.sarah49@yahoo.com',

        tiktokProfile: {
          handle: 'srhnicole',
          followers: 279
        },
        youtubeProfile: {
          handle: 'user-ep8vg9vi6e',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-08T08:05:58.000Z',
        amountCents: 3836
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '4027a626-8c1a-45a3-a047-278e35b3c768',
      content: [],
      creator: {
        id: '07916767-ef7b-4af5-bc3b-71ee07c79232',
        firstName: 'Anna',
        lastName: 'Izotov',
        email: 'an.izotov09@gmail.com',
        instagramProfile: {
          handle: 'anuta.aaaa',
          followers: 630
        },
        tiktokProfile: {
          handle: 'authentic.anuta',
          followers: 2
        },
        youtubeProfile: {
          handle: 'annabondar2511',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-08T08:45:26.000Z',
        amountCents: 2799
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'e9f4cec0-959a-4294-982b-944311394f03',
      content: [],
      creator: {
        id: '5616170b-1f8f-4038-992a-b32ab86d3a8b',
        firstName: 'Megan',
        lastName: 'Freund',
        email: 'ohheymeygeh@gmail.com',
        instagramProfile: {
          handle: '_meygeh_',
          followers: 6117,
          engagementRate: 1.5906490109530813
        },
        tiktokProfile: {
          handle: 'ohheymeygeh',
          followers: 162,
          engagementRate: 6.858710562414267
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-09T14:16:09.000Z',
        amountCents: 2117
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '2259f65b-1643-4746-99a3-1301c2d826b9',
      content: [],
      creator: {
        id: 'e1f11f16-7c3b-4520-af73-91b1f781330d',
        firstName: 'Alex',
        lastName: 'Baugh',
        email: 'bcelene89@icloud.com',

        tiktokProfile: {
          handle: 'itsyagirlcelene',
          followers: 3343,
          engagementRate: 1.9815033830440594
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-11T01:21:14.000Z',
        amountCents: 6000
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'c467b74c-13f1-4b9a-9beb-364abfb81942',
      content: [],
      creator: {
        id: '5b5ee751-eb07-4940-b81f-13ef6db2aaba',
        firstName: 'Emilie',
        lastName: 'Basagic',
        email: 'emilielaughlin@gmail.com',
        instagramProfile: {
          handle: 'elaughlin',
          followers: 12232,
          engagementRate: 0.22482014388489208
        },
        tiktokProfile: {
          handle: 'elaughlinn',
          followers: 4843
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-11T18:44:10.000Z',
        amountCents: 2995
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '822b1d10-8e1f-42d9-88a0-ff7e871fdeaf',
      content: [],
      creator: {
        id: '01a5129a-db0c-441a-b3e4-ef033f486a85',
        firstName: 'Mohammad',
        lastName: 'Fakhimnia',
        email: '123456789qazwsxedcrfv1@gmail.com',
        instagramProfile: {
          handle: 'blog_flex_mf',
          followers: 342,
          engagementRate: 6.578947368421052
        },
        tiktokProfile: {
          handle: 'mleaon',
          followers: 0
        },
        youtubeProfile: {
          handle: 'marfa-hs4xm',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-11T18:45:03.000Z',
        amountCents: 5144
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '919dc5b3-33db-4e66-a193-aeaf2ae5b58e',
      content: [],
      creator: {
        id: '44a1f23a-0e88-4580-ad4f-43757b6ae27f',
        firstName: 'Marina',
        lastName: 'Goly',
        email: 'marina0308@mail.ru',

        tiktokProfile: {
          handle: 'user1079824523519',
          followers: 9
        },
        youtubeProfile: {
          handle: 'user-rh8fh6lh3n',
          followers: 267
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-11T18:45:07.000Z',
        amountCents: 4468
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '89592033-97e1-4889-b651-7a16f9a65a4c',
      content: [],
      creator: {
        id: '7966c8ac-7284-47e0-95db-00f8c3390234',
        firstName: 'Shanakay',
        lastName: 'Scott',
        email: 'mhzbrown96@gmail.com',

        tiktokProfile: {
          handle: 'lly_mhz',
          followers: 443
        },
        youtubeProfile: {
          handle: 'jaysonnbrown',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-11T20:15:36.000Z',
        amountCents: 1895
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'c23b6ddf-b91b-438d-ab4a-8bb4b61b81dd',
      content: [],
      creator: {
        id: '0da3d3ef-3f88-4f75-9fcd-47a530684007',
        firstName: 'Sean',
        lastName: 'Kay',
        email: 'sean.kay777@gmail.com',

        tiktokProfile: {
          handle: 'seankay777',
          followers: 44
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-11T20:45:48.000Z',
        amountCents: 5474
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '823681ed-2028-427c-b929-e26e63a488bd',
      content: [],
      creator: {
        id: 'd8cec988-c72f-4d88-bc39-710a9ad7418f',
        firstName: 'Esequiel ',
        lastName: 'Monteros',
        email: 'zeke.monteros16@gmail.com',
        instagramProfile: {
          handle: 'zekemonteros',
          followers: 103
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-12T09:04:53.000Z',
        amountCents: 3192
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'f1dcd119-9680-4cdd-a64a-b47638632e6e',
      content: [],
      creator: {
        id: '4c9fc10f-e060-468c-a2a4-9f2a55a3e9f3',
        firstName: 'Vadim',
        lastName: 'Antipov',
        email: 'vad.ant@icloud.com',
        instagramProfile: {
          handle: 'midavmai',
          followers: 1309,
          engagementRate: 0.47364400305576776
        },
        tiktokProfile: {
          handle: 'lmiiinm',
          followers: 5,
          engagementRate: 1.7699115044247788
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-12T22:04:21.000Z',
        amountCents: 3694
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'bcfeab90-efef-4e9f-9bbe-c5e4113bddef',
      content: [],
      creator: {
        id: '61885afd-09ed-4bef-97aa-d72538fadb24',
        firstName: 'Jessica',
        lastName: 'Cervantes',
        email: 'jessicacervantes2431@gmail.com',

        tiktokProfile: {
          handle: 'jesscerv143',
          followers: 358,
          engagementRate: 6.592178770949721
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-13T21:02:11.000Z',
        amountCents: 3002
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'a9552979-26b8-405a-96a3-ca379a5d2b84',
      content: [],
      creator: {
        id: '0f3ff74b-a90b-4bb0-9009-6bfadb869a36',
        firstName: 'Lamantha',
        lastName: 'Laing',
        email: 'laing.laman21@gmail.com',

        tiktokProfile: {
          handle: 'legally_21_tho',
          followers: 294
        },
        youtubeProfile: {
          handle: 'glassesbabe419',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-14T00:22:36.000Z',
        amountCents: 3694
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'fe58627d-0520-4233-b824-8de66d80ce76',
      content: [],
      creator: {
        id: '5599a7fe-8ac0-4bdd-b2f7-6dcbc62f2e13',
        firstName: 'Shafagh',
        lastName: 'Son',
        email: 'sichyar@gmail.com',

        tiktokProfile: {
          handle: 'user6842391455010',
          followers: 1
        },
        youtubeProfile: {
          handle: 'sahar19821',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-15T03:55:59.000Z',
        amountCents: 5256
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'f0ed02f6-6125-4a85-8db6-31b52df6de52',
      content: [],
      creator: {
        id: '1e705458-70c7-4d26-bf98-0f54290e1262',
        firstName: 'Laci',
        lastName: 'Hunter',
        email: 'contactus@athomewiththehunters.com',
        instagramProfile: {
          handle: 'hunterhomecontentco',
          followers: 880,
          engagementRate: 1.4204545454545454
        },
        tiktokProfile: {
          handle: 'ooopsiedaisyitslaci',
          followers: 194,
          engagementRate: 6.355042016806722
        },
        youtubeProfile: {
          handle: 'athomewiththehunters',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-15T17:52:15.000Z',
        amountCents: 2995
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'dfc34082-f94d-4ead-8f8d-c542692e3080',
      content: [],
      creator: {
        id: '9c0466ef-ef5e-48e3-9a90-a608e024696d',
        firstName: 'Serg',
        lastName: 'Nedbalo',
        email: 'sergtulchin@gmail.com',

        tiktokProfile: {
          handle: 'marseille_c',
          followers: 638,
          engagementRate: 6.652806652806653
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-17T16:00:00.000Z',
        amountCents: 3769
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '4f8e378b-feea-4373-b5ff-7e0fb203b749',
      content: [],
      creator: {
        id: 'ca2ee641-839b-4422-b4c2-78fe842a5962',
        firstName: 'Alyssa',
        lastName: 'Vanlandingham',
        email: 'alyssalonglastname@gmail.com',
        instagramProfile: {
          handle: 'alyssalonglastname',
          followers: 42434,
          engagementRate: 0.9758684074091531
        },
        tiktokProfile: {
          handle: 'alyssalonglastname',
          followers: 68,
          engagementRate: 3.952023279344752
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-18T22:06:17.000Z',
        amountCents: 3030
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '002cb4f0-8504-4585-8e7e-3c4db7797dad',
      content: [],
      creator: {
        id: 'ea71bbaf-d80b-4b7f-8be2-5e0a1a7d9019',
        firstName: 'Ana-Sofia',
        lastName: 'DuLaney',
        email: 'nava.ana@gmail.com',
        instagramProfile: {
          handle: 'gardeninginjeans',
          followers: 1155,
          engagementRate: 2.1645021645021645
        },
        tiktokProfile: {
          handle: 'anasofianavarrete4',
          followers: 4
        },
        youtubeProfile: {
          handle: 'theanamachine',
          followers: 5
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T13:16:29.000Z',
        amountCents: 2995
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'a660a409-e8dc-4a66-894c-8792a8ef4d58',
      content: [],
      creator: {
        id: 'bf304d05-59da-431e-a37d-265aac682856',
        firstName: 'Jr',
        lastName: 'Sparrow',
        email: 'wvuncommonplace@gmail.com',
        instagramProfile: {
          handle: 'wvuncommonplace',
          followers: 1387,
          engagementRate: 0.41816870944484497
        },
        tiktokProfile: {
          handle: 'wvuncommonplace',
          followers: 5004,
          engagementRate: 4.594974521173783
        },
        youtubeProfile: {
          handle: 'wvuncommonplace3936',
          followers: 18
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T13:16:31.000Z',
        amountCents: 3708
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '0962b3cf-8707-4d0d-82a3-ca6174a300dd',
      content: [],
      creator: {
        id: 'b114c24a-fbab-4a5e-899c-d4f459c12f7e',
        firstName: 'Ally',
        lastName: 'Moore ',
        email: 'allysonmoore02@gmail.com',

        tiktokProfile: {
          handle: 'allysonmoore6',
          followers: 263
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T15:01:55.000Z',
        amountCents: 2833
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '2d2a19b8-dcd0-409e-99a9-b0a470f6eded',
      content: [],
      creator: {
        id: 'fa93d23a-1cb9-4998-981a-4d4156d52bf4',
        firstName: 'Nicholas',
        lastName: 'Mossey',
        email: '216mosseymgmt@gmail.com',
        instagramProfile: {
          handle: '216mossey',
          followers: 31531,
          engagementRate: 1.5494451668375744
        },
        tiktokProfile: {
          handle: 'fivestarrnick',
          followers: 1664,
          engagementRate: 1.103202846975089
        },
        youtubeProfile: {
          handle: '216mossey',
          followers: 1270
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T01:44:14.000Z',
        amountCents: 3009
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    }
  ]
}
