import Loading from '@momentum/components/loading'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { OrganicRankSummary } from '@momentum/routes/campaign/e-commerce/seoV2/organic/OrganicRankSummary'
import { Paper, Stack } from '@mui/material'
import { useEffect } from 'react'
import { KeywordTable } from '../common/keyword-table'
import { SeoTable } from '../common/seo-table'
import { SeoReportMode, useSeoContext } from '../context'
import { SeoOrganicImpressionsGraph } from './search-impressions-graph'
import { EmptyState } from '@momentum/routes/campaign/e-commerce/common/EmptyState'
import EmptyStateImage from '/images/empty-states/seo.png'
import { SUPPORTED_STORES } from '@momentum/routes/campaign/e-commerce/seoV2/common/utils'

export const OrganicSeo = () => {
  const { isAdminView } = useUserSessionContext()
  const { campaignDetails } = useCampaignContext()
  const { isLoading, productSeoSummary, productKeywordSummaries, seoReportMode, setSeoReportMode } = useSeoContext()

  useEffect(() => {
    setSeoReportMode(SeoReportMode.ORGANIC)
    return () => setSeoReportMode(undefined)
  }, [])

  if (isLoading || !productSeoSummary || seoReportMode !== SeoReportMode.ORGANIC) {
    return <Loading />
  }

  if (!isAdminView && !productKeywordSummaries?.length) {
    return (
      <EmptyState
        image={EmptyStateImage}
        title="We’re collecting search rank data"
        description="The SEO dashboard will be available soon."
      />
    )
  }

  return (
    <Stack spacing={5}>
      <Paper sx={{ px: 4, py: 2 }}>
        <OrganicRankSummary />
      </Paper>
      {SUPPORTED_STORES.includes(campaignDetails.product.store) && <SeoOrganicImpressionsGraph />}
      <SeoTable />
      <Stack>
        <KeywordTable />
      </Stack>
    </Stack>
  )
}
