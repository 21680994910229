import PackagePlusIcon from '@momentum/components/icons/package-plus'
import Row from '@momentum/components/row'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { AddProductCallback } from '@momentum/routes/brand/context/types'
import { getCdnImageUrl } from '@momentum/utils/imageUtils'
import { StoreToFlagIcon, StoreToIcon } from '@momentum/utils/storeIcons'
import { ellipsisString } from '@momentum/utils/stringUtils'
import { OpenInNewOutlined } from '@mui/icons-material'
import { Button, Chip, Link, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { STORE_TO_LOCALE } from '@productwindtom/shared-momentum-zeus-types'
import { toCurrencyStringCents } from '@productwindtom/shared-ws-currency'
import { Product } from '../context/queries'
import { getSkuType } from '@momentum/utils/storeUtils'

export const ProductCard = ({
  product,
  campaignId,
  maxWidth = 400,
  direction = 'column',
  onProductAdd,
  hasUpdateProductEnabled = false
}: {
  product: Product
  campaignId?: string
  maxWidth?: number
  direction?: 'row' | 'column'
  onProductAdd?: AddProductCallback
  hasUpdateProductEnabled?: boolean
}) => {
  const { setIsAddProductActiveOrCallback } = useBrandContext()

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      spacing={2}
      px={2}
      border={theme => `1px solid ${theme.palette.grey.A200}`}
      borderRadius={'4px'}
      maxWidth={maxWidth}
      width={'100%'}
    >
      <Box width={'68px'} height={'68px'} p={1} alignItems={'center'} justifyContent={'center'}>
        <img
          src={getCdnImageUrl(product.image)}
          loading="lazy"
          width="100%"
          height={'100%'}
          style={{ objectFit: 'contain' }}
        />
      </Box>
      <Stack spacing={1} flex={1} direction={direction} justifyContent={'space-between'}>
        {hasUpdateProductEnabled && onProductAdd && (
          <Row spacing={2} justifyContent={'space-between'}>
            <Chip
              label={'Placeholder product'}
              icon={<PackagePlusIcon />}
              size={'xSmall'}
              variant={'filled'}
              color={'secondary'}
              sx={{ verticalAlign: 'sub' }}
            />
            <Button
              sx={{
                fontSize: '12px'
              }}
              onClick={() =>
                setIsAddProductActiveOrCallback({
                  callback: onProductAdd,
                  disableManual: true,
                  campaignId: campaignId,
                  selectedProductId: product.id
                })
              }
            >
              Edit product
            </Button>
          </Row>
        )}
        <Typography variant={'body1'}>{ellipsisString(product.name, 120)}</Typography>
        <Stack direction={'row'} spacing={2}>
          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            {StoreToIcon[product.store]?.({ fontSize: 'mSmall', style: { verticalAlign: 'middle' } })}
            {StoreToFlagIcon[product.store]?.({ fontSize: 'mSmall', style: { verticalAlign: 'middle' } })}
            {product.priceCents != null && (
              <Typography variant={'caption2'} color={theme => theme.palette.grey.A700}>
                {toCurrencyStringCents(product.priceCents, STORE_TO_LOCALE[product.store])}
              </Typography>
            )}
          </Stack>

          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            {product.skuId && (
              <Typography variant={'caption2'} color={'grey.A700'}>
                {getSkuType(product.store)}: {product.skuId}
              </Typography>
            )}
            {!!product.listingLink && (
              <Link href={product.listingLink} target={'_blank'} display={'flex'}>
                <OpenInNewOutlined fontSize={'mSmall'} color={'primary'} />
              </Link>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
