import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { ROUTES } from '@momentum/routes/RouteNames'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { AddProductCallback } from '@momentum/routes/brand/context/types'
import { CampaignGoalInformation } from '@momentum/utils/campaignGoalUtils'
import { CampaignGoalTypeToIcon } from '@momentum/utils/goalIcons'
import { getCdnImageUrl, noProductImageAlt } from '@momentum/utils/imageUtils'
import { StoreToIcon } from '@momentum/utils/storeIcons'
import { ellipsisString } from '@momentum/utils/stringUtils'
import { OpenInNewOutlined, WarningAmberOutlined } from '@mui/icons-material'
import { Box, Button, Chip, Grid, Link, Paper, Stack, Typography } from '@mui/material'
import { CampaignStatus, getCampaignStatus } from '@productwindtom/shared-campaign'
import {
  GraphQLTypes,
  InputType,
  InvoiceStatus,
  PauseReason,
  STORE_TO_LOCALE,
  Selector
} from '@productwindtom/shared-momentum-zeus-types'
import { toCurrencyStringCents } from '@productwindtom/shared-ws-currency'
import { useFlag } from '@unleash/proxy-client-react'
import { DateTime } from 'luxon'
import { Link as RouterLink, generatePath } from 'react-router-dom'
import PackagePlusIcon from '../icons/package-plus'

type Campaign = InputType<GraphQLTypes['Campaign'], typeof campaignSelector>

export type CampaignCardProps = {
  brandId: string
  campaign: Campaign
  onUpdateProduct: AddProductCallback
}
export const CampaignCard = ({ campaign, brandId, onUpdateProduct }: CampaignCardProps) => {
  const { setIsAddProductActiveOrCallback } = useBrandContext()
  const { isAdminView } = useUserSessionContext()

  const { product } = campaign

  const onAddProduct = () => {
    setIsAddProductActiveOrCallback({
      selectedProductId: campaign.product?.id,
      productPriceOverride: campaign.proposal?.productPriceOverride,
      disableManual: true,
      campaignId: campaign.id,
      callback: onUpdateProduct
    })
  }

  const { startDate, preLaunchDate, endDate, numCreatorsJoined, pauseReason } = campaign
  const campaignStatus = getCampaignStatus(startDate, preLaunchDate, endDate, numCreatorsJoined > 0, pauseReason)
  const brandPlaceholderScheduleCampaign = useFlag('BrandPlaceholderScheduleCampaign')
  const hasUpdateProductEnabled =
    product &&
    !product.skuId &&
    (isAdminView || brandPlaceholderScheduleCampaign) &&
    [CampaignStatus.UPCOMING, CampaignStatus.DELAYED].includes(campaignStatus)

  return (
    <Paper sx={{ py: 2, px: 3 }} data-cy={`campaignCard_${campaign.id}`}>
      <Grid container spacing={2} columns={32}>
        <Grid item display={'flex'} alignItems={'center'} justifyContent={'center'} xs={4}>
          <img
            style={{ width: 80, height: 80, objectFit: 'contain' }}
            src={getCdnImageUrl(product?.image) || '/images/no-product.png'}
            alt={`${product?.name} image`}
            onError={noProductImageAlt}
          />
        </Grid>
        <Grid item xs={8}>
          <Stack spacing={0.5}>
            {!product?.skuId && (
              <Box>
                <Chip
                  icon={<PackagePlusIcon color={'primary'} />}
                  sx={theme => ({
                    background: '#EBEDF6',
                    color: theme.palette.primary.main
                  })}
                  size={'small'}
                  label="Placeholder product"
                />
              </Box>
            )}
            <Stack direction={'row'}>
              <Typography variant={'link1'} style={{ wordBreak: 'break-all' }}>
                {!!product?.name && ellipsisString(product?.name, 70)}{' '}
                {!!product?.listingLink && (
                  <Link
                    href={product?.listingLink}
                    target={'_blank'}
                    display={'inline-flex'}
                    sx={{ verticalAlign: 'sub' }}
                  >
                    <OpenInNewOutlined fontSize={'mSmall'} color={'primary'} />
                  </Link>
                )}
              </Typography>
            </Stack>
            <Typography variant={'body1'}>{campaign.title}</Typography>
            <Stack direction={'row'} alignItems={'center'} spacing={2}>
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                {product?.store && StoreToIcon[product.store]({ fontSize: 'mSmall' })}
                {product?.priceCents != null && (
                  <Typography
                    variant={'body2'}
                    color={theme => theme.palette.grey.A700}
                    data-cy={'campaignProductPrice'}
                  >
                    {toCurrencyStringCents(product.priceCents, STORE_TO_LOCALE[product.store])}
                  </Typography>
                )}
              </Stack>
              {campaign.goal && (
                <Stack direction={'row'} spacing={0.5} alignItems={'center'}>
                  <Box color={'primary'} display={'inline-flex'}>
                    {CampaignGoalTypeToIcon[campaign.goal]?.({
                      fontSize: 'mSmall',
                      sx: { color: theme => theme.palette.grey.A700 }
                    })}
                  </Box>
                  <Typography variant={'body1'} color={theme => theme.palette.grey.A700} data-cy={'campaignGoal'}>
                    {CampaignGoalInformation[campaign.goal]?.name}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs alignSelf={'center'}>
          <Grid container>
            <Grid item xs={4} alignSelf={'center'} data-cy={'campaignStatus'}>
              {getStatus(campaign, campaignStatus)}
            </Grid>
            <Grid item xs={3} alignSelf={'center'}>
              <Stack alignItems={'center'}>
                <Typography variant={'label1'} data-cy={'campaignCreatorsJoined'}>
                  {campaign.numCreatorsJoined}/{campaign.numCreators}
                </Typography>
                <Typography variant={'body1'}>Creators joined</Typography>
              </Stack>
            </Grid>
            {hasUpdateProductEnabled && (
              <Grid item xs={4} alignSelf={'center'} data-cy={`addLiveProductUrl-${campaign.id}`}>
                <Button
                  variant="outlined"
                  sx={{
                    flex: '0 0 auto'
                  }}
                  onClick={onAddProduct}
                >
                  Add live product URL
                </Button>
              </Grid>
            )}

            {product?.skuId && (
              <Grid item xs={4} alignSelf={'center'} data-cy={'campaignNotifications'}>
                {getNotification(campaign)}
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item alignSelf={'center'} ml={'auto'}>
          <Button
            component={RouterLink}
            data-cy={'viewCampaignButton'}
            variant={'contained'}
            to={generatePath(ROUTES.CAMPAIGN, { brandId, campaignId: campaign.id })}
            data-viewcampaignname={`${campaign.title}`}
          >
            View campaign
          </Button>
        </Grid>
      </Grid>
    </Paper>
  )
}

const getNotification = ({ proposal }: Campaign) => {
  if (proposal?.invoiceStatus === InvoiceStatus.NOT_PAID) {
    const minDueDate = proposal?.invoiceStatus === InvoiceStatus.NOT_PAID ? proposal.invoiceDueDate : undefined

    return (
      <Stack alignItems={'center'} spacing={1}>
        <Box>
          <Chip
            label={'Not paid'}
            variant={'filled'}
            color={'warning'}
            size={'small'}
            icon={<WarningAmberOutlined color={'warning'} fontSize={'mSmall'} />}
          />
        </Box>
        {!!minDueDate && (
          <Typography variant={'body1'}>
            Payment due {DateTime.fromISO(minDueDate).toLocaleString(DateTime.DATE_MED)}
          </Typography>
        )}
      </Stack>
    )
  }
  return null
}

const getStatus = ({ startDate, endDate, pauseReason }: Campaign, campaignStatus: CampaignStatus) => {
  const start = DateTime.fromISO(startDate)
  const end = endDate ? DateTime.fromISO(endDate) : undefined

  switch (campaignStatus) {
    case CampaignStatus.ACTIVE:
      return (
        <Stack alignItems={'center'} spacing={1}>
          <Box>
            <Chip label={'Active'} color={'success'} size={'small'} />
          </Box>
          <Typography variant={'body1'}>Launched {start.toLocaleString(DateTime.DATE_MED)}</Typography>
        </Stack>
      )
    case CampaignStatus.PRE_LAUNCH:
      return (
        <Stack alignItems={'center'} spacing={1}>
          <Box>
            <Chip label={'Pre-Launch'} sx={{ backgroundColor: '#94D5D9' }} size={'small'} />
          </Box>
          <Typography variant={'body1'}>Pre-Launch</Typography>
        </Stack>
      )
    case CampaignStatus.UPCOMING:
      return (
        <Stack alignItems={'center'} spacing={1}>
          <Box>
            <Chip label={'Upcoming'} sx={{ backgroundColor: '#94D5D9' }} size={'small'} />
          </Box>
          <Typography variant={'body1'}>Launching {start.toLocaleString(DateTime.DATE_MED)}</Typography>
        </Stack>
      )
    case CampaignStatus.DELAYED: {
      return (
        <Stack alignItems={'center'} spacing={1}>
          <Box>
            <Chip label={'Delayed'} color={'error'} size={'small'} />
          </Box>
          <Typography variant={'body1'}>Delayed</Typography>
        </Stack>
      )
    }
    case CampaignStatus.FINISHED:
      return (
        <Stack alignItems={'center'} spacing={1}>
          <Box>
            <Chip label={'Completed'} size={'small'} />
          </Box>
          <Typography variant={'body1'}>Finished {end?.toLocaleString(DateTime.DATE_MED)}</Typography>
        </Stack>
      )
    case CampaignStatus.PAUSED:
      return (
        <Stack alignItems={'center'} spacing={1}>
          <Box>
            <Chip label={'Paused'} color="warning" size={'small'} />
          </Box>
          {pauseReason && PauseReasonDescription[pauseReason] && (
            <Typography variant={'body1'}>{PauseReasonDescription[pauseReason]}</Typography>
          )}
        </Stack>
      )
    default:
      return (
        <Stack alignItems={'center'} spacing={1}>
          <Box>
            <Chip label={'Unknown'} size={'small'} />
          </Box>
          <Typography variant={'body1'}>Unknown status</Typography>
        </Stack>
      )
  }
}

const PauseReasonDescription: Partial<Record<PauseReason, string>> = {
  [PauseReason.OUT_OF_STOCK]: 'Out of stock'
}

const campaignSelector = Selector('Campaign')({
  id: true,
  startDate: true,
  preLaunchDate: true,
  endDate: true,
  goal: true,
  skuId: true,
  numCreators: true,
  numCreatorsJoined: true,
  title: true,
  product: {
    id: true,
    skuId: true,
    name: true,
    priceCents: true,
    image: true,
    store: true,
    listingLink: true
  },
  proposal: {
    invoiceDueDate: true,
    invoiceStatus: true,
    launchDate: true,
    productPriceOverride: true,
    productPrice: true,
    totalCredits: true
  },
  pauseReason: true
})
