import { BenchmarkType, ModelTypes } from '@productwindtom/shared-momentum-zeus-types'
import SAMPLE_CAMPAIGN_BENCHMARK_METRICS_LAUNDRY from './laundry'
import SAMPLE_CAMPAIGN_BENCHMARK_METRICS_WIRELESS_EARBUDS from './wireless-earbuds'

export const SAMPLE_CAMPAIGN_BENCHMARK_METRICS: Record<
  string,
  Array<ModelTypes['EtailerProductMetrics']> | Record<BenchmarkType, Array<ModelTypes['EtailerProductMetrics']>>
> = [SAMPLE_CAMPAIGN_BENCHMARK_METRICS_LAUNDRY, SAMPLE_CAMPAIGN_BENCHMARK_METRICS_WIRELESS_EARBUDS]
  .flatMap(c =>
    Object.entries(c).map(([key, value]) => ({
      [key]: value
    }))
  )
  .reduce((acc, val) => ({ ...acc, ...val }), {})
