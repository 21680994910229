import { Stack, Typography, ButtonGroup } from '@mui/material'
import { useBenchmarkContext } from '../../context/BenchmarkContext'
import { BenchmarkType } from '@productwindtom/shared-momentum-zeus-types'
import { METRIC_TYPE_TO_INPUT_STRING } from '../../constants'
import { Button } from '@mui/material'
import { BenchmarkGraphProps, useBenchmarkGraphContext } from './BenchmarkGraph'

export const MetricSelector = () => {
  const { graphMetrics } = useBenchmarkContext()
  const { setMetrics } = useBenchmarkGraphContext()

  const updateMetricType = (metricType: BenchmarkType) => {
    setMetrics((metrics: BenchmarkGraphProps) => ({...metrics, type: metricType}))
  }

  return (
    <Stack direction={'column'}>
      <Typography variant={'label1'}>Choose benchmarking metric</Typography>
      <ButtonGroup
        color="primary"
        variant="outlined"
        size="medium"
        disableRipple
        disableElevation
        style={{
          marginTop: 8,
          alignSelf: 'flex-end',
          backgroundColor: 'white'
        }}
     >
       {[BenchmarkType.UNITS_SOLD, BenchmarkType.REVENUE, BenchmarkType.TRAFFIC].map(type => (
         <Button
           key={type}
           onClick={() => updateMetricType(type)}
           variant={graphMetrics?.metricType === type ? 'contained' : 'outlined'}
         >
           {METRIC_TYPE_TO_INPUT_STRING[type]}
         </Button>
       ))}

     </ButtonGroup>
    </Stack>
  )
}
