import { PricingCreatorType } from '@productwindtom/shared-momentum-zeus-types'
import { chunk, max, min, round, sum, sumBy } from 'lodash'
import { stripEmpty, stripTrailingEmpty } from '@productwindtom/shared-node'

type GetAutoBuysDistributionInput = {
  customDurationDays?: number
  creatorPricing: { type: PricingCreatorType; numCreators: number; schedule?: number[] }[]
}

/*
Get distribution as per https://docs.google.com/spreadsheets/d/1mstJsbiBIx1xldPpqMOTGBRqyKppBjM538XgXTsmHgk/edit
 */
export const getAutoBuysDistribution = ({ creatorPricing, customDurationDays }: GetAutoBuysDistributionInput) => {
  const totalCreators = sumBy(creatorPricing, 'numCreators')

  if (totalCreators <= 0) {
    return creatorPricing.map(c => ({ type: c.type, dailySchedule: [] }))
  }
  const config = typeToDistribution.find(
    c => totalCreators >= c.minCreators && (!c.maxCreators || totalCreators <= c.maxCreators)
  )?.config
  if (!config) {
    throw new Error('Invalid number of creators')
  }

  const onlyOne = creatorPricing.filter(cp => !!cp.numCreators).length <= 1

  let distributions = creatorPricing.map(cp => {
    const typeConfig = config[cp.type]

    const creatorSchedule = typeConfig.distributionPercentage.map(p => round(p * cp.numCreators))
    const sumDistributed = sumBy(creatorSchedule)
    const diff = cp.numCreators - sumDistributed
    const dailySchedule = diff === 0 ? creatorSchedule : adjustOffDistributed(creatorSchedule, diff)
    return { type: cp.type, dailySchedule: onlyOne ? stripEmpty(dailySchedule) : dailySchedule }
  })

  if (customDurationDays) {
    // Interpolate the schedule to fit within a new period of days
    const maxDays = max(distributions.map(d => d.dailySchedule.length)) || 0
    distributions = distributions.map(d => ({
      ...d,
      dailySchedule: stripTrailingEmpty(
        adjustDistributionToNewPeriod(
          fillTrailingZeros(d.dailySchedule, Math.max(0, maxDays - d.dailySchedule.length)),
          customDurationDays
        )
      )
    }))
  }

  /*
 We want to trim out leading zeros so that the first day is not 0 for the campaign
 So we find the smallest index where the value is not 0, this means this will be the first day in
 the campaign schedule where there is a purchase. We then trim all days before this in all schedules
 which will shift it up
 Example: for the following two schedules of a campaign will change to
   [0, 0, 1, 2, 3, 4, 5] -> [1, 2, 3, 4, 5]
   [0, 0, 0, 1, 2, 3, 4, 5] -> [0, 1, 2, 3, 4, 5]
 */
  const smallestZeroIndex = max([
    0,
    min(distributions.filter(d => !!d.dailySchedule.length).map(d => d.dailySchedule.findIndex(v => v > 0)))
  ])

  return distributions.map(d => ({
    ...d,
    dailySchedule: d.dailySchedule.slice(smallestZeroIndex)
  }))
}

const adjustOffDistributed = (distributed: number[], extra: number) => {
  let index = distributed.length - 1
  while (extra !== 0 && index >= 0) {
    const diff = extra > 0 ? 1 : -1
    if (distributed[index] + diff >= 0) {
      distributed[index] += diff
      extra -= diff
    }
    index--
    if (index === 0) {
      index = distributed.length - 1
    }
  }
  return distributed
}

const fillTrailingZeros = (distributed: number[], extra: number) => {
  return [...distributed, ...Array(extra).fill(0)]
}

const adjustDistributionToNewPeriod = (originalDistribution: number[], newDays: number): number[] => {
  /**
   * Adjusts a numerical distribution over a new period while maintaining relative proportions.
   * Ensures the output contains only integer values and sums to the same total as the original.
   *
   * @param {number[]} originalDistribution - The original numerical distribution.
   * @param {number} newDays - The number of days for the new distribution.
   * @returns {number[]} - The adjusted numerical distribution with integer values.
   */
  const originalDays = originalDistribution.length
  const originalSum = originalDistribution.reduce((acc, val) => acc + val, 0)

  // Create an interpolated distribution over the new time period
  const xOld = Array.from({ length: originalDays }, (_, i) => i / (originalDays - 1))
  const xNew = Array.from({ length: newDays }, (_, i) => i / (newDays - 1))

  const interpolate = (x: number, x0: number, x1: number, y0: number, y1: number) => {
    return Math.round(y0 + ((y1 - y0) * (x - x0)) / (x1 - x0))
  }

  const newDistribution: number[] = xNew.map(x => {
    for (let i = 0; i < originalDays - 1; i++) {
      if (x >= xOld[i] && x <= xOld[i + 1]) {
        return interpolate(x, xOld[i], xOld[i + 1], originalDistribution[i], originalDistribution[i + 1])
      }
    }
    return originalDistribution[originalDays - 1] // Fallback for last element
  })

  // Adjust sum to match the original total while keeping integer values
  const adjustedSum = newDistribution.reduce((acc, val) => acc + val, 0)
  let difference = originalSum - adjustedSum
  // Distribute the difference to elements in a fair manner
  for (let i = 0; difference !== 0; i = (i + 1) % newDistribution.length) {
    if (difference > 0) {
      newDistribution[i]++
      difference--
    } else if (difference < 0 && newDistribution[i] > 0) {
      newDistribution[i]--
      difference++
    }
  }

  return newDistribution
}

export const typeToDistribution: {
  minCreators: number
  maxCreators?: number
  config: {
    [key in PricingCreatorType]: {
      distributionPercentage: number[]
    }
  }
}[] = [
  {
    minCreators: 0,
    maxCreators: 50,
    config: {
      [PricingCreatorType.ADVOCATE]: {
        distributionPercentage: [0, 0, 0, 0, 0.05, 0.08, 0.12, 0.2, 0.25, 0.18, 0.12]
      },
      [PricingCreatorType.UGC]: {
        distributionPercentage: [0.1, 0.15, 0.35, 0.25, 0.15]
      },
      [PricingCreatorType.SOCIAL]: {
        distributionPercentage: [0.2, 0.2, 0.2, 0.2, 0.2]
      },
      [PricingCreatorType.PREMIUM_UGC]: {
        distributionPercentage: [0.2, 0.2, 0.2, 0.2, 0.2]
      }
    }
  },
  {
    minCreators: 51,
    maxCreators: 499,
    config: {
      [PricingCreatorType.ADVOCATE]: {
        distributionPercentage: [
          0, 0, 0, 0, 0, 0.02, 0.04, 0.06, 0.08, 0.08, 0.09, 0.15, 0.15, 0.1, 0.1, 0.08, 0.02, 0.02, 0.01
        ]
      },
      [PricingCreatorType.UGC]: {
        distributionPercentage: [0.05, 0.1, 0.2, 0.3, 0.2, 0.15]
      },
      [PricingCreatorType.SOCIAL]: {
        distributionPercentage: [0.2, 0.2, 0.2, 0.2, 0.2]
      },
      [PricingCreatorType.PREMIUM_UGC]: {
        distributionPercentage: [0.2, 0.2, 0.2, 0.2, 0.2]
      }
    }
  },
  {
    minCreators: 500,
    config: {
      [PricingCreatorType.ADVOCATE]: {
        distributionPercentage: [
          0, 0, 0, 0, 0, 0.01, 0.02, 0.03, 0.04, 0.05, 0.1, 0.07, 0.07, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06,
          0.05, 0.04, 0.03, 0.01
        ]
      },
      [PricingCreatorType.UGC]: {
        distributionPercentage: [0.05, 0.05, 0.1, 0.2, 0.2, 0.2, 0.1, 0.1]
      },
      [PricingCreatorType.SOCIAL]: {
        distributionPercentage: [0.2, 0.2, 0.2, 0.2, 0.2]
      },
      [PricingCreatorType.PREMIUM_UGC]: {
        distributionPercentage: [0.2, 0.2, 0.2, 0.2, 0.2]
      }
    }
  }
]

// Convert daily distribution to weekly distribution
export const toWeeklyPartition = (dailyDistribution?: number[]) => {
  return chunk(dailyDistribution || [], 7).map(sum)
}
