import { sumBy } from 'lodash'

export const getCampaignReviewMetrics = (
  reviewMetrics?: { momentumRatingCount: number; momentumReviewRating: number }[]
) => {
  const momentumReviews = sumBy(reviewMetrics, r => r.momentumRatingCount)

  const weightedAverage =
    sumBy(reviewMetrics, r => r.momentumReviewRating * r.momentumRatingCount) /
    (sumBy(reviewMetrics, r => r.momentumRatingCount) || 1)

  return {
    momentumReviews,
    weightedAverage
  }
}
