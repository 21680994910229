import { useCampaignContext, ReportType } from '@momentum/routes/campaign/context/CampaignContext'
import { Box, Stack, Typography, useTheme, Paper, Divider, Tabs, Tab } from '@mui/material'
import 'chart.js/auto'
import 'chartjs-adapter-luxon'
import { first, last, sumBy } from 'lodash'
import { DateTime } from 'luxon'
import { Bar } from 'react-chartjs-2'
import { SalesReportView, useSalesContext } from '../salesContext'
import { useMemo } from 'react'
import { defaultTooltipOptions } from '@momentum/utils/tooltipUtils'
import { capitalizeFirstLetter } from '@productwindtom/shared-node'
import { alpha } from '@mui/material/styles'
import { subLabelsPlugin, campaignImagePlugin } from '../../common/GraphPlugins'

type SalesRecord = {
  date: DateTime
  label: string,
  total: number
}

export const SalesGraph = () => {
  const theme = useTheme()
  const {
    filteredSalesData,
    salesReportView,
    salesReportType,
    setSalesReportType,
    salesSkuType,
    startDateFilter,
    endDateFilter
  } = useSalesContext()
  const { campaignDetails } = useCampaignContext()

  const cumulative = useMemo(
    () =>
      filteredSalesData.reduce((acc: SalesRecord[], d) => {
        const prev = acc[acc.length - 1]
        const total = (salesReportView === SalesReportView.REVENUE ? (d.value / 100) : d.value) + (prev?.total || 0)

        return [
          ...acc,
          { 
            date: d.date,
            label: d.date.toFormat('LL/dd'),
            total 
          }
        ]
      }, []),
    [filteredSalesData]
  )

  const minDate = first(filteredSalesData)?.date || DateTime.now()
  const maxDate = last(filteredSalesData)?.date || minDate
  const weeks = Math.ceil(maxDate.diff(minDate, 'weeks').weeks || 1)
  const weeksArray = new Array(weeks).fill(0)
  const maxItemsBeforeRotationLabels = 8

  const weekly = useMemo(() => {
    return weeksArray.map((_, index) => {
      const start = minDate.plus({ weeks: index })
      const end = start.plus({ weeks: 1 })
      const weekRangeData = filteredSalesData.filter(d => d.date >= start && d.date < end)
      return {
        date: start,
        label: `${start.toFormat('L/d')} - ${end.toFormat('L/d')}`,
        total: sumBy(weekRangeData, i => (salesReportView === SalesReportView.REVENUE ? (i.value / 100) : i.value))
      }
    })
  }, [filteredSalesData])

  const data = salesReportType === ReportType.CUMULATIVE ? cumulative : weekly
  const total = data.map(d => d.total)
  const labels = data.map(d => d.label)

  const weeklyTagLabels = data.map((d, i) => {
    const startDateDateTime = DateTime.fromISO(campaignDetails.startDate)
    const endDateDateTime = campaignDetails.endDate ? DateTime.fromISO(campaignDetails.endDate) : undefined
    const startWeek = startDateDateTime.startOf('week')
    const endWeek = endDateDateTime?.startOf('week')
    const currentWeek = d.date.startOf('week')

    if (currentWeek.equals(startWeek)) {
      return 'Launch'
    }
    if (endWeek && currentWeek > startWeek && currentWeek <= endWeek) {
      return `Week ${i - data.findIndex(x => x.date.startOf('week').equals(startWeek)) + 1}`
    }
    return null
  })

  const barImagePlugin = {
    id: 'barImagePlugin',
    beforeDraw: (chart: any) => {
      const dates = data.map(d => d.date.toISODate())
      campaignImagePlugin(campaignDetails, dates, chart, salesReportType === ReportType.WEEKLY)
    }
  }

  const weeklyLabelsPlugin = {
    id: "weeklyLabelsPlugin",
    beforeDraw(chart: any) {
      if (salesReportType === ReportType.CUMULATIVE || !chart.scales["x"]) return;
      subLabelsPlugin(labels, weeklyTagLabels, chart, theme)
    },
  };
  
  const chartKey = useMemo(() => {
    return [
      salesReportType,
      salesSkuType, 
      salesReportView,
      startDateFilter?.toISODate(),
      endDateFilter?.toISODate()
    ].join(' - ')
  }, [salesReportType, salesSkuType, salesReportView, startDateFilter, endDateFilter])

  const graphBottomPadding = (() => {
    if (salesReportType === ReportType.CUMULATIVE) {
      return 0
    } else if (labels.length > maxItemsBeforeRotationLabels) {
      return 100
    } else {
      return 60
    }
  })()

  const isDateInCampaignRange = (date: DateTime): boolean => {
    const campaignStart = DateTime.fromISO(campaignDetails.startDate)
    const campaignEnd = campaignDetails.endDate ? DateTime.fromISO(campaignDetails.endDate) : null

    const dateIsAfterStart = date.endOf('day') > campaignStart
    const dateIsBeforeEnd = campaignEnd ? date.startOf('day') < campaignEnd : true

    return dateIsAfterStart && dateIsBeforeEnd
  }

  const isCampaignInWeekRange = (beginningOfWeek: DateTime, endOfWeek: DateTime): boolean => {
    const campaignStart = DateTime.fromISO(campaignDetails.startDate)
    const campaignEnd = campaignDetails.endDate ? DateTime.fromISO(campaignDetails.endDate) : null

    const weekContainsStart = beginningOfWeek <= campaignStart && campaignStart <= endOfWeek
    const weekContainsEnd = campaignEnd ? (beginningOfWeek <= campaignEnd && campaignEnd <= endOfWeek) : false
    const campaignContainsWeek = campaignStart <= beginningOfWeek && (campaignEnd ? campaignEnd >= endOfWeek : true)

    return weekContainsStart || weekContainsEnd || campaignContainsWeek
  }

  const getBackgroundColor = (isHighlighted: boolean) => {
    return isHighlighted 
      ? theme.palette.info.main
      : alpha(theme.palette.info.main, 0.7)
  }

  const calculateBackgroundColor = (index: number) => {
    const date = data[index].date

    if (salesReportType === ReportType.CUMULATIVE) {
      return getBackgroundColor(isDateInCampaignRange(date))
    } else {
      const endOfWeek = date.endOf('week')
      return getBackgroundColor(isCampaignInWeekRange(date.startOf('day'), endOfWeek))
    }
  }

  return (
      <Stack bgcolor={'white'} border={t => `1px solid ${t.palette.grey.A200}`} borderRadius={'4px'}>
        <Stack direction={'row'} p={3} justifyContent={'space-between'}>
          <Typography variant={'label1'}>{capitalizeFirstLetter(salesReportView)}</Typography>
          <Tabs value={salesReportType} onChange={(_, v) => setSalesReportType(v)} sx={{ minHeight: 0, height: 34 }}>
            <Tab label={'Cumulative'} value={ReportType.CUMULATIVE} sx={{ minHeight: 0, mr: 3 }} />
            <Tab label={'Weekly'} value={ReportType.WEEKLY} sx={{ minHeight: 0, mr: 3 }} />
          </Tabs>
        </Stack>
        <Divider />
        <Box p={2}>
          <Bar
            data-cy="salesGraph"
            key={chartKey} // Forces re-render when context changes
            data={{
              labels: labels,
              datasets: [
                {
                  backgroundColor: data.map((_, i) => calculateBackgroundColor(i)),
                  data: total,
                  borderRadius: 3
                }
              ]
            }}
            plugins={
              [barImagePlugin, weeklyLabelsPlugin]
            }
            options={{
              layout: {
                padding: {
                  bottom: graphBottomPadding
                },
              },
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false
                },
                tooltip: {
                  ...defaultTooltipOptions,
                  callbacks: {
                    label: item => {
                      const value = item.formattedValue
                      return `${value} ${salesReportView === SalesReportView.UNITS_SOLD ? 'units' : ''}`
                    },
                    title: () => '',
                    footer: () => ''
                  }
                },
              },
              scales: {
                y: {
                  beginAtZero: true,
                  suggestedMax: Math.max(...total) * 1.05, // Adds extra space for images
                  grid: {
                    drawOnChartArea: true,
                    drawTicks: false,
                    color: theme.palette.grey.A200,
                    lineWidth: 1
                  },
                  ticks: {
                    color: 'black',
                    padding: 16,
                    font: {
                      family: theme.typography.fontFamily as string,
                      size: theme.typography.label1.fontSize as number
                    }
                  },
                },
                x: {
                  ticks: {
                    display: salesReportType === ReportType.CUMULATIVE,
                    minRotation: 60,
                    source: 'labels',
                    padding: 16,
                    font: {
                      family: theme.typography.fontFamily as string,
                      size: theme.typography.label1.fontSize as number
                    },
                    color: value => {
                      return value.tick.label === DateTime.fromISO(campaignDetails.startDate).toFormat('MM/dd') ?
                        theme.palette.primary.main : 'black'
                    },
                  }
                }
              }
            }}
            height={'560px'}
          />
        </Box>
      </Stack>
  )
}
