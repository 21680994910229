import { useFormContext, useController } from 'react-hook-form'
import { ProposalCreateForm } from '@momentum/routes/proposals-create/types'
import { Select, MenuItem, Typography, Chip } from '@mui/material'
import { EVENTS, EventConfig, EVENTS_BY_TYPE } from '@momentum/utils/eventUtils'
import { Stack } from '@mui/system'
import { DateTime } from 'luxon'

export const EventSelector = () => {
  const { control } = useFormContext<ProposalCreateForm>()

  const dateLimit = DateTime.now()

  const {
    field: { value, onChange, onBlur }
  } = useController<ProposalCreateForm, 'eventType'>({ name: 'eventType', control })

  return (
    <Select
      fullWidth
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      data-cy={'eventSelectorInput'}
      placeholder={'Seasonal event'}
      displayEmpty
      renderValue={value =>
        value ? (
          <EventRender event={EVENTS_BY_TYPE[value]} />
        ) : (
          <Typography variant={'label3'} color={'grey.A400'}>
            Seasonal event
          </Typography>
        )
      }
    >
      {EVENTS.map(e => ({ ...e, enabled: e.enabled && e.eventDate && e.eventDate > dateLimit })).map(event => (
        <MenuItem key={event.id} value={event.id} disabled={!event.enabled} data-cy={'eventOption'}>
          <EventRender event={event} />
        </MenuItem>
      ))}
    </Select>
  )
}

const EventRender = ({ event }: { event: EventConfig }) => {
  const now = DateTime.now().plus({ days: 30 })
  const priority = event.preferredStartDate < now && event.enabled

  return (
    <Stack
      direction={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={'100%'}
      data-cy={'eventOption-' + event.id}
    >
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        {event.icon({ fontSize: 'medium' })}
        <Typography variant={'label2'}>{event.name}</Typography>
        {priority && (
          <Chip label={'Schedule now!'} size={'small'} sx={{ backgroundColor: 'info.main', color: 'white' }} />
        )}
      </Stack>
      <Stack direction={'row'} justifyContent={'space-between'} width={'60%'}>
        <Stack alignItems={'flex-end'} width={'33%'}>
          <Typography variant={'label2'}>{event.eventDate?.toLocaleString() || '--/--/2025'}</Typography>
          <Typography variant={'body1'} color={'grey.A700'}>
            Event date
          </Typography>
        </Stack>
        <Stack alignItems={'flex-end'}>
          <Typography variant={'label2'}>
            {event.peakSalesPeriodStart?.toLocaleString()} - {event.peakSalesPeriodEnd?.toLocaleString()}
          </Typography>
          <Typography variant={'body1'} color={'grey.A700'}>
            Peak sales period
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}
