import { Chip, Typography, useTheme, Stack } from '@mui/material'
import { useSubscriptionContext } from '@momentum/contexts/Subscription'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { SubscriptionBar } from './subscription-bar'

export const Header = () => {
  const { isAdminView } = useUserSessionContext()
  const { getSubscriptionStatus, name } = useSubscriptionContext()
  const { hasSubscription, isActive, hasStarted } = getSubscriptionStatus()
  const theme = useTheme()

  return (
    <Stack direction={'row'} justifyContent={'space-between'}>
      <Stack spacing={1}>
        <Stack direction={'row'} spacing={1}>
          <Typography variant={'h3'} mb={2}>
            Subscription
          </Typography>
          {hasSubscription && isActive ? (
            <Chip
              label={'Active'}
              sx={{
                borderRadius: '4px',
                background: theme => `${theme.palette.success.main}` + `1A`,
                color: theme.palette.success.main
              }}
            />
          ) : !hasStarted && hasSubscription ? (
            <Chip
              label={'Upcoming'}
              sx={{
                borderRadius: '4px',
                background: theme => `${theme.palette.info.main}`,
                color: 'white'
              }}
            />
          ) : (
            <Chip
              label={'Inactive'}
              sx={{
                borderRadius: '4px',
                opacity: 0.5
              }}
            />
          )}
        </Stack>
        <Typography variant={'h5'} mb={2}>
          {name}
        </Typography>
      </Stack>
      {isAdminView && <SubscriptionBar />}
    </Stack>
  )
}
