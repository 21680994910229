import { sumBy } from 'lodash'
import { DateTime } from 'luxon'

export const getCampaignSeoMetrics = (
  startDate: string,
  seoSummary?: {
    productSeoSummaryRecords: {
      fromDate: string
      organicPageOneWins?: number
      sponsoredPageOneWins?: number
      searchVolume?: number
    }[]
  },
  productKeywordSummaries?: {
    organicHighestRank?: number | undefined
    organicStartRank?: number | undefined
    sponsoredHighestRank?: number | undefined
    sponsoredStartRank?: number | undefined
  }[]
) => {
  const campaignStartWeek = DateTime.fromISO(startDate).startOf('week').toISODate()!
  const filteredRecords = seoSummary?.productSeoSummaryRecords.filter(r => r.fromDate >= campaignStartWeek) ?? []

  const searchVolumeSum = sumBy(filteredRecords, r => r.searchVolume ?? 0)
  const organicPageOneWinsSum = sumBy(filteredRecords, r => r.organicPageOneWins ?? 0)
  const sponsoredPageOneWinsSum = sumBy(filteredRecords, r => r.sponsoredPageOneWins ?? 0)
  const organicImprovedSlotsSum = sumBy(productKeywordSummaries, k =>
    k.organicHighestRank && k.organicStartRank && k.organicHighestRank <= k.organicStartRank
      ? Math.abs(k.organicHighestRank - k.organicStartRank)
      : 0
  )
  const sponsoredImprovedSlotsSum = sumBy(productKeywordSummaries, k =>
    k.sponsoredHighestRank && k.sponsoredStartRank && k.sponsoredHighestRank <= k.sponsoredStartRank
      ? Math.abs(k.sponsoredHighestRank - k.sponsoredStartRank)
      : 0
  )

  return {
    searchVolumeSum,
    organicPageOneWinsSum,
    organicImprovedSlotsSum,
    sponsoredPageOneWinsSum,
    sponsoredImprovedSlotsSum
  }
}
