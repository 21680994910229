import { FormMethodsType } from '@productwindtom/ui-base'
import { forwardRef, RefAttributes, useImperativeHandle } from 'react'
import { FieldValues, FormProvider, useForm } from 'react-hook-form'
import { ChildFormProps, FormProviderProps } from './types'

export const withFormProvider = (Component: React.FC<ChildFormProps>, providerDefaultValues?: any) =>
  forwardRef(({ onSubmit, defaultValues, resolver, ...rest }, ref) => {
    const methods = useForm({
      mode: 'onChange',
      resolver,
      defaultValues: providerDefaultValues || defaultValues
    })

    useImperativeHandle(ref, () => methods)

    const handleSubmit = onSubmit && methods.handleSubmit(data => onSubmit(data, methods))

    return (
      <FormProvider {...methods}>
        <Component onSubmit={handleSubmit} {...rest} />
      </FormProvider>
    )
  }) as <TFieldValues extends FieldValues>(
    props: FormProviderProps<TFieldValues> & RefAttributes<FormMethodsType<TFieldValues>>
  ) => JSX.Element
