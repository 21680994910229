import { DateTime } from 'luxon'
import { Form, SelectInput, SubmitButton, DateInput } from '@productwindtom/ui-base'
import { yupResolver } from '@hookform/resolvers/yup'
import { Stack, Typography, Alert } from '@mui/material'
import { BackButton } from '@momentum/components/back-button'
import { useFormContext } from 'react-hook-form'
import React, { useEffect } from 'react'
import * as yup from 'yup'

const TERM_OPTIONS_MONTHS = [3, 4, 6, 12]

const schema = yup.object().shape({
  startDate: yup.mixed<DateTime>().required('Required'),
  endDate: yup.mixed<DateTime>().required('Required'),
  term: yup.number().optional()
})

type ActivateSubscriptionFormData = {
  term?: number
  startDate: DateTime
  endDate: DateTime
}
export const ActivateSubscriptionForm = ({
  onSubmit,
  onCancel,
  name
}: {
  name?: string
  onCancel: () => void
  onSubmit: (data: ActivateSubscriptionFormData) => Promise<void>
}) => {
  const handleSubmit = async (submitValues: ActivateSubscriptionFormData) => {
    await onSubmit(submitValues)
  }

  return (
    <Form onSubmit={handleSubmit} resolver={yupResolver(schema)}>
      <Stack spacing={3}>
        <Typography variant={'label3'}>
          {name} users will have access to the Momentum dashboard effective immediately.
        </Typography>
        <Stack>
          <Typography variant={'subtitle2'}>Select subscription term that this company purchased:</Typography>
          <SelectInput
            fullWidth
            name="term"
            options={TERM_OPTIONS_MONTHS.map(months => ({ value: months, label: `${months} month term` }))}
            disableTags
          />
        </Stack>

        <DateReactiveFields />
        <Stack direction={'row'} justifyContent={'flex-end'} spacing={1} pt={2}>
          <BackButton variant={'text'} onClick={onCancel} text={'Cancel'} />
          <SubmitButton variant={'contained'} disableOnDirty={false} data-cy={'activateSubmitButton'}>
            Activate subscription
          </SubmitButton>
        </Stack>
      </Stack>
    </Form>
  )
}

const DateReactiveFields = () => {
  const { getValues, setValue, watch } = useFormContext<ActivateSubscriptionFormData>()

  const { startDate, endDate, term } = watch()

  useEffect(() => {
    if (term) {
      const vals = getValues()

      if (vals.startDate) {
        const newEndDate = vals.startDate.plus({ months: term })
        setValue('endDate', newEndDate)
      } else if (!vals.startDate) {
        const now = DateTime.now()
        setValue('startDate', now)
        setValue('endDate', now.plus({ months: term }))
      }
    }
  }, [term])

  const isDatesTooLong = term && startDate && endDate && endDate.diff(startDate, 'months').months > term

  return (
    <Stack spacing={3}>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <DateInput name={'startDate'} primaryText={'Subscription start date'} />
        <DateInput name={'endDate'} primaryText={'Subscription end date'} />
      </Stack>
      {!!isDatesTooLong && (
        <Alert variant={'outlined'} severity={'info'} data-cy={'exceedTermAlert'}>
          <Typography variant={'label3'}>
            This subscription is longer than {term} months. Are you sure you want to change the dates?
          </Typography>
        </Alert>
      )}
    </Stack>
  )
}
