// @ts-nocheck

import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'

export const SAMPLE_CAMPAIGN_WATER_FLOSSER_PRIME_DAY_LAUNCH: ModelTypes['CampaignDetails'] = {
  id: 'sample-campaign-water-flosser-prime-day',
  createdAt: '2024-06-25T11:14:43.976Z',
  skuId: 'sample-sku-water-flosser',
  liftCampaignId: null,
  title: 'Cordless Water Flosser - Boost Existing Product Launch - Prime Day Lead Out',
  goal: 'EXISTING_PRODUCT',
  numCreators: 501,
  numCreatorsJoined: 501,
  numAdditionalBrandAdvocateCreatorsJoined: null,
  numAdditionalBrandAdvocateCreators: null,
  numBrandAdvocateCreators: 501,
  numSocialCreators: 0,
  numPremiumUgcCreators: 0,
  numUgcCreators: 0,
  expectedSocialPostCount: null,
  expectedPremiumUgcPostCount: null,
  expectedUgcPostCount: null,
  expectedReviewsCount: null,
  expectedPlacementWins: 10,
  expectedClicksCounts: null,
  releaseSchedule: {
    ugcWeeklySchedule: [],
    premiumUgcWeeklySchedule: [],
    socialWeeklySchedule: [],
    brandAdvocatesWeeklySchedule: [285, 215]
  },
  anticipatedStartDate: '2024-07-18T04:00:00.000Z',
  startDate: '2024-07-18T09:14:24.000Z',
  preLaunchDate: null,
  endDate: '2024-09-20T21:30:14.226Z',
  cost: 4800,
  product: {
    id: 'f5b86c15-ea3d-4a7e-98ba-e116e038fe35',
    name: 'Rechargeable Portable Water Flosser',
    skuId: 'FLOSSER',
    priceCents: 4800,
    image: 'https://media.productwind.com/sample/images/sample-water-flosser.png',
    listingLink: 'https://www.amazon.com',
    store: 'amazon',
    availabilityDate: null,
    childSkus: [
      {
        id: 'ABC123',
        skuId: 'FLOSSER-1',
        
      },
      {
        id: 'CDE456',
        skuId: 'FLOSSER-2',
      }
    ]
  },
  selectedVariants: ['FLOSSER-1'],
  creators: [
    {
      userCampaignId: 'c1a43e6b-1a44-49c5-ad7c-9b8d7721d251',
      content: [],
      creator: {
        id: '99162f02-112a-451a-9efc-f5a186aeefbe',
        firstName: 'Osias',
        lastName: 'Bartolome',
        email: 'osiasfr2017@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'osias.bartolome',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'osiasbartolome5141',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-18T16:12:13.000Z',
        amountCents: 4740
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '23dbde12-1a5c-4498-a8c2-300e98e12787',
      content: [],
      creator: {
        id: '26b28c25-8d15-42d6-978b-73559b64a307',
        firstName: 'Satoya',
        lastName: 'Myrie',
        email: 'sattins_1@hotmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'yashekoyasheko',
          followers: 71,
          engagementRate: 6.796805678793256
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-18T16:17:10.000Z',
        amountCents: 4500
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'e3c4728d-7829-4cfe-a591-089671d1b7d3',
      content: [],
      creator: {
        id: 'c52b5469-35ee-441e-9516-e318e9c1c607',
        firstName: 'Stacy',
        lastName: 'Richards',
        email: 'srichardson11@stu.ucc.edu.jm',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'wow12169',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-18T16:18:02.000Z',
        amountCents: 4500
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '2e61a4e8-72aa-4278-ac9e-9f6a85dba882',
      content: [],
      creator: {
        id: '6bdb4185-d787-4928-a805-3a701e3c17b3',
        firstName: 'Czarina',
        lastName: 'Tagbak',
        email: 'czarinatagbak123@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'chachaching_24',
          followers: 74,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'czarinatagbak9527',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-18T16:19:18.000Z',
        amountCents: 5739
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '9772c15a-2deb-47af-8348-286c86cb2f34',
      content: [],
      creator: {
        id: '5da05871-4c75-4ca3-aab9-2a6ef1d5fa63',
        firstName: 'Marium',
        lastName: 'Tamton',
        email: 'marium.k.tamton@gmail.com',
        instagramProfile: {
          handle: 'nazara.beauty',
          followers: 1911,
          engagementRate: 5.9026687598116165
        },
        tiktokProfile: {
          handle: 'mk_reviews8',
          followers: 690,
          engagementRate: 13.002207801794427
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-18T16:26:45.000Z',
        amountCents: 4642
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '19a9cacf-e62b-4a89-b899-67655b55c12b',
      content: [],
      creator: {
        id: '4c9fc10f-e060-468c-a2a4-9f2a55a3e9f3',
        firstName: 'Vadim',
        lastName: 'Antipov',
        email: 'vad.ant@icloud.com',
        instagramProfile: {
          handle: 'midavmai',
          followers: 1309,
          engagementRate: 0.47364400305576776
        },
        tiktokProfile: {
          handle: 'lmiiinm',
          followers: 5,
          engagementRate: 1.7699115044247788
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-18T16:37:23.000Z',
        amountCents: 4299
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '9b94a1e7-798c-4183-b481-c561b4a86507',
      content: [],
      creator: {
        id: '725cb00d-ca1f-4fe5-871e-ae529bf4895c',
        firstName: 'Kimberly',
        lastName: 'Streete',
        email: 'rfinegan2@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'kimberly.streete',
          followers: 4,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'kimberlystreete4285',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-18T16:41:31.000Z',
        amountCents: 4332
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '4779e9b3-a864-496c-a1b0-dc625d8ced44',
      content: [],
      creator: {
        id: 'e03ed731-128f-4bb2-bb66-4238f6bcd997',
        firstName: 'Alfien',
        lastName: 'Divinagracia',
        email: 'alfienscloset@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'alfien223',
          followers: 60,
          engagementRate: 0
        },
        youtubeProfile: {
          handle: 'alfiendivinagracia9293',
          followers: 15,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-18T17:01:38.000Z',
        amountCents: 5739
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'afb29a76-74b6-475b-a54d-c52c8cd53f1f',
      content: [],
      creator: {
        id: 'fc7f9cf5-cf42-4db1-adb0-bd20c2ec8f88',
        firstName: 'Nigena',
        lastName: 'Walters',
        email: 'nigenawalters@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: '_nig.ena_',
          followers: 1476,
          engagementRate: 15.160003395297512
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-18T17:04:21.000Z',
        amountCents: 4299
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '72606a56-afde-4dde-bb25-f6c67bdda0cd',
      content: [],
      creator: {
        id: '5b518766-7ba1-4938-929d-e1418c302b7f',
        firstName: 'Alex',
        lastName: 'Baugh',
        email: 'alexbaugh94@yahoo.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'baughnfufa7',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-18T18:15:36.000Z',
        amountCents: 12000
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '4ddca8c2-b85c-430d-9404-6a99e296cc53',
      content: [],
      creator: {
        id: '05e7ab28-6b2b-4b2c-b1ed-59ba5e6e8b22',
        firstName: 'Monique',
        lastName: 'Rose',
        email: 'rosereidmonique@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'oceanmommiee',
          followers: 1263,
          engagementRate: 5.660377358490567
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-18T18:55:10.000Z',
        amountCents: 8975
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'b97b3722-0db2-4c28-8a05-9d4d066eb0f8',
      content: [],
      creator: {
        id: '0d106772-6fab-4e11-aebf-8173d05554e5',
        firstName: 'Crystal',
        lastName: 'McFarlane',
        email: 'Mcfarlanejody75@gmail.com',
        instagramProfile: {
          handle: 'exquisite__nails_by_jody',
          followers: 88,
          engagementRate: 3.0681818181818183
        },
        tiktokProfile: {
          handle: 'crystal_jojo',
          followers: 1153,
          engagementRate: 5.505496514251856
        },
        youtubeProfile: {
          handle: 'crystalmcfarlane9556',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-18T19:52:52.000Z',
        amountCents: 4698
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '666168e0-d49e-422f-81ed-bd10aac89402',
      content: [],
      creator: {
        id: '86f2e8ed-592e-4c18-bff4-8d91d8dfae8c',
        firstName: 'Vio',
        lastName: 'Sych',
        email: 'villison990@gmail.com',
        instagramProfile: {
          handle: 'sychevskaia.marina',
          followers: 1617,
          engagementRate: 4.246547103689961
        },
        tiktokProfile: {
          handle: 'redpantera',
          followers: 1839,
          engagementRate: 10.72961373390558
        },
        youtubeProfile: {
          handle: 'vioson-kz6sd',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-18T19:56:44.000Z',
        amountCents: 6599
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'f7275805-9b00-4124-9233-fc75685574e7',
      content: [],
      creator: {
        id: '0e798da8-7ab1-417a-ac6e-9cb9b885eeaf',
        firstName: 'Shakira',
        lastName: 'Rhoden',
        email: 'janelsummerlove@live.com',
        instagramProfile: {
          handle: 'dhayjhanel_remy',
          followers: 1851,
          engagementRate: 1.9611021069692056
        },
        tiktokProfile: {
          handle: 'juju_british',
          followers: 7609,
          engagementRate: 20
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-18T20:21:17.000Z',
        amountCents: 6000
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'f743c3ea-a27b-4a3a-b635-6047d732ede5',
      content: [],
      creator: {
        id: '3926b5af-fae2-454b-be6e-ec298a4106e8',
        firstName: 'Rayon',
        lastName: 'Bowen',
        email: 'bowenrayon97@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'lateshachin',
          followers: 223,
          engagementRate: 16.666666666666664
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-18T20:44:03.000Z',
        amountCents: 4279
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '50a7ef78-3832-4bf4-b8ff-1c96d6e9e37e',
      content: [],
      creator: {
        id: '0da3d3ef-3f88-4f75-9fcd-47a530684007',
        firstName: 'Sean',
        lastName: 'Kay',
        email: 'sean.kay777@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'seankay777',
          followers: 44,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-18T23:55:32.000Z',
        amountCents: 4696
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'c5099c73-ee8a-4fb2-9afc-0764cf9e2c9b',
      content: [],
      creator: {
        id: '50132cca-d423-4b06-ba3e-b76264a85518',
        firstName: 'Joy-Dian',
        lastName: 'Daley',
        email: 'joydiandaley508@gmail.com',
        instagramProfile: {
          handle: 'joydiandaley.cosmetics',
          followers: 1366,
          engagementRate: 2.0741825280624697
        },
        tiktokProfile: {
          handle: 'joydiandaley',
          followers: 1075,
          engagementRate: 9.539207760711399
        },
        youtubeProfile: {
          handle: 'joydiandaley',
          followers: 1,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T00:10:04.000Z',
        amountCents: 4500
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'fa6b350e-16f9-4630-a7ab-dd04115554f9',
      content: [],
      creator: {
        id: 'debe8d83-111a-477a-94e0-69bf372a9fe4',
        firstName: 'Monina',
        lastName: 'Anacaya',
        email: 'genshuna1@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'gen.shuna',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T00:43:49.000Z',
        amountCents: 4589
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '926fac25-1262-40be-9c62-44114db78042',
      content: [],
      creator: {
        id: '0e66737c-7843-44dc-ab14-1c8556a04b41',
        firstName: 'Sashane',
        lastName: 'Tyson',
        email: 'sashanetyson743@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'shantasia_skittlez',
          followers: 2900,
          engagementRate: 10.844968704862781
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T00:44:47.000Z',
        amountCents: 4299
      },
      status: 'INCOMPLETE',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'ea3fab97-7b5f-4d6e-96cd-d4ba0f09537b',
      content: [],
      creator: {
        id: '04d85065-b4e5-4deb-9770-3c4835e4dfbe',
        firstName: 'Taijah',
        lastName: 'Thorpe',
        email: 'tashandrewtt@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'r1xen__',
          followers: 772,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T03:16:32.000Z',
        amountCents: 2500
      },
      status: 'INCOMPLETE',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '2221e10f-c5a9-4f45-b1fb-8cddb31d4f1b',
      content: [],
      creator: {
        id: 'f938b41b-5fe7-4007-bfc1-dc72157b8fcf',
        firstName: 'Radin',
        lastName: 'Galy',
        email: 'viosych777@gmail.com',
        instagramProfile: {
          handle: 'radin_galy',
          followers: 0,
          engagementRate: 0
        },
        tiktokProfile: {
          handle: 'oliiivka26',
          followers: 122,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'itsoftware-h1d',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T04:44:09.000Z',
        amountCents: 5912
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'bcfc40cf-756f-4949-ab5f-eccf82f0487f',
      content: [],
      creator: {
        id: 'dacf785c-cb15-47a7-8a1b-47c99df949d7',
        firstName: 'Aciana ',
        lastName: 'Small ',
        email: 'jody41413@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'dolly8119',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T05:52:19.000Z',
        amountCents: 1999
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'ea2466fa-d1ce-48f3-b652-6710a959a8f2',
      content: [],
      creator: {
        id: 'f11fccce-6b89-41ef-bc81-7a36d08f8b28',
        firstName: 'Gray',
        lastName: 'Lang',
        email: 'graylangemo@yahoo.com',
        instagramProfile: {
          handle: 'graylangemo',
          followers: 991,
          engagementRate: 11.467444120505345
        },
        tiktokProfile: {
          handle: 'graylangemo',
          followers: 103,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T05:55:48.000Z',
        amountCents: 4652
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '1a11c831-ff2f-488f-828a-ede1e71b5369',
      content: [],
      creator: {
        id: 'd3816ce0-1cff-499a-bc60-06b40f22fd58',
        firstName: 'David',
        lastName: 'Graham',
        email: 'damianfagan45@outlook.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'deshaun935',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'desean4614',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T06:13:07.000Z',
        amountCents: 10796
      },
      status: 'INCOMPLETE',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '40a48679-7c13-41da-97a0-d5a09fd70055',
      content: [],
      creator: {
        id: 'd0f17e8e-4562-4ba5-b487-9114dae9e435',
        firstName: 'Jasmin',
        lastName: 'Bonghanoy',
        email: 'bonghanoyjasmin@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: '_imjaaas',
          followers: 17,
          engagementRate: 38.46153846153847
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T09:06:51.000Z',
        amountCents: 5398
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '5e1cc9c4-69cd-472e-925a-50d7aded20f1',
      content: [],
      creator: {
        id: '29aa0a12-11f7-42cb-89c9-e811e19ec715',
        firstName: 'Joebert',
        lastName: 'Rotaquio',
        email: 'jecelrotaquio0411@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'jc8j8',
          followers: 5,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'jc-jl7cr',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T09:16:25.000Z',
        amountCents: 4643
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'b6401328-06f6-484d-a8f5-863c0d61b435',
      content: [],
      creator: {
        id: '2ef2ecba-15c9-44a1-949b-3c0a9ea1faac',
        firstName: 'Fatima',
        lastName: 'Inot',
        email: 'fatimainot.teachabroad@gmail.com',
        instagramProfile: {
          handle: 'fottimaaay',
          followers: 1010,
          engagementRate: 2.4533856722276743
        },
        tiktokProfile: {
          handle: 'fatimainot',
          followers: 53,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'fatimainot1222',
          followers: 95,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T09:16:28.000Z',
        amountCents: 5398
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '7f92e5f9-c371-4104-b143-c0dc6f62909f',
      content: [],
      creator: {
        id: 'bf471b64-d302-4a7b-a352-e725bddbe1a4',
        firstName: 'Shaniece',
        lastName: 'Smalling ',
        email: 'smallingshan25@yahoo.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'shanzkitchen',
          followers: 19,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T10:20:22.000Z',
        amountCents: 4628
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '7f96ea86-6ef0-40e8-8a29-c3866dcbff23',
      content: [],
      creator: {
        id: '84873e64-5b15-4939-9433-6b975a9e9d64',
        firstName: 'Monique',
        lastName: 'Boothe',
        email: 'moniqueboothe2021@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'moneciapinnock',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T10:52:38.000Z',
        amountCents: 8709
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'f30ef7e6-bb5d-4380-a694-07c8434f77ea',
      content: [],
      creator: {
        id: '3a8aae1e-5eff-45f6-b0dc-cc3ab33c1bb6',
        firstName: 'Julie Ann',
        lastName: 'Friolanita',
        email: 'jaflaguda@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'julieannfriolanit',
          followers: 6,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'rj_jhazz_all-in2523',
          followers: 7,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T12:11:55.000Z',
        amountCents: 5752
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '540c8e95-24c8-48aa-b23b-b0e51b4585e2',
      content: [],
      creator: {
        id: '01a5129a-db0c-441a-b3e4-ef033f486a85',
        firstName: 'Mohammad',
        lastName: 'Fakhimnia',
        email: '123456789qazwsxedcrfv1@gmail.com',
        instagramProfile: {
          handle: 'blog_flex_mf',
          followers: 342,
          engagementRate: 6.578947368421052
        },
        tiktokProfile: {
          handle: 'mleaon',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'marfa-hs4xm',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T13:06:25.000Z',
        amountCents: 6567
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '391940f4-30bd-4c5b-8c1d-1becd070eb16',
      content: [],
      creator: {
        id: '80a57c7a-2c31-4fc3-9267-930f86aa1d94',
        firstName: 'Ian',
        lastName: 'Julye',
        email: 'julyejadan@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'ianjulye',
          followers: 119,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T13:53:04.000Z',
        amountCents: 9254
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '6a307035-aee9-47c8-bb21-6b0526e7682f',
      content: [],
      creator: {
        id: '1aeb5a8f-3a20-4f73-8100-3421ed4ebb79',
        firstName: 'Shanique',
        lastName: 'Lewin',
        email: 'shanquielewin@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'shanoelewin',
          followers: 251,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T14:36:44.000Z',
        amountCents: 5777
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'b2280823-700f-47db-a29f-70e1da037cc4',
      content: [],
      creator: {
        id: '130655f8-6034-4498-a5b5-e0ad68ff786a',
        firstName: 'Rochelle',
        lastName: 'Harris ',
        email: 'alwaynebedward04@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'anna_8763',
          followers: 119,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T16:05:43.000Z',
        amountCents: 5440
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '5fef485a-a590-46b4-8bec-ba79cc2660f1',
      content: [],
      creator: {
        id: 'de49204f-14b7-474d-b09c-6a45e617a32d',
        firstName: 'Ramona',
        lastName: 'Thompson',
        email: 'tramona200@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'r.a_mona2',
          followers: 1414,
          engagementRate: 9.090909090909092
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T16:13:45.000Z',
        amountCents: 3122
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'c9178a20-58bd-4360-b7f4-a1b36ec71be9',
      content: [],
      creator: {
        id: '269012b9-d396-4713-b695-58f1103e33ae',
        firstName: 'Jamile',
        lastName: 'Dunn',
        email: 'everjamz@gmail.com',
        instagramProfile: {
          handle: 'jam_jewelz',
          followers: 916,
          engagementRate: 4.967248908296943
        },
        tiktokProfile: {
          handle: 'jam_zellaaaa',
          followers: 1487,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T16:15:30.000Z',
        amountCents: 5298
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '036bcb4c-061d-4c70-812b-cbc69d7c99cd',
      content: [],
      creator: {
        id: '53f9cab6-7f64-4a94-b257-887f43a6fd77',
        firstName: 'Angelica',
        lastName: 'Uscanga',
        email: 'as.2309@live.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'amayuscg_',
          followers: 13,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'angelicauscangasantos2749',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T16:17:32.000Z',
        amountCents: 5677
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '4c2716f6-8e9a-4380-a1fa-f763beaac19b',
      content: [],
      creator: {
        id: 'ff34597a-9c33-428e-b901-fb558b524b5d',
        firstName: 'Lejoun',
        lastName: 'Taylor',
        email: 'lejountaylor@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'lejou',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T16:22:09.000Z',
        amountCents: 4500
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '848a7f6e-5008-4016-ab88-09d126c7025f',
      content: [],
      creator: {
        id: '57ad2a7f-68b0-4174-87a8-017440ab7cff',
        firstName: 'Aneyra',
        lastName: 'Rivera',
        email: 'aneyrarivera25@gmail.com',
        instagramProfile: {
          handle: 'anyrt25',
          followers: 813,
          engagementRate: 1.6113161131611315
        },
        tiktokProfile: {
          handle: 'user97715417686705',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T16:23:10.000Z',
        amountCents: 5810
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'aa0a8fbd-ab00-4f3e-8e71-b92ec73dfcdb',
      content: [],
      creator: {
        id: '36ff7efa-b6e9-4b5b-bd3c-295e2157d173',
        firstName: 'Moesha',
        lastName: 'Henry',
        email: 'moesha.henry1@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'mohen046',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T16:34:56.000Z',
        amountCents: 4500
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '128bbe8e-5ec1-489f-9473-a5494da5f61b',
      content: [],
      creator: {
        id: 'ded1353f-d688-4925-bb79-e664fe1cda6e',
        firstName: 'Dee',
        lastName: 'Basped',
        email: 'dee.strain6@gmail.com',
        instagramProfile: {
          handle: 'dee_basped6',
          followers: 1360,
          engagementRate: 3.257650542941757
        },
        tiktokProfile: {
          handle: 'dee.006',
          followers: 945,
          engagementRate: 8.071041562700506
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T16:41:15.000Z',
        amountCents: 4707
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '05e765e9-62dd-4c1d-9c0e-c3a0ae1e9e89',
      content: [],
      creator: {
        id: '32cc9b9a-43e6-471d-8efd-ecfb4ab5697f',
        firstName: 'Jahvanique',
        lastName: 'White',
        email: 'jahvaniquewhite@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'british985',
          followers: 1,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T16:46:41.000Z',
        amountCents: 2500
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'adb3931f-a80d-43c8-a8e1-ca0d5530236f',
      content: [],
      creator: {
        id: '8aed04db-10a2-4ba0-8950-5a41dba79cf5',
        firstName: 'Franz ',
        lastName: 'Cambridge ',
        email: 'kayanna876@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'kayannaarchibald',
          followers: 1,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'kayannaarchibald9812',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T16:51:23.000Z',
        amountCents: 8000
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'eaf9223e-79f2-41fb-aa0f-5f27d0f77532',
      content: [],
      creator: {
        id: 'ff99d4f2-020c-4e60-b090-e82e6f4d720e',
        firstName: 'Lisa',
        lastName: 'Rowe',
        email: 'lisarowe093@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'lisaritfmop',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T16:52:19.000Z',
        amountCents: 5000
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'c53a4a66-6277-4187-bdd3-ed4d835e2ba9',
      content: [],
      creator: {
        id: '6e13f9b1-1eae-47dd-a068-3151635719b2',
        firstName: 'Crystal',
        lastName: 'Gayle',
        email: 'fenlatercrystal@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'crystal.fenlater',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'crystalfenlater',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T16:59:07.000Z',
        amountCents: 6205
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'f2b52007-d8ee-495b-af58-a9f002509d4c',
      content: [],
      creator: {
        id: '408f7f59-10ce-4d02-beb8-1a0065e3d8fd',
        firstName: 'Tajae ',
        lastName: 'Hylton ',
        email: 'tajaehylton759@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'awesometaj',
          followers: 120,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'tajaehylton8750',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T17:16:51.000Z',
        amountCents: 4500
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '08627157-1529-49e5-b7f8-157622b56f21',
      content: [],
      creator: {
        id: '9a0ca503-44ff-422f-90d6-469818ebc0c9',
        firstName: 'Teresa ',
        lastName: 'Sawyers',
        email: 'tsawyers517@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'godblesssawyers',
          followers: 2109,
          engagementRate: 18.30985915492958
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T17:23:09.000Z',
        amountCents: 4430
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '3aee218f-9614-47de-ad8b-ffb06376d8d4',
      content: [],
      creator: {
        id: '68911b58-d055-4375-a199-57c751d3e36e',
        firstName: 'Arianna',
        lastName: 'Gordon',
        email: 'ariannagordon1997@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: '_callmearii_',
          followers: 79,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T17:46:42.000Z',
        amountCents: 6000
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'd2411980-5397-427d-a086-3bb27557118a',
      content: [],
      creator: {
        id: '7966c8ac-7284-47e0-95db-00f8c3390234',
        firstName: 'Shanakay',
        lastName: 'Scott',
        email: 'mhzbrown96@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'lly_mhz',
          followers: 443,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'jaysonnbrown',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T17:53:52.000Z',
        amountCents: 3999
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'f876c58f-ad09-411b-b2b0-8959c89a81d6',
      content: [],
      creator: {
        id: '68dbcebb-492b-495b-82ea-e07185e1fffa',
        firstName: 'Shantell',
        lastName: 'Latty ',
        email: 'richardsoshanice22@icloud.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'oshine564',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'shanice7312',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T18:10:20.000Z',
        amountCents: 6000
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '0fbe1df2-a81f-49eb-967a-e6902928d1ed',
      content: [],
      creator: {
        id: '2b01df8b-3b55-4e23-a1d7-65068e895986',
        firstName: 'Angelina',
        lastName: 'Susantyo',
        email: 'eddy.susantyo1955@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'susantyo.e',
          followers: 1,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'eddysusantyo',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T18:13:36.000Z',
        amountCents: 4643
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '303104a5-97bf-466a-87ce-6def528af8d4',
      content: [],
      creator: {
        id: '557ff18b-617b-48f7-bc59-38c32780aee8',
        firstName: 'Lotanya',
        lastName: 'Thompson',
        email: 'latoyat268@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'michealwilson1876',
          followers: 508,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T18:22:58.000Z',
        amountCents: 4492
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'e21d8c50-f258-4a72-9967-8bbdbfdb2c08',
      content: [],
      creator: {
        id: 'd8069a22-921b-4f11-9a88-a1fdd749e1d0',
        firstName: 'Kimberly',
        lastName: 'Streete',
        email: 'streetekaye1126@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'kimberly4306',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'kimberly-ee3gc',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T18:24:19.000Z',
        amountCents: 5430
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'dfbeb18a-7601-4524-ab39-fc5a3321659d',
      content: [],
      creator: {
        id: '48daa05e-c062-4f1f-9ec3-eb71c7adb3cd',
        firstName: 'Tris-Ann',
        lastName: 'Thompson',
        email: 'trisann_thompson@yahoo.com',
        instagramProfile: {
          handle: 'triplet_fashionz',
          followers: 60,
          engagementRate: 5.833333333333333
        },
        tiktokProfile: null,
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T19:04:05.000Z',
        amountCents: 4500
      },
      status: 'INCOMPLETE',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '6bc1eb41-1004-4421-8d89-a7e332ee4c84',
      content: [],
      creator: {
        id: '037ffe60-415a-49bf-ac9d-a995aac4d12a',
        firstName: 'Dayna',
        lastName: 'Barrett',
        email: 'daynabarrett28@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'dayna.barrett1',
          followers: 2,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T19:20:37.000Z',
        amountCents: 11000
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'aaa9ee98-be24-4b96-9165-d518fe0ca310',
      content: [],
      creator: {
        id: 'a089bdae-21d9-4a1d-bb62-2df3b358584f',
        firstName: 'Nadine',
        lastName: 'Smith',
        email: 'barrettnyshann@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'barrettnyshann',
          followers: 1,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'nysh-annbarrett8301',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T19:38:03.000Z',
        amountCents: 4865
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'bc07ca7b-833a-4d06-9805-22cad7c29a38',
      content: [],
      creator: {
        id: '85d5690b-4d2b-49f0-84ae-fb757888ea05',
        firstName: 'Toniann',
        lastName: 'Green',
        email: 'italliagreen@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'tonianngreen',
          followers: 111,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'italliagreen',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T19:48:32.000Z',
        amountCents: 4990
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'ea1d82b7-9b46-4c18-827c-488ea2101c43',
      content: [],
      creator: {
        id: '065ee5d3-28f1-4688-b755-e01455e124d9',
        firstName: 'Keadian ',
        lastName: 'Blake',
        email: 'keadianblake979@gmail.com',
        instagramProfile: {
          handle: 'thickasskady',
          followers: 543,
          engagementRate: 3.1570639305445938
        },
        tiktokProfile: {
          handle: 'kadykay24',
          followers: 1604,
          engagementRate: 5.3708439897698215
        },
        youtubeProfile: {
          handle: 'keadianblake7152',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T19:48:46.000Z',
        amountCents: 4470
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '0d4c1042-75c1-461f-a33e-8bda7dcb7e5c',
      content: [],
      creator: {
        id: '497de579-d8ef-4cbf-b93e-0612ee2bef10',
        firstName: 'Jacqueline',
        lastName: 'Henry',
        email: 'henry.jackie777@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'jacquelinehenry20',
          followers: 2,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'jackieh7773',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T20:33:06.000Z',
        amountCents: 5561
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'bcd39bcf-f744-49f1-9689-85a9dbcf147b',
      content: [],
      creator: {
        id: '78a4af53-0f76-48e5-9f93-26037ab402c5',
        firstName: 'Toniann',
        lastName: 'Green',
        email: 'tonianngreen561@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'toniann.green4',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'toni-anngreen5234',
          followers: 8,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T20:35:35.000Z',
        amountCents: 3848
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '2b2848fa-185f-4bd8-8a06-093707d3de17',
      content: [],
      creator: {
        id: '95e26e67-bf69-4bc4-8b17-56749717d0ad',
        firstName: 'Frank',
        lastName: 'OSullivan',
        email: 'ofrank94@yahoo.com',
        instagramProfile: {
          handle: 'luxuryaromaperfumeoils',
          followers: 83,
          engagementRate: 20.481927710843372
        },
        tiktokProfile: {
          handle: 'sullidon1',
          followers: 108,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'sullig9467',
          followers: 21,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T20:46:12.000Z',
        amountCents: 8676
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '08815e01-c698-4542-86bc-a5b8ac9866d3',
      content: [],
      creator: {
        id: '4ae8defc-d307-4c4f-9ea6-c6919c4be92e',
        firstName: 'Karla Cezaren',
        lastName: 'Alejo',
        email: 'raven3008@icloud.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'raven.stend',
          followers: 0,
          engagementRate: 0
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T22:36:47.000Z',
        amountCents: 4643
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '40b14b48-798a-4371-9234-b58449f12b8c',
      content: [],
      creator: {
        id: 'bd21949b-c7a9-4473-a652-d05996e1da08',
        firstName: 'Raymond',
        lastName: 'Anacaya',
        email: 'stev_jang@yahoo.com',
        instagramProfile: {
          handle: 'raym0nd3o',
          followers: 171,
          engagementRate: 1.1764705882352942
        },
        tiktokProfile: {
          handle: 'jnl.rmlcio',
          followers: 15350,
          engagementRate: 5.012531328320802
        },
        youtubeProfile: {
          handle: 'stevjang1',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T22:37:07.000Z',
        amountCents: 4643
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '3e130efd-38ed-48f3-81d7-cb650b28bdae',
      content: [],
      creator: {
        id: '4179ec49-04ec-4516-b443-619bee265b47',
        firstName: 'Shanelle',
        lastName: 'Tomlinson',
        email: 'shanelletom1919@gmail.com',
        instagramProfile: {
          handle: 'sh_anshan97',
          followers: 252,
          engagementRate: null
        },
        tiktokProfile: {
          handle: 'sheedah_gaming',
          followers: 1582,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T22:41:24.000Z',
        amountCents: 3799
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '52745795-42fa-4b69-989c-74b24270a934',
      content: [],
      creator: {
        id: '8db90147-90e5-4147-8d60-e96cd7bc76ba',
        firstName: 'Adanary',
        lastName: 'Rivera',
        email: 'adanaryrivera@hotmail.com',
        instagramProfile: {
          handle: 'deliciouscake2018_',
          followers: 32,
          engagementRate: 5.9375
        },
        tiktokProfile: {
          handle: 'adanaryriveraa',
          followers: 216,
          engagementRate: 2.6785714285714284
        },
        youtubeProfile: {
          handle: 'alydrivera',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-19T22:41:48.000Z',
        amountCents: 5553
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '0f13c62c-510f-4f94-8095-deefccd63bd8',
      content: [],
      creator: {
        id: '32c80520-df37-4837-b016-84675c7e9f83',
        firstName: 'Fayona',
        lastName: 'Thompson',
        email: 'fayonathompson43@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'fayona.thompson',
          followers: 8,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T00:26:29.000Z',
        amountCents: 4500
      },
      status: 'INCOMPLETE',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'a28960d8-9931-4dc2-839a-6e1494a761a5',
      content: [],
      creator: {
        id: 'efe265ea-ddcd-4c9c-bbce-87616dc9c03c',
        firstName: 'Tiffany',
        lastName: 'Williams',
        email: 'wtiffany120@gmail.com',
        instagramProfile: {
          handle: 'eurekazbeveragez',
          followers: 89,
          engagementRate: null
        },
        tiktokProfile: {
          handle: 'tiffany.williams820',
          followers: 866,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T01:01:51.000Z',
        amountCents: 4295
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '230ecc68-7e7e-4b37-ae6d-ab23e3caaef5',
      content: [],
      creator: {
        id: '1abb9a51-26a7-4e96-be08-de24ad796189',
        firstName: 'Devene',
        lastName: 'Barrett',
        email: 'devene_b@yahoo.com',
        instagramProfile: {
          handle: 'project_masters_printery',
          followers: 418,
          engagementRate: 2.0334928229665072
        },
        tiktokProfile: null,
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T01:23:03.000Z',
        amountCents: 8472
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'ab0c00d3-2905-4928-9e60-363af84b89ae',
      content: [],
      creator: {
        id: '7c008d65-7ddf-40f7-868e-9980d05dd11a',
        firstName: 'Moya',
        lastName: 'Grant',
        email: 'moya.grant92@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'putitonatee',
          followers: 18,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T02:15:50.000Z',
        amountCents: 8228
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '54d4bf09-83c8-4515-b2df-035c6dc1ec90',
      content: [],
      creator: {
        id: 'c00fec4b-07e5-4fd6-a439-0b68e2c9434e',
        firstName: 'Victoria',
        lastName: 'Korus',
        email: 'victoriakorus@gmail.com',
        instagramProfile: {
          handle: 'victoriakorus',
          followers: 4360,
          engagementRate: 86.62385321100918
        },
        tiktokProfile: {
          handle: 'victoriakorus',
          followers: 56880,
          engagementRate: 5.301645338208409
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T02:51:30.000Z',
        amountCents: 4319
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '25337559-f76f-404a-8c7e-c4d20eb3169d',
      content: [],
      creator: {
        id: '4e849a43-4a45-4d2c-803e-e0e4106c83f5',
        firstName: 'Lincon',
        lastName: 'Jonas',
        email: 'lincon.jonas.lj@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'london6x',
          followers: 1893,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T02:53:30.000Z',
        amountCents: 4843
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '08bfcde0-7a19-4d3a-a091-a435497058ca',
      content: [],
      creator: {
        id: 'b47d340b-6166-42c7-8038-7978e36095fb',
        firstName: 'Deja',
        lastName: 'Kennedy',
        email: 'dejakennedy24@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'dejnarae',
          followers: 706,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T03:25:43.000Z',
        amountCents: 4299
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '9fcbc857-ca8d-4699-9a91-5ab7e784e471',
      content: [],
      creator: {
        id: 'fab3a4c0-9afd-4aad-bf00-8e44f35d27c0',
        firstName: 'Tania’s',
        lastName: 'Robinson',
        email: 'tanise.robinsonja26@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'its_original_sky',
          followers: 1743,
          engagementRate: 17.857142857142858
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T05:02:08.000Z',
        amountCents: 4833
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'c67fded0-bdc0-4068-88be-e1cd3b5c1c22',
      content: [],
      creator: {
        id: '69b6bdd8-ee62-4339-b17b-abe0f1aa1f80',
        firstName: 'Jacqueline',
        lastName: 'Henry',
        email: 'educatejackie@yahoo.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'jacqueline.henry05',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'jacqueline-yq4xk',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T05:14:19.000Z',
        amountCents: 5079
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '7551d9e5-fa77-41e1-a445-b603ffa63c3a',
      content: [],
      creator: {
        id: '8b6f4a5c-375b-4f67-b194-3884471a7610',
        firstName: 'Reynae',
        lastName: 'Bryan',
        email: 'nicolabryan66@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'prettygyal_23',
          followers: 1399,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T05:26:15.000Z',
        amountCents: 6688
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'a3ca8c61-9e6b-41b4-bea3-6bf1a73a3aff',
      content: [],
      creator: {
        id: '5599a7fe-8ac0-4bdd-b2f7-6dcbc62f2e13',
        firstName: 'Shafagh',
        lastName: 'Son',
        email: 'sichyar@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'user6842391455010',
          followers: 1,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'sahar19821',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T05:33:19.000Z',
        amountCents: 5742
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '64f3c52a-4ea5-42c7-b482-6546e52edf13',
      content: [],
      creator: {
        id: '00508731-f740-40d5-9157-1290b2aa87a8',
        firstName: 'Mishka',
        lastName: 'Wade',
        email: 'mishkawade27@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'mishkafrazer',
          followers: 878,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T05:34:06.000Z',
        amountCents: 4295
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'bd8c6712-ea68-4a60-9945-a89b73051b83',
      content: [],
      creator: {
        id: 'c8d0bae7-a7d7-47e3-9134-0c80869c5a2e',
        firstName: 'Mary',
        lastName: 'Sasil',
        email: 'mannsasil@gmail.com',
        instagramProfile: {
          handle: 'annsasil',
          followers: 806,
          engagementRate: null
        },
        tiktokProfile: {
          handle: 'zynahamira05',
          followers: 282,
          engagementRate: 2.5
        },
        youtubeProfile: {
          handle: 'maryanndapiton9351',
          followers: 36,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T08:33:45.000Z',
        amountCents: 5189
      },
      status: 'INCOMPLETE',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '0fd44d20-6c9b-44a8-a95d-c44a8edbc99a',
      content: [],
      creator: {
        id: 'c289842d-a2b0-479e-8237-a0ee1280262e',
        firstName: 'Theodore',
        lastName: 'Sailsman',
        email: 'theosailsman23@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'theo_28_16',
          followers: 15,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T11:12:46.000Z',
        amountCents: 11769
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'ec16eb08-83e2-4df8-9744-d3c8a12a4d33',
      content: [],
      creator: {
        id: 'ef469d65-7d77-4771-a829-b4cc9afd9fb3',
        firstName: 'Marisha',
        lastName: 'Wilson',
        email: 'marishaugc@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'justms.wilson',
          followers: 428,
          engagementRate: 9.6
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T12:54:27.000Z',
        amountCents: 4299
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '721f190c-e2dc-4712-92ab-b072db305fd7',
      content: [],
      creator: {
        id: '2c516852-0c43-4350-94b6-05dae8970cd4',
        firstName: 'Saneika',
        lastName: 'Williams',
        email: 'saneikaw@yahoo.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'nursegoodiepooh',
          followers: 31,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T12:54:30.000Z',
        amountCents: 4295
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '956ed08f-a5df-4a76-a46d-9f67208a0b5b',
      content: [],
      creator: {
        id: '00de1f2b-3d9b-447b-acbc-2d69531ba17e',
        firstName: 'Petagay',
        lastName: 'Lattimore',
        email: 'rohjaybartlett@gmail.com',
        instagramProfile: {
          handle: 'keep_up_with_latty',
          followers: 1173,
          engagementRate: 4.254049445865302
        },
        tiktokProfile: {
          handle: 'llatty_toptier',
          followers: 3902,
          engagementRate: 6.872037914691943
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T13:44:57.000Z',
        amountCents: 4000
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'b5b7c9eb-9099-47a6-a338-09eaad6cddf0',
      content: [],
      creator: {
        id: 'da0bc316-c9a8-4e5e-8d99-35f4cfdd605d',
        firstName: 'Fatima',
        lastName: 'Fuentes',
        email: 'fatimafuentes21@outlook.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'fatimiyolln',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T14:36:34.000Z',
        amountCents: 5710
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '755a2be7-591c-45ff-9cca-45629ccf9d0e',
      content: [],
      creator: {
        id: '0e0dbabb-2dbb-43a4-9609-73f0d5761346',
        firstName: 'Debra',
        lastName: 'Brown ',
        email: 'dsexy0316@yahoo.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'debzaly',
          followers: 335,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T15:22:52.000Z',
        amountCents: 4354
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '010f2fc9-717e-4e1f-9ad6-54bbbea2288a',
      content: [],
      creator: {
        id: 'c896ba5d-db44-4b02-8923-2a636c3d5cd1',
        firstName: 'Sashagay',
        lastName: 'Richards',
        email: 'sammiirichards@icloud.com',
        instagramProfile: {
          handle: 'sammiis__skin',
          followers: 0,
          engagementRate: 100
        },
        tiktokProfile: {
          handle: 'sammii_smiless',
          followers: 22,
          engagementRate: 1.8556701030927836
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T15:56:13.000Z',
        amountCents: 10838
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'd70d2bbb-83bd-401e-8944-4e8296e2d475',
      content: [],
      creator: {
        id: 'b9702aa1-6871-4f57-91cc-b1a4b98e1396',
        firstName: 'Cherie',
        lastName: 'Hawkins',
        email: 'shegoes@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'specialkayrina',
          followers: 19,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'reviewsbykay',
          followers: 5520,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T16:12:29.000Z',
        amountCents: 5411
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'eda48190-921b-452a-9e70-1c9dc2a753b5',
      content: [],
      creator: {
        id: 'f1b663ed-6553-4d1d-812a-36d145b652ac',
        firstName: 'Stephen',
        lastName: 'Anderson',
        email: 'stephenstepper@gmail.com',
        instagramProfile: {
          handle: 'yung_genius876',
          followers: 1938,
          engagementRate: 2.7593254982115485
        },
        tiktokProfile: {
          handle: 'yung_genius8',
          followers: 277,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T16:13:19.000Z',
        amountCents: 5000
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '7f76222e-94c1-470a-af05-2288242ecc4d',
      content: [],
      creator: {
        id: '647dd009-33f0-46c8-a978-bb2ea7b08dd0',
        firstName: 'geethu',
        lastName: 'reddy',
        email: 'dancingdolphins13@gmail.com',
        instagramProfile: {
          handle: 'ariabeauty30',
          followers: 1645,
          engagementRate: 9.098277608915906
        },
        tiktokProfile: {
          handle: 'ariabeauty3',
          followers: 946,
          engagementRate: 1.5408320493066257
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T16:17:01.000Z',
        amountCents: 4301
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '4a46e89c-f273-435a-bc4b-2f6a83cbef44',
      content: [],
      creator: {
        id: '5a1a2bbc-724c-480a-8610-b309cb1c2a74',
        firstName: 'Joyce',
        lastName: 'Lu',
        email: 'mayday1542002@yahoo.com.tw',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'hhh26196',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'paulwang3561',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T16:17:48.000Z',
        amountCents: 5814
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'd9e8c705-0384-42f8-bd14-e262b1707cdc',
      content: [],
      creator: {
        id: '2170e86d-99b8-42ca-84cd-e5f16f178700',
        firstName: 'Parker',
        lastName: 'Hawkins',
        email: 'phawkinstx@gmail.com',
        instagramProfile: {
          handle: 'p4privity',
          followers: 75,
          engagementRate: 12.20657276995305
        },
        tiktokProfile: null,
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T16:18:24.000Z',
        amountCents: 5411
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '43ef56ce-c8db-43e5-b57f-788352d0c698',
      content: [],
      creator: {
        id: '23cd8458-4aa9-4a1f-a984-5e6243d8983d',
        firstName: 'Jacqueline',
        lastName: 'Pornia',
        email: 'jacquelinepornia@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'neverendingline',
          followers: 65,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T16:22:03.000Z',
        amountCents: 4629
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'e7b55c6d-c3b3-4d2a-b4fe-a5db7eee7c88',
      content: [],
      creator: {
        id: 'e38f8ce9-19ad-41e0-a3c4-33116cbb0bc3',
        firstName: 'Suheera',
        lastName: 'Malik',
        email: 'suheera@hotmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'areehu10',
          followers: 48,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'suheeramalik8877',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T16:33:07.000Z',
        amountCents: 4557
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '881e756c-86f8-4afe-a16b-361f2aafd62e',
      content: [],
      creator: {
        id: 'b3573f38-592b-461e-8591-bf9e4ce12db7',
        firstName: 'Jada',
        lastName: 'Bourne',
        email: 'jadabourne123@gmail.com',
        instagramProfile: {
          handle: 'island_queen_jada',
          followers: 5675,
          engagementRate: 1.568281938325991
        },
        tiktokProfile: {
          handle: 'jadabourne',
          followers: 10500,
          engagementRate: 9.580052493438322
        },
        youtubeProfile: {
          handle: 'islandqueenjada2263',
          followers: 328,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T17:12:50.000Z',
        amountCents: 4484
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'f3598de2-1e6e-4901-b55c-4b10f531ef3c',
      content: [],
      creator: {
        id: '69443cd1-bef2-46c4-9a12-50fdf3bdaac2',
        firstName: 'Shamar',
        lastName: 'Walters',
        email: 'waltersshamar6@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'fc3_.c',
          followers: 12,
          engagementRate: 4.243697478991597
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T17:34:32.000Z',
        amountCents: 4063
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '470c1bd5-b021-45ca-87cb-7d75c8cd0eff',
      content: [],
      creator: {
        id: '85894d0d-47a0-4e96-92de-576a572b9bed',
        firstName: 'Wayne',
        lastName: 'Herah',
        email: 'wayneherah@yahoo.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'waynnie5',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T17:46:30.000Z',
        amountCents: 2994
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'e7e28986-8215-4c5e-9905-ac575c36cd00',
      content: [],
      creator: {
        id: 'c83e8df5-3648-4841-a82b-4ea28efbfa22',
        firstName: 'Viktoriya',
        lastName: 'Lelych',
        email: 'worsted-batsmen.0o@icloud.com',
        instagramProfile: {
          handle: 'videomaker_viktoriya_lelych',
          followers: 42,
          engagementRate: 167.46031746031744
        },
        tiktokProfile: {
          handle: 'vika_lelych',
          followers: 6,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'viktoriyalelych5646',
          followers: 220,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T17:46:35.000Z',
        amountCents: 4557
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '35395f09-95a1-488c-b80d-2c6adb1da615',
      content: [],
      creator: {
        id: '2225fa5f-29df-4365-bb47-eb504aad5138',
        firstName: 'Denise',
        lastName: 'Smith',
        email: 'zyron5156@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'dendeninfluence',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'zyron-tk5gs',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T18:03:06.000Z',
        amountCents: 4130
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'bce0d3ac-c673-47b9-b661-c655a12d6791',
      content: [],
      creator: {
        id: '16226689-8d0b-4273-9fc2-57b612b514e4',
        firstName: 'Keke',
        lastName: 'Bailey',
        email: 'kekebailey100@yahoo.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'kekeb80',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T18:05:00.000Z',
        amountCents: 8020
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '22166332-5145-42a6-b6f6-c1ae4de7fb23',
      content: [],
      creator: {
        id: '4e85cd6e-4aef-417b-b010-53926c4e3937',
        firstName: 'Tineisha',
        lastName: 'Reid',
        email: 'cedella.reid26@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'della3177',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T18:15:06.000Z',
        amountCents: 2937
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '31fb30a9-f2fb-4b8f-8549-b437ac5c207c',
      content: [],
      creator: {
        id: '9f29d447-1e84-476c-a714-348b4ddba784',
        firstName: 'Greg',
        lastName: 'James',
        email: 'jgreg4938@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'jgregvfp1jq',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T18:20:40.000Z',
        amountCents: 7455
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'edcb72b5-d306-484b-8ce0-5fc0ea8ea0c0',
      content: [],
      creator: {
        id: '67415ae7-ced7-4d54-b625-776c20bf9f12',
        firstName: 'Isabelle',
        lastName: 'Schreiner',
        email: 'beautybybellanicole@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'bellalatethannever',
          followers: 10247,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T18:29:31.000Z',
        amountCents: 4360
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'bc44807b-6c4a-4e66-a7c8-1f72100feba2',
      content: [],
      creator: {
        id: '69d17b2d-c68a-4a44-a6af-1209b4329aac',
        firstName: 'Jessica',
        lastName: 'Conrad',
        email: 'jessifer.1018@yahoo.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'jessifer8093',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'jessiesjems8525',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T19:12:33.000Z',
        amountCents: 4331
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'c4ee1075-3ac3-4aa5-97d4-9c5bc2aeceb4',
      content: [],
      creator: {
        id: 'f28a97b9-f3a1-4b93-82a5-34f0ed44c74f',
        firstName: 'Jahnelle',
        lastName: 'Ricketts',
        email: 'rjahnelle@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'prettyslimchicchin',
          followers: 102,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T19:14:24.000Z',
        amountCents: 4654
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'f12dab65-eb11-448d-b09f-8d2a8c31057a',
      content: [],
      creator: {
        id: '7fa56711-bc6a-4bec-860a-3d61be869fb5',
        firstName: 'Shadera',
        lastName: 'Housen',
        email: '59shadera@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'shawdynameshaddy',
          followers: 523,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T19:14:34.000Z',
        amountCents: 4299
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'dc637083-4d08-4c58-902e-3558884f5ab0',
      content: [],
      creator: {
        id: '56f0529c-e362-445e-8295-6acf8d778a80',
        firstName: 'Simone ',
        lastName: 'Campbell',
        email: 'simonecampbell270@yahoo.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'tajae.hylton0',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T19:27:06.000Z',
        amountCents: 4500
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'b6aafb0b-af2d-4ff5-bfd6-8ea83ab4be02',
      content: [],
      creator: {
        id: '68b5f71c-df22-427c-8efc-e8a3f08af1f3',
        firstName: 'Christan',
        lastName: 'Doyley',
        email: 'christandoyley27@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'chris_don97',
          followers: 5447,
          engagementRate: 14.23444976076555
        },
        youtubeProfile: {
          handle: 'christandoyley',
          followers: 1,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T19:35:26.000Z',
        amountCents: 5038
      },
      status: 'INCOMPLETE',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'e30264ac-514e-4017-94e3-ceca67ec746a',
      content: [],
      creator: {
        id: '3588e0f5-fce1-44bf-96a4-db0d212ba6e6',
        firstName: 'Ana',
        lastName: 'Gamez',
        email: 'scarletfun2@gmail.com',
        instagramProfile: {
          handle: 'literalbeautygirl',
          followers: 1216,
          engagementRate: 3.1524122807017543
        },
        tiktokProfile: {
          handle: 'literalbeautygirl',
          followers: 258,
          engagementRate: 6.708268330733229
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T19:35:44.000Z',
        amountCents: 4281
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'd0b47f4b-a7fc-4bca-910e-dc269b81e5ea',
      content: [],
      creator: {
        id: 'f46b5ff3-156c-47c8-92be-f559566d2400',
        firstName: 'Shanique',
        lastName: 'Smith',
        email: 'lexiwray1010@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'shantorys.nails',
          followers: 297,
          engagementRate: 2.5345334640406376
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T19:54:14.000Z',
        amountCents: 4484
      },
      status: 'INCOMPLETE',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '34ff805e-5ca5-4ecc-9da5-7046dff6f27d',
      content: [],
      creator: {
        id: '725fd785-d6d7-41bd-aaef-6cfad14b1fc6',
        firstName: 'Koria',
        lastName: 'Allen',
        email: 'koriak92@live.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'kokonella1',
          followers: 32,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T19:58:37.000Z',
        amountCents: 4044
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '670b276a-ce8e-4203-bfc1-3f305bbb28fa',
      content: [],
      creator: {
        id: '89de6eee-4488-49eb-885f-e16191a33a67',
        firstName: 'Erlia',
        lastName: 'Bonanza',
        email: 'bonanzaerlia@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'grendyg',
          followers: 2308,
          engagementRate: 15.258410867584288
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T20:02:59.000Z',
        amountCents: 4654
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '7301966f-3afe-45e8-94ed-8b670c5d62f9',
      content: [],
      creator: {
        id: '5a1bc1ca-12d5-4e9e-9973-5690d70440e6',
        firstName: 'Felicia',
        lastName: 'Perez',
        email: 'feliciacperez20@yahoo.com',
        instagramProfile: {
          handle: 'felicia__86',
          followers: 480,
          engagementRate: 0.45662100456621013
        },
        tiktokProfile: null,
        youtubeProfile: {
          handle: 'feliciaperez5699',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T21:15:35.000Z',
        amountCents: 5411
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '6ee5d539-44bd-4e40-85a8-94542a64c1e3',
      content: [],
      creator: {
        id: 'c85f3cb2-b393-47bb-afd2-11663145248e',
        firstName: 'Tara',
        lastName: 'Smith',
        email: 'tarams78@gmail.com',
        instagramProfile: {
          handle: 'reviews.by.tara',
          followers: 0,
          engagementRate: 0
        },
        tiktokProfile: {
          handle: 'tarabertone6',
          followers: 2,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'tarabertone2587',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T21:34:39.000Z',
        amountCents: 4132
      },
      status: 'INCOMPLETE',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '4255e5cd-77f5-428c-a615-1be2307f234a',
      content: [],
      creator: {
        id: 'ed8cd2c2-c2c9-423d-90b1-035ec913b6be',
        firstName: 'Sherae',
        lastName: 'Roye LTN',
        email: 'sheraeroye10@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'ashleyup__',
          followers: 6360,
          engagementRate: 7.423580786026202
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T21:42:50.000Z',
        amountCents: 9893
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'eb553c9a-d720-41e5-ae6e-8b7b8711b3ba',
      content: [],
      creator: {
        id: 'fe9ee51c-f34c-4205-b8a0-29279b44e227',
        firstName: 'Charles',
        lastName: 'Steynberg',
        email: 'charlessteynberg@gmail.com',
        instagramProfile: {
          handle: 'sirdaxton',
          followers: 1059,
          engagementRate: 1.6902738432483473
        },
        tiktokProfile: null,
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T21:46:32.000Z',
        amountCents: 5425
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '29ada91d-cd16-4cca-a791-39a95bb8d0ef',
      content: [],
      creator: {
        id: '70346f57-f7e4-454b-b091-9512e768e7bf',
        firstName: 'Christina',
        lastName: 'Thompson',
        email: 'thompsonchristina742@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: '_gorgeous.cc',
          followers: 99,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T21:54:40.000Z',
        amountCents: 4436
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '3d296c49-6e64-47f0-9e40-e582638a0850',
      content: [],
      creator: {
        id: 'ec53ecd8-846e-4ce3-aea8-43916ae02df4',
        firstName: 'Zoann',
        lastName: 'Brown',
        email: 'zobrown056@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'zagga20',
          followers: 522,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T22:46:49.000Z',
        amountCents: 4000
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '44d892b7-534f-4db6-8c0c-04bc6b1b1e3b',
      content: [],
      creator: {
        id: 'ec4dea21-4901-4b4e-a0aa-493aa4dcbf69',
        firstName: 'Shinnel',
        lastName: 'Sewell',
        email: 'shinnelvsewell@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'nicenessnikki',
          followers: 1070,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T22:52:52.000Z',
        amountCents: 8744
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'e0dbddf1-968d-4cfc-bcd6-6ceea998d554',
      content: [],
      creator: {
        id: '69cdf64a-f4d9-4e7b-9a7f-ee5bcbdd9c0a',
        firstName: 'Shinnel',
        lastName: 'Sewell',
        email: 'viddyandvie@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'viddy278',
          followers: 1,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-20T22:52:58.000Z',
        amountCents: 7481
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '50c9a29d-fea3-4201-82c6-5a3bca5b33b9',
      content: [],
      creator: {
        id: 'd4587a11-f612-412b-b3f2-7423e3d0e5bd',
        firstName: 'Kimberly ',
        lastName: 'Brooks',
        email: 'annabrowns2003@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'kadie_dec16.bkup',
          followers: 246,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'kadiesworld6028',
          followers: 120,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T00:00:36.000Z',
        amountCents: 5298
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '7ed8b81f-7a7e-4e2c-8031-df54a7930f0b',
      content: [],
      creator: {
        id: '170751e8-c0ce-46f3-a5f3-73f8e0d8637a',
        firstName: 'Andina',
        lastName: 'Walker',
        email: 'andinawalker14@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'and._ina',
          followers: 3584,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T00:24:17.000Z',
        amountCents: 6626
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '62ede8e2-00b8-4c93-8c1c-dc2ce093773c',
      content: [],
      creator: {
        id: 'f873af65-4062-4e9b-839f-8ee290786f0e',
        firstName: 'Megan',
        lastName: 'Mcfarlane',
        email: 'meganmcfarlane1992@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'alicias504',
          followers: 587,
          engagementRate: 15.735294117647058
        },
        youtubeProfile: {
          handle: 'meganmcfarlane-ub2ki',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T00:46:38.000Z',
        amountCents: 3850
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'd176420b-687f-4c5f-9ae2-5c67f63725fd',
      content: [],
      creator: {
        id: 'd81a6a3f-f541-4e68-b9e7-6f1e9cd1f25d',
        firstName: 'Ashekia',
        lastName: 'Hunter',
        email: 'sheka.hunter@gmail.com',
        instagramProfile: {
          handle: 'shek_ia9',
          followers: 1115,
          engagementRate: 0.39461883408071746
        },
        tiktokProfile: {
          handle: 'shekabae',
          followers: 6111,
          engagementRate: 7.77402446699709
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T00:54:27.000Z',
        amountCents: 4299
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '0850227b-4993-464a-bc33-ef1b989aa831',
      content: [],
      creator: {
        id: '514604ac-02eb-48e7-b52c-0a886319275c',
        firstName: 'Monique',
        lastName: 'Douglas',
        email: 'momocreator0311@gmail.com',
        instagramProfile: {
          handle: 'prettyessentialthings',
          followers: 109,
          engagementRate: 0.3003003003003003
        },
        tiktokProfile: {
          handle: 'dropshipper8766',
          followers: 959,
          engagementRate: 4.672897196261682
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T00:54:36.000Z',
        amountCents: 4299
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '85c98539-aeb3-4d25-8c3d-525cbcaadc8d',
      content: [],
      creator: {
        id: '31aa7297-2582-4649-b8ec-dc1ecb597cee',
        firstName: 'Sasha-gay',
        lastName: 'Smith',
        email: 'wstay321@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'lisa688886',
          followers: 33,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'captainkkk4142',
          followers: 32,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T00:56:44.000Z',
        amountCents: 4321
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'a1f5e498-a4cc-4aab-8fe1-45e4b5a4ac37',
      content: [],
      creator: {
        id: '0eb81069-789d-4f7e-b5e8-c411d410d641',
        firstName: 'Peter-gay',
        lastName: 'Smith',
        email: 'kenisha820@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'annitabrown7',
          followers: 8,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'moneymoney-fx3eu',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T00:56:55.000Z',
        amountCents: 3537
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '17c0c0db-3950-4329-a0fe-7c1d2a2e8b4c',
      content: [],
      creator: {
        id: '8011d103-60f9-409d-8cc5-d81e462bba21',
        firstName: 'Shinnel',
        lastName: 'Sewell',
        email: 'matthewrgbennett@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'matthew.bennett60',
          followers: 1,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T01:02:08.000Z',
        amountCents: 8129
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '9ef40bdf-1f14-455a-aca4-5c75fed773d3',
      content: [],
      creator: {
        id: '6d10be29-6863-4520-960b-7bf12c9d6d4b',
        firstName: 'Shinnel',
        lastName: 'Sewell',
        email: 'bennettjustin0720@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'ben201734',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T01:02:14.000Z',
        amountCents: 8486
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '856762f3-3aaf-4458-b756-6433392aac5c',
      content: [],
      creator: {
        id: 'c1b58864-d10f-4b14-b1e4-74852e6fcac2',
        firstName: 'Chevelle',
        lastName: 'Fullerton',
        email: 'fullertonchevelle@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'chev.fullerton',
          followers: 1702,
          engagementRate: 19.429590017825312
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T01:15:32.000Z',
        amountCents: 4299
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '34f0ed11-4708-48f4-9552-b5e7caed76a7',
      content: [],
      creator: {
        id: 'cba11cd9-2503-45cf-a8f0-d14c040926e3',
        firstName: 'Tara',
        lastName: 'Ertone',
        email: 'heroes-wombats.0w@icloud.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'mstreviews',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'taraertone-mo6ij',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T01:35:34.000Z',
        amountCents: 4346
      },
      status: 'INCOMPLETE',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '8ec38008-46c6-4081-8c9d-21d24b4a17cb',
      content: [],
      creator: {
        id: '40a6b5a1-3e81-4678-89ce-2ca016e2a16c',
        firstName: 'Shanice',
        lastName: 'Davis',
        email: 'legendaryshanzy17@gmail.com',
        instagramProfile: {
          handle: 'shandollessentials',
          followers: 794,
          engagementRate: 2.0792079207920793
        },
        tiktokProfile: {
          handle: 'yuhgurlshanzy',
          followers: 1152,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'shanzybanzy156',
          followers: 7,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T02:45:20.000Z',
        amountCents: 4332
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '9dfd4c05-0615-4180-990a-19fce892fe30',
      content: [],
      creator: {
        id: 'a171111d-59ed-4710-8269-4f9ad6914099',
        firstName: 'Estefania',
        lastName: 'Madero ortiz',
        email: 'madero.estefania@gmail.com',
        instagramProfile: {
          handle: 'tefamadero',
          followers: 2407,
          engagementRate: 1.4873286248442041
        },
        tiktokProfile: {
          handle: 'estefaniamaderoo',
          followers: 153,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'stefmadero9230',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T02:54:09.000Z',
        amountCents: 4386
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '80773bc0-4696-4d20-b806-bf20f279ddd0',
      content: [],
      creator: {
        id: '3df95a8e-78d4-448e-b4b0-44ae8741b95a',
        firstName: 'Shaista',
        lastName: 'Shah',
        email: 'shaistamshah@gmail.com',
        instagramProfile: {
          handle: 'cali_paki',
          followers: 50,
          engagementRate: 8
        },
        tiktokProfile: {
          handle: 'shaistamshah',
          followers: 43,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T03:56:43.000Z',
        amountCents: 4632
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '89a960e8-05fb-42a8-8a64-55bb99ac4ed6',
      content: [],
      creator: {
        id: '52d3f437-af18-42c0-9ca8-7a0baf85a877',
        firstName: 'Tally',
        lastName: 'Farquharson',
        email: 'farquharsontally@yahoo.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'tallyboo28',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T05:04:04.000Z',
        amountCents: 7684
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '1f387c0f-2be7-4646-b245-1072aef383d0',
      content: [],
      creator: {
        id: 'ca1f26bb-221b-4ea9-8f32-7de55615c704',
        firstName: 'Sasha-Kay',
        lastName: 'Baker',
        email: 'princess12ab34@gmail.com',
        instagramProfile: {
          handle: 'princess_darkbeauty',
          followers: 260,
          engagementRate: null
        },
        tiktokProfile: {
          handle: 'princess_darkbeauty',
          followers: 216,
          engagementRate: 2.0118343195266273
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T05:24:15.000Z',
        amountCents: 4489
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '0fd7c74d-4abc-4a70-8573-348efab01a25',
      content: [],
      creator: {
        id: '9e28b618-8030-4d67-8c03-920bb529853f',
        firstName: 'Amelia',
        lastName: 'Thomas ',
        email: 'km1250686@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'cynnique0',
          followers: 14793,
          engagementRate: 3.309692671394799
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T05:24:26.000Z',
        amountCents: 4299
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'b7a6f26c-1e19-4815-ab00-ad52298ce897',
      content: [],
      creator: {
        id: '72fa1d39-8c76-4731-bbd5-099c48d53746',
        firstName: 'Jasmine',
        lastName: 'Jackson ',
        email: 'jasminepagejackson@yahoo.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'blvck.beauty_',
          followers: 58,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T05:55:29.000Z',
        amountCents: 4288
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '0bdb3922-cf23-4c27-b4fb-761bf20786f7',
      content: [],
      creator: {
        id: 'b50f1e46-1ecf-4f33-a245-1ec22d927f9c',
        firstName: 'Monique',
        lastName: 'Henry',
        email: 'starhenry399@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'starhenry5',
          followers: 398,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'starhenry1634',
          followers: 1,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T06:11:47.000Z',
        amountCents: 4401
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'fb939831-d9e2-4bbc-a407-8d31116eab52',
      content: [],
      creator: {
        id: '706c657e-d935-4371-8a7a-a99f1862c367',
        firstName: 'Kaydene',
        lastName: 'Alleyne',
        email: 'dkaydene@ymail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'kaydene_teacha',
          followers: 1194,
          engagementRate: 9.460708782742682
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T06:12:20.000Z',
        amountCents: 8399
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '49d53d9d-9b9f-4568-8246-ca90e9caf033',
      content: [],
      creator: {
        id: 'f46a2970-b96b-4f37-9df9-99023ce43fcc',
        firstName: 'Suz Marconi',
        lastName: 'Fuentes',
        email: 'suzmarcrf@gmail.com',
        instagramProfile: {
          handle: 'suzmarcrf',
          followers: 207,
          engagementRate: 2.4154589371980677
        },
        tiktokProfile: {
          handle: 'suzmarcrf',
          followers: 86,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'suzmarconi',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T06:43:38.000Z',
        amountCents: 4271
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '245802f9-2c3f-45e8-b68b-9cefd54f313a',
      content: [],
      creator: {
        id: '9c78bc35-153e-4d9f-897b-3fb1a58b2eb6',
        firstName: 'Garfield',
        lastName: 'Harris',
        email: 'davidrhymes7@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'proudtoblack_1234',
          followers: 17,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T07:04:14.000Z',
        amountCents: 4294
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '0cbb0c10-6d5a-4c45-9196-4eaba345d98a',
      content: [],
      creator: {
        id: 'a2b7108d-54d0-4bab-9d89-2aaae28e1282',
        firstName: 'Emma',
        lastName: 'Yoh',
        email: 'emmayoh@icloud.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'they.fw..emz21',
          followers: 14,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T07:25:44.000Z',
        amountCents: 5004
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'ebd642db-5b61-4672-911e-a316ac595804',
      content: [],
      creator: {
        id: '13388d6f-9090-44e4-809d-7620ff271213',
        firstName: 'Sandra Luz',
        lastName: 'Ordóñez',
        email: '2710slos@gmail.com',
        instagramProfile: {
          handle: '7537.luz',
          followers: 2483,
          engagementRate: 4.575110753121224
        },
        tiktokProfile: {
          handle: 'luz1468sandy',
          followers: 2936,
          engagementRate: 10.447301287012355
        },
        youtubeProfile: {
          handle: 'sandrasanchez-wr5tw',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T10:46:19.000Z',
        amountCents: 5762
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '9c13036f-64b1-4c72-be94-28a7806ae01e',
      content: [],
      creator: {
        id: '6ae06b0a-15b5-4089-b2ba-9fff32d9097b',
        firstName: 'Shadeeb',
        lastName: 'Cross',
        email: 'shadeeb876@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'nailinspofairy',
          followers: 7111,
          engagementRate: 3.207696932540775
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T12:51:06.000Z',
        amountCents: 4290
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '44e8835f-fe27-4b93-8da8-af10e5994820',
      content: [],
      creator: {
        id: '957df32f-7d00-48af-bfb8-7f61103f8d8c',
        firstName: 'Dymond',
        lastName: 'Rogers',
        email: 'dymondmonae715@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'g0ldendym',
          followers: 10287,
          engagementRate: 8.58770295264345
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T12:54:56.000Z',
        amountCents: 4654
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'a353ef88-8128-421b-9e74-17f632e7f993',
      content: [],
      creator: {
        id: 'b7c9fa67-33e3-40e3-90d0-a34243e1eb10',
        firstName: 'Ikeisha',
        lastName: 'Bent',
        email: 'chrissybaker490@gmail.com',
        instagramProfile: {
          handle: 'chrissyunpredictable',
          followers: 1107,
          engagementRate: 0.5413766434648105
        },
        tiktokProfile: {
          handle: 'thatgirl9179',
          followers: 3,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'kylieedmondson-hd8lt',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T13:04:31.000Z',
        amountCents: 4994
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'a0afe61d-dc23-4c73-b41b-5a57f6fe0c7d',
      content: [],
      creator: {
        id: '4f3262f1-7df0-4f79-9eec-37ddd58709db',
        firstName: 'Thanique',
        lastName: 'Miller',
        email: 'thaniquemiller@yahoo.com',
        instagramProfile: {
          handle: '_than_sm',
          followers: 198,
          engagementRate: null
        },
        tiktokProfile: {
          handle: 'than_.sm',
          followers: 1833,
          engagementRate: 14.423416233362973
        },
        youtubeProfile: {
          handle: 'tmiller2233',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T13:04:38.000Z',
        amountCents: 4299
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '47015572-ce12-49d1-8f44-03d98927015c',
      content: [],
      creator: {
        id: 'a3e4edaf-de7a-4336-adc9-8a28e18bb3ea',
        firstName: 'Venel',
        lastName: 'Sanchez',
        email: 'venel38@gmail.com',
        instagramProfile: {
          handle: 'venneellll',
          followers: 1399,
          engagementRate: null
        },
        tiktokProfile: {
          handle: 'venelsan',
          followers: 2183,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'venelsanchez9952',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T13:26:37.000Z',
        amountCents: 5480
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'a96b34e6-8cd3-4d4b-b4cc-064deae01628',
      content: [],
      creator: {
        id: '93e2f7f2-fe8a-4bab-8b90-ff8b1305a409',
        firstName: 'Moiya',
        lastName: 'Washington',
        email: 'moiya60756@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'moiyskull',
          followers: 4641,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'moiyawashington933',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T13:36:22.000Z',
        amountCents: 3799
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '7bcb84e7-03e2-40a4-b76e-c3b0a1310688',
      content: [],
      creator: {
        id: '79638531-f0d1-4aae-a2a4-76c9cbd51d4a',
        firstName: 'Annalee',
        lastName: 'Gray',
        email: 'annaleegray819@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'multitalentedanna1',
          followers: 1651,
          engagementRate: 6.118143459915612
        },
        youtubeProfile: {
          handle: 'annaleegray3805',
          followers: 1,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T14:55:05.000Z',
        amountCents: 4654
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '31da0bb1-8819-456a-beae-826936483bcc',
      content: [],
      creator: {
        id: '11fd0363-78c6-4bcd-a459-9632132bf097',
        firstName: 'Ashanti',
        lastName: 'Grape',
        email: 'ashantiiid12@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'wiginfluencerash',
          followers: 3040,
          engagementRate: 4.216867469879518
        },
        youtubeProfile: {
          handle: 'ashantidouglas7661',
          followers: 1,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T15:24:59.000Z',
        amountCents: 3955
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '48efdafc-1f19-4a15-b09b-5c456d2c305a',
      content: [],
      creator: {
        id: 'b82e04e4-c754-4c9a-ae1d-4294259e3623',
        firstName: 'Shinnel',
        lastName: 'Sewell',
        email: 'nicenessnikki20@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'niceness56',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T15:53:02.000Z',
        amountCents: 7125
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'fca24e75-6106-4ba7-bf96-ca366a3045f3',
      content: [],
      creator: {
        id: '7ae8eda2-5f83-40ac-8393-efbdb482092c',
        firstName: 'Kayla',
        lastName: 'Cernosek',
        email: 'kayster212@yahoo.com',
        instagramProfile: {
          handle: 'oo.la.yum',
          followers: 1025,
          engagementRate: 3.463414634146342
        },
        tiktokProfile: {
          handle: 'oo.la.yum',
          followers: 273,
          engagementRate: 9.976798143851507
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T16:12:01.000Z',
        amountCents: 4654
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'd41a2b7b-86d9-4d37-af9f-679d1f0de666',
      content: [],
      creator: {
        id: '42289673-b611-4997-be2d-40a97c84bb06',
        firstName: 'Itzel',
        lastName: 'Romero',
        email: 'itzeeldayana@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'itzeel28',
          followers: 54,
          engagementRate: 2.4827024827024826
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T16:17:01.000Z',
        amountCents: 5682
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'f4c2dedd-c29e-4709-a7be-6c102f38b6f4',
      content: [],
      creator: {
        id: '5589c569-13f8-4ed1-9d67-9893ed325695',
        firstName: 'Peta-gaye',
        lastName: 'Whyte',
        email: 'petagayswhyte@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'petagayewhyte_',
          followers: 155,
          engagementRate: 4.2105263157894735
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T16:18:02.000Z',
        amountCents: 4500
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '1e337b04-6cc6-42e0-a2d4-f99f1a2f972e',
      content: [],
      creator: {
        id: 'acade888-2de0-4116-abe1-5def71894001',
        firstName: 'Lisa',
        lastName: 'Rayl',
        email: 'rayl101@comcast.net',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'lisarayl3',
          followers: 21,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T16:22:21.000Z',
        amountCents: 4556
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'c0cc89d8-3de8-439f-bd41-ceef90214632',
      content: [],
      creator: {
        id: '0a00fa70-f9ff-4ccb-8976-c8e966496bbb',
        firstName: 'Aciana ',
        lastName: 'Small ',
        email: 'jodymyboo123@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'jody0485',
          followers: 5,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T16:23:01.000Z',
        amountCents: 5823
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '640d68cd-aa30-468e-b8be-07d7f0c950c1',
      content: [],
      creator: {
        id: 'c16e22ba-2387-49de-8774-d7513c7e26d5',
        firstName: 'Michelle',
        lastName: 'Rhine',
        email: 'meshelleybop1@yahoo.com',
        instagramProfile: {
          handle: 'meshell1216',
          followers: 827,
          engagementRate: 1.3301088270858523
        },
        tiktokProfile: {
          handle: 'michellerhine8',
          followers: 29,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T16:23:59.000Z',
        amountCents: 4250
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'f3a4d52f-6c9d-4f2c-a8de-3cd2f0c2a439',
      content: [],
      creator: {
        id: 'afe68f2f-c000-4c79-baca-72c6a947158b',
        firstName: 'Tashane ',
        lastName: 'Easy',
        email: 'oraineeasy7@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'kreidcrcdbn',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T16:31:34.000Z',
        amountCents: 5000
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '824e6d72-0abf-4be9-aed6-b81c94bebdc1',
      content: [],
      creator: {
        id: 'e823c0a7-54d1-44c0-9f49-048de492f939',
        firstName: 'Chantelle',
        lastName: 'Morgan rcj',
        email: 'burgesschantellee876@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'mood87666',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T16:33:21.000Z',
        amountCents: 4350
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '51d0d662-5a90-488e-a098-3ebba17ab778',
      content: [],
      creator: {
        id: '6d579b83-1339-4e5d-8990-1e2efb339b0c',
        firstName: 'Sergio',
        lastName: 'Alcoleas',
        email: 'xeneize001@hotmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'sergioalcoleas',
          followers: 50,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T16:36:36.000Z',
        amountCents: 3955
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '5eb4ccad-4473-4117-8a92-00fd26769806',
      content: [],
      creator: {
        id: '2f3b4171-4d4f-40ee-ab07-04e119e79521',
        firstName: 'Sheranique',
        lastName: 'Cumberbatch',
        email: 'itssniquesss@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: '_.dimplez.__',
          followers: 2989,
          engagementRate: 7.6942160302650136
        },
        youtubeProfile: {
          handle: 'vibewithshero',
          followers: 314,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T16:38:15.000Z',
        amountCents: 4290
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'af52ca81-7501-4d90-8506-d9dacfe86c54',
      content: [],
      creator: {
        id: 'faee568e-0785-495e-84d0-749a4f24c140',
        firstName: 'Kellian',
        lastName: 'Gidden ',
        email: 'patricegidden@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'kellykhloe7',
          followers: 2,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T16:41:58.000Z',
        amountCents: 4500
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'ed94287b-bdf6-4f9a-9519-87e7806fd261',
      content: [],
      creator: {
        id: '3dfcc86d-75be-43ba-a027-778f52633eed',
        firstName: 'Vivian',
        lastName: 'Pena',
        email: 'vivianpena98@gmail.com',
        instagramProfile: {
          handle: 'vivianxpena',
          followers: 10489,
          engagementRate: 2.5625539257981016
        },
        tiktokProfile: {
          handle: 'abcdef_3214',
          followers: 9447,
          engagementRate: 0.7246376811594203
        },
        youtubeProfile: {
          handle: 'vivianpena8137',
          followers: 15,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T16:42:08.000Z',
        amountCents: 3955
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '4a454077-a43f-48c1-a433-46d37653460d',
      content: [],
      creator: {
        id: '795eb954-d2a6-4174-ba49-8ae24bf2ff9c',
        firstName: 'Ariel',
        lastName: 'Madison',
        email: 'cutesylettuce@outlook.com',
        instagramProfile: {
          handle: 'cutesylettuce',
          followers: 1318,
          engagementRate: 1.471927162367223
        },
        tiktokProfile: {
          handle: 'cutesylettuce',
          followers: 13699,
          engagementRate: 3.75
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T17:05:29.000Z',
        amountCents: 4567
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '8b248083-cd05-49bc-8c56-e05b69511650',
      content: [],
      creator: {
        id: 'b0bd871f-6dbe-4915-a9b8-4f0641b6fee1',
        firstName: 'Brandon',
        lastName: 'Andre Lamoth',
        email: 'pairs.scowls05@outlook.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'dy7pdzvelfb8',
          followers: 77,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T17:07:49.000Z',
        amountCents: 10000
      },
      status: 'INCOMPLETE',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'aa6b3198-a7a7-4f9b-9ca2-c015eec4e5b0',
      content: [],
      creator: {
        id: 'eab8eb35-02d4-42cb-b613-b1d8a0a1c06a',
        firstName: 'Alicia',
        lastName: 'Green',
        email: 'aliciagreen0702@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'alicignya27',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T17:09:59.000Z',
        amountCents: 5000
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '2447e3aa-ab50-44f7-9737-16ad79385b89',
      content: [],
      creator: {
        id: '10a2e2a0-2342-40a0-accd-2cf8723266c3',
        firstName: 'Reyna Jane',
        lastName: 'Cotcot',
        email: 'najencotcot@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'najenxxpochi',
          followers: 32,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'reynajanecotcot933',
          followers: 3,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T17:11:33.000Z',
        amountCents: 5710
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '348b212b-c163-4a80-ac4a-866af308da6e',
      content: [],
      creator: {
        id: '742d8256-f6a1-40a3-9761-e27310fe5290',
        firstName: 'Yashica',
        lastName: 'Mundle',
        email: 'chermom35@yahoo.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'enjay_toptier',
          followers: 1706,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'iga_dauta2661',
          followers: 3,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T17:22:23.000Z',
        amountCents: 4671
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'd5c33af7-0146-4754-8d28-3f28129bb3df',
      content: [],
      creator: {
        id: '9fe5f3fb-41e4-4c8d-afdb-f46ab313d077',
        firstName: 'Monika',
        lastName: 'Conrad',
        email: 'conrad.monika.4@gmail.com',
        instagramProfile: {
          handle: 'ayee_monn',
          followers: 881,
          engagementRate: null
        },
        tiktokProfile: {
          handle: 'liliconrad509',
          followers: 10,
          engagementRate: null
        },
        youtubeProfile: {
          handle: '18monikalili',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T17:31:05.000Z',
        amountCents: 4707
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'a82dd77a-e78f-45a5-9c79-46375035f47a',
      content: [],
      creator: {
        id: '06b6d896-b231-4738-a02e-0297f6896c79',
        firstName: 'Mo',
        lastName: 'Abou',
        email: 'appleyoutv@gmail.com',
        instagramProfile: {
          handle: 'igaconstruction',
          followers: 72,
          engagementRate: 0.7142857142857143
        },
        tiktokProfile: {
          handle: 'dragon.fruit.king6',
          followers: 0,
          engagementRate: 2.898550724637681
        },
        youtubeProfile: {
          handle: 'annaseuf715',
          followers: 3,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T17:36:37.000Z',
        amountCents: 4290
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'dcb51620-bf07-4d97-929e-1eb2daf5875e',
      content: [],
      creator: {
        id: '5fa7374d-3d15-4ff9-ae98-795ef0ee5f63',
        firstName: 'Nzinga Britney',
        lastName: 'Jackson',
        email: 'b45035598@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'they.luvv.tayy1',
          followers: 333,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T17:43:06.000Z',
        amountCents: 4299
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'e9094213-1ae5-4cbb-a233-231503be79ce',
      content: [],
      creator: {
        id: 'a0308faf-2568-44e6-ad94-99286d7f8642',
        firstName: 'Britany ',
        lastName: 'Whyte ',
        email: 'brittanywhyte2019@gmail.com',
        instagramProfile: {
          handle: 'briiis_kloset_',
          followers: 1781,
          engagementRate: 0.4941044357102751
        },
        tiktokProfile: {
          handle: 'britbraatt21',
          followers: 617,
          engagementRate: 11.986301369863012
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T18:24:01.000Z',
        amountCents: 2511
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '1295870f-3766-42f4-aa38-4664c639e40c',
      content: [],
      creator: {
        id: 'd7b3b987-654e-4e42-8bea-04af2306ab33',
        firstName: 'Roxanne',
        lastName: 'Reid',
        email: 'racquelrreid73@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'e.nna__',
          followers: 464,
          engagementRate: 8.655616942909761
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T18:24:35.000Z',
        amountCents: 4290
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '7e697802-f1fd-4c16-86d4-6649ab09abce',
      content: [],
      creator: {
        id: '2ecd3204-bacb-42bd-abbe-f08f453793c3',
        firstName: 'Tetiana',
        lastName: 'Vushtei ',
        email: 'vushtei@gmail.com',
        instagramProfile: {
          handle: 'pinksale_',
          followers: 1407,
          engagementRate: null
        },
        tiktokProfile: {
          handle: 'vushtei',
          followers: 11,
          engagementRate: 0.35608308605341243
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T18:29:25.000Z',
        amountCents: 4281
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'fce4a797-e577-4421-9cae-aca486ed494d',
      content: [],
      creator: {
        id: 'ffff51ad-9040-4f3c-b4e5-c05369970444',
        firstName: 'Rey',
        lastName: 'Danii',
        email: 'dendanisdan@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'byee1565',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T18:34:39.000Z',
        amountCents: 6711
      },
      status: 'INCOMPLETE',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'e4a546bc-82c6-44c5-986b-a09c6db7b9af',
      content: [],
      creator: {
        id: 'afd7d5b9-a9c9-4ea7-8b3a-b17ef1245245',
        firstName: 'Liz',
        lastName: 'Harvey',
        email: 'tlddharvey@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'listwithlizharvey',
          followers: 179,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T19:12:27.000Z',
        amountCents: 4331
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '99c4f625-169b-4479-bad9-b4e6833cd227',
      content: [],
      creator: {
        id: '34a9503c-327f-4a38-8cdd-e12b9740b528',
        firstName: 'Carlie',
        lastName: 'Mohr',
        email: 'mohrcarl1992@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'carbar1231',
          followers: 26,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T19:28:44.000Z',
        amountCents: 5174
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '12183dac-289b-4553-82d5-b63531ed454b',
      content: [],
      creator: {
        id: '3e27bdc3-92bd-46f1-9ee8-addf7f0988d5',
        firstName: 'Ashekia',
        lastName: 'Hunter',
        email: 'shenereebarieffe@yahoo.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'huzbae',
          followers: 9,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T19:37:08.000Z',
        amountCents: 3955
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'ebb9b7bd-906b-4ffe-a29a-a367874fd0de',
      content: [],
      creator: {
        id: '11c9f29e-e7e8-4ede-b507-cf592d1a6d16',
        firstName: 'Rodroy',
        lastName: 'Graham',
        email: 'j.mcfarlane37@yahoo.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'grahavhl9x3',
          followers: 1,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T19:56:01.000Z',
        amountCents: 4905
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'eb0c5576-f6dd-4004-899f-0f3ad08e01d5',
      content: [],
      creator: {
        id: 'b9b5cff7-d76c-46a7-810c-1416a1a91228',
        firstName: 'Sherika',
        lastName: 'Morgan Clarke',
        email: 'morganclarkesherika@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'sheriimorganclarke',
          followers: 10810,
          engagementRate: 6.575576430401367
        },
        youtubeProfile: {
          handle: 'sherikamorgan-clarke',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T20:03:03.000Z',
        amountCents: 3807
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '16c6dd1c-4683-42c8-82d4-2336c933f65c',
      content: [],
      creator: {
        id: 'ee5429c6-12bb-4c6f-83a0-05c6b8fc8480',
        firstName: 'Georgiana',
        lastName: 'Anderson',
        email: 'georgiana.anderson340@gmail.com',
        instagramProfile: {
          handle: 'kiimm.__',
          followers: 1771,
          engagementRate: 0.7905138339920948
        },
        tiktokProfile: {
          handle: 'kimja459',
          followers: 1091,
          engagementRate: 3.30188679245283
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T20:44:38.000Z',
        amountCents: 3955
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '67cb70ac-b279-4b79-9c93-2f0df353df76',
      content: [],
      creator: {
        id: 'b26edff6-bc7b-4065-8a20-3566a24fbcf4',
        firstName: 'Amy',
        lastName: 'Bentley-Clodfelter',
        email: 'soccer2004_mom@yahoo.com',
        instagramProfile: {
          handle: 'abcbeefarm',
          followers: 35,
          engagementRate: 0.2777777777777778
        },
        tiktokProfile: {
          handle: 'corrin19970',
          followers: 11,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T21:03:58.000Z',
        amountCents: 4589
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '61a07b9a-e72e-4a36-be52-9a5420bdb00b',
      content: [],
      creator: {
        id: 'd5b267d5-1b7b-4d18-b54e-bd83446c2129',
        firstName: 'Crissy',
        lastName: 'Gayle',
        email: 'ranchris256@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'orandy12',
          followers: 20,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'rjay25fenlater76',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T21:23:58.000Z',
        amountCents: 7264
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '0f814e64-1022-4a44-8f0d-3fbd0b03df1a',
      content: [],
      creator: {
        id: '5c4c8ee1-d1a7-46b1-8645-91833f6525a8',
        firstName: 'Tracey',
        lastName: 'Reynolds',
        email: 'traceylreyn@gmail.com',
        instagramProfile: {
          handle: 'misbehaving_tl',
          followers: 722,
          engagementRate: 1.5433320142461415
        },
        tiktokProfile: {
          handle: 'misbehavingtl',
          followers: 1407,
          engagementRate: 10.869565217391305
        },
        youtubeProfile: {
          handle: 'youngyalqueen',
          followers: 5,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T21:31:35.000Z',
        amountCents: 4599
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '8f54e3d7-6f58-42a7-bd44-13b3a7748f2d',
      content: [],
      creator: {
        id: '6c3f6e25-ea22-4c59-b365-43bddd4d09ca',
        firstName: 'Doneille',
        lastName: 'Higginsocean',
        email: 'doneilleh@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'doneilleloko',
          followers: 3453,
          engagementRate: 2.2708674304418985
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T22:02:30.000Z',
        amountCents: 4061
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '9176f194-a231-4897-8756-84c1bbc1a103',
      content: [],
      creator: {
        id: '8e66319c-5221-4a0f-9dc2-cdbd804da4fe',
        firstName: 'Monecia',
        lastName: 'Pinnock',
        email: 'gwendolyncoxx@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'mopinn7',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T22:51:08.000Z',
        amountCents: 7077
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '5dacb78b-674f-4000-9c54-eed9a0f21735',
      content: [],
      creator: {
        id: '509a6824-b800-465f-95b2-4e11e1d64272',
        firstName: 'Miranda',
        lastName: 'Stutzman',
        email: 'mirandastutzman@yahoo.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'tuna.on.rye',
          followers: 1,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T23:12:46.000Z',
        amountCents: 5485
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'd9804c74-8254-42e1-902e-675c6f31ccc7',
      content: [],
      creator: {
        id: '4e5317aa-082e-467a-9c7a-db3e690aa0ec',
        firstName: 'Destanie',
        lastName: 'Edwards',
        email: 'dsternadel2017@yahoo.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'destaniebrienneedwards',
          followers: 248,
          engagementRate: 11.093966635359363
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-21T23:55:10.000Z',
        amountCents: 4222
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '1265d429-77be-4109-86ec-d612b5626334',
      content: [],
      creator: {
        id: '1546a1ff-a78c-4cb3-9e97-17fb96a3fa79',
        firstName: 'NicKeisha',
        lastName: 'Brown',
        email: 'nickeishabrown32@yahoo.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'nickii1458',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-22T00:02:58.000Z',
        amountCents: 7478
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'ed86fdbb-6377-40fa-b56c-1f34586122e3',
      content: [],
      creator: {
        id: '57ed4cac-37d1-4142-8d32-5037b3a5d08b',
        firstName: 'Leshon',
        lastName: 'Mattis',
        email: 'leshonmattis@yahoo.com',
        instagramProfile: {
          handle: 'zoeymattis123',
          followers: 6,
          engagementRate: null
        },
        tiktokProfile: {
          handle: 'leshyboo2023',
          followers: 19,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'donnamattis457',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-22T00:26:26.000Z',
        amountCents: 5313
      },
      status: 'INCOMPLETE',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'c8a5df38-1cb9-4d84-84bd-1c2903eba805',
      content: [],
      creator: {
        id: '49a05c5c-ef38-49db-b5de-0635912a2e37',
        firstName: 'Keneshia',
        lastName: 'Cole',
        email: 'kennifrass@yahoo.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'kenlu986',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-22T00:34:24.000Z',
        amountCents: 4358
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'ab62956c-7345-4b95-b855-a651e2cb8028',
      content: [],
      creator: {
        id: 'cc1605f3-61e7-4e86-8f88-1f72ab8e58d2',
        firstName: 'Martin',
        lastName: 'Charlton',
        email: 'martintajj23@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'martiligobg',
          followers: 2,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-22T01:30:01.000Z',
        amountCents: 1486
      },
      status: 'INCOMPLETE',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'fed56be1-31ec-4109-8e64-08042294360d',
      content: [],
      creator: {
        id: 'b9e0685c-a9d6-482f-8b9e-57c7795b4dad',
        firstName: 'Shantoya',
        lastName: 'Gapour',
        email: 'ssgapour@hotmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'shantoyagapour',
          followers: 37,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-22T04:43:53.000Z',
        amountCents: 7672
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '237f1bfd-f973-4a12-acb2-6c2719f1527e',
      content: [],
      creator: {
        id: '75938bd9-6415-4a94-97fe-5b4a38659e09',
        firstName: 'Ashley',
        lastName: 'Blake',
        email: 'ashleyblake5840@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'ashleyblake5840',
          followers: 1366,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-22T05:34:29.000Z',
        amountCents: 3955
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '9fba4066-b375-4dc0-b717-4e2cb614628b',
      content: [],
      creator: {
        id: '94e54f51-23f3-4a92-8759-3c4d69092071',
        firstName: 'Gilbert',
        lastName: 'Perez',
        email: 'gilbertrperez_81@yahoo.com',
        instagramProfile: {
          handle: 'grperez81',
          followers: 131,
          engagementRate: null
        },
        tiktokProfile: null,
        youtubeProfile: {
          handle: 'gilbertperez-uo9mi',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-22T06:54:56.000Z',
        amountCents: 5410
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '1023650a-1f97-4aa6-ab78-97e72d5ebeb7',
      content: [],
      creator: {
        id: '8e16e250-46f6-4c17-8ff3-f2e63944540e',
        firstName: 'Daneeila',
        lastName: 'Jackson',
        email: 'daneeilajackson05@gmail.com',
        instagramProfile: {
          handle: 'tishana_collection_',
          followers: 133,
          engagementRate: 2.2556390977443606
        },
        tiktokProfile: {
          handle: 'slimmazz_17',
          followers: 3754,
          engagementRate: 8.120121204824112
        },
        youtubeProfile: {
          handle: 'vibewithdanii',
          followers: 22,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-22T08:14:51.000Z',
        amountCents: 3955
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '1508f500-c252-4ca3-95fa-f2596cacc414',
      content: [],
      creator: {
        id: 'a800b07b-976c-4ced-ac7a-95a690b9b9e2',
        firstName: 'Deborah',
        lastName: 'Roberts',
        email: 'robertsdeborah45@gmail.com',
        instagramProfile: {
          handle: '__deborah_bee',
          followers: 1845,
          engagementRate: 26.395663956639563
        },
        tiktokProfile: {
          handle: 'vibewithdebbie',
          followers: 6235,
          engagementRate: 7.716589852456658
        },
        youtubeProfile: {
          handle: 'vibewithdebbie594',
          followers: 5,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-22T08:54:22.000Z',
        amountCents: 3799
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '80677243-4c47-471f-807b-a082e6e348e8',
      content: [],
      creator: {
        id: 'd5635d67-a5ae-452b-8663-e4b24cc48ee9',
        firstName: 'Dayton',
        lastName: 'Wilson',
        email: 'wilsondayton1987@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'bornaking87',
          followers: 343,
          engagementRate: 5.647840531561462
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-22T08:54:31.000Z',
        amountCents: 4299
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '3f2484f9-f3ae-4d11-b9f2-6a6deecc7b25',
      content: [],
      creator: {
        id: 'fbceade7-dffc-40c1-9f2e-662554dab1d9',
        firstName: 'Olga',
        lastName: 'Hey',
        email: 'heyolga333@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'user9832235881951',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-22T08:55:03.000Z',
        amountCents: 5144
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '5c54236d-6511-4225-a396-ee00c84eb031',
      content: [],
      creator: {
        id: '44a1f23a-0e88-4580-ad4f-43757b6ae27f',
        firstName: 'Marina',
        lastName: 'Goly',
        email: 'marina0308@mail.ru',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'user1079824523519',
          followers: 9,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'user-rh8fh6lh3n',
          followers: 267,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-22T08:55:08.000Z',
        amountCents: 5474
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '2a47bb6e-f0ed-4eca-96dc-a53b9eca15bc',
      content: [],
      creator: {
        id: '8a50d978-7c4d-4e4c-8586-38532c717ae0',
        firstName: 'Aciana',
        lastName: 'Small',
        email: 'shamlari173@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'sham3650',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-22T10:36:59.000Z',
        amountCents: 2913
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'e80c4ed5-20e6-4e95-be14-bd00b0a302c2',
      content: [],
      creator: {
        id: '8a35c48b-95bf-4214-bc04-7f0ddf6befe7',
        firstName: 'Catrina',
        lastName: 'Dickens',
        email: 'catrinad71@gmail.com',
        instagramProfile: {
          handle: 'catd71',
          followers: 995,
          engagementRate: 0.8040201005025126
        },
        tiktokProfile: {
          handle: 'catrinaa.dickens',
          followers: 142,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'catrinadickens9914',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-22T11:45:25.000Z',
        amountCents: 4252
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'a25aa283-8607-4b4b-bf45-e36202ac0c6a',
      content: [],
      creator: {
        id: '66b306f1-6b46-4a25-a5fc-5362d0891d0e',
        firstName: 'Tiffany',
        lastName: 'Johnson',
        email: 'tiffanypen74@gmail.com',
        instagramProfile: {
          handle: 'ts_sparkling_coochie_clenz',
          followers: 168,
          engagementRate: 3.7426900584795324
        },
        tiktokProfile: {
          handle: 'tiffanynation70',
          followers: 1761,
          engagementRate: 8.11552375919678
        },
        youtubeProfile: {
          handle: 'tiffanynation424',
          followers: 700,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-22T13:43:08.000Z',
        amountCents: 4299
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '4a2aed4c-b823-4b8f-8ffe-265c61473511',
      content: [],
      creator: {
        id: '1802789a-2c55-4e04-aed2-32d3e87848c9',
        firstName: 'Tiffany',
        lastName: 'Johnson',
        email: 'tobyanndavis@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'toby8553',
          followers: 22,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'tobyanndavis',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-22T13:43:19.000Z',
        amountCents: 4295
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'd62564b2-781a-4627-975a-78938baa5b6d',
      content: [],
      creator: {
        id: '1d613c6c-6fcc-4e94-8f8a-d52d29c5b8d8',
        firstName: 'Kemorla ',
        lastName: 'Lewin',
        email: 'Christopherwright1573@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'avi_xtreemfiah',
          followers: 222,
          engagementRate: 8.123711340206185
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-22T14:13:57.000Z',
        amountCents: 5569
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'c4f86e25-b504-462f-8036-6e1f321fd2e6',
      content: [],
      creator: {
        id: '7ff701d2-323f-4750-8f75-c9ac6515b233',
        firstName: 'Tara',
        lastName: 'Mary',
        email: 'beep08.acrylic@icloud.com',
        instagramProfile: {
          handle: 'product.reviews.by.tm',
          followers: 0,
          engagementRate: 0
        },
        tiktokProfile: null,
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-22T14:30:23.000Z',
        amountCents: 4998
      },
      status: 'INCOMPLETE',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '9a8f62f1-e3c7-4e5c-8f3c-57629bda38ae',
      content: [],
      creator: {
        id: '29fa7cf3-9723-4050-b8b2-8bb7f9e1b8b6',
        firstName: 'Lavancha',
        lastName: 'Gordon',
        email: 'lovelycream81@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'laviidoll',
          followers: 147,
          engagementRate: 8.190476190476192
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-22T15:44:46.000Z',
        amountCents: 4295
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'f95cdcda-5afc-4004-a545-caa88ba3fe73',
      content: [],
      creator: {
        id: 'd91820f0-474f-463b-a837-ee178ed3f019',
        firstName: 'Janeth Ann',
        lastName: 'Rotaquio',
        email: 'rjanethann@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'janethxxii',
          followers: 103,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'janethannrotaquio9331',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-22T16:11:52.000Z',
        amountCents: 5776
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '4b8fbcd4-82cc-4fb8-a94d-1afdc0265a8a',
      content: [],
      creator: {
        id: '30954c90-bb71-484d-9a21-8936d146de7f',
        firstName: 'Aciana ',
        lastName: 'Small ',
        email: 'smallaciana346@gmail.com',
        instagramProfile: {
          handle: 'sham1238757',
          followers: 2,
          engagementRate: null
        },
        tiktokProfile: {
          handle: 'sham52914',
          followers: 1,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-22T16:14:27.000Z',
        amountCents: 11269
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'b0c55383-58db-4ec6-a5aa-172631c72e27',
      content: [],
      creator: {
        id: '455eb035-2215-4e89-86b0-9ab2d33ca103',
        firstName: 'Shemeika',
        lastName: 'Stewart',
        email: 'tatistewy@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'shemeika_',
          followers: 2551,
          engagementRate: 5.441851602860235
        },
        youtubeProfile: {
          handle: 'shemeikaaofficial',
          followers: 5,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-22T16:19:40.000Z',
        amountCents: 4299
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '3b1c9b5b-0e0f-4d00-b617-cec0e564eb5f',
      content: [],
      creator: {
        id: 'f141031e-33fb-4fa9-8a9d-12a380fa4575',
        firstName: 'Africka',
        lastName: 'McDonald',
        email: 'stonewall_africka@yahoo.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'queenafricka',
          followers: 17606,
          engagementRate: 17.719568567026194
        },
        youtubeProfile: {
          handle: 'queen_africka',
          followers: 29,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-22T16:26:31.000Z',
        amountCents: 3955
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '27240cf2-2f89-4133-b4ea-d6ca79bf8fcd',
      content: [],
      creator: {
        id: '9cf7261d-2463-44b3-b038-b8cc4b104b46',
        firstName: 'Tiffany',
        lastName: 'Langrin',
        email: 'tashagaylangrin@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'tashagay51',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-22T16:28:53.000Z',
        amountCents: 4500
      },
      status: 'INCOMPLETE',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '0d5fbe0d-21a4-45ba-8003-7ccdb3bb5da5',
      content: [],
      creator: {
        id: '641363e1-44ac-409f-92a9-e06a54c7c7ab',
        firstName: 'Chiss',
        lastName: 'Batista',
        email: 'chissrachel15@gmail.com',
        instagramProfile: {
          handle: 'chissbatista',
          followers: 871,
          engagementRate: 6.486796785304248
        },
        tiktokProfile: {
          handle: 'chissrachel15',
          followers: 2478,
          engagementRate: 6.896551724137931
        },
        youtubeProfile: {
          handle: 'chissbatista1551',
          followers: 4,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-22T16:29:52.000Z',
        amountCents: 4299
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'bb379adf-9a27-4de9-8454-38fc38fd0045',
      content: [],
      creator: {
        id: '6a1efaa7-3557-44ef-9f3f-d07a53da3ff6',
        firstName: 'Angelee',
        lastName: 'Morrison',
        email: 'theonlyangelee@gmail.com',
        instagramProfile: {
          handle: 'theeangelee',
          followers: 1233,
          engagementRate: null
        },
        tiktokProfile: {
          handle: 'theeangelee',
          followers: 346,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-22T16:36:49.000Z',
        amountCents: 5287
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '8a94d874-18dd-4898-b9d7-b3e67a483d64',
      content: [],
      creator: {
        id: 'b0a81006-28c1-4e2b-8b33-42140c579f3a',
        firstName: 'Dareo',
        lastName: 'Cunningham',
        email: 'dareocad@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'dareo112211',
          followers: 1443,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'dareoc.',
          followers: 40,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-22T16:44:44.000Z',
        amountCents: 5596
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'b79cc326-b02d-4d65-8882-21265a0856a3',
      content: [],
      creator: {
        id: '59040079-edc3-452c-bd6f-646b475e049f',
        firstName: 'Jahset',
        lastName: 'Palmer',
        email: 'jahvontaebrumley9@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'jahsetpalmer',
          followers: 240,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'jahsetpalmer1862',
          followers: 1,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-22T16:57:43.000Z',
        amountCents: 4295
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'd40b4d1c-884e-4b19-92e4-8a2ad1796021',
      content: [],
      creator: {
        id: 'fe62113e-9b39-406a-a70f-5c0048dc5b6f',
        firstName: 'Lori',
        lastName: 'Vajgrt',
        email: 'lorivajgrt@icloud.com',
        instagramProfile: {
          handle: 'lorivajgrt',
          followers: 159,
          engagementRate: null
        },
        tiktokProfile: {
          handle: 'lollie50158',
          followers: 4334,
          engagementRate: 8.143939393939394
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-22T17:48:28.000Z',
        amountCents: 4137
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'bba0cee6-2f03-49e9-a9a9-b496707d1e50',
      content: [],
      creator: {
        id: '5549cb57-1918-4e24-9222-dccfe2acf0bf',
        firstName: 'Doris',
        lastName: 'Scales',
        email: 'angel333deedee@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'angel333deedee',
          followers: 18,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'deedeescales7754',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-22T18:04:15.000Z',
        amountCents: 4186
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'eee118d9-b004-4d88-b744-e1f25e636403',
      content: [],
      creator: {
        id: 'b8429ebf-c07f-4788-a418-9ed074ccab13',
        firstName: 'Carlee',
        lastName: 'Gerren',
        email: 'carleegerren1993@gmail.com',
        instagramProfile: {
          handle: 'carleegerren',
          followers: 28,
          engagementRate: 0
        },
        tiktokProfile: {
          handle: 'carleelynn',
          followers: 470,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'carleegerren3019',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-22T18:06:18.000Z',
        amountCents: 4641
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '711d5907-4229-413a-a2e6-a67a74ae9815',
      content: [],
      creator: {
        id: 'cf6970fb-67bd-4755-a3e0-c36ba86fac2d',
        firstName: 'Linda',
        lastName: 'Smart',
        email: 'foreverblessedcookies@hotmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'foreverblessedcookies',
          followers: 18,
          engagementRate: 4.6875
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-22T18:55:52.000Z',
        amountCents: 4176
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '90cbf319-2575-4483-88be-d25668edacaa',
      content: [],
      creator: {
        id: 'b6be1d88-fea8-463c-9361-0a9e4e0d9ea4',
        firstName: 'Jennifer',
        lastName: 'Potter',
        email: 'rockit_29@yahoo.com',
        instagramProfile: {
          handle: 'rockit.29',
          followers: 63,
          engagementRate: 1.8518518518518516
        },
        tiktokProfile: {
          handle: 'rockit.29',
          followers: 1,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'jenniferpotter9862',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-22T19:19:19.000Z',
        amountCents: 4595
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'bb6a18fe-f1f0-4762-b536-aa4759da0e3b',
      content: [],
      creator: {
        id: '524de050-ec59-4279-aaec-3b250bcfc261',
        firstName: 'Aliah',
        lastName: 'Johnson',
        email: 'aliahboo321@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'shan_foodie',
          followers: 1004,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-22T19:29:42.000Z',
        amountCents: 4500
      },
      status: 'INCOMPLETE',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '39f67235-9e27-4d4f-b916-631a48199de5',
      content: [],
      creator: {
        id: '1c902d53-c0c6-43f1-b872-1c4c0d1d3bde',
        firstName: 'Tina',
        lastName: 'Wang',
        email: 'hello.tinawang@gmail.com',
        instagramProfile: {
          handle: 'teaa.nuuh',
          followers: 1571,
          engagementRate: 4.659452577975812
        },
        tiktokProfile: {
          handle: 'teaa.nuuh',
          followers: 105,
          engagementRate: 8.4260106091092
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-22T19:51:25.000Z',
        amountCents: 3903
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '7bc61267-e234-434e-8d75-f80a58c0b685',
      content: [],
      creator: {
        id: 'ad85508c-2927-48bf-9e0b-b21fc36e6fc8',
        firstName: 'Shanoy',
        lastName: 'Gordon',
        email: 'shanoycassells@gmail.com',
        instagramProfile: {
          handle: 'reviews_withsweets',
          followers: 54,
          engagementRate: null
        },
        tiktokProfile: null,
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-22T21:25:46.000Z',
        amountCents: 4596
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '50dcde0e-c649-44d1-9511-070febaf7408',
      content: [],
      creator: {
        id: '84ce9939-33d8-4a25-b34f-d7f62f6bb8a6',
        firstName: 'Nitisha',
        lastName: 'Kharel',
        email: 'nitishakharel34@gmail.com',
        instagramProfile: {
          handle: 'reviewsbyniti',
          followers: 357,
          engagementRate: 7.086834733893557
        },
        tiktokProfile: {
          handle: 'niti_k_',
          followers: 1180,
          engagementRate: 31.63265306122449
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-22T21:42:18.000Z',
        amountCents: 4929
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'de0232eb-cd0c-4fbb-9807-0ffeb092bc7a',
      content: [],
      creator: {
        id: 'ee919794-6bed-44c3-b5f9-549f1490cb3b',
        firstName: 'Sarah',
        lastName: 'Cunningham',
        email: 'cunninghamsa12@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'sarah.cun31',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-23T00:05:55.000Z',
        amountCents: 7875
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '66c6ca57-3a13-459e-aafa-8aad406d77df',
      content: [],
      creator: {
        id: '9ccd69ad-402c-464f-bb91-834556791998',
        firstName: 'Marcia ',
        lastName: 'Smith ',
        email: 'sherlloyd33@yahoo.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'smith.marcia',
          followers: 12680,
          engagementRate: 23.02855844254223
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-23T00:46:09.000Z',
        amountCents: 3614
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '7850674e-eeb4-4bfc-923e-6eb611c1bf9c',
      content: [],
      creator: {
        id: '8e80b273-5753-4e1f-bd4b-316fe355bebf',
        firstName: 'Jennell',
        lastName: 'Moodie',
        email: 'moodiejennell4@gmail.com',
        instagramProfile: {
          handle: 'jen_humble_',
          followers: 944,
          engagementRate: null
        },
        tiktokProfile: {
          handle: 'jennellmoodie',
          followers: 192,
          engagementRate: 8.547008547008547
        },
        youtubeProfile: {
          handle: 'jennellmoodie9521',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-23T01:24:27.000Z',
        amountCents: 4295
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '9938a5ef-5716-4974-907d-ace1ba85c61a',
      content: [],
      creator: {
        id: 'bf1030c7-ceed-4ca8-99bb-c743384c409f',
        firstName: 'Tersha',
        lastName: 'Palmer',
        email: 'bijoux_dekhy@yahoo.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'bijouxdekhy',
          followers: 450,
          engagementRate: 14.202272646819358
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-23T01:30:46.000Z',
        amountCents: 8500
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'c7305548-3325-4ed8-acf9-cb446bf8d1fe',
      content: [],
      creator: {
        id: 'd36e5783-ace4-4339-aa0f-661005b166b3',
        firstName: 'Lara',
        lastName: 'Hallmark',
        email: 'laraelisa334@yahoo.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'larahallmark',
          followers: 5262,
          engagementRate: 13.148234926585442
        },
        youtubeProfile: {
          handle: 'larahallmark3727',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-23T01:55:28.000Z',
        amountCents: 4215
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '9108d03a-305a-4ffb-85d9-4575bbceb016',
      content: [],
      creator: {
        id: 'fffcf5e4-e499-470c-acc4-d4a26e77cac0',
        firstName: 'Paula',
        lastName: 'White',
        email: 'paulawhite246@gmail.com',
        instagramProfile: {
          handle: 'royaltys_hair_1',
          followers: 27,
          engagementRate: null
        },
        tiktokProfile: {
          handle: 'britishwhite246',
          followers: 4834,
          engagementRate: 9.86361625110415
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-23T04:34:26.000Z',
        amountCents: 4994
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '8db5554f-3205-4625-909d-4b16c54d7767',
      content: [],
      creator: {
        id: '2cdc31cb-0e42-44b6-96bb-9c2d7378aa0c',
        firstName: 'Mandy-Ann',
        lastName: 'Murray',
        email: 'mandyannmurray33@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: '__qveen_mandy',
          followers: 641,
          engagementRate: 13.705583756345177
        },
        youtubeProfile: {
          handle: 'mandyannmurray7906',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-23T08:12:42.000Z',
        amountCents: 4298
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'd6d67936-3957-4b64-a15e-76c714dde65a',
      content: [],
      creator: {
        id: '4c1ee1ab-80d9-4127-95b6-e80203b2114e',
        firstName: 'Stephanie',
        lastName: 'Jones',
        email: 'sj615080@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'stephii_unique',
          followers: 1010,
          engagementRate: 9.7799511002445
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-23T09:14:23.000Z',
        amountCents: 4295
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '05aa4c6d-7a13-4151-a9b4-1ad84fbc6e4c',
      content: [],
      creator: {
        id: 'd97b1309-8c2c-4f27-ad2e-bd48dedef823',
        firstName: 'Sherika',
        lastName: 'Brown',
        email: 'sherikaflemming@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'chiiney._',
          followers: 2336,
          engagementRate: 10.471204188481675
        },
        youtubeProfile: {
          handle: 'sherikabrown616',
          followers: 3,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-23T14:15:52.000Z',
        amountCents: 5349
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'e260ca41-77b2-4809-a44e-ece6c7ac125d',
      content: [],
      creator: {
        id: 'aa395163-5ddb-42aa-8bef-d9032f50679b',
        firstName: 'Paul',
        lastName: 'Wang',
        email: 'wangpokai@yahoo.com.tw',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'yoyowang22',
          followers: 70,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-23T16:10:53.000Z',
        amountCents: 5810
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'bfd01ebb-1d04-416b-9d60-2fe7cc9832c9',
      content: [],
      creator: {
        id: 'f2128105-bb35-4632-9d35-d15b6dcf30a1',
        firstName: 'Licia',
        lastName: 'Skyers',
        email: 'skyerslicia@gmail.com',
        instagramProfile: {
          handle: 'lightbeing1224',
          followers: 1975,
          engagementRate: 1.139240506329114
        },
        tiktokProfile: {
          handle: 'lightbeing90',
          followers: 2308,
          engagementRate: 13.131313131313133
        },
        youtubeProfile: {
          handle: 'liciaskyers1377',
          followers: 6,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-23T16:17:04.000Z',
        amountCents: 5278
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '56c21c44-7c56-460a-9233-802d5192b2e2',
      content: [],
      creator: {
        id: '145ce4fc-bb0a-49c8-aa73-9a6e98a2794b',
        firstName: 'Tasha',
        lastName: 'Morgan',
        email: 'tl_layton@hotmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'tmorgan852',
          followers: 1,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-23T16:18:17.000Z',
        amountCents: 4299
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '9c8b9819-bb61-46eb-a93a-afd3a352d918',
      content: [],
      creator: {
        id: '11c75f2c-9867-482b-ac50-4ca14fb3f5dd',
        firstName: 'Shereece',
        lastName: 'Francis',
        email: 'francis.shereece@icloud.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'ree_cy',
          followers: 1006,
          engagementRate: 16.988950276243095
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-23T16:19:24.000Z',
        amountCents: 4500
      },
      status: 'INCOMPLETE',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'cadce112-c2ab-4119-b1f5-4748c01cf55b',
      content: [],
      creator: {
        id: '626b1201-a695-452f-ae96-6da0002ffc74',
        firstName: 'Sage',
        lastName: 'Hernandez ',
        email: 'radlifevibes@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'sage.hernandez2',
          followers: 1,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'jessicahernandez-vp2fe',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-23T16:29:01.000Z',
        amountCents: 4707
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'bc29efde-f720-4eab-b95c-c933c209b1cc',
      content: [],
      creator: {
        id: 'cc2eafcb-2862-4404-976c-f5037cee0eea',
        firstName: 'Xo',
        lastName: 'Alsian',
        email: 'altemp440@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'xo_alsian',
          followers: 429,
          engagementRate: 20.28985507246377
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-23T16:35:11.000Z',
        amountCents: 3903
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'd1fccbd1-a651-440a-80fa-65f4268da4c2',
      content: [],
      creator: {
        id: '437720eb-7a34-4d62-a4d3-63f715daf905',
        firstName: 'Buffy',
        lastName: 'Ough',
        email: 'mrsgrinchough@hotmail.com',
        instagramProfile: {
          handle: 'foreverblessedcookies',
          followers: 193,
          engagementRate: 1.5083798882681565
        },
        tiktokProfile: {
          handle: 'buffyough',
          followers: 119,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-23T16:37:05.000Z',
        amountCents: 4205
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'a9e88ade-35c9-4638-b54a-9c0c45f94a54',
      content: [],
      creator: {
        id: '80ea6b62-21cf-4cfe-89be-4ef9cbe4b9a7',
        firstName: 'Jamile',
        lastName: 'Dunn',
        email: 'bucklerenjay5@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'kruffs.original',
          followers: 1198,
          engagementRate: 4.316546762589928
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-23T16:40:25.000Z',
        amountCents: 4998
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '86bb87ba-7d7a-4509-84ea-8a9171a4f95f',
      content: [],
      creator: {
        id: '34718c9d-3e98-467b-b768-ba4ea2e4f7e5',
        firstName: 'Carlene',
        lastName: 'Kent',
        email: 'carlenekent24@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'god_bless_yan',
          followers: 15011,
          engagementRate: 8.14319968359124
        },
        youtubeProfile: {
          handle: 'carlenekent3919',
          followers: 4,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-23T17:03:28.000Z',
        amountCents: 5952
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'ba306321-f400-43aa-b238-25f585477ed6',
      content: [],
      creator: {
        id: '40bcb994-3ea8-402a-9a44-a14f3f8dff8b',
        firstName: 'Shanea',
        lastName: 'Fearon',
        email: 'shaneafearon@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'shaneafearon',
          followers: 4992,
          engagementRate: 16.28065267365942
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-23T17:06:30.000Z',
        amountCents: 4249
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '4ccbd82d-c2ff-46ac-a271-2a2c72e64b52',
      content: [],
      creator: {
        id: 'a698f9be-c985-45e0-b15a-3dac5c4a2fb6',
        firstName: 'Kerrian',
        lastName: 'Kerridge',
        email: 'kkerridge9@gmail.com',
        instagramProfile: {
          handle: 'godchild_kerry',
          followers: 538,
          engagementRate: 0
        },
        tiktokProfile: {
          handle: 'kerrykay08',
          followers: 1171,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-23T17:21:24.000Z',
        amountCents: 4295
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '33084777-a88a-475b-aa29-d686ffeafe97',
      content: [],
      creator: {
        id: 'd460947d-743a-4b3d-b98d-bb59eff5d80f',
        firstName: 'Enarria',
        lastName: 'Rollins',
        email: 'erollins18@yahoo.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'cay_dab',
          followers: 171,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'cay_dab',
          followers: 8,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-23T17:24:56.000Z',
        amountCents: 4274
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'bea3b38a-a750-4c28-877c-ea83844aca66',
      content: [],
      creator: {
        id: 'b78e2136-0583-4012-80dc-778a7f21b4e4',
        firstName: 'Camoy',
        lastName: 'Williams',
        email: 'camoychrisanwilliams@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'c.a.m.o.y',
          followers: 1401,
          engagementRate: 10.99476439790576
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-23T17:39:30.000Z',
        amountCents: 5298
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '09382642-e3b5-46e7-b22d-a314efbc8680',
      content: [],
      creator: {
        id: '9584507b-6b01-4ec3-a6e5-219eb20ea3e6',
        firstName: 'Kershia',
        lastName: 'Joseph',
        email: 'kershiajoseph@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'shiaaababyy',
          followers: 102,
          engagementRate: 8.235294117647058
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-23T19:12:39.000Z',
        amountCents: 4994
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'ba835c6a-4b4a-47d5-81e9-a250901b6d22',
      content: [],
      creator: {
        id: '2c30d208-7936-423e-ab86-349e3577ded3',
        firstName: 'Brittany ',
        lastName: 'Beecher',
        email: 'brittaney182@gmail.com',
        instagramProfile: {
          handle: 'rxnae.__',
          followers: 0,
          engagementRate: null
        },
        tiktokProfile: {
          handle: 'brii.nae',
          followers: 82,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-23T19:42:24.000Z',
        amountCents: 4924
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '2a7f4c78-4246-4f2c-87e3-5de84edadfa4',
      content: [],
      creator: {
        id: 'd6ccf143-b4b4-4ea7-a81a-4943295f8a56',
        firstName: 'Dion',
        lastName: 'Mckoy Gordon',
        email: 'dionveneshamckoy1@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'dionvmckoy',
          followers: 1061,
          engagementRate: 4.912280701754386
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-23T19:54:06.000Z',
        amountCents: 4299
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'b8611627-939e-4093-912d-25e1faa9b37b',
      content: [],
      creator: {
        id: '32e8f36c-6858-4fd7-8d41-0c494575ac64',
        firstName: 'Kimmona',
        lastName: 'Moffat',
        email: 'unrulykim13@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'boss_lady.kim',
          followers: 157,
          engagementRate: 14.893617021276595
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-23T20:22:44.000Z',
        amountCents: 4994
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '04df11db-d60a-45ca-aaca-d7faf0c5edd8',
      content: [],
      creator: {
        id: '5fecb676-a27b-4b2b-b91a-6c809cb919c0',
        firstName: 'Cristy',
        lastName: 'Almonte',
        email: 'cristyjalmonte@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'thecrunchpot',
          followers: 10184,
          engagementRate: 14.377138004319878
        },
        youtubeProfile: {
          handle: 'thecrunchpot',
          followers: 710,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-23T20:26:56.000Z',
        amountCents: 4162
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '169dd40a-69af-4cf3-b279-9cc6d63f0984',
      content: [],
      creator: {
        id: 'd58e9aa9-a25f-4725-b755-11a5a59046a2',
        firstName: 'Melinda',
        lastName: 'Barrios',
        email: 'mindyb1512@yahoo.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'melindabarrios416',
          followers: 70,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'melindabarrios5621',
          followers: 1,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-23T20:43:14.000Z',
        amountCents: 4291
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '5356d4b2-f357-4ede-accf-da6bf7c30df3',
      content: [],
      creator: {
        id: '6c3cfbab-4eff-4aac-a3fc-fedf58ab2721',
        firstName: 'Trisheena',
        lastName: 'Ferguson',
        email: 'fergusontrisheena@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'sheenacontentcreator',
          followers: 203,
          engagementRate: 8.622112211221122
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-23T20:53:42.000Z',
        amountCents: 4290
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '89a8306c-4b13-4113-a768-f89bc6ac0622',
      content: [],
      creator: {
        id: '85cc7114-3cbf-4e3e-820a-d95ac5ea3e30',
        firstName: 'Alex',
        lastName: 'Cory Baugh',
        email: 'alexbaugh94@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'cecebarbz',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-23T21:03:11.000Z',
        amountCents: 12000
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '7c70f711-f85e-41c8-9f37-22969b3afc0f',
      content: [],
      creator: {
        id: 'bc3864d8-8343-43e9-8e89-87a0d1bfc863',
        firstName: 'Jecenia',
        lastName: 'Jackson',
        email: 'jeceniamonique@jmoniqueinfluence.com',
        instagramProfile: {
          handle: 'jeceniamonique_',
          followers: 1415,
          engagementRate: 1.9505300353356891
        },
        tiktokProfile: {
          handle: 'jeceniamonique_',
          followers: 873,
          engagementRate: 4.310344827586207
        },
        youtubeProfile: {
          handle: 'jeceniamonique_',
          followers: 399,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-23T21:05:00.000Z',
        amountCents: 4601
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '609b0831-4a8f-401e-96b1-4ac7f39dfe49',
      content: [],
      creator: {
        id: '4d7b9f8f-acda-4332-b36a-3d630563d184',
        firstName: 'Anna',
        lastName: 'Dantes',
        email: 'annasharp2@icloud.com',
        instagramProfile: {
          handle: 'aamw.s',
          followers: 73,
          engagementRate: null
        },
        tiktokProfile: {
          handle: 'annasharpp',
          followers: 263,
          engagementRate: 1.06951871657754
        },
        youtubeProfile: {
          handle: 'annasharp8827',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-23T21:06:11.000Z',
        amountCents: 4557
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'ad0fda60-a530-4f07-80a4-508551ef6efc',
      content: [],
      creator: {
        id: '42ceb022-5930-4723-9128-c6e0b0b18dda',
        firstName: 'Tiandra',
        lastName: 'Campbell',
        email: 'tiandracampbell15@gmail.com',
        instagramProfile: {
          handle: 'xo._curly',
          followers: 1102,
          engagementRate: null
        },
        tiktokProfile: {
          handle: 'xo_curly',
          followers: 1512,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-23T21:32:45.000Z',
        amountCents: 4989
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'f37dc7dd-7c05-4647-9ee5-099c3b9737fa',
      content: [],
      creator: {
        id: '89ecc1f8-1129-419f-8e6f-f7237b97107a',
        firstName: 'Katlin',
        lastName: 'Futch',
        email: 'katlin5650@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'futchesskat',
          followers: 145,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-23T23:01:59.000Z',
        amountCents: 4176
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '6d55e9a3-298a-424e-bb39-122a89eca853',
      content: [],
      creator: {
        id: '9abb4be5-7d26-4870-b407-fd5418f95c5a',
        firstName: 'Kenesha ',
        lastName: 'Gadiare Patterson',
        email: 'lgadiare@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'sexckeki',
          followers: 93,
          engagementRate: 9.463559008509066
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-23T23:34:42.000Z',
        amountCents: 4097
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'b7a8126f-091a-40dc-8dc6-0974c6515891',
      content: [],
      creator: {
        id: '5ceaf9ae-cfd6-407c-97eb-7cbc86d20ba4',
        firstName: 'Brittany',
        lastName: 'Burnett',
        email: 'bburnett1986@msn.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'brittanyburnett2',
          followers: 152,
          engagementRate: 2.564102564102564
        },
        youtubeProfile: {
          handle: 'newl1275',
          followers: 19,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-24T00:53:43.000Z',
        amountCents: 4299
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'af2ff44e-7320-444c-a04e-9c714aa35aa9',
      content: [],
      creator: {
        id: '47e8754d-0a2e-4581-9f73-b5be892334c5',
        firstName: 'Shauna Gay',
        lastName: 'Williamson',
        email: 'king1822boss@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'kingmother_18',
          followers: 301,
          engagementRate: 14.23076923076923
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-24T01:06:22.000Z',
        amountCents: 4299
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '1004bc1f-df68-4237-877d-71ab74a40824',
      content: [],
      creator: {
        id: '4f4135e7-9f04-4c72-b4d6-904ee36405d4',
        firstName: 'Reena',
        lastName: 'Patel',
        email: 'reena.dhansukh.patel@gmail.com',
        instagramProfile: {
          handle: 'historiadereina',
          followers: 1371,
          engagementRate: 5.4808794414921325
        },
        tiktokProfile: {
          handle: 'rolltide___',
          followers: 23932,
          engagementRate: 14.021571648690292
        },
        youtubeProfile: {
          handle: 'rolltide___',
          followers: 7,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-24T03:04:38.000Z',
        amountCents: 4643
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '3ba2c0ce-2d98-4521-88ce-4b45b186baa3',
      content: [],
      creator: {
        id: '2bd73501-51c3-4f80-9a22-4118ed31123c',
        firstName: 'Shinnel',
        lastName: 'Sewell',
        email: 'jordonbennett85@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'vie6990',
          followers: 1,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-24T04:26:32.000Z',
        amountCents: 8524
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'ddcc4770-555c-4718-855f-eaef3bcc5560',
      content: [],
      creator: {
        id: 'f51de49d-795b-49bc-aed2-b8a707cc0663',
        firstName: 'J',
        lastName: 'Saint Louis',
        email: 'zsaintlouis2017@gmail.com',
        instagramProfile: {
          handle: 'jeantries81',
          followers: 1,
          engagementRate: null
        },
        tiktokProfile: {
          handle: 'user5596383713547',
          followers: 5,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-24T05:33:19.000Z',
        amountCents: 5472
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '1f180fa1-322a-4f3a-84ed-ed60bc12ea3d',
      content: [],
      creator: {
        id: '1033f543-a88c-4aac-8d9d-f4f2c54b2da4',
        firstName: 'Moesha',
        lastName: 'Henry',
        email: 'moesha.henry1@icloud.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'moe70989',
          followers: 1,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-24T05:46:20.000Z',
        amountCents: 4363
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '9293de33-12d6-4089-a5af-2e9a1e7e5342',
      content: [],
      creator: {
        id: 'a0ecaf9c-6829-4e4e-9c1a-8af29a43b3d8',
        firstName: 'Samoya',
        lastName: 'Flowers',
        email: 'odeshaflowers@gmail.com',
        instagramProfile: {
          handle: 'dreambellehair',
          followers: 8,
          engagementRate: 66.66666666666666
        },
        tiktokProfile: {
          handle: 'moya_297',
          followers: 61,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-24T14:12:45.000Z',
        amountCents: 4290
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '7c5f8eba-2295-4afd-8fe8-30668a087ac7',
      content: [],
      creator: {
        id: '6ca4429c-8624-4e76-aa01-141b3c88fc4d',
        firstName: 'Jacqueline',
        lastName: 'Augusto',
        email: 'miamibeautyreviewer@gmail.com',
        instagramProfile: {
          handle: 'miami_beauty_reviews',
          followers: 1816,
          engagementRate: 2.7698237885462555
        },
        tiktokProfile: {
          handle: 'miami_beauty_reviewer',
          followers: 1276,
          engagementRate: 6.772650100324124
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-24T16:13:51.000Z',
        amountCents: 4290
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '3f9c8784-9f7f-4a92-b1ad-332231d6b130',
      content: [],
      creator: {
        id: 'f63b8246-e5e0-4ad2-85d2-f7d986a9da58',
        firstName: 'Ashneak',
        lastName: 'Russell',
        email: 'ashneakrussell@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'ashbaeee3',
          followers: 1713,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'journeyswithash',
          followers: 178,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-24T16:14:31.000Z',
        amountCents: 8598
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '7e0899d1-4173-4c13-9a24-6f9c6995ad5f',
      content: [],
      creator: {
        id: '4db54252-518b-432f-9f0d-13167945bf2b',
        firstName: 'Christopher',
        lastName: 'Schlumbrecht',
        email: 'email@christopherschlumbrecht.online',
        instagramProfile: {
          handle: 'haveaball717',
          followers: 1034,
          engagementRate: 0.04835589941972921
        },
        tiktokProfile: {
          handle: 'haveaball717',
          followers: 506,
          engagementRate: 0.42337002540220153
        },
        youtubeProfile: {
          handle: 'haveaball717',
          followers: 1,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-24T16:22:20.000Z',
        amountCents: 4705
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'd3ce782e-2af0-499c-803e-97f5029a9248',
      content: [],
      creator: {
        id: '4a5a7ce2-3482-4d66-9bce-a7444489a349',
        firstName: 'Semeka',
        lastName: 'Robinson ',
        email: 'semeka.robinson54@yahoo.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'meka045',
          followers: 61,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-24T16:27:31.000Z',
        amountCents: 4299
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '1b0482db-3a2c-40f1-8079-8d8e32fc67c6',
      content: [],
      creator: {
        id: '46ea828a-5986-436b-af71-106e2eea25e9',
        firstName: 'Chris',
        lastName: 'Schlumbrecht',
        email: 'christopherSchlumbrecht@gmail.com',
        instagramProfile: {
          handle: 'chriscoin0052',
          followers: 2,
          engagementRate: 20
        },
        tiktokProfile: {
          handle: 'chriscoin0052',
          followers: 19,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'chriscoin0052',
          followers: null,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-24T16:31:49.000Z',
        amountCents: 4272
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '962d5e68-4283-4562-ac2f-d49bd22f241a',
      content: [],
      creator: {
        id: '70d6f47f-3f1e-452a-b349-05f7f0e52f72',
        firstName: 'Shawna',
        lastName: 'Clark',
        email: 'shawnamarieclark84@gmail.com',
        instagramProfile: {
          handle: 'shaunaclark748',
          followers: 56,
          engagementRate: 0.5102040816326531
        },
        tiktokProfile: {
          handle: 'shawnamarie528',
          followers: 61,
          engagementRate: 0
        },
        youtubeProfile: {
          handle: 'shawnaclark-sz7jo',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-24T16:35:27.000Z',
        amountCents: 4137
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'd1d03fa5-1a09-474f-b0b3-56f8a1dc1a64',
      content: [],
      creator: {
        id: 'db7a23df-35eb-4f4f-a30a-cdc2671046a5',
        firstName: 'Tatiana',
        lastName: 'Small',
        email: 'tatianasmall30@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'dazzlemycases',
          followers: 806,
          engagementRate: 7.453416149068323
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-24T16:41:24.000Z',
        amountCents: 5289
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '40cf25ce-4155-4689-b30c-75cae2e23350',
      content: [],
      creator: {
        id: '52be222e-9713-42bd-a627-67146c331db6',
        firstName: 'Kayan',
        lastName: 'Newell',
        email: 'kayannewell51@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'kayan_lishous',
          followers: 1895,
          engagementRate: 8.75979804087949
        },
        youtubeProfile: {
          handle: 'kayannewell5243',
          followers: 1,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-24T16:41:50.000Z',
        amountCents: 5304
      },
      status: 'INCOMPLETE',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'adf65d76-cbb2-4de7-967f-60cd41dc53d4',
      content: [],
      creator: {
        id: '83c13c11-9f73-4791-8be1-85a86ba71ed9',
        firstName: 'Marcus',
        lastName: 'Garrett',
        email: 'gerodgarrett@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'theyadoretami',
          followers: 2223,
          engagementRate: 22.115384615384613
        },
        youtubeProfile: {
          handle: 'they_adoretami',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-24T16:48:14.000Z',
        amountCents: 1457
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'cdc6deb8-b7ff-482e-9cb1-d0939493822e',
      content: [],
      creator: {
        id: '57ef8183-10d9-4e1f-864a-60fd6e02c91d',
        firstName: 'Lisa',
        lastName: 'Mikec',
        email: 'lisagrennell2019@gmail.com',
        instagramProfile: {
          handle: 'lisamikec89',
          followers: 4,
          engagementRate: 0
        },
        tiktokProfile: {
          handle: 'justaheartyoubroke',
          followers: 30,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'lisagrennell6319',
          followers: 10,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-24T17:34:25.000Z',
        amountCents: 6023
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '77f85e59-6680-4d67-bfa8-a7c1a254b983',
      content: [],
      creator: {
        id: '28d58dbb-acd1-4337-a9f3-a06349f9d67f',
        firstName: 'Shannen',
        lastName: 'Ortiz',
        email: 'shannenortiz@gmail.com',
        instagramProfile: {
          handle: 'shannen_o_',
          followers: 506,
          engagementRate: 0.7905138339920948
        },
        tiktokProfile: {
          handle: 'shannen_o_',
          followers: 488,
          engagementRate: 4.951661261702258
        },
        youtubeProfile: {
          handle: 'xartisticmvmntx',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-24T17:54:30.000Z',
        amountCents: 3903
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'a6061eab-a560-4c79-946b-52105d1baf2d',
      content: [],
      creator: {
        id: '1e3a8cdc-e12f-42d7-b6a2-86cd8834b0c9',
        firstName: 'Gigi',
        lastName: 'Pereira',
        email: 'gholtdestefano@gmail.com',
        instagramProfile: {
          handle: 'gigiandlance',
          followers: 158,
          engagementRate: 6.962025316455696
        },
        tiktokProfile: {
          handle: 'gigiandlance',
          followers: 366,
          engagementRate: 11.91969887076537
        },
        youtubeProfile: {
          handle: 'lancepereira',
          followers: 552,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-24T19:15:08.000Z',
        amountCents: 4924
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '53b8d2b2-00ea-4ed7-9cc4-3042304ab9b6',
      content: [],
      creator: {
        id: '293714cd-a7ab-47df-8378-8105e77d819a',
        firstName: 'Deidra',
        lastName: 'Jeffers',
        email: 'deidramorris@hotmail.com',
        instagramProfile: {
          handle: 'beaureine97',
          followers: 73,
          engagementRate: 0.273972602739726
        },
        tiktokProfile: {
          handle: 'beau_reine97',
          followers: 1118,
          engagementRate: 15.443037974683543
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-24T19:16:27.000Z',
        amountCents: 3903
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '0f3930fc-d1de-4e35-b4ad-19df5502efe3',
      content: [],
      creator: {
        id: '33938374-af88-4550-9ca5-4ac37a54a74e',
        firstName: 'Tiffany',
        lastName: 'Williams',
        email: 'williatifffa@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'tiffytree_',
          followers: 137,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-24T19:46:27.000Z',
        amountCents: 4290
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '8ffea866-6cf7-4e1c-9fdb-b082bf7ffd42',
      content: [],
      creator: {
        id: 'afe30c9a-1b6e-4d02-86a5-329246a1460e',
        firstName: 'Amber',
        lastName: 'Sauceda',
        email: 'mamabear.influencer@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'mrs.asauceda',
          followers: 82,
          engagementRate: 1.042654028436019
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-24T20:56:08.000Z',
        amountCents: 4186
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '6cb4b13c-ff5a-455c-a204-0f805aa57a8d',
      content: [],
      creator: {
        id: '4ee0a2dd-2cc8-4520-bf86-dab80b149c48',
        firstName: 'Tabyre',
        lastName: 'Hoyle',
        email: 'tabyreeaston13@live.com',
        instagramProfile: {
          handle: 'tabyrehoyle',
          followers: 1369,
          engagementRate: 7.231555880204529
        },
        tiktokProfile: {
          handle: 'tabes0',
          followers: 4497,
          engagementRate: 7.788062559846792
        },
        youtubeProfile: {
          handle: 'hollistergirl2',
          followers: 8,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-24T21:15:52.000Z',
        amountCents: 6185
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '59a7b122-08d8-40a8-8465-6c047d65bab9',
      content: [],
      creator: {
        id: 'b1824555-20e3-4987-ad95-cdb18853e81d',
        firstName: 'Tahjna',
        lastName: 'Heath',
        email: 'tahjnaheath44@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'babyt0900',
          followers: 11180,
          engagementRate: 17.177914110429448
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-24T22:39:08.000Z',
        amountCents: 4299
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '9c230ca9-9fe2-427e-9618-05488a407965',
      content: [],
      creator: {
        id: '5776cbe3-9ccd-4769-8610-f00b27d29cb8',
        firstName: 'Camaro',
        lastName: 'Lewis',
        email: 'chanksenior@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'zhuri623',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-24T23:44:04.000Z',
        amountCents: 4602
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'd16c45f4-c7f7-4082-8c58-1fa7c96942a1',
      content: [],
      creator: {
        id: '9beba374-431a-49d5-a6f3-1e062237296a',
        firstName: 'Victoria',
        lastName: 'Campbell',
        email: 'campbellsimone863@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'issaqueen8621',
          followers: 4282,
          engagementRate: 6.595132011596008
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-25T04:06:19.000Z',
        amountCents: 4998
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'f9184017-b063-4440-a869-923252b1a91d',
      content: [],
      creator: {
        id: '164070b7-54bd-4af1-aecb-34b651979ce6',
        firstName: 'Esta',
        lastName: 'Polime',
        email: 'siviensatoika2001@gmail.com.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'sivie9',
          followers: 1020,
          engagementRate: 10.41605047816228
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-25T04:16:18.000Z',
        amountCents: 4998
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '26656a52-bd9d-42ad-97a1-9c71d625049f',
      content: [],
      creator: {
        id: 'c50d46b1-308a-4d92-bb35-dbad78c236e6',
        firstName: 'Oleksandra',
        lastName: 'Hulova',
        email: 'gulovasasha@gmail.com',
        instagramProfile: {
          handle: 'hulova_work',
          followers: 670,
          engagementRate: 1.130952380952381
        },
        tiktokProfile: {
          handle: 'sashahulova_life',
          followers: 1610,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'oleksandrahulova2767',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-25T04:24:01.000Z',
        amountCents: 4698
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '107e9c1f-0ac6-4dc1-aaf3-7bb7bf5d3d92',
      content: [],
      creator: {
        id: '496648ec-4e24-42e4-badd-ad8fa2fa0ec6',
        firstName: 'Meech',
        lastName: 'Skull',
        email: 'meechout@icloud.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'ashleylopez.2',
          followers: 512,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-25T07:11:44.000Z',
        amountCents: 5133
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '128f607e-7f78-4d80-b4dd-5338066ae48c',
      content: [],
      creator: {
        id: 'dbf7fe61-cac7-4963-ad48-b5803128974c',
        firstName: 'Chanice',
        lastName: 'Palmer',
        email: 'chanicepalmer@yahoo.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'chaniqmoq60',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-25T07:39:14.000Z',
        amountCents: 4265
      },
      status: 'INCOMPLETE',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '19b5cdd0-8e4c-4216-af92-8a870f46785a',
      content: [],
      creator: {
        id: 'd0aa027e-bc04-41ba-878e-fc753ea7be97',
        firstName: 'Janelle',
        lastName: 'Johnson',
        email: 'johnsonjanelle373@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: '_bbynelle28',
          followers: 251,
          engagementRate: 4.365620736698499
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-25T08:22:37.000Z',
        amountCents: 4994
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'ce9f11c2-c06d-4b5c-83ec-2c721e943c4b',
      content: [],
      creator: {
        id: '5964eedf-9271-4d13-9d88-07aa0fe89348',
        firstName: 'Denay',
        lastName: 'Dominic',
        email: 'mydivinebeautyobsession@gmail.com',
        instagramProfile: {
          handle: 'mydivinebeautyobsession',
          followers: 477,
          engagementRate: 0.3983228511530398
        },
        tiktokProfile: {
          handle: 'thesacredlotustemple',
          followers: 4259,
          engagementRate: 4.357743097238896
        },
        youtubeProfile: {
          handle: 'mydivinebeautyobsession',
          followers: 17,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-25T12:53:13.000Z',
        amountCents: 4600
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '26240256-a4ec-46a0-8cc5-645a3086c5d7',
      content: [],
      creator: {
        id: '40fca231-51da-4e9c-aad8-c44b44f50bd2',
        firstName: 'Mollie',
        lastName: 'Detamore',
        email: 'mollieatully@gmail.com',
        instagramProfile: {
          handle: 'moltul',
          followers: 312,
          engagementRate: 9.967320261437909
        },
        tiktokProfile: {
          handle: 'moldet',
          followers: 33,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-25T16:11:43.000Z',
        amountCents: 4225
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '2af08ad6-0134-44db-b075-a0108d16a56b',
      content: [],
      creator: {
        id: 'c134a642-e69a-42dc-8444-98d9df45b20a',
        firstName: 'Serena',
        lastName: 'Campbell',
        email: 'serenacampbell49@gmail.com',
        instagramProfile: {
          handle: 'aner._.80',
          followers: 1791,
          engagementRate: 3.4059184812953656
        },
        tiktokProfile: {
          handle: 'r3nalove',
          followers: 1198,
          engagementRate: 26.990291262135923
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-25T16:12:42.000Z',
        amountCents: 4290
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '032a0445-d4f2-426d-aabe-660f93bd5a7a',
      content: [],
      creator: {
        id: '9299e718-3652-40b6-a461-6db661effe27',
        firstName: 'Elizabeth',
        lastName: 'Rendon',
        email: 'rendon.elizabeth5@gmail.com',
        instagramProfile: {
          handle: 'lizr.915',
          followers: 1526,
          engagementRate: 3.591087811271297
        },
        tiktokProfile: null,
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-25T16:16:15.000Z',
        amountCents: 4644
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '4f06943f-2bb9-4941-ba76-f506fd525221',
      content: [],
      creator: {
        id: '60a0d10b-a439-42fb-b898-591bf47314f9',
        firstName: 'Edith',
        lastName: 'Rabaja',
        email: 'edithrabaja@gmail.com',
        instagramProfile: {
          handle: 'supermegastar',
          followers: 538,
          engagementRate: 3.965303593556382
        },
        tiktokProfile: {
          handle: '_supermegastar',
          followers: 8,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'livelaughleeloo',
          followers: 82,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-25T16:16:35.000Z',
        amountCents: 4250
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '94f09971-a9e4-476f-ace3-83e10ece0afd',
      content: [],
      creator: {
        id: '638cfa8e-bc8e-4701-8a18-7bd1c3c88b33',
        firstName: 'Indra',
        lastName: 'Baat',
        email: 'ibaatarkhuu@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'bounty1091',
          followers: 22,
          engagementRate: 7.881136950904392
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-25T16:26:07.000Z',
        amountCents: 4137
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '58555ffc-956a-45bf-969b-79dc89cfd111',
      content: [],
      creator: {
        id: '89098b99-0601-4db7-ac49-5edc6191016a',
        firstName: 'Darin',
        lastName: 'Kleinpell',
        email: 'darinkleinpell@gmail.com',
        instagramProfile: {
          handle: 'darin_kleinpell',
          followers: 376,
          engagementRate: 0.09148936170212765
        },
        tiktokProfile: {
          handle: 'darinkleinpell',
          followers: 189,
          engagementRate: 10.782080485952923
        },
        youtubeProfile: {
          handle: 'kleinpellteamrealty4561',
          followers: 18,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-25T16:28:14.000Z',
        amountCents: 4205
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '7aef3271-e5ed-4226-8bf4-94dfc45322d9',
      content: [],
      creator: {
        id: '88d27240-b438-46d8-a43c-058f5e2d138f',
        firstName: 'Elizabeth',
        lastName: 'Foster',
        email: 'bella7air@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'lizchristine1',
          followers: 3600,
          engagementRate: 11.738917250728274
        },
        youtubeProfile: {
          handle: 'elizabethfoster6983',
          followers: 1,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-25T16:29:08.000Z',
        amountCents: 4626
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'b7c38eae-78c6-4358-90b3-fa0579ef5801',
      content: [],
      creator: {
        id: '18806087-c83b-4807-b94b-db9d08713cd7',
        firstName: 'Tracy ',
        lastName: 'Allen Chapman ',
        email: 'tracyannallen18@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'babyanna770',
          followers: 76,
          engagementRate: 4.523809523809524
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-25T16:37:46.000Z',
        amountCents: 8200
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'b74dd28a-3d8b-4237-9c77-270f6b9bd4a5',
      content: [],
      creator: {
        id: '439a1dfe-b7fd-4ffa-8866-2a6c86cc8559',
        firstName: 'Ajah',
        lastName: 'Yearwood',
        email: 'ajahyearwood@gmail.com',
        instagramProfile: {
          handle: 'real.ajah',
          followers: 566,
          engagementRate: null
        },
        tiktokProfile: {
          handle: 'uh.ajah',
          followers: 261,
          engagementRate: 15.789473684210526
        },
        youtubeProfile: {
          handle: 'ajahmarie',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-25T16:39:43.000Z',
        amountCents: 3903
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '3eff078f-6ba9-4d03-82a2-9b09b91a2dbf',
      content: [],
      creator: {
        id: '9a0e0536-6d9a-4c1e-8c14-9f22b0b5b067',
        firstName: 'Jessica',
        lastName: 'Edwards',
        email: 'edwards16jessica@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'jessie_bby__',
          followers: 1335,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-25T16:42:29.000Z',
        amountCents: 6583
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'add2d42d-1c63-4815-aab5-a137c4429b76',
      content: [],
      creator: {
        id: 'e4ca4b62-bac4-4091-aea3-72d5d587ad8a',
        firstName: 'Sam',
        lastName: 'Hernandez',
        email: 'samjshernandez@yahoo.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'samuel_sebastian1',
          followers: 67,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'samjshernandez',
          followers: 11,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-25T16:59:39.000Z',
        amountCents: 4274
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '8063cb72-b3fb-4a43-bf19-dfbc36b4206d',
      content: [],
      creator: {
        id: '8902b10c-50e4-49b2-badb-bf368d074d12',
        firstName: 'Romoya',
        lastName: 'Barrett',
        email: 'osheleeb@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'ithz_romoya',
          followers: 1114,
          engagementRate: 12.105263157894736
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-25T17:07:05.000Z',
        amountCents: 3903
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '33f9614c-d852-482d-b589-826fbb1ac0ec',
      content: [],
      creator: {
        id: '37777e5b-4839-4e35-a168-dd93fe6e7920',
        firstName: 'Krystle',
        lastName: 'Howell',
        email: 'tutugirlsdetroit@gmail.com',
        instagramProfile: {
          handle: 'tutu_girl_magic',
          followers: 428,
          engagementRate: 2.5233644859813085
        },
        tiktokProfile: {
          handle: 'takeitfromanaquarius',
          followers: 3524,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'krysandthefab5',
          followers: 2,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-25T17:18:04.000Z',
        amountCents: 4644
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '54b1f3ec-e9d3-4724-98f6-4dcb9e05d0ce',
      content: [],
      creator: {
        id: 'b4bb26f7-32ac-47cb-8f4e-ea063ec20df3',
        firstName: 'Katherine',
        lastName: 'Kelly',
        email: 'jcademom@gmail.com',
        instagramProfile: {
          handle: 'katherinekelly192',
          followers: 80,
          engagementRate: null
        },
        tiktokProfile: {
          handle: 'katherinekelly12',
          followers: 60,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'katherinekelly992',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-25T17:27:43.000Z',
        amountCents: 4600
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '61bec073-c5e7-4220-a6b2-efc1aa6412a2',
      content: [],
      creator: {
        id: '512e732f-1de0-4c83-add8-4848d9fa2a92',
        firstName: 'Angeline',
        lastName: 'Maglasang',
        email: 'angelinemaglasang1980@gmail.com',
        instagramProfile: {
          handle: 'anz0891',
          followers: 1,
          engagementRate: null
        },
        tiktokProfile: {
          handle: 'elvisleane',
          followers: 15,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'elvisleane1968',
          followers: 5,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-25T17:43:41.000Z',
        amountCents: 4730
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '9a255547-2012-4b12-aa3b-7cde45dec1f7',
      content: [],
      creator: {
        id: '76629c64-fa4b-4a25-bb09-b6f0bd4be9d1',
        firstName: 'Mikayla',
        lastName: 'Schell',
        email: 'mikaylachristina2@gmail.com',
        instagramProfile: {
          handle: 'mikayla.reviews',
          followers: 2155,
          engagementRate: 12.700696055684455
        },
        tiktokProfile: {
          handle: 'mikayla.reviews',
          followers: 489,
          engagementRate: 2.6717557251908395
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-25T18:14:06.000Z',
        amountCents: 4299
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '07fd0f84-08ff-4c03-8d4b-cb0e05422a0b',
      content: [],
      creator: {
        id: 'c7c3614f-15a2-4d4f-9862-6d7359957815',
        firstName: 'Shawnette',
        lastName: 'Treasure',
        email: 'shawnettetreasure@yahoo.com',
        instagramProfile: {
          handle: 'hiddentreasuresboutiquee',
          followers: 1911,
          engagementRate: null
        },
        tiktokProfile: {
          handle: 'manifestingwithnetty_',
          followers: 6230,
          engagementRate: 4.750374321542204
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-25T19:12:44.000Z',
        amountCents: 4681
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '279a4f46-1493-4b65-a73e-2f5e53321b76',
      content: [],
      creator: {
        id: '5177b511-1c7c-440e-b3b6-f8628c3a88f1',
        firstName: 'Breanna',
        lastName: 'Blake',
        email: 'breannablake00@icloud.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'viewsabroad',
          followers: 1007,
          engagementRate: 15.201900237529692
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-25T19:14:08.000Z',
        amountCents: 4602
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '7853862b-7b07-4f3e-8221-44285c41a8a5',
      content: [],
      creator: {
        id: '3a1bd946-4edd-4372-bec9-7ffe6a3b4b1c',
        firstName: 'Maria',
        lastName: 'Jimenez',
        email: 'map_er2007@hotmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'ma..fernanda.jime',
          followers: 4,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-25T19:34:39.000Z',
        amountCents: 3903
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'e0b4e5e4-5897-43ef-9d55-da852917e008',
      content: [],
      creator: {
        id: 'c44c65cc-6dbc-4951-8210-55404b921a34',
        firstName: 'Janelle',
        lastName: 'Wizzart',
        email: 'waulnubian@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'nubianjaye',
          followers: 222,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'janellewizzart368',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-25T19:36:22.000Z',
        amountCents: 4290
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'a168807f-4c45-4a15-bbd0-1db6b114bacc',
      content: [],
      creator: {
        id: '50af848d-41cd-430e-8888-641d11ca6231',
        firstName: 'Carona ',
        lastName: 'McDonald ',
        email: 'croyemcdonald@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'penny.proud65',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-25T20:22:41.000Z',
        amountCents: 9372
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '069298e7-250a-4b2b-a1f6-f96388cab3d6',
      content: [],
      creator: {
        id: 'a16106a0-a722-4a83-b26a-f6c7f44a3b25',
        firstName: 'Verenna',
        lastName: 'Gordon',
        email: 'verennagordon15@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'prettyyvee._',
          followers: 919,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-25T20:32:29.000Z',
        amountCents: 7114
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '0832e280-0188-4628-bf65-de8bb7d68018',
      content: [],
      creator: {
        id: 'f1bdaed1-827a-4773-9b1a-5185c3171666',
        firstName: 'Greg',
        lastName: 'Minto',
        email: 'gregoryminto68@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'gregmintzz2',
          followers: 2330,
          engagementRate: 13.031914893617023
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-25T21:30:06.000Z',
        amountCents: 5000
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '452eb4d2-5feb-46be-adb0-47b93daeab35',
      content: [],
      creator: {
        id: 'dc1f84e7-e2c5-4689-b01a-785178b0bae7',
        firstName: 'Britannia ',
        lastName: 'Reid',
        email: 'me8837424@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'kyimarie._',
          followers: 21,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-25T22:21:52.000Z',
        amountCents: 5000
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'a52f9627-e618-4547-a3cb-4210e9d93e20',
      content: [],
      creator: {
        id: '93d447ef-1132-48c9-8f6b-b2f5698c2362',
        firstName: 'George',
        lastName: 'Pativate',
        email: 'georgepativate@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'georgepativate',
          followers: 234,
          engagementRate: 1.8826135105204873
        },
        youtubeProfile: {
          handle: 'georgepativate',
          followers: 2,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-25T22:32:46.000Z',
        amountCents: 2674
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'ed40ad90-f32a-4d7c-ad44-b6044ff4fb9e',
      content: [],
      creator: {
        id: '9a57df8a-36c7-4db6-87c3-21f939183ed1',
        firstName: 'Phuong',
        lastName: 'Quach',
        email: 'thephuongbox@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'thephuongbox',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'phuongquach7323',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-25T22:46:15.000Z',
        amountCents: 4323
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '754be4bf-c760-4179-9607-44b14f0a57c1',
      content: [],
      creator: {
        id: '94a33b8c-0c1c-4c3e-83e8-2c3c9dd0ef36',
        firstName: 'Anika',
        lastName: 'France',
        email: 'anikafrance@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'introvert_prettypreps',
          followers: 3354,
          engagementRate: 18.97590361445783
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-26T00:26:20.000Z',
        amountCents: 4998
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '9f547708-8168-4347-8e54-76fa87660c95',
      content: [],
      creator: {
        id: '053179dc-68c0-47ec-802d-9e74df6cef97',
        firstName: 'Racqualia',
        lastName: 'Porter',
        email: 'pracqualia@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'kay_la3002',
          followers: 59,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-26T01:43:06.000Z',
        amountCents: 5418
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '073e8572-bddb-4c2a-8c97-8c9a1b599a6e',
      content: [],
      creator: {
        id: '74029e68-ecd0-4448-9e2a-9439748aa442',
        firstName: 'Zavier',
        lastName: 'McIntosh',
        email: 'richbadmoney@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'swiss_gad_zaza',
          followers: 104,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-26T02:25:34.000Z',
        amountCents: 8948
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '90d39198-e091-490d-b5d3-81462d695d3e',
      content: [],
      creator: {
        id: 'b7a90bc7-2970-4bfc-9990-e26d8d282c39',
        firstName: 'Chelseann',
        lastName: 'Mcpherson',
        email: 'mchelseann12@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: '_humblechelsea',
          followers: 1330,
          engagementRate: 10.284463894967178
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-26T03:34:38.000Z',
        amountCents: 4590
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '63924c74-a5cb-46fc-b52b-c544f2f3a07c',
      content: [],
      creator: {
        id: '63e1f342-c527-4f54-a340-c67a32a55e1d',
        firstName: 'Kyra',
        lastName: 'Vasquez',
        email: 'kyralvasquez@gmail.com',
        instagramProfile: {
          handle: 'punkyvinyls',
          followers: 147,
          engagementRate: 11.292517006802722
        },
        tiktokProfile: {
          handle: 'poisoncherrrry',
          followers: 119,
          engagementRate: 8.333333333333332
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-26T04:41:26.000Z',
        amountCents: 4249
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '321938f7-bac1-4659-8333-60212588df93',
      content: [],
      creator: {
        id: 'b3ad8814-a5ea-4bdc-af08-e391e478d196',
        firstName: 'Grace',
        lastName: 'Cook',
        email: 'gracecook03@gmail.com',
        instagramProfile: {
          handle: 'gracie.sampson23',
          followers: 581,
          engagementRate: 3.2702237521514634
        },
        tiktokProfile: {
          handle: 'makeupbygracie05',
          followers: 503,
          engagementRate: 5.5148555148555145
        },
        youtubeProfile: {
          handle: 'cookslittlevalleyfarmgarde4473',
          followers: null,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-26T06:46:13.000Z',
        amountCents: 4589
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '168f1da2-55e5-47ad-9624-d5d20c303980',
      content: [],
      creator: {
        id: '1d73647b-cff6-4ea7-b1e4-25660be52598',
        firstName: 'Ashackie',
        lastName: 'Goodall',
        email: 'delroygoodall@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'bossianateirah',
          followers: 63,
          engagementRate: 11.904761904761903
        },
        youtubeProfile: {
          handle: 'delroygoodall1754',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-26T08:43:42.000Z',
        amountCents: 5990
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '395f7f3a-1187-4a03-8b0d-7a81f773b3f0',
      content: [],
      creator: {
        id: '74a7c7d7-1968-4d73-b767-51fbd7992e7d',
        firstName: 'Kerica',
        lastName: 'Myers',
        email: 'myerskerica9@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: '_case1000',
          followers: 33,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-26T09:04:24.000Z',
        amountCents: 5001
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'fb7ec729-66c1-4ab2-9584-d596140853d9',
      content: [],
      creator: {
        id: 'a2f79afe-4a14-4da1-b7b9-31b8db93bf1b',
        firstName: 'Patricia',
        lastName: 'Dennis',
        email: 'dennispatricia678@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'lulu876l',
          followers: 1304,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-26T09:14:26.000Z',
        amountCents: 3903
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '844d42df-a884-455a-96ed-47e1c1871212',
      content: [],
      creator: {
        id: 'fdcce613-ad21-4976-b8b2-02104965b178',
        firstName: 'Charmaine',
        lastName: 'Hyatt',
        email: 'charmainehyatt222@icloud.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'trixs220',
          followers: 1688,
          engagementRate: 14.348785871964681
        },
        youtubeProfile: {
          handle: 'charmainehyatt9858',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-26T09:54:24.000Z',
        amountCents: 4299
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'c6e3ccef-214d-4623-905d-18a765e3ab3e',
      content: [],
      creator: {
        id: '4418d14a-c03b-4bda-af7d-3c7106bd2e5a',
        firstName: 'Yaneke',
        lastName: 'Senior',
        email: 'yanekesenior@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'missssenior',
          followers: 634,
          engagementRate: 2.380952380952381
        },
        youtubeProfile: {
          handle: 'kimoneabby1810',
          followers: 2,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-26T12:42:48.000Z',
        amountCents: 4671
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '90c47462-8f75-456f-b661-146c3c5ef9c8',
      content: [],
      creator: {
        id: '69f55aee-54d0-42ec-ab38-bbe69911cfe5',
        firstName: 'Gianna',
        lastName: 'Kleinpell',
        email: 'giannamc@aol.com',
        instagramProfile: {
          handle: 'darin_kleinpell',
          followers: 515,
          engagementRate: 3.747572815533981
        },
        tiktokProfile: {
          handle: 'gianna.realtor',
          followers: 57,
          engagementRate: 1.4760147601476015
        },
        youtubeProfile: {
          handle: 'darinkleinpell',
          followers: 5,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-26T15:15:12.000Z',
        amountCents: 4205
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '80cdd886-159f-4dbb-98a5-8dc38eec999e',
      content: [],
      creator: {
        id: '5f4d9606-258f-4d27-9fe6-b9f26e4146d8',
        firstName: 'Cheryl',
        lastName: 'DSouza',
        email: 'info@cheryldsouza.com',
        instagramProfile: {
          handle: 'dsouzadsigns',
          followers: 438,
          engagementRate: 2.28310502283105
        },
        tiktokProfile: {
          handle: 'cheryldsouzer',
          followers: 48,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'cheryldeez',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-26T16:14:14.000Z',
        amountCents: 4740
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '31822caf-4e7a-4bbc-bc82-2e5c4cb5de8a',
      content: [],
      creator: {
        id: '7164a0b5-ea9b-4dde-8913-cf082b1d37af',
        firstName: 'Madison',
        lastName: 'Mort',
        email: 'madisonmort244@gmail.com',
        instagramProfile: {
          handle: 'maddiiii2.0',
          followers: 664,
          engagementRate: 0.8995502248875562
        },
        tiktokProfile: {
          handle: '_maddi_issues',
          followers: 461,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'maddiissues',
          followers: 2,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-26T16:22:18.000Z',
        amountCents: 4718
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '0163ef55-f02e-4d81-babc-675348a42bca',
      content: [],
      creator: {
        id: 'fe73834d-7004-40ff-8008-137424ce0cc3',
        firstName: 'Elizabeth',
        lastName: 'Gilbert',
        email: 'gilbertelizabeth34@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'lgilb2',
          followers: 253,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-26T16:26:36.000Z',
        amountCents: 4176
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'a1778b7e-b8ce-4bef-9843-17c95fba6b05',
      content: [],
      creator: {
        id: '9e537c4e-3c46-4ba2-83ad-78a01e77328b',
        firstName: 'Clarissa',
        lastName: 'Anderson',
        email: 'sandraclarissa.anderson@gmail.com',
        instagramProfile: {
          handle: 'sca_selfcare_drops',
          followers: 1282,
          engagementRate: 5.826833073322933
        },
        tiktokProfile: {
          handle: 'selfcare_drops',
          followers: 357,
          engagementRate: 0.31695721077654515
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-26T16:31:48.000Z',
        amountCents: 4604
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '368aac0d-151b-4ddf-b69e-80da027314b3',
      content: [],
      creator: {
        id: 'c2361f81-602a-4103-8d4a-94806bd9b497',
        firstName: 'Paul',
        lastName: 'Wang',
        email: 'wangpokaiad@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'paul.wang13',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'paulwang-y6t',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-26T16:33:05.000Z',
        amountCents: 5814
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'c103549b-74a4-4081-8078-1092d77d3aea',
      content: [],
      creator: {
        id: 'ffa59928-3ad0-466e-a821-022270fbcdcc',
        firstName: 'Destiny',
        lastName: 'Copeland',
        email: 'dpcopeland1995@icloud.com',
        instagramProfile: {
          handle: 'desbianss',
          followers: 923,
          engagementRate: 0.6806282722513088
        },
        tiktokProfile: {
          handle: 'desbians_',
          followers: 206,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'thedesbians8139',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-26T16:33:58.000Z',
        amountCents: 4215
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'bb12041f-d6fa-4d41-983a-056248ebfb9a',
      content: [],
      creator: {
        id: 'c47d2c7c-1b05-42c6-b35e-2f720df55097',
        firstName: 'Hobert ',
        lastName: 'Mendoza ',
        email: 'camryn_harrison@yahoo.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'chiney398',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-26T17:03:43.000Z',
        amountCents: 12000
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '23b6dce8-433d-4396-8c6e-0db2d4425ee5',
      content: [],
      creator: {
        id: '21d564d3-57b0-4b7c-bf7e-6cdb3dc57685',
        firstName: 'Jillian',
        lastName: 'Piraino',
        email: 'jillpiraino@yahoo.com',
        instagramProfile: {
          handle: 'its_a_me_jillyp',
          followers: 871,
          engagementRate: null
        },
        tiktokProfile: {
          handle: 'dixiethelabradork',
          followers: 623,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-26T17:06:30.000Z',
        amountCents: 4215
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'c1956285-0bc6-433a-8c4d-c29264142dbc',
      content: [],
      creator: {
        id: '14072e56-1a62-4846-8abb-bbf83f3219db',
        firstName: 'Andrea ',
        lastName: 'Bryan',
        email: 'famousadrea@yahoo.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'dreabryan626',
          followers: 1071,
          engagementRate: 7.296137339055794
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-26T17:11:40.000Z',
        amountCents: 8100
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '926d7dde-732e-4be1-9473-543e4bb39b33',
      content: [],
      creator: {
        id: 'ec100098-1c44-4d8c-b707-984be7afc2aa',
        firstName: 'Blackfire',
        lastName: 'Mo',
        email: 'sweet.blackfire@icloud.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'blackfiremo',
          followers: 1182,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'audios2231',
          followers: 45,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-26T17:24:23.000Z',
        amountCents: 3903
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '56c71b58-cff7-4a87-bcb0-6b1d5211091b',
      content: [],
      creator: {
        id: '01865128-ccc6-4fac-b783-b3168e9739c9',
        firstName: 'Tanyakaye',
        lastName: 'Thompson',
        email: 'tanyakayethompson@gmail.com',
        instagramProfile: {
          handle: 'gabby_sweet_essence_collection',
          followers: 102,
          engagementRate: 3.8461538461538463
        },
        tiktokProfile: {
          handle: 'the_.original_gabby',
          followers: 3050,
          engagementRate: 10.625346974383376
        },
        youtubeProfile: {
          handle: 'lifeofgabby19',
          followers: 1070,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-26T17:34:34.000Z',
        amountCents: 5211
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '5abbce54-34e7-4d6c-bf3c-6c4cf23335ff',
      content: [],
      creator: {
        id: '430879f6-06ce-4888-b826-a61d4a66fec6',
        firstName: 'Cassandra',
        lastName: 'Querica',
        email: 'cquerica@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'cassiequerica',
          followers: 434,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'cassiebquerica',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-26T18:45:25.000Z',
        amountCents: 4219
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'ab5f05c0-62d2-437a-95b3-44450e63af62',
      content: [],
      creator: {
        id: 'a78da47e-5601-4189-9a19-34e262ef49ae',
        firstName: 'Nicoya',
        lastName: 'Foster',
        email: 'nicoyafostercollabs@icloud.com',
        instagramProfile: {
          handle: '_that.melanin.girl_',
          followers: 1215,
          engagementRate: 6.419753086419753
        },
        tiktokProfile: {
          handle: 'coya_melaningirl',
          followers: 709,
          engagementRate: 12.09555517910895
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-26T18:49:09.000Z',
        amountCents: 4299
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'f9a09fff-df33-444c-8486-581ea452d53c',
      content: [],
      creator: {
        id: '62a12646-cfb0-489b-b742-655955f962c0',
        firstName: 'Melanie',
        lastName: 'Malone',
        email: 'melanie_malone@comcast.net',
        instagramProfile: {
          handle: 'melanielynnmalone',
          followers: 975,
          engagementRate: 0.3384615384615384
        },
        tiktokProfile: {
          handle: 'melanie_malone',
          followers: 5150,
          engagementRate: 3.668329769541459
        },
        youtubeProfile: {
          handle: 'melaniemalone4802',
          followers: 5,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-26T19:12:33.000Z',
        amountCents: 3903
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '5f21a426-d1d4-4121-90ac-3f215f21a6cc',
      content: [],
      creator: {
        id: 'af1dbcd0-eea6-4431-9271-8597420b2228',
        firstName: 'Ashley',
        lastName: 'Bowlin',
        email: 'ashleybowlin.an@gmail.com',
        instagramProfile: {
          handle: 'itryandreviewforyou',
          followers: 1475,
          engagementRate: 2.5694915254237287
        },
        tiktokProfile: {
          handle: 'itryandreviewforyou',
          followers: 443,
          engagementRate: 1.03359173126615
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-26T19:26:18.000Z',
        amountCents: 4169
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '59ba8c68-d8c8-444a-902e-1ec37272d3b4',
      content: [],
      creator: {
        id: '4f3f8d21-5c70-49cd-bdcd-b56529e21144',
        firstName: 'Jessica',
        lastName: 'Hernandez',
        email: 'theradlifeofjess@gmail.com',
        instagramProfile: {
          handle: 'aradteacher',
          followers: 990,
          engagementRate: 0.08417508417508418
        },
        tiktokProfile: {
          handle: 'radlifeofjess.sahm',
          followers: 1327,
          engagementRate: 9.045226130653267
        },
        youtubeProfile: {
          handle: 'h2opolo20111',
          followers: 21,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-26T20:15:46.000Z',
        amountCents: 4643
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '891282f3-e258-4f58-8c70-4e417ac4fab1',
      content: [],
      creator: {
        id: '72ee8fc3-07f8-45f4-bd0e-1eef5d34c5c9',
        firstName: 'Isha',
        lastName: 'Francis',
        email: 'ishafrancis23@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'senoritamelody',
          followers: 3501,
          engagementRate: 10.10928961748634
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-27T00:54:03.000Z',
        amountCents: 4572
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'ff7711d0-78e9-4983-a121-0d6aa0432309',
      content: [],
      creator: {
        id: 'ed96ec5a-11b9-4984-af88-a6c5f037e94f',
        firstName: 'Terinay',
        lastName: 'Morgan',
        email: 'terinaymorgan84@outlook.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'terinay73',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-27T01:23:45.000Z',
        amountCents: 7123
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '956601a3-d11c-4556-ad8e-a6442f6ed87d',
      content: [],
      creator: {
        id: '9e478b64-305c-4ddd-bad6-8573aec0b5e1',
        firstName: 'Jevaughn',
        lastName: 'Porter',
        email: 'porterjevaughn@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'jevaughn.porter',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-27T02:13:43.000Z',
        amountCents: 5297
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '69ca68d9-66d8-452d-9737-3669f1bf251e',
      content: [],
      creator: {
        id: 'c664131d-fc57-4beb-b94b-60d9707b0090',
        firstName: 'Tiffany',
        lastName: 'Bailey',
        email: 'tbailey92@live.com',
        instagramProfile: {
          handle: 'di_rebelchild',
          followers: 1669,
          engagementRate: 0.9586578789694428
        },
        tiktokProfile: {
          handle: 'lilrebel_child',
          followers: 223,
          engagementRate: 7.352941176470589
        },
        youtubeProfile: {
          handle: 'hottiffa',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-27T06:15:26.000Z',
        amountCents: 3903
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'c18df665-ef16-43aa-838e-73091aac5f8e',
      content: [],
      creator: {
        id: 'c2efcdd6-263a-40ba-beea-04d59bce757c',
        firstName: 'Crystal',
        lastName: 'Coldspring',
        email: 'crissy_ck2@yahoo.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'cryssiiboo',
          followers: 732,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-27T10:04:11.000Z',
        amountCents: 3494
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'eb8a4796-f73e-4643-9862-4e9a8c207fe0',
      content: [],
      creator: {
        id: '53fa0848-43bd-4b61-8505-0886fc76722b',
        firstName: 'Crystalyn',
        lastName: 'Hawkes',
        email: 'crystalyn.ayala@aol.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'crystalynhawkes',
          followers: 92,
          engagementRate: 13.681592039800993
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-27T12:41:58.000Z',
        amountCents: 4299
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '0a425ab8-017d-4313-93a7-98193b00f5c0',
      content: [],
      creator: {
        id: 'dacbcc49-f8ba-4c1c-9ee4-9fd00979d290',
        firstName: 'Veronique',
        lastName: 'Edmonson',
        email: 'verhype@yahoo.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'verhype101',
          followers: 189,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-27T14:21:04.000Z',
        amountCents: 3815
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '25ba549c-e537-4d1d-bea5-4fe9ce5c1272',
      content: [],
      creator: {
        id: '8aaeefb6-5019-44ae-b2a7-84347d5d9f28',
        firstName: 'Alecia',
        lastName: 'Speid',
        email: 'aleciaspeid3@gmail.com',
        instagramProfile: {
          handle: 'aleciakayyy',
          followers: 9,
          engagementRate: null
        },
        tiktokProfile: {
          handle: 'aleciaqueen12',
          followers: 275,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'allthingsalecia5730',
          followers: 2,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-27T14:54:38.000Z',
        amountCents: 3399
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'ece7db84-8e32-4001-9724-272d989062ad',
      content: [],
      creator: {
        id: 'e1c1c7fd-2a8e-499b-a2a3-2e2026f76ad5',
        firstName: 'Amanda Rose',
        lastName: 'Carter',
        email: 'amandatip56@gmail.com',
        instagramProfile: {
          handle: 'queen_amanda_rose',
          followers: 1814,
          engagementRate: 1.5821389195148843
        },
        tiktokProfile: {
          handle: 'amandarosecarter',
          followers: 1447,
          engagementRate: 6.418918918918918
        },
        youtubeProfile: {
          handle: 'amandatipaldo9934',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-27T16:16:23.000Z',
        amountCents: 5239
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '22dcdf97-9fc5-4638-b09d-d1cb4657a5b6',
      content: [],
      creator: {
        id: '70e3af80-ece3-4db3-9f82-4d7d20b8be17',
        firstName: 'Ketrin',
        lastName: 'Nadala',
        email: 'ketrinlove8888@gmail.com',
        instagramProfile: {
          handle: 'kateryna_nadala',
          followers: 899,
          engagementRate: null
        },
        tiktokProfile: {
          handle: 'user5626065223562',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-27T16:17:14.000Z',
        amountCents: 4537
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'b5033404-acac-433f-9ecd-f99bec7bfa2b',
      content: [],
      creator: {
        id: '9ca66607-bf69-473c-aecd-7678f12a27e0',
        firstName: 'Vasyl',
        lastName: 'Nadala',
        email: 'vasulhadala@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'katya9918',
          followers: 4,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-27T16:26:26.000Z',
        amountCents: 4206
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '61e76b20-3620-4d43-96cb-220d45b0d6ee',
      content: [],
      creator: {
        id: '7e0dadad-0438-4c42-8fae-bf1d288f7396',
        firstName: 'Saima',
        lastName: 'Kazmi',
        email: 'saimakazm@gmail.com',
        instagramProfile: {
          handle: 'sbkaqk',
          followers: 323,
          engagementRate: 2.5806451612903225
        },
        tiktokProfile: {
          handle: 'sbkaqk',
          followers: 16,
          engagementRate: 0
        },
        youtubeProfile: {
          handle: 'saimakazm',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-27T16:26:39.000Z',
        amountCents: 4053
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'c1625639-0bb2-47dd-b2d3-06a905a16497',
      content: [],
      creator: {
        id: 'b1fede61-6f97-462d-a30c-17e0460632f8',
        firstName: 'Bri',
        lastName: 'Benson',
        email: 'briannabenson13@aol.com',
        instagramProfile: {
          handle: 'notnarcs',
          followers: 1356,
          engagementRate: 7.374631268436578
        },
        tiktokProfile: null,
        youtubeProfile: {
          handle: 'notnarcs-co8947',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-27T16:29:18.000Z',
        amountCents: 4617
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '8e94be81-a3bd-4dce-9ad5-41132dee53fd',
      content: [],
      creator: {
        id: '89355690-1e38-4e39-9d56-6eba60e68894',
        firstName: 'Chanice',
        lastName: 'Palmer',
        email: 'herhairslyque@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'influxttovi',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-27T16:32:15.000Z',
        amountCents: 4529
      },
      status: 'INCOMPLETE',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '58cb8a4a-86fb-42db-ae71-c57a5a882ebd',
      content: [],
      creator: {
        id: 'a4e600aa-15b1-406b-b869-61d1cf82c65d',
        firstName: 'Aaliyah',
        lastName: 'Thompson',
        email: 'kianat983@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'missblkgirlinstem',
          followers: 2498,
          engagementRate: 18.322378556023416
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-27T16:34:06.000Z',
        amountCents: 4505
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '9991caa9-beae-4572-a333-68031e4a5c4b',
      content: [],
      creator: {
        id: 'a4344819-6ab3-4014-a2f8-ea44a84007c1',
        firstName: 'Jennifer',
        lastName: 'Martinez',
        email: 'jennifer_martinez36@yahoo.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'jnieeboo',
          followers: 1515,
          engagementRate: 11.830985915492958
        },
        youtubeProfile: {
          handle: 'patito36',
          followers: 6,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-27T16:47:04.000Z',
        amountCents: 4177
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '425ec281-609c-4b06-ac54-b75fb123a47a',
      content: [],
      creator: {
        id: '83fe52eb-c0be-4eb9-a884-eb8b569f5b03',
        firstName: 'Avion',
        lastName: 'Buchanan',
        email: 'buchananavion390@gmail.com',
        instagramProfile: {
          handle: '_brii_han_na_',
          followers: 5720,
          engagementRate: 0.1048951048951049
        },
        tiktokProfile: {
          handle: 'glockpack4',
          followers: 26,
          engagementRate: 3.5464079923724245
        },
        youtubeProfile: {
          handle: 'briisworld',
          followers: 123,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-27T17:20:44.000Z',
        amountCents: 4199
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '8853abbf-f1d4-448c-970d-d854016d5c6d',
      content: [],
      creator: {
        id: '942e30ff-44fb-4d98-b56f-566a3aa61e9d',
        firstName: 'Lesa',
        lastName: 'Hylton',
        email: 'attitudeprincess2121@gmail.com',
        instagramProfile: {
          handle: 'kayz_beautynglamour',
          followers: 86,
          engagementRate: 2.713178294573644
        },
        tiktokProfile: {
          handle: 'kaykay_influencer',
          followers: 8565,
          engagementRate: 22.14847629217086
        },
        youtubeProfile: {
          handle: 'mortalbabe6600',
          followers: 14,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-27T17:42:36.000Z',
        amountCents: 4290
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '1dedef58-0931-4e75-aeaf-37bba81df9be',
      content: [],
      creator: {
        id: '428499a0-e529-43bf-8367-067fc38ce213',
        firstName: 'Diane',
        lastName: 'Benson',
        email: 'dianebnsn@aol.com',
        instagramProfile: {
          handle: 'texasguadaloop',
          followers: 1293,
          engagementRate: 3.8533834586466162
        },
        tiktokProfile: null,
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-27T17:51:43.000Z',
        amountCents: 4240
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '562e67dd-8466-436e-93da-f133e3a4fa24',
      content: [],
      creator: {
        id: 'cd7d2f0c-4019-46d0-9ae7-d3e6acfa94db',
        firstName: 'Hanna',
        lastName: 'Wagner',
        email: 'hannawagner979@gmail.com',
        instagramProfile: {
          handle: 'high__hanna',
          followers: 245,
          engagementRate: 6.9164265129683
        },
        tiktokProfile: {
          handle: 'high_hanna',
          followers: 1592,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'hannawagner5377',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-27T18:26:10.000Z',
        amountCents: 4577
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'ac15e9e9-fdc1-46e3-b60d-4c2618389d2b',
      content: [],
      creator: {
        id: 'c561c6b3-5047-48b5-a525-2341a77ed18f',
        firstName: 'Eric',
        lastName: 'Anderson',
        email: '28ejande@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'ericcc_aaa',
          followers: 248,
          engagementRate: 11.960132890365449
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-27T19:16:29.000Z',
        amountCents: 6315
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '2929a651-3a4e-4f08-9618-318af3b6b1ab',
      content: [],
      creator: {
        id: '6fb51a23-f119-4efd-8362-0d13f16e5830',
        firstName: 'Monique',
        lastName: 'Mccalla',
        email: 'mccallamonique41@gmail.com',
        instagramProfile: {
          handle: 'annette_kids_boutique_and_more',
          followers: 317,
          engagementRate: 3.4700315457413247
        },
        tiktokProfile: {
          handle: 'mo_nique_m',
          followers: 1019,
          engagementRate: 16.142557651991616
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-27T19:52:33.000Z',
        amountCents: 4240
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'f83da7fe-f18d-45c4-9b03-5a055c1772f7',
      content: [],
      creator: {
        id: 'cf40dce8-ba7e-4827-bd38-f001d01d629b',
        firstName: 'Davian',
        lastName: 'Smith',
        email: 'daviansmith613@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'chef_davian',
          followers: 577,
          engagementRate: 6.940874035989718
        },
        youtubeProfile: {
          handle: 'daviansmith8536',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-27T19:55:08.000Z',
        amountCents: 4998
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '2e14c127-4267-4ae1-a9c4-b3429f99454c',
      content: [],
      creator: {
        id: '214900b2-5b65-4f1e-aa89-bc4d591f85e4',
        firstName: 'Elzara',
        lastName: 'Isliamova',
        email: 'eemirasanovaa@gmail.com',
        instagramProfile: {
          handle: 'islyamova_zara',
          followers: 1642,
          engagementRate: 45.401948842874546
        },
        tiktokProfile: {
          handle: 'zara_cook_',
          followers: 1556,
          engagementRate: 2.3121019578052224
        },
        youtubeProfile: {
          handle: 'zara-e',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-27T20:15:45.000Z',
        amountCents: 4611
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'a0befb96-8e31-4362-845e-2288198f5ee8',
      content: [],
      creator: {
        id: 'd2a15f64-b1f2-472d-a259-9d88dd25f32c',
        firstName: 'Cherstan',
        lastName: 'Gilzene',
        email: 'cherstangilzene6@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'chrissy.thompson93',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'jollyrancher5909',
          followers: 10,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-27T20:25:42.000Z',
        amountCents: 3399
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'ba6adea4-54e5-469e-95ea-e9668346cce0',
      content: [],
      creator: {
        id: 'c0c793ac-74ba-47ce-82f7-c19fcaeda940',
        firstName: 'Nataliia',
        lastName: 'Dehtiarova',
        email: 'mikki.o.nikki@gmail.com',
        instagramProfile: {
          handle: 'high.heelsua',
          followers: 423,
          engagementRate: 7.722616233254531
        },
        tiktokProfile: {
          handle: 'mikki_o_nikki',
          followers: 11354,
          engagementRate: 3.344027537817809
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-27T21:07:06.000Z',
        amountCents: 4232
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '38336a0f-0e89-4344-9dc3-2a5173cd09c2',
      content: [],
      creator: {
        id: '9e085062-6712-4e60-a449-b169b5ab256a',
        firstName: 'Britany',
        lastName: 'Bruce',
        email: 'abigailbbruce17@yahoo.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'brit__a.n.y',
          followers: 400,
          engagementRate: 10.526315789473683
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-27T22:17:05.000Z',
        amountCents: 3399
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '79dbdb48-684e-4f95-82fc-84f39bd08e8a',
      content: [],
      creator: {
        id: 'c6a66383-e968-48be-871e-29d7aac2ed85',
        firstName: 'Anna',
        lastName: 'Delgado',
        email: 'ugcprincesss@gmail.com',
        instagramProfile: {
          handle: 'ugcprincess',
          followers: 1142,
          engagementRate: 6.282837127845885
        },
        tiktokProfile: {
          handle: 'annnaaamariaaa',
          followers: 4334,
          engagementRate: 1.557890374372592
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-27T22:52:52.000Z',
        amountCents: 4611
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '148f6e5c-66aa-464a-af3a-a15f54f30878',
      content: [],
      creator: {
        id: '570561e4-a87d-4835-a5ee-2614e8f98393',
        firstName: 'Kristina',
        lastName: 'Pashchenko',
        email: 'kristina.546457@gmail.com',
        instagramProfile: {
          handle: 'miss.kyforak',
          followers: 338,
          engagementRate: 2.921597633136095
        },
        tiktokProfile: {
          handle: 'miss.kyforak',
          followers: 174,
          engagementRate: 3.3605174353205847
        },
        youtubeProfile: {
          handle: 'kristinapashchenko4844',
          followers: 2,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-27T23:10:52.000Z',
        amountCents: 4740
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '453e86dd-62fa-4934-936d-a7807517c5e4',
      content: [],
      creator: {
        id: '409eb2b8-0abc-47b7-9549-5b0912d73cf3',
        firstName: 'Tetiana',
        lastName: 'Bobyr',
        email: 'taniadidovuch@yahoo.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'user85608612635332',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'user-ln1uh4wc4t',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-28T04:16:11.000Z',
        amountCents: 4200
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'a5e1e6a7-d7ba-474c-a2ac-390c0e87be86',
      content: [],
      creator: {
        id: '028bb2d4-1a24-42fd-bef2-77c1e5dbe448',
        firstName: 'selena',
        lastName: 'vassell',
        email: 'selenavassell616@gmail.com',
        instagramProfile: {
          handle: 'flawless_wigs_and_extentions',
          followers: 134,
          engagementRate: 1.4925373134328357
        },
        tiktokProfile: {
          handle: 'selena22779',
          followers: 2557,
          engagementRate: 26.804123711340207
        },
        youtubeProfile: {
          handle: 'browneyeslena2398',
          followers: 1,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-28T09:14:23.000Z',
        amountCents: 4299
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '0ceedfa4-912f-4943-bf23-70b4edfa1c9d',
      content: [],
      creator: {
        id: 'f8de43ad-e912-4f86-b245-541797d16178',
        firstName: 'Karyna',
        lastName: 'Kuzmych',
        email: 'karinahrebinevych@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'karyna_22k',
          followers: 12,
          engagementRate: 0.6028636021100227
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-28T10:53:58.000Z',
        amountCents: 4611
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '50e28b11-8df2-4852-8db7-f269e2675093',
      content: [],
      creator: {
        id: '726c93d1-e908-4dad-8318-d0433c3b6ce0',
        firstName: 'Veronika',
        lastName: 'Iliushchenko',
        email: 'veronika.ilyshenko@gmail.com',
        instagramProfile: {
          handle: 'veronika_ilyshenko',
          followers: 417,
          engagementRate: 2.661870503597122
        },
        tiktokProfile: {
          handle: 'veronikail45',
          followers: 104,
          engagementRate: 1.0870967363643826
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-28T11:23:36.000Z',
        amountCents: 4186
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '25d6f60c-188e-4945-a8e1-0db662a8a0bd',
      content: [],
      creator: {
        id: '93718d3a-d9cd-46e8-aa53-af28246cf094',
        firstName: 'Keyona',
        lastName: 'Toney',
        email: 'keyona.toney@yahoo.com',
        instagramProfile: {
          handle: 'keke_taughtu',
          followers: 527,
          engagementRate: 14.421252371916509
        },
        tiktokProfile: {
          handle: 'keke_taughtu',
          followers: 44,
          engagementRate: 3.4129692832764507
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-28T14:23:14.000Z',
        amountCents: 4299
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '3430a6be-96f3-4fc4-8630-b18a9a65f61a',
      content: [],
      creator: {
        id: '8be0bd62-2cb3-45de-95b7-e0d5b87910b5',
        firstName: 'Snizhana',
        lastName: 'Malchenko',
        email: 'malchenkosnezhana10@gmail.com',
        instagramProfile: {
          handle: 'snizhanaaa10',
          followers: 753,
          engagementRate: 14.475431606905712
        },
        tiktokProfile: {
          handle: 'snezhanaaa10',
          followers: 719,
          engagementRate: 12.173913043478262
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-28T14:41:19.000Z',
        amountCents: 4299
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '93d49907-f20d-4937-b918-45a97b18bd50',
      content: [],
      creator: {
        id: '1bcc7557-4cf6-42b6-a008-744ed9ca36da',
        firstName: 'Tara',
        lastName: 'Smith',
        email: 'diva-patrol.0v@icloud.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'tarabertone',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'tm-reviews',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-28T15:31:56.000Z',
        amountCents: 4154
      },
      status: 'INCOMPLETE',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '5c87dae2-54d4-4079-8cba-de11c1a78937',
      content: [],
      creator: {
        id: '67626eef-d1c5-4f86-acfb-1c5c8360adfd',
        firstName: 'Cassandra',
        lastName: 'Croft',
        email: 'cassiecroft9374@gmail.com',
        instagramProfile: {
          handle: 'obsessed_clean_beauty',
          followers: 838,
          engagementRate: 1.7064439140811456
        },
        tiktokProfile: {
          handle: 'obsessedcleanbeauty',
          followers: 1048,
          engagementRate: 10.144927536231885
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-28T16:13:33.000Z',
        amountCents: 4568
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '914af941-3744-4fe2-bb5d-fd939c717268',
      content: [],
      creator: {
        id: '2a00927e-11c2-4847-8dc0-2469021d0d15',
        firstName: 'Alina',
        lastName: 'Battalova',
        email: 'alinaodair@gmail.com',
        instagramProfile: {
          handle: 'alinaodair.ugc',
          followers: 1737,
          engagementRate: 2.072538860103627
        },
        tiktokProfile: {
          handle: 'alinaodair',
          followers: 685,
          engagementRate: 4.545454545454546
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-28T16:14:27.000Z',
        amountCents: 5844
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'ffa42f3e-b401-4ada-ba49-feddfb184d5f',
      content: [],
      creator: {
        id: 'f44e665f-eca9-4c2d-9ec8-cd5892c495dd',
        firstName: 'Roxanne',
        lastName: 'Murray',
        email: 'roxymurry71@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'krissyjt',
          followers: 78,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-28T16:17:06.000Z',
        amountCents: 8598
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '1fbe9dcf-f721-4b1b-8e2d-01fc488d4b25',
      content: [],
      creator: {
        id: '25be72cb-dce9-4e30-a380-05c4bd60dd21',
        firstName: 'Anastasiia',
        lastName: 'Kalvarovska',
        email: 'kalvarovskanastya52@gmail.com',
        instagramProfile: {
          handle: 'nastiakk52',
          followers: 71,
          engagementRate: 10.140845070422536
        },
        tiktokProfile: {
          handle: 'nastiakk52',
          followers: 187,
          engagementRate: 5.468649524577092
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-28T16:18:01.000Z',
        amountCents: 5650
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '245d4212-f193-4475-a08c-9733d31802af',
      content: [],
      creator: {
        id: '16c92ce7-c560-41fc-b883-1850744a951e',
        firstName: 'Dana',
        lastName: 'Fergz',
        email: 'ferguson.daeyna@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'melanin_bae26',
          followers: 546,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-28T16:26:13.000Z',
        amountCents: 4232
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'cd787daf-14b3-41f3-a289-93c0ff34e35b',
      content: [],
      creator: {
        id: 'bdad6d58-9060-44dd-960d-01cae39b4144',
        firstName: 'Damonique',
        lastName: 'Parkinson',
        email: 'parkinsondamonique@gmail.com',
        instagramProfile: {
          handle: 'niqueofficial__',
          followers: 1902,
          engagementRate: 1.4458464773922188
        },
        tiktokProfile: {
          handle: 'its__niqueofficial',
          followers: 1466,
          engagementRate: 10.903426791277258
        },
        youtubeProfile: {
          handle: 'lifewithnique4707',
          followers: 197,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-28T16:27:29.000Z',
        amountCents: 8500
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '2f55e583-72d1-47b8-98bd-2d3acb98ba6d',
      content: [],
      creator: {
        id: 'f3083390-6a45-4a24-aadf-7b50de700ee4',
        firstName: 'Tasheika',
        lastName: 'Hylton',
        email: 'thyltoncampbell@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'frozen_queen_iceucg',
          followers: 951,
          engagementRate: 24.137931034482758
        },
        youtubeProfile: {
          handle: 'tasheikahylton5230',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-28T16:48:08.000Z',
        amountCents: 4299
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '18bc8c62-1e40-4136-8495-de73fd25a532',
      content: [],
      creator: {
        id: '348e2935-9720-4182-93fd-a89780750f8b',
        firstName: 'Alliea',
        lastName: 'Simpson',
        email: 'allieasimpson4@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: '4allywayy',
          followers: 3977,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-28T16:53:05.000Z',
        amountCents: 5206
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '38282de6-d3a5-47f9-80fc-ed4fdd113e3c',
      content: [],
      creator: {
        id: 'ba46d6b2-e618-40f4-bd83-59a76e5262b0',
        firstName: 'Gabriella',
        lastName: 'Stanbury',
        email: 'parisstanbury@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'gabbyyyyyy___',
          followers: 465,
          engagementRate: 6.925740669488264
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-28T17:25:50.000Z',
        amountCents: 4199
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'b1dac13f-bf47-4064-abb5-b83706fbc6ba',
      content: [],
      creator: {
        id: 'b55cb4e0-bba1-4353-b441-bcf8278d7d17',
        firstName: 'Aneisha',
        lastName: 'Hylton',
        email: 'sionplace@hotmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'caribbeankitchenwithsio1',
          followers: 390,
          engagementRate: 5.971277399848828
        },
        youtubeProfile: {
          handle: 'caribbeankitchenwithsionhy',
          followers: 1080,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-28T17:26:25.000Z',
        amountCents: 4154
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'a73b79c4-1eff-4801-b5be-d8c531006aaa',
      content: [],
      creator: {
        id: '36d47444-f5dc-48e6-ac57-a1e3694ef492',
        firstName: 'Jay',
        lastName: 'Thakker',
        email: 'jd.thakker@gmail.com',
        instagramProfile: {
          handle: 'thakr29',
          followers: 979,
          engagementRate: 9.560776302349336
        },
        tiktokProfile: null,
        youtubeProfile: {
          handle: 'mrdesidj',
          followers: 849,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-28T17:42:49.000Z',
        amountCents: 4163
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '860a86ea-477f-42c3-88a4-378f427dd25d',
      content: [],
      creator: {
        id: '998c7a0b-4175-4e3b-be49-eff7da2ecc2c',
        firstName: 'Taylor',
        lastName: 'Wade',
        email: 'doingmybesttok6@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'doingmybest4',
          followers: 1329,
          engagementRate: 15.135787674250567
        },
        youtubeProfile: {
          handle: 'doingmybestt8',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-28T17:43:47.000Z',
        amountCents: 4654
      },
      status: 'INCOMPLETE',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '3e9df187-1482-4736-9473-478b6e471140',
      content: [],
      creator: {
        id: 'd68d4d95-681b-406c-a14d-907d811378cc',
        firstName: 'Tia',
        lastName: 'Doyley',
        email: 'tiadoyley553@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'pinkey_not_so_friendly',
          followers: 9139,
          engagementRate: 12.105263157894736
        },
        youtubeProfile: {
          handle: 'princessniah-yd4wz',
          followers: 13,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-28T17:45:34.000Z',
        amountCents: 5338
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '90bba560-e474-40be-b802-a37d5614c4e0',
      content: [],
      creator: {
        id: '4ca6c0c9-a4df-4803-b3ba-13e18e4b7880',
        firstName: 'Valecian',
        lastName: 'Hines',
        email: 'valecianvenhines@yahoo.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'valecianlifestyle',
          followers: 1730,
          engagementRate: 3.125
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-28T18:06:28.000Z',
        amountCents: 5173
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'e193ccee-0467-4d5e-aa21-9d7e7e8a1086',
      content: [],
      creator: {
        id: 'c6cc3f5f-66ed-41e9-967e-8306dacf17a0',
        firstName: 'Molly',
        lastName: 'McNeely',
        email: 'mollyecom@gmail.com',
        instagramProfile: {
          handle: 'mollydeann23',
          followers: 1746,
          engagementRate: 0.036273386788850705
        },
        tiktokProfile: {
          handle: 'gooseprobably',
          followers: 1098,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'ugcbymolly',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-28T19:45:55.000Z',
        amountCents: 4528
      },
      status: 'INCOMPLETE',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'db6ccf94-febd-41f8-a88b-245147e6e517',
      content: [],
      creator: {
        id: '25bcac50-7d99-4a37-bfb1-7e0975de797c',
        firstName: 'Carolyn',
        lastName: 'Allen',
        email: 'allencarolyn95@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'c.allen59',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-28T20:24:29.000Z',
        amountCents: 7761
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '296707b1-9ca0-49db-84bc-3ee442d2badb',
      content: [],
      creator: {
        id: 'd06c7a89-7f30-47f5-af06-4fe9bbedec9a',
        firstName: 'Breanna ',
        lastName: 'Bowley ',
        email: 'breannatbowley@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'breannatiffani',
          followers: 503,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'breannabowley9592',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-28T20:36:08.000Z',
        amountCents: 4539
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'a32a3a95-a047-4fe4-95f7-338452464ce9',
      content: [],
      creator: {
        id: 'a427bdaf-312c-44dc-ba6e-e34a0b9417a5',
        firstName: 'Samantha ',
        lastName: 'Polson',
        email: 'samantha.polson@icloud.com',
        instagramProfile: {
          handle: 'chante._sam',
          followers: 889,
          engagementRate: null
        },
        tiktokProfile: {
          handle: 'chante_sam',
          followers: 678,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-28T21:36:58.000Z',
        amountCents: 4199
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '9de1280e-16ef-497b-af98-1c0ba46765e8',
      content: [],
      creator: {
        id: '0618a48c-02fa-422e-89ba-27f4958cc008',
        firstName: 'Susan',
        lastName: 'Yang',
        email: 'yang.sue03@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'noodlenbean',
          followers: 49,
          engagementRate: 14.98800959232614
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-28T22:24:01.000Z',
        amountCents: 4512
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '261a8ef1-163b-4f9e-839c-ce5ef2fc5e59',
      content: [],
      creator: {
        id: '6c73c173-02e7-4b73-85aa-71375a1a7c67',
        firstName: 'Danike',
        lastName: 'Graham',
        email: 'uniquedanike@gmail.com',
        instagramProfile: {
          handle: 'uniquedanike',
          followers: 1370,
          engagementRate: 1.437956204379562
        },
        tiktokProfile: {
          handle: 'uniquedanike',
          followers: 3403,
          engagementRate: 10.560964786131441
        },
        youtubeProfile: {
          handle: 'uniquedanike6494',
          followers: 587,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-28T23:25:34.000Z',
        amountCents: 5298
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '4d4f2c8e-ed5f-4486-b63b-a418f69f10f8',
      content: [],
      creator: {
        id: '51fb79f4-7ab7-42a3-b7f7-7d606fa31a1a',
        firstName: 'Sharlene',
        lastName: 'Mcbean',
        email: 'sharlenejames01@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'mz_legendary01',
          followers: 9792,
          engagementRate: 11.647816740377637
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-29T00:24:13.000Z',
        amountCents: 3815
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '93bdb8c2-614d-49d5-a98b-283cdaffe9c9',
      content: [],
      creator: {
        id: '57aad76b-d13f-4cb8-a01c-a2162e8bd3a1',
        firstName: 'Jose',
        lastName: 'Correa',
        email: 'eduardo.ortiz777@gmail.com',
        instagramProfile: {
          handle: 'innerdemonsco',
          followers: 151,
          engagementRate: 14.6
        },
        tiktokProfile: {
          handle: 'josekelly77',
          followers: 3954,
          engagementRate: 2.1353251710897445
        },
        youtubeProfile: {
          handle: 'eduardortiz777',
          followers: 7,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-29T02:42:33.000Z',
        amountCents: 4082
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'ca47508e-dfea-4d4f-8499-45cb6b8a6520',
      content: [],
      creator: {
        id: '41ff8e45-d5aa-4457-91f8-51e6bf5a8816',
        firstName: 'Xuan',
        lastName: 'Cao',
        email: 'kaijucat43@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'thatssocutesy',
          followers: 16,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-29T03:25:20.000Z',
        amountCents: 4044
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '50e250f8-c0e5-46f0-8c57-36aef367c6f3',
      content: [],
      creator: {
        id: '22fe917f-c221-4c45-a01e-a8a64bd97121',
        firstName: 'Toniann',
        lastName: 'Frazer',
        email: 'samanthafraser76@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'toeknee_.hand',
          followers: 615,
          engagementRate: 9.64334739946108
        },
        youtubeProfile: {
          handle: 'toni-ann__',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-29T06:06:00.000Z',
        amountCents: 4299
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'bb60dbf2-b0dc-4bbc-974a-7e0b57ebeb93',
      content: [],
      creator: {
        id: '6480e605-2f51-4fda-9fcc-f1bed4285823',
        firstName: 'Lauren',
        lastName: 'Dawson',
        email: 'laurenmu95@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'luxeslife',
          followers: 1171,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-29T06:36:07.000Z',
        amountCents: 4073
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '35e8c2e6-d49b-4ab6-ac78-1885aeff87ce',
      content: [],
      creator: {
        id: '84615fe5-39bd-40af-9db1-2bbb13badd93',
        firstName: 'Ihesha',
        lastName: 'Brown',
        email: 'partnerwithihesha@gmail.com',
        instagramProfile: {
          handle: 'iheshab',
          followers: 1658,
          engagementRate: null
        },
        tiktokProfile: {
          handle: 'iheshaab',
          followers: 1994,
          engagementRate: 13.497822931785198
        },
        youtubeProfile: {
          handle: 'iheshabrown9007',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-29T08:34:02.000Z',
        amountCents: 4998
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '23d4b2e4-ee2a-4db3-b0d1-5f817198002c',
      content: [],
      creator: {
        id: 'a6a7a07e-6a90-4525-9122-fb021cc8a3b8',
        firstName: 'Julio',
        lastName: 'Cabanilla',
        email: 'julioacabanilla@hotmail.com',
        instagramProfile: {
          handle: 'julioacabanilla',
          followers: 292,
          engagementRate: null
        },
        tiktokProfile: {
          handle: 'juliocabanilla449',
          followers: 61,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'julioacabanilla1',
          followers: 1,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-29T12:29:47.000Z',
        amountCents: 3815
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '4281f5ee-4a65-4bb1-8a74-af97dbf2b6cb',
      content: [],
      creator: {
        id: 'e1ed3b9a-7b84-4ee2-955a-f3b7e85d4565',
        firstName: 'Yanique',
        lastName: 'St Rosier',
        email: 'shawndranique@gmail.com',
        instagramProfile: {
          handle: 'drenique_lashes_hair_and_more',
          followers: 430,
          engagementRate: 7.480620155038759
        },
        tiktokProfile: {
          handle: 'queen3pleb',
          followers: 2559,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-29T13:36:18.000Z',
        amountCents: 4003
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'ed993bc6-5a84-4fde-9882-dde22522dc80',
      content: [],
      creator: {
        id: '2166cb19-2276-4232-bc73-71364d2300ca',
        firstName: 'Regeana ',
        lastName: 'Woolcock',
        email: 'regeanawoolcock90@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: '_theyluvv.reg_',
          followers: 1386,
          engagementRate: 21.435059037238872
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-29T13:45:29.000Z',
        amountCents: 4494
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '7620bc7f-b6f9-4edc-81e4-6d19c7c8547c',
      content: [],
      creator: {
        id: '66114cfd-5a57-4a6b-9e19-14021cd0397c',
        firstName: 'Dee',
        lastName: 'Black',
        email: 'dejablair5@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'officialslimi.anna',
          followers: 167,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'chefdedrii',
          followers: 12,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-29T15:54:01.000Z',
        amountCents: 7401
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'db7b42d5-a77a-418e-8532-36dc88e50e65',
      content: [],
      creator: {
        id: '647215fd-f4b3-4f79-aa8d-bdf1cc3b6fbe',
        firstName: 'Aciana S',
        lastName: 'Small',
        email: 'aprilbeauty3348@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'workfromhomejobs2',
          followers: 4,
          engagementRate: 1.9905852636413215
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-29T16:34:02.000Z',
        amountCents: 11377
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '5825ef21-31b6-4674-9ab9-5f506d1bcfd4',
      content: [],
      creator: {
        id: '781a96eb-4b6d-4fcd-974c-2ea31fad78a9',
        firstName: 'Charlie',
        lastName: 'Beaber',
        email: 'shopwcharlie@gmail.com',
        instagramProfile: {
          handle: 'shopping_with_charlie',
          followers: 34,
          engagementRate: 19.607843137254903
        },
        tiktokProfile: {
          handle: 'shopping_with_char_',
          followers: 13,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'shoppingwithcharlie',
          followers: 1,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-29T18:45:49.000Z',
        amountCents: 4646
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'e4ba435d-aecb-426d-a492-fd7940e8cb81',
      content: [],
      creator: {
        id: 'ecb66801-b91a-46f0-aa85-437dbf9de869',
        firstName: 'Jonalyne',
        lastName: 'Dimaranan',
        email: 'jonalynedimaranan@yahoo.com.ph',
        instagramProfile: {
          handle: 'jjjoonnaalovesu',
          followers: 999,
          engagementRate: null
        },
        tiktokProfile: null,
        youtubeProfile: {
          handle: 'jonalynedimaranan3648',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-29T20:16:50.000Z',
        amountCents: 5432
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'f908af81-c4b4-4ebc-87a6-8bdf8b7cf90d',
      content: [],
      creator: {
        id: 'a5137d58-0948-4376-a635-c0e7ca0b6b73',
        firstName: 'Shara',
        lastName: 'Harris',
        email: 'sharaharris22@gmail.com',
        instagramProfile: {
          handle: 'zen_cuppycake_',
          followers: 1223,
          engagementRate: null
        },
        tiktokProfile: {
          handle: 'lady_sharris',
          followers: 2302,
          engagementRate: 11.820652173913043
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T01:20:00.000Z',
        amountCents: 4240
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '1a0109b1-2934-4cfd-96e7-d4330dbab63b',
      content: [],
      creator: {
        id: '24981c92-0b50-41f4-bd69-9c23de7e385c',
        firstName: 'Amanda',
        lastName: 'Johnson',
        email: 'amandanovajohnson@gmail.com',
        instagramProfile: {
          handle: '_natali.eee__',
          followers: 1186,
          engagementRate: 0.8431703204047217
        },
        tiktokProfile: {
          handle: '_natali.eee__',
          followers: 7482,
          engagementRate: 0.23396373562097877
        },
        youtubeProfile: {
          handle: 'amandajohnson1626',
          followers: 47,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T06:34:36.000Z',
        amountCents: 4120
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'ccbd660a-dcc0-45ef-83dc-d3ebbdb4beea',
      content: [],
      creator: {
        id: '9942ea0a-1316-446c-9418-4de9ec2476c5',
        firstName: 'Ashante ',
        lastName: 'Lewin',
        email: 'ashantelewin13@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'mochaprincesss_',
          followers: 938,
          engagementRate: 7.777777777777778
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T09:32:42.000Z',
        amountCents: 4299
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '83c619e0-8e6a-4b50-a8d6-72b9f88511f9',
      content: [],
      creator: {
        id: 'f0c1d9a6-0e66-4147-8f34-ea38f6056c72',
        firstName: 'Sashay',
        lastName: 'Coore',
        email: 'sashaycoore.content@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'b.sashh',
          followers: 782,
          engagementRate: 2.6361910325690086
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T09:34:23.000Z',
        amountCents: 3903
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '143dca99-dee7-432e-9331-e9c1511392c3',
      content: [],
      creator: {
        id: 'ff45ca9e-70c7-4a76-aec6-7b57bd47b0b7',
        firstName: 'Shariek',
        lastName: 'Brown',
        email: 'shariek2001@yahoo.com',
        instagramProfile: {
          handle: 'shariekk',
          followers: 6909,
          engagementRate: null
        },
        tiktokProfile: {
          handle: '1shariek',
          followers: 2287,
          engagementRate: 10.013908205841446
        },
        youtubeProfile: {
          handle: 'simplyshariek1344',
          followers: 251,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T11:54:04.000Z',
        amountCents: 4240
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '8dac087e-49fc-4878-92fa-027e1d5a7e8c',
      content: [],
      creator: {
        id: 'e054f615-7585-4aef-af69-ddde82923f5d',
        firstName: 'Latoya',
        lastName: 'Hylton',
        email: 'kaydianhylton9@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'latoyahylton693',
          followers: 21,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T12:21:55.000Z',
        amountCents: 3815
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'adfb8793-0624-4deb-aaec-94b4b052a324',
      content: [],
      creator: {
        id: '6a0efba0-5f16-490a-a9da-3298dd4d54cb',
        firstName: 'Keyton',
        lastName: 'Woolcock',
        email: 'shawtyddon@gmail.com',
        instagramProfile: {
          handle: 'jaynell_contents',
          followers: 688,
          engagementRate: 1.4534883720930232
        },
        tiktokProfile: {
          handle: 'shawty_ddon',
          followers: 2449,
          engagementRate: 27.421758569299552
        },
        youtubeProfile: {
          handle: 'janiellerobinson1030',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T14:04:04.000Z',
        amountCents: 4998
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '30bfdb95-0e2a-471c-a5a9-b1332b92c462',
      content: [],
      creator: {
        id: '01b08883-3bf1-4908-b58b-10f6634abe05',
        firstName: 'Jerri',
        lastName: 'Gizzarelli',
        email: 'jgizzarelli@icloud.com',
        instagramProfile: {
          handle: 'jerseyann540',
          followers: 1640,
          engagementRate: 3.536585365853658
        },
        tiktokProfile: {
          handle: 'jerseyx3',
          followers: 344,
          engagementRate: 7.161125319693094
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T16:14:53.000Z',
        amountCents: 4017
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'e087d361-896d-4304-bb67-7f513f96084f',
      content: [],
      creator: {
        id: '7f7b5095-ec4c-46fe-b62d-ed1aca901855',
        firstName: 'Shadee',
        lastName: 'Clarke',
        email: 'shadeerenee876@yahoo.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'shadee.renee',
          followers: 7037,
          engagementRate: 5.335696785135696
        },
        youtubeProfile: {
          handle: 'lifewithshadeerenee',
          followers: 16,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T16:15:56.000Z',
        amountCents: 3815
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '6732c384-d14d-489f-a1ed-1f602f2fc1d8',
      content: [],
      creator: {
        id: '0691082c-c4c3-423a-997d-ab01b1307f2b',
        firstName: 'Ruby',
        lastName: 'Palmer',
        email: 'ruby.palmer94@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'rubyarias275',
          followers: 1946,
          engagementRate: 2.7755604828775557
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T16:16:47.000Z',
        amountCents: 4654
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '3b875b2b-3c87-415d-8c43-6b31a069429b',
      content: [],
      creator: {
        id: 'f2d30177-67dd-45f8-aa3b-52035c4f564a',
        firstName: 'Lauren',
        lastName: 'Loomstein',
        email: 'lauren806.lt@gmail.com',
        instagramProfile: {
          handle: 'laur_en6730',
          followers: 1079,
          engagementRate: 5.838739573679333
        },
        tiktokProfile: {
          handle: 'itsmelt7',
          followers: 76,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'lauren76-p2u',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T16:18:29.000Z',
        amountCents: 4706
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '764b7c53-d4ad-480d-9dd0-b6dff1b958a6',
      content: [],
      creator: {
        id: '4f65887c-6e81-4d90-a104-badbe8d3ca63',
        firstName: 'Tene',
        lastName: 'Malcolm Campbell',
        email: 'gorgeoushubja@gmail.com',
        instagramProfile: {
          handle: 'gorgeoushubja',
          followers: 1361,
          engagementRate: 1.2049963262307126
        },
        tiktokProfile: {
          handle: 'gorgeoushubja',
          followers: 1782,
          engagementRate: 14.060258249641318
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T16:18:31.000Z',
        amountCents: 4053
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'cc297491-8131-4ce9-8026-ef582bea7934',
      content: [],
      creator: {
        id: 'c948ebf0-b0b5-4b00-8121-8e46287600b1',
        firstName: 'Kimberly',
        lastName: 'Ortega',
        email: 'vereniceoh@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'verenicesoy_',
          followers: 93,
          engagementRate: 1.3444302176696543
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T16:18:59.000Z',
        amountCents: 4535
      },
      status: 'INCOMPLETE',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '118d1ccf-020e-4d80-b321-d9cc9322e45c',
      content: [],
      creator: {
        id: '57aa9c03-d30f-476f-a033-b768b654f003',
        firstName: 'Karina',
        lastName: 'Guerrero',
        email: 'kguerrero254@icloud.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'cisquh',
          followers: 140,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'karinaguerrero4186',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T16:19:40.000Z',
        amountCents: 4545
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '8bd3db40-dd9c-4dcb-8e5b-e1f54e2b5759',
      content: [],
      creator: {
        id: '6219d0d3-7048-40b0-bc96-993e589b57a9',
        firstName: 'Crystal',
        lastName: 'Vazquez',
        email: 'crystal_velez@icloud.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'crystalrubyv',
          followers: 16827,
          engagementRate: 2.8711483025316995
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T16:19:57.000Z',
        amountCents: 4199
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '489a27e3-4bf0-40dd-9a7b-60a5a6018847',
      content: [],
      creator: {
        id: '4288883b-f7e6-48a4-a34b-53aa6eefb010',
        firstName: 'Heather',
        lastName: 'Martin',
        email: 'heathermartin1015@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'heathermartin80',
          followers: 1478,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'heathermartin7558',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T16:20:07.000Z',
        amountCents: 4068
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '1a423ac6-4b4d-460e-8cff-e4a1bb8552a7',
      content: [],
      creator: {
        id: 'f94cab91-d3b7-4ad7-a404-ee17e0d53d63',
        firstName: 'Marie kateline',
        lastName: 'SaintCyr',
        email: 'mariekatelinesaint@gmail.com',
        instagramProfile: {
          handle: 'sassoufashions',
          followers: 28,
          engagementRate: null
        },
        tiktokProfile: {
          handle: 'katelinesaintt',
          followers: 1535,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T16:21:35.000Z',
        amountCents: 5599
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '8502f3bc-ecb0-4519-b19d-a45770094028',
      content: [],
      creator: {
        id: 'a6734fde-6c5d-4a3e-a8aa-740859612ade',
        firstName: 'Karlee',
        lastName: 'Attleson',
        email: 'karlee.attleson@gmail.com',
        instagramProfile: {
          handle: 'karlee.attleson',
          followers: 1687,
          engagementRate: null
        },
        tiktokProfile: {
          handle: '_karls.jr_',
          followers: 217,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T16:23:02.000Z',
        amountCents: 4654
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '6aa8bb79-474e-402f-a78b-ee3cdfc8e3c4',
      content: [],
      creator: {
        id: '97453cc7-78f4-40dc-b7cd-a1da78b87bfc',
        firstName: 'Shenell',
        lastName: 'Williams',
        email: 'monwill374@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: '222.jaliah',
          followers: 29,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T16:23:37.000Z',
        amountCents: 4907
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '1d8662e7-b1e6-4534-bfb2-e43f05ab6e77',
      content: [],
      creator: {
        id: '98f83106-a5a9-4aed-83c2-26518d36dbc4',
        firstName: 'Lae',
        lastName: 'N',
        email: 'laenish22@gmail.com',
        instagramProfile: {
          handle: 'mekalae',
          followers: 1473,
          engagementRate: null
        },
        tiktokProfile: {
          handle: 'mekalae',
          followers: 1176,
          engagementRate: 5.11302475780409
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T16:24:27.000Z',
        amountCents: 3815
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'af2c8522-9635-4b7d-b432-ad331122e5f0',
      content: [],
      creator: {
        id: 'dd09fef9-659d-426e-a384-d68d31b7cf27',
        firstName: 'Jeanelle',
        lastName: 'Brown',
        email: 'jeanelletia111@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'iamjehnell',
          followers: 3787,
          engagementRate: 18.440541805027788
        },
        youtubeProfile: {
          handle: 'iamjehnell',
          followers: 143,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T16:29:01.000Z',
        amountCents: 3815
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '597ccea2-308c-4110-a94d-f4dbf424a93d',
      content: [],
      creator: {
        id: '2d0499e9-11da-45e5-820c-d8d7eafa6a3c',
        firstName: 'Samanda',
        lastName: 'Richards',
        email: 'samanda.richards2608@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'ma__ndaaa',
          followers: 4904,
          engagementRate: 5.674508636092912
        },
        youtubeProfile: {
          handle: 'hawanya360',
          followers: 265,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T16:37:55.000Z',
        amountCents: 3815
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'ff86f4da-ca63-4c66-8169-873c17f89a1e',
      content: [],
      creator: {
        id: 'af7ef84c-1e0a-4eba-a3b5-939b57609109',
        firstName: 'Rabia',
        lastName: 'Shahbaz',
        email: 'nasirrabia27@yahoo.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'noorie1225',
          followers: 22,
          engagementRate: 9.581504605303827
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T16:44:44.000Z',
        amountCents: 4632
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'd392af0e-ffff-4aeb-a560-6cc83dd969b8',
      content: [],
      creator: {
        id: '1b4ec747-0e3f-439e-b982-fa499449b1dd',
        firstName: 'Devonnette ',
        lastName: 'Johnson ',
        email: 'devonnettejohnson16@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'yougirldebz',
          followers: 5044,
          engagementRate: 16.274761555392516
        },
        youtubeProfile: {
          handle: 'debbydebzworld',
          followers: 737,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T16:48:46.000Z',
        amountCents: 3815
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '517ae06d-c918-4a83-a09e-1b7ce8f4ba28',
      content: [],
      creator: {
        id: 'c3397b71-0545-4b71-a8fc-965090480df6',
        firstName: 'Mahlia',
        lastName: 'Samuels',
        email: 'mahliasamuels4@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'stealhishoodie',
          followers: 144,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T16:49:46.000Z',
        amountCents: 4294
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '09c72322-7827-483b-8e7e-39714e9159bf',
      content: [],
      creator: {
        id: 'e63f4bfd-2b35-4e96-b7bf-294dec25327b',
        firstName: 'Ashantie ',
        lastName: 'Whyte ',
        email: 'whyteashanti16@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'ashantiedagreatest',
          followers: 1330,
          engagementRate: 6.487695749440715
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T17:03:58.000Z',
        amountCents: 4199
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '5d40f329-94d4-4ca1-b07c-70a6771b0338',
      content: [],
      creator: {
        id: '7c3256bb-17e3-43c1-a9a6-275c8cb53b0a',
        firstName: 'Jamie',
        lastName: 'Shell',
        email: 'jm1253@icloud.com',
        instagramProfile: {
          handle: 'jm277606',
          followers: 2134,
          engagementRate: 2.610121836925961
        },
        tiktokProfile: {
          handle: 'augustj5',
          followers: 2381,
          engagementRate: 14.976780185758514
        },
        youtubeProfile: {
          handle: 'jamieshell4994',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T17:05:47.000Z',
        amountCents: 4686
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '5600abec-7013-4e25-88b1-c1dafe17185f',
      content: [],
      creator: {
        id: '0f3ff74b-a90b-4bb0-9009-6bfadb869a36',
        firstName: 'Lamantha',
        lastName: 'Laing',
        email: 'laing.laman21@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'legally_21_tho',
          followers: 294,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'glassesbabe419',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T17:11:44.000Z',
        amountCents: 5396
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '73db25a8-b001-426a-bb6d-01237523931e',
      content: [],
      creator: {
        id: 'ae06b0a6-8b27-4f7b-a3db-eb424c5c7d51',
        firstName: 'kyler',
        lastName: 'mccauley',
        email: 'kylermccauley0@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'kymccauley',
          followers: 1015,
          engagementRate: 7.8664858965176165
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T17:33:01.000Z',
        amountCents: 4451
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'd898ec26-61c5-4a83-9f3c-ac34d181f8e2',
      content: [],
      creator: {
        id: '2094b92c-7267-46fa-af53-ef4241a5440e',
        firstName: 'Joshua',
        lastName: 'Lively',
        email: 'livelyworshipofficial@gmail.com',
        instagramProfile: {
          handle: 'livelyworship',
          followers: 1510,
          engagementRate: 2.986754966887417
        },
        tiktokProfile: {
          handle: 'livelyworship',
          followers: 137,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'livelyworship',
          followers: 213,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T17:35:21.000Z',
        amountCents: 4299
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '55d2126f-bd8b-42f5-957d-e4c0c251b38d',
      content: [],
      creator: {
        id: '707a09cd-b2dc-4c57-b903-4b1e3083248b',
        firstName: 'destiny',
        lastName: 'renteria',
        email: 'llil.princesss22@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'he.luvs.destinyyyy',
          followers: 443,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'destinyrenteria3796',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T17:41:59.000Z',
        amountCents: 4937
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '6b1ab0e0-0aa5-4703-89c7-81adac30e60e',
      content: [],
      creator: {
        id: '22862314-d9f3-4520-a9f4-2c4ca622709a',
        firstName: 'Aiman',
        lastName: 'Jamadar',
        email: 'lifewithaimanj@gmail.com',
        instagramProfile: {
          handle: 'aiman.f.j',
          followers: 1380,
          engagementRate: null
        },
        tiktokProfile: {
          handle: 'lifewithaimanj',
          followers: 7110,
          engagementRate: 6.024805060673756
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T17:48:50.000Z',
        amountCents: 4503
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '7c9bc7d1-6028-48aa-9852-c4c0f91eb798',
      content: [],
      creator: {
        id: '8cb81983-3269-4ac2-9d95-9519ae7b1136',
        firstName: 'Tiffany',
        lastName: 'Aiken',
        email: 'shinnaythegraceful@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'tiffster__',
          followers: 179,
          engagementRate: 8.743169398907105
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T17:54:06.000Z',
        amountCents: 4299
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '992cccfe-851a-4336-a67f-206600092d52',
      content: [],
      creator: {
        id: 'a0fbe3fd-b39a-480f-9048-a28b2598a4d2',
        firstName: 'Alecia',
        lastName: 'Daley',
        email: 'tfrasskull@gmail.com',
        instagramProfile: {
          handle: 'street_champs_music',
          followers: 462,
          engagementRate: 8.008658008658008
        },
        tiktokProfile: {
          handle: 'saskyberry',
          followers: 1648,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'saskyberry5753',
          followers: 130,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T18:05:44.000Z',
        amountCents: 3815
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '8488ffea-5e07-4a8b-a50d-54f7c3137777',
      content: [],
      creator: {
        id: 'b225bc57-b6fb-4d5d-a54f-6d6701ee917e',
        firstName: 'Janilsa',
        lastName: 'Luciano',
        email: 'jluciano821@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'prettydamnlegit',
          followers: 199,
          engagementRate: 1.4749262536873156
        },
        youtubeProfile: {
          handle: 'nilsa21',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T18:13:03.000Z',
        amountCents: 4068
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '9af398ba-6754-466e-bebd-aa0a5ffe4513',
      content: [],
      creator: {
        id: 'f02b7d1a-499b-420d-9ee7-424df60150c4',
        firstName: 'Haley',
        lastName: 'Vermillion',
        email: 'hvellen1@icloud.com',
        instagramProfile: {
          handle: 'hales.ln',
          followers: 692,
          engagementRate: 4.841040462427745
        },
        tiktokProfile: {
          handle: 'luv..hales',
          followers: 4886,
          engagementRate: 12.500562556255627
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T18:16:09.000Z',
        amountCents: 4641
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'f19be872-8d07-41f8-86d0-6a04e2bcdeb6',
      content: [],
      creator: {
        id: 'da754650-4312-4fe8-9ddd-aec35e7eb616',
        firstName: 'Jewels',
        lastName: 'Scribner',
        email: 'jewelsy.woolsey13@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'evange.13',
          followers: 481,
          engagementRate: 20.129310344827587
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T18:42:04.000Z',
        amountCents: 5448
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'eff47eca-5a50-4a45-a52a-cf888eea3bd0',
      content: [],
      creator: {
        id: '271259bd-4fa9-45e9-ba20-563766e84b14',
        firstName: 'Sameerah',
        lastName: 'Shaheed',
        email: 'spoildbystarr@gmail.com',
        instagramProfile: {
          handle: 'spoildbystarr',
          followers: 297,
          engagementRate: null
        },
        tiktokProfile: null,
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T18:45:47.000Z',
        amountCents: 4299
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '70453dae-7f96-4266-985c-15e319f1d82b',
      content: [],
      creator: {
        id: 'cd9307f0-f081-44ef-a084-782e9c04035d',
        firstName: 'Venissa',
        lastName: 'McNair-Eccleston',
        email: 'venissa8@yahoo.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'nessas_chic_boutique',
          followers: 39,
          engagementRate: 1.6238159675236805
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T19:22:27.000Z',
        amountCents: 4299
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'ad120e2b-1344-40a5-bfb7-45246fc7dfcd',
      content: [],
      creator: {
        id: '5baaddf7-b303-4291-9655-e58f4fce1178',
        firstName: 'Britany',
        lastName: 'Peart',
        email: 'aikenbrittanny2023@gmail.com',
        instagramProfile: {
          handle: 'briibeautysupplies',
          followers: 60,
          engagementRate: 38.333333333333336
        },
        tiktokProfile: {
          handle: 'lareinabrii',
          followers: 364,
          engagementRate: 2.3090586145648313
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T19:24:50.000Z',
        amountCents: 4088
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'a2178533-1302-42b1-ac6e-f3f6ccf7defa',
      content: [],
      creator: {
        id: '68809829-ccef-42ec-9094-bedf01607d2e',
        firstName: 'Ashley ',
        lastName: 'Copeland ',
        email: 'shanelletom867@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'shanshan5150',
          followers: 124,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T19:26:20.000Z',
        amountCents: 4514
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '0a053f05-f14b-4b4f-97f1-ca2b067b019f',
      content: [],
      creator: {
        id: '6fa39565-a3b3-4b74-bf0d-0cbdb90f103b',
        firstName: 'Nataly',
        lastName: 'Soriano castro',
        email: 'natalysoriano523@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'natzs94',
          followers: 1372,
          engagementRate: 2.660406885758998
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T19:26:56.000Z',
        amountCents: 4199
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'bdd46dde-d6db-433e-9a7a-4effcb018862',
      content: [],
      creator: {
        id: 'c773bb2f-d25c-4706-8c8f-4a5f60e98b11',
        firstName: 'Raevaughan',
        lastName: 'Miller',
        email: 'raevaughanmiller06@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'gri.mey',
          followers: 26,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'top_manackeemtv4852',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T19:36:54.000Z',
        amountCents: 5552
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'd734ff2f-5636-4bf2-9770-f00de57f2f00',
      content: [],
      creator: {
        id: 'c4ed60c4-97dd-426c-8bc5-e431ebd831e0',
        firstName: 'Tajmonet',
        lastName: 'Gooden',
        email: 'shanellegooden05@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'shanelleg6',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'taj-monetgooden',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T19:42:32.000Z',
        amountCents: 7383
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'a8ae1074-f868-4119-a56f-99a163cb0db6',
      content: [],
      creator: {
        id: '1e3b9f13-248a-4ce9-ad70-d35f6219514c',
        firstName: 'Amanda',
        lastName: 'S',
        email: 'ashenker15@gmail.com',
        instagramProfile: {
          handle: 'mrsshenker',
          followers: 1254,
          engagementRate: 0.025985518905872883
        },
        tiktokProfile: {
          handle: 'ashenker',
          followers: 40,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T20:15:19.000Z',
        amountCents: 4199
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '872a8599-1d13-49ab-aa58-d6b60c33ce5c',
      content: [],
      creator: {
        id: '83c541b5-6848-4247-9e80-59699d060d56',
        firstName: 'Dominick',
        lastName: 'Simpson',
        email: 'yingbreanna@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'breanaskyee',
          followers: 1102,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T21:05:55.000Z',
        amountCents: 3815
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '3eb966eb-35b9-4403-85fd-0adec427075e',
      content: [],
      creator: {
        id: 'f8ad1738-2bfa-40b8-b410-963fb154f793',
        firstName: 'Daniel',
        lastName: 'Howard',
        email: 'howarddan13666@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'danielhell666',
          followers: 220,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'danhoward-qq8gf',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T21:53:47.000Z',
        amountCents: 4898
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '6d74f816-965d-418e-926b-3ba19bed79af',
      content: [],
      creator: {
        id: '861f6596-36bf-43cc-b4db-e649db1f12fc',
        firstName: 'Ashani',
        lastName: 'Pinnock',
        email: 'ashanipinnock0@gmail.com',
        instagramProfile: {
          handle: 'simplicity_hair_lash',
          followers: 10,
          engagementRate: null
        },
        tiktokProfile: {
          handle: 'britz6143',
          followers: 7,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T22:04:07.000Z',
        amountCents: 5940
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'c63e1868-1b4e-4553-b01d-c593a06ab84a',
      content: [],
      creator: {
        id: 'f33f6ea9-dec4-4d49-a539-7169b6c0e5d0',
        firstName: 'Michelle',
        lastName: 'Whittingham',
        email: 'michellewhittingham328@gmail.com',
        instagramProfile: {
          handle: 'prettii_michiboo',
          followers: 1109,
          engagementRate: 7.8449053201082055
        },
        tiktokProfile: {
          handle: 'prettii_michii',
          followers: 681,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'michellevwhittingham3278',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T23:35:30.000Z',
        amountCents: 4998
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '9f6a005d-4dea-457c-b868-c1fd1520455e',
      content: [],
      creator: {
        id: '2f24eea5-47e6-418f-8bc8-f55507bdeb29',
        firstName: 'Rhayssa',
        lastName: 'Silva',
        email: 'rhayssacreator@gmail.com',
        instagramProfile: {
          handle: 'rhayssaduart',
          followers: 1306,
          engagementRate: 9.698825931597755
        },
        tiktokProfile: {
          handle: 'rhayssaduart',
          followers: 328,
          engagementRate: 1.3274336283185841
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T23:42:46.000Z',
        amountCents: 4053
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '4864d23b-1db4-4998-8145-aff0d2b55ee8',
      content: [],
      creator: {
        id: 'ed96d1cf-e0f1-421a-bfac-0f93f9350896',
        firstName: 'Anesia',
        lastName: 'Speid',
        email: 'speidanesia546@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'anesia.__',
          followers: 2082,
          engagementRate: 8.421052631578947
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-31T00:35:30.000Z',
        amountCents: 3815
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '836daa42-1182-42e5-b4d4-a700221b47a0',
      content: [],
      creator: {
        id: 'c2fdd0a6-00b8-448d-8374-39d40c43db91',
        firstName: 'Trendi',
        lastName: 'Francis',
        email: 'trendifrancis@icloud.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'cuzzzinsssfalype',
          followers: 949,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-31T00:36:24.000Z',
        amountCents: 5361
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'dfea8b24-5b03-4e26-9491-df75322cd839',
      content: [],
      creator: {
        id: '71c9a680-2527-49c4-8d07-8d23ae5d0f71',
        firstName: 'Shenice',
        lastName: 'B',
        email: 'aliciadouglas474@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'mommin_it_daily',
          followers: 554,
          engagementRate: 10.68181160573487
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-31T00:55:31.000Z',
        amountCents: 3815
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '7bce289d-bb5d-4413-b5c2-bf739cf42468',
      content: [],
      creator: {
        id: 'e9a8021e-d1e7-4738-8cfc-c17740595f82',
        firstName: 'Karla',
        lastName: 'Alejo',
        email: 'karlacezaren1189@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'karlacezarenoveri',
          followers: 48,
          engagementRate: 5.381165919282512
        },
        youtubeProfile: {
          handle: 'karlacezarenalejo9342',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-31T01:25:44.000Z',
        amountCents: 5668
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'd0179e96-ceb6-4a07-a27a-bb62c6a7f0e5',
      content: [],
      creator: {
        id: 'ab43dcbb-6647-4e22-ba13-4fa9f61f5411',
        firstName: 'Kaydian',
        lastName: 'Williams',
        email: 'kaydianwilliams9@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'reignbeaux5',
          followers: 56,
          engagementRate: 1.3108614232209739
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-31T01:34:19.000Z',
        amountCents: 4514
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '63f8c678-f218-4bf9-9dd0-508fa0c98dab',
      content: [],
      creator: {
        id: '332a3464-08c7-4a5e-ab55-1061c193c946',
        firstName: 'Terreka',
        lastName: 'Phipps',
        email: 'terrekabusiness@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'godlybarbz_',
          followers: 480,
          engagementRate: 7.4397398741782705
        },
        youtubeProfile: {
          handle: 'queenterri_',
          followers: 168,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-31T01:34:25.000Z',
        amountCents: 4998
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'd5472c92-c88d-4908-a792-e6d360cea12d',
      content: [],
      creator: {
        id: 'd3910a55-d55b-4050-a1f0-892807996a5f',
        firstName: 'Angella',
        lastName: 'Bonnick',
        email: 'nardbonn27@yahoo.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'angiebbb4',
          followers: 19,
          engagementRate: 0.6932409012131715
        },
        youtubeProfile: {
          handle: 'susanclarke2388',
          followers: 6,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-31T01:34:35.000Z',
        amountCents: 3908
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '4d822d5c-e90c-4119-bb00-c431124db853',
      content: [],
      creator: {
        id: '2c8d15f2-c006-4c27-8aca-5fb5e08a09a4',
        firstName: 'Paige',
        lastName: 'Holmes',
        email: 'hepaige99@gmail.com',
        instagramProfile: {
          handle: 'hepaigee',
          followers: 474,
          engagementRate: null
        },
        tiktokProfile: {
          handle: 'radiantpage',
          followers: 5261,
          engagementRate: 11.646930106834857
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-31T02:14:45.000Z',
        amountCents: 4299
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '6c63158f-c215-4338-8bba-38600897068f',
      content: [],
      creator: {
        id: 'b165e180-f466-422e-bec1-f423015acd40',
        firstName: 'Alex',
        lastName: 'Lindley',
        email: 'akrslindley@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'akrslzuprwb',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'kaylalindley-um7vd',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-31T02:15:31.000Z',
        amountCents: 4535
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '6940390d-e42b-44cc-98c2-12fedbb220d9',
      content: [],
      creator: {
        id: 'cc69d39a-3df9-4203-b105-804eeb2c7639',
        firstName: 'Daisha ',
        lastName: 'Fraser ',
        email: 'frazertiana417@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'capricornbaby28',
          followers: 3314,
          engagementRate: 13.163064833005894
        },
        youtubeProfile: {
          handle: 'lifewithdaisha909',
          followers: 2,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-31T02:43:36.000Z',
        amountCents: 4092
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'af765a16-5afb-42b2-89ad-d379921f6605',
      content: [],
      creator: {
        id: '2f6301d2-39e5-4964-9fd7-f029e0128434',
        firstName: 'Jay',
        lastName: 'Grant',
        email: 'jahnelg@icloud.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'yourpersonalshopper06',
          followers: 897,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-31T04:01:50.000Z',
        amountCents: 5598
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '802b629c-f516-41eb-a33e-bb63ef6f591d',
      content: [],
      creator: {
        id: '37c7eadf-fe25-4479-ae88-dd0131d2392c',
        firstName: 'Cleide',
        lastName: 'Tagliari ',
        email: 'edut_2009@hotmail.com',
        instagramProfile: {
          handle: 'cleidetagliaridecor',
          followers: 138,
          engagementRate: null
        },
        tiktokProfile: {
          handle: 'cleide.tagliari',
          followers: 223,
          engagementRate: 4.887218045112782
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-31T04:42:49.000Z',
        amountCents: 4697
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '1f5830a8-b3b5-40b9-9303-07258714e9f9',
      content: [],
      creator: {
        id: '2bcf3c2b-c947-4e26-8d43-5ad0e24fb1b1',
        firstName: 'Annette',
        lastName: 'Berry',
        email: 'annetteberry1@yahoo.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'plumshumberry',
          followers: 195,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-31T05:03:15.000Z',
        amountCents: 7594
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'b1f730a1-f2fa-4428-adeb-de425dd37bf6',
      content: [],
      creator: {
        id: '8e7a84dc-dfab-4ce5-9d67-dc452e4ec6bc',
        firstName: 'Kavone',
        lastName: 'Sterling',
        email: 'kavonesterling2@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'zedd.4pf',
          followers: 1726,
          engagementRate: 10.105633802816902
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-31T06:34:05.000Z',
        amountCents: 4514
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '91b8ab36-519c-4739-a780-c1841a223b55',
      content: [],
      creator: {
        id: '9be0cb4a-eda6-4343-8356-5c0ee6c63dd9',
        firstName: 'Kerrome',
        lastName: 'Heath',
        email: 'kerromekheath@gmail.com',
        instagramProfile: {
          handle: 'kerromekhadijah_',
          followers: 1564,
          engagementRate: null
        },
        tiktokProfile: {
          handle: 'kerromekhadijah',
          followers: 996,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-31T06:34:11.000Z',
        amountCents: 4998
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '804d9a39-d38d-437e-80ca-018a8008bc0d',
      content: [],
      creator: {
        id: 'f37fb043-dd0f-45fb-a75f-6962162235d4',
        firstName: 'Jawara',
        lastName: 'Grant',
        email: 'jawaragrant10@gmail.com',
        instagramProfile: {
          handle: 'promo_party_',
          followers: 24,
          engagementRate: null
        },
        tiktokProfile: {
          handle: 'offworld__',
          followers: 57,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-31T06:54:13.000Z',
        amountCents: 4199
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '539e414a-0fb9-4293-ad4a-c0a08a483f10',
      content: [],
      creator: {
        id: '11253045-52f2-4d5c-92e3-d0b29a141e2f',
        firstName: 'Shelleka',
        lastName: 'Lafayette',
        email: 'shellekalafayette@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: '__.chinnnn',
          followers: 585,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-31T07:33:03.000Z',
        amountCents: 4199
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '8e07e417-ad91-495b-b5db-723bc89242a2',
      content: [],
      creator: {
        id: 'e3d08084-d461-4aa3-a7d2-84dc2723878d',
        firstName: 'Tyrick ',
        lastName: 'Powers',
        email: 'tyrickpowers12@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'karse.l',
          followers: 38,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-31T08:43:59.000Z',
        amountCents: 6153
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'ec24b25e-c43f-44a9-8ff7-fa4737271101',
      content: [],
      creator: {
        id: '22200a6b-6ed1-4a69-9ffc-aef144c0395f',
        firstName: 'Angel',
        lastName: 'Day',
        email: 'aday4010@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'crazygotswag',
          followers: 1005,
          engagementRate: 12.162162162162163
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-31T09:55:08.000Z',
        amountCents: 4096
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'e766368a-eeaf-4591-9737-c03938b1ea1a',
      content: [],
      creator: {
        id: 'af017905-3036-40c0-ab74-baa8959328ce',
        firstName: 'Chanderpaul',
        lastName: 'Cole',
        email: 'friofarm86@gmail.com',
        instagramProfile: {
          handle: 'frio_farm86',
          followers: 1225,
          engagementRate: 2.5142857142857142
        },
        tiktokProfile: {
          handle: 'frio_farm86',
          followers: 4140,
          engagementRate: 2.7744630071599046
        },
        youtubeProfile: {
          handle: 'friofarm',
          followers: 1770,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-31T12:11:58.000Z',
        amountCents: 4514
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '3407e055-522d-419a-9a2b-3eed94558954',
      content: [],
      creator: {
        id: '75a0daf5-02f1-4c1e-938b-ad3207e4da11',
        firstName: 'Shawnah',
        lastName: 'Ennis',
        email: 'ennisshawnah23@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'shawnah46',
          followers: 0,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-31T13:14:00.000Z',
        amountCents: 4898
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'be7ceb01-8f01-4d15-9a46-1f7aad3b9a48',
      content: [],
      creator: {
        id: '7cea544c-9ec0-4b33-b998-52c112f40bc5',
        firstName: 'Dieu',
        lastName: 'Ho',
        email: 'shopsouthernsugar@gmail.com',
        instagramProfile: {
          handle: 'southernsugar.mulan',
          followers: 725,
          engagementRate: 1.4252873563218391
        },
        tiktokProfile: {
          handle: 'southernsugarboutique',
          followers: 139,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-31T15:05:30.000Z',
        amountCents: 4535
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '7563742c-0368-4f0c-9442-714b8e5f3f9f',
      content: [],
      creator: {
        id: 'bdfb4b58-4982-45f5-9a5b-91e2657760f7',
        firstName: 'Amy',
        lastName: 'Chambers',
        email: 'amyelizabeth742@gmail.com',
        instagramProfile: {
          handle: 'pnwpetcare',
          followers: 183,
          engagementRate: 20.32967032967033
        },
        tiktokProfile: {
          handle: 'amy.nugget',
          followers: 753,
          engagementRate: 7.455012853470437
        },
        youtubeProfile: {
          handle: 'amy-th1mu',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-31T16:09:30.000Z',
        amountCents: 3815
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '98249145-e6c5-46b5-8140-df4cbf1c08e6',
      content: [],
      creator: {
        id: '5cb8b088-111b-41f1-a434-b6fcc6dd566e',
        firstName: 'kristin',
        lastName: 'brasher',
        email: 'kb444222kb@gmail.com',
        instagramProfile: {
          handle: 'pixel.girlfriend',
          followers: 560,
          engagementRate: null
        },
        tiktokProfile: {
          handle: 'psychwardshortyy',
          followers: 5759,
          engagementRate: 12.741775358597787
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-31T16:11:56.000Z',
        amountCents: 4299
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '1651cd3a-dbc1-4bf8-b6eb-de895b63744e',
      content: [],
      creator: {
        id: '9a2f6385-b3d4-4056-88fb-937670392e91',
        firstName: 'Emily',
        lastName: 'Ryan',
        email: 'emilyryan1197@icloud.com',
        instagramProfile: {
          handle: 'emilydryan',
          followers: 729,
          engagementRate: null
        },
        tiktokProfile: {
          handle: 'emmyryan77',
          followers: 65,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-31T16:12:54.000Z',
        amountCents: 4740
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '04ba20b5-26b9-4ab9-b69b-e4ffe41110b2',
      content: [],
      creator: {
        id: '91c1b8b9-36cf-46b7-8123-0671784ba4d6',
        firstName: 'Victoria',
        lastName: 'Shimo',
        email: 'victoriaxshimo@gmail.com',
        instagramProfile: {
          handle: 'victorianvikki',
          followers: 1768,
          engagementRate: 0.7635746606334841
        },
        tiktokProfile: {
          handle: 'thatvictorianbpd',
          followers: 39,
          engagementRate: 8.47953216374269
        },
        youtubeProfile: {
          handle: 'victoriaasmr1032',
          followers: 7,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-31T16:15:37.000Z',
        amountCents: 5614
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '4b4c86b4-f152-4e35-a9bf-55b08a20d280',
      content: [],
      creator: {
        id: 'f9d62f18-66a6-4d25-9e66-7ad2924bceec',
        firstName: 'Nicole',
        lastName: 'Taylor',
        email: 'nicole.weiss1014@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'nikkitay14',
          followers: 1165,
          engagementRate: 41.48259705488621
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-31T16:18:39.000Z',
        amountCents: 4557
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'ed636905-4599-4017-bb19-ccf4a08de6ef',
      content: [],
      creator: {
        id: '56af844c-14a7-4467-a3e1-cfb6bf61cc09',
        firstName: 'Lance',
        lastName: 'Mcmurrin',
        email: 'lmacky56@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'soccer101114',
          followers: 576,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-31T16:19:06.000Z',
        amountCents: 3815
      },
      status: 'IN_PROGRESS',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '68be989b-20fb-4891-96bf-149a867b8ed6',
      content: [],
      creator: {
        id: 'fa58e81c-7875-4c5d-b848-2ab19419cc52',
        firstName: 'Brenna',
        lastName: 'Ard',
        email: 'brenna.ard@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'brendal143',
          followers: 43,
          engagementRate: null
        },
        youtubeProfile: {
          handle: 'brennachapman4745',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-31T16:20:36.000Z',
        amountCents: 4118
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '40536dc9-a662-48ea-a9fd-1997c40c58fe',
      content: [],
      creator: {
        id: '85fa49f2-87b1-423c-b7a4-e2e00fe6f1f7',
        firstName: 'Victoria',
        lastName: 'Yale',
        email: 'v8404040@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'vicki6757',
          followers: 4840,
          engagementRate: 5.915259847732539
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-31T16:23:10.000Z',
        amountCents: 4535
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '521600b4-72d0-4e42-9b00-e0cefdcb8b27',
      content: [],
      creator: {
        id: '6fd2ad70-d685-4244-9cfa-3c9819619586',
        firstName: 'Amanda ',
        lastName: 'Hamilton ',
        email: 'ahamilton.gh@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'gabbywhyte876',
          followers: 67,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-31T16:26:13.000Z',
        amountCents: 3815
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: 'b7acd63d-ef94-44d2-a3a9-0b1ffe3f9f54',
      content: [],
      creator: {
        id: '924f60cc-1974-4e03-ad08-e3750d2b80a4',
        firstName: 'Sarah',
        lastName: 'Hampsey',
        email: 'dcanphyfe@gmail.com',
        instagramProfile: {
          handle: 'dcanphyfe',
          followers: 569,
          engagementRate: 0.5217391304347827
        },
        tiktokProfile: {
          handle: 'dcanphyfe',
          followers: 55,
          engagementRate: 2.842207495429616
        },
        youtubeProfile: {
          handle: 'sarahhampsey8855',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-31T16:30:13.000Z',
        amountCents: 3815
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '77045ba8-d0b1-4810-802c-48d14005c843',
      content: [],
      creator: {
        id: 'ec905afc-b479-4755-9967-1266c9aae029',
        firstName: 'Victoria',
        lastName: 'Ruiz',
        email: 'yazkararuizgiron@gmail.com',
        instagramProfile: null,
        tiktokProfile: {
          handle: 'yazkararg',
          followers: 3995,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-31T16:40:59.000Z',
        amountCents: 5574
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '5bb66a37-0b0f-4437-95dc-d0eea6e2c7ed',
      content: [],
      creator: {
        id: 'da5acd62-73cd-4253-9f80-e2fdd9826f39',
        firstName: 'Grace',
        lastName: 'Ramirez',
        email: 'gmathers91@gmail.com',
        instagramProfile: {
          handle: 'grace_reviews2',
          followers: 908,
          engagementRate: 3.933236574746009
        },
        tiktokProfile: null,
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-07-31T16:53:01.000Z',
        amountCents: 4177
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '10bbabfe-808f-4332-91c0-721e02b47f31',
      content: [],
      creator: {
        id: '64908281-e955-4856-b592-5e6504d88020',
        firstName: 'Christina',
        lastName: 'Tomlin Miller',
        email: 'christenatomlin@gmail.com',
        instagramProfile: {
          handle: 'aulineashley',
          followers: 165,
          engagementRate: 3.939393939393939
        },
        tiktokProfile: {
          handle: 'mrs.miller31',
          followers: 685,
          engagementRate: 10.622710622710622
        },
        youtubeProfile: {
          handle: 'christenatomlin1011',
          followers: 0,
          engagementRate: null
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-31T16:56:55.000Z',
        amountCents: 8400
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    },
    {
      userCampaignId: '3dc3ef7a-0ec2-46b0-9f8e-eff02a5b2659',
      content: [],
      creator: {
        id: 'b8b4809b-4456-4548-ae79-82f37311c0d4',
        firstName: 'Kacie',
        lastName: 'Lawrence',
        email: 'lawrence.kaciec@gmail.com',
        instagramProfile: {
          handle: 'kac.c.law',
          followers: 101,
          engagementRate: 9.405940594059405
        },
        tiktokProfile: {
          handle: 'kac.c.law',
          followers: 20,
          engagementRate: null
        },
        youtubeProfile: null
      },
      purchaseDetails: {
        purchaseDate: '2024-08-15T04:00:00.000Z',
        amountCents: 15900
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE'
    }
  ],
  proposal: null
}
