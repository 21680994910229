import { Box, Stack } from '@mui/system'
import {
  Typography,
  Paper,
  TextField,
  List,
  ListItem,
  ListItemButton,
  Divider,
  useAutocomplete,
  Chip,
  CircularProgress
} from '@mui/material'
import { useController, useFormContext } from 'react-hook-form'
import { Search, Add, Close } from '@mui/icons-material'
import { getCdnImageUrl, noProductImageSmallAlt } from '@momentum/utils/imageUtils'
import { ellipsisString } from '@momentum/utils/stringUtils'
import { GraphQLTypes, InputType, STORE_TO_LOCALE, Selector } from '@productwindtom/shared-momentum-zeus-types'
import { toCurrencyStringCents } from '@productwindtom/shared-ws-currency'
import { useEffect, useState } from 'react'
import { StoreToIcon } from '@momentum/utils/storeIcons'
import PackagePlusIcon from '@momentum/components/icons/package-plus'

const ASIN_REGEX = /(?:[/dp/]|$)([A-Z0-9]{10})/

const productSelector = Selector('Product')({
  id: true,
  name: true,
  image: true,
  price: true,
  priceCents: true,
  skuId: true,
  store: true
})
type Product = InputType<GraphQLTypes['Product'], typeof productSelector> & { childrenIds?: string[] }
const ProductSelectorV2 = ({
  products,
  onAddProductClick,
  width = '100%',
  disabled,
  disableAddProduct,
  loadingProducts,
  onSelectProduct
}: {
  products: Product[]
  width?: number | string
  onAddProductClick?: () => void
  onSelectProduct?: (product?: Product) => void
  disabled?: boolean
  disableAddProduct?: boolean
  loadingProducts?: boolean
}) => {
  const [isListOpen, setIsListOpen] = useState(false)
  const { trigger, control } = useFormContext()
  const [textValue, setTextValue] = useState('')

  const {
    field: { onChange, value }
  } = useController({ name: 'productId', control })

  const handleChange = (val?: string) => {
    if (onChange && !disabled) {
      onChange(val)
      trigger('productId')
      setTextValue(products.find(p => p.id === val)?.name || '')
      onSelectProduct?.(products.find(p => p.id === val))
    }
  }

  useEffect(() => {
    if (!value) setTextValue('')
  }, [value])

  const { getRootProps, getInputProps, getListboxProps, groupedOptions } = useAutocomplete<Product>({
    options: products,
    getOptionLabel: option => option.name + option.skuId,
    disabled,
    onChange: (_, value) => {
      setTextValue(value?.name || '')
    },
    onInputChange: (_, value) => {
      setTextValue(value)
      setIsListOpen(true)
    },
    open: isListOpen,
    openOnFocus: true,
    onOpen: () => setIsListOpen(true),
    onClose: (e, reason) => {
      if (reason === 'toggleInput') return
      setIsListOpen(false)
    },
    filterOptions: (options, { inputValue }) => {
      const input = (inputValue.match(ASIN_REGEX)?.[1] || inputValue).toLowerCase()
      return options.filter(
        option => option.name.toLowerCase().includes(input) || option.skuId?.toLowerCase().includes(input)
      )
    }
  })

  return (
    <Box {...getRootProps()} sx={{ width }} position={'relative'}>
      <TextField
        placeholder={'Search ASIN or product title'}
        inputProps={{
          autoComplete: 'off',
          'data-cy': 'productSelectorSearchInput',
          ...getInputProps(),
          value: textValue
        }}
        fullWidth
        InputProps={{
          endAdornment: value ? (
            <Close sx={{ cursor: 'pointer' }} onClick={() => handleChange(undefined)} />
          ) : loadingProducts ? (
            <CircularProgress size={16} />
          ) : (
            <Search />
          )
        }}
        disabled={disabled}
      />

      {isListOpen && (
        <Paper
          component={'ul'}
          sx={{ p: 0, m: 0, maxWidth: width, position: 'absolute', zIndex: 1, width: '100%', boxSizing: 'border-box' }}
          {...getListboxProps()}
        >
          <List sx={{ overflow: 'auto', maxHeight: 420 }} disablePadding>
            {groupedOptions
              .map(n => n as Product)
              .map((n, index) => (
                <ListItem key={index} disablePadding onClick={() => handleChange(n.id)}>
                  <ListItemButton data-cy={`productSelectorListItem-${n.skuId}`}>
                    <Stack direction={'row'} spacing={2} alignItems={'start'} flex={1}>
                      <Box width={24} mr={'5px'} alignSelf={'center'}>
                        <img
                          src={getCdnImageUrl(n.image) || '/images/no-product-small.png'}
                          loading="lazy"
                          width={24}
                          height={24}
                          style={{ objectFit: 'contain' }}
                          onError={noProductImageSmallAlt}
                        />
                      </Box>
                      <Typography variant={'subtitle2'} flexGrow={1}>
                        {ellipsisString(n.name, 50)}{' '}
                        {!n.skuId && (
                          <Chip
                            label={'Placeholder'}
                            icon={<PackagePlusIcon />}
                            size={'xSmall'}
                            variant={'filled'}
                            color={'secondary'}
                            sx={{ verticalAlign: 'sub' }}
                          />
                        )}
                      </Typography>
                      <Stack alignItems={'flex-start'}>
                        <Typography variant={'subtitle1'}>
                          {!!n.priceCents && toCurrencyStringCents(n.priceCents, STORE_TO_LOCALE[n.store])}
                        </Typography>
                        <Typography variant={'caption2'} color={theme => theme.palette.grey.A700}>
                          ASP
                        </Typography>
                        {!!n.childrenIds?.length && (
                          <Typography variant={'caption2'} color={theme => theme.palette.grey.A700}>
                            PARENT
                          </Typography>
                        )}
                      </Stack>
                      <Stack alignItems={'center'} pt={0.5}>
                        {StoreToIcon[n.store]?.({ fontSize: 'mSmall' })}
                      </Stack>
                    </Stack>
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
          {onAddProductClick && !disableAddProduct && (
            <>
              <Divider />
              <ListItem disablePadding onClick={onAddProductClick}>
                <ListItemButton data-cy={'createProduct-addNewProduct'}>
                  <Stack direction={'row'} alignItems={'center'} spacing={1} py={1}>
                    <Add color={'primary'} />
                    <Typography variant={'subtitle2'} color={'primary'}>
                      Add new product
                    </Typography>
                  </Stack>
                </ListItemButton>
              </ListItem>
            </>
          )}
        </Paper>
      )}
    </Box>
  )
}

export default ProductSelectorV2
