import BonusIcon from '@momentum/components/icons/bonus'
import PendingCircleIcon from '@momentum/components/icons/pending-circle'
import Loading from '@momentum/components/loading'
import { RoutesBreadcrumb } from '@momentum/components/routes-breadcrumb-v2'
import { useSubscriptionContext } from '@momentum/contexts/Subscription'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { useClientBreadcrumbs } from '@momentum/hooks/useClientBreadcrumbs'
import { useDisclose } from '@momentum/hooks/useDisclose'
import { SubscriptionBar } from './subscription-bar'
import { SubscriptionCreditAction } from '@momentum/routes/queries'
import { AdjustCreditsForm, AdjustCreditsFormData } from '@momentum/routes/subscription/AdjustCreditsForm'
import { NotSubscribed } from '@momentum/routes/subscription/NotSubscribed'
import { SubscriptionEnded } from '@momentum/routes/subscription/SubscriptionEnded'
import { errorToast, successToast } from '@momentum/utils/toastUtils'
import {
  AssignmentTurnedInOutlined,
  Circle,
  Close,
  RadioButtonUncheckedOutlined,
  RocketLaunchOutlined
} from '@mui/icons-material'
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Chip,
  Dialog,
  IconButton,
  Stack,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
  stepConnectorClasses,
  styled
} from '@mui/material'
import {
  CreditAction,
  InvoicePaymentType,
  InvoiceStatus,
  SubscriptionLevel
} from '@productwindtom/shared-momentum-zeus-types'
import { notEmpty } from '@productwindtom/shared-node'
import { captureException } from '@sentry/react'
import { orderBy } from 'lodash'
import { DateTime } from 'luxon'
import { memo, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { BuyCreditsFormData, BuyCreditsFormV2 } from './BuyCreditsFormV2'

export const SubscriptionV1 = () => {
  const { selectedCompany, selectedBrand, brands, isAdminView } = useUserSessionContext()
  const {
    name,
    subscriptionLevel,
    startsAt,
    endsAt,
    getSubscriptionStatus,
    creditsRemaining,
    subscriptionCreditActions,
    overdueCreditActions,
    addCredits,
    spendCredits,
    purchaseCredits
  } = useSubscriptionContext()

  const { isActive, hasStarted, hasSubscription } = getSubscriptionStatus()

  const [adjustCreditsAction, setAdjustCreditsAction] = useState<CreditAction>(CreditAction.ADD)
  const [isAdjustCreditsOpen, setIsAdjustCreditsOpen] = useState(false)
  const { isOpen: isBuyCreditsOpen, onOpen: onOpenBuyCredits, onClose: onCloseBuyCredits } = useDisclose()

  const crumbs = useClientBreadcrumbs(selectedCompany, selectedBrand)

  const companyBrands = brands.filter(b => b.companyId === selectedCompany?.id)

  useEffect(() => {
    if (isActive && creditsRemaining === 0) {
      toast(<Typography variant={'subtitle2'}>You are out of credits!</Typography>, { type: 'warning' })
    }
  }, [])

  const handleAdjustCreditsClose = () => {
    setIsAdjustCreditsOpen(false)
  }

  const handleAdjustCreditsSubmit = async (data: AdjustCreditsFormData) => {
    try {
      if (data.action === CreditAction.ADD) {
        await addCredits({ numCredits: data.numCredits })
        toast(
          <Typography variant={'subtitle2'}>You have added {data.numCredits.toLocaleString()} credits</Typography>,
          {
            type: 'success'
          }
        )
      } else if (data.action === CreditAction.REMOVE) {
        await spendCredits({ numCredits: data.numCredits, associatedBrandId: data.associatedBrandId })
        toast(<Typography variant={'subtitle2'}>You have used {data.numCredits.toLocaleString()} credits</Typography>, {
          type: 'success'
        })
      }
    } catch (e) {
      toast(<Typography variant={'subtitle2'}>Failed to adjust credits!</Typography>, { type: 'error' })
    }
    setIsAdjustCreditsOpen(false)
  }

  const handleAddCreditsClick = () => {
    onCloseBuyCredits()
    setAdjustCreditsAction(CreditAction.ADD)
    setIsAdjustCreditsOpen(true)
  }

  const handleUseCreditsClick = () => {
    setAdjustCreditsAction(CreditAction.REMOVE)
    setIsAdjustCreditsOpen(true)
  }

  const submitBuyCredits = async ({ invoiceMethod, ...input }: BuyCreditsFormData) => {
    try {
      await purchaseCredits(input)
      successToast('Credits purchased successfully')
      onCloseBuyCredits()
    } catch (e) {
      console.error(e)
      captureException(e)
      errorToast('An error occurred while purchasing credits')
    }
  }

  if (!selectedBrand || !selectedCompany) {
    return <Loading />
  }

  return (
    <Stack py={3}>
      {isAdminView && (
        <Box mb={1}>
          <RoutesBreadcrumb crumbs={crumbs} />
        </Box>
      )}
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Stack>
          <Typography variant={'h3'} mb={2}>
            Subscription
          </Typography>
          <Typography variant={'h5'} mb={2}>
            {name}
          </Typography>
        </Stack>
        {isAdminView && <SubscriptionBar />}
      </Stack>

      {hasSubscription && (
        <>
          <Stack direction={'row'} spacing={4} mb={5}>
            {isActive ? (
              <Chip color={'success'} label={'Active subscription'} />
            ) : !hasStarted ? (
              <Chip
                label={'Upcoming subscription'}
                sx={{ background: theme => theme.palette.info.main, color: 'white' }}
              />
            ) : (
              <Chip label={'Inactive subscription'} />
            )}
            {!!startsAt && (
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <RocketLaunchOutlined sx={{ color: theme => theme.palette.grey.A700 }} />
                <Typography variant={'label2'}>
                  {!isActive && !hasStarted ? 'Starting' : 'Started'} {startsAt.toLocaleString(DateTime.DATE_SHORT)}
                </Typography>
              </Stack>
            )}
            {!!endsAt && (
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <AssignmentTurnedInOutlined sx={{ color: theme => theme.palette.grey.A700 }} />
                <Typography variant={'label2'}>
                  {isActive || !hasStarted ? 'Ending' : 'Ended'} {endsAt.toLocaleString(DateTime.DATE_SHORT)}
                </Typography>
              </Stack>
            )}
          </Stack>

          {isActive && (
            <Stack direction={'row'} spacing={4} alignItems={'center'} mb={5}>
              <Stack sx={{ borderLeft: '8px solid #258987', pl: 1 }}>
                <Stack direction={'row'} spacing={1}>
                  <BonusIcon sx={{ color: t => t.palette.warning.main }} />
                  <Typography variant={'h3'}>{creditsRemaining.toLocaleString()}</Typography>
                </Stack>
                <Typography variant={'subtitle2'} color={theme => theme.palette.grey.A700}>
                  Credits remaining
                </Typography>
                {subscriptionLevel === SubscriptionLevel.COMPANY && (
                  <Typography variant={'subtitle2'} color={theme => theme.palette.grey.A700}>
                    for {selectedCompany.name}
                  </Typography>
                )}
              </Stack>

              <Stack direction={'row'} spacing={2}>
                <Box>
                  <Button variant={'contained'} onClick={onOpenBuyCredits} data-cy={'buyCreditsButton'}>
                    Buy credits
                  </Button>
                </Box>
                {isAdminView && (
                  <Box>
                    <Button variant={'outlined'} onClick={handleUseCreditsClick} data-cy={'useCreditsButton'}>
                      Use credits
                    </Button>
                  </Box>
                )}
              </Stack>
            </Stack>
          )}

          {!!overdueCreditActions.length && (
            <Stack mb={5} spacing={1}>
              {overdueCreditActions.map(creditAction => (
                <Alert severity="error" variant="outlined">
                  <AlertTitle>
                    <Typography variant={'label1'} color={'black'}>
                      You have an overdue invoice for new credits.
                    </Typography>
                  </AlertTitle>
                  <Typography variant="label3" color={'black'}>
                    Pay invoice #{creditAction.invoiceNumber} to add credits and schedule new campaigns.
                  </Typography>
                </Alert>
              ))}
            </Stack>
          )}

          <Stepper orientation="vertical" connector={<StyledConnector />}>
            {orderBy(subscriptionCreditActions, 'createdAt', 'desc').map((step, index) => (
              <Step active={true} key={step.actionId} data-cy={'ledgerStep'}>
                <MemoCustomStep
                  brandName={
                    brands.find(b => b.id === (step.proposal?.brandId || step.associatedBrandId || step.id))?.name ??
                    name
                  }
                  creditAction={step}
                />
              </Step>
            ))}
          </Stepper>
        </>
      )}
      {!isActive && !startsAt && <NotSubscribed />}
      {!isActive && hasStarted && endsAt && <SubscriptionEnded endDate={endsAt} />}
      <Dialog open={isAdjustCreditsOpen} maxWidth={'xs'} fullWidth>
        <Stack p={3}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={4}>
            <Typography variant={'h4'}>
              {adjustCreditsAction === CreditAction.ADD ? 'Add' : 'Use'} credits{' '}
              {adjustCreditsAction === CreditAction.ADD ? 'for' : 'from'} {name}
            </Typography>
            <IconButton onClick={handleAdjustCreditsClose}>
              <Close />
            </IconButton>
          </Stack>
          <AdjustCreditsForm
            action={adjustCreditsAction}
            onCancel={handleAdjustCreditsClose}
            onSubmit={handleAdjustCreditsSubmit}
            creditsRemaining={creditsRemaining}
            brands={subscriptionLevel === SubscriptionLevel.COMPANY ? companyBrands : []}
          />
        </Stack>
      </Dialog>
      <Dialog open={isBuyCreditsOpen} maxWidth={'sm'} fullWidth>
        <Stack p={3} spacing={4}>
          <Stack>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography variant={'h4'}>Buy credits for {name}</Typography>
              <IconButton onClick={onCloseBuyCredits}>
                <Close />
              </IconButton>
            </Stack>
            <Typography variant={'subtitle2'} color={'grey.A700'}>
              Credits can be used on any Momentum campaign. An invoice will be generated through{' '}
              <Typography color={'primary'} variant={'subtitle1'} component={'span'}>
                Bill.com
              </Typography>
              .
            </Typography>
          </Stack>
          <BuyCreditsFormV2
            onCancel={onCloseBuyCredits}
            onManuallyAddCreditsClick={handleAddCreditsClick}
            onSubmit={submitBuyCredits}
          />
        </Stack>
      </Dialog>
    </Stack>
  )
}

const StyledConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.vertical}`]: {
    marginLeft: '7px'
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#EAECF0',
    borderWidth: 2,
    borderRadius: 1
  },
  [`& .${stepConnectorClasses.active}`]: {
    borderColor: '#EAECF0'
  }
}))

const CustomStep = ({ brandName, creditAction }: { brandName?: string; creditAction: SubscriptionCreditAction }) => {
  let line
  if (
    creditAction.proposal?.paymentType === InvoicePaymentType.EXISTING_INVOICE ||
    [CreditAction.INFO_SUBSCRIPTION_EXTENDED, CreditAction.INFO_SUBSCRIPTION_ACTIVATED].includes(creditAction.action)
  ) {
    line = <ExternalLine creditAction={creditAction} brandName={brandName} />
  } else if (creditAction.proposal?.paymentType === InvoicePaymentType.NEW_INVOICE) {
    line = <NewInvoiceLine creditAction={creditAction} brandName={brandName} />
  } else if (
    creditAction.action === CreditAction.BUY_CREDITS ||
    (creditAction.action === CreditAction.ADD && creditAction.invoiceStatus === InvoiceStatus.NOT_PAID)
  ) {
    line = <PurchaseCreditsLine creditAction={creditAction} brandName={brandName} />
  } else if (creditAction.action === CreditAction.ADD && creditAction.invoiceStatus === InvoiceStatus.PAID) {
    line = <PurchaseCreditsPaidLine creditAction={creditAction} brandName={brandName} />
  } else {
    line = <StandardLine creditAction={creditAction} brandName={brandName} />
  }

  return (
    <StepLabel
      data-cy={'ledgerStepLabel'}
      StepIconComponent={props => (
        <RadioButtonUncheckedOutlined className={props.className} color={props.color as any} sx={{ ...props.sx }} />
      )}
      StepIconProps={{ color: 'primary', sx: { fontSize: '16px' } }}
      sx={{ py: 0 }}
    >
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        <Typography
          data-cy={'ledgerStepLabelDate'}
          variant={'label1'}
          sx={{
            opacity:
              (creditAction.action === CreditAction.BUY_CREDITS || creditAction.action === CreditAction.ADD) &&
              creditAction.invoiceStatus &&
              creditAction.invoiceStatus !== InvoiceStatus.PAID
                ? 0.26
                : undefined
          }}
        >
          {DateTime.fromISO(creditAction.createdAt).toLocaleString(DateTime.DATE_SHORT)}
        </Typography>
        <Circle sx={{ color: '#EAECF0', fontSize: '4px' }} />
        <Box data-cy={'ledgerStepBody'}>{line}</Box>
      </Stack>
    </StepLabel>
  )
}

const MemoCustomStep = memo(
  CustomStep,
  (prevProps, nextProps) => prevProps.creditAction.id === nextProps.creditAction.id
)

export const StandardLine = ({
  creditAction,
  brandName
}: {
  creditAction: SubscriptionCreditAction
  brandName?: string
}) => {
  const brandNameElement = brandName ? <Typography variant={'label2'}>{brandName} </Typography> : ''
  const actionMessage = creditAction.action === CreditAction.REMOVE ? 'used' : 'added'
  const proposalBrandMessage = brandName ? `${brandName} new ` : ''
  const proposalMessage = creditAction.proposal
    ? ` for ${proposalBrandMessage}${creditAction.proposal.title} campaign`
    : ''

  return (
    <Typography variant={'label3'}>
      {creditAction.actionCreatedByFirstName} {creditAction.actionCreatedByLastName} {brandNameElement ? `at ` : ''}
      {brandNameElement}
      {actionMessage} <Typography variant={'label2'}>{creditAction.numCredits.toLocaleString()} credits</Typography>
      {proposalMessage}
    </Typography>
  )
}

export const PurchaseCreditsLine = ({
  creditAction,
  brandName
}: {
  creditAction: SubscriptionCreditAction
  brandName?: string
}) => {
  const brandNameElement = brandName ? <Typography variant={'label2'}>{brandName} </Typography> : ''
  const actionMessage = `issued invoice #${creditAction.invoiceNumber} for `

  return (
    <Stack direction={'row'} spacing={1} alignItems={'center'}>
      <Typography variant={'label3'} sx={{ opacity: creditAction.invoiceStatus !== InvoiceStatus.PAID ? 0.26 : 1 }}>
        {creditAction.actionCreatedByFirstName} {creditAction.actionCreatedByLastName} {brandNameElement ? `at ` : ''}
        {brandNameElement}
        {actionMessage} <Typography variant={'label2'}>{creditAction.numCredits.toLocaleString()} credits</Typography>
      </Typography>
      {creditAction.invoiceStatus !== InvoiceStatus.PAID && (
        <Chip
          data-cy={'ledgerStepAwaitingPaymentIcon'}
          icon={<PendingCircleIcon fontSize={'small'} sx={{ color: theme => theme.palette.grey.A700 }} />}
          label={'Awaiting payment'}
          color={'default'}
          size={'small'}
        />
      )}
    </Stack>
  )
}

export const PurchaseCreditsPaidLine = ({
  creditAction,
  brandName
}: {
  creditAction: SubscriptionCreditAction
  brandName?: string
}) => {
  const brandNameElement = brandName ? <Typography variant={'label2'}> {brandName}</Typography> : ''

  return (
    <Stack direction={'row'} spacing={1} alignItems={'center'}>
      <Typography
        variant={'label3'}
        sx={{
          opacity: creditAction.invoiceStatus !== InvoiceStatus.PAID ? 0.26 : 1
        }}
      >
        Invoice #{creditAction.invoiceNumber} was paid and{' '}
        <Typography variant={'label2'}>{creditAction.numCredits.toLocaleString()} credits</Typography> were added to
        {brandNameElement}
      </Typography>
      {creditAction.invoiceStatus !== InvoiceStatus.PAID && (
        <Chip
          icon={<PendingCircleIcon fontSize={'small'} sx={{ color: theme => theme.palette.grey.A700 }} />}
          label={'Awaiting payment'}
          color={'default'}
          size={'small'}
        />
      )}
    </Stack>
  )
}

const NewInvoiceLine = ({
  creditAction,
  brandName
}: {
  creditAction: SubscriptionCreditAction
  brandName?: string
}) => {
  const credits = <Typography variant={'label2'}>{creditAction.numCredits.toLocaleString()} credits</Typography>

  const brandNameElement = brandName ? <Typography variant={'label2'}> {brandName}</Typography> : ''
  const name = [creditAction.actionCreatedByFirstName, creditAction.actionCreatedByLastName].filter(notEmpty).join(' ')
  const invoiceNumber = creditAction.invoiceNumber || creditAction.proposal?.invoiceNumber
  const invoiceNumberDisplay = invoiceNumber ? `Invoice #${invoiceNumber} ` : ''
  const proposalTitle = creditAction.proposal?.title ? ` ${creditAction.proposal.title} campaign` : ''

  let body
  if (creditAction.action === CreditAction.INFO_INVOICE_ISSUED) {
    body = (
      <>
        {invoiceNumberDisplay}
        for {credits} was issued to {name} for{brandNameElement}
        {proposalTitle}
      </>
    )
  } else if (creditAction.action === CreditAction.ADD) {
    body = (
      <>
        {invoiceNumberDisplay}was PAID for{brandNameElement}
        {proposalTitle} and {credits} were automatically deposited
      </>
    )
  } else if (creditAction.action === CreditAction.REMOVE) {
    body = (
      <>
        {credits} were automatically applied to{brandNameElement}
        {proposalTitle}
      </>
    )
  }

  return <Typography variant={'label3'}>{body}</Typography>
}

export const ExternalLine = ({
  creditAction,
  brandName
}: {
  creditAction: SubscriptionCreditAction
  brandName?: string
}) => {
  const name = [creditAction.actionCreatedByFirstName, creditAction.actionCreatedByLastName].filter(notEmpty).join(' ')
  const brandNameElement = brandName ? <Typography variant={'label2'}>{brandName} </Typography> : ''
  const credits = <Typography variant={'label2'}>{creditAction.numCredits.toLocaleString()} credits</Typography>
  const proposalTitle = creditAction.proposal?.title ? `${creditAction.proposal.title} campaign ` : ''
  const paymentDue = creditAction.proposal?.invoicePaidDate
    ? `with all payment due on ${DateTime.fromISO(creditAction.proposal.invoicePaidDate).toLocaleString(DateTime.DATE_SHORT)} `
    : ''
  let body
  if (creditAction.action === CreditAction.INFO_INVOICE_ISSUED) {
    body = (
      <>
        {name} at ProductWind stated an outside invoice was issued for {brandNameElement}
        {proposalTitle}
        {paymentDue}for {credits}
      </>
    )
  } else if (creditAction.action === CreditAction.INFO_INVOICE_PAID) {
    body = (
      <>
        {name} at ProductWind confirmed that the {brandNameElement}
        {proposalTitle}has been paid in full for {credits}
      </>
    )
  } else if (creditAction.action === CreditAction.INFO_INVOICE_NOT_PAID) {
    body = (
      <>
        {name} at ProductWind stated that an invoice was not paid for {brandNameElement}
        {proposalTitle}
        {paymentDue}for {credits}
      </>
    )
  } else if (creditAction.action === CreditAction.INFO_SUBSCRIPTION_ACTIVATED) {
    body = (
      <>
        {name} activated the {brandNameElement} {creditAction.subscriptionTermMonths} month subscription for{' '}
        <Typography variant={'label2'}>{getSubscriptionDate(creditAction)}</Typography>
      </>
    )
  } else if (creditAction.action === CreditAction.INFO_SUBSCRIPTION_EXTENDED) {
    body = (
      <>
        {name} extended the {brandNameElement} {creditAction.subscriptionTermMonths} month subscription to{' '}
        <Typography variant={'label2'}>{getSubscriptionDate(creditAction)}</Typography>
      </>
    )
  }

  return <Typography variant={'label3'}>{body}</Typography>
}

const getSubscriptionDate = ({
  subscriptionStartsAt,
  subscriptionEndsAt
}: {
  subscriptionStartsAt?: string
  subscriptionEndsAt?: string
}) => {
  const parts = []

  if (subscriptionStartsAt) {
    parts.push(DateTime.fromISO(subscriptionStartsAt).toLocaleString(DateTime.DATE_SHORT))
  }

  if (subscriptionEndsAt) {
    if (!subscriptionStartsAt) {
      return `ending ${DateTime.fromISO(subscriptionEndsAt).toLocaleString(DateTime.DATE_SHORT)}`
    }
    parts.push(DateTime.fromISO(subscriptionEndsAt).toLocaleString(DateTime.DATE_SHORT))
  }

  if (parts.length) {
    return parts.join(' - ')
  }
}
