import { mApi } from '@momentum/api'
import { isNumber, sortBy } from 'lodash'
import { campaignSelector, productSelector, proposalSelector } from './selectors'
import { Product, Proposal } from './types'

export const listProposals = async (brandId: string, nextToken?: string): Promise<Proposal[]> => {
  const {
    listProposalsByBrandId: { items, nextToken: nt }
  } = await mApi('query')({
    listProposalsByBrandId: [
      { brandId, nextToken },
      {
        items: proposalSelector,
        nextToken: true
      }
    ]
  })
  if (nt) {
    const nextItems = await listProposals(brandId, nt)
    return [...items, ...nextItems]
  }
  return items
}

export const getProposal = async (id: string) => {
  return (
    await mApi('query')({
      getProposal: [{ id }, proposalSelector]
    })
  ).getProposal
}

const SEARCH_PRODUCTS_CHUNK_SIZE = 100

const searchBrandProducts = async (brandId: string, from: number): Promise<Product[]> => {
  const {
    searchBrandProducts: { items }
  } = await mApi('query')({
    searchBrandProducts: [
      { brandId, from, size: SEARCH_PRODUCTS_CHUNK_SIZE },
      {
        items: productSelector
      }
    ]
  })

  return items
}

export const listProducts = async (brandId: string, productCount?: number): Promise<Product[]> => {
  let count = productCount ?? 0

  if (!isNumber(productCount)) {
    const {
      searchBrandProducts: { total }
    } = await mApi('query')({
      searchBrandProducts: [
        { brandId, size: 0 },
        {
          total: true
        }
      ]
    })

    count = total || 0
  }

  if (!count) {
    return []
  }

  const chunks = Math.ceil(count / SEARCH_PRODUCTS_CHUNK_SIZE)

  const productChunks = await Promise.all(
    Array.from({ length: chunks }, (_, index) => {
      const from = index * SEARCH_PRODUCTS_CHUNK_SIZE

      return searchBrandProducts(brandId, from)
    })
  )

  return sortBy(productChunks.flat(), 'name')
}

export const listBrandCampaigns = async (brandId: string) => {
  return (
    await mApi('query')({
      listBrandCampaigns: [{ brandId }, campaignSelector]
    })
  ).listBrandCampaigns
}

export const generateCompanyRollup = async (companyId: string) => {
  return (
    await mApi('query')({
      generateCompanyRollupReport: [{ companyId }, true]
    })
  ).generateCompanyRollupReport
}
