import { mApi } from '@momentum/api'
import { GraphQLTypes, InputType, Selector } from '@productwindtom/shared-momentum-zeus-types'
import { productSelector } from '../../selectors'

export const getProductWithVariations = async (id: string) => {
  const { getProduct } = await mApi('query')({
    getProduct: [
      {
        id
      },
      {
        ...productSelector,
        parentSku: {
          ...productSelector,
          childSkus: {
            items: productSelector
          }
        },
        childSkus: {
          items: productSelector
        }
      }
    ]
  })

  return getProduct
}

const scrapeJobSelector = Selector('ScrapeRequestJob')({
  id: true,
  status: true,
  skuId: true,
  message: true
})
export type ScrapeRequestJob = InputType<GraphQLTypes['ScrapeRequestJob'], typeof scrapeJobSelector>
export const getScrapeRequestJob = async (id: string) => {
  return (
    await mApi('query')({
      getScrapeRequestJob: [{ id }, scrapeJobSelector]
    })
  ).getScrapeRequestJob
}
