import {
  Channel,
  Store,
  ContentType,
  CampaignCreatorStatus,
  CreatorType,
  ProposalGoal
} from '@productwindtom/shared-momentum-zeus-types'
import { CampaignDetails } from '@momentum/routes/campaign/context/queries'

export const SAMPLE_CAMPAIGN_KEYBOARD_REVIEWS_PL: CampaignDetails = {
  id: 'sample-campaign-keyword-reviews-pl',
  createdAt: '2024-04-23T03:07:48.678Z',
  skuId: 'sample-sku-wireless-keyboard',

  title: 'Wireless Keyboard - New Product Launch - Reviews/Ratings Only',
  goal: ProposalGoal.PRODUCT_LAUNCH,
  numCreators: 40,
  numCreatorsJoined: 40,

  numBrandAdvocateCreators: 0,
  numSocialCreators: 0,
  numPremiumUgcCreators: 0,
  numUgcCreators: 40,

  expectedUgcPostCount: 40,
  expectedReviewsCount: 20,
  expectedPlacementWins: 4,

  releaseSchedule: {
    ugcWeeklySchedule: [18, 12, 6, 3, 1],
    premiumUgcWeeklySchedule: [],
    socialWeeklySchedule: [],
    brandAdvocatesWeeklySchedule: []
  },
  anticipatedStartDate: '2024-05-03T04:00:00.000Z',
  startDate: '2024-05-04T16:16:21.000Z',

  endDate: '2024-07-03T14:54:30.679Z',
  cost: 2059897,
  product: {
    id: 'sample-sku-wireless-keyboard',
    name: 'Wireless Keyboard',
    skuId: 'WLKEYBOARD',
    priceCents: 19999,
    image: 'https://media.productwind.com/sample/images/sample-sku-wireless-keyboard.png',
    listingLink: 'https://www.amazon.com',
    store: Store.amazon,
    childSkus: { items: [] }
  },
  creators: [
    {
      userCampaignId: 'e5f9302e-737f-43de-8f50-37691e8de3a7',
      content: [
        {
          groupId: 'ddc373da-58bb-40b2-884e-20f0ea59f125',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-15T19:36:30.726Z',

          createdAt: '2024-05-13T01:20:35.538Z',
          content: [
            {
              caption: '',

              id: 'aa689dee-5a36-4c6d-b8d2-72570a5bdb23',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e5f9302e-737f-43de-8f50-37691e8de3a7/ugc/ddc373da-58bb-40b2-884e-20f0ea59f125/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'b63ce90e-1917-4400-81e4-6d7c77237521',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e5f9302e-737f-43de-8f50-37691e8de3a7/ugc/ddc373da-58bb-40b2-884e-20f0ea59f125/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'f6f2fe7d-1e56-4c35-94b5-4341d90c6982',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-15T19:36:34.691Z',

          createdAt: '2024-05-13T01:20:08.064Z',
          content: [
            {
              caption: '',

              id: '579667f1-792b-4590-8169-12bfc09e7f82',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e5f9302e-737f-43de-8f50-37691e8de3a7/ugc/f6f2fe7d-1e56-4c35-94b5-4341d90c6982/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '4df48747-6482-4138-9984-6d03ad3fd288',
        firstName: 'Nicola',
        lastName: 'Ianeselli',
        email: 'nicola.ianeselli@gmail.com',
        instagramProfile: {
          handle: 'nicolaianeselli',
          followers: 4423,
          engagementRate: 0.32557087949355645
        },
        tiktokProfile: {
          handle: 'nicolaianeselli',
          followers: 33,
          engagementRate: 22.26027397260274
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-04T01:06:30.000Z',
        amountCents: 21399
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '63da648b-1ac4-4bd8-8701-e33f7c5b4829',
      content: [
        {
          groupId: '53646550-d927-4ccb-ac6a-5ea411db5f69',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-15T05:15:20.685Z',

          createdAt: '2024-05-12T20:55:12.840Z',
          content: [
            {
              caption: '',

              id: '84f3ac22-4938-4733-9b7b-07ec0779fc16',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/63da648b-1ac4-4bd8-8701-e33f7c5b4829/ugc/53646550-d927-4ccb-ac6a-5ea411db5f69/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'eb4012af-9c24-42d9-b676-86f362844b0f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-15T05:15:15.374Z',

          createdAt: '2024-05-12T21:34:37.933Z',
          content: [
            {
              caption: '',

              id: '2d940082-c6aa-4d5e-bd90-122c31f28663',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/63da648b-1ac4-4bd8-8701-e33f7c5b4829/ugc/eb4012af-9c24-42d9-b676-86f362844b0f/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '53808b5d-83f0-4ae8-994e-cb85dbd28248',
        firstName: 'Elieset',
        lastName: 'Matias',
        email: 'wkeyely@gmail.com',

        tiktokProfile: {
          handle: 'wkeyely',
          followers: 54535,
          engagementRate: 10.770194113963683
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-04T02:14:05.000Z',
        amountCents: 21269
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '58f2dc6e-5e46-4573-a3d7-a2a51cbd572b',
      content: [
        {
          groupId: 'e5c2c9aa-7aad-49d1-a9a5-52b4eb6c7110',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-18T01:08:41.200Z',

          createdAt: '2024-05-14T01:30:07.378Z',
          content: [
            {
              caption: '',

              id: 'e868d07d-c587-4efd-a140-5be0b796ec50',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/58f2dc6e-5e46-4573-a3d7-a2a51cbd572b/ugc/e5c2c9aa-7aad-49d1-a9a5-52b4eb6c7110/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '67cd82c9-d0be-4423-9ea2-c5a39496ba70',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/58f2dc6e-5e46-4573-a3d7-a2a51cbd572b/ugc/e5c2c9aa-7aad-49d1-a9a5-52b4eb6c7110/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '006c5c5a-6585-470e-84ce-2633f420d3c8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/58f2dc6e-5e46-4573-a3d7-a2a51cbd572b/ugc/e5c2c9aa-7aad-49d1-a9a5-52b4eb6c7110/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'fbee4199-7421-44da-9742-bf3f7df5c4dc',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-18T01:08:34.439Z',

          createdAt: '2024-05-14T01:28:19.526Z',
          content: [
            {
              caption: '',

              id: '81f8e2a6-888d-4db9-92e2-1eb26d8eab94',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/58f2dc6e-5e46-4573-a3d7-a2a51cbd572b/ugc/fbee4199-7421-44da-9742-bf3f7df5c4dc/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'f834ab22-40dc-4a90-a9fd-b2cef6eb4cf5',
        firstName: 'Nelson',
        lastName: 'Lista',
        email: 'nelsonlistam@gmail.com',
        instagramProfile: {
          handle: 'nelson_lista',
          followers: 26461,
          engagementRate: 4.262499527606667
        },
        tiktokProfile: {
          handle: 'elpanadelugc',
          followers: 2281,
          engagementRate: 14.669206789054382
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-04T16:16:20.000Z',
        amountCents: 21399
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '02cf0f23-029b-48fa-a881-935afe7422da',
      content: [
        {
          groupId: '1d2b9cca-a5e2-4606-92b0-91551324f698',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-15T06:29:32.811Z',

          createdAt: '2024-05-11T20:30:22.955Z',
          content: [
            {
              caption: '',

              id: 'a1e82b74-28be-4079-b414-f5435b172c76',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/02cf0f23-029b-48fa-a881-935afe7422da/ugc/1d2b9cca-a5e2-4606-92b0-91551324f698/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'f8b19980-c0e0-4030-a23d-0f4f75524782',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/02cf0f23-029b-48fa-a881-935afe7422da/ugc/1d2b9cca-a5e2-4606-92b0-91551324f698/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '303433ab-be75-4722-b1ed-6223ceee749b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-15T06:29:25.886Z',

          createdAt: '2024-05-11T20:26:37.018Z',
          content: [
            {
              caption: '',

              id: '4a262dcc-d23e-4305-8fa9-ca24488668ec',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/02cf0f23-029b-48fa-a881-935afe7422da/ugc/303433ab-be75-4722-b1ed-6223ceee749b/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'f036e199-d883-46a6-a170-85e547e8a250',
        firstName: 'Bryan',
        lastName: 'Chen',
        email: 'bryanchen0905@gmail.com',
        instagramProfile: {
          handle: 'bryanxchen',
          followers: 1242,
          engagementRate: 7.226247987117553
        },
        tiktokProfile: {
          handle: 'bryanxchen',
          followers: 26059,
          engagementRate: 8.143322475570033
        },
        youtubeProfile: {
          handle: 'bryanxchen',
          followers: 164
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-04T16:18:17.000Z',
        amountCents: 21649
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '17243259-de7f-4106-91d3-ce79bb3cc3fd',
      content: [
        {
          groupId: 'beeac426-cde6-4e08-9cb3-3c05d95fcc3a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-08T19:14:13.736Z',

          createdAt: '2024-05-05T21:55:04.620Z',
          content: [
            {
              caption: '',

              id: 'd5d855a8-e475-47c9-b1a1-4718b6fedfa8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/17243259-de7f-4106-91d3-ce79bb3cc3fd/ugc/beeac426-cde6-4e08-9cb3-3c05d95fcc3a/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '492bce13-1e3e-4236-8501-3e2147b9d0fc',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-08T16:19:36.652Z',

          createdAt: '2024-05-05T21:56:16.745Z',
          content: [
            {
              caption: '',

              id: 'cba0be64-0929-4ecc-bacf-da0f08c93cc8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/17243259-de7f-4106-91d3-ce79bb3cc3fd/ugc/492bce13-1e3e-4236-8501-3e2147b9d0fc/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'e5cb6d19-38c8-4edb-9f9e-049613e80626',
        firstName: 'Ayoehh',
        lastName: 'TV',
        email: 'ayoehhtv@gmail.com',
        instagramProfile: {
          handle: 'ayoehhtv',
          followers: 776,
          engagementRate: 5.025773195876289
        },
        tiktokProfile: {
          handle: 'ayoehhtv',
          followers: 19116,
          engagementRate: 2.7414898320070735
        },
        youtubeProfile: {
          handle: 'ayoehh',
          followers: 1530
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-04T16:24:45.000Z',
        amountCents: 21649
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'c57593a3-3969-443f-8151-1ef145be47b9',
      content: [
        {
          groupId: '489f392a-f322-4dd0-937d-efcbe4108354',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-21T17:19:03.669Z',

          createdAt: '2024-05-14T01:18:11.337Z',
          content: [
            {
              caption: '',

              id: '955feb7c-fe38-4b8e-87ef-01192543d146',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c57593a3-3969-443f-8151-1ef145be47b9/ugc/489f392a-f322-4dd0-937d-efcbe4108354/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'e50b280e-e47f-47a0-a2aa-d3c0a306f787',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-21T17:19:08.918Z',

          createdAt: '2024-05-14T01:17:04.837Z',
          content: [
            {
              caption: '',

              id: 'c31b7b23-12a3-41b0-aea3-01131432ad00',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c57593a3-3969-443f-8151-1ef145be47b9/ugc/e50b280e-e47f-47a0-a2aa-d3c0a306f787/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '2fd7130e-458a-48ac-8001-56c46458133f',
        firstName: 'Berto',
        lastName: 'Suarez',
        email: 'rstarkid73@gmail.com',
        instagramProfile: {
          handle: 'rstarkid73',
          followers: 266713,
          engagementRate: 6.778897166617301
        },
        tiktokProfile: {
          handle: 'rstarkid73',
          followers: 503128,
          engagementRate: 10.117129941809646
        },
        youtubeProfile: {
          handle: 'rstarkid73',
          followers: 7840
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-04T18:13:58.000Z',
        amountCents: 21199
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '152b33df-4c24-45d4-90e4-7218fd4dd130',
      content: [
        {
          groupId: 'e5d03eca-4b99-46a7-804e-150fabc50c36',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-13T14:59:53.562Z',

          createdAt: '2024-05-10T06:14:22.990Z',
          content: [
            {
              caption: '',

              id: 'd2351ac0-a145-48ba-bb23-3728ce38a207',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/152b33df-4c24-45d4-90e4-7218fd4dd130/ugc/e5d03eca-4b99-46a7-804e-150fabc50c36/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'cd3e28db-ec2f-44c4-bda0-127a105e0c87',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/152b33df-4c24-45d4-90e4-7218fd4dd130/ugc/e5d03eca-4b99-46a7-804e-150fabc50c36/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '9c5ccf43-06d7-4bc1-90cc-a4438affc238',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-13T14:59:49.373Z',

          createdAt: '2024-05-10T06:01:36.207Z',
          content: [
            {
              caption: '',

              id: '820d4a69-89f3-4630-a8b2-f707e86a928e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/152b33df-4c24-45d4-90e4-7218fd4dd130/ugc/9c5ccf43-06d7-4bc1-90cc-a4438affc238/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'd2ea7fd2-a013-4dd8-8493-217bed186561',
        firstName: 'Neesh',
        lastName: 'Riaz',
        email: 'neeshceo@gmail.com',
        instagramProfile: {
          handle: 'neesh__me',
          followers: 38346,
          engagementRate: 0.9330829812757523
        },
        tiktokProfile: {
          handle: 'neesh_me',
          followers: 36915,
          engagementRate: 9.55207994396863
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-04T21:23:15.000Z',
        amountCents: 21899
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '8fa026a1-5427-427b-8a7a-97adb9bc3527',
      content: [],
      creator: {
        id: '58736121-4052-4709-9329-06eef849c6f2',
        firstName: 'Carisia',
        lastName: 'Gallegos',
        email: 'prettycarisia@gmail.com',
        instagramProfile: {
          handle: 'chaicari',
          followers: 2277,
          engagementRate: 6.741326306543698
        },
        tiktokProfile: {
          handle: 'haveyoumetcari',
          followers: 21552,
          engagementRate: 18.336630148103065
        },
        youtubeProfile: {
          handle: 'cari8124',
          followers: 9
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-04T21:29:06.000Z',
        amountCents: 21724
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '77baa71d-077e-4f9c-b756-823c4443025b',
      content: [],
      creator: {
        id: '4155ee60-2ad5-46dc-8890-4bc3a3851142',
        firstName: 'Katherine',
        lastName: 'Nunez',
        email: 'misspetite@misspetitecosplay.com',
        instagramProfile: {
          handle: 'misspetitecosplay',
          followers: 7537,
          engagementRate: 6.506567599840785
        },
        tiktokProfile: {
          handle: 'misspetitecosplay',
          followers: 899,
          engagementRate: 10.850439882697946
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-05T05:16:11.000Z',
        amountCents: 21399
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '3ecaa10e-910b-4228-babd-fae1ee27ac2a',
      content: [
        {
          groupId: 'a8aa0fc9-9bf6-4a01-be18-bf935cd43248',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-21T17:44:08.879Z',

          createdAt: '2024-05-19T06:40:33.225Z',
          content: [
            {
              caption: '',

              id: 'fe694ce8-72d5-4dd7-b8aa-357816709aef',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3ecaa10e-910b-4228-babd-fae1ee27ac2a/ugc/a8aa0fc9-9bf6-4a01-be18-bf935cd43248/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '326a586d-7a4c-4cac-927a-4dc8e6de993e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-21T17:44:13.567Z',

          createdAt: '2024-05-19T06:38:18.359Z',
          content: [
            {
              caption: '',

              id: '6790fb99-36fb-46ea-8fc7-a4ab56a769b5',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3ecaa10e-910b-4228-babd-fae1ee27ac2a/ugc/326a586d-7a4c-4cac-927a-4dc8e6de993e/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '4b35b0cf-fcd9-473d-864d-b1d239a7cb4b',
        firstName: 'David',
        lastName: 'Greenberg',
        email: 'smallbeezy@gmail.com',
        instagramProfile: {
          handle: 'smallbossmc',
          followers: 17782,
          engagementRate: 0.39534360589360024
        },
        tiktokProfile: {
          handle: 'smallbossmc',
          followers: 7429
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-05T09:28:07.000Z',
        amountCents: 21899
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a246bcbd-0869-481a-848c-0fd5e4821083',
      content: [
        {
          groupId: '61a53bfd-765f-40a2-bfcb-ac529a4c50c1',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-17T18:03:12.852Z',

          createdAt: '2024-05-14T18:18:52.071Z',
          content: [
            {
              caption: '',

              id: '3bc4e800-e49b-489d-a959-e0d54996964c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a246bcbd-0869-481a-848c-0fd5e4821083/ugc/61a53bfd-765f-40a2-bfcb-ac529a4c50c1/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'b8d4d043-d68d-433c-bc39-ff0469d55974',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a246bcbd-0869-481a-848c-0fd5e4821083/ugc/61a53bfd-765f-40a2-bfcb-ac529a4c50c1/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '631230c0-13f4-42bf-895b-0eb41bc8a91d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a246bcbd-0869-481a-848c-0fd5e4821083/ugc/61a53bfd-765f-40a2-bfcb-ac529a4c50c1/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'add007fb-fe88-4319-bc9e-77178b55c8d1',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-17T18:03:05.958Z',

          createdAt: '2024-05-14T18:18:16.649Z',
          content: [
            {
              caption: '',

              id: 'f1a8c7e2-f4c4-4a14-a630-ca5cd128f84a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a246bcbd-0869-481a-848c-0fd5e4821083/ugc/add007fb-fe88-4319-bc9e-77178b55c8d1/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'cc0b3bf7-ef2e-402b-a53e-d911b32d80cb',
        firstName: 'Austin',
        lastName: 'Becker',
        email: 'becker.austin@outlook.com',
        instagramProfile: {
          handle: 'theskierguy',
          followers: 11020,
          engagementRate: 1.0989110707803993
        },
        tiktokProfile: {
          handle: 'theskierguyco',
          followers: 1559,
          engagementRate: 7.894119817348014
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-05T19:24:32.000Z',
        amountCents: 21789
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f83126e8-83dc-4926-9d8c-f9d09d595f87',
      content: [
        {
          groupId: 'b1897b40-2bf1-4ecf-bf9b-2b5c6befad3d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T19:45:56.436Z',

          createdAt: '2024-05-29T02:09:29.976Z',
          content: [
            {
              caption: '',

              id: '7972e1d3-fe24-4d87-bdbf-39e270f4382a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f83126e8-83dc-4926-9d8c-f9d09d595f87/ugc/b1897b40-2bf1-4ecf-bf9b-2b5c6befad3d/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'af1f7c1d-3bd8-4b2d-a1c1-1890a526ef61',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-29T19:46:00.328Z',

          createdAt: '2024-05-29T01:57:45.899Z',
          content: [
            {
              caption: '',

              id: '6bbc6db3-ea9c-41c9-9157-07e47db11703',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f83126e8-83dc-4926-9d8c-f9d09d595f87/ugc/af1f7c1d-3bd8-4b2d-a1c1-1890a526ef61/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'f511ade1-6f1f-4413-8929-429df95ce3ce',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f83126e8-83dc-4926-9d8c-f9d09d595f87/ugc/af1f7c1d-3bd8-4b2d-a1c1-1890a526ef61/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'd6fe66ba-d499-45e6-8da7-74b522d8cacc',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f83126e8-83dc-4926-9d8c-f9d09d595f87/ugc/af1f7c1d-3bd8-4b2d-a1c1-1890a526ef61/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'f4ff3237-ff2a-4288-b3fd-0d4c63431065',
        firstName: 'Hassan',
        lastName: 'Chammout',
        email: 'chammouth@gmail.com',
        instagramProfile: {
          handle: 'hassan_chammout',
          followers: 610,
          engagementRate: 11.295081967213115
        },
        tiktokProfile: {
          handle: 'chammouth',
          followers: 11186,
          engagementRate: 2.2288006111535523
        },
        youtubeProfile: {
          handle: 'chammouth',
          followers: 164
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-06T01:12:04.000Z',
        amountCents: 21199
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '136250a5-8026-4f11-9a51-8e4914facffd',
      content: [
        {
          groupId: '64407603-e134-4fe1-a058-32b54759d24b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-13T14:46:45.334Z',

          createdAt: '2024-05-10T18:09:05.231Z',
          content: [
            {
              caption: '',

              id: 'e7980ee2-92b5-400f-b22e-b7650d2a2073',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/136250a5-8026-4f11-9a51-8e4914facffd/ugc/64407603-e134-4fe1-a058-32b54759d24b/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '2ce7cdde-d976-4bdf-89bf-453f8489e9d7',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-13T14:46:49.628Z',

          createdAt: '2024-05-10T17:45:05.985Z',
          content: [
            {
              caption: '',

              id: 'cc890293-0ae9-4085-aa03-0d743a4fece7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/136250a5-8026-4f11-9a51-8e4914facffd/ugc/2ce7cdde-d976-4bdf-89bf-453f8489e9d7/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '379b2b2e-56b1-4d93-97c5-0fdbf20eb9df',
        firstName: 'Izzy',
        lastName: 'Hanneman',
        email: 'izzy561999@live.com',
        instagramProfile: {
          handle: 'izzythrills',
          followers: 4460,
          engagementRate: 1.7600896860986548
        },
        tiktokProfile: {
          handle: 'izzythrills',
          followers: 32156,
          engagementRate: 9.802148543164076
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-06T14:36:30.000Z',
        amountCents: 21624
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '72a0805e-91df-4fcf-8021-2949eea3aca2',
      content: [
        {
          groupId: 'ae30e676-c79f-4942-a91a-e9e693d9f3b5',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-21T16:25:11.475Z',

          createdAt: '2024-05-15T23:46:28.341Z',
          content: [
            {
              caption: '',

              id: '24d75670-c87a-48ed-9057-f6e57a62f47e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/72a0805e-91df-4fcf-8021-2949eea3aca2/ugc/ae30e676-c79f-4942-a91a-e9e693d9f3b5/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '1e3ce822-ed9e-4447-9f2e-a9439851dfe8',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-21T16:38:57.492Z',

          createdAt: '2024-05-16T04:26:18.102Z',
          content: [
            {
              caption: '',

              id: 'a2b16a43-7667-4efb-92ee-5922d735dc5d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/72a0805e-91df-4fcf-8021-2949eea3aca2/ugc/1e3ce822-ed9e-4447-9f2e-a9439851dfe8/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '4c3dd077-4f96-4810-ab10-f20e4fb02196',
        firstName: 'Benjamin',
        lastName: 'Miller',
        email: 'wrekonizereal@gmail.com',
        instagramProfile: {
          handle: 'wrekonize',
          followers: 106783,
          engagementRate: 0.5243343977974022
        },
        tiktokProfile: {
          handle: 'wrekonizemusic',
          followers: 4063,
          engagementRate: 10.32917139614075
        },
        youtubeProfile: {
          handle: 'wrekonizethemusic',
          followers: 105000
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-06T16:16:13.000Z',
        amountCents: 21399
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '32c8ea34-0213-425b-ab79-67aed57bb7ee',
      content: [
        {
          groupId: 'f57d5352-3086-4327-9e5e-d8ffa62cc744',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-21T19:00:06.893Z',

          createdAt: '2024-05-17T22:15:30.656Z',
          content: [
            {
              caption: '',

              id: '836e6a90-f33b-430b-8cf8-aeeb23d2a52e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/32c8ea34-0213-425b-ab79-67aed57bb7ee/ugc/f57d5352-3086-4327-9e5e-d8ffa62cc744/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '7dba6fc0-3c14-42d9-9fb5-db996888e2c5',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/32c8ea34-0213-425b-ab79-67aed57bb7ee/ugc/f57d5352-3086-4327-9e5e-d8ffa62cc744/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '77cb25e8-3102-4743-9586-f6e466942cb5',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/32c8ea34-0213-425b-ab79-67aed57bb7ee/ugc/f57d5352-3086-4327-9e5e-d8ffa62cc744/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '8bc01229-075a-46d0-a35f-ae71f864aa05',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-21T19:00:11.991Z',

          createdAt: '2024-05-17T22:14:59.949Z',
          content: [
            {
              caption: '',

              id: '832ab985-8f6e-4e0e-88a8-fd34ce849518',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/32c8ea34-0213-425b-ab79-67aed57bb7ee/ugc/8bc01229-075a-46d0-a35f-ae71f864aa05/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '9136f750-6c7a-402c-bdc5-edd4398d2c22',
        firstName: 'TheWayne',
        lastName: 'Twinz',
        email: 'hernandezwaynetwins@gmail.com',

        tiktokProfile: {
          handle: 'preacherwayne',
          followers: 93037,
          engagementRate: 4.989966234700846
        },
        youtubeProfile: {
          handle: 'waynetwinzfn',
          followers: 3440
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-06T23:24:06.000Z',
        amountCents: 22934
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f25609f6-45dd-4592-9429-8fbef41022b6',
      content: [],
      creator: {
        id: 'ab302bbf-8bd3-4fd1-9d34-4d355e6fee7b',
        firstName: 'Ovi',
        lastName: 'Kabir',
        email: 'ovi@kabircreative.com',
        instagramProfile: {
          handle: 'ovikabir',
          followers: 49276,
          engagementRate: 1.137876451010634
        },
        tiktokProfile: {
          handle: 'basmatiboy',
          followers: 1991,
          engagementRate: 4.27807486631016
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-07T01:53:31.000Z',
        amountCents: 21774
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a93a2290-0bc4-4367-b992-02b24d347306',
      content: [
        {
          groupId: 'e006c876-f5ea-4046-ae71-23056438a260',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-16T18:43:07.814Z',

          createdAt: '2024-05-15T04:24:00.529Z',
          content: [
            {
              caption: '',

              id: 'd6b4d6db-6580-4312-8f81-8a5bc1392bcc',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a93a2290-0bc4-4367-b992-02b24d347306/ugc/e006c876-f5ea-4046-ae71-23056438a260/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '5f742afc-98f8-4a39-860a-76abbe62f712',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a93a2290-0bc4-4367-b992-02b24d347306/ugc/e006c876-f5ea-4046-ae71-23056438a260/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '60b70dbd-2249-4409-a049-56c95e1d112b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a93a2290-0bc4-4367-b992-02b24d347306/ugc/e006c876-f5ea-4046-ae71-23056438a260/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'c7044248-7473-4840-a15b-587b53f7e815',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-16T18:43:02.041Z',

          createdAt: '2024-05-15T04:23:13.764Z',
          content: [
            {
              caption: '',

              id: '1002b580-d283-4e2f-adfb-e2943f484fb6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a93a2290-0bc4-4367-b992-02b24d347306/ugc/c7044248-7473-4840-a15b-587b53f7e815/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'b2fa3071-495c-41ca-bf45-8de958dd1ac0',
        firstName: 'Brittney',
        lastName: 'Moore',
        email: 'brittneyyvonne96@gmail.com',
        instagramProfile: {
          handle: 'brittneyyvonne',
          followers: 925,
          engagementRate: 0.7891891891891891
        },
        tiktokProfile: {
          handle: 'brittneyyvonne',
          followers: 911,
          engagementRate: 3.8467658469899995
        },
        youtubeProfile: {
          handle: 'brittneyyvonne'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-08T01:03:59.000Z',
        amountCents: 21449
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'e0cb47f6-135a-4038-88d5-c23a99625504',
      content: [
        {
          groupId: '36161cf5-4491-4ad5-af8e-28f0ac126575',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-15T04:59:28.013Z',

          createdAt: '2024-05-11T17:50:34.583Z',
          content: [
            {
              caption: '',

              id: 'a3fc842b-b7fc-44ec-90fd-ae5b4dec0d2d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e0cb47f6-135a-4038-88d5-c23a99625504/ugc/36161cf5-4491-4ad5-af8e-28f0ac126575/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '243e33e7-769d-486e-b4f1-f454db4116b9',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-15T04:59:21.934Z',

          createdAt: '2024-05-11T17:49:46.567Z',
          content: [
            {
              caption: '',

              id: 'f6670b72-a0bc-4468-862e-9d77187f6bdc',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e0cb47f6-135a-4038-88d5-c23a99625504/ugc/243e33e7-769d-486e-b4f1-f454db4116b9/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '34955116-7653-4064-acff-38a26d2bb81c',
        firstName: 'Emily',
        lastName: 'Supiot',
        email: 'emilysupiot@gmail.com',
        instagramProfile: {
          handle: 'cozyclicks',
          followers: 18606,
          engagementRate: 0.23917016016338816
        },
        tiktokProfile: {
          handle: 'cozyclicks',
          followers: 6705
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-08T01:32:11.000Z',
        amountCents: 21719
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '299edfa2-f517-405e-a108-024a01816ce3',
      content: [
        {
          groupId: 'f4e87c05-51e3-4b7b-96a1-1d6aee2df2f6',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-22T15:15:56.248Z',

          createdAt: '2024-05-21T06:31:07.021Z',
          content: [
            {
              caption: '',

              id: 'be55678f-e0d2-42e0-97d2-b149561cc84b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/299edfa2-f517-405e-a108-024a01816ce3/ugc/f4e87c05-51e3-4b7b-96a1-1d6aee2df2f6/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'c73c2a80-0ec8-442e-856e-d6ad70d4791b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/299edfa2-f517-405e-a108-024a01816ce3/ugc/f4e87c05-51e3-4b7b-96a1-1d6aee2df2f6/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'a5b41d79-ed62-466d-aac1-c4e794be6bb0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/299edfa2-f517-405e-a108-024a01816ce3/ugc/f4e87c05-51e3-4b7b-96a1-1d6aee2df2f6/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '4243d977-e21a-4f69-98f7-30c125ca5649',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-22T15:15:51.110Z',

          createdAt: '2024-05-21T07:16:13.596Z',
          content: [
            {
              caption: '',

              id: '2e1cb1db-996f-440b-9bc3-537805141ed9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/299edfa2-f517-405e-a108-024a01816ce3/ugc/4243d977-e21a-4f69-98f7-30c125ca5649/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '32710b08-068f-430c-8902-442fbaffb853',
        firstName: 'Mary',
        lastName: 'Su',
        email: 'mareeesu@gmail.com',
        instagramProfile: {
          handle: 'maryinbeige',
          followers: 3,
          engagementRate: 9.443127962085308
        },
        tiktokProfile: {
          handle: '___marysu',
          followers: 132,
          engagementRate: 2.054794520547945
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-13T04:00:00.000Z',
        amountCents: 22049
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'b77bf5db-3bed-4211-9545-77f385dd5f8e',
      content: [
        {
          groupId: '580fb84c-1c3a-403f-9e6d-678353293028',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-24T15:42:27.861Z',

          createdAt: '2024-05-23T16:53:02.371Z',
          content: [
            {
              caption: '',

              id: 'e6d6eea9-1aa7-4316-b853-213dcd022f53',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b77bf5db-3bed-4211-9545-77f385dd5f8e/ugc/580fb84c-1c3a-403f-9e6d-678353293028/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'b5f1094d-41db-440a-b312-582fc9d847d1',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-22T15:18:53.604Z',

          createdAt: '2024-05-21T19:58:20.201Z',
          content: [
            {
              caption: '',

              id: 'f043dd7f-8db5-4348-91e2-7129fd28c245',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b77bf5db-3bed-4211-9545-77f385dd5f8e/ugc/b5f1094d-41db-440a-b312-582fc9d847d1/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'b48c0e77-4128-470f-96ea-f59a56f044d0',
        firstName: 'Shaun',
        lastName: 'Bailey',
        email: 'shaunbailey803@gmail.com',
        instagramProfile: {
          handle: 'patrivkbailey',
          followers: 2731,
          engagementRate: 1.2303185646283414
        },
        tiktokProfile: {
          handle: 'iamshaunbailey',
          followers: 247,
          engagementRate: 6.92410119840213
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-13T20:02:45.000Z',
        amountCents: 21199
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '2846402c-6e9c-4b63-8565-fa496866726c',
      content: [
        {
          groupId: '2bef49ab-d845-4c61-ae9f-e09bc98bc0a8',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-24T21:55:40.035Z',

          createdAt: '2024-05-22T18:13:49.292Z',
          content: [
            {
              caption: '',

              id: '0083d5f5-59b6-4458-a5d0-207d62fabbf6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2846402c-6e9c-4b63-8565-fa496866726c/ugc/2bef49ab-d845-4c61-ae9f-e09bc98bc0a8/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '3e112745-9fdd-49c0-ad9a-bde1b9275551',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2846402c-6e9c-4b63-8565-fa496866726c/ugc/2bef49ab-d845-4c61-ae9f-e09bc98bc0a8/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '365c05b0-c723-4e0d-99c5-a8d4bee4311a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-24T21:55:43.774Z',

          createdAt: '2024-05-23T17:01:56.813Z',
          content: [
            {
              caption: '',

              id: '9e35078c-90af-44bc-8f4a-ad86a5c1b058',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2846402c-6e9c-4b63-8565-fa496866726c/ugc/365c05b0-c723-4e0d-99c5-a8d4bee4311a/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'b7a1627c-9a4d-4359-aea3-0cda4048b9b4',
        firstName: 'Jennifer',
        lastName: 'Jarvie',
        email: 'jarvie.jennifer@gmail.com',

        tiktokProfile: {
          handle: 'worldlyjen',
          followers: 22355,
          engagementRate: 10.289389067524116
        },
        youtubeProfile: {
          handle: 'clubjarvie3708',
          followers: 25
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-13T22:01:54.000Z',
        amountCents: 21399
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '3acd9df2-9424-416e-92dd-cbf3cf7aabcb',
      content: [],
      creator: {
        id: 'ecb08bdf-6955-455b-bf25-f7b9136640c1',
        firstName: 'Zhé',
        lastName: 'Aqueen',
        email: 'shine@zheaqueen.com',
        instagramProfile: {
          handle: 'zheaqueen',
          followers: 8602,
          engagementRate: 1.1973959544292025
        },
        tiktokProfile: {
          handle: 'zheaqueenmusic',
          followers: 3345,
          engagementRate: 14.788042807796694
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-14T15:22:44.000Z',
        amountCents: 21199
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'bd32897b-1a65-4b93-97f6-43d239190641',
      content: [
        {
          groupId: '7083cc46-9aa0-42e6-af70-ac26bcbcb007',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-28T18:07:03.392Z',

          createdAt: '2024-05-26T18:33:18.893Z',
          content: [
            {
              caption: '',

              id: '5ec1b9fc-8886-4da9-ad0b-bf352cf8e41b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/bd32897b-1a65-4b93-97f6-43d239190641/ugc/7083cc46-9aa0-42e6-af70-ac26bcbcb007/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '326014ca-0b99-4e17-9ac1-eb4a5fa16bea',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/bd32897b-1a65-4b93-97f6-43d239190641/ugc/7083cc46-9aa0-42e6-af70-ac26bcbcb007/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '264fec96-aa23-42e2-a9b9-44f33fa7db6e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/bd32897b-1a65-4b93-97f6-43d239190641/ugc/7083cc46-9aa0-42e6-af70-ac26bcbcb007/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'd0d41d38-0ff3-4b7a-a704-73262cc151a8',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-21T21:22:31.875Z',

          createdAt: '2024-05-20T17:56:49.411Z',
          content: [
            {
              caption: '',

              id: '591dddf1-7d09-4fe7-97cf-edf2c26feb15',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/bd32897b-1a65-4b93-97f6-43d239190641/ugc/d0d41d38-0ff3-4b7a-a704-73262cc151a8/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'd89b43cc-332b-4430-bea1-864303a7b94a',
        firstName: 'Isaac',
        lastName: 'Hernandez',
        email: 'isaac@eightshotsmedia.com',
        instagramProfile: {
          handle: 'shotbyisaaccc',
          followers: 3263,
          engagementRate: 1.4710389212381243
        },
        tiktokProfile: {
          handle: 'shotbyisaaccc',
          followers: 254321,
          engagementRate: 5.821558507487944
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-15T18:05:06.000Z',
        amountCents: 21489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '99467819-b188-4176-b57b-28d835c14204',
      content: [],
      creator: {
        id: '1186abc0-e3c4-476d-8bd8-84dba7d50af0',
        firstName: 'Deya',
        lastName: 'Lopez',
        email: 'natureee99@gmail.com',
        instagramProfile: {
          handle: 'naturebabe99',
          followers: 26350,
          engagementRate: 2.089943074003795
        },
        tiktokProfile: {
          handle: 'naturebabe99',
          followers: 42074,
          engagementRate: 13.97804216855117
        },
        youtubeProfile: {
          handle: 'deya4382'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-15T19:54:50.000Z',
        amountCents: 21619
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '48bf5347-210d-46dd-920d-253f87095e1d',
      content: [
        {
          groupId: '56d66164-1878-4632-b2cd-2e283fb00a36',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T20:16:26.568Z',

          createdAt: '2024-05-26T04:07:34.406Z',
          content: [
            {
              caption: '',

              id: '8df656ea-499b-411d-9969-207d4c7496f7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/48bf5347-210d-46dd-920d-253f87095e1d/ugc/56d66164-1878-4632-b2cd-2e283fb00a36/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '3b22f892-f90d-46af-a7d1-0134645d3366',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-29T19:36:37.008Z',

          createdAt: '2024-05-28T19:16:26.906Z',
          content: [
            {
              caption: '',

              id: '45b396d4-f198-44fe-a950-9759d0e668b8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/48bf5347-210d-46dd-920d-253f87095e1d/ugc/3b22f892-f90d-46af-a7d1-0134645d3366/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '298221ee-af53-4a07-9701-98f2b727b517',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/48bf5347-210d-46dd-920d-253f87095e1d/ugc/3b22f892-f90d-46af-a7d1-0134645d3366/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '1459e7c1-a1e4-4a03-9da3-210218f72645',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/48bf5347-210d-46dd-920d-253f87095e1d/ugc/3b22f892-f90d-46af-a7d1-0134645d3366/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '3abb2602-5a0b-46e7-811b-0409d043cac8',
        firstName: 'Nyejah',
        lastName: 'Davis',
        email: 'nyejahbolds@gmail.com',
        instagramProfile: {
          handle: 'nyejahboldsphotography',
          followers: 9447,
          engagementRate: 1.213083518577326
        },
        tiktokProfile: {
          handle: 'itsnyejah',
          followers: 2427,
          engagementRate: 8.752820512820513
        },
        youtubeProfile: {
          handle: 'itsnyejah',
          followers: 26
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-15T23:51:55.000Z',
        amountCents: 23135
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'c90bc058-947a-4fc4-9765-dbb915e1840b',
      content: [
        {
          groupId: '2398009b-84c2-4251-aa9f-c047d8bbc0b0',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-04T14:43:42.732Z',

          createdAt: '2024-06-02T20:45:33.654Z',
          content: [
            {
              caption: '',

              id: '72b3eaf0-79a8-47f8-b7bd-3d605f2374e5',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c90bc058-947a-4fc4-9765-dbb915e1840b/ugc/2398009b-84c2-4251-aa9f-c047d8bbc0b0/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '71473f60-56c5-4dd0-a69f-0a16fd28d8b6',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-04T14:43:50.654Z',

          createdAt: '2024-06-02T20:16:53.927Z',
          content: [
            {
              caption: '',

              id: '2565821f-9fa9-47a3-857c-610be702c7fd',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c90bc058-947a-4fc4-9765-dbb915e1840b/ugc/71473f60-56c5-4dd0-a69f-0a16fd28d8b6/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '9937bb61-7c0b-4f0c-a511-3c38a3bbde87',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c90bc058-947a-4fc4-9765-dbb915e1840b/ugc/71473f60-56c5-4dd0-a69f-0a16fd28d8b6/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '05f4988d-7b16-4876-8857-99b048df901f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c90bc058-947a-4fc4-9765-dbb915e1840b/ugc/71473f60-56c5-4dd0-a69f-0a16fd28d8b6/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '7b2073ff-a965-472d-93cd-d1cfb8a03e1a',
        firstName: 'Jaidah',
        lastName: 'Liverman',
        email: 'info@jaidahofficial.com',
        instagramProfile: {
          handle: 'jaidahofficial',
          followers: 2470,
          engagementRate: 1.1214574898785423
        },
        tiktokProfile: {
          handle: 'jaidahofficial',
          followers: 456,
          engagementRate: 0.6864988558352403
        },
        youtubeProfile: {
          handle: 'jaidahofficial'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-15T23:51:55.000Z',
        amountCents: 21399
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '2f169f14-c1a7-4956-ab1e-e9259efdd10d',
      content: [],
      creator: {
        id: '54960d91-674b-4ba6-a9e9-f708967fc83a',
        firstName: 'Chris',
        lastName: 'Parker',
        email: 'officialteamhlg@gmail.com',
        instagramProfile: {
          handle: 'hlg_cpthakidd',
          followers: 83047,
          engagementRate: 0.8351897118499164
        },
        tiktokProfile: {
          handle: 'thehlgshow',
          followers: 25738,
          engagementRate: 9.788359788359788
        },
        youtubeProfile: {
          handle: 'officialhlgshow'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-16T01:32:56.000Z',
        amountCents: 22406
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '8c09235d-71e8-4bb7-9ff4-46d5e185726b',
      content: [],
      creator: {
        id: 'a32acc3d-7fdb-47cb-819d-955a36e4defb',
        firstName: 'Omar',
        lastName: 'Delafuente',
        email: 'blinxybusiness@gmail.com',
        instagramProfile: {
          handle: 'blinxygaming',
          followers: 884,
          engagementRate: 2.0475113122171944
        },
        tiktokProfile: {
          handle: 'blinxygaming',
          followers: 845778
        },
        youtubeProfile: {
          handle: 'blinxygaming',
          followers: 58700
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-16T12:14:44.000Z',
        amountCents: 22406
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '7e358dc0-f4b3-491d-aa5b-c5fde5a3af1d',
      content: [],
      creator: {
        id: 'ac2cb015-fe2b-4453-b63c-2960fe3b18bf',
        firstName: 'Monica',
        lastName: 'Seabourn',
        email: 'hello@monicaseabourn.com',
        instagramProfile: {
          handle: 'monicaseabourn',
          followers: 2200,
          engagementRate: 1.5636363636363635
        },
        tiktokProfile: {
          handle: 'monicaseabourn',
          followers: 1310,
          engagementRate: 8.348134991119005
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-16T17:23:01.000Z',
        amountCents: 21699
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '3de2d52c-8ebc-4ff8-bd85-76210e2eb297',
      content: [
        {
          groupId: 'd8d5b820-ad20-4376-a587-ad568070dd73',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-04T03:23:25.054Z',

          createdAt: '2024-06-01T18:36:32.815Z',
          content: [
            {
              caption: '',

              id: 'e3aebcc3-cf80-4fe8-907b-d6f3e5a55b21',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3de2d52c-8ebc-4ff8-bd85-76210e2eb297/ugc/d8d5b820-ad20-4376-a587-ad568070dd73/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '111561ca-0f93-4573-9d98-973ebc88eafc',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-04T03:23:30.730Z',

          createdAt: '2024-06-01T18:36:52.794Z',
          content: [
            {
              caption: '',

              id: '0301e423-3562-4919-b72d-7e311d810c0a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3de2d52c-8ebc-4ff8-bd85-76210e2eb297/ugc/111561ca-0f93-4573-9d98-973ebc88eafc/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '177caa77-8977-4bfb-9f7b-4bcd9c14a6da',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3de2d52c-8ebc-4ff8-bd85-76210e2eb297/ugc/111561ca-0f93-4573-9d98-973ebc88eafc/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'a00f615e-8f8a-4ed2-a6ad-95fdedbda1fa',
        firstName: 'Carolina',
        lastName: 'Viloria',
        email: 'carolinacvp23@gmail.com',
        instagramProfile: {
          handle: 'caroolvvp',
          followers: 2136,
          engagementRate: 3.206928838951311
        },
        tiktokProfile: {
          handle: 'carocv',
          followers: 1179,
          engagementRate: 13.297872340425531
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-17T15:06:12.000Z',
        amountCents: 21299
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'fca5a28f-41a9-499f-890b-1971541af0a2',
      content: [
        {
          groupId: 'afb412f7-268c-44ad-a9df-48609a92b457',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-24T13:35:48.985Z',

          createdAt: '2024-05-23T23:13:45.010Z',
          content: [
            {
              caption: '',

              id: 'fcb87868-6824-4b87-8761-a3c5ca71e1df',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/fca5a28f-41a9-499f-890b-1971541af0a2/ugc/afb412f7-268c-44ad-a9df-48609a92b457/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '21546e47-c85b-4a92-81e8-799a14965fc9',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-24T13:35:54.499Z',

          createdAt: '2024-05-23T23:38:39.767Z',
          content: [
            {
              caption: '',

              id: 'e63d6e71-7792-4a71-a873-a7c84b08c1ae',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/fca5a28f-41a9-499f-890b-1971541af0a2/ugc/21546e47-c85b-4a92-81e8-799a14965fc9/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '5b7b79de-84fa-4b53-a7e4-6387c3f50856',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/fca5a28f-41a9-499f-890b-1971541af0a2/ugc/21546e47-c85b-4a92-81e8-799a14965fc9/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'faa4896d-a02e-4470-8fc1-082ebafd7169',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/fca5a28f-41a9-499f-890b-1971541af0a2/ugc/21546e47-c85b-4a92-81e8-799a14965fc9/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '55ed0f31-1432-4855-9f30-b903dc8f414b',
        firstName: 'Ariel',
        lastName: 'Ford',
        email: 'arieluneque1997@gmail.com',

        tiktokProfile: {
          handle: 'arielmomlife',
          followers: 2258,
          engagementRate: 10.604960677555958
        },
        youtubeProfile: {
          handle: 'arieluford',
          followers: 1510
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-19T00:41:55.000Z',
        amountCents: 21599
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '2fff5ad1-3e05-4875-a339-fde095347d8d',
      content: [
        {
          groupId: 'a2b8384c-a8eb-4550-8519-237b9b6e8894',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-30T22:50:21.341Z',

          createdAt: '2024-05-25T00:27:04.905Z',
          content: [
            {
              caption: '',

              id: '2f20210a-4de1-470d-8b9d-f7f76f571511',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2fff5ad1-3e05-4875-a339-fde095347d8d/ugc/a2b8384c-a8eb-4550-8519-237b9b6e8894/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '532000da-8634-4f3b-882a-d7830e0edb53',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-30T22:50:26.240Z',

          createdAt: '2024-05-25T00:32:04.722Z',
          content: [
            {
              caption: '',

              id: 'ed190247-df9d-497f-9dcc-7b4f5dd60be5',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2fff5ad1-3e05-4875-a339-fde095347d8d/ugc/532000da-8634-4f3b-882a-d7830e0edb53/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'f2ee4f8d-f91d-412b-877f-b8daa9253cac',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2fff5ad1-3e05-4875-a339-fde095347d8d/ugc/532000da-8634-4f3b-882a-d7830e0edb53/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'd134fdab-f741-4112-b66d-05665a519cbf',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2fff5ad1-3e05-4875-a339-fde095347d8d/ugc/532000da-8634-4f3b-882a-d7830e0edb53/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'e912f993-536f-4041-b857-5ec523be9f99',
        firstName: 'Alexis',
        lastName: 'Montalvo',
        email: 'amtmontalvo@gmail.com',
        instagramProfile: {
          handle: 'alexismontalvo',
          followers: 93004,
          engagementRate: 1.6525095694808825
        },
        tiktokProfile: {
          handle: 'blamealexis',
          followers: 132281,
          engagementRate: 6.692607003891051
        },
        youtubeProfile: {
          handle: 'blamealexis',
          followers: 232
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-21T07:44:33.000Z',
        amountCents: 21549
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '2283bb32-59d8-4dd1-a796-99daf2eba236',
      content: [
        {
          groupId: '14c7cc4a-d980-4135-a3bf-e3cba8bdcafe',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-29T15:14:38.806Z',

          createdAt: '2024-05-27T18:43:31.315Z',
          content: [
            {
              caption: '',

              id: '9d7815da-49d3-498d-b28c-c50e59c4cfc6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2283bb32-59d8-4dd1-a796-99daf2eba236/ugc/14c7cc4a-d980-4135-a3bf-e3cba8bdcafe/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'b0b9be56-44d5-4c06-97d8-72cb495eae66',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2283bb32-59d8-4dd1-a796-99daf2eba236/ugc/14c7cc4a-d980-4135-a3bf-e3cba8bdcafe/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'e0623807-d88c-4f4a-9637-2aa40639714c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2283bb32-59d8-4dd1-a796-99daf2eba236/ugc/14c7cc4a-d980-4135-a3bf-e3cba8bdcafe/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '3b0e6c48-b3ec-4d2a-a85c-dfd894f19638',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T15:14:33.492Z',

          createdAt: '2024-05-27T19:15:34.720Z',
          content: [
            {
              caption: '',

              id: '45c77aa1-a955-4949-ac70-6f11e3845f4d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2283bb32-59d8-4dd1-a796-99daf2eba236/ugc/3b0e6c48-b3ec-4d2a-a85c-dfd894f19638/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '2094b92c-7267-46fa-af53-ef4241a5440e',
        firstName: 'Joshua',
        lastName: 'Lively',
        email: 'livelyworshipofficial@gmail.com',
        instagramProfile: {
          handle: 'livelyworship',
          followers: 1510,
          engagementRate: 2.986754966887417
        },
        tiktokProfile: {
          handle: 'livelyworship',
          followers: 137
        },
        youtubeProfile: {
          handle: 'livelyworship',
          followers: 213
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-23T19:42:41.000Z',
        amountCents: 21349
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'ce74e878-f25e-4133-b489-bf6308cd7afb',
      content: [
        {
          groupId: '190d9e22-2efd-4112-b5c8-2c53c950e9f1',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T18:34:10.412Z',

          createdAt: '2024-05-28T20:10:26.364Z',
          content: [
            {
              caption: '',

              id: 'f3b52231-71bf-46d8-a200-29d24b651d64',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ce74e878-f25e-4133-b489-bf6308cd7afb/ugc/190d9e22-2efd-4112-b5c8-2c53c950e9f1/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'd9faa5c9-e4aa-4aa4-a33d-8586dff97c1b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-28T18:08:09.198Z',

          createdAt: '2024-05-27T04:44:30.087Z',
          content: [
            {
              caption: '',

              id: 'e7f2b9f5-9554-4eed-907d-3c4f14dac10f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ce74e878-f25e-4133-b489-bf6308cd7afb/ugc/d9faa5c9-e4aa-4aa4-a33d-8586dff97c1b/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '79bac9f6-b8b2-49db-9294-1e71e4cd405b',
        firstName: 'Buck',
        lastName: 'Jet',
        email: 'jetsbusinessmail@gmail.com',

        tiktokProfile: {
          handle: 'jtpcs',
          followers: 12142,
          engagementRate: 4.617033555940308
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T00:23:16.000Z',
        amountCents: 22828
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '2267ffd9-499d-404b-a8bf-571d6cb1f670',
      content: [
        {
          groupId: 'ad0978f8-411d-473b-b46b-d1a1c21b7303',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-09T11:50:21.854Z',

          createdAt: '2024-06-05T16:27:10.205Z',
          content: [
            {
              caption: '',

              id: 'e1f88263-a974-46cb-a267-22d59a5629e7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2267ffd9-499d-404b-a8bf-571d6cb1f670/ugc/ad0978f8-411d-473b-b46b-d1a1c21b7303/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '6e19e3d0-fb5e-41db-be71-7fb05d9ab394',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2267ffd9-499d-404b-a8bf-571d6cb1f670/ugc/ad0978f8-411d-473b-b46b-d1a1c21b7303/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'a1f13e74-73a9-4b98-9751-20e2770d8dfd',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-09T11:50:14.091Z',

          createdAt: '2024-06-05T20:57:47.939Z',
          content: [
            {
              caption: '',

              id: '6034e82b-7889-448a-b723-34b5d2951fea',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2267ffd9-499d-404b-a8bf-571d6cb1f670/ugc/a1f13e74-73a9-4b98-9751-20e2770d8dfd/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '9382c4fb-06c9-469c-af2f-e59698ab6f21',
        firstName: 'Ciera',
        lastName: 'Pinkerton',
        email: 'cieraalynn95@gmail.com',
        instagramProfile: {
          handle: 'cieralynnphotography',
          followers: 174,
          engagementRate: 1.7241379310344827
        },
        tiktokProfile: {
          handle: 'cierapinkerton',
          followers: 26185,
          engagementRate: 7.677965344765989
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T04:13:31.000Z',
        amountCents: 21349
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '90296733-ebe7-4add-8e78-5a27c43274fe',
      content: [
        {
          groupId: 'bcc9a71d-fb2a-430f-8666-0eaac62997b7',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-30T21:08:25.809Z',

          createdAt: '2024-05-29T14:53:34.233Z',
          content: [
            {
              caption: '',

              id: '8b562807-f282-490c-bd0a-72b13f927789',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/90296733-ebe7-4add-8e78-5a27c43274fe/ugc/bcc9a71d-fb2a-430f-8666-0eaac62997b7/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'bb9ff6ef-279b-427d-82cd-0ca71af6bfc9',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-30T21:08:21.333Z',

          createdAt: '2024-05-29T14:48:26.118Z',
          content: [
            {
              caption: '',

              id: '6cf3b144-a19e-4be5-af1b-f13973ccd3c6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/90296733-ebe7-4add-8e78-5a27c43274fe/ugc/bb9ff6ef-279b-427d-82cd-0ca71af6bfc9/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '9c40ba25-9013-4c2f-ab37-93e498aee380',
        firstName: 'Aurelia',
        lastName: 'Fuller',
        email: 'reliamgmt@gmail.com',
        instagramProfile: {
          handle: 'iamrelia',
          followers: 18023,
          engagementRate: 0.4749486766908949
        },
        tiktokProfile: {
          handle: 'iamrelia',
          followers: 1500,
          engagementRate: 5.535055350553505
        },
        youtubeProfile: {
          handle: 'iamrelia'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T18:33:31.000Z',
        amountCents: 21599
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '2272a354-83c5-4f9f-917b-9525b7095ccd',
      content: [],
      creator: {
        id: '3193b7de-2e8e-4e88-9eeb-3beaa9564855',
        firstName: 'Payton',
        lastName: 'Tomicic',
        email: 'thedoodwinston@gmail.com',
        instagramProfile: {
          handle: 'the_doodwinston',
          followers: 53288,
          engagementRate: 0.625281489265876
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T16:11:55.000Z',
        amountCents: 21489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f509a935-4ad3-4d19-9d35-42b352f63903',
      content: [
        {
          groupId: 'c4df6557-82d4-430e-97aa-37397a6f6ffe',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-07T20:42:11.701Z',

          createdAt: '2024-06-06T20:54:43.302Z',
          content: [
            {
              caption: '',

              id: '6f98c710-6db7-47d3-86ff-ed5fa474481f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f509a935-4ad3-4d19-9d35-42b352f63903/ugc/c4df6557-82d4-430e-97aa-37397a6f6ffe/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '97a4315a-389e-4742-b9a4-d3b0d68ef0c1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f509a935-4ad3-4d19-9d35-42b352f63903/ugc/c4df6557-82d4-430e-97aa-37397a6f6ffe/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '5f1abf5b-7d48-4a98-9bf7-131a51b88196',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f509a935-4ad3-4d19-9d35-42b352f63903/ugc/c4df6557-82d4-430e-97aa-37397a6f6ffe/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '8c6b8ea7-61bc-4db6-80a7-4d1cbb285149',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T20:42:06.219Z',

          createdAt: '2024-06-06T20:51:59.984Z',
          content: [
            {
              caption: '',

              id: '586b2bea-585e-481b-ac95-da28a6f06d9f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f509a935-4ad3-4d19-9d35-42b352f63903/ugc/8c6b8ea7-61bc-4db6-80a7-4d1cbb285149/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '0ab7f485-6f47-4028-9630-ed81e15c395c',
        firstName: 'Ethan',
        lastName: 'Eade',
        email: 'mrchr0nixbusiness@gmail.com',
        instagramProfile: {
          handle: 'twitchmrchr0nix',
          followers: 123,
          engagementRate: 11.788617886178862
        },
        tiktokProfile: {
          handle: 'twitchmrchr0nix',
          followers: 1262,
          engagementRate: 8.118081180811808
        },
        youtubeProfile: {
          handle: 'mrchr0nix'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T22:39:07.000Z',
        amountCents: 21199
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'c4362e86-c384-467a-b890-2e83b20a2110',
      content: [
        {
          groupId: '9b9e76e5-4ffe-4c48-9998-9e2f14f0a01d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T20:47:10.343Z',

          createdAt: '2024-06-03T22:16:39.980Z',
          content: [
            {
              caption: '',

              id: '2aede05f-d3d4-4126-b0bb-7e46544a8f9d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c4362e86-c384-467a-b890-2e83b20a2110/ugc/9b9e76e5-4ffe-4c48-9998-9e2f14f0a01d/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'c91d0a25-44ad-4361-b4bd-79d6fc5bd3c3',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T20:47:01.908Z',

          createdAt: '2024-06-03T22:16:07.284Z',
          content: [
            {
              caption: '',

              id: '8ec96a95-abf6-4021-a5a2-55c7e1653399',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c4362e86-c384-467a-b890-2e83b20a2110/ugc/c91d0a25-44ad-4361-b4bd-79d6fc5bd3c3/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '73cd0637-f504-4dbf-ba68-2d640ae2a3fb',
        firstName: 'Kreena',
        lastName: 'Parikh',
        email: 'kreenaparikh@gmail.com',
        instagramProfile: {
          handle: 'life_of_kreena',
          followers: 6125,
          engagementRate: 2.0408163265306123
        },
        tiktokProfile: {
          handle: 'kreeniebeenie',
          followers: 1139,
          engagementRate: 2.825887626712852
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T19:02:56.000Z',
        amountCents: 24575
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '1fe9775b-fe99-47ce-8dee-c5345b4fca94',
      content: [
        {
          groupId: '0a845de3-59c0-4765-91cd-1cd9e2c16a78',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-04T20:18:09.553Z',

          createdAt: '2024-06-17T12:24:02.564Z',
          content: [
            {
              caption: '',

              id: 'd92e8f69-267c-490d-9601-9cf992cafb6f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1fe9775b-fe99-47ce-8dee-c5345b4fca94/ugc/0a845de3-59c0-4765-91cd-1cd9e2c16a78/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '7961cfd8-c1ed-488f-928f-666662de71de',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1fe9775b-fe99-47ce-8dee-c5345b4fca94/ugc/0a845de3-59c0-4765-91cd-1cd9e2c16a78/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '65e81d37-4639-4288-bd1a-6902c8998e63',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1fe9775b-fe99-47ce-8dee-c5345b4fca94/ugc/0a845de3-59c0-4765-91cd-1cd9e2c16a78/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '9a912496-b5f1-42d2-9468-74b323ad3233',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-18T03:55:03.612Z',

          createdAt: '2024-06-15T01:28:33.249Z',
          content: [
            {
              caption: '',

              id: '27aacdda-09d5-435f-b3fb-dd6d5ad6fb35',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1fe9775b-fe99-47ce-8dee-c5345b4fca94/ugc/9a912496-b5f1-42d2-9468-74b323ad3233/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'b07d5a7b-f97e-40af-a8fa-e91548cd506a',
        firstName: 'Joseph',
        lastName: 'Hammond',
        email: 'fnedior@gmail.com',
        instagramProfile: {
          handle: 'fnedior',
          followers: 33176,
          engagementRate: 0.6785025319508078
        },
        tiktokProfile: {
          handle: '24kdior24k',
          followers: 8661,
          engagementRate: 15.632458233890215
        },
        youtubeProfile: {
          handle: 'fnedior',
          followers: 804
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-04T01:12:58.000Z',
        amountCents: 21682
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '2d9d0373-6631-4449-80a0-036c218e2318',
      content: [],
      creator: {
        id: '28f50294-49d8-46bd-8356-e66e9fd9c180',
        firstName: 'Arthur',
        lastName: 'Amicy',
        email: 'arthuraamicy@gmail.com',
        instagramProfile: {
          handle: 'alexamicy',
          followers: 8070,
          engagementRate: 0.3903345724907063
        },
        tiktokProfile: {
          handle: 'alexamicy',
          followers: 4097,
          engagementRate: 7.326007326007327
        },
        youtubeProfile: {
          handle: 'amicy'
        }
      },

      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'c3bc947f-3714-42a0-bdb2-eec6a3f632ef',
      content: [
        {
          groupId: 'e044cb45-831b-4c5d-82df-84c58e397f5a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-22T01:12:09.699Z',

          createdAt: '2024-05-16T17:46:30.516Z',
          content: [
            {
              caption: '',

              id: '23a991fb-d314-4ef2-bc7f-84176d751fc0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c3bc947f-3714-42a0-bdb2-eec6a3f632ef/ugc/e044cb45-831b-4c5d-82df-84c58e397f5a/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'ed9d36e8-d3e3-43b5-a98a-28f6a8f14eeb',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c3bc947f-3714-42a0-bdb2-eec6a3f632ef/ugc/e044cb45-831b-4c5d-82df-84c58e397f5a/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '4b5c0654-0db7-4150-a07f-3b0aef1b21d7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c3bc947f-3714-42a0-bdb2-eec6a3f632ef/ugc/e044cb45-831b-4c5d-82df-84c58e397f5a/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '186d487a-8dda-42f7-bb28-6b3940e01992',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-22T01:12:14.698Z',

          createdAt: '2024-05-16T19:30:48.590Z',
          content: [
            {
              caption: '',

              id: '7289b0dc-3c5a-448c-8012-4eee7e905925',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c3bc947f-3714-42a0-bdb2-eec6a3f632ef/ugc/186d487a-8dda-42f7-bb28-6b3940e01992/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '0017d14a-b46f-4cd4-846d-e6c4317de2c5',
        firstName: 'Brandon',
        lastName: 'Kimbro',
        email: 'kimbroplays@gmail.com',
        instagramProfile: {
          handle: 'kimbroplays',
          followers: 1130,
          engagementRate: 2.0530973451327434
        },
        tiktokProfile: {
          handle: 'kimbroplays',
          followers: 1491,
          engagementRate: 6.570841889117044
        },
        youtubeProfile: {
          handle: 'kimbroplays'
        }
      },

      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a3786964-dba9-41aa-9586-d8096c01bed0',
      content: [],
      creator: {
        id: '5b7bce41-82a8-455d-acb7-d09abda00c9a',
        firstName: 'Ahliil',
        lastName: 'Saitanan',
        email: 'liilygyal@gmail.com',
        instagramProfile: {
          handle: 'liilygyal',
          followers: 34894,
          engagementRate: 0.14013870579469248
        },
        tiktokProfile: {
          handle: 'liilygyal',
          followers: 34
        }
      },

      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    }
  ]
}
