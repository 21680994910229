import { SvgIcon, SvgIconProps } from '@mui/material'

export default function PriceDecreaseIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 22 20" fill="none">
      <path
        id="Subtract"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.52332 4.56066C3.80463 4.84196 4.18616 5 4.58398 5C4.98181 5 5.36334 4.84196 5.64464 4.56066C5.92595 4.27936 6.08398 3.89782 6.08398 3.5C6.08398 3.10218 5.92595 2.72064 5.64464 2.43934C5.36334 2.15804 4.98181 2 4.58398 2C4.18616 2 3.80463 2.15804 3.52332 2.43934C3.24202 2.72064 3.08398 3.10218 3.08398 3.5C3.08398 3.89782 3.24202 4.27936 3.52332 4.56066ZM11.494 0.58L20.494 9.58C20.854 9.94 21.084 10.44 21.084 11C21.084 11.55 20.864 12.05 20.494 12.41L13.494 19.41C13.134 19.77 12.634 20 12.084 20C11.534 20 11.034 19.77 10.664 19.41L9.06326 17.8075L10.1686 16.7021C11.1737 15.697 11.1737 14.0675 10.1686 13.0624C9.25104 12.1448 7.81291 12.0649 6.8047 12.8228V11.3535C6.8047 9.93212 5.65243 8.77985 4.23104 8.77985C3.09817 8.77985 2.13626 9.51179 1.79242 10.5286L1.67398 10.41C1.30398 10.05 1.08398 9.55 1.08398 9V2C1.08398 0.89 1.97398 0 3.08398 0H10.084C10.634 0 11.134 0.22 11.494 0.58Z"
        fill="#4668EE"
      />
      <path
        id="Vector"
        d="M7.86328 15.166L4.43162 18.5977M4.43162 18.5977L0.999955 15.166M4.43162 18.5977L4.43162 11.4589"
        stroke="#4668EE"
        strokeWidth="1.71583"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}
