import { BenchmarkType } from '@productwindtom/shared-momentum-zeus-types'
import { EtailerSkuMetrics } from './queries'

export const BENCHMARK_PRODUCT_COLORS = ['info.main', 'error.light', 'warning.main', 'success.main']

export const BENCHMARK_TYPE_TO_GOAL_STRING = {
  [BenchmarkType.TRAFFIC]: 'Glance views',
  [BenchmarkType.UNITS_SOLD]: 'Units sold',
  [BenchmarkType.REVENUE]: 'In revenue'
}

export const METRIC_TYPE_TO_INPUT_STRING = {
  [BenchmarkType.TRAFFIC]: 'Traffic',
  [BenchmarkType.UNITS_SOLD]: 'Units sold',
  [BenchmarkType.REVENUE]: 'Revenue'
}

export const BENCHMARK_TYPE_TO_VALUE = {
  [BenchmarkType.TRAFFIC]: 'pageViews',
  [BenchmarkType.UNITS_SOLD]: 'unitsSold',
  [BenchmarkType.REVENUE]: 'totalSalesAmount'
} as {
  [BenchmarkType.TRAFFIC]: keyof EtailerSkuMetrics,
  [BenchmarkType.UNITS_SOLD]: keyof EtailerSkuMetrics,
  [BenchmarkType.REVENUE]: keyof EtailerSkuMetrics
}
