import Row from '@momentum/components/row'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { Alert, Button, Typography } from '@mui/material'
import { useFlag } from '@unleash/proxy-client-react'
import { useFormContext } from 'react-hook-form'
import { useCreateProposalContext } from '../../context/CreateProposalContext'

const PlaceholderProductAlert = ({ isAddProductEnabled }: { isAddProductEnabled?: boolean }) => {
  const { setIsAddProductActiveOrCallback, products } = useBrandContext()
  const { selectedProduct, setSelectedProduct, proposal } = useCreateProposalContext()
  const { setValue } = useFormContext()
  const brandPlaceholderScheduleCampaign = useFlag('BrandPlaceholderScheduleCampaign')

  if (!selectedProduct || selectedProduct?.skuId) return null

  const onAddProduct = () => {
    setIsAddProductActiveOrCallback({
      selectedProductId: selectedProduct?.id,
      productPriceOverride: proposal?.productPriceOverride,
      callback: async params => {
        setValue('productId', params.product.id)
        setSelectedProduct(products?.find(p => p.id === params.product.id))
      }
    })
  }

  return (
    <Alert variant="outlined" severity="info" data-cy="selfService-placeholderItem">
      <Row justifyContent={'space-between'} spacing={4}>
        <Typography variant="label3">
          {isAddProductEnabled ? (
            'This campaign has a placeholder product. Schedule this campaign then add the live product URL before the campaign start date.'
          ) : brandPlaceholderScheduleCampaign ? (
            <>
              This campaign has a placeholder product. You can schedule this campaign now, then will be required to add
              the live product URL before the campaign start date.
              <br />
              <br />
              If the cost of the product increases, you will be asked to use additional credits for the campaign.
            </>
          ) : (
            'This campaign has a placeholder product. Remove the product and add the live product to schedule this campaign.'
          )}
        </Typography>
        {isAddProductEnabled && (
          <Button
            variant="outlined"
            sx={{
              flex: '0 0 auto'
            }}
            onClick={onAddProduct}
          >
            Add live product URL
          </Button>
        )}
      </Row>
    </Alert>
  )
}

export default PlaceholderProductAlert
