import { Grid, Box, Stack, Typography, Divider } from '@mui/material'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { DateTime } from 'luxon'
import Loading from '@momentum/components/loading'
import { getStringForStep, TimelineStepType } from '@productwindtom/shared-momentum'
import { useCampaignTimelineSteps } from './useCampaignTimelineSteps'
import EventHeartIcon from '@momentum/components/icons/event-heart'

export const Timeline = () => {
  const { campaignDetails, liftCampaignDetails } = useCampaignContext()

  const timelineSteps = useCampaignTimelineSteps()

  const subtractIndex = timelineSteps
    .slice()
    .reverse()
    .findIndex(s => s.date && s.date <= DateTime.now())

  const activeIndex = subtractIndex === -1 ? 0 : timelineSteps.length - subtractIndex

  if (campaignDetails.liftCampaignId && !liftCampaignDetails) {
    return <Loading />
  }

  return (
    <Grid container alignItems={'center'} columns={timelineSteps.length} height={'110px'}>
      {timelineSteps.map((s, index) => (
        <Grid key={index} item height={'100%'} xs={1} justifyItems={'center'} position={'relative'}>
          <Box sx={{ height: 'calc(100% - 50% - 12px)' }} alignContent={'end'}>
            <Stack height={'100%'} justifyContent={'flex-end'}>
              <Typography
                textAlign={'center'}
                variant={'caption1'}
                color={theme => (index < activeIndex ? 'black' : theme.palette.grey.A400)}
              >
                {index % 2 === 0 ? getStringForStep(s) : ''}
              </Typography>
              <Typography
                textAlign={'center'}
                variant={'caption2'}
                minHeight={'1em'}
                pt={0.5}
                color={theme => (index < activeIndex ? 'black' : theme.palette.grey.A400)}
              >
                {index % 2 === 0 ? s.date?.toLocaleString(DateTime.DATE_MED) : ''}
              </Typography>
            </Stack>
          </Box>
          <Box
            sx={{
              my: 1,
              display: 'flex',
              marginX: 'auto',
              height: 24,
              width: 24,
              borderRadius: '50%',
              backgroundColor: theme => (index < activeIndex ? theme.palette.primary.main : theme.palette.grey.A400)
            }}
            alignItems={'center'}
            justifyContent={'center'}
          >
            {s.type === TimelineStepType.EVENT ? (
              <EventHeartIcon
                fontSize={'mSmall'}
                sx={{ color: theme => (index < activeIndex ? theme.palette.primary.main : 'white') }}
              />
            ) : (
              <Typography variant={'caption'} color={'white'}>
                {(s.displayIndex || 0) + 1}
              </Typography>
            )}
          </Box>
          <Box sx={{ height: 'calc(100% - 50% - 12px)' }} alignContent={'top'}>
            <Stack>
              <Typography
                textAlign={'center'}
                variant={'caption1'}
                color={theme => (index < activeIndex ? 'black' : theme.palette.grey.A400)}
              >
                {index % 2 === 1 ? getStringForStep(s) : ''}
              </Typography>

              <Typography
                textAlign={'center'}
                pt={0.5}
                variant={'caption2'}
                color={theme => (index < activeIndex ? 'black' : theme.palette.grey.A400)}
              >
                {index % 2 === 1 ? s.date?.toLocaleString(DateTime.DATE_MED) : ' '}
              </Typography>
            </Stack>
          </Box>
          {index < timelineSteps.length - 1 && (
            <Divider
              sx={{
                my: 1,
                position: 'absolute',
                top: '50%',
                left: '70%',
                width: '60%',
                borderColor: theme => (index < activeIndex ? theme.palette.primary.main : 'action')
              }}
            />
          )}
        </Grid>
      ))}
    </Grid>
  )
}
