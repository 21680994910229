import { SvgIcon, SvgIconProps } from '@mui/material'

export default function MothersDayIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 25" fill="none">
      <path
        d="M16.0769 5.25865C16.0657 5.19115 15.6063 1.25928 11.5151 1.25928C11.2076 1.25928 10.8794 1.28178 10.5307 1.32865C6.10756 1.9324 6.32694 6.01053 6.31944 6.7924C6.31194 7.57428 6.72444 13.9774 7.45006 15.3837C7.73506 15.933 8.48506 16.0755 9.18631 16.0755C9.94006 16.0755 10.6376 15.9105 10.6376 15.9105L12.7019 15.5824L14.3126 15.3312C14.3126 15.3312 15.1882 15.1793 15.5576 14.4462C16.4932 12.6012 16.5476 8.24553 16.0769 5.25865Z"
        fill="#543930"
      />
      <path
        d="M16.0769 5.25865C16.0657 5.19115 15.6063 1.25928 11.5151 1.25928C11.2076 1.25928 10.8794 1.28178 10.5307 1.32865C6.10756 1.9324 6.32694 6.01053 6.31944 6.7924C6.31194 7.57428 6.72444 13.9774 7.45006 15.3837C7.73506 15.933 8.48506 16.0755 9.18631 16.0755C9.94006 16.0755 10.6376 15.9105 10.6376 15.9105L12.7019 15.5824L14.3126 15.3312C14.3126 15.3312 15.1882 15.1793 15.5576 14.4462C16.4932 12.6012 16.5476 8.24553 16.0769 5.25865Z"
        fill="url(#paint0_radial_1554_118110)"
      />
      <path
        d="M18.9856 23.7425C18.1737 23.7425 11.3993 23.75 10.4937 23.75C9.58808 23.75 8.97308 23.6768 8.68995 23.6243C7.36245 23.3825 5.92245 23.15 4.8237 22.3906C3.9837 21.8112 3.11183 20.735 3.98183 18.8318C5.19495 16.0343 6.25808 13.2743 8.74433 12.9218C9.57683 12.8037 10.8012 12.8168 12.1756 12.8168C13.55 12.8168 14.4556 13.0437 15.0293 13.1693C19.1262 14.0675 19.2987 17.2906 19.6737 20.7968C19.6737 20.7968 19.9943 23.7425 18.9856 23.7425Z"
        fill="url(#paint1_linear_1554_118110)"
      />
      <path
        d="M12.1383 11.658H10.7939V13.1355C10.7939 13.803 11.3414 14.3448 12.0164 14.3448H12.2602C12.9352 14.3448 13.4827 13.803 13.4827 13.1355V11.658H12.1383Z"
        fill="#E59600"
      />
      <path
        d="M15.3935 6.83006C14.7991 3.89381 12.9372 2.66382 11.0604 2.74819C10.8466 2.75757 10.6329 2.78382 10.4191 2.82694C8.3435 3.24882 6.73475 5.18194 7.40037 8.45569C7.93475 11.0844 9.87912 12.3088 11.396 12.5226C11.6097 12.5526 11.8122 12.5619 12.0035 12.5544C12.1385 12.5488 12.266 12.5338 12.3841 12.5094C12.6729 12.4513 12.9841 12.3257 13.2954 12.1382C14.6116 11.3507 15.9279 9.46256 15.3935 6.83006Z"
        fill="#FFCA28"
      />
      <path
        d="M10.182 7.07008C10.0451 6.93883 9.75075 6.76258 9.2445 6.84133C8.73825 6.92008 8.51137 7.17883 8.42325 7.3457C8.38387 7.41883 8.40825 7.49383 8.4495 7.53508C8.48887 7.57258 8.58825 7.59695 8.68762 7.54633C8.787 7.4957 8.9745 7.35695 9.31575 7.3007C9.65887 7.25008 9.88012 7.3232 9.98887 7.34195C10.0976 7.3607 10.1876 7.30633 10.212 7.25945C10.242 7.20695 10.242 7.1282 10.182 7.07008ZM14.1345 6.44945C13.9976 6.3182 13.7032 6.14195 13.197 6.2207C12.6907 6.29945 12.4639 6.5582 12.3758 6.72508C12.3364 6.7982 12.3607 6.8732 12.402 6.91445C12.4414 6.95195 12.5426 6.97633 12.6401 6.9257C12.7376 6.87508 12.927 6.73633 13.2682 6.68008C13.6114 6.62945 13.8326 6.70258 13.9414 6.72133C14.052 6.74008 14.1401 6.6857 14.1645 6.63883C14.1945 6.58445 14.1945 6.50758 14.1345 6.44945Z"
        fill="#6D4C41"
      />
      <path
        d="M12.7949 9.94051C12.448 10.2274 11.1937 10.4261 10.7737 10.2574C10.5337 10.1618 10.3405 10.3961 10.4624 10.5818C10.5824 10.7636 11.3399 11.1124 11.9305 11.0186C12.523 10.9249 13.1268 10.3643 13.1849 10.1524C13.2487 9.94051 12.9955 9.77551 12.7949 9.94051Z"
        fill="#795548"
      />
      <path
        d="M12.0544 8.80063C12.0413 8.79688 12.0281 8.79688 12.015 8.79688L11.1656 8.93C11.1525 8.93375 11.1413 8.93938 11.1281 8.945C11.0569 8.98813 11.0269 9.07437 11.0756 9.155C11.1244 9.23562 11.3325 9.44563 11.6738 9.39313C12.015 9.33875 12.1463 9.07438 12.1688 8.9825C12.1913 8.8925 12.1369 8.81938 12.0544 8.80063Z"
        fill="#E59600"
      />
      <path
        d="M9.48954 8.48755C9.24017 8.52693 9.00579 8.50068 8.83517 8.42943C8.76204 8.39755 8.72642 8.42943 8.75079 8.50443C8.84454 8.7988 9.18767 8.98443 9.55892 8.9263C9.93017 8.86818 10.2002 8.58693 10.1983 8.27755C10.1983 8.1988 10.1552 8.17818 10.0952 8.23068C9.95454 8.35068 9.74079 8.4463 9.48954 8.48755ZM13.457 7.86318C13.2077 7.90255 12.9733 7.8763 12.8027 7.80505C12.7295 7.77318 12.6939 7.80505 12.7183 7.88005C12.812 8.17443 13.1552 8.36005 13.5264 8.30193C13.8977 8.2438 14.1677 7.96255 14.1658 7.65318C14.1658 7.57443 14.1227 7.55568 14.0627 7.60631C13.922 7.72631 13.7083 7.8238 13.457 7.86318Z"
        fill="#404040"
      />
      <path
        d="M19.0744 20.9976C17.925 18.9408 17.6006 18.6426 17.4394 18.2826C17.1712 17.6808 16.9031 17.4426 16.7025 17.3564C16.68 17.1276 16.6237 16.852 16.5469 16.5164C16.665 16.4358 16.7569 16.3233 16.8112 16.1883C16.8806 16.012 16.8769 15.8189 16.8019 15.6445L16.2169 14.3039C16.1419 14.1295 10.1194 16.7039 10.1194 16.7039C9.92248 17.032 9.84748 17.3208 10.095 17.8608C10.2394 18.1758 10.5694 18.4308 10.6387 18.4008L11.2031 18.187C11.2819 18.3033 11.3944 18.3933 11.5294 18.4458C11.5819 18.4664 11.7937 18.5226 12.0731 18.4364L13.5412 17.812C13.7437 18.1101 14.0475 18.1439 14.2462 18.127C14.2462 18.127 14.4037 18.6014 14.8237 18.8114C14.6269 19.1208 14.9569 19.6964 15.1425 20.0639C15.4294 20.6301 14.7919 22.7414 15.0169 23.7483L18.99 23.767C19.3012 23.1351 19.6256 21.982 19.0744 20.9976Z"
        fill="url(#paint2_linear_1554_118110)"
      />
      <path
        d="M17.4073 15.0425L16.8223 13.7018C16.7473 13.5275 16.6066 13.3943 16.4304 13.325C16.2541 13.2556 16.061 13.2593 15.8866 13.3343L11.5235 15.1906C11.3513 15.2661 11.2159 15.4066 11.1471 15.5816C11.0782 15.7566 11.0814 15.9517 11.156 16.1243L11.741 17.465C11.816 17.6393 11.9566 17.7725 12.1329 17.8418C12.1854 17.8625 12.2379 17.8756 12.2923 17.885C12.4216 17.9037 12.5529 17.885 12.6766 17.8325L17.0398 15.9762C17.2141 15.9012 17.3473 15.7606 17.4166 15.5843C17.4879 15.4081 17.4841 15.2168 17.4073 15.0425Z"
        fill="url(#paint3_linear_1554_118110)"
      />
      <path
        d="M11.3854 15.2732C11.1417 15.3876 11.0235 15.7832 11.1398 16.0476L11.8092 17.5851C11.9254 17.8494 12.4092 17.9657 12.6735 17.8494L17.0404 15.9763L11.3854 15.2732Z"
        fill="#FFFDE7"
      />
      <path
        d="M10.8224 16.4655C10.9124 16.4411 11.0306 16.4523 11.0849 16.5255C11.1468 16.6098 11.1899 16.743 11.2349 16.8405C11.3118 17.0092 11.3624 17.1855 11.3587 17.3711C11.3587 17.3917 11.3568 17.4161 11.3399 17.4311C11.3287 17.4423 11.3118 17.4461 11.2949 17.4498C11.1937 17.4705 11.0887 17.478 10.9856 17.4742C10.7793 17.4667 10.6706 17.2848 10.6068 17.1067C10.5674 16.9942 10.5487 16.8742 10.5712 16.758C10.5956 16.6417 10.6668 16.533 10.7718 16.4805C10.7887 16.4767 10.8037 16.4711 10.8224 16.4655Z"
        fill="#C53929"
      />
      <path
        d="M11.8092 17.585L11.6029 17.675C11.4642 17.735 11.2917 17.7012 11.1904 17.5887C11.0929 17.48 11.1004 17.3318 11.0798 17.1912C11.0536 17.0075 10.9879 16.8406 10.8661 16.7243C10.8304 16.6906 10.7873 16.6625 10.7536 16.6268C10.6017 16.4637 10.7311 16.1693 10.9111 16.0906L11.1173 16.0006C11.3142 15.9143 11.6742 16.0812 11.8879 16.6006C12.1017 17.1162 12.0061 17.4987 11.8092 17.585Z"
        fill="#E06055"
      />
      <path
        d="M10.3155 17.9768C10.2011 18.9443 9.40052 19.7693 8.47614 20.0187C7.49739 20.285 6.26552 19.7206 5.6599 18.8731C4.52927 17.2943 5.73677 15.98 5.99552 15.6856C6.64052 14.9543 7.59302 14.9675 8.34677 15.0143C9.89177 15.11 10.473 16.6587 10.3155 17.9768Z"
        fill="#C5E1A5"
      />
      <path
        d="M8.09402 14.7106C7.70402 14.7144 7.10777 14.75 6.6859 14.9975C6.49652 15.11 6.43652 15.305 6.43652 15.305C6.94277 15.1119 7.45465 15.0762 8.0884 15.3087C9.27902 15.7456 9.47965 16.7544 9.3784 18.0219C9.30527 18.9387 8.69777 19.6812 7.95902 19.9175C7.61402 20.0281 7.64402 20.195 8.09402 20.2269C9.7159 20.3394 10.8521 18.9912 10.8521 17.4687C10.8521 15.9462 9.8359 14.6937 8.09402 14.7106Z"
        fill="#FEF6E0"
      />
      <path
        d="M10.9716 18.547C13.2929 18.7702 13.9641 20.0695 13.9641 20.0695C16.291 20.5852 16.9004 22.132 16.7147 22.8333C16.7147 22.8333 15.6497 23.4595 14.986 23.4727C14.0279 23.4933 13.066 23.5027 12.1116 23.4595C12.1116 23.4595 12.2579 22.3983 11.9091 22.0908C11.6972 21.9052 11.3316 22.0927 10.9791 21.8845C10.3885 21.5358 9.4866 21.0014 8.7291 20.5908C7.97535 20.182 7.31348 19.9964 7.31348 19.9964C7.9866 19.2127 9.19223 18.3764 10.9716 18.547Z"
        fill="#BDBDBD"
      />
      <path
        d="M5.37422 16.625C5.37422 16.625 5.55047 17.8756 6.72985 18.1138C8.3461 18.44 9.41485 17.6656 9.41485 17.6656C9.41485 17.6656 9.52735 17.8625 9.8311 17.7894C10.0748 17.7312 10.583 17.6 10.6936 17.4144C10.8417 17.1687 10.8155 16.955 10.8155 16.955C10.808 17.0413 10.838 17.1444 10.8417 17.2325C10.853 17.4669 10.8436 17.705 10.808 17.9375C10.7723 18.1738 10.6523 18.5225 10.6523 18.5225C10.313 18.6163 9.98485 19.0306 10.1873 19.5931C10.6373 20.8325 11.8486 21.5619 13.0673 21.4888C13.5923 21.4569 14.1061 21.3369 14.6198 21.23C14.7098 21.2112 14.8336 21.1719 14.8861 21.245C15.0961 21.5375 14.6067 22.0269 14.4698 22.175C15.4898 22.7169 16.613 22.1994 16.6617 22.2069C16.7105 22.2144 16.0917 22.8969 14.9517 22.8969C14.4117 22.8969 13.8998 22.6888 13.3823 22.5538C12.8761 22.4206 12.2611 22.5706 11.9555 22.1431C11.7698 21.8844 11.3255 22.085 11.003 21.9012C10.3692 21.5375 8.8786 20.6169 8.1661 20.3187C7.5586 20.0656 7.42922 20.03 7.19297 19.9644C6.4261 19.7506 5.72485 20.2869 5.13985 19.4975C4.81172 19.055 5.3386 18.3031 5.3386 18.3031C4.98235 17.4462 5.37422 16.625 5.37422 16.625Z"
        fill="url(#paint4_linear_1554_118110)"
      />
      <path
        d="M15.6321 18.0537C15.0846 18.2656 15.5233 19.0175 15.7483 19.4619C16.0783 20.1106 16.3183 21.9762 16.7552 22.7206C17.3514 23.7369 18.9302 24.0237 19.6146 23.5344C20.1808 23.1294 20.3739 21.6294 19.6839 20.3937C18.5346 18.3369 18.2102 18.0387 18.0489 17.6787C17.5164 16.4825 16.9821 16.7262 16.9821 16.7262L15.6321 18.0537Z"
        fill="url(#paint5_linear_1554_118110)"
      />
      <path
        d="M16.8949 22.3831C16.8949 22.2631 16.8518 22.1318 16.5593 22.2481C15.8186 22.5425 15.2336 22.7543 14.3993 22.4243C14.4874 22.5012 15.5149 21.635 14.9149 21.2037C14.658 21.1118 14.1143 21.7081 13.5761 21.7475C13.0361 21.7268 12.378 21.7156 11.9111 22.1937L11.7236 23.7481C12.2036 23.7481 14.4855 23.8175 15.3799 23.6937C15.9068 23.5568 16.7786 23.135 16.8949 22.3831Z"
        fill="url(#paint6_linear_1554_118110)"
      />
      <path
        d="M14.811 13.6193C14.6441 13.595 14.3347 13.6043 14.2747 13.76C14.1547 14.0656 14.4847 14.2381 14.706 14.2943C15.051 14.3825 15.4278 14.4575 15.4278 14.4575L15.7503 15.0068C15.7503 15.0068 15.0435 14.8025 14.7191 14.795C14.3066 14.7856 13.7985 15.0031 13.491 15.1306C13.1103 15.2862 13.386 15.7175 13.791 15.71C14.1341 15.7025 14.5297 15.5506 14.5297 15.5506L15.0078 15.7381C14.8053 15.7512 14.256 15.7906 14.1416 16.1187C14.016 16.4806 14.2503 16.7825 14.2503 16.7825C14.2503 16.7825 14.1041 16.9756 14.2503 17.2868C14.3685 17.5362 14.7228 17.7368 15.0622 17.7425C15.2366 18.2825 15.6978 18.3818 15.9603 18.3781C16.5172 18.3725 16.9747 18.0293 17.1735 17.6281C17.4097 17.1481 17.4678 16.5087 17.0422 15.5468C16.7928 14.9843 16.3616 14.1012 15.9866 13.9231C15.5235 13.7037 15.4672 13.7187 14.811 13.6193Z"
        fill="url(#paint7_linear_1554_118110)"
      />
      <path
        d="M14.79 17.6973C14.8781 17.7236 14.97 17.7405 15.0619 17.7423C15.1331 17.9655 15.255 18.1098 15.3919 18.2073C15.3394 18.1492 15.2456 17.9786 15.2363 17.9411C15.1838 17.703 15.2306 17.6017 15.3075 17.4573C15.3844 17.313 15.3806 17.2098 15.3225 17.1648C15.2531 17.1105 14.94 17.2136 14.8669 17.2192C14.8669 17.2192 15.0731 17.418 14.985 17.568C14.94 17.6392 14.8612 17.6786 14.79 17.6973ZM14.25 16.7823C14.25 16.7823 14.58 16.4167 15.3937 16.4692C15.3937 16.4692 15.1462 16.3005 14.79 16.3473C14.3719 16.4036 14.25 16.7823 14.25 16.7823ZM15.0075 15.7361L15.4988 15.7305C15.4988 15.7305 15.1781 15.618 14.9944 15.5842C14.8106 15.5523 14.5294 15.5486 14.5294 15.5486L15.0075 15.7361Z"
        fill="#EDA600"
      />
      <path
        d="M15.7536 5.78202C15.6486 4.83327 14.9268 1.11889 10.4811 1.72827C6.35614 2.29452 6.69364 6.43077 6.74614 7.35702C6.78552 8.05077 6.87927 9.41764 6.97489 10.1808C7.07427 10.9833 7.90864 11.1558 7.90864 11.1558C7.90864 11.1558 7.90489 9.98202 7.85052 8.85327C7.80176 7.85202 7.81114 7.02515 8.26489 6.85639C10.9105 5.87577 12.2005 4.13202 12.2005 4.13202C12.7386 4.94389 14.1243 5.59827 14.6249 5.85139C15.0355 6.05952 15.1574 7.16389 15.223 7.63077L15.0936 10.9664C15.0936 10.9664 15.9093 10.4695 15.9655 9.64639C16.0199 8.89077 15.8586 6.73077 15.7536 5.78202Z"
        fill="#543930"
      />
      <path
        d="M15.7536 5.78202C15.6486 4.83327 14.9268 1.11889 10.4811 1.72827C6.35614 2.29452 6.69364 6.43077 6.74614 7.35702C6.78552 8.05077 6.87927 9.41764 6.97489 10.1808C7.07427 10.9833 7.90864 11.1558 7.90864 11.1558C7.90864 11.1558 7.90489 9.98202 7.85052 8.85327C7.80176 7.85202 7.81114 7.02515 8.26489 6.85639C10.9105 5.87577 12.2005 4.13202 12.2005 4.13202C12.7386 4.94389 14.1243 5.59827 14.6249 5.85139C15.0355 6.05952 15.1574 7.16389 15.223 7.63077L15.0936 10.9664C15.0936 10.9664 15.9093 10.4695 15.9655 9.64639C16.0199 8.89077 15.8586 6.73077 15.7536 5.78202Z"
        fill="url(#paint8_radial_1554_118110)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_1554_118110"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(11.4535 8.45733) rotate(-93.5431) scale(7.8989 6.42298)"
        >
          <stop offset="0.758" stopColor="#6D4C41" stopOpacity="0" />
          <stop offset="1" stopColor="#6D4C41" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_1554_118110"
          x1="11.6712"
          y1="15.07"
          x2="11.6712"
          y2="22.8667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#651FFF" />
          <stop offset="1" stopColor="#530EEB" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1554_118110"
          x1="14.6546"
          y1="27.7932"
          x2="14.6546"
          y2="14.3254"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#212121" stopOpacity="0.8" />
          <stop offset="1" stopColor="#212121" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1554_118110"
          x1="15.5514"
          y1="14.9787"
          x2="10.6933"
          y2="17.2983"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#81D4FA" />
          <stop offset="1" stopColor="#0288D1" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1554_118110"
          x1="10.852"
          y1="23.4012"
          x2="10.852"
          y2="15.5523"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1" stopColor="#212121" stopOpacity="0.4" />
          <stop offset="1" stopColor="#252525" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1554_118110"
          x1="17.4458"
          y1="20.2081"
          x2="20.1504"
          y2="23.9598"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#651FFF" />
          <stop offset="0.817" stopColor="#530EEB" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1554_118110"
          x1="11.7221"
          y1="22.4823"
          x2="16.8939"
          y2="22.4823"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB300" />
          <stop offset="0.5" stopColor="#FFCA28" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1554_118110"
          x1="16.8517"
          y1="18.3436"
          x2="14.8347"
          y2="14.9916"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB300" />
          <stop offset="0.5" stopColor="#FFCA28" />
        </linearGradient>
        <radialGradient
          id="paint8_radial_1554_118110"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(11.3819 8.22419) rotate(-94.9335) scale(8.08748 6.69774)"
        >
          <stop offset="0.701" stopColor="#6D4C41" stopOpacity="0" />
          <stop offset="0.991" stopColor="#6D4C41" />
        </radialGradient>
      </defs>
    </SvgIcon>
  )
}
