import { BuyCampaignCredits } from '@momentum/components/buy-campaign-credits'
import BonusAlertIcon from '@momentum/components/icons/bonus-alert'
import BonusHighlightedIcon from '@momentum/components/icons/bonus-highlighted'
import { Question } from '@momentum/components/proposal-common/Question'
import { Stack, Typography } from '@mui/material'

export const CreditPayment = ({
  creditsRemaining,
  totalCostCredits,
  associatedProposalId
}: {
  creditsRemaining: number
  totalCostCredits: number
  associatedProposalId?: string
}) => {
  return (
    <Stack spacing={4}>
      <Question primaryText={'Schedule campaign:'}>
        <Stack direction={'row'} alignItems={'center'} gap={2}>
          <Stack direction={'row'} spacing={1} width={'50%'}>
            <BonusAlertIcon fontSize={'large'} />
            <Stack>
              <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                <Typography variant={'h3'} data-cy={'creditsRequired'}>
                  {totalCostCredits.toLocaleString()}
                </Typography>
                <Typography variant={'label3'}>credits</Typography>
              </Stack>
              <Typography variant={'label3'}>required for this campaign</Typography>
            </Stack>
          </Stack>
          <Stack direction={'row'} spacing={1} width={'50%'}>
            <BonusHighlightedIcon fontSize={'large'} />
            <Stack>
              <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                <Typography variant={'h3'} data-cy={'creditsRemaining'}>
                  {creditsRemaining.toLocaleString()}
                </Typography>
                <Typography variant={'label3'}>credits</Typography>
              </Stack>
              <Typography variant={'label3'}>currently available</Typography>
            </Stack>
          </Stack>
        </Stack>
      </Question>
      <BuyCampaignCredits
        creditsRemaining={creditsRemaining}
        totalCostCredits={totalCostCredits}
        associatedProposalId={associatedProposalId}
      />
    </Stack>
  )
}
