import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'
import { SAMPLE_CAMPAIGN_WIRELESS_EARBUDS } from '../../../getCampaign/sample-data/wireless-earbuds'

export default [
  {
    unitsSold: 14,
    totalSalesAmount: 139986,
    pageViews: 559,
    date: '2025-01-21',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 25,
    totalSalesAmount: 249975,
    pageViews: 1553,
    date: '2025-01-22',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 37,
    totalSalesAmount: 369963,
    pageViews: 1989,
    date: '2025-01-23',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 45,
    totalSalesAmount: 449955,
    pageViews: 1999,
    date: '2025-01-24',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 41,
    totalSalesAmount: 409959,
    pageViews: 2187,
    date: '2025-01-25',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 26,
    totalSalesAmount: 260054,
    pageViews: 1978,
    date: '2025-01-26',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 30,
    totalSalesAmount: 299970,
    pageViews: 1946,
    date: '2025-01-27',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 23,
    totalSalesAmount: 230500,
    pageViews: 1956,
    date: '2025-01-28',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 38,
    totalSalesAmount: 380491,
    pageViews: 1705,
    date: '2025-01-29',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 31,
    totalSalesAmount: 309786,
    pageViews: 1815,
    date: '2025-01-30',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 40,
    totalSalesAmount: 399960,
    pageViews: 2135,
    date: '2025-01-31',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 42,
    totalSalesAmount: 419942,
    pageViews: 2554,
    date: '2025-02-01',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 55,
    totalSalesAmount: 549937,
    pageViews: 2839,
    date: '2025-02-02',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 49,
    totalSalesAmount: 489951,
    pageViews: 2622,
    date: '2025-02-03',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 41,
    totalSalesAmount: 410446,
    pageViews: 2591,
    date: '2025-02-04',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 45,
    totalSalesAmount: 449615,
    pageViews: 2445,
    date: '2025-02-05',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 40,
    totalSalesAmount: 400074,
    pageViews: 2502,
    date: '2025-02-06',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 37,
    totalSalesAmount: 370027,
    pageViews: 2579,
    date: '2025-02-07',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 32,
    totalSalesAmount: 319968,
    pageViews: 2175,
    date: '2025-02-08',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 39,
    totalSalesAmount: 389974,
    pageViews: 3198,
    date: '2025-02-09',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 21,
    totalSalesAmount: 210118,
    pageViews: 3009,
    date: '2025-02-10',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 24,
    totalSalesAmount: 239963,
    pageViews: 3065,
    date: '2025-02-11',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 22,
    totalSalesAmount: 220658,
    pageViews: 2773,
    date: '2025-02-12',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 39,
    totalSalesAmount: 389961,
    pageViews: 5549,
    date: '2025-02-13',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 35,
    totalSalesAmount: 350319,
    pageViews: 6160,
    date: '2025-02-14',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 46,
    totalSalesAmount: 460297,
    pageViews: 4123,
    date: '2025-02-15',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 40,
    totalSalesAmount: 400041,
    pageViews: 4038,
    date: '2025-02-16',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 58,
    totalSalesAmount: 580721,
    pageViews: 4488,
    date: '2025-02-17',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 57,
    totalSalesAmount: 569943,
    pageViews: 4622,
    date: '2025-02-18',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 52,
    totalSalesAmount: 520155,
    pageViews: 5612,
    date: '2025-02-19',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 89,
    totalSalesAmount: 891813,
    pageViews: 6655,
    date: '2025-02-20',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 77,
    totalSalesAmount: 480679,
    pageViews: 3943,
    date: '2025-02-21',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 47,
    totalSalesAmount: 290379,
    pageViews: 3176,
    date: '2025-02-22',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 61,
    totalSalesAmount: 350553,
    pageViews: 3710,
    date: '2025-02-23',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 63,
    totalSalesAmount: 390023,
    pageViews: 4094,
    date: '2025-02-24',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 60,
    totalSalesAmount: 600063,
    pageViews: 5171,
    date: '2025-02-25',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 50,
    totalSalesAmount: 500683,
    pageViews: 4566,
    date: '2025-02-26',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 54,
    totalSalesAmount: 539946,
    pageViews: 4786,
    date: '2025-02-27',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 48,
    totalSalesAmount: 480729,
    pageViews: 4319,
    date: '2025-02-28',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 62,
    totalSalesAmount: 620950,
    pageViews: 3540,
    date: '2025-03-01',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 43,
    totalSalesAmount: 432252,
    pageViews: 3656,
    date: '2025-03-02',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 63,
    totalSalesAmount: 631868,
    pageViews: 3364,
    date: '2025-03-03',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 193,
    totalSalesAmount: 1929797,
    pageViews: 4317,
    date: '2025-03-04',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 242,
    totalSalesAmount: 2421259,
    pageViews: 4711,
    date: '2025-03-05',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 51,
    totalSalesAmount: 510245,
    pageViews: 3923,
    date: '2025-03-06',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 64,
    totalSalesAmount: 639936,
    pageViews: 5011,
    date: '2025-03-07',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 96,
    totalSalesAmount: 961566,
    pageViews: 5076,
    date: '2025-03-08',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 92,
    totalSalesAmount: 920765,
    pageViews: 4603,
    date: '2025-03-09',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 92,
    totalSalesAmount: 921935,
    pageViews: 4764,
    date: '2025-03-10',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 61,
    totalSalesAmount: 610849,
    pageViews: 4136,
    date: '2025-03-11',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 50,
    totalSalesAmount: 500400,
    pageViews: 4885,
    date: '2025-03-12',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 75,
    totalSalesAmount: 749408,
    pageViews: 4778,
    date: '2025-03-13',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 75,
    totalSalesAmount: 753387,
    pageViews: 4569,
    date: '2025-03-14',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 109,
    totalSalesAmount: 1092989,
    pageViews: 4901,
    date: '2025-03-15',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 130,
    totalSalesAmount: 1302441,
    pageViews: 5131,
    date: '2025-03-16',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 98,
    totalSalesAmount: 981697,
    pageViews: 4857,
    date: '2025-03-17',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 94,
    totalSalesAmount: 941757,
    pageViews: 5043,
    date: '2025-03-18',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 101,
    totalSalesAmount: 1011903,
    pageViews: 5307,
    date: '2025-03-19',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 84,
    totalSalesAmount: 837935,
    pageViews: 4553,
    date: '2025-03-20',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 107,
    totalSalesAmount: 1068447,
    pageViews: 4575,
    date: '2025-03-21',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 82,
    totalSalesAmount: 822048,
    pageViews: 3767,
    date: '2025-03-22',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 37,
    totalSalesAmount: 369264,
    pageViews: 2414,
    date: '2025-03-23',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 34,
    totalSalesAmount: 338623,
    pageViews: 1678,
    date: '2025-03-24',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 43,
    totalSalesAmount: 426833,
    pageViews: 1883,
    date: '2025-03-25',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 32,
    totalSalesAmount: 318089,
    pageViews: 1304,
    date: '2025-03-26',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  },
  {
    unitsSold: 96,
    totalSalesAmount: 957238,
    pageViews: 4078,
    date: '2025-03-27',
    skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId
  }
] as Array<ModelTypes['EtailerProductMetrics']>
