export const SAMPLE_CAMPAIGN_REVIEW_METRICS_PILLOW = [
  {
    fromDate: '2024-03-02',
    toDate: '2024-03-03',
    numRatings: 3,
    numReviews: 3,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 3.4,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-03',
    toDate: '2024-03-04',
    numRatings: 3,
    numReviews: 3,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 3.4,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-04',
    toDate: '2024-03-05',
    numRatings: 3,
    numReviews: 3,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 3.4,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-05',
    toDate: '2024-03-06',
    numRatings: 3,
    numReviews: 3,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 3.4,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-06',
    toDate: '2024-03-07',
    numRatings: 3,
    numReviews: 3,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 3.4,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-07',
    toDate: '2024-03-08',
    numRatings: 3,
    numReviews: 3,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 3.3,
    momentumRating: 0,
    organicRating: 3.3,
    vineRating: 0
  },
  {
    fromDate: '2024-03-08',
    toDate: '2024-03-09',
    numRatings: 4,
    numReviews: 4,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 3.5,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-09',
    toDate: '2024-03-10',
    numRatings: 5,
    numReviews: 5,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 1,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 3.6,
    momentumRating: 4.8,
    organicRating: 2.4000000000000004,
    vineRating: 0
  },
  {
    fromDate: '2024-03-10',
    toDate: '2024-03-11',
    numRatings: 5,
    numReviews: 5,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 3.6,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-11',
    toDate: '2024-03-12',
    numRatings: 5,
    numReviews: 5,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 3.6,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-12',
    toDate: '2024-03-13',
    numRatings: 5,
    numReviews: 5,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 3.6,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-13',
    toDate: '2024-03-14',
    numRatings: 6,
    numReviews: 6,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [
      {
        title: 'Great quality!',
        text: 'We love this toilet paper. The quality is amazing.. especially for a recycled paper. It’s soft and just the right thickness.',
        name: 'Brittany',
        rating: 5,
        attributedUserId: '687309d9-cb89-414b-8eac-75967eabb762',
        reviewedAt: '2024-07-18T00:00:00.000Z'
      }
    ],
    rating: 3.7,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-14',
    toDate: '2024-03-15',
    numRatings: 6,
    numReviews: 6,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 3.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-15',
    toDate: '2024-03-16',
    numRatings: 6,
    numReviews: 6,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 3.8,
    momentumRating: 0,
    organicRating: 3.8,
    vineRating: 0
  },
  {
    fromDate: '2024-03-16',
    toDate: '2024-03-17',
    numRatings: 7,
    numReviews: 7,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [
      {
        title: 'Great product',
        text: 'Strong and durable, lasts a long time.',
        name: 'Tabyre Easton',
        rating: 5,
        attributedUserId: '4ee0a2dd-2cc8-4520-bf86-dab80b149c48',
        reviewedAt: '2024-07-21T00:00:00.000Z'
      }
    ],
    rating: 3.9,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-17',
    toDate: '2024-03-18',
    numRatings: 11,
    numReviews: 11,
    ratingsInPeriod: 4,
    momentumRatingCount: 4,
    vineReviewCount: 0,
    momentumReviewCount: 4,
    momentumReviewRating: 5,
    organicRatingCount: 0,
    reviewsInPeriod: 4,
    reviews: [
      {
        title: 'Really good quality',
        text: 'I love this,it’s very good quality and thick',
        name: 'Ellen G',
        rating: 5,
        attributedUserId: '8f4755db-efe3-4b28-aefc-57c62d3cc9cf',
        reviewedAt: '2024-07-22T00:00:00.000Z'
      },
      {
        title: 'Large and soft rolls',
        text: 'Very nice toilet tissue, large rolls that last long and soft. Love that it’s recyclable.',
        name: 'Cottage Park Home',
        rating: 5,
        attributedUserId: 'b0b8c473-0d8b-4da1-8f41-e860abdd93d4',
        reviewedAt: '2024-07-22T00:00:00.000Z'
      },
      {
        title: 'Amazing',
        text: 'I’ve been using Aria 100% Recycled Toilet Paper, and I’m thoroughly impressed. It’s incredibly soft and strong, making it a great choice for both comfort and durability. I love that it’s made from 100% recycled materials, aligning perfectly with my eco-friendly values. Plus, the packaging is also recyclable, which adds to its environmental benefits. Overall, Aria has proven that going green doesn’t mean compromising on quality.',
        name: 'Zulya',
        rating: 5,
        attributedUserId: '7dd2cf7c-09cd-4621-a882-dcd75e2d769e',
        reviewedAt: '2024-07-22T00:00:00.000Z'
      },
      {
        title: 'Bath Tissue',
        text: 'Very soft ..and lots on the roll!',
        name: 'Ariel White',
        rating: 5,
        attributedUserId: '55ed0f31-1432-4855-9f30-b903dc8f414b',
        reviewedAt: '2024-07-23T00:00:00.000Z'
      }
    ],
    rating: 4.1,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-18',
    toDate: '2024-03-19',
    numRatings: 13,
    numReviews: 13,
    ratingsInPeriod: 2,
    momentumRatingCount: 2,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4.2,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-19',
    toDate: '2024-03-20',
    numRatings: 17,
    numReviews: 17,
    ratingsInPeriod: 4,
    momentumRatingCount: 4,
    vineReviewCount: 0,
    momentumReviewCount: 4,
    momentumReviewRating: 4.8500000000000005,
    organicRatingCount: 1,
    reviewsInPeriod: 4,
    reviews: [
      {
        title: 'Very durable',
        text: 'This is definitely worth the value for the money and it’s super soft and highly recommend. Great packaging and I will definitely be purchasing.',
        name: 'Yesenia M',
        rating: 5,
        attributedUserId: '38b3d598-17eb-489a-a562-2ca815a82569',
        reviewedAt: '2024-07-27T00:00:00.000Z'
      }
    ],
    rating: 4.3,
    momentumRating: 4.8500000000000005,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-03-20',
    toDate: '2024-03-21',
    numRatings: 18,
    numReviews: 18,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [
      {
        title: 'Great!',
        text: 'Love this toilet paper!',
        name: 'Kelly Trudersheim',
        rating: 5,
        attributedUserId: '6b5383b5-75a3-4dc1-a4fa-442976874f29',
        reviewedAt: '2024-07-27T00:00:00.000Z'
      }
    ],
    rating: 4.4,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-21',
    toDate: '2024-03-22',
    numRatings: 18,
    numReviews: 18,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 0
  },
  {
    fromDate: '2024-03-22',
    toDate: '2024-03-23',
    numRatings: 20,
    numReviews: 20,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [
      {
        title: 'Love!',
        text: 'awesome quality & super durable',
        name: 'Olivia Tuggle',
        rating: 5,
        attributedUserId: '52574d62-ba31-439b-a420-6f2fda3eb2c8',
        reviewedAt: '2024-07-28T00:00:00.000Z'
      }
    ],
    rating: 4.4,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-23',
    toDate: '2024-03-24',
    numRatings: 20,
    numReviews: 20,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.2,
    momentumRating: 0,
    organicRating: 4.2,
    vineRating: 0
  },
  {
    fromDate: '2024-03-24',
    toDate: '2024-03-25',
    numRatings: 22,
    numReviews: 22,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-25',
    toDate: '2024-03-26',
    numRatings: 25,
    numReviews: 25,
    ratingsInPeriod: 3,
    momentumRatingCount: 3,
    vineReviewCount: 0,
    momentumReviewCount: 3,
    momentumReviewRating: 4.866666666666667,
    organicRatingCount: 0,
    reviewsInPeriod: 3,
    reviews: [
      {
        title: 'Great tissue paper!!',
        text: 'Love this stuff it’s a great value for your money and I love the material!',
        name: 'Mariah Nonette',
        rating: 5,
        attributedUserId: '973714b1-ffd0-4197-838d-973a415fcc56',
        reviewedAt: '2024-07-30T00:00:00.000Z'
      }
    ],
    rating: 4.4,
    momentumRating: 4.866666666666667,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-26',
    toDate: '2024-03-27',
    numRatings: 26,
    numReviews: 26,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.4,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-27',
    toDate: '2024-03-28',
    numRatings: 27,
    numReviews: 27,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [
      {
        title: 'We’ve completely switched over',
        text: 'We love Aria it’s great and amazing that it’s 100% recyclable',
        name: 'Eva Lebron',
        rating: 5,
        attributedUserId: '765e2f68-c509-42c6-9843-57387c600461',
        reviewedAt: '2024-08-02T00:00:00.000Z'
      }
    ],
    rating: 4.4,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-28',
    toDate: '2024-03-29',
    numRatings: 30,
    numReviews: 30,
    ratingsInPeriod: 3,
    momentumRatingCount: 3,
    vineReviewCount: 0,
    momentumReviewCount: 3,
    momentumReviewRating: 5,
    organicRatingCount: 0,
    reviewsInPeriod: 3,
    reviews: [
      {
        title: 'Very soft!',
        text: 'I’m pleased. Very soft and comfortable. Great quality!',
        name: 'Amelia',
        rating: 5,
        attributedUserId: '692f5a0e-ad8b-44cf-a218-d9ef5abe55b4',
        reviewedAt: '2024-08-03T00:00:00.000Z'
      },
      {
        title: 'Must get tissue',
        text: 'If you’re looking for soft and smooth tissues and value for your money? Then this is the tissue to get. Great that it’s recyclable, nice size that can last long and help you with the cleanliness you want.',
        name: 'Semeka Robinson',
        rating: 5,
        attributedUserId: 'b79abc01-eb58-42ac-a435-ae9020898fc5',
        reviewedAt: '2024-08-04T00:00:00.000Z'
      },
      {
        title: 'Good',
        text: 'It’s good. Fell better using recycled paper!',
        name: 'worldlyjen',
        rating: 5,
        attributedUserId: 'b7a1627c-9a4d-4359-aea3-0cda4048b9b4',
        reviewedAt: '2024-08-04T00:00:00.000Z'
      }
    ],
    rating: 4.4,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-29',
    toDate: '2024-03-30',
    numRatings: 32,
    numReviews: 32,
    ratingsInPeriod: 2,
    momentumRatingCount: 2,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 5,
    organicRatingCount: 0,
    reviewsInPeriod: 2,
    reviews: [
      {
        title: 'Nice recycled toilet paper',
        text: 'This toilet paper was very pleasant to use. No complaints at all.',
        name: 'Alyssa Heinrich',
        rating: 5,
        attributedUserId: '2dc979a3-de65-4892-bb7c-0d4916a01a79',
        reviewedAt: '2024-08-05T00:00:00.000Z'
      },
      {
        title: 'Soft and durable',
        text: 'Great brand ! Septic safe ! Feels good!',
        name: 'Jaleeha',
        rating: 5,
        attributedUserId: 'ae6147dd-f6a4-46a9-af5f-c186efcab5fe',
        reviewedAt: '2024-08-05T00:00:00.000Z'
      }
    ],
    rating: 4.5,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-03-30',
    toDate: '2024-03-31',
    numRatings: 34,
    numReviews: 34,
    ratingsInPeriod: 2,
    momentumRatingCount: 2,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.9,
    organicRatingCount: 1,
    reviewsInPeriod: 2,
    reviews: [
      {
        title: 'Very thick',
        text: 'I was just randomly searching for toilet paper to see which brand I’d eventually like. I tried many different brand toilet paper and I’m surprised these were so thick and soft. They’re regular size toilet paper, very easy to roll off and worth the price.',
        name: 'Isha Francis',
        rating: 5,
        attributedUserId: '72ee8fc3-07f8-45f4-bd0e-1eef5d34c5c9',
        reviewedAt: '2024-08-06T00:00:00.000Z'
      }
    ],
    rating: 4.5,
    momentumRating: 4.9,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-03-31',
    toDate: '2024-04-01',
    numRatings: 38,
    numReviews: 38,
    ratingsInPeriod: 4,
    momentumRatingCount: 4,
    vineReviewCount: 0,
    momentumReviewCount: 4,
    momentumReviewRating: 4.8500000000000005,
    organicRatingCount: 1,
    reviewsInPeriod: 4,
    reviews: [
      {
        title: 'Nicee',
        text: 'I got this tissue for free on a sponsorship app and it is soo soft! And not the soft kinda of tissue that stays when you wipe lol',
        name: 'Lovely Mo',
        rating: 5,
        attributedUserId: '2d6fb5d0-447e-467b-819a-e88960980722',
        reviewedAt: '2024-08-07T00:00:00.000Z'
      }
    ],
    rating: 4.6,
    momentumRating: 4.8500000000000005,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-04-01',
    toDate: '2024-04-02',
    numRatings: 39,
    numReviews: 39,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 1,
    reviewsInPeriod: 1,
    reviews: [
      {
        title: 'Sustainable!!!',
        text: 'Very good product, I didn’t expect this quality for recycled paper!',
        name: 'Ceyda',
        rating: 5,
        attributedUserId: 'f6bc663f-e1f5-41a4-983e-70c35f2d066c',
        reviewedAt: '2024-08-08T00:00:00.000Z'
      }
    ],
    rating: 4.6,
    momentumRating: 5,
    organicRating: 4.199999999999999,
    vineRating: 0
  },
  {
    fromDate: '2024-04-02',
    toDate: '2024-04-03',
    numRatings: 40,
    numReviews: 40,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [
      {
        title: 'Soft',
        text: 'Soft and good. It is effective. The only thing is maybe a 4-ply rather than 3-ply but overall very good. It is wonderful that it is recycled.',
        name: 'Yvonne Taylor',
        rating: 4,
        attributedUserId: '545070aa-3a79-4acb-b729-68f7080b1655',
        reviewedAt: '2024-08-09T00:00:00.000Z'
      }
    ],
    rating: 4.6,
    momentumRating: 4,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-04-03',
    toDate: '2024-04-04',
    numRatings: 41,
    numReviews: 41,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.6,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-04-04',
    toDate: '2024-04-05',
    numRatings: 43,
    numReviews: 43,
    ratingsInPeriod: 2,
    momentumRatingCount: 2,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4.6,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-04-05',
    toDate: '2024-04-06',
    numRatings: 44,
    numReviews: 44,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.6,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-04-06',
    toDate: '2024-04-07',
    numRatings: 47,
    numReviews: 47,
    ratingsInPeriod: 2,
    momentumRatingCount: 2,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4.6,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-04-07',
    toDate: '2024-04-08',
    numRatings: 47,
    numReviews: 47,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 2,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.6,
    momentumRating: 0,
    organicRating: 4.6,
    vineRating: 0
  },
  {
    fromDate: '2024-04-08',
    toDate: '2024-04-13',
    numRatings: 50,
    numReviews: 50,
    ratingsInPeriod: 3,
    momentumRatingCount: 3,
    vineReviewCount: 0,
    momentumReviewCount: 3,
    momentumReviewRating: 4.933333333333334,
    organicRatingCount: 3,
    reviewsInPeriod: 3,
    reviews: [
      {
        title: 'Soft and durable',
        text: 'Soft and gentle, yet strong and durable. Pleasantly surprised',
        name: 'Christopher S',
        rating: 5,
        attributedUserId: '4db54252-518b-432f-9f0d-13167945bf2b',
        reviewedAt: '2024-08-15T00:00:00.000Z'
      },
      {
        title: 'Soft',
        text: 'I love the size of the tissue and it’s 6 in the pack. It’s ease to use and it’s so soft. This is definitely a repurchased. You don’t need to use a lot because it’s thick',
        name: 'Tiffany',
        rating: 5,
        attributedUserId: '66b306f1-6b46-4a25-a5fc-5362d0891d0e',
        reviewedAt: '2024-08-16T00:00:00.000Z'
      }
    ],
    rating: 4.6,
    momentumRating: 4.933333333333334,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-04-13',
    toDate: '2024-04-20',
    numRatings: 58,
    numReviews: 58,
    ratingsInPeriod: 8,
    momentumRatingCount: 6,
    vineReviewCount: 0,
    momentumReviewCount: 6,
    momentumReviewRating: 4.766666666666667,
    organicRatingCount: 4,
    reviewsInPeriod: 8,
    reviews: [
      {
        title: 'ITS VERY GOOD QUALITY.',
        text: 'ITS VERY GOOD QUALITY. MUST TRY!',
        name: 'James M',
        rating: 5,
        attributedUserId: '7d441f19-cc60-4071-823e-bc66142e8f49',
        reviewedAt: '2024-08-21T00:00:00.000Z'
      },
      {
        title: 'Thick tissue',
        text: "Very thick toilet paper. It comes in one packaging, so when you pull it open there's no package to cover for the tissue inside, just the package cover. So, open with care.",
        name: 'Lamantha Laing',
        rating: 4,
        attributedUserId: '0f3ff74b-a90b-4bb0-9009-6bfadb869a36',
        reviewedAt: '2024-08-21T00:00:00.000Z'
      },
      {
        title: 'Soft Bath Tissue!',
        text: 'Great product! Soft for the bottom and it a plus because it is recycled!',
        name: 'Rhonda Duren',
        rating: 5,
        attributedUserId: 'a1845334-950a-4d41-85f8-bda36cfaa175',
        reviewedAt: '2024-08-21T00:00:00.000Z'
      },
      {
        title: 'Love Aria toilet paper',
        text: 'I truly was impressed with the Aria toilet paper! Its 3 layers make it so soft. It’s a great size and absorbs so well. It works way better than so many toilet papers I have used in the past. It’s a good value for the money for many reasons. I love that it’s not wrapped in plastic! I dont get any lint from it and it’s great to use especially on kids because it’s soft.',
        name: 'The Blonde and Co',
        rating: 5,
        attributedUserId: '68ae23d2-b9ba-4e27-a5c1-f626e20ee85a',
        reviewedAt: '2024-08-22T00:00:00.000Z'
      }
    ],
    rating: 4.7,
    momentumRating: 4.766666666666667,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-04-20',
    toDate: '2024-04-27',
    numRatings: 59,
    numReviews: 59,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 4,
    reviewsInPeriod: 1,
    reviews: [
      {
        title: 'Great TP',
        text: 'Thickness was great! And it wasn’t cheap feeling!!',
        name: 'Blaina',
        rating: 5,
        attributedUserId: '8b2483a4-c03b-41a0-8acb-a2ebcc74c08a',
        reviewedAt: '2024-08-28T00:00:00.000Z'
      }
    ],
    rating: 4.7,
    momentumRating: 5,
    organicRating: 4.625,
    vineRating: 0
  },
  {
    fromDate: '2024-04-27',
    toDate: '2024-05-04',
    numRatings: 64,
    numReviews: 64,
    ratingsInPeriod: 5,
    momentumRatingCount: 3,
    vineReviewCount: 0,
    momentumReviewCount: 3,
    momentumReviewRating: 4.933333333333334,
    organicRatingCount: 2,
    reviewsInPeriod: 5,
    reviews: [
      {
        title: 'Very nice',
        text: 'It’s actually three ply. Usually when. I buy three ply tissue it isn’t there but this time it is. It’s soft and big too.',
        name: 'Ashley',
        rating: 5,
        attributedUserId: '64908281-e955-4856-b592-5e6504d88020',
        reviewedAt: '2024-09-03T00:00:00.000Z'
      },
      {
        title: 'Good Product',
        text: 'Soft, eco friendly, and gets the job done. What more do you need',
        name: 'Chanel Garcia',
        rating: 5,
        attributedUserId: 'bf658792-0aa5-4348-949e-3c7f704d68b7',
        reviewedAt: '2024-09-05T00:00:00.000Z'
      }
    ],
    rating: 4.7,
    momentumRating: 4.933333333333334,
    organicRating: 5,
    vineRating: 0
  }
]
