import { mApi } from '@momentum/api'
import { ValueTypes } from '@productwindtom/shared-momentum-zeus-types'
import { productSelector } from './selectors'

export const requestCreateProductFromUrl = async (input: ValueTypes['RequestCreateProductFromUrlInput']) =>
  (
    await mApi('mutation')({
      requestCreateProductFromUrl: [
        {
          input
        },
        {
          id: true,
          status: true,
          skuId: true,
          parentSkuId: true
        }
      ]
    })
  )?.requestCreateProductFromUrl

export const createManualProduct = async (input: ValueTypes['ProductInput']) => {
  return (
    await mApi('mutation')({
      createManualProduct: [
        {
          input
        },
        productSelector
      ]
    })
  ).createManualProduct
}

export const updateManualProduct = async (input: ValueTypes['UpdateManualProductInput']) => {
  return (
    await mApi('mutation')({
      updateManualProduct: [
        {
          input
        },
        productSelector
      ]
    })
  ).updateManualProduct
}

export const adjustProductRecommendationVisibility = async (
  input: ValueTypes['AdjustProductRecommendationVisibilityInput']
) => {
  return (
    await mApi('mutation')({
      adjustProductRecommendationVisibility: [
        {
          input
        },
        productSelector
      ]
    })
  ).adjustProductRecommendationVisibility
}

export const updateCampaignProduct = async (
  campaignId: string,
  productId: string,
  productVariationSkus?: string[],
  creditsRequired?: number
) => {
  return (
    await mApi('mutation')({
      updateCampaignProduct: [
        { campaignId, productId, productVariationSkus, creditsRequired },
        {
          productId: true
        }
      ]
    })
  ).updateCampaignProduct
}
