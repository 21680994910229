import {
  BrandApiType,
  ContentType,
  CreatorType,
  PricingCreatorType,
  Region,
  Store
} from '@productwindtom/shared-momentum-zeus-types'

export const SOCIAL_CREATOR_TYPES = [CreatorType.IG, CreatorType.TT, CreatorType.YOUTUBE]
export const UGC_CREATOR_TYPES = [CreatorType.UGC, CreatorType.PREMIUM_UGC]

export const SOCIAL_CREATOR_DISTRIBUTION = {
  [CreatorType.IG]: 0.6,
  [CreatorType.TT]: 0.4
}
export const DEFAULT_CREATOR_CLOSEOUT_BONUS = {
  [CreatorType.IG]: 500,
  [CreatorType.TT]: 500,
  [CreatorType.YOUTUBE]: 500,
  [CreatorType.UGC]: 1000,
  [CreatorType.PREMIUM_UGC]: 500,
  [CreatorType.ADVOCATE]: 0
}
export const DEFAULT_CONTENT_REQUIREMENTS = {
  [CreatorType.IG]: [
    {
      contentType: ContentType.IG_REEL,
      bonus: 0
    },
    {
      contentType: ContentType.IG_STORY,
      bonus: 0
    },
    {
      contentType: ContentType.IG_STORY,
      bonus: 0
    }
  ],
  [CreatorType.TT]: [
    {
      contentType: ContentType.TT_VIDEO,
      bonus: 0
    },
    {
      contentType: ContentType.TT_VIDEO,
      bonus: 0
    }
  ],
  [CreatorType.YOUTUBE]: [],
  [CreatorType.UGC]: [
    {
      contentType: ContentType.UGC_VIDEO,
      bonus: 1000
    },
    {
      contentType: ContentType.UGC_PHOTO,
      bonus: 500
    }
  ],
  [CreatorType.PREMIUM_UGC]: [
    {
      contentType: ContentType.UGC_VIDEO,
      bonus: 0
    },
    {
      contentType: ContentType.UGC_PHOTO,
      bonus: 0
    }
  ],
  [CreatorType.ADVOCATE]: []
}

export const CREATOR_REVIEW_RATE = {
  [CreatorType.IG]: 0.6,
  [CreatorType.TT]: 0.6,
  [CreatorType.YOUTUBE]: 0.6,
  [CreatorType.UGC]: 0.7,
  [CreatorType.PREMIUM_UGC]: 0.7,
  [CreatorType.ADVOCATE]: 0
}

export const getCreatorReviewRate = (numCreators: number = 0, distribution: CreatorType) => {
  return Math.round(numCreators * CREATOR_REVIEW_RATE[distribution])
}

export const getSocialCreatorDistribution = (numCreators: number, distribution: CreatorType.IG | CreatorType.TT) => {
  return Math.round(numCreators * SOCIAL_CREATOR_DISTRIBUTION[distribution])
}

export const getPricingTypeFromCreatorType = (creatorType: CreatorType) => {
  switch (creatorType) {
    case CreatorType.IG:
    case CreatorType.TT:
    case CreatorType.YOUTUBE:
      return PricingCreatorType.SOCIAL
    case CreatorType.UGC:
      return PricingCreatorType.UGC
    case CreatorType.PREMIUM_UGC:
      return PricingCreatorType.PREMIUM_UGC
    case CreatorType.ADVOCATE:
      return PricingCreatorType.ADVOCATE
    default:
      throw new Error(`Invalid creator type: ${creatorType}`)
  }
}

export const SALES_PERCENTAGE_AMZ_VENDOR = 0.1
export const SALES_PERCENTAGE_AMZ_SELLER = 0.06
export const SALES_PERCENTAGE_COSTCO = 0.03
export const SALES_PERCENTAGE_OTHERS = 0.2

export const getReviewPercentage = (store?: Store, isVendor?: boolean): number => {
  switch (store) {
    case Store.amazon:
    case Store.amazon_ca:
    case Store.amazon_uk:
    case Store.amazon_de:
      return isVendor ? SALES_PERCENTAGE_AMZ_VENDOR : SALES_PERCENTAGE_AMZ_SELLER
    case Store.costco:
      return SALES_PERCENTAGE_COSTCO
    case Store.walmart:
    case Store.bestBuy:
    case Store.dtc:
    case Store.target:
    case Store.kroger:
    case Store.shopify:
    case Store.other:
    default:
      return SALES_PERCENTAGE_OTHERS
  }
}

export const getReviewsAllowedPerWeek = (unitsPerWeek: number, store?: Store, isVendor?: boolean): number => {
  return Math.ceil(unitsPerWeek * getReviewPercentage(store, isVendor))
}

export const BRAND_API_TYPE_BY_REGION: Record<Region, BrandApiType[]> = {
  [Region.US]: [BrandApiType.amazon_na_vendor, BrandApiType.amazon_na_seller],
  [Region.CA]: [BrandApiType.amazon_na_vendor, BrandApiType.amazon_na_seller],
  [Region.UK]: [BrandApiType.amazon_eu_vendor, BrandApiType.amazon_eu_seller],
  [Region.DE]: [BrandApiType.amazon_eu_vendor, BrandApiType.amazon_eu_seller]
}

export const CUSTOM_FEEDBACK_RESPONSE_COST = 5
export const CUSTOM_FEEDBACK_RESPONSE_RATE = 0.5
