import { mApi } from '@momentum/api'
import { ratingSnapshotsSelector } from '@momentum/utils/proposalUtils'
import { ModelSortDirection } from '@productwindtom/shared-momentum-zeus-types'

export const listProductRatingSnapshots = async (skuId: string) => {
  return (
    (
      await mApi('query')({
        listProductRatingSnapshots: [
          {
            skuId,
            sortDirection: ModelSortDirection.DESC
          },
          {
            items: ratingSnapshotsSelector
          }
        ]
      })
    ).listProductRatingSnapshots?.items ?? []
  )
}
