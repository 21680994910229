import { GraphQLTypes, InputType, Selector } from '@productwindtom/shared-ws-zeus-types'

export enum SocialChannel {
  INSTAGRAM = 'INSTAGRAM',
  TIKTOK = 'TIKTOK',
  RETAILER = 'RETAILER',
  YOUTUBE = 'YOUTUBE',
  UGC = 'UGC'
}

export enum ContentRequirementLevel {
  IS_REQUIRED = 'IS_REQUIRED',
  IS_OPTIONAL = 'IS_OPTIONAL'
}

export enum ContentQualityRating {
  NOT_USABLE = 'NOT_USABLE',
  NEW_USER = 'NEW_USER',
  PASSABLE_CONTENT = 'PASSABLE_CONTENT',
  MID_LEVEL_CONTENT = 'MID_LEVEL_CONTENT',
  TOP_QUALITY_CONTENT = 'TOP_QUALITY_CONTENT'
}

export enum SocialVerificationMethod {
  CODE = 'CODE',
  INTEGRATION = 'INTEGRATION'
}

export const userSelector = Selector('User')({
  fraudReviewStatus: true,
  declineToReviewCount: true,
  incompleteCampaignCount: true,
  completedLiftCampaignCount: true,
  completedLifestyleCampaignCount: true,
  completedSocialCampaignCount: true,
  participatedLifestyleCampaignCount: true,
  claimedReviewCount: true,
  attributedReviewCount: true,
  amznReviewerId: true,
  collisionSkuSet: true,
  subscriptionStatus: true,
  subscriptionEndsAt: true,
  instagramTrailingPostImpressionsMedian: true,
  tiktokMedianViews: true,
  instagramFollowersInt: true,
  tiktokFollowersInt: true,
  instagramTrailingPostEngagementRateMedian: true,
  tiktokMedianPostEngagementRateViews: true,
  tiktokVerificationMethod: true,
  instagramVerificationMethod: true
})

export type User = InputType<GraphQLTypes['User'], typeof userSelector>

export interface ProductSocialContent {
  requirementLevel: ContentRequirementLevel
  channel: SocialChannel
  instances: number
}

export const productVisibilitySelector = Selector('Product')({
  isQualityRequirementsEnabled: true,
  stagingSocialQualityRequirement: true,
  impressionsInstagramRangeRequirement: {
    min: true,
    max: true
  },
  impressionsTiktokRangeRequirement: {
    min: true,
    max: true
  },
  engagementRateInstagramRangeRequirement: {
    min: true,
    max: true
  },
  engagementRateTiktokRangeRequirement: {
    min: true,
    max: true
  },
  followersInstagramRangeRequirement: {
    min: true,
    max: true
  },
  followersTiktokRangeRequirement: {
    min: true
  }
})

const productSelector = Selector('Product')({
  id: true,
  ...productVisibilitySelector,
  campaignStateMachine: true,
  instantJoinType: true,
  instantJoinSeatsAvailable: true,
  contentRequirement: {
    requirementLevel: true,
    channel: true,
    instances: true
  }
})

export type Product = InputType<GraphQLTypes['Product'], typeof productSelector>
