import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'

export default [
  {
    unitsSold: 5,
    totalSalesAmount: 64995,
    pageViews: 82,
    date: '2025-01-29',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2025-01-30',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2025-01-31',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 35,
    date: '2025-02-01',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2025-02-02',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: -1,
    totalSalesAmount: -12999,
    pageViews: 0,
    date: '2025-02-03',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 12999,
    pageViews: 16,
    date: '2025-02-04',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2025-02-05',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2025-02-07',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2025-02-08',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2025-02-09',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2025-02-10',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2025-02-11',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2025-02-12',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2025-02-13',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2025-02-14',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2025-02-15',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2025-02-16',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2025-02-17',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2025-02-18',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2025-02-19',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2025-02-22',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2025-02-23',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2025-02-24',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: -1,
    totalSalesAmount: -12999,
    pageViews: 0,
    date: '2025-02-25',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2025-02-26',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 19,
    totalSalesAmount: 247204,
    pageViews: 314,
    date: '2025-02-27',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 70,
    totalSalesAmount: 910153,
    pageViews: 1716,
    date: '2025-02-28',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 49,
    totalSalesAmount: 636951,
    pageViews: 1460,
    date: '2025-03-01',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 47,
    totalSalesAmount: 610953,
    pageViews: 1358,
    date: '2025-03-02',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 35,
    totalSalesAmount: 454742,
    pageViews: 1399,
    date: '2025-03-03',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 46,
    totalSalesAmount: 597954,
    pageViews: 1886,
    date: '2025-03-04',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 38,
    totalSalesAmount: 493962,
    pageViews: 2410,
    date: '2025-03-05',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 30,
    totalSalesAmount: 389970,
    pageViews: 1721,
    date: '2025-03-06',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 37,
    totalSalesAmount: 480963,
    pageViews: 2175,
    date: '2025-03-07',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 22,
    totalSalesAmount: 285978,
    pageViews: 2022,
    date: '2025-03-08',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 37,
    totalSalesAmount: 480963,
    pageViews: 1648,
    date: '2025-03-09',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 43,
    totalSalesAmount: 558957,
    pageViews: 1558,
    date: '2025-03-10',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 30,
    totalSalesAmount: 389970,
    pageViews: 1355,
    date: '2025-03-11',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 32,
    totalSalesAmount: 415968,
    pageViews: 1506,
    date: '2025-03-12',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 16,
    totalSalesAmount: 207984,
    pageViews: 1328,
    date: '2025-03-13',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 32,
    totalSalesAmount: 415968,
    pageViews: 1381,
    date: '2025-03-14',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 51,
    totalSalesAmount: 663556,
    pageViews: 1606,
    date: '2025-03-15',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 17,
    totalSalesAmount: 221188,
    pageViews: 1513,
    date: '2025-03-16',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 18,
    totalSalesAmount: 234216,
    pageViews: 1657,
    date: '2025-03-17',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 21,
    totalSalesAmount: 273092,
    pageViews: 1662,
    date: '2025-03-18',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 39183,
    pageViews: 388,
    date: '2025-03-19',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 116940,
    pageViews: 1006,
    date: '2025-03-20',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 14,
    totalSalesAmount: 181986,
    pageViews: 1259,
    date: '2025-03-21',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 13,
    totalSalesAmount: 169120,
    pageViews: 1330,
    date: '2025-03-22',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 18,
    totalSalesAmount: 234115,
    pageViews: 1512,
    date: '2025-03-23',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 14,
    totalSalesAmount: 181912,
    pageViews: 1424,
    date: '2025-03-24',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 90958,
    pageViews: 1469,
    date: '2025-03-25',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 22,
    totalSalesAmount: 285938,
    pageViews: 1172,
    date: '2025-03-26',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  },
  {
    unitsSold: 17,
    totalSalesAmount: 220720,
    pageViews: 1216,
    date: '2025-03-27',
    skuId: 'ef977bea-87d4-4c54-8b65-f701c972b257'
  }
] as Array<ModelTypes['EtailerProductMetrics']>
