import { DateTime } from 'luxon'
import { SalesData } from '../salesContext'

export const LAUNDRY_SALES_1: SalesData[] = [
  {
    date: '2023-07-27T00:00:00.000-03:00',
    value: 1
  },
  {
    date: '2023-07-28T00:00:00.000-03:00',
    value: 5
  },
  {
    date: '2023-07-29T00:00:00.000-03:00',
    value: 10
  },
  {
    date: '2023-07-30T00:00:00.000-03:00',
    value: 20
  },
  {
    date: '2023-08-01T00:00:00.000-03:00',
    value: 15
  },
  {
    date: '2023-08-02T00:00:00.000-03:00',
    value: 38
  },
  {
    date: '2023-08-03T00:00:00.000-03:00',
    value: 35
  },
  {
    date: '2023-08-04T00:00:00.000-03:00',
    value: 14
  },
  {
    date: '2023-08-05T00:00:00.000-03:00',
    value: 12
  },
  {
    date: '2023-08-06T00:00:00.000-03:00',
    value: 37
  },
  {
    date: '2023-08-07T00:00:00.000-03:00',
    value: 42
  },
  {
    date: '2023-08-08T00:00:00.000-03:00',
    value: 75
  },
  {
    date: '2023-08-09T00:00:00.000-03:00',
    value: 96
  },
  {
    date: '2023-08-10T00:00:00.000-03:00',
    value: 66
  },
  {
    date: '2023-08-11T00:00:00.000-03:00',
    value: 55
  },
  {
    date: '2023-08-12T00:00:00.000-03:00',
    value: 46
  },
  {
    date: '2023-08-13T00:00:00.000-03:00',
    value: 45
  },
  {
    date: '2023-08-14T00:00:00.000-03:00',
    value: 29
  },
  {
    date: '2023-08-15T00:00:00.000-03:00',
    value: 73
  },
  {
    date: '2023-08-16T00:00:00.000-03:00',
    value: 12
  },
  {
    date: '2023-08-17T00:00:00.000-03:00',
    value: 55
  },
  {
    date: '2023-08-18T00:00:00.000-03:00',
    value: 76
  },
  {
    date: '2023-08-19T00:00:00.000-03:00',
    value: 78
  },
  {
    date: '2023-08-20T00:00:00.000-03:00',
    value: 88
  },
  {
    date: '2023-08-21T00:00:00.000-03:00',
    value: 90
  },
  {
    date: '2023-08-22T00:00:00.000-03:00',
    value: 85
  },
  {
    date: '2023-08-23T00:00:00.000-03:00',
    value: 84
  },
  {
    date: '2023-08-24T00:00:00.000-03:00',
    value: 59
  },
  {
    date: '2023-08-25T00:00:00.000-03:00',
    value: 48
  },
  {
    date: '2023-08-26T00:00:00.000-03:00',
    value: 27
  },
  {
    date: '2023-08-27T00:00:00.000-03:00',
    value: 30
  },
  {
    date: '2023-08-28T00:00:00.000-03:00',
    value: 22
  },
  {
    date: '2023-08-29T00:00:00.000-03:00',
    value: 25
  },
  {
    date: '2023-08-30T00:00:00.000-03:00',
    value: 26
  },
  {
    date: '2023-08-31T00:00:00.000-03:00',
    value: 23
  },
  {
    date: '2023-09-01T00:00:00.000-03:00',
    value: 25
  },
  {
    date: '2023-09-02T00:00:00.000-03:00',
    value: 28
  },
  {
    date: '2023-09-03T00:00:00.000-03:00',
    value: 22
  },
  {
    date: '2023-09-04T00:00:00.000-03:00',
    value: 21
  },
  {
    date: '2023-09-05T00:00:00.000-03:00',
    value: 25
  },
  {
    date: '2023-09-06T00:00:00.000-03:00',
    value: 33
  },
  {
    date: '2023-09-07T00:00:00.000-03:00',
    value: 25
  },
  {
    date: '2023-09-08T00:00:00.000-03:00',
    value: 31
  },
  {
    date: '2023-09-09T00:00:00.000-03:00',
    value: 31
  },
  {
    date: '2023-09-10T00:00:00.000-03:00',
    value: 21
  },
  {
    date: '2023-09-11T00:00:00.000-03:00',
    value: 14
  },
  {
    date: '2023-09-12T00:00:00.000-03:00',
    value: 15
  },
  {
    date: '2023-09-13T00:00:00.000-03:00',
    value: 18
  },
  {
    date: '2023-09-14T00:00:00.000-03:00',
    value: 15
  },
  {
    date: '2023-09-15T00:00:00.000-03:00',
    value: 14
  },
  {
    date: '2023-09-16T00:00:00.000-03:00',
    value: 19
  },
  {
    date: '2023-09-17T00:00:00.000-03:00',
    value: 22
  },
  {
    date: '2023-09-18T00:00:00.000-03:00',
    value: 23
  },
  {
    date: '2023-09-19T00:00:00.000-03:00',
    value: 21
  },
  {
    date: '2023-09-20T00:00:00.000-03:00',
    value: 21
  },
  {
    date: '2023-09-21T00:00:00.000-03:00',
    value: 30
  },
  {
    date: '2023-09-22T00:00:00.000-03:00',
    value: 20
  },
  {
    date: '2023-09-23T00:00:00.000-03:00',
    value: 10
  },
  
].map(d => ({ ...d, date: DateTime.fromISO(d.date) }))


export const LAUNDRY_SALES_2: SalesData[] = [
  {
    date: '2023-08-03T00:00:00.000-03:00',
    value: 31
  },
  {
    date: '2023-08-04T00:00:00.000-03:00',
    value: 19
  },
  {
    date: '2023-08-05T00:00:00.000-03:00',
    value: 28
  },
  {
    date: '2023-08-06T00:00:00.000-03:00',
    value: 42
  },
  {
    date: '2023-08-07T00:00:00.000-03:00',
    value: 51
  },
  {
    date: '2023-08-08T00:00:00.000-03:00',
    value: 63
  },
  {
    date: '2023-08-09T00:00:00.000-03:00',
    value: 82
  },
  {
    date: '2023-08-10T00:00:00.000-03:00',
    value: 71
  },
  {
    date: '2023-08-11T00:00:00.000-03:00',
    value: 44
  },
  {
    date: '2023-08-12T00:00:00.000-03:00',
    value: 39
  },
  {
    date: '2023-08-13T00:00:00.000-03:00',
    value: 52
  },
  {
    date: '2023-08-14T00:00:00.000-03:00',
    value: 33
  },
  {
    date: '2023-08-15T00:00:00.000-03:00',
    value: 67
  },
  {
    date: '2023-08-16T00:00:00.000-03:00',
    value: 25
  },
  {
    date: '2023-08-17T00:00:00.000-03:00',
    value: 48
  },
  {
    date: '2023-08-18T00:00:00.000-03:00',
    value: 69
  },
  {
    date: '2023-08-19T00:00:00.000-03:00',
    value: 72
  },
  {
    date: '2023-08-20T00:00:00.000-03:00',
    value: 91
  },
  {
    date: '2023-08-21T00:00:00.000-03:00',
    value: 86
  },
  {
    date: '2023-08-22T00:00:00.000-03:00',
    value: 79
  },
  {
    date: '2023-08-23T00:00:00.000-03:00',
    value: 77
  },
  {
    date: '2023-08-24T00:00:00.000-03:00',
    value: 54
  },
  {
    date: '2023-08-25T00:00:00.000-03:00',
    value: 41
  },
  {
    date: '2023-08-26T00:00:00.000-03:00',
    value: 32
  },
  {
    date: '2023-08-27T00:00:00.000-03:00',
    value: 35
  },
  {
    date: '2023-08-28T00:00:00.000-03:00',
    value: 27
  },
  {
    date: '2023-08-29T00:00:00.000-03:00',
    value: 29
  },
  {
    date: '2023-08-30T00:00:00.000-03:00',
    value: 31
  },
  {
    date: '2023-08-31T00:00:00.000-03:00',
    value: 28
  },
  {
    date: '2023-09-01T00:00:00.000-03:00',
    value: 30
  },
  {
    date: '2023-09-02T00:00:00.000-03:00',
    value: 33
  },
  {
    date: '2023-09-03T00:00:00.000-03:00',
    value: 26
  },
  {
    date: '2023-09-04T00:00:00.000-03:00',
    value: 24
  },
  {
    date: '2023-09-05T00:00:00.000-03:00',
    value: 29
  },
  {
    date: '2023-09-06T00:00:00.000-03:00',
    value: 37
  },
  {
    date: '2023-09-07T00:00:00.000-03:00',
    value: 28
  },
  {
    date: '2023-09-08T00:00:00.000-03:00',
    value: 34
  },
  {
    date: '2023-09-09T00:00:00.000-03:00',
    value: 36
  },
  {
    date: '2023-09-10T00:00:00.000-03:00',
    value: 25
  },
  {
    date: '2023-09-11T00:00:00.000-03:00',
    value: 19
  },
  {
    date: '2023-09-12T00:00:00.000-03:00',
    value: 22
  },
  {
    date: '2023-09-13T00:00:00.000-03:00',
    value: 24
  },
  {
    date: '2023-09-14T00:00:00.000-03:00',
    value: 20
  },
  {
    date: '2023-09-15T00:00:00.000-03:00',
    value: 18
  },
  {
    date: '2023-09-16T00:00:00.000-03:00',
    value: 23
  },
  {
    date: '2023-09-17T00:00:00.000-03:00',
    value: 27
  },
  {
    date: '2023-09-18T00:00:00.000-03:00',
    value: 29
  },
  {
    date: '2023-09-19T00:00:00.000-03:00',
    value: 25
  },
  {
    date: '2023-09-20T00:00:00.000-03:00',
    value: 24
  },
  {
    date: '2023-09-21T00:00:00.000-03:00',
    value: 30
  },
  {
    date: '2023-09-22T00:00:00.000-03:00',
    value: 20
  },
  {
    date: '2023-09-23T00:00:00.000-03:00',
    value: 10
  }
  
].map(d => ({ ...d, date: DateTime.fromISO(d.date) }))
