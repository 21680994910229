/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { ZeusInterceptor } from '../types'
import { SAMPLE_CAMPAIGN_BENCHMARK_METRICS } from './sample-data'

export const getBenchmarkMetrics: ZeusInterceptor<'query', 'getBenchmarkMetrics'> = async args => {
  const params = args.o.getBenchmarkMetrics?.[0]
  const data = SAMPLE_CAMPAIGN_BENCHMARK_METRICS[params?.skuId!]

  if (typeof data === 'object' && !Array.isArray(data)) {
    return data[params?.benchmarkType!]
  } else {
    return data
  }
}
