import { DateTime } from "luxon"
import { Close, Circle } from "@mui/icons-material"
import { useSubscriptionContext } from "@momentum/contexts/Subscription"
import { CreditAction, PaymentTermsType, SubscriptionLevel } from "@productwindtom/shared-momentum-zeus-types"
import { useUserSessionContext } from "@momentum/contexts/UserSession"
import { Box, Button, IconButton, Dialog, Stack, Typography } from "@mui/material"
import { useDisclose } from "@momentum/hooks/useDisclose"
import { Alert, AlertTitle } from "@mui/material"
import { AdjustCreditsForm } from "./AdjustCreditsForm"
import { useState } from "react"
import { toast } from "react-toastify"
import { BuyCreditsFormV2 } from "./BuyCreditsFormV2"
import { BuyCreditsFormData } from "./BuyCreditsFormV2"
import { errorToast, successToast } from '@momentum/utils/toastUtils'
import { captureException } from '@sentry/react'
import { AdjustCreditsFormData } from "./AdjustCreditsForm"
import ClockWarningOutlineIcon from "@momentum/components/icons/clock-warning-outline";
import BonusHighlightedIcon from "@momentum/components/icons/bonus-highlighted";

export const Actions = () => {
  const {
    name,
    subscriptionLevel,
    getSubscriptionStatus,
    creditsRemaining,
    overdueCreditActions,
    addCredits,
    spendCredits,
    purchaseCredits,
    termMonths,
    startsAt,
    endsAt
  } = useSubscriptionContext()
  const { isAdminView, selectedCompany, brands } = useUserSessionContext()
  const { isActive, hasStarted } = getSubscriptionStatus()
  const [adjustCreditsAction, setAdjustCreditsAction] = useState<CreditAction>(CreditAction.ADD)
  const [isAdjustCreditsOpen, setIsAdjustCreditsOpen] = useState(false)
  const { isOpen: isBuyCreditsOpen, onOpen: onOpenBuyCredits, onClose: onCloseBuyCredits } = useDisclose()

  const companyBrands = brands.filter(b => b.companyId === selectedCompany?.id)

  if (!selectedCompany) {
    return null;
  }

  const handleAdjustCreditsClose = () => {
    setIsAdjustCreditsOpen(false)
  }

  const handleAdjustCreditsSubmit = async (data: AdjustCreditsFormData) => {
    try {
      if (data.action === CreditAction.ADD) {
        await addCredits({ numCredits: data.numCredits })
        toast(
          <Typography variant={'subtitle2'}>You have added {data.numCredits.toLocaleString()} credits</Typography>,
          {
            type: 'success'
          }
        )
      } else if (data.action === CreditAction.REMOVE) {
        await spendCredits({ numCredits: data.numCredits, associatedBrandId: data.associatedBrandId })
        toast(<Typography variant={'subtitle2'}>You have used {data.numCredits.toLocaleString()} credits</Typography>, {
          type: 'success'
        })
      }
    } catch (e) {
      toast(<Typography variant={'subtitle2'}>Failed to adjust credits!</Typography>, { type: 'error' })
    }
    setIsAdjustCreditsOpen(false)
  }

  const handleAddCreditsClick = () => {
    onCloseBuyCredits()
    setAdjustCreditsAction(CreditAction.ADD)
    setIsAdjustCreditsOpen(true)
  }

  const handleUseCreditsClick = () => {
    setAdjustCreditsAction(CreditAction.REMOVE)
    setIsAdjustCreditsOpen(true)
  }

  const submitBuyCredits = async ({ invoiceMethod, ...input }: BuyCreditsFormData) => {
    try {
      await purchaseCredits(input)
      successToast('Credits purchased successfully')
      onCloseBuyCredits()
    } catch (e) {
      console.error(e)
      captureException(e)
      errorToast('An error occurred while purchasing credits')
    }
  }

  const companyPaymentTerms = (): string => {
    if (selectedCompany.paymentTermsType === PaymentTermsType.NET_CUSTOM) {
      return `Net ${selectedCompany.paymentTermsCustomNetDays || 7} days`
    } else {
      return 'Due upon receipt'
    }
  }

  return (
    <>
    {!!overdueCreditActions.length && (
      <Stack mb={5} spacing={1}>
        {overdueCreditActions.map(creditAction => (
          <Alert 
            key={creditAction.id}
            severity="error" 
            variant="outlined"
            iconMapping={{
              error: <Box sx={{
                display: 'flex',
                alignItems: 'center',
              }}>
                <ClockWarningOutlineIcon color="error" fontSize={'large'}  />
              </Box>
            }}
          >
            <AlertTitle>
              <Typography variant={'label1'} color={'black'}>
                You have an overdue invoice for new credits.
              </Typography>
            </AlertTitle>
            <Typography variant="label3" color={'black'}>
              Pay invoice #{creditAction.invoiceNumber} to add credits and schedule new campaigns.
            </Typography>
          </Alert>
        ))}
      </Stack>
    )}

    {isActive && (
      <Stack
        direction={'row'}
        spacing={4}
        justifyContent={'space-between'}
        mb={5}
        border={theme => `1px solid ${theme.palette.grey[200]}`}
        borderRadius={2}
        px={3}
        py={2}
      >
        <Stack direction={'row'} spacing={1}>
          <Box width={8} bgcolor={'info.main'} borderRadius={100} />
          <Stack direction={'column'}>
            <Typography variant={'subtitle2'} color={theme => theme.palette.grey.A700}>
              Credits remaining <span style={{ fontWeight: 'bold' }}>{subscriptionLevel === SubscriptionLevel.COMPANY && (<>for {selectedCompany.name}</>)}</span>
            </Typography>
            <Stack direction={'row'} spacing={1} mt={1}>
              <BonusHighlightedIcon sx={{ color: t => t.palette.warning.main, width: 32, height: 32 }} />
              <Typography variant={'h3'}>{creditsRemaining.toLocaleString()} credits</Typography>
            </Stack>
            <Stack direction={'row'} spacing={2} alignItems={'flex-end'} mt={2}>
              <Box>
                <Button variant={'contained'} onClick={onOpenBuyCredits} data-cy={'buyCreditsButton'}>
                  + Buy credits
                </Button>
              </Box>
              {isAdminView && (
                <Box>
                  <Button variant={'outlined'} onClick={handleUseCreditsClick} data-cy={'useCreditsButton'}>
                    Use credits
                  </Button>
                </Box>
              )}
            </Stack>
          </Stack>
        </Stack>

        <Stack direction={'column'} spacing={2} alignItems={'flex-end'}>
          <Stack direction={'column'} alignItems={'flex-end'}>
            { !!termMonths && (
              <Typography variant="label2" color="grey.A700" align="right">
                Subscription term: <span style={{ color: 'black' }}>{`${termMonths}`} months</span>
              </Typography>
            )}
            <Stack direction={'row'} spacing={0.5} justifyContent={'flex-end'}>
              {!!startsAt && (
                <Typography variant={'label2'} color="grey.A700" align="right">
                  {!isActive && !hasStarted ? 'Starting' : 'Started'} <span style={{ color: 'black' }}>{startsAt.toLocaleString(DateTime.DATE_SHORT)}</span>
                </Typography>
              )}
              <Circle sx={{ color: 'grey.A400', fontSize: '4px', alignSelf: 'center' }} />
            {!!endsAt && (
              <Typography variant={'label2'} color="grey.A700" align="right">
                {isActive || !hasStarted ? 'Ending' : 'Ended'} <span style={{ color: 'black' }}>{endsAt.toLocaleString(DateTime.DATE_SHORT)}</span>
                </Typography>
              )}
            </Stack>
          </Stack>

          <Typography variant={'label2'} color="grey.A700">
            Payment terms: <span style={{ color: 'black' }}>{companyPaymentTerms()}</span>
          </Typography>
        </Stack>
      </Stack>
    )}

    <Dialog open={isAdjustCreditsOpen} maxWidth={'xs'} fullWidth>
      <Stack p={3}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={4}>
          <Typography variant={'h4'}>
            {adjustCreditsAction === CreditAction.ADD ? 'Add' : 'Use'} credits{' '}
            {adjustCreditsAction === CreditAction.ADD ? 'for' : 'from'} {name}
          </Typography>
          <IconButton onClick={handleAdjustCreditsClose}>
            <Close />
          </IconButton>
        </Stack>
        <AdjustCreditsForm
          action={adjustCreditsAction}
          onCancel={handleAdjustCreditsClose}
          onSubmit={handleAdjustCreditsSubmit}
          creditsRemaining={creditsRemaining}
          brands={subscriptionLevel === SubscriptionLevel.COMPANY ? companyBrands : []}
        />
      </Stack>
    </Dialog>
    <Dialog open={isBuyCreditsOpen} maxWidth={'sm'} fullWidth>
      <Stack p={3} spacing={4}>
        <Stack>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant={'h4'}>Buy credits for {name}</Typography>
            <IconButton onClick={onCloseBuyCredits}>
              <Close />
            </IconButton>
          </Stack>
          <Typography variant={'subtitle2'} color={'grey.A700'}>
            Credits can be used on any Momentum campaign. An invoice will be generated through{' '}
            <Typography color={'primary'} variant={'subtitle1'} component={'span'}>
              Bill.com
            </Typography>
            .
          </Typography>
        </Stack>
        <BuyCreditsFormV2
          onCancel={onCloseBuyCredits}
          onManuallyAddCreditsClick={handleAddCreditsClick}
          onSubmit={submitBuyCredits}
        />
      </Stack>
    </Dialog>
  </>
  )
}

export default Actions
