//@ts-nocheck
import { SAMPLE_ETAILER_PRODUCT_METRICS_ENERGY_DRINK } from '@momentum/api/interceptors/listEtailerProductMetrics/sample-data/energy-drink'
import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'
import { SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING } from '../../getCampaign/sample-data/energy-drink-pd-existing'
import { SAMPLE_CAMPAIGN_HEADPHONES } from '../../getCampaign/sample-data/headphones'
import { SAMPLE_CAMPAIGN_KEYBOARD } from '../../getCampaign/sample-data/keyboard'
import { SAMPLE_CAMPAIGN_LAUNDRY } from '../../getCampaign/sample-data/laundry'
import { SAMPLE_CAMPAIGN_MARKER } from '../../getCampaign/sample-data/marker'
import { SAMPLE_CAMPAIGN_PILLOW } from '../../getCampaign/sample-data/pillow'
import { SAMPLE_CAMPAIGN_WHITENING } from '../../getCampaign/sample-data/whitening'
import { SAMPLE_CAMPAIGN_WIRELESS_EARBUDS } from '../../getCampaign/sample-data/wireless-earbuds'
import { SAMPLE_ETAILER_PRODUCT_METRICS_HEADPHONES } from './headphones'
import { SAMPLE_ETAILER_PRODUCT_METRICS_LAUNDRY } from './laundry'
import { SAMPLE_ETAILER_PRODUCT_METRICS_WIRELESS_EARBUDS } from './wireless-earbuds'

export const SAMPLE_ETAILER_PRODUCT_METRICS: Record<string, ModelTypes['ModelItemsEtailerProductMetrics']> = {
  [SAMPLE_CAMPAIGN_LAUNDRY.skuId]: SAMPLE_ETAILER_PRODUCT_METRICS_LAUNDRY,
  'ABC123': [], // laundry variants
  'DEF456': [],
  [SAMPLE_CAMPAIGN_HEADPHONES.skuId]: SAMPLE_ETAILER_PRODUCT_METRICS_HEADPHONES,
  [SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.skuId]: SAMPLE_ETAILER_PRODUCT_METRICS_ENERGY_DRINK,
  [SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId]: SAMPLE_ETAILER_PRODUCT_METRICS_WIRELESS_EARBUDS,
  [SAMPLE_CAMPAIGN_KEYBOARD.skuId]: {
    items: [
      {
        date: '2024-02-22',
        pageViews: 2325,
        totalSalesAmount: 418916,
        unitsSold: 84
      },
      {
        date: '2024-02-23',
        pageViews: 2246,
        totalSalesAmount: 299940,
        unitsSold: 60
      },
      {
        date: '2024-02-24',
        pageViews: 1799,
        totalSalesAmount: 249950,
        unitsSold: 50
      },
      {
        date: '2024-02-25',
        pageViews: 1581,
        totalSalesAmount: 304939,
        unitsSold: 61
      },
      {
        date: '2024-02-26',
        pageViews: 1748,
        totalSalesAmount: 344931,
        unitsSold: 69
      },
      {
        date: '2024-02-27',
        pageViews: 1743,
        totalSalesAmount: 414917,
        unitsSold: 83
      },
      {
        date: '2024-02-28',
        pageViews: 1852,
        totalSalesAmount: 434913,
        unitsSold: 87
      },
      {
        date: '2024-02-29',
        pageViews: 1766,
        totalSalesAmount: 379924,
        unitsSold: 76
      },
      {
        date: '2024-03-01',
        pageViews: 1839,
        totalSalesAmount: 434913,
        unitsSold: 87
      },
      {
        date: '2024-03-02',
        pageViews: 1496,
        totalSalesAmount: 199960,
        unitsSold: 40
      },
      {
        date: '2024-03-03',
        pageViews: 1147,
        totalSalesAmount: 244954,
        unitsSold: 46
      },
      {
        date: '2024-03-04',
        pageViews: 1182,
        totalSalesAmount: 231458,
        unitsSold: 42
      },
      {
        date: '2024-03-05',
        pageViews: 1102,
        totalSalesAmount: 277265,
        unitsSold: 49
      }
    ]
  },
  [SAMPLE_CAMPAIGN_WHITENING.skuId]: {
    items: [
      {
        date: '2024-01-04',
        pageViews: null,
        totalSalesAmount: 569392,
        unitsSold: 130
      },
      {
        date: '2024-01-05',
        pageViews: null,
        totalSalesAmount: 549943,
        unitsSold: 111
      },
      {
        date: '2024-01-06',
        pageViews: null,
        totalSalesAmount: 546343,
        unitsSold: 111
      },
      {
        date: '2024-01-07',
        pageViews: null,
        totalSalesAmount: 573089,
        unitsSold: 114
      },
      {
        date: '2024-01-08',
        pageViews: null,
        totalSalesAmount: 539186,
        unitsSold: 114
      },
      {
        date: '2024-01-09',
        pageViews: null,
        totalSalesAmount: 318726,
        unitsSold: 66
      },
      {
        date: '2024-01-10',
        pageViews: 5667,
        totalSalesAmount: 472154,
        unitsSold: 99
      },
      {
        date: '2024-01-11',
        pageViews: 4754,
        totalSalesAmount: 507307,
        unitsSold: 105
      },
      {
        date: '2024-01-12',
        pageViews: 4102,
        totalSalesAmount: 206180,
        unitsSold: 41
      },
      {
        date: '2024-01-13',
        pageViews: 3863,
        totalSalesAmount: 219824,
        unitsSold: 44
      },
      {
        date: '2024-01-14',
        pageViews: 5660,
        totalSalesAmount: 400083,
        unitsSold: 80
      },
      {
        date: '2024-01-15',
        pageViews: 5937,
        totalSalesAmount: 477461,
        unitsSold: 95
      },
      {
        date: '2024-01-16',
        pageViews: 5410,
        totalSalesAmount: 481834,
        unitsSold: 96
      },
      {
        date: '2024-01-17',
        pageViews: 5793,
        totalSalesAmount: 375018,
        unitsSold: 75
      },
      {
        date: '2024-01-18',
        pageViews: 5740,
        totalSalesAmount: 416097,
        unitsSold: 83
      },
      {
        date: '2024-01-19',
        pageViews: 3841,
        totalSalesAmount: 225929,
        unitsSold: 45
      },
      {
        date: '2024-01-20',
        pageViews: 3899,
        totalSalesAmount: 258296,
        unitsSold: 52
      },
      {
        date: '2024-01-21',
        pageViews: 6039,
        totalSalesAmount: 568212,
        unitsSold: 129
      },
      {
        date: '2024-01-22',
        pageViews: 5228,
        totalSalesAmount: 326252,
        unitsSold: 65
      },
      {
        date: '2024-01-23',
        pageViews: 5622,
        totalSalesAmount: 431000,
        unitsSold: 86
      },
      {
        date: '2024-01-24',
        pageViews: null,
        totalSalesAmount: 452182,
        unitsSold: 92
      },
      {
        date: '2024-01-25',
        pageViews: null,
        totalSalesAmount: 506962,
        unitsSold: 101
      },
      {
        date: '2024-01-26',
        pageViews: null,
        totalSalesAmount: 396296,
        unitsSold: 79
      },
      {
        date: '2024-01-27',
        pageViews: null,
        totalSalesAmount: 540224,
        unitsSold: 108
      },
      {
        date: '2024-01-28',
        pageViews: null,
        totalSalesAmount: 510950,
        unitsSold: 102
      },
      {
        date: '2024-01-29',
        pageViews: null,
        totalSalesAmount: 449578,
        unitsSold: 92
      },
      {
        date: '2024-01-30',
        pageViews: null,
        totalSalesAmount: 410845,
        unitsSold: 84
      },
      {
        date: '2024-01-31',
        pageViews: null,
        totalSalesAmount: 491312,
        unitsSold: 101
      },
      {
        date: '2024-02-01',
        pageViews: null,
        totalSalesAmount: 379796,
        unitsSold: 77
      },
      {
        date: '2024-02-02',
        pageViews: null,
        totalSalesAmount: 432426,
        unitsSold: 88
      },
      {
        date: '2024-02-03',
        pageViews: null,
        totalSalesAmount: 435807,
        unitsSold: 89
      },
      {
        date: '2024-02-04',
        pageViews: null,
        totalSalesAmount: 650859,
        unitsSold: 132
      },
      {
        date: '2024-02-05',
        pageViews: null,
        totalSalesAmount: 311576,
        unitsSold: 63
      },
      {
        date: '2024-02-06',
        pageViews: null,
        totalSalesAmount: 194839,
        unitsSold: 39
      },
      {
        date: '2024-02-07',
        pageViews: 1707,
        totalSalesAmount: 196634,
        unitsSold: 39
      },
      {
        date: '2024-02-08',
        pageViews: 8063,
        totalSalesAmount: 1107098,
        unitsSold: 319
      },
      {
        date: '2024-02-09',
        pageViews: 12232,
        totalSalesAmount: 1349646,
        unitsSold: 428
      },
      {
        date: '2024-02-10',
        pageViews: 11692,
        totalSalesAmount: 988888,
        unitsSold: 330
      },
      {
        date: '2024-02-11',
        pageViews: 16454,
        totalSalesAmount: 1159251,
        unitsSold: 385
      },
      {
        date: '2024-02-12',
        pageViews: 17564,
        totalSalesAmount: 1252040,
        unitsSold: 417
      },
      {
        date: '2024-02-13',
        pageViews: 18425,
        totalSalesAmount: 1477375,
        unitsSold: 491
      },
      {
        date: '2024-02-14',
        pageViews: 17381,
        totalSalesAmount: 1437000,
        unitsSold: 479
      },
      {
        date: '2024-02-15',
        pageViews: 9991,
        totalSalesAmount: 717609,
        unitsSold: 158
      },
      {
        date: '2024-02-16',
        pageViews: 10658,
        totalSalesAmount: 610540,
        unitsSold: 127
      },
      {
        date: '2024-02-17',
        pageViews: 7280,
        totalSalesAmount: 742679,
        unitsSold: 159
      },
      {
        date: '2024-02-18',
        pageViews: 12097,
        totalSalesAmount: 831140,
        unitsSold: 185
      },
      {
        date: '2024-02-19',
        pageViews: 9981,
        totalSalesAmount: 713027,
        unitsSold: 159
      },
      {
        date: '2024-02-20',
        pageViews: 9407,
        totalSalesAmount: 727146,
        unitsSold: 149
      },
      {
        date: '2024-02-21',
        pageViews: null,
        totalSalesAmount: 627923,
        unitsSold: 133
      },
      {
        date: '2024-02-22',
        pageViews: 9765,
        totalSalesAmount: 739559,
        unitsSold: 151
      },
      {
        date: '2024-02-23',
        pageViews: 9355,
        totalSalesAmount: 660191,
        unitsSold: 132
      },
      {
        date: '2024-02-24',
        pageViews: 8295,
        totalSalesAmount: 705268,
        unitsSold: 141
      },
      {
        date: '2024-02-25',
        pageViews: 5322,
        totalSalesAmount: 483746,
        unitsSold: 101
      },
      {
        date: '2024-02-26',
        pageViews: 7540,
        totalSalesAmount: 949307,
        unitsSold: 225
      },
      {
        date: '2024-02-27',
        pageViews: 6117,
        totalSalesAmount: 836007,
        unitsSold: 201
      },
      {
        date: '2024-02-28',
        pageViews: 21790,
        totalSalesAmount: 968389,
        unitsSold: 232
      },
      {
        date: '2024-02-29',
        pageViews: 26609,
        totalSalesAmount: 580296,
        unitsSold: 132
      },
      {
        date: '2024-03-01',
        pageViews: 14169,
        totalSalesAmount: 412750,
        unitsSold: 92
      },
      {
        date: '2024-03-02',
        pageViews: 3632,
        totalSalesAmount: 231325,
        unitsSold: 46
      },
      {
        date: '2024-03-03',
        pageViews: 3998,
        totalSalesAmount: 129896,
        unitsSold: 26
      },
      {
        date: '2024-03-04',
        pageViews: 5026,
        totalSalesAmount: 222850,
        unitsSold: 44
      },
      {
        date: '2024-03-05',
        pageViews: 12974,
        totalSalesAmount: 384375,
        unitsSold: 76
      },
      {
        date: '2024-03-06',
        pageViews: 11845,
        totalSalesAmount: 371494,
        unitsSold: 74
      },
      {
        date: '2024-03-07',
        pageViews: 12497,
        totalSalesAmount: 399680,
        unitsSold: 80
      },
      {
        date: '2024-03-08',
        pageViews: 12892,
        totalSalesAmount: 349720,
        unitsSold: 70
      },
      {
        date: '2024-03-09',
        pageViews: 13381,
        totalSalesAmount: 357113,
        unitsSold: 73
      },
      {
        date: '2024-03-10',
        pageViews: 12812,
        totalSalesAmount: 406245,
        unitsSold: 88
      },
      {
        date: '2024-03-11',
        pageViews: 13449,
        totalSalesAmount: 393850,
        unitsSold: 86
      },
      {
        date: '2024-03-12',
        pageViews: 11717,
        totalSalesAmount: 292834,
        unitsSold: 59
      },
      {
        date: '2024-03-13',
        pageViews: 10036,
        totalSalesAmount: 336060,
        unitsSold: 71
      },
      {
        date: '2024-03-14',
        pageViews: 11305,
        totalSalesAmount: 299206,
        unitsSold: 62
      },
      {
        date: '2024-03-15',
        pageViews: 14305,
        totalSalesAmount: 424991,
        unitsSold: 89
      },
      {
        date: '2024-03-16',
        pageViews: 11283,
        totalSalesAmount: 553749,
        unitsSold: 117
      },
      {
        date: '2024-03-17',
        pageViews: 5637,
        totalSalesAmount: 235137,
        unitsSold: 48
      },
      {
        date: '2024-03-18',
        pageViews: 2129,
        totalSalesAmount: 164441,
        unitsSold: 33
      },
      {
        date: '2024-03-19',
        pageViews: 11655,
        totalSalesAmount: 582779,
        unitsSold: 144
      },
      {
        date: '2024-03-20',
        pageViews: 19414,
        totalSalesAmount: 1482887,
        unitsSold: 371
      },
      {
        date: '2024-03-21',
        pageViews: 17760,
        totalSalesAmount: 1031226,
        unitsSold: 258
      }
    ]
  },
  [SAMPLE_CAMPAIGN_MARKER.skuId]: {
    items: [
      {
        date: '2024-02-05',
        pageViews: null,
        totalSalesAmount: 27286,
        unitsSold: 14
      },
      {
        date: '2024-02-06',
        pageViews: null,
        totalSalesAmount: 42878,
        unitsSold: 22
      },
      {
        date: '2024-02-07',
        pageViews: null,
        totalSalesAmount: 39980,
        unitsSold: 20
      },
      {
        date: '2024-02-08',
        pageViews: null,
        totalSalesAmount: 22139,
        unitsSold: 11
      },
      {
        date: '2024-02-09',
        pageViews: 142,
        totalSalesAmount: 29985,
        unitsSold: 15
      },
      {
        date: '2024-02-10',
        pageViews: 115,
        totalSalesAmount: 28036,
        unitsSold: 14
      },
      {
        date: '2024-02-11',
        pageViews: 142,
        totalSalesAmount: 49975,
        unitsSold: 25
      },
      {
        date: '2024-02-12',
        pageViews: 124,
        totalSalesAmount: 26037,
        unitsSold: 13
      },
      {
        date: '2024-02-13',
        pageViews: 159,
        totalSalesAmount: 31984,
        unitsSold: 16
      },
      {
        date: '2024-02-14',
        pageViews: 246,
        totalSalesAmount: 57971,
        unitsSold: 29
      },
      {
        date: '2024-02-15',
        pageViews: 264,
        totalSalesAmount: 51974,
        unitsSold: 26
      },
      {
        date: '2024-02-16',
        pageViews: 352,
        totalSalesAmount: 53973,
        unitsSold: 27
      },
      {
        date: '2024-02-17',
        pageViews: 358,
        totalSalesAmount: 59970,
        unitsSold: 30
      },
      {
        date: '2024-02-18',
        pageViews: 488,
        totalSalesAmount: 55972,
        unitsSold: 28
      },
      {
        date: '2024-02-19',
        pageViews: 471,
        totalSalesAmount: 63968,
        unitsSold: 32
      },
      {
        date: '2024-02-20',
        pageViews: 540,
        totalSalesAmount: 61969,
        unitsSold: 31
      },
      {
        date: '2024-02-21',
        pageViews: 536,
        totalSalesAmount: 69965,
        unitsSold: 35
      },
      {
        date: '2024-02-22',
        pageViews: 542,
        totalSalesAmount: 77905,
        unitsSold: 39
      },
      {
        date: '2024-02-23',
        pageViews: 507,
        totalSalesAmount: 89899,
        unitsSold: 45
      },
      {
        date: '2024-02-24',
        pageViews: 500,
        totalSalesAmount: 49957,
        unitsSold: 25
      },
      {
        date: '2024-02-25',
        pageViews: 523,
        totalSalesAmount: 67922,
        unitsSold: 34
      },
      {
        date: '2024-02-26',
        pageViews: 1374,
        totalSalesAmount: 117863,
        unitsSold: 59
      },
      {
        date: '2024-02-27',
        pageViews: 2476,
        totalSalesAmount: 177785,
        unitsSold: 89
      },
      {
        date: '2024-02-28',
        pageViews: 2306,
        totalSalesAmount: 167858,
        unitsSold: 84
      },
      {
        date: '2024-02-29',
        pageViews: 2562,
        totalSalesAmount: 125849,
        unitsSold: 61
      },
      {
        date: '2024-03-01',
        pageViews: 2599,
        totalSalesAmount: 165751,
        unitsSold: 83
      },
      {
        date: '2024-03-02',
        pageViews: 2748,
        totalSalesAmount: 149853,
        unitsSold: 75
      },
      {
        date: '2024-03-03',
        pageViews: 2015,
        totalSalesAmount: 139582,
        unitsSold: 70
      },
      {
        date: '2024-03-04',
        pageViews: 5174,
        totalSalesAmount: 289619,
        unitsSold: 145
      },
      {
        date: '2024-03-05',
        pageViews: 7569,
        totalSalesAmount: 423386,
        unitsSold: 212
      },
      {
        date: '2024-03-06',
        pageViews: 7489,
        totalSalesAmount: 411782,
        unitsSold: 206
      },
      {
        date: '2024-03-07',
        pageViews: 8171,
        totalSalesAmount: 523208,
        unitsSold: 262
      },
      {
        date: '2024-03-08',
        pageViews: 8708,
        totalSalesAmount: 555961,
        unitsSold: 282
      },
      {
        date: '2024-03-09',
        pageViews: 9453,
        totalSalesAmount: 547925,
        unitsSold: 275
      },
      {
        date: '2024-03-10',
        pageViews: 9791,
        totalSalesAmount: 527193,
        unitsSold: 266
      },
      {
        date: '2024-03-11',
        pageViews: 9391,
        totalSalesAmount: 429659,
        unitsSold: 215
      },
      {
        date: '2024-03-12',
        pageViews: 9063,
        totalSalesAmount: 551672,
        unitsSold: 276
      },
      {
        date: '2024-03-13',
        pageViews: 8929,
        totalSalesAmount: 496120,
        unitsSold: 243
      },
      {
        date: '2024-03-14',
        pageViews: 9134,
        totalSalesAmount: 774397,
        unitsSold: 384
      },
      {
        date: '2024-03-15',
        pageViews: 10698,
        totalSalesAmount: 860725,
        unitsSold: 434
      },
      {
        date: '2024-03-16',
        pageViews: 8889,
        totalSalesAmount: 598670,
        unitsSold: 302
      },
      {
        date: '2024-03-17',
        pageViews: 10824,
        totalSalesAmount: 577454,
        unitsSold: 289
      },
      {
        date: '2024-03-18',
        pageViews: 8940,
        totalSalesAmount: 543014,
        unitsSold: 272
      },
      {
        date: '2024-03-19',
        pageViews: 8820,
        totalSalesAmount: 457273,
        unitsSold: 229
      },
      {
        date: '2024-03-20',
        pageViews: 8208,
        totalSalesAmount: 641234,
        unitsSold: 321
      },
      {
        date: '2024-03-21',
        pageViews: 8389,
        totalSalesAmount: 565737,
        unitsSold: 283
      },
      {
        date: '2024-03-22',
        pageViews: 8756,
        totalSalesAmount: 634949,
        unitsSold: 318
      },
      {
        date: '2024-03-23',
        pageViews: 8139,
        totalSalesAmount: 507251,
        unitsSold: 254
      },
      {
        date: '2024-03-24',
        pageViews: 7359,
        totalSalesAmount: 510756,
        unitsSold: 256
      }
    ]
  },
  [SAMPLE_CAMPAIGN_PILLOW.skuId]: {
    items: []
  }
}
