import { useBenchmarkContext } from '../../context/BenchmarkContext'
import { Box, Divider, Stack, Typography, Paper, Tab, Tabs } from '@mui/material'
import { METRIC_TYPE_TO_INPUT_STRING } from '../../constants'
import { CumulativeGraph } from './CumulativeGraph'
import { WeeklyGraph } from './WeeklyGraph'
import { SkuSelector } from './SkuSelector'
import { MetricSelector } from './MetricSelector'
import { ReportType } from '@momentum/routes/campaign/context/CampaignContext'
import { GoalSelector } from './GoalSelector'
import Loading from '@momentum/components/loading'
import { useState, createContext, useContext, useEffect, Dispatch, SetStateAction } from 'react'
import { BenchmarkType } from '@productwindtom/shared-momentum-zeus-types'

export type BenchmarkGraphProps = {
  goal: number
  type: string
}

type BenchmarkGraphContextType = {
  metrics: BenchmarkGraphProps
  setMetrics: Dispatch<SetStateAction<BenchmarkGraphProps>>
}

const BenchmarkGraphContext = createContext<BenchmarkGraphContextType>({
  metrics: { goal: 1000, type: BenchmarkType.UNITS_SOLD },
  setMetrics: () => {}
})

const BenchmarkGraph = () => {
  const { graphMetrics, setGraphMetrics, isLoadingData } = useBenchmarkContext()
  const [metrics, setMetrics] = useState<BenchmarkGraphProps>({
    goal: graphMetrics?.metricGoal || 1000,
    type: graphMetrics?.metricType || BenchmarkType.UNITS_SOLD
  })

  if (!graphMetrics) return null

  useEffect(() => {
    setGraphMetrics({...graphMetrics, metricGoal: metrics.goal})
  }, [metrics.goal])

  useEffect(() => {
    setGraphMetrics({...graphMetrics, metricType: metrics.type as BenchmarkType})
  }, [metrics.type])

  return (
    <Paper sx={{ p: 3, backgroundColor: 'grey.A100' }}>
      <Stack direction={'column'} spacing={4}>
        <Stack direction={'row'} alignItems={'center'} gap={3}>
          <Box flexGrow={1}>
            <SkuSelector />
          </Box>
          <BenchmarkGraphContext.Provider value={{ metrics, setMetrics }}>
            <Stack direction={'row'} alignItems={'center'} gap={2}>
              <MetricSelector />
              <GoalSelector />
            </Stack>
          </BenchmarkGraphContext.Provider>
        </Stack>
        <Stack bgcolor={'white'} border={t => `1px solid ${t.palette.grey.A200}`} borderRadius={'4px'}>
          <Stack direction={'row'} p={3} justifyContent={'space-between'}>
          <Typography variant={'label1'}>{METRIC_TYPE_TO_INPUT_STRING[graphMetrics.metricType]}</Typography>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Tabs value={graphMetrics.reportType} onChange={(_, v) => setGraphMetrics({...graphMetrics, reportType: v})} sx={{ minHeight: 0, height: 34 }}>
                <Tab label={'Cumulative'} value={ReportType.CUMULATIVE} sx={{ minHeight: 0, mr: 3 }} />
                <Tab label={'Weekly'} value={ReportType.WEEKLY} sx={{ minHeight: 0, mr: 3 }} />
              </Tabs>
            </Stack>
          </Stack>
          <Divider />
          <Box py={3}>
            {isLoadingData ? (
              <Box sx={{ minHeight: 500, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Loading />
              </Box>
            ) : graphMetrics.reportType === ReportType.CUMULATIVE ? (
              <CumulativeGraph />
            ) : (
              <WeeklyGraph />
            )}
          </Box>
        </Stack>
      </Stack>
    </Paper>
  )
}
export const useBenchmarkGraphContext = () => useContext(BenchmarkGraphContext)

export default BenchmarkGraph
