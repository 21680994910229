import { DateTime } from 'luxon'
import { EventConfig } from '@momentum/utils/eventUtils'
import { Alert, Typography } from '@mui/material'

export const EventDateAlerts = ({
  launchDate,
  eventConfig,
  recommendedLaunchDate,
  durationWeeks
}: {
  launchDate: DateTime
  eventConfig: EventConfig
  recommendedLaunchDate: DateTime
  durationWeeks?: number
}) => {
  if (
    recommendedLaunchDate.toISODate()! === launchDate?.toISODate() &&
    launchDate.plus({ weeks: durationWeeks || 0 }) > eventConfig.eventDate!
  ) {
    return (
      <Alert variant={'outlined'} severity={'warning'} color={'warning'}>
        <Typography color={'black'} variant={'label3'}>
          This campaign cannot be completed by your selected seasonal event. Your creators will continue to join this
          campaign after your event date, therefore this campaign may not be as effective.
        </Typography>
      </Alert>
    )
  } else if (
    recommendedLaunchDate.toISODate()! !== launchDate?.toISODate() &&
    launchDate.plus({ weeks: durationWeeks || 0 }) > eventConfig.eventDate!
  ) {
    return (
      <Alert severity={'warning'} variant={'outlined'}>
        <Typography color={'black'} variant={'label3'}>
          You have edited the campaign start date. Your creators will continue to join this campaign after your event
          date, therefore this campaign may not be as effective. Reset to the recommendation.
        </Typography>
      </Alert>
    )
  } else if (recommendedLaunchDate.toISODate()! !== launchDate?.toISODate()) {
    return (
      <Alert severity={'info'} variant={'outlined'}>
        <Typography color={'black'} variant={'label3'}>
          You have edited the campaign start date. This campaign will drive impact ahead of your event, but the campaign
          may not be as effective.
        </Typography>
      </Alert>
    )
  }
  return null
}
