import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'

export default [
  {
    unitsSold: 3,
    totalSalesAmount: 6444,
    pageViews: 13,
    date: '2024-10-20',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 14,
    date: '2024-10-21',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 2148,
    pageViews: 16,
    date: '2024-10-22',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 2148,
    pageViews: 7,
    date: '2024-10-23',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 4296,
    pageViews: 8,
    date: '2024-10-24',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 2148,
    pageViews: 7,
    date: '2024-10-25',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 6,
    date: '2024-10-26',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 5,
    date: '2024-10-27',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 6,
    date: '2024-10-28',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 13,
    date: '2024-10-29',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 7,
    date: '2024-10-30',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 5,
    date: '2024-10-31',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 8,
    date: '2024-11-01',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 2148,
    pageViews: 8,
    date: '2024-11-02',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 2148,
    pageViews: 8,
    date: '2024-11-03',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 5,
    date: '2024-11-04',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 5,
    date: '2024-11-05',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 7,
    date: '2024-11-06',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 10,
    date: '2024-11-07',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 2148,
    pageViews: 12,
    date: '2024-11-08',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 6,
    date: '2024-11-09',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 6,
    date: '2024-11-10',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 16,
    date: '2024-11-11',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 6444,
    pageViews: 14,
    date: '2024-11-12',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 2148,
    pageViews: 8,
    date: '2024-11-13',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 4296,
    pageViews: 5,
    date: '2024-11-14',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 8,
    date: '2024-11-15',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 3,
    date: '2024-11-16',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 15,
    date: '2024-11-17',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 2148,
    pageViews: 8,
    date: '2024-11-18',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 2,
    date: '2024-11-19',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 2148,
    pageViews: 1,
    date: '2024-11-20',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 7,
    date: '2024-11-21',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 5,
    date: '2024-11-22',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2592,
    pageViews: 3,
    date: '2024-11-23',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 5,
    date: '2024-11-24',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5184,
    pageViews: 5,
    date: '2024-11-25',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1296,
    pageViews: 9,
    date: '2024-11-26',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 6,
    date: '2024-11-27',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 2,
    date: '2024-11-28',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 3,
    date: '2024-11-29',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1296,
    pageViews: 7,
    date: '2024-11-30',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 6,
    date: '2024-12-01',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5184,
    pageViews: 5,
    date: '2024-12-02',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 2,
    date: '2024-12-03',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1296,
    pageViews: 10,
    date: '2024-12-04',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1296,
    pageViews: 2,
    date: '2024-12-05',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 1,
    date: '2024-12-06',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1296,
    pageViews: 1,
    date: '2024-12-07',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 5,
    date: '2024-12-08',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2592,
    pageViews: 10,
    date: '2024-12-09',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 8,
    date: '2024-12-10',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 3,
    date: '2024-12-11',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1296,
    pageViews: 3,
    date: '2024-12-12',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 5,
    date: '2024-12-13',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2592,
    pageViews: 3,
    date: '2024-12-14',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1296,
    pageViews: 4,
    date: '2024-12-15',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 4,
    date: '2024-12-16',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 10,
    date: '2024-12-17',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 6,
    date: '2024-12-18',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 5,
    date: '2024-12-19',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 5,
    date: '2024-12-20',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1296,
    pageViews: 5,
    date: '2024-12-21',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 6,
    date: '2024-12-22',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 4,
    date: '2024-12-23',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 4,
    date: '2024-12-24',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 2,
    date: '2024-12-25',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1296,
    pageViews: 4,
    date: '2024-12-26',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 3,
    date: '2024-12-27',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5184,
    pageViews: 5,
    date: '2024-12-28',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 5,
    date: '2024-12-29',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 7056,
    pageViews: 9,
    date: '2024-12-30',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 9,
    date: '2024-12-31',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 3,
    date: '2025-01-01',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1164,
    pageViews: 2,
    date: '2025-01-02',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 5,
    date: '2025-01-03',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 4,
    date: '2025-01-04',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1164,
    pageViews: 3,
    date: '2025-01-05',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 4656,
    pageViews: 8,
    date: '2025-01-06',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2328,
    pageViews: 9,
    date: '2025-01-07',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1164,
    pageViews: 9,
    date: '2025-01-08',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1164,
    pageViews: 14,
    date: '2025-01-09',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 12,
    date: '2025-01-10',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 7,
    date: '2025-01-11',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 5,
    date: '2025-01-12',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1164,
    pageViews: 8,
    date: '2025-01-13',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2328,
    pageViews: 6,
    date: '2025-01-14',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 7,
    date: '2025-01-15',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 4656,
    pageViews: 4,
    date: '2025-01-16',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 3492,
    pageViews: 12,
    date: '2025-01-17',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2328,
    pageViews: 13,
    date: '2025-01-18',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 11,
    date: '2025-01-19',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 9,
    date: '2025-01-20',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 4656,
    pageViews: 24,
    date: '2025-01-21',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 5820,
    pageViews: 23,
    date: '2025-01-22',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: -1,
    totalSalesAmount: -1164,
    pageViews: 11,
    date: '2025-01-23',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2328,
    pageViews: 16,
    date: '2025-01-24',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 8,
    date: '2025-01-25',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 22,
    date: '2025-01-26',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 4656,
    pageViews: 15,
    date: '2025-01-27',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1164,
    pageViews: 21,
    date: '2025-01-28',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 3492,
    pageViews: 44,
    date: '2025-01-29',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 3492,
    pageViews: 53,
    date: '2025-01-30',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 11640,
    pageViews: 99,
    date: '2025-01-31',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 4656,
    pageViews: 110,
    date: '2025-02-01',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 3492,
    pageViews: 53,
    date: '2025-02-02',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1164,
    pageViews: 24,
    date: '2025-02-03',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 29,
    date: '2025-02-04',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 28,
    date: '2025-02-05',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2328,
    pageViews: 12,
    date: '2025-02-06',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 4656,
    pageViews: 17,
    date: '2025-02-07',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 28,
    date: '2025-02-08',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1164,
    pageViews: 33,
    date: '2025-02-09',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1164,
    pageViews: 28,
    date: '2025-02-10',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1164,
    pageViews: 11,
    date: '2025-02-11',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2328,
    pageViews: 6,
    date: '2025-02-12',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 5820,
    pageViews: 34,
    date: '2025-02-13',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1164,
    pageViews: 14,
    date: '2025-02-14',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1164,
    pageViews: 17,
    date: '2025-02-15',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2328,
    pageViews: 30,
    date: '2025-02-16',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2328,
    pageViews: 12,
    date: '2025-02-17',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 5820,
    pageViews: 25,
    date: '2025-02-18',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2328,
    pageViews: 30,
    date: '2025-02-19',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 4656,
    pageViews: 33,
    date: '2025-02-20',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 3492,
    pageViews: 12,
    date: '2025-02-21',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 11,
    date: '2025-02-22',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1164,
    pageViews: 6,
    date: '2025-02-23',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1164,
    pageViews: 5,
    date: '2025-02-24',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 5,
    date: '2025-02-25',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 25,
    date: '2025-02-26',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: -1,
    totalSalesAmount: -1164,
    pageViews: 13,
    date: '2025-02-27',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1164,
    pageViews: 34,
    date: '2025-02-28',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1164,
    pageViews: 24,
    date: '2025-03-01',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 3492,
    pageViews: 58,
    date: '2025-03-02',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 1164,
    pageViews: 45,
    date: '2025-03-03',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2328,
    pageViews: 33,
    date: '2025-03-04',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 13,
    date: '2025-03-05',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1164,
    pageViews: 11,
    date: '2025-03-06',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 5820,
    pageViews: 25,
    date: '2025-03-07',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 3492,
    pageViews: 15,
    date: '2025-03-08',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2328,
    pageViews: 22,
    date: '2025-03-09',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 8148,
    pageViews: 7,
    date: '2025-03-10',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2328,
    pageViews: 24,
    date: '2025-03-11',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1164,
    pageViews: 44,
    date: '2025-03-12',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 4656,
    pageViews: 186,
    date: '2025-03-13',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 258,
    date: '2025-03-14',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 266,
    date: '2025-03-15',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 3492,
    pageViews: 254,
    date: '2025-03-16',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2328,
    pageViews: 260,
    date: '2025-03-17',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2328,
    pageViews: 272,
    date: '2025-03-18',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 9360,
    pageViews: 235,
    date: '2025-03-19',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2328,
    pageViews: 234,
    date: '2025-03-20',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2340,
    pageViews: 231,
    date: '2025-03-21',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 8172,
    pageViews: 255,
    date: '2025-03-22',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2328,
    pageViews: 297,
    date: '2025-03-23',
    skuId: 'e963f5d4-fe18-46c1-b117-cc75f70e743c'
  }
] as Array<ModelTypes['EtailerProductMetrics']>
