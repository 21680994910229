import * as yup from 'yup'
import { DateTime } from 'luxon'
import { Form, DateInput, SubmitButton } from '@productwindtom/ui-base'
import { yupResolver } from '@hookform/resolvers/yup'
import { Stack, Alert, Typography, Box, Button } from '@mui/material'
import { BackButton } from '@momentum/components/back-button'
import { useFormContext } from 'react-hook-form'
import React, { useState } from 'react'

const schema = yup.object().shape({
  startDate: yup.mixed<DateTime>().required('Required'),
  endDate: yup.mixed<DateTime>().required('Required')
})

type SubscriptionFormData = {
  term?: number
  startDate: DateTime
  endDate: DateTime
}
export const EditSubscriptionForm = ({
  onSubmit,
  onCancel,
  initialValues
}: {
  onCancel: () => void
  onSubmit: (data: SubscriptionFormData) => Promise<void>
  initialValues: SubscriptionFormData
}) => {
  const [isChangeSubscriptionDates, setIsChangeSubscriptionDates] = useState(false)

  const handleSubmit = async (submitValues: SubscriptionFormData) => {
    await onSubmit(submitValues)
  }

  if (!isChangeSubscriptionDates) {
    return (
      <Box>
        <Button
          variant={'text'}
          onClick={() => setIsChangeSubscriptionDates(true)}
          data-cy={'changeSubscriptionDatesButton'}
        >
          Change subscription dates
        </Button>
      </Box>
    )
  }

  return (
    <Form onSubmit={handleSubmit} defaultValues={initialValues} resolver={yupResolver(schema)}>
      <Stack spacing={3}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <DateInput name={'startDate'} primaryText={'Subscription start date'} />
          <DateInput name={'endDate'} primaryText={'Subscription end date'} />
        </Stack>
        {!!initialValues.term && <ExtendingWarning originalTermMonths={initialValues.term} />}
        <Stack direction={'row'} justifyContent={'flex-end'} spacing={1} pt={2}>
          <BackButton variant={'text'} onClick={onCancel} text={'Cancel'} />
          <SubmitButton variant={'contained'} disableOnDirty={false} data-cy={'editSubscriptionSubmitButton'}>
            Save changes
          </SubmitButton>
        </Stack>
      </Stack>
    </Form>
  )
}

const ExtendingWarning = ({ originalTermMonths }: { originalTermMonths: number }) => {
  const { watch } = useFormContext<SubscriptionFormData>()

  const { startDate, endDate } = watch()

  if (startDate && endDate && endDate.diff(startDate, 'months').months > originalTermMonths) {
    return (
      <Alert severity={'warning'} variant={'outlined'} data-cy={'extendingSubscriptionAlert'}>
        <Typography variant={'label3'}>You are extending this subscription.</Typography>
      </Alert>
    )
  }
  return null
}
