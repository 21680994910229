import { useLocation } from 'react-router-dom'
import { ROUTE_NAMES_PROPOSAL } from '@momentum/routes/RouteNames'
import { Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { CheckCircleOutlined, ChevronRight } from '@mui/icons-material'

const progressConfig = [
  { title: 'Add creators' },
  { title: 'Estimated campaign timeline' },
  { title: 'Review campaign', isLast: true }
]

export const StepProgress = () => {
  const { pathname } = useLocation()
  const step = [
    pathname.endsWith(ROUTE_NAMES_PROPOSAL.PRODUCT_CREATORS),
    pathname.endsWith(ROUTE_NAMES_PROPOSAL.TIMELINE),
    pathname.endsWith(ROUTE_NAMES_PROPOSAL.REVIEW),
    pathname.endsWith(ROUTE_NAMES_PROPOSAL.WIN)
  ]
  const indexOfStep = step.findIndex(s => s)

  return (
    <Stack direction={'row'} spacing={2}>
      {progressConfig.map((config, index) => (
        <Stack direction={'row'} key={index} spacing={2} alignItems={'center'}>
          {index >= indexOfStep ? (
            <Box
              alignItems={'center'}
              justifyContent={'center'}
              sx={{
                display: 'flex',
                borderRadius: '50%',
                width: '32px',
                height: '32px',
                color: index === indexOfStep ? 'white' : 'black',
                bgcolor: index === indexOfStep ? 'primary.main' : 'transparent',
                border: theme => (index === indexOfStep ? undefined : `1px solid ${theme.palette.grey.A400}`)
              }}
            >
              <Typography variant={'label1'}>{index + 1}</Typography>
            </Box>
          ) : index < indexOfStep ? (
            <CheckCircleOutlined fontSize={'large'} color={'primary'} />
          ) : (
            <Box
              alignItems={'center'}
              justifyContent={'center'}
              sx={{
                display: 'flex',
                borderRadius: '50%',
                width: '32px',
                height: '32px',
                border: theme => `1px solid ${theme.palette.grey.A400}`
              }}
            >
              <Typography variant={'label1'}>{index + 1}</Typography>
            </Box>
          )}
          <Typography
            variant={index === indexOfStep ? 'label2' : 'label3'}
            color={index <= indexOfStep ? 'primary' : undefined}
          >
            {config.title}
          </Typography>
          {!config.isLast && <ChevronRight />}
        </Stack>
      ))}
    </Stack>
  )
}
