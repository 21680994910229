import { BrandApiType, ModelTypes } from '@productwindtom/shared-momentum-zeus-types'

export const BRAND_API_TYPE_LABELS: Record<BrandApiType, string> = {
  [BrandApiType.amazon_na_vendor]: 'Amazon vendor',
  [BrandApiType.amazon_na_seller]: 'Amazon seller',
  [BrandApiType.amazon_eu_vendor]: 'Amazon vendor',
  [BrandApiType.amazon_eu_seller]: 'Amazon seller',
  [BrandApiType.amazon_fe_vendor]: 'Amazon vendor',
  [BrandApiType.amazon_fe_seller]: 'Amazon seller',
  [BrandApiType.shopify]: 'Shopify'
}

const VENDOR_API_TYPES = [BrandApiType.amazon_na_vendor, BrandApiType.amazon_eu_vendor]
const SELLER_API_TYPES = [BrandApiType.amazon_na_seller, BrandApiType.amazon_eu_seller]

export const getSellerBrandApi = <T extends Pick<ModelTypes['BrandApi'], 'enabled' | 'api'>>(
  brandApis?: T[]
): T | undefined => brandApis?.find(brandApi => brandApi.enabled && SELLER_API_TYPES.includes(brandApi.api))

export const getVendorBrandApi = <T extends Pick<ModelTypes['BrandApi'], 'enabled' | 'api'>>(
  brandApis?: T[]
): T | undefined => brandApis?.find(brandApi => brandApi.enabled && VENDOR_API_TYPES.includes(brandApi.api))
