import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { useReviewsContext, ReviewMetric } from '@momentum/routes/campaign/e-commerce/reviewsV2/reviewsContext'
import { defaultTooltipOptions } from '@momentum/utils/tooltipUtils'
import { Box, Divider, Stack, Typography, useTheme, Tabs, Tab, Tooltip } from '@mui/material'
import 'chart.js/auto'
import 'chartjs-adapter-luxon'
import { DateTime } from 'luxon'
import { Line } from 'react-chartjs-2'
import CampaignLaunchImage from '/images/campaign-launch.png'
import CampaignEndedImage from '/images/calendar-check.png'
import { first, last, sum } from 'lodash'
import { ArrowUpward, InfoOutlined } from '@mui/icons-material'
import { useState } from 'react'

enum GraphViewType {
  CUMULATIVE = 'Cumulative',
  WEEKLY = 'Weekly'
}

const convertDataToWeekly = (data: ReviewMetric[]) => {
  const firstDataRecord = first(data)
  const lastDataRecord = last(data)

  if (!firstDataRecord || !lastDataRecord) {
    return []
  }

  const firstDate = DateTime.fromISO(firstDataRecord.date)
  const lastDate = DateTime.fromISO(lastDataRecord.date)
  const weeks = Math.ceil(lastDate.diff(firstDate, 'weeks').weeks || 1)
  const weeksArray = new Array(weeks).fill(0)

  return weeksArray.map((_, index) => {
    const start = firstDate.plus({ weeks: index })
    const end = start.plus({ weeks: 1 })
    const startString = start.toISODate()!
    const endString = end.toISODate()!
    const weekData = data.filter(d => d.date >= startString && d.date <= endString)
    return {
      date: startString,
      ratingsInPeriod: sum(weekData.map(d => d.ratingsInPeriod)),
      averageRatingsInPeriod: sum(weekData.map(d => d.averageRatingsInPeriod))
    }
  })
}

export const ReviewsGraph = () => {
  const [graphViewType, setGraphViewType] = useState(GraphViewType.CUMULATIVE)

  const theme = useTheme()
  const {
    campaignDetails: { startDate, endDate }
  } = useCampaignContext()
  const { filteredReviewData } = useReviewsContext()

  const startDateDateTime = DateTime.fromISO(startDate)
  const startDateNoTime = startDateDateTime.toISODate()
  const endDateDateTime = endDate ? DateTime.fromISO(endDate) : undefined
  const endDateNoTime = endDateDateTime?.toISODate()

  const summaryTotalRatings =
    (last(filteredReviewData)?.cumulativeRatings || 0) - (first(filteredReviewData)?.cumulativeRatings || 0)
  const summaryTotalAverageRatings =
    (last(filteredReviewData)?.cumulativeAverageRatings || 0) -
    (first(filteredReviewData)?.cumulativeAverageRatings || 0)

  const totalRatings = filteredReviewData.map(r => r.cumulativeRatings)
  const totalAverageRatings = filteredReviewData.map(r => r.cumulativeAverageRatings)

  const weeklyData = convertDataToWeekly(filteredReviewData)
  const weeklyLabels = weeklyData.map(d => d.date)
  const weeklyTotalRatings = weeklyData.map(d => d.ratingsInPeriod)
  const weeklyTotalAverageRatings = weeklyData.map(d => d.averageRatingsInPeriod)

  const dailyLabels = filteredReviewData.map(d => d.date)
  const labels = graphViewType === GraphViewType.WEEKLY ? weeklyLabels : dailyLabels

  const startDateIndex = labels.findIndex(v => v === startDateNoTime)
  const endDateIndex = endDateNoTime && labels.findIndex(v => v === endDateNoTime)
  const totalDatasetIndex = 0

  const tickDefinition = labels.map((_, index) => (labels.length < 15 ? 1 : index % 4 === 0 ? 1 : 0))

  const rocketImage = new Image(24, 24)
  rocketImage.src = CampaignLaunchImage

  const completedImage = new Image(24, 24)
  completedImage.src = CampaignEndedImage

  const momentumDataset = [
    {
      borderColor: theme.palette.info.main,
      pointBorderColor: theme.palette.info.main,
      pointBackgroundColor: theme.palette.info.main,
      data: graphViewType === GraphViewType.WEEKLY ? weeklyTotalRatings : totalRatings,
      pointStyle: totalRatings.map((_, i) =>
        i === startDateIndex ? rocketImage : i === endDateIndex ? completedImage : undefined
      )
    },
    {
      borderColor: theme.palette.error.light,
      pointBorderColor: theme.palette.error.light,
      pointBackgroundColor: theme.palette.error.light,
      borderDash: [10, 12],
      borderCapStyle: 'round' as any,
      data: graphViewType === GraphViewType.WEEKLY ? weeklyTotalAverageRatings : totalAverageRatings
    }
  ]

  return (
    <Stack spacing={3}>
      <Stack direction={'row'} flexWrap={'wrap'} gap={2}>
        <Stack
          direction={'row'}
          bgcolor={'white'}
          p={2}
          spacing={'8%'}
          border={t => `1px solid ${t.palette.grey.A200}`}
          borderRadius={'4px'}
          flexGrow={1}
        >
          <Stack direction={'row'} spacing={2} alignItems={'center'}>
            <Box width={8} bgcolor={'info.main'} borderRadius={100} height={75} />
            <Stack>
              <Stack direction={'row'} spacing={1}>
                <Typography variant={'h3'}>{summaryTotalRatings.toLocaleString()}</Typography>
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  color={'success.main'}
                  bgcolor={'rgba(208, 241, 225, 0.40)'}
                  spacing={0.5}
                  px={1}
                  py={0.5}
                  borderRadius={30}
                >
                  <ArrowUpward fontSize={'mSmall'} />
                  <Typography variant={'label3'}>
                    +{(summaryTotalRatings - summaryTotalAverageRatings).toLocaleString()} ratings!
                  </Typography>
                </Stack>
              </Stack>
              <Typography variant={'label3'}>Reviews & ratings in period</Typography>
            </Stack>
          </Stack>
          <Stack direction={'row'} spacing={2} alignItems={'center'}>
            <Box width={8} bgcolor={'error.light'} borderRadius={100} height={75} />
            <Stack>
              <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <Typography variant={'h3'}>{summaryTotalAverageRatings.toLocaleString()}</Typography>
                <Tooltip
                  title={
                    <Typography variant={'body1'}>
                      This is your average review rate <b>without</b> a ProductWind campaign.
                    </Typography>
                  }
                >
                  <InfoOutlined sx={{ color: theme => theme.palette.grey.A700 }} />
                </Tooltip>
              </Stack>
              <Typography variant={'label3'}>Predicted reviews & ratings in period</Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          bgcolor={'white'}
          p={3}
          border={t => `1px solid ${t.palette.grey.A200}`}
          borderRadius={'4px'}
          spacing={2}
        >
          <Stack direction={'row'} spacing={2} alignItems={'flex-start'}>
            <Stack p={0.5} bgcolor={t => t.palette.grey.A100} borderRadius={'50%'}>
              <Box
                component="img"
                src={CampaignLaunchImage}
                width={24}
                height={24}
              />
            </Stack>
            <Stack>
              <Typography variant={'label2'}>Campaign start</Typography>
              <Typography variant={'label3'} color={'grey.A700'}>
                {startDateDateTime.toLocaleString(DateTime.DATE_MED)}
              </Typography>
            </Stack>
          </Stack>
          {!!endDateDateTime && (
            <Stack direction={'row'} spacing={2} alignItems={'flex-start'}>
              <Stack p={0.5} bgcolor={t => t.palette.grey.A100} borderRadius={'50%'}>
              <Box
                component="img"
                src={CampaignEndedImage}
                width={24}
                height={24}
              />
              </Stack>
              <Stack>
                <Typography variant={'label2'}>Campaign end</Typography>
                <Typography variant={'label3'} color={'grey.A700'}>
                  {endDateDateTime.toLocaleString(DateTime.DATE_MED)}
                </Typography>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
      <Stack bgcolor={'white'} border={t => `1px solid ${t.palette.grey.A200}`} borderRadius={'4px'}>
        <Stack direction={'row'} p={3} justifyContent={'space-between'}>
          <Typography variant={'label1'}>Reviews & ratings</Typography>
          <Tabs value={graphViewType} onChange={(_, v) => setGraphViewType(v)} sx={{ minHeight: 0, height: 34 }}>
            <Tab label={'Cumulative'} value={GraphViewType.CUMULATIVE} sx={{ minHeight: 0, mr: 3 }} />
            <Tab label={'Weekly'} value={GraphViewType.WEEKLY} sx={{ minHeight: 0, mr: 3 }} />
          </Tabs>
        </Stack>
        <Divider />
        <Box py={3}>
          <Line
            data={{
              labels: labels,
              datasets: momentumDataset.map(d => ({
                ...d,
                borderWidth: 8,
                cubicInterpolationMode: 'monotone'
              }))
            }}
            height={500}
            options={{
              borderColor: theme.palette.grey.A400,
              elements: {
                point: {
                  radius: value => {
                    return value.datasetIndex === totalDatasetIndex &&
                      (value.dataIndex === startDateIndex || value.dataIndex === endDateIndex)
                      ? 10
                      : 0
                  },
                  hitRadius: 10,
                  drawActiveElementsOnTop: true
                }
              },
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false
                },
                tooltip: {
                  ...defaultTooltipOptions,
                  callbacks: {
                    label: item => {
                      if (item.datasetIndex === totalDatasetIndex && item.dataIndex === startDateIndex) {
                        return ['Campaign launch!', startDateDateTime.toLocaleString(DateTime.DATE_SHORT)]
                      }
                      if (
                        endDateDateTime &&
                        item.datasetIndex === totalDatasetIndex &&
                        item.dataIndex === endDateIndex
                      ) {
                        return ['Campaign complete!', endDateDateTime.toLocaleString(DateTime.DATE_SHORT)]
                      }
                      return `${item.formattedValue} reviews & ratings`
                    },
                    title: () => '',
                    footer: () => ''
                  }
                }
              },
              scales: {
                y: {
                  display: true,
                  beginAtZero: false,
                  grid: {
                    drawOnChartArea: true,
                    drawTicks: false,
                    color: theme.palette.grey.A200,
                    lineWidth: 1
                  },
                  offset: true,
                  ticks: {
                    font: {
                      family: theme.typography.fontFamily as string,
                      weight: theme.typography.fontWeightRegular as number,
                      size: theme.typography.label1.fontSize as number
                    },
                    color: 'black',
                    padding: 16
                  },
                  border: {
                    width: 1,
                    color: theme.palette.grey.A200
                  }
                },
                x: {
                  type: 'time',
                  ticks: {
                    source: 'labels',
                    minRotation: 60,
                    padding: 16,
                    font: (ctx, options) => {
                      const date = DateTime.fromMillis(ctx.tick.value).toISODate()
                      return {
                        family: theme.typography.fontFamily as string,
                        weight: date === startDateNoTime ? 800 : (theme.typography.fontWeightRegular as number),
                        size: theme.typography.label1.fontSize as number
                      }
                    },
                    color: value => {
                      const date = DateTime.fromMillis(value.tick.value).toISODate()
                      return date === startDateNoTime ? theme.palette.primary.main : 'black'
                    }
                  },
                  time: {
                    unit: 'day',
                    tooltipFormat: 'LL/dd',
                    displayFormats: {
                      day: 'LL/dd'
                    }
                  },
                  grid: {
                    drawOnChartArea: true,
                    drawTicks: false,
                    color: theme.palette.grey.A200,
                    lineWidth: tickDefinition
                  },
                  border: {
                    width: 1,
                    color: theme.palette.grey.A200
                  }
                }
              }
            }}
          />
        </Box>
      </Stack>
    </Stack>
  )
}
