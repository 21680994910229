import { retry } from '@lifeomic/attempt'
import { useSubscriptionContext } from '@momentum/contexts/Subscription'
import { Product as BrandProduct, Campaign } from '@momentum/routes/brand/types'
import { getStoreFromRegionRetailer } from '@momentum/utils/storeUtils'
import { getCampaignProductCost } from '@productwindtom/shared-momentum'
import { ScrapeRequestStatus } from '@productwindtom/shared-momentum-zeus-types'
import { notEmpty } from '@productwindtom/shared-node'
import { toCurrencyStringCents } from '@productwindtom/shared-ws-currency'
import { captureException } from '@sentry/react'
import { debounce, maxBy, uniqBy } from 'lodash'
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useBrandContext } from '../../context/BrandContext'
import { isAddProductCallback } from '../../context/types'
import { createManualProduct, requestCreateProductFromUrl, updateManualProduct } from '../../mutations'
import { ScrapeRequestJob, getProductWithVariations, getScrapeRequestJob } from './queries'
import {
  AddLiveProductForm,
  AddPlaceholderProductForm,
  AddProductForm,
  AddProductState,
  LiveProduct,
  isChildProduct,
  isParentProduct
} from './types'
import { parseEtailerProductUrl } from '@productwindtom/shared-etailers'

interface AddProductContextState {
  initialProduct: BrandProduct | undefined
  scrapedProduct: LiveProduct | undefined // scraped product from URL
  selectedProduct: LiveProduct | undefined // product variation that the campaign will use (child if only one variation is selected, parent otherwise)
  selectableVariations: LiveProduct[] | undefined // flat list of all variations (parent + children) for the scraped product
  isCallbackLoading: boolean
  addProduct: (isProductLive: boolean, data: AddProductForm) => Promise<void>
  addProductState?: AddProductState
  campaign?: Campaign
  hasManualAdd: boolean
  highestProductPriceCents?: number
  creditsRequired?: number
  hasRequiredCredits: boolean
}

export const AddProductProvider = ({
  children,
  initialProduct
}: {
  children: React.ReactNode
  initialProduct?: LiveProduct
}) => {
  const [addProductState, setAddProductState] = useState<AddProductState>()
  const [scrapedProduct, setScrapedProduct] = useState<LiveProduct>()
  const [isCallbackLoading, setIsCallbackLoading] = useState(false)

  const { brand, setProducts, isAddProductActiveOrCallback, setIsAddProductActiveOrCallback, campaigns } =
    useBrandContext()
  const { creditsRemaining } = useSubscriptionContext()

  const addProductExecutionCount = useRef<number>(0)
  const currentSkuScrape = useRef<string | undefined>()
  const createProductFromUrlCache = useRef<{
    [key: string]: Promise<LiveProduct | undefined>
  }>({})

  const { watch, setValue, getFieldState } = useFormContext<AddProductForm>()

  const productId = watch('productId')
  const productVariationSkus = watch('productVariationSkus')
  const isProductLive = watch('isProductLive') === 'true'
  const hasManualAdd =
    !isAddProductCallback(isAddProductActiveOrCallback) || !isAddProductActiveOrCallback?.disableManual
  const initialProductPrice = isAddProductCallback(isAddProductActiveOrCallback)
    ? isAddProductActiveOrCallback?.productPriceOverride || initialProduct?.priceCents
    : undefined

  useEffect(() => {
    addProductExecutionCount.current++
    setScrapedProduct(undefined)
  }, [isProductLive, addProductExecutionCount])

  useEffect(() => {
    if (scrapedProduct) {
      setValue('productId', scrapedProduct.id)
    }
  }, [scrapedProduct])

  const selectableVariations = useMemo(() => {
    if (scrapedProduct) {
      return uniqBy(
        [
          scrapedProduct,
          ...(isParentProduct(scrapedProduct) ? scrapedProduct.childSkus.items : []),
          ...(isChildProduct(scrapedProduct)
            ? [scrapedProduct.parentSku, ...(scrapedProduct.parentSku?.childSkus.items ?? [])]
            : [])
        ].filter(notEmpty),
        p => p.id
      )
    }
  }, [scrapedProduct])

  const selectedProduct = useMemo(() => {
    if (productId) {
      return selectableVariations?.find(p => p.id === productId)
    }
  }, [productId, selectableVariations])

  const campaign = useMemo(() => {
    return isAddProductCallback(isAddProductActiveOrCallback)
      ? campaigns?.find(c => c.id === isAddProductActiveOrCallback?.campaignId)
      : undefined
  }, [isAddProductActiveOrCallback, campaigns])

  const highestProductPriceCents = useMemo(() => {
    return maxBy(
      selectableVariations?.filter(p => p.skuId && productVariationSkus?.includes(p.skuId)),
      p => p.priceCents
    )?.priceCents
  }, [selectableVariations, productVariationSkus])

  const creditsRequired = useMemo(() => {
    if (campaign && initialProductPrice && highestProductPriceCents) {
      const initialProductCost = getCampaignProductCost(
        campaign.numCreators,
        initialProductPrice,
        campaign.proposal?.exchangeRate
      )

      const newProductCost = getCampaignProductCost(
        campaign.numCreators,
        highestProductPriceCents,
        campaign.proposal?.exchangeRate
      )

      return newProductCost - initialProductCost
    }
  }, [initialProduct, highestProductPriceCents, campaign])

  useEffect(() => {
    if (creditsRequired !== undefined) {
      const { isTouched } = getFieldState('creditsSpendAgreed')
      const requiresAgreement = creditsRequired > 0
      if (!isTouched && !requiresAgreement) {
        // do not require agreement if no credits are required
        setValue('creditsSpendAgreed', true, {
          shouldValidate: true,
          shouldDirty: false,
          shouldTouch: false
        })
      }
    }
  }, [creditsRequired])

  const hasRequiredCredits = useMemo(() => {
    return !creditsRequired || creditsRequired <= creditsRemaining
  }, [creditsRequired, creditsRemaining])

  // Handlers
  const handleProductUrlChange = useCallback(
    debounce(async (productUrl: string) => {
      const cleanProductUrl = productUrl.split('?')?.[0]
      const productSkuUrlParsed = parseEtailerProductUrl(cleanProductUrl)
      const productSku = productSkuUrlParsed?.sku

      if (productSku && productSku === currentSkuScrape.current) {
        return
      }

      currentSkuScrape.current = productSku

      let product: LiveProduct | undefined

      const currentExecution = addProductExecutionCount.current
      const brandId = brand.id

      if (!productSku) {
        if (currentExecution === addProductExecutionCount.current) {
          return setAddProductState({
            status: ScrapeRequestStatus.ERROR,
            message: 'The product URL is not a valid product page. Enter a different URL or contact customer success.'
          })
        } else {
          return
        }
      }

      try {
        setAddProductState({
          status: ScrapeRequestStatus.IN_PROGRESS,
          message: `We're searching for your product! This could take up to 30 seconds.`
        })

        // Cache the promise to prevent duplicate calls
        const existingProductPromise = createProductFromUrlCache.current[productSku]

        if (!existingProductPromise) {
          createProductFromUrlCache.current[productSku] = scrapeProduct({
            brandId,
            productUrl
          })
        }

        product = await createProductFromUrlCache.current[productSku]
      } catch (err: any) {
        console.error('Error adding live product', err)
        captureException(err, {
          data: {
            productUrl
          }
        })
        const error = err?.errors?.[0] ?? err
        if (error?.errorType === 'ERROR') {
          setAddProductState({
            status: ScrapeRequestStatus[error.message as keyof typeof ScrapeRequestStatus] || ScrapeRequestStatus.ERROR,
            message: error.message || 'Your product was not found. Enter a different URL or contact customer success.'
          })

          return
        } else if (err) {
          setAddProductState({
            status: ScrapeRequestStatus.ERROR,
            message: err.message || `Your product was not found. Enter a different URL or contact customer success.`
          })
        }
      }

      if (currentExecution === addProductExecutionCount.current) {
        if (product) {
          const productWithVariations = await getProductWithVariations(product.id)
          const variations = [
            productWithVariations,
            productWithVariations?.parentSku,
            ...(productWithVariations?.parentSku?.childSkus?.items || []),
            ...(productWithVariations?.childSkus?.items || [])
          ].filter(notEmpty)

          setProducts(currentProducts => uniqBy([...variations, ...(currentProducts || [])], p => p.id))

          if (product.priceCents) {
            setAddProductState({
              status: ScrapeRequestStatus.SUCCESS
            })
            setScrapedProduct(productWithVariations)
          } else {
            setAddProductState({
              status: ScrapeRequestStatus.ERROR,
              message: `We have found your product, but it does not have a price. Please update the price on the retailer or add a placeholder product for this proposal.`
            })
          }
        }
      }
    }, 750),
    [brand.id, setProducts]
  )

  const scrapeProduct = async ({
    brandId,
    productUrl
  }: {
    brandId: string
    productUrl: string
  }): Promise<LiveProduct | undefined> => {
    const requestResp = await requestCreateProductFromUrl({
      brandId,
      productUrl
    })

    if (requestResp?.skuId) {
      return await getProductWithVariations(requestResp.skuId)
    } else if (requestResp && requestResp.id) {
      const pollingResponse = await retry(
        async () => {
          const scrapeJob = (await getScrapeRequestJob(requestResp!.id!))!
          if (scrapeJob.status === ScrapeRequestStatus.IN_PROGRESS) {
            throw new Error('Still in progress')
          }

          if (scrapeJob.status === ScrapeRequestStatus.SUCCESS && scrapeJob.skuId) {
            return await getProductWithVariations(scrapeJob.skuId)
          }

          if (scrapeJob.status === ScrapeRequestStatus.ERROR) {
            return scrapeJob
          }
        },
        {
          delay: 5000,
          maxAttempts: 10
        }
      )
      if ((pollingResponse as ScrapeRequestJob)?.status === ScrapeRequestStatus.ERROR) {
        throw new ScrapeError(
          (pollingResponse as ScrapeRequestJob).message ||
            'Your product was not found. Enter a different URL or contact customer success.'
        )
      }
      return pollingResponse as LiveProduct | undefined
    }
  }

  useEffect(() => {
    const watchSub = watch((value, { name }) => {
      if (name && ['isProductLive', 'productUrl'].includes(name)) {
        if (value.isProductLive === 'true' && value.productUrl) {
          handleProductUrlChange(value.productUrl)
        }
      }
    })

    return () => watchSub.unsubscribe()
  }, [watch, handleProductUrlChange])

  const handleAddLiveProduct = async (_data: AddLiveProductForm) => {
    if (selectedProduct) {
      isAddProductCallback(isAddProductActiveOrCallback) &&
        (await isAddProductActiveOrCallback.callback({
          campaignId: isAddProductActiveOrCallback.campaignId,
          product: selectedProduct,
          selectedVariantIds: productVariationSkus ?? undefined,
          creditsRequired: creditsRequired
        }))
      setIsAddProductActiveOrCallback(false)
    }
  }

  const handleAddPlaceholderProduct = async (data: AddPlaceholderProductForm) => {
    try {
      setAddProductState({
        status: ScrapeRequestStatus.IN_PROGRESS,
        message: `We're creating your product!`
      })

      const region = brand.region
      const brandId = brand.id

      const { productId, productTitle: title, productImage, retailer, price } = data

      const productInput = {
        id: productId!,
        store: getStoreFromRegionRetailer(region, retailer),
        name: title,
        price: toCurrencyStringCents(price, region),
        image: productImage
      }

      // Check if this is an update to an existing product
      const product = await (initialProduct
        ? updateManualProduct(productInput)
        : createManualProduct({
            ...productInput,
            brandId
          }))

      if (product) {
        setAddProductState({
          status: ScrapeRequestStatus.SUCCESS
        })
        setProducts(currentProducts => uniqBy([product!, ...(currentProducts || [])], 'id'))

        isAddProductCallback(isAddProductActiveOrCallback) &&
          (await isAddProductActiveOrCallback.callback({
            product
          }))
        setIsAddProductActiveOrCallback(false)
      }
    } catch (err) {
      console.log('err', err)
      setAddProductState({
        status: ScrapeRequestStatus.ERROR,
        message: `Your product was not found. Enter a different URL or contact customer success.`
      })
    }
  }

  const addProduct = async (isProductLive: boolean, data: AddProductForm) => {
    try {
      addProductExecutionCount.current++
      setIsCallbackLoading(true)

      if (data.isProductLive === 'true') {
        await handleAddLiveProduct(data)
      } else {
        await handleAddPlaceholderProduct(data)
      }

      setScrapedProduct(undefined)
    } catch (err) {
      console.log('err', err)
    } finally {
      setIsCallbackLoading(false)
    }
  }

  return (
    <AddProductContext.Provider
      value={{
        initialProduct,
        scrapedProduct,
        selectableVariations,
        selectedProduct,
        isCallbackLoading,
        addProduct,
        addProductState,
        campaign,
        hasManualAdd,
        highestProductPriceCents,
        creditsRequired,
        hasRequiredCredits
      }}
    >
      {children}
    </AddProductContext.Provider>
  )
}

const AddProductContext = createContext<AddProductContextState | undefined>(undefined)

// Custom hook to use the product context
export const useAddProductContext = (): AddProductContextState => {
  const context = useContext(AddProductContext)

  if (context === undefined) {
    throw new Error('useAddProductContext must be used within an AddProductProvider')
  }

  return context
}

class ScrapeError extends Error {
  constructor(message: string) {
    super(message)
  }
}
