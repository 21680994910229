import { Stack } from "@mui/material";
import { useSubscriptionContext } from '@momentum/contexts/Subscription'
import { SubscriptionCreditAction } from "@momentum/routes/queries";
import { groupBy } from 'lodash'
import { Typography, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { CreditAction, InvoiceStatus } from '@productwindtom/shared-momentum-zeus-types'
import { useState } from 'react'
import { DateTime } from 'luxon'
import { Filters } from "./Filters";
import { Row } from "./Row";

export const actionsToShow = [
  CreditAction.ADD,
  CreditAction.BUY_CREDITS,
  CreditAction.REMOVE,
]
export const purchaseActions = [
  CreditAction.BUY_CREDITS,
  CreditAction.ADD,
]

export enum ActionStatus {
  SUCCESS = 'SUCCESS',
  AWAITING_PAYMENT = 'AWAITING_PAYMENT',
  PAYMENT_OVERDUE = 'PAYMENT_OVERDUE',
}

export const itemActionStatus = (status?: InvoiceStatus, date?: string) => {
  if (!status && !date) return ActionStatus.SUCCESS
  else if (status === InvoiceStatus.NOT_PAID && date && DateTime.fromISO(date) < DateTime.now()) {
    return ActionStatus.PAYMENT_OVERDUE
  } else if (status === InvoiceStatus.NOT_PAID && date && DateTime.fromISO(date) > DateTime.now()) {
    return ActionStatus.AWAITING_PAYMENT
  } else {
    return ActionStatus.SUCCESS
  }
}

export const SubscriptionTable = () => {
  const { subscriptionCreditActions } = useSubscriptionContext()
  const [year, setYear] = useState<number | null>(null)
  const [status, setStatus] = useState<ActionStatus | null>(null)
  const [action, setAction] = useState<CreditAction | null>(null)

  const actionFilter = (item: SubscriptionCreditAction) => {
    if (!actionsToShow.includes(item.action)) return false
    if (action) {
      if (action === CreditAction.ADD && purchaseActions.includes(item.action)) return true
      return item.action === action
    }
    return true
  }

  const statusFilter = (item: SubscriptionCreditAction) => {
    const actionStatus = itemActionStatus(item.invoiceStatus || InvoiceStatus.PAID, item.invoiceDueDate || '')
    if (status === null) return true
    return (actionStatus === status)
  }

  const existingAddCreditsAction = (item: SubscriptionCreditAction) => {
    return subscriptionCreditActions.some(action => action.invoiceId === item.invoiceId && action.action === CreditAction.ADD)
  }

  const filteredSubscriptionCreditActions = subscriptionCreditActions
    .filter(item => {
      if (item.action === CreditAction.BUY_CREDITS && existingAddCreditsAction(item)) return false
      if (year && DateTime.fromISO(item.createdAt).year !== year) return false
      if (status && !statusFilter(item)) return false
      return actionFilter(item)
    })
    .sort((a, b) => DateTime.fromISO(b.createdAt).toMillis() - DateTime.fromISO(a.createdAt).toMillis())

  const availableYears = [...new Set(subscriptionCreditActions.map(action => DateTime.fromISO(action.createdAt).year))]
  const groupedByMonth = groupBy(filteredSubscriptionCreditActions, item => DateTime.fromISO(item.createdAt).toFormat('MMMM yyyy'))

  const actionBalances: Record<string, number> = {}
  let runningBalance = 0
  
  subscriptionCreditActions
    .sort((a, b) => DateTime.fromISO(a.createdAt).toMillis() - DateTime.fromISO(b.createdAt).toMillis())
    .forEach((actionItem) => {
      runningBalance += actionItem.numCredits * (
        actionItem.action === CreditAction.REMOVE ? -1 : actionItem.action === CreditAction.ADD ? 1 : 0
      )
      actionBalances[actionItem.actionId] = runningBalance
    })

  return (
    <Stack spacing={3}>
      <Filters availableYears={availableYears} year={year} status={status} action={action} setYear={setYear} setStatus={setStatus} setAction={setAction} />
      <Table sx={{border: theme => `1px solid ${theme.palette.grey.A200}`, borderRadius: '4px', borderCollapse: 'separate'}}>
        <TableHead>
          <TableRow>
            <TableCell><Typography variant="label2" sx={{ pl: 2 }}>Date</Typography></TableCell>
            <TableCell><Typography variant="label2">Activity</Typography></TableCell>
            <TableCell><Typography variant="label2">Status</Typography></TableCell>
            <TableCell><Typography variant="label2">Amount</Typography></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(groupedByMonth).map(([month, items]) => (
            <Row key={month} month={month} items={items} actionBalances={actionBalances} />
          ))}
        </TableBody>
      </Table>
    </Stack>
  )
}

export default SubscriptionTable
