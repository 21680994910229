import { BenchmarkType, ModelTypes } from '@productwindtom/shared-momentum-zeus-types'
import { SAMPLE_CAMPAIGN_WIRELESS_EARBUDS } from '../../getCampaign/sample-data/wireless-earbuds'

export const SAMPLE_CAMPAIGN_BENCHMARK_WIRELESS_EARBUDS: ModelTypes['CampaignBenchmark'] = {
  skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId,
  campaignId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id,
  benchmarkedProductIds: [
    SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId,
    '1ff3c70c-108c-4a6e-a5ce-f73256e9109e',
    'c39486ce-efb1-4368-b608-8e2bfc51be0a',
    'ef977bea-87d4-4c54-8b65-f701c972b257'
  ],
  benchmarkGoal: 10000,
  benchmarkType: BenchmarkType.REVENUE,
  createdAt: '2021-07-01T00:00:00.000Z',
  updatedAt: '2021-07-01T00:00:00.000Z'
}
