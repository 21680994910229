import { SvgIcon, SvgIconProps } from '@mui/material'

export default function PriceIncreaseIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 22 20" fill="none">
      <path
        id="Subtract"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.52332 4.56066C3.80463 4.84196 4.18616 5 4.58398 5C4.98181 5 5.36334 4.84196 5.64464 4.56066C5.92595 4.27936 6.08398 3.89782 6.08398 3.5C6.08398 3.10218 5.92595 2.72064 5.64464 2.43934C5.36334 2.15804 4.98181 2 4.58398 2C4.18616 2 3.80463 2.15804 3.52332 2.43934C3.24202 2.72064 3.08398 3.10218 3.08398 3.5C3.08398 3.89782 3.24202 4.27936 3.52332 4.56066ZM11.494 0.58L20.494 9.58C20.854 9.94 21.084 10.44 21.084 11C21.084 11.55 20.864 12.05 20.494 12.41L13.494 19.41C13.134 19.77 12.634 20 12.084 20C11.534 20 11.034 19.77 10.664 19.41L9.16779 17.9121C9.53366 17.7897 9.87749 17.5828 10.1688 17.2915C11.1739 16.2864 11.1739 14.6568 10.1688 13.6517L6.05108 9.53399C5.046 8.52891 3.41645 8.52891 2.41137 9.53399L1.60546 10.3399C1.27698 9.98642 1.08398 9.51514 1.08398 9V2C1.08398 0.89 1.97398 0 3.08398 0H10.084C10.634 0 11.134 0.22 11.494 0.58Z"
        fill="#4668EE"
      />
      <path
        id="Vector"
        d="M1 14.8906L4.43166 11.459M4.43166 11.459L7.86333 14.8906M4.43166 11.459V18.5977"
        stroke="#4668EE"
        strokeWidth="1.71583"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}
