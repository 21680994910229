import { Stack, Typography, Select, MenuItem } from '@mui/material'
import { useSalesContext } from './salesContext'
import { SkuSelectionType } from '@momentum/routes/campaign/context/CampaignContext'

export const SalesSkuSelector = () => {
  const { salesSkuType, setSalesSkuType } = useSalesContext()
  
  return (
    <Stack direction={'column'} spacing={2} sx={{ width: '100%', maxWidth: 470 }}>
      <Typography variant={'label1'}>Select sales data view</Typography>
      <Select
        data-cy={`salesSkuSelector`}
        value={salesSkuType}
        onChange={e => setSalesSkuType(e.target.value as SkuSelectionType)}
        sx={{ minWidth: 200 }}
      >
        <MenuItem
          value={SkuSelectionType.SELECTED_VARIATIONS }
          data-cy={`salesSelectedVariantsMenuItem`}
        >
          Selected variations promoted in campaign
        </MenuItem>
        <MenuItem
          value={SkuSelectionType.ENTIRE_LISTING}
          data-cy={`salesEntireListingMenuItem`}
        >
          Entire listing
        </MenuItem>
      </Select>
    </Stack>
  )
}
