import React from 'react'
import { useTheme, useMediaQuery } from '@mui/material'

export type LayoutContextType = {
  isMobile: boolean
  sidebarOpen: boolean
  setSidebarOpen: (open: boolean) => void
}

const LayoutContext = React.createContext<LayoutContextType>({
  isMobile: false,
  sidebarOpen: false,
  setSidebarOpen: () => {}
})

export const LayoutProvider = ({ children }: { children?: React.ReactNode }) => {
  const [sidebarOpen, setSidebarOpen] = React.useState(false)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

  return <LayoutContext.Provider value={{ isMobile, sidebarOpen, setSidebarOpen }}>{children}</LayoutContext.Provider>
}

export const useLayoutContext = () => React.useContext(LayoutContext)
