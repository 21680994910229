import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { Stack, Typography } from '@mui/material'
import { getCampaignSeoMetrics } from '@productwindtom/shared-momentum'
import { Metric } from '../../common/Metric'
import { useSeoContext } from '../context'

export const SponsoredRankSummary = () => {
  const { productSeoSummary, productKeywordSummaries } = useSeoContext()
  const { campaignDetails } = useCampaignContext()
  const { sponsoredPageOneWinsSum, sponsoredImprovedSlotsSum } = getCampaignSeoMetrics(
    campaignDetails.startDate,
    productSeoSummary,
    productKeywordSummaries
  )

  return (
    <Stack spacing={3}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Stack direction={'row'} spacing={2} alignItems={'center'} width={'100%'}>
          <Typography variant={'h4'}>Sponsored rank summary</Typography>
        </Stack>
      </Stack>
      <Stack direction={'row'} justifyContent={'space-evenly'}>
        <Metric label={'sponsored page 1 wins'} value={sponsoredPageOneWinsSum.toLocaleString() ?? '--'} />
        <Metric label={'improved slots in sponsored rank'} value={sponsoredImprovedSlotsSum.toLocaleString() ?? '--'} />
      </Stack>
    </Stack>
  )
}
