import { SvgIcon, SvgIconProps } from '@mui/material'
import { ReactNode } from 'react'

export default function MegaphoneIcon(props: SvgIconProps): ReactNode {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
      <path
        d="M9 17C9 17 16 18 19 21H20C20.2652 21 20.5196 20.8946 20.7071 20.7071C20.8946 20.5196 21 20.2652 21 20V13.937C21.4298 13.8265 21.8106 13.5762 22.0825 13.2255C22.3544 12.8749 22.502 12.4437 22.502 12C22.502 11.5563 22.3544 11.1251 22.0825 10.7745C21.8106 10.4238 21.4298 10.1735 21 10.063V4C21 3.73478 20.8946 3.48043 20.7071 3.29289C20.5196 3.10536 20.2652 3 20 3H19C16 6 9 7 9 7H5C4.46957 7 3.96086 7.21071 3.58579 7.58579C3.21071 7.96086 3 8.46957 3 9V15C3 15.5304 3.21071 16.0391 3.58579 16.4142C3.96086 16.7893 4.46957 17 5 17H6L7 22H9V17ZM11 8.661C11.683 8.515 12.527 8.311 13.44 8.044C15.118 7.55 17.25 6.773 19 5.574V18.425C17.25 17.227 15.117 16.45 13.44 15.956C12.6345 15.7206 11.8207 15.5152 11 15.34V8.661ZM5 9H9V15H5V9Z"
        fill="currentColo"
      />
    </SvgIcon>
  )
}
