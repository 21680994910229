import { yupResolver } from '@hookform/resolvers/yup'
import { DebouncedTextField } from '@momentum/components/debounced-text-field'
import Loading from '@momentum/components/loading'
import { DataGrid } from '@momentum/components/table'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { Search } from '@mui/icons-material'
import EditIcon from '@mui/icons-material/Edit'
import SortIcon from '@mui/icons-material/Sort'
import { Button, Stack, Typography } from '@mui/material'
import { gridClasses } from '@mui/x-data-grid-pro'
import { PageOneWinType } from '@productwindtom/shared-momentum-zeus-types'
import { Form, SubmitButton } from '@productwindtom/ui-base'
import { maxBy, orderBy } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { SeoReportMode, useSeoContext } from '../../context'
import { UploadSeoData } from '../upload'
import { KeywordRowType, KeywordWin, KeywordWinType, useColumnDefinitions } from './utils'

type Keyword = {
  keyword: string
  searchVolume?: number | null
  organicPageOneWin?: boolean | null
  organicPageOneWinDate?: string | null
  organicPageOneWinType?: PageOneWinType | null
  sponsoredPageOneWin?: boolean | null
  sponsoredPageOneWinDate?: string | null
  sponsoredPageOneWinType?: PageOneWinType | null
  organicStartRank?: number | null
  organicHighestRank?: number | null
  sponsoredStartRank?: number | null
  sponsoredHighestRank?: number | null
  hasAmazonChoiceBadge?: boolean | null
  isClientAdded: boolean
  hasUploadedData: boolean
}

type KeywordTableForm = {
  keywords: Keyword[]
}

const schema = yup
  .object({
    keywords: yup
      .array()
      .of(
        yup
          .object({
            keyword: yup.string().required(),
            searchVolume: yup.number().notRequired(),
            organicPageOneWin: yup.boolean().notRequired(),
            organicPageOneWinDate: yup.string().notRequired(),
            organicPageOneWinType: yup.string().oneOf(Object.values(PageOneWinType)).notRequired(),
            sponsoredPageOneWin: yup.boolean().notRequired(),
            sponsoredPageOneWinDate: yup.string().notRequired(),
            sponsoredPageOneWinType: yup.string().oneOf(Object.values(PageOneWinType)).notRequired(),
            organicStartRank: yup.number().notRequired(),
            organicHighestRank: yup.number().notRequired(),
            sponsoredStartRank: yup.number().notRequired(),
            sponsoredHighestRank: yup.number().notRequired(),
            hasAmazonChoiceBadge: yup.boolean().notRequired(),
            isClientAdded: yup.boolean().required(),
            hasUploadedData: yup.boolean().required()
          })
          .required()
          .noUnknown(true)
      )
      .required()
  })
  .required()

export const KeywordTable = () => {
  const { productKeywordSummaries, updateProductKeywordSummaries, isUpdating, seoReportMode, setSearch, search } =
    useSeoContext()
  const [isEditMode, setIsEditMode] = useState(false)

  const { isAdminView } = useUserSessionContext()

  const defaultValues: KeywordTableForm = useMemo(
    () => ({
      keywords: productKeywordSummaries ?? []
    }),
    [productKeywordSummaries]
  )

  if (!productKeywordSummaries) {
    return <Loading />
  }

  const handleSubmit = async (data: KeywordTableForm) => {
    try {
      await updateProductKeywordSummaries(
        data.keywords.filter(k => k.hasUploadedData).map(({ isClientAdded, hasUploadedData, ...update }) => update)
      )
    } catch (e) {
      console.error(e)
      toast(<Typography variant={'subtitle2'}>Unknown issue, please try again later</Typography>, { type: 'error' })
    } finally {
      setIsEditMode(false)
    }
  }

  return (
    <Form onSubmit={handleSubmit} defaultValues={defaultValues} resolver={yupResolver(schema)}>
      <Stack spacing={3}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} spacing={2}>
          <DebouncedTextField
            value={search}
            onChange={setSearch}
            placeholder={'Search keywords'}
            sx={{ flex: 1 }}
            InputProps={{
              endAdornment: <Search />
            }}
          />
          <Stack direction={'row'} spacing={3} justifyContent={'end'}>
            {isAdminView && (
              <>
                {!!productKeywordSummaries.length && (
                  <Button
                    variant={'contained'}
                    startIcon={<EditIcon />}
                    onClick={() => setIsEditMode(true)}
                    disabled={isEditMode || isUpdating}
                    sx={{ alignSelf: 'end' }}
                  >
                    Edit search terms
                  </Button>
                )}
                <UploadSeoData />
              </>
            )}
          </Stack>
        </Stack>
        <KeywordTableBody isEditMode={isEditMode} />
      </Stack>
    </Form>
  )
}

const getKeywordWins = (
  pageOneWin?: boolean | null,
  pageOneWinDate?: string | null,
  pageOneWinType?: PageOneWinType | null,
  highestRank?: number | null,
  startRank?: number | null
): KeywordWin[] => {
  const keywordWins: KeywordWin[] = []
  if (pageOneWin) {
    const type =
      pageOneWinType === PageOneWinType.MAINTAINED
        ? KeywordWinType.PAGE_ONE_WIN_MAINTAINED
        : KeywordWinType.PAGE_ONE_WIN
    keywordWins.push({
      type,
      date: type === KeywordWinType.PAGE_ONE_WIN && pageOneWinDate ? pageOneWinDate : undefined // only show date for new page one wins
    })
  }
  const slotsImproved = highestRank && startRank && highestRank <= startRank ? Math.abs(highestRank - startRank) : 0
  if (slotsImproved > 100) {
    keywordWins.push({
      type: KeywordWinType.RANK_SURGE
    })
  }

  return keywordWins
}

const KeywordTableBody = ({ isEditMode }: { isEditMode: boolean }) => {
  const { campaignDetails } = useCampaignContext()
  const { productKeywordSummaries, seoReportMode, search } = useSeoContext()

  const { watch, reset } = useFormContext<KeywordTableForm>()

  const columns = useColumnDefinitions()

  useEffect(() => {
    reset({
      keywords: productKeywordSummaries ?? []
    })
  }, [productKeywordSummaries, seoReportMode])

  const keywords = watch('keywords')

  const rows: KeywordRowType[] = useMemo(() => {
    const lowerSearch = search?.toLowerCase().trim()
    return orderBy(
      keywords
        .filter(k => !lowerSearch || k.keyword.toLowerCase().includes(lowerSearch))
        .map((k, idx) => {
          const organicStartRank = k.organicStartRank || 0
          const organicHighestRank = k.organicHighestRank || 0
          const sponsoredStartRank = k.sponsoredStartRank || 0
          const sponsoredHighestRank = k.sponsoredHighestRank || 0

          return {
            id: k.keyword,
            trend: productKeywordSummaries?.find(s => s.keyword === k.keyword)?.records ?? [],
            keyword: k.keyword,
            searchVolume: k.searchVolume || 0,
            organicKeywordWins: getKeywordWins(
              k.organicPageOneWin,
              k.organicPageOneWinDate,
              k.organicPageOneWinType,
              k.organicHighestRank,
              k.organicStartRank
            ),
            sponsoredKeywordWins: getKeywordWins(
              k.sponsoredPageOneWin,
              k.sponsoredPageOneWinDate,
              k.sponsoredPageOneWinType,
              k.sponsoredHighestRank,
              k.sponsoredStartRank
            ),
            organicStartRank,
            organicHighestRank,
            sponsoredStartRank,
            sponsoredHighestRank,
            organicSlotsImproved:
              organicHighestRank && organicStartRank && organicHighestRank <= organicStartRank
                ? Math.abs(organicHighestRank - organicStartRank)
                : 0,
            sponsoredSlotsImproved:
              sponsoredHighestRank && sponsoredStartRank && sponsoredHighestRank <= sponsoredStartRank
                ? Math.abs(sponsoredHighestRank - sponsoredStartRank)
                : 0,
            hasAmazonChoiceBadge: k.hasAmazonChoiceBadge || false,
            isEditMode,
            idx,
            isClientAdded: k.isClientAdded,
            hasUploadedData: k.hasUploadedData
          }
        }) ?? [],
      [
        k =>
          seoReportMode === SeoReportMode.ORGANIC
            ? maxBy(k.organicKeywordWins, kw => kw.type)?.type ?? 0
            : maxBy(k.sponsoredKeywordWins, kw => kw.type)?.type ?? 0,
        k => k.searchVolume
      ],
      ['desc', 'desc']
    )
  }, [keywords, isEditMode, search])

  return (
    <Stack spacing={3}>
      <DataGrid
        autoHeight
        rows={rows}
        columns={columns}
        disableColumnMenu={true}
        disableColumnReorder={true}
        pagination
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } }
        }}
        pageSizeOptions={[10, 25, 50]}
        sx={{
          [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
            outline: 'none'
          },
          [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
            outline: 'none'
          }
        }}
        slots={{
          columnSortedAscendingIcon: SortIcon,
          columnSortedDescendingIcon: (props: any) => (
            <SortIcon
              {...props}
              sx={{
                transform: 'scale(1, -1)'
              }}
            />
          )
        }}
      />

      {isEditMode && (
        <Stack direction={'row'} spacing={2} mt={2}>
          <SubmitButton variant={'contained'}>Save</SubmitButton>
        </Stack>
      )}
    </Stack>
  )
}
