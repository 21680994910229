import { SAMPLE_CAMPAIGN_LAUNDRY } from '@momentum/api/interceptors/getCampaign/sample-data/laundry'
import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'

export default [
  {
    unitsSold: 1,
    totalSalesAmount: 1516,
    pageViews: 12,
    date: '2025-02-12',
    skuId: SAMPLE_CAMPAIGN_LAUNDRY.skuId
  },
  {
    unitsSold: 2,
    totalSalesAmount: 3032,
    pageViews: 104,
    date: '2025-02-13',
    skuId: SAMPLE_CAMPAIGN_LAUNDRY.skuId
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1516,
    pageViews: 194,
    date: '2025-02-14',
    skuId: SAMPLE_CAMPAIGN_LAUNDRY.skuId
  },
  {
    unitsSold: 2,
    totalSalesAmount: 3032,
    pageViews: 68,
    date: '2025-02-15',
    skuId: SAMPLE_CAMPAIGN_LAUNDRY.skuId
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1516,
    pageViews: 55,
    date: '2025-02-16',
    skuId: SAMPLE_CAMPAIGN_LAUNDRY.skuId
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 37,
    date: '2025-02-17',
    skuId: SAMPLE_CAMPAIGN_LAUNDRY.skuId
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 51,
    date: '2025-02-18',
    skuId: SAMPLE_CAMPAIGN_LAUNDRY.skuId
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 53,
    date: '2025-02-19',
    skuId: SAMPLE_CAMPAIGN_LAUNDRY.skuId
  },
  {
    unitsSold: 2,
    totalSalesAmount: 3032,
    pageViews: 52,
    date: '2025-02-20',
    skuId: SAMPLE_CAMPAIGN_LAUNDRY.skuId
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 26,
    date: '2025-02-21',
    skuId: SAMPLE_CAMPAIGN_LAUNDRY.skuId
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 7,
    date: '2025-02-22',
    skuId: SAMPLE_CAMPAIGN_LAUNDRY.skuId
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 2,
    date: '2025-02-23',
    skuId: SAMPLE_CAMPAIGN_LAUNDRY.skuId
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 15,
    date: '2025-02-24',
    skuId: SAMPLE_CAMPAIGN_LAUNDRY.skuId
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1516,
    pageViews: 16,
    date: '2025-02-25',
    skuId: SAMPLE_CAMPAIGN_LAUNDRY.skuId
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 20,
    date: '2025-02-26',
    skuId: SAMPLE_CAMPAIGN_LAUNDRY.skuId
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1392,
    pageViews: 30,
    date: '2025-02-27',
    skuId: SAMPLE_CAMPAIGN_LAUNDRY.skuId
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4176,
    pageViews: 24,
    date: '2025-02-28',
    skuId: SAMPLE_CAMPAIGN_LAUNDRY.skuId
  },
  {
    unitsSold: 0,
    totalSalesAmount: 1392,
    pageViews: 14,
    date: '2025-03-01',
    skuId: SAMPLE_CAMPAIGN_LAUNDRY.skuId
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 7,
    date: '2025-03-02',
    skuId: SAMPLE_CAMPAIGN_LAUNDRY.skuId
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2784,
    pageViews: 26,
    date: '2025-03-03',
    skuId: SAMPLE_CAMPAIGN_LAUNDRY.skuId
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 22,
    date: '2025-03-04',
    skuId: SAMPLE_CAMPAIGN_LAUNDRY.skuId
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1392,
    pageViews: 23,
    date: '2025-03-05',
    skuId: SAMPLE_CAMPAIGN_LAUNDRY.skuId
  },
  {
    unitsSold: 12,
    totalSalesAmount: 16704,
    pageViews: 44,
    date: '2025-03-06',
    skuId: SAMPLE_CAMPAIGN_LAUNDRY.skuId
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5568,
    pageViews: 837,
    date: '2025-03-07',
    skuId: SAMPLE_CAMPAIGN_LAUNDRY.skuId
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2784,
    pageViews: 215,
    date: '2025-03-08',
    skuId: SAMPLE_CAMPAIGN_LAUNDRY.skuId
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2784,
    pageViews: 155,
    date: '2025-03-09',
    skuId: SAMPLE_CAMPAIGN_LAUNDRY.skuId
  },
  {
    unitsSold: 18,
    totalSalesAmount: 25056,
    pageViews: 304,
    date: '2025-03-10',
    skuId: SAMPLE_CAMPAIGN_LAUNDRY.skuId
  },
  {
    unitsSold: 15,
    totalSalesAmount: 20880,
    pageViews: 317,
    date: '2025-03-11',
    skuId: SAMPLE_CAMPAIGN_LAUNDRY.skuId
  },
  {
    unitsSold: 19,
    totalSalesAmount: 26448,
    pageViews: 473,
    date: '2025-03-12',
    skuId: SAMPLE_CAMPAIGN_LAUNDRY.skuId
  },
  {
    unitsSold: 26,
    totalSalesAmount: 36192,
    pageViews: 506,
    date: '2025-03-13',
    skuId: SAMPLE_CAMPAIGN_LAUNDRY.skuId
  }
] as Array<ModelTypes['EtailerProductMetrics']>
