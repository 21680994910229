import { StoreName } from '@productwindtom/shared-campaign'

type ParsedEtailerProductUrl = { store: StoreName; sku: string }

export const parseEtailerProductUrl = (url: string): ParsedEtailerProductUrl | null => {
  try {
    const parsedUrl = new URL(url)
    const hostname = parsedUrl.hostname
    const pathname = parsedUrl.pathname

    if (hostname.includes('amazon.com')) {
      const match = pathname.match(/\/dp\/([A-Z0-9]+)/i)
      return match ? { store: StoreName.amazon, sku: match[1].toUpperCase() } : null
    } else if (hostname.includes('amazon.ca')) {
      const match = pathname.match(/\/dp\/([A-Z0-9]+)/i)
      return match ? { store: StoreName.amazon_ca, sku: match[1].toUpperCase() } : null
    } else if (hostname.includes('amazon.co.uk')) {
      const match = pathname.match(/\/dp\/([A-Z0-9]+)/i)
      return match ? { store: StoreName.amazon_uk, sku: match[1].toUpperCase() } : null
    } else if (hostname.includes('amazon.de')) {
      const match = pathname.match(/\/dp\/([A-Z0-9]+)/i)
      return match ? { store: StoreName.amazon_de, sku: match[1].toUpperCase() } : null
    } else if (hostname.includes('bestbuy.com')) {
      const match = pathname.match(/\/site\/[^/]+\/(\d+)\.p/)
      return match ? { store: StoreName.bestBuy, sku: match[1] } : null
    } else if (hostname.includes('walmart.com')) {
      const match = pathname.match(/\/ip\/[^/]+\/(\d+)/)
      return match ? { store: StoreName.walmart, sku: match[1] } : null
    } else if (hostname.includes('target.com')) {
      const match = pathname.match(/\/p\/(?:[^/]+\/)?-\/A-(\d+)/)
      return match ? { store: StoreName.target, sku: match[1] } : null
    } else if (hostname.includes('costco.com')) {
      const match = pathname.match(/(?:[^/]+\/)?\.product\.(\d+)\.html$/)
      return match ? { store: StoreName.costco, sku: match[1] } : null
    }
  } catch (error) {
    console.error('Invalid URL', error)
  }
  return null
}
