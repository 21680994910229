import { RoutesBreadcrumb } from '@momentum/components/routes-breadcrumb-v2'
import { useSubscriptionContext } from '@momentum/contexts/Subscription'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { useClientBreadcrumbs } from '@momentum/hooks/useClientBreadcrumbs'
import { NotSubscribed } from '@momentum/routes/subscription/NotSubscribed'
import { SubscriptionEnded } from '@momentum/routes/subscription/SubscriptionEnded'
import {
  Box,
  Stack,
  Typography
} from '@mui/material'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { Header } from './Header'
import { Actions } from './Actions'
import { SubscriptionTable } from './table'
import Loading from '@momentum/components/loading'
import { SubscriptionV1 } from './SubscriptionV1'
import { useFlag } from '@unleash/proxy-client-react'

export const Subscription = () => {
  const { selectedCompany, selectedBrand, isAdminView } = useUserSessionContext()
  const {
    getSubscriptionStatus,
    creditsRemaining,
    startsAt,
    endsAt
  } = useSubscriptionContext()

  const newSubscriptionPageFlag = useFlag('NewSubscriptionPage')

  const { isActive, hasStarted, hasSubscription } = getSubscriptionStatus()
  const crumbs = useClientBreadcrumbs(selectedCompany, selectedBrand)


  useEffect(() => {
    if (isActive && creditsRemaining === 0) {
      toast(<Typography variant={'subtitle2'}>You are out of credits!</Typography>, { type: 'warning' })
    }
  }, [])

  if (!selectedBrand || !selectedCompany) {
    return <Loading />
  }

  if (!newSubscriptionPageFlag) {
    return <SubscriptionV1 />
  }

  return (
    <Stack py={3}>
      {isAdminView && (
        <Box mb={1}>
          <RoutesBreadcrumb crumbs={crumbs} />
        </Box>
      )}

      <Stack spacing={3}>
        <Header />
        {!isActive && hasStarted && endsAt && <SubscriptionEnded endDate={endsAt} />}
        {isActive && <Actions />}
        {hasSubscription && <SubscriptionTable />}
      </Stack>
      {!isActive && !startsAt && <NotSubscribed />}
    </Stack>
  )
}

