import { Close, EditOutlined } from '@mui/icons-material'
import { Dialog, Stack, Typography, IconButton, Button, Alert } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import React, { useState } from 'react'
import { DateTime } from 'luxon'
import { toast } from 'react-toastify'
import { useFlag } from '@unleash/proxy-client-react'
import { EditSubscriptionForm } from './EditSubscriptionForm'
import { ActivateSubscriptionForm } from '@momentum/routes/subscription/subscription-bar/ActivateSubscriptionForm'
import { useSubscriptionContext } from '@momentum/contexts/Subscription'

export const SubscriptionBar = () => {
  const [isActivateOpen, setIsActivateOpen] = useState(false)
  const [isEditOpen, setIsEditOpen] = useState(false)
  const [isDeactivateOpen, setIsDeactivateOpen] = useState(false)
  const [isDeactivateLoading, setIsDeactivateLoading] = useState(false)

  const adminEditSubscriptionFlag = useFlag('AdminEditSubscription')

  const {
    name,
    getSubscriptionStatus,
    termMonths,
    startsAt: startDateTimeObj,
    endsAt: endDateTimeObj,
    updateSubscription
  } = useSubscriptionContext()

  const { isActive, hasSubscription, hasStarted, hasEnded } = getSubscriptionStatus()

  const handleActivateSubmit = async (startDate: DateTime, endDate: DateTime, termMonths?: number) => {
    await updateSubscription(
      startDate.setZone('America/Los_Angeles', { keepLocalTime: true }).startOf('day').toJSDate().toISOString(),
      endDate.setZone('America/Los_Angeles', { keepLocalTime: true }).startOf('day').toJSDate().toISOString(),
      termMonths
    )
    toast(<Typography variant={'subtitle2'}>Your subscription is now active!</Typography>, { type: 'success' })
    handleActivateClose()
  }

  const handleActivateClose = () => {
    setIsActivateOpen(false)
  }

  const handleEditSubmit = async (startDate: DateTime, endDate: DateTime) => {
    await updateSubscription(
      startDate.setZone('America/Los_Angeles', { keepLocalTime: true }).startOf('day').toJSDate().toISOString(),
      endDate.setZone('America/Los_Angeles', { keepLocalTime: true }).startOf('day').toJSDate().toISOString()
    )
    toast(<Typography variant={'subtitle2'}>Your subscription is now active!</Typography>, { type: 'success' })
    handleEditClose()
  }

  const handleEditClose = () => {
    setIsEditOpen(false)
  }

  const handleDeactivateSubmit = async () => {
    setIsDeactivateLoading(true)
    await updateSubscription(undefined, new Date().toISOString())
    toast(<Typography variant={'subtitle2'}>Your subscription has been deactivated!</Typography>, { type: 'success' })
    setIsDeactivateLoading(false)
    handleDeactivateClose()
  }

  const handleDeactivateClose = () => {
    setIsDeactivateOpen(false)
  }

  return (
    <Stack spacing={2} alignItems={'flex-end'} data-cy={'subscriptionBar'}>
      <Stack direction={'row'} spacing={2} alignItems={'center'}>
        {(!hasSubscription || hasEnded) && (
          <Button variant={'outlined'} onClick={() => setIsActivateOpen(true)} data-cy={'activateSubscriptionButton'}>
            Activate subscription
          </Button>
        )}
        {(isActive || (hasSubscription && !hasStarted)) && (
          <Button
            variant={'outlined'}
            onClick={() => setIsDeactivateOpen(true)}
            data-cy={'deactivateSubscriptionButton'}
          >
            Deactivate subscription
          </Button>
        )}
        {hasSubscription && !hasEnded && (
          <Button
            variant={'text'}
            onClick={() => setIsEditOpen(true)}
            startIcon={<EditOutlined />}
            data-cy={'editSubscriptionButton'}
          >
            Edit subscription
          </Button>
        )}
      </Stack>

      <Dialog open={isActivateOpen} onClose={handleActivateClose}>
        <Stack p={3} spacing={2}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant={'h4'}>Activate subscription for {name}</Typography>
            <IconButton onClick={handleActivateClose}>
              <Close />
            </IconButton>
          </Stack>
          <ActivateSubscriptionForm
            name={name}
            onSubmit={d => handleActivateSubmit(d.startDate, d.endDate, d.term)}
            onCancel={handleActivateClose}
          />
        </Stack>
      </Dialog>

      <Dialog open={isEditOpen} onClose={handleEditClose}>
        <Stack p={3} spacing={2}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant={'h4'}>Edit subscription for {name}</Typography>
            <IconButton onClick={handleEditClose}>
              <Close />
            </IconButton>
          </Stack>
          <Typography variant={'label3'}>
            {name} users will have access to the Momentum dashboard effective immediately.
          </Typography>
          {startDateTimeObj && endDateTimeObj && (
            <Stack>
              {termMonths && (
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                  <Typography variant={'label1'}>Subscription activated:</Typography>
                  <Typography variant={'label3'}>{termMonths} month term</Typography>
                </Stack>
              )}
              <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <Typography variant={'label1'}>Subscription dates:</Typography>
                <Typography variant={'label3'}>
                  {startDateTimeObj.toLocaleString(DateTime.DATE_SHORT)} -{' '}
                  {endDateTimeObj.toLocaleString(DateTime.DATE_SHORT)}
                </Typography>
              </Stack>
            </Stack>
          )}
          {!adminEditSubscriptionFlag && (
            <Alert severity={'info'} variant={'outlined'} data-cy={'contactSuperAdminAlert'}>
              <Typography variant={'label3'}>Contact Jason or Tim to edit this subscription</Typography>
            </Alert>
          )}

          {adminEditSubscriptionFlag && startDateTimeObj && endDateTimeObj && (
            <EditSubscriptionForm
              onSubmit={d => handleEditSubmit(d.startDate, d.endDate)}
              onCancel={handleEditClose}
              initialValues={{ startDate: startDateTimeObj, endDate: endDateTimeObj, term: termMonths }}
            />
          )}
        </Stack>
      </Dialog>

      <Dialog open={isDeactivateOpen} onClose={handleDeactivateClose}>
        <Stack p={3} spacing={2}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant={'h4'}>Are you sure you want to deactivate your subscription for {name}?</Typography>
            <IconButton onClick={handleDeactivateClose}>
              <Close />
            </IconButton>
          </Stack>
          <Typography variant={'label3'}>
            {name} users will no longer have access to the Momentum dashboard effective immediately. The subscription
            end will be set to {DateTime.now().toLocaleString(DateTime.DATE_MED)}.
          </Typography>
          <Stack direction={'row'} justifyContent={'flex-end'} spacing={2} pt={2}>
            <Button variant={'text'} onClick={handleDeactivateClose}>
              No, go back
            </Button>
            <LoadingButton variant={'contained'} loading={isDeactivateLoading} onClick={handleDeactivateSubmit}>
              Yes, I want to deactivate
            </LoadingButton>
          </Stack>
        </Stack>
      </Dialog>
    </Stack>
  )
}
