import { Link, Stack, Typography, Box } from '@mui/material'
import { DateTime } from 'luxon'
import NoSubscriptionImage from '@momentum/routes/subscription/NoSubscription.png'

export const SubscriptionEnded = ({ endDate }: { endDate: DateTime }) => {
  return (
    <Stack alignItems={'center'} spacing={4} py={2}>
      <img src={NoSubscriptionImage} alt={'You are not subscribed'} width={144} />
      <Stack alignItems={'center'} spacing={1}>
        <Typography variant={'h4'}>Your subscription ended on {endDate.toLocaleString(DateTime.DATE_SHORT)}</Typography>
        <Stack direction={'column'} alignItems={'center'} spacing={0}>
          <Typography variant={'label3'}>
            Contact Momentum to resubscribe
          </Typography>
          <Link href={'mailto:sales@productwind.com'} variant={'label3'} target={'_blank'}>
            sales@productwind.com
          </Link>
        </Stack>
      </Stack>
    </Stack>
  )
}
