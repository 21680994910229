import CloseButton from '@momentum/components/close-button'
import Row from '@momentum/components/row'
import VideoOrImage from '@momentum/components/video-or-image'
import { Product } from '@momentum/routes/brand/types'
import { StoreToIcon } from '@momentum/utils/storeIcons'
import { OpenInNewOutlined } from '@mui/icons-material'
import { Box, Chip, Link, Paper, Stack, Typography } from '@mui/material'
import { BenchmarkType, STORE_TO_LOCALE } from '@productwindtom/shared-momentum-zeus-types'
import { toCurrencyStringCents } from '@productwindtom/shared-ws-currency'
import { get } from 'lodash'
import { useMemo } from 'react'
import { BENCHMARK_PRODUCT_COLORS, BENCHMARK_TYPE_TO_GOAL_STRING, BENCHMARK_TYPE_TO_VALUE } from '../constants'
import { useBenchmarkContext } from '../context/BenchmarkContext'

const BenchmarkProductCard = ({
  product: { id, image, name, priceCents, store, listingLink },
  index
}: {
  product: Product
  index: number
}) => {
  const {
    promotedProductId,
    enabledBenchmarkProducts,
    setEnabledBenchmarkProducts,
    setGraphMetrics,
    graphMetrics,
    benchmarkData
  } = useBenchmarkContext()

  const isPromotedProduct = promotedProductId === id

  const { metricType } = graphMetrics ?? {}
  const metricGoal =
    metricType === BenchmarkType.REVENUE
      ? `$${graphMetrics?.metricGoal.toLocaleString()}`
      : graphMetrics?.metricGoal.toLocaleString()

  const daysToGoal = useMemo(() => {
    if (!graphMetrics || !metricType) {
      return
    }
    const metricKey = BENCHMARK_TYPE_TO_VALUE[metricType]
    const metrics = benchmarkData[id] ?? []
    let sum = 0
    const goal = metricType === BenchmarkType.REVENUE ? graphMetrics.metricGoal * 100 : graphMetrics.metricGoal
    const daysToGoal = metrics.findIndex(metric => {
      if (metric[metricKey] === undefined) return false
      sum += metric[metricKey] as number
      return sum >= goal
    })
    return daysToGoal >= 0 ? daysToGoal : undefined
  }, [metricType, benchmarkData, id, graphMetrics])

  if (!graphMetrics || !metricType || !benchmarkData[id]) return null

  return (
    <Paper
      onClick={() => {
        !isPromotedProduct &&
          setEnabledBenchmarkProducts(
            enabledBenchmarkProducts.includes(id)
              ? enabledBenchmarkProducts.filter(pId => pId !== id)
              : [...enabledBenchmarkProducts, id]
          )
      }}
      elevation={0}
      sx={{
        cursor: !isPromotedProduct ? 'pointer' : 'default',
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'stretch',
        ...((isPromotedProduct || enabledBenchmarkProducts.includes(id)) && {
          borderColor: theme => theme.palette.primary.dark,
          borderWidth: 2,
          borderRadius: '4px'
        })
      }}
    >
      <Stack direction={'column'} spacing={1} alignItems={'center'} padding={1.5} flex={1}>
        <Stack
          width="100%"
          position="relative"
          alignItems="center"
          sx={{
            borderRadius: '4px',
            border: '1px solid #E0E0E0'
          }}
        >
          <VideoOrImage src={image} height={100} />
          {isPromotedProduct ? (
            <Chip
              size={'small'}
              sx={theme => ({
                position: 'absolute',
                top: 4,
                left: 4,
                bgcolor: get(theme.palette, BENCHMARK_PRODUCT_COLORS[index]),
                color: () => {
                  const colorPath = BENCHMARK_PRODUCT_COLORS[index].split('.')
                  colorPath.pop()
                  return get(theme.palette, `${colorPath}.contrastText`)
                }
              })}
              label="Promoted"
            />
          ) : (
            <Typography
              variant="body1"
              fontWeight={800}
              sx={theme => ({
                position: 'absolute',
                top: 4,
                left: 4,
                border: `2px solid ${theme.palette.grey.A200}`,
                padding: '4px 10px',
                borderRadius: 2
              })}
            >
              {index}
            </Typography>
          )}
          {!isPromotedProduct && (
            <CloseButton
              iconButtonProps={{
                size: 'small',
                sx: {
                  color: 'white',
                  height: 4,
                  width: 4,
                  position: 'absolute',
                  top: 6,
                  right: 6,
                  backgroundColor: '#BDBDBD',
                  borderRadius: 50,
                  padding: 1
                },
                onClick: () => {
                  setGraphMetrics({
                    ...graphMetrics!,
                    benchmarkedProductIds: graphMetrics!.benchmarkedProductIds.filter((item: string) => item !== id)
                  })
                }
              }}
              iconProps={{
                sx: { fontSize: 'medium' }
              }}
            />
          )}
        </Stack>
        <Stack direction={'row'} spacing={1} alignItems={'flex-start'} width={'100%'} height={'100%'}>
          <Box
            width={8}
            bgcolor={theme => get(theme.palette, BENCHMARK_PRODUCT_COLORS[index])}
            borderRadius={100}
            height={'100%'}
          />
          <Stack spacing={0.5} alignItems={'flex-start'} flex={1} width={'100%'}>
            <Row spacing={1}>
              {StoreToIcon[store]({ fontSize: 'small' })}
              {priceCents != null && (
                <Typography variant="caption1" color="grey.A700">
                  {toCurrencyStringCents(priceCents, STORE_TO_LOCALE[store])}
                </Typography>
              )}
            </Row>
            <Stack direction={'row'} alignItems={'flex-start'} spacing={1} width="100%">
              <Typography
                variant="caption2"
                sx={{
                  fontWeight: 500,
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  minHeight: '30px',
                  flex: 1
                }}
              >
                {name}
              </Typography>
              <Link href={listingLink} target={'_blank'} display={'inline-flex'} sx={{ flexShrink: 0 }}>
                <OpenInNewOutlined fontSize={'mSmall'} color={'primary'} />
              </Link>
            </Stack>
            {!!BENCHMARK_TYPE_TO_GOAL_STRING[metricType] && (
              <Typography sx={{ fontWeight: 800, minHeight: '40px', width: '100%' }} variant="label3">
                {`${daysToGoal || '--'} days to ${metricGoal?.toLocaleString()} ${BENCHMARK_TYPE_TO_GOAL_STRING[metricType].toLowerCase()}`}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  )
}

export default BenchmarkProductCard
