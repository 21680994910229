import { yupResolver } from '@hookform/resolvers/yup'
import { PricingForm, schema } from '@momentum/components/pricing-form'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { Typography } from '@mui/material'
import { getBrandStorePricing, StorePricing } from '@productwindtom/shared-momentum'
import { Form } from '@productwindtom/ui-base'
import { captureException } from '@sentry/react'
import { useMemo } from 'react'
import { toast } from 'react-toastify'
import { Brand } from '../queries'
import { createBrandPricing, deleteBrandPricing, updateBrandPricing } from './queries'
import { successToast } from '@momentum/utils/toastUtils'

type PricingFormValues = {
  pricing: StorePricing[]
}

export const BrandPricing = ({ brand }: { brand: Brand }) => {
  const { refreshBrand, selectedBrand, selectedCompany, agencies } = useUserSessionContext()

  if (!selectedBrand) {
    return null
  }

  const brandPricing = useMemo(
    () =>
      getBrandStorePricing(
        selectedBrand.region,
        selectedBrand.pricing,
        selectedCompany?.pricing,
        (agencies.find(a => a.id === selectedCompany?.agencyId) ?? selectedCompany?.agency)?.clientPricing
      ),
    [selectedBrand, selectedCompany, agencies]
  )

  const defaultValues = {
    pricing: brandPricing
  }

  const onSubmit = async (submitValues: PricingFormValues) => {
    try {
      await Promise.all(
        submitValues.pricing.map(bp => {
          const input = {
            id: brand.id,
            store: bp.store,
            creatorPricing: bp.creatorPricing
              .filter(cp => cp.price != null)
              .map(cp => ({
                type: cp.type,
                price: cp.price!
              }))
          }

          const anyNotBlank = !!bp.creatorPricing.find(cp => cp.price)

          if (brand.pricing.find(p => p.store === bp.store)) {
            if (anyNotBlank) {
              return updateBrandPricing(input)
            } else {
              return deleteBrandPricing({ id: brand.id, store: bp.store })
            }
          } else {
            return createBrandPricing(input)
          }
        })
      )
      successToast('Brand pricing saved successfully!')
    } catch (error) {
      captureException(error)
      toast(<Typography variant={'subtitle2'}>Failed to save brand pricing!</Typography>, { type: 'error' })
    }
    await refreshBrand(brand.id)
  }

  return (
    <Form defaultValues={defaultValues} onSubmit={onSubmit} resolver={yupResolver(schema)}>
      <PricingForm initialValues={defaultValues} title={'Brand pricing'} submitText={'Save brand pricing'} />
    </Form>
  )
}
