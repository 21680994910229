import { Container, Stack } from '@mui/system'
import { RocketIcon } from '@momentum/routes/proposals-create/create-win/RocketIcon'
import { Box, Button, Typography } from '@mui/material'
import { useCreateProposalContext } from '../context/CreateProposalContext'
import { SelectedProductInformation, StepProgress } from '@momentum/routes/proposals-create'
import { generatePath, Link as RouterLink, useSearchParams } from 'react-router-dom'
import { ROUTES } from '@momentum/routes/RouteNames'
import { DateTime } from 'luxon'
import { useWatch } from 'react-hook-form'
import { ProposalCreateForm } from '@momentum/routes/proposals-create/types'

export const CreateWin = () => {
  const { proposal, selectedProduct } = useCreateProposalContext()
  const launchDate = useWatch<ProposalCreateForm, 'launchDate'>({ name: 'launchDate' })
  const [params] = useSearchParams()
  const invoiceLink = params.get('invoiceLink') || proposal?.invoiceLink

  const product = selectedProduct

  if (!proposal || !product) {
    return null
  }

  return (
    <Container data-cy="campaignCreateSuccess">
      <Stack spacing={1} py={4}>
        <StepProgress />
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <Typography variant={'h3'}>{proposal?.title}</Typography>
          <Box maxWidth={'500px'}>
            <SelectedProductInformation
              goal={proposal.goal}
              product={product}
              productPriceOverride={proposal.productPriceOverride}
              readonly
            />
          </Box>
        </Stack>
      </Stack>
      <Stack spacing={3} alignItems={'center'}>
        <RocketIcon sx={{ width: 375, height: 375 }} />
        <Typography variant={'h4'}>Congrats! Your campaign has been successfully created.</Typography>
        <Typography variant={'h5'}>
          Your campaign is set to launch on {launchDate?.toLocaleString(DateTime.DATE_SHORT)}
        </Typography>
        <Stack direction={'row'} spacing={2} pb={4}>
          <Button
            variant={'contained'}
            data-cy="viewCampaignButton"
            component={RouterLink}
            to={generatePath(ROUTES.CAMPAIGN, { brandId: proposal.brandId, campaignId: proposal.id })}
          >
            View campaign
          </Button>
          {!!invoiceLink && (
            <Button variant={'outlined'} href={invoiceLink} target={'_blank'}>
              View invoice
            </Button>
          )}
        </Stack>
      </Stack>
    </Container>
  )
}
