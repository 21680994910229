import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'

export default [
  {
    unitsSold: 1,
    totalSalesAmount: 1334,
    pageViews: 15,
    date: '2024-09-26',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1334,
    pageViews: 33,
    date: '2024-09-27',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 34,
    date: '2024-09-28',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2668,
    pageViews: 29,
    date: '2024-09-29',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 14,
    date: '2024-09-30',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2668,
    pageViews: 30,
    date: '2024-10-01',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4002,
    pageViews: 26,
    date: '2024-10-02',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2668,
    pageViews: 23,
    date: '2024-10-03',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8004,
    pageViews: 32,
    date: '2024-10-04',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4002,
    pageViews: 24,
    date: '2024-10-05',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8004,
    pageViews: 28,
    date: '2024-10-06',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5336,
    pageViews: 32,
    date: '2024-10-07',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 13340,
    pageViews: 89,
    date: '2024-10-08',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 12006,
    pageViews: 60,
    date: '2024-10-09',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2668,
    pageViews: 25,
    date: '2024-10-10',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 6670,
    pageViews: 25,
    date: '2024-10-11',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 10672,
    pageViews: 41,
    date: '2024-10-12',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 6670,
    pageViews: 34,
    date: '2024-10-13',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1334,
    pageViews: 12,
    date: '2024-10-14',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2668,
    pageViews: 17,
    date: '2024-10-15',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1334,
    pageViews: 29,
    date: '2024-10-16',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 9338,
    pageViews: 34,
    date: '2024-10-17',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 6670,
    pageViews: 27,
    date: '2024-10-18',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 6670,
    pageViews: 19,
    date: '2024-10-19',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2668,
    pageViews: 29,
    date: '2024-10-20',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 12006,
    pageViews: 41,
    date: '2024-10-21',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2668,
    pageViews: 27,
    date: '2024-10-22',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2668,
    pageViews: 27,
    date: '2024-10-23',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 11,
    totalSalesAmount: 14674,
    pageViews: 28,
    date: '2024-10-24',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2668,
    pageViews: 20,
    date: '2024-10-25',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8004,
    pageViews: 36,
    date: '2024-10-26',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 10672,
    pageViews: 35,
    date: '2024-10-27',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4002,
    pageViews: 38,
    date: '2024-10-28',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8004,
    pageViews: 36,
    date: '2024-10-29',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 9338,
    pageViews: 34,
    date: '2024-10-30',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 9338,
    pageViews: 18,
    date: '2024-10-31',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 12006,
    pageViews: 32,
    date: '2024-11-01',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 9338,
    pageViews: 59,
    date: '2024-11-02',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 13340,
    pageViews: 47,
    date: '2024-11-03',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4002,
    pageViews: 36,
    date: '2024-11-04',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8004,
    pageViews: 52,
    date: '2024-11-05',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8004,
    pageViews: 33,
    date: '2024-11-06',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4002,
    pageViews: 44,
    date: '2024-11-07',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 13340,
    pageViews: 43,
    date: '2024-11-08',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 15,
    totalSalesAmount: 20010,
    pageViews: 44,
    date: '2024-11-09',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5336,
    pageViews: 28,
    date: '2024-11-10',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5336,
    pageViews: 38,
    date: '2024-11-11',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 12,
    totalSalesAmount: 16008,
    pageViews: 41,
    date: '2024-11-12',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 9338,
    pageViews: 41,
    date: '2024-11-13',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1334,
    pageViews: 31,
    date: '2024-11-14',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 9338,
    pageViews: 23,
    date: '2024-11-15',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 9338,
    pageViews: 33,
    date: '2024-11-16',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 12006,
    pageViews: 37,
    date: '2024-11-17',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 6670,
    pageViews: 40,
    date: '2024-11-18',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5336,
    pageViews: 21,
    date: '2024-11-19',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 9338,
    pageViews: 43,
    date: '2024-11-20',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8004,
    pageViews: 31,
    date: '2024-11-21',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4002,
    pageViews: 32,
    date: '2024-11-22',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 12006,
    pageViews: 44,
    date: '2024-11-23',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 12006,
    pageViews: 37,
    date: '2024-11-24',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 6670,
    pageViews: 24,
    date: '2024-11-25',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1334,
    pageViews: 18,
    date: '2024-11-26',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 6670,
    pageViews: 21,
    date: '2024-11-27',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8004,
    pageViews: 15,
    date: '2024-11-28',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 9338,
    pageViews: 32,
    date: '2024-11-29',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4002,
    pageViews: 18,
    date: '2024-11-30',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8004,
    pageViews: 21,
    date: '2024-12-01',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 9338,
    pageViews: 39,
    date: '2024-12-02',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5336,
    pageViews: 28,
    date: '2024-12-03',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 9338,
    pageViews: 42,
    date: '2024-12-04',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 13340,
    pageViews: 32,
    date: '2024-12-05',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2668,
    pageViews: 23,
    date: '2024-12-06',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5336,
    pageViews: 37,
    date: '2024-12-07',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 6670,
    pageViews: 36,
    date: '2024-12-08',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 9338,
    pageViews: 40,
    date: '2024-12-09',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 12,
    totalSalesAmount: 16008,
    pageViews: 49,
    date: '2024-12-10',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 10672,
    pageViews: 54,
    date: '2024-12-11',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5336,
    pageViews: 35,
    date: '2024-12-12',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 6670,
    pageViews: 39,
    date: '2024-12-13',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8004,
    pageViews: 32,
    date: '2024-12-14',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 20,
    totalSalesAmount: 26680,
    pageViews: 57,
    date: '2024-12-15',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 6670,
    pageViews: 37,
    date: '2024-12-16',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 12006,
    pageViews: 50,
    date: '2024-12-17',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8004,
    pageViews: 41,
    date: '2024-12-18',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8004,
    pageViews: 43,
    date: '2024-12-19',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 9338,
    pageViews: 33,
    date: '2024-12-20',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8004,
    pageViews: 37,
    date: '2024-12-21',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 6670,
    pageViews: 34,
    date: '2024-12-22',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4002,
    pageViews: 34,
    date: '2024-12-23',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 14,
    date: '2024-12-24',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8004,
    pageViews: 18,
    date: '2024-12-25',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5336,
    pageViews: 36,
    date: '2024-12-26',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5336,
    pageViews: 47,
    date: '2024-12-27',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 12006,
    pageViews: 37,
    date: '2024-12-28',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 14,
    totalSalesAmount: 18676,
    pageViews: 48,
    date: '2024-12-29',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8004,
    pageViews: 60,
    date: '2024-12-30',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 10672,
    pageViews: 43,
    date: '2024-12-31',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 13340,
    pageViews: 65,
    date: '2025-01-01',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5336,
    pageViews: 45,
    date: '2025-01-02',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 12006,
    pageViews: 66,
    date: '2025-01-03',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 12,
    totalSalesAmount: 16008,
    pageViews: 46,
    date: '2025-01-04',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 13340,
    pageViews: 73,
    date: '2025-01-05',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 13340,
    pageViews: 59,
    date: '2025-01-06',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1334,
    pageViews: 44,
    date: '2025-01-07',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 11,
    totalSalesAmount: 14674,
    pageViews: 52,
    date: '2025-01-08',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 11,
    totalSalesAmount: 14674,
    pageViews: 63,
    date: '2025-01-09',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 12006,
    pageViews: 59,
    date: '2025-01-10',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 11,
    totalSalesAmount: 14674,
    pageViews: 67,
    date: '2025-01-11',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 12006,
    pageViews: 59,
    date: '2025-01-12',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8004,
    pageViews: 43,
    date: '2025-01-13',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5336,
    pageViews: 62,
    date: '2025-01-14',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 11,
    totalSalesAmount: 14674,
    pageViews: 66,
    date: '2025-01-15',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 12,
    totalSalesAmount: 16008,
    pageViews: 65,
    date: '2025-01-16',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 13,
    totalSalesAmount: 17342,
    pageViews: 139,
    date: '2025-01-17',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 12,
    totalSalesAmount: 16008,
    pageViews: 155,
    date: '2025-01-18',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 13,
    totalSalesAmount: 17342,
    pageViews: 223,
    date: '2025-01-19',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 15,
    totalSalesAmount: 20010,
    pageViews: 159,
    date: '2025-01-20',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8004,
    pageViews: 172,
    date: '2025-01-21',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 19,
    totalSalesAmount: 25346,
    pageViews: 174,
    date: '2025-01-22',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 12,
    totalSalesAmount: 16008,
    pageViews: 183,
    date: '2025-01-23',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 16,
    totalSalesAmount: 21344,
    pageViews: 164,
    date: '2025-01-24',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 9338,
    pageViews: 111,
    date: '2025-01-25',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 8550,
    pageViews: 138,
    date: '2025-01-26',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 5412,
    pageViews: 147,
    date: '2025-01-27',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 15763,
    pageViews: 124,
    date: '2025-01-28',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 7216,
    pageViews: 78,
    date: '2025-01-29',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 9020,
    pageViews: 119,
    date: '2025-01-30',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 7213,
    pageViews: 170,
    date: '2025-01-31',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 9020,
    pageViews: 135,
    date: '2025-02-01',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 12628,
    pageViews: 128,
    date: '2025-02-02',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 5409,
    pageViews: 104,
    date: '2025-02-03',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 5415,
    pageViews: 138,
    date: '2025-02-04',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 10818,
    pageViews: 145,
    date: '2025-02-05',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 3608,
    pageViews: 130,
    date: '2025-02-06',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 9020,
    pageViews: 124,
    date: '2025-02-07',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 12628,
    pageViews: 159,
    date: '2025-02-08',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 12625,
    pageViews: 140,
    date: '2025-02-09',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 5415,
    pageViews: 110,
    date: '2025-02-10',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 5406,
    pageViews: 121,
    date: '2025-02-11',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 14432,
    pageViews: 165,
    date: '2025-02-12',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 14429,
    pageViews: 172,
    date: '2025-02-13',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 9020,
    pageViews: 126,
    date: '2025-02-14',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 9020,
    pageViews: 140,
    date: '2025-02-15',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 10818,
    pageViews: 115,
    date: '2025-02-16',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 12619,
    pageViews: 157,
    date: '2025-02-17',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 10821,
    pageViews: 151,
    date: '2025-02-18',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 7216,
    pageViews: 138,
    date: '2025-02-19',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 12583,
    pageViews: 128,
    date: '2025-02-20',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 0,
    pageViews: 79,
    date: '2025-02-21',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8085,
    pageViews: 79,
    date: '2025-02-22',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 3240,
    pageViews: 23,
    date: '2025-02-23',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4866,
    pageViews: 47,
    date: '2025-02-24',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 85,
    date: '2025-02-25',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 3237,
    pageViews: 81,
    date: '2025-02-26',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1784,
    pageViews: 45,
    date: '2025-02-27',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1794,
    pageViews: 25,
    date: '2025-02-28',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1794,
    pageViews: 41,
    date: '2025-03-01',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 27,
    date: '2025-03-02',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1794,
    pageViews: 55,
    date: '2025-03-03',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 8970,
    pageViews: 13,
    date: '2025-03-05',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2025-03-06',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2025-03-07',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 4,
    date: '2025-03-09',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1794,
    pageViews: 2,
    date: '2025-03-10',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: -2,
    totalSalesAmount: -3588,
    pageViews: 0,
    date: '2025-03-11',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 5382,
    pageViews: 0,
    date: '2025-03-12',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2025-03-14',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 3588,
    pageViews: 5,
    date: '2025-03-15',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 3588,
    pageViews: 0,
    date: '2025-03-16',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 7176,
    pageViews: 6,
    date: '2025-03-17',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 6,
    date: '2025-03-18',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: -1,
    totalSalesAmount: -1794,
    pageViews: 0,
    date: '2025-03-19',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2025-03-20',
    skuId: '8ea7fe24-53b9-4cb4-9fd5-afdae2ea9dbb'
  }
] as Array<ModelTypes['EtailerProductMetrics']>
