import { Stack, Typography } from '@mui/material'
import BenchmarkProvider from './context/BenchmarkContext'
import BenchmarkProducts from './components/BenchmarkProducts'
import BenchmarkGraph from './components/graph/BenchmarkGraph'

const Benchmark = () => {
  return (
    <BenchmarkProvider>
      <Stack spacing={3}>
        <Stack spacing={1}>
          <Typography variant="h4">Benchmark speed to product launch</Typography>
          <Typography variant="label3" color="grey.700">Measure Product Launch campaign impact by comparing product sales and traffic performance against your catalog baseline.</Typography>
        </Stack>
        <BenchmarkProducts />
        <BenchmarkGraph />
      </Stack>
    </BenchmarkProvider>
  )
}

export default Benchmark
