import { yupResolver } from "@hookform/resolvers/yup";
import ProductVariationsInput from "@momentum/components/form-inputs/ProductVariationsInput";
import CheckOutlineIcon from "@momentum/components/icons/check-outline";
import { ImageSelect } from "@momentum/components/image-select";
import Row from "@momentum/components/row";
import { useUserSessionContext } from "@momentum/contexts/UserSession";
import { getRetailerOptions } from "@momentum/utils/selectOptions";
import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { STORE_TO_RETAILER } from "@productwindtom/shared-momentum";
import {
  ScrapeRequestStatus,
  UploadRequestType,
} from "@productwindtom/shared-momentum-zeus-types";
import { getCurrencySymbol } from "@productwindtom/shared-ws-currency";
import {
  Form,
  NumberInput,
  RadioInput,
  SelectInput,
  TextInput,
} from "@productwindtom/ui-base";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import * as uuid from "uuid";
import { useBrandContext } from "../../context/BrandContext";
import { isAddProductCallback } from "../../context/types";
import { CreditsRefund } from "./CreditsRefund";
import { CreditsSpend } from "./CreditsSpend";
import { LiveProductDetails } from "./LiveProductDetails";
import { AddProductProvider, useAddProductContext } from "./context";
import { AddProductForm } from "./types";
import { schema } from "./validations";

const AddProductDialog = () => {
  const {
    isAddProductActiveOrCallback,
    setIsAddProductActiveOrCallback,
    products: brandProducts,
  } = useBrandContext();
  const handleClose = useCallback(
    () => setIsAddProductActiveOrCallback(false),
    [setIsAddProductActiveOrCallback],
  );

  const initialProduct = isAddProductCallback(isAddProductActiveOrCallback)
    ? brandProducts?.find(
      (p) => p.id === isAddProductActiveOrCallback?.selectedProductId,
    )
    : undefined;

  return (
    <Dialog
      open={!!isAddProductActiveOrCallback}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
    >
      <Form
        autocomplete={"off"}
        onSubmit={() => null}
        resolver={yupResolver(schema)}
        defaultValues={{
          productId: initialProduct?.id || uuid.v4(),
          isProductLive: (
            (isAddProductCallback(isAddProductActiveOrCallback) &&
              !!isAddProductActiveOrCallback?.disableManual) ||
            !initialProduct?.skuId
          ).toString(),
          productTitle: initialProduct?.name || undefined,
          productImage: initialProduct?.productImageKey || undefined,
          retailer:
            (initialProduct?.store &&
              STORE_TO_RETAILER[initialProduct.store]) ||
            undefined,
          price: initialProduct?.priceCents || undefined,
          creditsSpendAgreed:
            isAddProductCallback(isAddProductActiveOrCallback) &&
            !isAddProductActiveOrCallback.campaignId,
        }}
      >
        <AddProductProvider initialProduct={initialProduct}>
          <AddProductFormBody handleClose={handleClose} />
        </AddProductProvider>
      </Form>
    </Dialog>
  );
};

const AddProductFormBody = ({ handleClose }: { handleClose: () => void }) => {
  const {
    addProductState,
    initialProduct,
    scrapedProduct,
    selectableVariations,
    selectedProduct,
    isCallbackLoading,
    addProduct,
    hasManualAdd,
    campaign,
    creditsRequired,
    hasRequiredCredits,
  } = useAddProductContext();
  const { isAdminView } = useUserSessionContext();
  const { brand, products: brandProducts } = useBrandContext();

  const {
    getValues,
    watch,
    formState: { isValid },
  } = useFormContext<AddProductForm>();

  const productId = watch("productId");
  const isProductLive = watch("isProductLive") === "true";
  const availableRetailers = getRetailerOptions(brand.region, isAdminView);

  return (
    <Stack spacing={3} p={3}>
      <Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant={"h4"}>
            {hasManualAdd ? "Add product" : "Add live product URL"}
          </Typography>
          <IconButton onClick={handleClose} size="small">
            <Close />
          </IconButton>
        </Stack>
        <Typography
          variant={"label3"}
          color={(theme) => theme.palette.grey.A700}
        >
          {hasManualAdd
            ? "Add a product via its URL if the product is live. Manually add the product info if the product is not live yet."
            : campaign
              ? "Update your placeholder product by adding its live URL. Add the live product URL before the campaign start date in order to launch this campaign."
              : "Update the product via its URL if the product is live."}
        </Typography>
      </Stack>

      {hasManualAdd && (
        <RadioInput
          name="isProductLive"
          radioProps={{
            size: "medium",
          }}
          options={[
            {
              value: true,
              label: (
                <Typography variant="label3">The product is live</Typography>
              ),
            },
            {
              value: false,
              label: (
                <Typography variant="label3">
                  The product is NOT live
                </Typography>
              ),
            },
          ]}
        />
      )}

      {isProductLive && !scrapedProduct && (
        <TextInput
          primaryText={"Enter Product URL"}
          name={"productUrl"}
          data-cy="createProduct-productUrlInput"
          disabled={addProductState?.status === ScrapeRequestStatus.IN_PROGRESS}
        />
      )}

      {!isProductLive && (
        <Stack spacing={3}>
          {!isAdminView && initialProduct && !initialProduct?.skuId && (
            <Alert
              variant="outlined"
              severity="info"
              data-cy="selfService-placeholderItem"
            >
              <Typography variant="label3">
                Select "the product is live" to schedule this campaign.
              </Typography>
            </Alert>
          )}
          <TextInput
            primaryText={"Product Title"}
            name={"productTitle"}
            data-cy="createProduct-productTitleInput"
          />
          <ImageSelect
            name={"productImage"}
            productId={productId ?? undefined}
            primaryText={"Product image"}
            buttonText={"Click to upload"}
            replacementText={"Click to replace"}
            buttonProps={{
              variant: "outlined",
            }}
            uploadType={UploadRequestType.UPLOAD_PRODUCT_IMAGE}
            dataCy="createProduct-imageInput"
          />
          <SelectInput
            primaryText={"Retailer"}
            name={"retailer"}
            options={availableRetailers}
            dataCy="createProduct-retailerInput"
          />
          <Stack direction={"row"} justifyContent={"space-between"} spacing={3}>
            <NumberInput
              primaryText={"Product Price (ASP)"}
              name={"price"}
              prefix={getCurrencySymbol(brand.region)}
              decimalScale={2}
              fullWidth
              returnAsNumber
              returnAsCents
              data-cy="createProduct-priceInput"
            />
          </Stack>
        </Stack>
      )}

      {addProductState?.message && (
        <Row
          spacing={1}
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
        >
          {addProductState.status === ScrapeRequestStatus.IN_PROGRESS && (
            <CircularProgress
              size={16}
              sx={{
                flex: "0 0 auto",
              }}
            />
          )}

          {addProductState.status === ScrapeRequestStatus.SUCCESS && (
            <CheckOutlineIcon color="success" />
          )}

          <Typography
            data-cy={`createProduct-message-${addProductState.status}`}
            variant="label3"
            {...(addProductState.status === ScrapeRequestStatus.ERROR && {
              color: "error",
            })}
          >
            {addProductState.status === ScrapeRequestStatus.ERROR && "*"}
            {addProductState.message}
          </Typography>
        </Row>
      )}

      {isProductLive &&
        addProductState?.status === ScrapeRequestStatus.SUCCESS &&
        scrapedProduct && (
          <>
            <LiveProductDetails />
            {/* Show only when updating campaign */}
            {campaign && (
              <>
                <Box
                  borderRadius={"4px"}
                  border={(t) => `1px solid ${t.palette.grey.A200}`}
                  bgcolor={"white"}
                  p={1.5}
                >
                  <ProductVariationsInput
                    brandProducts={brandProducts}
                    selectedProduct={selectedProduct}
                    selectableProducts={selectableVariations ?? []}
                  />
                </Box>
                {(creditsRequired ?? 0) > 0 && <CreditsSpend />}
                {(creditsRequired ?? 0) < 0 && <CreditsRefund />}
              </>
            )}
          </>
        )}

      <Stack direction={"row"} justifyContent={"flex-end"} spacing={1}>
        <Button variant={"outlined"} onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton
          data-cy="createProduct-addProduct"
          variant={"contained"}
          loading={isCallbackLoading}
          disabled={
            !isValid ||
            !hasRequiredCredits ||
            isCallbackLoading ||
            addProductState?.status === ScrapeRequestStatus.IN_PROGRESS ||
            (isProductLive &&
              (addProductState?.status !== ScrapeRequestStatus.SUCCESS ||
                !selectedProduct))
          }
          onClick={() => addProduct(isProductLive, getValues())}
        >
          {initialProduct && !isProductLive
            ? "Save Product"
            : campaign
              ? "Update Product"
              : "Add Product"}
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default AddProductDialog;
