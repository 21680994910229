import { SvgIcon, SvgIconProps } from '@mui/material'

export default function ReviewPencilGraphicIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 48 48" fill="none">
      <circle cx="24" cy="24.5" r="24" fill="#4668EE" />
      <path
        d="M39.4762 6.88086H9.52381C7.57766 6.88086 6 8.45853 6 10.4047V42.119L14.8095 35.0713H39.4762C41.4223 35.0713 43 33.4937 43 31.5475V10.4047C43 8.45852 41.4223 6.88086 39.4762 6.88086Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
      />
      <path d="M24 26.5L20 30.5H37V26.5H24Z" fill="#243C98" />
      <path d="M12 26.5V30.5H16L27 19.5L23 15.5L12 26.5Z" fill="#F3C94E" />
      <path
        d="M28 18.5L24 14.5L25.5858 12.9142C26.3668 12.1332 27.6332 12.1332 28.4142 12.9142L29.5858 14.0858C30.3668 14.8668 30.3668 16.1332 29.5858 16.9142L28 18.5Z"
        fill="#F58080"
      />
      <defs>
        <rect width="48" height="48" fill="white" transform="translate(0 0.5)" />
      </defs>
    </SvgIcon>
  )
}
