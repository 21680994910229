import { keyBy } from 'lodash'
import { DateTime } from 'luxon'
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon'
import AmazonIcon from '@momentum/components/icons/amazon'
import { ProposalPromoEventType } from '@productwindtom/shared-momentum-zeus-types'
import EasterEggIcon from '@momentum/components/icons/easter-egg'
import MothersDayIcon from '@momentum/components/icons/mothers-day'
import FathersDayIcon from '@momentum/components/icons/fathers-day'
import BackpackIcon from '@momentum/components/icons/backpack'
import ShoppingBagsIcon from '@momentum/components/icons/shopping-bags'
import HeartWithRibbonIcon from '@momentum/components/icons/heart-with-ribbon'
import PumpkinIcon from '@momentum/components/icons/pumpkin'
import GraduationCapIcon from '@momentum/components/icons/graduation-cap'
import FootballIcon from '@momentum/components/icons/football'
import USFlagIcon from '@momentum/components/icons/flags/us'
import July4thIcon from '@momentum/components/icons/july-4th'
import HammerAndWrenchIcon from '@momentum/components/icons/hammer-and-wrench'

export type EventConfig = {
  id: ProposalPromoEventType
  enabled?: boolean
  name: string
  icon: (props?: SvgIconProps) => JSX.Element
  eventDate?: DateTime
  preferredStartDate: DateTime
  peakSalesPeriodStart: DateTime
  peakSalesPeriodEnd: DateTime
}

export const EVENTS: EventConfig[] = [
  {
    enabled: true,
    id: ProposalPromoEventType.EASTER,
    name: 'Easter',
    icon: (props?: SvgIconProps) => <EasterEggIcon {...props} />,
    eventDate: DateTime.fromISO('2025-04-20'),
    preferredStartDate: DateTime.fromISO('2025-02-22'),
    peakSalesPeriodStart: DateTime.fromISO('2025-04-01'),
    peakSalesPeriodEnd: DateTime.fromISO('2025-04-17')
  },
  {
    enabled: true,
    id: ProposalPromoEventType.MOTHERS_DAY,
    name: "Mother's Day",
    icon: (props?: SvgIconProps) => <MothersDayIcon {...props} />,
    eventDate: DateTime.fromISO('2025-05-11'),
    preferredStartDate: DateTime.fromISO('2025-02-22'),
    peakSalesPeriodStart: DateTime.fromISO('2025-04-01'),
    peakSalesPeriodEnd: DateTime.fromISO('2025-05-08')
  },
  {
    enabled: true,
    id: ProposalPromoEventType.GRADUATION,
    name: 'Graduation',
    icon: (props?: SvgIconProps) => <GraduationCapIcon {...props} />,
    eventDate: DateTime.fromISO('2025-05-10'),
    preferredStartDate: DateTime.fromISO('2025-02-20'),
    peakSalesPeriodStart: DateTime.fromISO('2025-03-20'),
    peakSalesPeriodEnd: DateTime.fromISO('2025-05-18')
  },
  {
    enabled: true,
    id: ProposalPromoEventType.MEMORIAL_DAY,
    name: 'Memorial Day',
    icon: (props?: SvgIconProps) => <USFlagIcon {...props} />,
    eventDate: DateTime.fromISO('2025-05-26'),
    preferredStartDate: DateTime.fromISO('2025-03-01'),
    peakSalesPeriodStart: DateTime.fromISO('2025-04-27'),
    peakSalesPeriodEnd: DateTime.fromISO('2025-05-25')
  },
  {
    enabled: true,
    id: ProposalPromoEventType.FATHERS_DAY,
    name: "Father's Day",
    icon: (props?: SvgIconProps) => <FathersDayIcon {...props} />,
    eventDate: DateTime.fromISO('2025-06-15'),
    preferredStartDate: DateTime.fromISO('2025-03-22'),
    peakSalesPeriodStart: DateTime.fromISO('2025-05-01'),
    peakSalesPeriodEnd: DateTime.fromISO('2025-06-12')
  },
  {
    enabled: true,
    id: ProposalPromoEventType.JULY_4TH,
    name: 'July 4th',
    icon: (props?: SvgIconProps) => <July4thIcon {...props} />,
    eventDate: DateTime.fromISO('2025-07-04'),
    preferredStartDate: DateTime.fromISO('2025-03-20'),
    peakSalesPeriodStart: DateTime.fromISO('2025-05-20'),
    peakSalesPeriodEnd: DateTime.fromISO('2025-07-03')
  },
  {
    enabled: true,
    id: ProposalPromoEventType.BACK_TO_SCHOOL,
    name: 'Back to School',
    icon: (props?: SvgIconProps) => <BackpackIcon {...props} />,
    eventDate: DateTime.fromISO('2025-09-01'),
    preferredStartDate: DateTime.fromISO('2025-06-01'),
    peakSalesPeriodStart: DateTime.fromISO('2025-06-22'),
    peakSalesPeriodEnd: DateTime.fromISO('2025-08-31')
  },
  {
    enabled: true,
    id: ProposalPromoEventType.PRIME_DAY_1,
    name: 'Prime Day (1)',
    icon: (props?: SvgIconProps) => <AmazonIcon {...props} />,
    eventDate: DateTime.fromISO('2025-07-23'),
    preferredStartDate: DateTime.fromISO('2025-06-22'),
    peakSalesPeriodStart: DateTime.fromISO('2025-07-07'),
    peakSalesPeriodEnd: DateTime.fromISO('2025-07-23')
  },
  {
    enabled: false,
    id: ProposalPromoEventType.PRIME_DAY_2,
    name: 'Prime Day (2)',
    icon: (props?: SvgIconProps) => <AmazonIcon {...props} />,
    // eventDate: DateTime.fromISO('2025-07-23'),
    preferredStartDate: DateTime.fromISO('2025-06-22'),
    peakSalesPeriodStart: DateTime.fromISO('2025-07-07'),
    peakSalesPeriodEnd: DateTime.fromISO('2025-07-23')
  },
  {
    enabled: true,
    id: ProposalPromoEventType.LABOR_DAY,
    name: 'Labor Day',
    icon: (props?: SvgIconProps) => <HammerAndWrenchIcon {...props} />,
    eventDate: DateTime.fromISO('2025-09-01'),
    preferredStartDate: DateTime.fromISO('2025-07-07'),
    peakSalesPeriodStart: DateTime.fromISO('2025-08-10'),
    peakSalesPeriodEnd: DateTime.fromISO('2025-08-30')
  },
  {
    enabled: true,
    id: ProposalPromoEventType.HALLOWEEN,
    name: 'Halloween',
    icon: (props?: SvgIconProps) => <PumpkinIcon {...props} />,
    eventDate: DateTime.fromISO('2025-10-31'),
    preferredStartDate: DateTime.fromISO('2025-08-15'),
    peakSalesPeriodStart: DateTime.fromISO('2025-09-15'),
    peakSalesPeriodEnd: DateTime.fromISO('2025-10-29')
  },

  {
    enabled: true,
    id: ProposalPromoEventType.BFCM,
    name: 'BFCM',
    icon: (props?: SvgIconProps) => <ShoppingBagsIcon {...props} />,
    eventDate: DateTime.fromISO('2025-12-01'),
    preferredStartDate: DateTime.fromISO('2025-10-15'),
    peakSalesPeriodStart: DateTime.fromISO('2025-11-01'),
    peakSalesPeriodEnd: DateTime.fromISO('2025-12-01')
  },
  {
    enabled: true,
    id: ProposalPromoEventType.SUPERBOWL,
    name: 'Superbowl',
    icon: (props?: SvgIconProps) => <FootballIcon {...props} />,
    eventDate: DateTime.fromISO('2026-02-08'),
    preferredStartDate: DateTime.fromISO('2025-12-15'),
    peakSalesPeriodStart: DateTime.fromISO('2026-01-11'),
    peakSalesPeriodEnd: DateTime.fromISO('2026-02-07')
  },
  {
    enabled: true,
    id: ProposalPromoEventType.V_DAY,
    name: "Valentine's Day",
    icon: (props?: SvgIconProps) => <HeartWithRibbonIcon {...props} />,
    eventDate: DateTime.fromISO('2026-02-14'),
    preferredStartDate: DateTime.fromISO('2025-12-15'),
    peakSalesPeriodStart: DateTime.fromISO('2026-01-01'),
    peakSalesPeriodEnd: DateTime.fromISO('2026-02-12')
  }
]

export const EVENTS_BY_TYPE = keyBy(EVENTS, e => e.id)
