import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'
import { SAMPLE_CAMPAIGN_LAUNDRY } from '../../getCampaign/sample-data/laundry'
import { SAMPLE_CAMPAIGN_WIRELESS_EARBUDS } from '../../getCampaign/sample-data/wireless-earbuds'
import { SAMPLE_CAMPAIGN_BENCHMARK_LAUNDRY } from './laundry'
import { SAMPLE_CAMPAIGN_BENCHMARK_WIRELESS_EARBUDS } from './wireless-earbuds'

export const SAMPLE_CAMPAIGN_BENCHMARK: Record<string, ModelTypes['CampaignBenchmark']> = {
  [SAMPLE_CAMPAIGN_LAUNDRY.skuId]: SAMPLE_CAMPAIGN_BENCHMARK_LAUNDRY,
  [SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId]: SAMPLE_CAMPAIGN_BENCHMARK_WIRELESS_EARBUDS
}
