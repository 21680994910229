import { SocialQuality, InstantJoinType } from '@productwindtom/shared-ws-zeus-types'
import { ContentRequirementLevel, Product, ProductSocialContent, SocialChannel, User } from './types'
import { groupBy, head } from 'lodash'
import { notEmpty } from '@productwindtom/shared-node'
import { isQualifiedForInstantJoinFlow } from '../instant-join'
import { InstantJoinCampaign } from '../instant-join/types'
import { NO_CONTENT_FLOWS } from '../../configs/flows'

export const determineProductForUserV2 = <T extends Product>(
  user: User,
  campaign: InstantJoinCampaign,
  products: T[],
  isInstantJoinAllAccess?: boolean
) => {
  const instantJoinFlows = products.filter(p => p.instantJoinType === InstantJoinType.INSTANT_JOIN)
  const nonInstantJoinFlows = products.filter(p => p.instantJoinType !== InstantJoinType.INSTANT_JOIN)

  if (!instantJoinFlows.length) {
    return determineProductForUser(user, nonInstantJoinFlows)
  }

  // Social top priority
  // Premium UGC Second

  //Fighting methods for other cases
  let selectedProduct: undefined | T = undefined

  for (const product of products) {
    if (
      (product.instantJoinType === InstantJoinType.INSTANT_JOIN &&
        isQualifiedForInstantJoinFlow(user, campaign, product, isInstantJoinAllAccess)) ||
      (product.instantJoinType !== InstantJoinType.INSTANT_JOIN && doesUserQualifyForProduct(user, product))
    ) {
      if (!selectedProduct) {
        selectedProduct = product
      } else if (isProductBetterChoice(selectedProduct, product)) {
        selectedProduct = product
      }
    }
  }
  return selectedProduct
}

const NO_CONTENT_FLOW_KEYS = Object.keys(NO_CONTENT_FLOWS)
const SOCIAL_CHANNELS = [SocialChannel.TIKTOK, SocialChannel.INSTAGRAM, SocialChannel.YOUTUBE]

// Logic for https://app.clickup.com/t/8698181/ENG-5973
const isProductBetterChoice = (defender: Product, challenger: Product) => {
  const { campaignStateMachine: defenderFlow } = defender
  const { campaignStateMachine: challengerFlow } = challenger

  const isDefenderInstantJoin = defender.instantJoinType === InstantJoinType.INSTANT_JOIN
  const isDefenderNoContent = NO_CONTENT_FLOW_KEYS.includes(defenderFlow!)

  const isChallengerInstantJoin = challenger.instantJoinType === InstantJoinType.INSTANT_JOIN
  const isChallengerNoContent = NO_CONTENT_FLOW_KEYS.includes(challengerFlow!)

  const isDefenderSocialOrPremiumUGC =
    defender.stagingSocialQualityRequirement === SocialQuality.PREMIUM ||
    !!defender.contentRequirement?.find(r => SOCIAL_CHANNELS.includes(r?.channel))

  const isChallengerSocialOrPremiumUGC =
    challenger.stagingSocialQualityRequirement === SocialQuality.PREMIUM ||
    !!challenger.contentRequirement?.find(r => SOCIAL_CHANNELS.includes(r?.channel))

  if (isDefenderSocialOrPremiumUGC && !isChallengerSocialOrPremiumUGC) {
    //If UGC Premium or Social is included in the campaign, users who qualify will only see the UGC Premium or Social application flow. They are never shown the instant join option for BA or standard UGC.
    return false
  }
  if (!isDefenderSocialOrPremiumUGC && isChallengerSocialOrPremiumUGC) {
    //If UGC Premium or Social is included in the campaign, users who qualify will only see the UGC Premium or Social application flow. They are never shown the instant join option for BA or standard UGC.
    return true
  }

  if (isDefenderSocialOrPremiumUGC && isChallengerSocialOrPremiumUGC) {
    return isProductRequirementsMoreDifficult(defender, challenger)
  }

  // UGC NOT flow and BA is IJ, always show the UGC IJ flow if qualifies
  if (isDefenderNoContent && !isChallengerNoContent && !isChallengerInstantJoin) {
    return true
  }
  if (isChallengerNoContent && !isDefenderNoContent && !isDefenderInstantJoin) {
    return false
  }

  // UGC is IJ and BA is NOT IJ, always show IJ flow
  if (!isDefenderNoContent && isDefenderInstantJoin && !isChallengerInstantJoin) {
    return false
  }
  if (!isChallengerNoContent && isChallengerInstantJoin && !isDefenderInstantJoin) {
    return true
  }

  //UGC and BA are IJ
  if (isDefenderInstantJoin && isChallengerInstantJoin) {
    if (!isDefenderNoContent && (defender.instantJoinSeatsAvailable || 0) > 0) {
      // Show UGC flow if slots available
      return false
    }
    if (!isChallengerNoContent && (challenger.instantJoinSeatsAvailable || 0) > 0) {
      // Show UGC flow if slots available
      return true
    }
  }

  // At this point, both could be no content, return one with slots
  return (challenger.instantJoinSeatsAvailable || 0) > 0
}

export const determineProductForUser = <T extends Product>(user: User, products: T[]) => {
  let selectedProduct: undefined | T = undefined

  for (const product of products) {
    if (doesUserQualifyForProduct(user, product)) {
      if (!selectedProduct) {
        selectedProduct = product
      } else if (isProductRequirementsMoreDifficult(selectedProduct, product)) {
        selectedProduct = product
      }
    }
  }

  return selectedProduct
}

const socialQualityToNumber: { [key: string]: number } = {
  [SocialQuality.PREMIUM]: 2,
  [SocialQuality.STANDARD]: 1,
  [SocialQuality.DERANKED]: -1
}

export const determineAllProductsForUser = <T extends Product>(user: User, products: T[]) => {
  const qualifiedProducts = products.filter(product => doesUserQualifyForProduct(user, product))
  const groupedByChannel = groupBy(qualifiedProducts, p => (p.contentRequirement || []).find(cr => cr.channel)?.channel)

  return Object.values(groupedByChannel)
    .map(products => {
      return head(
        products.sort((a, b) => {
          const aScore = socialQualityToNumber[a.stagingSocialQualityRequirement || ''] || 0
          const bScore = socialQualityToNumber[b.stagingSocialQualityRequirement || ''] || 0
          return bScore - aScore
        })
      )
    })
    .filter(notEmpty)
}

const checkRange = (valueComparator: number, rangeComparator?: { min?: number; max?: number }) => {
  if (rangeComparator?.min && valueComparator < rangeComparator?.min) {
    return false
  } else if (rangeComparator?.max && valueComparator > rangeComparator?.max) {
    return false
  }
  return true
}

export const doesUserQualifyForProduct = (user: User, product: Product) => {
  const {
    impressionsTiktokRangeRequirement,
    impressionsInstagramRangeRequirement,
    engagementRateInstagramRangeRequirement,
    engagementRateTiktokRangeRequirement,
    followersInstagramRangeRequirement,
    followersTiktokRangeRequirement,
    isQualityRequirementsEnabled,
    contentRequirement
  } = product

  const {
    instagramTrailingPostImpressionsMedian = 0,
    tiktokMedianViews = 0,
    instagramFollowersInt = 0,
    tiktokFollowersInt = 0,
    instagramTrailingPostEngagementRateMedian = 0,
    tiktokMedianPostEngagementRateViews = 0,
    tiktokVerificationMethod,
    instagramVerificationMethod
  } = user

  if (isQualityRequirementsEnabled) {
    if (!checkRange(instagramTrailingPostImpressionsMedian, impressionsInstagramRangeRequirement)) {
      return false
    }
    if (!checkRange(tiktokMedianViews, impressionsTiktokRangeRequirement)) {
      return false
    }
    if (!checkRange(instagramTrailingPostEngagementRateMedian, engagementRateInstagramRangeRequirement)) {
      return false
    }
    if (!checkRange(tiktokMedianPostEngagementRateViews, engagementRateTiktokRangeRequirement)) {
      return false
    }
    if (!checkRange(instagramFollowersInt, followersInstagramRangeRequirement)) {
      return false
    }
    if (!checkRange(tiktokFollowersInt, followersTiktokRangeRequirement)) {
      return false
    }
  }

  const hasInstagram = (contentRequirement || []).find(cr => cr.channel === SocialChannel.INSTAGRAM)
  const hasTiktok = (contentRequirement || []).find(cr => cr.channel === SocialChannel.TIKTOK)

  if (hasInstagram && !instagramVerificationMethod) {
    return false
  }
  if (hasTiktok && !tiktokVerificationMethod) {
    return false
  }

  return true
}

export const isProductRequirementsMoreDifficult = (defender: Product, challenger: Product) => {
  const { contentRequirement: defenderRequirements } = defender
  const { contentRequirement: challengerRequirements } = challenger

  const requiredContentRequirementsComparison = compareContentRequirements(
    defenderRequirements,
    challengerRequirements,
    ContentRequirementLevel.IS_REQUIRED
  )

  if (requiredContentRequirementsComparison === 1) {
    return true
  } else if (requiredContentRequirementsComparison === -1) {
    return false
  }

  const optionalContentRequirementsComparison = compareContentRequirements(
    defenderRequirements,
    challengerRequirements,
    ContentRequirementLevel.IS_OPTIONAL
  )

  if (optionalContentRequirementsComparison === 1) {
    return true
  } else if (optionalContentRequirementsComparison === -1) {
    return false
  }
  return false
}

export const compareContentRequirements = (
  defenderRequirements: ProductSocialContent[] = [],
  challengerRequirements: ProductSocialContent[] = [],
  requirementLevel: ContentRequirementLevel
) => {
  const totalDefenderRequired = (defenderRequirements || [])
    .filter(d => d.requirementLevel === requirementLevel)
    .map(d => d.instances)
    .reduce((a, b) => a + b, 0)

  const totalChallengerRequired = (challengerRequirements || [])
    .filter(d => d.requirementLevel === requirementLevel)
    .map(d => d.instances)
    .reduce((a, b) => a + b, 0)

  if (totalChallengerRequired > totalDefenderRequired) {
    return 1
  } else if (totalDefenderRequired > totalChallengerRequired) {
    return -1
  }
  return 0
}
