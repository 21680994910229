import { useState } from 'react'
import { useCreateProposalContext } from '@momentum/routes/proposals-create/context/CreateProposalContext'
import { useFormContext } from 'react-hook-form'
import { ProposalCreateForm } from '@momentum/routes/proposals-create/types'
import { ROUTES } from '@momentum/routes/RouteNames'
import { sumBy } from 'lodash'
import { LoadingButton } from '@mui/lab'
import { ArrowForwardOutlined } from '@mui/icons-material'
import { Button } from '@mui/material'
import { useNavigate, generatePath } from 'react-router-dom'
import { Schema } from 'yup'

export const SaveNextButton = ({
  disabled,
  nextPath,
  validationSchema
}: {
  disabled?: boolean
  nextPath: string
  validationSchema: Schema
}) => {
  const navigate = useNavigate()
  const [saving, setSaving] = useState(false)
  const { saveAndNavigate } = useCreateProposalContext()
  const methods = useFormContext<ProposalCreateForm>()
  const { watch } = methods
  const data = watch()

  const handleSaveNext = async () => {
    setSaving(true)
    await saveAndNavigate?.(data, methods, nextPath)
    setSaving(false)
  }

  const totalCreators = sumBy(data.creatorPricing || [], cp => cp.numCreators)

  const handleNext = () => {
    navigate(
      generatePath([ROUTES.BRAND_CREATE_PROPOSAL_EDIT, nextPath].join('/'), {
        brandId: data.brandId,
        id: data.id
      })
    )
  }

  if (data.id) {
    return (
      <Button
        variant={'contained'}
        onClick={handleNext}
        disabled={disabled || !validationSchema.isValidSync(data) || !totalCreators}
        endIcon={<ArrowForwardOutlined />}
        data-cy={'nextButton'}
      >
        Next
      </Button>
    )
  }

  return (
    <LoadingButton
      loading={saving}
      variant={'contained'}
      onClick={handleSaveNext}
      disabled={disabled || !validationSchema.isValidSync(data) || !totalCreators}
      endIcon={<ArrowForwardOutlined />}
      data-cy={'nextButton'}
    >
      Save & next
    </LoadingButton>
  )
}
