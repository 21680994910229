import { ProductVariationFormFields } from '@momentum/components/form-inputs/ProductVariationsInput'
import { Retailer } from '@productwindtom/shared-momentum'
import { GraphQLTypes, InputType, ScrapeRequestStatus } from '@productwindtom/shared-momentum-zeus-types'
import { childProduct, parentProduct } from '../../selectors'

export type AddLiveProductForm = {
  productUrl: string
  isProductLive: 'true'
  creditsSpendAgreed: boolean
} & ProductVariationFormFields

export type AddPlaceholderProductForm = {
  productId?: string
  productTitle: string
  isProductLive: 'false'
  productImage?: string
  retailer: Retailer
  price: number
  listingLink?: string
}

export type AddProductForm = AddLiveProductForm | AddPlaceholderProductForm

export type AddProductState = {
  status: ScrapeRequestStatus
  message?: string
}

export type ParentProduct = InputType<GraphQLTypes['Product'], typeof parentProduct>
export type ChildProduct = InputType<GraphQLTypes['Product'], typeof childProduct>
export type LiveProduct = ParentProduct | ChildProduct

export const isChildProduct = (product: LiveProduct): product is ChildProduct => {
  return 'parentSku' in product
}

export const isParentProduct = (product: LiveProduct): product is ParentProduct => {
  return !isChildProduct(product)
}
