import { Chip, Stack, Typography, useTheme } from "@mui/material";
import { CheckCircleOutline, AccessTimeOutlined } from "@mui/icons-material";
import { InvoiceStatus } from "@productwindtom/shared-momentum-zeus-types";
import ClockWarningOutlineIcon from "@momentum/components/icons/clock-warning-outline";
import { ActionStatus, itemActionStatus } from "./index";

export const StatusChip = ({ status, date }: { status?: InvoiceStatus, date?: string }) => {
  const theme = useTheme()

  const translucentColor = (color: string) => {
    return color + '1A'
  }

  const actionStatus = itemActionStatus(status, date)

  return (
    <>
      {actionStatus === ActionStatus.SUCCESS &&
        <Chip
          data-cy="subscriptionTableStatusChipSuccess"
          label={
            <Stack direction="row" spacing={1} alignItems="center">
              <CheckCircleOutline sx={{ width: 16, height: 16 }} />
              <Typography variant="body1" fontWeight={800}>Success</Typography>
            </Stack>
          }
          sx={{ 
            borderRadius: '4px',
            background: theme => translucentColor(theme.palette.success.main),
            color: theme.palette.success.main,
          }}
        />
      }
      {actionStatus === ActionStatus.PAYMENT_OVERDUE &&
        <Chip
          data-cy="subscriptionTableStatusChipPaymentOverdue"
          label={
            <Stack direction="row" spacing={1} alignItems="center">
              <ClockWarningOutlineIcon fontSize={'mSmall'} />
              <Typography variant="body1" fontWeight={800}>Payment overdue</Typography>
            </Stack>
          }
          sx={{ 
            borderRadius: '4px',
            background: theme => translucentColor(theme.palette.error.main),
            color: theme.palette.error.main,
          }}
        />
      }
      {actionStatus === ActionStatus.AWAITING_PAYMENT &&
        <Chip
          data-cy="subscriptionTableStatusChipAwaitingPayment"
          label={
            <Stack direction="row" spacing={1} alignItems="center">
              <AccessTimeOutlined sx={{ width: 16, height: 16 }} />
              <Typography variant="body1" fontWeight={800}>Awaiting payment</Typography>
            </Stack>
          }
          sx={{ 
            borderRadius: '4px',
            background: theme => translucentColor(theme.palette.grey.A700),
            color: theme.palette.grey.A700,
          }}
        />
      }
    </>
  )
}
