import { Form, SubmitButton, NumberInput, SelectInput } from '@productwindtom/ui-base'
import { yupResolver } from '@hookform/resolvers/yup'
import { Stack } from '@mui/system'
import * as yup from 'yup'
import { CreditAction } from '@productwindtom/shared-momentum-zeus-types'
import { useWatch } from 'react-hook-form'
import { BackButton } from '@momentum/components/back-button'

const schema = (creditsRemaining: number, hasBrands?: boolean) =>
  yup
    .object({
      action: yup.string<CreditAction>().required('Required'),
      numCredits: yup
        .number()
        .required('Required')
        .when('action', {
          is: CreditAction.REMOVE,
          then: schema => schema.max(creditsRemaining, 'You do not have enough credits to use'),
          otherwise: schema => schema
        }),
      associatedBrandId: yup.string().when('action', {
        is: (v: CreditAction) => v === CreditAction.REMOVE && hasBrands,
        then: schema => schema.required('Required'),
        otherwise: schema => schema.optional().nullable()
      })
    })
    .noUnknown(true)
export type AdjustCreditsFormData = {
  action: CreditAction
  numCredits: number
  associatedBrandId?: string
}

export const AdjustCreditsForm = ({
  action,
  brands,
  creditsRemaining,
  onSubmit,
  onCancel
}: {
  action: CreditAction
  creditsRemaining: number
  brands: { id: string; name: string }[]
  onCancel: () => void
  onSubmit: (data: AdjustCreditsFormData) => Promise<void>
}) => {
  const handleSubmit = async (submitValues: AdjustCreditsFormData) => {
    await onSubmit(submitValues)
  }

  const defaultValues = {
    action
  }
  return (
    <Form
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      resolver={yupResolver(schema(creditsRemaining, !!brands.length))}
    >
      <Stack spacing={3}>
        <FormBody creditsRemaining={creditsRemaining} brands={brands} />
        <Stack direction={'row'} justifyContent={'flex-end'} spacing={1}>
          <BackButton variant={'text'} onClick={onCancel} text={'Cancel'} />
          <SubmitButton variant={'contained'} disableOnDirty={false} data-cy="adjustCreditsSubmitButton">
            Submit
          </SubmitButton>
        </Stack>
      </Stack>
    </Form>
  )
}

const FormBody = ({
  creditsRemaining,
  brands
}: {
  creditsRemaining: number
  brands: { id: string; name: string }[]
}) => {
  const data = useWatch()
  return (
    <Stack spacing={2}>
      <NumberInput
        returnAsNumber
        inputProps={{ 'data-cy': 'adminNumCredits' }}
        max={data.action === CreditAction.REMOVE ? creditsRemaining : undefined}
        decimalScale={0}
        name={'numCredits'}
        primaryText={`Enter the amount of credits you want to ${data.action === CreditAction.REMOVE ? 'use' : 'add'}`}
      />
      {!!brands.length && data.action === CreditAction.REMOVE && (
        <SelectInput
          name={'associatedBrandId'}
          primaryText={'Select a brand'}
          options={brands.map(b => ({ value: b.id, label: b.name }))}
        />
      )}
    </Stack>
  )
}
