import { useCreateProposalContext } from '@momentum/routes/proposals-create/context/CreateProposalContext'
import useEstimatedDeliverables from '@momentum/routes/proposals-create/modules/useEstimatedDeliverables'
import { ProposalCreateForm } from '@momentum/routes/proposals-create/types'
import {
  PRODUCT_LAUNCH_RATING_COUNT,
  ProductRatingSnapshot,
  getRecommendedCreatorCountMessage,
  getRecommendedCreatorCounts
} from '@momentum/utils/proposalUtils'
import { MANAGED_SERVICE_CREATOR_TYPES, getReviewRate, STORE_TO_RETAILER } from '@productwindtom/shared-momentum'
import { PricingCreatorType, ProposalGoal } from '@productwindtom/shared-momentum-zeus-types'
import { sum } from 'lodash'
import { useEffect, useState } from 'react'
import { useWatch } from 'react-hook-form'
import { listProductRatingSnapshots } from './queries'

export type SavedRecommendation = {
  creatorCounts: { [key in PricingCreatorType]?: number }
  monthlyUnitsSold: number
  warnings: { message: string; type?: PricingCreatorType }[]
  recommendationMessage: string
  durationWeeks?: number
}

export const useRecommendation = () => {
  const [savedRecommendation, setSavedRecommendation] = useState<SavedRecommendation>()

  const estimateDeliverables = useEstimatedDeliverables()
  const { selectedProduct } = useCreateProposalContext()
  const eventDate = useWatch<ProposalCreateForm, 'eventDate'>({ name: 'eventDate' })
  const eventEndDate = useWatch<ProposalCreateForm, 'eventEndDate'>({ name: 'eventEndDate' })
  const creatorPricing = useWatch<ProposalCreateForm, 'creatorPricing'>({ name: 'creatorPricing' })
  const type = useWatch<ProposalCreateForm, 'goal'>({ name: 'goal' })
  const monthlyUnitsSold = useWatch<ProposalCreateForm, 'estimatedUnitsSoldPerMonth'>({
    name: 'estimatedUnitsSoldPerMonth'
  })
  const ratingGoal = useWatch<ProposalCreateForm, 'ratingGoal'>({ name: 'ratingGoal' })
  const estimatedAverageRating = useWatch<ProposalCreateForm, 'estimatedAverageRating'>({
    name: 'estimatedAverageRating'
  })
  // for each product that is selected, fetch and cache the rating snapshots so we can use it to calculate the recommended creator counts
  const [productsRatingSnapshots, setProductsRatingSnapshots] =
    useState<Partial<Record<string, ProductRatingSnapshot[]>>>()

  useEffect(() => {
    if (selectedProduct && !productsRatingSnapshots?.[selectedProduct.id]) {
      listProductRatingSnapshots(selectedProduct.id).then(p => {
        if (p) {
          setProductsRatingSnapshots(prev => ({ ...prev, [selectedProduct.id]: p }))
        }
      })
    }
  }, [selectedProduct?.id])

  const customFeedbackResponses = useWatch<ProposalCreateForm, 'customFeedbackResponses'>({
    name: 'customFeedbackResponses'
  })

  const isSelfServiceDisabled = creatorPricing.some(
    cp => MANAGED_SERVICE_CREATOR_TYPES.includes(cp.type) && cp.numCreators > 0
  )

  const isSelfServiceSupported = [
    ProposalGoal.PRODUCT_LAUNCH,
    ProposalGoal.EXISTING_PRODUCT,
    ProposalGoal.BOOST_RATING,
    ProposalGoal.EVENT,
    ProposalGoal.MARKET_RESEARCH,
    ProposalGoal.SUPPORT_MARKETING_PROMOTION,
    ProposalGoal.BOOST_RUFUS
  ].includes(type)

  const totalCreators = sum((creatorPricing || []).map(cp => cp.numCreators))

  const clearSavedRecommendation = () => {
    setSavedRecommendation(undefined)
  }

  if (
    !type ||
    !selectedProduct ||
    !creatorPricing ||
    !monthlyUnitsSold ||
    !productsRatingSnapshots?.[selectedProduct.id]
  ) {
    return { isDifferent: false, warnings: [], isSelfServiceDisabled, isSelfServiceSupported, clearSavedRecommendation }
  }

  const eventDaysDuration = eventDate && eventEndDate ? eventEndDate.diff(eventDate, 'days').days : undefined

  const creatorCounts = getRecommendedCreatorCounts({
    type,
    product: selectedProduct,
    durationDays: eventDaysDuration,
    monthlyUnits: monthlyUnitsSold,
    ratingSnapshots: productsRatingSnapshots[selectedProduct.id],
    desiredStarRating: ratingGoal,
    averageReviewRating: estimatedAverageRating,
    customFeedbackResponses
  })

  const warnings: { message: string; type?: PricingCreatorType }[] = []

  const ugcPricing = creatorPricing.find(cp => cp.type === PricingCreatorType.UGC)

  const monthsDuration = estimateDeliverables.durationWeeks ? Math.round(estimateDeliverables.durationWeeks / 4) : 0

  const isDifferent =
    creatorPricing.some(cp => cp.numCreators !== creatorCounts[cp.type]) ||
    !savedRecommendation ||
    savedRecommendation.monthlyUnitsSold !== monthlyUnitsSold ||
    !!creatorPricing.find(cp => cp.numCreators !== savedRecommendation.creatorCounts[cp.type])

  switch (type) {
    case ProposalGoal.PRODUCT_LAUNCH: {
      const minCreators = Math.ceil(monthlyUnitsSold * 0.2)
      if (totalCreators < minCreators) {
        warnings.push({
          message: `For this campaign we recommend at least ${minCreators.toLocaleString()} total creators to best impact SEO and traffic!`,
          type: undefined
        })
      }
      if (monthsDuration >= 3) {
        warnings.push({
          message: `Based on your estimate of ${monthlyUnitsSold.toLocaleString()} units sold per month, it will take ${monthsDuration.toLocaleString()}+ months to drive ${estimateDeliverables.numReviews?.toLocaleString()} reviews (${(estimateDeliverables.reviewsPerWeek * 4).toLocaleString()} reviews / mo)`,
          type: PricingCreatorType.UGC
        })
      }
      if ((ugcPricing?.numCreators || 0) * getReviewRate(STORE_TO_RETAILER[selectedProduct.store]) < 20) {
        warnings.push({
          message:
            'Speed to 20 reviews is a common success metric. We encourage you to add more Standard UGC creators to generate more reviews.',
          type: PricingCreatorType.UGC
        })
      }
      break
    }
    case ProposalGoal.EXISTING_PRODUCT:
      if (totalCreators < monthlyUnitsSold * 0.2) {
        warnings.push({
          message: `Total creators in this campaign is below 20% of your monthly sales. We recommend increasing your creator count to best impact SEO and traffic!`,
          type: undefined
        })
      }
      if ((ugcPricing?.numCreators || 0) < creatorCounts.UGC) {
        warnings.push({
          message: `This campaign is likely to only drive ${estimateDeliverables?.numReviews?.toLocaleString()} reviews. We recommend adding at least ${creatorCounts.UGC?.toLocaleString()} UGC creators to get ${PRODUCT_LAUNCH_RATING_COUNT} incremental reviews.`,
          type: PricingCreatorType.UGC
        })
      }

      if (monthsDuration >= 3) {
        warnings.push({
          message: `Due to expected sales velocity, this campaign will take ${monthsDuration.toLocaleString()} months.`,
          type: PricingCreatorType.UGC
        })
      }
      break
    case ProposalGoal.BOOST_RATING:
      if ((ugcPricing?.numCreators || 0) < creatorCounts.UGC) {
        warnings.push({
          message: `This campaign is unlikely to achieve your desired star rating. Consider adding ${(creatorCounts.UGC - (ugcPricing?.numCreators || 0))?.toLocaleString()} more UGC creators to achieve improved star rating.`,
          type: PricingCreatorType.UGC
        })
      }
      if (monthsDuration >= 3) {
        warnings.push({
          message: `Due to expected sales velocity, this campaign will take ${monthsDuration.toLocaleString()} months.`,
          type: PricingCreatorType.UGC
        })
      }
      break
    case ProposalGoal.EVENT:
      if (totalCreators < monthlyUnitsSold * 0.2) {
        warnings.push({
          message: `Total creators in this campaign is below 20% of your monthly sales. We recommend increasing your creator count to best impact SEO and traffic!`,
          type: undefined
        })
      }
      if (monthsDuration >= 3) {
        warnings.push({
          message: `Due to expected sales velocity, this campaign will take ${monthsDuration.toLocaleString()} months.`,
          type: PricingCreatorType.UGC
        })
      }
      break
    case ProposalGoal.OTHER:
    case ProposalGoal.EVERGREEN:
    default:
      break
  }

  const generatedMessage = getRecommendedCreatorCountMessage(
    type,
    selectedProduct,
    creatorCounts,
    ratingGoal,
    estimatedAverageRating,
    customFeedbackResponses
  )

  const saveRecommendation = () => {
    if (creatorCounts) {
      setSavedRecommendation({
        creatorCounts,
        monthlyUnitsSold,
        warnings,
        recommendationMessage: generatedMessage,
        durationWeeks: estimateDeliverables.durationWeeks
      })
    }
  }

  return {
    isDifferent,
    warnings: totalCreators ? warnings : [],
    monthlyUnitsSold,
    creatorCounts,
    recommendationMessage: generatedMessage,
    durationWeeks: estimateDeliverables.durationWeeks,
    isSelfServiceDisabled,
    isSelfServiceSupported,
    saveRecommendation,
    savedRecommendation,
    clearSavedRecommendation
  }
}
