import { Alert, Typography } from '@mui/material'
import { CheckCircleOutlined } from '@mui/icons-material'
import { DateTime } from 'luxon'
import { PricingCreatorType } from '@productwindtom/shared-momentum-zeus-types'

export const SupportMarketingPromotionRecommendationAlert = ({
  recommendationCreatorCounts,
  recommendedEndDate,
  recommendedLaunchDate
}: {
  recommendationCreatorCounts: Record<PricingCreatorType, number>
  recommendedLaunchDate: DateTime
  recommendedEndDate: DateTime
}) => {
  return (
    <Alert
      variant={'outlined'}
      severity={'success'}
      icon={<CheckCircleOutlined color={'success'} />}
      sx={{ py: 1, px: 2, bgcolor: 'white' }}
      data-cy={'recommendationMessage'}
    >
      <Typography variant={'label2'} flexShrink={1} color={'black'}>
        Your campaign recommendation for your marketing promotion
      </Typography>
      <Typography variant={'body2'} flexShrink={1} color={'black'}>
        We recommend starting your campaign on {recommendedLaunchDate?.toLocaleString()} and ending on{' '}
        {recommendedEndDate.toLocaleString()}. This will boost SEO throughout your promotion period to supercharge
        promotion results. Based on your expected sales, we recommend using{' '}
        {recommendationCreatorCounts[PricingCreatorType.ADVOCATE]?.toLocaleString()} Brand advocates to maximize
        visibility and conversion.
      </Typography>
    </Alert>
  )
}
