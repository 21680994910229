import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'

export default [
  {
    unitsSold: 6,
    totalSalesAmount: 10824,
    pageViews: 21,
    date: '2023-07-18',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 21,
    date: '2023-07-19',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 22,
    date: '2023-07-20',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1726,
    pageViews: 23,
    date: '2023-07-21',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 23,
    date: '2023-07-22',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 5178,
    pageViews: 34,
    date: '2023-07-23',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4932,
    pageViews: 36,
    date: '2023-07-24',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1480,
    pageViews: 25,
    date: '2023-07-25',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2960,
    pageViews: 24,
    date: '2023-07-26',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8880,
    pageViews: 33,
    date: '2023-07-27',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2960,
    pageViews: 28,
    date: '2023-07-28',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1480,
    pageViews: 42,
    date: '2023-07-29',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1480,
    pageViews: 39,
    date: '2023-07-30',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1480,
    pageViews: 40,
    date: '2023-07-31',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 11840,
    pageViews: 39,
    date: '2023-08-01',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1480,
    pageViews: 53,
    date: '2023-08-02',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 38,
    date: '2023-08-03',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2960,
    pageViews: 39,
    date: '2023-08-04',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2960,
    pageViews: 31,
    date: '2023-08-05',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4440,
    pageViews: 35,
    date: '2023-08-06',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1480,
    pageViews: 47,
    date: '2023-08-07',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4440,
    pageViews: 68,
    date: '2023-08-08',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 41,
    date: '2023-08-09',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 51,
    date: '2023-08-10',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2636,
    pageViews: 23,
    date: '2023-08-11',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 46,
    date: '2023-08-12',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 50,
    date: '2023-08-13',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 54,
    date: '2023-08-14',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2960,
    pageViews: 52,
    date: '2023-08-15',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4440,
    pageViews: 32,
    date: '2023-08-16',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 41,
    date: '2023-08-17',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2960,
    pageViews: 56,
    date: '2023-08-18',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2960,
    pageViews: 42,
    date: '2023-08-19',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1480,
    pageViews: 53,
    date: '2023-08-20',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2960,
    pageViews: 45,
    date: '2023-08-21',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 56,
    date: '2023-08-22',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1480,
    pageViews: 62,
    date: '2023-08-23',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 61,
    date: '2023-08-24',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 50,
    date: '2023-08-25',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4440,
    pageViews: 51,
    date: '2023-08-26',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4440,
    pageViews: 57,
    date: '2023-08-27',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4440,
    pageViews: 49,
    date: '2023-08-28',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 48,
    date: '2023-08-29',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 54,
    date: '2023-08-30',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 37,
    date: '2023-08-31',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4440,
    pageViews: 59,
    date: '2023-09-01',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4440,
    pageViews: 51,
    date: '2023-09-02',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 46,
    date: '2023-09-03',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 55,
    date: '2023-09-04',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 50,
    date: '2023-09-05',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 49,
    date: '2023-09-06',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 11,
    totalSalesAmount: 16280,
    pageViews: 33,
    date: '2023-09-07',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2960,
    pageViews: 47,
    date: '2023-09-08',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 55,
    date: '2023-09-09',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 44,
    date: '2023-09-10',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 67,
    date: '2023-09-11',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2960,
    pageViews: 51,
    date: '2023-09-12',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 42,
    date: '2023-09-13',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8880,
    pageViews: 47,
    date: '2023-09-14',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4440,
    pageViews: 40,
    date: '2023-09-15',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 35,
    date: '2023-09-16',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2960,
    pageViews: 50,
    date: '2023-09-17',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 49,
    date: '2023-09-18',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 53,
    date: '2023-09-19',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8880,
    pageViews: 56,
    date: '2023-09-20',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 10360,
    pageViews: 53,
    date: '2023-09-21',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4440,
    pageViews: 54,
    date: '2023-09-22',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2960,
    pageViews: 46,
    date: '2023-09-23',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 11840,
    pageViews: 73,
    date: '2023-09-24',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 51,
    date: '2023-09-25',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1480,
    pageViews: 41,
    date: '2023-09-26',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 47,
    date: '2023-09-27',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2960,
    pageViews: 54,
    date: '2023-09-28',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4440,
    pageViews: 56,
    date: '2023-09-29',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 48,
    date: '2023-09-30',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 35,
    date: '2023-10-01',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 40,
    date: '2023-10-02',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4440,
    pageViews: 41,
    date: '2023-10-03',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 11840,
    pageViews: 58,
    date: '2023-10-04',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 14800,
    pageViews: 49,
    date: '2023-10-05',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 43,
    date: '2023-10-06',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1480,
    pageViews: 24,
    date: '2023-10-07',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 46,
    date: '2023-10-08',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 41,
    date: '2023-10-09',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4440,
    pageViews: 35,
    date: '2023-10-10',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 35,
    date: '2023-10-11',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 23,
    date: '2023-10-12',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4440,
    pageViews: 25,
    date: '2023-10-13',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2960,
    pageViews: 24,
    date: '2023-10-14',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8880,
    pageViews: 43,
    date: '2023-10-15',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 29,
    date: '2023-10-16',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2960,
    pageViews: 28,
    date: '2023-10-17',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8880,
    pageViews: 28,
    date: '2023-10-18',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8880,
    pageViews: 35,
    date: '2023-10-19',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 30,
    date: '2023-10-20',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 28,
    date: '2023-10-21',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5080,
    pageViews: 35,
    date: '2023-10-22',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 11430,
    pageViews: 38,
    date: '2023-10-23',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 7620,
    pageViews: 50,
    date: '2023-10-24',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 12,
    totalSalesAmount: 15240,
    pageViews: 48,
    date: '2023-10-25',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5080,
    pageViews: 50,
    date: '2023-10-26',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 12700,
    pageViews: 48,
    date: '2023-10-27',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 7620,
    pageViews: 47,
    date: '2023-10-28',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 3810,
    pageViews: 35,
    date: '2023-10-29',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 8890,
    pageViews: 51,
    date: '2023-10-30',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 10160,
    pageViews: 50,
    date: '2023-10-31',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 11430,
    pageViews: 48,
    date: '2023-11-01',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 12700,
    pageViews: 55,
    date: '2023-11-02',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 3810,
    pageViews: 42,
    date: '2023-11-03',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 7620,
    pageViews: 32,
    date: '2023-11-04',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 6350,
    pageViews: 50,
    date: '2023-11-05',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2540,
    pageViews: 29,
    date: '2023-11-06',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5080,
    pageViews: 41,
    date: '2023-11-07',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 7620,
    pageViews: 35,
    date: '2023-11-08',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 14800,
    pageViews: 32,
    date: '2023-11-09',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4440,
    pageViews: 28,
    date: '2023-11-10',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2960,
    pageViews: 14,
    date: '2023-11-11',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 29,
    date: '2023-11-12',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4440,
    pageViews: 49,
    date: '2023-11-13',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 30,
    date: '2023-11-14',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2960,
    pageViews: 31,
    date: '2023-11-15',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 13,
    totalSalesAmount: 19240,
    pageViews: 30,
    date: '2023-11-16',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8880,
    pageViews: 30,
    date: '2023-11-17',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 14800,
    pageViews: 28,
    date: '2023-11-18',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1480,
    pageViews: 23,
    date: '2023-11-19',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 13320,
    pageViews: 28,
    date: '2023-11-20',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2960,
    pageViews: 28,
    date: '2023-11-21',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 18,
    date: '2023-11-22',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2960,
    pageViews: 17,
    date: '2023-11-23',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 10360,
    pageViews: 25,
    date: '2023-11-24',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 22,
    date: '2023-11-25',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1480,
    pageViews: 29,
    date: '2023-11-26',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2960,
    pageViews: 30,
    date: '2023-11-27',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 32,
    date: '2023-11-28',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 22,
    date: '2023-11-29',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 14800,
    pageViews: 31,
    date: '2023-11-30',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 20,
    date: '2023-12-01',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4440,
    pageViews: 31,
    date: '2023-12-02',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4440,
    pageViews: 26,
    date: '2023-12-03',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 45,
    date: '2023-12-04',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 36,
    date: '2023-12-05',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 39,
    date: '2023-12-06',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1480,
    pageViews: 23,
    date: '2023-12-07',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8880,
    pageViews: 34,
    date: '2023-12-08',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 14800,
    pageViews: 30,
    date: '2023-12-09',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 24,
    date: '2023-12-10',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 33,
    date: '2023-12-11',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 45,
    date: '2023-12-12',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 27,
    date: '2023-12-13',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 11,
    totalSalesAmount: 16280,
    pageViews: 33,
    date: '2023-12-14',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8880,
    pageViews: 41,
    date: '2023-12-15',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 13320,
    pageViews: 39,
    date: '2023-12-16',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1480,
    pageViews: 29,
    date: '2023-12-17',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4440,
    pageViews: 25,
    date: '2023-12-18',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 37,
    date: '2023-12-19',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8880,
    pageViews: 33,
    date: '2023-12-20',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 34,
    date: '2023-12-21',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 24,
    date: '2023-12-22',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4440,
    pageViews: 29,
    date: '2023-12-23',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 25,
    date: '2023-12-24',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2960,
    pageViews: 21,
    date: '2023-12-25',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 37,
    date: '2023-12-26',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 13,
    totalSalesAmount: 19240,
    pageViews: 41,
    date: '2023-12-27',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 34,
    date: '2023-12-28',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 51,
    date: '2023-12-29',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 11840,
    pageViews: 37,
    date: '2023-12-30',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 50,
    date: '2023-12-31',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 164,
    date: '2024-01-01',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 13320,
    pageViews: 131,
    date: '2024-01-02',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2960,
    pageViews: 161,
    date: '2024-01-03',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4440,
    pageViews: 159,
    date: '2024-01-04',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 13,
    totalSalesAmount: 19240,
    pageViews: 148,
    date: '2024-01-05',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 13320,
    pageViews: 134,
    date: '2024-01-06',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 10360,
    pageViews: 154,
    date: '2024-01-07',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 160,
    date: '2024-01-08',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 108,
    date: '2024-01-09',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 111,
    date: '2024-01-10',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 10360,
    pageViews: 101,
    date: '2024-01-11',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 13320,
    pageViews: 103,
    date: '2024-01-12',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 10360,
    pageViews: 84,
    date: '2024-01-13',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 122,
    date: '2024-01-14',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 10360,
    pageViews: 144,
    date: '2024-01-15',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 14800,
    pageViews: 115,
    date: '2024-01-16',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 10360,
    pageViews: 135,
    date: '2024-01-17',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8880,
    pageViews: 103,
    date: '2024-01-18',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 10360,
    pageViews: 93,
    date: '2024-01-19',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 109,
    date: '2024-01-20',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 10360,
    pageViews: 106,
    date: '2024-01-21',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 13320,
    pageViews: 106,
    date: '2024-01-22',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 11840,
    pageViews: 101,
    date: '2024-01-23',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 15,
    totalSalesAmount: 22200,
    pageViews: 93,
    date: '2024-01-24',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4440,
    pageViews: 73,
    date: '2024-01-25',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 104,
    date: '2024-01-26',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8880,
    pageViews: 94,
    date: '2024-01-27',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 92,
    date: '2024-01-28',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 105,
    date: '2024-01-29',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 97,
    date: '2024-01-30',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 11840,
    pageViews: 77,
    date: '2024-01-31',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 11,
    totalSalesAmount: 16280,
    pageViews: 81,
    date: '2024-02-01',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 112,
    date: '2024-02-02',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 102,
    date: '2024-02-03',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2960,
    pageViews: 82,
    date: '2024-02-04',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 11840,
    pageViews: 93,
    date: '2024-02-05',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8880,
    pageViews: 96,
    date: '2024-02-06',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 14800,
    pageViews: 104,
    date: '2024-02-07',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8880,
    pageViews: 88,
    date: '2024-02-08',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 98,
    date: '2024-02-09',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 88,
    date: '2024-02-10',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 10360,
    pageViews: 133,
    date: '2024-02-11',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 11,
    totalSalesAmount: 16280,
    pageViews: 106,
    date: '2024-02-12',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8880,
    pageViews: 116,
    date: '2024-02-13',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 88,
    date: '2024-02-14',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 83,
    date: '2024-02-15',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8880,
    pageViews: 87,
    date: '2024-02-16',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2960,
    pageViews: 98,
    date: '2024-02-17',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 14800,
    pageViews: 120,
    date: '2024-02-18',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 12,
    totalSalesAmount: 17760,
    pageViews: 100,
    date: '2024-02-19',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 11840,
    pageViews: 96,
    date: '2024-02-20',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 14800,
    pageViews: 81,
    date: '2024-02-21',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 13320,
    pageViews: 79,
    date: '2024-02-22',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 79,
    date: '2024-02-23',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 11840,
    pageViews: 61,
    date: '2024-02-24',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 13,
    totalSalesAmount: 19240,
    pageViews: 123,
    date: '2024-02-25',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2960,
    pageViews: 67,
    date: '2024-02-26',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8880,
    pageViews: 99,
    date: '2024-02-27',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 13,
    totalSalesAmount: 19240,
    pageViews: 77,
    date: '2024-02-28',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8880,
    pageViews: 63,
    date: '2024-02-29',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 11,
    totalSalesAmount: 16280,
    pageViews: 73,
    date: '2024-03-01',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5080,
    pageViews: 86,
    date: '2024-03-02',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 10160,
    pageViews: 102,
    date: '2024-03-03',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 11430,
    pageViews: 104,
    date: '2024-03-04',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 7620,
    pageViews: 94,
    date: '2024-03-05',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 10160,
    pageViews: 104,
    date: '2024-03-06',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 11430,
    pageViews: 101,
    date: '2024-03-07',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 10160,
    pageViews: 97,
    date: '2024-03-08',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 11430,
    pageViews: 111,
    date: '2024-03-09',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 10160,
    pageViews: 115,
    date: '2024-03-10',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 14,
    totalSalesAmount: 17780,
    pageViews: 98,
    date: '2024-03-11',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 15,
    totalSalesAmount: 19050,
    pageViews: 105,
    date: '2024-03-12',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 6350,
    pageViews: 107,
    date: '2024-03-13',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 16,
    totalSalesAmount: 20320,
    pageViews: 104,
    date: '2024-03-14',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 6350,
    pageViews: 100,
    date: '2024-03-15',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 10160,
    pageViews: 85,
    date: '2024-03-16',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 10160,
    pageViews: 99,
    date: '2024-03-17',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 6350,
    pageViews: 113,
    date: '2024-03-18',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 13,
    totalSalesAmount: 16510,
    pageViews: 89,
    date: '2024-03-19',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 12,
    totalSalesAmount: 17550,
    pageViews: 74,
    date: '2024-03-20',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 10150,
    pageViews: 79,
    date: '2024-03-21',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 11,
    totalSalesAmount: 16280,
    pageViews: 57,
    date: '2024-03-22',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8880,
    pageViews: 74,
    date: '2024-03-23',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8880,
    pageViews: 72,
    date: '2024-03-24',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 10360,
    pageViews: 72,
    date: '2024-03-25',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 14800,
    pageViews: 58,
    date: '2024-03-26',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 3170,
    pageViews: 57,
    date: '2024-03-27',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 14,
    totalSalesAmount: 20720,
    pageViews: 65,
    date: '2024-03-28',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 15,
    totalSalesAmount: 22200,
    pageViews: 48,
    date: '2024-03-29',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 12,
    totalSalesAmount: 19200,
    pageViews: 31,
    date: '2024-03-30',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 6400,
    pageViews: 33,
    date: '2024-03-31',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 11,
    totalSalesAmount: 17600,
    pageViews: 54,
    date: '2024-04-01',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 6400,
    pageViews: 66,
    date: '2024-04-02',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 11200,
    pageViews: 50,
    date: '2024-04-03',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 14400,
    pageViews: 63,
    date: '2024-04-04',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1600,
    pageViews: 31,
    date: '2024-04-05',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 6400,
    pageViews: 50,
    date: '2024-04-06',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 8570,
    pageViews: 44,
    date: '2024-04-07',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1790,
    pageViews: 36,
    date: '2024-04-08',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 12530,
    pageViews: 60,
    date: '2024-04-09',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 5370,
    pageViews: 63,
    date: '2024-04-10',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 14320,
    pageViews: 66,
    date: '2024-04-11',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 10740,
    pageViews: 75,
    date: '2024-04-12',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 10740,
    pageViews: 64,
    date: '2024-04-13',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 16110,
    pageViews: 76,
    date: '2024-04-14',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1790,
    pageViews: 62,
    date: '2024-04-15',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 16110,
    pageViews: 61,
    date: '2024-04-16',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 7160,
    pageViews: 65,
    date: '2024-04-17',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 8875,
    pageViews: 67,
    date: '2024-04-18',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 10740,
    pageViews: 41,
    date: '2024-04-19',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 7160,
    pageViews: 60,
    date: '2024-04-20',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 7160,
    pageViews: 69,
    date: '2024-04-21',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 10740,
    pageViews: 56,
    date: '2024-04-22',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 12,
    totalSalesAmount: 21480,
    pageViews: 76,
    date: '2024-04-23',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 8950,
    pageViews: 43,
    date: '2024-04-24',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 3580,
    pageViews: 49,
    date: '2024-04-25',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 11,
    totalSalesAmount: 19615,
    pageViews: 50,
    date: '2024-04-26',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 8950,
    pageViews: 49,
    date: '2024-04-27',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 12530,
    pageViews: 46,
    date: '2024-04-28',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 8725,
    pageViews: 42,
    date: '2024-04-29',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 6760,
    pageViews: 54,
    date: '2024-04-30',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 11830,
    pageViews: 43,
    date: '2024-05-01',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 16900,
    pageViews: 64,
    date: '2024-05-02',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 8450,
    pageViews: 48,
    date: '2024-05-03',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 8450,
    pageViews: 59,
    date: '2024-05-04',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 5070,
    pageViews: 67,
    date: '2024-05-05',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 10140,
    pageViews: 48,
    date: '2024-05-06',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4800,
    pageViews: 52,
    date: '2024-05-07',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 8000,
    pageViews: 62,
    date: '2024-05-08',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 14400,
    pageViews: 70,
    date: '2024-05-09',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 6400,
    pageViews: 48,
    date: '2024-05-10',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 9600,
    pageViews: 45,
    date: '2024-05-11',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 9600,
    pageViews: 63,
    date: '2024-05-12',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 12800,
    pageViews: 72,
    date: '2024-05-13',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 11,
    totalSalesAmount: 17600,
    pageViews: 77,
    date: '2024-05-14',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 8000,
    pageViews: 66,
    date: '2024-05-15',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 14400,
    pageViews: 60,
    date: '2024-05-16',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4800,
    pageViews: 47,
    date: '2024-05-17',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 14400,
    pageViews: 49,
    date: '2024-05-18',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 8000,
    pageViews: 54,
    date: '2024-05-19',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 8000,
    pageViews: 48,
    date: '2024-05-20',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 8000,
    pageViews: 61,
    date: '2024-05-21',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 5370,
    pageViews: 45,
    date: '2024-05-22',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 14320,
    pageViews: 53,
    date: '2024-05-23',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 7160,
    pageViews: 39,
    date: '2024-05-24',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 5370,
    pageViews: 48,
    date: '2024-05-25',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 10740,
    pageViews: 43,
    date: '2024-05-26',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 5370,
    pageViews: 37,
    date: '2024-05-27',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 10740,
    pageViews: 55,
    date: '2024-05-28',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 8950,
    pageViews: 54,
    date: '2024-05-29',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 5370,
    pageViews: 0,
    date: '2024-05-30',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 14320,
    pageViews: 0,
    date: '2024-05-31',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 5370,
    pageViews: 0,
    date: '2024-06-01',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 5370,
    pageViews: 0,
    date: '2024-06-02',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 11814,
    pageViews: 59,
    date: '2024-06-03',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 10740,
    pageViews: 55,
    date: '2024-06-04',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 7160,
    pageViews: 69,
    date: '2024-06-05',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 14,
    totalSalesAmount: 23380,
    pageViews: 54,
    date: '2024-06-06',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 51,
    date: '2024-06-07',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 5010,
    pageViews: 61,
    date: '2024-06-08',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 8350,
    pageViews: 47,
    date: '2024-06-09',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 8350,
    pageViews: 72,
    date: '2024-06-10',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 5010,
    pageViews: 46,
    date: '2024-06-11',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 6680,
    pageViews: 47,
    date: '2024-06-12',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 8350,
    pageViews: 107,
    date: '2024-06-13',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 8350,
    pageViews: 75,
    date: '2024-06-14',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 10020,
    pageViews: 68,
    date: '2024-06-15',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 15030,
    pageViews: 69,
    date: '2024-06-16',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 5010,
    pageViews: 70,
    date: '2024-06-17',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 10020,
    pageViews: 82,
    date: '2024-06-18',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 12,
    totalSalesAmount: 20040,
    pageViews: 113,
    date: '2024-06-19',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1670,
    pageViews: 74,
    date: '2024-06-20',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 5130,
    pageViews: 78,
    date: '2024-06-21',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 14200,
    pageViews: 88,
    date: '2024-06-22',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 14,
    totalSalesAmount: 25420,
    pageViews: 81,
    date: '2024-06-23',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 7160,
    pageViews: 78,
    date: '2024-06-24',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 14320,
    pageViews: 81,
    date: '2024-06-25',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 7160,
    pageViews: 77,
    date: '2024-06-26',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 13834,
    pageViews: 86,
    date: '2024-06-27',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 17900,
    pageViews: 79,
    date: '2024-06-28',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 3580,
    pageViews: 47,
    date: '2024-06-29',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2024-06-30',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2024-07-01',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2024-07-02',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2024-07-03',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2024-07-04',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2024-07-05',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2024-07-06',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: -1,
    totalSalesAmount: -1790,
    pageViews: 0,
    date: '2024-07-07',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2024-07-08',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 2,
    date: '2024-07-09',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 1,
    date: '2024-07-10',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2024-07-11',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2024-07-12',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2024-07-13',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2024-07-14',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1990,
    pageViews: 0,
    date: '2024-07-15',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2024-07-16',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2024-07-17',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2024-07-18',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2024-07-19',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2024-07-20',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 0,
    date: '2024-07-21',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 14,
    totalSalesAmount: 27860,
    pageViews: 38,
    date: '2024-07-22',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 19900,
    pageViews: 84,
    date: '2024-07-23',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 16122,
    pageViews: 72,
    date: '2024-07-24',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 3580,
    pageViews: 73,
    date: '2024-07-25',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 6360,
    pageViews: 69,
    date: '2024-07-26',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4770,
    pageViews: 50,
    date: '2024-07-27',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 6360,
    pageViews: 55,
    date: '2024-07-28',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 11,
    totalSalesAmount: 17490,
    pageViews: 75,
    date: '2024-07-29',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 3180,
    pageViews: 75,
    date: '2024-07-30',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 3180,
    pageViews: 60,
    date: '2024-07-31',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 11130,
    pageViews: 94,
    date: '2024-08-01',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7950,
    pageViews: 93,
    date: '2024-08-02',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 12720,
    pageViews: 75,
    date: '2024-08-03',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 3180,
    pageViews: 93,
    date: '2024-08-04',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 3180,
    pageViews: 73,
    date: '2024-08-05',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 6360,
    pageViews: 66,
    date: '2024-08-06',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 3180,
    pageViews: 47,
    date: '2024-08-07',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 9540,
    pageViews: 50,
    date: '2024-08-08',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4770,
    pageViews: 39,
    date: '2024-08-09',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7950,
    pageViews: 35,
    date: '2024-08-10',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7950,
    pageViews: 40,
    date: '2024-08-11',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 9540,
    pageViews: 29,
    date: '2024-08-12',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 11130,
    pageViews: 38,
    date: '2024-08-13',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7950,
    pageViews: 65,
    date: '2024-08-14',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 3180,
    pageViews: 50,
    date: '2024-08-15',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7950,
    pageViews: 43,
    date: '2024-08-16',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 6360,
    pageViews: 47,
    date: '2024-08-17',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 41,
    date: '2024-08-18',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 6360,
    pageViews: 54,
    date: '2024-08-19',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 6360,
    pageViews: 43,
    date: '2024-08-20',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7950,
    pageViews: 43,
    date: '2024-08-21',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 15900,
    pageViews: 49,
    date: '2024-08-22',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 6360,
    pageViews: 42,
    date: '2024-08-23',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 37,
    date: '2024-08-24',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 55,
    date: '2024-08-25',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 47,
    date: '2024-08-26',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 10360,
    pageViews: 37,
    date: '2024-08-27',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 26,
    date: '2024-08-28',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 14,
    totalSalesAmount: 20720,
    pageViews: 51,
    date: '2024-08-29',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 10360,
    pageViews: 44,
    date: '2024-08-30',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4440,
    pageViews: 38,
    date: '2024-08-31',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 12,
    totalSalesAmount: 17760,
    pageViews: 72,
    date: '2024-09-01',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 14800,
    pageViews: 68,
    date: '2024-09-02',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4550,
    pageViews: 50,
    date: '2024-09-03',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 11130,
    pageViews: 31,
    date: '2024-09-04',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7950,
    pageViews: 46,
    date: '2024-09-05',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7950,
    pageViews: 32,
    date: '2024-09-06',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 15900,
    pageViews: 47,
    date: '2024-09-07',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 15,
    totalSalesAmount: 23850,
    pageViews: 34,
    date: '2024-09-08',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 12720,
    pageViews: 46,
    date: '2024-09-09',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 6360,
    pageViews: 45,
    date: '2024-09-10',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 14310,
    pageViews: 40,
    date: '2024-09-11',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 9540,
    pageViews: 37,
    date: '2024-09-12',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 14310,
    pageViews: 45,
    date: '2024-09-13',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 12720,
    pageViews: 49,
    date: '2024-09-14',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 9540,
    pageViews: 36,
    date: '2024-09-15',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 9540,
    pageViews: 48,
    date: '2024-09-16',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7950,
    pageViews: 40,
    date: '2024-09-17',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 14310,
    pageViews: 50,
    date: '2024-09-18',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 9540,
    pageViews: 60,
    date: '2024-09-19',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 14310,
    pageViews: 49,
    date: '2024-09-20',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 15900,
    pageViews: 56,
    date: '2024-09-21',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 11130,
    pageViews: 66,
    date: '2024-09-22',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 9540,
    pageViews: 58,
    date: '2024-09-23',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 11130,
    pageViews: 45,
    date: '2024-09-24',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 11,
    totalSalesAmount: 17490,
    pageViews: 51,
    date: '2024-09-25',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 6360,
    pageViews: 54,
    date: '2024-09-26',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 6360,
    pageViews: 56,
    date: '2024-09-27',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 9540,
    pageViews: 52,
    date: '2024-09-28',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 6360,
    pageViews: 46,
    date: '2024-09-29',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 17,
    totalSalesAmount: 26250,
    pageViews: 80,
    date: '2024-09-30',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 11130,
    pageViews: 67,
    date: '2024-10-01',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 14310,
    pageViews: 71,
    date: '2024-10-02',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 15,
    totalSalesAmount: 23850,
    pageViews: 59,
    date: '2024-10-03',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4770,
    pageViews: 45,
    date: '2024-10-04',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7950,
    pageViews: 47,
    date: '2024-10-05',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 11130,
    pageViews: 31,
    date: '2024-10-06',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 6360,
    pageViews: 43,
    date: '2024-10-07',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 6360,
    pageViews: 41,
    date: '2024-10-08',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 11130,
    pageViews: 52,
    date: '2024-10-09',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7950,
    pageViews: 31,
    date: '2024-10-10',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 12720,
    pageViews: 24,
    date: '2024-10-11',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 45,
    date: '2024-10-12',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 11130,
    pageViews: 29,
    date: '2024-10-13',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 12720,
    pageViews: 36,
    date: '2024-10-14',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1590,
    pageViews: 32,
    date: '2024-10-15',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4770,
    pageViews: 29,
    date: '2024-10-16',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 6360,
    pageViews: 20,
    date: '2024-10-17',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 11130,
    pageViews: 46,
    date: '2024-10-18',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7950,
    pageViews: 34,
    date: '2024-10-19',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 11130,
    pageViews: 41,
    date: '2024-10-20',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 6360,
    pageViews: 46,
    date: '2024-10-21',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 11130,
    pageViews: 26,
    date: '2024-10-22',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 3180,
    pageViews: 32,
    date: '2024-10-23',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 3180,
    pageViews: 33,
    date: '2024-10-24',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1590,
    pageViews: 24,
    date: '2024-10-25',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 11130,
    pageViews: 25,
    date: '2024-10-26',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 14310,
    pageViews: 25,
    date: '2024-10-27',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 6360,
    pageViews: 34,
    date: '2024-10-28',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4770,
    pageViews: 26,
    date: '2024-10-29',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7950,
    pageViews: 35,
    date: '2024-10-30',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 3180,
    pageViews: 23,
    date: '2024-10-31',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 15,
    date: '2024-11-01',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 3180,
    pageViews: 15,
    date: '2024-11-02',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 6360,
    pageViews: 30,
    date: '2024-11-03',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 9540,
    pageViews: 26,
    date: '2024-11-04',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 9540,
    pageViews: 21,
    date: '2024-11-05',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 11130,
    pageViews: 29,
    date: '2024-11-06',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4770,
    pageViews: 38,
    date: '2024-11-07',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 6360,
    pageViews: 26,
    date: '2024-11-08',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 15,
    totalSalesAmount: 23850,
    pageViews: 45,
    date: '2024-11-09',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 13,
    totalSalesAmount: 20670,
    pageViews: 38,
    date: '2024-11-10',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 9540,
    pageViews: 52,
    date: '2024-11-11',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7950,
    pageViews: 29,
    date: '2024-11-12',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7950,
    pageViews: 24,
    date: '2024-11-13',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 15900,
    pageViews: 30,
    date: '2024-11-14',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 6360,
    pageViews: 35,
    date: '2024-11-15',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 12720,
    pageViews: 29,
    date: '2024-11-16',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 12,
    totalSalesAmount: 19080,
    pageViews: 36,
    date: '2024-11-17',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4770,
    pageViews: 27,
    date: '2024-11-18',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 14310,
    pageViews: 23,
    date: '2024-11-19',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 3180,
    pageViews: 28,
    date: '2024-11-20',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 12720,
    pageViews: 24,
    date: '2024-11-21',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4770,
    pageViews: 25,
    date: '2024-11-22',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 11130,
    pageViews: 23,
    date: '2024-11-23',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4500,
    pageViews: 25,
    date: '2024-11-24',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 6000,
    pageViews: 28,
    date: '2024-11-25',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 15000,
    pageViews: 26,
    date: '2024-11-26',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 3000,
    pageViews: 36,
    date: '2024-11-27',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 3000,
    pageViews: 12,
    date: '2024-11-28',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2940,
    pageViews: 17,
    date: '2024-11-29',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 12,
    date: '2024-11-30',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4440,
    pageViews: 13,
    date: '2024-12-01',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 14800,
    pageViews: 27,
    date: '2024-12-02',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 14800,
    pageViews: 28,
    date: '2024-12-03',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 27,
    date: '2024-12-04',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2960,
    pageViews: 34,
    date: '2024-12-05',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4440,
    pageViews: 22,
    date: '2024-12-06',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 14800,
    pageViews: 21,
    date: '2024-12-07',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2960,
    pageViews: 20,
    date: '2024-12-08',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2960,
    pageViews: 24,
    date: '2024-12-09',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2960,
    pageViews: 41,
    date: '2024-12-10',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 13320,
    pageViews: 30,
    date: '2024-12-11',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 10360,
    pageViews: 29,
    date: '2024-12-12',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 2960,
    pageViews: 31,
    date: '2024-12-13',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8880,
    pageViews: 34,
    date: '2024-12-14',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 24,
    date: '2024-12-15',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 13,
    totalSalesAmount: 19240,
    pageViews: 21,
    date: '2024-12-16',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 28,
    date: '2024-12-17',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 13320,
    pageViews: 34,
    date: '2024-12-18',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 10360,
    pageViews: 23,
    date: '2024-12-19',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8880,
    pageViews: 28,
    date: '2024-12-20',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 7,
    date: '2024-12-21',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8880,
    pageViews: 16,
    date: '2024-12-22',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4440,
    pageViews: 19,
    date: '2024-12-23',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 14,
    date: '2024-12-24',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 17,
    date: '2024-12-25',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8880,
    pageViews: 29,
    date: '2024-12-26',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 13320,
    pageViews: 38,
    date: '2024-12-27',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 12,
    totalSalesAmount: 17760,
    pageViews: 30,
    date: '2024-12-28',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 27,
    date: '2024-12-29',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1480,
    pageViews: 34,
    date: '2024-12-30',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1480,
    pageViews: 21,
    date: '2024-12-31',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 10360,
    pageViews: 25,
    date: '2025-01-01',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 13320,
    pageViews: 24,
    date: '2025-01-02',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 10360,
    pageViews: 33,
    date: '2025-01-03',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5920,
    pageViews: 24,
    date: '2025-01-04',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 23,
    date: '2025-01-05',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 30,
    date: '2025-01-06',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 14800,
    pageViews: 34,
    date: '2025-01-07',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8880,
    pageViews: 25,
    date: '2025-01-08',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 11,
    totalSalesAmount: 16280,
    pageViews: 46,
    date: '2025-01-09',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 13320,
    pageViews: 41,
    date: '2025-01-10',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 13320,
    pageViews: 39,
    date: '2025-01-11',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 27,
    date: '2025-01-12',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4440,
    pageViews: 36,
    date: '2025-01-13',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 5970,
    pageViews: 20,
    date: '2025-01-14',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 5970,
    pageViews: 34,
    date: '2025-01-15',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 13930,
    pageViews: 22,
    date: '2025-01-16',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 5970,
    pageViews: 85,
    date: '2025-01-17',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 9950,
    pageViews: 115,
    date: '2025-01-18',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 3980,
    pageViews: 72,
    date: '2025-01-19',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 7960,
    pageViews: 83,
    date: '2025-01-20',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 12,
    totalSalesAmount: 23880,
    pageViews: 71,
    date: '2025-01-21',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 5970,
    pageViews: 42,
    date: '2025-01-22',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 5970,
    pageViews: 67,
    date: '2025-01-23',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 3980,
    pageViews: 92,
    date: '2025-01-24',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1990,
    pageViews: 81,
    date: '2025-01-25',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 11940,
    pageViews: 92,
    date: '2025-01-26',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1990,
    pageViews: 55,
    date: '2025-01-27',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1990,
    pageViews: 66,
    date: '2025-01-28',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 7560,
    pageViews: 62,
    date: '2025-01-29',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 5970,
    pageViews: 97,
    date: '2025-01-30',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 9950,
    pageViews: 56,
    date: '2025-01-31',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 7160,
    pageViews: 96,
    date: '2025-02-01',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 10740,
    pageViews: 90,
    date: '2025-02-02',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 8950,
    pageViews: 87,
    date: '2025-02-03',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 10740,
    pageViews: 59,
    date: '2025-02-04',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 10740,
    pageViews: 52,
    date: '2025-02-05',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 5370,
    pageViews: 49,
    date: '2025-02-06',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 3580,
    pageViews: 83,
    date: '2025-02-07',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 103,
    date: '2025-02-08',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 5370,
    pageViews: 118,
    date: '2025-02-09',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 8950,
    pageViews: 100,
    date: '2025-02-10',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 17900,
    pageViews: 83,
    date: '2025-02-11',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 5370,
    pageViews: 77,
    date: '2025-02-12',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 8950,
    pageViews: 73,
    date: '2025-02-13',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 16110,
    pageViews: 71,
    date: '2025-02-14',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 8950,
    pageViews: 80,
    date: '2025-02-15',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1790,
    pageViews: 106,
    date: '2025-02-16',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 9290,
    pageViews: 66,
    date: '2025-02-17',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 14710,
    pageViews: 106,
    date: '2025-02-18',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 9000,
    pageViews: 112,
    date: '2025-02-19',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 10500,
    pageViews: 100,
    date: '2025-02-20',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 4500,
    pageViews: 51,
    date: '2025-02-21',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 11,
    totalSalesAmount: 12000,
    pageViews: 41,
    date: '2025-02-22',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 3000,
    pageViews: 71,
    date: '2025-02-23',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 4500,
    pageViews: 78,
    date: '2025-02-24',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7500,
    pageViews: 100,
    date: '2025-02-25',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7500,
    pageViews: 96,
    date: '2025-02-26',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 13500,
    pageViews: 88,
    date: '2025-02-27',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 5960,
    pageViews: 103,
    date: '2025-02-28',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 11840,
    pageViews: 109,
    date: '2025-03-01',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 13320,
    pageViews: 139,
    date: '2025-03-02',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 13320,
    pageViews: 175,
    date: '2025-03-03',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 14800,
    pageViews: 101,
    date: '2025-03-04',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 13,
    totalSalesAmount: 19240,
    pageViews: 108,
    date: '2025-03-05',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 10360,
    pageViews: 84,
    date: '2025-03-06',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 13320,
    pageViews: 82,
    date: '2025-03-07',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7480,
    pageViews: 116,
    date: '2025-03-08',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7380,
    pageViews: 126,
    date: '2025-03-09',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 86,
    date: '2025-03-10',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 11840,
    pageViews: 114,
    date: '2025-03-11',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8880,
    pageViews: 94,
    date: '2025-03-12',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 10360,
    pageViews: 123,
    date: '2025-03-13',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 11,
    totalSalesAmount: 16280,
    pageViews: 145,
    date: '2025-03-14',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 10360,
    pageViews: 127,
    date: '2025-03-15',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 11,
    totalSalesAmount: 16280,
    pageViews: 139,
    date: '2025-03-16',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 13320,
    pageViews: 82,
    date: '2025-03-17',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 7400,
    pageViews: 84,
    date: '2025-03-18',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 8880,
    pageViews: 64,
    date: '2025-03-19',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 31,
    date: '2025-03-20',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 18,
    date: '2025-03-21',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 4440,
    pageViews: 17,
    date: '2025-03-22',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 1480,
    pageViews: 17,
    date: '2025-03-23',
    skuId: '602f6e6a-241f-404a-a502-130a7f93c6a9'
  }
] as Array<ModelTypes['EtailerProductMetrics']>
