import { mApi } from '@momentum/api'
import { ValueTypes, ProposalStatus } from '@productwindtom/shared-momentum-zeus-types'
import { proposalSelector } from '@momentum/routes/proposals-create/context/queries'

export const createProposal = async (input: ValueTypes['ModelInputCreateProposal']) =>
  (
    await mApi('mutation')({
      createProposal: [
        {
          input
        },
        proposalSelector
      ]
    })
  ).createProposal

export const updateProposal = async (input: ValueTypes['ModelInputUpdateProposal']) =>
  (
    await mApi('mutation')({
      updateProposal: [
        {
          input
        },
        proposalSelector
      ]
    })
  ).updateProposal

export const deleteProposal = async (id: string) =>
  (
    await mApi('mutation')({
      deleteProposal: [
        {
          input: {
            id
          }
        },
        {
          id: true
        }
      ]
    })
  ).deleteProposal

export const createProposalSlide = async (input: ValueTypes['CreateProposalSlideInput']) =>
  (
    await mApi('mutation')({
      createProposalSlide: [
        {
          input
        },
        {
          link: true
        }
      ]
    })
  ).createProposalSlide

export const createCampaign = async (proposalId: string) => {
  try {
    return (
      await mApi('mutation')({
        createCampaign: [
          {
            proposalId
          },
          {
            id: true,
            invoiceLink: true
          }
        ]
      })
    ).createCampaign
  } catch (e: any) {
    if (e.errors) {
      throw new Error(e.errors[0].message)
    }
    throw e
  }
}

export const checkIfProposalSubmitted = async (id: string) => {
  const proposal = (
    await mApi('query')({
      getProposal: [
        { id },
        {
          id: true,
          status: true
        }
      ]
    })
  ).getProposal

  if (proposal?.status === ProposalStatus.SUBMITTED) {
    return true
  }
  return false
}
