import { PricingCreatorType } from '@productwindtom/shared-momentum-zeus-types'


export const CREATOR_TYPE_PRICING_CONFIG: Record<
  PricingCreatorType,
  {
    title: string
    lowerTitle?: string
    description: string,
    learnMore: {
      title: string,
      description: string,
      useCasesTitle: string,
      useCases: string[]
    }
  }
> = {
  [PricingCreatorType.ADVOCATE]: {
    title: 'Brand Advocates',
    lowerTitle: 'brand advocates',
    description: 'Drive SEO wins, traffic, and conversion.',
    learnMore: {
      title: 'What are brand advocates?',
      description:
        'Use brand advocate to strategically deliver sales to the listing at any time. Brand advocates will purchase your product, share the PDP with friends and family to help drive traffic and provide valuable product feedback.',
      useCasesTitle: 'When to use brand advocates:',
      useCases: [
        'Improve organic and sponsored product ranks',
        'Increase share of voice',
        'Want fast, private feedback on your product or listing'
      ]
    }
  },
  [PricingCreatorType.UGC]: {
    title: 'UGC Creators',
    lowerTitle: 'UGC creators',
    description: 'Drive incremental reviews and authentic content.',
    learnMore: {
      title: 'What are UGC creators?',
      description:
        'Use UGC creators to improve product ratings and reviews. UGC creators will purchase your product and create organic lifestyle photos and videos.',
      useCasesTitle: 'When to use UGC creators:',
      useCases: [
        'Drive incremental reviews to your listing',
        'Boost the star rating of your listing with incremental reviews',
        'Get lifestyle content of your product'
      ]
    }
  },
  [PricingCreatorType.PREMIUM_UGC]: {
    title: 'Premium UGC Creators',
    lowerTitle: 'premium UGC creators',
    description: 'Creators that are skilled at creating higher quality images and short form video.',
    learnMore: {
      title: 'What are Premium UGC creators?',
      description:
        'Use Premium UGC creators to generate high quality lifestyle photos and videos which you can repurpose.',
      useCasesTitle: 'When to use Premium UGC creators:',
      useCases: [
        'Get high quality lifestyle content of your product'
      ]
    }
  },
  [PricingCreatorType.SOCIAL]: {
    title: 'Social Creators',
    lowerTitle: 'social creators',
    description: 'Creators that publicly post content to their own social channels (TikTok + Instagram).',
    learnMore: {
      title: 'What are Social creators?',
      description:
        'Use social creators to create engaging, high quality content posted to social media. Generate word-of-mouth marketing across social channels like Instagram, TikTok, and Youtube.',
      useCasesTitle: 'When to use Social creators:',
      useCases: [
        'Get engaging, quality content posted to social channels',
        'Drive quality traffic to your PDP',
        'Generate brand awareness and upper funnel metrics'
      ]
    }
  }
}
