import { Stack } from '@mui/material'
import { useReviewsContext, TopLevelViewType } from '@momentum/routes/campaign/e-commerce/reviewsV2/reviewsContext'
import { ToggleSwitch } from '@momentum/components/toggle-switch'

export const TopLevelToggle = () => {
  const { topLevelViewType, setTopLevelViewType } = useReviewsContext()
  return (
    <Stack direction={'row'} justifyContent={'flex-end'}>
      <ToggleSwitch
        value={topLevelViewType}
        values={[
          { label: 'Reviews', value: TopLevelViewType.REVIEWS },
          { label: 'Star rating', value: TopLevelViewType.STAR_RATING }
        ]}
        onChange={v => setTopLevelViewType(v)}
      />
    </Stack>
  )
}
