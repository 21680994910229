import { ProposalCreateForm } from '@momentum/routes/proposals-create/types'
import { getCustomFeedbackResponsesForBrandAdvocates } from '@momentum/utils/proposalUtils'
import { Add, InfoOutlined } from '@mui/icons-material'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import LockIcon from '@mui/icons-material/Lock'
import QuestionAnswerOutlined from '@mui/icons-material/QuestionAnswerOutlined'
import {
  Alert,
  Box,
  Button,
  Chip,
  IconButton,
  TableCell as MuiTableCell,
  Stack,
  styled,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import { CUSTOM_FEEDBACK_RESPONSE_COST } from '@productwindtom/shared-momentum'
import {
  PricingCreatorType,
  ProposalCustomFeedbackQuestionType,
  ProposalCustomFeedbackQuestionValidationType,
  ProposalGoal
} from '@productwindtom/shared-momentum-zeus-types'
import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import * as uuid from 'uuid'
import { MAX_CUSTOM_QUESTIONS, MAX_CUSTOM_QUESTIONS_LENGTH } from '../validations'

const includedQuestions = ['What did you love about this product?', 'What would you improve about this product?']

const TableCell = styled(MuiTableCell)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey.A200}`,
  padding: '8px',
  alignContent: 'center',
  justifyContent: 'center',
  fontFamily: 'Avenir',
  fontSize: '14px',
  fontWeight: 400,
  letterSpacing: '0.28px',
  textAlign: 'center'
}))

export const CustomFeedbackQuestionsInput = () => {
  const { getValues, setValue } = useFormContext<ProposalCreateForm>()

  const goal = getValues('goal')
  const creatorPricing = getValues('creatorPricing')
  const customFeedbackQuestions = getValues('customFeedbackQuestions')

  const [addQuestionEnabled, setAddQuestionEnabled] = useState(
    goal === ProposalGoal.MARKET_RESEARCH && (customFeedbackQuestions?.length ?? 0) < MAX_CUSTOM_QUESTIONS
  )
  const [inputQuestionText, setInputQuestionText] = useState('')

  const totalBrandAdvocates = creatorPricing.find(cp => cp.type === PricingCreatorType.ADVOCATE)?.numCreators ?? 0

  useEffect(() => {
    setValue(
      'customFeedbackQuestions',
      customFeedbackQuestions?.map(q => ({
        ...q,
        creditsCost: totalBrandAdvocates * CUSTOM_FEEDBACK_RESPONSE_COST
      }))
    )
  }, [totalBrandAdvocates])

  const handleAddQuestion = () => {
    setValue('customFeedbackQuestions', [
      ...(customFeedbackQuestions ?? []),
      {
        id: uuid.v4(),
        text: inputQuestionText,
        type: ProposalCustomFeedbackQuestionType.textarea,
        validation: [
          {
            type: ProposalCustomFeedbackQuestionValidationType.response_correctness
          },
          {
            type: ProposalCustomFeedbackQuestionValidationType.required
          },
          {
            type: ProposalCustomFeedbackQuestionValidationType.min_length,
            parameters: JSON.stringify({
              value: 90
            })
          }
        ],
        creditsCost: totalBrandAdvocates * CUSTOM_FEEDBACK_RESPONSE_COST
      }
    ])
    setInputQuestionText('')
    setAddQuestionEnabled(false)
  }

  const handleRemoveQuestion = (index: number) => {
    setValue(
      'customFeedbackQuestions',
      customFeedbackQuestions?.slice(0, index).concat(customFeedbackQuestions?.slice(index + 1))
    )
  }

  const isQuestionInputValid = inputQuestionText?.trim() && inputQuestionText.length <= MAX_CUSTOM_QUESTIONS_LENGTH
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      if (isQuestionInputValid) {
        handleAddQuestion()
      }
    }
  }

  return (
    <Stack spacing={2}>
      <TableContainer
        sx={{
          borderRadius: '4px',
          borderTop: t => `1px solid ${t.palette.grey.A200}`,
          borderLeft: t => `1px solid ${t.palette.grey.A200}`,
          borderRight: t => `1px solid ${t.palette.grey.A200}`
        }}
      >
        <Table size={'small'}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ textAlign: 'left', py: 0.5 }}>
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                  <Box
                    sx={{
                      height: 24,
                      width: 24,
                      bgcolor: theme => theme.palette.primary.lighter,
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <QuestionAnswerOutlined
                      color={'info'}
                      sx={{
                        height: 18,
                        width: 18
                      }}
                    />
                  </Box>
                  <Typography variant={'label2'}>Questions for brand advocates</Typography>
                </Stack>
              </TableCell>
              <TableCell width={100} sx={{ textAlign: 'left', py: 0.5 }}>
                <Stack direction={'row'} spacing={0.5} alignItems={'end'}>
                  <Typography variant={'label2'} textAlign={'start'}>
                    Expected responses{' '}
                    <Tooltip title={'On average, 50% of Brand Advocate creators provide product feedback!'}>
                      <InfoOutlined
                        fontSize={'mSmall'}
                        sx={{ color: theme => theme.palette.grey.A700, verticalAlign: 'sub' }}
                      />
                    </Tooltip>
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell width={120} sx={{ py: 0.5 }}>
                <Typography variant={'label2'}>Cost</Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {includedQuestions.map((q, index) => (
              <TableRow
                key={`customFeedbackQuestions_default_question_${index}`}
                data-cy={`customFeedbackQuestions_default_question_${index}`}
              >
                <TableCell style={{ textAlign: 'left' }}>
                  <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <LockIcon color={'info'} sx={{ alignSelf: 'center', height: 16, width: 16 }} />
                    <Typography variant={'label4'}>{q}</Typography>
                  </Stack>
                </TableCell>
                <TableCell>
                  <Typography variant={'label4'}>-</Typography>
                </TableCell>
                <TableCell>
                  <Chip
                    size={'small'}
                    label={'Included'}
                    sx={{
                      bgcolor: theme => theme.palette.info.main,
                      color: 'white'
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}

            {customFeedbackQuestions?.map((q, index) => (
              <TableRow key={q.id} data-cy={`customFeedbackQuestions_question_${index}`}>
                <TableCell style={{ textAlign: 'left' }}>
                  <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <IconButton onClick={() => handleRemoveQuestion(index)} size={'small'} sx={{ p: 0 }}>
                      <CancelRoundedIcon
                        sx={{ color: 'black', alignSelf: 'center', opacity: 0.26, height: 16, width: 16 }}
                      />
                    </IconButton>
                    <Typography variant={'label4'}>{q.text}</Typography>
                  </Stack>
                </TableCell>
                <TableCell>
                  <Typography variant={'label4'}>
                    {getCustomFeedbackResponsesForBrandAdvocates(totalBrandAdvocates)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={'label4'}>{q.creditsCost.toLocaleString()} credits</Typography>
                </TableCell>
              </TableRow>
            ))}

            {addQuestionEnabled && (
              <TableRow data-cy={'customFeedbackQuestions_input_row'}>
                <TableCell style={{ textAlign: 'left' }}>
                  <Typography variant={'label4'}>
                    <Typography variant={'label4'} ml={3}>
                      --
                    </Typography>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={'label4'}>
                    <Typography variant={'label4'}>--</Typography>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={'label4'}>
                    <Typography variant={'label4'}>-- credits</Typography>
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {addQuestionEnabled && (
        <Stack spacing={1}>
          <TextField
            data-cy={'customFeedbackQuestions_input'}
            value={inputQuestionText}
            variant={'outlined'}
            placeholder={'Enter product feedback question'}
            fullWidth
            multiline
            onKeyDown={handleKeyDown}
            onChange={e => setInputQuestionText(e.target.value)}
          />
          <Typography
            variant={'body1'}
            color={inputQuestionText.length <= MAX_CUSTOM_QUESTIONS_LENGTH ? 'black' : 'red'}
            align="right"
            sx={{ mt: 1 }}
          >
            {inputQuestionText.length} / {MAX_CUSTOM_QUESTIONS_LENGTH}
          </Typography>
        </Stack>
      )}

      {(customFeedbackQuestions?.length ?? 0) >= MAX_CUSTOM_QUESTIONS && (
        <Alert variant="outlined" severity="info" data-cy="customFeedbackQuestions_maxQuestions">
          <Typography variant="label3">
            In order to maintain a high response rate, we only allow two custom questions per campaign.
          </Typography>
        </Alert>
      )}

      <Button
        data-cy={'customFeedbackQuestions_input_add'}
        startIcon={<Add />}
        variant={'text'}
        onClick={() => setAddQuestionEnabled(true)}
        disabled={addQuestionEnabled || (customFeedbackQuestions?.length ?? 0) >= MAX_CUSTOM_QUESTIONS}
        sx={{ alignSelf: 'flex-start', typography: 'link2' }}
      >
        Add product feedback question
      </Button>
    </Stack>
  )
}
