import { Box, Stack, Typography } from '@mui/material'

type ButtonToggleGroupProps<T> = {
  value: T
  values: { label: string; value: T }[]
  onChange?: (value: T) => void
}

export const ButtonToggleGroup = <T,>({ value, values, onChange }: ButtonToggleGroupProps<T>) => {
  return (
    <Stack
      direction={'row'}
      spacing={1}
      sx={{
        borderRadius: '50px',
        padding: '8px',
        background: theme => theme.palette.primary.lighter
      }}
    >
      {values.map((v, i) => (
        <Box
          key={i}
          onClick={() => v.value !== value && v.value != null && onChange?.(v.value)}
          sx={{
            flexGrow: 1,
            width: '100%',
            cursor: 'pointer',
            padding: '8px 12px',
            textAlign: 'center',
            transition: 'all 0.3s',
            ...(v.value === value && {
              borderRadius: '100px',
              background: t => t.palette.primary.main
            })
          }}
        >
          <Typography
            variant={'label2'}
            color={v.value === value ? 'white' : 'black'}
            sx={{
              ...(v.value !== value && { opacity: 0.5 })
            }}
          >
            {v.label}
          </Typography>
        </Box>
      ))}
    </Stack>
  )
}
