import { ProposalGoal } from '@productwindtom/shared-momentum-zeus-types'

export const CampaignGoalInformation: Record<ProposalGoal, { name: string; description: string; benefits: string[] }> =
  {
    PRODUCT_LAUNCH: {
      name: 'Product launch',
      description:
        'Run a ProductWind campaign to quickly ramp up your new product, maximizing visibility and significantly reducing the time from launch to achieving sales momentum. ',
      benefits: ['SEO wins', 'Incremental reviews']
    },
    EXISTING_PRODUCT: {
      name: 'Boost existing product',
      description:
        'Boost demand for an existing products ahead of promotional periods or peak seasons. Drive traffic, sales, and reviews/ratings, positioning the product for enhanced performance during key periods.',
      benefits: ['SEO wins', 'Incremental reviews']
    },
    BOOST_RATING: {
      name: 'Review boost',
      description:
        'Accumulate authentic reviews and improved star ratings to make your product more likely to convert potential buyers, significantly enhancing its market performance.',
      benefits: ['Incremental reviews']
    },
    EVERGREEN: {
      name: 'Evergreen',
      description:
        'An always-on, continuous marketing approach that extends the life of a product online. This campaign type maintains product visibility and sales performance over an extended period, ideal for sustaining momentum post-launch.',
      benefits: ['SEO wins', 'Incremental reviews', 'Relevancy']
    },
    EVENT: {
      name: 'Seasonal event',
      description:
        "Run a ProductWind campaign before a seasonal event to increase the product's visibility when traffic is low and it's easier to gain share of voice",
      benefits: ['SEO wins']
    },
    MARKET_RESEARCH: {
      name: 'Market research',
      description:
        'Ask your brand advocates customized questions to get early product feedback from consumers while driving SEO',
      benefits: ['SEO wins']
    },
    SUPPORT_MARKETING_PROMOTION: {
      name: 'Support marketing promotion',
      description:
        "Run a ProductWind campaign during a promotion period to increase the product's visibility and supercharge your promotion results.",
      benefits: ['SEO wins']
    },
    BOOST_RUFUS: {
      name: 'Boost Rufus',
      description:
        "Want to win Rufus? Run a ProductWind campaign to boost SEO and win as many long tail search keywords as possible.",
      benefits: ['SEO wins']
    },
    OTHER: {
      name: 'Other',
      description: 'Select this option if your campaign goal does not fit any of the other categories.',
      benefits: []
    }
  }
