import { ProposalCreateForm } from '@momentum/routes/proposals-create/types'
import { notEmpty } from '@productwindtom/shared-node'
import { keyBy, maxBy } from 'lodash'
import { useFormContext } from 'react-hook-form'
import { useCreateProposalContext } from './context/CreateProposalContext'

export const useMostExpensiveVariation = () => {
    const { selectableProducts, selectedProduct } = useCreateProposalContext()
    const {  getValues } = useFormContext<ProposalCreateForm>()

    const productVariationSkus = getValues('productVariationSkus')
      const keyedProducts = keyBy(selectableProducts, 'id')
      const selectedVariations =
        selectedProduct &&
        !!productVariationSkus?.length &&
        selectedProduct?.childrenIds
          ?.map(id => keyedProducts[id])
          .filter(notEmpty)
          .filter(v => v.skuId && productVariationSkus.includes(v.skuId))

      return maxBy(selectedVariations || [], 'priceCents')
}