import ProductSelectorV2 from '@momentum/components/form-inputs/ProductSelector'
import ProductVariationsInput from '@momentum/components/form-inputs/ProductVariationsInput'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { ROUTE_NAMES_PROPOSAL } from '@momentum/routes/RouteNames'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { SaveNextButton } from '@momentum/routes/proposals-create/common/SaveNextButton'
import { BOX_PROPS } from '@momentum/routes/proposals-create/common/styling'
import { requestExchangeRate } from '@momentum/routes/proposals-create/context/queries'
import { EventDateAlerts } from '@momentum/routes/proposals-create/product-creators/EventDateAlerts'
import { SeasonalEventGraph } from '@momentum/routes/proposals-create/product-creators/SeasonalEventGraph'
import { useRecommendation } from '@momentum/routes/proposals-create/product-creators/useRecommendation'
import { ProposalCreateForm, ProposalCreatorPricingInput } from '@momentum/routes/proposals-create/types'
import { creatorProductsFormValidationSchema } from '@momentum/routes/proposals-create/validations'
import { CreatorPricing } from '@momentum/routes/queries'
import { CREATOR_TYPE_PRICING_CONFIG } from '@momentum/utils/brandPricing'
import { CampaignGoalInformation } from '@momentum/utils/campaignGoalUtils'
import { isHoliday, isWeekend, minusBusinessDays } from '@momentum/utils/dateUtils'
import { EVENTS_BY_TYPE } from '@momentum/utils/eventUtils'
import { CampaignGoalTypeToIcon } from '@momentum/utils/goalIcons'
import { RECOMMENDATION_SUPPORTED_PROPOSAL_TYPES, getMinLaunchDate } from '@momentum/utils/proposalUtils'
import { getCreatorPricingStore } from '@momentum/utils/storeUtils'
import {
  AddCircleRounded,
  CancelRounded,
  CheckCircle,
  CheckCircleOutlined,
  Close,
  InfoOutlined
} from '@mui/icons-material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Alert, Button, Chip, Collapse, Grid, IconButton, Link, Tooltip, Typography, useTheme } from '@mui/material'
import { Box, Stack } from '@mui/system'
import {
  DEFAULT_PRICING,
  MANAGED_SERVICE_CREATOR_TYPES,
  STORE_TO_CURRENCY,
  StorePricing,
  getAutoBuysDistribution,
  getBrandStorePricing,
  getSocialCreatorDistribution,
  toWeeklyPartition
} from '@productwindtom/shared-momentum'
import {
  CreatorType,
  PricingCreatorType,
  ProposalGoal,
  ProposalPromoEventType,
  Region,
  Store
} from '@productwindtom/shared-momentum-zeus-types'
import { toLocaleCurrency } from '@productwindtom/shared-node'
import { DateInput, NumberInput, SubmitButton } from '@productwindtom/ui-base'
import { useFlag } from '@unleash/proxy-client-react'
import { get, keyBy, max, sumBy } from 'lodash'
import { DateTime } from 'luxon'
import { ReactNode, useEffect, useState } from 'react'
import { UseFormSetValue, useController, useFormContext, useWatch } from 'react-hook-form'
import { Question } from '../../../components/proposal-common/Question'
import { useCreateProposalContext } from '../context/CreateProposalContext'
import PlaceholderProductAlert from '../modules/placeholder-product-alert'
import { useMostExpensiveVariation } from '../useMostExpensiveVariation'
import { EventSelector } from './EventSelector'
import { ExtraFeesForm } from './ExtraFeesForm'
import { SelectedProduct } from './SelectedProduct'
import { SupportMarketingPromotionRecommendationAlert } from '@momentum/routes/proposals-create/product-creators/SupportMarketingPromotionRecommendationAlert'

export const ProductCreators = () => {
  const selfServiceFlag = useFlag('ProposalSelfServiceSetup')
  const isProposalVariationsEnabled = useFlag('ProposalVariations')

  const { isAdminView, agencies } = useUserSessionContext()
  const { company, brand, loadingProducts, setIsAddProductActiveOrCallback, products } = useBrandContext()
  const { selectableProducts, selectedProduct, setSelectedProduct, recommendationType, proposal } =
    useCreateProposalContext()

  const { setValue, getValues, watch, trigger } = useFormContext<ProposalCreateForm>()

  const mostExpensiveVariation = useMostExpensiveVariation()

  const selectedProductId = watch('productId')
  const selectedGoal = watch('goal')
  const estimatedUnitsSoldPerMonth = watch('estimatedUnitsSoldPerMonth')
  const customFeedbackResponses = watch('customFeedbackResponses')
  const eventDate = watch('eventDate')
  const eventEndDate = watch('eventEndDate')
  const eventType = watch('eventType')
  const launchDate = watch('launchDate')
  const recommendedLaunchDate = watch('recommendedLaunchDate')
  const minLaunchDate = getMinLaunchDate(brand.region)

  const eventConfig = eventType ? EVENTS_BY_TYPE[eventType] : undefined

  const creatorPricing = watch('creatorPricing')
  const managedServiceFee = watch('managedServiceFee')
  const managedServiceFeeAmount = managedServiceFee ? toLocaleCurrency(managedServiceFee) : null

  const creatorCount = sumBy(creatorPricing, 'numCreators')

  const productPricingStore = selectedProduct?.store ? getCreatorPricingStore(selectedProduct.store) : undefined
  const pricing = getBrandStorePricing(
    brand.region,
    brand.pricing,
    company.pricing,
    (agencies.find(a => a.id === company.agencyId) ?? company.agency)?.clientPricing
  ).find(sp => sp.store === productPricingStore)

  const {
    warnings,
    isDifferent,
    creatorCounts: recommendationCreatorCounts,
    durationWeeks,
    isSelfServiceDisabled,
    isSelfServiceSupported,
    saveRecommendation,
    savedRecommendation,
    clearSavedRecommendation
  } = useRecommendation()

  const storeCurrency = selectedProduct?.store ? STORE_TO_CURRENCY[selectedProduct?.store] : undefined

  useEffect(() => {
    if (selectedProductId) {
      const foundProduct = selectableProducts?.find(p => p.id === selectedProductId)
      if (foundProduct) {
        setSelectedProduct(foundProduct)
      }
    } else if (selectedProduct && !selectedProductId) {
      // This is to handle when setting the selected product from add manual product.
      setSelectedProduct(undefined)
    }
  }, [selectedProduct, selectedProductId, selectableProducts, setSelectedProduct])

  // Refresh exchange rate every time product currency changes
  useEffect(() => {
    if (selectedProduct) {
      if (selectedProduct.id === proposal?.productId) {
        setValue('exchangeRate', proposal.exchangeRate || 1, {
          shouldValidate: true
        })
      } else {
        requestExchangeRate(STORE_TO_CURRENCY[selectedProduct.store]).then(exchangeRate => {
          setValue('exchangeRate', exchangeRate, {
            shouldValidate: true
          })
        })
      }
    } else {
      setValue('exchangeRate', undefined, {
        shouldValidate: true
      })
    }
  }, [storeCurrency])

  useEffect(() => {
    clearSavedRecommendation()
  }, [selectedGoal])

  useEffect(() => {
    handleRecommendedDates({
      goal: selectedGoal,
      creatorPricingWithSchedule: getValues('creatorPricing'),
      brandRegion: brand.region,
      event: eventType,
      eventDate,
      setValue
    })
  }, [eventDate])

  useEffect(() => {
    if (selectedGoal === ProposalGoal.EVENT) {
      const event = eventType ? EVENTS_BY_TYPE[eventType] : undefined
      if (event) {
        setValue('eventDate', event.eventDate)
      } else {
        setValue('eventDate', undefined)
      }
    } else if (selectedGoal !== ProposalGoal.SUPPORT_MARKETING_PROMOTION) {
      setValue('eventDate', undefined)
      setValue('recommendedLaunchDate', undefined)
      setValue('eventType', undefined)
    }
  }, [eventType, selectedGoal])

  useEffect(() => {
    const existingValues = getValues()
    if (selectedProduct) {
      const productPrice = mostExpensiveVariation?.priceCents || selectedProduct.priceCents
      if (existingValues.productPrice !== productPrice) {
        setValue('productPrice', productPrice)
      }
      if (existingValues.productRating !== selectedProduct.ratingSummary?.rating) {
        setValue('productRating', selectedProduct.ratingSummary?.rating)
      }
      if (existingValues.productRatingCount !== selectedProduct.ratingSummary?.numRatings) {
        setValue('productRatingCount', selectedProduct.ratingSummary?.numRatings)
      }
    }
  }, [selectedProduct, mostExpensiveVariation, selectedGoal, recommendationType, pricing?.creatorPricing])

  const onAddProduct = () => {
    setIsAddProductActiveOrCallback({
      selectedProductId: selectedProduct?.id,
      productPriceOverride: proposal?.productPriceOverride,
      callback: async params => {
        setValue('productId', params.product.id)
      }
    })
  }

  const onGenerateRecommendation = () => {
    const vals = getValues()
    if (selectedProduct && recommendationCreatorCounts) {
      const pricingWithCreators = vals.creatorPricing.map(cp => ({
        ...cp,
        numCreators: recommendationCreatorCounts[cp.type] || 0
      }))

      setValue('creatorPricing', pricingWithCreators)

      handleRecommendedDates({
        goal: selectedGoal,
        creatorPricingWithSchedule: pricingWithCreators,
        brandRegion: brand.region,
        event: eventType,
        eventDate,
        setValue
      })

      trigger('creatorPricing')
      saveRecommendation?.()
    }
  }

  const getDisabledGenerateButtonMessage = () => {
    if (!estimatedUnitsSoldPerMonth) {
      return 'Please enter how many units you expect to sell during the first 30 days of the campaign'
    }
    if (!selectedProduct) {
      return 'Please select a product'
    }
    if (!RECOMMENDATION_SUPPORTED_PROPOSAL_TYPES.includes(selectedGoal)) {
      return 'Recommendations are not available for this campaign type'
    }
  }
  const disabledButtonMessage = getDisabledGenerateButtonMessage()
  const isEstimatedUnitSoldPerMonthError = !estimatedUnitsSoldPerMonth
  const isCustomFeedbackResponsesError = !customFeedbackResponses

  return (
    <Stack spacing={2} pb={2}>
      <Stack {...BOX_PROPS} spacing={2}>
        <Question
          primaryText={'Add product'}
          subtext={'Momentum helps products selling on Amazon, Walmart, Target and other retailers.'}
        >
          {!selectedProduct && (
            <Box data-cy={'productSelector'}>
              <ProductSelectorV2
                width={450}
                products={(selectableProducts || []).filter(p => p.priceCents)}
                onAddProductClick={onAddProduct}
                loadingProducts={loadingProducts}
              />
            </Box>
          )}
          {selectedProduct && <SelectedProduct product={selectedProduct} onRemove={() => setValue('productId', '')} />}
        </Question>
        {isProposalVariationsEnabled &&
          (selectableProducts.find(p => selectedProduct?.parentSkuId === p.id)?.childrenIds?.length ||
            selectedProduct?.childrenIds?.length ||
            0) > 1 && (
            <Question primaryText={'Select eligible product variations'}>
              <ProductVariationsInput
                brandProducts={products}
                selectableProducts={selectableProducts}
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
              />
            </Question>
          )}
        <PlaceholderProductAlert isAddProductEnabled />
      </Stack>
      <Box {...BOX_PROPS}>
        <Question
          primaryText={'Select campaign goal'}
          subtext={
            <>
              Your campaign goal is the business goal you hope to achieve by running your campaign. Hover over each one
              for more information.
              <Link
                variant={'link2'}
                target="_blank"
                href="https://info.productwind.com/campaign-types"
                underline={'none'}
                ml={0.5}
                sx={{ cursor: 'pointer', display: 'inline' }}
              >
                Learn more
              </Link>
            </>
          }
        >
          <Box>
            <CampaignGoalSelector isAgency={!!company.agencyId} />
          </Box>
        </Question>
      </Box>
      {selectedGoal === ProposalGoal.EVENT && (
        <Box {...BOX_PROPS}>
          <Question primaryText={'What seasonal event do you want to prepare for?'}>
            <Box>
              <EventSelector />
            </Box>
          </Question>
        </Box>
      )}

      {!!selectedProduct && selectedGoal === ProposalGoal.SUPPORT_MARKETING_PROMOTION && (
        <Box {...BOX_PROPS}>
          <Question primaryText={'What is the start and end date of your promotion?'}>
            <Stack direction={'row'} spacing={3}>
              <DateInput
                fullWidth
                name={'eventDate'}
                minDate={minLaunchDate.date}
                slotProps={{ textField: { 'data-cy': 'eventDateInput' } }}
              />
              <DateInput
                fullWidth
                name={'eventEndDate'}
                minDate={max([eventDate?.plus({ days: 1 }), minLaunchDate.date.plus({ days: 1 })])}
                slotProps={{ textField: { 'data-cy': 'eventEndDateInput' } }}
              />
            </Stack>
          </Question>
        </Box>
      )}

      {!!selectedProduct && (
        <Box {...BOX_PROPS}>
          <Question
            primaryText={
              <>
                Without ProductWind, how many units would your product typically sell in the first 30 days of this
                campaign?{' '}
                <Tooltip title={'Momentum uses projected sales velocity to calculate your estimated campaign timeline'}>
                  <InfoOutlined fontSize={'mSmall'} sx={{ color: theme => theme.palette.grey.A700 }} />
                </Tooltip>
              </>
            }
            subtext={
              'Do NOT include expected sales from this ProductWind campaign. ProductWind uses this to estimate when you will receive reviews & ratings.'
            }
          >
            <NumberInput
              name={'estimatedUnitsSoldPerMonth'}
              data-cy={'estimatedUnitsSoldPerMonth'}
              placeholder={'Units sold'}
              returnAsNumber
              hideError
              hideZero
              error={isEstimatedUnitSoldPerMonthError}
              allowNegative={false}
            />
          </Question>
        </Box>
      )}

      {!!selectedProduct && selectedGoal === ProposalGoal.MARKET_RESEARCH && (
        <Box {...BOX_PROPS}>
          <Question
            primaryText={'What is your desired number of responses?'}
            subtext={
              'Ask your brand advocates additional customized questions to get early product feedback from consumers.'
            }
          >
            <NumberInput
              name={'customFeedbackResponses'}
              data-cy={'customFeedbackResponses'}
              placeholder={'Number of responses'}
              returnAsNumber
              hideError
              hideZero
              error={isCustomFeedbackResponsesError}
              allowNegative={false}
            />
          </Question>
        </Box>
      )}

      {!!selectedProduct && selectedGoal === ProposalGoal.BOOST_RATING && (
        <Box {...BOX_PROPS}>
          <Question
            primaryText={'What is the product’s desired star rating?'}
            subtext={`The product currently has a ${selectedProduct.ratingSummary?.rating ?? '?'} star rating with ${selectedProduct.ratingSummary?.numRatings?.toLocaleString() ?? '?'} reviews and ratings.`}
          >
            <RatingGoalQuestion />
          </Question>
        </Box>
      )}

      {!!selectedProduct && (
        <Box {...BOX_PROPS}>
          <Stack spacing={2}>
            <Question
              subtext={'You can add creators or generate a recommendation based on your monthly sales'}
              primaryText={'Add creators'}
            >
              {isSelfServiceSupported && (
                <Stack spacing={2} mb={2}>
                  <Tooltip disableHoverListener={!disabledButtonMessage} title={disabledButtonMessage}>
                    <Grid item xs={'auto'}>
                      <Button
                        variant={'outlined'}
                        size={'small'}
                        onClick={onGenerateRecommendation}
                        disabled={
                          (!isDifferent && !!savedRecommendation) ||
                          !RECOMMENDATION_SUPPORTED_PROPOSAL_TYPES.includes(selectedGoal) ||
                          !selectedProduct ||
                          !estimatedUnitsSoldPerMonth ||
                          (selectedGoal === ProposalGoal.EVENT && !eventDate) ||
                          !recommendationCreatorCounts
                        }
                        data-cy={'generateRecommendationButton'}
                      >
                        Generate creator recommendation
                      </Button>
                    </Grid>
                  </Tooltip>
                  {!!savedRecommendation && !isDifferent && (
                    <Alert
                      variant={'outlined'}
                      severity={'success'}
                      icon={<CheckCircleOutlined color={'success'} />}
                      sx={{ py: 0, px: 1 }}
                      data-cy={'recommendationMessage'}
                    >
                      <Typography variant={'label3'} flexShrink={1} color={'black'}>
                        {savedRecommendation.recommendationMessage}
                      </Typography>
                    </Alert>
                  )}
                </Stack>
              )}

              <CreatorPricingInputList />

              {isAdminView && (
                <Box mt={2}>
                  <OverridePricingSwitch brandPricing={pricing} />
                </Box>
              )}
            </Question>

            {isSelfServiceDisabled && managedServiceFeeAmount && (
              <Alert severity={'info'} variant={'outlined'} data-cy="managedCreatorsWarning">
                <Typography color={'black'} variant={'label3'}>
                  Campaigns with premium UGC or social creators require a {managedServiceFeeAmount} managed service fee
                  and cannot be scheduled through Momentum. Reach out to your sales representative to learn more about
                  scheduling a campaign with social creators.
                </Typography>
              </Alert>
            )}

            {isAdminView && <ExtraFeesForm />}

            {!!warnings.length && (
              <Stack spacing={1}>
                {warnings.map(w => (
                  <Alert key={w.message} variant={'outlined'} severity={'warning'} color={'warning'}>
                    <Typography color={'black'} variant={'label3'}>
                      {w.message}
                    </Typography>
                  </Alert>
                ))}
              </Stack>
            )}
          </Stack>
        </Box>
      )}

      {!!selectedProduct && (
        <Box {...BOX_PROPS}>
          <Stack spacing={2}>
            <Question
              primaryText={'Select campaign launch date'}
              subtext={`Your campaign must start at least ${minLaunchDate.businessDays} business days in the future${minLaunchDate.region !== Region.US ? ' because it is outside of the US.' : '.'}`}
            >
              {(!!creatorCount || selectedGoal !== ProposalGoal.EVENT) && (
                <Stack direction={'row'} spacing={1}>
                  <Box sx={{ maxWidth: 288 }}>
                    <DateInput
                      fullWidth
                      name={'launchDate'}
                      minDate={minLaunchDate.date}
                      shouldDisableDate={(day: unknown) => {
                        return isWeekend(day as DateTime) || isHoliday(day as DateTime)
                      }}
                    />
                  </Box>
                  {!!recommendedLaunchDate && (
                    <Button
                      variant={'text'}
                      disableRipple
                      disableTouchRipple
                      disabled={recommendedLaunchDate.toISODate()! === launchDate?.toISODate()}
                      onClick={() => setValue('launchDate', recommendedLaunchDate)}
                    >
                      Reset to recommendation
                    </Button>
                  )}
                </Stack>
              )}
            </Question>
            {!creatorCount && (
              <Box borderRadius={'4px'} bgcolor={'info.lightest'} p={2}>
                <Typography color={'info.main'} variant={'label3'}>
                  Your recommended launch date will populate once you add creators to your campaign.
                </Typography>
              </Box>
            )}
            {!!eventConfig && !!recommendedLaunchDate && !!creatorCount && (
              <EventDateAlerts
                launchDate={launchDate}
                eventConfig={eventConfig}
                recommendedLaunchDate={recommendedLaunchDate}
                durationWeeks={durationWeeks}
              />
            )}
            {!!eventConfig && !!creatorCount && (
              <SeasonalEventGraph eventConfig={eventConfig} durationWeeks={durationWeeks} />
            )}{' '}
            {selectedGoal === ProposalGoal.SUPPORT_MARKETING_PROMOTION &&
              !!recommendationCreatorCounts &&
              recommendedLaunchDate &&
              eventEndDate && (
                <SupportMarketingPromotionRecommendationAlert
                  recommendationCreatorCounts={recommendationCreatorCounts}
                  recommendedLaunchDate={recommendedLaunchDate}
                  recommendedEndDate={eventEndDate}
                />
              )}
          </Stack>
        </Box>
      )}

      {!!selectedProduct && isEstimatedUnitSoldPerMonthError && (
        <Alert variant={'outlined'} severity={'error'} color={'error'}>
          <Typography color={'black'} variant={'label3'}>
            You must enter your estimated units sold in order to proceed to the next step.
          </Typography>
        </Alert>
      )}

      <Stack direction={'row'} mt={4} justifyContent={'flex-end'} alignItems={'top'}>
        <Stack spacing={1}>
          {selfServiceFlag && !isSelfServiceSupported && (
            <Alert severity={'info'} variant={'outlined'}>
              <Typography color={'black'} variant={'label3'}>
                Self service is unavailable for this campaign type.
              </Typography>
            </Alert>
          )}
        </Stack>

        <Box ml={'auto'} pl={2} alignSelf={'end'}>
          {selfServiceFlag && isSelfServiceSupported && !isSelfServiceDisabled ? (
            <SaveNextButton
              disabled={isSelfServiceDisabled}
              nextPath={ROUTE_NAMES_PROPOSAL.TIMELINE}
              validationSchema={creatorProductsFormValidationSchema}
            />
          ) : (
            <SubmitButton variant={'contained'} disableOnDirty={false} data-cy="saveProposal" sx={{ minWidth: 130 }}>
              Save proposal
            </SubmitButton>
          )}
        </Box>
      </Stack>
    </Stack>
  )
}

const OverridePricingSwitch = ({ brandPricing }: { brandPricing?: StorePricing }) => {
  const { setValue, getValues, watch } = useFormContext()

  const isOverrideEnabled = watch('isOverridePricing')
  const resetPricing = () => {
    setValue(
      'creatorPricing',
      getValues('creatorPricing').map((p: CreatorPricing) => ({
        ...p,
        price:
          brandPricing?.creatorPricing.find(c => c.type === p.type)?.price ||
          brandPricing?.creatorPricing.find(c => c.type === p.type)?.fallbackPrice ||
          DEFAULT_PRICING[p.type].default
      }))
    )
    setValue('isOverridePricing', false)
  }

  const setOverrideEnabled = () => {
    setValue('isOverridePricing', true)
  }

  return (
    <Stack direction={'row'} spacing={2}>
      <Button variant={'text'} disabled={isOverrideEnabled} onClick={setOverrideEnabled}>
        Override pricing
      </Button>
      {!!isOverrideEnabled && (
        <Button variant={'text'} onClick={resetPricing}>
          Reset to default
        </Button>
      )}
    </Stack>
  )
}

const disabledMessage = (type: ProposalGoal, store?: Store) => {
  if (type === ProposalGoal.BOOST_RUFUS && store !== Store.amazon) {
    return (
      <Typography variant="body1">
        This campaign type is only available for <strong>Amazon</strong> products.
      </Typography>
    )
  }
}

const CampaignGoalSelector = ({ isAgency }: { isAgency?: boolean }) => {
  const holidayCampaignTypeFlag = useFlag('HolidayCampaignType')
  const marketResearchTypeFlag = useFlag('MarketResearchType')
  const supportMarketingPromotionTypeFlag = useFlag('SupportMarketingPromotion')
  const rufusCampaignTypeFlag = useFlag('RufusCampaignType')
  const goalTypes = [
    ProposalGoal.PRODUCT_LAUNCH,
    ProposalGoal.EXISTING_PRODUCT,
    ProposalGoal.BOOST_RATING,
    ...(holidayCampaignTypeFlag ? [ProposalGoal.EVENT] : []),
    ...(!isAgency ? [ProposalGoal.EVERGREEN] : []),
    ...(supportMarketingPromotionTypeFlag ? [ProposalGoal.SUPPORT_MARKETING_PROMOTION] : []),
    ...(marketResearchTypeFlag ? [ProposalGoal.MARKET_RESEARCH] : []),
    ...(rufusCampaignTypeFlag ? [ProposalGoal.BOOST_RUFUS] : [])
  ]
  const betaTypes = [ProposalGoal.BOOST_RUFUS]

  const [hoveredType, setHoveredType] = useState<ProposalGoal>()
  const { selectedProduct } = useCreateProposalContext()

  const { control } = useFormContext<ProposalCreateForm>()
  const {
    field: { value, onChange }
  } = useController<ProposalCreateForm, 'goal'>({ name: 'goal', control })

  const typeInformationToDisplay = get(CampaignGoalInformation, hoveredType || value)

  return (
    <Stack direction={'row'} spacing={2}>
      <Stack spacing={1} flexGrow={1}>
        {goalTypes.map(type => (
          <CampaignGoalOption
            key={type}
            type={type}
            selected={value === type}
            isHover={hoveredType === type}
            beta={betaTypes.includes(type)}
            disabledMessage={disabledMessage(type, selectedProduct?.store)}
            onClick={() => onChange(type)}
            onMouseEnter={() => setHoveredType(type)}
            onMouseLeave={() => setHoveredType(undefined)}
          />
        ))}
      </Stack>
      {typeInformationToDisplay && (
        <Stack spacing={2} width={'50%'}>
          <Stack spacing={1}>
            <Typography variant={'label1'}>{typeInformationToDisplay.name}</Typography>
            <Typography variant={'label3'} color={'grey.A700'} minHeight={110}>
              {typeInformationToDisplay.description}
            </Typography>
          </Stack>
          <Stack spacing={1}>
            <Typography variant={'label1'}>Good for</Typography>
            <Stack spacing={1} alignItems={'self-start'} minHeight={90}>
              {typeInformationToDisplay.benefits.map(b => (
                <Box key={b} bgcolor={'primary.lighter'} px={1} borderRadius={'4px'}>
                  <Typography variant={'label3'} color={'primary'}>
                    {b}
                  </Typography>
                </Box>
              ))}
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}

const CampaignGoalOption = ({
  type,
  onClick,
  selected,
  isHover,
  beta,
  disabledMessage,
  onMouseEnter,
  onMouseLeave,
}: {
  type: ProposalGoal
  selected?: boolean
  isHover?: boolean
  beta?: boolean
  disabledMessage?: ReactNode
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  onClick: () => void
}) => {
  const unselectedColor = !disabledMessage ? 'black' : 'grey.A400'

  return (
    <Tooltip title={disabledMessage}>
      <Stack
        data-cy={`campaignGoalOption-${type}`}
        direction={'row'}
        alignItems={'center'}
        spacing={2}
        py={0.5}
        px={1}
        borderRadius={'4px'}
        bgcolor={t => (selected ? t.palette.primary.lighter : isHover ? t.palette.grey.A100 : 'white')}
        sx={{ cursor: !disabledMessage ? 'pointer' : 'default' }}
        onClick={!disabledMessage ? onClick : undefined}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Box
          bgcolor={selected ? 'primary.main' : 'grey.A100'}
          borderRadius={'4px'}
          borderColor={selected ? 'primary.main' : 'grey.A200'}
          sx={{ borderWidth: 1, borderStyle: 'solid' }}
          p={1}
          alignItems={'center'}
          justifyContent={'center'}
        >
          {CampaignGoalTypeToIcon[type]?.({
            fontSize: 'medium',
            sx: { display: 'block', color: selected ? 'white' : unselectedColor }
          })}
        </Box>
        <Stack direction={'row'} spacing={2}>
          <Typography variant={'label2'} color={selected ? 'primary' : unselectedColor}>
            {CampaignGoalInformation[type]?.name}
          </Typography>
          {beta && (
            <Chip
              label="BETA"
              size="small"
              sx={{
                borderRadius: '4px',
                background: selected ? 'white' : unselectedColor,
                color: selected ? 'primary.main' : 'white',
                fontWeight: 800,
                boxShadow: selected || (isHover && !disabledMessage)? '0px 1px 3px 2px rgba(0, 0, 0, 0.18)' : 'none'
              }}
            />
          )}
        </Stack>
      </Stack>
    </Tooltip>
  )
}

const CreatorPricingInputList = () => {
  const { watch } = useFormContext<ProposalCreateForm>()
  const pricing = watch('creatorPricing')
  const { warnings } = useRecommendation()

  return (
    <Stack borderRadius={'4px'} spacing={1}>
      {pricing.map(p => (
        <CreatorPricingInput key={p.type} type={p.type} warning={warnings.some(w => w.type === p.type)} />
      ))}
    </Stack>
  )
}

const RatingGoalQuestion = () => {
  const { formState } = useFormContext<ProposalCreateForm>()

  const error = formState.errors?.ratingGoal?.message

  return (
    <Stack spacing={1}>
      <NumberInput
        name={'ratingGoal'}
        placeholder={'Desired rating'}
        returnAsNumber
        allowNegative={false}
        hideError
        error={!!error}
        max={5}
      />
      {!!error && (
        <Alert variant={'outlined'} severity={'error'} color={'error'}>
          <Typography color={'black'} variant={'label3'}>
            {error}
          </Typography>
        </Alert>
      )}
    </Stack>
  )
}

const CreatorPricingInput = ({ type, warning }: { type: PricingCreatorType; warning?: boolean }) => {
  const { isAdminView } = useUserSessionContext()
  const { setValue, getValues } = useFormContext<ProposalCreateForm>()
  const { brand } = useBrandContext()

  const creatorPricing = useWatch<ProposalCreateForm>({ name: `creatorPricing` }) as ProposalCreatorPricingInput[]
  const index = creatorPricing.findIndex(p => p.type === type)

  const [startingValue, setStartingValue] = useState<number>(getValues(`creatorPricing.${index}.numCreators`))
  const numCreators = useWatch<ProposalCreateForm>({ name: `creatorPricing.${index}.numCreators` }) as number
  const hidden = useWatch<ProposalCreateForm>({ name: `creatorPricing.${index}.hidden` }) as boolean

  const data = useWatch<ProposalCreateForm>({ name: `creatorPricing.${index}` }) as ProposalCreatorPricingInput

  useEffect(() => {
    if (numCreators !== startingValue) {
      setStartingValue(numCreators)
      updateDistributionSchedule()
    }
  }, [numCreators])

  const updateDistributionSchedule = () => {
    const { eventType, eventDate, eventEndDate, goal, creatorPricing, isDailyScheduling } = getValues()
    const customDurationDays =
      goal === ProposalGoal.SUPPORT_MARKETING_PROMOTION && eventDate && eventEndDate
        ? eventEndDate.diff(eventDate, 'days').days
        : undefined
    const autoBuyDistribution = keyBy(getAutoBuysDistribution({ creatorPricing, customDurationDays }), 'type')
    for (let i = 0; i < creatorPricing.length; i++) {
      const c = creatorPricing[i]
      const dailySchedule = isDailyScheduling
        ? autoBuyDistribution[c.type]?.dailySchedule
        : toWeeklyPartition(autoBuyDistribution[c.type]?.dailySchedule)
      setValue(`creatorPricing.${i}.schedule`, dailySchedule)
      creatorPricing[i].schedule = dailySchedule
    }

    handleRecommendedDates({
      goal,
      creatorPricingWithSchedule: creatorPricing,
      brandRegion: brand.region,
      event: eventType,
      eventDate,
      setValue
    })
  }

  useEffect(() => {
    if (type === PricingCreatorType.SOCIAL) {
      const numCreators = data.numCreators
      const socialNumCreators = sumBy(data.socialCreatorPricing, 'numCreators')

      if (numCreators !== socialNumCreators) {
        data?.socialCreatorPricing?.forEach((pricing, socialIndex) => {
          const type = pricing.type

          if ([CreatorType.IG, CreatorType.TT].includes(type)) {
            const creatorType = pricing.type as CreatorType.IG | CreatorType.TT

            setValue(
              `creatorPricing.${index}.socialCreatorPricing.${socialIndex}.numCreators`,
              getSocialCreatorDistribution(numCreators, creatorType)
            )
            setValue(
              `creatorPricing.${index}.socialCreatorPricing.${socialIndex}.schedule`,
              pricing.schedule.map(s => getSocialCreatorDistribution(s, creatorType))
            )
          }
        })
      }
    }
  }, [type, data, setValue, index])

  if (hidden) {
    // only admins are allowed to see hidden creators
    return isAdminView ? <AddCreatorPricingInput type={type} index={index} /> : null
  }

  return <EditCreatorPricingInput type={type} warning={warning} index={index} />
}

const AddCreatorPricingInput = ({ type, index }: { type: PricingCreatorType; index: number }) => {
  const { setValue } = useFormContext<ProposalCreateForm>()
  const theme = useTheme()

  const handleAddCreatorPricing = () => {
    setValue(`creatorPricing.${index}.hidden`, false)
  }

  return (
    <Box border={`1px solid ${theme.palette.primary.lighter}`} borderRadius={1} p={2} display={'flex'}>
      <Stack direction={'row'} spacing={1}>
        <IconButton
          size={'small'}
          onClick={handleAddCreatorPricing}
          data-cy={`addCreatorPricing-${type}`}
          color="primary"
          disableRipple
          sx={{ p: 0 }}
        >
          <AddCircleRounded fontSize={'mSmall'} />
        </IconButton>
        <Typography variant={'label2'} alignSelf={'center'} color={theme => theme.palette.grey.A400}>
          {CREATOR_TYPE_PRICING_CONFIG[type]?.title}
        </Typography>
      </Stack>
    </Box>
  )
}

const EditCreatorPricingInput = ({
  type,
  warning,
  index
}: {
  type: PricingCreatorType
  warning?: boolean
  index: number
}) => {
  const [learnMoreOpen, setLearnMoreOpen] = useState(false)

  const { isAdminView } = useUserSessionContext()
  const { setValue } = useFormContext<ProposalCreateForm>()
  const theme = useTheme()

  const isOverridePricing = useWatch<ProposalCreateForm, 'isOverridePricing'>({ name: 'isOverridePricing' })
  const data = useWatch<ProposalCreateForm>({ name: `creatorPricing.${index}` }) as ProposalCreatorPricingInput

  const cost = data.numCreators * data.price

  const handleRemoveCreatorPricing = () => {
    setValue(`creatorPricing.${index}.numCreators`, 0)
    setValue(`creatorPricing.${index}.hidden`, true)
  }

  const config = CREATOR_TYPE_PRICING_CONFIG[type]

  return (
    <Stack border={`1px solid ${theme.palette.primary.lighter}`} borderRadius={1}>
      <Stack direction={'row'}>
        <Box
          width={'100%'}
          borderRight={`1px solid ${theme.palette.primary.lighter}`}
          p={2}
          pb={1}
          minHeight={80}
          display={'flex'}
        >
          <Stack direction={'row'} width={'100%'}>
            <Grid container justifyContent={'space-between'}>
              <Grid item xs alignSelf={'start'} height={'100%'}>
                <Stack alignItems={'start'} justifyContent={'space-between'} height={'100%'}>
                  <Stack direction={'row'} spacing={1}>
                    {isAdminView && MANAGED_SERVICE_CREATOR_TYPES.includes(type) && (
                      <IconButton
                        size={'small'}
                        onClick={handleRemoveCreatorPricing}
                        data-cy={`removeCreatorPricing-${type}`}
                        color="default"
                        disableRipple
                        sx={{
                          p: 0,
                          visibility: isAdminView && MANAGED_SERVICE_CREATOR_TYPES.includes(type) ? 'visible' : 'hidden'
                        }}
                      >
                        <CancelRounded fontSize="mSmall" />
                      </IconButton>
                    )}
                    <Typography variant={'label2'} alignSelf={'center'}>
                      {config?.title}
                    </Typography>
                  </Stack>
                  <Typography variant={'body1'} color={'grey.A700'} pt={0.5}>
                    {config?.description}
                  </Typography>
                  <Button
                    onClick={() => setLearnMoreOpen(!learnMoreOpen)}
                    variant="text"
                    endIcon={learnMoreOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    sx={{
                      pt: 1
                    }}
                  >
                    Learn more
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={'auto'} alignSelf={'center'}>
                <Stack direction={'row'}>
                  {isOverridePricing ? (
                    <Grid item alignSelf={'center'} px={1} pb={0.5}>
                      <Stack alignItems={'center'} spacing={1}>
                        <NumberInput
                          sx={{ width: '45px', height: '24px', px: 0, py: 0, textAlign: 'center' }}
                          name={`creatorPricing.${index}.price`}
                          data-cy={`creatorPricing.${index}.price`}
                          decimalScale={2}
                          returnAsNumber
                          allowNegative={false}
                        />
                        <Typography variant={'caption2'}>credits</Typography>
                      </Stack>
                    </Grid>
                  ) : (
                    <Grid item alignSelf={'center'} px={1}>
                      <Stack spacing={0.5} alignItems={'center'}>
                        <Typography variant={'label1'} pb={0.5}>
                          {data.price.toLocaleString()}
                        </Typography>
                        <Typography variant={'body1'}>credits</Typography>
                      </Stack>
                    </Grid>
                  )}
                  <Grid item alignSelf={'center'} px={1}>
                    <Close fontSize={'mSmall'} />
                  </Grid>
                  <Grid item alignSelf={'center'} px={1} py={0.5}>
                    <Stack alignItems={'center'} spacing={1}>
                      <NumCreatorsInput index={index} warning={warning} />
                      <Typography variant={'body1'}>creators</Typography>
                    </Stack>
                  </Grid>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Box>
        <Box bgcolor={'primary.lightest'} display={'flex'} width={100} alignItems={'center'} justifyContent={'center'}>
          <Stack alignItems={'center'} px={1} pt={1} spacing={0.5} pb={0.5}>
            <Typography variant={'label1'}>{cost.toLocaleString()}</Typography>
            <Typography variant={'body1'}>credits</Typography>
          </Stack>
        </Box>
      </Stack>
      <Collapse in={learnMoreOpen} timeout="auto" unmountOnExit>
        <Stack p={2} spacing={1} borderTop={`1px solid ${theme.palette.primary.lighter}`}>
          <Typography variant="label2">{config.learnMore.title}</Typography>
          <Typography variant="label3">{config.learnMore.description}</Typography>
          <Typography variant="label2" mt={1}>
            {config.learnMore.useCasesTitle}
          </Typography>
          {config.learnMore.useCases.map((useCase, index) => (
            <Stack direction={'row'} key={index} spacing={1}>
              <CheckCircle
                color={'success'}
                sx={{
                  height: '16px',
                  width: '16px'
                }}
              />
              <Typography variant="label3">{useCase}</Typography>
            </Stack>
          ))}
        </Stack>
      </Collapse>
    </Stack>
  )
}

const NumCreatorsInput = ({ index, warning }: { index: number; warning?: boolean }) => {
  return (
    <NumberInput
      focused={warning}
      color={warning ? 'warning' : 'primary'}
      sx={{
        width: '45px',
        height: '24px',
        px: 0,
        py: 0,
        textAlign: 'center'
      }}
      name={`creatorPricing.${index}.numCreators`}
      decimalScale={0}
      returnAsNumber
      allowNegative={false}
      max={5000}
    />
  )
}

// Sets the recommended launch date and launch date for Holiday/Promo (EVENT) proposals
const handleRecommendedDates = ({
  goal,
  creatorPricingWithSchedule,
  event,
  brandRegion,
  eventDate,
  setValue
}: {
  goal: ProposalGoal
  creatorPricingWithSchedule: ProposalCreatorPricingInput[]
  event?: ProposalPromoEventType
  brandRegion: Region
  eventDate?: DateTime
  setValue: UseFormSetValue<ProposalCreateForm>
}) => {
  let recommendedLaunchDate: DateTime | undefined
  if (goal === ProposalGoal.EVENT && event) {
    const eventConfig = EVENTS_BY_TYPE[event]
    const sumCreators = sumBy(creatorPricingWithSchedule, 'numCreators')
    if (!sumCreators) {
      setValue('recommendedLaunchDate', undefined)
      return
    }
    recommendedLaunchDate = eventConfig.preferredStartDate
  } else if (goal === ProposalGoal.SUPPORT_MARKETING_PROMOTION && eventDate && eventDate.isValid) {
    recommendedLaunchDate = eventDate
  }

  if (recommendedLaunchDate) {
    /*
   If the recommended launch date is on a weekend or is on a holiday, we will move it to be 1 business day earlier
   so that it will still finish before the Holiday/Promo date
   */
    const safeRecommendedLaunchDate =
      isWeekend(recommendedLaunchDate) || isHoliday(recommendedLaunchDate)
        ? minusBusinessDays(recommendedLaunchDate, 1)
        : recommendedLaunchDate

    const safeRecommendedLaunchDateMax = max([getMinLaunchDate(brandRegion).date, safeRecommendedLaunchDate])!

    setValue('recommendedLaunchDate', safeRecommendedLaunchDateMax)
    // If the recommended date is before the earliest possible date, set the launch date to the max of the two.
    // A warning will be displayed to the user indicating this.
    setValue('launchDate', safeRecommendedLaunchDateMax)
  }
}
