import StarRating from '@momentum/components/star-rating'
import TableLink from '@momentum/components/table-link'
import { ROUTES } from '@momentum/routes/RouteNames'
import { getCdnImageUrl } from '@momentum/utils/imageUtils'
import { StoreToIcon } from '@momentum/utils/storeIcons'
import { Link, Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid-premium'
import { RecommendationType, Store } from '@productwindtom/shared-momentum-zeus-types'
import { isNumber } from 'lodash'
import { Link as RouterLink, generatePath } from 'react-router-dom'

export const PRODUCT_COLUMN_DEFINITIONS: GridColDef[] = [
  {
    field: 'image',
    headerName: 'Image',
    resizable: false,
    width: 65,
    renderCell: ({ value, row }) =>
      value && (
        <img src={getCdnImageUrl(value)} alt={'product'} width={36} height={36} style={{ objectFit: 'contain' }} />
      )
  },
  {
    field: 'name',
    headerName: 'Product Name',
    flex: 1,
    cellClassName: '--wrap-cell',
    renderCell: ({ value, row }) =>
      row.listingLink ? (
        <TableLink to={row.listingLink} label={value} className={'--two-lines'} />
      ) : (
        <Typography variant={'body2'} className={'--two-lines'}>
          {value}
        </Typography>
      )
  },

  {
    field: 'store',
    headerName: 'Retailer',
    resizable: false,
    align: 'center',
    headerAlign: 'center',
    width: 75,
    renderCell: ({ value }) => StoreToIcon[value as Store]?.({ fontSize: 'medium', style: { verticalAlign: 'middle' } })
  },
  {
    field: 'variation',
    headerName: 'Variation'
  },
  {
    field: 'price',
    headerName: 'Price',
    width: 128
  },
  {
    field: 'numRatings',
    headerName: 'Reviews & ratings',
    sortable: true,
    width: 128,
    renderCell: c => c.value && c.value.toLocaleString()
  },
  {
    field: 'rating',
    headerName: 'Rating',
    sortable: true,
    width: 128,
    renderCell: ({ value }) => (isNumber(value) ? <StarRating rating={value} /> : value)
  },
  {
    field: 'actions',
    headerName: '',
    resizable: false,
    align: 'right',
    width: 128,
    renderCell: ({ value, row }) =>
      value &&
      !row.isParent && (
        <Link
          variant={'label2'}
          underline={'none'}
          component={RouterLink}
          to={generatePath(ROUTES.BRAND_CREATE_PROPOSAL_FROM_RECOMMENDATION, {
            brandId: value.brandId,
            productId: value.productId,
            type: RecommendationType.PRODUCT_LAUNCH
          })}
        >
          Create proposal
        </Link>
      )
  }
]
