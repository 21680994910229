import { SvgIcon, SvgIconProps } from '@mui/material'

export default function BonusRefundIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
      <circle
        cx="12"
        cy="12"
        r="11.5312"
        transform="matrix(-1 0 0 1 24 0)"
        fill="#EBEDF6"
        stroke="#EBEDF6"
        stroke-width="0.9375"
      />
      <g clip-path="url(#clip0_2232_14668)">
        <path
          d="M18 6.50114C14.0397 5.53359 12.0245 5.34517 8.19274 6.44641L8.91043 9.60674H11.4104V13.6067H8.91043L8.19274 17.0012C12.0759 18.3665 14.0742 18.3012 18 17.0011V6.50114Z"
          fill="#F9E4A7"
        />
        <path
          d="M7.912 16.4004L7.08 17.2004C8.2 18.1604 10.248 18.8004 12.6 18.8004C16.128 18.8004 19 17.3684 19 15.6004V7.60039C19 5.83239 16.136 4.40039 12.6 4.40039C10.24 4.40039 8.2 5.04039 7.08 6.00039L7.912 6.80039L8.2 7.12039C8.88 6.61639 10.376 6.00039 12.6 6.00039C15.696 6.00039 17.4 7.20039 17.4 7.60039C17.4 8.00039 15.696 9.20039 12.6 9.20039C11.504 9.20039 10.6 9.04839 9.864 8.83239L9.096 9.60039H11.4V10.7364C11.8 10.7764 12.2 10.8004 12.6 10.8004C14.512 10.8004 16.224 10.3764 17.4 9.71239V11.9604C16.36 12.7204 14.536 13.2004 12.6 13.2004C12.2 13.2004 11.8 13.1764 11.4 13.1364V13.6004H9.096L9.896 14.4004L9.8 14.4884C10.672 14.6884 11.608 14.8004 12.6 14.8004C14.424 14.8004 16.112 14.4404 17.4 13.8164V15.6004C17.4 16.0004 15.696 17.2004 12.6 17.2004C10.376 17.2004 8.88 16.5844 8.2 16.0884L7.912 16.4004ZM7.064 7.66439L8.2 8.80039L6.2 10.8004H10.2V12.4004H6.2L8.2 14.4004L7.064 15.5364L3.128 11.6004L7.064 7.66439Z"
          fill="#F3C94E"
        />
        <path
          d="M8.19922 8.80006L7.06322 7.66406L3.12722 11.6001L7.06322 15.5361L8.19922 14.4001L6.19922 12.4001H10.1992V10.8001H6.19922L8.19922 8.80006Z"
          fill="#324AA8"
        />
      </g>
      <defs>
        <clipPath id="clip0_2232_14668">
          <rect width="19.2" height="19.2" fill="white" transform="matrix(-1 0 0 1 21.5996 2.40039)" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}
