import { SvgIcon, SvgIconProps } from '@mui/material'

export default function July4thIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 25" fill="none">
      <path
        d="M1.27734 9.08374V21.2225C1.27734 21.8075 1.82109 22.1994 2.46234 22.6494C3.19359 23.1631 4.05234 23.6337 4.36922 23.6337L21.9098 23.75C22.3598 23.75 22.7236 23.1987 22.7236 22.7487V9.08374H1.27734Z"
        fill="#BDBDBD"
      />
      <path
        opacity="0.29"
        d="M22.7224 22.7488L21.1699 21.1532V8.73509L22.7224 8.69946V22.7488Z"
        fill="url(#paint0_linear_2486_53490)"
      />
      <path
        d="M22.7221 10.1225L21.5109 9.36312L19.6152 1.25H20.6352C22.4446 1.25 22.7221 2.29437 22.7221 2.65062V10.1225Z"
        fill="#C62828"
      />
      <path
        d="M1.82812 9.08374V20.8362C1.82812 21.3425 2.23875 21.7512 2.74312 21.7512H20.5275C21.0337 21.7512 21.5062 21.3744 21.5062 20.8681V9.08374H1.82812Z"
        fill="#FAFAFA"
      />
      <path
        d="M21.5123 9.36312H1.27734V2.32812C1.27734 1.73188 1.75922 1.25 2.35547 1.25H20.4661C21.0642 1.25 21.548 1.73562 21.5442 2.33375L21.5123 9.36312Z"
        fill="#F44336"
      />
      <path
        d="M7.86598 3.39871H8.63848V5.99371C8.63848 6.23184 8.58598 6.44371 8.48098 6.62559C8.37598 6.80746 8.22598 6.94996 8.03473 7.04934C7.8416 7.14871 7.62598 7.19934 7.3841 7.19934C6.98848 7.19934 6.6791 7.09809 6.45785 6.89746C6.2366 6.69684 6.12598 6.40996 6.12598 6.04246H6.9041C6.9041 6.22621 6.94348 6.36121 7.02035 6.44934C7.09723 6.53746 7.2191 6.58059 7.38598 6.58059C7.5341 6.58059 7.65035 6.52996 7.73848 6.42871C7.8266 6.32746 7.86973 6.18309 7.86973 5.99559V3.39871H7.86598ZM10.9185 6.86371C10.7347 7.08684 10.4816 7.19746 10.1572 7.19746C9.8591 7.19746 9.63035 7.11121 9.47473 6.94059C9.31723 6.76996 9.2366 6.51684 9.23473 6.18684V4.36246H9.9791V6.16434C9.9791 6.45496 10.1104 6.59934 10.3747 6.59934C10.6279 6.59934 10.8004 6.51121 10.8941 6.33684V4.36246H11.6404V7.14684H10.941L10.9185 6.86371ZM12.9847 7.14684H12.2385V3.19434H12.9847V7.14684ZM14.6366 6.09309L15.1522 4.36059H15.951L14.8316 7.57809L14.7697 7.72434C14.6029 8.08809 14.3291 8.26996 13.9466 8.26996C13.8379 8.26996 13.7291 8.25309 13.6166 8.22121V7.65684L13.7291 7.65871C13.8697 7.65871 13.9747 7.63809 14.0441 7.59496C14.1135 7.55184 14.1679 7.48059 14.2072 7.38121L14.2954 7.15246L13.3204 4.36059H14.121L14.6366 6.09309Z"
        fill="white"
      />
      <path
        d="M11.663 17.938H8.264V16.552L11.443 11.712H13.247V16.552H14.248V17.938H13.247V19.5H11.663V17.938ZM11.663 13.868H11.641L9.914 16.552H11.663V13.868Z"
        fill="black"
      />
      <path opacity="0.29" d="M21.5062 10.2949L1.82812 10.3155V9.36304H21.5062V10.2949Z" fill="#616161" />
      <path
        d="M4.27559 5.15737C5.01081 5.15737 5.60684 4.56135 5.60684 3.82612C5.60684 3.09089 5.01081 2.49487 4.27559 2.49487C3.54036 2.49487 2.94434 3.09089 2.94434 3.82612C2.94434 4.56135 3.54036 5.15737 4.27559 5.15737Z"
        fill="#606060"
      />
      <path
        d="M3.8332 4.71694C4.56843 4.71694 5.16445 4.12092 5.16445 3.38569C5.16445 2.65046 4.56843 2.05444 3.8332 2.05444C3.09797 2.05444 2.50195 2.65046 2.50195 3.38569C2.50195 4.12092 3.09797 4.71694 3.8332 4.71694Z"
        fill="#94D1E0"
      />
      <path
        d="M3.94657 2.83447C3.91657 2.99384 3.81532 3.12884 3.69907 3.24322C3.56032 3.37822 3.3972 3.49072 3.21345 3.55259C3.1047 3.59009 2.97907 3.60509 2.88345 3.54134C2.61907 3.36322 2.83095 2.69384 3.03157 2.51572C3.3372 2.24572 4.05157 2.29634 3.94657 2.83447Z"
        fill="#C9EFF2"
      />
      <path
        d="M18.9943 5.15737C19.7296 5.15737 20.3256 4.56135 20.3256 3.82612C20.3256 3.09089 19.7296 2.49487 18.9943 2.49487C18.2591 2.49487 17.6631 3.09089 17.6631 3.82612C17.6631 4.56135 18.2591 5.15737 18.9943 5.15737Z"
        fill="#606060"
      />
      <path
        d="M18.5539 4.71694C19.2891 4.71694 19.8852 4.12092 19.8852 3.38569C19.8852 2.65046 19.2891 2.05444 18.5539 2.05444C17.8187 2.05444 17.2227 2.65046 17.2227 3.38569C17.2227 4.12092 17.8187 4.71694 18.5539 4.71694Z"
        fill="#94D1E0"
      />
      <path
        d="M18.6673 2.83447C18.6373 2.99384 18.536 3.12884 18.4198 3.24322C18.281 3.37822 18.1179 3.49072 17.9342 3.55259C17.8254 3.59009 17.6998 3.60509 17.6042 3.54134C17.3398 3.36322 17.5517 2.69384 17.7523 2.51572C18.0579 2.24572 18.7704 2.29634 18.6673 2.83447Z"
        fill="#C9EFF2"
      />
      <path
        d="M19.4457 22.7637C18.8495 22.8369 18.2532 22.8687 17.657 22.9212C17.0607 22.9587 16.4645 22.9925 15.8682 23.015C15.272 23.045 14.6757 23.0506 14.0795 23.0694L12.2907 23.0862L10.502 23.0675C9.90574 23.0506 9.30949 23.0431 8.71324 23.0131C8.11699 22.9906 7.52074 22.9569 6.92449 22.9194C6.32824 22.8669 5.73199 22.835 5.13574 22.7619V22.7244L12.2889 22.7056L19.442 22.7244V22.7637H19.4457ZM15.8439 21.7512H20.5914C21.0976 21.7512 21.5064 21.3406 21.5064 20.8362V17.3806C21.5064 17.3806 20.4751 18.8131 18.9132 19.9906C17.3514 21.1681 15.8439 21.7512 15.8439 21.7512Z"
        fill="#757575"
      />
      <path
        d="M20.0943 20.0582C21.5418 18.3538 21.5062 17.3826 21.5062 17.3826C21.5062 17.3826 21.088 17.9713 19.7737 18.1044C19.3105 18.1513 18.6468 17.9732 18.1574 17.8101C17.863 17.7126 17.5574 17.9226 17.548 18.2301C17.5349 18.6257 17.4693 19.1807 17.2574 19.8407C16.9049 20.9432 15.8418 21.7532 15.8418 21.7532C15.8418 21.7532 18.4762 21.9613 20.0943 20.0582Z"
        fill="#BDBDBD"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2486_53490"
          x1="21.9462"
          y1="14.5071"
          x2="21.9462"
          y2="21.9938"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.337" stop-color="#616161" />
          <stop offset="1" stop-color="#616161" stop-opacity="0" />
        </linearGradient>
      </defs>
    </SvgIcon>
  )
}
