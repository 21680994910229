import CalendarCheckIcon from '@momentum/components/icons/calendar-check'
import { Question } from '@momentum/components/proposal-common/Question'
import { useSubscriptionContext } from '@momentum/contexts/Subscription'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { BOX_PROPS } from '@momentum/routes/proposals-create/common/styling'
import { useCreateProposalContext } from '@momentum/routes/proposals-create/context/CreateProposalContext'
import useEstimatedDeliverables from '@momentum/routes/proposals-create/modules/useEstimatedDeliverables'
import { createCampaign } from '@momentum/routes/proposals-create/mutations'
import { BillingContactsInput } from '@momentum/routes/proposals-create/review/BillingContactsInput'
import { CreditPayment } from '@momentum/routes/proposals-create/review/CreditPayment'
import { PaymentBillingContactInput } from '@momentum/routes/proposals-create/review/PaymentBillingContactInput'
import { ProposalCreateForm } from '@momentum/routes/proposals-create/types'
import { useSaveProposal } from '@momentum/routes/proposals-create/useSaveProposal'
import { ROUTE_NAMES_BRAND, ROUTE_NAMES_PROPOSAL, ROUTES } from '@momentum/routes/RouteNames'
import { CampaignOrderFormPdf } from '@momentum/utils/order-forms/CampaignOrderFormPdf'
import { errorToast } from '@momentum/utils/toastUtils'
import { ScheduleOutlined } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Alert, Button, CircularProgress, Link, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { STORE_DETAILS } from '@productwindtom/shared-campaign'
import {
  InvoiceMethod,
  InvoicePaymentType,
  PaymentTermsType,
  PricingCreatorType,
  ProposalStatus,
  STORE_TO_LOCALE
} from '@productwindtom/shared-momentum-zeus-types'
import { toLocaleCurrency } from '@productwindtom/shared-node'
import { toCurrencyStringCents } from '@productwindtom/shared-ws-currency'
import { DateInput, TextInput } from '@productwindtom/ui-base'
import { pdf } from '@react-pdf/renderer'
import { captureException } from '@sentry/react'
import { useFlag } from '@unleash/proxy-client-react'
import { keyBy, max, upperFirst } from 'lodash'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { generatePath, useLocation, useNavigate } from 'react-router-dom'
import PlaceholderProductAlert from '../modules/placeholder-product-alert'

export const Review = () => {
  const { selectedBrand, selectedCompany, agencies, isAdmin, isAdminView, agency, isViewOnly, profile } =
    useUserSessionContext()
  const { refreshCampaigns, refreshProposal } = useBrandContext()
  const { creditsRemaining, refreshCredits, overdueCreditActions, netTermsDays, paymentTermsType } =
    useSubscriptionContext()
  const {
    totalCostCredits,
    totalCostCreditsForAgency,
    durationWeeks,
    productPrice,
    totalCreatorCostCredits,
    totalProductCostCredits
  } = useEstimatedDeliverables()
  const { proposal, selectedProduct } = useCreateProposalContext()
  const adminScheduleCampaign = useFlag('AdminScheduleCampaign')
  const brandPlaceholderScheduleCampaign = useFlag('BrandPlaceholderScheduleCampaign')

  const { saveProposal } = useSaveProposal(proposal?.id)
  const {
    watch,
    getValues,
    setValue,
    formState: { isValid }
  } = useFormContext<ProposalCreateForm>()
  const data = watch()
  const [launchDate, paymentType, invoiceDueDate, invoicePONumber] = watch([
    'launchDate',
    'paymentType',
    'invoiceDueDate',
    'invoicePONumber'
  ])
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [scheduling, setScheduling] = useState(false)

  const companyAgency = agencies?.find(agency => agency.id === selectedCompany?.agencyId)

  const netTermsType = paymentTermsType
  const netTerms = netTermsDays

  const isAdminSaveOnly = !adminScheduleCampaign && isAdmin

  useEffect(() => {
    if (!selectedProduct) {
      navigate([...pathname.split('/').slice(0, -1), ROUTE_NAMES_PROPOSAL.PRODUCT_CREATORS].join('/'), {
        replace: true
      })
    }
    if (!paymentType) {
      setValue('paymentType', InvoicePaymentType.CREDITS)
    }
  }, [])

  useEffect(() => {
    if (paymentType === InvoicePaymentType.NEW_INVOICE && netTerms) {
      setValue(
        'invoiceDueDate',
        netTermsType === PaymentTermsType.NET_CUSTOM
          ? DateTime.now().plus({ days: netTerms })
          : max([DateTime.now().plus({ days: 1 }), launchDate.minus({ days: netTerms })])
      )
    } else if (paymentType === InvoicePaymentType.END_OF_MONTH_INVOICE) {
      setValue('invoiceDueDate', DateTime.now().plus({ month: 1 }).startOf('month'))
    }
  }, [paymentType, netTermsType, netTerms])

  const handleBack = () => {
    navigate([...pathname.split('/').slice(0, -1), ROUTE_NAMES_PROPOSAL.TIMELINE].join('/'), { replace: true })
  }

  const handleScheduleCampaign = async () => {
    setScheduling(true)

    try {
      const updatedProposal = await saveProposal(data)
      const resp = await createCampaign(updatedProposal.id)
      refreshCampaigns()
      refreshProposal(updatedProposal.id)
      if (data.paymentType === InvoicePaymentType.CREDITS) {
        refreshCredits()
      }
      navigate(
        generatePath([ROUTES.BRAND_CREATE_PROPOSAL_EDIT, ROUTE_NAMES_PROPOSAL.WIN].join('/'), {
          brandId: updatedProposal.brandId,
          id: updatedProposal.id
        }) + `?invoiceLink=${resp.invoiceLink ?? ''}`
      )
    } catch (e: any) {
      console.error(e)
      captureException(e)
      switch (e.message) {
        case 'UNAUTHORIZED':
          errorToast('You are not authorized to schedule this campaign.')
          break
        case 'PROPOSAL_NOT_FOUND':
          errorToast('Proposal not found.')
          break
        case 'CAMPAIGN_ALREADY_EXISTS':
          errorToast('Campaign already exists.')
          return navigate(
            generatePath([ROUTES.BRAND, ROUTE_NAMES_BRAND.PROPOSALS].join('/'), {
              brandId: data.brandId
            })
          )
        case 'INVALID_PROPOSAL':
          errorToast('Invalid proposal.')
          break
        case 'SKU_NOT_FOUND':
          errorToast('SKU not found.')
          break
        case 'BRAND_NOT_FOUND':
          errorToast('Brand not found.')
          break
        case 'PRICE_NOT_FOUND':
          errorToast('SKU price not found.')
          break
        case 'INSUFFICIENT_CREDITS':
          errorToast('Insufficient credits.')
          break
        case 'INVOICE_DUE_DATE_REQUIRED':
          errorToast('Invoice due date required.')
          break
        case 'BILLING_CONTACTS_REQUIRED':
          errorToast('Billing contacts required.')
          break
        case 'CREDITS_CHANGED':
        default:
          errorToast('An error has occurred, please try again later!')
      }
      navigate(
        generatePath([ROUTES.BRAND_CREATE_PROPOSAL_EDIT, ROUTE_NAMES_PROPOSAL.REVIEW].join('/'), {
          brandId: data.brandId,
          id: data.id
        })
      )
    }
    setScheduling(false)
  }

  const generatePdf = async () => {
    const vals = getValues()
    if ((vals.paymentType === InvoicePaymentType.CREDITS || vals.invoiceDueDate) && vals.paymentType && productPrice) {
      const keyedCreatorCounts = keyBy(vals.creatorPricing, c => c.type)

      const pdfDocument = (
        <CampaignOrderFormPdf
          companyName={selectedCompany!.name}
          productName={selectedProduct!.name}
          retailChannel={upperFirst(STORE_DETAILS[selectedProduct!.store].storeLink!)}
          productPrice={toCurrencyStringCents(productPrice, STORE_TO_LOCALE[selectedProduct!.store])}
          startDate={vals.launchDate.toLocaleString(DateTime.DATE_MED)}
          endDate={vals.launchDate.plus({ weeks: durationWeeks }).toLocaleString(DateTime.DATE_MED)}
          paymentType={vals.paymentType}
          paymentDueDate={vals.invoiceDueDate?.toLocaleString(DateTime.DATE_MED)}
          clientContactName={`${profile.firstName} ${profile.lastName}`}
          clientContactEmail={vals?.paymentBillingContactEmail || ''}
          contacts={(vals.billingContacts || []).map(c => c.email)}
          numCredits={totalCostCredits || 0}
          numCreatorCostCredits={totalCreatorCostCredits || 0}
          numProductCostCredits={totalProductCostCredits || 0}
          numBrandAdvocates={keyedCreatorCounts[PricingCreatorType.ADVOCATE]?.numCreators || 0}
          numSocialCreators={keyedCreatorCounts[PricingCreatorType.SOCIAL]?.numCreators || 0}
          numPremiumUgcCreators={keyedCreatorCounts[PricingCreatorType.PREMIUM_UGC]?.numCreators || 0}
          numUgcCreators={keyedCreatorCounts[PricingCreatorType.UGC]?.numCreators || 0}
        />
      )

      await pdf(pdfDocument)
        .toBlob()
        .then(blob => {
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = `draft-campaign-receipt.pdf`
          a.click()
        })
    }
  }

  const isPONumberValid =
    paymentType !== InvoicePaymentType.NEW_INVOICE ||
    selectedCompany?.invoiceMethod !== InvoiceMethod.CUSTOM_PO ||
    !!invoicePONumber

  return (
    <Box {...BOX_PROPS}>
      <Stack spacing={3}>
        <Question primaryText={'Campaign launch date:'}>
          <Box display={'flex'}>
            <Stack
              direction={'row'}
              spacing={1}
              px={2}
              py={1}
              borderRadius={'4px'}
              alignItems={'center'}
              sx={{ backgroundColor: t => t.palette.grey.A100 }}
              border={'1px solid #EAEAEA'}
              width={250}
            >
              <CalendarCheckIcon />
              <Typography variant={'label3'}>{launchDate.toFormat('M/dd/yyyy')}</Typography>
            </Stack>
          </Box>
        </Question>

        {(companyAgency || isAdminView) && paymentType === InvoicePaymentType.END_OF_MONTH_INVOICE && (
          <Stack>
            <Alert variant="outlined" severity="info">
              <Typography variant="label3">
                {agency?.name} will be billed{' '}
                {toLocaleCurrency(totalCostCreditsForAgency || 0, 'USD', { minimumFractionDigits: 0 })} on{' '}
                {invoiceDueDate?.toLocaleString(DateTime.DATE_MED)} for this campaign. By clicking “schedule campaign”
                you agree to pay this amount.
              </Typography>
            </Alert>
          </Stack>
        )}

        {paymentType === InvoicePaymentType.CREDITS && (
          <CreditPayment
            creditsRemaining={creditsRemaining}
            totalCostCredits={totalCostCredits || 0}
            associatedProposalId={data.id}
          />
        )}

        {paymentType === InvoicePaymentType.NEW_INVOICE && (
          <Question
            primaryText={'When is the total payment due?'}
            subtext={
              netTermsType === PaymentTermsType.NET_CUSTOM && netTerms
                ? `${companyAgency?.name || selectedCompany?.name} has Net ${netTerms} payment terms. The due date is set ${netTerms} days from the date the invoice is generated.`
                : `${companyAgency?.name || selectedCompany?.name} has payment terms which require payment ${netTerms} days before launch. The due date is set ${netTerms} days from the campaign launch date of ${launchDate.toLocaleString(DateTime.DATE_SHORT)}.`
            }
          >
            <Box sx={{ maxWidth: 165 }}>
              <DateInput fullWidth name={'invoiceDueDate'} disabled readOnly />
            </Box>
          </Question>
        )}

        {paymentType === InvoicePaymentType.NEW_INVOICE && (
          <Question
            primaryText={
              'Enter PO number' + (selectedCompany?.invoiceMethod !== InvoiceMethod.CUSTOM_PO ? ' (optional)' : '')
            }
          >
            <Box>
              <TextInput name={'invoicePONumber'} />
            </Box>
          </Question>
        )}

        {paymentType === InvoicePaymentType.NEW_INVOICE && (
          <Question
            primaryText={'Contact responsible for the invoice payment'}
            subtext={`An invoice will be generated and emailed to this contact.`}
          >
            <PaymentBillingContactInput />
          </Question>
        )}

        {paymentType === InvoicePaymentType.NEW_INVOICE && (
          <Question
            primaryText={"Enter additional contacts to be cc'ed on the invoice"}
            subtext={`An invoice will be generated and cc’ed to these contacts.`}
          >
            <BillingContactsInput name={'billingContacts'} />
          </Question>
        )}

        {paymentType === InvoicePaymentType.NEW_INVOICE &&
          selectedCompany?.invoiceMethod === InvoiceMethod.CUSTOM_PO && (
            <Question
              primaryText={'What is your invoicing system?'}
              subtext={'Enter your required invoicing system, such as Ariba or EasyO'}
            >
              <Box>
                <TextInput name={'invoicePOSystem'} />
              </Box>
            </Question>
          )}

        {paymentType === InvoicePaymentType.NEW_INVOICE &&
          selectedCompany?.invoiceMethod !== InvoiceMethod.CUSTOM_PO && (
            <Question
              primaryText={
                'Provide any additional information which is required by your finance team to be added to the invoice.'
              }
            >
              <TextInput name={'invoiceAdditionalInformation'} multiline minRows={3} />
            </Question>
          )}

        {!companyAgency && paymentType && paymentType !== InvoicePaymentType.CREDITS && isAdminView && (
          <Question primaryText={'Enter the link to your existing HubSpot deal'}>
            <Box>
              <TextInput name={'hubspotDealLink'} placeholder={'HubSpot deal link'} fullWidth />
            </Box>
          </Question>
        )}

        {isViewOnly && (
          <Alert severity={'info'} variant={'outlined'}>
            <Typography color={'black'} variant={'label3'}>
              This campaign cannot be scheduled because your access level is View-only. Save the proposal and contact
              Customer Success to schedule this campaign.
            </Typography>
          </Alert>
        )}

        {isAdminSaveOnly && (
          <Alert severity={'info'} variant={'outlined'}>
            <Typography color={'black'} variant={'label3'}>
              The {selectedBrand?.name} brand must schedule this campaign directly.
            </Typography>
          </Alert>
        )}

        <Stack spacing={2} pt={2}>
          <PlaceholderProductAlert />
          <Stack direction={'row'} spacing={1} justifyContent={'flex-end'}>
            <Button onClick={handleBack} data-cy="goBackButton">
              Go back
            </Button>

            <LoadingButton
              loading={scheduling}
              variant={'contained'}
              data-cy="scheduleCampaignButton"
              endIcon={<ScheduleOutlined />}
              disabled={
                isAdminSaveOnly ||
                isViewOnly ||
                !paymentType ||
                (!isAdminView && !selectedProduct?.skuId && !brandPlaceholderScheduleCampaign) ||
                proposal?.status === ProposalStatus.SUBMITTED ||
                (paymentType === InvoicePaymentType.CREDITS &&
                  (!totalCostCredits || totalCostCredits > creditsRemaining)) ||
                !isValid ||
                !isPONumberValid ||
                !!overdueCreditActions.length
              }
              onClick={handleScheduleCampaign}
            >
              {proposal?.status === ProposalStatus.SUBMITTED ? 'Scheduled' : 'Schedule campaign'}
            </LoadingButton>
          </Stack>
          {scheduling && (
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <CircularProgress size={24} />
              <Typography variant={'label3'}>
                We're scheduling your campaign! This could take up to 30 seconds.
              </Typography>
            </Stack>
          )}

          {!!overdueCreditActions.length && (
            <Stack mb={5} spacing={1}>
              {overdueCreditActions.map(creditAction => (
                <Alert severity="warning" variant="outlined">
                  <Typography variant="label3" color={'black'}>
                    You have an overdue invoice for new credits. Pay invoice #{creditAction.invoiceNumber} before you
                    can schedule this campaign.
                  </Typography>
                </Alert>
              ))}
            </Stack>
          )}

          <Typography variant={'body1'} textAlign={'right'} color={theme => theme.palette.grey.A700}>
            By clicking “schedule campaign” you agree to the{' '}
            <Link
              variant={'body1'}
              sx={{ fontWeight: 800 }}
              href={'https://www.productwind.com/terms-and-conditions'}
              target={'_blank'}
            >
              Momentum Platform Terms and Conditions
            </Link>{' '}
            and will receive a{' '}
            <Typography
              variant={'body1'}
              onClick={generatePdf}
              component={'span'}
              color={'primary'}
              sx={{ cursor: 'pointer', fontWeight: 800 }}
            >
              campaign receipt
            </Typography>{' '}
            via email.
          </Typography>
        </Stack>
      </Stack>
    </Box>
  )
}
