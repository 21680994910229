import { BuyCampaignCredits } from '@momentum/components/buy-campaign-credits'
import BonusAlertIcon from '@momentum/components/icons/bonus-alert'
import BonusHighlightedIcon from '@momentum/components/icons/bonus-highlighted'
import { useSubscriptionContext } from '@momentum/contexts/Subscription'
import { Box, Divider, Stack, Typography } from '@mui/material'
import { CheckInput } from '@productwindtom/ui-base'
import { useAddProductContext } from './context'

export const CreditsSpend = () => {
  const { creditsRequired } = useAddProductContext()
  const { creditsRemaining } = useSubscriptionContext()

  if (!creditsRequired) {
    return null
  }

  return (
    <Box borderRadius={'4px'} border={t => `1px solid ${t.palette.grey.A200}`} bgcolor={'white'}>
      <Stack spacing={2} px={1.5} py={1.5}>
        <Typography variant="label2">Add credits to schedule this campaign</Typography>

        <Stack direction={'row'} spacing={1} justifyContent={'space-between'}>
          <Typography variant="label3">Currently available</Typography>
          <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
            <BonusHighlightedIcon sx={{ color: t => t.palette.warning.main }} />
            <Stack direction={'row'} spacing={0.5} alignItems={'baseline'}>
              <Typography typography={'label1'}>{creditsRemaining.toLocaleString()}</Typography>
              <Typography typography={'label3'}> credits</Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction={'row'} spacing={1} justifyContent={'space-between'}>
          <Typography variant="label3">Required for this campaign</Typography>
          <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
            <BonusAlertIcon sx={{ color: t => t.palette.warning.main }} />
            <Stack direction={'row'} spacing={0.5} alignItems={'baseline'}>
              <Typography typography={'label1'} data-cy={'spendCredits-total'}>{creditsRequired.toLocaleString()}</Typography>
              <Typography typography={'label3'}> credits</Typography>
            </Stack>
          </Stack>
        </Stack>

        <Divider />

        <BuyCampaignCredits creditsRemaining={creditsRemaining} totalCostCredits={creditsRequired} />

        {creditsRequired <= creditsRemaining && (
          <CheckInput
            name="creditsSpendAgreed"
            label={`I agree to use ${creditsRequired.toLocaleString()} credits to schedule this campaign with this product`}
            checkboxProps={{ size: 'medium' }}
            controlLabelProps={{
              slotProps: {
                typography: { variant: 'label3' }
              }
            }}
          />
        )}
      </Stack>
    </Box>
  )
}
