import { SvgIcon, SvgIconProps } from '@mui/material'

export default function FathersDayIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 25" fill="none">
      <path
        d="M18.9905 23.7649C18.1786 23.7649 11.4042 23.7724 10.4986 23.7724C9.59296 23.7724 8.97796 23.6993 8.69483 23.6468C7.36733 23.4049 5.92733 23.1724 4.82858 22.4131C3.98858 21.8337 3.11671 20.7574 3.98671 18.8543C5.19983 16.0568 6.26296 13.2968 8.74921 12.9443C9.58171 12.8262 10.8061 12.8393 12.1805 12.8393C13.5548 12.8393 14.4605 13.0662 15.0342 13.1918C19.1311 14.0899 19.3036 17.3131 19.6786 20.8193C19.6767 20.8193 19.9973 23.7649 18.9905 23.7649Z"
        fill="url(#paint0_linear_1554_118156)"
      />
      <path
        d="M19.0773 21.0201C17.9279 18.9632 17.6035 18.6651 17.4423 18.3051C17.1742 17.7032 16.906 17.4651 16.7054 17.3788C16.6829 17.1501 16.6267 16.8745 16.5498 16.5388C16.6679 16.4582 16.7598 16.3457 16.8142 16.2107C16.8835 16.0345 16.8798 15.8413 16.8048 15.667L16.2198 14.3263C16.1448 14.152 10.1223 16.7263 10.1223 16.7263C9.92541 17.0545 9.85041 17.3432 10.0979 17.8832C10.2423 18.1982 10.5723 18.4532 10.6417 18.4232L11.206 18.2095C11.2848 18.3257 11.3973 18.4157 11.5323 18.4682C11.5848 18.4888 11.7967 18.5451 12.076 18.4588L13.5442 17.8345C13.7467 18.1326 14.0504 18.1663 14.2492 18.1495C14.2492 18.1495 14.4067 18.6238 14.8267 18.8338C14.6298 19.1432 14.9598 19.7188 15.1454 20.0863C15.4323 20.6526 14.7948 22.7638 15.0198 23.7707L18.9929 23.7895C19.3023 23.1595 19.6267 22.0045 19.0773 21.0201Z"
        fill="url(#paint1_linear_1554_118156)"
      />
      <path
        d="M17.4063 15.0425L16.8213 13.7018C16.7463 13.5275 16.6057 13.3943 16.4294 13.325C16.2532 13.2556 16.06 13.2593 15.8857 13.3343L11.5225 15.1906C11.3503 15.2661 11.215 15.4066 11.1461 15.5816C11.0772 15.7566 11.0805 15.9517 11.155 16.1243L11.74 17.465C11.815 17.6393 11.9557 17.7725 12.1319 17.8418C12.1844 17.8625 12.2369 17.8756 12.2913 17.885C12.4207 17.9037 12.5519 17.885 12.6757 17.8325L17.0388 15.9762C17.2132 15.9012 17.3463 15.7606 17.4157 15.5843C17.4869 15.4081 17.4832 15.2168 17.4063 15.0425Z"
        fill="url(#paint2_linear_1554_118156)"
      />
      <path
        d="M11.3854 15.2732C11.1417 15.3876 11.0235 15.7832 11.1398 16.0476L11.8092 17.5851C11.9254 17.8494 12.4092 17.9657 12.6735 17.8494L17.0404 15.9763L11.3854 15.2732Z"
        fill="#FFFDE7"
      />
      <path
        d="M10.8224 16.4655C10.9124 16.4411 11.0306 16.4523 11.0849 16.5255C11.1468 16.6098 11.1899 16.743 11.2349 16.8405C11.3118 17.0092 11.3624 17.1855 11.3587 17.3711C11.3587 17.3917 11.3568 17.4161 11.3399 17.4311C11.3287 17.4423 11.3118 17.4461 11.2949 17.4498C11.1937 17.4705 11.0887 17.478 10.9856 17.4742C10.7793 17.4667 10.6706 17.2848 10.6068 17.1067C10.5674 16.9942 10.5487 16.8742 10.5712 16.758C10.5956 16.6417 10.6668 16.533 10.7718 16.4805C10.7887 16.4767 10.8037 16.4711 10.8224 16.4655Z"
        fill="#C53929"
      />
      <path
        d="M11.8092 17.585L11.6029 17.675C11.4642 17.735 11.2917 17.7012 11.1904 17.5887C11.0929 17.48 11.1004 17.3318 11.0798 17.1912C11.0536 17.0075 10.9879 16.8406 10.8661 16.7243C10.8304 16.6906 10.7873 16.6625 10.7536 16.6268C10.6017 16.4637 10.7311 16.1693 10.9111 16.0906L11.1173 16.0006C11.3142 15.9143 11.6742 16.0812 11.8879 16.6006C12.1017 17.1162 12.0061 17.4987 11.8092 17.585Z"
        fill="#E06055"
      />
      <path
        d="M10.3175 18.0011C10.2031 18.9686 9.40247 19.7936 8.4781 20.0448C7.49935 20.3111 6.26747 19.7467 5.66185 18.8992C4.53122 17.3205 5.73872 16.0061 5.99747 15.7117C6.64247 14.9805 7.59497 14.9936 8.34872 15.0405C9.89185 15.1323 10.4731 16.6811 10.3175 18.0011Z"
        fill="#C5E1A5"
      />
      <path
        d="M8.09891 14.7331C7.70891 14.7368 7.11266 14.7725 6.69078 15.02C6.50141 15.1325 6.44141 15.3275 6.44141 15.3275C6.94766 15.1343 7.45953 15.0987 8.09328 15.3312C9.28391 15.7681 9.48453 16.7768 9.38328 18.0443C9.31016 18.9612 8.70266 19.7037 7.96391 19.94C7.61891 20.0506 7.64891 20.2175 8.09891 20.2493C9.72078 20.3618 10.857 19.0137 10.857 17.4912C10.857 15.9687 9.83891 14.7162 8.09891 14.7331Z"
        fill="#FEF6E0"
      />
      <path
        d="M10.9745 18.5693C13.2958 18.7924 13.967 20.0918 13.967 20.0918C16.2939 20.6074 16.9033 22.1543 16.7177 22.8555C16.7177 22.8555 15.6527 23.4818 14.9889 23.4949C14.0308 23.5155 13.0689 23.523 12.1145 23.4818C12.1145 23.4818 12.2608 22.4205 11.912 22.113C11.7002 21.9274 11.3345 22.1149 10.982 21.9068C10.3914 21.558 9.48953 21.0236 8.73203 20.613C7.97828 20.2043 7.31641 20.0186 7.31641 20.0186C7.99141 19.2349 9.19703 18.3986 10.9745 18.5693Z"
        fill="#BDBDBD"
      />
      <path
        d="M5.3752 16.6475C5.3752 16.6475 5.55145 17.8981 6.73082 18.1362C8.34707 18.4625 9.41582 17.6881 9.41582 17.6881C9.41582 17.6881 9.52832 17.885 9.83207 17.8118C10.0758 17.7537 10.5839 17.6225 10.6946 17.4368C10.8427 17.1912 10.8164 16.9775 10.8164 16.9775C10.8089 17.0637 10.8389 17.1668 10.8427 17.255C10.8539 17.4893 10.8446 17.7275 10.8089 17.96C10.7733 18.1962 10.6533 18.545 10.6533 18.545C10.3139 18.6387 9.98582 19.0531 10.1883 19.6156C10.6383 20.855 11.8496 21.5843 13.0683 21.5112C13.5933 21.4793 14.1071 21.3593 14.6208 21.2525C14.7108 21.2337 14.8346 21.1943 14.8871 21.2675C15.0971 21.56 14.6077 22.0493 14.4708 22.1975C15.4908 22.7393 16.6139 22.2218 16.6627 22.2293C16.7114 22.2368 16.0927 22.9193 14.9527 22.9193C14.4127 22.9193 13.9008 22.7112 13.3833 22.5762C12.8771 22.4431 12.2621 22.5931 11.9564 22.1656C11.7708 21.9068 11.3264 22.1075 11.0039 21.9237C10.3702 21.56 8.87957 20.6393 8.16707 20.3412C7.55957 20.0881 7.4302 20.0525 7.19395 19.9868C6.42707 19.7731 5.72582 20.3093 5.14082 19.52C4.8127 19.0775 5.33957 18.3256 5.33957 18.3256C4.9852 17.4687 5.3752 16.6475 5.3752 16.6475Z"
        fill="url(#paint3_linear_1554_118156)"
      />
      <path
        d="M15.636 18.0764C15.0885 18.2883 15.5272 19.0402 15.7522 19.4846C16.0822 20.1333 16.3222 21.9989 16.7591 22.7433C17.3553 23.7596 18.9341 24.0464 19.6185 23.5571C20.1847 23.1521 20.3778 21.6521 19.6878 20.4164C18.5385 18.3596 18.2141 18.0614 18.0528 17.7014C17.5203 16.5052 16.986 16.7489 16.986 16.7489L15.636 18.0764Z"
        fill="url(#paint4_linear_1554_118156)"
      />
      <path
        d="M16.8959 22.4075C16.8959 22.2875 16.8527 22.1562 16.5602 22.2725C15.8196 22.5669 15.2346 22.7787 14.4002 22.4487C14.4884 22.5256 15.5159 21.6594 14.9159 21.2281C14.659 21.1362 14.1152 21.7325 13.5771 21.7719C13.0371 21.7512 12.379 21.74 11.9121 22.2181L11.7246 23.7725C12.2046 23.7725 14.4865 23.8419 15.3809 23.7181C15.9077 23.5794 16.7815 23.1594 16.8959 22.4075Z"
        fill="url(#paint5_linear_1554_118156)"
      />
      <path
        d="M14.811 13.6193C14.6441 13.595 14.3347 13.6043 14.2747 13.76C14.1547 14.0656 14.4847 14.2381 14.706 14.2943C15.051 14.3825 15.4278 14.4575 15.4278 14.4575L15.7503 15.0068C15.7503 15.0068 15.0435 14.8025 14.7191 14.795C14.3066 14.7856 13.7985 15.0031 13.491 15.1306C13.1103 15.2862 13.386 15.7175 13.791 15.71C14.1341 15.7025 14.5297 15.5506 14.5297 15.5506L15.0078 15.7381C14.8053 15.7512 14.256 15.7906 14.1416 16.1187C14.016 16.4806 14.2503 16.7825 14.2503 16.7825C14.2503 16.7825 14.1041 16.9756 14.2503 17.2868C14.3685 17.5362 14.7228 17.7368 15.0622 17.7425C15.2366 18.2825 15.6978 18.3818 15.9603 18.3781C16.5172 18.3725 16.9747 18.0293 17.1735 17.6281C17.4097 17.1481 17.4678 16.5087 17.0422 15.5468C16.7928 14.9843 16.3616 14.1012 15.9866 13.9231C15.5235 13.7037 15.4672 13.7187 14.811 13.6193Z"
        fill="url(#paint6_linear_1554_118156)"
      />
      <path
        d="M14.79 17.6973C14.8781 17.7236 14.97 17.7405 15.0619 17.7423C15.1331 17.9655 15.255 18.1098 15.3919 18.2073C15.3394 18.1492 15.2456 17.9786 15.2363 17.9411C15.1838 17.703 15.2306 17.6017 15.3075 17.4573C15.3844 17.313 15.3806 17.2098 15.3225 17.1648C15.2531 17.1105 14.94 17.2136 14.8669 17.2192C14.8669 17.2192 15.0731 17.418 14.985 17.568C14.94 17.6392 14.8612 17.6786 14.79 17.6973ZM14.25 16.7823C14.25 16.7823 14.58 16.4167 15.3937 16.4692C15.3937 16.4692 15.1462 16.3005 14.79 16.3473C14.3719 16.4036 14.25 16.7823 14.25 16.7823ZM15.0075 15.7361L15.4988 15.7305C15.4988 15.7305 15.1781 15.618 14.9944 15.5842C14.8106 15.5523 14.5294 15.5486 14.5294 15.5486L15.0075 15.7361Z"
        fill="#EDA600"
      />
      <path
        d="M12.139 11.6808H10.7946V13.1583C10.7946 13.8258 11.3421 14.3677 12.0171 14.3677H12.2609C12.9359 14.3677 13.4834 13.8258 13.4834 13.1583V11.6808H12.139ZM14.4621 6.6727L8.21649 7.55582C7.54337 7.65145 7.07462 8.3152 7.17587 9.03332C7.27712 9.75145 7.91087 10.2596 8.58399 10.1639L14.8296 9.28082C15.5027 9.1852 15.9715 8.52145 15.8702 7.80332C15.769 7.0852 15.1352 6.57707 14.4621 6.6727Z"
        fill="#E59600"
      />
      <path
        d="M15.3749 6.87859C14.8424 4.24984 12.898 3.02546 11.3793 2.81359C11.1781 2.7847 10.9748 2.77403 10.7718 2.78171C10.6368 2.78734 10.5093 2.80234 10.3911 2.82671C10.1024 2.88484 9.79115 3.01046 9.4799 3.19796C8.16365 3.98546 6.8474 5.87359 7.38177 8.50609C7.97615 11.4442 9.83802 12.6723 11.7149 12.5898C11.9286 12.5805 12.1424 12.5542 12.358 12.5111C14.4318 12.0855 16.0405 10.1523 15.3749 6.87859Z"
        fill="#FFCA28"
      />
      <path
        d="M10.1854 7.09058C10.0485 6.95933 9.75416 6.78308 9.24791 6.86183C8.74166 6.94058 8.51478 7.19933 8.42666 7.36621C8.38728 7.43933 8.43041 7.55933 8.47166 7.60058C8.51103 7.63808 8.60666 7.67183 8.70603 7.62121C8.80541 7.56871 8.99291 7.43183 9.33416 7.37558C9.67728 7.32496 9.89853 7.39808 10.0073 7.41683C10.1179 7.43558 10.1929 7.36996 10.2173 7.32308C10.2473 7.26871 10.2454 7.15058 10.1854 7.09058ZM14.1379 6.46996C14.001 6.33871 13.7067 6.16246 13.2004 6.24121C12.6942 6.31996 12.4673 6.57871 12.3792 6.74558C12.3398 6.81871 12.3735 6.93121 12.4148 6.97246C12.4542 7.00996 12.5592 7.05121 12.6585 7.00058C12.7579 6.94808 12.9454 6.81121 13.2867 6.75496C13.6298 6.70433 13.851 6.77746 13.9598 6.79621C14.0892 6.81871 14.1623 6.73808 14.1867 6.69121C14.2167 6.63683 14.1979 6.52808 14.1379 6.46996Z"
        fill="#6D4C41"
      />
      <path
        d="M12.7969 9.96122C12.45 10.25 11.1956 10.4468 10.7756 10.28C10.5356 10.1843 10.3425 10.4187 10.4644 10.6043C10.5844 10.7862 11.3419 11.135 11.9344 11.0412C12.5269 10.9475 13.1306 10.3868 13.1887 10.175C13.2487 9.96122 12.9956 9.79622 12.7969 9.96122Z"
        fill="#795548"
      />
      <path
        d="M12.0564 8.82113C12.0432 8.81738 12.0301 8.81738 12.017 8.81738L11.1676 8.95051C11.1545 8.95426 11.1432 8.95988 11.1301 8.96738C11.0589 9.01051 11.0289 9.09676 11.0776 9.17738C11.1282 9.25613 11.3345 9.46801 11.6757 9.41551C12.017 9.36113 12.1482 9.09676 12.1707 9.00676C12.1932 8.91301 12.1389 8.83988 12.0564 8.82113Z"
        fill="#E59600"
      />
      <path
        d="M9.49149 8.50814C9.24212 8.54752 9.00774 8.52127 8.83712 8.45002C8.76399 8.42002 8.72837 8.45002 8.75274 8.52502C8.84649 8.81939 9.18962 9.00502 9.56087 8.94689C9.93212 8.88877 10.2021 8.60752 10.2002 8.29814C10.2002 8.21939 10.1571 8.20064 10.0971 8.25127C9.95649 8.37314 9.74274 8.46877 9.49149 8.50814ZM13.459 7.88377C13.2096 7.92314 12.9752 7.89689 12.8046 7.82564C12.7315 7.79377 12.6959 7.82564 12.7202 7.90252C12.814 8.19689 13.1571 8.38252 13.5284 8.32439C13.8996 8.26627 14.1696 7.98502 14.1677 7.67564C14.1677 7.59689 14.1246 7.57814 14.0646 7.62877C13.924 7.74877 13.7084 7.84439 13.459 7.88377Z"
        fill="#404040"
      />
      <path
        d="M10.4001 1.75063H10.3964C5.25515 2.62438 7.2764 8.32813 7.2764 8.32813C7.2764 8.32813 7.6064 8.89626 7.7564 9.14563C7.7789 9.18126 7.83327 9.16813 7.83515 9.12688C7.86327 8.61688 7.97202 6.80938 8.08452 6.24688C8.11659 6.08648 8.20402 5.94245 8.33152 5.83997C8.45902 5.73749 8.61848 5.68308 8.78202 5.68626C9.3014 5.69563 10.1226 5.67501 11.0095 5.53063C11.0133 5.53063 11.0151 5.52876 11.0189 5.52876C11.0226 5.52876 11.0245 5.52876 11.0283 5.52688C11.9151 5.38063 12.6989 5.13501 13.1883 4.96063C13.507 4.84813 13.8595 4.97563 14.0301 5.26813C14.3151 5.76313 14.9939 7.43001 15.187 7.91001C15.202 7.94938 15.2601 7.94376 15.2676 7.90251L15.4626 6.97813C15.4626 6.98001 15.5489 0.931258 10.4001 1.75063Z"
        fill="#543930"
      />
      <path
        d="M15.464 6.98001C15.464 6.98001 15.5502 0.931258 10.4015 1.75063H10.3977C10.3171 1.76376 10.2402 1.77876 10.1634 1.79563C10.0115 1.82751 9.86523 1.86313 9.72648 1.90438C9.7246 1.90438 9.7246 1.90438 9.72273 1.90626C9.71335 1.90813 9.70398 1.91188 9.6946 1.91563C5.45335 3.17376 7.28148 8.33001 7.28148 8.33001L7.76148 9.14376C7.7821 9.17938 7.83835 9.16626 7.84023 9.12501C7.87023 8.61126 7.9771 6.81126 8.0896 6.24876C8.12167 6.08835 8.2091 5.94432 8.3366 5.84184C8.4641 5.73937 8.62356 5.68496 8.7871 5.68813C9.30648 5.69751 10.1277 5.67688 11.0146 5.53251C11.0184 5.53251 11.0202 5.53063 11.024 5.53063C11.0277 5.53063 11.0296 5.53063 11.0334 5.52876C11.9202 5.38251 12.704 5.13688 13.1934 4.96251C13.5121 4.85001 13.8646 4.97751 14.0352 5.27001C14.3221 5.76688 15.0046 7.44876 15.194 7.91938C15.209 7.95876 15.2652 7.95313 15.2746 7.91188C15.3327 7.62688 15.464 6.98001 15.464 6.98001Z"
        fill="url(#paint7_radial_1554_118156)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1554_118156"
          x1="11.6751"
          y1="15.0929"
          x2="11.6751"
          y2="22.8897"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00BFA5" />
          <stop offset="0.47" stopColor="#00B29A" />
          <stop offset="1" stopColor="#009E89" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1554_118156"
          x1="14.6566"
          y1="27.816"
          x2="14.6566"
          y2="14.3483"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#212121" stopOpacity="0.8" />
          <stop offset="1" stopColor="#212121" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1554_118156"
          x1="15.5504"
          y1="14.9787"
          x2="10.6923"
          y2="17.2983"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#81D4FA" />
          <stop offset="1" stopColor="#0288D1" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1554_118156"
          x1="10.8539"
          y1="23.4241"
          x2="10.8539"
          y2="15.5753"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1" stopColor="#212121" stopOpacity="0.4" />
          <stop offset="1" stopColor="#252525" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1554_118156"
          x1="17.196"
          y1="19.0646"
          x2="18.9459"
          y2="23.3458"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00BFA5" />
          <stop offset="0.47" stopColor="#00B29A" />
          <stop offset="1" stopColor="#009E89" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1554_118156"
          x1="11.7242"
          y1="22.5054"
          x2="16.896"
          y2="22.5054"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB300" />
          <stop offset="0.5" stopColor="#FFCA28" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1554_118156"
          x1="16.8517"
          y1="18.3436"
          x2="14.8347"
          y2="14.9916"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB300" />
          <stop offset="0.5" stopColor="#FFCA28" />
        </linearGradient>
        <radialGradient
          id="paint7_radial_1554_118156"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(11.2026 6.62766) rotate(-9.34646) scale(4.37108 4.93153)"
        >
          <stop offset="0.794" stopColor="#6D4C41" stopOpacity="0" />
          <stop offset="1" stopColor="#6D4C41" />
        </radialGradient>
      </defs>
    </SvgIcon>
  )
}
