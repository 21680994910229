import { Stack, Alert, Typography, useTheme } from '@mui/material'
import { CheckCircleOutlined } from '@mui/icons-material'
import { EVENT_BRAND_ADVOCATE_SALES_PERCENTAGE } from '@momentum/utils/proposalUtils'
import { useFormContext } from 'react-hook-form'
import { ProposalCreateForm } from '@momentum/routes/proposals-create/types'
import { EventConfig } from '@momentum/utils/eventUtils'
import { DateTime } from 'luxon'
import { Line } from 'react-chartjs-2'
import { defaultTooltipOptions } from '@momentum/utils/tooltipUtils'
import { orderBy } from 'lodash'
import { notEmpty } from '@productwindtom/shared-node'

export const SeasonalEventGraph = ({
  eventConfig,
  durationWeeks
}: {
  eventConfig: EventConfig
  durationWeeks?: number
}) => {
  const { watch } = useFormContext<ProposalCreateForm>()

  const launchDate = watch('launchDate')
  const recommendedLaunchDate = watch('recommendedLaunchDate')
  const estimatedUnitsSoldPerMonth = watch('estimatedUnitsSoldPerMonth') || 0

  const recommendedUnits = Math.max((estimatedUnitsSoldPerMonth || 0) * EVENT_BRAND_ADVOCATE_SALES_PERCENTAGE, 100)

  return (
    <Stack
      border={t => `1px solid ${t.palette.grey.A200}`}
      borderRadius={'4px'}
      bgcolor={'grey.A100'}
      p={2}
      spacing={2}
    >
      <Alert
        variant={'outlined'}
        severity={'success'}
        icon={<CheckCircleOutlined color={'success'} />}
        sx={{ py: 0, px: 1, bgcolor: 'white' }}
        data-cy={'recommendationMessage'}
      >
        <Typography variant={'label2'} flexShrink={1} color={'black'}>
          Your campaign recommendation for your seasonal event
        </Typography>
        <Typography variant={'body2'} flexShrink={1} color={'black'}>
          We recommend starting your campaign on {recommendedLaunchDate?.toLocaleString()}. This will boost SEO before
          peak sales days leading into your event. Based on your expected sales, we recommend using{' '}
          {recommendedUnits?.toLocaleString()} Brand advocates{' '}
          {recommendedUnits > 100
            ? `(${EVENT_BRAND_ADVOCATE_SALES_PERCENTAGE * 100}% of
          the ${estimatedUnitsSoldPerMonth.toLocaleString()} expected units sold) `
            : ``}
          to maximize visibility and conversion.
        </Typography>
      </Alert>
      {!!recommendedLaunchDate && (
        <AdaptiveGraph
          launchDate={launchDate}
          eventConfig={eventConfig}
          recommendedLaunchDate={recommendedLaunchDate}
          durationWeeks={durationWeeks}
        />
      )}
    </Stack>
  )
}

type DataRecord = {
  value: number
  date?: DateTime
  label: string
}

/**
 * Renders a line graph component that visualizes campaign timing in relation to seasonal events.
 * Shows peak sales periods, event dates, and campaign start/end dates with recommended vs selected timings.
 *
 * @param launchDate - The selected campaign launch date
 * @param eventConfig - Configuration for the seasonal event including peak sales period and event date
 * @param recommendedLaunchDate - The recommended optimal launch date for the campaign
 * @param durationWeeks - Optional campaign duration in weeks
 * @returns A styled Line chart component with gradient fill and custom tooltips
 */
const AdaptiveGraph = ({
  launchDate,
  eventConfig,
  recommendedLaunchDate,
  durationWeeks
}: {
  launchDate: DateTime
  eventConfig: EventConfig
  recommendedLaunchDate: DateTime
  durationWeeks?: number
}) => {
  const theme = useTheme()

  const recommendedEnd = recommendedLaunchDate.plus({ weeks: durationWeeks || 0 })
  const startMatchesRecommended = launchDate.toISODate() === recommendedLaunchDate.toISODate()
  const endDate = launchDate.plus({ weeks: durationWeeks || 0 })
  const endMatchesRecommended =
    launchDate.plus({ weeks: durationWeeks || 0 }).toISODate() === recommendedEnd.toISODate()

  // Construct the records based on if the dates are different or the same than the recommended
  const peakSalesStart = eventConfig.peakSalesPeriodStart
  const peakSalesEnd = eventConfig.peakSalesPeriodEnd
  const eventDate = eventConfig.eventDate
  const data: DataRecord[] = [
    { value: 1, date: peakSalesStart, label: 'Peak sales start' },
    { value: 6, date: peakSalesEnd, label: 'Peak sales end' },
    { value: 0, date: eventDate, label: eventConfig.name }
  ]

  if (startMatchesRecommended) {
    data.push({ value: 0, date: launchDate, label: '' })
    data.push({ value: 0, date: launchDate, label: 'Campaign starts' })
  } else {
    data.push({ value: 0, date: launchDate, label: 'Selected: Campaign starts' })
  }

  if (endMatchesRecommended) {
    data.push({ value: 0, date: endDate, label: 'Campaign ends' })
  } else {
    data.push({ value: 0, date: endDate, label: 'Selected: Campaign ends' })
  }

  // Need to add an xValue so we can generate the labels correctly and have them placed correctly.
  // We also need to do this so we can add extra points to manipulate the graph
  const ordered = [{ value: 0, label: '' }, ...orderBy(data, 'date'), { value: 0, label: '' }]
    .map((d, i) => ({
      ...d,
      xValue: i
    }))
    .map(r => (r.label.includes('Peak sales start') ? [r, { ...r, label: '', xValue: r.xValue + 0.1, value: 1 }] : r))
    .flat()

  const indexOfPeakSalesStart = ordered.findIndex(o => o.label.includes('Peak sales start'))
  const indexOfPeakSalesEnd = ordered.findIndex(o => o.label.includes('Peak sales end'))
  const indexOfEventDate = ordered.findIndex(o => o.label.includes(eventConfig.name))
  const indexOfCampaignStart = ordered.findIndex(o => o.label.includes('Campaign starts'))
  const indexOfCampaignEnd = ordered.findIndex(o => o.label.includes('Campaign ends'))

  if (
    (indexOfCampaignStart > indexOfPeakSalesStart && indexOfCampaignStart < indexOfPeakSalesEnd) ||
    (indexOfCampaignStart > indexOfPeakSalesEnd && indexOfCampaignStart < indexOfEventDate)
  ) {
    ordered[indexOfCampaignStart].value = 3
  }

  if (
    (indexOfCampaignEnd > indexOfPeakSalesStart && indexOfCampaignEnd < indexOfPeakSalesEnd) ||
    (indexOfCampaignEnd > indexOfPeakSalesEnd && indexOfCampaignEnd < indexOfEventDate)
  ) {
    ordered[indexOfCampaignEnd].value = 3
  }

  const orderedLabels = ordered.map(d =>
    d.label ? [...d.label.split(': '), d.date?.toLocaleString()].filter(notEmpty) : ''
  )

  const labels = ordered
    .filter(o => o.xValue % 0.5 === 0)
    .map(d => (d.label ? [...d.label.split(': '), d.date?.toLocaleString()].filter(notEmpty) : ''))
  const datapoints = ordered.map(d => d.value)

  return (
    <Stack bgcolor={'white'} border={t => `1px solid ${t.palette.grey.A200}`} borderRadius={'4px'}>
      <Stack px={3} py={2} spacing={1}>
        <Typography variant={'label2'}>{eventConfig.name} campaign</Typography>
        <Typography variant={'body1'}>
          This graph displays the{' '}
          <Typography component={'span'} variant={'body1'} fontWeight={800}>
            typical traffic trends
          </Typography>{' '}
          leading into this seasonal event.
        </Typography>
      </Stack>
      <Line
        style={{ background: 'white', paddingLeft: '0px' }}
        height={'300px'}
        data={{
          labels: ordered.map(o => o.xValue),
          datasets: [
            {
              cubicInterpolationMode: 'monotone',
              data: datapoints,
              fill: true,
              borderColor: theme.palette.info.main,
              pointBackgroundColor: 'white',
              pointBorderColor: (item: any) =>
                orderedLabels[item.dataIndex]?.includes('Selected') ? theme.palette.grey.A400 : theme.palette.info.main,
              borderWidth: 4,
              backgroundColor: function (context) {
                const chart = context.chart
                const { ctx } = chart
                const gradient = ctx.createLinearGradient(0, 0, 0, 400)
                gradient.addColorStop(1, '#4668EE00')
                gradient.addColorStop(0, '#4668EE')
                return gradient
              }
            }
          ]
        }}
        plugins={[
          {
            id: 'chartAreaBorder',
            beforeDraw(chart, args, options) {
              const {
                ctx,
                chartArea: { left, top, width, height }
              } = chart
              ctx.save()
              ctx.strokeStyle = theme.palette.grey.A200
              ctx.lineWidth = 1
              ctx.strokeRect(left, top, width, height)
              ctx.restore()
            }
          }
        ]}
        options={{
          backgroundColor: 'white',
          borderColor: theme.palette.grey.A200,
          layout: {
            padding: { left: 16 }
          },
          elements: {
            point: {
              pointStyle: 'circle',
              borderWidth: 3,
              hitRadius: (item: any) => (ordered[item.dataIndex]?.label ? 10 : 0),
              // Hides or shows the dot if there is a label.
              radius: (item: any) => (ordered[item.dataIndex]?.label ? 6 : 0)
            }
          },

          responsive: true,
          plugins: {
            legend: {
              display: false
            },

            tooltip: {
              ...defaultTooltipOptions,

              callbacks: {
                label: (item: any) => {
                  return orderedLabels[item.dataIndex] || ''
                },
                title: () => '',
                footer: () => ''
              }
            }
          },
          scales: {
            y: {
              beginAtZero: true,
              grid: {
                drawOnChartArea: true,
                drawTicks: false
              },
              offset: false,
              max: 7,
              min: -0.2, // Makes all big dots visible
              ticks: {
                display: false,
                color: 'black',
                padding: 8
              },
              border: {
                color: theme.palette.grey.A200,
                width: 1
              }
            },
            x: {
              min: 0,
              alignToPixels: true,
              type: 'linear',
              ticks: {
                stepSize: 1,
                minRotation: 58,
                padding: 6,

                callback: function (val, index) {
                  return labels[index]
                },
                // Sets the color for each label depending on if there is selected or recommended in it
                color: (item: any) =>
                  labels[item.index]?.includes('Recommended')
                    ? theme.palette.info.main
                    : labels[item.index]?.includes('Selected')
                      ? theme.palette.grey.A700
                      : 'black',
                font: {
                  family: theme.typography.fontFamily as string,
                  weight: theme.typography.fontWeightRegular as number,
                  size: theme.typography.label3.fontSize as number
                }
              },
              grid: {
                drawOnChartArea: false,
                drawTicks: false
              },
              border: {
                color: theme.palette.grey.A200,
                width: 1
              }
            }
          }
        }}
      />
    </Stack>
  )
}
