export const SAMPLE_CAMPAIGN_REVIEW_METRICS_KEYBOARD = [
  {
    fromDate: '2024-01-09',
    toDate: '2024-01-11',
    numRatings: 1,
    numReviews: 1,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 5,
    momentumRating: 0,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-01-11',
    toDate: '2024-01-12',
    numRatings: 1,
    numReviews: 1,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 5,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-12',
    toDate: '2024-01-13',
    numRatings: 1,
    numReviews: 1,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 5,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-13',
    toDate: '2024-01-14',
    numRatings: 1,
    numReviews: 1,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 5,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-14',
    toDate: '2024-01-15',
    numRatings: 1,
    numReviews: 1,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 5,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-15',
    toDate: '2024-01-16',
    numRatings: 1,
    numReviews: 1,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 5,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-16',
    toDate: '2024-01-17',
    numRatings: 2,
    numReviews: 2,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 2,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 5,
    momentumRating: 0,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-01-17',
    toDate: '2024-01-18',
    numRatings: 2,
    numReviews: 2,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 5,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-18',
    toDate: '2024-01-19',
    numRatings: 2,
    numReviews: 2,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 5,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-19',
    toDate: '2024-01-20',
    numRatings: 2,
    numReviews: 2,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 5,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-20',
    toDate: '2024-01-21',
    numRatings: 4,
    numReviews: 4,
    ratingsInPeriod: 2,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 1,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 5,
    momentumRating: 4.8,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-01-21',
    toDate: '2024-01-22',
    numRatings: 4,
    numReviews: 4,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 5,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-22',
    toDate: '2024-01-23',
    numRatings: 4,
    numReviews: 4,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 5,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-23',
    toDate: '2024-01-24',
    numRatings: 5,
    numReviews: 5,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-01-24',
    toDate: '2024-01-26',
    numRatings: 6,
    numReviews: 6,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 1,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.5,
    momentumRating: 4.8,
    organicRating: 4.2,
    vineRating: 0
  },
  {
    fromDate: '2024-01-26',
    toDate: '2024-01-27',
    numRatings: 6,
    numReviews: 6,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.5,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-27',
    toDate: '2024-01-29',
    numRatings: 7,
    numReviews: 7,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [
      {
        title: 'Beautiful',
        text: 'The colors in the ad don’t do it justice! Love the built in mag charger. Lightweight case that is flexible but strong enough to protect! Love the zagg products!',
        name: 'Momsnest_boutique',
        rating: 5,
        attributedUserId: '74721a0d-cb1b-4326-8a7e-8175bd350651',
        reviewedAt: '2024-10-12T00:00:00.000Z'
      }
    ],
    rating: 4.5,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-29',
    toDate: '2024-01-30',
    numRatings: 8,
    numReviews: 8,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [
      {
        title: 'Cute case!',
        text: 'I love the iridescence of the cover when you move it in the light. I also love the wave pattern! Having a MagSafe compatible case makes it even better and all the buttons and charging ports are accessible. Love this case!',
        name: 'Erin Ludy',
        rating: 5,
        attributedUserId: '6db9f1d2-a2eb-4186-99d2-9ad98d06ad23',
        reviewedAt: '2024-10-12T00:00:00.000Z'
      }
    ],
    rating: 4.5,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-01-30',
    toDate: '2024-01-31',
    numRatings: 13,
    numReviews: 13,
    ratingsInPeriod: 5,
    momentumRatingCount: 4,
    vineReviewCount: 1,
    momentumReviewCount: 4,
    momentumReviewRating: 4.95,
    organicRatingCount: 0,
    reviewsInPeriod: 5,
    reviews: [
      {
        title: 'The case in good quality',
        text: 'This case is strong enough to protect my phone. I love its design also',
        name: 'J',
        rating: 5,
        attributedUserId: 'fc80d254-2d70-482e-b433-8eccfabe48dc',
        reviewedAt: '2024-10-13T00:00:00.000Z'
      },
      {
        title: 'Great quality and aesthetically pleasing phone case',
        text: 'I like the phone case a lot as it’s light, simple, protective and makes your phone look like the ocean. super comfortable and cool case but has a high price tag which is a little unfortunate',
        name: 'Iris Delany',
        rating: 5,
        attributedUserId: '71056abd-4d84-42cf-b082-dde7d7ab98df',
        reviewedAt: '2024-10-13T00:00:00.000Z'
      },
      {
        title: 'Good quality!',
        text: 'This case is really beautiful!',
        name: 'Jhoen',
        rating: 5,
        attributedUserId: '40fdcf8b-22fa-435b-8d5a-5df22587aa28',
        reviewedAt: '2024-10-14T00:00:00.000Z'
      }
    ],
    rating: 4.6,
    momentumRating: 4.95,
    organicRating: 0,
    vineRating: 5
  },
  {
    fromDate: '2024-01-31',
    toDate: '2024-02-01',
    numRatings: 15,
    numReviews: 15,
    ratingsInPeriod: 2,
    momentumRatingCount: 2,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4.7,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-02-01',
    toDate: '2024-02-02',
    numRatings: 15,
    numReviews: 15,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 4.7,
    vineRating: 0
  },
  {
    fromDate: '2024-02-02',
    toDate: '2024-02-03',
    numRatings: 17,
    numReviews: 17,
    ratingsInPeriod: 2,
    momentumRatingCount: 1,
    vineReviewCount: 1,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 1,
    reviewsInPeriod: 2,
    reviews: [
      {
        title: 'Absolutely recommend',
        text: 'It fits my phone perfectly, I’m definitely planning on getting another soon',
        name: 'Shami Hewitt',
        rating: 5,
        attributedUserId: '0b43ebf2-b988-4bc5-9c43-46ff2511af8f',
        reviewedAt: '2024-10-17T00:00:00.000Z'
      }
    ],
    rating: 4.7,
    momentumRating: 5,
    organicRating: 4.100000000000001,
    vineRating: 5
  },
  {
    fromDate: '2024-02-03',
    toDate: '2024-02-04',
    numRatings: 18,
    numReviews: 18,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 1,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.8,
    momentumRating: 4.8,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-02-04',
    toDate: '2024-02-05',
    numRatings: 20,
    numReviews: 20,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.8,
    momentumRating: 0,
    organicRating: 4.8,
    vineRating: 0
  },
  {
    fromDate: '2024-02-05',
    toDate: '2024-02-06',
    numRatings: 23,
    numReviews: 23,
    ratingsInPeriod: 3,
    momentumRatingCount: 2,
    vineReviewCount: 1,
    momentumReviewCount: 2,
    momentumReviewRating: 4.9,
    organicRatingCount: 0,
    reviewsInPeriod: 3,
    reviews: [
      {
        title: 'Super cute',
        text: 'Love my new phone case! It’s lightweight, easy to put on my phone and super cute!',
        name: 'Taylor Wysong',
        rating: 5,
        attributedUserId: 'a9f6f5bc-cd23-4300-a906-c8a551384e56',
        reviewedAt: '2024-10-21T00:00:00.000Z'
      }
    ],
    rating: 4.7,
    momentumRating: 4.9,
    organicRating: 0,
    vineRating: 5
  },
  {
    fromDate: '2024-02-06',
    toDate: '2024-02-07',
    numRatings: 24,
    numReviews: 24,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 4.7,
    vineRating: 0
  },
  {
    fromDate: '2024-02-07',
    toDate: '2024-02-08',
    numRatings: 31,
    numReviews: 31,
    ratingsInPeriod: 7,
    momentumRatingCount: 1,
    vineReviewCount: 1,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 6,
    reviewsInPeriod: 7,
    reviews: [],
    rating: 4.8,
    momentumRating: 4.8,
    organicRating: 4.933333333333334,
    vineRating: 4
  },
  {
    fromDate: '2024-02-08',
    toDate: '2024-02-09',
    numRatings: 36,
    numReviews: 36,
    ratingsInPeriod: 5,
    momentumRatingCount: 2,
    vineReviewCount: 1,
    momentumReviewCount: 2,
    momentumReviewRating: 4.9,
    organicRatingCount: 2,
    reviewsInPeriod: 5,
    reviews: [
      {
        title: 'Look cool AND protect your iPhone',
        text: 'Very cool iridescent color. Every angle and every lighting scheme gives off a different color.  Easy to pop on straight from the box. Made from recycled materials which is a huge plus. 13ft drop protection……although I will take their word for that.  It has a light/slim design that won’t weigh you down and comes with the Zagg Limited Lifetime Warranty!  Great quality and durability, awesome value and a great name to stand behind it!',
        name: 'Darin & Gianna',
        rating: 5,
        attributedUserId: '89098b99-0601-4db7-ac49-5edc6191016a',
        reviewedAt: '2024-10-24T00:00:00.000Z'
      }
    ],
    rating: 4.8,
    momentumRating: 4.9,
    organicRating: 5,
    vineRating: 5
  },
  {
    fromDate: '2024-02-09',
    toDate: '2024-02-10',
    numRatings: 44,
    numReviews: 44,
    ratingsInPeriod: 8,
    momentumRatingCount: 1,
    vineReviewCount: 1,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 7,
    reviewsInPeriod: 8,
    reviews: [],
    rating: 4.7,
    momentumRating: 4.8,
    organicRating: 4.642857142857144,
    vineRating: 5
  },
  {
    fromDate: '2024-02-10',
    toDate: '2024-02-11',
    numRatings: 46,
    numReviews: 46,
    ratingsInPeriod: 2,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 1,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4.7,
    momentumRating: 4.8,
    organicRating: 4.6000000000000005,
    vineRating: 0
  },
  {
    fromDate: '2024-02-11',
    toDate: '2024-02-12',
    numRatings: 48,
    numReviews: 48,
    ratingsInPeriod: 2,
    momentumRatingCount: 0,
    vineReviewCount: 1,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 4.4,
    vineRating: 5
  },
  {
    fromDate: '2024-02-12',
    toDate: '2024-02-13',
    numRatings: 48,
    numReviews: 48,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 2,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 4.7,
    vineRating: 0
  },
  {
    fromDate: '2024-02-13',
    toDate: '2024-02-14',
    numRatings: 48,
    numReviews: 48,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 4.7,
    vineRating: 0
  },
  {
    fromDate: '2024-02-14',
    toDate: '2024-02-15',
    numRatings: 50,
    numReviews: 50,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.7,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-02-15',
    toDate: '2024-02-16',
    numRatings: 50,
    numReviews: 50,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-02-16',
    toDate: '2024-02-17',
    numRatings: 50,
    numReviews: 50,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-02-17',
    toDate: '2024-02-18',
    numRatings: 52,
    numReviews: 52,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.7,
    momentumRating: 0,
    organicRating: 4.7,
    vineRating: 0
  },
  {
    fromDate: '2024-02-18',
    toDate: '2024-02-19',
    numRatings: 54,
    numReviews: 54,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.7,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  }
]
