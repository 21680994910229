import { Retailer } from '@productwindtom/shared-momentum'
import * as yup from 'yup'

export const schema = yup.object().shape({
  // common fields
  isProductLive: yup.string().required().oneOf(['true', 'false']),
  productId: yup.string().required(),
  // live form fields
  productUrl: yup.string().optional(),
  productVariationSkus: yup.array().of(yup.string()).optional(),
  creditsSpendAgreed: yup
    .boolean()
    .required()
    .when('isProductLive', {
      is: 'true',
      then: (schema: yup.BooleanSchema) =>
        schema.isTrue('You must agree to use credits to schedule this campaign with this product').required(),
      otherwise: (schema: yup.BooleanSchema) => schema.optional()
    }),
  // manual form fields
  productTitle: yup
    .string()
    .required()
    .when('isProductLive', {
      is: 'false',
      then: (schema: yup.StringSchema) => schema.required('Product title is required when product URL is not provided'),
      otherwise: (schema: yup.StringSchema) => schema.optional()
    }),
  productImage: yup.string().optional(),
  retailer: yup
    .string()
    .oneOf(Object.values(Retailer))
    .required()
    .when('isProductLive', {
      is: 'false',
      then: (schema: yup.StringSchema) => schema.required('Retailer is required when product URL is not provided'),
      otherwise: (schema: yup.StringSchema) => schema.optional()
    }),
  price: yup
    .number()
    .required()
    .when('isProductLive', {
      is: 'false',
      then: (schema: yup.NumberSchema) => schema.required('Price is required when product URL is not provided'),
      otherwise: (schema: yup.NumberSchema) => schema.optional()
    })
})
