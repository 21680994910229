import { Campaign, Product, Proposal } from '@momentum/routes/brand/types'
import { Brand, Company } from '@momentum/routes/queries'

export const isAddProductCallback = (o: AddProductActiveOrCallback): o is AddProductCallbackArgs => {
  return typeof o === 'object'
}

export type AddProductCallbackArgs = {
  callback: AddProductCallback
  selectedProductId?: string
  disableManual?: boolean
  productPriceOverride?: number
  campaignId?: string
}

export type AddProductCallback = (params: {
  product: Product
  campaignId?: string
  selectedVariantIds?: string[]
  creditsRequired?: number
}) => Promise<void>

// boolean to just open/close the modal
// object to open the modal and pass a callback to be called when a product is selected
export type AddProductActiveOrCallback = boolean | AddProductCallbackArgs

export type BrandContextType = {
  brand: Brand
  company: Company
  proposals?: Proposal[]
  setProposals: React.Dispatch<React.SetStateAction<Proposal[] | undefined>>
  setProducts: React.Dispatch<React.SetStateAction<Product[] | undefined>>
  refreshProposal: (proposalId: string) => Promise<void>
  products?: Product[]
  loadingProducts?: boolean
  recommendations?: Product[]
  campaigns?: Campaign[]
  refreshCampaigns: () => Promise<void>

  adjustProductRecommendationVisibility: (id: string, isVisible: boolean) => Promise<Product | void>

  // Product Creation
  isAddProductActiveOrCallback: AddProductActiveOrCallback
  setIsAddProductActiveOrCallback: React.Dispatch<React.SetStateAction<AddProductActiveOrCallback>>

  amazonScrapedAt?: string
  walmartScrapedAt?: string
}
