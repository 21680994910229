import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Stack,
  styled,
  TableContainer,
  Typography,
  Box
} from '@mui/material'
import { DateTime } from 'luxon'
import { first, last, orderBy } from 'lodash'
import { useReviewsContext } from '@momentum/routes/campaign/e-commerce/reviewsV2/reviewsContext'
import { TableHeadCell } from '../../common/TableHeadCell'

const BodyTableCell = styled(TableCell)(({ theme }) => ({
  ...theme.typography.label2,
  fontWeight: 400
}))

export const ReviewsTable = () => {
  const { filteredReviewData } = useReviewsContext()

  const minDateString = first(filteredReviewData)?.date
  const maxDateString = last(filteredReviewData)?.date

  if (!minDateString || !maxDateString) return null

  const minDate = DateTime.fromISO(minDateString)
  const maxDate = DateTime.fromISO(maxDateString)

  const weeks = Math.ceil(maxDate.diff(minDate, 'weeks').weeks || 1)
  const weeksArray = new Array(weeks).fill(0)

  const weekData = weeksArray.map((_, index) => {
    const start = minDate.plus({ weeks: index })
    const end = start.plus({ weeks: 1 })
    const startString = start.toISODate()!
    const endString = end.toISODate()!
    const weekRangeData = orderBy(
      filteredReviewData.filter(d => d.date >= startString && d.date <= endString),
      'fromDate',
      'asc'
    )
    const total = (last(weekRangeData)?.cumulativeRatings || 0) - (first(weekRangeData)?.cumulativeRatings || 0)
    const average =
      (last(weekRangeData)?.cumulativeAverageRatings || 0) - (first(weekRangeData)?.cumulativeAverageRatings || 0)

    return {
      start,
      end,
      totalRatings: total,
      totalAverageRatings: average
    }
  })

  return (
    <Stack bgcolor={'white'} border={t => `1px solid ${t.palette.grey.A200}`} borderRadius={'4px'}>
      <TableContainer
        sx={{
          '&::-webkit-scrollbar': {
            height: '40px',
            background: 'white'
          },
          '&::-webkit-scrollbar-thumb': {
            border: '16px solid white',
            background: '#C4C4C4',
            borderRadius: '25px',
            height: '10px',
            width: '10px'
          }
        }}
      >
        <Table
          sx={{
            overflowX: 'auto',
            whiteSpace: 'nowrap'
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant={'label1'}>Weekly reviews</Typography>
              </TableCell>
              {weekData.map((d, index) => (
                <TableHeadCell key={index} week={index + 1} date={d.start} align={'center'} />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <BodyTableCell>
                <Stack direction={'row'} spacing={1}>
                  <Box width={8} borderRadius={100} bgcolor={'info.main'} />
                  <Typography variant={'label4'} fontWeight={800}>
                    Reviews & ratings
                  </Typography>
                </Stack>
              </BodyTableCell>
              {weekData.map((d, index) => (
                <BodyTableCell key={index} align={'center'}>
                  {d.totalRatings.toLocaleString()}
                </BodyTableCell>
              ))}
            </TableRow>
            <TableRow>
              <BodyTableCell>
                <Stack direction={'row'} spacing={1}>
                  <Box width={8} borderRadius={100} bgcolor={'error.light'} />
                  <Typography variant={'label4'} fontWeight={800}>
                    Predicted reviews & ratings
                  </Typography>
                </Stack>
              </BodyTableCell>
              {weekData.map((d, index) => (
                <BodyTableCell key={index} align={'center'}>
                  {d.totalAverageRatings.toLocaleString()}
                </BodyTableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
}
