import { SvgIcon, SvgIconProps } from '@mui/material'

export default function FootballIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 25" fill="none">
      <path
        d="M14.7876 14.0488L4.06445 23.5194C4.06445 23.5194 7.2857 24.5769 12.5113 22.7075C17.872 20.7894 22.4282 15.7513 22.9551 9.40628C23.4126 3.89565 22.4038 3.26003 22.0907 2.85315C21.7851 2.45565 21.2695 2.40503 21.2695 2.40503L14.7876 14.0488Z"
        fill="#6D2A19"
      />
      <path
        d="M5.2183 6.63134C0.722053 11.5532 0.903928 17.3863 1.0033 19.5782C1.06893 21.0407 1.32205 21.7832 2.03268 22.5651C2.69643 23.2945 4.08205 23.947 6.90393 23.3488C9.84205 22.7263 14.3139 20.6713 18.3639 16.0588C22.6596 11.1651 23.0121 3.44571 21.8496 2.61509C20.6871 1.78446 18.3302 1.15446 16.0408 1.28759C13.7514 1.42071 9.43518 2.01696 5.2183 6.63134Z"
        fill="#9C3C25"
      />
      <path
        d="M2.00161 22.5367C1.94724 22.5723 1.51036 22.2892 1.49161 21.7717C1.45786 20.898 2.04849 18.3873 2.88474 16.4692C3.67036 14.6636 4.23286 13.8555 4.23286 13.8555C4.23286 13.8555 3.70224 13.338 3.72099 12.3742C3.73974 11.4105 4.07349 9.56922 7.74286 6.0236C11.6279 2.26422 12.9385 2.6561 13.561 2.6711C14.0729 2.68422 14.8735 2.98797 14.9335 3.00485C14.9335 3.00485 16.1654 2.20047 17.4329 1.93422C18.7004 1.66797 19.9754 1.8161 20.1835 1.83672C20.3935 1.85735 20.7535 1.97547 21.0404 2.11235C21.3141 2.24172 21.6385 2.47047 21.6385 2.47047C21.6385 2.47047 19.3885 2.1911 17.9241 2.5661C16.4597 2.9411 15.541 3.4886 15.541 3.4886C15.541 3.4886 15.9535 4.2161 15.916 4.72047C15.8616 5.43485 15.3985 6.0236 14.0766 7.3811C12.9235 8.5661 8.78536 13.2311 8.27349 13.7542C7.61349 14.433 6.79224 14.7536 6.05911 14.6823C5.32786 14.6111 4.88161 14.3261 4.88161 14.3261C4.88161 14.3261 4.07724 15.7005 3.48849 17.148C2.89974 18.5955 2.50599 20.0767 2.16661 21.7548C2.08224 22.1786 2.10286 22.4692 2.00161 22.5367Z"
        fill="#6D291A"
      />
      <path
        d="M4.99342 13.6512C5.47155 13.9343 6.75217 14.0825 7.47967 13.4956C8.20717 12.9106 9.16717 11.5475 11.344 9.13433C12.994 7.30808 14.8897 5.75745 14.9797 4.87058C15.0978 3.69683 14.0403 3.27495 12.904 3.36308C11.7697 3.4512 9.6228 5.01308 7.84905 6.85808C6.0753 8.70308 4.69155 10.4581 4.40842 11.6993C4.23405 12.4625 4.41405 13.3062 4.99342 13.6512Z"
        fill="#9C3C25"
      />
      <path
        d="M12.7489 1.81065C12.7489 1.81065 14.1833 2.0694 15.6552 3.0444C17.127 4.0194 18.732 5.29065 19.7145 6.36877C20.2714 6.98002 21.8239 8.9694 21.9139 8.9694C22.0039 8.9694 22.182 7.78065 22.2364 7.33815C22.2889 6.89565 22.3789 6.46815 22.3245 6.30877C22.272 6.1494 21.2352 4.76377 19.557 3.27502C17.6502 1.58377 15.8802 1.28377 15.8802 1.28377C15.8802 1.28377 15.4208 1.2669 14.2639 1.4169C13.4258 1.52752 12.7489 1.81065 12.7489 1.81065ZM1.54391 13.4394C1.54391 13.4394 2.49454 13.6681 4.12579 14.8025C5.96329 16.0813 7.10516 17.09 8.48891 18.5094C9.80329 19.8575 11.2152 21.65 11.2152 21.7569C11.2152 21.8638 10.6564 22.0869 9.83704 22.4469C9.43579 22.6231 8.67266 22.9531 8.54704 22.8988C8.42329 22.8463 7.41641 21.3181 5.84516 19.8031C4.85141 18.845 3.76954 17.8175 2.38579 16.9644C1.50079 16.4206 1.05079 16.3156 1.05079 16.3156C1.05079 16.3156 1.04704 15.6706 1.23266 14.6938C1.39954 13.8294 1.54391 13.4394 1.54391 13.4394Z"
        fill="#F2F0EE"
      />
      <path
        d="M9.96495 6.75492C7.6062 8.98992 4.93057 12.5299 4.78245 12.7455C4.52557 13.1168 4.51995 13.4112 4.85182 13.6174C5.1837 13.8237 5.4237 13.4937 5.6337 13.2237C5.8662 12.9255 7.97745 9.87117 10.1824 7.74867C12.7324 5.29804 14.4837 4.14304 14.6974 3.93117C14.8549 3.77367 15.2656 3.59367 14.9281 3.27492C14.6712 3.03117 14.1649 3.34617 13.9531 3.50554C13.7393 3.66492 12.4606 4.39054 9.96495 6.75492Z"
        fill="#FDFFFF"
      />
      <path
        d="M12.4088 3.05196C12.0731 3.31259 12.105 3.78884 12.5063 4.10384C12.8119 4.34384 13.4644 4.85946 13.7756 5.14446C14.1544 5.49134 14.5163 5.55696 14.7956 5.33946C15.0881 5.11259 15.24 4.54821 14.7206 4.02696C14.205 3.51134 13.9069 3.20196 13.365 2.98634C13.0125 2.84571 12.6488 2.86821 12.4088 3.05196ZM11.9775 4.33634C11.6681 4.21634 11.1469 3.98196 10.7381 4.35321C10.3294 4.72446 10.545 5.20259 10.9125 5.46884C11.28 5.73509 11.8088 6.26009 12.3338 6.63321C12.6338 6.84509 13.1513 7.01946 13.5188 6.61446C13.8225 6.27884 13.5638 5.72384 13.335 5.44446C13.0688 5.11634 12.5213 4.54634 11.9775 4.33634ZM10.5431 5.87196C10.1419 5.64509 9.46877 5.42759 9.01314 5.89446C8.51439 6.40634 8.79564 6.78321 9.28502 7.08696C9.69564 7.34196 10.5319 8.06196 10.8038 8.24759C11.2481 8.55134 11.8144 8.41821 12.03 8.08446C12.3881 7.53134 11.8988 6.88821 11.5313 6.58821C11.1731 6.29571 10.7063 5.96571 10.5431 5.87196ZM7.44189 7.43384C7.14002 7.78446 7.09502 8.16134 7.56189 8.67134C8.02877 9.18134 8.84627 9.77946 9.31877 9.94071C9.86064 10.1245 10.1213 9.91821 10.3163 9.61446C10.5113 9.31071 10.3575 8.62634 9.85877 8.18196C9.54377 7.90071 8.98127 7.40196 8.28752 7.25009C7.91064 7.16759 7.63689 7.20696 7.44189 7.43384ZM6.69377 8.94321C6.43314 8.87009 6.09752 8.97509 5.90252 9.21509C5.70752 9.45321 5.61377 9.92571 5.84814 10.192C6.08627 10.4638 6.23064 10.5632 6.63002 10.9082C7.03127 11.2551 7.43252 11.6788 7.91064 11.7332C8.24627 11.7707 8.55002 11.6038 8.69252 11.3863C8.83314 11.1688 8.81814 10.6851 8.43189 10.2688C8.15814 9.97259 7.46252 9.15884 6.69377 8.94321ZM4.51689 10.9813C4.12127 11.497 4.33877 12.1963 5.31564 12.8132C6.11627 13.3213 6.82127 13.6776 7.30127 13.0945C7.75127 12.547 7.31252 11.9882 6.30377 11.2945C5.35877 10.6457 4.87502 10.5163 4.51689 10.9813Z"
        fill="#FDFFFF"
      />
    </SvgIcon>
  )
}
