import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'

export default [
  {
    unitsSold: 9,
    totalSalesAmount: 206991,
    pageViews: 0,
    date: '2023-09-01',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 137994,
    pageViews: 0,
    date: '2023-09-02',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 68997,
    pageViews: 0,
    date: '2023-09-03',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 91996,
    pageViews: 0,
    date: '2023-09-04',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 732,
    date: '2023-09-05',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 606,
    date: '2023-09-06',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 467,
    date: '2023-09-07',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 421,
    date: '2023-09-08',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 519,
    date: '2023-09-09',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 547,
    date: '2023-09-10',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 548,
    date: '2023-09-11',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 923,
    date: '2023-09-12',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 863,
    date: '2023-09-13',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 758,
    date: '2023-09-14',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 691,
    date: '2023-09-15',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 718,
    date: '2023-09-16',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 1018,
    date: '2023-09-17',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 890,
    date: '2023-09-18',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 114995,
    pageViews: 0,
    date: '2023-09-19',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 11,
    totalSalesAmount: 252989,
    pageViews: 0,
    date: '2023-09-20',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 68997,
    pageViews: 0,
    date: '2023-09-21',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 184193,
    pageViews: 0,
    date: '2023-09-22',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 229990,
    pageViews: 0,
    date: '2023-09-23',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 207620,
    pageViews: 0,
    date: '2023-09-24',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 160993,
    pageViews: 0,
    date: '2023-09-25',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 45998,
    pageViews: 0,
    date: '2023-09-26',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 229990,
    pageViews: 0,
    date: '2023-09-27',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 207222,
    pageViews: 0,
    date: '2023-09-28',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 115590,
    pageViews: 0,
    date: '2023-09-29',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 115225,
    pageViews: 0,
    date: '2023-09-30',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 184222,
    pageViews: 0,
    date: '2023-10-01',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 22999,
    pageViews: 0,
    date: '2023-10-02',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 511,
    date: '2023-10-03',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 460,
    date: '2023-10-04',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 537,
    date: '2023-10-05',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 563,
    date: '2023-10-06',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 628,
    date: '2023-10-07',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 702,
    date: '2023-10-08',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 777,
    date: '2023-10-09',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 1137,
    date: '2023-10-10',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 852,
    date: '2023-10-11',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 520,
    date: '2023-10-12',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 446,
    date: '2023-10-13',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 501,
    date: '2023-10-14',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 668,
    date: '2023-10-15',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 706,
    date: '2023-10-16',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 138380,
    pageViews: 0,
    date: '2023-10-17',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 183992,
    pageViews: 0,
    date: '2023-10-18',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 115224,
    pageViews: 0,
    date: '2023-10-19',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 161131,
    pageViews: 0,
    date: '2023-10-20',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 184295,
    pageViews: 0,
    date: '2023-10-21',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 206991,
    pageViews: 0,
    date: '2023-10-22',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 114995,
    pageViews: 0,
    date: '2023-10-23',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 183992,
    pageViews: 0,
    date: '2023-10-24',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 114995,
    pageViews: 0,
    date: '2023-10-25',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 183992,
    pageViews: 0,
    date: '2023-10-26',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 137994,
    pageViews: 0,
    date: '2023-10-27',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 91996,
    pageViews: 0,
    date: '2023-10-28',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 68997,
    pageViews: 0,
    date: '2023-10-29',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 114995,
    pageViews: 0,
    date: '2023-10-30',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 607,
    date: '2023-10-31',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 594,
    date: '2023-11-01',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 823,
    date: '2023-11-02',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 654,
    date: '2023-11-03',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 823,
    date: '2023-11-04',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 924,
    date: '2023-11-05',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 637,
    date: '2023-11-06',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 762,
    date: '2023-11-07',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 769,
    date: '2023-11-08',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 816,
    date: '2023-11-09',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 1094,
    date: '2023-11-10',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 1152,
    date: '2023-11-11',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 1154,
    date: '2023-11-12',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 874,
    date: '2023-11-13',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 207221,
    pageViews: 0,
    date: '2023-11-14',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 184289,
    pageViews: 0,
    date: '2023-11-15',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 183992,
    pageViews: 0,
    date: '2023-11-16',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 26,
    totalSalesAmount: 520189,
    pageViews: 0,
    date: '2023-11-17',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 25,
    totalSalesAmount: 500446,
    pageViews: 0,
    date: '2023-11-18',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 22,
    totalSalesAmount: 444515,
    pageViews: 0,
    date: '2023-11-19',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 24,
    totalSalesAmount: 480335,
    pageViews: 0,
    date: '2023-11-20',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 21,
    totalSalesAmount: 418818,
    pageViews: 0,
    date: '2023-11-21',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 25,
    totalSalesAmount: 503136,
    pageViews: 0,
    date: '2023-11-22',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 28,
    totalSalesAmount: 559972,
    pageViews: 0,
    date: '2023-11-23',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 67,
    totalSalesAmount: 1339953,
    pageViews: 0,
    date: '2023-11-24',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 21,
    totalSalesAmount: 420767,
    pageViews: 0,
    date: '2023-11-25',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 24,
    totalSalesAmount: 486483,
    pageViews: 0,
    date: '2023-11-26',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 63,
    totalSalesAmount: 1260861,
    pageViews: 0,
    date: '2023-11-27',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 1499,
    date: '2023-11-28',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 1136,
    date: '2023-11-29',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 1150,
    date: '2023-11-30',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 1126,
    date: '2023-12-01',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 1165,
    date: '2023-12-02',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 1433,
    date: '2023-12-03',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 1501,
    date: '2023-12-04',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 931,
    date: '2023-12-05',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 868,
    date: '2023-12-06',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 1016,
    date: '2023-12-07',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 1002,
    date: '2023-12-08',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 1066,
    date: '2023-12-09',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 2071,
    date: '2023-12-10',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 1769,
    date: '2023-12-11',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 21,
    totalSalesAmount: 420296,
    pageViews: 0,
    date: '2023-12-12',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 22,
    totalSalesAmount: 439978,
    pageViews: 0,
    date: '2023-12-13',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 19,
    totalSalesAmount: 381047,
    pageViews: 0,
    date: '2023-12-14',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 26,
    totalSalesAmount: 520537,
    pageViews: 0,
    date: '2023-12-15',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 25,
    totalSalesAmount: 499975,
    pageViews: 0,
    date: '2023-12-16',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 24,
    totalSalesAmount: 479976,
    pageViews: 0,
    date: '2023-12-17',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 23,
    totalSalesAmount: 459977,
    pageViews: 0,
    date: '2023-12-18',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 28,
    totalSalesAmount: 561471,
    pageViews: 0,
    date: '2023-12-19',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 18,
    totalSalesAmount: 360959,
    pageViews: 0,
    date: '2023-12-20',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 25,
    totalSalesAmount: 500697,
    pageViews: 0,
    date: '2023-12-21',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 13,
    totalSalesAmount: 260723,
    pageViews: 0,
    date: '2023-12-22',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 18,
    totalSalesAmount: 361483,
    pageViews: 0,
    date: '2023-12-23',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 20,
    totalSalesAmount: 399980,
    pageViews: 0,
    date: '2023-12-24',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 17,
    totalSalesAmount: 340701,
    pageViews: 0,
    date: '2023-12-25',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 1608,
    date: '2023-12-26',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 1419,
    date: '2023-12-27',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 1300,
    date: '2023-12-28',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 1252,
    date: '2023-12-29',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 1306,
    date: '2023-12-30',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 1216,
    date: '2023-12-31',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 1561,
    date: '2024-01-01',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 1395,
    date: '2024-01-02',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 1695,
    date: '2024-01-03',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 1442,
    date: '2024-01-04',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 1346,
    date: '2024-01-05',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 1627,
    date: '2024-01-06',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 1398,
    date: '2024-01-07',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 1225,
    date: '2024-01-08',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 206991,
    pageViews: 0,
    date: '2024-01-09',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 93157,
    pageViews: 0,
    date: '2024-01-10',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 230048,
    pageViews: 0,
    date: '2024-01-11',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 140994,
    pageViews: 0,
    date: '2024-01-12',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 160993,
    pageViews: 0,
    date: '2024-01-13',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 114995,
    pageViews: 0,
    date: '2024-01-14',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 137994,
    pageViews: 0,
    date: '2024-01-15',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 114971,
    pageViews: 0,
    date: '2024-01-16',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 229990,
    pageViews: 0,
    date: '2024-01-17',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 206991,
    pageViews: 0,
    date: '2024-01-18',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 69353,
    pageViews: 0,
    date: '2024-01-19',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 91996,
    pageViews: 0,
    date: '2024-01-20',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 221463,
    pageViews: 0,
    date: '2024-01-21',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 132157,
    pageViews: 0,
    date: '2024-01-22',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 153921,
    pageViews: 0,
    date: '2024-02-06',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 154410,
    pageViews: 0,
    date: '2024-02-07',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 87987,
    pageViews: 0,
    date: '2024-02-08',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 12,
    totalSalesAmount: 263988,
    pageViews: 0,
    date: '2024-02-09',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 18,
    totalSalesAmount: 395982,
    pageViews: 0,
    date: '2024-02-10',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: -1,
    totalSalesAmount: -21159,
    pageViews: 0,
    date: '2024-02-11',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 156330,
    pageViews: 0,
    date: '2024-02-12',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 198991,
    pageViews: 0,
    date: '2024-02-13',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 12,
    totalSalesAmount: 263988,
    pageViews: 0,
    date: '2024-02-14',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 131994,
    pageViews: 0,
    date: '2024-02-15',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 112995,
    pageViews: 0,
    date: '2024-02-16',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 184992,
    pageViews: 0,
    date: '2024-02-17',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 91996,
    pageViews: 0,
    date: '2024-02-18',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 15,
    totalSalesAmount: 344985,
    pageViews: 0,
    date: '2024-02-19',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 201677,
    pageViews: 841,
    date: '2024-03-05',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 183992,
    pageViews: 821,
    date: '2024-03-06',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 178678,
    pageViews: 874,
    date: '2024-03-07',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 92305,
    pageViews: 839,
    date: '2024-03-08',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 194028,
    pageViews: 882,
    date: '2024-03-09',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 176658,
    pageViews: 921,
    date: '2024-03-10',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 198492,
    pageViews: 792,
    date: '2024-03-11',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 12,
    totalSalesAmount: 263988,
    pageViews: 786,
    date: '2024-03-12',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 176241,
    pageViews: 796,
    date: '2024-03-13',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 154762,
    pageViews: 789,
    date: '2024-03-14',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 220142,
    pageViews: 826,
    date: '2024-03-15',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 176082,
    pageViews: 842,
    date: '2024-03-16',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 153993,
    pageViews: 1113,
    date: '2024-03-17',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 154165,
    pageViews: 991,
    date: '2024-03-18',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 12,
    totalSalesAmount: 272848,
    pageViews: 863,
    date: '2024-04-02',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 11,
    totalSalesAmount: 249927,
    pageViews: 858,
    date: '2024-04-03',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 15,
    totalSalesAmount: 341983,
    pageViews: 730,
    date: '2024-04-04',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 162602,
    pageViews: 717,
    date: '2024-04-05',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 68997,
    pageViews: 631,
    date: '2024-04-06',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 91996,
    pageViews: 741,
    date: '2024-04-07',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 161237,
    pageViews: 641,
    date: '2024-04-08',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 160993,
    pageViews: 653,
    date: '2024-04-09',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 115446,
    pageViews: 635,
    date: '2024-04-10',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 91996,
    pageViews: 643,
    date: '2024-04-11',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 160993,
    pageViews: 648,
    date: '2024-04-12',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 68997,
    pageViews: 619,
    date: '2024-04-13',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 11,
    totalSalesAmount: 241989,
    pageViews: 766,
    date: '2024-04-14',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 220105,
    pageViews: 718,
    date: '2024-04-15',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 15,
    totalSalesAmount: 329985,
    pageViews: 988,
    date: '2024-04-30',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 14,
    totalSalesAmount: 307986,
    pageViews: 878,
    date: '2024-05-01',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 87996,
    pageViews: 906,
    date: '2024-05-02',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 11,
    totalSalesAmount: 241989,
    pageViews: 811,
    date: '2024-05-03',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 87996,
    pageViews: 891,
    date: '2024-05-04',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 219990,
    pageViews: 882,
    date: '2024-05-05',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 175992,
    pageViews: 854,
    date: '2024-05-06',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 109995,
    pageViews: 710,
    date: '2024-05-07',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 175992,
    pageViews: 808,
    date: '2024-05-08',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 219922,
    pageViews: 691,
    date: '2024-05-09',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 197991,
    pageViews: 703,
    date: '2024-05-10',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 175992,
    pageViews: 733,
    date: '2024-05-11',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 132233,
    pageViews: 736,
    date: '2024-05-12',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 45408,
    pageViews: 697,
    date: '2024-05-13',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 22999,
    pageViews: 690,
    date: '2024-05-28',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 165246,
    pageViews: 727,
    date: '2024-05-29',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 11,
    totalSalesAmount: 252989,
    pageViews: 1,
    date: '2024-05-30',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 114995,
    pageViews: 0,
    date: '2024-05-31',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 68997,
    pageViews: 1,
    date: '2024-06-01',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 137994,
    pageViews: 1,
    date: '2024-06-02',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 45998,
    pageViews: 409,
    date: '2024-06-03',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 22999,
    pageViews: 438,
    date: '2024-06-04',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 1,
    totalSalesAmount: 22999,
    pageViews: 447,
    date: '2024-06-05',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 137994,
    pageViews: 843,
    date: '2024-06-06',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 137994,
    pageViews: 785,
    date: '2024-06-07',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 13,
    totalSalesAmount: 295497,
    pageViews: 905,
    date: '2024-06-08',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 137994,
    pageViews: 820,
    date: '2024-06-09',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 115025,
    pageViews: 790,
    date: '2024-06-10',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 16,
    totalSalesAmount: 367984,
    pageViews: 941,
    date: '2024-06-25',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 20,
    totalSalesAmount: 459980,
    pageViews: 1011,
    date: '2024-06-26',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 12,
    totalSalesAmount: 275988,
    pageViews: 1029,
    date: '2024-06-27',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 12,
    totalSalesAmount: 275988,
    pageViews: 887,
    date: '2024-06-28',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 22,
    totalSalesAmount: 505978,
    pageViews: 995,
    date: '2024-06-29',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 19,
    totalSalesAmount: 436981,
    pageViews: 1135,
    date: '2024-06-30',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 229990,
    pageViews: 828,
    date: '2024-07-01',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 160993,
    pageViews: 766,
    date: '2024-07-02',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 183992,
    pageViews: 748,
    date: '2024-07-03',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 91996,
    pageViews: 674,
    date: '2024-07-04',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 160993,
    pageViews: 678,
    date: '2024-07-05',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 206991,
    pageViews: 734,
    date: '2024-07-06',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 114995,
    pageViews: 725,
    date: '2024-07-07',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 45998,
    pageViews: 615,
    date: '2024-07-08',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 206991,
    pageViews: 1144,
    date: '2024-07-23',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 137994,
    pageViews: 983,
    date: '2024-07-24',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 0,
    pageViews: 873,
    date: '2024-07-25',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 45998,
    pageViews: 944,
    date: '2024-07-26',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 114995,
    pageViews: 1035,
    date: '2024-07-27',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 12,
    totalSalesAmount: 240988,
    pageViews: 1378,
    date: '2024-07-28',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 136493,
    pageViews: 2026,
    date: '2024-07-29',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 17,
    totalSalesAmount: 334433,
    pageViews: 1712,
    date: '2024-07-30',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 13,
    totalSalesAmount: 253487,
    pageViews: 1605,
    date: '2024-07-31',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 12,
    totalSalesAmount: 234363,
    pageViews: 1724,
    date: '2024-08-01',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 16,
    totalSalesAmount: 311984,
    pageViews: 1975,
    date: '2024-08-02',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 13,
    totalSalesAmount: 253144,
    pageViews: 1775,
    date: '2024-08-03',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 17,
    totalSalesAmount: 331483,
    pageViews: 1830,
    date: '2024-08-04',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 21,
    totalSalesAmount: 409479,
    pageViews: 1766,
    date: '2024-08-05',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 91996,
    pageViews: 857,
    date: '2024-08-20',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 4,
    totalSalesAmount: 91996,
    pageViews: 808,
    date: '2024-08-21',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 3500,
    pageViews: 837,
    date: '2024-08-22',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 68997,
    pageViews: 790,
    date: '2024-08-23',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 141494,
    pageViews: 839,
    date: '2024-08-24',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 15,
    totalSalesAmount: 295985,
    pageViews: 1347,
    date: '2024-08-25',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 26,
    totalSalesAmount: 507063,
    pageViews: 1978,
    date: '2024-08-26',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 17,
    totalSalesAmount: 329403,
    pageViews: 2145,
    date: '2024-08-27',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 15,
    totalSalesAmount: 292550,
    pageViews: 2021,
    date: '2024-08-28',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 97495,
    pageViews: 2368,
    date: '2024-08-29',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 97495,
    pageViews: 2374,
    date: '2024-08-30',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 175491,
    pageViews: 2535,
    date: '2024-08-31',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 17,
    totalSalesAmount: 331483,
    pageViews: 2852,
    date: '2024-09-01',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 25,
    totalSalesAmount: 485363,
    pageViews: 2128,
    date: '2024-09-02',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 14,
    totalSalesAmount: 279986,
    pageViews: 1340,
    date: '2024-09-17',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 180407,
    pageViews: 1215,
    date: '2024-09-18',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 179991,
    pageViews: 1162,
    date: '2024-09-19',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 119994,
    pageViews: 1060,
    date: '2024-09-20',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 139993,
    pageViews: 1247,
    date: '2024-09-21',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 19,
    totalSalesAmount: 378929,
    pageViews: 1287,
    date: '2024-09-22',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 21,
    totalSalesAmount: 378189,
    pageViews: 4466,
    date: '2024-09-23',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 23,
    totalSalesAmount: 414315,
    pageViews: 5497,
    date: '2024-09-24',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 25,
    totalSalesAmount: 451480,
    pageViews: 4842,
    date: '2024-09-25',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 15,
    totalSalesAmount: 267921,
    pageViews: 4960,
    date: '2024-09-26',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 23,
    totalSalesAmount: 413977,
    pageViews: 5395,
    date: '2024-09-27',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 27,
    totalSalesAmount: 485797,
    pageViews: 4766,
    date: '2024-09-28',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 26,
    totalSalesAmount: 466257,
    pageViews: 4629,
    date: '2024-09-29',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 17,
    totalSalesAmount: 305894,
    pageViews: 5816,
    date: '2024-09-30',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 23,
    totalSalesAmount: 413859,
    pageViews: 5156,
    date: '2024-10-01',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 15,
    totalSalesAmount: 269947,
    pageViews: 6713,
    date: '2024-10-02',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 27,
    totalSalesAmount: 486853,
    pageViews: 4879,
    date: '2024-10-03',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 22,
    totalSalesAmount: 396310,
    pageViews: 6454,
    date: '2024-10-04',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 18,
    totalSalesAmount: 323982,
    pageViews: 6214,
    date: '2024-10-05',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 12,
    totalSalesAmount: 216021,
    pageViews: 3383,
    date: '2024-10-06',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 187990,
    pageViews: 1700,
    date: '2024-10-07',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 82,
    totalSalesAmount: 1377113,
    pageViews: 8195,
    date: '2024-10-08',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 87,
    totalSalesAmount: 1455280,
    pageViews: 10336,
    date: '2024-10-09',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 43,
    totalSalesAmount: 731703,
    pageViews: 2921,
    date: '2024-10-10',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 63569,
    pageViews: 908,
    date: '2024-10-11',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 42708,
    pageViews: 610,
    date: '2024-10-12',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 0,
    totalSalesAmount: 5959,
    pageViews: 475,
    date: '2024-10-13',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 92441,
    pageViews: 895,
    date: '2024-10-14',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 127189,
    pageViews: 1453,
    date: '2024-10-15',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 179990,
    pageViews: 1238,
    date: '2024-10-16',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 179990,
    pageViews: 1173,
    date: '2024-10-17',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 18,
    totalSalesAmount: 323982,
    pageViews: 1204,
    date: '2024-10-18',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 53997,
    pageViews: 1406,
    date: '2024-10-19',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 125993,
    pageViews: 1384,
    date: '2024-10-20',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 16,
    totalSalesAmount: 287984,
    pageViews: 1429,
    date: '2024-10-21',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 17,
    totalSalesAmount: 305983,
    pageViews: 1521,
    date: '2024-10-22',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 89995,
    pageViews: 1294,
    date: '2024-10-23',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 179990,
    pageViews: 1040,
    date: '2024-10-24',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 161991,
    pageViews: 534,
    date: '2024-10-26',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 143992,
    pageViews: 972,
    date: '2024-10-27',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 180104,
    pageViews: 1059,
    date: '2024-10-28',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 11,
    totalSalesAmount: 198141,
    pageViews: 1067,
    date: '2024-10-29',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 108129,
    pageViews: 1169,
    date: '2024-10-30',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 125945,
    pageViews: 1184,
    date: '2024-10-31',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 108275,
    pageViews: 1210,
    date: '2024-11-01',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 89995,
    pageViews: 1126,
    date: '2024-11-02',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 11,
    totalSalesAmount: 197989,
    pageViews: 1354,
    date: '2024-11-03',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 179990,
    pageViews: 1297,
    date: '2024-11-04',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 179990,
    pageViews: 1212,
    date: '2024-11-05',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 14,
    totalSalesAmount: 251986,
    pageViews: 1149,
    date: '2024-11-06',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 16,
    totalSalesAmount: 287988,
    pageViews: 1696,
    date: '2024-11-07',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 14,
    totalSalesAmount: 251986,
    pageViews: 1368,
    date: '2024-11-08',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 16,
    totalSalesAmount: 287984,
    pageViews: 1256,
    date: '2024-11-09',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 21,
    totalSalesAmount: 360979,
    pageViews: 3873,
    date: '2024-11-10',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 22,
    totalSalesAmount: 373978,
    pageViews: 6103,
    date: '2024-11-11',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 26,
    totalSalesAmount: 442250,
    pageViews: 4855,
    date: '2024-11-12',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 18,
    totalSalesAmount: 303685,
    pageViews: 4587,
    date: '2024-11-13',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 22,
    totalSalesAmount: 373978,
    pageViews: 4452,
    date: '2024-11-14',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 15,
    totalSalesAmount: 253665,
    pageViews: 6035,
    date: '2024-11-15',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 18,
    totalSalesAmount: 303961,
    pageViews: 7222,
    date: '2024-11-16',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 17,
    totalSalesAmount: 288983,
    pageViews: 10600,
    date: '2024-11-17',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 17,
    totalSalesAmount: 286575,
    pageViews: 6311,
    date: '2024-11-18',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 12,
    totalSalesAmount: 204276,
    pageViews: 5018,
    date: '2024-11-19',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 151991,
    pageViews: 5756,
    date: '2024-11-20',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 28,
    totalSalesAmount: 475972,
    pageViews: 8426,
    date: '2024-11-21',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 40,
    totalSalesAmount: 677663,
    pageViews: 7288,
    date: '2024-11-22',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 38,
    totalSalesAmount: 645806,
    pageViews: 2443,
    date: '2024-11-23',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 22,
    totalSalesAmount: 373822,
    pageViews: 2148,
    date: '2024-11-24',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 40,
    totalSalesAmount: 679960,
    pageViews: 2453,
    date: '2024-11-25',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 34,
    totalSalesAmount: 577966,
    pageViews: 2440,
    date: '2024-11-26',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 44,
    totalSalesAmount: 747956,
    pageViews: 2759,
    date: '2024-11-27',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 72,
    totalSalesAmount: 999002,
    pageViews: 3542,
    date: '2024-11-28',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 127,
    totalSalesAmount: 1757896,
    pageViews: 4890,
    date: '2024-11-29',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 74,
    totalSalesAmount: 1030781,
    pageViews: 2663,
    date: '2024-11-30',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 15,
    totalSalesAmount: 208648,
    pageViews: 1692,
    date: '2024-12-01',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 51,
    totalSalesAmount: 705587,
    pageViews: 2129,
    date: '2024-12-02',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 75,
    totalSalesAmount: 1087197,
    pageViews: 3603,
    date: '2024-12-03',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 134871,
    pageViews: 1868,
    date: '2024-12-04',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 143992,
    pageViews: 2943,
    date: '2024-12-05',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 184974,
    pageViews: 1725,
    date: '2024-12-06',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 61849,
    pageViews: 1786,
    date: '2024-12-07',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 14,
    totalSalesAmount: 242986,
    pageViews: 1991,
    date: '2024-12-08',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 20,
    totalSalesAmount: 344808,
    pageViews: 1889,
    date: '2024-12-09',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 13,
    totalSalesAmount: 225865,
    pageViews: 1917,
    date: '2024-12-10',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 169990,
    pageViews: 1591,
    date: '2024-12-11',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 33,
    totalSalesAmount: 487216,
    pageViews: 1898,
    date: '2024-12-12',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 20,
    totalSalesAmount: 293652,
    pageViews: 1807,
    date: '2024-12-13',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 23,
    totalSalesAmount: 341002,
    pageViews: 1723,
    date: '2024-12-14',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 22,
    totalSalesAmount: 319277,
    pageViews: 1611,
    date: '2024-12-15',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 29,
    totalSalesAmount: 428769,
    pageViews: 1759,
    date: '2024-12-16',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 101218,
    pageViews: 1706,
    date: '2024-12-17',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 25,
    totalSalesAmount: 369397,
    pageViews: 1612,
    date: '2024-12-18',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 19,
    totalSalesAmount: 274604,
    pageViews: 1844,
    date: '2024-12-19',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 19,
    totalSalesAmount: 280739,
    pageViews: 2598,
    date: '2024-12-20',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 14,
    totalSalesAmount: 206875,
    pageViews: 2026,
    date: '2024-12-21',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 147835,
    pageViews: 1651,
    date: '2024-12-22',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 16,
    totalSalesAmount: 238332,
    pageViews: 1490,
    date: '2024-12-23',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 17,
    totalSalesAmount: 250997,
    pageViews: 1285,
    date: '2024-12-24',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 127797,
    pageViews: 1479,
    date: '2024-12-25',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 2,
    totalSalesAmount: 39237,
    pageViews: 1565,
    date: '2024-12-26',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 11,
    totalSalesAmount: 202139,
    pageViews: 1253,
    date: '2024-12-27',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 180800,
    pageViews: 1286,
    date: '2024-12-28',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 22,
    totalSalesAmount: 346007,
    pageViews: 1653,
    date: '2024-12-29',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 28,
    totalSalesAmount: 420629,
    pageViews: 1533,
    date: '2024-12-30',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 27,
    totalSalesAmount: 405427,
    pageViews: 1341,
    date: '2024-12-31',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 16,
    totalSalesAmount: 239984,
    pageViews: 1267,
    date: '2025-01-01',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 19,
    totalSalesAmount: 284981,
    pageViews: 1584,
    date: '2025-01-02',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 39,
    totalSalesAmount: 585337,
    pageViews: 1680,
    date: '2025-01-03',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 24,
    totalSalesAmount: 359945,
    pageViews: 1696,
    date: '2025-01-04',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 29,
    totalSalesAmount: 432285,
    pageViews: 1730,
    date: '2025-01-05',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 24,
    totalSalesAmount: 361244,
    pageViews: 1695,
    date: '2025-01-06',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 27,
    totalSalesAmount: 405212,
    pageViews: 1950,
    date: '2025-01-07',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 31,
    totalSalesAmount: 463647,
    pageViews: 1977,
    date: '2025-01-08',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 24,
    totalSalesAmount: 360469,
    pageViews: 1812,
    date: '2025-01-09',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 34,
    totalSalesAmount: 509966,
    pageViews: 1819,
    date: '2025-01-10',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 31,
    totalSalesAmount: 464903,
    pageViews: 2051,
    date: '2025-01-11',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 26,
    totalSalesAmount: 390242,
    pageViews: 1890,
    date: '2025-01-12',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 37,
    totalSalesAmount: 556392,
    pageViews: 1897,
    date: '2025-01-13',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 33,
    totalSalesAmount: 495206,
    pageViews: 1871,
    date: '2025-01-14',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 23,
    totalSalesAmount: 378777,
    pageViews: 1911,
    date: '2025-01-15',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 119594,
    pageViews: 1419,
    date: '2025-01-16',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 33,
    totalSalesAmount: 543578,
    pageViews: 1589,
    date: '2025-01-17',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 24,
    totalSalesAmount: 384172,
    pageViews: 1806,
    date: '2025-01-18',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 39,
    totalSalesAmount: 623909,
    pageViews: 1883,
    date: '2025-01-19',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 21,
    totalSalesAmount: 337213,
    pageViews: 1721,
    date: '2025-01-20',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 18,
    totalSalesAmount: 324502,
    pageViews: 1576,
    date: '2025-01-21',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 13,
    totalSalesAmount: 259987,
    pageViews: 1412,
    date: '2025-01-22',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 139993,
    pageViews: 1175,
    date: '2025-01-23',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 9,
    totalSalesAmount: 183562,
    pageViews: 1236,
    date: '2025-01-24',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 11,
    totalSalesAmount: 219989,
    pageViews: 1283,
    date: '2025-01-25',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 7,
    totalSalesAmount: 139993,
    pageViews: 1442,
    date: '2025-01-26',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 199990,
    pageViews: 1291,
    date: '2025-01-27',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 159992,
    pageViews: 1212,
    date: '2025-01-28',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 13,
    totalSalesAmount: 259987,
    pageViews: 1189,
    date: '2025-01-29',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 159992,
    pageViews: 1110,
    date: '2025-01-30',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 11,
    totalSalesAmount: 219589,
    pageViews: 1032,
    date: '2025-01-31',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 119994,
    pageViews: 1085,
    date: '2025-02-01',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 15,
    totalSalesAmount: 299985,
    pageViews: 1303,
    date: '2025-02-02',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 31,
    totalSalesAmount: 484969,
    pageViews: 1866,
    date: '2025-02-03',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 40,
    totalSalesAmount: 599449,
    pageViews: 2095,
    date: '2025-02-04',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 43,
    totalSalesAmount: 644957,
    pageViews: 2113,
    date: '2025-02-05',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 37,
    totalSalesAmount: 554920,
    pageViews: 2243,
    date: '2025-02-06',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 28,
    totalSalesAmount: 419972,
    pageViews: 1985,
    date: '2025-02-07',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 31,
    totalSalesAmount: 465159,
    pageViews: 1992,
    date: '2025-02-08',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 32,
    totalSalesAmount: 475207,
    pageViews: 2035,
    date: '2025-02-09',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 29,
    totalSalesAmount: 434971,
    pageViews: 2127,
    date: '2025-02-10',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 19,
    totalSalesAmount: 284981,
    pageViews: 1798,
    date: '2025-02-11',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 32,
    totalSalesAmount: 479968,
    pageViews: 2098,
    date: '2025-02-12',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 25,
    totalSalesAmount: 374975,
    pageViews: 1913,
    date: '2025-02-13',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 24,
    totalSalesAmount: 359976,
    pageViews: 1677,
    date: '2025-02-14',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 30,
    totalSalesAmount: 449970,
    pageViews: 2103,
    date: '2025-02-15',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 29,
    totalSalesAmount: 435092,
    pageViews: 2406,
    date: '2025-02-16',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 35,
    totalSalesAmount: 524965,
    pageViews: 2149,
    date: '2025-02-17',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 13,
    totalSalesAmount: 194987,
    pageViews: 2368,
    date: '2025-02-18',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 28,
    totalSalesAmount: 419972,
    pageViews: 2428,
    date: '2025-02-19',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 28,
    totalSalesAmount: 420052,
    pageViews: 2322,
    date: '2025-02-20',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 26,
    totalSalesAmount: 284981,
    pageViews: 1458,
    date: '2025-02-21',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 27,
    totalSalesAmount: 239984,
    pageViews: 1626,
    date: '2025-02-22',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 24,
    totalSalesAmount: 149990,
    pageViews: 1586,
    date: '2025-02-23',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 29,
    totalSalesAmount: 314979,
    pageViews: 1503,
    date: '2025-02-24',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 31,
    totalSalesAmount: 464969,
    pageViews: 2269,
    date: '2025-02-25',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 28,
    totalSalesAmount: 419972,
    pageViews: 2257,
    date: '2025-02-26',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 26,
    totalSalesAmount: 389974,
    pageViews: 1979,
    date: '2025-02-27',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 37,
    totalSalesAmount: 554963,
    pageViews: 1859,
    date: '2025-02-28',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 21,
    totalSalesAmount: 314979,
    pageViews: 1881,
    date: '2025-03-01',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 37,
    totalSalesAmount: 554945,
    pageViews: 1901,
    date: '2025-03-02',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 168590,
    pageViews: 1696,
    date: '2025-03-03',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 3,
    totalSalesAmount: 55197,
    pageViews: 1531,
    date: '2025-03-04',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 8,
    totalSalesAmount: 147192,
    pageViews: 1400,
    date: '2025-03-05',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 5,
    totalSalesAmount: 91995,
    pageViews: 1332,
    date: '2025-03-06',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 6,
    totalSalesAmount: 110394,
    pageViews: 1236,
    date: '2025-03-07',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 10,
    totalSalesAmount: 183990,
    pageViews: 1592,
    date: '2025-03-08',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 12,
    totalSalesAmount: 220788,
    pageViews: 1465,
    date: '2025-03-09',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 49,
    totalSalesAmount: 734951,
    pageViews: 2075,
    date: '2025-03-10',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 24,
    totalSalesAmount: 359976,
    pageViews: 1923,
    date: '2025-03-11',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 36,
    totalSalesAmount: 539964,
    pageViews: 1878,
    date: '2025-03-12',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 32,
    totalSalesAmount: 479968,
    pageViews: 1935,
    date: '2025-03-13',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 24,
    totalSalesAmount: 360276,
    pageViews: 1837,
    date: '2025-03-14',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 36,
    totalSalesAmount: 544825,
    pageViews: 1878,
    date: '2025-03-15',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 24,
    totalSalesAmount: 360813,
    pageViews: 1985,
    date: '2025-03-16',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 24,
    totalSalesAmount: 360405,
    pageViews: 1906,
    date: '2025-03-17',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 34,
    totalSalesAmount: 510181,
    pageViews: 1917,
    date: '2025-03-18',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 29,
    totalSalesAmount: 430940,
    pageViews: 1875,
    date: '2025-03-19',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 31,
    totalSalesAmount: 465536,
    pageViews: 1973,
    date: '2025-03-20',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 20,
    totalSalesAmount: 300387,
    pageViews: 1727,
    date: '2025-03-21',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 23,
    totalSalesAmount: 344977,
    pageViews: 2024,
    date: '2025-03-22',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 29,
    totalSalesAmount: 435732,
    pageViews: 1969,
    date: '2025-03-23',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 26,
    totalSalesAmount: 390397,
    pageViews: 1995,
    date: '2025-03-24',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 29,
    totalSalesAmount: 434971,
    pageViews: 2073,
    date: '2025-03-25',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 30,
    totalSalesAmount: 450481,
    pageViews: 1938,
    date: '2025-03-26',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  },
  {
    unitsSold: 19,
    totalSalesAmount: 284981,
    pageViews: 1714,
    date: '2025-03-27',
    skuId: 'c39486ce-efb1-4368-b608-8e2bfc51be0a'
  }
] as Array<ModelTypes['EtailerProductMetrics']>
